import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import RequiredLabel from '../../../../common/form/required-label';
import { useGetContactDefaultPackageSpec } from '../../../../common/react-hooks/use-get-contact-default-package-spec';
import { useOrderFormEditAccess } from '../../../orders/components/order-form/contexts/order-form-edit-access-context';
import {
  type OrderFormValues,
  type PackageValues,
} from '../../../orders/components/order-form/forms/types';
import { getInitialPackageValues } from '../../../orders/components/order-form/forms/utils';
import PackageRow from './package-row';

const PADDING_AMOUNT = '3px';

const Packages = ({
  saveOrderLoading,
}: {
  readonly saveOrderLoading: boolean;
}) => {
  const { watch, setValue } = useFormContext<OrderFormValues>();
  const getContactDefaultPackageSpec = useGetContactDefaultPackageSpec();
  const contactUuid = watch('contactUuid');

  const watchFieldArray: PackageValues[] = watch('packages') ?? [];

  const { disabledIfInvoicePosted } = useOrderFormEditAccess();

  const deletePackage = (uuid: string) => {
    setValue(
      'packages',
      watchFieldArray.filter((pkg) => pkg.uuid !== uuid),
    );
  };

  const addPackage = async () => {
    const defaultPackageSpec = await getContactDefaultPackageSpec(contactUuid);
    setValue('packages', [
      ...watchFieldArray,
      getInitialPackageValues(defaultPackageSpec),
    ]);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h6" sx={{ fontSize: '16px' }}>
          Packages
        </Typography>
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          {saveOrderLoading && <CircularProgress size={20} />}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <TableContainer sx={{ width: '100%' }}>
          <Table sx={{ width: '100%' }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ padding: PADDING_AMOUNT }}>
                  <RequiredLabel>Qty</RequiredLabel>
                </TableCell>
                <TableCell sx={{ padding: PADDING_AMOUNT }}>Weight</TableCell>
                <TableCell sx={{ padding: PADDING_AMOUNT }}>Dims</TableCell>
                <TableCell sx={{ padding: PADDING_AMOUNT, minWidth: '80px' }}>
                  Dim Weight
                </TableCell>
              </TableRow>
            </TableHead>
            {watchFieldArray.map((package_, idx) => (
              <PackageRow
                key={package_.uuid}
                idx={idx}
                deletePackage={deletePackage}
              />
            ))}
          </Table>
        </TableContainer>
        <Button
          sx={{ mr: 'auto' }}
          disabled={disabledIfInvoicePosted}
          onClick={addPackage}
        >
          + Add package
        </Button>
      </Box>
    </Box>
  );
};

export default Packages;
