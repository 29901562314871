import { Button, type ButtonProps } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import type React from 'react';

const ConfirmationButton = (
  props: ButtonProps & {
    readonly onConfirm: () => void;
    readonly onCancel?: () => void;
    readonly children: React.ReactNode;
    readonly confirmationTitle?: React.ReactNode;
    readonly confirmationDescription?: React.ReactNode;
    readonly cancellationText?: string;
    readonly confirmationText?: string;
    readonly hideCancelButton?: boolean;
  },
) => {
  const confirm = useConfirm();
  const {
    children,
    confirmationTitle,
    confirmationDescription,
    cancellationText,
    confirmationText,
    hideCancelButton,
    onConfirm,
    onCancel,
  } = props;
  return (
    <Button
      {...props}
      onClick={async () => {
        await confirm({
          title: confirmationTitle,
          description: confirmationDescription,
          cancellationText: cancellationText ?? `Cancel`,
          confirmationText: confirmationText ?? `Confirm`,
          hideCancelButton,
        })
          .then(onConfirm)
          .catch(onCancel);
      }}
    >
      {children}
    </Button>
  );
};

export { ConfirmationButton };
