import DownloadIcon from '@mui/icons-material/Download';
import {
  Button,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TableCell,
  TableRow,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import React, { type Dispatch, type SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useMe from '../../../../../common/react-hooks/use-me';
import {
  type InvoiceSendJobBatchFragment,
  useInvoiceSendJobsShallowQuery,
} from '../../../../../generated/graphql';
import PalletLink from '../../../../../pallet-ui/links/link/pallet-link';
import useInvoicesStore from '../../../invoices-store';
import InvoiceSendJobProgress from './invoice-send-job-progress';

const InvoiceSendJobBatchRow = ({
  invoiceSendJobBatch,
  setSelectedInvoiceSendJobBatch,
}: {
  readonly invoiceSendJobBatch: InvoiceSendJobBatchFragment;
  readonly setSelectedInvoiceSendJobBatch: Dispatch<
    SetStateAction<InvoiceSendJobBatchFragment | undefined>
  >;
}) => {
  const navigate = useNavigate();
  const { companyConfiguration } = useMe();
  const downloadInvoiceBatchDocument = useInvoicesStore(
    (state) => state.downloadInvoiceBatchDocument,
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const { data: invoiceSendJobsData } = useInvoiceSendJobsShallowQuery({
    variables: {
      invoiceSendJobBatchUuid: invoiceSendJobBatch.uuid,
      first: 3,
    },
  });

  const { invoiceBatchDocuments } = invoiceSendJobBatch;

  const goToInvoice = (invoiceUuid: string, name: string) => {
    navigate(
      `/accounting/?tab=1&invoiceUuid=${invoiceUuid}&invoiceName=${name}`,
    );
  };

  const totalCount = invoiceSendJobsData?.invoiceSendJobs.totalCount ?? 0;
  const invoiceSendJobs = invoiceSendJobsData?.invoiceSendJobs;
  const invoiceSendJobsLength = invoiceSendJobs?.edges.length ?? 0;

  return (
    <TableRow
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <TableCell>
        {dayjs(invoiceSendJobBatch.createdAt).format('MM/DD/YY hh:mm a')}
      </TableCell>
      <TableCell sx={{ width: 150 }}>
        {invoiceSendJobs?.edges.map(({ node: invoiceSendJob }, index) => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <PalletLink
            key={invoiceSendJob.uuid}
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              goToInvoice(
                invoiceSendJob.invoice.uuid,
                invoiceSendJob.invoice.name,
              );
            }}
          >
            {companyConfiguration?.useJournalNumberForInvoice === true
              ? String(invoiceSendJob.invoice.journalNumber)
              : invoiceSendJob.invoice.name}
            {index === invoiceSendJobsLength - 1 ? '' : ', '}
          </PalletLink>
        ))}
        {invoiceSendJobsLength >= totalCount
          ? ''
          : `\n+ ${totalCount - invoiceSendJobsLength} more`}
      </TableCell>
      <TableCell>{sentenceCase(invoiceSendJobBatch.type)}</TableCell>
      <TableCell align="right" sx={{ width: 400 }}>
        <InvoiceSendJobProgress
          invoiceSendJobBatchProgress={
            invoiceSendJobBatch.invoiceSendJobBatchProgress
          }
        />
      </TableCell>
      <TableCell align="right">
        <Fade in={isHovering}>
          <Stack spacing={1} direction="row" justifyContent="right">
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                setSelectedInvoiceSendJobBatch(invoiceSendJobBatch);
              }}
            >
              View info
            </Button>
            <IconButton
              size="small"
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
              }}
            >
              <DownloadIcon />
            </IconButton>
          </Stack>
        </Fade>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          onClose={() => {
            setAnchorEl(null);
          }}
        >
          <MenuItem
            disabled={isNil(invoiceBatchDocuments.invoicesZipDocument)}
            onClick={() => {
              if (!isNil(invoiceBatchDocuments.invoicesZipDocument)) {
                downloadInvoiceBatchDocument(
                  invoiceBatchDocuments.invoicesZipDocument,
                );
              }
            }}
          >
            Download invoices
          </MenuItem>
          <MenuItem
            disabled={isNil(invoiceBatchDocuments.invoicesCombinedDocument)}
            onClick={() => {
              if (!isNil(invoiceBatchDocuments.invoicesCombinedDocument)) {
                downloadInvoiceBatchDocument(
                  invoiceBatchDocuments.invoicesCombinedDocument,
                );
              }
            }}
          >
            Download combined pdf
          </MenuItem>
          <MenuItem
            disabled={isNil(invoiceBatchDocuments.invoicesBackupDocument)}
            onClick={() => {
              if (!isNil(invoiceBatchDocuments.invoicesBackupDocument)) {
                downloadInvoiceBatchDocument(
                  invoiceBatchDocuments.invoicesBackupDocument,
                );
              }
            }}
          >
            Download backups
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
};

export default InvoiceSendJobBatchRow;
