import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Stack,
} from '@mui/material';
import { isNil } from 'lodash';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import useMe from '../../../common/react-hooks/use-me';
import {
  MeDocument,
  useUpdateOrderFormFieldsMutation,
} from '../../../generated/graphql';

type FormState = {
  onePackageRequired: boolean;
  onePackageWeightRequired: boolean;
};
type EditableField = {
  name: keyof FormState;
  label: string;
};
const EDITABLE_FIELDS: EditableField[] = [
  {
    name: 'onePackageRequired',
    label: 'At least one package',
  },
  {
    name: 'onePackageWeightRequired',
    label: 'At least one package weight',
  },
];

const OrderFormFields = ({ disabled }: { readonly disabled: boolean }) => {
  const { control, reset, handleSubmit } = useForm<FormState>();

  const {
    companyData,
    companyConfiguration,
    loading: meLoading,
  } = useMe({
    onCompleted: (meData) => {
      reset({
        onePackageRequired:
          meData.me?.company.orderFormFields.onePackageRequired,
        onePackageWeightRequired:
          meData.me?.company.orderFormFields.onePackageWeightRequired,
      });
    },
  });

  const [updateOrderFormFields] = useUpdateOrderFormFieldsMutation({
    refetchQueries: [MeDocument],
  });

  const disabledRequiredFields = useMemo(
    () => [
      {
        label: 'Order label',
        checked: true,
      },
      {
        label: 'HAWB',
        checked: true,
      },
      {
        label: 'Inbound address (for pickups)',
        checked: true,
      },
      {
        label: 'Inbound service date',
        checked: true,
      },
      {
        label: 'Inbound terminal',
        checked: true,
        hidden: companyConfiguration?.terminalsEnabled,
      },
      {
        label: 'Outbound address (for deliveries)',
        checked: true,
      },
      {
        label: 'Outbound service date',
        checked: true,
      },
      {
        label: 'Outbound terminal',
        checked: true,
        hidden: companyConfiguration?.terminalsEnabled,
      },
    ],
    [companyConfiguration?.terminalsEnabled],
  );

  const onSubmit = async (values: FormState) => {
    await updateOrderFormFields({
      variables: {
        updateOrderFormFieldsInput: {
          onePackageRequired: values.onePackageRequired,
          onePackageWeightRequired: values.onePackageWeightRequired,
        },
      },
    });
  };

  if (isNil(companyData?.orderFormFields) || meLoading)
    return <CircularProgress />;

  return (
    <Stack direction="column">
      {disabledRequiredFields.map((field) => {
        if (field.hidden === true) return null;
        return (
          <FormControlLabel
            key={field.label}
            disabled
            checked
            value={field.checked}
            control={<Checkbox value={field.checked} />}
            label={field.label}
          />
        );
      })}
      {EDITABLE_FIELDS.map((field) => {
        return (
          <Controller<FormState>
            key={field.name}
            control={control}
            name={field.name}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={disabled}
                    checked={value ?? false}
                    onChange={(e) => {
                      onChange(e);
                      handleSubmit(onSubmit)();
                    }}
                  />
                }
                label={field.label}
              />
            )}
          />
        );
      })}
    </Stack>
  );
};

export default OrderFormFields;
