/* eslint-disable no-return-assign */
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  FormControl,
  TableCell,
  TableRow,
  TextField,
  Typography,
  IconButton,
  FormHelperText,
  Fade,
  Stack,
} from '@mui/material';
import { isNil, get } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { type FieldPath, useFormContext } from 'react-hook-form';
import { isNilOrEmptyString } from 'shared/string';
import { calculateDimensionalWeight } from 'shared/weight';
import FormNumberInput from '../../../orders/components/order-form/components/common/form-number-input';
import { useOrderFormEditAccess } from '../../../orders/components/order-form/contexts/order-form-edit-access-context';
import {
  type OrderFormValues,
  type PackageValues,
  type OrderFormFieldValues,
} from '../../../orders/components/order-form/forms/types';

export enum WeightUnit {
  Kilograms = 'kg',
  Pounds = 'lb',
}

export enum MeasurementUnit {
  Inches = 'in',
  Centimeters = 'cm',
}

const FONT_SIZE = '12px';
const styles = {
  dimensionsTextField: {
    width: '50px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none', // Change this to the desired border color
      },
    },
  },
  errorText: {
    color: '#D32F2F',
    position: 'absolute',
    whiteSpace: 'nowrap',
  },
  inputWithErrorContainer: {
    position: 'relative',
    flexWrap: 'nowrap',
  },
};

const DimensionsTextField = ({
  idx,
  disabled,
}: {
  readonly idx: number;
  readonly disabled: boolean;
}) => {
  const { setValue, watch } = useFormContext();
  const lengthKey = `packages.${idx}.length`;
  const heightKey = `packages.${idx}.height`;
  const widthKey = `packages.${idx}.width`;
  const length = watch(lengthKey);
  const height = watch(heightKey);
  const width = watch(widthKey);
  const [heightInput, setHeightInput] = useState(String(height ?? ''));
  const [widthInput, setWidthInput] = useState(String(width ?? ''));
  const [lengthInput, setLengthInput] = useState(String(length ?? ''));
  const inputRefs = useRef<Array<HTMLInputElement | null>>([null, null, null]);

  const focusNextInput = (index: number) => {
    if (inputRefs.current[index + 1]) {
      inputRefs.current[index + 1]?.focus();
    } else if (inputRefs.current[0]) {
      inputRefs.current[0]?.focus();
    }
  };

  const focusPrevInput = (index: number) => {
    if (inputRefs.current[index - 1]) {
      inputRefs.current[index - 1]?.focus();
    } else if (inputRefs.current[2]) {
      inputRefs.current[2]?.focus();
    }
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent, index: number) => {
      const inputRef = inputRefs.current[index];
      if (!inputRef) return;

      const { selectionStart, value } = inputRef;

      if (e.key === 'ArrowRight' || e.key === 'ArrowDown') {
        if (selectionStart === value.length) {
          // Prevent tabbing only when caret is at the end
          e.preventDefault();
          focusNextInput(index);
        }
      } else if (
        (e.key === 'ArrowLeft' || e.key === 'ArrowUp') &&
        selectionStart === 0
      ) {
        // Prevent tabbing only when caret is at the beginning
        e.preventDefault();
        focusPrevInput(index);
      }
    };

    const inputRefsWithListeners = inputRefs.current.map((inputRef, index) => {
      if (!isNil(inputRef)) {
        inputRef.addEventListener('keydown', (e) => {
          handleKeyDown(e, index);
        });
      }
      return inputRef;
    });

    return () => {
      for (const inputRef of inputRefsWithListeners) {
        if (!isNil(inputRef)) {
          inputRef.removeEventListener('keydown', (e) => {
            handleKeyDown(e, 0);
          });
        }
      }
    };
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid',
        borderRadius: '4px',
        borderColor: '#bfbebd',
        height: 25,
      }}
    >
      <FormControl>
        <TextField
          disabled={disabled}
          size="small"
          inputRef={(el) => (inputRefs.current[0] = el)}
          inputProps={{ style: { fontSize: FONT_SIZE } }}
          sx={styles.dimensionsTextField}
          value={lengthInput}
          onChange={(e) => {
            const parsedFloat = Number.parseFloat(e.target.value);
            setLengthInput(e.target.value);
            if (!Number.isNaN(parsedFloat)) {
              setValue(lengthKey, parsedFloat);
            }
          }}
        />
      </FormControl>
      <Typography>⨉</Typography>
      <FormControl>
        <TextField
          disabled={disabled}
          size="small"
          inputRef={(el) => (inputRefs.current[1] = el)}
          inputProps={{ style: { fontSize: FONT_SIZE } }}
          sx={styles.dimensionsTextField}
          value={widthInput}
          onChange={(e) => {
            const parsedFloat = Number.parseFloat(e.target.value);
            setWidthInput(e.target.value);
            if (!Number.isNaN(parsedFloat)) {
              setValue(widthKey, parsedFloat);
            }
          }}
        />
      </FormControl>
      <Typography>⨉</Typography>
      <FormControl>
        <TextField
          disabled={disabled}
          size="small"
          inputRef={(el) => (inputRefs.current[2] = el)}
          inputProps={{ style: { fontSize: FONT_SIZE } }}
          sx={styles.dimensionsTextField}
          value={heightInput}
          onChange={(e) => {
            const parsedFloat = Number.parseFloat(e.target.value);
            setHeightInput(e.target.value);
            if (!Number.isNaN(parsedFloat)) {
              setValue(heightKey, parsedFloat);
            }
          }}
        />
      </FormControl>
    </Box>
  );
};

const PackageRow = ({
  idx,
  deletePackage,
}: {
  readonly idx: number;
  readonly deletePackage: (uuid: string) => void;
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const weightKey = `packages.${idx}.weight`;
  const quantityKey: FieldPath<OrderFormFieldValues> = `packages.${idx}.quantity`;
  const heightKey = `packages.${idx}.height`;
  const widthKey = `packages.${idx}.width`;
  const lengthKey = `packages.${idx}.length`;
  const dimFactorKey = 'dimFactor';
  const quantity: PackageValues['quantity'] = watch(quantityKey);
  const weight: PackageValues['weight'] = watch(weightKey);
  const [weightInput, setWeightInput] = useState(String(weight ?? ''));
  const length: PackageValues['length'] = watch(lengthKey);
  const width: PackageValues['width'] = watch(widthKey);
  const height: PackageValues['height'] = watch(heightKey);
  const dimFactor: OrderFormValues['dimFactor'] = watch(dimFactorKey);
  const uuid: PackageValues['uuid'] = watch(`packages.${idx}.uuid`);
  const [dimWeight, setDimWeight] = useState('');
  const packageQuantityError = get(
    errors,
    `packages.${idx}.quantity.message`,
  )?.toString();
  const packageWeightError = get(
    errors,
    `packages.${idx}.weight.message`,
  )?.toString();
  const hasError =
    !isNilOrEmptyString(packageQuantityError) ||
    !isNilOrEmptyString(packageWeightError);
  const PADDING_AMOUNT = hasError ? '16px 3px' : '3px';

  const { disabledIfFinalizedOrLater } = useOrderFormEditAccess();

  useEffect(() => {
    const dimensionalWeight = calculateDimensionalWeight({
      length,
      width,
      height,
      quantity,
      dimFactor: dimFactor ?? undefined,
    });
    setDimWeight(dimensionalWeight.toFixed(2));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [length, width, height, dimFactor, quantity, weight]);

  return (
    <TableRow
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <TableCell sx={{ width: '250px', padding: PADDING_AMOUNT }}>
        <Box sx={styles.inputWithErrorContainer}>
          <FormNumberInput
            nonNegativeInteger
            fieldName={quantityKey}
            disabled={disabledIfFinalizedOrLater}
            sx={{ width: '40px' }}
            inputProps={{ style: { fontSize: FONT_SIZE, height: 12.5 } }}
            error={!isNil(packageQuantityError)}
          />
          {!isNil(packageQuantityError) && (
            <FormHelperText sx={styles.errorText}>
              {packageQuantityError}
            </FormHelperText>
          )}
        </Box>
      </TableCell>
      <TableCell sx={{ width: '250px', padding: PADDING_AMOUNT }}>
        {/* <FormControl fullWidth>
          <TextField
            disabled={disabledIfFinalizedOrLater}
            size="small"
            inputProps={{ style: { fontSize: FONT_SIZE, height: 12.5 } }}
            sx={{ width: '60px' }}
            value={weightInput}
            onChange={(e) => {
              const parsedFloat = parseFloat(e.target.value);
              setWeightInput(e.target.value);
              if (!Number.isNaN(parsedFloat)) {
                setValue(weightKey, parsedFloat);
              }
            }}
          />
          {!isNil(packageWeightError) && (
            <FormHelperText sx={{ color: '#D32F2F' }}>
              {packageWeightError}
            </FormHelperText>
          )}
        </FormControl> */}
        <Box sx={styles.inputWithErrorContainer}>
          <FormControl fullWidth>
            <TextField
              disabled={disabledIfFinalizedOrLater}
              size="small"
              inputProps={{ style: { fontSize: FONT_SIZE, height: 12.5 } }}
              sx={{ width: '60px' }}
              value={weightInput}
              onChange={(e) => {
                const parsedFloat = Number.parseFloat(e.target.value);
                setWeightInput(e.target.value);
                if (!Number.isNaN(parsedFloat)) {
                  setValue(weightKey, parsedFloat);
                }
              }}
            />
          </FormControl>
          {!isNil(packageWeightError) && (
            <FormHelperText sx={styles.errorText}>
              {packageWeightError}
            </FormHelperText>
          )}
        </Box>
      </TableCell>
      <TableCell sx={{ padding: PADDING_AMOUNT }}>
        <DimensionsTextField idx={idx} disabled={disabledIfFinalizedOrLater} />
      </TableCell>
      <TableCell sx={{ padding: PADDING_AMOUNT }}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="caption">{dimWeight}</Typography>{' '}
          {!disabledIfFinalizedOrLater && (
            <Fade in={isHovering}>
              <IconButton
                size="small"
                onClick={() => {
                  deletePackage(uuid);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Fade>
          )}
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default PackageRow;
