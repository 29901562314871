import { ArrowRightAlt } from '@mui/icons-material';
import { Stack, TextField, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { isNilOrEmptyString } from 'shared/string';

type RangeInputProps = {
  readonly rangeIndex: number;
  readonly prevValue: number | undefined;
  readonly rangeLength: number;
  readonly disabled?: boolean;
  readonly error?: string;
  readonly inputLabel?: string;
  readonly onBlur?: () => void;
  readonly onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  readonly displayValue: number | '?' | undefined;
  readonly editable: boolean;
};

export const RangeInput = ({
  rangeIndex,
  prevValue,
  rangeLength,
  disabled,
  error,
  inputLabel,
  onBlur,
  onChange,
  displayValue,
  editable,
}: RangeInputProps) => {
  let prevValueToDisplay: number | '?' = '?';
  if (rangeIndex > 0 && !isNil(prevValue)) {
    prevValueToDisplay = prevValue;
  } else if (rangeIndex === 0) {
    prevValueToDisplay = 0;
  }

  if (rangeIndex < rangeLength - 1) {
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>{prevValueToDisplay}</Typography>
        <ArrowRightAlt />
        {editable ? (
          <TextField
            disabled={disabled}
            error={!isNilOrEmptyString(error)}
            name="range"
            size="small"
            label={inputLabel}
            value={displayValue}
            type="number"
            helperText={error}
            onBlur={onBlur}
            onChange={onChange}
            onWheel={(e) => {
              (e.target as HTMLTextAreaElement).blur();
            }}
          />
        ) : (
          <Typography>{displayValue}</Typography>
        )}
      </Stack>
    );
  }

  return (
    <Typography>
      {prevValueToDisplay}
      {prevValueToDisplay !== '?' && '+'} {inputLabel}
    </Typography>
  );
};
