import { FormControl, FormHelperText, TextField } from '@mui/material';
import { isNil } from 'lodash';
import React, { type FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import useMe from '../../../../../common/react-hooks/use-me';
import { type OrderFormValues } from '../forms/types';

type OrderNameFieldProps = {
  readonly disabled: boolean;
};

const OrderNameFieldComponent: FunctionComponent<OrderNameFieldProps> = ({
  disabled,
}) => {
  const { useAllCaps } = useMe();
  const {
    control,
    formState: { errors },
  } = useFormContext<OrderFormValues>();

  return (
    <Controller
      name="name"
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl sx={{ width: '190px' }}>
          <TextField
            label="Order Name"
            data-cy="order-name-input-if"
            data-testid="order-name"
            size="small"
            sx={{ width: '100%' }}
            value={value ?? ''}
            error={!isNil(errors.name)}
            disabled={disabled}
            inputProps={
              useAllCaps ? { style: { textTransform: 'uppercase' } } : {}
            }
            onChange={onChange}
          />
          {!isNil(errors.name) && (
            <FormHelperText sx={{ color: '#D32F2F' }}>
              {errors.name?.message?.toString()}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export const OrderNameField = React.memo(OrderNameFieldComponent);
