import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../../redux/store';
import { type CustomChargeError } from './drayage/order-form-types';

type CustomChargeErrorsStoreState = CustomChargeError;

const customChargesErrorsAdapter =
  createEntityAdapter<CustomChargeErrorsStoreState>({
    selectId: (customCharge) => customCharge.uuid,
  });

export const customChargesErrorsSlice = createSlice({
  name: 'customChargesErrors',
  initialState: customChargesErrorsAdapter.getInitialState(),
  reducers: {
    addCustomChargeErrors: customChargesErrorsAdapter.addOne,
    upsertCustomChargeErrors: customChargesErrorsAdapter.upsertOne,
    deleteCustomChargeErrors: customChargesErrorsAdapter.removeOne,
    updateCustomChargesErrors: customChargesErrorsAdapter.updateMany,
  },
});

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectById: selectCustomChargeErrorsById,
  // Pass in a selector that returns the posts slice of state
} = customChargesErrorsAdapter.getSelectors(
  (state: RootState) => state.orderFormCustomChargesErrors,
);

export const { upsertCustomChargeErrors } = customChargesErrorsSlice.actions;

export default customChargesErrorsSlice.reducer;
