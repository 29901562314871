import { type SxProps, TextField } from '@mui/material';
import { isNil } from 'lodash';
import { useMemo } from 'react';
import {
  type TerminalEntity,
  useTerminalsQuery,
} from '../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';

export type TerminalOption = Pick<TerminalEntity, 'uuid' | 'code' | 'name'>;

const isOptionEqualToValue = (
  option: TerminalOption | '',
  value: TerminalOption | '',
) =>
  typeof option !== 'string' &&
  typeof value !== 'string' &&
  option.uuid === value.uuid;

const getOptionLabel = (option: TerminalOption | '') =>
  typeof option === 'string' ? '' : option.code;

type TerminalSelectProps = Readonly<{
  value: TerminalOption | null;
  onChange: (value: TerminalOption) => void;
  required?: boolean;
  disabled?: boolean;
  sx?: SxProps;
  // Used for handling errors from loading terminals.
  onError: (error: Error) => void;
}>;

// Displays an autocomplete element with active terminals.
export const TerminalSelect = ({
  value,
  onChange,
  required,
  disabled,
  sx,
  onError,
}: TerminalSelectProps) => {
  const { data, loading } = useTerminalsQuery({
    fetchPolicy: 'cache-and-network',
    onError,
    variables: {
      getTerminalsInput: {
        excludeNotActive: true,
      },
    },
  });
  const options = useMemo<TerminalOption[]>(() => {
    if (isNil(data)) {
      return [];
    }
    const { terminals } = data;
    if (
      isNil(value) ||
      terminals.some((terminal) => terminal.uuid === value.uuid)
    ) {
      return terminals;
    }
    // The selected terminal was previously saved but now it is archived.
    return [...terminals, value];
  }, [data, value]);

  return (
    <AutocompleteFuzzy<TerminalOption | '', false, true, false>
      disableClearable
      sx={sx}
      disabled={disabled}
      loading={loading}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      matchSortOptions={{ keys: ['code'] }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Terminal"
          required={required}
          size="small"
        />
      )}
      options={options}
      value={value ?? ''}
      onChange={(_e, newValue) => {
        if (!isNil(newValue) && newValue !== '') {
          onChange(newValue);
        }
      }}
    />
  );
};
