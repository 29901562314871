// eslint-disable-next-line no-restricted-imports
import { Grid, Typography } from '@mui/material';
import { sentenceCase } from 'change-case';
import React from 'react';
import {
  CustomChargeBillingMethod,
  type LoadCustomChargeFragment,
} from '../../../../generated/graphql';

type CustomChargesTableProps = {
  readonly customCharges: LoadCustomChargeFragment[];
};

const CustomChargesTable = ({ customCharges }: CustomChargesTableProps) => {
  return (
    <Grid container spacing={2} sx={{ marginBottom: 6 }}>
      <Grid item xs={12}>
        <Typography variant="h6">Additional Charges</Typography>
      </Grid>
      {customCharges.map((customCharge) => {
        return (
          <>
            <Grid item xs={2}>
              <Typography sx={{ fontWeight: 'bold' }}>Charge type</Typography>
              {sentenceCase(customCharge.customChargeBillingMethod)}
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ fontWeight: 'bold' }}>Name</Typography>
              {customCharge.customChargeBillingMethod ===
                CustomChargeBillingMethod.AdHoc && customCharge?.name}
              {customCharge.customChargeBillingMethod ===
                CustomChargeBillingMethod.Accessorial &&
                customCharge?.accessorialTemplate?.name}
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ fontWeight: 'bold' }}>Description</Typography>
              {customCharge.description}
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ fontWeight: 'bold' }}>
                {customCharge?.accessorialTemplate?.__typename ===
                'WaitTimeAccessorialEntity'
                  ? 'Minutes'
                  : 'Quantity'}
              </Typography>
              {customCharge.quantity}
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ fontWeight: 'bold' }}>Rate</Typography>
              {customCharge.rate}
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ fontWeight: 'bold' }}>Total</Typography>
              {`$${customCharge.total.toFixed(2)}`}
            </Grid>
          </>
        );
      })}
    </Grid>
  );
};
export default CustomChargesTable;
