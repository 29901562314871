/* eslint-disable no-param-reassign */
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { type RootState } from '../../redux/store';

type VersionHistoryState = {
  versionHistoryOpen: boolean;
  logSearchInput: string;
  showEdits: boolean;
  selectedVersionUuid: string | undefined;
  previousVersionState: RootState | undefined;
};

type VersionHistoryActions = {
  setVersionHistoryOpen: (open: boolean) => void;
  setLogSearchInput: (text: string) => void;
  setShowEdits: (show: boolean) => void;
  setSelectedVersionUuid: (uuid: string | undefined) => void;
  setPreviousVersionState: (previousState: RootState | undefined) => void;
};

const useVersionHistoryStore = create(
  immer<VersionHistoryState & VersionHistoryActions>((set) => ({
    versionHistoryOpen: false,
    logSearchInput: '',
    showEdits: true,
    selectedVersionUuid: undefined,
    previousVersionState: undefined,
    setVersionHistoryOpen: (open) => {
      set((state) => {
        state.versionHistoryOpen = open;
      });
    },
    setLogSearchInput: (input) => {
      set((state) => {
        state.logSearchInput = input;
      });
    },
    setShowEdits: (show) => {
      set((state) => {
        state.showEdits = show;
      });
    },
    setSelectedVersionUuid: (uuid) => {
      set((state) => {
        state.selectedVersionUuid = uuid;
      });
    },
    setPreviousVersionState: (previousState) => {
      set((state) => {
        state.previousVersionState = previousState;
      });
    },
  })),
);

export default useVersionHistoryStore;
