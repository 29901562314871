import { Chip } from '@mui/material';
import React from 'react';
import { exhaustive } from 'shared/switch';
import {
  EmailSendStatus,
  EmailTransactionStatus,
} from '../../../../../../generated/graphql';

const EmailTransactionStatusChip = ({
  status,
}: {
  readonly status: EmailTransactionStatus | EmailSendStatus;
}) => {
  switch (status) {
    case EmailSendStatus.NotSent: {
      return <Chip size="small" label="Not sent" />;
    }
    case EmailSendStatus.Sent:
    case EmailTransactionStatus.Sent: {
      return <Chip size="small" label="Sent" color="success" />;
    }
    case EmailSendStatus.Failed:
    case EmailTransactionStatus.Failed: {
      return <Chip size="small" label="Failed" color="error" />;
    }
    case EmailSendStatus.PartiallySent:
    case EmailTransactionStatus.PartiallySent: {
      return <Chip size="small" label="Partially sent" color="warning" />;
    }
    case EmailSendStatus.Sending:
    case EmailTransactionStatus.Sending: {
      return <Chip size="small" label="Sending" color="info" />;
    }
    default: {
      return exhaustive(status);
    }
  }
};

export default EmailTransactionStatusChip;
