import { Box, Stack } from '@mui/material';
import React from 'react';
import { shallow } from 'zustand/shallow';
import useDispatchTableColors from '../../../common/react-hooks/use-dispatch-table-colors';
import {
  FilterViewPage,
  type StopsQueryVariables,
} from '../../../generated/graphql';
import useGlobalStore from '../../../layouts/dashboard/global-store';
import { StopsTab } from '../../ag-grid/dispatch-stops/constants';
import DispatchStopsControlPanel from '../../ag-grid/dispatch-stops/dispatch-stops-control-panel';
import { type DefaultFilterTabsConfigs } from '../../ag-grid/dispatch-stops/types';
import useDispatchStore from '../dispatch-store';
import Routes from '../routes/routes';

const defaultFilterTabConfig: DefaultFilterTabsConfigs<StopsTab> = {
  defaultTab: StopsTab.AllStops,
  tabs: [
    {
      value: StopsTab.AllStops,
      label: 'All',
      filtersToApply: {},
    },
  ],
};

const RoutesMapSidebar = ({
  onTableFiltersChanged,
}: {
  readonly onTableFiltersChanged: (variables: StopsQueryVariables) => void;
}) => {
  const selectedTerminalUuid = useGlobalStore(
    (state) => state.selectedTerminalUuid,
  );
  const [showUnassignedStopsInMap] = useDispatchStore(
    (state) => [state.showUnassignedStopsInMap],
    shallow,
  );

  const { dispatchTableColors } = useDispatchTableColors();

  return (
    <Stack height="100%">
      {showUnassignedStopsInMap && (
        <Stack
          sx={{
            height: '45%',
            overflowY: 'scroll',
          }}
          direction="column"
        >
          <DispatchStopsControlPanel
            shouldAllowSavedFilterViews
            showFilterMenu
            isMapView
            pageType={FilterViewPage.Dispatch}
            dispatchTableColors={dispatchTableColors}
            defaultFilterTabsConfigs={defaultFilterTabConfig}
            selectedTerminalUuid={selectedTerminalUuid}
            onDataChanged={onTableFiltersChanged}
          />
        </Stack>
      )}
      <Box sx={{ flex: 1, height: '55%' }}>
        <Routes isMapView terminalUuid={selectedTerminalUuid} />
      </Box>
    </Stack>
  );
};

export default React.memo(RoutesMapSidebar);
