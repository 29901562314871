import { isNil } from 'lodash';
import { z } from 'zod';
import { type OrderFormFieldsFragment } from '../../../../../generated/graphql';
import { packageSchema } from './package-schema';

export const getPackagesSchema = (
  orderFormFields?: OrderFormFieldsFragment,
) => {
  const basePackagesSchema = z.array(packageSchema);

  const packagesSchema =
    orderFormFields?.onePackageRequired === true
      ? basePackagesSchema.min(1, 'At least one package is required')
      : basePackagesSchema.nullish();

  return packagesSchema.refine(
    (packages) => {
      if (orderFormFields?.onePackageWeightRequired !== true) {
        return true;
      }
      if (isNil(packages)) {
        return false;
      }
      return packages.some((pkg) => !isNil(pkg.weight));
    },
    { message: 'At least one package must have a weight' },
  );
};
