import SwapVertRoundedIcon from '@mui/icons-material/SwapVertRounded';
import { type AgGridReact } from 'ag-grid-react';
import React, { type RefObject } from 'react';
import {
  type FormattedOrderFragment,
  type OrderTableField,
} from '../../../../../generated/graphql';
import IconButtonWithPopover from '../IconButtonWithPopover';
import SortPopover from './sort-popover';

type SortButtonProps = {
  readonly gridRef: RefObject<AgGridReact<FormattedOrderFragment>>;
  readonly orderTableFields: OrderTableField[];
  readonly hasSorts: boolean;
  readonly hasChanges: boolean;
};

const SortButton = ({
  gridRef,
  orderTableFields,
  hasSorts,
  hasChanges,
}: SortButtonProps) => {
  return (
    <IconButtonWithPopover
      icon={<SwapVertRoundedIcon />}
      shouldHighlight={hasSorts}
      showBadge={hasChanges}
      render={(handleIconPopoverClose) => (
        <SortPopover
          orderTableFields={orderTableFields}
          gridRef={gridRef}
          onClose={handleIconPopoverClose}
        />
      )}
    />
  );
};

export default SortButton;
