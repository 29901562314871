import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Box,
  Button,
  Divider,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  Modal,
  Snackbar,
  Typography,
} from '@mui/material';
import fileDownload from 'js-file-download';
import { isEmpty, isNil } from 'lodash';
import { type Dispatch, type SetStateAction, useMemo, useState } from 'react';
import { exhaustive } from 'shared/switch';
import CustomerFilterButton from '../../../../../common/components/customer-filter-button';
import DateDropdownPicker, {
  type DateOption,
  defaultPast1WeekDateRangeOption,
} from '../../../../../common/components/date-dropdown-picker';
import DownloadTypeSelection, {
  DownloadType,
} from '../../../../../common/components/download-type-selection';
import { type Option } from '../../../../../common/filters/types';
import { useMeasureExecutionTime } from '../../../../../common/react-hooks/use-measure-execution-time';
import {
  type ChargeBreakdownReportInput,
  useChargeBreakdownReportCsvLazyQuery,
  useChargeBreakdownReportPdfLazyQuery,
} from '../../../../../generated/graphql';
import useInvoicesStore from '../../../invoices-store';
import styles from '../../../styles';
import { accountingReportDefaultDateOption } from '../../accounting-reports/constants';
import ReportDateFilterTypeButton, {
  ReportDateFilterType,
} from './components/report-date-filter-type-button';

type DownloadCustomerChargesReportModalProps = {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const DownloadChargeBreakdownReportModal = ({
  isOpen,
  setIsOpen,
}: DownloadCustomerChargesReportModalProps) => {
  const [reportDateFilterType, setReportDateFilterType] =
    useState<ReportDateFilterType>(ReportDateFilterType.INVOICE_DATE);
  const [dateOption, setDateOption] = useState<DateOption>(
    defaultPast1WeekDateRangeOption,
  );
  const [customerOption, setCustomerOption] = useState<Option | undefined>();
  const [downloadType, setDownloadType] = useState<DownloadType>(
    DownloadType.PDF,
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [getChargeBreakdownReportPdf] = useChargeBreakdownReportPdfLazyQuery();
  const [getChargeBreakdownReportCsv] = useChargeBreakdownReportCsvLazyQuery();

  const createFileDownload = useInvoicesStore(
    (state) => state.createFileDownload,
  );

  const resetFilters = () => {
    setDateOption(accountingReportDefaultDateOption);
  };

  const input: ChargeBreakdownReportInput | null = useMemo(() => {
    if (isNil(customerOption?.value)) {
      return null;
    }
    const dateFilter = {
      gte: dateOption.startDate,
      lte: dateOption.endDate,
    };
    let invoiceDateFilter;
    let serviceDateFilter;
    switch (reportDateFilterType) {
      case ReportDateFilterType.SERVICE_DATE: {
        serviceDateFilter = dateFilter;
        break;
      }
      case ReportDateFilterType.INVOICE_DATE: {
        invoiceDateFilter = dateFilter;
        break;
      }
      default: {
        exhaustive(reportDateFilterType);
      }
    }

    return {
      billingPartyContactUuid: customerOption.value,
      serviceDateFilter,
      invoiceDateFilter,
    };
  }, [
    customerOption?.value,
    dateOption.startDate,
    dateOption.endDate,
    reportDateFilterType,
  ]);

  const startDownload = async () => {
    if (isNil(customerOption) || isNil(input)) {
      setErrorMessage(
        'Please select a specific customer to download this report',
      );
      return;
    }
    const completeDownload = createFileDownload();
    let presignedGetUrl = null;
    let fileName = null;
    let errors = null;

    switch (downloadType) {
      case DownloadType.PDF: {
        const pdfRes = await getChargeBreakdownReportPdf({
          variables: {
            input,
          },
        });

        presignedGetUrl = pdfRes.data?.chargeBreakdownReportPdf.url;
        fileName = pdfRes.data?.chargeBreakdownReportPdf.fileName;
        errors = pdfRes.data?.chargeBreakdownReportPdf.errors;
        break;
      }

      case DownloadType.CSV: {
        const csvRes = await getChargeBreakdownReportCsv({
          variables: {
            input,
          },
        });

        presignedGetUrl = csvRes.data?.chargeBreakdownReportCsv.url;
        fileName = csvRes.data?.chargeBreakdownReportCsv.fileName;
        errors = csvRes.data?.chargeBreakdownReportCsv.errors;
        break;
      }

      default: {
        errors = [`Download type not selected`];
        break;
      }
    }

    if (!isNil(errors) && !isEmpty(errors)) {
      setErrorMessage(errors[0]);
    } else if (!isNil(presignedGetUrl) && !isNil(fileName)) {
      const getFileRes = await fetch(presignedGetUrl, { cache: 'no-cache' });

      const blob = await getFileRes.blob();

      fileDownload(blob, fileName);
      resetFilters();
      completeDownload();
    }
  };

  const startDownloadWithTiming = useMeasureExecutionTime({
    fn: startDownload,
    rumLabel: 'download-charge-breakdown-report',
    logData: input ?? undefined,
  });

  return (
    <>
      <Snackbar
        autoHideDuration={3000}
        sx={{ marginTop: '60px' }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!isNil(errorMessage)}
      >
        <Alert
          severity="error"
          onClose={() => {
            setErrorMessage(undefined);
          }}
        >
          {errorMessage ?? ''}
        </Alert>
      </Snackbar>
      <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <Box sx={[styles.modal, { height: 'fit-content', width: '500px' }]}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={10}>
              <Typography sx={{ fontWeight: 'bold' }}>
                Download Charge Breakdown Report
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                sx={{ float: 'right' }}
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <ReportDateFilterTypeButton
                value={reportDateFilterType}
                onChange={setReportDateFilterType}
              />
            </Grid>
            <Grid item xs={12} display="flex" flexDirection="row" gap={1}>
              <DateDropdownPicker
                filterTitle={reportDateFilterType}
                dateOption={dateOption}
                setDateOption={setDateOption}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
            >
              <CustomerFilterButton
                disableAllOption
                selectedOption={customerOption}
                handleChange={(option: Option | undefined) => {
                  setCustomerOption(option);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{ float: 'left' }}
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={1}
              >
                <DownloadTypeSelection
                  labelText="File type"
                  cacheId="CHARGE_BREAKDOWN_REPORT"
                  selectedOption={downloadType}
                  handleChange={(selectedDownloadType: DownloadType) => {
                    setDownloadType(selectedDownloadType);
                  }}
                />
              </Box>
              <Box sx={{ float: 'right' }}>
                <Button
                  sx={{ width: '100px' }}
                  variant="contained"
                  color="info"
                  onClick={startDownloadWithTiming}
                >
                  Download
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default DownloadChargeBreakdownReportModal;
