import { Alert, Stack, TextField } from '@mui/material';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import { shallow } from 'zustand/shallow';
import {
  isNilOrEmptyString,
  isMutationErrorOutput,
} from '../../../../common/utils/utils';
import {
  DispatchViewsDocument,
  useCreateDispatchViewMutation,
} from '../../../../generated/graphql';
import PalletModal from '../../../../pallet-ui/modal/pallet-modal-old';
import useDispatchStore from '../../dispatch-store';

const CreateDispatchViewModal = React.memo(
  ({
    open,
    onClose,
  }: {
    readonly open: boolean;
    readonly onClose: () => void;
  }) => {
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [setSelectedViewUuid] = useDispatchStore(
      (state) => [state.setSelectedViewUuid],
      shallow,
    );
    const [createDispatchView, { loading }] = useCreateDispatchViewMutation({
      refetchQueries: [DispatchViewsDocument],
      onError: ({ message }) => {
        setErrorMessage(message);
      },
    });
    const [name, setName] = useState<string>('');

    const onConfirm = async () => {
      if (isNilOrEmptyString(name)) {
        setErrorMessage('Please enter a name');
        return;
      }

      const res = await createDispatchView({
        variables: {
          input: {
            name,
          },
        },
      });

      if (!isNil(res.errors?.[0])) {
        setErrorMessage(res.errors?.[0]?.message);
      } else if (isMutationErrorOutput(res.data?.createDispatchView)) {
        setErrorMessage(res.data?.createDispatchView.message);
      } else {
        setSelectedViewUuid(res.data?.createDispatchView?.dispatchView.id);
        onClose();
      }
    };

    return (
      <PalletModal
        open={open}
        title="Create dispatch view"
        actions={{
          bottomRight: [
            {
              children: 'Confirm',
              onClick: onConfirm,
              disabled: loading,
            },
          ],
        }}
        onClose={onClose}
      >
        <Stack sx={{ pt: 1 }} spacing={1}>
          <TextField
            required
            size="small"
            label="Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          {!isNil(errorMessage) && (
            <Alert
              severity="error"
              onClose={() => {
                setErrorMessage(undefined);
              }}
            >
              {errorMessage}
            </Alert>
          )}
        </Stack>
      </PalletModal>
    );
  },
);

export { CreateDispatchViewModal };
