import { ChevronRight, Close, Edit, Print, Receipt } from '@mui/icons-material';
import {
  Box,
  Button,
  Fade,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { isNil, uniq } from 'lodash';
import { useEffect, useState } from 'react';
import useStateRef from 'react-usestateref';
import CustomerFilterButton from '../../../common/components/customer-filter-button';
import { type Option } from '../../../common/filters/types';
import useWarehouses from '../../../common/react-hooks/use-warehouses';
import {
  type PicklistsFragment,
  usePicklistsLazyQuery,
  useWmsContactsQuery,
} from '../../../generated/graphql';
import PalletLink from '../../../pallet-ui/links/link/pallet-link';
import useCustomerPortalStore from '../../customer-portal/use-customer-portal-store';
import useStyles from '../../orders/components/styles';
import PickListModal from '../../storage-orders/components/common/modals/pick-list-modal';
import WarehouseSelector from '../../storage-orders/components/common/warehouse-selector';
import PackingSlipModal from './packing-list/packing-slip-modal';
import { getUniqueContactsFromPicklist } from './packing-list/utils';
import PicklistPdf from './picklist.pdf';

const PAGE_SIZE = 25;
type PicklistsTableProps = {
  readonly isCustomerPortal?: boolean;
};
const PicklistsTable = ({ isCustomerPortal = false }: PicklistsTableProps) => {
  const theme = useTheme();
  const styles = useStyles();
  const [page, setPage] = useState<number>(0);
  const [selectedPicklist, setSelectedPicklist] =
    useState<PicklistsFragment | null>(null);
  const [isCreatingPicklist, setIsCreatingPicklist] = useState(false);
  const [isPicklistModalOpen, setIsPicklistModalOpen] = useState(false);
  const [isPicklistPdfModalOpen, setIsPicklistPdfModalOpen] = useState(false);
  const [isPackingSlipModalOpen, setIsPackingSlipModalOpen] = useState(false);
  const [contactOption, setContactOption, contactOptionRef] =
    useStateRef<Option>();
  const [warehouseOption, setWarehouseOption, warehouseOptionRef] =
    useStateRef<Option>();
  const customerPortalCompany = useCustomerPortalStore(
    ({ company }) => company,
  );
  const { data: wmsContactsData } = useWmsContactsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      companyUuid: customerPortalCompany?.uuid,
    },
  });
  const { warehouses } = useWarehouses();
  // if company selection changes, unset contact selecgor
  useEffect(() => {
    if (
      wmsContactsData?.wmsContacts.find(
        (c) => c.uuid === contactOption?.value,
      ) === undefined
    ) {
      setContactOption(undefined);
    }
  }, [wmsContactsData, contactOption, setContactOption]);
  const [getPicklists, { data: picklistsData, loading, refetch }] =
    usePicklistsLazyQuery({
      fetchPolicy: 'cache-and-network',
    });
  const fetchPicklists = ({
    first,
    after,
    last,
    before,
  }: {
    first?: number | null | undefined;
    after?: string | null | undefined;
    last?: number | null | undefined;
    before?: string | null | undefined;
  }) => {
    getPicklists({
      variables: {
        picklistsInput: {
          first,
          after,
          last,
          before,
          companyUuid: customerPortalCompany?.uuid,
          contactUuid: contactOptionRef.current?.value,
          warehouseUuid: warehouseOptionRef.current?.value,
        },
      },
    });
  };
  const prev = async () => {
    await fetchPicklists({
      last: PAGE_SIZE,
      before: picklistsData?.picklists.pageInfo.startCursor ?? undefined,
    });
  };
  const next = async () => {
    await fetchPicklists({
      first: PAGE_SIZE,
      after: picklistsData?.picklists.pageInfo.endCursor ?? undefined,
    });
  };
  const refresh = () => {
    fetchPicklists({ first: PAGE_SIZE });
    setPage(0);
  };
  useEffect(() => {
    // refresh grid when company filter changes
    refresh();
    // if a company is selected and the selected picklist is not associated with the selected company, unset it
    if (
      !isNil(customerPortalCompany) &&
      !isNil(selectedPicklist) &&
      !selectedPicklist?.picklistItemGroups.some(
        (pig) =>
          pig.itemGroup.storageUnit?.storageOrder?.contact.company.uuid ===
          customerPortalCompany.uuid,
      )
    ) {
      setSelectedPicklist(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerPortalCompany, selectedPicklist]);

  return (
    <>
      <Stack
        justifyContent="flex-end"
        direction="row"
        width="100%"
        marginBottom={1}
        marginRight={1}
      >
        <Button
          variant="contained"
          onClick={() => {
            setIsCreatingPicklist(true);
            setIsPicklistModalOpen(true);
          }}
        >
          Create pick ticket
        </Button>
      </Stack>
      <Grid container sx={{ backgroundColor: 'white', height: '100%' }}>
        <Grid item xs={12}>
          <Grid
            container
            spacing={0}
            sx={{ overflowY: 'hidden', height: '100%' }}
          >
            <Grid
              item
              xs={12}
              md={isNil(selectedPicklist) ? 12 : 5}
              sx={{
                borderRight: 1,
                borderRightColor: theme.palette.borderColor.main,
                height: '100%',
                transition: '0.15s',
              }}
            >
              <TableContainer
                sx={{
                  overflowY: 'scroll',
                }}
              >
                <Stack
                  direction="row-reverse"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <TablePagination
                    rowsPerPageOptions={[PAGE_SIZE]}
                    component="div"
                    count={picklistsData?.picklists.totalCount ?? 0}
                    rowsPerPage={PAGE_SIZE}
                    page={page}
                    backIconButtonProps={{
                      disabled: loading || page === 0,
                    }}
                    nextIconButtonProps={{
                      disabled:
                        loading ||
                        picklistsData?.picklists.totalCount === 0 ||
                        page + 1 ===
                          Math.ceil(
                            (picklistsData?.picklists.totalCount ?? 0) /
                              PAGE_SIZE,
                          ),
                    }}
                    onPageChange={(e, newPage: number) => {
                      if (newPage > page) {
                        next();
                      } else {
                        prev();
                      }
                      setPage(newPage);
                    }}
                  />
                  <Stack direction="row" gap={1} alignItems="center">
                    <WarehouseSelector
                      value={warehouseOption ?? null}
                      cacheId="pick-tickets"
                      onChange={(option) => {
                        setWarehouseOption(option ?? undefined);
                        refresh();
                        // if selected picklist is not associated with the selected warehouse, unselect it
                        if (
                          !isNil(option) &&
                          !isNil(selectedPicklist) &&
                          selectedPicklist?.picklistItemGroups.every(
                            (pig) =>
                              pig.itemGroup.storageUnit?.storageOrder?.warehouse
                                .uuid !== option.value,
                          )
                        ) {
                          setSelectedPicklist(null);
                        }
                      }}
                    />
                    {(wmsContactsData?.wmsContacts.length ?? 0) > 1 && (
                      <CustomerFilterButton
                        isSmall
                        cacheId="pick-tickets"
                        contactsOverride={wmsContactsData?.wmsContacts}
                        selectedOption={contactOption}
                        handleChange={(option) => {
                          setContactOption(option);
                          refresh();
                          // if selected picklist is not associated with the selected contact, unselect it
                          if (
                            !isNil(option) &&
                            !isNil(selectedPicklist) &&
                            selectedPicklist?.picklistItemGroups.every(
                              (pig) =>
                                pig.itemGroup.storageUnit?.storageOrder?.contact
                                  .uuid !== option.value,
                            )
                          ) {
                            setSelectedPicklist(null);
                          }
                        }}
                      />
                    )}
                  </Stack>
                </Stack>
                <Table stickyHeader size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Customer</TableCell>
                      {(warehouses?.length ?? 0) > 1 && (
                        <TableCell>Warehouse</TableCell>
                      )}
                      <TableCell>Outbound ref #</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {picklistsData?.picklists.edges.map((edge) => {
                      const picklist = edge.node;
                      return (
                        <TableRow
                          key={picklist.id}
                          hover
                          sx={{
                            cursor: 'pointer',
                          }}
                          selected={selectedPicklist?.id === picklist.id}
                          onClick={() => {
                            setSelectedPicklist(picklist);
                          }}
                        >
                          <TableCell sx={{ maxWidth: '30px' }}>
                            {getUniqueContactsFromPicklist(picklist)
                              .map((c) => c.displayName)
                              .join(', ')}
                          </TableCell>
                          {(warehouses?.length ?? 0) > 1 && (
                            <TableCell>
                              {uniq(
                                picklist.picklistItemGroups.map(
                                  (pig) =>
                                    pig.itemGroup.storageUnit?.storageOrder
                                      ?.warehouse.name,
                                ),
                              ).join(', ')}
                            </TableCell>
                          )}
                          <TableCell>
                            {picklist.outboundReferenceNumber}
                          </TableCell>
                          <TableCell>
                            {dayjs(picklist.date).format('MMM D, YYYY')}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton>
                              <ChevronRight />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Fade in={!isNil(selectedPicklist)}>
              <Grid item xs={12} md={7}>
                {!isNil(selectedPicklist) && (
                  <Grid container>
                    <Stack
                      direction="row"
                      width="100%"
                      justifyContent="space-between"
                      alignItems="center"
                      paddingLeft={2}
                      height={52}
                    >
                      <Stack direction="row" gap={1}>
                        <Typography variant="body2" fontWeight="bold">
                          Outbound ref #:
                        </Typography>
                        {selectedPicklist.outboundReferenceNumber}
                      </Stack>
                      <Stack direction="row" gap={1} mr={1}>
                        <Tooltip title="Edit pick ticket">
                          <IconButton
                            onClick={() => {
                              setIsPicklistModalOpen(true);
                            }}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Print pick ticket">
                          <IconButton
                            onClick={() => {
                              setIsPicklistPdfModalOpen(true);
                            }}
                          >
                            <Print />
                          </IconButton>
                        </Tooltip>
                        {!isCustomerPortal && (
                          <Tooltip title="Print packing slip">
                            <IconButton
                              onClick={() => {
                                setIsPackingSlipModalOpen(true);
                              }}
                            >
                              <Receipt />
                            </IconButton>
                          </Tooltip>
                        )}
                        <Tooltip title="Close">
                          <IconButton
                            onClick={() => {
                              setSelectedPicklist(null);
                            }}
                          >
                            <Close />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </Stack>
                    <TableContainer sx={{ overflowY: 'scroll' }}>
                      <Table
                        stickyHeader
                        aria-label="invoice-preview-table"
                        size="small"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>SKU</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Location</TableCell>
                            <TableCell>Container ID</TableCell>
                            <TableCell>PO number</TableCell>
                            <TableCell>Lot number</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectedPicklist.picklistItemGroups.map(
                            (picklistItemGroup) => {
                              return (
                                <TableRow key={picklistItemGroup.id}>
                                  <TableCell>
                                    {picklistItemGroup.itemGroup.item.sku}
                                  </TableCell>
                                  <TableCell>
                                    {
                                      picklistItemGroup.itemGroup.item
                                        .description
                                    }
                                  </TableCell>
                                  <TableCell>
                                    {picklistItemGroup.pickedQuantity}
                                  </TableCell>
                                  <TableCell>
                                    {
                                      picklistItemGroup.itemGroup.storageUnit
                                        ?.warehouseLocation?.name
                                    }
                                  </TableCell>
                                  <TableCell>
                                    {isCustomerPortal ||
                                    picklistItemGroup.itemGroup.storageUnit
                                      ?.storageOrder == null ? (
                                      (picklistItemGroup.itemGroup.storageUnit
                                        ?.name ?? '-')
                                    ) : (
                                      <PalletLink
                                        target="_blank"
                                        href={`/warehouse/storage-orders/${picklistItemGroup.itemGroup.storageUnit.storageOrder.uuid}`}
                                      >
                                        {picklistItemGroup.itemGroup.storageUnit
                                          ?.name ?? '-'}
                                      </PalletLink>
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {
                                      picklistItemGroup.itemGroup.storageUnit
                                        ?.storageOrder?.purchaseOrderNumber
                                    }
                                  </TableCell>
                                  <TableCell>
                                    {
                                      picklistItemGroup.itemGroup.storageUnit
                                        ?.storageOrder?.lotNumber
                                    }
                                  </TableCell>
                                </TableRow>
                              );
                            },
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                )}
              </Grid>
            </Fade>
          </Grid>
        </Grid>
      </Grid>
      <PickListModal
        isCustomerPortal={isCustomerPortal}
        initialPicklist={isCreatingPicklist ? null : selectedPicklist}
        open={isPicklistModalOpen}
        onClose={() => {
          setIsPicklistModalOpen(false);
          setIsCreatingPicklist(false);
        }}
        onUpsertPicklist={async (picklist) => {
          await refetch();
          setSelectedPicklist(picklist);
        }}
      />
      {!isNil(selectedPicklist) && (
        <Modal
          open={isPicklistPdfModalOpen}
          onClose={() => {
            setIsPicklistPdfModalOpen(false);
          }}
        >
          <Box
            sx={[
              styles.modal,
              {
                height: '95vh',
                padding: 2,
                justifyContent: 'space-between',
                display: 'flex',
                direction: 'column',
                gap: 2,
              },
            ]}
          >
            <Stack
              justifyContent="space-between"
              alignItems="center"
              direction="row"
            >
              <Typography variant="h6">Print pick ticket</Typography>
              <IconButton
                onClick={() => {
                  setIsPicklistPdfModalOpen(false);
                }}
              >
                <Close />
              </IconButton>
            </Stack>
            <PDFViewer showToolbar style={{ width: '100%', height: '100%' }}>
              <PicklistPdf picklist={selectedPicklist} />
            </PDFViewer>
            <Stack justifyContent="flex-end" direction="row">
              <Button variant="contained">
                <PDFDownloadLink
                  document={<PicklistPdf picklist={selectedPicklist} />}
                  fileName={`pick-ticket-${selectedPicklist.outboundReferenceNumber}.pdf`}
                >
                  Download
                </PDFDownloadLink>
              </Button>
            </Stack>
          </Box>
        </Modal>
      )}
      {!isNil(selectedPicklist) && (
        <Modal
          open={isPackingSlipModalOpen}
          onClose={() => {
            setIsPackingSlipModalOpen(false);
          }}
        >
          <Box
            sx={[
              styles.modal,
              {
                height: '95vh',
                padding: 2,
                justifyContent: 'space-between',
                display: 'flex',
                direction: 'column',
                gap: 2,
              },
            ]}
          >
            <PackingSlipModal
              picklist={selectedPicklist}
              onClose={() => {
                setIsPackingSlipModalOpen(false);
              }}
            />
          </Box>
        </Modal>
      )}
    </>
  );
};

export default PicklistsTable;
