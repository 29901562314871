import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Alert,
  Box,
  Button,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Menu,
  Snackbar,
  Stack,
  TextField,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { isEmpty, isNil } from 'lodash';
import pluralize from 'pluralize';
import React, { useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  PermissionResource,
  useCreateOrdersFromRecurringTemplatesMutation,
  useRecurringOrderTemplatesQuery,
} from '../../../generated/graphql';
import BillingPartyContactModal from '../../orders/components/standard/billing-party-contact-modal';
import ConfirmCreateOrdersFromTemplates from './confirm-create-orders-from-templates-modal';
import RecurringOrderTable from './recurring-order-template-table-component';

dayjs.extend(isBetween);

const RecurringOrderTemplateTable = () => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteCompanyRecurringOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyRecurringOrders,
  );

  const [searchQuery, setSearchQuery] = useState<string>();
  const [showBillingPartyModal, setShowBillingPartyModal] = useState(false);

  const [selectedTemplateUuids, setSelectedTemplateUuids] = useState<string[]>(
    [],
  );
  const { data: recurringOrderTemplatesData } =
    useRecurringOrderTemplatesQuery();
  const [createOrdersFromTemplates, { loading: loadingCreateOrders }] =
    useCreateOrdersFromRecurringTemplatesMutation();

  const [bulkActionsAnchorEl, setBulkActionsAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [showConfirmManualCreationModal, setShowConfirmManualCreationModal] =
    useState<boolean>(false);
  const [manualCreationSuccessMessage, setManualCreationSuccessMessage] =
    useState<string>('');
  const [manualCreationErrorMessage, setManualCreationErrorMessage] =
    useState<string>('');

  return (
    <Box>
      <BillingPartyContactModal
        isRecurringTemplate
        setIsOpen={setShowBillingPartyModal}
        open={showBillingPartyModal}
      />
      <ConfirmCreateOrdersFromTemplates
        open={showConfirmManualCreationModal}
        templates={
          recurringOrderTemplatesData?.recurringOrderTemplates.filter(
            (template) => selectedTemplateUuids.includes(template.uuid),
          ) ?? []
        }
        onClose={() => {
          setShowConfirmManualCreationModal(false);
        }}
        onCreateOrders={async () => {
          setShowConfirmManualCreationModal(false);
          try {
            const res = await createOrdersFromTemplates({
              variables: { uuids: selectedTemplateUuids },
            });
            if (!isEmpty(res.errors)) {
              setManualCreationErrorMessage(
                'Error creating orders from templates.',
              );
            }
            const numOrdersCreated =
              res.data?.createOrdersFromRecurringTemplates.length;
            setManualCreationSuccessMessage(
              `${numOrdersCreated} ${pluralize(
                'order',
                numOrdersCreated,
              )} created successfully.`,
            );
          } catch {
            setManualCreationErrorMessage(
              'Error creating orders from templates.',
            );
          }
          setSelectedTemplateUuids([]);
        }}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
        open={!isEmpty(manualCreationErrorMessage)}
        onClose={() => {
          setManualCreationErrorMessage('');
        }}
      >
        <Alert severity="error">{manualCreationErrorMessage}</Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
        open={!isEmpty(manualCreationSuccessMessage)}
        onClose={() => {
          setManualCreationSuccessMessage('');
        }}
      >
        <Alert severity="success">{manualCreationSuccessMessage}</Alert>
      </Snackbar>
      <Grid container sx={{ px: 1 }}>
        <Grid item xs={6}>
          <TextField
            size="small"
            label="Search Templates"
            InputProps={{ style: { backgroundColor: 'white' } }}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
          />
        </Grid>
        <Grid
          item
          xs={6}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
          gap={1}
        >
          {canWriteCompanyRecurringOrders && (
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <>
                <Button
                  size="small"
                  color="info"
                  disabled={selectedTemplateUuids.length <= 0}
                  aria-haspopup="menu"
                  onClick={(e) => {
                    setBulkActionsAnchorEl(e.currentTarget);
                  }}
                >
                  Bulk Actions
                  <ArrowDropDownIcon />
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={bulkActionsAnchorEl}
                  open={Boolean(bulkActionsAnchorEl)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  onClose={() => {
                    setBulkActionsAnchorEl(null);
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      setShowConfirmManualCreationModal(true);
                      setBulkActionsAnchorEl(null);
                    }}
                  >
                    Manually create {selectedTemplateUuids.length}{' '}
                    {pluralize('order', selectedTemplateUuids.length)}
                  </MenuItem>
                </Menu>
              </>
            </Stack>
          )}
          <Button
            variant="contained"
            disabled={!canWriteCompanyRecurringOrders}
            onClick={() => {
              setShowBillingPartyModal(true);
            }}
          >
            Add template
          </Button>
        </Grid>
      </Grid>
      <RecurringOrderTable
        selectedTemplateUuids={selectedTemplateUuids}
        setSelectedTemplateUuids={setSelectedTemplateUuids}
        recurringOrderTemplatesData={
          !isNil(recurringOrderTemplatesData) &&
          !isNil(recurringOrderTemplatesData.recurringOrderTemplates)
            ? recurringOrderTemplatesData.recurringOrderTemplates
            : []
        }
        loadingCreateOrders={loadingCreateOrders}
        searchQuery={searchQuery}
        canWriteCompanyRecurringOrders={canWriteCompanyRecurringOrders}
        hideEdit={false}
        hideCheckBox={false}
      />
    </Box>
  );
};

export default RecurringOrderTemplateTable;
