import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  Divider,
  FormControl,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import { type FunctionComponent, useEffect, useState } from 'react';
import { useCreateSchedulingRegionMutation } from '../../../generated/graphql';

type CreateSchedulingRegionModalProps = {
  readonly open: boolean;
  readonly onCreated: (region: { id: string }) => void;
  readonly onClose: () => void;
};

export const CreateSchedulingRegionModal: FunctionComponent<
  CreateSchedulingRegionModalProps
> = ({ open, onCreated, onClose }) => {
  const [error, setError] = useState<string | null>(null);
  const [create, { loading: creating }] = useCreateSchedulingRegionMutation({
    onCompleted: (data) => {
      if (
        data.createSchedulingRegion.__typename ===
        'CreateSchedulingRegionSuccessOutput'
      ) {
        onCreated(data.createSchedulingRegion.schedulingRegion);
      } else {
        setError(data.createSchedulingRegion.message);
      }
    },
  });
  const [name, setName] = useState('');

  useEffect(() => {
    if (!open) {
      setError(null);
      setName('');
    }
  }, [open]);

  const onCreate = () => {
    setError(null);
    create({
      variables: {
        input: {
          name,
        },
      },
    });
  };

  return (
    <Dialog
      open={open}
      PaperProps={{ sx: { width: '460px' } }}
      onClose={onClose}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={2}
      >
        <Typography variant="h4" fontSize="20px" lineHeight={1}>
          Create a scheduling region
        </Typography>
        <IconButton
          disabled={creating}
          style={{ width: '30px', height: '30px' }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider />
      <Box p={3}>
        {!isNil(error) && (
          <Typography color="error" mb={2}>
            {error}
          </Typography>
        )}
        <FormControl required sx={{ width: '100%' }}>
          <TextField
            id="scheduling-region-name"
            label="Name"
            value={name}
            style={{ width: '100%' }}
            onChange={({ target }) => {
              setName(target.value);
            }}
          />
        </FormControl>
      </Box>
      <Divider />
      <Stack direction="row" justifyContent="flex-end" p={2} gap={2}>
        <Button
          variant="text"
          color="inherit"
          disabled={creating}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={creating || name.trim().length === 0}
          onClick={onCreate}
        >
          Create
        </Button>
      </Stack>
    </Dialog>
  );
};
