import { TableCell, TableRow, Fade, Button, Box } from '@mui/material';
import { type Dispatch, type SetStateAction, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import { PermissionResource } from '../../../generated/graphql';

const RouteNameRow = ({
  uuid,
  name,
  setModalUuid,
}: {
  readonly uuid: string;
  readonly name: string;
  readonly setModalUuid: Dispatch<SetStateAction<string | null>>;
}) => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteCompanyRouteNames } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyRouteNames,
  );

  const [isHovering, setIsHovering] = useState(false);

  return (
    <TableRow
      key={uuid}
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <TableCell>{name}</TableCell>
      <TableCell>
        <Fade in={isHovering}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'right',
            }}
          >
            <Button
              variant="contained"
              disabled={!canWriteCompanyRouteNames}
              onClick={() => {
                setModalUuid(uuid);
              }}
            >
              Edit
            </Button>
          </Box>
        </Fade>
      </TableCell>
    </TableRow>
  );
};

export default RouteNameRow;
