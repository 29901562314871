import { FormControl, MenuItem, Select } from '@mui/material';
import { sentenceCase } from 'change-case';
import React from 'react';
import { shallow } from 'zustand/shallow';
import { StopType } from '../../../../generated/graphql';
import useDispatchStore from '../../dispatch-store';

const RouteStopTypeFilterComponent = () => {
  const [stopTypeFilter, setStopTypeFilter] = useDispatchStore(
    (state) => [state.stopTypeFilter, state.setStopTypeFilter],
    shallow,
  );

  return (
    <FormControl sx={{ width: 100 }}>
      <Select
        displayEmpty
        size="small"
        labelId="stop-type-select-label"
        value={stopTypeFilter ?? 'All Stops'}
        sx={{
          '& .MuiSelect-select': {
            height: 'auto !important',
            fontSize: 13,
          },
        }}
        onChange={(event) => {
          const { value } = event.target;
          setStopTypeFilter(
            value === 'All Stops' ? undefined : (value as StopType),
          );
        }}
      >
        <MenuItem sx={{ fontSize: 13 }} value="All Stops">
          All Stops
        </MenuItem>
        {[
          StopType.Delivery,
          StopType.Pickup,
          StopType.Recovery,
          StopType.Transfer,
        ].map((value) => (
          <MenuItem key={value} sx={{ fontSize: 13 }} value={value}>
            {sentenceCase(value)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const RouteStopTypeFilter = React.memo(RouteStopTypeFilterComponent);
