import { Alert, Box, Snackbar, Typography } from '@mui/material';
import { orange } from '@mui/material/colors';
import { isNil } from 'lodash';
import { useMemo, useState } from 'react';
import {
  type Accept,
  ErrorCode,
  type FileRejection,
  useDropzone,
} from 'react-dropzone';
import { exhaustive } from 'shared/switch';
import styled from 'styled-components';
import theme from '../../../theme';

const DEFAULT_FILE_TYPES_ACCEPT = {
  'application/pdf': [],
};
const DRAG_MESSAGE = 'Drag and drop files here';
const DROP_MESSAGE = 'Release to drop the files here';

const isErrorCode = (
  code: ErrorCode | string | undefined,
): code is ErrorCode => {
  return Object.values(ErrorCode).includes(code as ErrorCode);
};

const getErrorMessage = (code: ErrorCode | string | undefined) => {
  if (isNil(code) || !isErrorCode(code)) {
    return 'Error uploading file';
  }
  switch (code) {
    case ErrorCode.FileInvalidType: {
      return 'Unsupported file type';
    }
    case ErrorCode.FileTooLarge: {
      return 'File too large';
    }
    case ErrorCode.FileTooSmall: {
      return 'File too small';
    }
    case ErrorCode.TooManyFiles: {
      return 'Too many files';
    }
    default: {
      return exhaustive(code);
    }
  }
};

const StyledOrUploadAFileContainer = styled.div`
  color: ${orange[700]};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border: 2px solid ${orange[700]};
  padding: 6px;
  font-weight: bold;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  :hover {
    background-color: ${orange[700]};
    color: white;
  }
`;

const styles: Record<string, React.CSSProperties> = {
  dropzoneBase: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition:
      'border .24s ease-in-out, background-color .24s ease-in-out, color .24s ease-in-out',
    cursor: 'pointer',
    width: '100%',
    padding: '40px 75px',
  },
  dropZoneActive: {
    borderColor: theme.palette.paleOrange.main,
    backgroundColor: `${theme.palette.paleOrange.main}30`,
    color: 'black',
  },
};

type DropzoneUpdateProps = {
  readonly onDrop: (acceptedFiles: File[]) => void;
  readonly accept?: Accept;
};

const DropzoneUpdate = ({ onDrop, accept }: DropzoneUpdateProps) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const onDropRejected = (fileRejections: FileRejection[]) => {
    setErrorMessage(getErrorMessage(fileRejections[0]?.errors[0]?.code));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    noClick: false,
    accept: accept ?? DEFAULT_FILE_TYPES_ACCEPT,
    maxFiles: 0, // 0 means no limit
    multiple: true,
  });

  const style = useMemo(
    () => ({
      ...styles.dropzoneBase,
      ...(isDragActive ? styles.dropZoneActive : {}),
      gap: '8px',
      height: '350px',
      justifyContent: 'center',
    }),
    [isDragActive],
  );

  return (
    <>
      <Box {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <Typography>{isDragActive ? DROP_MESSAGE : DRAG_MESSAGE}</Typography>
        <StyledOrUploadAFileContainer>
          Or, upload a file...
        </StyledOrUploadAFileContainer>
      </Box>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={errorMessage !== null}
        onClose={() => {
          setErrorMessage(null);
        }}
      >
        <Alert
          severity="error"
          onClose={() => {
            setErrorMessage(null);
          }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default DropzoneUpdate;
