import {
  Dialog,
  Typography,
  Button,
  Stack,
  CircularProgress,
} from '@mui/material';
import { type Dispatch, type SetStateAction, memo } from 'react';
import {
  useRemoveRouteMutation,
  RoutesDocument,
} from '../../../../../generated/graphql';

const DeleteRoutesModal = ({
  open,
  setOpen,
  routeUuids,
  onDeleteRoute,
}: {
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
  readonly routeUuids: string[];
  readonly onDeleteRoute: () => void;
}) => {
  const [removeRouteMutation, { loading }] = useRemoveRouteMutation({
    refetchQueries: [RoutesDocument],
  });

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Stack direction="column" sx={{ p: 2, width: '300px' }} spacing={2}>
        <Typography variant="h6">
          Delete {routeUuids.length} Route{routeUuids.length > 1 ? 's' : ''}
        </Typography>
        <Typography>Warning: this action is not reversible</Typography>
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <Button
            variant="outlined"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            endIcon={
              loading ? (
                <CircularProgress size={15} sx={{ color: 'white' }} />
              ) : null
            }
            onClick={async () => {
              await Promise.all(
                routeUuids.map(async (routeUuid) =>
                  removeRouteMutation({
                    variables: {
                      uuid: routeUuid,
                    },
                  }),
                ),
              );
              onDeleteRoute();
              setOpen(false);
            }}
          >
            Confirm
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default memo(DeleteRoutesModal);
