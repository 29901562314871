import {
  RouteStatus,
  type StopFragment,
  type StopOnRouteFragment,
} from '../../../generated/graphql';
import theme from '../../../theme';

export const MAX_ROUTES_TO_CREATE = 100;

export enum RouteSortType {
  CREATED_TIME = 'Created Time',
  NAME = 'Name',
  DRIVER = 'Driver',
  STATUS = 'Status',
  NUMBER_STOPS_COMPLETE = '# Stops Complete',
  NUMBER_STOPS_INCOMPLETE = '# Stops Incomplete',
}

export enum MapRoutePathType {
  PATH = 'Path',
  LINE = 'Line',
  NONE = 'None',
}

export type RouteStopInsertInput = {
  stopUuid: string;
  index: number;
};

export const statusOrder = {
  [RouteStatus.NotStarted]: 0,
  [RouteStatus.InProgress]: 1,
  [RouteStatus.Incomplete]: 2,
  [RouteStatus.Complete]: 3,
};

export type NotifiedDriver = {
  driverName: string;
  phoneNumber: string;
  routeName: string;
  messageSummary: string;
  messageContents: string;
  routeUuid: string;
  driverUuid: string;
};

export type TextDriversApiResponse = {
  notifiedDrivers: NotifiedDriver[];
};

export type RouteOption = {
  value: string;
  label: string;
  driverName: string;
};

export type LastUnassignedData = {
  routeUuid: string;
  stopUuid: string;
  index: number | undefined;
};

export type StopOnRouteAddress = StopOnRouteFragment['address'];
export type CachedStopAddress = StopFragment['stop']['address'];
export type StopOnRouteOrder = NonNullable<
  NonNullable<StopOnRouteFragment['shipment']>['order']
>;
export type CachedStopOrder = NonNullable<StopFragment['order']>;

export const routeCardHighlightedStyle = {
  borderWidth: '1px',
  backgroundColor: theme.palette.primary.light,
  outline: `1px solid rgba(0, 0, 0, 0.12)`,
};

export const routeCardDefaultStyle = {
  borderWidth: '1px',
  backgroundColor: '#ffffff',
  outline: 'none',
};

export enum DispatchSettingsTab {
  ROUTES = 'Routes',
  DISPLAY = 'Display',
  COLORS = 'Colors',
}
