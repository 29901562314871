import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isEmpty, isNil } from 'lodash';
import pluralize from 'pluralize';
import { filterNotNil } from 'shared/array';
import { transformAddressToFullAddressString } from 'shared/copy';
import { formatAppointmentRange } from '../../../common/utils/prettyPrintUtils';
import {
  getDriverName,
  getMeasurementUnitsAbbreviation,
  getPackageTypeAbbreviation,
  getWeightUnitsAbbreviation,
} from '../../../common/utils/utils';
import {
  PackageType,
  type RouteFragment,
  Segment,
  StopType,
  type WeightUnits,
} from '../../../generated/graphql';
import { getInboundStopDetails } from '../utils';

dayjs.extend(utc);
dayjs.extend(timezone);

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-regular-webfont.ttf',
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-bold-webfont.ttf',
      fontWeight: 700,
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 24,
    fontFamily: 'Roboto',
  },
  stopSection: {
    flexDirection: 'row',
    fontSize: '10px',
    marginTop: '12px',
  },
  stopSectionMediumSlice: {
    width: '27%',
    paddingLeft: '10px',
  },
  stopSectionLargeSlice: {
    width: '45%',
    paddingLeft: '10px',
  },
  stopSectionFirstSlice: {
    width: '8%',
  },
  stopSectionSmallSlice: {
    width: '20%',
    paddingLeft: '10px',
  },
  titleA: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  additionalSection: {
    flexDirection: 'row',
    fontSize: '10px',
    paddingTop: '8px',
  },
  pageNumbers: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 10,
    textAlign: 'right',
    fontSize: '10px',
  },
  superscript: {
    fontSize: 8,
    verticalAlign: 'super',
    lineHeight: 1,
  },
});

type GeneratedManifestPdfProps = {
  readonly routes: RouteFragment[] | null;
  readonly segment?: Segment | undefined;
  readonly defaultWeightUnits?: WeightUnits;
  readonly timeZone: string;
  // Flag for whether to display package spec names or package type.
  readonly displayPackageSpecName: boolean;
};

const GeneratedManifestPdf = ({
  routes,
  segment,
  defaultWeightUnits,
  timeZone,
  displayPackageSpecName,
}: GeneratedManifestPdfProps) => {
  const bolString = segment === Segment.Cartage ? 'HAWB' : 'Ref #';

  return (
    <Document title="Manifest new">
      {routes?.map((route) => {
        const noDriver = route.drivers.length === 0;
        const drivers = noDriver
          ? 'N/A'
          : route.drivers.map((driver) => getDriverName(driver)).join(', ');

        const hazmatStops = filterNotNil(
          route.slots.map((slot, idx) =>
            slot.stops?.[0]?.shipment?.order?.hazmat === true ? idx + 1 : null,
          ),
        );
        let hazmatRoute = '';
        if (hazmatStops.length > 0) {
          hazmatRoute = `HAZMAT (${pluralize('Stop', hazmatStops.length)} ${hazmatStops.join(', ')})`;
        }

        return (
          <Page key={route.uuid} size="LETTER" style={styles.page}>
            <Text
              fixed
              style={styles.pageNumbers}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
            />
            <View>
              <Text style={styles.titleA}>Manifest for {route?.name}</Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
                fontSize: '10px',
                marginTop: '6px',
              }}
            >
              <View style={{ flexGrow: 1, width: '68%' }}>
                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ fontWeight: 700 }}>Driver(s): </Text>
                  <Text>{drivers}</Text>
                </View>

                {!isEmpty(hazmatRoute) && (
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={{ fontWeight: 700 }}>WARNING: </Text>
                    <Text>{hazmatRoute}</Text>
                  </View>
                )}
                {route.equipments?.map((equipment) => {
                  return (
                    <View key={equipment.uuid} style={{ flexDirection: 'row' }}>
                      <Text style={{ fontWeight: 700 }}>
                        {`${equipment.type}: `}
                      </Text>
                      <Text>{equipment.name}</Text>
                    </View>
                  );
                })}
              </View>
              <View style={{ flexGrow: 1, paddingLeft: '2%', width: '36%' }}>
                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ fontWeight: 700 }}>Manifested at: </Text>
                  <Text>{dayjs().format('MM/DD/YYYY hh:mm a')}</Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ fontWeight: 700 }}>Route date: </Text>
                  <Text style={{ marginRight: '5px' }}>
                    {dayjs(route.date).tz(timeZone).format('MM/DD')}
                  </Text>
                  <Text style={{ fontWeight: 700 }}>Start time: </Text>
                  <Text>
                    {isNil(route.defaultStartTime)
                      ? 'N/A'
                      : dayjs(route.defaultStartTime)
                          .tz(timeZone)
                          .format('hh:mm a')}
                  </Text>
                </View>
              </View>
            </View>
            {route?.slots.map((slot, idx) => {
              const stop = slot.stops[0];
              const order = stop?.shipment?.order;
              const appointmentDate =
                stop?.shipment?.standardShipmentFields?.deliveryDate;
              const appointmentWindow = stop?.appointmentTime;
              const endAppointmentTime = stop?.endAppointmentTime;
              const specialInstructions = stop?.specialInstructions;
              const stopType = stop?.stopType;
              const serviceName = order?.service?.name;
              const shipperBillOfLadingNumber =
                order?.standardOrderFields.shipperBillOfLadingNumber;
              const masterAirwayBillOfLadingNumber =
                order?.standardOrderFields.masterAirwayBillOfLadingNumber;
              const orderName = order?.name;
              const refNumbers = order?.refNumbers.join(', ');
              const contactInfo = filterNotNil([
                stop?.contactPerson?.firstName,
                stop?.contactPerson?.lastName,
                stop?.contactPerson?.phone,
              ]).join(' ');
              const tags = order?.tags;
              const inboundStopDetails = getInboundStopDetails(
                order,
                stopType ?? undefined,
              );
              const defaultMeasurementUnits =
                order?.standardOrderFields.measurementUnits;
              const packageLocations = order?.packages
                .map((pkg) => pkg.warehouseLocation?.name)
                .filter((location) => !isNil(location));
              const etas = stop?.cachedEta;

              const stopTime =
                formatAppointmentRange(
                  appointmentDate,
                  appointmentWindow,
                  endAppointmentTime,
                ) ?? 'None';

              const address = stop?.address;

              return (
                <>
                  <View
                    key={slot.uuid}
                    style={{ marginTop: '12px' }}
                    wrap={false}
                  >
                    <View style={{ borderTop: '1px solid black' }} />
                    <View style={styles.stopSection}>
                      <View style={styles.stopSectionFirstSlice}>
                        <Text style={{ fontWeight: 700 }}>{`Stop ${
                          idx + 1
                        }`}</Text>
                        {(!isNil(etas?.arrivalTime) ||
                          !isNil(etas?.finishTime)) && (
                          <>
                            <Text
                              style={{
                                marginTop: '12px',
                              }}
                            >
                              ETA
                            </Text>
                            <Text style={{ fontSize: '9px' }}>
                              {isNil(etas?.arrivalTime)
                                ? ''
                                : dayjs(etas?.arrivalTime)
                                    .tz(timeZone)
                                    .format('hh:mma')}{' '}
                              {!isNil(etas?.arrivalTime) &&
                              !isNil(etas?.finishTime) &&
                              dayjs(etas?.finishTime)
                                .tz(timeZone)
                                .format('hh:mma') !==
                                dayjs(etas?.arrivalTime)
                                  .tz(timeZone)
                                  .format('hh:mma')
                                ? '- '
                                : ''}
                              {!isNil(etas?.finishTime) &&
                              dayjs(etas?.finishTime)
                                .tz(timeZone)
                                .format('hh:mma') !==
                                dayjs(etas?.arrivalTime)
                                  .tz(timeZone)
                                  .format('hh:mma')
                                ? dayjs(etas?.finishTime)
                                    .tz(timeZone)
                                    .format('hh:mma')
                                : ''}{' '}
                            </Text>
                          </>
                        )}
                      </View>
                      <View style={styles.stopSectionLargeSlice}>
                        {!isEmpty(order?.billingPartyContact.displayName) && (
                          <Text
                            style={{
                              marginBottom: '6px',
                            }}
                          >
                            <Text
                              style={{
                                fontWeight: 700,
                              }}
                            >
                              {`Customer: `}
                            </Text>
                            {order?.billingPartyContact.displayName}
                          </Text>
                        )}

                        <Text>
                          <Text
                            style={{
                              fontWeight: 700,
                            }}
                          >
                            {`Go to: `}
                          </Text>
                          {address?.name}
                        </Text>
                        <Text>
                          {transformAddressToFullAddressString({
                            ...address,
                            city: address?.city ?? null,
                            line1: address?.line1 ?? null,
                            zip: address?.zip ?? null,
                          })}
                        </Text>

                        <Text
                          style={{
                            marginTop: '6px',
                          }}
                        >
                          <Text
                            style={{
                              fontWeight: 700,
                            }}
                          >
                            Appointment time:{' '}
                          </Text>
                          {stopTime}
                        </Text>
                        {!isNil(serviceName) && (
                          <Text>
                            <Text
                              style={{
                                fontWeight: 700,
                              }}
                            >
                              Service level:{' '}
                            </Text>
                            {sentenceCase(serviceName)}
                          </Text>
                        )}
                        {!isEmpty(inboundStopDetails) && (
                          <Text>{`Inbound via ${inboundStopDetails}`}</Text>
                        )}
                        {!isNil(tags) && !isEmpty(tags) && (
                          <Text>
                            <Text
                              style={{
                                fontWeight: 700,
                              }}
                            >
                              Tags:{' '}
                            </Text>
                            <Text>
                              {tags.map((tag) => tag.value).join(', ')}
                            </Text>
                          </Text>
                        )}
                      </View>
                      <View style={styles.stopSectionMediumSlice}>
                        {order?.hazmat === true && (
                          <Text>
                            <Text
                              style={{
                                fontWeight: 700,
                              }}
                            >
                              HAZMAT
                            </Text>
                          </Text>
                        )}
                        {order?.isCollectOnDelivery === true &&
                          stopType === StopType.Delivery && (
                            <Text>
                              <Text
                                style={{
                                  fontWeight: 700,
                                }}
                              >
                                COD
                              </Text>
                            </Text>
                          )}
                        {!isNil(order?.dateMarkedOnHand) && (
                          <Text>
                            <Text
                              style={{
                                fontWeight: 700,
                              }}
                            >
                              ON HAND
                            </Text>
                          </Text>
                        )}
                        <Text
                          style={{
                            fontWeight: 700,
                          }}
                        >
                          <Text
                            style={{
                              fontWeight: 700,
                            }}
                          >
                            Packages:{' '}
                          </Text>

                          {order?.packages.length === 0 ? 'None' : ''}
                        </Text>
                        {order?.packages?.map(
                          ({
                            uuid,
                            packageSpec,
                            type,
                            quantity,
                            weight,
                            description,
                            length,
                            width,
                            height,
                          }) => (
                            <View key={uuid}>
                              <Text>
                                {quantity}{' '}
                                {pluralize(
                                  (displayPackageSpecName
                                    ? packageSpec?.name
                                    : getPackageTypeAbbreviation(
                                        isNil(type) ? PackageType.Piece : type,
                                      )
                                  )?.toLowerCase() ?? 'item',
                                  quantity,
                                )}
                                , {weight ?? '?'}{' '}
                                {!isNil(defaultWeightUnits) &&
                                  getWeightUnitsAbbreviation(
                                    defaultWeightUnits,
                                  )}
                                {!isNil(length) &&
                                  !isNil(width) &&
                                  !isNil(height) && (
                                    <Text>
                                      , {length}x{width}x{height}{' '}
                                      {!isNil(defaultMeasurementUnits) &&
                                        getMeasurementUnitsAbbreviation(
                                          defaultMeasurementUnits,
                                        )}
                                      <Text style={styles.superscript}>3</Text>
                                    </Text>
                                  )}
                              </Text>
                              <Text>{description}</Text>
                            </View>
                          ),
                        )}
                        <Text
                          style={{
                            marginTop: '6px',
                          }}
                        >
                          <Text
                            style={{
                              fontWeight: 700,
                            }}
                          >
                            Contact:{' '}
                          </Text>
                          {isEmpty(contactInfo) ? 'None' : `\n${contactInfo}`}
                        </Text>
                        {!isEmpty(packageLocations) && (
                          <>
                            <Text
                              style={{
                                marginTop: '6px',
                                fontWeight: 700,
                              }}
                            >
                              Warehouse Locations
                            </Text>
                            <Text>{packageLocations?.join(', ')}</Text>
                          </>
                        )}
                      </View>
                      <View style={styles.stopSectionSmallSlice}>
                        <Text
                          style={{
                            fontWeight: 700,
                          }}
                        >
                          {(stopType ?? '').toUpperCase()}
                        </Text>
                        <Text
                          style={{
                            marginTop: isNil(stopType) ? '0px' : '6px',
                          }}
                        >
                          <Text
                            style={{
                              fontWeight: 700,
                            }}
                          >
                            {`${bolString}: `}
                          </Text>
                          {shipperBillOfLadingNumber ?? ''}
                        </Text>
                        <Text>
                          <Text
                            style={{
                              fontWeight: 700,
                            }}
                          >
                            MAWB:{' '}
                          </Text>
                          {masterAirwayBillOfLadingNumber ?? ''}
                        </Text>
                        <Text>
                          <Text
                            style={{
                              fontWeight: 700,
                            }}
                          >
                            Order #:{' '}
                          </Text>
                          {orderName ?? ''}
                        </Text>
                        {segment === Segment.Cartage && (
                          <Text>
                            <Text
                              style={{
                                fontWeight: 700,
                              }}
                            >
                              Ref #s:{' '}
                            </Text>
                            {refNumbers ?? ''}
                          </Text>
                        )}
                      </View>
                    </View>
                  </View>
                  {!isNil(specialInstructions) &&
                    specialInstructions.length > 0 && (
                      <View style={styles.additionalSection}>
                        <View
                          style={{
                            ...styles.stopSectionFirstSlice,
                          }}
                        />
                        <View
                          style={{
                            paddingLeft: '10px',
                            borderTop: '1px solid lightgray',
                            width: '90%',
                          }}
                        >
                          <Text
                            style={{
                              marginTop: '6px',
                            }}
                          >
                            <Text
                              style={{
                                fontWeight: 700,
                              }}
                            >
                              Special Instructions:{' '}
                            </Text>
                            {specialInstructions}
                          </Text>
                        </View>
                      </View>
                    )}
                </>
              );
            })}
          </Page>
        );
      })}
    </Document>
  );
};

export default GeneratedManifestPdf;
