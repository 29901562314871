import { Stack, Typography } from '@mui/material';
import currency from 'currency.js';
import React from 'react';
import { type CustomChargeDifferenceFragment } from '../../../../../../../../../generated/graphql';
import { buildStringDifferenceBlockComponent } from '../audit-log-utils';

type CustomChargeDifferenceBlockProps = {
  readonly customChargeDifferences: CustomChargeDifferenceFragment[];
  readonly isOldCustomCharges: boolean;
};

const CustomChargeDifferenceBlock = ({
  customChargeDifferences,
  isOldCustomCharges,
}: CustomChargeDifferenceBlockProps) => {
  return (
    <Stack>
      {/* // eslint-disable-next-line react/jsx-key */}
      {customChargeDifferences.map((customChargeDifference) => {
        if (customChargeDifference.wasAdded && isOldCustomCharges) {
          // eslint-disable-next-line react/jsx-key
          return <Typography fontSize={12}> None </Typography>;
        }
        if (customChargeDifference.wasDeleted && !isOldCustomCharges) {
          // eslint-disable-next-line react/jsx-key
          return <Typography fontSize={12}> None </Typography>;
        }
        // eslint-disable-next-line react/jsx-key
        return (
          // eslint-disable-next-line react/jsx-key
          <Stack
            direction="row"
            spacing={0.5}
            alignItems="center"
            justifyContent="flex-start"
            flexWrap="wrap"
          >
            {buildStringDifferenceBlockComponent(
              customChargeDifference.name?.oldValue ?? undefined,
              customChargeDifference.name?.newValue ?? undefined,
              isOldCustomCharges,
              false,
              false,
              12,
            )}
            <Typography fontSize={12}>-</Typography>
            {buildStringDifferenceBlockComponent(
              currency(customChargeDifference.totalAmountCents?.oldValue ?? 0, {
                fromCents: true,
              }).format() ?? undefined,
              currency(customChargeDifference.totalAmountCents?.newValue ?? 0, {
                fromCents: true,
              }).format() ?? undefined,
              isOldCustomCharges,
              false,
              false,
              12,
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};

export default CustomChargeDifferenceBlock;
