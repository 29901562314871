import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Stack, TextField, Typography } from '@mui/material';
import { exhaustive } from 'shared/switch';
import useServices from '../../../common/react-hooks/use-services';
import {
  DispatchTableColorField,
  type DispatchTableColorFragment,
} from '../../../generated/graphql';
import AutocompleteFuzzy from '../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';

type Service = NonNullable<DispatchTableColorFragment['service']>;

export const ColorSettingFieldRow = ({
  index,
  dispatchTableColor,
  field,
  onChange,
  onDelete,
}: {
  readonly index: number;
  readonly dispatchTableColor: DispatchTableColorFragment;
  readonly field: DispatchTableColorField;
  readonly onChange: (newData: DispatchTableColorFragment) => void;
  readonly onDelete: () => void;
}) => {
  const { services } = useServices();

  let fieldLabel: string;
  switch (field) {
    case DispatchTableColorField.AppointmentStartWithin: {
      fieldLabel = 'appointment starts within';
      break;
    }
    case DispatchTableColorField.AppointmentEndWithin: {
      fieldLabel = 'appointment ends within';
      break;
    }
    case DispatchTableColorField.AppointmentPassed: {
      fieldLabel = 'appointment has passed';
      break;
    }
    case DispatchTableColorField.HasAppointmentDate: {
      fieldLabel =
        dispatchTableColor.hasAppointmentDate === true
          ? 'has an appointment date'
          : 'does not have an appointment date';
      break;
    }
    case DispatchTableColorField.DeadlineToday: {
      fieldLabel = 'deadline is today';
      break;
    }
    case DispatchTableColorField.DeadlineTomorrow: {
      fieldLabel = 'deadline is tomorrow';
      break;
    }
    case DispatchTableColorField.DeadlinePassed: {
      fieldLabel = 'deadline has passed';
      break;
    }
    case DispatchTableColorField.IsSpecial: {
      // We've always treated the IsSpecial field as requiring the stop to be special.
      fieldLabel =
        dispatchTableColor.isSpecial === false ? 'not special' : 'special';
      break;
    }
    case DispatchTableColorField.Service: {
      fieldLabel = 'service is';
      break;
    }
    default: {
      exhaustive(field);
    }
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      gap={1}
      pr="4px"
      width="max-content"
    >
      <Typography>
        {index === 0 ? 'where' : 'and'} {fieldLabel}
      </Typography>
      {field === DispatchTableColorField.AppointmentStartWithin && (
        <>
          <TextField
            size="small"
            sx={{
              width: '65px',
            }}
            value={dispatchTableColor.appointmentStartWithinMinutes ?? ''}
            onChange={(e) => {
              const value = e.target.value.trim();
              if (value === '') {
                onChange({
                  ...dispatchTableColor,
                  appointmentStartWithinMinutes: null,
                });
              } else {
                onChange({
                  ...dispatchTableColor,
                  appointmentStartWithinMinutes: Number(value),
                });
              }
            }}
          />
          <Typography>minutes</Typography>
        </>
      )}
      {field === DispatchTableColorField.AppointmentEndWithin && (
        <>
          <TextField
            size="small"
            sx={{
              width: '65px',
            }}
            value={dispatchTableColor.appointmentEndWithinMinutes ?? ''}
            onChange={(e) => {
              const value = e.target.value.trim();
              if (value === '') {
                onChange({
                  ...dispatchTableColor,
                  appointmentEndWithinMinutes: null,
                });
              } else {
                onChange({
                  ...dispatchTableColor,
                  appointmentEndWithinMinutes: Number(value),
                });
              }
            }}
          />
          <Typography>minutes</Typography>
        </>
      )}
      {field === DispatchTableColorField.Service && (
        <AutocompleteFuzzy<Service | { name: '' }, false, true, false>
          disableClearable
          size="small"
          sx={{
            width: '230px',
          }}
          value={
            dispatchTableColor.service ?? {
              name: '',
            }
          }
          options={services}
          matchSortOptions={{ keys: ['name'] }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          )}
          isOptionEqualToValue={(option, value) =>
            'uuid' in option && 'uuid' in value && option.uuid === value.uuid
          }
          getOptionLabel={(option) => option.name}
          onChange={(_event, option) => {
            if ('uuid' in option) {
              onChange({
                ...dispatchTableColor,
                service: option,
              });
            }
          }}
        />
      )}
      <IconButton size="small" onClick={onDelete}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </Stack>
  );
};
