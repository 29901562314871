import {
  type DispatchViewsQuery,
  useDispatchViewsQuery,
} from '../../../generated/graphql';

export type DispatchView = DispatchViewsQuery['dispatchViews'][0];

const useDispatchViews = () => {
  const {
    data: dispatchViewsData,
    loading,
    refetch: refetchDispatchViews,
  } = useDispatchViewsQuery();

  return {
    dispatchViews: dispatchViewsData?.dispatchViews,
    loading,
    refetchDispatchViews,
  };
};

export { useDispatchViews };
