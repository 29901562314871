import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import { isPalletAdmin } from '../../../../../utils';
import useTerminals from '../../../../common/react-hooks/use-terminals';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import {
  PermissionResource,
  useUsersQuery,
} from '../../../../generated/graphql';
import CreateOrEdit from '../../enums/create-or-edit';
import {
  ActiveArchivedButtonGroup,
  ActiveOrArchivedTab,
} from '../common/active-archived-tab-panel';
import UserRow from './user-row';
import UsersModal from './users-modal';

type UsersTableProps = {
  readonly companyUuid?: string;
};

const UsersTable = ({ companyUuid }: UsersTableProps) => {
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState<CreateOrEdit>(CreateOrEdit.Create);
  const { data: users } = useUsersQuery({
    variables: {
      getUsersInput: {
        includeNotActive: true,
      },
    },
  });

  const [isActiveOrArchived, setIsActiveOrArchived] =
    useState<ActiveOrArchivedTab>(ActiveOrArchivedTab.ACTIVE);

  const { userPermissions } = useUserRoles();
  const { hasMasterPermission, canWrite: canWriteCompanyUsers } =
    getPermissionsFlags(userPermissions, PermissionResource.CompanyUsers);

  let usersFiltered = users?.users.filter((u) =>
    isActiveOrArchived === ActiveOrArchivedTab.ACTIVE
      ? u.isActive
      : !u.isActive,
  );

  if (!hasMasterPermission) {
    usersFiltered = usersFiltered?.filter((user) => !isPalletAdmin(user.email));
  }

  return (
    <>
      <Stack direction="column" sx={{ display: 'flex' }} gap={2}>
        <ActiveArchivedButtonGroup
          activeTab={isActiveOrArchived}
          setTab={setIsActiveOrArchived}
        />
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell sx={{ width: '300px' }}>Name</TableCell>
                {terminalsEnabled && <TableCell>Terminal</TableCell>}
                <TableCell>Role(s)</TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    disabled={!canWriteCompanyUsers}
                    onClick={() => {
                      setMode(CreateOrEdit.Create);
                      setOpen(true);
                    }}
                  >
                    Add User
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersFiltered?.map((user) => (
                // eslint-disable-next-line react/jsx-key
                <UserRow
                  user={user}
                  companyUuid={companyUuid}
                  terminalsEnabled={terminalsEnabled}
                  canWriteCompanyUsers={canWriteCompanyUsers}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <UsersModal
        open={open}
        setOpen={setOpen}
        createOrEdit={mode}
        companyUuid={companyUuid}
        selectedUser={undefined}
      />
    </>
  );
};

export default UsersTable;
