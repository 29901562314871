import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Table,
  TableBody,
  TableCell,
  Collapse,
  TableHead,
  TableRow,
  IconButton,
  Chip,
} from '@mui/material';
import React, { useState } from 'react';
import { type BatchAddShipmentsToInvoicesResultInvoiceFragment } from '../../../../generated/graphql';
import InvoiceNameOrJournalNumber from '../invoices/invoice-name-or-journal-number';

const InvoiceResultRow = ({
  invoice,
  isExisting,
  shipmentUuidsAdded,
}: {
  readonly invoice: BatchAddShipmentsToInvoicesResultInvoiceFragment;
  readonly isExisting: boolean;
  readonly shipmentUuidsAdded: string[];
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            size="small"
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
          >
            {isExpanded ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell sx={{ width: '20%' }}>
          {isExisting ? (
            <Chip label="Existing Invoice" />
          ) : (
            <Chip label="New Invoice" color="success" />
          )}
        </TableCell>
        <TableCell sx={{ width: '20%' }}>
          <InvoiceNameOrJournalNumber invoice={invoice} />
        </TableCell>
        <TableCell>
          {isExisting
            ? invoice.shipments.reduce(
                (prev, curr) =>
                  prev + (shipmentUuidsAdded.includes(curr.uuid) ? 1 : 0),
                0,
              )
            : invoice.shipments.length}
        </TableCell>
        <TableCell>{invoice.shipments.length}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse unmountOnExit in={isExpanded} timeout="auto">
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Client Reference Number</TableCell>
                  <TableCell>Stop Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoice.shipments.map((shipment) => (
                  <TableRow key={shipment.uuid}>
                    <TableCell>
                      {(shipmentUuidsAdded.includes(shipment.uuid) ||
                        !isExisting) && (
                        <Chip
                          size="small"
                          sx={{ fontSize: '11px' }}
                          label="Added"
                          color="info"
                        />
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {
                        shipment.order?.standardOrderFields
                          .shipperBillOfLadingNumber
                      }
                    </TableCell>
                    <TableCell>{shipment.legs[0]?.endStop.stopType}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default React.memo(InvoiceResultRow);
