import { ArrowBack } from '@mui/icons-material';
import {
  Box,
  TextField,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../../../common/styles/Home.module.css';
import { validateEmail } from '../../../common/utils/utils';
import {
  useSignUpForThirdPartyUserAccountMutation,
  useLoginMutation,
  SignUpForThirdPartyUserAccountStatus,
} from '../../../generated/graphql';
import PalletRouterLink from '../../../pallet-ui/links/router-link/pallet-router-link';

const CreateCustomerPortalAccount = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const [signUpForThirdPartyUserAccount] =
    useSignUpForThirdPartyUserAccountMutation();
  const [login] = useLoginMutation();

  const handleCreateAccount = async () => {
    setIsLoading(true);
    setError('');
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!email || !password) return;
    if (!validateEmail(email)) {
      setError('Invalid email address');
      setIsLoading(false);
      return;
    }
    const response = await signUpForThirdPartyUserAccount({
      variables: {
        signUpForThirdPartyUserAccountInput: {
          email,
          password,
        },
      },
    });
    const status = response.data?.signUpForThirdPartyUserAccount.status;
    if (status !== SignUpForThirdPartyUserAccountStatus.Success) {
      setError(status);
      setIsLoading(false);
      return;
    }

    await login({
      variables: {
        loginInputData: {
          email,
          password,
        },
      },
    });
    navigate('/customer-portal');
    setIsLoading(false);
  };

  return (
    <div className={styles.container}>
      <PalletRouterLink to="/">
        <Button
          startIcon={<ArrowBack />}
          variant="outlined"
          color="primary"
          sx={{ mt: 4 }}
        >
          Back to login
        </Button>
      </PalletRouterLink>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        mt="25vh"
      >
        <Typography variant="h5" pb={8}>
          Create a customer portal account
        </Typography>
        <form
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
          onSubmit={(e) => {
            e.preventDefault();
            handleCreateAccount();
          }}
        >
          <TextField
            inputProps={{
              'aria-label': 'Email',
            }}
            data-cy="email"
            id="outlined-basic"
            label="Email"
            sx={{ mb: 1 }}
            value={email}
            variant="outlined"
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
          <TextField
            inputProps={{
              'aria-label': 'Password',
            }}
            data-cy="password"
            id="outlined-basic"
            label="Password"
            sx={{ mb: 1 }}
            type="password"
            value={password}
            variant="outlined"
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />
          <Button
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            disabled={!email || !password || isLoading}
            startIcon={isLoading && <CircularProgress size={20} />}
            sx={{ width: '100%' }}
            variant="contained"
            type="submit"
            onClick={handleCreateAccount}
          >
            Create account
          </Button>
        </form>
        <Typography color="red" data-cy="error" sx={{ pt: 1 }}>
          {isNil(error) ? '' : sentenceCase(error)}
        </Typography>
      </Box>
    </div>
  );
};

export default CreateCustomerPortalAccount;
