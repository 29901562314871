import { StatsigClient } from '@statsig/js-client';
import { LocalOverrideAdapter } from '@statsig/js-local-overrides';
import { useMemo, useState } from 'react';
import { isNilOrEmptyString } from 'shared/string';
import {
  EnvironmentVariables,
  isDevelopment,
} from '../../environment-variables';
import StatsigOverrides from './statsig-overrides';

const applyOverrides = (adapter: LocalOverrideAdapter) => {
  for (const gate of StatsigOverrides) {
    adapter.overrideGate(gate, true);
  }
};

export type StatsigUserDetails = {
  userUuid: string | undefined;
  userEmail: string | undefined;
  companyUuid: string | undefined;
};

const useStatsigClient = ({
  userUuid,
  userEmail,
  companyUuid,
}: StatsigUserDetails) => {
  const overrideAdapter = useMemo(() => new LocalOverrideAdapter(), []);
  applyOverrides(overrideAdapter);

  const [client] = useState(() => {
    if (isNilOrEmptyString(EnvironmentVariables.VITE_STATSIG_API_KEY)) {
      throw new Error('Statsig API key is not set');
    }
    return new StatsigClient(
      EnvironmentVariables.VITE_STATSIG_API_KEY,
      // These values may be undefined if useMe query hasn't finished yet,
      // so we make sure to update them below if they ever differ
      { userID: userUuid, email: userEmail, custom: { companyUuid } },
      {
        environment: {
          tier: EnvironmentVariables.VITE_APP_ENV,
        },
        // Only apply overrides if in development
        overrideAdapter: isDevelopment() ? overrideAdapter : undefined,
      },
    );
  });

  if (
    userUuid !== client.getContext().user.userID ||
    companyUuid !== client.getContext().user.custom?.companyUuid ||
    userEmail !== client.getContext().user.email
  ) {
    client.updateUserAsync({
      userID: userUuid,
      email: userEmail,
      custom: { companyUuid },
    });
  }

  return client;
};

export default useStatsigClient;
