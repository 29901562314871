import { Draggable, Droppable } from '@hello-pangea/dnd';
import { Box, Stack } from '@mui/material';
import { isNil } from 'lodash';
import React, { memo } from 'react';
import { type RouteFragment, StopStatus } from '../../../generated/graphql';
import RouteStopCard from './route-stop-card';

type RouteStopsListProps = {
  readonly route: RouteFragment;
  readonly width?: number;
  readonly dragDropSuffixId?: string;
};

const RouteStopsList = ({
  route,
  dragDropSuffixId,
  width,
}: RouteStopsListProps) => {
  return (
    <Droppable droppableId={route.uuid}>
      {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
      {(provided, snapshot) => (
        <Stack ref={provided.innerRef} spacing={1} {...provided.droppableProps}>
          {route.slots.map((slot, index) => {
            const stop = slot.stops[0];
            if (isNil(stop)) return <Box />;
            return (
              <Draggable
                key={slot.uuid}
                draggableId={`${slot.uuid}${
                  isNil(dragDropSuffixId) ? '' : `_${dragDropSuffixId}`
                }`}
                index={index}
                isDragDisabled={
                  stop.status !== StopStatus.NotArrived || route.locked
                }
              >
                {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
                {(prov, snap) => (
                  <div
                    ref={prov.innerRef}
                    {...prov.draggableProps}
                    {...prov.dragHandleProps}
                  >
                    <RouteStopCard
                      stop={stop}
                      locked={route.locked}
                      index={index}
                      width={width}
                    />
                  </div>
                )}
              </Draggable>
            );
          })}
          {provided.placeholder}
        </Stack>
      )}
    </Droppable>
  );
};
export default memo(RouteStopsList);
