import { ApolloLink, Observable } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';
import { TimeoutError } from './timeout-error';

export const GRAPHQL_TIMEOUT_DURATION_MS = Number.parseInt(
  import.meta.env.VITE_GRAPHQL_TIMEOUT_DURATION_MS ??
    (5 * 60 * 1000).toString(),
  10,
);

export const timeoutLink = new ApolloLink((operation, forward) => {
  return new Observable((observer) => {
    const startTime = Date.now();

    const timeout = setTimeout(() => {
      const duration = Date.now() - startTime;

      const timeoutError = new TimeoutError(
        `Timeout: Request took longer than ${GRAPHQL_TIMEOUT_DURATION_MS}ms`,
        {
          operationName: operation.operationName,
          operationVariables: operation.variables,
          duration,
          timedOut: true,
        },
      );
      // Using console.log instead of console.error so a duplicate error is not sent to datadog
      // eslint-disable-next-line no-console
      console.log(timeoutError);

      operation.setContext({
        timeoutError,
      });

      datadogRum.addError(timeoutError, timeoutError.context);
    }, GRAPHQL_TIMEOUT_DURATION_MS);

    const subscription = forward(operation).subscribe({
      next: (result) => {
        clearTimeout(timeout);
        observer.next(result);
      },
      error: (error) => {
        clearTimeout(timeout);
        observer.error(error);
      },
      complete: observer.complete.bind(observer),
    });

    return () => {
      clearTimeout(timeout);
      subscription.unsubscribe();
    };
  });
});
