import { Box } from '@mui/material';
import { isNil } from 'lodash';
import React from 'react';
import { Marker } from 'react-map-gl';
import { shallow } from 'zustand/shallow';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import {
  darkenHexColor,
  lightenHexColor,
} from '../../../../common/utils/colors';
import {
  type StopFragment,
  type StopOnRouteFragment,
  StopStatus,
} from '../../../../generated/graphql';
import useDispatchStore from '../../dispatch-store';
import useRouteActions from '../../hooks/use-route-actions';
import CustomPin from './pin';
import StopMarkerCircleIcon from './stop-marker-circle-icon';
import StopMarkerPopup from './stop-marker-popup';

const MARKER_SIZE = 20;

type StopMarkerProps = {
  stop: StopOnRouteFragment | StopFragment;
  color: string;
  ordinal: number; // 1 - indexed
  text?: string;
  onClick?: () => void;
};

const StopMarker = ({
  stop,
  color,
  ordinal,
  text,
  onClick,
}: StopMarkerProps) => {
  const { updateStopOrder } = useRouteActions();
  const {
    address,
    status,
    uuid: stopUuid,
  } = stop.__typename === 'StandardStopEntity' ? stop : stop.stop;
  const [
    isSelected,
    isHovered,
    setHoveredStopUuid,
    setHoveredOrderUuid,
    getSelectedMapStop,
    getRouteByUuid,
    setSelectedMapStop,
    setOpenedOrderUuid,
  ] = useDispatchStore(
    (state) => [
      state.selectedStopUuids.includes(stopUuid) ||
        state.selectedMapStop?.uuid === stopUuid,
      state.hoveredStopUuid === stopUuid,
      state.setHoveredStopUuid,
      state.setHoveredOrderUuid,
      state.getSelectedMapStop,
      state.getRouteByUuid,
      state.setSelectedMapStop,
      state.setOpenedOrderUuid,
    ],
    shallow,
  );

  const ffDispatchTrackParity = useFeatureFlag(
    FeatureFlag.FF_DISPATCH_TRACK_PARITY,
  );

  const routeSlot =
    stop.__typename === 'StandardStopEntity' ? stop.routeSlot : null;

  const onSwapStops = async () => {
    const selectedMapStop = getSelectedMapStop();
    const selectedMapRouteSlot =
      selectedMapStop?.__typename === 'StandardStopEntity'
        ? selectedMapStop.routeSlot
        : null;
    const selectedRouteUuid = selectedMapRouteSlot?.route?.uuid;
    if (isNil(selectedMapRouteSlot) || isNil(selectedRouteUuid)) {
      return;
    }

    const selectedRoute = getRouteByUuid(selectedRouteUuid);
    if (
      !isNil(selectedRoute) &&
      selectedMapRouteSlot.uuid !== routeSlot?.uuid &&
      routeSlot?.route?.uuid === selectedRoute.uuid
    ) {
      await updateStopOrder({
        routeUuid: selectedRoute.uuid,
        startIndex: ordinal - 1,
        endIndex: selectedRoute.slots.findIndex(
          (slot) => slot.uuid === selectedMapRouteSlot.uuid,
        ),
        swap: true,
      });
    }
  };

  const order =
    stop.__typename === 'StandardStopEntity'
      ? stop.shipment?.order
      : stop.order;

  try {
    if (isNil(address)) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    }
    const defaultMarkerColor =
      status === StopStatus.NotArrived ? color : lightenHexColor(color, 0.5);
    const markerColor = isHovered
      ? darkenHexColor(defaultMarkerColor, 0.35)
      : defaultMarkerColor;
    return (
      <Box
        onMouseEnter={() => {
          setHoveredStopUuid(stopUuid);
          setHoveredOrderUuid(order?.uuid);
        }}
        onMouseLeave={() => {
          setHoveredStopUuid(undefined);
          setHoveredOrderUuid(undefined);
        }}
        onDoubleClick={() => {
          setOpenedOrderUuid(order?.uuid);
        }}
        onClick={(e) => {
          document
            .getElementById(`route_stop_card_${stopUuid}`)
            ?.scrollIntoView({ block: 'start' });
          if (e.altKey) {
            onSwapStops();
          }
          if (isSelected) {
            setSelectedMapStop(undefined);
          } else {
            setSelectedMapStop(
              stop.__typename === 'StandardStopEntity' ? stop : stop.stop,
            );
          }
          onClick?.();
        }}
      >
        {isHovered && <StopMarkerPopup stop={stop} markerSize={MARKER_SIZE} />}
        <Box>
          {!isNil(address.longitude) && !isNil(address.latitude) && (
            <Marker
              longitude={address.longitude}
              latitude={address.latitude}
              anchor="bottom"
            >
              {ffDispatchTrackParity ? (
                <CustomPin
                  stop={stop}
                  pinColor={color}
                  isSelected={isSelected}
                  text={isNil(text) ? `${ordinal}` : text}
                />
              ) : (
                <StopMarkerCircleIcon
                  color={markerColor}
                  text={`${ordinal}`}
                  size={isSelected ? 'large' : 'medium'}
                  style={{ cursor: 'pointer' }}
                />
              )}
            </Marker>
          )}
        </Box>
      </Box>
    );
  } catch {
    // TODO: Sentry logging for when this happens. For now, return a fragment since it doesn't make sense to return a component in this case since there is not much actionable to the user.
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }
};

export default React.memo(StopMarker);
