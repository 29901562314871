import { TextField, useTheme } from '@mui/material';
import { isNil } from 'lodash';
import { useEffect } from 'react';
import { type Option } from '../../../../common/filters/types';
import useWarehouses from '../../../../common/react-hooks/use-warehouses';
import AutocompleteFuzzy from '../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';

const CACHE_PREFIX = 'WAREHOUSE_FILTER';

type WarehouseSelectorProps = {
  readonly value: Option | null;
  readonly onChange: (value: Option | null) => void;
  readonly disabled?: boolean;
  readonly required?: boolean;
  readonly width?: string | number;
  readonly error?: boolean;
  readonly whiteBackground?: boolean;
  readonly noLabel?: boolean;
  readonly cacheId?: string;
};
const WarehouseSelector = ({
  value,
  onChange,
  disabled,
  required,
  width = '200px',
  error,
  whiteBackground = false,
  noLabel = false,
  cacheId,
}: WarehouseSelectorProps) => {
  const theme = useTheme();
  const { warehouses } = useWarehouses();

  const handleChange = (option: Option | null | undefined) => {
    if (!isNil(cacheId)) {
      if (!isNil(option) && !isNil(option.label) && !isNil(option.value)) {
        localStorage.setItem(`${CACHE_PREFIX}_LABEL_${cacheId}`, option.label);
        localStorage.setItem(`${CACHE_PREFIX}_VALUE_${cacheId}`, option.value);
      } else {
        localStorage.removeItem(`${CACHE_PREFIX}_LABEL_${cacheId}`);
        localStorage.removeItem(`${CACHE_PREFIX}_VALUE_${cacheId}`);
      }
    }
    onChange(option ?? null);
  };

  // load cached selection
  useEffect(() => {
    if (!isNil(cacheId)) {
      const cachedLabel = localStorage.getItem(
        `${CACHE_PREFIX}_LABEL_${cacheId}`,
      );
      const cachedValue = localStorage.getItem(
        `${CACHE_PREFIX}_VALUE_${cacheId}`,
      );
      if (!isNil(cachedLabel) && !isNil(cachedValue)) {
        onChange({
          label: cachedLabel,
          value: cachedValue,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // if there are < 2 warehouses, do not show the selector
  if ((warehouses?.length ?? 0) < 2) {
    return null;
  }
  return (
    <AutocompleteFuzzy
      options={
        warehouses?.map((warehouse) => ({
          label: warehouse.name,
          value: warehouse.uuid,
        })) ?? []
      }
      isOptionEqualToValue={(o, v) => o.value === v.value}
      size="small"
      disabled={disabled}
      sx={{ width, outlineColor: 'blue' }}
      value={value}
      matchSortOptions={{ keys: ['label'] }}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          label={noLabel ? undefined : 'Warehouse'}
          size="small"
          error={error}
          sx={{
            backgroundColor: whiteBackground
              ? theme.palette.background.paper
              : theme.palette.primary.light,
          }}
          required={required}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        />
      )}
      onChange={(_, newValue) => {
        handleChange(newValue);
      }}
    />
  );
};

export default WarehouseSelector;
