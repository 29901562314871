import {
  Alert,
  Box,
  CircularProgress,
  Paper,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import { isNil } from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import useMe from '../../../common/react-hooks/use-me';
import useWindowDimensions from '../../../common/react-hooks/use-window-dimensions';
import {
  type OrdersForOrderMatchingQuery,
  Segment,
  useOrdersForOrderMatchingLazyQuery,
} from '../../../generated/graphql';
import { type FetchCompanyDocumentsParams } from '../types/company-documents';
import OrderMatchingRow from './order-matching-row';

const DEFAULT_ROWS_PER_PAGE = 10;

type EndOfDayOrderMatchingTableProps = {
  // setShowSpecifyBolNumberPagesModal: React.Dispatch<
  //   React.SetStateAction<boolean>
  // >;

  readonly fetchCompanyDocuments: (params: FetchCompanyDocumentsParams) => void;
};

const EndOfDayOrderMatchingTable = ({
  fetchCompanyDocuments,
}: EndOfDayOrderMatchingTableProps) => {
  const me = useMe();
  const { height } = useWindowDimensions();
  const [queryOrders] = useOrdersForOrderMatchingLazyQuery();
  const [page, setPage] = React.useState(0);
  const [showFetchOrdersErrorSnackbar, setShowFetchOrdersErrorSnackbar] =
    useState<boolean>(false);
  const [orderData, setOrderData] = useState<
    OrdersForOrderMatchingQuery | undefined
  >();
  const [searchText, setSearchText] = useState<string | undefined>();
  const [searching, setSearching] = useState<boolean>(false);
  const [tc, setTc] = useState<number>(0);

  const fetchOrders = async ({
    first,
    after,
    last,
    before,
    totalCount,
  }: {
    first?: number | null | undefined;
    after?: string | null | undefined;
    last?: number | null | undefined;
    before?: string | null | undefined;
    totalCount?: boolean | null | undefined;
  }) => {
    const variables = {
      first,
      after,
      last,
      before,
      totalCount,
      searchText,
    };
    const res = await queryOrders({
      variables,
    });
    if (isNil(res.error)) {
      setOrderData(res.data);
    } else {
      setShowFetchOrdersErrorSnackbar(true);
    }
  };

  const search = () => {
    setSearching(true);
    fetchOrders({ first: DEFAULT_ROWS_PER_PAGE, totalCount: true }).then(() => {
      setPage(0);
      setTc(0);
      setSearching(false);
    });
  };

  useEffect(() => {
    search();
  }, []);

  useEffect(() => {
    if (tc === 0) {
      setTc(orderData?.orders?.totalCount ?? 0);
    }
  }, [orderData]);

  const handleChangePage = async (event: unknown, newPage: number) => {
    await (newPage > page
      ? fetchOrders({
          first: DEFAULT_ROWS_PER_PAGE,
          after: orderData?.orders?.pageInfo.endCursor,
        })
      : fetchOrders({
          last: DEFAULT_ROWS_PER_PAGE,
          before: orderData?.orders?.pageInfo.startCursor,
        }));
    setPage(newPage);
  };

  const emptyRows =
    page > 0
      ? Math.max(
          0,
          DEFAULT_ROWS_PER_PAGE - (orderData?.orders?.edges.length ?? 0),
        )
      : 0;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        color: 'black',
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <TextField
          size="small"
          label="Search Orders"
          InputProps={{ style: { backgroundColor: 'white' } }}
          value={searchText}
          sx={{ width: '250px', margin: '10px' }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              search();
            }
          }}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
        {searching && <CircularProgress size={20} />}
        <TablePagination
          rowsPerPageOptions={[DEFAULT_ROWS_PER_PAGE]}
          component="div"
          count={tc}
          rowsPerPage={DEFAULT_ROWS_PER_PAGE}
          page={page}
          onPageChange={handleChangePage}
        />
      </Stack>
      <TableContainer component={Paper} style={{ height: height - 195 }}>
        <Table
          stickyHeader
          size="small"
          aria-label="collapsible table"
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: 'none',
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">
                {me.segment === Segment.Cartage ? 'HAWB' : 'Pro #'}
              </TableCell>
              <TableCell align="left" />
            </TableRow>
          </TableHead>
          <TableBody>
            {orderData?.orders?.edges.map(({ node: data }) => (
              <OrderMatchingRow
                key={data.uuid}
                data={data}
                refreshOrders={search}
                fetchCompanyDocuments={fetchCompanyDocuments}
              />
            ))}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 33 * emptyRows,
                }}
              >
                <TableCell colSpan={8} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showFetchOrdersErrorSnackbar}
        autoHideDuration={3000}
        onClose={() => {
          setShowFetchOrdersErrorSnackbar(false);
        }}
      >
        <Alert
          severity="error"
          onClose={() => {
            setShowFetchOrdersErrorSnackbar(false);
          }}
        >
          Unable to fetch orders - please contact support if this issue
          persists.
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EndOfDayOrderMatchingTable;
