import { Divider, Stack, Box } from '@mui/material';
import { isNil } from 'lodash';
import React from 'react';
import { shallow } from 'zustand/shallow';
import { FeatureFlag } from '../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../common/react-hooks/use-feature-flag';
import useTerminals from '../../../../../common/react-hooks/use-terminals';
import { TariffGroupType } from '../../../../../generated/graphql';
import { TariffModalMode } from '../store/tariff-group-controller';
import useTariffGroupStore from '../store/tariff-group-state-store';
import TariffConfigSection from '../tariff-config-section';
import TariffGroupEditorContainer from '../tariff-group-editor-container';
import ContactAccordionsList from './tariff-modal-contact-accordions-list';
import FuelProfileField from './tariff-modal-fuel-profile-field';
import NameField from './tariff-modal-name-field';
import ServicesField from './tariff-modal-services-field';
import StartEndDateField from './tariff-modal-start-end-date-field';
import TariffGroupTypeField from './tariff-modal-tariff-group-type-field';
import TerminalsField from './tariff-modal-terminals-field';
import UseDeficitBillingField from './tariff-modal-use-deficit-billing-field';
import VehicleTypesField from './tariff-modal-vehicle-types-field';
import ZoneGroupField from './tariff-modal-zone-group-field';

const TariffModalContent = ({
  isCompanyDefaultTariff,
}: {
  readonly isCompanyDefaultTariff?: boolean;
}) => {
  const [modalMode, tariffGroupType] = useTariffGroupStore(
    (state) => [state.modalMode, state.tariffGroupType],
    shallow,
  );
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const zoneGroupsEnabled = useFeatureFlag(FeatureFlag.FF_ZONE_GROUPS_ENABLED);
  const tariffChainsEnabled = useFeatureFlag(
    FeatureFlag.FF_TARIFF_CHAINS_ENABLED,
  );

  // If the tariff is the company default tariff, don't show the contact accordion
  // If tariff chains are enabled, don't show the contact accordion
  // Otherwise, show the contact accordion
  const showContactAccordion = !isCompanyDefaultTariff && !tariffChainsEnabled;

  return (
    <Stack gap="20px">
      <Stack direction="row" spacing={2}>
        {(isNil(isCompanyDefaultTariff) || !isCompanyDefaultTariff) && (
          <Box width="200px">
            <NameField />
          </Box>
        )}
        <Stack direction="column" spacing={1}>
          {isCompanyDefaultTariff !== true && (
            <Box width="200px">
              <TariffGroupTypeField />
            </Box>
          )}
          {(isCompanyDefaultTariff !== true ||
            tariffGroupType === TariffGroupType.Transfer) && (
            <Box width="200px">
              <FuelProfileField />
            </Box>
          )}
        </Stack>
        <Stack direction="column" spacing={1}>
          {terminalsEnabled && (
            <Box width="200px">
              <TerminalsField />
            </Box>
          )}
          {zoneGroupsEnabled && (
            <Box width="200px">
              <ZoneGroupField />
            </Box>
          )}
        </Stack>
        {tariffChainsEnabled && (
          <Stack direction="column" spacing={1}>
            <Box width="200px">
              <ServicesField />
            </Box>
            <Box width="200px">
              <VehicleTypesField />
            </Box>
          </Stack>
        )}
        {(isNil(isCompanyDefaultTariff) || !isCompanyDefaultTariff) && (
          <Box width="200px">
            <StartEndDateField />
          </Box>
        )}
        <Box width="200px">
          <UseDeficitBillingField />
        </Box>
      </Stack>
      <Stack direction="row" gap="20px" sx={{ maxHeight: '70vh' }}>
        {showContactAccordion && (
          <Stack gap="15px" width="20%" maxHeight="60vh">
            <ContactAccordionsList />
          </Stack>
        )}
        <Stack
          sx={{ border: 'solid 0.5px black', padding: 2, overflowX: 'scroll' }}
          direction="row"
          justifyContent="space-between"
          flex="1"
          gap="10px"
        >
          <TariffConfigSection
            disableTariffTypeChange={modalMode === TariffModalMode.EDIT}
          />
          <Divider orientation="vertical" />
          <TariffGroupEditorContainer />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default TariffModalContent;
