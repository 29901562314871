import { Clear } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  CircularProgress,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  InputAdornment,
  type InputProps,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import {
  type ColDef,
  type ColumnMovedEvent,
  type ColumnResizedEvent,
  type ColumnState,
  type FilterChangedEvent,
  type IRowNode,
  type PaginationChangedEvent,
  type SelectionChangedEvent,
  type SideBarDef,
  type SortChangedEvent,
  type SortModelItem,
} from 'ag-grid-community';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { debounce, isEmpty, isNil, uniq, uniqBy } from 'lodash';
import React, {
  type Dispatch,
  type RefObject,
  type SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import useStateRef from 'react-usestateref';
import { filterNotNil } from 'shared/array';
import useLocalStorageState from 'use-local-storage-state';
import { shallow } from 'zustand/shallow';
import DateDropdownPicker, {
  type DateOption,
  DatePickerFilterType,
  initialDateOption,
} from '../../../common/components/date-dropdown-picker';
import MultiselectFilterButton from '../../../common/components/multiselect-filter-button';
import TerminalFilterButton from '../../../common/components/terminal-filter-button';
import { type Option } from '../../../common/filters/types';
import useMe from '../../../common/react-hooks/use-me';
import {
  LinehaulDispatchTableField,
  OrderLineHaulSegmentSortFields,
  type SortDirection,
  TableFieldsDocument,
  useLineHaulOrdersWithUpcomingSegmentAndInboundStopStatusQuery,
  useSegmentedLineHaulOrdersV2Query,
  useUpdateUserMutation,
} from '../../../generated/graphql';
import ConfigureLinehaulDispatchTableColumns from '../components/ag-grid/configure-line-haul-dispatch-columns';
import useLineHaulDispatchActions from '../hooks/use-line-haul-dispatch-actions';
import useLineHaulDispatchStore from '../store/line-haul-dispatch-store';
import { type SegmentedLineHaulOrder } from '../types';
import { canAddOrderToManifest } from '../utils';

const getSortModelFromColumnState = (
  columnState: ColumnState[] | undefined,
): SortModelItem[] => {
  if (isNil(columnState)) return [];
  return filterNotNil(
    columnState
      .sort((a, b) => (a.sortIndex ?? 0) - (b.sortIndex ?? 0))
      .map((col) =>
        isNil(col.sort) ? null : { colId: col.colId, sort: col.sort },
      ),
  );
};

type Sorts = Array<{
  sortBy: OrderLineHaulSegmentSortFields;
  sortDirection: SortDirection;
}>;

const getSortAndFilterVariables = ({
  sortModel = [],
}: {
  sortModel?: SortModelItem[];
}): Sorts => {
  let sorts: Sorts = [];
  if (!isNil(sortModel)) {
    sorts = filterNotNil(
      sortModel.map((sort) =>
        Object.values(OrderLineHaulSegmentSortFields).includes(
          sort.colId as OrderLineHaulSegmentSortFields,
        )
          ? {
              sortBy: sort.colId as OrderLineHaulSegmentSortFields,
              sortDirection: sort.sort.toUpperCase() as SortDirection,
            }
          : null,
      ),
    );
  }

  return sorts;
};

type State = {
  currentCursor: string | null | undefined;
  debouncedSearchText: string;
  startTerminalOption: Option | undefined;
  endTerminalOption: Option | undefined;
  serviceDateOption: DateOption;
  hasPageLoadedBeforeForPageChange: boolean;
};

type LineHaulOrdersTableProps = {
  readonly tableHeaders?: LinehaulDispatchTableField[];
  readonly columnDefinitions: Array<ColDef<SegmentedLineHaulOrder>>;
  readonly gridRef: RefObject<AgGridReact<SegmentedLineHaulOrder>>;
  readonly selectedUuids: string[];
  readonly setSelectedUuids: Dispatch<SetStateAction<string[]>>;
  readonly isHeaderCheckboxSelected: boolean;
  readonly setIsHeaderCheckboxSelected: Dispatch<SetStateAction<boolean>>;
};

export const DemoLineHaulDispatchTableAgGrid = ({
  tableHeaders,
  columnDefinitions,
  gridRef,
  selectedUuids,
  setSelectedUuids,
  isHeaderCheckboxSelected,
  setIsHeaderCheckboxSelected,
}: LineHaulOrdersTableProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [, setState, stateRef] = useStateRef<State>({
    currentCursor: null,
    debouncedSearchText: '',
    startTerminalOption: undefined,
    endTerminalOption: undefined,
    serviceDateOption: initialDateOption,
    hasPageLoadedBeforeForPageChange: false,
  });
  const toolPanelVisibleRef = useRef(false);
  const [sorts, setSorts] = useState<Sorts>([]);
  const { data: ordersData } = useSegmentedLineHaulOrdersV2Query({
    fetchPolicy: 'cache-first',
    variables: {
      findSegmentedLineHaulOrdersInputV2: {
        includeCount: true,
        pageSize: 200,
        page: 0,
        searchText: stateRef.current.debouncedSearchText?.trim(),
        startTerminalUuid: stateRef.current.startTerminalOption?.value,
        endTerminalUuid: stateRef.current.endTerminalOption?.value,
        sorts,
        serviceDateFilters: {
          startFilterValue: stateRef.current.serviceDateOption.startDate,
          endFilterValue: stateRef.current.serviceDateOption.endDate,
        },
      },
    },
  });
  const orderUuids = useMemo(
    () =>
      (ordersData?.segmentedLineHaulOrdersV2.edges ?? []).map(
        (s) => s.order.uuid,
      ),
    [ordersData],
  );
  const { data: ordersWithStopStatusData } =
    useLineHaulOrdersWithUpcomingSegmentAndInboundStopStatusQuery({
      ...(orderUuids.length > 0
        ? { variables: { uuids: orderUuids } }
        : { skip: true }),
    });
  const rowData = useMemo(() => {
    return ordersData?.segmentedLineHaulOrdersV2.edges.map((edge) => {
      const orderWithUpcomingSegment =
        ordersWithStopStatusData?.ordersByUuids?.find(
          (o) => o.uuid === edge.order.uuid,
        );
      if (!isNil(orderWithUpcomingSegment)) {
        const returnedValue: SegmentedLineHaulOrder = {
          ...edge,
          upcomingLineHaulSegment:
            orderWithUpcomingSegment.upcomingLineHaulSegment ?? undefined,
          hasNoneOrCompletedInboundStop:
            orderWithUpcomingSegment.hasNoneOrCompletedInboundStop,
          lastCompletedLineHaulSegment:
            orderWithUpcomingSegment.lastCompletedLineHaulSegment ?? undefined,
        };
        return returnedValue;
      }
      return edge;
    });
  }, [ordersData, ordersWithStopStatusData]);

  const routePageNumber = (() => {
    const routePage = searchParams.get('page');
    if (typeof routePage === 'string') {
      return Number.parseInt(routePage, 10);
    }
    return 1;
  })();

  const [searchText, setSearchText] = useState('');
  const onPaginationChangedHandler = (
    params: PaginationChangedEvent<SegmentedLineHaulOrder>,
  ) => {
    if (
      stateRef.current.hasPageLoadedBeforeForPageChange &&
      params.api.paginationGetCurrentPage() !== routePageNumber - 1
    ) {
      setSearchParams((sp) => {
        const newParams = new URLSearchParams(sp);
        newParams.set(
          'page',
          (params.api.paginationGetCurrentPage() + 1).toString(),
        );
        return newParams;
      });
    }

    if (stateRef.current.hasPageLoadedBeforeForPageChange) return;
    if (routePageNumber === 1) {
      stateRef.current.hasPageLoadedBeforeForPageChange = true;
    } else if (
      params.api.getDisplayedRowCount() > 1 &&
      params.api.paginationGetCurrentPage() !== routePageNumber - 1
    ) {
      stateRef.current.hasPageLoadedBeforeForPageChange = true;
      params.api.paginationGoToPage(routePageNumber - 1);
    }
  };

  const [
    shouldRefreshGrid,
    setShouldRefreshGrid,
    openedManifest,
    currentManifestTab,
    setSnackbarSuccessMessage,
  ] = useLineHaulDispatchStore(
    (state) => [
      state.shouldRefreshGrid,
      state.setShouldRefreshGrid,
      state.openedManifest,
      state.currentManifestTab,
      state.setSnackbarSuccessMessage,
    ],
    shallow,
  );
  const { addMultipleOrdersToOpenedManifest } = useLineHaulDispatchActions();
  const [columnWidths, setColumnWidths] = useLocalStorageState<
    Array<{ width: number | undefined; colId: string }>
  >('line_haul_dispatch_column_widths', {
    defaultValue: [],
  });
  const { userUuid } = useMe();
  const [updateUser] = useUpdateUserMutation({
    refetchQueries: [TableFieldsDocument],
  });
  const [showConfigureHeaders, setShowConfigureHeaders] = useState(false);

  const deselectAll = () => {
    gridRef.current?.api.deselectAll();
    setSelectedUuids([]);
  };

  const refreshGrid = ({
    shouldDeselect = false,
  }: {
    shouldDeselect?: boolean;
  }) => {
    setState((prevState) => {
      return {
        ...prevState,
        currentCursor: null,
        totalCount: undefined,
      };
    });
    if (!isNil(gridRef.current?.api)) {
      gridRef.current?.api.paginationGoToFirstPage();
      gridRef.current?.api.refreshServerSide({ purge: true });
      if (shouldDeselect) {
        deselectAll();
        gridRef.current?.api.deselectAll();
      }
    }
  };

  const handleSortChanged = (_event: SortChangedEvent) => {
    setSorts(
      getSortAndFilterVariables({
        sortModel: getSortModelFromColumnState(
          gridRef.current?.columnApi?.getColumnState(),
        ),
      }),
    );
    refreshGrid({});
    setState((prevState) => ({
      ...prevState,
      currentCursor: null,
    }));
  };

  useEffect(() => {
    if (shouldRefreshGrid) {
      refreshGrid({
        shouldDeselect: true,
      });
      setShouldRefreshGrid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefreshGrid]);

  useEffect(() => {
    refreshGrid({
      shouldDeselect: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRef.current.serviceDateOption]);

  useEffect(() => {
    setState((prevState) => {
      return {
        ...prevState,
        startTerminalOption: isNil(openedManifest)
          ? undefined
          : {
              label: openedManifest.startTerminal.code,
              value: openedManifest.startTerminal.uuid,
            },
        endTerminalOption: isNil(openedManifest)
          ? undefined
          : {
              label: openedManifest.endTerminal.code,
              value: openedManifest.endTerminal.uuid,
            },
      };
    });
    refreshGrid({
      shouldDeselect: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedManifest?.uuid]);

  const updateColumnWidthsOnGridChange = () => {
    // Columns widths can't be updated until AG Grid has finished rendering the columns
    // So this code can't be placed in the useEffect above
    if (!isNil(gridRef.current?.columnApi)) {
      gridRef.current?.columnApi.setColumnWidths(
        columnWidths.map((columnWidth) => ({
          newWidth: columnWidth.width ?? 0,
          key: columnWidth.colId,
        })),
      );
    }
  };

  const closeFilterToolPanel = () => {
    const filterToolPanel =
      gridRef.current?.api.getToolPanelInstance('filters');
    filterToolPanel?.collapseFilters();
    // reset filters to all before closing.
    filterToolPanel?.refresh();
    filterToolPanel?.setFilterLayout(columnDefinitions);
    gridRef.current?.api.closeToolPanel();
  };

  const defaultColDef: ColDef<SegmentedLineHaulOrder> = useMemo(() => {
    return {
      flex: 1,
      wrapHeaderText: true,
      wrapText: true,
      resizable: true,
      suppressMenu: true,
    };
  }, []);

  const sideBar = useMemo<
    SideBarDef | string | string[] | boolean | null
  >(() => {
    return {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
          toolPanelParams: {
            suppressExpandAll: false,
            suppressFilterSearch: true,
          },
        },
      ],
      defaultToolPanel: 'filters',
      position: 'right',
    };
  }, []);

  // This closes the toolpanel if we click outside the filter toolpanel since AG Grid doesn't have an API for this
  useEffect(() => {
    const handleDocumentClick = (event: Event) => {
      const gridApi = gridRef.current?.api;
      const isToolPanelClicked = (event.target as Element).closest(
        '.ag-filter-toolpanel',
      );

      const isDateRangePickerClicked = (event.target as Element).closest(
        '.rmdp-wrapper',
      );

      if (
        gridApi &&
        !isToolPanelClicked &&
        !isDateRangePickerClicked &&
        toolPanelVisibleRef.current
      ) {
        closeFilterToolPanel();
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridRef]);

  const handleSearch = () => {
    setState((prevState) => {
      return {
        ...prevState,
        currentCursor: null,
      };
    });
    refreshGrid({});
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((value) => {
      setState((prevState) => {
        return {
          ...prevState,
          debouncedSearchText: value,
          currentCursor: null,
        };
      });
      refreshGrid({
        shouldDeselect: true,
      });
    }, 1000),
    [],
  );

  const bulkAddOrders = async () => {
    const res = await addMultipleOrdersToOpenedManifest({
      orderUuids: selectedUuids,
      openedManifestUuid: openedManifest?.uuid,
    });
    if (!isNil(res)) {
      setSnackbarSuccessMessage(
        `Order(s) added to ${openedManifest?.referenceNumber}`,
      );
      gridRef.current?.api.applyServerSideTransaction({
        remove: gridRef.current.api.getSelectedNodes().map((node) => node.data),
      });
    }
    deselectAll();
  };

  const handleStartTerminalChange = (option: Option | null | undefined) => {
    setState((prevState) => {
      return {
        ...prevState,
        startTerminalOption: option ?? undefined,
      };
    });
    refreshGrid({ shouldDeselect: true });
  };
  const handleEndTerminalChange = (option: Option | null | undefined) => {
    setState((prevState) => {
      return {
        ...prevState,
        endTerminalOption: option ?? undefined,
      };
    });
    refreshGrid({ shouldDeselect: true });
  };

  const handleRowSelected = (
    selectedNode?: IRowNode<SegmentedLineHaulOrder>,
  ) => {
    // when selecting or unselecting nodes, need to select/unselect
    // all duplicates created by the reselection that was done above
    for (const node of gridRef.current?.api.getSelectedNodes() ?? []) {
      if (node.data?.order.uuid === selectedNode?.data?.order.uuid) {
        if (selectedNode?.isSelected() === false) {
          node.setSelected(false);
        } else {
          node.setSelected(true);
        }
      }
    }

    const selectedRows = gridRef.current?.api.getSelectedRows();
    setSelectedUuids(uniqBy(selectedRows, 'uuid').map((row) => row.order.uuid));
  };

  const selectAllOrdersOnPage = () => {
    const allSelectedUuids = uniq([
      ...selectedUuids,
      ...filterNotNil(
        gridRef.current?.api.getRenderedNodes().map((node) => {
          node.setSelected(true);
          handleRowSelected(node);
          return node.data?.order.uuid;
        }) ?? [],
      ),
    ]);
    setSelectedUuids(allSelectedUuids);
  };

  const handleSelectionChanged = (
    event: SelectionChangedEvent<SegmentedLineHaulOrder>,
  ) => {
    if (event?.source === 'uiSelectAll') {
      if (isHeaderCheckboxSelected) {
        deselectAll();
      } else {
        selectAllOrdersOnPage();
      }
      setIsHeaderCheckboxSelected((prev) => !prev);
    }
  };

  const handleFilterChanged = (_event: FilterChangedEvent) => {
    refreshGrid({ shouldDeselect: true });
  };

  const handleColumnResized = async (e: ColumnResizedEvent) => {
    const columnState = gridRef.current?.columnApi.getColumnState();

    if (e.finished && e.source === 'uiColumnResized' && !isNil(columnState)) {
      setColumnWidths(
        columnState.map((col) => ({ colId: col.colId, width: col.width })),
      );
    }
  };

  const handleColumnMoved = async (e: ColumnMovedEvent) => {
    if (isNil(userUuid)) {
      return;
    }
    const state = gridRef.current?.columnApi.getColumnState();
    if (e.finished && e.source === 'uiColumnMoved' && !isNil(state)) {
      const headers =
        state.filter((col) => col.hide !== true).map((col) => col.colId) ?? [];
      const updatedLinehaulDispatchFields = filterNotNil(
        headers.map((header) => {
          const field = Object.values(LinehaulDispatchTableField).find(
            (f) => f === header,
          );
          return field;
        }),
      );

      updateUser({
        variables: {
          updateUserInput: {
            uuid: userUuid,
            linehaulDispatchTableFields: updatedLinehaulDispatchFields,
          },
        },
      });
    }
  };

  const TextFieldInputProps: Partial<InputProps> = {
    startAdornment: (
      <InputAdornment position="start">
        <SearchIcon />
      </InputAdornment>
    ),
    endAdornment: (
      <IconButton
        disabled={isEmpty(searchText)}
        onClick={() => {
          setSearchText('');
          debouncedSearch('');
        }}
      >
        <Clear />
      </IconButton>
    ),
  };

  const [viewMode, setViewMode] = useLocalStorageState<
    'None' | 'Inbound terminal' | 'Inbound terminal & date'
  >('demo-line-haul-view-mode', {
    defaultValue: 'None',
  });

  const columnDefinitionsGroup = useMemo(() => {
    if (viewMode === 'Inbound terminal') {
      return columnDefinitions.map((cd) =>
        cd.field === 'segment.startTerminal.code'
          ? {
              ...cd,
              rowGroup: true,
            }
          : cd,
      );
    }
    if (viewMode === 'Inbound terminal & date') {
      return columnDefinitions.map((cd) =>
        cd.field === 'segment.startTerminal.code' ||
        cd.field === LinehaulDispatchTableField.ServiceDate
          ? {
              ...cd,
              rowGroup: true,
            }
          : cd,
      );
    }
    return columnDefinitions;
  }, [columnDefinitions, viewMode]);

  const ConfigureHeadersButton = useCallback(
    () => (
      <Button
        variant="outlined"
        style={{
          borderRadius: '4px',
        }}
        onClick={() => {
          setShowConfigureHeaders(true);
        }}
      >
        Edit columns
      </Button>
    ),
    [setShowConfigureHeaders],
  );

  const [selectedOptionsMultiselect, setSelectedOptionsMultiselect] = useState<
    Option[] | null
  >([
    { value: 'onHand', label: 'On hand' },
    { value: 'inboundToday', label: 'Inbound today' },
  ]);

  // const groupRowRendererParams = useMemo(() => {
  //   return {
  //     innerRenderer: GroupRowInnerRenderer,
  //   };
  // }, []);

  return (
    <Grid
      item
      xs={12}
      sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
    >
      <Stack direction="row" gap={2} p={2} sx={{ flexWrap: 'wrap' }}>
        {showConfigureHeaders && !isNil(userUuid) && !isNil(tableHeaders) && (
          <ConfigureLinehaulDispatchTableColumns
            open={showConfigureHeaders}
            setOpen={setShowConfigureHeaders}
            userUuid={userUuid}
            tableFields={tableHeaders}
          />
        )}
        <TextField
          sx={{
            flex: 1,
            flexBasis: '180px',
            minWidth: '170px',
            maxWidth: '180px',
          }}
          className="dispatch-search-input"
          variant="standard"
          size="medium"
          placeholder="Search orders..."
          autoComplete="off"
          InputProps={TextFieldInputProps}
          value={searchText}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearch();
              debouncedSearch.cancel();
            }
          }}
          onChange={(e) => {
            setSearchText(e.target.value);
            debouncedSearch(e.target.value);
          }}
        />
        <MultiselectFilterButton
          isSmall
          selectedOptions={selectedOptionsMultiselect}
          options={[
            { value: 'onHand', label: 'On hand' },
            { value: 'inboundToday', label: 'Inbound today' },
            { value: 'inboundTomorrow', label: 'Inbound tomorrow' },
          ]}
          handleChange={(options) => {
            setSelectedOptionsMultiselect(options ?? null);
          }}
          prefixText="Status"
          filterLabel={
            // eslint-disable-next-line
            selectedOptionsMultiselect
              ?.map((option) => option.label)
              .join(', ') || 'All'
          }
          cachePrefix="DEMO_LINE_HAUL"
          cacheId="status"
        />
        <TerminalFilterButton
          isSmall
          prefixText="From"
          selectedOption={stateRef.current.startTerminalOption}
          handleChange={handleStartTerminalChange}
          includeInactiveTerminals={false}
        />
        <TerminalFilterButton
          isSmall
          prefixText="To"
          selectedOption={stateRef.current.endTerminalOption}
          handleChange={handleEndTerminalChange}
          includeInactiveTerminals={false}
        />
        <Box>
          <DateDropdownPicker
            small
            allowNoLimit
            filterTitle="Service Date"
            dateOption={stateRef.current.serviceDateOption}
            setDateOption={(option: DateOption) => {
              setState((prevState) => {
                return {
                  ...prevState,
                  serviceDateOption: option,
                };
              });
            }}
            showFilters={[
              DatePickerFilterType.AllSelect,
              DatePickerFilterType.PastDayInput,
              DatePickerFilterType.DayPaginate,
              DatePickerFilterType.Range,
            ]}
          />
        </Box>
        {canAddOrderToManifest(openedManifest, currentManifestTab) && (
          <Box>
            <Button
              variant="contained"
              disabled={selectedUuids.length <= 0}
              sx={{ padding: '5.5px 8.25px', borderRadius: '8px' }}
              onClick={() => {
                bulkAddOrders();
              }}
            >
              Add Orders ({selectedUuids.length})
            </Button>
          </Box>
        )}
      </Stack>
      <Stack direction="row" gap={2} px={2} pb={2}>
        <Select
          style={{ minWidth: '235px', height: '34px' }}
          value={viewMode}
          renderValue={(value) => (
            <span style={{ fontSize: '15px' }}>
              Group by: <b>{value}</b>
            </span>
          )}
          onChange={(e) => {
            setViewMode(
              e.target.value as
                | 'None'
                | 'Inbound terminal'
                | 'Inbound terminal & date',
            );
          }}
        >
          <MenuItem id="None" value="None">
            None
          </MenuItem>
          <MenuItem id="Inbound terminal" value="Inbound terminal">
            Inbound terminal
          </MenuItem>
          <MenuItem
            id="Inbound terminal & date"
            value="Inbound terminal & date"
          >
            Inbound terminal & date
          </MenuItem>
        </Select>
      </Stack>
      {!stateRef.current.hasPageLoadedBeforeForPageChange && (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </div>
      )}
      <div
        className="ag-theme-material ag-non-compact"
        style={{
          flex: 1,
          width: '100%',
          visibility: stateRef.current.hasPageLoadedBeforeForPageChange
            ? 'visible'
            : 'hidden',
        }}
      >
        <AgGridReact<SegmentedLineHaulOrder>
          key={viewMode}
          ref={gridRef}
          pagination
          rowMultiSelectWithClick
          suppressDragLeaveHidesColumns
          blockLoadDebounceMillis={100}
          rowData={rowData}
          columnDefs={columnDefinitionsGroup}
          className="line-haul-ag-grid"
          defaultColDef={defaultColDef}
          sideBar={sideBar}
          rowSelection="multiple"
          getRowId={(params) => params.data?.uuid}
          paginationPageSize={100}
          components={{
            ConfigureHeadersButton,
          }}
          statusBar={{
            statusPanels: [
              {
                statusPanel: 'ConfigureHeadersButton',
                align: 'left',
              },
            ],
          }}
          groupDisplayType="multipleColumns"
          onColumnMoved={handleColumnMoved}
          onRowSelected={(e) => {
            handleRowSelected(e.node);
          }}
          onSelectionChanged={handleSelectionChanged}
          onFilterChanged={handleFilterChanged}
          onSortChanged={handleSortChanged}
          onColumnResized={handleColumnResized}
          onColumnEverythingChanged={updateColumnWidthsOnGridChange}
          onPaginationChanged={onPaginationChangedHandler}
        />
      </div>
    </Grid>
  );
};
