import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { type PartialDeep } from 'type-fest';
import { type DeepStringified } from '../../../common/types';
import type { RootState } from '../../../redux/store';
import { type AddressFormField } from './addresses-values-slice';

export type AddressFormFieldError = DeepStringified<
  PartialDeep<Omit<AddressFormField, 'uuid'>> & { uuid: string }
>;
type AddressErrorsStoreState = AddressFormFieldError;

const addressesErrorsAdapter = createEntityAdapter<AddressErrorsStoreState>({
  selectId: (address) => address.uuid,
});

export const addressesErrorsSlice = createSlice({
  name: 'addressesErrors',
  initialState: addressesErrorsAdapter.getInitialState(),
  reducers: {
    addAddressErrors: addressesErrorsAdapter.addOne,
    upsertAddressErrors: addressesErrorsAdapter.upsertOne,
    deleteAddressErrors: addressesErrorsAdapter.removeOne,
    updateAddressesErrors: addressesErrorsAdapter.updateMany,
  },
});

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectById: selectAddressErrorsById,
  // Pass in a selector that returns the posts slice of state
} = addressesErrorsAdapter.getSelectors(
  (state: RootState) => state.orderFormAddressesErrors,
);

export const { upsertAddressErrors } = addressesErrorsSlice.actions;

export default addressesErrorsSlice.reducer;
