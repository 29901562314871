import { Button, Fade, TableCell, TableRow, Box } from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import { useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  type HeaderFileMappingType,
  PermissionResource,
  QuickbooksHeaderFileMappingsDocument,
  useDeleteQuickbooksHeaderFileMappingMutation,
} from '../../../generated/graphql';
import CreateOrEditQuickbooksHeader, {
  CreateOrEditQBHeader,
} from './create-or-edit-quickbooks-header';

const QuickbooksHeaderMappingRow = ({
  uuid,
  name,
  headerType,
}: {
  readonly uuid: string;
  readonly name: string;
  readonly headerType: HeaderFileMappingType;
}) => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteSettingsQuickbooks } = getPermissionsFlags(
    userPermissions,
    PermissionResource.SettingsQuickbooks,
  );

  const [editUuid, setEditUuid] = useState<string | null>(null);
  const [isHovering, setIsHovering] = useState(false);
  const [deleteQuickbooksHeader] = useDeleteQuickbooksHeaderFileMappingMutation(
    { refetchQueries: [QuickbooksHeaderFileMappingsDocument] },
  );
  return (
    <>
      {!isNil(editUuid) && (
        <CreateOrEditQuickbooksHeader
          open={!isNil(editUuid)}
          setOpen={(res: boolean) => {
            if (!res) {
              setEditUuid(null);
            }
          }}
          createOrEdit={CreateOrEditQBHeader.Edit}
          numberOfMappings={0}
          uuid={uuid}
          headerName={name}
          headerType={headerType}
        />
      )}
      <TableRow
        key={uuid}
        onMouseEnter={() => {
          setIsHovering(true);
        }}
        onMouseLeave={() => {
          setIsHovering(false);
        }}
      >
        <TableCell>{name}</TableCell>
        <TableCell>{sentenceCase(headerType)}</TableCell>
        <TableCell>
          <Fade in={isHovering}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
              <Button
                variant="contained"
                disabled={!canWriteSettingsQuickbooks}
                onClick={async () =>
                  deleteQuickbooksHeader({ variables: { uuid } })
                }
              >
                Delete
              </Button>
              <Button
                variant="contained"
                disabled={!canWriteSettingsQuickbooks}
                onClick={() => {
                  setEditUuid(uuid);
                }}
              >
                Edit
              </Button>
            </Box>
          </Fade>
        </TableCell>
      </TableRow>
    </>
  );
};

export default QuickbooksHeaderMappingRow;
