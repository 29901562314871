import { Box, Button, Fade, TableCell, TableRow } from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import { useAllowOpenInNewTab } from '../../../common/react-hooks/use-allow-open-in-new-tab';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  formatDateWithTime,
  formatOrderSource,
} from '../../../common/utils/prettyPrintUtils';
import {
  type OrderSource,
  PermissionResource,
  ScannedOrderFailureReason,
  ScannedOrderResultStatus,
  useUpdateScannedOrderResultStatusMutation,
} from '../../../generated/graphql';

// eslint-disable-next-line consistent-return
const canViewCompletedOrder = (status: ScannedOrderResultStatus): boolean => {
  // eslint-disable-next-line default-case
  switch (status) {
    case ScannedOrderResultStatus.Attached:
    case ScannedOrderResultStatus.Duplicate:
    case ScannedOrderResultStatus.Failed:
    case ScannedOrderResultStatus.InProgress:
    case ScannedOrderResultStatus.InReview:
    case ScannedOrderResultStatus.Rejected: {
      return false;
    }
    case ScannedOrderResultStatus.Approved:
    case ScannedOrderResultStatus.ManuallyEntered: {
      return true;
    }
  }
};

const getFailureReasonString = (
  failureReason: ScannedOrderFailureReason,
  // eslint-disable-next-line consistent-return
): string => {
  // eslint-disable-next-line default-case
  switch (failureReason) {
    case ScannedOrderFailureReason.InvalidPageSize: {
      return 'Invalid page size. Use Letter';
    }
    case ScannedOrderFailureReason.InvalidPageSizeA0:
    case ScannedOrderFailureReason.InvalidPageSizeA1:
    case ScannedOrderFailureReason.InvalidPageSizeA2:
    case ScannedOrderFailureReason.InvalidPageSizeA3:
    case ScannedOrderFailureReason.InvalidPageSizeA4:
    case ScannedOrderFailureReason.InvalidPageSizeA5:
    case ScannedOrderFailureReason.InvalidPageSizeA6:
    case ScannedOrderFailureReason.InvalidPageSizeA7:
    case ScannedOrderFailureReason.InvalidPageSizeA8:
    case ScannedOrderFailureReason.InvalidPageSizeA9:
    case ScannedOrderFailureReason.InvalidPageSizeA10:
    case ScannedOrderFailureReason.InvalidPageSizeB0:
    case ScannedOrderFailureReason.InvalidPageSizeB1:
    case ScannedOrderFailureReason.InvalidPageSizeB2:
    case ScannedOrderFailureReason.InvalidPageSizeB3:
    case ScannedOrderFailureReason.InvalidPageSizeB4:
    case ScannedOrderFailureReason.InvalidPageSizeB5:
    case ScannedOrderFailureReason.InvalidPageSizeB6:
    case ScannedOrderFailureReason.InvalidPageSizeB7:
    case ScannedOrderFailureReason.InvalidPageSizeB8:
    case ScannedOrderFailureReason.InvalidPageSizeB9:
    case ScannedOrderFailureReason.InvalidPageSizeB10:
    case ScannedOrderFailureReason.InvalidPageSizeC0:
    case ScannedOrderFailureReason.InvalidPageSizeC1:
    case ScannedOrderFailureReason.InvalidPageSizeC2:
    case ScannedOrderFailureReason.InvalidPageSizeC3:
    case ScannedOrderFailureReason.InvalidPageSizeC4:
    case ScannedOrderFailureReason.InvalidPageSizeC5:
    case ScannedOrderFailureReason.InvalidPageSizeC6:
    case ScannedOrderFailureReason.InvalidPageSizeC7:
    case ScannedOrderFailureReason.InvalidPageSizeC8:
    case ScannedOrderFailureReason.InvalidPageSizeC9:
    case ScannedOrderFailureReason.InvalidPageSizeC10:
    case ScannedOrderFailureReason.InvalidPageSizeJuniorLegal:
    case ScannedOrderFailureReason.InvalidPageSizeLegal:
    case ScannedOrderFailureReason.InvalidPageSizeLetter:
    case ScannedOrderFailureReason.InvalidPageSizeTabloid: {
      const failureReasonString = failureReason.replace(
        'INVALID_PAGE_SIZE_',
        '',
      );
      return `Invalid page size: ${sentenceCase(
        failureReasonString,
      )}. Use Letter`;
    }
    case ScannedOrderFailureReason.InvalidFiletype: {
      return 'Invalid file type';
    }
    case ScannedOrderFailureReason.LowDocumentQuality: {
      return 'Low document quality';
    }
    case ScannedOrderFailureReason.Unknown: {
      return 'Unknown';
    }
    case ScannedOrderFailureReason.UnrecognizedDocument: {
      return 'Unsupported document';
    }
    case ScannedOrderFailureReason.NoHawbExtracted: {
      return 'No HAWB found';
    }
    case ScannedOrderFailureReason.TooManyPages: {
      return 'Too many pages (max 15)';
    }
  }
};

const getStatusString = (
  status: ScannedOrderResultStatus,
  failureReason: ScannedOrderFailureReason | null | undefined,
  duplicateOrderSource: OrderSource | null | undefined,
  // eslint-disable-next-line consistent-return
): string => {
  // eslint-disable-next-line default-case
  switch (status) {
    case ScannedOrderResultStatus.Duplicate: {
      return `Duplicate ${formatOrderSource(
        duplicateOrderSource ?? undefined,
      )}`;
    }
    case ScannedOrderResultStatus.InProgress: {
      return 'Loading';
    }
    case ScannedOrderResultStatus.Approved: {
      return sentenceCase(status);
    }
    case ScannedOrderResultStatus.Failed: {
      return `${sentenceCase(status)} ${
        isNil(failureReason) ? '' : `(${getFailureReasonString(failureReason)})`
      }`;
    }
    case ScannedOrderResultStatus.Attached: {
      return sentenceCase(status);
    }
    case ScannedOrderResultStatus.InReview: {
      return sentenceCase(status);
    }
    case ScannedOrderResultStatus.ManuallyEntered: {
      return sentenceCase(status);
    }
    case ScannedOrderResultStatus.Rejected: {
      return sentenceCase(status);
    }
  }
};

const ScannedOrderRow = ({
  billingPartyContact,
  date,
  duplicateIsDocScan,
  duplicateOrderUuid,
  duplicateOrderSource,
  failureReason,
  filename,
  orderUuid,
  pageStart,
  pageEnd,
  scannedOrderResultUuid,
  status,
  shipperBillOfLadingNumber,
}: {
  readonly billingPartyContact: string | null | undefined;
  readonly date: Date;
  readonly duplicateIsDocScan: boolean;
  readonly duplicateOrderUuid: string | null | undefined;
  readonly duplicateOrderSource: OrderSource | null | undefined;
  readonly failureReason: ScannedOrderFailureReason | null | undefined;
  readonly filename: string;
  readonly orderUuid: string | null | undefined;
  readonly shipperBillOfLadingNumber: string | null | undefined;
  readonly pageStart: number;
  readonly pageEnd: number;
  readonly scannedOrderResultUuid: string;
  readonly status: ScannedOrderResultStatus;
}) => {
  const { userPermissions } = useUserRoles();

  const { canWrite: canWriteScannedOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.ScannedOrders,
  );

  const { allowOpenInNewTab } = useAllowOpenInNewTab();
  const [showButtons, setShowButtons] = useState(false);
  const emptyState =
    status === ScannedOrderResultStatus.InProgress ? 'Loading' : 'None';

  const pages =
    pageStart === pageEnd ? pageStart + 1 : `${pageStart + 1}-${pageEnd + 1}`;

  const [updateScannedOrderResultStatus] =
    useUpdateScannedOrderResultStatusMutation();

  return (
    <TableRow
      key={scannedOrderResultUuid}
      onMouseEnter={() => {
        setShowButtons(true);
      }}
      onMouseLeave={() => {
        setShowButtons(false);
      }}
    >
      <TableCell key="date" sx={{ border: 'none' }}>
        <Box>{formatDateWithTime(date)}</Box>
      </TableCell>
      <TableCell key="filename" sx={{ border: 'none' }}>
        <Box>
          {filename} ({pages})
        </Box>
      </TableCell>
      <TableCell key="status" sx={{ border: 'none' }}>
        <Box>{shipperBillOfLadingNumber}</Box>
      </TableCell>
      <TableCell key="billingPartyContact" sx={{ border: 'none' }}>
        <Box>{billingPartyContact ?? emptyState}</Box>
      </TableCell>
      <TableCell key="status" sx={{ border: 'none' }}>
        <Box>
          {getStatusString(status, failureReason, duplicateOrderSource)}
        </Box>
      </TableCell>
      <TableCell key="buttons" sx={{ width: '5%', border: 'none' }}>
        <Fade in={showButtons}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: '5px',
              // Consistent height whether or not buttons are shown
              minHeight: '36px',
            }}
          >
            {!isNil(orderUuid) &&
              status === ScannedOrderResultStatus.InReview && (
                <Button
                  sx={{ marginRight: '5px' }}
                  variant="contained"
                  disabled={!canWriteScannedOrders}
                  onClick={(e) => {
                    allowOpenInNewTab(
                      e,
                      `/orders/?orderUuid=${orderUuid}&isDocumentScan=true`,
                    );
                  }}
                >
                  Review
                </Button>
              )}
            {status === ScannedOrderResultStatus.Failed && (
              <>
                <Button
                  sx={{ marginRight: '5px', width: '135px' }}
                  variant="contained"
                  disabled={!canWriteScannedOrders}
                  onClick={(e) => {
                    allowOpenInNewTab(
                      e,
                      `/order-entry/add?scannedOrderResultUuid=${scannedOrderResultUuid}&enterManuallyFromDocScan=true`,
                    );
                  }}
                >
                  Enter manually
                </Button>
                <Button
                  sx={{ marginRight: '5px', width: '135px' }}
                  variant="contained"
                  disabled={!canWriteScannedOrders}
                  onClick={async () => {
                    if (!isNil(scannedOrderResultUuid)) {
                      await updateScannedOrderResultStatus({
                        variables: {
                          updateScannedOrderResultStatusInput: {
                            scannedOrderResultUuid,
                            status: ScannedOrderResultStatus.Rejected,
                          },
                        },
                      });
                    }
                  }}
                >
                  Delete
                </Button>
              </>
            )}
            {canViewCompletedOrder(status) && (
              <Button
                sx={{ marginRight: '5px' }}
                variant="contained"
                disabled={!canWriteScannedOrders}
                onClick={(e) => {
                  allowOpenInNewTab(
                    e,
                    `/orders/?orderUuid=${orderUuid}&isDocumentScan=true`,
                  );
                }}
              >
                View
              </Button>
            )}
            {status === ScannedOrderResultStatus.Duplicate &&
              !isNil(duplicateOrderUuid) && (
                <Button
                  color="secondary"
                  sx={{
                    marginRight: '5px',
                    width: '130px',
                  }}
                  variant="contained"
                  disabled={!canWriteScannedOrders}
                  onClick={(e) => {
                    const url = duplicateIsDocScan
                      ? `/orders/?orderUuid=${duplicateOrderUuid}&isDocumentScan=true`
                      : `/orders/?orderUuid=${duplicateOrderUuid}`;
                    allowOpenInNewTab(e, url);
                  }}
                >
                  View Existing
                </Button>
              )}
            {status === ScannedOrderResultStatus.Duplicate && (
              <Button
                sx={{ marginRight: '5px', width: '130px' }}
                variant="contained"
                disabled={!canWriteScannedOrders}
                onClick={(e) => {
                  allowOpenInNewTab(
                    e,
                    `/orders/?orderUuid=${orderUuid}&isDocumentScan=true`,
                  );
                }}
              >
                View Scan
              </Button>
            )}
          </Box>
        </Fade>
      </TableCell>
    </TableRow>
  );
};

export default ScannedOrderRow;
