import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import {
  Alert,
  Box,
  Button,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  Modal,
  Snackbar,
  Stack,
  type SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { sentenceCase } from 'change-case';
import { isEmpty, isNil } from 'lodash';
import React, {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { filterNotNil } from 'shared/array';
import useContacts from '../../../../../../../common/react-hooks/use-contacts';
import useMe from '../../../../../../../common/react-hooks/use-me';
import useServices from '../../../../../../../common/react-hooks/use-services';
import useTerminals from '../../../../../../../common/react-hooks/use-terminals';
import {
  type CoverSheetDocumentTypeSequenceFragment,
  CoverSheetDocumentTypeSequenceType,
  type DocumentType,
  type OrderEventForCoverSheetFragment,
  OrderEventSourceType,
  OrderEventType,
  useCoverSheetDocumentTypeSequencesLazyQuery,
  useCreateCoverSheetDocumentTypeSequenceMutation,
  useOrderInfosForCoverSheetsLazyQuery,
  useNameOfRouteLazyQuery,
  useUpdateCoverSheetDocumentTypeSequenceMutation,
} from '../../../../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import GeneratedOrderCoverSheetPdf from '../../../../../../generated-documents/components/generated-order-cover-sheet-pdf';
import {
  convertOrderFormValuesToCoverSheetData,
  type OrderCoverSheetData,
} from '../../../../../../generated-documents/utils';
import DocumentTypeSequenceEditor from '../../../../../graphql/cover-sheet/document-type-sequence-editor';
import EditDocumentTypeSequenceModal from '../../../../../graphql/cover-sheet/edit-document-type-sequence-modal';
import {
  INBOUND_STOP_IDX,
  OUTBOUND_STOP_IDX,
} from '../../../../order-form/components/constants';
import {
  type OrderFormValues,
  type StopValues,
} from '../../../../order-form/forms/types';

const styles = {
  shipmentBillOfLadingModal: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1300px',
    bgcolor: 'background.paper',
    padding: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  } as SxProps,
  center: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  } as SxProps,
};

const ADD_NEW_SEQUENCE_OPTION = 'Add new sequence';

const PrintOrderCoverSheetModalNew = ({
  orderUuid,
  isOpen,
  setIsOpen,
  isEditMode,
}: {
  readonly orderUuid: string | null | undefined;
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly isEditMode: boolean;
}) => {
  const { segment, companyData, user } = useMe();
  const { getServiceName } = useServices();
  const { getContactName } = useContacts();
  const { getTerminalCode, terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const [getOrderInfosForCoverSheets] = useOrderInfosForCoverSheetsLazyQuery();
  const [getRouteName] = useNameOfRouteLazyQuery();
  const [
    getCoverSheetDocumentTypeSequences,
    { data: coverSheetDocumentTypeSequencesData },
  ] = useCoverSheetDocumentTypeSequencesLazyQuery();
  const [createCoverSheetDocumentTypeSequence] =
    useCreateCoverSheetDocumentTypeSequenceMutation();
  const [updateCoverSheetDocumentTypeSequence] =
    useUpdateCoverSheetDocumentTypeSequenceMutation();
  const [
    selectedCoverSheetDocumentTypeSequence,
    setSelectedCoverSheetDocumentTypeSequence,
  ] = useState<CoverSheetDocumentTypeSequenceFragment | undefined>(undefined);
  const [documentTypes, setDocumentTypes] = useState<
    Array<DocumentType | undefined> | undefined
  >(undefined);
  const [forceRerenderDocumentTypeEditor, setForceRerenderDocumentTypeEditor] =
    useState<boolean>(false);
  const [
    coverSheetDocumentTypeSequenceToEdit,
    setCoverSheetDocumentTypeSequenceToEdit,
  ] = useState<CoverSheetDocumentTypeSequenceFragment | undefined>(undefined);
  const [
    addDocumentTypeSequenceModalOpen,
    setAddDocumentTypeSequenceModalOpen,
  ] = useState<boolean>(false);
  const [
    editDocumentTypeSequenceModalOpen,
    setEditDocumentTypeSequenceModalOpen,
  ] = useState<boolean>(false);
  const [showOrderCoverSheetError, setShowOrderCoverSheetError] =
    useState<boolean>(false);

  const [routeNameInbound, setRouteNameInbound] = useState<
    string | undefined
  >();
  const [routeNameOutbound, setRouteNameOutbound] = useState<
    string | undefined
  >();
  const [orderCreatedEvent, setOrderCreatedEvent] = useState<
    OrderEventForCoverSheetFragment | null | undefined
  >();

  const { getValues } = useFormContext<OrderFormValues>();
  const order = getValues();
  const stopRouteUuids =
    order.stops?.map((stop: StopValues) => stop.routeUuid) ?? [];
  const inboundUuid = stopRouteUuids.at(INBOUND_STOP_IDX);
  const outboundUuid = stopRouteUuids.at(OUTBOUND_STOP_IDX);

  const orderForCoverSheet: OrderCoverSheetData = useMemo(
    () =>
      convertOrderFormValuesToCoverSheetData({
        order,
        getTerminalCode,
        getContactName,
        getServiceName,
        companyName: companyData?.name,
        companyAddress: companyData?.defaultAddress,
        terminalsEnabled,
        logisticsEnabled:
          companyData?.configuration?.logisticsSectionEnabled ?? false,
        routeNameInbound,
        routeNameOutbound,
        orderCreatedEvent,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isOpen,
      companyData,
      routeNameInbound,
      routeNameOutbound,
      orderCreatedEvent,
    ],
  );

  const getRouteNameInbound = async () => {
    if (!isNil(inboundUuid)) {
      const res = await getRouteName({ variables: { uuid: inboundUuid } });
      setRouteNameInbound(res.data?.route.name);
    }
  };

  const getRouteNameOutbound = async () => {
    if (!isNil(outboundUuid)) {
      const res = await getRouteName({ variables: { uuid: outboundUuid } });
      setRouteNameOutbound(res.data?.route.name);
    }
  };

  useEffect(() => {
    if (isOpen && isEditMode) {
      getRouteNameInbound();
      getRouteNameOutbound();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inboundUuid, outboundUuid]);

  useEffect(() => {
    if (isOpen) {
      if (isEditMode && !isNil(orderUuid)) {
        getOrderInfosForCoverSheets({
          variables: { uuids: [orderUuid] },
        }).then((res) => {
          if (isNil(res.error)) {
            const orderInfo = res.data?.ordersByUuids[0];
            const orderCoverSheetDocumentTypeSequence =
              orderInfo?.coverSheetDocumentTypeSequence;
            setSelectedCoverSheetDocumentTypeSequence(
              orderCoverSheetDocumentTypeSequence ?? undefined,
            );
            setDocumentTypes(
              orderCoverSheetDocumentTypeSequence?.documentTypes,
            );
            setOrderCreatedEvent(
              orderInfo?.orderEvents.find(
                (event) => event.eventType === OrderEventType.OrderCreated,
              ),
            );
          } else {
            setShowOrderCoverSheetError(true);
          }
        });
      } else if (!isEditMode) {
        setOrderCreatedEvent({
          eventType: OrderEventType.OrderCreated,
          sourceType: OrderEventSourceType.User,
          createdAt: new Date(),
          sourceUser: {
            email: user?.email ?? '',
          },
        });
      }
      getCoverSheetDocumentTypeSequences();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, user?.email]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  const [generatedPdf, setGeneratedPdf] = useState<JSX.Element>(<></>);
  useEffect(() => {
    if (isOpen) {
      setGeneratedPdf(
        <GeneratedOrderCoverSheetPdf
          segment={segment}
          companyConfiguration={companyData?.configuration}
          order={orderForCoverSheet}
        />,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segment, orderForCoverSheet, isOpen]);

  const onDownload = async () => {
    if (isEditMode && !isNil(orderUuid) && !isNil(documentTypes)) {
      if (
        isNil(selectedCoverSheetDocumentTypeSequence) ||
        selectedCoverSheetDocumentTypeSequence?.type ===
          CoverSheetDocumentTypeSequenceType.Template
      ) {
        await createCoverSheetDocumentTypeSequence({
          variables: {
            input: {
              orderUuids: [orderUuid],
              name: selectedCoverSheetDocumentTypeSequence?.name ?? '',
              type: CoverSheetDocumentTypeSequenceType.Record,
              documentTypes: filterNotNil(documentTypes),
            },
          },
        });
      } else if (
        selectedCoverSheetDocumentTypeSequence.type ===
        CoverSheetDocumentTypeSequenceType.Record
      ) {
        await updateCoverSheetDocumentTypeSequence({
          variables: {
            input: {
              uuid: selectedCoverSheetDocumentTypeSequence.uuid,
              orderUuids: [orderUuid],
              documentTypes: filterNotNil(documentTypes),
            },
          },
        });
      }
    }
  };

  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <Box sx={styles.center}>
        {!isNil(orderForCoverSheet) && (
          <Box sx={styles.shipmentBillOfLadingModal}>
            <IconButton
              sx={{ padding: 0, float: 'right', mb: '10px', ml: 'auto' }}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
            <Grid container spacing={2}>
              <Grid item xs={isEditMode ? 8 : 12}>
                <PDFViewer
                  showToolbar
                  style={{ width: '100%', height: '75vh' }}
                >
                  {generatedPdf}
                </PDFViewer>
              </Grid>
              {isEditMode && (
                <Grid item xs={4}>
                  <Stack>
                    <AutocompleteFuzzy
                      size="small"
                      value={
                        isNil(selectedCoverSheetDocumentTypeSequence)
                          ? null
                          : {
                              value:
                                selectedCoverSheetDocumentTypeSequence.uuid,
                              label:
                                selectedCoverSheetDocumentTypeSequence.name,
                              documentTypes:
                                selectedCoverSheetDocumentTypeSequence.documentTypes,
                            }
                      }
                      options={
                        coverSheetDocumentTypeSequencesData?.coverSheetDocumentTypeSequences
                          .sort((a, b) => a.name.localeCompare(b.name))
                          ?.map((sequence) => ({
                            value: sequence.uuid,
                            label: sequence.name,
                            documentTypes: sequence.documentTypes,
                          })) ?? []
                      }
                      stickyOptionProps={{
                        stickyOption: {
                          value: ADD_NEW_SEQUENCE_OPTION,
                          label: `+ ${ADD_NEW_SEQUENCE_OPTION}`,
                          documentTypes: [],
                        },
                        filterStickyOption: false,
                        stickyOptionPosition: 'last',
                      }}
                      matchSortOptions={{ keys: ['label'] }}
                      getOptionLabel={(option) => option.label}
                      renderOption={(props, option) => {
                        return (
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          <li {...props} key={option.value}>
                            <Grid container alignItems="center" spacing={1}>
                              <Grid item xs={10}>
                                <Stack>
                                  <Typography sx={{ fontSize: '14px' }}>
                                    {option.label}
                                  </Typography>
                                  {!isEmpty(option.documentTypes) && (
                                    <Typography
                                      variant="caption"
                                      color="text.secondary"
                                    >
                                      {option.documentTypes
                                        .map((docType) => sentenceCase(docType))
                                        .join(', ')}
                                    </Typography>
                                  )}
                                </Stack>
                              </Grid>
                              <Grid item xs={2}>
                                {option.value !== ADD_NEW_SEQUENCE_OPTION && (
                                  <IconButton
                                    sx={{ float: 'right' }}
                                    onClick={(e) => {
                                      setCoverSheetDocumentTypeSequenceToEdit(
                                        coverSheetDocumentTypeSequencesData?.coverSheetDocumentTypeSequences.find(
                                          (sequence) =>
                                            sequence.uuid === option.value,
                                        ),
                                      );
                                      setEditDocumentTypeSequenceModalOpen(
                                        true,
                                      );
                                      e.stopPropagation();
                                    }}
                                  >
                                    <EditIcon sx={{ fontSize: '20px' }} />
                                  </IconButton>
                                )}
                              </Grid>
                            </Grid>
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="Paperwork included"
                        />
                      )}
                      onChange={(_, option) => {
                        if (option?.value === ADD_NEW_SEQUENCE_OPTION) {
                          setAddDocumentTypeSequenceModalOpen(true);
                        } else {
                          const templateDocumentTypeSequence =
                            coverSheetDocumentTypeSequencesData?.coverSheetDocumentTypeSequences.find(
                              (sequence) => sequence.uuid === option?.value,
                            );
                          setSelectedCoverSheetDocumentTypeSequence(
                            templateDocumentTypeSequence,
                          );
                          setDocumentTypes(
                            templateDocumentTypeSequence?.documentTypes,
                          );
                          setForceRerenderDocumentTypeEditor(true);
                        }
                      }}
                    />
                    <DocumentTypeSequenceEditor
                      documentTypes={documentTypes}
                      setDocumentTypes={setDocumentTypes}
                      forceRerender={forceRerenderDocumentTypeEditor}
                      setForceRerender={setForceRerenderDocumentTypeEditor}
                    />
                  </Stack>
                </Grid>
              )}
              <Button
                variant="contained"
                sx={{
                  ml: 'auto',
                  mr: 'auto',
                  position: 'absolute',
                  bottom: 20,
                  right: 20,
                }}
                onClick={onDownload}
              >
                <PDFDownloadLink
                  document={generatedPdf}
                  fileName={`order-cover-sheet-${
                    orderForCoverSheet.shipperBillOfLadingNumber ??
                    orderForCoverSheet.orderName
                  }.pdf`}
                >
                  {({ loading }) => (loading ? 'Loading...' : 'Download')}
                </PDFDownloadLink>
              </Button>
            </Grid>
          </Box>
        )}
        <EditDocumentTypeSequenceModal
          isCreate
          open={addDocumentTypeSequenceModalOpen}
          setOpen={setAddDocumentTypeSequenceModalOpen}
          onSave={() => {
            getCoverSheetDocumentTypeSequences();
          }}
        />
        <EditDocumentTypeSequenceModal
          open={editDocumentTypeSequenceModalOpen}
          setOpen={setEditDocumentTypeSequenceModalOpen}
          documentTypeSequence={coverSheetDocumentTypeSequenceToEdit}
          onSave={() => {
            getCoverSheetDocumentTypeSequences();
          }}
        />
        <Snackbar
          autoHideDuration={5000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={showOrderCoverSheetError}
          onClose={() => {
            setShowOrderCoverSheetError(false);
          }}
        >
          <Alert severity="error">
            Unable to generate order cover sheet - please contact support.
          </Alert>
        </Snackbar>
      </Box>
    </Modal>
  );
};

export default PrintOrderCoverSheetModalNew;
