import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isNotNilOrEmptyString } from 'shared/string';
import { shallow } from 'zustand/shallow';
import useMe from '../../../../../common/react-hooks/use-me';
import {
  Segment,
  type IngestedEmailOrdersQuery,
} from '../../../../../generated/graphql';
import useIngestedEmailsStore from '../ingested-emails-store';
import ReviewEmailOrdersTablePagination from './table-pagination';

const useStyles = () => ({
  headerRow: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
});

type ReviewEmailOrdersTableRowProps = {
  readonly ingestedEmailOrder: IngestedEmailOrdersQuery['scannedOrderResults']['edges'][number]['node'];
  readonly rowIsSelected: boolean;
  readonly selectRow: () => void;
};

const ReviewEmailOrdersCollapsedTableRow = ({
  ingestedEmailOrder,
  rowIsSelected,
  selectRow,
}: ReviewEmailOrdersTableRowProps) => {
  const [isHovering, setIsHovering] = useState<boolean>(false);

  return (
    <TableRow
      sx={[
        isHovering && { backgroundColor: 'lightgray', cursor: 'pointer' },
        rowIsSelected && { cursor: 'default', backgroundColor: '#FFE0B2' },
      ]}
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      onClick={() => {
        if (!rowIsSelected) selectRow();
      }}
    >
      <TableCell>
        {
          ingestedEmailOrder?.order?.standardOrderFields
            .shipperBillOfLadingNumber
        }
      </TableCell>
    </TableRow>
  );
};

const ReviewEmailOrdersCollapsedTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { segment } = useMe();

  const [selectedOrderUuid, setSelectedOrderUuid, ingestedEmailOrdersData] =
    useIngestedEmailsStore(
      (state) => [
        state.selectedOrderUuid,
        state.setSelectedOrderUuid,
        state.ingestedEmailOrdersData,
      ],
      shallow,
    );

  const orderUuid = searchParams.get('orderUuid');

  const styles = useStyles();

  useEffect(() => {
    if (isNotNilOrEmptyString(orderUuid)) {
      setSelectedOrderUuid(orderUuid);
      setSearchParams((sp) => {
        const newParams = new URLSearchParams(sp);
        newParams.set('orderUuid', orderUuid);
        return newParams;
      });
    }
  }, [orderUuid, setSearchParams, setSelectedOrderUuid]);

  const inputFieldString = segment === Segment.Cartage ? 'HAWB' : 'Pro #';

  const scannedOrderResultsToReview =
    ingestedEmailOrdersData?.scannedOrderResults.edges.map(
      ({ node: order }) => order,
    );

  return (
    <TableContainer sx={{ margin: '0!important' }}>
      <Table
        stickyHeader
        padding="normal"
        aria-label="freight-snap-preview-table"
        sx={{
          overflowY: 'scroll',
          borderTop: '1px solid #e0e0e0',
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell sx={styles.headerRow}>{inputFieldString}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {scannedOrderResultsToReview?.map((scannedOrderResult) => (
            <ReviewEmailOrdersCollapsedTableRow
              key={scannedOrderResult.uuid}
              ingestedEmailOrder={scannedOrderResult}
              rowIsSelected={
                selectedOrderUuid === scannedOrderResult.order?.uuid
              }
              selectRow={() => {
                const { order } = scannedOrderResult;
                if (!isNil(order)) {
                  setSelectedOrderUuid(order.uuid);
                  setSearchParams((sp) => {
                    const newParams = new URLSearchParams(sp);
                    newParams.set('orderUuid', order.uuid);
                    return newParams;
                  });
                }
              }}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ReviewEmailOrdersSidebar = () => {
  const [setShowReportADocScanIssueDialog] = useIngestedEmailsStore(
    (state) => [state.setShowReportADocScanIssueDialog],
    shallow,
  );

  return (
    <Box
      sx={{
        minWidth: 'max-content',
        maxWidth: '200px',
        flexShrink: 0,
        // right border grey
        borderRight: '1px solid #e0e0e0',
        position: 'relative',
        overflowY: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignSelf: 'stretch',
        maxHeight: '100%',
      }}
    >
      <Stack spacing={2} sx={{ flexShrink: 1, pt: 2 }}>
        <Stack sx={{ pl: 2, pr: 0.5 }}>
          <Typography variant="h6" sx={{ alignSelf: 'start' }}>
            Email Orders
          </Typography>
          <ReviewEmailOrdersTablePagination stretchWidth />
        </Stack>
        <ReviewEmailOrdersCollapsedTable />
      </Stack>
      <Button
        variant="outlined"
        sx={{
          borderRadius: '4px',
          color: '#EF5350',
          m: 1,
          backgroundColor: 'white',
        }}
        onClick={() => {
          setShowReportADocScanIssueDialog(true);
        }}
      >
        Report an issue
      </Button>
    </Box>
  );
};

export default ReviewEmailOrdersSidebar;
