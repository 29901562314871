import { Clear } from '@mui/icons-material';
import { Typography, Box, Button } from '@mui/material';
import { sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { useState } from 'react';
import { filterNotNil } from 'shared/array';
import { exhaustive } from 'shared/switch';
import {
  type DispatchDateFilterOption,
  DispatchFilterType,
} from '../../ag-grid/dispatch-stops/types';
import { AGGridFilterType } from './enums/order-filters';

const useStyles = () => {
  return {
    filterTitle: {
      fontSize: '14px',
      ml: '3px',
    },
    filterValue: {
      fontSize: '14px',
      ml: '5px',
      fontWeight: 'bold',
    },
  };
};

const filterValueToString = (
  filterValue: DispatchDateFilterOption | string,
) => {
  if (typeof filterValue === 'string') {
    return filterValue;
  }
  switch (filterValue.filterType) {
    case DispatchFilterType.FIXED: {
      return filterValue.fixedDateOption;
    }
    case DispatchFilterType.RELATIVE: {
      return filterValue.relativeDateOption;
    }
    default: {
      return exhaustive(filterValue);
    }
  }
};

export type FilterButtonForToolbarProps = {
  readonly field: string;
  readonly isDefaultFilter: boolean;
  readonly prependText: string | undefined;
  readonly filterType: AGGridFilterType;
  readonly filterValues: Array<string | DispatchDateFilterOption>;
  readonly handleDelete: () => void;
  readonly handleSelect: () => void;
  readonly delimiter?: string;
  readonly dateRangeStart?: Date;
  readonly dateRangeEnd?: Date;
  readonly filterName?: string;
};

const FilterButtonForToolbar = ({
  field,
  isDefaultFilter,
  prependText,
  filterType,
  filterValues,
  handleDelete,
  handleSelect,
  delimiter = ', ',
  dateRangeStart,
  dateRangeEnd,
  filterName,
}: FilterButtonForToolbarProps) => {
  const styles = useStyles();
  const [hoverOnClear, setHoverOnClear] = useState(false);

  let textToShow = '';
  textToShow =
    filterType === AGGridFilterType.DATE &&
    !isNil(dateRangeStart) &&
    !isNil(dateRangeEnd)
      ? `${dayjs(dateRangeStart).format('MM/DD/YYYY')} - ${dayjs(
          dateRangeEnd,
        ).format('MM/DD/YYYY')}`
      : filterNotNil(filterValues).map(filterValueToString).join(delimiter);

  return (
    <Button
      ref={null}
      endIcon={
        isDefaultFilter ? null : (
          <Clear
            fontSize="small"
            sx={{
              color: hoverOnClear ? 'black' : 'gray',
              ml: 0,
            }}
            onMouseEnter={() => {
              setHoverOnClear(true);
            }}
            onMouseLeave={() => {
              setHoverOnClear(false);
            }}
            onClick={(e) => {
              handleDelete();
              e.stopPropagation();
            }}
          />
        )
      }
      size="small"
      sx={{
        opacity: isDefaultFilter ? '75%' : '100%',
        cursor: isDefaultFilter ? 'default' : 'pointer',
      }}
      variant="outlined"
      onClick={isDefaultFilter ? () => {} : handleSelect}
    >
      <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}>
        {prependText !== undefined && (
          <Typography>{sentenceCase(prependText)}</Typography>
        )}
        <Typography sx={styles.filterTitle}>
          {prependText === undefined
            ? sentenceCase(filterName ?? field)
            : (filterName ?? field).toLowerCase()}
          :
        </Typography>
        <Typography sx={styles.filterValue}>{textToShow}</Typography>
      </Box>
    </Button>
  );
};

export default FilterButtonForToolbar;
