import {
  FormControl,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Stack,
  InputLabel,
} from '@mui/material';
import { isArray, isNil } from 'lodash';
import React from 'react';
import useTags from '../react-hooks/use-tags';
import { Size } from '../types';
import { ColoredChip } from './colored-chip';

const TagsDropdownField = ({
  tagUuids,
  onChange,
  size = Size.sm,
  disabled,
  showLabel = true,
}: {
  readonly tagUuids: string[] | undefined | null;
  readonly onChange: (tagUuids: string[]) => void;
  readonly size?: Size;
  readonly disabled?: boolean;
  readonly showLabel?: boolean;
}) => {
  const { tags, getTag } = useTags();

  const handleChange = (event: SelectChangeEvent) => {
    if (typeof event.target.value !== 'string') {
      onChange(event.target.value);
    }
  };

  return (
    <FormControl sx={{ minWidth: 100, height: '100%' }}>
      {showLabel && (
        <InputLabel
          sx={{
            zIndex: 0, // input label z index is too high(overlaps other modals)
          }}
        >
          Tags
        </InputLabel>
      )}
      <Select
        multiple
        size={size === Size.xs ? Size.sm : size}
        label={showLabel && 'Tags'}
        disabled={disabled}
        sx={{
          '& .MuiSelect-select': {
            height: 'auto !important',
          },
        }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value={tagUuids ?? []}
        renderValue={(selectedTagUuids) => {
          if (isArray(selectedTagUuids)) {
            return (
              <Stack direction="row" spacing={0.5} sx={{ height: '100%' }}>
                {selectedTagUuids.map((uuid) => {
                  const tag = getTag(uuid);
                  if (isNil(tag)) return null;
                  return (
                    <ColoredChip
                      key={uuid}
                      size={size}
                      color={tag.color}
                      label={tag?.value}
                    />
                  );
                })}
              </Stack>
            );
          }
          // eslint-disable-next-line react/jsx-no-useless-fragment
          return <></>;
        }}
        onChange={handleChange}
      >
        {tags?.map((tag) => (
          <MenuItem key={tag.uuid} value={tag.uuid}>
            {tag.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TagsDropdownField;
