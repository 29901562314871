// eslint-disable-next-line no-restricted-imports
import { Grid } from '@mui/material';
import { isNil } from 'lodash';
import React from 'react';
import {
  type BatchAddShipmentsToInvoicesPreviewFragment,
  type BatchAddShipmentsToInvoicesResultInvoiceFragment,
} from '../../../../generated/graphql';
import ContactResultCard from './contact-result-card';

const BatchInvoicesPreview = ({
  batchAddShipmentsToInvoicesPreview,
  postedInvoicesByContactUuid,
}: {
  readonly batchAddShipmentsToInvoicesPreview:
    | BatchAddShipmentsToInvoicesPreviewFragment[]
    | undefined;
  readonly postedInvoicesByContactUuid:
    | Record<string, BatchAddShipmentsToInvoicesResultInvoiceFragment[]>
    | undefined;
}) => {
  return (
    <Grid container sx={{ height: '100%' }}>
      <Grid item xs={12} sx={{ height: '70vh', overflowY: 'scroll' }}>
        {!isNil(postedInvoicesByContactUuid) &&
          Object.entries(postedInvoicesByContactUuid).map(
            ([contactUuid, invoices]) => (
              <ContactResultCard
                key={contactUuid}
                invoices={invoices}
                existingInvoiceAdds={
                  batchAddShipmentsToInvoicesPreview?.find(
                    (preview) => preview.contactUuid === contactUuid,
                  )?.existingInvoiceAdds
                }
              />
            ),
          )}
      </Grid>
    </Grid>
  );
};

export default BatchInvoicesPreview;
