import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useReportADocScanIssueMutation } from '../../../generated/graphql';
import { type OrderFormValues } from './order-form/forms/types';

type ReportDocScanIssueDialogProps = {
  readonly onConfirm: () => void;
  readonly onCancel: () => void;
  readonly open: boolean;
  readonly scannedOrderResultUuid: string;
};

const ReportDocScanIssueDialog = ({
  onCancel,
  onConfirm,
  open,
  scannedOrderResultUuid,
}: ReportDocScanIssueDialogProps) => {
  const [reportADocScanIssue, { loading }] = useReportADocScanIssueMutation();
  const [message, setMessage] = useState('');

  const { getValues } = useFormContext<OrderFormValues>();

  const handleConfirm = async () => {
    await reportADocScanIssue({
      variables: {
        reportADocScanIssueInput: {
          scannedOrderResultUuid,
          message,
          orderStateJsonBlob: JSON.stringify(getValues()),
        },
      },
    });
    setMessage('');
    onConfirm();
  };

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          Leave feedback
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Typography>
            Please leave any feedback you have about this document scan or
            document scanning in general.
          </Typography>
        </Box>
        <Box>
          <TextField
            multiline
            rows={4}
            value={message}
            sx={{ mt: '10px', width: '100%' }}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          sx={{ gap: 1, display: 'flex', justifyContent: 'flex-end' }}
        >
          <Button disabled={loading} variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            disabled={loading || message.length === 0}
            variant="contained"
            onClick={handleConfirm}
          >
            Send feedback
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ReportDocScanIssueDialog;
