import { Check } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import { MenuItem, MenuList, Stack, Tooltip, Typography } from '@mui/material';
import { sentenceCase } from 'change-case';
import { ReportRevenueType } from '../../../generated/graphql';
import useStyles from '../reports-styles';

const ReportFilterSingleSelectMenuList = ({
  selectedOption,
  options,
  handleChange,
}: {
  readonly selectedOption: string | undefined;
  readonly options: string[];
  readonly handleChange: (option: string) => void;
}) => {
  const styles = useStyles();
  return (
    <MenuList
      dense
      sx={{
        p: 0,
      }}
    >
      {options.map((option) => (
        <MenuItem
          key={option}
          sx={{
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'visible',
            pl: '10px',
          }}
          onClick={() => {
            handleChange(option);
          }}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Check
              sx={{
                visibility: selectedOption === option ? undefined : 'hidden',
                fontSize: '14px',
                ml: 0,
                mr: '6px',
              }}
            />
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography sx={styles.menuText}>
                {sentenceCase(option)}
              </Typography>
              {option === ReportRevenueType.Projected && (
                <Tooltip title="Revenue for all orders that are not on hold, grouped by service date">
                  <InfoIcon sx={{ fontSize: 14 }} color="info" />
                </Tooltip>
              )}
              {option === ReportRevenueType.Earned && (
                <Tooltip title="Revenue for orders that have been completed">
                  <InfoIcon sx={{ fontSize: 14 }} color="info" />
                </Tooltip>
              )}
            </Stack>
          </Stack>
        </MenuItem>
      ))}
    </MenuList>
  );
};

export default ReportFilterSingleSelectMenuList;
