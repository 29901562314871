import {
  Alert,
  Button,
  Snackbar,
  Stack,
  type SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import type React from 'react';
import { useState } from 'react';
import { type Option } from '../../../../common/filters/types';
import useWarehouses from '../../../../common/react-hooks/use-warehouses';
import {
  useDeleteUnusedWarehouseLocationsMutation,
  useImportWarehouseLocationsFromCsvMutation,
  useWarehouseLocationsQuery,
  WarehouseLocationsDocument,
  type WarehouseLocationsLocationFragment,
} from '../../../../generated/graphql';
import WarehouseSelector from '../../../storage-orders/components/common/warehouse-selector';
import WarehouseLocationLabelsModal from '../../modals/warehouse-location-labels-modal';
import ConfirmDeleteWarehouseLocationModal from './confirm-delete-warehouse-location-modal';
import WarehouseLocationModal from './warehouse-location-modal';
import WarehouseLocationsA4Modal from './warehouse-locations-a4-modal';

const styles = {
  stackView: {
    display: 'flex',
  } as SxProps,
  warehouseEmployeeButton: {
    mx: 1.5,
  } as SxProps,
};

const WarehouseLocationsTable = () => {
  const [
    importWarehouseLocationsFromCsv,
    { loading: importWarehouseLocationsFromCsvLoading },
  ] = useImportWarehouseLocationsFromCsvMutation({
    refetchQueries: [WarehouseLocationsDocument],
  });

  const [deleteUnusedWarehouseLocations] =
    useDeleteUnusedWarehouseLocationsMutation({
      refetchQueries: [WarehouseLocationsDocument],
    });

  const confirm = useConfirm();
  const [selectedWarehouse, setSelectedWarehouse] = useState<Option | null>(
    null,
  );
  const { warehouses } = useWarehouses();
  const { data: warehouseLocationsData } = useWarehouseLocationsQuery({
    variables: { warehouseUuid: selectedWarehouse?.value },
    fetchPolicy: 'cache-and-network',
  });
  const warehouseLocations =
    warehouseLocationsData?.warehouseLocations?.warehouseLocations;

  const [warehouseLocationModalOpen, setWarehouseLocationModalOpen] =
    useState(false);

  const [selectedWarehouseLocation, setSelectedWarehouseLocation] = useState<
    WarehouseLocationsLocationFragment | undefined
  >();
  const [
    selectedWarehouseLocationsForLabels,
    setSelectedWarehouseLocationsForLabels,
  ] = useState<WarehouseLocationsLocationFragment[]>([]);

  const [
    warehouseLocationLabelsModalOpen,
    setWarehouseLocationsLabelModalOpen,
  ] = useState(false);

  const [warehouseLocationsA4ModalOpen, setWarehouseLocationsA4ModalOpen] =
    useState(false);

  const [
    deleteWarehouseLocationsModalOpen,
    setDeleteWarehouseLocationsModalOpen,
  ] = useState(false);

  const [successSnackbarVisible, setSuccessSnackbarVisible] = useState(false);
  const [errorSnackbarVisible, setErrorSnackbarVisible] = useState(false);

  const [deleteSuccessSnackbarVisible, setDeleteSuccessSnackbarVisible] =
    useState(false);
  const [deleteErrorSnackbarVisible, setDeleteErrorSnackbarVisible] =
    useState(false);
  const [
    deleteAllUnusedSuccessSnackbarVisible,
    setDeleteAllUnusedSuccessSnackbarVisible,
  ] = useState(false);
  const [
    deleteAllUnusedErrorSnackbarVisible,
    setDeleteAllUnusedErrorSnackbarVisible,
  ] = useState(false);
  const [uploadSuccessSnackbarVisible, setUploadSuccessSnackbarVisible] =
    useState(false);
  const [uploadErrorSnackbarVisible, setUploadErrorSnackbarVisible] =
    useState(false);

  const handleSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.addEventListener('load', async () => {
        const csvText = reader.result?.toString();
        if (isNil(csvText)) {
          return;
        }
        const res = await importWarehouseLocationsFromCsv({
          variables: {
            importWarehouseLocationsFromCsvInput: {
              csvText,
            },
          },
        });
        if (!isNil(res.errors)) {
          setUploadErrorSnackbarVisible(true);
          return;
        }
        setUploadSuccessSnackbarVisible(true);
      });
      reader.readAsText(file);
    } else {
      setUploadErrorSnackbarVisible(true);
    }
  };

  return (
    <Stack direction="column" alignItems="flex-end" sx={styles.stackView}>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={successSnackbarVisible}
        onClose={() => {
          setSuccessSnackbarVisible(false);
        }}
      >
        <Alert severity="success">Saved warehouse location</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={errorSnackbarVisible}
        onClose={() => {
          setErrorSnackbarVisible(false);
        }}
      >
        <Alert severity="error">Error saving warehouse location</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={deleteSuccessSnackbarVisible}
        onClose={() => {
          setDeleteSuccessSnackbarVisible(false);
        }}
      >
        <Alert severity="success">Deleted warehouse location</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={deleteErrorSnackbarVisible}
        onClose={() => {
          setDeleteErrorSnackbarVisible(false);
        }}
      >
        <Alert severity="error">Error deleting warehouse location</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={deleteAllUnusedSuccessSnackbarVisible}
        onClose={() => {
          setDeleteAllUnusedSuccessSnackbarVisible(false);
        }}
      >
        <Alert severity="success">Deleted unused warehouse locations</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={deleteAllUnusedErrorSnackbarVisible}
        onClose={() => {
          setDeleteAllUnusedErrorSnackbarVisible(false);
        }}
      >
        <Alert severity="error">
          Error deleting unused warehouse locations
        </Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={uploadSuccessSnackbarVisible}
        onClose={() => {
          setUploadSuccessSnackbarVisible(false);
        }}
      >
        <Alert severity="success">Uploaded warehouse locations</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={uploadErrorSnackbarVisible}
        onClose={() => {
          setUploadErrorSnackbarVisible(false);
        }}
      >
        <Alert severity="error">Error uploading warehouse locations</Alert>
      </Snackbar>
      <WarehouseLocationModal
        open={warehouseLocationModalOpen}
        setSuccessSnackbarVisible={setSuccessSnackbarVisible}
        setErrorSnackbarVisible={setErrorSnackbarVisible}
        selectedWarehouseLocation={selectedWarehouseLocation}
        setSelectedWarehouseLocation={setSelectedWarehouseLocation}
        onClose={() => {
          setWarehouseLocationModalOpen(false);
        }}
      />
      <Stack direction="row" gap={1}>
        <Button
          sx={{ marginBottom: '16px' }}
          variant="contained"
          size="small"
          color="secondary"
          onClick={() => {
            confirm({
              title: 'Delete all unused locations?',
              description:
                'This will delete all warehouse locations that are not assigned to any containers or inventory items',
              confirmationText: 'Delete',
            }).then(() => {
              deleteUnusedWarehouseLocations().then((res) => {
                if (!isNil(res.errors)) {
                  setDeleteAllUnusedErrorSnackbarVisible(true);
                  return;
                }
                setDeleteAllUnusedSuccessSnackbarVisible(true);
              });
            });
          }}
        >
          Delete all unused locations
        </Button>
        <Button
          sx={{ marginBottom: '16px' }}
          variant="contained"
          disabled={isNil(warehouseLocations) || isEmpty(warehouseLocations)}
          size="small"
          onClick={() => {
            if (isNil(warehouseLocations)) {
              return;
            }
            setSelectedWarehouseLocationsForLabels(warehouseLocations);
            setWarehouseLocationsA4ModalOpen(true);
          }}
        >
          Print A4 labels
        </Button>
        <Button
          sx={{ marginBottom: '16px' }}
          variant="contained"
          disabled={isNil(warehouseLocations) || isEmpty(warehouseLocations)}
          size="small"
          onClick={() => {
            if (isNil(warehouseLocations)) {
              return;
            }
            setSelectedWarehouseLocationsForLabels(warehouseLocations);
            setWarehouseLocationsLabelModalOpen(true);
          }}
        >
          Print all labels
        </Button>
        <Button
          component="label"
          sx={{ marginBottom: '16px' }}
          variant="contained"
          size="small"
          disabled={importWarehouseLocationsFromCsvLoading}
        >
          Upload locations from CSV
          <input
            hidden
            multiple
            type="file"
            accept=".csv"
            onChange={handleSelectFile}
          />
        </Button>
        <Button
          sx={{ marginBottom: '16px' }}
          variant="contained"
          size="small"
          onClick={() => {
            setWarehouseLocationModalOpen(true);
          }}
        >
          Add location
        </Button>
      </Stack>
      <Stack direction="row" gap={1} width="100%">
        <WarehouseSelector
          value={selectedWarehouse}
          width="50%"
          cacheId="warehouse-locations"
          onChange={setSelectedWarehouse}
        />
      </Stack>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="warehouse-locations-table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              {(warehouses?.length ?? 0) > 1 && (
                <TableCell>Warehouse</TableCell>
              )}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {warehouseLocations?.map((warehouseLocation) => {
              return (
                <TableRow
                  key={warehouseLocation.uuid}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{warehouseLocation.name}</TableCell>
                  {(warehouses?.length ?? 0) > 1 && (
                    <TableCell>{warehouseLocation.warehouse.name}</TableCell>
                  )}
                  <TableCell>
                    <Stack direction="row" gap={1}>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          setSelectedWarehouseLocation(warehouseLocation);
                          setWarehouseLocationModalOpen(true);
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="outlined"
                        color="secondary"
                        size="small"
                        onClick={() => {
                          setSelectedWarehouseLocation(warehouseLocation);
                          setDeleteWarehouseLocationsModalOpen(true);
                        }}
                      >
                        Delete
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          setSelectedWarehouseLocationsForLabels([
                            warehouseLocation,
                          ]);
                          setWarehouseLocationsLabelModalOpen(true);
                        }}
                      >
                        Print Label
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <WarehouseLocationLabelsModal
          open={warehouseLocationLabelsModalOpen}
          warehouseLocations={selectedWarehouseLocationsForLabels}
          onClose={() => {
            setWarehouseLocationsLabelModalOpen(false);
          }}
        />
        <WarehouseLocationsA4Modal
          open={warehouseLocationsA4ModalOpen}
          warehouseLocations={selectedWarehouseLocationsForLabels}
          onClose={() => {
            setWarehouseLocationsA4ModalOpen(false);
          }}
        />
        <ConfirmDeleteWarehouseLocationModal
          open={deleteWarehouseLocationsModalOpen}
          setOpen={setDeleteWarehouseLocationsModalOpen}
          setSuccessSnackbarVisible={setDeleteSuccessSnackbarVisible}
          setErrorSnackbarVisible={setDeleteErrorSnackbarVisible}
          selectedWarehouseLocation={selectedWarehouseLocation}
          setSelectedWarehouseLocation={setSelectedWarehouseLocation}
        />
      </TableContainer>
    </Stack>
  );
};

export default WarehouseLocationsTable;
