import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { type ScannedOrderResultEntity } from '../../../generated/graphql';
import type { RootState } from '../../../redux/store';

export type ScannedOrderResult = Omit<
  ScannedOrderResultEntity,
  | '__typename'
  | 'scannedOrderResultPages'
  | 'createdAt'
  | 'updatedAt'
  | 'scannedOrder'
  | 'filename'
  | 'dismissed'
> & {
  scannedOrderUuid: string;
};

const scannedOrderResultsAdapter = createEntityAdapter<ScannedOrderResult>({
  selectId: (scannedOrderResult) => scannedOrderResult.uuid,
});

export const scannedOrderResultsSlice = createSlice({
  name: 'scannedOrderResult',
  initialState: scannedOrderResultsAdapter.getInitialState(),
  reducers: {
    addOneScannedOrderResult: scannedOrderResultsAdapter.addOne,
    upsertOneScannedOrderResult: scannedOrderResultsAdapter.upsertOne,
    addManyScannedOrderResult: scannedOrderResultsAdapter.addMany,
  },
});

export const { selectById: selectScannedOrderResultById } =
  scannedOrderResultsAdapter.getSelectors(
    (state: RootState) => state.scannedOrderResults,
  );

export const { addOneScannedOrderResult, upsertOneScannedOrderResult } =
  scannedOrderResultsSlice.actions;

const scannedOrderResultsReducer = scannedOrderResultsSlice.reducer;

export default scannedOrderResultsReducer;
