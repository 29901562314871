import { isNil } from 'lodash';
import { useMemo } from 'react';
import {
  type LineHaulLaneFragment,
  useLineHaulLaneLazyQuery,
  useLineHaulLanesQuery,
} from '../../generated/graphql';
import useMe from './use-me';

const useLineHaulLanes = ({
  includeInactiveTerminals,
}: {
  includeInactiveTerminals: boolean;
}) => {
  const { companyConfiguration } = useMe();

  const { data: lanesData } = useLineHaulLanesQuery({
    fetchPolicy: 'cache-first',
  });

  const [getLineHaulLane] = useLineHaulLaneLazyQuery();

  const lineHaulEnabled = companyConfiguration?.lineHaulEnabled;

  const allLanes = useMemo(() => {
    return lanesData?.lineHaulLanes.map((l) => ({
      ...l,
      label: `${l.originTerminal.code}-${l.destinationTerminal.code}`,
    }));
  }, [lanesData?.lineHaulLanes]);

  const lanesFilteredForInactiveTerminals = useMemo(() => {
    if (includeInactiveTerminals) {
      return allLanes;
    }
    return allLanes?.filter((lane) => lane.isActive);
  }, [allLanes, includeInactiveTerminals]);

  const getLane = async (
    uuid: string | null | undefined,
  ): Promise<LineHaulLaneFragment | null | undefined> => {
    if (isNil(uuid) || uuid.length === 0) {
      return null;
    }
    const res = await getLineHaulLane({
      variables: {
        uuid,
      },
      fetchPolicy: 'cache-first',
    });
    return res.data?.lineHaulLane;
  };

  return {
    lineHaulEnabled: lineHaulEnabled === true,
    lanes: lanesFilteredForInactiveTerminals,
    allLanes,
    getLane,
  };
};

export default useLineHaulLanes;
