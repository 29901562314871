import {
  Box,
  AppBar,
  Typography,
  type SxProps,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  CircularProgress,
  Card,
} from '@mui/material';
import { isNil } from 'lodash';
import { type FunctionComponent, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { transformAddressToFullAddressString } from 'shared/copy';
import {
  ShipmentStatus,
  useShipmentsByPhoneOrReferenceNumberLazyQuery,
} from '../../../generated/graphql';

const styles = {
  appBar: {
    position: 'sticky',
    flexDirection: 'row',
    height: 75,
    alignItems: 'center',
    justifyContent: 'center',
  } as SxProps,
  appBarTypography: {
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '29px',
    color: '#FFFFFF',
  } as SxProps,
};

const transformStatusToStatusText = (status: ShipmentStatus) => {
  switch (status) {
    case ShipmentStatus.Created: {
      return 'Not started';
    }
    case ShipmentStatus.InProgress: {
      return 'In progress';
    }
    default: {
      return 'Delivered';
    }
  }
};

type ConsigneeShipmentsViewProps = {
  readonly companyUuid: string;
};

const ConsigneeShipmentsView: FunctionComponent<
  ConsigneeShipmentsViewProps
> = ({ companyUuid }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const searchText = searchParams.get('searchText');
  const [getShipmentsByPhoneOrRefNumber, { data, loading }] =
    useShipmentsByPhoneOrReferenceNumberLazyQuery();

  useEffect(() => {
    if (typeof searchText === 'string')
      getShipmentsByPhoneOrRefNumber({
        variables: { companyUuid, searchText },
      });
  }, [companyUuid, searchText, getShipmentsByPhoneOrRefNumber]);

  return (
    <Box
      sx={{ backgroundColor: '#F7F7F7', height: '100vh', overflowY: 'scroll' }}
    >
      <AppBar color="primary" sx={styles.appBar}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Typography sx={styles.appBarTypography}>Your shipments</Typography>
        </Box>
      </AppBar>
      <Box sx={{ p: '20px' }}>
        <Card>
          {loading ? (
            <CircularProgress />
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.shipmentsByPhoneOrReferenceNumber.map((shipment) => (
                  <TableRow
                    key={shipment.uuid}
                    onClick={() => {
                      navigate(`/consignee/shipment-profile/${shipment.uuid}`);
                    }}
                  >
                    <TableCell>
                      {shipment.order?.standardOrderFields
                        .shipperBillOfLadingNumber ?? 'Shipment'}
                    </TableCell>
                    <TableCell>
                      {!isNil(shipment.legs[0]) &&
                      !isNil(shipment.legs[0].endStop.address)
                        ? transformAddressToFullAddressString(
                            shipment.legs[0].endStop.address,
                          )
                        : 'NA'}
                    </TableCell>
                    <TableCell>
                      {transformStatusToStatusText(shipment.status)}
                    </TableCell>
                    <TableCell>
                      <Button>View</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Card>
      </Box>
    </Box>
  );
};

export default ConsigneeShipmentsView;
