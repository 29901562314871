import { z } from 'zod';
import { freightChargeSchema } from './freight-charge-schema';
import { zDateOrDatetimeString } from './zod-utils';

export const lineHaulShipmentSchema = z.object({
  uuid: z.string().uuid(),
  freightCharge: freightChargeSchema.nullish(),
  isLocal: z.boolean(),
  // TODO: This is poorly named. It should be called lineHaulCompletedDate
  dateForFuelCharge: zDateOrDatetimeString().nullish(),
  totalCharge: z.number().nullish(),
  invoiceUuid: z.string().uuid().nullish(),
  invoiceName: z.string().nullish(),
  invoiceDate: zDateOrDatetimeString().nullish(),
  invoicePostedDate: zDateOrDatetimeString().nullish(),
});
