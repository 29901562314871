import {
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import React, {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useState,
} from 'react';
import { ONE_MINUTE_IN_MS } from 'shared/constants';
import CenteredCircularProgress from '../../../../../common/components/centered-circular-progress';
import { FeatureFlag } from '../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../common/react-hooks/use-feature-flag';
import useWindowDimensions from '../../../../../common/react-hooks/use-window-dimensions';
import {
  type InvoiceSendJobBatchFragment,
  useInvoiceSendJobBatchesLazyQuery,
} from '../../../../../generated/graphql';
import InvoiceSendJobBatchRow from './invoice-send-job-batch-row';

const DEFAULT_ROWS_PER_PAGE = 10;

const InvoiceSendJobsBatchList = ({
  open,
  setSelectedInvoiceSendJobBatch,
}: {
  readonly open: boolean;
  readonly setSelectedInvoiceSendJobBatch: Dispatch<
    SetStateAction<InvoiceSendJobBatchFragment | undefined>
  >;
}) => {
  const { height } = useWindowDimensions();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [sortByCreatedAtAsc, setSortByCreatedAtAsc] = useState<boolean>(false);
  const [
    getInvoiceSendJobBatches,
    { data: invoiceSendJobBatchesData, startPolling, stopPolling, loading },
  ] = useInvoiceSendJobBatchesLazyQuery();
  const enableInvoiceSendJobsBatchListPolling = useFeatureFlag(
    FeatureFlag.FF_ENABLE_INVOICE_SEND_JOBS_BATCH_LIST_POLLING,
  );

  const fetchInvoiceSendJobBatches = async ({
    first,
    after,
    last,
    before,
  }: {
    first?: number | null | undefined;
    after?: string | null | undefined;
    last?: number | null | undefined;
    before?: string | null | undefined;
  }) => {
    const res = await getInvoiceSendJobBatches({
      variables: {
        first,
        after,
        last,
        before,
        sortByCreatedAtAsc,
      },
    });
    setTotalCount(res.data?.invoiceSendJobBatches.totalCount ?? 0);
  };

  const refresh = () => {
    fetchInvoiceSendJobBatches({
      first: DEFAULT_ROWS_PER_PAGE,
    });
    setPage(0);
  };

  const prev = async (newPage: number) => {
    await fetchInvoiceSendJobBatches({
      last: DEFAULT_ROWS_PER_PAGE,
      before:
        invoiceSendJobBatchesData?.invoiceSendJobBatches.pageInfo
          ?.startCursor ?? undefined,
    });
    setPage(newPage);
  };
  const next = async (newPage: number) => {
    await fetchInvoiceSendJobBatches({
      first: DEFAULT_ROWS_PER_PAGE,
      after:
        invoiceSendJobBatchesData?.invoiceSendJobBatches.pageInfo?.endCursor ??
        undefined,
    });
    setPage(newPage);
  };

  useEffect(() => {
    if (open) {
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortByCreatedAtAsc]);

  useEffect(() => {
    if (open && enableInvoiceSendJobsBatchListPolling) {
      startPolling(ONE_MINUTE_IN_MS);
    } else {
      stopPolling();
    }
  }, [open, enableInvoiceSendJobsBatchListPolling, startPolling, stopPolling]);

  if (loading) {
    return <CenteredCircularProgress />;
  }

  return (
    <TableContainer
      sx={{
        maxHeight: height - 305,
        overflowY: 'scroll',
      }}
    >
      <Grid
        container
        alignItems="center"
        sx={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 1 }}
      >
        <Grid item xs={4}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            Invoice download & send progress
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <TablePagination
            labelRowsPerPage="Show"
            rowsPerPageOptions={[]}
            component="div"
            count={totalCount}
            rowsPerPage={DEFAULT_ROWS_PER_PAGE}
            page={page}
            onPageChange={(e, newPage: number) => {
              if (newPage > page) {
                next(newPage);
              } else {
                prev(newPage);
              }
            }}
          />
        </Grid>
      </Grid>

      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active
                direction={sortByCreatedAtAsc ? 'asc' : 'desc'}
                hideSortIcon={false}
                onClick={() => {
                  setSortByCreatedAtAsc(!sortByCreatedAtAsc);
                }}
              >
                Started at
              </TableSortLabel>
            </TableCell>
            <TableCell>Invoices</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Progress (finished / total)</TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {invoiceSendJobBatchesData?.invoiceSendJobBatches.edges.map(
            ({ node: invoiceSendJobBatch }) => (
              <InvoiceSendJobBatchRow
                key={invoiceSendJobBatch.uuid}
                invoiceSendJobBatch={invoiceSendJobBatch}
                setSelectedInvoiceSendJobBatch={setSelectedInvoiceSendJobBatch}
              />
            ),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InvoiceSendJobsBatchList;
