import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  type RouteFragment,
  RoutesDocument,
  useTextDriverMutation,
} from '../../../../../generated/graphql';
import useRouteTextDriversData from '../../../hooks/use-text-drivers-data';
import TextDriverRow from './route-text-driver-row';

const modalInnerContainerStyle = {
  bgcolor: 'background.paper',
  boxShadow: 24,
  color: 'black',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '20px',
};

const textDriversTableCellStyle = {
  paddingTop: '5px',
  paddingBottom: '2px',
  whiteSpace: 'nowrap',
  width: '225px',
};

const TextDriversModal = ({
  open,
  setOpen,
  routes,
}: {
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
  readonly routes: RouteFragment[] | null;
}) => {
  const [selectedRouteUuids, setSelectedRouteUuids] = useState<string[]>([]);
  const { notifiedDrivers } = useRouteTextDriversData(routes, true);
  const [textDriver] = useTextDriverMutation({
    refetchQueries: [RoutesDocument],
  });

  useEffect(() => {
    setSelectedRouteUuids(notifiedDrivers.map((driver) => driver.routeUuid));
  }, [notifiedDrivers.length, open]);

  const handleClick = () => {
    notifiedDrivers
      .filter((notifiedDriver) =>
        selectedRouteUuids.includes(notifiedDriver.routeUuid),
      )
      .map(async (notifiedDriver) => {
        await textDriver({
          variables: {
            textDriverInput: {
              driverUuid: notifiedDriver.driverUuid,
              routeUuid: notifiedDriver.routeUuid,
              messageContents: notifiedDriver.messageContents,
              dispatchedDate: new Date(),
            },
          },
        });
        setOpen(false);
      });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Box sx={modalInnerContainerStyle}>
        <Typography variant="h6">
          Text {selectedRouteUuids.length} routes now
        </Typography>
        <TableContainer
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: '15px',
          }}
        >
          <TableHead>
            <TableRow sx={{ width: '100%' }}>
              <TableCell>
                <Checkbox
                  defaultChecked
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedRouteUuids(
                        notifiedDrivers.map((driver) => driver.routeUuid),
                      );
                    } else {
                      setSelectedRouteUuids([]);
                    }
                  }}
                />
              </TableCell>
              <TableCell sx={textDriversTableCellStyle}>Driver</TableCell>
              <TableCell sx={textDriversTableCellStyle}>Route</TableCell>
              <TableCell sx={textDriversTableCellStyle}>
                Message summary
              </TableCell>
              <TableCell sx={{ width: '175px' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {notifiedDrivers.map((notifiedDriver) => (
              <TextDriverRow
                key={notifiedDriver.driverUuid}
                notifiedDriver={notifiedDriver}
                isSelected={selectedRouteUuids.includes(
                  notifiedDriver.routeUuid,
                )}
                setSelectedRouteUuids={setSelectedRouteUuids}
              />
            ))}
          </TableBody>
        </TableContainer>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setOpen(false);
            }}
          >
            Close
          </Button>
          <Button variant="contained" onClick={handleClick}>
            Text now
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default TextDriversModal;
