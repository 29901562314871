import { QuestionMark, Star } from '@mui/icons-material';
import {
  Box,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  type SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import { noCase } from 'change-case';
import dayjs from 'dayjs';
import { isEmpty, isNil } from 'lodash';
import React from 'react';
import { exhaustive } from 'shared/switch';
import { transformTimeToTimeString } from '../../../common/utils/prettyPrintUtils';
import { isNilOrEmptyString } from '../../../common/utils/utils';
import {
  StopType,
  SameDayDispatchCellHeader,
  type SameDayDispatchConfigFragment,
  type SameDayDispatchRouteSlotFragment,
  type ServiceFragment,
} from '../../../generated/graphql';
import { GridItem, GridItemTooltip } from './daily-control-center-grid-item';

const useStyles = (): Record<string, SxProps> => {
  const theme = useTheme();
  return {
    container: {
      minWidth: 125,
      maxWidth: 125,
    },
    stopNumber: {
      color: 'black',
      fontWeight: 700,
      fontSize: '10px',
      textAlign: 'center',
    },
    blueLink: {
      color: theme.palette.blueLink.main,
      fontWeight: 700,
      fontSize: '12px',
    },
    centeredRow: {
      display: 'flex',
      justifyContent: 'center',
    },
    subText: {
      color: 'black',
      fontWeight: 400,
      fontSize: '10px',
      textAlign: 'center',
    },
    redSubText: {
      color: 'red',
      fontWeight: 400,
      fontSize: '10px',
      textAlign: 'center',
    },
  };
};

type DailyControlCenterStopItemProps = {
  readonly cumulativeDistanceMeters: number;
  readonly cumulativeDurationSeconds: number;
  readonly routeSlot: SameDayDispatchRouteSlotFragment;
  readonly sameDayDispatchCellHeader: SameDayDispatchCellHeader;
  readonly colorCodingConfig: SameDayDispatchConfigFragment | null | undefined;
  readonly arrivalTime: string | null | undefined;
  readonly finishTime: string | null | undefined;
  readonly isNext: boolean;
  readonly idxOfSlot: number;
};

/**
 * Rules for determining the header:
 *
 * @param billOfLadingNumber
 * @param orderName
 * @param stopType
 * @param productionCompany
 * @param sameDayDispatchCellHeader
 * @param secondaryRefNumber
 * @param service
 */
export const getCellHeader = ({
  billOfLadingNumber,
  orderName,
  stopType,
  sameDayDispatchCellHeader,
  secondaryRefNumber,
  service,
}: {
  billOfLadingNumber: string | null | undefined;
  secondaryRefNumber: string | null | undefined;
  orderName: string;
  stopType: StopType | null | undefined;
  sameDayDispatchCellHeader: SameDayDispatchCellHeader;
  service: ServiceFragment | null | undefined;
}): string => {
  const shortOrderName = orderName.split('-').slice(0, -1).join('-');
  switch (sameDayDispatchCellHeader) {
    case SameDayDispatchCellHeader.BillOfLadingNumber: {
      const suffix =
        stopType === StopType.Pickup ? secondaryRefNumber : billOfLadingNumber;
      if (isNilOrEmptyString(suffix)) {
        return orderName;
      }
      return `${shortOrderName} - ${suffix}`;
    }
    case SameDayDispatchCellHeader.ServiceLevel: {
      if (isNil(service)) {
        return orderName;
      }
      return `${shortOrderName} - ${noCase(service.name)}`;
    }
    default: {
      return exhaustive(sameDayDispatchCellHeader);
    }
  }
};

const secondsToMinutes = (seconds: number): number => {
  return Math.floor(seconds / 60);
};

const metersToMiles = (meters: number): number => {
  return Math.floor(meters * 0.000_621_371);
};

const DailyControlCenterStopItem = ({
  cumulativeDistanceMeters,
  cumulativeDurationSeconds,
  routeSlot,
  sameDayDispatchCellHeader,
  colorCodingConfig,
  arrivalTime,
  finishTime,
  isNext,
  idxOfSlot,
}: DailyControlCenterStopItemProps) => {
  const styles = useStyles();
  const stop = routeSlot.stops[0];
  const shipment = stop?.leg?.shipment;

  if (isNil(shipment)) {
    return null;
  }

  // Cut off the hyphenated count at the end of ORDER-NUMBER
  const orderName = shipment.order?.name ?? 'Order';
  const cellHeader = getCellHeader({
    billOfLadingNumber:
      shipment.order?.standardOrderFields.shipperBillOfLadingNumber,
    orderName,
    stopType: stop?.stopType,
    sameDayDispatchCellHeader,
    secondaryRefNumber: shipment.order?.secondaryRefNumber,
    service: shipment.order?.service,
  });

  const duration = isNil(routeSlot.previousSlotDurationSeconds)
    ? '-'
    : secondsToMinutes(cumulativeDurationSeconds);
  const distance = isNil(routeSlot.previousSlotDistanceMeters)
    ? '-'
    : metersToMiles(cumulativeDistanceMeters);

  const startApptTime = stop?.appointmentTime;
  const endApptTime = stop?.endAppointmentTime;

  const startApptString = isNil(startApptTime)
    ? ''
    : dayjs(startApptTime).format('h:mma');
  const endApptString = isNil(endApptTime)
    ? ''
    : dayjs(endApptTime).format('h:mma');

  const routeChangeAcknowledged = stop?.routeChangeAcknowledged;

  const etaBox = () => {
    let formattedEta: string =
      !isNil(arrivalTime) && !isNil(finishTime)
        ? `${transformTimeToTimeString(
            arrivalTime,
          )} - ${transformTimeToTimeString(finishTime)}`
        : '-';
    if (
      !isNil(arrivalTime) &&
      (transformTimeToTimeString(arrivalTime) ===
        transformTimeToTimeString(finishTime) ||
        isNil(finishTime))
    ) {
      formattedEta = transformTimeToTimeString(arrivalTime);
    }
    return (
      <Box sx={{ textAlign: 'center' }}>
        <Typography sx={styles.subText}>ETA: {formattedEta}</Typography>
      </Box>
    );
  };

  // set border color depending on the shipment
  let borderColorHex = null;
  let tooltipTitle = null;
  let tooltipSubtitle = null;
  if (!isNil(colorCodingConfig)) {
    const specialCharge = shipment.customCharges.find(
      (charge) =>
        charge.accessorialTemplate?.__typename === 'SpecialAccessorialEntity',
    );
    if (!isNil(specialCharge)) {
      borderColorHex = colorCodingConfig.isSpecialColorCode;
      tooltipTitle = 'Special stop';
      tooltipSubtitle = `${specialCharge.accessorialTemplate?.name} accessorial applied to order`;
    }
    const currentDateTime = dayjs(); // Get the current date and time

    // assume the appointment's date is today and modify the time to be the appointment times in the db.
    const endAppointmentTime = dayjs(endApptTime);
    const endAppointmentDateTime = currentDateTime
      .set('hour', endAppointmentTime.hour())
      .set('minute', endAppointmentTime.minute())
      .set('second', endAppointmentTime.second())
      .set('millisecond', endAppointmentTime.millisecond());

    const isPastEndAppointmentTime = currentDateTime.isAfter(
      endAppointmentDateTime,
    );
    const timeDiffMinutes = endAppointmentDateTime.diff(
      currentDateTime,
      'minutes',
    );
    if (isPastEndAppointmentTime) {
      borderColorHex = colorCodingConfig.isPastDeadlineColorCode;
      tooltipTitle = `Missed appointment window`;
      tooltipSubtitle = `${Math.abs(timeDiffMinutes)} minutes overdue`;
    }

    if (stop?.stopType === StopType.Pickup) {
      const isBeforeEndAppointmentTime = currentDateTime.isBefore(
        endAppointmentDateTime,
      );
      if (
        isBeforeEndAppointmentTime &&
        timeDiffMinutes <= colorCodingConfig.pickupAppointmentTimeThreshold
      ) {
        borderColorHex = colorCodingConfig.pickupWithinTimeWindowColorCode;
        tooltipTitle = `Pickup appointment ending soon`;
        tooltipSubtitle = `${timeDiffMinutes} minutes before appointment ends`;
      }
    }
    if (stop?.stopType === StopType.Delivery) {
      const isBeforeEndAppointmentTime = currentDateTime.isBefore(
        endAppointmentDateTime,
      );
      if (
        isBeforeEndAppointmentTime &&
        timeDiffMinutes <= colorCodingConfig.deliveryAppointmentTimeThreshold
      ) {
        borderColorHex = colorCodingConfig.deliveryWithinTimeWindowColorCode;
        tooltipTitle = `Delivery appointment ending soon`;
        tooltipSubtitle = `${timeDiffMinutes} minutes before appointment ends`;
      }
    }
  }

  return (
    <GridItem borderColorHex={borderColorHex}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {isNext && <Star sx={{ width: 15, height: 15 }} />}
        <Box>
          <Grid item xs={12} sx={styles.centeredRow}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
              <Typography sx={styles.blueLink}>{cellHeader}</Typography>
              <Typography sx={styles.stopNumber}>
                Stop {idxOfSlot + 1}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sx={styles.centeredRow}>
            {stop?.stopType === StopType.Delivery && (
              <Typography sx={styles.subText}>DEL</Typography>
            )}
            {stop?.stopType === StopType.Pickup && (
              <Typography sx={styles.subText}>PU</Typography>
            )}
          </Grid>
          <Grid item xs={12} sx={styles.centeredRow}>
            <Typography sx={styles.subText}>
              {duration} mins, {distance} miles
            </Typography>
          </Grid>
          <Grid item xs={12} sx={styles.centeredRow}>
            {etaBox()}
          </Grid>
          <Grid item xs={12} sx={styles.centeredRow}>
            {!isEmpty(startApptString) && !isEmpty(endApptString) ? (
              <Typography sx={styles.subText}>
                Appt: {startApptString}
                {' - '}
                {endApptString}
              </Typography>
            ) : (
              <Typography sx={styles.subText}>Appt:{' - '}</Typography>
            )}
          </Grid>
          {!isNil(routeChangeAcknowledged) && !routeChangeAcknowledged && (
            <Grid item xs={12} sx={styles.centeredRow}>
              <Typography sx={styles.redSubText}>
                Unacknowledged change
              </Typography>
            </Grid>
          )}
          {!isNil(tooltipTitle) && (
            <GridItemTooltip
              title={
                <>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 'bold' }}
                    color="inherit"
                  >
                    {tooltipTitle}
                  </Typography>
                  <Typography variant="body2"> {tooltipSubtitle} </Typography>
                </>
              }
            >
              <IconButton
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  padding: 0,
                  color: borderColorHex,
                }}
                aria-label="Help"
              >
                <QuestionMark sx={{ fontSize: 16 }} />
              </IconButton>
            </GridItemTooltip>
          )}
        </Box>
      </Box>
    </GridItem>
  );
};

export default DailyControlCenterStopItem;
