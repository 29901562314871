import { isEmpty, isNil } from 'lodash';
import { type Resolver, useForm } from 'react-hook-form';
import { MAX_INTEGER, safeDivide } from 'shared/math';
import { type AccessorialDateRangeConfigFragment } from '../../../../../generated/graphql';
import { type AccessorialRange, NO_FUEL_PROFILE } from '../common';

export type AccessorialDateRangeConfigFormValues = {
  startDate: Date;
  endDate: Date | null;
  rate: number;
  percentForSettlement: number | null;
  fuelProfileUuid: string | null;
  ranges: AccessorialRange[];
};

export const getAccessorialRangesForForm = (
  accessorialDateRangeConfig: AccessorialDateRangeConfigFragment,
) => {
  return accessorialDateRangeConfig.accessorialRanges
    .sort(
      (a, b) =>
        (a.lessThanOrEqualToValue ?? 0) - (b.lessThanOrEqualToValue ?? 0),
    )
    .map((range) => ({
      ...range,
      lessThanOrEqualToValue:
        range.lessThanOrEqualToValue === MAX_INTEGER
          ? null
          : range.lessThanOrEqualToValue,
      rate: safeDivide(range.rateUsdCents, 100, 10),
    }));
};

const resolver: Resolver<AccessorialDateRangeConfigFormValues> = async (
  formValues,
) => {
  const errors: {
    [property in keyof AccessorialDateRangeConfigFormValues]?: {
      type: string;
      message: string;
    };
  } = {};

  const { startDate, rate } = formValues;

  if (isNil(startDate) || isEmpty(startDate)) {
    errors.startDate = {
      type: 'required',
      message: 'Start date is required',
    };
  }

  if (isNil(rate)) {
    errors.rate = {
      type: 'required',
      message: 'Rate is required',
    };
  }

  return {
    values: formValues,
    errors,
  };
};

const useAccessorialDateRangeConfigForm = () => {
  return useForm({
    resolver,
    defaultValues: { fuelProfileUuid: NO_FUEL_PROFILE, ranges: [] },
  });
};

export default useAccessorialDateRangeConfigForm;
