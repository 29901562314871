import CheckIcon from '@mui/icons-material/Check';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Typography, Box, IconButton, Fade, TextField } from '@mui/material';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { isValidPartialOrCompleteNumberInput } from '../../../../../utils';
import { isNilOrEmptyString } from '../../../../common/utils/utils';

const EditTextField = ({
  fieldValue,
  onFinishEditing,
  setFieldValue,
  isNumber,
  endAdornment,
}: {
  readonly fieldValue: string | undefined | null;
  readonly onFinishEditing?: (name: string) => void;
  readonly setFieldValue: (name: string) => void;
  readonly isNumber?: boolean;
  readonly endAdornment?: string;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [inputValue, setInputValue] = useState(fieldValue ?? '');
  useEffect(() => {
    setInputValue(fieldValue ?? '');
  }, [fieldValue]);

  if (isEditing) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          alignItems: 'center',
        }}
      >
        <TextField
          size="small"
          value={inputValue}
          InputProps={{
            endAdornment: !isNil(endAdornment) && (
              <Typography sx={{ color: 'gray' }}>{endAdornment}</Typography>
            ),
          }}
          onChange={(e) => {
            if (isNumber === true) {
              if (isValidPartialOrCompleteNumberInput(e.target.value)) {
                setInputValue(e.target.value);
              }
            } else {
              setFieldValue(e.target.value);
            }
          }}
          onBlur={(e) => {
            if (isNumber === true) {
              setFieldValue(e.target.value);
            }
          }}
        />
        <IconButton
          onClick={() => {
            if (!isNil(onFinishEditing) && !isNilOrEmptyString(fieldValue)) {
              onFinishEditing(fieldValue ?? '');
            }
            setIsEditing(false);
          }}
        >
          <CheckIcon sx={{ fontSize: '20px' }} />
        </IconButton>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        alignItems: 'center',
      }}
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <Typography>
        {fieldValue ?? '-'}
        {endAdornment ?? ''}
      </Typography>
      <Fade in={isHovering}>
        <IconButton
          sx={{ p: 0 }}
          onClick={() => {
            setIsEditing(true);
          }}
        >
          <ModeEditIcon sx={{ fontSize: '20px' }} />
        </IconButton>
      </Fade>
    </Box>
  );
};

export default EditTextField;
