import {
  createEntityAdapter,
  createSelector,
  createSlice,
  type EntityId,
  type EntityState,
} from '@reduxjs/toolkit';
import { type StandardShipmentValues } from 'shared/types';
import type { RootState } from '../../../redux/store';

const standardShipmentsValuesAdapter =
  createEntityAdapter<StandardShipmentValues>({
    selectId: (standardShipmentValues) => standardShipmentValues.uuid,
  });

export const standardShipmentsValuesSlice = createSlice({
  name: 'standardShipmentsValues',
  initialState: standardShipmentsValuesAdapter.getInitialState(),
  reducers: {
    addOneStandardShipmentValues: standardShipmentsValuesAdapter.addOne,
    removeOneStandardShipmentValues: standardShipmentsValuesAdapter.removeOne,
    setAllStandardShipmentValues: standardShipmentsValuesAdapter.setAll,
    updateStandardShipmentValues: standardShipmentsValuesAdapter.updateOne,
    upsertOneStandardShipmentValues: standardShipmentsValuesAdapter.upsertOne,
    upsertStandardShipmentValues: standardShipmentsValuesAdapter.upsertMany,
  },
});

export const {
  addOneStandardShipmentValues,
  removeOneStandardShipmentValues,
  setAllStandardShipmentValues,
  updateStandardShipmentValues,
  upsertOneStandardShipmentValues,
  upsertStandardShipmentValues,
} = standardShipmentsValuesSlice.actions;

export const {
  selectById: selectStandardShipmentValuesById,
  selectIds: selectStandardShipmentIds,
  selectAll: selectAllStandardShipments,
} = standardShipmentsValuesAdapter.getSelectors(
  (state: RootState) => state.standardShipmentValues,
);

export const selectStandardShipmentFreightChargeId = createSelector(
  selectStandardShipmentValuesById,
  (values: StandardShipmentValues | undefined) => {
    return values?.freightChargeId;
  },
);

export const selectStandardShipmentCustomChargeId = createSelector(
  selectStandardShipmentValuesById,
  (values: StandardShipmentValues | undefined) => {
    return values?.customChargeIds;
  },
);

export const selectStandardShipmentAirportInfoUuid = createSelector(
  selectStandardShipmentValuesById,
  (values: StandardShipmentValues | undefined) => {
    return values?.airportInfoUuid;
  },
);

export const selectOrderUuidForShipment = createSelector(
  selectStandardShipmentValuesById,
  (values: StandardShipmentValues | undefined) => {
    return values?.orderUuid;
  },
);

export const selectHideFromBillingForShipment = createSelector(
  selectStandardShipmentValuesById,
  (values: StandardShipmentValues | undefined) => {
    return values?.hideFromBilling;
  },
);

export const selectOverridePackageWeightForShipment = createSelector(
  selectStandardShipmentValuesById,
  (values: StandardShipmentValues | undefined) => {
    return values?.overridePackageWeight;
  },
);

export const selectQuoteUuidForShipment = createSelector(
  selectStandardShipmentValuesById,
  (values: StandardShipmentValues | undefined) => {
    return values?.quoteUuid;
  },
);

export const selectServiceUuidForShipment = createSelector(
  selectStandardShipmentValuesById,
  (values: StandardShipmentValues | undefined) => {
    return values?.serviceUuid;
  },
);

export const selectDeadlineTypeForShipment = createSelector(
  selectStandardShipmentValuesById,
  (values: StandardShipmentValues | undefined) => {
    return values?.deadlineType;
  },
);

export const selectDeadlineDateForShipment = createSelector(
  selectStandardShipmentValuesById,
  (values: StandardShipmentValues | undefined) => {
    return values?.deadlineDate;
  },
);

export const selectShipmentPickupOrDelivery = createSelector(
  selectStandardShipmentValuesById,
  (values: StandardShipmentValues | undefined) => {
    return values?.pickupOrDelivery;
  },
);

export const selectStandardShipmentFirstLegUuid = createSelector(
  selectStandardShipmentValuesById,
  (values: StandardShipmentValues | undefined) => {
    return values?.firstLegUuid;
  },
);

export const selectShouldUseDimensionalWeightForShipment = createSelector(
  selectStandardShipmentValuesById,
  (values: StandardShipmentValues | undefined) => {
    return values?.shouldUseDimensionalWeight;
  },
);

const shipmentValuesSelector = (state: RootState) =>
  state.standardShipmentValues;

export const selectStandardShipmentsByIds = createSelector(
  shipmentValuesSelector,
  (state: RootState, ids: EntityId[]) => ids,
  (state: EntityState<StandardShipmentValues>, entityIds: EntityId[]) => {
    const entityResults: StandardShipmentValues[] = [];
    for (const id of entityIds) {
      const match = standardShipmentsValuesAdapter
        .getSelectors()
        .selectById(state, id);
      if (match) {
        entityResults.push(match);
      }
    }
    return entityResults;
  },
);

const standardShipmentValuesReducer = standardShipmentsValuesSlice.reducer;

export default standardShipmentValuesReducer;
