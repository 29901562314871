import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { type Dispatch, type SetStateAction } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDeleteOrderMutation } from '../../../../../generated/graphql';
import useGlobalStore from '../../../../../layouts/dashboard/global-store';

type DeleteOrderModalProps = {
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
  readonly setWasSubmitSuccessful: Dispatch<SetStateAction<boolean>>;
};

const DeleteOrderModal = ({
  open,
  setOpen,
  setWasSubmitSuccessful,
}: DeleteOrderModalProps) => {
  const { control } = useFormContext();
  const uuid = useWatch({ control, name: 'uuid' });
  const setOpenedOrderUuid = useGlobalStore(
    (state) => state.setCurrentOrderUuid,
  );
  const navigate = useNavigate();
  const [deleteOrder] = useDeleteOrderMutation();
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Delete order</DialogTitle>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={async () => {
            setOpen(false);
            await deleteOrder({ variables: { uuid } });
            setWasSubmitSuccessful(true);
            setOpenedOrderUuid(undefined);
            navigate('/orders');
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteOrderModal;
