import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './app';
import './sentry';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import './css/prime-react-theme.css';
import './common/styles/globals.css';
import './common/styles/timePicker.css';
import './common/styles/nlux-chat.css';

createRoot(document.querySelector('#root')!).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
