import { useState } from 'react';
import {
  type FilterViewPage,
  type SavedFilterViewFragment,
} from '../../../../../generated/graphql';
import { EditSavedViewMenu } from '../../../../saved-filter-views/components/edit-saved-view-menu';
import EditSavedViewModal from '../../../../saved-filter-views/components/edit-saved-view-modal';

type ViewTabOverflowMenuProps = {
  readonly anchorEl: HTMLElement | null;
  readonly onClose: () => void;
  readonly view: SavedFilterViewFragment;
  readonly userUuid: string;
  readonly pageType: FilterViewPage;
  readonly handleSetIsViewShared: (isShared: boolean) => void;
  readonly handleDeleteSavedView: () => void;
  readonly handleEditSavedViewName: (newName: string) => void;
};

const ViewTabOverflowMenu = ({
  anchorEl,
  onClose,
  view,
  userUuid,
  pageType,
  handleSetIsViewShared,
  handleDeleteSavedView,
  handleEditSavedViewName,
}: ViewTabOverflowMenuProps) => {
  const [showEditSavedViewModal, setShowEditSavedViewModal] = useState(false);
  return (
    <>
      <EditSavedViewMenu
        shouldStopPropagation
        anchorEl={anchorEl}
        isViewShared={view.isShared}
        isUserViewCreator={view.user.uuid === userUuid}
        pageType={pageType}
        handleCloseMenu={onClose}
        handleClickRename={() => {
          setShowEditSavedViewModal(true);
        }}
        handleSetIsViewShared={handleSetIsViewShared}
        handleClickDelete={handleDeleteSavedView}
      />
      {showEditSavedViewModal && (
        <EditSavedViewModal
          shouldStopPropagation
          open={showEditSavedViewModal}
          setOpen={setShowEditSavedViewModal}
          currentName={view.displayName ?? undefined}
          editSavedView={handleEditSavedViewName}
        />
      )}
    </>
  );
};

export default ViewTabOverflowMenu;
