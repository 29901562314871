import { Button, CircularProgress, Stack } from '@mui/material';
import { type WebViewerInstance } from '@pdftron/webviewer';
import { isNil } from 'lodash';
import { type FunctionComponent, useEffect, useRef, useState } from 'react';
import useStateRef from 'react-usestateref';
import useDocuments from '../../../common/react-hooks/use-documents';
import { EnvironmentVariables } from '../../../environment-variables';
import {
  DocumentForSigningDocument,
  useDocumentForSigningLazyQuery,
} from '../../../generated/graphql';

const postMessageBackToNativeApp = (message: string) => {
  try {
    globalThis.ReactNativeWebView.postMessage(message);
  } catch {
    if (!isNil(globalThis.webkit.messageHandlers.ReactNativeWebView)) {
      // tell the containing webview to navigate away.
      globalThis.webkit.messageHandlers.ReactNativeWebView.postMessage(message);
    }
  }
};

type SignDigitalDocumentViewProps = {
  readonly orderUuid: string | null;
  readonly documentUuid: string | null;
};

const SignDigitalDocumentView: FunctionComponent<
  SignDigitalDocumentViewProps
> = ({ orderUuid, documentUuid }) => {
  const viewer = useRef(null);

  const [documentGetUrl, setDocumentGetUrl] = useState<string | null>(null);
  const [webviewerInstance, setWebviewerInstance] =
    useState<WebViewerInstance | null>(null);

  const [
    getDocumentForSigning,
    { data: documentForSigningData, loading: documentForSigningDataLoading },
  ] = useDocumentForSigningLazyQuery();

  const [, setAnnotationsCount, annotationsCountRef] = useStateRef(0);

  const { uploadSignedDocumentToAwsAndReplaceOriginal, uploadInProgress } =
    useDocuments();

  useEffect(() => {
    async function getDocumentsForSigning() {
      if (!isNil(orderUuid) && !isNil(documentUuid)) {
        await getDocumentForSigning({
          variables: {
            documentForSigningInput: {
              orderUuid,
              documentUuid,
            },
          },
        });
      }
    }
    getDocumentsForSigning();
  }, [orderUuid, documentUuid, getDocumentForSigning]);

  useEffect(() => {
    if (documentForSigningDataLoading || isNil(documentForSigningData)) return;

    if (!isNil(documentForSigningData?.documentForSigning.document)) {
      const doc = documentForSigningData?.documentForSigning.document;
      if (!isNil(doc?.preSignedGetUrl))
        setDocumentGetUrl(doc?.preSignedGetUrl ?? null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    documentForSigningDataLoading,
    documentForSigningData?.documentForSigning?.document,
  ]);

  // for now we download on click
  const onClick = async () => {
    if (isNil(webviewerInstance)) return;

    const { documentViewer, annotationManager } = webviewerInstance.Core;
    const doc = documentViewer.getDocument();

    const xfdfString = await annotationManager.exportAnnotations();

    const data = await doc?.getFileData({
      xfdfString,
    });

    const blob = new Blob([data], { type: 'application/pdf' });

    if (!isNil(orderUuid) && !isNil(documentUuid)) {
      const fileName = `signed_${documentForSigningData?.documentForSigning?.document?.fileName ?? ''}`;
      const fileType = 'application/pdf';

      const res = await uploadSignedDocumentToAwsAndReplaceOriginal({
        orderUuid,
        originalDocumentUuid: documentUuid,
        fileName,
        fileType,
        blob,
        refetchQueries: [DocumentForSigningDocument],
      });

      if (isNil(res)) {
        postMessageBackToNativeApp('Something went wrong');
      } else if (!isNil(res.errorMessage)) {
        postMessageBackToNativeApp(res.errorMessage);
      } else if (!isNil(res.newDocumentUuid)) {
        postMessageBackToNativeApp('SUCCESS');
      }
    }
  };

  useEffect(() => {
    const callView = async () => {
      if (
        isNil(viewer.current) ||
        isNil(EnvironmentVariables.VITE_APRYSE_WEBVIEWER_LICENSE_KEY)
      )
        return;
      try {
        const WebViewer = (await import('@pdftron/webviewer')).default;
        const instance = await WebViewer(
          {
            path: '/webviewer/lib',
            licenseKey: EnvironmentVariables.VITE_APRYSE_WEBVIEWER_LICENSE_KEY,
            initialDoc: documentGetUrl ?? undefined,
            disabledElements: [
              'menuButton',
              'viewControlsButton',
              'selectToolButton',
              'panToolButton',
              'leftPanelButton',
              'toggleNotesButton',
              'searchButton',
              'toolbarGroup-View',
              'toolbarGroup-Shapes',
              'toolbarGroup-Insert',
              'toolbarGroup-Edit',
              'toolbarGroup-Forms',
              'underlineToolGroupButton',
              'highlightToolGroupButton',
              'shapeToolGroupButton',
              'freeHandHighlightToolGroupButton',
              'squigglyToolGroupButton',
              'strikeoutToolGroupButton',
              'markReplaceTextGroupButton',
              'stickyToolGroupButton',
              'checkStampToolButton',
              'crossStampToolButton',
              'rubberStampToolButton',
              'dotStampToolButton',
              'dateFreeTextToolButton',
              'markInsertTextGroupButton',
              'eraserToolButton',
              'annotationCommentButton',
              'annotationStyleEditButton',
              'linkButton',
            ],
          },
          viewer.current,
        );
        setWebviewerInstance(instance);

        const annotManager =
          instance.Core.documentViewer.getAnnotationManager();
        // Listen for annotation added event
        annotManager.addEventListener(
          'annotationChanged',
          (_, action, { imported }) => {
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            if (imported) return;
            if (action === 'add') {
              setAnnotationsCount(annotationsCountRef.current + 1);
              // Handle the addition of a new annotation
            } else if (action === 'delete') {
              setAnnotationsCount(annotationsCountRef.current - 1);
              // Handle deletion of an annotation
            }
          },
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Could not load document viewer:', error);
      }
    };
    if (
      !isNil(documentGetUrl) &&
      !isNil(EnvironmentVariables.VITE_APRYSE_WEBVIEWER_LICENSE_KEY)
    )
      callView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentGetUrl, EnvironmentVariables.VITE_APRYSE_WEBVIEWER_LICENSE_KEY]);

  return (
    <Stack gap={2}>
      {!isNil(documentGetUrl) && (
        <div ref={viewer} className="webviewer" style={{ height: '90vh' }} />
      )}
      {isNil(EnvironmentVariables.VITE_APRYSE_WEBVIEWER_LICENSE_KEY) && (
        <Stack
          sx={{
            height: '80vh',
            alignItems: 'center',
            justifyContent: 'center',
            width: '70%',
          }}
        >
          There were issues loading this PDF, please contact support
        </Stack>
      )}
      {!isNil(documentForSigningData?.documentForSigning.errorMessage) && (
        <Stack
          sx={{
            height: '80vh',
            alignItems: 'center',
            justifyContent: 'center',
            width: '70%',
          }}
        >
          {documentForSigningData?.documentForSigning.errorMessage}
        </Stack>
      )}
      {(isNil(documentGetUrl) ||
        uploadInProgress ||
        documentForSigningDataLoading) && (
        <Stack
          sx={{
            height: '80vh',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={100} />
        </Stack>
      )}
      <Button
        variant="contained"
        disabled={annotationsCountRef.current === 0 || uploadInProgress}
        sx={{
          alignSelf: 'center',
        }}
        onClick={onClick}
      >
        {' '}
        Submit signed document
      </Button>
    </Stack>
  );
};

export default SignDigitalDocumentView;
