import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Fade,
  FormControl,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { sentenceCase } from 'change-case';
import currency from 'currency.js';
import { isNil, unionBy } from 'lodash';
import React, { useContext, useMemo, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import {
  CustomChargeBillingMethod,
  useAccessorialQuery,
} from '../../../../../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { useOrderFormEditAccess } from '../../../../contexts/order-form-edit-access-context';
import {
  type CustomChargeValues,
  type OrderFormValues,
} from '../../../../forms/types';
import { useOrderFormAccessorials } from '../../../../hooks/use-order-form-accessorials';
import FormNumberInput from '../../../common/form-number-input';
import CustomChargeRateInput from '../custom-charge/custom-charge-rate-input';
import DescriptionComponent, {
  MT_IF_HAS_DESCRIPTION_STYLES,
} from '../description-component';
import OrderFormChargesContext from '../order-form-charges-context';

const CUSTOM_CHARGE_KEY = 'CUSTOM_CHARGE';

const customChargeOption = {
  value: CUSTOM_CHARGE_KEY,
  label: sentenceCase(CUSTOM_CHARGE_KEY),
};

const OrderChargesCustomChargeRow = ({
  customChargeIdx,
  deleteCharge,
  inBillingReview,
  newlyAddedCharge,
  setNewlyAddedCharge,
}: {
  readonly customChargeIdx: number;
  readonly deleteCharge: (uuid: string | undefined) => void;
  readonly inBillingReview: boolean;
  readonly newlyAddedCharge: boolean;
  readonly setNewlyAddedCharge: (newlyAddedCharge: boolean) => void;
}) => {
  const theme = useTheme();
  const {
    isRatingAccessorials,
    isSwitchingAccessorial,
    setIsSwitchingAccessorial,
  } = useContext(OrderFormChargesContext);

  const { accessorials } = useOrderFormAccessorials();
  const { setValue, control } = useFormContext<OrderFormValues>();

  const [isHovering, setIsHovering] = useState(false);

  const customChargeKey = `orderChargesShipment.customCharges.${customChargeIdx}`;
  const customCharge = useWatch({
    control,
    name: `orderChargesShipment.customCharges.${customChargeIdx}`,
  }) as CustomChargeValues | null;

  const { disabledIfFinalizedOrLater, disabledIfInvoicePosted } =
    useOrderFormEditAccess();

  const accessorialUuid = customCharge?.accessorialUuid;

  // Fetch detailed info about the current accessorial. Rates, ranges, etc.
  const { data: currentAccessorialData, loading } = useAccessorialQuery(
    isNil(accessorialUuid)
      ? {
          skip: true,
        }
      : {
          variables: {
            uuid: accessorialUuid,
          },
        },
  );

  const currentAccessorial = currentAccessorialData?.accessorial;

  const loadingCurrentAccessorial =
    loading || isSwitchingAccessorial || isRatingAccessorials;

  const accessorialsToUse = useMemo(() => {
    let mergedAccessorials: Array<{ name: string; uuid: string }> = (
      accessorials ?? []
    ).filter(
      (accessorial) =>
        accessorial.isArchived !== true &&
        accessorial.__typename !== 'ZoneBasedAccessorialEntity' &&
        accessorial.__typename !== 'SpecialAccessorialEntity' &&
        accessorial.__typename !== 'WaitTimeAccessorialEntity',
    );

    if (
      !mergedAccessorials.some((a) => a.uuid === accessorialUuid) && // Manually add it to the list of options. This might happen if the
      // accessorial was archived, is for another terminal, contact, etc.
      !isNil(currentAccessorial)
    ) {
      mergedAccessorials = unionBy<{ name: string; uuid: string }>(
        mergedAccessorials,
        [{ uuid: currentAccessorial.uuid, name: currentAccessorial.name }],
        (acc) => acc.uuid,
      );
    }
    return mergedAccessorials;
  }, [accessorials, currentAccessorial, accessorialUuid]);

  const accessorialOptions = useMemo(() => {
    let ord = 1;
    // Filter out the options based on terminal if the terminal option is non-null.

    const options: Array<{
      value: string;
      label: string;
    }> = (
      accessorialsToUse.map((accessorial) => ({
        value: accessorial.uuid,
        label: accessorial.name,
      })) ?? []
    ).sort((a, b) => a.label?.localeCompare(b.label));
    return options.map((option) => {
      if (
        options.find(
          (o) => o.label === option.label && o.value !== option.value,
        )
      ) {
        const newOption = { ...option };
        newOption.label = `${option.label} (${ord})`;
        ord += 1;
        return newOption;
      }
      return option;
    });
  }, [accessorialsToUse]);

  const AccessorialAutocomplete = (
    <>
      <Box
        sx={{
          ...MT_IF_HAS_DESCRIPTION_STYLES,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '5px',
        }}
      >
        <AutocompleteFuzzy
          disableClearable
          autoHighlight
          autoSelect
          openOnFocus
          size="small"
          disabled={disabledIfFinalizedOrLater}
          value={
            customCharge?.billingMethod ===
            CustomChargeBillingMethod.Accessorial
              ? {
                  value: customCharge?.accessorialUuid,
                  label:
                    accessorialOptions?.find(
                      (option) =>
                        option.value === customCharge?.accessorialUuid,
                    )?.label ?? '',
                }
              : customChargeOption
          }
          renderOption={(props, option) => {
            return option.value === CUSTOM_CHARGE_KEY ? (
              <li
                {...props}
                style={{ fontWeight: 'bold', borderBottom: '1px solid grey' }}
              >
                {option.label}
              </li>
            ) : (
              <li {...props}>{option.label}</li>
            );
          }}
          options={accessorialOptions}
          stickyOptionProps={{
            stickyOption: customChargeOption,
            filterStickyOption: true,
          }}
          matchSortOptions={{ keys: ['label'] }}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              sx={{ width: '200px' }}
              label="Accessorial"
              autoFocus={newlyAddedCharge}
            />
          )}
          onClose={() => {
            setNewlyAddedCharge(false);
          }}
          onChange={(_event, option) => {
            let customChargeBillingMethod = CustomChargeBillingMethod.AdHoc;
            if (option?.value !== CUSTOM_CHARGE_KEY) {
              customChargeBillingMethod = CustomChargeBillingMethod.Accessorial;
              setValue(
                `orderChargesShipment.customCharges.${customChargeIdx}.accessorialUuid`,
                option?.value ?? null,
              );
              setValue(
                `orderChargesShipment.customCharges.${customChargeIdx}.accessorialName`,
                option?.label ?? null,
              );
              setValue(
                `orderChargesShipment.customCharges.${customChargeIdx}.name`,
                option?.label,
              );
              setValue(
                `orderChargesShipment.customCharges.${customChargeIdx}.rate`,
                null,
              );
            } else if (option?.value === CUSTOM_CHARGE_KEY) {
              customChargeBillingMethod = CustomChargeBillingMethod.AdHoc;
              setValue(
                `orderChargesShipment.customCharges.${customChargeIdx}.accessorialUuid`,
                null,
              );
              setValue(
                `orderChargesShipment.customCharges.${customChargeIdx}.accessorialName`,
                null,
              );
              setValue(
                `orderChargesShipment.customCharges.${customChargeIdx}.name`,
                null,
              );
              setValue(
                `orderChargesShipment.customCharges.${customChargeIdx}.rate`,
                null,
              );
              setValue(
                `orderChargesShipment.customCharges.${customChargeIdx}.quantity`,
                1,
              );
            }
            setValue(
              `orderChargesShipment.customCharges.${customChargeIdx}.billingMethod`,
              customChargeBillingMethod,
            );
            setValue(
              `orderChargesShipment.customCharges.${customChargeIdx}.useAccessorialRate`,
              true,
            );
            setIsSwitchingAccessorial(true);
          }}
        />
        {customCharge?.billingMethod === CustomChargeBillingMethod.AdHoc && (
          <Controller
            name={`orderChargesShipment.customCharges.${customChargeIdx}.name`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth sx={{ maxWidth: 350 }}>
                <TextField
                  disabled={disabledIfFinalizedOrLater}
                  label="Name"
                  size="small"
                  value={value}
                  onChange={onChange}
                />
              </FormControl>
            )}
          />
        )}
      </Box>
      <DescriptionComponent
        keyString={customChargeKey}
        disabled={disabledIfInvoicePosted}
      />
    </>
  );

  return (
    <TableRow
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <TableCell>{AccessorialAutocomplete}</TableCell>
      <TableCell sx={{ minWidth: 180 }}>
        <Box
          sx={{
            mt: inBillingReview ? undefined : '16px',
          }}
        >
          <CustomChargeRateInput
            isStopCustomCharge={false}
            stopIdx={0}
            customCharge={customCharge}
            customChargeIdx={customChargeIdx}
            currentAccessorial={undefined}
            disabled={
              disabledIfFinalizedOrLater ||
              isSwitchingAccessorial ||
              loadingCurrentAccessorial
            }
          />
        </Box>
        {!inBillingReview && (
          <Typography color={theme.palette.grey[500]}>
            Fuel surcharge:{' '}
            {loadingCurrentAccessorial
              ? '-'
              : (customCharge?.postedFuelSurchargeRate ??
                customCharge?.fuelSurchargePercentageRate ??
                0)}
            %
          </Typography>
        )}
      </TableCell>
      <TableCell>
        <FormNumberInput
          nonNegativeInteger
          fieldName={`orderChargesShipment.customCharges.${customChargeIdx}.quantity`}
          label="Quantity"
          disabled={
            disabledIfFinalizedOrLater ||
            isSwitchingAccessorial ||
            loadingCurrentAccessorial
          }
        />
      </TableCell>
      <TableCell>
        {!isNil(customCharge) && !isRatingAccessorials
          ? currency(customCharge.totalCharge).format()
          : '-'}
      </TableCell>
      <TableCell>
        {!disabledIfFinalizedOrLater && (
          <Fade in={isHovering}>
            <IconButton
              onClick={() => {
                deleteCharge(customCharge?.uuid);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Fade>
        )}
      </TableCell>
    </TableRow>
  );
};

export default OrderChargesCustomChargeRow;
