import { useCallback, useState } from 'react';

type HasUnsavedChanges = {
  hasUnsavedChanges: boolean;
  triggerHasUnsavedChanges: () => void;
  resetHasUnsavedChanges: () => void;
};

export const useHasUnsavedChanges = (): HasUnsavedChanges => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const triggerHasUnsavedChanges = useCallback(() => {
    setHasUnsavedChanges(true);
  }, []);
  const resetHasUnsavedChanges = useCallback(() => {
    setHasUnsavedChanges(false);
  }, []);

  return {
    hasUnsavedChanges,
    triggerHasUnsavedChanges,
    resetHasUnsavedChanges,
  };
};
