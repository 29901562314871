import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { sentenceCase } from 'change-case';
import currency from 'currency.js';
import { sum } from 'lodash';
import { type AccessorialOption } from '../../../../../common/components/accessorial-filter-button';
import { type Option } from '../../../../../common/filters/types';
import {
  type AccessorialChargeDetailsDataFragment,
  type ContactAccessorialChargeDetailsDataFragment,
  type MeQuery,
} from '../../../../../generated/graphql';

const TEXT_PADDING = '3px';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 25,
    fontFamily: 'Roboto',
  },
  header1: {
    fontSize: '18px',
  },
  header2: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  rowWithSpaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  filterLabelText: {
    fontWeight: 'bold',
    width: '75px',
  },
  contactBlock: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12px',
    marginTop: 4,
  },
  contactBlockHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#f0eded',
    padding: 4,
  },
  invoicesTable: {
    width: '100%',
  },
  invoicesTableRow: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 2,
    paddingBottom: 2,
    justifyContent: 'space-between',
    fontSize: '8px',
    width: '100%',
    textAlign: 'center',
  },
  invoicesTableHeader: {
    borderTop: 'none',
    fontWeight: 'bold',
    borderBottom: '1px solid black',
  },
  invoicesTableRowLeftSection: {
    display: 'flex',
    flexDirection: 'row',
    width: '80%',
    textAlign: 'left',
  },
  invoicesTableRowRightSection: {
    display: 'flex',
    flexDirection: 'row',
    width: '20%',
    textAlign: 'right',
  },
  customerCol: {
    width: '50%',
    paddingRight: TEXT_PADDING,
  },
  contactRefNumCol: {
    width: '25%',
    paddingRight: TEXT_PADDING,
  },
  countCol: {
    width: '25%',
  },
  totalCol: {
    width: '100%',
  },
});

const AccessorialDetailsTable = ({
  accessorialDetails,
}: {
  readonly accessorialDetails: AccessorialChargeDetailsDataFragment;
}) => {
  let total = 0;

  return (
    <View style={styles.invoicesTable}>
      <View style={[styles.invoicesTableRow, styles.invoicesTableHeader]}>
        <View style={styles.invoicesTableRowLeftSection}>
          <Text style={styles.customerCol}>Customer</Text>
          <Text style={styles.contactRefNumCol}>Account ID</Text>
          <Text style={styles.countCol}>Shipment Count</Text>
        </View>
        <View style={styles.invoicesTableRowRightSection}>
          <Text style={styles.totalCol}>Total</Text>
        </View>
      </View>
      {accessorialDetails.chargeDetailsByContact.map(
        (contactDetails: ContactAccessorialChargeDetailsDataFragment) => {
          total += contactDetails.totalAccessorialCharge;
          return (
            // eslint-disable-next-line react/jsx-key
            <View style={[styles.invoicesTableRow]}>
              <View style={styles.invoicesTableRowLeftSection}>
                <Text style={styles.customerCol}>
                  {contactDetails.displayName}
                </Text>
                <Text style={styles.contactRefNumCol}>
                  {contactDetails.contactReferenceNumber}
                </Text>
                <Text style={styles.countCol}>
                  {contactDetails.shipmentsWithAccessorialCount}
                </Text>
              </View>
              <View style={styles.invoicesTableRowRightSection}>
                <Text style={styles.totalCol}>
                  {currency(contactDetails.totalAccessorialCharge).format()}
                </Text>
              </View>
            </View>
          );
        },
      )}
      <View style={styles.invoicesTableRow}>
        <View
          style={[
            styles.invoicesTableRowLeftSection,
            { justifyContent: 'flex-end' },
          ]}
        >
          <Text style={{ fontWeight: 'bold' }}>
            {accessorialDetails.accessorialName} totals:{' '}
          </Text>
        </View>
        <View style={styles.invoicesTableRowRightSection}>
          <Text style={styles.totalCol}>{currency(total).format()}</Text>
        </View>
      </View>
    </View>
  );
};

export type GeneratedAccessorialDetailsReportProps = {
  readonly companyData: MeQuery | undefined;
  readonly status: string;
  readonly dateString: string;
  readonly terminalOption?: Option | null | undefined;
  readonly accessorialOption?: AccessorialOption | null | undefined;
  readonly accessorialDetails: AccessorialChargeDetailsDataFragment[];
  readonly terminalsEnabled: boolean;
};

const GeneratedAccessorialDetailsReport = ({
  companyData,
  status,
  dateString,
  terminalOption,
  accessorialOption,
  accessorialDetails,
  terminalsEnabled,
}: GeneratedAccessorialDetailsReportProps) => {
  const total = sum(
    accessorialDetails.flatMap((accDetails) =>
      accDetails.chargeDetailsByContact.flatMap(
        (contactDetails: ContactAccessorialChargeDetailsDataFragment) =>
          contactDetails.totalAccessorialCharge,
      ),
    ),
  );

  return (
    <Document title="Accessorial Details Report">
      <Page size="LETTER" style={styles.page}>
        <View
          style={[
            styles.rowWithSpaceBetween,
            {
              marginTop: '10px',
              fontSize: '12px',
            },
          ]}
        >
          <Text style={styles.header1}>Accessorial Details Report</Text>
        </View>
        <View
          style={[
            styles.rowWithSpaceBetween,
            {
              fontSize: '12px',
            },
          ]}
        >
          <Text style={styles.header2}>{companyData?.me?.company.name}</Text>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '10px',
            fontSize: '12px',
          }}
        >
          <View style={styles.rowWithSpaceBetween}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontSize: '10px',
              }}
            >
              <Text style={styles.filterLabelText}>Invoice Date</Text>
              <Text style={{ marginLeft: '10px' }}>{dateString}</Text>
            </View>
          </View>
          <View style={styles.rowWithSpaceBetween}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontSize: '10px',
              }}
            >
              <Text style={styles.filterLabelText}>Accessorial</Text>
              <Text style={{ marginLeft: '10px' }}>
                {accessorialOption?.label ?? 'All'}
              </Text>
            </View>
          </View>
          {terminalsEnabled && (
            <View style={styles.rowWithSpaceBetween}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  fontSize: '10px',
                }}
              >
                <Text style={styles.filterLabelText}>Terminal</Text>
                <Text style={{ marginLeft: '10px' }}>
                  {terminalOption?.label ?? 'All'}
                </Text>
              </View>
            </View>
          )}
          <View style={styles.rowWithSpaceBetween}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontSize: '10px',
              }}
            >
              <Text style={styles.filterLabelText}>Invoice Status</Text>
              <Text style={{ marginLeft: '10px' }}>{sentenceCase(status)}</Text>
            </View>
          </View>
        </View>
        {accessorialDetails.map((accessorialDetailsSection) => {
          return (
            <View
              key={accessorialDetailsSection.accessorialUuid}
              style={styles.contactBlock}
            >
              <View style={styles.contactBlockHeader}>
                <Text style={{ fontWeight: 'bold' }}>
                  {accessorialDetailsSection.accessorialName}
                </Text>
              </View>
              <AccessorialDetailsTable
                accessorialDetails={accessorialDetailsSection}
              />
            </View>
          );
        })}
        <View style={styles.contactBlock}>
          <View style={styles.invoicesTable}>
            <View style={styles.invoicesTableRow}>
              <View
                style={[
                  styles.invoicesTableRowLeftSection,
                  { justifyContent: 'flex-end' },
                ]}
              >
                <Text style={{ fontWeight: 'bold' }}>Report total: </Text>
              </View>
              <View style={styles.invoicesTableRowRightSection}>
                <Text style={{ width: '100%' }}>
                  {currency(total).format()}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default GeneratedAccessorialDetailsReport;
