import { isEmpty, isNil } from 'lodash';
import { assertNotNil } from 'shared/optional';
import { shallow } from 'zustand/shallow';
import {
  type OutstandingOrdersForReviewQueryVariables,
  useConsolidatableOrderSummaryLazyQuery,
  useOutstandingOrderLazyQuery,
  useOutstandingOrdersForReviewLazyQuery,
} from '../../../generated/graphql';
import useBillingReviewStore from '../billing-review-store';

const useBillingReviewActions = () => {
  const [
    outstandingOrdersInPage,
    searchedOrders,
    openedOutstandingOrderUuid,
    outstandingOrderUuidsInReview,
    setOutstandingOrder,
    setOutstandingOrdersInPage,
    setOutstandingOrderUuidsInReview,
    setOpenedOutstandingOrderUuid,
    setSearchedOrder,
    setQueriedProratedOrderOnOutstandingOrder,
    setQueriedProratedOrderOnSearchedOrder,
    resetStore,
    setPaginationInfo,
    setOrdersListDataLoading,
  ] = useBillingReviewStore(
    (state) => [
      state.outstandingOrdersInPage,
      state.searchedOrders,
      state.openedOutstandingOrderUuid,
      state.outstandingOrderUuidsInReview,
      state.setOutstandingOrder,
      state.setOutstandingOrdersInPage,
      state.setOutstandingOrderUuidsInReview,
      state.setOpenedOutstandingOrderUuid,
      state.setSearchedOrder,
      state.setQueriedProratedOrderOnOutstandingOrder,
      state.setQueriedProratedOrderOnSearchedOrder,
      state.resetStore,
      state.setPaginationInfo,
      state.setOrdersListDataLoading,
    ],
    shallow,
  );
  const [getOutstandingOrder] = useOutstandingOrderLazyQuery();
  const [getConsolidatableOrderSummaries] =
    useConsolidatableOrderSummaryLazyQuery();
  const [outstandingOrdersQuery] = useOutstandingOrdersForReviewLazyQuery();

  const fetchProratedOrderCacheFirst = async ({
    orderUuid,
    parentOrderUuid,
    bypassCache,
  }: {
    orderUuid: string;
    parentOrderUuid: string;
    bypassCache?: boolean;
  }) => {
    const cachedOrder = outstandingOrdersInPage.find(
      (o) => o.uuid === parentOrderUuid,
    );
    const cachedSearchedOrder = searchedOrders.find(
      (o) => o.uuid === parentOrderUuid,
    );

    const cachedProratedOrder =
      cachedOrder?.queriedProratedOrdersWith?.find(
        (o) => o.uuid === orderUuid,
      ) ??
      cachedSearchedOrder?.queriedProratedOrdersWith?.find(
        (o) => o.uuid === orderUuid,
      );

    if (bypassCache !== true && !isNil(cachedProratedOrder)) {
      return cachedProratedOrder;
    }

    // Fetch order from backend if not already stored in state
    const res = await getOutstandingOrder({
      variables: {
        uuid: orderUuid,
      },
    });
    const order = res.data?.standardOrder;
    if (!isNil(order)) {
      if (!isNil(cachedOrder)) {
        setQueriedProratedOrderOnOutstandingOrder(order, cachedOrder.uuid);
      }
      if (!isNil(cachedSearchedOrder)) {
        setQueriedProratedOrderOnSearchedOrder(order, cachedSearchedOrder.uuid);
      }
      return order;
    }
    return null;
  };

  const fetchOrderCacheFirst = async ({
    orderUuid,
    bypassCache,
    addToSearchedOrders,
  }: {
    orderUuid?: string;
    bypassCache?: boolean;
    addToSearchedOrders?: boolean;
  }) => {
    if (isNil(orderUuid) || isEmpty(orderUuid)) {
      return null;
    }
    const cachedOrder = outstandingOrdersInPage.find(
      (o) => o.uuid === orderUuid,
    );
    const cachedSearchedOrder = searchedOrders.find(
      (o) => o.uuid === orderUuid,
    );
    if (bypassCache !== true) {
      if (!isNil(cachedOrder)) {
        return cachedOrder;
      }
      if (!isNil(cachedSearchedOrder)) {
        return cachedSearchedOrder;
      }
    }

    // use the prorated order-specific function if the uuid passed in is a prorated order
    if (isNil(cachedOrder) && isNil(cachedSearchedOrder)) {
      const outstandingOrder = outstandingOrdersInPage.find((o) =>
        o.queriedProratedOrdersWith?.some((p) => p.uuid === orderUuid),
      );
      const searchedOrder = searchedOrders.find((o) =>
        o.queriedProratedOrdersWith?.some((p) => p.uuid === orderUuid),
      );
      if (!isNil(outstandingOrder)) {
        const res = await fetchProratedOrderCacheFirst({
          orderUuid,
          parentOrderUuid: outstandingOrder.uuid,
          bypassCache,
        });
        return res;
      }
      if (!isNil(searchedOrder)) {
        const res = await fetchProratedOrderCacheFirst({
          orderUuid,
          parentOrderUuid: searchedOrder.uuid,
          bypassCache,
        });
        return res;
      }
    }

    // Fetch order from backend if not already stored in state
    const res = await getOutstandingOrder({
      variables: {
        uuid: orderUuid,
      },
    });
    const order = res.data?.standardOrder;
    if (!isNil(order)) {
      if (!isNil(cachedOrder)) {
        setOutstandingOrder(order);
      }
      if (!isNil(cachedSearchedOrder) || addToSearchedOrders === true) {
        setSearchedOrder(order);
      }
      return order;
    }
    return null;
  };

  const refetchOrdersAndOverwriteCache = async (orderUuids: string[]) => {
    const cachedOrders = outstandingOrdersInPage.filter(
      (o) =>
        orderUuids.includes(o.uuid) ||
        // refresh if another order's consolidatable orders list includes one
        // that we want to be refetched.
        !isNil(
          o.consolidatableOrders.orders.find((co) =>
            orderUuids.includes(co.uuid),
          ),
        ),
    );
    const cachedSearchedOrders = searchedOrders.filter(
      (o) =>
        orderUuids.includes(o.uuid) ||
        // refresh if another order's consolidatable orders list includes one
        // that we want to be refetched.
        !isNil(
          o.consolidatableOrders.orders.find((co) =>
            orderUuids.includes(co.uuid),
          ),
        ),
    );

    for (const cachedOrder of cachedOrders) {
      const res = await getOutstandingOrder({
        variables: {
          uuid: cachedOrder.uuid,
        },
      });
      if (res.data?.standardOrder != null)
        setOutstandingOrder(res.data?.standardOrder);
    }

    for (const cachedSearchedOrder of cachedSearchedOrders) {
      const res = await getOutstandingOrder({
        variables: {
          uuid: cachedSearchedOrder.uuid,
        },
      });
      if (res.data?.standardOrder != null)
        setSearchedOrder(res.data?.standardOrder);
    }

    // orders not initially in the modal that we need to fetch and add.
    const newOrderUuidsToFetch = orderUuids.filter(
      (uuid) =>
        !cachedOrders.some((o) => o.uuid === uuid) &&
        !cachedSearchedOrders.some((o) => o.uuid === uuid),
    );

    for (const uuid of newOrderUuidsToFetch) {
      const res = await getOutstandingOrder({
        variables: {
          uuid,
        },
      });
      if (res.data?.standardOrder != null)
        setSearchedOrder(res.data?.standardOrder);
    }
  };

  const fetchConsolidatableOrdersSummaries = async () => {
    const currentOrder = await fetchOrderCacheFirst({
      orderUuid: openedOutstandingOrderUuid,
    });
    if (!isNil(currentOrder)) {
      const res = await getConsolidatableOrderSummaries({
        variables: {
          uuids:
            currentOrder.consolidatableOrders?.orders.map((o) => o.uuid) ?? [],
        },
      });

      return res.data?.ordersByUuids;
    }
    return [];
  };

  const fetchAndAddSearchedOrder = async (orderUuid: string) => {
    await fetchOrderCacheFirst({
      orderUuid,
      addToSearchedOrders: true,
    });
  };

  const fetchOutstandingOrdersAndSetStore = async ({
    queryVariables,
    orderUuidToOpen,
    openLastOrderOnPage,
    uuids,
    isInitialFetch,
  }: {
    queryVariables: OutstandingOrdersForReviewQueryVariables;
    orderUuidToOpen?: string;
    openLastOrderOnPage: boolean;
    uuids?: string[];
    isInitialFetch?: boolean;
  }) => {
    setOrdersListDataLoading(true);
    const result = await outstandingOrdersQuery({
      variables: queryVariables,
    });

    // put the consolidated orders last.
    // Filter out orders that are within another order's ordersProratedWith
    // Create a Set to keep track of orders we've seen in prorated groups
    const seenInProratedGroups = new Set<string>();

    const outstandingOrders =
      result.data?.outstandingOrdersForReview?.edges.map((e) => e.node);

    const uuidsToUse = uuids ?? outstandingOrderUuidsInReview;

    try {
      assertNotNil(outstandingOrders);
      assertNotNil(uuidsToUse);

      const filteredOutUuids: string[] = [];
      // Filter the results in the returned page.
      const filteredResult = outstandingOrders.filter((order) => {
        if (
          !isNil(order.ordersProratedWith) &&
          order.ordersProratedWith.length > 0
        ) {
          // This is a prorated group
          const groupOrderUuids = order.ordersProratedWith.map((o) => o.uuid);

          // If we haven't seen any order from this group, keep this one
          if (!groupOrderUuids.some((uuid) => seenInProratedGroups.has(uuid))) {
            // Mark all orders in this group as seen
            for (const uuid of groupOrderUuids) seenInProratedGroups.add(uuid);
            seenInProratedGroups.add(order.uuid);
            return true;
          }
          filteredOutUuids.push(order.uuid);
          return false;
        }
        // Keep non-prorated orders
        return true;
      });

      const finalList = filteredResult;

      setOutstandingOrdersInPage(finalList);

      const filteredUuids = uuidsToUse.filter(
        (uuid) => !filteredOutUuids.includes(uuid),
      );

      setOutstandingOrderUuidsInReview(filteredUuids);

      const ordersLength = finalList.length;
      if (!isNil(orderUuidToOpen)) {
        setOpenedOutstandingOrderUuid(orderUuidToOpen);
      } else if (
        openLastOrderOnPage &&
        !isNil(ordersLength) &&
        ordersLength > 0
      ) {
        const nextOpenedUuid = finalList[ordersLength - 1]?.uuid;
        setOpenedOutstandingOrderUuid(nextOpenedUuid);
      } else {
        const nextOpenedUuid = finalList[0]?.uuid;
        setOpenedOutstandingOrderUuid(nextOpenedUuid);
      }

      if (isInitialFetch === true) {
        setPaginationInfo({
          hasNextPage:
            result.data?.outstandingOrdersForReview?.pageInfo.hasNextPage ??
            false,
          hasPrevPage:
            result.data?.outstandingOrdersForReview?.pageInfo.hasPreviousPage ??
            false,
          startCursor:
            result.data?.outstandingOrdersForReview?.pageInfo.startCursor ??
            null,
          endCursor:
            result.data?.outstandingOrdersForReview?.pageInfo.endCursor ?? null,
          lastPaginationArgs: {
            ...queryVariables,
          },
        });
      }
      setOrdersListDataLoading(false);
      return result;
    } catch {
      // TODO: add an error message
      return null;
    }
  };

  const clearBillingReviewData = () => {
    resetStore();
  };

  return {
    fetchOrderCacheFirst,
    fetchProratedOrderCacheFirst,
    fetchConsolidatableOrdersSummaries,
    refetchOrdersAndOverwriteCache,
    fetchAndAddSearchedOrder,
    clearBillingReviewData,
    fetchOutstandingOrdersAndSetStore,
  };
};

export default useBillingReviewActions;
