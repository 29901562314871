import { Button, type ButtonProps } from '@mui/material';
import {
  type AnchorHTMLAttributes,
  forwardRef,
  type MouseEvent,
  type PropsWithChildren,
} from 'react';
import { useNavigate } from 'react-router-dom';

type ButtonLinkProps = PropsWithChildren<{
  readonly href: string;
  readonly target?: AnchorHTMLAttributes<HTMLAnchorElement>['target'];
}> &
  ButtonProps;

const ButtonLink = forwardRef<HTMLButtonElement, ButtonLinkProps>(
  ({ href, target, ...buttonProps }, ref) => {
    const navigate = useNavigate();
    const handleClick = (event: MouseEvent) => {
      if (event.metaKey || event.ctrlKey) {
        // CMD or CTRL key is held, so open in a new tab.
        window.open(href, '_blank');
      } else {
        navigate(href);
      }
    };
    return <Button {...buttonProps} ref={ref} onClick={handleClick} />;
  },
);

export default ButtonLink;
