function Env() {
  return (
    <>
      MODE: {import.meta.env.MODE}
      <br />
      VITE_RENDER_ENV: {import.meta.env.VITE_RENDER_ENV}
      <br />
      VITE_APP_ENV: {import.meta.env.VITE_APP_ENV}
    </>
  );
}

export default Env;
