import { Checkbox, TableCell, TableRow } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { isProduction } from '../../../../../environment-variables';
import { type OrderFragmentForEdiReviewFragment } from '../../../../../generated/graphql';

type ReviewEdiOrdersTableRowProps = {
  readonly ediOrder: OrderFragmentForEdiReviewFragment;
  readonly rowIsSelected: boolean;
  readonly setRowAsSelected: () => void;
  readonly unselectRow: () => void;
  readonly hasPendingChanges: boolean;
};

const useStyles = () => ({
  truncatedTableCell: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    paddingLeft: '4px',
    paddingRight: '4px',
  },
  paddedTableCell: {
    paddingLeft: '4px',
    paddingRight: '4px',
  },
});

const ReviewEdiOrdersTableRow = ({
  ediOrder,
  rowIsSelected,
  setRowAsSelected,
  unselectRow,
  hasPendingChanges,
}: ReviewEdiOrdersTableRowProps) => {
  const [isHovering, setIsHovering] = useState<boolean>(false);

  const selectRow = async () => {
    setRowAsSelected();
  };

  const checkboxChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      selectRow();
    } else if (!hasPendingChanges) {
      unselectRow();
    }
  };

  const styles = useStyles();

  // QA would like to keep source column for testing purposes
  const shouldShowSource = !isProduction();

  return (
    <TableRow
      sx={[
        isHovering && { backgroundColor: 'lightgray', cursor: 'pointer' },
        rowIsSelected && { cursor: 'default' },
      ]}
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      onClick={() => {
        if (!rowIsSelected) selectRow();
      }}
    >
      <TableCell sx={styles.paddedTableCell}>
        <Checkbox checked={rowIsSelected} onChange={checkboxChanged} />
      </TableCell>
      <TableCell
        sx={styles.truncatedTableCell}
      >{`${ediOrder?.name}`}</TableCell>
      <TableCell sx={styles.truncatedTableCell}>
        {ediOrder.standardOrderFields.shipperBillOfLadingNumber}
      </TableCell>
      {shouldShowSource && (
        <TableCell sx={styles.truncatedTableCell}>{ediOrder?.source}</TableCell>
      )}
      <TableCell sx={styles.truncatedTableCell}>
        {ediOrder?.contact.displayName}
      </TableCell>
      <TableCell sx={styles.truncatedTableCell}>
        {dayjs(ediOrder.createdAt).format('MM/DD/YY hh:mm a')}
      </TableCell>
    </TableRow>
  );
};

export default React.memo(ReviewEdiOrdersTableRow);
