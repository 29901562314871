import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Alert,
  Box,
  Button,
  Chip,
  Divider,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { isEmpty, isNil, union } from 'lodash';
import { type FunctionComponent, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddressAutocompleteForm from '../../../../common/components/address-autocomplete-form';
import { ErrorsAlert } from '../../../../common/components/errors-alert';
import { FeatureFlag } from '../../../../common/feature-flags';
import { useErrors } from '../../../../common/react-hooks/use-errors';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import useTerminals from '../../../../common/react-hooks/use-terminals';
import {
  type TerminalFragment,
  TerminalsDocument,
  useCreateAddressMutation,
  useTerminalQuery,
  useUpdateAddressMutation,
  useUpdateTerminalMutation,
} from '../../../../generated/graphql';
import theme from '../../../../theme';
import type { AddressFormField } from '../../../addresses/redux/addresses-values-slice';
import ArchiveActionComponent, {
  ArchiveableEntity,
} from '../common/archive-action-component';
import {
  type ServiceRegionOption,
  ServiceRegionSelect,
} from '../service-region-select/service-region-select';
import {
  type TerminalOption,
  TerminalSelect,
} from '../terminal-select/terminal-select';
import TerminalServiceAreas from './terminal-service-areas';

type EditTerminalProps = {
  readonly uuid: string;
};

const EditTerminal: FunctionComponent<EditTerminalProps> = ({ uuid }) => {
  const navigate = useNavigate();
  const ffLineHaulNetworks = useFeatureFlag(FeatureFlag.FF_LINE_HAUL_NETWORKS);
  const [showSaveSuccessAlert, setShowSaveSuccessAlert] = useState(false);
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [serviceRegion, setServiceRegion] =
    useState<ServiceRegionOption | null>(null);
  const [currentAddress, setCurrentAddress] = useState<
    AddressFormField | undefined
  >(undefined);
  // The terminals to which there is a lane starting at this terminal.
  const [laneEndTerminals, setLaneEndTerminals] = useState<TerminalOption[]>(
    [],
  );
  const [showAddTerminalLane, setShowAddTerminalLane] = useState(false);
  // The temporary form state of the terminal selector when adding a lane.
  const [addLaneEndTerminal, setAddLaneEndTerminal] =
    useState<TerminalOption | null>(null);

  const resetFormState = (terminal: TerminalFragment) => {
    setCode(terminal.code);
    setName(terminal.name);
    setServiceRegion(terminal.serviceRegion ?? null);
    setCurrentAddress({
      ...terminal.address,
      isLocal: true,
    });
    setLaneEndTerminals(
      terminal.startForTerminalLanes.map((lane) => lane.endTerminal),
    );
    setShowAddTerminalLane(false);
    setAddLaneEndTerminal(null);
  };

  const { errors, onError, clearErrors } = useErrors();
  const { data: terminalData, refetch } = useTerminalQuery({
    onError,
    onCompleted: ({ terminal }) => {
      resetFormState(terminal);
    },
    variables: {
      uuid,
    },
  });
  const { refetchTerminals } = useTerminals({
    includeInactiveTerminals: false,
    onError,
  });
  const [createAddress, { loading: createAddressLoading }] =
    useCreateAddressMutation({
      onError,
    });
  const [updateAddress, { loading: updateAddressLoading }] =
    useUpdateAddressMutation({
      onError,
      refetchQueries: [TerminalsDocument],
    });
  const [updateTerminal, { loading: updateTerminalLoading }] =
    useUpdateTerminalMutation({
      onError,
      onCompleted: (data) => {
        if (
          data.updateTerminalV2.__typename === 'UpdateTerminalSuccessOutput'
        ) {
          setShowSaveSuccessAlert(true);
          // This refetch is needed for the Terminals page and the rest of the app.
          void refetchTerminals();
          const { terminal } = data.updateTerminalV2;
          resetFormState(terminal);
        } else {
          onError(data.updateTerminalV2);
        }
      },
      refetchQueries: [TerminalsDocument],
    });

  const handleAddressChange = useCallback(
    (_isAutofillChange: boolean, newAddress?: AddressFormField) => {
      if (isNil(newAddress)) {
        setCurrentAddress(undefined);
      } else {
        setCurrentAddress(newAddress);
      }
    },
    [],
  );

  const reloadTerminal = () => {
    void refetch();
  };

  const terminal = terminalData?.terminal;

  const handleSave = async () => {
    if (isNil(terminal)) {
      return;
    }
    if (isEmpty(code)) {
      onError('Please enter a terminal code.');
      return;
    }
    if (isEmpty(name)) {
      onError('Please enter a terminal name');
      return;
    }
    if (ffLineHaulNetworks && isNil(serviceRegion)) {
      onError('Please select a service region.');
      return;
    }
    if (isNil(currentAddress)) {
      onError('Please enter an address.');
      return;
    }

    try {
      let newAddressUuid: string | undefined;
      if (currentAddress.uuid === terminal.address.uuid) {
        await updateAddress({
          variables: {
            input: {
              addressUpdateInput: {
                uuid: terminal.address.uuid,
                name: currentAddress?.name ?? '',
                line1: currentAddress?.line1 ?? '',
                city: currentAddress?.city ?? '',
                state: currentAddress?.state ?? '',
                zip: currentAddress?.zip ?? '',
                country: currentAddress?.country ?? '',
                preventCoordRecompute: false,
                latitude: null,
                longitude: null,
              },
            },
          },
        });
      } else {
        const { data } = await createAddress({
          variables: {
            input: {
              addressCreateInput: {
                name: currentAddress?.name ?? '',
                line1: currentAddress?.line1 ?? '',
                city: currentAddress?.city ?? '',
                state: currentAddress?.state ?? '',
                zip: currentAddress?.zip ?? '',
                country: currentAddress?.country ?? '',
              },
            },
          },
        });
        if (isNil(data)) {
          // Errors are handled at the hook level.
          return;
        }
        newAddressUuid = data.createAddress.uuid;
      }
      void updateTerminal({
        variables: {
          input: {
            uuid: terminal.uuid,
            code,
            name,
            serviceRegionId: serviceRegion?.id,
            addressUuid: newAddressUuid ?? terminal.address.uuid,
            laneEndTerminalUuids: laneEndTerminals.map((t) => t.uuid),
          },
        },
      });
    } catch {
      onError('An error occurred. Please try again.');
    }
  };

  const handleArchiveOrUnarchive = async ({
    shouldArchive,
  }: {
    shouldArchive: boolean;
  }) => {
    if (isNil(terminal)) {
      return;
    }
    try {
      await updateTerminal({
        variables: {
          input: {
            uuid: terminal.uuid,
            isActive: !shouldArchive,
          },
        },
      });
      void refetchTerminals();
      navigate(-1);
      setShowSaveSuccessAlert(true);
    } catch {
      onError('An error occurred. Please try again.');
    }
  };

  return (
    <Box p={3}>
      <Stack direction="row" justifyContent="space-between" mb={2}>
        <Button
          variant="outlined"
          size="small"
          onClick={async () => {
            navigate(-1);
          }}
        >
          <ArrowBackIcon sx={{ mr: '10px' }} /> Back
        </Button>
        <Button
          variant="contained"
          disabled={
            createAddressLoading ||
            updateAddressLoading ||
            updateTerminalLoading
          }
          onClick={handleSave}
        >
          Save
        </Button>
      </Stack>
      <ErrorsAlert errors={errors} onClear={clearErrors} />
      {!isNil(terminal) && (
        <Stack direction="row" gap={3} mt={1}>
          <Stack flexBasis="40%">
            <Typography variant="h6" mb={2}>
              Terminal information
            </Typography>
            <Stack direction="column" gap={theme.spacing(2)}>
              <Stack direction="row" gap={1}>
                <TextField
                  label="Terminal Code"
                  helperText="A unique identifier, e.g., GSO"
                  size="small"
                  value={code.toUpperCase()}
                  onChange={(e) => {
                    setCode(e.target.value.toUpperCase());
                  }}
                />
                <TextField
                  label="Terminal Name"
                  size="small"
                  sx={{ flexGrow: 1 }}
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </Stack>
              {ffLineHaulNetworks && (
                <ServiceRegionSelect
                  value={serviceRegion}
                  onChange={setServiceRegion}
                  onError={onError}
                />
              )}
              <AddressAutocompleteForm
                currentAddress={currentAddress}
                handleChange={handleAddressChange}
              />
              {ffLineHaulNetworks && (
                <Box>
                  <Typography variant="h6" mb={1}>
                    Line haul lanes
                  </Typography>
                  <Typography>
                    This terminal has line haul lanes to the following
                    terminals:
                    {laneEndTerminals.length === 0 && (
                      <Typography
                        color="text.secondary"
                        component="span"
                        pl={0.5}
                      >
                        (None)
                      </Typography>
                    )}
                  </Typography>
                  {laneEndTerminals.length > 0 && (
                    <Stack direction="row" flexWrap="wrap" gap={1} mt={2}>
                      {laneEndTerminals.map((laneEndTerminal) => (
                        <Chip
                          key={laneEndTerminal.uuid}
                          label={
                            <span style={{ fontWeight: 700 }}>
                              {laneEndTerminal.code}
                            </span>
                          }
                          sx={{
                            borderRadius: '4px',
                          }}
                          onDelete={() => {
                            setLaneEndTerminals((terminals) =>
                              terminals.filter(
                                (t) => t.uuid !== laneEndTerminal.uuid,
                              ),
                            );
                          }}
                        />
                      ))}
                    </Stack>
                  )}
                  {showAddTerminalLane ? (
                    <Stack direction="row" gap={2} mt={2}>
                      <TerminalSelect
                        value={addLaneEndTerminal}
                        sx={{ width: theme.spacing(15) }}
                        onChange={setAddLaneEndTerminal}
                        onError={onError}
                      />
                      <Button
                        size="small"
                        variant="outlined"
                        disabled={
                          createAddressLoading ||
                          updateAddressLoading ||
                          updateTerminalLoading ||
                          isNil(addLaneEndTerminal)
                        }
                        onClick={() => {
                          if (isNil(addLaneEndTerminal)) {
                            return;
                          }
                          setLaneEndTerminals((terminals) =>
                            union(terminals, [addLaneEndTerminal]),
                          );
                          setShowAddTerminalLane(false);
                          setAddLaneEndTerminal(null);
                        }}
                      >
                        Add
                      </Button>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          setShowAddTerminalLane(false);
                          setAddLaneEndTerminal(null);
                        }}
                      >
                        Cancel
                      </Button>
                    </Stack>
                  ) : (
                    <Button
                      size="small"
                      variant="contained"
                      sx={{ mt: 2 }}
                      onClick={() => {
                        setShowAddTerminalLane(true);
                      }}
                    >
                      Add lane
                    </Button>
                  )}
                </Box>
              )}
              <Box
                mt={2}
                sx={{
                  border: `1px solid ${theme.palette.borderColor.main}`,
                  p: 2,
                  borderRadius: '10px',
                }}
              >
                <ArchiveActionComponent
                  entityType={ArchiveableEntity.LINE_HAUL_STATION}
                  isActive={terminal.isActive}
                  handleArchive={() => {
                    handleArchiveOrUnarchive({ shouldArchive: true });
                  }}
                  handleUnarchive={() => {
                    handleArchiveOrUnarchive({ shouldArchive: false });
                  }}
                  disableUnarchive={
                    !isNil(terminal.serviceRegion) &&
                    terminal.serviceRegion.isArchived
                  }
                />
              </Box>
            </Stack>
          </Stack>
          <Divider orientation="vertical" />
          <TerminalServiceAreas
            terminalUuid={terminal.uuid}
            serviceAreas={terminal.serviceAreas}
            reloadTerminal={reloadTerminal}
          />
        </Stack>
      )}
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showSaveSuccessAlert}
        onClose={() => {
          setShowSaveSuccessAlert(false);
        }}
      >
        <Alert> Saved </Alert>
      </Snackbar>
    </Box>
  );
};

export default EditTerminal;
