/* eslint-disable react/jsx-props-no-spreading */
import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Typography,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import type React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { type FileRejection } from 'react-dropzone';
import Dropzone from '../../../../../../common/components/upload/dropzone';
import {
  ImageGrid,
  type ImageInformation,
} from '../../../../../../common/components/upload/image-grid';
import { grabFileType } from '../../../../../../common/utils/file';
import { useCsvOrderMappingsQuery } from '../../../../../../generated/graphql';
import useSubmitCsvOrder, {
  type ParseCsvOrdersOnCompleted,
} from '../../../../hooks/use-submit-csv-order';
import CreateCsvOrdersConfirmationMessage, {
  type ParseResultData,
} from './create-csv-orders-confirmation-message';

const FILE_TYPE = new Set(['text/csv']);

export const styles = {
  centeredRow: {
    display: 'flex',
    justifyContent: 'center',
  },
  imageGridWithImages: {
    height: 200,
    display: 'flex',
    alignItems: 'center',
    marginTop: '75px',
  },
} as Record<string, React.CSSProperties>;

const UploadContent = ({
  onParseCompleted,
}: {
  readonly onParseCompleted: ParseCsvOrdersOnCompleted;
}) => {
  const [images, setImages] = useState<ImageInformation[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [shouldShowLoader, setShouldShowLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedCsvOrderMappingUuid, setSelectedCsvOrderMappingUuid] =
    useState<string>('');
  const { submitCsvOrders, loading: submitIsLoading } = useSubmitCsvOrder();

  const { data: csvOrderMappingsData } = useCsvOrderMappingsQuery();
  const options = useMemo(() => {
    return (csvOrderMappingsData?.csvOrderMappings ?? []).map(
      (csvOrderMapping) => {
        return (
          <MenuItem key={csvOrderMapping.uuid} value={csvOrderMapping.uuid}>
            {csvOrderMapping.name}
          </MenuItem>
        );
      },
    );
  }, [csvOrderMappingsData]);

  useEffect(() => {
    const uuid = csvOrderMappingsData?.csvOrderMappings?.at(0)?.uuid;
    if (!isNil(uuid) && !isEmpty(uuid)) {
      setSelectedCsvOrderMappingUuid(uuid);
    }
  }, [csvOrderMappingsData]);

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (fileRejections.length > 0) {
        const newErrorMessage =
          'Please upload a CSV file. You uploaded a file type we do not accept.';
        setErrorMessage(newErrorMessage);
        return [];
      }
      let fileFinishedNumber = 0;
      setShouldShowLoader(true);
      acceptedFiles.map((file) => {
        setErrorMessage('');
        if (!FILE_TYPE.has(file.type)) {
          const fileType = grabFileType(file.name);
          const newErrorMessage =
            fileType !== undefined && fileType.length > 0
              ? `Please upload a CSV file. We do not accept ${fileType} files`
              : `Please upload a CSV file. You uploaded a file type we do not accept.`;
          setErrorMessage(newErrorMessage);
          setShouldShowLoader(false);
          return [];
        }

        const reader = new FileReader();
        reader.addEventListener('load', (e) => {
          fileFinishedNumber += 1;
          if (fileFinishedNumber === acceptedFiles.length) {
            setShouldShowLoader(false);
          }
          setImages((prevState) => [
            ...prevState,
            {
              src: e?.target?.result ?? '',
              name: file.name,
              type: file.type,
            },
          ]);
        });
        reader.readAsDataURL(file);
        setFiles((prevState) => [...prevState, file]);
        return file;
      });
      setShouldShowLoader(false);
      return [];
    },
    [],
  );

  const handleCsvOrderMappingChange = (e: SelectChangeEvent) => {
    setSelectedCsvOrderMappingUuid(e.target.value);
  };

  return (
    <Grid container spacing={2} height="100%">
      <Grid item xs={12}>
        <FormControl sx={{ marginTop: '7px' }}>
          <InputLabel id="import-csv-template-label">Template</InputLabel>
          <Select
            label="Template"
            labelId="import-csv-template-label"
            size="small"
            style={{ width: '300px' }}
            value={selectedCsvOrderMappingUuid}
            onChange={handleCsvOrderMappingChange}
          >
            {options}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ textAlign: 'center' }}>
          <strong>Please upload one file at a time.</strong>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Dropzone
          errorMessage={errorMessage}
          maxFiles={1}
          accept={{ 'text/csv': [] }}
          onDrop={onDrop}
        />
      </Grid>
      <Grid
        item
        xs={6}
        sx={isEmpty(images) ? null : styles.imageGridWithImages}
      >
        <ImageGrid
          shouldShowLoader={shouldShowLoader}
          images={images}
          setImages={setImages}
          files={files}
          setFiles={setFiles}
        />
      </Grid>
      <Grid item xs={12} sx={styles.centeredRow}>
        {!isEmpty(images) && (
          <Button
            startIcon={submitIsLoading && <CircularProgress size={15} />}
            variant="contained"
            className="button"
            disabled={
              shouldShowLoader ||
              submitIsLoading ||
              isEmpty(selectedCsvOrderMappingUuid)
            }
            style={{ marginTop: '20px' }}
            onClick={async () => {
              try {
                await submitCsvOrders(
                  files,
                  onParseCompleted,
                  selectedCsvOrderMappingUuid,
                );
              } catch (error) {
                console.error('Error submitting csv orders', error);
                setErrorMessage(
                  'Error in uploading documents. Please contact support.',
                );
              }
            }}
          >
            Submit Orders
          </Button>
        )}
      </Grid>
      {errorMessage !== undefined && errorMessage.length > 0 && (
        <Grid item xs={12} sx={styles.centeredRow}>
          <Typography
            sx={{
              color: 'red',
              fontSize: '18px',
            }}
          >
            {errorMessage}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

type UploadCsvOrdersDialogProps = {
  readonly open: boolean;
  readonly setOpen: (isOpen: boolean) => void;
  readonly setShouldRefreshGrid: (show: boolean) => void;
  readonly setShowAcceptedCsvOrdersSuccessMessage: (show: boolean) => void;
  readonly setShowRejectedCsvOrdersSuccessMessage: (show: boolean) => void;
};

const UploadCsvOrdersDialog = ({
  open,
  setOpen,
  setShouldRefreshGrid,
  setShowAcceptedCsvOrdersSuccessMessage,
  setShowRejectedCsvOrdersSuccessMessage,
}: UploadCsvOrdersDialogProps) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [resultData, setResultData] = useState<ParseResultData | null>(null);
  const closeModal = () => {
    setOpen(false);
  };
  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="lg"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      PaperProps={{
        sx: isNil(resultData)
          ? null
          : {
              minHeight: '850px',
              maxHeight: '850px',
            },
      }}
    >
      <DialogTitle>Import CSV Orders</DialogTitle>
      <IconButton
        sx={{
          position: 'absolute',
          top: '1%',
          right: '1%',
          cursor: 'pointer',
        }}
        onClick={closeModal}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          paddingTop: 0,
        }}
      >
        {!isNil(errorMessage) && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {errorMessage}
          </Alert>
        )}
        {isNil(resultData) ? (
          <UploadContent
            onParseCompleted={(result) => {
              if (result.__typename === 'ParseCsvOrdersSuccessOutput') {
                setErrorMessage(null);
                setResultData(result);
              } else {
                setErrorMessage(result.message);
              }
            }}
          />
        ) : (
          <CreateCsvOrdersConfirmationMessage
            {...resultData}
            closeModal={closeModal}
            setShouldRefreshGrid={setShouldRefreshGrid}
            setShowAcceptedCsvOrdersSuccessMessage={
              setShowAcceptedCsvOrdersSuccessMessage
            }
            setShowRejectedCsvOrdersSuccessMessage={
              setShowRejectedCsvOrdersSuccessMessage
            }
            setErrorMessage={setErrorMessage}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UploadCsvOrdersDialog;
