import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  type SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { type GraphQLFormattedError } from 'graphql';
import { isEmpty, isNil } from 'lodash';
import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { getPermissionsFlags } from 'shared/roles';
import useTerminals from '../../../../common/react-hooks/use-terminals';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import {
  PermissionResource,
  useCreateUserMutation,
  useReportsEmailSenderIsVerifiedLazyQuery,
  type UserFragment,
  useRolesQuery,
  UsersDocument,
  useUpdateUserMutation,
  useUpdateUserReportsEmailMutation,
} from '../../../../generated/graphql';
import theme from '../../../../theme';
import CreateOrEdit from '../../enums/create-or-edit';

type UserForm = {
  email: string;
  reportsEmail: string;
  name: string;
  password: string;
  roleUuids: string[];
  sendSummaryEmailWithIncompleteRoutes: boolean;
  sendAllInvoices: boolean;
  terminalUuid?: string;
};

type UserModalProps = {
  readonly createOrEdit: CreateOrEdit;
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
  readonly selectedUser?: UserFragment;
  // setSelectedUser: Dispatch<SetStateAction<UserFragment | undefined>>;
  readonly companyUuid?: string;
};

const UsersModal = ({
  createOrEdit,
  open,
  setOpen,
  selectedUser,
  companyUuid,
}: UserModalProps) => {
  const styles = {
    modalInnerContainer: {
      bgcolor: 'background.paper',
      boxShadow: 24,
      color: 'black',
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      p: 4,
    } as SxProps,
  };

  const [
    showVerificationEmailSentSnackbar,
    setShowVerificationEmailSentSnackbar,
  ] = useState<boolean>(false);
  const [showVerificationErrorSnackbar, setShowVerificationErrorSnackbar] =
    useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<
    GraphQLFormattedError[] | string[]
  >([]);

  const { terminalsEnabled, terminals } = useTerminals({
    includeInactiveTerminals: false,
  });

  const { userPermissions } = useUserRoles();
  const { hasMasterPermission, canWrite: canWriteRoles } = getPermissionsFlags(
    userPermissions,
    PermissionResource.SettingsRoles,
  );

  const { data: rolesData } = useRolesQuery({
    variables: {
      findRolesInput: { includeInternalRoles: hasMasterPermission },
    },
  });

  const defaultValues: UserForm = useMemo(() => {
    return {
      email: selectedUser?.email ?? '',
      reportsEmail: selectedUser?.reportsEmail ?? '',
      name: selectedUser?.name ?? '',
      password: '',
      roleUuids: (selectedUser?.roles ?? [])?.map((role) => role.uuid),
      sendSummaryEmailWithIncompleteRoutes:
        selectedUser?.sendSummaryEmailWithIncompleteRoutes ?? false,
      sendAllInvoices: selectedUser?.sendAllInvoices ?? false,
      terminalUuid: selectedUser?.terminal?.uuid,
    };
  }, [selectedUser]);

  const { control, handleSubmit, reset, watch } = useForm<UserForm>({
    values: defaultValues,
  });

  const [createUser] = useCreateUserMutation({
    refetchQueries: [UsersDocument],
  });
  const [updateUser] = useUpdateUserMutation({
    refetchQueries: [UsersDocument],
  });
  const [updateReportsEmail, { loading: updateReportsEmailLoading }] =
    useUpdateUserReportsEmailMutation({
      refetchQueries: [UsersDocument],
    });
  const [getReportsEmailSenderIsVerified, { data: reportsEmailVerifiedData }] =
    useReportsEmailSenderIsVerifiedLazyQuery();

  const reportsEmail: UserForm['reportsEmail'] = watch('reportsEmail');

  const checkReportsEmailSenderIsVerified = useCallback(() => {
    if (!isNil(selectedUser)) {
      getReportsEmailSenderIsVerified({
        variables: {
          userUuid: selectedUser.uuid,
        },
      });
    }
  }, [selectedUser, getReportsEmailSenderIsVerified]);

  useEffect(() => {
    if (!isNil(selectedUser) && open) {
      checkReportsEmailSenderIsVerified();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser?.uuid, open]);

  const saveReportsEmail = async () => {
    if (
      !isEmpty(reportsEmail) &&
      !isNil(reportsEmail) &&
      !isNil(selectedUser)
    ) {
      const res = await updateReportsEmail({
        variables: {
          userUuid: selectedUser.uuid,
          email: reportsEmail,
        },
      });
      if (isNil(res.data?.updateReportsEmail.reportsEmailSendgridSenderId)) {
        setShowVerificationErrorSnackbar(true);
      } else {
        setShowVerificationEmailSentSnackbar(true);
      }
    }
  };

  const handleReset = () => {
    reset(defaultValues);
    setErrorMessages([]);
    // setSelectedUser(undefined);
    setOpen(false);
  };

  const onSubmit = async (data: UserForm) => {
    const {
      password,
      email,
      name,
      roleUuids,
      sendSummaryEmailWithIncompleteRoutes,
      sendAllInvoices,
      terminalUuid,
    } = data;

    try {
      if (createOrEdit === CreateOrEdit.Create) {
        const { errors } = await createUser({
          variables: {
            createUserInput: {
              email,
              name,
              password,
              sendSummaryEmailWithIncompleteRoutes,
              sendAllInvoices,
              companyUuid,
              terminalUuid,
              roleUuids,
            },
          },
        });
        if (!isNil(errors) && !isEmpty(errors)) {
          setErrorMessages([...errors]);
          return;
        }
      } else if (createOrEdit === CreateOrEdit.Edit && !isNil(selectedUser)) {
        const { errors } = await updateUser({
          variables: {
            updateUserInput: {
              email,
              name,
              reportsEmail,
              password,
              sendSummaryEmailWithIncompleteRoutes,
              sendAllInvoices,
              uuid: selectedUser.uuid,
              terminalUuid,
              roleUuids,
            },
          },
        });
        if (!isNil(errors) && !isEmpty(errors)) {
          setErrorMessages([...errors]);
          return;
        }
      }

      handleReset();
    } catch (error) {
      setErrorMessages([
        `Unable to ${
          createOrEdit === CreateOrEdit.Create ? 'create' : 'edit'
        } user. Please contact support.`,
      ]);
      captureException(error);
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleReset}>
      <Box sx={styles.modalInnerContainer}>
        <Typography variant="h6">{createOrEdit} User</Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="column" gap={theme.spacing(2)}>
            <Controller
              control={control}
              name="name"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  size="small"
                  label="Name"
                  error={!isNil(fieldState.error)}
                  type="name"
                  autoComplete="off"
                />
              )}
            />
            <Controller
              control={control}
              name="email"
              rules={{ required: true, pattern: /\S+@\S+\.\S+/ }}
              render={({ field, fieldState }) => (
                <TextField
                  required
                  {...field}
                  size="small"
                  label="Email"
                  error={!isNil(fieldState.error)}
                  type="email"
                  autoComplete="off"
                />
              )}
            />
            <Controller
              control={control}
              name="password"
              rules={{
                required: createOrEdit === CreateOrEdit.Create,
              }}
              render={({ field, fieldState }) => (
                <TextField
                  required={createOrEdit === CreateOrEdit.Create}
                  {...field}
                  size="small"
                  label="Password"
                  error={!isNil(fieldState.error)}
                  autoComplete="off"
                  helperText={
                    createOrEdit === CreateOrEdit.Edit &&
                    "Entering a password will reset this user's password."
                  }
                />
              )}
            />
            <Controller
              control={control}
              name="roleUuids"
              rules={{
                required: createOrEdit === CreateOrEdit.Create,
              }}
              render={({ field }) => (
                <FormControl>
                  <InputLabel
                    id="rolesUuids"
                    shrink={!isNil(field.value) && !isEmpty(field.value)}
                  >
                    Roles
                  </InputLabel>
                  <Select
                    multiple
                    required={createOrEdit === CreateOrEdit.Create}
                    {...field}
                    size="small"
                    labelId="roleUuids"
                    label="Roles"
                    autoComplete="off"
                    disabled={!canWriteRoles}
                  >
                    {rolesData?.roles?.roles?.map((role) => {
                      return (
                        <MenuItem key={role.uuid} value={role.uuid}>
                          {role.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            />
            {terminalsEnabled && (
              <Controller
                name="terminalUuid"
                control={control}
                render={({ field }) => (
                  <FormControl>
                    <InputLabel
                      id="terminal"
                      shrink={!isNil(field.value) && !isEmpty(field.value)}
                    >
                      Terminal
                    </InputLabel>
                    <Select
                      labelId="terminal"
                      label="Terminal"
                      {...field}
                      size="small"
                      sx={{ width: '100%' }}
                    >
                      {terminals?.map((terminal) => {
                        return (
                          <MenuItem key={terminal.uuid} value={terminal.uuid}>
                            {terminal.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              />
            )}
            {createOrEdit === CreateOrEdit.Edit && (
              <Stack spacing={1}>
                <Stack direction="row" spacing={1}>
                  <Controller
                    control={control}
                    name="reportsEmail"
                    rules={{ required: false, pattern: /\S+@\S+\.\S+/ }}
                    render={({ field, fieldState }) => (
                      <FormControl fullWidth>
                        <TextField
                          {...field}
                          label="Reports Email"
                          error={!isNil(fieldState.error)}
                          value={reportsEmail}
                          size="small"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {reportsEmailVerifiedData?.reportsEmailSenderIsVerified ===
                                true ? (
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    <Typography
                                      color="success"
                                      variant="caption"
                                    >
                                      Verified
                                    </Typography>
                                    <CheckCircleIcon
                                      sx={{ fontSize: 15 }}
                                      color="success"
                                    />
                                  </Stack>
                                ) : (
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    <Typography color="error" variant="caption">
                                      Not verified
                                    </Typography>
                                    <CloseIcon
                                      sx={{ fontSize: 15 }}
                                      color="error"
                                    />
                                  </Stack>
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    )}
                  />
                  <Button
                    sx={{ minWidth: '120px' }}
                    disabled={
                      updateReportsEmailLoading || isEmpty(reportsEmail)
                    }
                    endIcon={
                      updateReportsEmailLoading && (
                        <CircularProgress size={15} />
                      )
                    }
                    variant="contained"
                    onClick={saveReportsEmail}
                  >
                    Verify
                  </Button>
                </Stack>
              </Stack>
            )}
            <Controller
              control={control}
              name="sendSummaryEmailWithIncompleteRoutes"
              defaultValue={false}
              render={({ field }) => (
                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value}
                        onChange={field.onChange}
                      />
                    }
                    label="Send Daily Email With Incomplete Routes"
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="sendAllInvoices"
              defaultValue={false}
              render={({ field }) => (
                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value}
                        onChange={field.onChange}
                      />
                    }
                    label="Send All Invoices"
                  />
                </FormControl>
              )}
            />
            <Box>
              <Button type="submit" variant="contained" sx={{ float: 'right' }}>
                Save
              </Button>
            </Box>
          </Stack>
        </form>
        {errorMessages.map((message) => {
          if (typeof message === 'string') {
            // eslint-disable-next-line react/jsx-key
            return <Typography sx={{ color: 'red' }}>{message}</Typography>;
          }
          return (
            // eslint-disable-next-line react/jsx-key
            <Typography sx={{ color: 'red' }}>{message.message}</Typography>
          );
        })}
      </Box>
      <Snackbar
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showVerificationEmailSentSnackbar}
        onClose={() => {
          setShowVerificationEmailSentSnackbar(false);
        }}
      >
        <Alert color="success">
          We have sent a verification email to {reportsEmail}.<br />
          Please confirm to enable sending from this address.
        </Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showVerificationErrorSnackbar}
        onClose={() => {
          setShowVerificationErrorSnackbar(false);
        }}
      >
        <Alert color="error">
          An error occurred - failed to send verification email.
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default UsersModal;
