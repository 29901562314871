import {
  Button,
  CircularProgress,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useDebounce } from 'use-debounce';
import { isNilOrEmptyString } from '../../../../common/utils/utils';
import {
  FilterOperator,
  type PicklistsFragment,
  useOrdersForPackingSlipQuery,
} from '../../../../generated/graphql';
import { DEBOUNCE_WAIT_TIME_MS } from '../../../orders/hooks/use-rate-order';
import { type PackingSlipFormValues } from './use-packing-slip-form';
import {
  formatAppointmentTime,
  formatDate,
  getUniqueContactsFromPicklist,
} from './utils';

type PackingSlipDetailsFormProps = {
  readonly picklist: PicklistsFragment;
  readonly onNext: () => void;
};
const PackingSlipDetailsForm = ({
  picklist,
  onNext,
}: PackingSlipDetailsFormProps) => {
  const { control, setValue } = useFormContext<PackingSlipFormValues>();
  const searchText = useWatch({ control, name: 'searchText' }) ?? '';
  const selectedOrder = useWatch({ control, name: 'order' });

  const [debouncedSearchText] = useDebounce(searchText, DEBOUNCE_WAIT_TIME_MS);
  const { data: ordersForPackingSlipData, loading } =
    useOrdersForPackingSlipQuery({
      variables: {
        searchText: debouncedSearchText,
        billingPartyContactUuidsFilter: {
          fieldLevelFilterOperator: FilterOperator.Or,
          values: getUniqueContactsFromPicklist(picklist).map((c) => c.uuid),
        },
      },
      skip: isNilOrEmptyString(debouncedSearchText),
      fetchPolicy: 'cache-and-network',
    });

  const orders =
    ordersForPackingSlipData?.orders.edges.map(({ node }) => node) ?? [];

  const handleSelectOrder = (order: PackingSlipFormValues['order']) => {
    setValue('order', order);
    onNext();
  };

  return (
    <Stack justifyContent="space-between" direction="column" flex={1}>
      <Stack direction="column">
        <Controller
          control={control}
          name="searchText"
          render={({ field }) => (
            <TextField
              label="Search orders"
              size="small"
              autoComplete="off"
              {...field}
            />
          )}
        />
        <TableContainer>
          <Table>
            <TableHead>
              <TableCell>Contact</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>HAWB</TableCell>
              <TableCell>MAWB</TableCell>
              <TableCell>Delivery date</TableCell>
              <TableCell>Delivery time</TableCell>
            </TableHead>
            {isEmpty(searchText) && !loading && (
              <TableCell
                colSpan={12}
                sx={{ width: '100%', textAlign: 'center', border: 'none' }}
              >
                Search for an order
              </TableCell>
            )}
            {loading && isEmpty(orders) && (
              <TableCell
                colSpan={12}
                sx={{ width: '100%', textAlign: 'center', border: 'none' }}
              >
                <CircularProgress />
              </TableCell>
            )}
            {!loading && isEmpty(orders) && !isEmpty(searchText) && (
              <TableCell
                colSpan={12}
                sx={{ width: '100%', textAlign: 'center', border: 'none' }}
              >
                No orders found
              </TableCell>
            )}
            {orders.map((order) => (
              <TableRow
                key={order.uuid}
                hover
                selected={selectedOrder?.uuid === order.uuid}
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  handleSelectOrder(order);
                }}
              >
                <TableCell>{order.billingPartyContact.displayName}</TableCell>
                <TableCell>{order.name}</TableCell>
                <TableCell>
                  {order.standardOrderFields.shipperBillOfLadingNumber}
                </TableCell>
                <TableCell>
                  {order.standardOrderFields.masterAirwayBillOfLadingNumber}
                </TableCell>
                <TableCell>
                  {formatDate(
                    order.outboundShipment?.standardShipmentFields
                      ?.deliveryDate,
                  ) ??
                    formatDate(
                      order?.outboundShipment?.legs[0]?.endStop?.serviceDate,
                    ) ??
                    '-'}
                </TableCell>
                <TableCell>
                  {formatAppointmentTime(
                    order?.outboundShipment?.legs[0]?.endStop?.appointmentTime,
                    order?.outboundShipment?.legs[0]?.endStop
                      ?.endAppointmentTime,
                  ) ?? '-'}
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </TableContainer>
      </Stack>
      <Stack direction="row" justifyContent="flex-end">
        <Button variant="contained" onClick={onNext}>
          Next
        </Button>
      </Stack>
    </Stack>
  );
};

export default PackingSlipDetailsForm;
