import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  // eslint-disable-next-line no-restricted-imports
  Grid,
} from '@mui/material';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import React from 'react';
import { pdfjs } from 'react-pdf';
import { type WarehouseLocationsLocationFragment } from '../../../../generated/graphql';
import WarehouseLocationsA4Pdf from './warehouse-locations-a4-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type WarehouseLocationsA4ModalProps = {
  readonly open: boolean;

  readonly onClose: () => void;

  readonly warehouseLocations: WarehouseLocationsLocationFragment[];
};

const WarehouseLocationsA4Modal = ({
  open,
  onClose,
  warehouseLocations,
}: WarehouseLocationsA4ModalProps) => {
  const warehouseLocationLabelsPdf = (
    <WarehouseLocationsA4Pdf warehouseLocations={warehouseLocations} />
  );
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        Warehouse Location Labels
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <PDFViewer showToolbar style={{ width: '100%', height: '75vh' }}>
              {warehouseLocationLabelsPdf}
            </PDFViewer>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button variant="contained">
              <PDFDownloadLink
                document={warehouseLocationLabelsPdf}
                fileName="warehouse-locations.pdf"
              >
                {({ loading }) => (loading ? 'Loading...' : 'Download')}
              </PDFDownloadLink>
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default WarehouseLocationsA4Modal;
