import { MenuItem, TextField } from '@mui/material';
import { sentenceCase } from 'change-case';
import { type ReactNode } from 'react';
import { useAvailableTariffTypes } from '../../../../common/react-hooks/use-available-tariff-types';
import { TariffGroupType } from '../../../../generated/graphql';

export const TariffGroupTypeDropdown = ({
  tariffGroupType,
  setTariffGroupType,
  disabled,
  fullWidth = true,
}: {
  readonly tariffGroupType: TariffGroupType;
  readonly setTariffGroupType: (tariffGroupType: TariffGroupType) => void;
  readonly disabled?: boolean;
  readonly fullWidth?: boolean;
}) => {
  const {
    ordinaryTariffGroupAvailable,
    lineHaulTariffGroupAvailable,
    transferTariffGroupAvailable,
    pointToPointTariffGroupAvailable,
  } = useAvailableTariffTypes();

  const options: ReactNode[] = [];

  if (ordinaryTariffGroupAvailable) {
    options.push(<MenuItem value={TariffGroupType.Ordinary}>Default</MenuItem>);
  }
  if (transferTariffGroupAvailable) {
    options.push(
      <MenuItem value={TariffGroupType.Transfer}>
        {sentenceCase(TariffGroupType.Transfer)}
      </MenuItem>,
    );
  }
  if (lineHaulTariffGroupAvailable) {
    options.push(
      <MenuItem value={TariffGroupType.LineHaul}>
        {sentenceCase(TariffGroupType.LineHaul)}
      </MenuItem>,
    );
  }
  if (pointToPointTariffGroupAvailable) {
    options.push(
      <MenuItem value={TariffGroupType.PointToPoint}>
        {sentenceCase(TariffGroupType.PointToPoint)}
      </MenuItem>,
    );
  }

  return (
    <TextField
      select
      label="Tariff Group Type"
      value={tariffGroupType}
      size="small"
      fullWidth={fullWidth}
      disabled={disabled}
      sx={{ minWidth: '150px' }}
      onChange={(e) => {
        setTariffGroupType(e.target.value as TariffGroupType);
      }}
    >
      {options}
    </TextField>
  );
};
