import { z } from 'zod';
import {
  DayOfWeekAll,
  RepeatFrequencyUnit,
} from '../../../../../generated/graphql';
import { zDateOrDatetimeString } from './zod-utils';

export const recurringOrderFrequencySchema = z.object({
  uuid: z.string().uuid(),
  startDate: zDateOrDatetimeString({
    required_error: 'Start date is required',
  }),
  endDate: zDateOrDatetimeString().nullish(),
  repeatInterval: z.number({
    required_error: 'Weekly repeat interval is required',
  }),
  repeatFrequencyUnit: z.nativeEnum(RepeatFrequencyUnit, {
    required_error: 'Repeat frequency type is required',
  }),
  daysOfMonth: z.array(z.number()),
  daysOfWeek: z.array(z.nativeEnum(DayOfWeekAll)),
});
