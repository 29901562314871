import { minutes } from '@buge/ts-units/time';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  type InputBaseComponentProps,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Stack,
  type SxProps,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { sentenceCase } from 'change-case';
import { isSameDay } from 'date-fns';
import dayjs, { type Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { differenceBy, isEmpty, isNil, isString } from 'lodash';
import React, {
  type FunctionComponent,
  type ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Controller,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { filterNotNil } from 'shared/array';
import { getNoonOfDay } from 'shared/date';
import { exhaustive } from 'shared/switch';
import { v4 } from 'uuid';
import {
  getStopAddDestinationTestId,
  getStopCardTestId,
  getStopServiceDateTestId,
  getStopTypeRadioButtonTestId,
} from '../../../../../../../utils';
import TimePickerComponent from '../../../../../../common/components/time-picker-component';
import { FeatureFlag } from '../../../../../../common/feature-flags';
import useDrivers from '../../../../../../common/react-hooks/use-drivers';
import useFeatureFlag from '../../../../../../common/react-hooks/use-feature-flag';
import useFirstRender from '../../../../../../common/react-hooks/use-first-render';
import useLineHaulLanes from '../../../../../../common/react-hooks/use-line-haul-lanes';
import useMe from '../../../../../../common/react-hooks/use-me';
import useRecoveryLocationInfos from '../../../../../../common/react-hooks/use-recovery-location-infos';
import useServices from '../../../../../../common/react-hooks/use-services';
import useTerminals from '../../../../../../common/react-hooks/use-terminals';
import {
  DeadlineType,
  FreightBillingMethod,
  FulfillmentType,
  InboundMethod,
  OrderDetailedStatus,
  OrderStatus,
  OutboundMethod,
  ShipmentStatus,
  ShipmentType,
  StopStatus,
  useDriverQualificationsQuery,
  useTerminalsForAddressLazyQuery,
} from '../../../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { dummyStopTypes, isCrossDock } from '../../../../lib/stop-types';
import { type AutocompletePerson } from '../../../standard/components/autocomplete-person';
import useStyles from '../../../styles';
import { useOrderFormEditAccess } from '../../contexts/order-form-edit-access-context';
import { isServiceDateFieldDisabled } from '../../forms/service-date-utils';
import {
  StopType,
  convertServiceStopTypeToOrderFormStopType,
  stopTypeOptions,
  StopMethod,
} from '../../forms/stop-type';
import {
  type AddressOptionalValues,
  type AddressValues,
  type StopValues,
  type OrderFormFieldValues,
} from '../../forms/types';
import { OrderFormEditAccess } from '../../forms/use-order-form-edit-access';
import { addressIsEmpty } from '../../forms/utils';
import { useUpdateShipmentToMatchExpected } from '../../hooks/use-expected-order-components';
import { useServiceDateUtilities } from '../../hooks/use-service-date-utilities';
import useSpecialChargeUpdate from '../../hooks/use-special-charge-update';
import {
  isValidStopTypeForSpecialAccessorialCharges,
  stopTypeLabel,
} from '../../utils';
import { INBOUND_STOP_IDX, OUTBOUND_STOP_IDX } from '../constants';
import { OrderFormCard } from '../order-form-card';
import { OrderFormCardTitle } from '../order-form-card-title';
import {
  DemoShipmentType,
  useShipmentTypeDemoFieldValue,
} from '../shipment-type-demo-field';
import TerminalAutocompleteComponent from '../terminal-autocomplete-component';
import Address from './address';
import StopLocationInfo from './stop-location-info';

dayjs.extend(customParseFormat);
const INCOMPLETE_DATE_FORMATS = ['MM/DD', 'MM/D', 'MM', 'M'];

export const stopIsEmpty = (stop: StopValues | null | undefined) => {
  if (isNil(stop) || stop.stopType === StopType.None) {
    return true;
  }
  // Check address
  if (!addressIsEmpty(stop.address) || !isEmpty(stop.terminalUuid)) {
    return false;
  }
  // Check dates
  if (
    !isNil(stop.deadlineTime) ||
    !isNil(stop.deadlineDate) ||
    !isNil(stop.deliveryDate) ||
    !isNil(stop.appointmentTime) ||
    !isNil(stop.endAppointmentTime) ||
    !isNil(stop.outboundDeadlineDate) ||
    !isNil(stop.inboundDeadlineDate) ||
    !isNil(stop.routeDate) ||
    !isNil(stop.expectedInboundArrivalDate) ||
    !isNil(stop.expectedOutboundDate) ||
    !isNil(stop.serviceDate) ||
    !isNil(stop.completedAt) ||
    !isNil(stop.arrivedAt)
  ) {
    return false;
  }
  // Check route + notes
  if (
    !isEmpty(stop.driverUuid) ||
    !isEmpty(stop.proofOfDeliverySignee) ||
    !isEmpty(stop.equipmentNames) ||
    !isEmpty(stop.routeUuid) ||
    !isEmpty(stop.routeSlotUuid) ||
    !isEmpty(stop.specialInstructions) ||
    !isEmpty(stop.notes) ||
    !isEmpty(stop.documents)
  ) {
    return false;
  }
  // Check contact person
  const { contactPerson } = stop;
  if (
    !isEmpty(contactPerson?.email) ||
    !isEmpty(contactPerson?.firstName) ||
    !isEmpty(contactPerson?.lastName) ||
    !isEmpty(contactPerson?.notes) ||
    !isEmpty(contactPerson?.phone)
  ) {
    return false;
  }
  // Check destinations
  if (
    !isEmpty(stop.destinationAirport) ||
    !isEmpty(stop.incomingCarrier) ||
    !isEmpty(stop.outboundCarrier) ||
    !isEmpty(stop.airportInfoUuid)
  ) {
    return false;
  }
  return true;
};

type StopDetailsProps = {
  readonly index: number;
  readonly method: StopMethod;
  readonly otherStopIndex: number;
  readonly isQuotePage: boolean;
  readonly forceEditMode?: boolean;
  readonly recurringTemplate?: boolean;
  readonly showDocumentComponent?: boolean;
  readonly isEditMode: boolean;
  readonly autocompletePerson: AutocompletePerson | null;
  readonly setAutocompletePerson: (person: AutocompletePerson | null) => void;
  readonly sx?: SxProps;
};

const StopDetails: FunctionComponent<StopDetailsProps> = ({
  index,
  method,
  otherStopIndex,
  isQuotePage,
  forceEditMode,
  recurringTemplate = false,
  showDocumentComponent,
  isEditMode,
  autocompletePerson,
  setAutocompletePerson,
  sx,
}) => {
  const theme = useTheme();
  const ffCourierv1 = useFeatureFlag(FeatureFlag.FF_COURIER_V1);
  const ffContactNameFieldsCombined = useFeatureFlag(
    FeatureFlag.FF_CONTACT_NAME_FIELDS_COMBINED,
  );
  const ffNoRecoveryTransfer = useFeatureFlag(
    FeatureFlag.FF_NO_RECOVERY_TRANSFER,
  );
  const { data: driverQualificationsData } = useDriverQualificationsQuery({
    fetchPolicy: 'cache-and-network',
  });
  const { control, getValues, setValue } =
    useFormContext<OrderFormFieldValues>();
  const { errors } = useFormState({ control });
  const styles = useStyles();
  const { updateServiceDate, outboundServiceDateRequired } =
    useServiceDateUtilities();
  const [getTerminalsForAddress, { data: terminalsForAddress }] =
    useTerminalsForAddressLazyQuery();
  const { recoveryLocationInfos } = useRecoveryLocationInfos();
  const stopLocationInfoUuid = useWatch({
    control,
    name: `stops.${index}.airportInfoUuid`,
  });
  const { useAllCaps } = useMe();
  const uppercaseInputProps: InputBaseComponentProps = useAllCaps
    ? { style: { textTransform: 'uppercase' } }
    : {};

  const terminalUuid = useWatch({
    control,
    name: `stops.${index}.terminalUuid`,
  });

  const isSpecial = useWatch({
    control,
    name: `stops.${index}.isSpecial`,
  });
  const appointmentTime = useWatch({
    control,
    name: `stops.${index}.appointmentTime`,
  });
  const endAppointmentTime = useWatch({
    control,
    name: `stops.${index}.endAppointmentTime`,
  });
  const deliveryDate = useWatch({
    control,
    name: `stops.${index}.deliveryDate`,
  });
  const serviceUuid = useWatch({ control, name: 'serviceUuid' });
  const { specialErrorMessage, specialForStopLoading, onCheckIsSpecial } =
    useSpecialChargeUpdate({
      feedbackOnly: true,
      dataLoading: false,
      stopIndex: index,
    });

  const { lineHaulEnabled } = useLineHaulLanes({
    includeInactiveTerminals: false,
  });
  const lineHaulLaneUuid = useWatch({ control, name: 'lineHaulLaneUuid' });

  const { drivers, getDriverName } = useDrivers();
  const { services } = useServices();
  const fulfillmentType = useWatch({
    control,
    name: 'fulfillmentType',
  });

  const [showTerminalPicker, setShowTerminalPicker] = useState(false);
  const [isDriverSearchTextEmpty, setIsDriverSearchTextEmpty] =
    useState<boolean>(true);
  const specialInstructions = useWatch({
    control,
    name: `stops.${index}.specialInstructions`,
  });
  const addressSpecialInstructions = useWatch({
    control,
    name: `stops.${index}.address.specialInstructions`,
  });
  const [modifyAddressSpecialInstructions] = useState(
    !isNil(addressSpecialInstructions) &&
      specialInstructions === addressSpecialInstructions,
  );
  const type = useWatch({ control, name: `stops.${index}.stopType` });
  const stopMethod = method;

  const otherStopType = useWatch({
    control,
    name: `stops.${otherStopIndex}.stopType`,
  });
  const otherAppointmentTime = useWatch({
    control,
    name: `stops.${otherStopIndex}.appointmentTime`,
  });
  const otherEndAppointmentTime = useWatch({
    control,
    name: `stops.${otherStopIndex}.endAppointmentTime`,
  });
  const otherStopDriverUuid = useWatch({
    control,
    name: `stops.${otherStopIndex}.driverUuid`,
  });
  const destinationInInbound = useWatch({
    control,
    name: `stops.${index}.destinationInInbound`,
  });
  const destinationInOutbound = useWatch({
    control,
    name: `stops.${index}.destinationInOutbound`,
  });
  const ffRecoveryTransferAddressOnly = useFeatureFlag(
    FeatureFlag.FF_RECOVERY_TRANSFER_ADDRESS_ONLY,
  );
  const ffShowTransferAddress = useFeatureFlag(
    FeatureFlag.FF_SHOW_TRANSFER_ADDRESS,
  );
  const ffDemoLoadManagement = useFeatureFlag(
    FeatureFlag.FF_DEMO_LOAD_MANAGEMENT,
  );
  const [demoShipmentType] = useShipmentTypeDemoFieldValue();
  const orderStatus = useWatch({ control, name: 'status' });
  const orderOnHand = useWatch({ control, name: 'onHand' });
  const orderReceivedAtOriginDate = useWatch({
    control,
    name: 'receivedAtOriginDate',
  });
  const firstRender = useFirstRender();
  const { terminals, terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const stopStatus = useWatch({ control, name: `stops.${index}.status` });
  const stopServiceDate = useWatch({
    control,
    name: `stops.${index}.serviceDate`,
  });
  const otherStopStatus = useWatch({
    control,
    name: `stops.${otherStopIndex}.status`,
  });
  const [warningMessage, setWarningMessage] = useState<string>('');
  const otherShipperAddress = useWatch({
    control,
    name: `stops.${otherStopIndex}.shipperAddress`,
  });
  const otherConsigneeAddress = useWatch({
    control,
    name: `stops.${otherStopIndex}.consigneeAddress`,
  });
  const otherConsigneeContactPerson = useWatch({
    control,
    name: `stops.${otherStopIndex}.consigneeContactPerson`,
  });
  const otherShipperContactPerson = useWatch({
    control,
    name: `stops.${otherStopIndex}.shipperContactPerson`,
  });

  const address = useWatch({
    control,
    name: `stops.${index}.address`,
  });
  const currentShipperAddress = useWatch({
    control,
    name: `stops.${index}.shipperAddress`,
  });
  const currentConsigneeAddress = useWatch({
    control,
    name: `stops.${index}.consigneeAddress`,
  });
  const currentShipperContactPerson = useWatch({
    control,
    name: `stops.${index}.shipperContactPerson`,
  });
  const currentConsigneeContactPerson = useWatch({
    control,
    name: `stops.${index}.consigneeContactPerson`,
  });

  const shipperAddress = currentShipperAddress ?? otherShipperAddress;
  const consigneeAddress = currentConsigneeAddress ?? otherConsigneeAddress;
  const shipperContactPerson =
    currentShipperContactPerson ?? otherShipperContactPerson;
  const consigneeContactPerson =
    currentConsigneeContactPerson ?? otherConsigneeContactPerson;

  const typeOptions = stopTypeOptions(method);

  const { editAccess, disabledIfFinalizedOrLater, disabledIfInvoicePosted } =
    useOrderFormEditAccess();

  const resetMiles = () => {
    if (
      getValues(`stops.${index}.freightCharge.billingMethod`) ===
      FreightBillingMethod.Tariff
    ) {
      setValue(`stops.${index}.miles`, null);
    }
  };

  const setTerminal = (newTerminalUuid?: string | null) => {
    if (isNil(newTerminalUuid)) {
      setValue(`stops.${index}.terminalUuid`, null);
      resetMiles();
      return;
    }
    if (isNil(terminalUuid)) {
      setValue(`stops.${index}.terminalUuid`, newTerminalUuid);
      resetMiles();
      return;
    }
    if (terminalUuid === newTerminalUuid) {
      return;
    }
    const currentTerminal = terminals?.find((t) => t.uuid === terminalUuid);
    const newTerminal = terminals?.find((t) => t.uuid === newTerminalUuid);
    if (isNil(currentTerminal) || isNil(newTerminal)) return;

    resetMiles();
    setValue(`stops.${index}.terminalUuid`, newTerminalUuid);
  };

  const setAddress = (newAddress: NonNullable<AddressOptionalValues>) => {
    setValue(`stops.${index}.address`, {
      uuid: newAddress.uuid,
      city: newAddress.city ?? '',
      country: newAddress.country ?? '',
      line1: newAddress.line1 ?? '',
      line2: newAddress.line2 ?? '',
      name: newAddress.name ?? '',
      state: newAddress.state ?? '',
      zip: newAddress.zip ?? '',
      longitude: newAddress.longitude,
      latitude: newAddress.latitude,
      preventCoordRecompute: newAddress.preventCoordRecompute,
      specialInstructions: newAddress.specialInstructions,
      internalNotes: newAddress.internalNotes,
      iataCode: newAddress.iataCode,
    });
  };

  const { updateShipmentToMatchExpected, createInitialFreightCharge } =
    useUpdateShipmentToMatchExpected();

  // Note that this function should not be made async because the effect hook below that handles serviceUuid changes
  // blocks other changes on this function.
  const changeStopType = (newStopType: StopType) => {
    setValue(`stops.${index}.miles`, null);
    setValue(`stops.${index}.appointmentRequired`, null);
    if (
      !ffRecoveryTransferAddressOnly ||
      !(
        (type === StopType.Pickup && newStopType === StopType.Recovery) ||
        (type === StopType.Recovery && newStopType === StopType.Pickup) ||
        (type === StopType.Delivery && newStopType === StopType.Transfer) ||
        (type === StopType.Transfer && newStopType === StopType.Delivery)
      )
    ) {
      setValue(`stops.${index}.address`, null);
    }
    setValue(`stops.${index}.freightCharge`, null);
    // NOTE(Dwayne): There's some really rare race condition where changing to
    // recovery doesn't always clear the freight charge. So the stop type
    // change is intentionally after clearing the freight charge but I don't
    // know if this actually fixes it.
    setValue(`stops.${index}.stopType`, newStopType);
    setValue(`stops.${index}.customCharges`, []);
    setValue(`stops.${index}.terminalsEnabled`, terminalsEnabled);
    setWarningMessage('');
    // TODO(Luke): Use updateShipmentToMatchExpected for stops here as well
    const newStopHasFreightCharge =
      !dummyStopTypes.includes(newStopType) &&
      fulfillmentType !== FulfillmentType.Dedicated;
    if (newStopHasFreightCharge) {
      createInitialFreightCharge({
        shipmentType: ShipmentType.Regular,
        stopIdx: index,
        stopType: newStopType,
      });

      setValue(`stops.${index}.hideFromBilling`, false);
      setValue(`stops.${index}.hideFromDispatch`, false);
    }
    updateShipmentToMatchExpected({ shipmentType: ShipmentType.OrderCharges });

    if (
      method === StopMethod.Inbound && // Auto-complete inbound if outbound completed
      stopStatus !== StopStatus.Completed &&
      otherStopStatus === StopStatus.Completed
    ) {
      setValue(`stops.${index}.status`, StopStatus.Completed);
      setValue(`stops.${index}.completedAt`, new Date());
      setValue(`stops.${index}.shipmentStatus`, ShipmentStatus.Delivered);
      setValue('status', OrderStatus.Delivered);
      setValue('detailedStatus', OrderDetailedStatus.Complete);
    }

    if (!isValidStopTypeForSpecialAccessorialCharges(newStopType)) {
      setValue(`stops.${index}.isSpecial`, undefined);
    }

    switch (newStopType) {
      case StopType.Delivery: {
        if (showDocumentComponent === true) {
          if (!isNil(consigneeAddress)) {
            setAddress(consigneeAddress);
          }
          if (!isNil(consigneeContactPerson)) {
            setValue(`stops.${index}.contactPerson`, consigneeContactPerson);
          }
        }
        if (otherStopType === StopType.Recovery) {
          setValue(`stops.${index}.inboundMethod`, InboundMethod.Recovery);
        } else if (otherStopType === StopType.Pickup) {
          setValue(`stops.${index}.inboundMethod`, InboundMethod.Other);
        } else {
          setValue(`stops.${index}.inboundMethod`, null);
        }
        if (otherStopType === StopType.Pickup) {
          setValue(
            `stops.${otherStopIndex}.outboundMethod`,
            OutboundMethod.LocalDelivery,
          );
        }
        if (otherStopType === StopType.PartnerCarrierDropoff) {
          setValue(`stops.${otherStopIndex}.outboundMethod`, null);
        }
        if (newStopHasFreightCharge) {
          setValue(
            `stops.${index}.freightCharge.billingMethod`,
            FreightBillingMethod.Tariff,
          );
        }
        setValue(`stops.${index}.outboundMethod`, null);
        break;
      }
      case StopType.Pickup: {
        if (showDocumentComponent === true) {
          if (!isNil(shipperAddress)) {
            setAddress(shipperAddress);
          }
          if (!isNil(shipperContactPerson)) {
            setValue(`stops.${index}.contactPerson`, shipperContactPerson);
          }
        }
        if (otherStopType === StopType.Transfer) {
          setValue(
            `stops.${index}.outboundMethod`,
            OutboundMethod.AirportTransfer,
          );
        }
        if (otherStopType === StopType.None) {
          setValue(`stops.${index}.outboundMethod`, OutboundMethod.Other);
        }
        if (otherStopType === StopType.Delivery) {
          setValue(
            `stops.${index}.outboundMethod`,
            OutboundMethod.LocalDelivery,
          );
          setValue(
            `stops.${otherStopIndex}.inboundMethod`,
            InboundMethod.Other,
          );
        }
        setValue(`stops.${index}.inboundMethod`, null);
        if (newStopHasFreightCharge) {
          setValue(
            `stops.${index}.freightCharge.billingMethod`,
            FreightBillingMethod.Tariff,
          );
        }

        /**
         * Ensure that inbound stop service date always defaults to today's date when the order is being created.
         * If the user has overriden the default date, then maintain the overriden date.
         */
        if (!isEditMode && isNil(stopServiceDate)) {
          setValue(`stops.${index}.serviceDate`, getNoonOfDay(new Date()));
        }
        break;
      }
      case StopType.Recovery: {
        setValue(
          `stops.${otherStopIndex}.inboundMethod`,
          InboundMethod.Recovery,
        );
        if (!ffRecoveryTransferAddressOnly) {
          const newRecoveryLocation = recoveryLocationInfos.find(
            (location) => location.uuid === stopLocationInfoUuid,
          )?.address;
          setValue(`stops.${index}.address`, newRecoveryLocation);
        }
        /**
         * Ensure that inbound stop service date always defaults to today's date when the order is being created.
         * If the user has overriden the default date, then maintain the overriden date.
         */
        if (!isEditMode && isNil(stopServiceDate)) {
          setValue(`stops.${index}.serviceDate`, getNoonOfDay(new Date()));
        }
        break;
      }
      case StopType.Transfer: {
        setValue(
          `stops.${otherStopIndex}.outboundMethod`,
          OutboundMethod.AirportTransfer,
        );
        if (!ffRecoveryTransferAddressOnly) {
          const newRecoveryLocation = recoveryLocationInfos.find(
            (location) => location.uuid === stopLocationInfoUuid,
          )?.address;
          if (destinationInOutbound === true) {
            setValue(`stops.${index}.address`, newRecoveryLocation);
          } else {
            setValue(`stops.${index}.address`, null);
            setTerminal(null);
          }
        }
        if (newStopHasFreightCharge) {
          setValue(
            `stops.${index}.freightCharge.billingMethod`,
            FreightBillingMethod.Tariff,
          );
        }
        break;
      }
      case StopType.PartnerCarrierDropoff: {
        setValue(`stops.${index}.inboundMethod`, InboundMethod.InboundDelivery);
        setValue(`stops.${index}.outboundMethod`, null);
        /**
         * Ensure that inbound stop service date always defaults to today's date when the order is being created.
         * If the user has overriden the default date, then maintain the overriden date.
         * If the order is being edited and the user switches stop types, ensure that the partner carrier stop
         * maintains parity between its service date and inbound arrival date.
         */
        if (!isEditMode && isNil(stopServiceDate)) {
          const serviceDate = getNoonOfDay(new Date());
          setValue(`stops.${index}.expectedInboundArrivalDate`, serviceDate);
          setValue(`stops.${index}.serviceDate`, serviceDate);
        } else if (!isNil(stopServiceDate)) {
          setValue(
            `stops.${index}.expectedInboundArrivalDate`,
            stopServiceDate,
          );
        }
        setValue(`stops.${index}.hideFromDispatch`, true);
        setValue(`stops.${index}.hideFromBilling`, true);
        break;
      }
      case StopType.PartnerCarrierPickup: {
        setValue(`stops.${index}.inboundMethod`, null);
        setValue(`stops.${index}.outboundMethod`, OutboundMethod.LocalDelivery);
        setValue(`stops.${index}.hideFromDispatch`, true);
        setValue(`stops.${index}.hideFromBilling`, true);
        /**
         * Ensure that the partner carrier stop maintains parity between its service date and outbound arrival date.
         */
        if (isNil(stopServiceDate)) {
          setValue(`stops.${index}.serviceDate`, null);
          setValue(`stops.${index}.expectedOutboundDate`, null);
        } else {
          setValue(`stops.${index}.expectedOutboundDate`, stopServiceDate);
        }
        break;
      }
      case StopType.None: {
        if (stopMethod === StopMethod.Inbound) {
          setValue(
            `stops.${otherStopIndex}.inboundMethod`,
            InboundMethod.Other,
          );
        }
        if (stopMethod === StopMethod.Outbound) {
          setValue(
            `stops.${otherStopIndex}.outboundMethod`,
            OutboundMethod.Other,
          );
        }
        setValue(`stops.${index}.address`, null);
        setTerminal(null);
        break;
      }
      default: {
        exhaustive(newStopType);
      }
    }
  };

  const StopTypeComponent = (
    <Controller
      name={`stops.${index}.stopType`}
      control={control}
      render={({ field }) => (
        <FormControl>
          <RadioGroup
            value={field}
            onChange={(e) => {
              field.onChange(e.target.value);
              changeStopType(e.target.value as StopType);
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                columnGap: '1px',
                rowGap: '3px',
              }}
            >
              {typeOptions.map((option) => {
                if (
                  ffNoRecoveryTransfer &&
                  (option === StopType.Recovery || option === StopType.Transfer)
                ) {
                  return null;
                }
                const noneStopTypeDisabled =
                  (lineHaulEnabled && !isEmpty(lineHaulLaneUuid)) ||
                  fulfillmentType === FulfillmentType.Dedicated;
                let tooltipText: string | null = null;
                let disabled = disabledIfFinalizedOrLater;
                if (
                  orderStatus === OrderStatus.Finalized ||
                  orderStatus === OrderStatus.Invoiced
                ) {
                  tooltipText = 'Unfinalize charges to change stop type';
                  disabled = true;
                } else if (stopStatus === StopStatus.Completed) {
                  /**
                   * The next three cases simplify edge cases around marking
                   * stops on hand and later changing the stop type.
                   *
                   * When marking an order "on hand" or "received at origin",
                   * we automatically complete the inbound stop, if it exists.
                   *
                   * But, if the inbound stop type is None when the order is marked
                   * OH/received and the user later changes the inbound stop type
                   * to not None, it leads to weird states where we expect an order's
                   * inbound to be complete (because it's OH/received) but it's not.
                   *
                   * See FTO-894 for more details.
                   */
                  tooltipText =
                    'Mark stop as not completed to change stop type';
                  disabled = true;
                } else if (index === INBOUND_STOP_IDX && orderOnHand === true) {
                  tooltipText =
                    'Mark order as not on hand to change inbound stop type';
                  disabled = true;
                } else if (
                  index === INBOUND_STOP_IDX &&
                  !isNil(orderReceivedAtOriginDate)
                ) {
                  tooltipText =
                    'Mark order as not received at origin to change inbound stop type';
                  disabled = true;
                } else if (option === StopType.None && noneStopTypeDisabled) {
                  tooltipText = 'Deselect line haul to set stop to None';
                  disabled = true;
                } else {
                  tooltipText = null;
                  disabled = disabledIfFinalizedOrLater;
                }
                return (
                  <Tooltip key={option} title={tooltipText} placement="top">
                    <FormControlLabel
                      disabled={disabled}
                      checked={option === type}
                      value={option}
                      control={
                        <Radio
                          inputProps={{
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            'data-testid': getStopTypeRadioButtonTestId({
                              stopIdx: index,
                              stopType: option,
                            }),
                          }}
                        />
                      }
                      label={stopTypeLabel(option)}
                      sx={{ mx: 0 }}
                    />
                  </Tooltip>
                );
              })}
            </Box>
          </RadioGroup>
          {!isNil(errors.stops?.[index]?.message) && (
            <FormHelperText sx={{ color: '#D32F2F' }}>
              {errors.stops?.[index]?.message?.toString()}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );

  useEffect(() => {
    if (
      !isNil(serviceUuid) &&
      editAccess === OrderFormEditAccess.All &&
      !isEditMode
    ) {
      const service = services.find((s) => s.uuid === serviceUuid);
      if (!isNil(service)) {
        const stop = getValues(`stops.${index}`);
        const defaultInboundType = isNil(service.defaultInboundStopType)
          ? null
          : convertServiceStopTypeToOrderFormStopType(
              service.defaultInboundStopType,
            );
        const defaultOutboundType = isNil(service.defaultOutboundStopType)
          ? null
          : convertServiceStopTypeToOrderFormStopType(
              service.defaultOutboundStopType,
            );

        let newStopType: StopType = type;
        let autocompleteAddress: AddressValues | null | undefined = null;
        if (method === StopMethod.Inbound) {
          if (!isNil(defaultInboundType) && type !== defaultInboundType) {
            if (stopIsEmpty(stop)) {
              changeStopType(defaultInboundType);
              newStopType = defaultInboundType;
            } else {
              setWarningMessage(
                `Could not change stop type to ${defaultInboundType} because stop is not empty.`,
              );
            }
          }

          // Address and appointment auto-complete, mostly for courier.
          if (newStopType === StopType.Pickup) {
            autocompleteAddress = autocompletePerson?.defaultPickupAddress;

            let newAppointmentTime = appointmentTime;
            if (
              service.defaultStartNow &&
              // Require that the delivery date of the inbound stop is either blank or today.
              (isNil(deliveryDate) || isSameDay(deliveryDate, new Date())) &&
              isNil(appointmentTime) &&
              isNil(endAppointmentTime)
            ) {
              // Set the start appointment time of the pickup stop.
              newAppointmentTime = new Date();
              setValue(`stops.${index}.appointmentTime`, newAppointmentTime);
              setValue(`stops.${index}.deliveryDate`, newAppointmentTime);
              updateServiceDate({ stopIdx: index });

              if (!isNil(service.defaultPickupPadding)) {
                // Set the end appointment time of the pickup stop.
                setValue(
                  `stops.${index}.endAppointmentTime`,
                  dayjs(newAppointmentTime)
                    .add(
                      service.defaultPickupPadding.in(minutes).amount,
                      'minute',
                    )
                    .toDate(),
                );
              }
            }
            if (
              !isNil(newAppointmentTime) &&
              !isNil(service.defaultTotalDuration) &&
              isNil(otherAppointmentTime) &&
              isNil(otherEndAppointmentTime) &&
              (otherStopType === StopType.Delivery ||
                defaultOutboundType === StopType.Delivery)
            ) {
              // Set the end appointment time of the delivery stop. We can't do this in the else branch below (for the outbound stop) because
              // we need to know the latest value of the inbound start appointment time, which may be stale in this closure when this effect
              // runs for the outbound stop.
              const newOtherEndAppointmentTime = dayjs(newAppointmentTime)
                .add(service.defaultTotalDuration.in(minutes).amount, 'minute')
                .toDate();
              setValue(
                `stops.${otherStopIndex}.endAppointmentTime`,
                newOtherEndAppointmentTime,
              );
              if (!isNil(service.defaultDeliveryPadding)) {
                const newOtherAppointmentTime = dayjs(
                  newOtherEndAppointmentTime,
                )
                  .subtract(
                    service.defaultDeliveryPadding.in(minutes).amount,
                    'minute',
                  )
                  .toDate();
                setValue(
                  `stops.${otherStopIndex}.appointmentTime`,
                  newOtherAppointmentTime,
                );
              }
              if (isNil(deliveryDate)) {
                setValue(
                  `stops.${otherStopIndex}.deliveryDate`,
                  newAppointmentTime,
                );
                updateServiceDate({ stopIdx: otherStopIndex });
              }
            }
          }
        } else {
          if (!isNil(defaultOutboundType) && type !== defaultOutboundType) {
            if (stopIsEmpty(stop)) {
              changeStopType(defaultOutboundType);
              newStopType = defaultOutboundType;
            } else {
              setWarningMessage(
                `Could not change stop type to ${defaultOutboundType} because stop is not empty.`,
              );
            }
          }
          if (newStopType === StopType.Delivery) {
            autocompleteAddress = autocompletePerson?.defaultDeliveryAddress;
          }
        }

        if (!isNil(autocompletePerson)) {
          if (addressIsEmpty(address) && !isNil(autocompleteAddress)) {
            setAddress(autocompleteAddress);
            if (isEmpty(specialInstructions)) {
              setValue(
                `stops.${index}.specialInstructions`,
                autocompleteAddress.specialInstructions,
              );
            }
          }
          // We've handled all autocompletions, so clear the autocomplete person (this field isn't saved).
          setAutocompletePerson(null);
        }
      }
    }
    // TODO: Look into making this exhaustive
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceUuid]);

  useEffect(() => {
    setValue('isCrossDock', isCrossDock(type, otherStopType));
    // TODO: Look into making this exhaustive
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, otherStopType]);

  useEffect(() => {
    /*
     * When the service type is dedicated the inbound and outbound
     * stops should have the same driver. To avoid an infinite loop
     * of each stop trying to update the other stop's driver, we only
     * change the driver for the outbound stop.
     */
    if (
      fulfillmentType === FulfillmentType.Dedicated &&
      index === OUTBOUND_STOP_IDX
    ) {
      setValue(`stops.${index}.driverUuid`, otherStopDriverUuid);
    }
    setValue('isCrossDock', isCrossDock(type, otherStopType));
    // TODO: Look into making this exhaustive
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherStopDriverUuid, fulfillmentType]);

  const TerminalPicker = (
    <Modal disableEscapeKeyDown open={showTerminalPicker}>
      <Box sx={{ ...styles.modal, width: 400, justifyContent: 'flex-start' }}>
        <Box mb={2}>
          <Typography variant="h5">Select terminal</Typography>
          <Typography variant="body1">
            This address matches multiple terminals. Please select one.
          </Typography>
        </Box>
        <Box
          sx={{
            overflow: 'auto',
          }}
        >
          {terminalsForAddress?.terminalsForAddress.terminals?.map(
            (terminal) => (
              <Button
                key={terminal.uuid}
                fullWidth
                variant="text"
                disabled={disabledIfFinalizedOrLater}
                onClick={() => {
                  setTerminal(terminal?.uuid);
                  setShowTerminalPicker(false);
                }}
              >
                {terminal?.name}
              </Button>
            ),
          )}
        </Box>
      </Box>
    </Modal>
  );

  const DeadlineDateComponent = (
    <Box
      sx={{
        mt: '7px',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        gap: '10px',
      }}
    >
      <Controller
        name={`stops.${index}.deadlineType`}
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControl>
            <InputLabel id={`stop-deadline-type-label-${index}`}>
              Deadline Type
            </InputLabel>
            <Select
              label="Deadline Type"
              id={`stop-deadline-type-${index}`}
              labelId={`stop-deadline-type-label-${index}`}
              size="small"
              value={value}
              disabled={disabledIfInvoicePosted}
              sx={{
                width: '130px',
              }}
              onChange={onChange}
            >
              {Object.values(DeadlineType).map((deadlineType) => (
                <MenuItem key={deadlineType} value={deadlineType}>
                  {sentenceCase(deadlineType)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      <Controller
        name={`stops.${index}.deadlineDate`}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Stack>
            <DatePicker
              disabled={disabledIfInvoicePosted}
              renderInput={(props) => (
                <TextField
                  size="small"
                  sx={{ width: '200px' }}
                  {...props}
                  label="Customer Deadline Date"
                  onBlur={(e) => {
                    const date = e.target.value;
                    const newDate = dayjs(date, INCOMPLETE_DATE_FORMATS);
                    if (newDate.isValid()) {
                      onChange(newDate.toDate());
                      updateServiceDate({ stopIdx: index });
                    }
                  }}
                />
              )}
              value={value}
              onChange={(date) => {
                const newDate = dayjs(date);
                onChange(newDate?.isValid() ? newDate.toDate() : null);
                updateServiceDate({ stopIdx: index });
              }}
            />
          </Stack>
        )}
      />
    </Box>
  );

  const { serviceDateInputTestId } = getStopServiceDateTestId({
    stopIdx: index,
  });

  const ServiceDateComponent = (
    <Controller
      name={`stops.${index}.serviceDate`}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl
          sx={{
            width: '140px',
            flexShrink: 0,
          }}
        >
          <DatePicker
            disabled={isServiceDateFieldDisabled({
              stopStatus,
              editAccess,
            })}
            value={value ?? null}
            renderInput={(props) => (
              <TextField
                size="small"
                {...props}
                label="Service Date"
                required={
                  index === OUTBOUND_STOP_IDX
                    ? outboundServiceDateRequired
                    : true
                }
                error={!isNil(error)}
                inputProps={{
                  // eslint-disable-next-line react/prop-types
                  ...props?.inputProps,
                  'data-testid': serviceDateInputTestId,
                }}
                onBlur={(e) => {
                  const date = e.target.value;
                  const newDate = dayjs(date, INCOMPLETE_DATE_FORMATS);
                  if (newDate.isValid()) {
                    onChange(newDate.toDate());
                  }
                }}
              />
            )}
            // Undefined shows current date
            onChange={(newDate) => {
              onChange(
                dayjs(newDate).isValid() ? dayjs(newDate).toDate() : null,
              );
            }}
          />
          {!isNil(error?.message) && (
            <FormHelperText sx={{ color: '#D32F2F' }}>
              {error?.message?.toString()}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );

  const DeliveryDateComponent = (
    <Controller
      name={`stops.${index}.deliveryDate`}
      control={control}
      render={({ field: { onChange, value } }) => (
        <DatePicker
          renderInput={(props) => (
            <TextField
              size="small"
              {...props}
              label="Appointment Date"
              onBlur={(e) => {
                const date = e.target.value;
                let newDate: Dayjs | null = dayjs(
                  date,
                  INCOMPLETE_DATE_FORMATS,
                );
                newDate = newDate.isValid() ? newDate : null;

                if (!isNil(newDate)) {
                  onChange(newDate.toDate());
                  if (type === StopType.Recovery) {
                    setValue(`stops.${index}.deadlineDate`, newDate.toDate());
                  }
                  updateServiceDate({ stopIdx: index });
                }
              }}
            />
          )}
          value={value}
          disabled={disabledIfInvoicePosted}
          onChange={(date) => {
            let newDate: Dayjs | null = dayjs(date);
            newDate = newDate.isValid() ? newDate : null;
            onChange(newDate);
            if (!isNil(newDate) && type === StopType.Recovery) {
              setValue(`stops.${index}.deadlineDate`, newDate.toDate());
            }
            updateServiceDate({ stopIdx: index });
          }}
        />
      )}
    />
  );

  let appointmentCopy = 'Start';
  appointmentCopy = type === StopType.Recovery ? 'ETA' : appointmentCopy;
  appointmentCopy = type === StopType.Transfer ? 'Deadline' : appointmentCopy;
  const AppointmentTimeComponent = (
    <Controller
      name={`stops.${index}.appointmentTime`}
      control={control}
      render={({ field: { onChange, value } }) => (
        <TimePickerComponent
          fullWidth
          hideClearable
          label={appointmentCopy}
          appointmentTime={value}
          updateAppointmentTime={(newTime: Dayjs | undefined | null) => {
            if (isNil(newTime)) {
              onChange(null);
            } else if (newTime.isValid()) {
              onChange(newTime.toDate());
            }
          }}
          disabled={disabledIfInvoicePosted}
        />
      )}
    />
  );

  const EndAppointmentTimeComponent = (
    <Controller
      name={`stops.${index}.endAppointmentTime`}
      control={control}
      render={({ field: { onChange, value } }) => (
        <TimePickerComponent
          hideClearable
          label="End"
          appointmentTime={value}
          updateAppointmentTime={(newTime: Dayjs | undefined | null) => {
            if (isNil(newTime)) {
              onChange(null);
            } else if (newTime.isValid()) {
              onChange(newTime.toDate());
            }
          }}
          disabled={disabledIfInvoicePosted}
        />
      )}
    />
  );

  const contactPerson = useWatch({
    control,
    name: `stops.${index}.contactPerson`,
  });
  const ContactComponent = (
    <Box>
      <FormLabel
        component="legend"
        sx={{
          mb: '8px',
        }}
      >
        Contact
      </FormLabel>
      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: '12px',
        }}
      >
        <FormControl>
          <TextField
            label={ffContactNameFieldsCombined ? 'Name' : 'First Name'}
            size="small"
            value={contactPerson?.firstName ?? ''}
            inputProps={uppercaseInputProps}
            disabled={disabledIfInvoicePosted}
            onChange={(e) => {
              const input = e.target.value;
              if (isNil(contactPerson)) {
                setValue(`stops.${index}.contactPerson`, {
                  uuid: v4(),
                  firstName: input,
                  lastName: undefined,
                  email: undefined,
                  phone: undefined,
                });
              } else {
                setValue(`stops.${index}.contactPerson.firstName`, input);
              }
            }}
          />
        </FormControl>
        {!ffContactNameFieldsCombined && (
          <FormControl>
            <TextField
              label="Last Name"
              size="small"
              value={contactPerson?.lastName ?? ''}
              inputProps={uppercaseInputProps}
              disabled={disabledIfInvoicePosted}
              onChange={(e) => {
                const input = e.target.value;
                if (isNil(contactPerson)) {
                  setValue(`stops.${index}.contactPerson`, {
                    uuid: v4(),
                    firstName: undefined,
                    lastName: input,
                    email: undefined,
                    phone: undefined,
                  });
                } else {
                  setValue(`stops.${index}.contactPerson.lastName`, input);
                }
              }}
            />
          </FormControl>
        )}
        <FormControl>
          <TextField
            label="Phone"
            size="small"
            value={contactPerson?.phone ?? ''}
            error={!isNil(errors.stops?.[index]?.contactPerson?.phone?.message)}
            disabled={disabledIfInvoicePosted}
            onChange={(e) => {
              if (isNil(contactPerson)) {
                setValue(`stops.${index}.contactPerson`, {
                  uuid: v4(),
                  firstName: undefined,
                  lastName: undefined,
                  email: undefined,
                  phone: e.target.value,
                });
              } else {
                setValue(`stops.${index}.contactPerson.phone`, e.target.value);
              }
            }}
          />
          {!isNil(errors.stops?.[index]?.contactPerson?.phone?.message) && (
            <FormHelperText sx={{ color: '#D32F2F' }}>
              {errors.stops?.[index]?.contactPerson?.phone?.message}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl>
          <TextField
            label="Email"
            size="small"
            value={contactPerson?.email ?? ''}
            inputProps={uppercaseInputProps}
            disabled={disabledIfInvoicePosted}
            error={!isNil(errors.stops?.[index]?.contactPerson?.email?.message)}
            onChange={(e) => {
              const input = e.target.value;
              if (isNil(contactPerson)) {
                setValue(`stops.${index}.contactPerson`, {
                  uuid: v4(),
                  firstName: undefined,
                  lastName: undefined,
                  email: input,
                  phone: undefined,
                });
              } else {
                setValue(`stops.${index}.contactPerson.email`, input);
              }
            }}
          />
          {!isNil(errors.stops?.[index]?.contactPerson?.email?.message) && (
            <FormHelperText sx={{ color: '#D32F2F' }}>
              {errors.stops?.[index]?.contactPerson?.email?.message}
            </FormHelperText>
          )}
        </FormControl>
      </Stack>
    </Box>
  );

  const NotesComponent = (
    <Controller
      name={`stops.${index}.specialInstructions`}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl fullWidth sx={{ mt: '5px' }}>
          <TextField
            multiline
            label="Special instructions"
            size="small"
            value={value ?? ''}
            minRows={1}
            inputProps={uppercaseInputProps}
            disabled={disabledIfInvoicePosted}
            onChange={(e) => {
              const input = e.target.value;
              onChange({
                ...e,
                target: { ...e.target, value: input },
              });
              if (modifyAddressSpecialInstructions) {
                setValue(`stops.${index}.address.specialInstructions`, input);
              }
            }}
          />
        </FormControl>
      )}
    />
  );

  const DestinationRecoveryLocationComponent = (
    <Controller
      name={`stops.${index}.destinationAirport`}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl fullWidth>
          <Tooltip title="3-letter IATA code for the airport of this order's final destination. If provided, printable labels will use this code over the address's airport code.">
            <TextField
              label="Final destination airport code"
              size="small"
              value={value}
              inputProps={uppercaseInputProps}
              disabled={disabledIfInvoicePosted}
              onChange={onChange}
            />
          </Tooltip>
        </FormControl>
      )}
    />
  );

  const incomingCarrier = useWatch({
    control,
    name: `stops.${index}.incomingCarrier`,
  });
  const IncomingCarrierComponent = (
    <FormControl fullWidth>
      <TextField
        label="Carrier"
        size="small"
        value={incomingCarrier}
        inputProps={uppercaseInputProps}
        disabled={disabledIfInvoicePosted}
        onChange={(e) => {
          setValue(`stops.${index}.incomingCarrier`, e.target.value);
        }}
      />
    </FormControl>
  );

  const outboundCarrier = useWatch({
    control,
    name: `stops.${index}.outboundCarrier`,
  });
  const OutboundCarrierComponent = (
    <FormControl fullWidth>
      <TextField
        label="Carrier"
        size="small"
        value={outboundCarrier}
        inputProps={uppercaseInputProps}
        disabled={disabledIfInvoicePosted}
        onChange={(e) => {
          setValue(`stops.${index}.outboundCarrier`, e.target.value);
        }}
      />
    </FormControl>
  );

  const expectedInboundArrivalDate = useWatch({
    control,
    name: `stops.${index}.expectedInboundArrivalDate`,
  });
  const ExpectedInboundArrivalDateComponent = (
    <FormControl
      sx={{
        width: '195px',
        flexShrink: 0,
      }}
    >
      <DatePicker
        value={expectedInboundArrivalDate ?? null}
        label="Inbound Arrival Date"
        renderInput={(props) => (
          <TextField
            size="small"
            {...props}
            required
            error={
              !isNil(errors.stops?.[index]?.expectedInboundArrivalDate?.message)
            }
            onBlur={(e) => {
              const date = e.target.value;
              const newDate = dayjs(date, INCOMPLETE_DATE_FORMATS);
              if (newDate.isValid()) {
                /**
                 * Uses the service date in place of the expected inbound arrival date on the backend
                 * while maintaining the concept of the inbound arrival date for partner carrier dropoffs to users.
                 *
                 * TODO: Remove the setValue call for the inbound arrival date field once its not used in core logic across the code.
                 */
                setValue(
                  `stops.${index}.expectedInboundArrivalDate`,
                  newDate.toDate(),
                );
                setValue(`stops.${index}.serviceDate`, newDate.toDate());
              }
            }}
          />
        )}
        disabled={disabledIfInvoicePosted}
        onChange={(date) => {
          const newDate = dayjs(date).isValid() ? dayjs(date).toDate() : null;
          setValue(`stops.${index}.expectedInboundArrivalDate`, newDate);
          setValue(`stops.${index}.serviceDate`, newDate);
        }}
      />
      {!isNil(errors.stops?.[index]?.serviceDate?.message) && (
        <FormHelperText sx={{ color: '#D32F2F' }}>
          {errors.stops?.[index]?.serviceDate?.message?.toString()}
        </FormHelperText>
      )}
    </FormControl>
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const expectedOutboundArrivalDateRef = useRef<any>();
  const expectedOutboundArrivalDate = useWatch({
    control,
    name: `stops.${index}.expectedOutboundDate`,
  });
  const ExpectedOutboundArrivalDateComponent = (
    <FormControl
      sx={{
        width: '195px',
        flexShrink: 0,
      }}
      onBlur={() => {
        expectedOutboundArrivalDateRef.current?.closeCalendar();
      }}
    >
      <DatePicker
        label="Outbound Arrival Date"
        value={expectedOutboundArrivalDate}
        renderInput={(props) => (
          <TextField
            size="small"
            {...props}
            required={outboundServiceDateRequired}
            onBlur={(e) => {
              const date = e.target.value;
              const newDate = dayjs(date, INCOMPLETE_DATE_FORMATS);
              if (newDate.isValid()) {
                /**
                 * Uses the service date in place of the expected outbound date on the backend
                 * while maintaining the concept of the expected outbound date for partner carrier pickups to users.
                 *
                 * TODO: Remove the setValue call for the inbound arrival date field once its not used in core logic across the code.
                 */
                setValue(
                  `stops.${index}.expectedOutboundDate`,
                  newDate.toDate(),
                );
                setValue(`stops.${index}.serviceDate`, newDate.toDate());
              }
            }}
          />
        )}
        disabled={disabledIfInvoicePosted}
        onChange={(date) => {
          const newDate = dayjs(date).isValid() ? dayjs(date).toDate() : null;
          setValue(`stops.${index}.expectedOutboundDate`, newDate);
          setValue(`stops.${index}.serviceDate`, newDate);
        }}
      />
      {!isNil(errors.stops?.[index]?.serviceDate?.message) && (
        <FormHelperText sx={{ color: '#D32F2F' }}>
          {errors.stops?.[index]?.serviceDate?.message?.toString()}
        </FormHelperText>
      )}
    </FormControl>
  );

  const AppointmentComponent = (
    <Stack direction="column" gap="2px" mt="6px">
      <Stack
        sx={{
          flexDirection: 'row',
          alignItems: 'start',
          gap: '14px',
        }}
      >
        {!recurringTemplate && (
          <Box
            sx={{
              width: '160px',
              flexShrink: 0,
            }}
          >
            {DeliveryDateComponent}
          </Box>
        )}
        {AppointmentTimeComponent}
        {EndAppointmentTimeComponent}
      </Stack>
      <Box
        sx={{
          ml: specialForStopLoading ? undefined : '-6px',
          mt: '8px',
          display: 'flex',
          flexDirection: 'row',
          gap: '12px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {specialForStopLoading ? (
            <CircularProgress
              size={16}
              thickness={5}
              sx={{
                // This specific margin prevents layout shift when the icon/checkbox swap out.
                mr: '12px',
              }}
            />
          ) : (
            <Checkbox
              checked={isSpecial}
              disabled={disabledIfFinalizedOrLater}
              inputProps={{
                id: `stop-is-special-${index}`,
              }}
              onChange={(e) => {
                onCheckIsSpecial(e.target.checked);
              }}
            />
          )}
          <FormLabel htmlFor={`stop-is-special-${index}`}>Special</FormLabel>
        </Box>
        <Controller
          name={`stops.${index}.appointmentConfirmed`}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '2px',
                alignItems: 'center',
              }}
            >
              <Checkbox
                checked={value ?? false}
                disabled={disabledIfInvoicePosted}
                inputProps={{
                  id: `stop-appointment-confirmed-${index}`,
                }}
                onChange={onChange}
              />
              <FormLabel htmlFor={`stop-appointment-confirmed-${index}`}>
                Appointment confirmed
              </FormLabel>
            </Box>
          )}
        />
        <Controller
          name={`stops.${index}.appointmentRequired`}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '2px',
                alignItems: 'center',
              }}
            >
              <Checkbox
                checked={value ?? false}
                disabled={disabledIfInvoicePosted}
                inputProps={{
                  id: `stop-appointment-required-${index}`,
                }}
                onChange={onChange}
              />
              <FormLabel htmlFor={`stop-appointment-required-${index}`}>
                Appointment required
              </FormLabel>
            </Box>
          )}
        />
      </Box>
      {!isEmpty(specialErrorMessage) && (
        <FormHelperText error>{specialErrorMessage}</FormHelperText>
      )}
    </Stack>
  );

  const driverUuid = useWatch({
    control,
    name: `stops.${index}.driverUuid`,
  });
  const driverQualificationIds = useWatch({
    control,
    name: 'driverQualificationIds',
  });

  const selectedDriver = isNil(driverUuid)
    ? null
    : drivers.find((d) => d.uuid === driverUuid);
  const missingDriverQualificationIds = isNil(selectedDriver)
    ? []
    : differenceBy(
        driverQualificationIds,
        selectedDriver.qualifications,
        (elem) => (isString(elem) ? elem : elem.id),
      );
  const missingDriverQualifications = filterNotNil(
    missingDriverQualificationIds.map((id) =>
      driverQualificationsData?.driverQualifications.find((q) => q.id === id),
    ),
  );

  const terminalRequired = terminalsEnabled && isNil(terminalUuid);

  const showServiceDateAndDriver = !recurringTemplate;

  const driverOptions = useMemo(() => {
    return (
      drivers
        ?.filter(
          (driver) =>
            !isDriverSearchTextEmpty ||
            !terminalsEnabled ||
            (isDriverSearchTextEmpty &&
              (driver.terminal?.uuid === terminalUuid ||
                isNil(driver.terminal?.uuid))),
        )
        .map((driver) => ({
          value: driver.uuid,
          label: `${
            isEmpty(driver.driverReferenceNumber)
              ? ''
              : `${driver.driverReferenceNumber} - `
          }${getDriverName(driver.uuid)}`,
          terminal:
            driver.terminal?.name ??
            (terminalsEnabled ? 'All terminals' : undefined),
        })) ?? []
    );
  }, [
    drivers,
    getDriverName,
    isDriverSearchTextEmpty,
    terminalUuid,
    terminalsEnabled,
  ]);

  const DriverSelect = (
    <Box flexGrow={1}>
      <Tooltip
        title={
          // eslint-disable-next-line no-nested-ternary
          terminalRequired
            ? 'Please select a terminal before assigning a driver'
            : isQuotePage
              ? 'A driver cannot be assigned to a quote'
              : null
        }
      >
        <AutocompleteFuzzy
          size="small"
          value={
            isNil(driverUuid)
              ? null
              : {
                  value: driverUuid,
                  label: getDriverName(driverUuid),
                  terminal: undefined,
                }
          }
          options={driverOptions}
          matchSortOptions={{ keys: ['label'] }}
          getOptionLabel={(option) => option.label}
          disabled={terminalRequired || disabledIfInvoicePosted || isQuotePage}
          renderOption={(props, option) => {
            return (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <li {...props} key={option.value}>
                <Stack>
                  <Typography sx={{ fontSize: '14px' }}>
                    {option.label}
                  </Typography>
                  {!isNil(option.terminal) && (
                    <Typography variant="caption" color="text.secondary">
                      {option.terminal}
                    </Typography>
                  )}
                </Stack>
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
              }}
              size="small"
              label="Driver"
              onChange={(e) => {
                setIsDriverSearchTextEmpty(isEmpty(e.target.value));
              }}
            />
          )}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          onChange={(_, option) => {
            if (isNil(option?.value) && !isNil(driverUuid)) {
              // Set the flag here when the value changes from non-null to null
              setValue(`stops.${index}.removedDriver`, true);
            } else if (!isNil(option?.value)) {
              setValue(`stops.${index}.removedDriver`, false);
            }
            setValue(`stops.${index}.driverUuid`, option?.value);
          }}
        />
      </Tooltip>
      {ffCourierv1 && missingDriverQualifications.length > 0 && (
        <FormHelperText sx={{ color: theme.palette.warning.main }}>
          The assigned driver is missing{' '}
          {missingDriverQualifications.map((dq) => dq.name).join(', ')}.
        </FormHelperText>
      )}
    </Box>
  );

  const serviceDateAndDriver = (
    <Stack
      sx={{
        flexDirection: 'row',
        mt: '5px',
        gap: '14px',
      }}
    >
      {ServiceDateComponent}
      {DriverSelect}
    </Stack>
  );

  const autoAssociateTerminalThroughServiceArea = async ({
    zipcode,
    city,
  }: {
    zipcode: string;
    city: string;
  }) => {
    const matchingTerminalsData = await getTerminalsForAddress({
      variables: {
        findTerminalForAddressInput: {
          zipcode,
          city,
        },
      },
    });

    if (isEmpty(matchingTerminalsData.data?.terminalsForAddress.terminals)) {
      return;
    }

    if (
      matchingTerminalsData.data?.terminalsForAddress.terminals?.length === 1
    ) {
      resetMiles();
      setTerminal(
        matchingTerminalsData.data?.terminalsForAddress.terminals?.[0]?.uuid,
      );
      return;
    }

    setShowTerminalPicker(true);
  };

  useEffect(() => {
    if (!isNil(address) && !firstRender) {
      autoAssociateTerminalThroughServiceArea({
        zipcode: address.zip,
        city: address.city,
      });
    }
    // TODO: Look into making this exhaustive
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address?.city, address?.zip]);

  const addDestinationButtonTestId = getStopAddDestinationTestId({
    stopIdx: index,
  });

  const TerminalComponent = terminalsEnabled && (
    <Controller
      name={`stops.${index}.terminalUuid`}
      control={control}
      render={({ field: { onChange } }) => (
        <TerminalAutocompleteComponent
          terminalUuid={terminalUuid}
          disabled={disabledIfFinalizedOrLater}
          error={errors.stops?.[index]?.terminalUuid}
          stopIdx={index}
          onChange={(_event, option) => {
            onChange(option?.value);
            resetMiles();
          }}
        />
      )}
    />
  );

  let body: ReactNode;

  if (type === StopType.Delivery || type === StopType.Pickup) {
    body = (
      <Stack mt="1px" gap="10px">
        <Box>
          <Address
            index={index}
            disabled={disabledIfFinalizedOrLater}
            forceEditMode={forceEditMode}
          />
        </Box>
        {TerminalComponent}
        {DeadlineDateComponent}
        {AppointmentComponent}
        {showServiceDateAndDriver && serviceDateAndDriver}
        {ContactComponent}
        {NotesComponent}
      </Stack>
    );
  }

  if (type === StopType.Recovery || type === StopType.Transfer) {
    body = (
      <Stack
        mt={
          // Less top margin when showing address fields.
          ffRecoveryTransferAddressOnly ? '1px' : '9px'
        }
        gap="10px"
      >
        {(type === StopType.Recovery ||
          (type === StopType.Transfer && destinationInOutbound === true)) && (
          <>
            {ffRecoveryTransferAddressOnly ? (
              <FormControl fullWidth>
                <Address
                  index={index}
                  disabled={disabledIfFinalizedOrLater}
                  forceEditMode={forceEditMode}
                  isTransferAddress={type === StopType.Transfer}
                />
              </FormControl>
            ) : (
              <StopLocationInfo index={index} stopType={type} />
            )}
            {type === StopType.Transfer && (
              <Box mt="5px">{DestinationRecoveryLocationComponent}</Box>
            )}
            {!isNil(TerminalComponent) && (
              <Box mt="5px">{TerminalComponent}</Box>
            )}
            {type === StopType.Transfer &&
              !ffRecoveryTransferAddressOnly &&
              ffShowTransferAddress && (
                <Box mt="5px">
                  <FormControl fullWidth>
                    <Address
                      isTransferAddress
                      index={index}
                      disabled={disabledIfFinalizedOrLater}
                      forceEditMode={forceEditMode}
                    />
                  </FormControl>
                  {ContactComponent}
                </Box>
              )}
            <Stack direction="row" alignItems="start" mt="5px" gap="14px">
              {DeliveryDateComponent}
              {AppointmentTimeComponent}
            </Stack>
            {showServiceDateAndDriver && serviceDateAndDriver}
            {NotesComponent}
            {type === StopType.Transfer &&
              (ffRecoveryTransferAddressOnly || ffShowTransferAddress) &&
              TerminalPicker}
          </>
        )}
        {type === StopType.Transfer && destinationInOutbound === true && (
          <Button
            size="small"
            disabled={disabledIfFinalizedOrLater}
            onClick={() => {
              setValue(`stops.${index}.destinationInOutbound`, false);
              setValue(
                `stops.${otherStopIndex}.outboundMethod`,
                OutboundMethod.Other,
              );
              setValue(`stops.${index}.address`, null);
              setValue(`stops.${index}.airportInfoUuid`, null);
              setValue(`stops.${index}.transferAddress`, null);
              setTerminal(null);
            }}
          >
            Remove destination
          </Button>
        )}
        {type === StopType.Transfer && destinationInOutbound !== true && (
          <Button
            size="small"
            disabled={disabledIfFinalizedOrLater}
            data-testid={addDestinationButtonTestId}
            onClick={() => {
              setValue(`stops.${index}.destinationInOutbound`, true);
              setValue(
                `stops.${otherStopIndex}.outboundMethod`,
                OutboundMethod.AirportTransfer,
              );
            }}
          >
            Add destination
          </Button>
        )}
      </Stack>
    );
  }

  if (
    type === StopType.PartnerCarrierDropoff &&
    stopMethod === StopMethod.Inbound
  ) {
    body = (
      <Stack mt="9px" gap="10px">
        {IncomingCarrierComponent}
        <Stack direction="row" alignItems="start" mt="5px" gap="14px">
          {ExpectedInboundArrivalDateComponent}
          {TerminalComponent}
        </Stack>
        {destinationInInbound === true && (
          <>
            <Box>
              <Address
                isPartnerCarrierAddress
                index={index}
                disabled={disabledIfFinalizedOrLater}
                forceEditMode={forceEditMode}
              />
            </Box>
            {ContactComponent}
          </>
        )}
        {destinationInInbound === true ? (
          <Button
            size="small"
            disabled={disabledIfFinalizedOrLater}
            onClick={() => {
              setValue(`stops.${index}.destinationInInbound`, false);
              setValue(`stops.${index}.address`, null);
              setValue(`stops.${index}.contactPerson`, null);
            }}
          >
            Remove origin information
          </Button>
        ) : (
          <Button
            size="small"
            disabled={disabledIfFinalizedOrLater}
            onClick={() => {
              setValue(`stops.${index}.destinationInInbound`, true);
            }}
          >
            Add origin information
          </Button>
        )}
      </Stack>
    );
  }

  if (
    type === StopType.PartnerCarrierPickup &&
    stopMethod === StopMethod.Outbound
  ) {
    body = (
      <Stack mt="9px" gap="10px">
        {OutboundCarrierComponent}
        <Stack direction="row" alignItems="start" mt="5px" gap="14px">
          {ExpectedOutboundArrivalDateComponent}
          {TerminalComponent}
        </Stack>
        {DestinationRecoveryLocationComponent}
        {destinationInOutbound === true && (
          <>
            <Box>
              <Address
                isPartnerCarrierAddress
                index={index}
                disabled={disabledIfFinalizedOrLater}
                forceEditMode={forceEditMode}
              />
            </Box>
            {ContactComponent}
          </>
        )}
        {destinationInOutbound === true ? (
          <Button
            size="small"
            disabled={disabledIfFinalizedOrLater}
            onClick={() => {
              setValue(`stops.${index}.destinationInOutbound`, false);
              setValue(`stops.${index}.address`, null);
              setValue(`stops.${index}.contactPerson`, null);
            }}
          >
            Remove destination information
          </Button>
        ) : (
          <Button
            size="small"
            disabled={disabledIfFinalizedOrLater}
            onClick={() => {
              setValue(`stops.${index}.destinationInOutbound`, true);
            }}
          >
            Add destination information
          </Button>
        )}
      </Stack>
    );
  }

  if (ffDemoLoadManagement && demoShipmentType === DemoShipmentType.Truckload) {
    return (
      <OrderFormCard sx={sx}>
        <Stack direction="row" alignItems="center" gap={1}>
          <OrderFormCardTitle
            title={method === StopMethod.Inbound ? 'Origin' : 'Destination'}
            sx={{ marginBottom: 0 }}
          />
        </Stack>
        <Stack mt="1px" gap="10px">
          <Box>
            <Address
              index={index}
              disabled={disabledIfFinalizedOrLater}
              forceEditMode={forceEditMode}
            />
          </Box>
          {TerminalComponent}
          {DeadlineDateComponent}
          {AppointmentComponent}
          <Stack
            sx={{
              mt: '5px',
            }}
          >
            {ServiceDateComponent}
          </Stack>
          {ContactComponent}
          {NotesComponent}
        </Stack>
      </OrderFormCard>
    );
  }

  return (
    <OrderFormCard data-testid={getStopCardTestId({ stopIdx: index })} sx={sx}>
      <Stack direction="row" alignItems="center" gap={1}>
        <OrderFormCardTitle
          title={sentenceCase(method)}
          sx={{ marginBottom: 0 }}
        />
        {StopTypeComponent}
      </Stack>
      <Typography color={theme.palette.warning.main} fontStyle="italic">
        {warningMessage}
      </Typography>
      {body}
    </OrderFormCard>
  );
};

export default React.memo(StopDetails);
