import { Check, ExpandMore } from '@mui/icons-material';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import useStyles from './general-styles';

const CACHE_PREFIX = 'SINGLE_SELECT_FILTER';

type SingleSelectFilterButtonProps<T> = {
  readonly cacheId?: string;
  readonly option: T | undefined;
  readonly handleChange: (newOption: T | undefined) => void;
  readonly options: T[];
  readonly renderOption?: (option: T) => string;
  readonly filterTitle?: string;
  readonly enableDefaultFilter?: boolean;
  readonly defaultFilterTitle?: string;
};

const SingleSelectFilterButton = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unnecessary-type-constraint
  DefaultOptionsType extends string,
>({
  cacheId,
  option,
  handleChange,
  options,
  renderOption,
  filterTitle,
  enableDefaultFilter,
  defaultFilterTitle,
}: SingleSelectFilterButtonProps<DefaultOptionsType>) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const styles = useStyles();

  useEffect(() => {
    if (!isNil(cacheId)) {
      const cachedValue = localStorage.getItem(
        `${CACHE_PREFIX}_VALUE_${cacheId}`,
      );
      handleChange(
        isNil(cachedValue) ? undefined : (cachedValue as DefaultOptionsType),
      );
    }
  }, []);

  const onChange = (newOption: DefaultOptionsType | undefined) => {
    if (!isNil(cacheId)) {
      if (isNil(newOption)) {
        localStorage.removeItem(`${CACHE_PREFIX}_VALUE_${cacheId}`);
      } else {
        localStorage.setItem(`${CACHE_PREFIX}_VALUE_${cacheId}`, newOption);
      }
    }
    handleChange(newOption);
  };

  return (
    <Box>
      <Button
        size="large"
        variant="outlined"
        sx={[styles.filterButton, { whiteSpace: 'nowrap' }]}
        onClick={(e) => {
          setMenuAnchorEl(e.currentTarget);
          e.stopPropagation();
        }}
      >
        <Box
          sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}
        >
          <Typography sx={styles.filterTitle}>{filterTitle ?? ''}</Typography>
          <Typography sx={styles.filterValue}>
            {isNil(option) ? defaultFilterTitle : sentenceCase(option)}
          </Typography>
          <ExpandMore fontSize="small" sx={{ mr: 0 }} />
        </Box>
      </Button>
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        sx={{
          '& .MuiMenu-paper': { overflow: 'visible' },
          top: '3px',
        }}
        onClose={() => {
          setMenuAnchorEl(null);
        }}
      >
        <MenuList
          dense
          sx={{
            p: 0,
          }}
        >
          {enableDefaultFilter === true && (
            <MenuItem
              key="default"
              sx={{
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'visible',
                pl: '10px',
              }}
              onClick={(e) => {
                onChange(undefined);
                e.stopPropagation();
              }}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Check
                  data-testid="boolean-and-input-filter-all-option-checkmark"
                  sx={{
                    visibility: isNil(option) ? undefined : 'hidden',
                    fontSize: '14px',
                    ml: 0,
                    mr: '6px',
                  }}
                />
                <Typography sx={styles.menuText}>
                  {defaultFilterTitle}
                </Typography>
              </Stack>
            </MenuItem>
          )}
          {options.map((opt) => (
            <MenuItem
              key={opt}
              sx={{
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'visible',
                pl: '10px',
              }}
              onClick={(e) => {
                onChange(opt);
                e.stopPropagation();
              }}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Check
                  data-testid="boolean-and-input-filter-all-option-checkmark"
                  sx={{
                    visibility: option === opt ? undefined : 'hidden',
                    fontSize: '14px',
                    ml: 0,
                    mr: '6px',
                  }}
                />
                <Typography sx={styles.menuText}>
                  {isNil(renderOption) ? sentenceCase(opt) : renderOption(opt)}
                </Typography>
              </Stack>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default SingleSelectFilterButton;
