import { isNil } from 'lodash';
import { useEffect, useMemo } from 'react';
import {
  useContactIsOverCreditLimitLazyQuery,
  useContactIsOverCreditLimitByOrderUuidLazyQuery,
} from '../../../generated/graphql';

const useContactIsOverCreditLimit = ({
  contactUuid,
}: {
  contactUuid: string | undefined | null;
}) => {
  const [getContactIsOverCreditLimit, { data: contactIsOverCreditLimitData }] =
    useContactIsOverCreditLimitLazyQuery();
  const [getContactIsOverCreditLimitByOrderUuid] =
    useContactIsOverCreditLimitByOrderUuidLazyQuery();

  useEffect(() => {
    if (!isNil(contactUuid)) {
      getContactIsOverCreditLimit({
        variables: {
          uuid: contactUuid,
        },
      });
    }
  }, [contactUuid]);

  const isOverCreditLimit = useMemo(() => {
    if (isNil(contactIsOverCreditLimitData)) return false;

    return contactIsOverCreditLimitData.contact.isOverCreditLimit;
  }, [contactIsOverCreditLimitData]);

  const isContactOverCreditLimitByOrderUuid = async (orderUuid: string) => {
    const res = await getContactIsOverCreditLimitByOrderUuid({
      variables: {
        uuid: orderUuid,
      },
    });

    const order = res.data?.order;

    return {
      uuid: orderUuid,
      shipperBillOfLadingNumber:
        order?.standardOrderFields.shipperBillOfLadingNumber,
      isOverLimit: order?.contact.isOverCreditLimit === true,
    };
  };

  return {
    isOverCreditLimit,
    isContactOverCreditLimitByOrderUuid,
  };
};

export default useContactIsOverCreditLimit;
