import { useStatsigClient } from '@statsig/react-bindings';
import { type FeatureFlag } from '../feature-flags';

/**
 * Feature flag hook
 * @param query - name of feature flag
 */

const useFeatureFlag = (name: FeatureFlag): boolean => {
  const { checkGate } = useStatsigClient();
  return checkGate(name);
};

export default useFeatureFlag;
