import {
  Alert,
  Box,
  Button,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  useTheme,
} from '@mui/material';
import dayjs, { type Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isEmpty, isNil } from 'lodash';
import { useMountEffect } from 'primereact/hooks';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import { getCurrentTimeDefaultTimezone } from 'shared/time';
import PlanningDatePicker from '../../common/components/date-picker';
import TabPanel from '../../common/components/tab-panel/tab-panel';
import useInterval from '../../common/react-hooks/use-interval';
import useMe from '../../common/react-hooks/use-me';
import useUserRoles from '../../common/react-hooks/use-user-roles';
import {
  type DocumentFragment,
  FindCompanyDocumentStatusFilter,
  PermissionResource,
  ScannedOrderResultType,
  type ScannedOrderResultWithOrdersFragment,
  SignedPodMatchStatus,
  useCompanyDocumentsLazyQuery,
  useDetachDocumentsFromShipmentsMutation,
  useOrderLazyQuery,
  useScannedOrderResultsLazyQuery,
} from '../../generated/graphql';
import useGlobalStore from '../../layouts/dashboard/global-store';
import { getPlanningDate } from '../dispatch/utils';
import { DOCUMENT_PAGE_SIZE } from './components/bulk-change-document-type';
import EndOfDayMatchDocuments from './components/end-of-day-match-documents';
import EndOfDayRoutes from './components/end-of-day-routes';
import SpecifyBolNumberPagesModal from './components/specify-bol-number-pages-modal';
import UploadEODDocumentsModal from './components/upload-eod-documents-modal';
import UploadedPodsMenu from './components/uploaded-pods-menu';
import EndOfDayContext, {
  type DocumentUuidToDocumentType,
} from './end-of-day-context';
import { type FetchCompanyDocumentsParams } from './types/company-documents';

dayjs.extend(utc);
dayjs.extend(timezone);

const PLANNING_DATE = 'planningDate';
const PLANNING_DATE_EXPIRATION = 'planningDateExpiration';

const EndOfDayPage = () => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteEndOfDay } = getPermissionsFlags(
    userPermissions,
    PermissionResource.EndOfDay,
  );

  const podButtonRef = useRef(null);
  const selectedTerminalUuid = useGlobalStore(
    (state) => state.selectedTerminalUuid,
  );
  const [documentStatusFilter, setDocumentStatusFilter] =
    useState<FindCompanyDocumentStatusFilter>(
      FindCompanyDocumentStatusFilter.Unmatched,
    );
  const [shouldLoadRoutes, setShouldLoadRoutes] = useState<boolean>(false);
  const [shouldLoadDocuments, setShouldLoadDocuments] =
    useState<boolean>(false);
  const [showUploadPodsModal, setShowUploadPodsModal] =
    useState<boolean>(false);
  const [showUploadedPodsMenu, setShowUploadedPodsMenu] =
    useState<boolean>(false);
  const [showSpecifyBolNumberPagesModal, setShowSpecifyBolNumberPagesModal] =
    useState<boolean>(false);
  const [selectedRouteUuid, setSelectedRouteUuid] = useState<
    string | undefined
  >();
  const [selectedStopUuid, setSelectedStopUuid] = useState<
    string | undefined
  >();
  const [selectedOrderToMatchUuid, setSelectedOrderToMatchUuid] = useState<
    string | undefined
  >();
  const [selectedMatchedOrderUuid, setSelectedMatchedOrderUuid] = useState<
    string | undefined
  >();
  const [scannedPodResults, setScannedPodResults] = useState<
    ScannedOrderResultWithOrdersFragment[]
  >([]);
  const [companyDocuments, setCompanyDocuments] = useState<DocumentFragment[]>(
    [],
  );
  const [selectedDocumentUuids, setSelectedDocumentUuids] = useState<string[]>(
    [],
  );
  const [documentUuidToDocumentType, setDocumentUuidToDocumentType] =
    useState<DocumentUuidToDocumentType>({});
  const [
    completedStopsNotOnRoutesSelected,
    setCompletedStopsNotOnRoutesSelected,
  ] = useState(false);
  const [getOrder, { data: orderData }] = useOrderLazyQuery();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isReloading, setIsReloading] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const localPlanningDate: string | null = localStorage.getItem(PLANNING_DATE);
  const localPlanningDateExpiration: string | null = localStorage.getItem(
    PLANNING_DATE_EXPIRATION,
  );
  const [currentDate, setCurrentDate] = useState<Dayjs>(
    getPlanningDate({
      planningDate: localPlanningDate,
      planningDateExpiration: localPlanningDateExpiration,
    }),
  );
  const theme = useTheme();

  const [showMatchOrderSuccessMessage, setShowMatchOrderSuccessMessage] =
    useState(false);
  const [showMatchOrderErrorMessage, setShowMatchOrderErrorMessage] =
    useState(false);
  const [showUnmatchOrderSuccessMessage, setShowUnmatchOrderSuccessMessage] =
    useState(false);
  const [showUnmatchOrderErrorMessage, setShowUnmatchOrderErrorMessage] =
    useState(false);

  const [getScannedOrderResults] = useScannedOrderResultsLazyQuery();

  const loadScannedPods = async () => {
    getScannedOrderResults({
      variables: {
        createdAtAfter: getCurrentTimeDefaultTimezone().startOf('day').toDate(),
        type: ScannedOrderResultType.SignedPod,
        signedPodMatchStatuses: [
          SignedPodMatchStatus.InProgress,
          SignedPodMatchStatus.Failed,
          SignedPodMatchStatus.Unmatched,
          SignedPodMatchStatus.Matched,
        ],
      },
    }).then((res) => {
      const signedPodResults = res.data?.scannedOrderResults;
      if (!isNil(signedPodResults)) {
        setScannedPodResults(
          signedPodResults.edges.map((edge) => edge.node) ?? [],
        );
        if (signedPodResults.edges.length !== scannedPodResults.length) {
          setShouldLoadRoutes(true);
        }
      }
    });
  };

  useMountEffect(() => {
    loadScannedPods();
  });

  useInterval(() => {
    loadScannedPods();
  }, 60_000);

  useEffect(() => {
    localStorage.setItem(PLANNING_DATE, currentDate.toISOString());
    const endOfToday = dayjs()
      .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .endOf('day');
    localStorage.setItem(PLANNING_DATE_EXPIRATION, endOfToday.toISOString());
  }, [currentDate]);

  const [searchText, setSearchText] = useState<string | undefined>();
  const { companyUuid } = useMe();
  const [getCompanyDocuments, { loading: companyDocumentsLoading }] =
    useCompanyDocumentsLazyQuery();
  const [detachDocumentsFromShipments] =
    useDetachDocumentsFromShipmentsMutation();

  const sortedMatchedDocuments = useMemo(() => {
    const documents = orderData?.order?.documents ?? [];
    documents.sort((a, b) => {
      if (isNil(a.pageNumber) || isNil(b.pageNumber)) {
        return 0;
      }

      return a.pageNumber - b.pageNumber;
    });
    return documents;
  }, [orderData]);

  const fetchCompanyDocuments = async ({
    first,
    after,
    last,
    before,
  }: FetchCompanyDocumentsParams) => {
    if (isNil(companyUuid) || isEmpty(companyUuid)) {
      return;
    }
    const res = await getCompanyDocuments({
      variables: {
        first,
        after,
        last,
        before,
        companyUuid,
        terminalUuid: selectedTerminalUuid,
        statusFilter: documentStatusFilter,
        date: currentDate.toISOString(),
        searchText,
        uploadDateAscending:
          documentStatusFilter === FindCompanyDocumentStatusFilter.Unmatched
            ? true
            : undefined,
      },
    });
    // const documents = (res?.data?.companyDocuments?.edges ?? [])?.map(
    //   ({ node: document }) => document,
    // );
    const documents = res?.data?.companyDocuments ?? [];
    setCompanyDocuments(documents);
  };

  const onClickUnmatchAll = async () => {
    try {
      const uuidsToRemove = sortedMatchedDocuments.map(
        (document) => document.uuid,
      );
      await detachDocumentsFromShipments({
        variables: {
          detachDocumentsFromShipmentsInput: {
            uuids: uuidsToRemove,
          },
        },
      });
      fetchCompanyDocuments({ first: DOCUMENT_PAGE_SIZE });
      setSelectedDocumentUuids(
        selectedDocumentUuids.filter((uuid) => !uuidsToRemove.includes(uuid)),
      );
      setShowUnmatchOrderSuccessMessage(true);
      if (!isNil(selectedMatchedOrderUuid)) {
        getOrder({ variables: { uuid: selectedMatchedOrderUuid } });
      }
    } catch {
      setShowUnmatchOrderErrorMessage(true);
    }
  };

  const memoizedState = useMemo(
    () => ({
      documentStatusFilter,
      setDocumentStatusFilter,
      scannedPodResults,
      setScannedPodResults,
      companyDocuments,
      setCompanyDocuments,
      shouldLoadDocuments,
      setShouldLoadDocuments,
      selectedDocumentUuids,
      setSelectedDocumentUuids,
      shouldLoadRoutes,
      setShouldLoadRoutes,
      selectedRouteUuid,
      setSelectedRouteUuid,
      selectedStopUuid,
      setSelectedStopUuid,
      selectedOrderToMatchUuid,
      setSelectedOrderToMatchUuid,
      selectedMatchedOrderUuid,
      setSelectedMatchedOrderUuid,
      currentDate,
      setCurrentDate,
      isLoading,
      setIsLoading,
      isReloading,
      setIsReloading,
      completedStopsNotOnRoutesSelected,
      setCompletedStopsNotOnRoutesSelected,
      documentUuidToDocumentType,
      setDocumentUuidToDocumentType,
    }),
    [
      documentStatusFilter,
      setDocumentStatusFilter,
      scannedPodResults,
      setScannedPodResults,
      companyDocuments,
      setCompanyDocuments,
      shouldLoadDocuments,
      setShouldLoadDocuments,
      selectedDocumentUuids,
      setSelectedDocumentUuids,
      shouldLoadRoutes,
      setShouldLoadRoutes,
      selectedRouteUuid,
      setSelectedRouteUuid,
      selectedStopUuid,
      setSelectedStopUuid,
      selectedOrderToMatchUuid,
      setSelectedOrderToMatchUuid,
      selectedMatchedOrderUuid,
      setSelectedMatchedOrderUuid,
      currentDate,
      setCurrentDate,
      isLoading,
      setIsLoading,
      isReloading,
      setIsReloading,
      completedStopsNotOnRoutesSelected,
      setCompletedStopsNotOnRoutesSelected,
      documentUuidToDocumentType,
      setDocumentUuidToDocumentType,
    ],
  );

  return (
    <EndOfDayContext.Provider value={memoizedState}>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          backgroundColor: 'white',
          overflow: 'scroll',
        }}
      >
        <Snackbar
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={showUnmatchOrderSuccessMessage}
          onClose={() => {
            setShowUnmatchOrderSuccessMessage(false);
          }}
        >
          <Alert severity="success">
            Successfully unmatched document(s) from order
          </Alert>
        </Snackbar>
        <Snackbar
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={showUnmatchOrderErrorMessage}
          onClose={() => {
            setShowUnmatchOrderErrorMessage(false);
          }}
        >
          <Alert severity="error">
            Failed to unmatch document(s) from order
          </Alert>
        </Snackbar>
        <Snackbar
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={showMatchOrderSuccessMessage}
          onClose={() => {
            setShowMatchOrderSuccessMessage(false);
          }}
        >
          <Alert severity="success">
            Successfully matched document(s) to order
          </Alert>
        </Snackbar>
        <Snackbar
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={showMatchOrderErrorMessage}
          onClose={() => {
            setShowMatchOrderErrorMessage(false);
          }}
        >
          <Alert severity="error">Failed to matched document(s) to order</Alert>
        </Snackbar>
        <Grid
          container
          spacing={0}
          sx={{ display: 'flex', height: '100%' }}
          alignItems="stretch"
        >
          <Grid
            item
            xs={12}
            height="100%"
            sx={{ padding: 0, display: 'flex', flexDirection: 'column' }}
          >
            <Stack direction="column" spacing={1} height="100%">
              <Grid container spacing={0} sx={{ pr: 1 }}>
                <Grid item xs={7}>
                  <Stack direction="row" alignItems="bottom">
                    <Tabs
                      orientation="horizontal"
                      variant="scrollable"
                      value={tabIndex}
                      aria-label="end of day tabs"
                      onChange={(e, newIndex) => {
                        setTabIndex(newIndex);
                      }}
                    >
                      <Tab label="Routes" />
                      <Tab label="Documents" />
                    </Tabs>
                  </Stack>
                </Grid>
                <Grid item xs={5}>
                  <Stack
                    direction="row-reverse"
                    alignItems="center"
                    spacing={1}
                    sx={{ mt: 1 }}
                  >
                    <PlanningDatePicker
                      date={currentDate}
                      setDate={setCurrentDate}
                      text=""
                    />
                    {tabIndex === 1 && (
                      <Stack spacing={1} direction="row">
                        <Button
                          variant="contained"
                          disabled={!canWriteEndOfDay}
                          onClick={() => {
                            setShowUploadPodsModal(true);
                          }}
                        >
                          Upload Document
                        </Button>
                        {documentStatusFilter ===
                          FindCompanyDocumentStatusFilter.Matched && (
                          <Button
                            variant="contained"
                            sx={{
                              backgroundColor: theme.palette.redColor.main,
                            }}
                            disabled={!canWriteEndOfDay}
                            onClick={onClickUnmatchAll}
                          >
                            Unmatch all
                          </Button>
                        )}
                      </Stack>
                    )}
                  </Stack>
                </Grid>
              </Grid>
              <TabPanel selectedValue={tabIndex} panelValue={0}>
                <EndOfDayRoutes />
              </TabPanel>
              <TabPanel selectedValue={tabIndex} panelValue={1}>
                <EndOfDayMatchDocuments
                  // setShowSpecifyBolNumberPagesModal={
                  //   setShowSpecifyBolNumberPagesModal
                  // }
                  // setShowMatchOrderSuccessMessage={
                  //   setShowMatchOrderSuccessMessage
                  // }
                  // setShowMatchOrderErrorMessage={setShowMatchOrderErrorMessage}
                  searchText={searchText}
                  setSearchText={setSearchText}
                  fetchCompanyDocuments={fetchCompanyDocuments}
                  companyDocumentsLoading={companyDocumentsLoading}
                  getOrder={getOrder}
                  orderData={orderData}
                />
              </TabPanel>
            </Stack>
          </Grid>
        </Grid>
        <UploadEODDocumentsModal
          open={showUploadPodsModal}
          setOpen={setShowUploadPodsModal}
        />
        <UploadedPodsMenu
          open={showUploadedPodsMenu}
          setOpen={setShowUploadedPodsMenu}
          anchorEl={podButtonRef}
        />
        <SpecifyBolNumberPagesModal
          open={showSpecifyBolNumberPagesModal}
          orderUuid={selectedOrderToMatchUuid}
          setShowMatchOrderSuccessMessage={setShowMatchOrderSuccessMessage}
          setShowMatchOrderErrorMessage={setShowMatchOrderErrorMessage}
          fetchCompanyDocuments={fetchCompanyDocuments}
          onClose={() => {
            setShowSpecifyBolNumberPagesModal(false);
          }}
        />
      </Box>
    </EndOfDayContext.Provider>
  );
};

export default EndOfDayPage;
