import { Skeleton } from '@mui/material';
import React from 'react';

const SkeletonChartLoader = ({ height }: { readonly height: number }) => {
  return (
    <Skeleton
      variant="rectangular"
      animation="wave"
      width="100%"
      height={height}
    />
  );
};

export default SkeletonChartLoader;
