import { type StatsigClient } from '@statsig/js-client';
import { isNil } from 'lodash';
import { useInterval } from 'primereact/hooks';
import { useCallback } from 'react';
import { ONE_MINUTE_IN_MS } from 'shared/constants';
import { EnvironmentVariables } from '../../environment-variables';
import { type StatsigUserDetails } from './use-statsig-client';

const ENV_FEATURE_FLAG_POLL_INTERVAL =
  EnvironmentVariables.FEATURE_FLAG_POLL_INTERVAL_SECONDS;
const DEFAULT_FEATURE_FLAG_POLL_INTERVAL = 2 * ONE_MINUTE_IN_MS;

// If the environment variable is not a number or not set, use the default poll interval
const FEATURE_FLAG_POLL_INTERVAL = Number.isNaN(
  Number(ENV_FEATURE_FLAG_POLL_INTERVAL),
)
  ? DEFAULT_FEATURE_FLAG_POLL_INTERVAL
  : Number(ENV_FEATURE_FLAG_POLL_INTERVAL);

/*
 * Polling so feature flags are refreshed when changes in the dashboard are made
 */

const useFeatureFlagPolling = (
  client: StatsigClient,
  userDetails: StatsigUserDetails,
) => {
  const { userUuid, userEmail, companyUuid } = userDetails;

  const updateStatsigUser = useCallback(() => {
    if (isNil(client)) {
      return;
    }
    client.updateUserAsync({
      userID: userUuid,
      email: userEmail,
      custom: { companyUuid },
    });
  }, [client, userUuid, userEmail, companyUuid]);
  useInterval(updateStatsigUser, FEATURE_FLAG_POLL_INTERVAL);
};

export default useFeatureFlagPolling;
