import AddIcon from '@mui/icons-material/Add';
import {
  Accordion,
  AccordionDetails,
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as EmailValidator from 'email-validator';
import { type FunctionComponent, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import {
  ContactCommunicationType,
  PermissionResource,
} from '../../../../generated/graphql';
import { AccordionSummary } from '../../../../pallet-ui/accordion/accordion-summary';
import { GridDivider } from './grid-divider';
import type { NotificationsForm } from './use-notifications-form';

type OrderSubscribersNotificationsProps = {
  readonly form: NotificationsForm['orderSubscribers'];
};

export const OrderSubscribersNotifications: FunctionComponent<
  OrderSubscribersNotificationsProps
> = ({
  form: { emails, addEmail, removeEmail, events, addEvent, removeEvent },
}) => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteContacts } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Contacts,
  );
  const [showNewEmail, setShowNewEmail] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [showInvalidEmailMessage, setShowInvalidEmailMessage] = useState(false);

  return (
    <Accordion>
      <AccordionSummary
        heading="Order subscribers"
        subheading="Email notifications to specific people when there is an order update"
      />
      <AccordionDetails>
        <Box mb={2}>
          {emails.length > 0 && (
            <Stack direction="row" flexWrap="wrap" gap={2} my={2} mb={2}>
              {emails.map((email) => (
                <Chip
                  key={email}
                  label={
                    <span style={{ fontWeight: 500, paddingRight: '2px' }}>
                      {email}
                    </span>
                  }
                  sx={{
                    borderRadius: '4px',
                  }}
                  onDelete={() => {
                    removeEmail(email);
                  }}
                />
              ))}
            </Stack>
          )}
          {showNewEmail ? (
            <>
              <Stack direction="row" alignItems="center" gap={2}>
                <TextField
                  size="small"
                  sx={{ width: '220px' }}
                  value={newEmail}
                  placeholder="Email address"
                  onChange={(e) => {
                    setNewEmail(e.target.value);
                    setShowInvalidEmailMessage(false);
                  }}
                />
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    const trimmedEmail = newEmail.trim();
                    if (!EmailValidator.validate(trimmedEmail)) {
                      setShowInvalidEmailMessage(true);
                      return;
                    }
                    addEmail(trimmedEmail);
                    setShowNewEmail(false);
                    setNewEmail('');
                  }}
                >
                  Add
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setShowNewEmail(false);
                    setNewEmail('');
                    setShowInvalidEmailMessage(false);
                  }}
                >
                  Cancel
                </Button>
              </Stack>
              {showInvalidEmailMessage && (
                <Alert severity="error" sx={{ my: 1 }}>
                  Please enter a valid email address
                </Alert>
              )}
            </>
          ) : (
            <Button
              variant="outlined"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => {
                setShowNewEmail(true);
              }}
            >
              Add an email
            </Button>
          )}
        </Box>
        <Box
          display="grid"
          gridTemplateColumns="1fr minmax(85px, max-content)"
          alignItems="center"
          gap={1}
        >
          <Typography color="text.secondary" fontSize="14px">
            Notify order subscribers when...
          </Typography>
          <Box /* Future: select all checkbox here */ />
          <GridDivider span={2} />
          <Typography fontSize="14px">Driver has arrived</Typography>
          <Box>
            <FormControlLabel
              control={<Checkbox />}
              label="Email"
              sx={{ marginRight: 0 }}
              checked={events.includes(
                ContactCommunicationType.PerOrderDriverOnSite,
              )}
              disabled={!canWriteContacts}
              onChange={(_e, checked) => {
                if (checked) {
                  addEvent(ContactCommunicationType.PerOrderDriverOnSite);
                } else {
                  removeEvent(ContactCommunicationType.PerOrderDriverOnSite);
                }
              }}
            />
          </Box>
          <GridDivider span={2} />
          <Typography fontSize="14px">Order is completed</Typography>
          <Box>
            <FormControlLabel
              control={<Checkbox />}
              label="Email"
              sx={{ marginRight: 0 }}
              checked={events.includes(
                ContactCommunicationType.PerOrderCompleted,
              )}
              disabled={!canWriteContacts}
              onChange={(_e, checked) => {
                if (checked) {
                  addEvent(ContactCommunicationType.PerOrderCompleted);
                } else {
                  removeEvent(ContactCommunicationType.PerOrderCompleted);
                }
              }}
            />
          </Box>
          <GridDivider span={2} />
          <Typography fontSize="14px">Order is out for delivery</Typography>
          <Box>
            <FormControlLabel
              control={<Checkbox />}
              label="Email"
              sx={{ marginRight: 0 }}
              checked={events.includes(
                ContactCommunicationType.PerOrderOutForDelivery,
              )}
              disabled={!canWriteContacts}
              onChange={(_e, checked) => {
                if (checked) {
                  addEvent(ContactCommunicationType.PerOrderOutForDelivery);
                } else {
                  removeEvent(ContactCommunicationType.PerOrderOutForDelivery);
                }
              }}
            />
          </Box>
          <GridDivider span={2} />
          <Typography fontSize="14px">
            Appointment date for a stop is updated
          </Typography>
          <Box>
            <FormControlLabel
              control={<Checkbox />}
              label="Email"
              sx={{ marginRight: 0 }}
              checked={events.includes(
                ContactCommunicationType.PerAppointmentDateChanged,
              )}
              disabled={!canWriteContacts}
              onChange={(_e, checked) => {
                if (checked) {
                  addEvent(ContactCommunicationType.PerAppointmentDateChanged);
                } else {
                  removeEvent(
                    ContactCommunicationType.PerAppointmentDateChanged,
                  );
                }
              }}
            />
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
