import { Box, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { isEmpty, isNil } from 'lodash';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

const DriverSection = ({ idx }: { readonly idx: number }) => {
  const { control } = useFormContext();
  const stopKey = `stops.${idx}`;
  const driverName = useWatch({ control, name: `${stopKey}.driverName` });
  const driverNotes = useWatch({ control, name: `${stopKey}.notes` });
  const routeDate = useWatch({ control, name: `${stopKey}.routeDate` });
  const theme = useTheme();

  if (isNil(driverName)) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        pt: '10px',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
        <Typography variant="subtitle1" fontWeight={500} fontSize="16px">
          {driverName}
        </Typography>
        <Typography
          variant="subtitle1"
          color={theme.palette.grey[500]}
          fontSize="16px"
        >
          {' '}
          / Driver notes
        </Typography>
      </Box>
      {!isNil(routeDate) && (
        <Typography>{dayjs(routeDate).format('MM/DD')}</Typography>
      )}
      <Box
        sx={{
          p: '10px',
          backgroundColor: theme.palette.grey[100],
        }}
      >
        <Typography>
          {isNil(driverNotes) || isEmpty(driverNotes) ? 'None' : driverNotes}
        </Typography>
      </Box>
    </Box>
  );
};

export default React.memo(DriverSection);
