import CloseIcon from '@mui/icons-material/Close';
import {
  TableRow,
  TableCell,
  Fade,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';

const HelperRow = ({
  firstName,
  lastName,
  driverReferenceNumber,
  onRemove,
}: {
  readonly firstName: string;
  readonly lastName: string;
  readonly driverReferenceNumber?: string | null;
  readonly onRemove: () => void;
}) => {
  const [isHovering, setIsHovering] = useState(false);
  return (
    <TableRow
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <TableCell>
        <Stack direction="row" alignItems="center">
          <Typography color="text.secondary">
            {driverReferenceNumber}
          </Typography>
          <Typography>
            {firstName} {lastName}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell align="right">
        <Fade in={isHovering}>
          <IconButton onClick={onRemove}>
            <CloseIcon />
          </IconButton>
        </Fade>
      </TableCell>
    </TableRow>
  );
};

export default HelperRow;
