import {
  Block,
  CheckCircle,
  ContentPaste,
  ContentPasteOff,
  EmailOutlined,
  HideImageOutlined,
  PhotoLibraryOutlined,
  PhotoOutlined,
} from '@mui/icons-material';
import {
  CircularProgress,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  type TooltipProps,
  Typography,
  useTheme,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { countBy, isEmpty, isNil, uniq } from 'lodash';
import pluralize from 'pluralize';
import { memo, useMemo } from 'react';
import { filterNotNil } from 'shared/array';
import {
  type DocumentType,
  type OrderPaperworkWithGroupedDocumentsFragment,
} from '../../../generated/graphql';
import ContentPasteSignature from '../../../icons/content-paste-signature.svg?react';
import NumberedFilterIcon from './order-form/components/common/numbered-filter-icon';

const SMALL_ICON_SIZE = 14;
const LARGE_ICON_SIZE = 24;

const formatDocumentsForTooltip = (
  documents: DocumentType[] | null | undefined,
) => {
  if (isNil(documents)) {
    return '';
  }
  const docTypeCounts = countBy(documents, (docType) => sentenceCase(docType));

  return uniq(Object.keys(docTypeCounts))
    .map((docType) =>
      (docTypeCounts[docType] ?? 0) > 1
        ? `${docType} (${docTypeCounts[docType]})`
        : docType,
    )
    .join(', ');
};

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }}>
    <Stack paddingX="2px" alignItems="center" direction="row">
      {props.children}
    </Stack>
  </Tooltip>
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});
type PaperworkProps = {
  readonly paperwork: OrderPaperworkWithGroupedDocumentsFragment;
  readonly isSmall?: boolean;
};

const Paperwork = ({ paperwork, isSmall = false }: PaperworkProps) => {
  const theme = useTheme();
  const ACTIVE_ICON_COLOR = theme.palette.grey[700];
  const TOOLTIP_ICON_COLOR = 'white';

  const proofOfPickupOrDelivery = useMemo(() => {
    if (!isEmpty(paperwork.proofOfDeliveryOrPickupSigned)) {
      const tooltipText = formatDocumentsForTooltip(
        paperwork.proofOfDeliveryOrPickupSigned,
      );
      return {
        cellIcon: (
          <NoMaxWidthTooltip title={tooltipText} color="black">
            <ContentPasteSignature
              color={theme.palette.text.secondary}
              width={isSmall ? SMALL_ICON_SIZE : LARGE_ICON_SIZE}
              height={isSmall ? SMALL_ICON_SIZE : LARGE_ICON_SIZE}
            />
          </NoMaxWidthTooltip>
        ),
        tooltipIcon: (
          <ContentPasteSignature
            color="white"
            width={isSmall ? SMALL_ICON_SIZE : LARGE_ICON_SIZE}
            height={isSmall ? SMALL_ICON_SIZE : LARGE_ICON_SIZE}
          />
        ),
        tooltipText,
      };
    }
    if (!isEmpty(paperwork.proofOfDeliveryOrPickupReadyToSign)) {
      const tooltipText = formatDocumentsForTooltip(
        paperwork.proofOfDeliveryOrPickupReadyToSign,
      );
      return {
        cellIcon: (
          <NoMaxWidthTooltip title={tooltipText}>
            <ContentPaste
              htmlColor={ACTIVE_ICON_COLOR}
              sx={{
                fontSize: isSmall ? SMALL_ICON_SIZE : LARGE_ICON_SIZE,
              }}
            />
          </NoMaxWidthTooltip>
        ),
        tooltipIcon: (
          <ContentPaste
            htmlColor={TOOLTIP_ICON_COLOR}
            style={{ fontSize: SMALL_ICON_SIZE }}
          />
        ),
        tooltipText,
      };
    }
    return {
      cellIcon: (
        <NoMaxWidthTooltip title="No PoDs">
          <ContentPasteOff
            color="disabled"
            sx={{
              fontSize: isSmall ? SMALL_ICON_SIZE : LARGE_ICON_SIZE,
            }}
          />
        </NoMaxWidthTooltip>
      ),
    };
  }, [
    ACTIVE_ICON_COLOR,
    theme.palette.text.secondary,
    isSmall,
    paperwork.proofOfDeliveryOrPickupReadyToSign,
    paperwork.proofOfDeliveryOrPickupSigned,
  ]);
  const alerts = useMemo(() => {
    if (!isEmpty(paperwork.alerts)) {
      const tooltipText = formatDocumentsForTooltip(paperwork.alerts);
      return {
        cellIcon: (
          <NoMaxWidthTooltip title={tooltipText}>
            <EmailOutlined
              htmlColor={ACTIVE_ICON_COLOR}
              sx={{
                fontSize: isSmall ? SMALL_ICON_SIZE : LARGE_ICON_SIZE,
              }}
            />
          </NoMaxWidthTooltip>
        ),
        tooltipIcon: (
          <EmailOutlined
            htmlColor={TOOLTIP_ICON_COLOR}
            style={{ fontSize: SMALL_ICON_SIZE }}
          />
        ),
        tooltipText,
      };
    }
    return {
      cellIcon: (
        <NoMaxWidthTooltip title="No alerts" sx={{ paddingX: '2px' }}>
          <img
            src="/images/icons/mail-off.svg"
            alt="Signed Pod"
            height={isSmall ? SMALL_ICON_SIZE : LARGE_ICON_SIZE}
            style={{ margin: '0 2px' }}
          />
        </NoMaxWidthTooltip>
      ),
    };
  }, [ACTIVE_ICON_COLOR, isSmall, paperwork.alerts]);
  const photos = useMemo(() => {
    if (!isEmpty(paperwork.photos)) {
      return {
        cellIcon: (
          <NoMaxWidthTooltip title={`Photos (${paperwork.photos?.length})`}>
            <PhotoLibraryOutlined
              htmlColor={ACTIVE_ICON_COLOR}
              sx={{
                fontSize: isSmall ? SMALL_ICON_SIZE : LARGE_ICON_SIZE,
              }}
            />
          </NoMaxWidthTooltip>
        ),
        tooltipIcon: (
          <PhotoLibraryOutlined
            htmlColor={TOOLTIP_ICON_COLOR}
            style={{ fontSize: SMALL_ICON_SIZE }}
          />
        ),
        tooltipText: `${paperwork.photos?.length} photos`,
      };
    }
    if (paperwork.photos?.length === 1) {
      return {
        cellIcon: (
          <NoMaxWidthTooltip title="Photo (1)">
            <PhotoOutlined
              htmlColor={ACTIVE_ICON_COLOR}
              sx={{
                fontSize: isSmall ? SMALL_ICON_SIZE : LARGE_ICON_SIZE,
              }}
            />
          </NoMaxWidthTooltip>
        ),
        tooltipIcon: (
          <PhotoLibraryOutlined
            htmlColor={TOOLTIP_ICON_COLOR}
            style={{ fontSize: SMALL_ICON_SIZE }}
          />
        ),
        tooltipText: '1 photo',
      };
    }
    return {
      cellIcon: (
        <NoMaxWidthTooltip title="No photos">
          <HideImageOutlined
            color="disabled"
            sx={{
              fontSize: isSmall ? SMALL_ICON_SIZE : LARGE_ICON_SIZE,
            }}
          />
        </NoMaxWidthTooltip>
      ),
    };
  }, [ACTIVE_ICON_COLOR, isSmall, paperwork.photos]);
  const otherDocuments = useMemo(() => {
    if (!isEmpty(paperwork.other)) {
      return {
        cellIcon: (
          <NoMaxWidthTooltip title={formatDocumentsForTooltip(paperwork.other)}>
            <Stack alignItems="center" justifyContent="center">
              <NumberedFilterIcon
                num={paperwork.other?.length ?? 0}
                htmlColor={ACTIVE_ICON_COLOR}
                // the filter icons appear larger than the other icons, so reduce the size
                sx={{
                  fontSize: isSmall ? SMALL_ICON_SIZE - 2 : LARGE_ICON_SIZE - 3,
                }}
              />
            </Stack>
          </NoMaxWidthTooltip>
        ),
        tooltipIcon: (
          <NumberedFilterIcon
            num={paperwork.other?.length ?? 0}
            htmlColor={TOOLTIP_ICON_COLOR}
            style={{ fontSize: SMALL_ICON_SIZE }}
          />
        ),
        tooltipText: `${paperwork.other?.length} ${pluralize('other', paperwork.other?.length)}`,
      };
    }
    return null;
  }, [ACTIVE_ICON_COLOR, isSmall, paperwork.other]);

  const documentsList = useMemo(
    () =>
      filterNotNil([
        proofOfPickupOrDelivery.tooltipIcon,
        proofOfPickupOrDelivery.tooltipText,
        alerts.tooltipIcon,
        alerts.tooltipText,
        photos.tooltipIcon,
        photos.tooltipText,
        otherDocuments?.tooltipIcon,
        otherDocuments?.tooltipText,
      ]),
    [alerts, otherDocuments, photos, proofOfPickupOrDelivery],
  );

  const completedStatus = useMemo(() => {
    if (paperwork.paperworkComplete) {
      return {
        tooltipIcon: (
          <CheckCircle
            key="check"
            htmlColor={TOOLTIP_ICON_COLOR}
            style={{ fontSize: SMALL_ICON_SIZE }}
          />
        ),
        tooltipText: 'Complete for order',
        cellIcon: (
          <CheckCircle
            color="success"
            sx={{
              fontSize: isSmall ? SMALL_ICON_SIZE : LARGE_ICON_SIZE,
            }}
          />
        ),
      };
    }
    if (!isEmpty(paperwork.missingDocuments)) {
      return {
        tooltipIcon: (
          <Block
            htmlColor={TOOLTIP_ICON_COLOR}
            style={{ fontSize: SMALL_ICON_SIZE }}
          />
        ),
        tooltipText: 'Missing documents',
        cellIcon: (
          <Block
            color="disabled"
            sx={{
              fontSize: isSmall ? SMALL_ICON_SIZE : LARGE_ICON_SIZE,
            }}
          />
        ),
      };
    }
    if (isEmpty(documentsList)) {
      return {
        tooltipIcon: (
          <Block
            htmlColor={TOOLTIP_ICON_COLOR}
            style={{ fontSize: SMALL_ICON_SIZE }}
          />
        ),
        tooltipText: 'No documents',
        cellIcon: (
          <Block
            color="disabled"
            sx={{
              fontSize: isSmall ? SMALL_ICON_SIZE : LARGE_ICON_SIZE,
            }}
          />
        ),
      };
    }
    return {
      tooltipIcon: (
        <Block
          htmlColor={TOOLTIP_ICON_COLOR}
          style={{ fontSize: SMALL_ICON_SIZE }}
        />
      ),
      tooltipText: 'Incomplete',
      cellIcon: (
        <Block
          color="disabled"
          sx={{
            fontSize: isSmall ? SMALL_ICON_SIZE : LARGE_ICON_SIZE,
          }}
        />
      ),
    };
  }, [
    documentsList,
    isSmall,
    paperwork.missingDocuments,
    paperwork.paperworkComplete,
  ]);

  return (
    <Stack direction="row" alignItems="center">
      <NoMaxWidthTooltip
        title={
          <Stack
            direction="row"
            alignItems="center"
            whiteSpace="nowrap"
            gap={0.5}
          >
            {completedStatus.tooltipIcon}
            {completedStatus.tooltipText}
            {!isEmpty(documentsList) && !isNil(completedStatus) && (
              <Typography color="white" fontSize={SMALL_ICON_SIZE}>
                |
              </Typography>
            )}
            {documentsList}
          </Stack>
        }
      >
        {completedStatus.cellIcon}
      </NoMaxWidthTooltip>
      <Typography
        color={theme.palette.grey['400']}
        fontSize={isSmall ? SMALL_ICON_SIZE : LARGE_ICON_SIZE}
      >
        |
      </Typography>
      {proofOfPickupOrDelivery.cellIcon}
      {alerts.cellIcon}
      {photos.cellIcon}
      {otherDocuments?.cellIcon}
    </Stack>
  );
};

export default memo(
  ({
    paperwork,
    isSmall,
  }: {
    readonly paperwork:
      | OrderPaperworkWithGroupedDocumentsFragment
      | null
      | undefined;
    readonly isSmall?: boolean;
  }) => {
    if (isNil(paperwork)) {
      return (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          width={120}
        >
          <CircularProgress />
        </Stack>
      );
    }
    return <Paperwork paperwork={paperwork} isSmall={isSmall} />;
  },
);
