import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Box,
  Card,
  CardContent,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { isNil } from 'lodash';
import * as React from 'react';
import { type CSSProperties } from 'react';
import useStyles from '../end-of-day-styles';

const CardContentSpecialPadding = styled(CardContent)(`
  padding: 10px;
  padding-left:15px;
  &:last-child {
    padding-bottom: 10px;
  }
`);

const SecondaryTypography = styled(Typography)(({ theme }) => ({
  variant: 'body2',
  fontSize: 13,
  color: theme.palette.text.secondary,
}));

const LinearProgressWithLabel = ({
  defaultColor,
  numeratorLabel,
  denominatorLabel,
}: {
  readonly defaultColor?:
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'inherit'
    | undefined;
  readonly numeratorLabel: number;
  readonly denominatorLabel: number;
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          color={numeratorLabel === denominatorLabel ? 'success' : defaultColor}
          variant="determinate"
          value={(numeratorLabel / denominatorLabel) * 100}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          color="text.secondary"
        >{`${numeratorLabel}/${denominatorLabel}`}</Typography>
      </Box>
    </Box>
  );
};

type RoutesCardProps = {
  readonly style: CSSProperties;
  readonly routeName: string;
  readonly driverName: string | null;
  readonly numberOfDocumentsUploaded: number;
  readonly numberOfCompleteStops?: number;
  readonly numberOfAttemptedStops?: number;
  readonly numberOfStops: number;
  readonly isSelected: boolean;

  readonly onClick: () => void;

  readonly showTotalStops?: boolean;
};

const RoutesCard = ({
  style,
  routeName,
  driverName,
  numberOfDocumentsUploaded,
  numberOfCompleteStops,
  numberOfAttemptedStops,
  numberOfStops,
  isSelected,
  onClick,
  showTotalStops,
}: RoutesCardProps) => {
  const styles = useStyles();
  return (
    <Card
      style={style}
      variant="outlined"
      sx={[
        {
          borderColor: isSelected ? undefined : 'white',
          boxShadow: isSelected ? undefined : 'none',
          background: isSelected ? 'rgba(37, 48, 82, 0.08)' : 'undefined',
        },
        styles.cardHover,
      ]}
      onClick={onClick}
    >
      <CardContentSpecialPadding>
        <Grid container sx={styles.routeCardContainer}>
          <Stack direction="column" sx={{ width: '80%' }}>
            <Typography sx={{ fontSize: 15 }}>{routeName}</Typography>
            {!isNil(driverName) && (
              <SecondaryTypography gutterBottom>
                Driver: {driverName}
              </SecondaryTypography>
            )}
            {showTotalStops === true && !isNil(numberOfStops) && (
              <SecondaryTypography>
                Total Stops: {numberOfStops}
              </SecondaryTypography>
            )}
            {numberOfStops === 0 ? (
              <SecondaryTypography>No Stops</SecondaryTypography>
            ) : (
              <>
                {!isNil(numberOfCompleteStops) &&
                  !isNil(numberOfAttemptedStops) &&
                  !isNil(numberOfStops) && (
                    <>
                      <SecondaryTypography>
                        Completed/Total Stops
                      </SecondaryTypography>
                      <LinearProgressWithLabel
                        numeratorLabel={
                          numberOfCompleteStops + numberOfAttemptedStops
                        }
                        denominatorLabel={numberOfStops}
                      />
                    </>
                  )}
                <SecondaryTypography>Documents Uploaded</SecondaryTypography>
                <LinearProgressWithLabel
                  defaultColor="warning"
                  numeratorLabel={numberOfDocumentsUploaded}
                  denominatorLabel={numberOfStops}
                />
              </>
            )}
          </Stack>
          <KeyboardArrowRightIcon sx={{ color: styles.unassignedColor }} />
        </Grid>
      </CardContentSpecialPadding>
    </Card>
  );
};

export default RoutesCard;
