import { Box, Popover } from '@mui/material';
import { isNil } from 'lodash';
import { useRef, useState } from 'react';
import {
  type ColorChangeHandler,
  type ColorResult,
  TwitterPicker,
} from 'react-color';
import { COLORS } from '../../../common/constants';

const SettingsColorPicker = ({
  currentColor,
  setColor,
  onChangeComplete,
  disabled,
}: {
  readonly currentColor: string | undefined | null;
  readonly setColor: (colorHex: string) => void;
  readonly onChangeComplete?: (colorHex: string) => void;
  readonly disabled?: boolean;
}) => {
  const [showPicker, setShowPicker] = useState(false);
  const currentColorBoxRef = useRef(null);

  const handleColorChange: ColorChangeHandler = (color: ColorResult) => {
    setColor(color.hex);
  };

  return (
    <>
      <Box
        ref={currentColorBoxRef}
        sx={{
          cursor: disabled === true ? undefined : 'pointer',
          width: '20px',
          height: '20px',
          backgroundColor: currentColor,
          borderRadius: '25%',
        }}
        onClick={() => {
          if (disabled !== true) setShowPicker(!showPicker);
        }}
      />
      <Popover
        open={showPicker}
        anchorEl={currentColorBoxRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: -10,
          horizontal: 10,
        }}
        PaperProps={{ style: { overflow: 'visible' } }}
        onClose={() => {
          setShowPicker(false);
        }}
      >
        <TwitterPicker
          colors={COLORS}
          color={currentColor ?? undefined}
          onChange={handleColorChange}
          onChangeComplete={(color: ColorResult) => {
            if (!isNil(onChangeComplete)) {
              onChangeComplete(color.hex);
            }
          }}
        />
      </Popover>
    </>
  );
};

export default SettingsColorPicker;
