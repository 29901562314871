import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  CircularProgress,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from '@mui/material';
import { isEmpty, uniq } from 'lodash';
import {
  type Dispatch,
  type SetStateAction,
  memo,
  useMemo,
  useState,
} from 'react';
import { filterNotNil } from 'shared/array';
import { shallow } from 'zustand/shallow';
import {
  type OrderForDocumentAttachmentsDownloadFragment,
  type RouteFragment,
} from '../../../../generated/graphql';
import { useBulkDownloadDocumentsForMultipleOrders } from '../../../orders/components/bulk-action/useBulkDownloadDocumentsForMultipleOrders';
import useDispatchStore from '../../dispatch-store';
import useFetchRoutes from '../../hooks/use-fetch-routes';
import useRouteActions from '../../hooks/use-route-actions';
import { type TableStopOnRoute } from '../route-card-stops-list-columns';

export type OpenModalsActions = {
  setShowRouteDateModal: Dispatch<SetStateAction<boolean>>;
  setShowTextDriversModal: Dispatch<SetStateAction<boolean>>;
  setShowPrintManifestModal: Dispatch<SetStateAction<boolean>>;
  setShowConfirmUnsignedPodsDownloadModal: Dispatch<SetStateAction<boolean>>;
  setOrdersWithoutUnsignedPods: Dispatch<
    SetStateAction<OrderForDocumentAttachmentsDownloadFragment[]>
  >;
  setShowOptimizeRouteModal: Dispatch<SetStateAction<boolean>>;
  setShowDeleteRouteModal: Dispatch<SetStateAction<boolean>>;
};

const RouteContextMenu = ({
  route,
  stops,
  openModalsActions,
}: {
  readonly route: RouteFragment;
  readonly stops: TableStopOnRoute[];
  readonly openModalsActions: OpenModalsActions;
}) => {
  const [dropdownMenuRef, setDropdownMenuRef] = useState<null | HTMLElement>(
    null,
  );
  const dropdownMenuOpen = Boolean(dropdownMenuRef);
  const [isLoadingEstimateRoute, setIsLoadingEstimateRoute] =
    useState<boolean>(false);
  const {
    setShowRouteDateModal,
    setShowTextDriversModal,
    setShowPrintManifestModal,
    setShowConfirmUnsignedPodsDownloadModal,
    setOrdersWithoutUnsignedPods,
    setShowOptimizeRouteModal,
    setShowDeleteRouteModal,
  } = openModalsActions;
  const setErrorMessage = useDispatchStore(
    (state) => state.setErrorMessage,
    shallow,
  );
  const {
    calculateRouteEta,
    createAppointments,
    downloadCoverSheetsForRoutes,
    downloadUnsignedPodDocumentsForRoutes,
    lockRoute,
    unlockRoute,
    reverseRoute,
    generateRoutePaths,
  } = useRouteActions();
  const { fetchRoute } = useFetchRoutes();

  const orderUuidsFromStops = useMemo(() => {
    return filterNotNil(uniq(stops.map((s) => s.shipment?.order?.uuid)));
  }, [stops]);

  const {
    downloadLotLabelsForMultipleOrders,
    downloadOutboundLabelsForMultipleOrders,
  } = useBulkDownloadDocumentsForMultipleOrders({
    orderUuids: orderUuidsFromStops,
  });

  const closeDropdownMenu = () => {
    setDropdownMenuRef(null);
  };

  const printUnsignedPods = async () => {
    const ordersMissingDocument = await downloadUnsignedPodDocumentsForRoutes(
      [route],
      false,
    );

    if (!isEmpty(ordersMissingDocument)) {
      setOrdersWithoutUnsignedPods(ordersMissingDocument);
      setShowConfirmUnsignedPodsDownloadModal(true);
    }
    closeDropdownMenu();
  };

  const printLotLabels = async () => {
    await downloadLotLabelsForMultipleOrders();
    closeDropdownMenu();
  };

  const printOutboundLabels = async () => {
    await downloadOutboundLabelsForMultipleOrders();
    closeDropdownMenu();
  };

  const { uuid, locked } = route;

  return (
    <>
      <IconButton
        size="small"
        sx={{ p: '5px' }}
        onClick={(e) => {
          setDropdownMenuRef(e.currentTarget);
          e.stopPropagation();
        }}
      >
        <MoreVertIcon sx={{ fontSize: '20px' }} />
      </IconButton>
      <Menu
        anchorEl={dropdownMenuRef}
        open={dropdownMenuOpen}
        onClose={closeDropdownMenu}
      >
        <MenuList dense sx={{ py: 0 }}>
          <MenuItem
            disabled={locked}
            onClick={async () => {
              setIsLoadingEstimateRoute(true);
              await generateRoutePaths({ uuids: [route.uuid] });
              await calculateRouteEta(route.uuid);
              await fetchRoute(route.uuid);
              closeDropdownMenu();
              setIsLoadingEstimateRoute(false);
            }}
          >
            {isLoadingEstimateRoute && (
              <CircularProgress size={15} sx={{ mr: 1 }} />
            )}
            {isLoadingEstimateRoute
              ? 'Calculating miles and ETAs...'
              : 'Calculate miles and ETAs'}
            {/* what's your ETA? What's your ETA? (Mmm-hmm) what's your ETA? What's your ETA? */}
          </MenuItem>
          <MenuItem
            disabled={locked}
            onClick={async () => {
              closeDropdownMenu();
              await createAppointments(route.uuid);
            }}
          >
            Create appointments
          </MenuItem>
          <MenuItem
            disabled={locked}
            onClick={() => {
              closeDropdownMenu();
              setShowRouteDateModal(true);
            }}
          >
            Edit route date
          </MenuItem>
          <MenuItem
            onClick={() => {
              closeDropdownMenu();
              setShowTextDriversModal(true);
            }}
          >
            Text drivers
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              closeDropdownMenu();
              setShowPrintManifestModal(true);
            }}
          >
            Print manifest
          </MenuItem>
          <MenuItem
            onClick={() => {
              closeDropdownMenu();
              downloadCoverSheetsForRoutes([route]);
            }}
          >
            Print cover sheets
          </MenuItem>
          <MenuItem onClick={printUnsignedPods}>Print unsigned PODs</MenuItem>
          <MenuItem disabled={stops.length <= 0} onClick={printLotLabels}>
            Print lot labels
          </MenuItem>
          <MenuItem disabled={stops.length <= 0} onClick={printOutboundLabels}>
            Print outbound labels
          </MenuItem>
          <Divider />
          <MenuItem
            disabled={locked}
            onClick={() => {
              closeDropdownMenu();
              setShowOptimizeRouteModal(true);
            }}
          >
            Optimize route
          </MenuItem>
          <MenuItem
            disabled={locked}
            onClick={async () => {
              closeDropdownMenu();
              const res = await reverseRoute(uuid);
              if (!res) {
                fetchRoute(route.uuid);
                setErrorMessage(
                  'Failed to reverse stops because the route has changed - please try again.',
                );
              }
            }}
          >
            Reverse route
          </MenuItem>
          {locked ? (
            <MenuItem
              onClick={async () => {
                closeDropdownMenu();
                await unlockRoute(uuid);
              }}
            >
              Unlock route
            </MenuItem>
          ) : (
            <MenuItem
              onClick={async () => {
                closeDropdownMenu();
                await lockRoute(uuid);
              }}
            >
              Lock route
            </MenuItem>
          )}
          <Divider />
          <MenuItem
            disabled={locked}
            onClick={() => {
              closeDropdownMenu();
              setShowDeleteRouteModal(true);
            }}
          >
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText>Delete route</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};

export default memo(RouteContextMenu);
