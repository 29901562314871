import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { isEmpty, isNil, range, sum } from 'lodash';
import React, { useMemo } from 'react';
import { type PackageValues } from 'shared/types';
import { generateBarcode } from '../../../common/utils/barcode';
import { isNilOrEmptyString, zeroPad } from '../../../common/utils/utils';
import { type PackageFragment } from '../../../generated/graphql';
import { type StopType } from '../../orders/components/order-form/forms/stop-type';
import {
  type UniquePackagePiece,
  type AddressType,
  LabelType,
  getShipperText,
  getRoutingText,
} from '../utils';

type InventoryLabelDocumentProps = {
  readonly companyName: string;
  readonly contact: string;
  readonly orderName: string;
  readonly mawb: string;
  readonly hawb: string;
  readonly shipper?: AddressType;
  readonly consignee?: string;
  readonly packages: PackageValues[] | PackageFragment[];
  readonly inboundStopType?: StopType;
  readonly outboundStopType?: StopType;
  readonly recoveryAddress?: AddressType;
  readonly pcdCarrier?: string;
  readonly pcpCarrier?: string;
  readonly outboundFlightAirline?: string;
  readonly outboundFlightOriginCode?: string;
  readonly outboundFlightDestinationCode?: string | null;
  readonly destinationDetails?: string | null;
  readonly ffSurelogixOverrides?: boolean;
  readonly destinationTerminalCode?: string;
  readonly isForwardAirComplete?: boolean;
  readonly widthDimension: number;
  readonly heightDimension: number;
  readonly inboundIataCode?: string;
  readonly outboundIataCode?: string;
  readonly ffRecoveryTransferAddressOnly?: boolean;
};

const GeneratedInventoryLabelPdf = ({
  companyName,
  contact,
  orderName,
  mawb,
  hawb,
  shipper,
  consignee,
  packages,
  inboundStopType,
  outboundStopType,
  recoveryAddress,
  pcdCarrier,
  pcpCarrier,
  outboundFlightAirline,
  outboundFlightOriginCode,
  outboundFlightDestinationCode,
  destinationDetails,
  ffSurelogixOverrides,
  destinationTerminalCode,
  isForwardAirComplete = false,
  widthDimension,
  heightDimension,
  inboundIataCode,
  outboundIataCode,
  ffRecoveryTransferAddressOnly,
}: InventoryLabelDocumentProps) => {
  const styles = StyleSheet.create({
    page: {
      padding: 4,
      fontFamily: 'Roboto',
      fontSize: '10px',
      flexDirection: 'column',
    },
    titleBold: {
      fontSize: '18px',
      fontWeight: 'bold',
    },
    headerText: {
      fontSize: '16px',
      fontWeight: 'bold',
    },
    subHeaderTextSize: {
      fontSize: '10px',
    },
    subHeaderText: {
      fontSize: '10px',
      fontWeight: 'bold',
    },
    paddingUnderRow: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      paddingBottom: '5px',
    },
    outerCell: {
      width: '100%',
      display: 'flex',
      padding: '3px',
    },
    cell: {
      borderWidth: 1,
      borderColor: 'black',
      width: '100%',
      display: 'flex',
      padding: '3px',
    },
    airportInformationCell: {
      width: '100%',
      padding: 4,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    orderInformationCell: {
      borderWidth: 1,
      borderColor: 'black',
      display: 'flex',
      padding: '3px',
      flexDirection: 'column',
      fontSize: '14px',
    },
    originCell: {
      width: '50%',
      marginBottom: 4,
    },
    originText: {
      fontSize: '30px',
    },
    airlineText: {
      fontSize: '14px',
    },
    packageRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    packageCell: {
      display: 'flex',
      flexDirection: 'row',
    },
    packageText: {
      fontSize: '18px',
      marginRight: 12,
    },
  });

  const shipperText = getShipperText(
    LabelType.OutboundLabel,
    inboundStopType,
    shipper,
    recoveryAddress,
    pcdCarrier,
  );

  const routingText = getRoutingText(
    outboundStopType,
    outboundFlightAirline,
    pcpCarrier,
  );

  const totalNumberOfPieces = useMemo(() => {
    return sum(packages?.map((pkg) => pkg.quantity ?? 1));
  }, [packages]);

  let count = 0;

  const uniquePackagePieces: UniquePackagePiece[] = [];
  let pieceNumber = 1;
  packages.forEach((pkg) => {
    range(pkg?.quantity ?? 0).forEach(() => {
      uniquePackagePieces.push({
        pkg: {
          ...pkg,
          quantity: pkg.quantity ?? 0,
          weight: pkg.weight,
        },
        pieceNumber,
      });
      pieceNumber += 1;
    });
  });

  const getDestinationText = () => {
    if (
      ffSurelogixOverrides === true ||
      ffRecoveryTransferAddressOnly === true
    ) {
      if (!isNilOrEmptyString(destinationDetails)) {
        return destinationDetails;
      }
      if (!isNilOrEmptyString(outboundIataCode)) {
        return outboundIataCode;
      }
      if (!isNilOrEmptyString(outboundFlightDestinationCode)) {
        return outboundFlightDestinationCode;
      }
      return destinationTerminalCode;
    }
    if (!isNilOrEmptyString(outboundIataCode)) {
      return outboundIataCode;
    }
    return isNilOrEmptyString(outboundFlightDestinationCode?.trim())
      ? destinationTerminalCode
      : outboundFlightDestinationCode;
  };

  // The destination text has a C in a special case for Forward Air specifically
  const destinationText = isForwardAirComplete
    ? `${getDestinationText()?.toUpperCase()}C`
    : getDestinationText()?.toUpperCase();

  const originText = isNilOrEmptyString(inboundIataCode)
    ? outboundFlightOriginCode?.toUpperCase()
    : inboundIataCode;
  return (
    <Document>
      {uniquePackagePieces?.map((uniquePackagePiece) => {
        const { pkg, pieceNumber: packagePieceNumber } = uniquePackagePiece;
        const data =
          !isEmpty(outboundFlightOriginCode) && !isEmpty(mawb)
            ? `${outboundFlightOriginCode}472${mawb}${zeroPad(
                packagePieceNumber,
                5,
              )}`
            : null;
        const barcodeData =
          // eslint-disable-next-line no-nested-ternary
          isNil(data)
            ? null
            : generateBarcode({
                data,
                width: 3,
                height: 60,
                displayValue: false,
              });
        count += 1;
        return (
          <Page
            key={pkg.uuid}
            size={{ width: widthDimension * 72, height: heightDimension * 72 }} // convert from pt to inc
            orientation="portrait"
            style={styles.page}
          >
            <View
              style={[
                {
                  flexDirection: 'column',
                  alignItems: 'center',
                },
                styles.outerCell,
              ]}
            >
              <View
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Text style={styles.headerText}>
                  {companyName.toUpperCase()}
                </Text>
              </View>
              <View style={[styles.cell]}>
                <View style={[styles.airportInformationCell]}>
                  <Text style={styles.headerText}>{contact.toUpperCase()}</Text>
                  {!isNilOrEmptyString(routingText) && (
                    <Text style={styles.airlineText}>
                      {routingText?.toUpperCase()}
                    </Text>
                  )}
                </View>

                <View style={[styles.airportInformationCell]}>
                  {!isNilOrEmptyString(originText) && (
                    <View style={[styles.originCell]}>
                      <Text>Origin:</Text>
                      <Text style={styles.originText}>{originText}</Text>
                    </View>
                  )}
                  {!isNilOrEmptyString(destinationText) && (
                    <View style={[styles.originCell]}>
                      <Text>Destination:</Text>
                      <Text style={styles.originText}>{destinationText}</Text>
                    </View>
                  )}
                </View>

                <View style={[styles.orderInformationCell]}>
                  <Text>{`Order: ${orderName}`}</Text>
                  <Text>{`MAWB#: ${mawb}`}</Text>
                  <Text>{`HAWB#: ${hawb}`}</Text>
                  {!isEmpty(shipperText) && (
                    <Text>{`SHIP: ${shipperText}`}</Text>
                  )}
                  {!isEmpty(consignee) && <Text>{`CONS: ${consignee}`}</Text>}
                </View>
                <View style={[styles.packageRow]}>
                  <View style={styles.packageCell}>
                    {!isNil(pkg.weight) && pkg.weight !== 0 && (
                      <View style={styles.packageCell}>
                        <Text style={styles.packageText}>
                          {pkg.weight.toFixed(2)}
                        </Text>
                        <Text style={styles.packageText}>LBS</Text>
                      </View>
                    )}
                  </View>
                  <View style={styles.packageCell}>
                    <Text style={styles.packageText}>{count}</Text>
                    <Text style={styles.packageText}>of</Text>
                    <Text style={styles.packageText}>
                      {totalNumberOfPieces}
                    </Text>
                    <Text style={styles.packageText}>{pkg.type ?? 'PCS'}</Text>
                  </View>
                </View>
              </View>
              {!isNil(barcodeData) && (
                <>
                  `{' '}
                  <View>
                    <Image
                      source={{
                        uri: barcodeData,
                        method: 'GET',
                        headers: {},
                        body: '',
                      }}
                    />
                  </View>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {isNil(data) ? null : (
                    <View>
                      <Text style={styles.packageText}>{data}</Text>
                    </View>
                  )}
                  `
                </>
              )}
            </View>
          </Page>
        );
      })}
    </Document>
  );
};

export default GeneratedInventoryLabelPdf;
