import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { type Dispatch, type SetStateAction } from 'react';

type CannotCompleteOrderModalProps = {
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;

  readonly message?: string;

  readonly setMessage: Dispatch<SetStateAction<string | undefined>>;
};

const CannotCompleteOrderModal = ({
  open,
  setOpen,
  message,
  setMessage,
}: CannotCompleteOrderModalProps) => {
  const handleClose = () => {
    setOpen(false);
    setMessage('');
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Cannot complete order</DialogTitle>
      <DialogContent>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CannotCompleteOrderModal;
