import { Alert, Button, Snackbar, Stack } from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isNil } from 'lodash';
import { Calendar } from 'primereact/calendar';
import { useMountEffect } from 'primereact/hooks';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import React, { useMemo } from 'react';
import { shallow } from 'zustand/shallow';
import TabPanel from '../../common/components/tab-panel/tab-panel';
import { FeatureFlag } from '../../common/feature-flags';
import {
  PLANNING_DATE_EXPIRATION_KEY,
  PLANNING_DATE_KEY,
} from '../../common/local-storage/keys';
import DispatchMultiplayerLocationComponent from '../../common/multiplayer/components/dispatch-multiplayer-location-component';
import useDispatchTableColors from '../../common/react-hooks/use-dispatch-table-colors';
import useFeatureFlag from '../../common/react-hooks/use-feature-flag';
import useMe from '../../common/react-hooks/use-me';
import { FilterViewPage, PickupOrDelivery } from '../../generated/graphql';
import useGlobalStore from '../../layouts/dashboard/global-store';
import { StopsTab } from '../ag-grid/dispatch-stops/constants';
import DispatchStopsControlPanel from '../ag-grid/dispatch-stops/dispatch-stops-control-panel';
import { type DefaultFilterTabsConfigs } from '../ag-grid/dispatch-stops/types';
import { useNewTableFunctionsFeatureFlag } from '../ag-grid/use-new-table-functions-feature-flag';
import {
  convertStopTypeFilterTabToDispatchTableFilterModel,
  convertStopTypeFilterTabToLegacyFilterModelJson,
} from '../ag-grid/utils';
import { OrderDialog } from '../orders/components/order-dialog';
import DispatchAvatarPresenceGroup from './dispatch-avatar-presence-group';
import useDispatchStore from './dispatch-store';
import { DispatchTabs } from './dispatch-views/dispatch-tabs';
import { useDispatchViews } from './dispatch-views/use-dispatch-views';
import useFetchRoutes from './hooks/use-fetch-routes';
import useRouteActions from './hooks/use-route-actions';
import { useUnassignStopsRouteActions } from './hooks/use-unassign-stops-route-actions';
import Map from './map/map';
import Routes from './routes/routes';
import DispatchSettingsContextMenu from './settings/dispatch-settings-context-menu';
import { getPlanningDate } from './utils';

dayjs.extend(utc);
dayjs.extend(timezone);

export const useDispatchViewDefaultFilterTabsConfigs = (
  ffEnableNewTableFunctions: boolean,
): DefaultFilterTabsConfigs<StopsTab> => {
  return useMemo(() => {
    const convertStopTypeFilterToFilterModel = ffEnableNewTableFunctions
      ? convertStopTypeFilterTabToDispatchTableFilterModel
      : convertStopTypeFilterTabToLegacyFilterModelJson;
    return {
      defaultTab: StopsTab.AllStops,
      tabs: [
        {
          value: StopsTab.AllStops,
          label: 'All',
          filtersToApply: {},
        },
        {
          value: StopsTab.Pickups,
          label: 'Pickups',
          filterModel: convertStopTypeFilterToFilterModel(
            PickupOrDelivery.Pickup,
          ),
          filtersToApply: {
            pickupOrDeliveryTypes: [PickupOrDelivery.Pickup],
          },
        },
        {
          value: StopsTab.Deliveries,
          label: 'Deliveries',
          filterModel: convertStopTypeFilterToFilterModel(
            PickupOrDelivery.Delivery,
          ),
          filtersToApply: {
            pickupOrDeliveryTypes: [PickupOrDelivery.Delivery],
          },
        },
        {
          value: StopsTab.Recoveries,
          label: 'Recoveries',
          filterModel: convertStopTypeFilterToFilterModel(
            PickupOrDelivery.Recovery,
          ),
          filtersToApply: {
            pickupOrDeliveryTypes: [PickupOrDelivery.Recovery],
          },
        },
        {
          value: StopsTab.Transfers,
          label: 'Transfers',
          filterModel: convertStopTypeFilterToFilterModel(
            PickupOrDelivery.Transfer,
          ),
          filtersToApply: {
            pickupOrDeliveryTypes: [PickupOrDelivery.Transfer],
          },
        },
      ],
    };
  }, [ffEnableNewTableFunctions]);
};

const DispatchPage = () => {
  const { companyUuid, email } = useMe();
  const { dispatchViews } = useDispatchViews();
  const { dispatchTableColors } = useDispatchTableColors();
  const selectedTerminalUuid = useGlobalStore(
    (state) => state.selectedTerminalUuid,
    shallow,
  );
  const [
    planningDate,
    showMap,
    selectedViewUuid,
    showUnassignedSnackbar,
    errorMessage,
    openedOrderUuid,
    arrangeStopsAndRoutesVertically,
  ] = useDispatchStore(
    (state) => [
      state.planningDate,
      state.showMap,
      state.selectedViewUuid,
      state.showUnassignedSnackbar,
      state.errorMessage,
      state.openedOrderUuid,
      state.arrangeStopsAndRoutesVertically,
    ],
    shallow,
  );
  const [
    setPlanningDate,
    setShowUnassignedSnackbar,
    setOpenedOrderUuid,
    setErrorMessage,
    getRoutesByOrderUuid,
    setRoutesLoading,
  ] = useDispatchStore(
    (state) => [
      state.setPlanningDate,
      state.setShowUnassignedSnackbar,
      state.setOpenedOrderUuid,
      state.setErrorMessage,
      state.getRoutesByOrderUuid,
      state.setRoutesLoading,
    ],
    shallow,
  );
  const ffNoRecoveryTransfer = useFeatureFlag(
    FeatureFlag.FF_NO_RECOVERY_TRANSFER,
  );
  const { ffEnableNewTableFunctions } = useNewTableFunctionsFeatureFlag(
    FilterViewPage.Dispatch,
  );
  const defaultFilterTabsConfigs = useDispatchViewDefaultFilterTabsConfigs(
    ffEnableNewTableFunctions,
  );
  const filteredDefaultFilterTabsConfigs = useMemo<
    DefaultFilterTabsConfigs<StopsTab>
  >(() => {
    if (ffNoRecoveryTransfer) {
      return {
        ...defaultFilterTabsConfigs,
        tabs: defaultFilterTabsConfigs.tabs.filter(
          (tab) =>
            tab.value !== StopsTab.Recoveries &&
            tab.value !== StopsTab.Transfers,
        ),
      };
    }
    return defaultFilterTabsConfigs;
  }, [ffNoRecoveryTransfer, defaultFilterTabsConfigs]);

  const { undoUnassignStop } = useUnassignStopsRouteActions();
  const { clearRoutes } = useRouteActions();
  const { fetchRoute } = useFetchRoutes();
  const localPlanningDate: string | null =
    localStorage.getItem(PLANNING_DATE_KEY);
  const localPlanningDateExpiration: string | null = localStorage.getItem(
    PLANNING_DATE_EXPIRATION_KEY,
  );

  useMountEffect(() => {
    setPlanningDate(
      getPlanningDate({
        planningDate: localPlanningDate,
        planningDateExpiration: localPlanningDateExpiration,
      }),
      dayjs.tz.guess(),
    );
  });

  const stopsTable = (
    <DispatchStopsControlPanel
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      shouldAllowSavedFilterViews
      sequentiallyLoadRouteCardsAfterLoad
      pageType={FilterViewPage.Dispatch}
      dispatchTableColors={dispatchTableColors}
      defaultFilterTabsConfigs={filteredDefaultFilterTabsConfigs}
      selectedTerminalUuid={selectedTerminalUuid}
    />
  );

  return (
    <Stack sx={{ background: 'white', height: '100%' }}>
      {!isNil(companyUuid) && !isNil(email) && (
        <DispatchMultiplayerLocationComponent
          companyUuid={companyUuid}
          name={email}
          email={email}
        />
      )}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          borderBottom: '1px solid',
          borderColor: 'divider',
          pr: 1,
        }}
      >
        <DispatchTabs />
        <Stack
          direction="row"
          justifyContent="flex-end"
          gap={1}
          alignItems="center"
        >
          <Calendar
            showButtonBar
            style={{ minWidth: '76px', maxWidth: '120px' }}
            value={planningDate?.toDate()}
            placeholder="Planning Date"
            onChange={(e) => {
              if (!isNil(e.value)) {
                clearRoutes();
                setRoutesLoading(true);
                setPlanningDate(dayjs(String(e.value)), dayjs.tz.guess());
              }
            }}
          />
          <DispatchAvatarPresenceGroup />
          <DispatchSettingsContextMenu />
        </Stack>
      </Stack>
      <TabPanel
        panelValue="map"
        selectedValue={
          showMap && isNil(selectedViewUuid) ? 'map' : selectedViewUuid
        }
      >
        <Map />
      </TabPanel>
      <TabPanel
        panelValue="routes"
        selectedValue={
          !showMap && isNil(selectedViewUuid) ? 'routes' : selectedViewUuid
        }
      >
        <Splitter
          style={{ border: 0, height: '100%' }}
          stateStorage="local"
          layout={arrangeStopsAndRoutesVertically ? 'vertical' : 'horizontal'}
          stateKey={
            arrangeStopsAndRoutesVertically
              ? 'route-splitter-vertical'
              : 'route-splitter-panel'
          }
          gutterSize={7}
        >
          <SplitterPanel
            className="flex align-items-center justify-content-center"
            size={50}
          >
            {stopsTable}
          </SplitterPanel>
          <SplitterPanel
            className="flex align-items-center justify-content-center"
            size={50}
            style={{ overflow: 'scroll' }}
          >
            {!isNil(companyUuid) && (
              <Routes terminalUuid={selectedTerminalUuid} />
            )}
          </SplitterPanel>
        </Splitter>
      </TabPanel>
      {dispatchViews?.map((view) => (
        <TabPanel
          key={view.id}
          panelValue={view.id}
          selectedValue={selectedViewUuid}
        >
          <Routes terminalUuid={selectedTerminalUuid} />
        </TabPanel>
      ))}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={4000}
        open={showUnassignedSnackbar}
        message="Unassigned Stop"
        action={
          <Button
            color="info"
            size="small"
            onClick={async () => {
              setShowUnassignedSnackbar(false);
              await undoUnassignStop();
            }}
          >
            Undo
          </Button>
        }
        onClose={() => {
          setShowUnassignedSnackbar(false);
        }}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
        open={!isNil(errorMessage)}
        onClose={() => {
          setErrorMessage(undefined);
        }}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      {!isNil(openedOrderUuid) && (
        <OrderDialog
          open
          orderUuid={openedOrderUuid}
          onClose={() => {
            getRoutesByOrderUuid(openedOrderUuid)?.forEach((r) => {
              fetchRoute(r.uuid);
            });
            setOpenedOrderUuid(undefined);
          }}
        />
      )}
    </Stack>
  );
};

export default DispatchPage;
