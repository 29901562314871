import { Tooltip, type TooltipProps } from '@mui/material';
import { type FunctionComponent } from 'react';

export type PalletTooltipProps = Record<string, unknown> & TooltipProps;

/**
 * A PalletTooltip is a simple wrapper for {@link Tooltip} with some default
 * options.
 */
export const PalletTooltip: FunctionComponent<PalletTooltipProps> = ({
  children,
  ...props
}) => {
  return (
    <Tooltip
      arrow
      placement="top"
      slotProps={{
        popper: {
          modifiers: [
            // Move the tooltip up 4px to account for the arrow.
            {
              name: 'offset',
              options: {
                offset: [0, -4],
              },
            },
          ],
        },
      }}
      {...props}
    >
      {children}
    </Tooltip>
  );
};
