import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import type React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { exhaustive } from 'shared/switch';
import { objectKeys } from 'tsafe';
import GeneralLedgerCodeAutocomplete from '../../../../common/components/general-ledger-code-autocomplete';
import {
  validateNonNegativeNumber,
  validateString,
  type ValidationResponse,
} from '../../../../common/form/formValidators';
import useTerminals from '../../../../common/react-hooks/use-terminals';
import {
  useAccessorialLazyQuery,
  useCreateAccessorialMutation,
  useFuelProfilesQuery,
  useUpdateAccessorialMutation,
  type UpdateAccessorialInput,
  AccessorialType,
} from '../../../../generated/graphql';
import { muiStyles } from '../accessorial.styles';
import AccessorialPrices from './accessorial-prices/accessorial-prices';
import {
  ALL_TERMINALS,
  FormMode,
  getBackUrl,
  NO_FUEL_PROFILE,
  type WaitTimeAccessorialFormErrors,
  type WaitTimeAccessorialFormFieldName,
  type WaitTimeAccessorialFormValues,
  WaitTimeAccessorialTextField,
} from './common';

const validateField = (
  field: WaitTimeAccessorialFormFieldName,
  value: string | number | boolean | null | undefined,
) => {
  switch (field) {
    case 'name': {
      return validateString(value as string, true);
    }
    case 'maximumCharge':
    case 'minimumCharge': {
      return validateNonNegativeNumber(value as number, false);
    }

    case 'generalLedgerCodeId':
    case 'invoiceDisplayName': {
      return { valid: true, explanation: '' };
    }
    case 'rate':
    case 'percentForSettlement':
    case 'waitTimeFreeMinutes':
    case 'waitTimeChargePeriod':
    case 'terminalUuid':
    case 'fuelProfileUuid':
    case 'code':
    case 'ediCode': {
      const valid = value !== null;
      const explanation = valid ? '' : 'field is required';
      return { valid, explanation };
    }
    default: {
      return exhaustive(field);
    }
  }
};

const validateAllFields = (formValues: WaitTimeAccessorialFormValues) => {
  const invalidFields: Partial<
    Record<WaitTimeAccessorialFormFieldName, ValidationResponse>
  > = {};
  for (const fieldName of objectKeys(formValues)) {
    const result = validateField(fieldName, formValues[fieldName]);
    if (!result.valid) {
      invalidFields[fieldName] = result;
    }
  }
  return invalidFields;
};

const makeCreateInput = (
  formValues: WaitTimeAccessorialFormValues,
  contactUuid: string | undefined,
  templateUuid: string | undefined,
  isAuthoCodeRequired: boolean,
) => {
  // This should never happen since these must be validated beforehand.
  if (
    formValues.rate === null ||
    formValues.name === null ||
    formValues.waitTimeChargePeriod === null ||
    formValues.waitTimeFreeMinutes === null
  ) {
    throw new Error(
      'Name or rate or charge period or free minutes is null after validation',
    );
  }
  return {
    variables: {
      input: {
        accessorialCreateInput: {
          waitTimeAccessorialCreateInput: {
            maximumCharge: formValues.maximumCharge,
            minimumCharge: formValues.minimumCharge,
            name: formValues.name,
            rate: formValues.rate,
            waitTimeFreeMinutes: formValues.waitTimeFreeMinutes,
            waitTimeChargePeriod: formValues.waitTimeChargePeriod,
          },
          matchingGlobalAccessorial: templateUuid,
          contactUuid,
          percentForSettlement: formValues.percentForSettlement,
          terminalUuid:
            formValues.terminalUuid === ALL_TERMINALS
              ? null
              : formValues.terminalUuid,
          code: formValues.code,
          ediCode: formValues.ediCode,
          fuelProfileUuid:
            formValues.fuelProfileUuid === NO_FUEL_PROFILE
              ? null
              : formValues.fuelProfileUuid,
          isAuthoCodeRequired,
          generalLedgerCodeId: formValues.generalLedgerCodeId,
        },
      },
    },
  };
};

const makeUpdateInput = (
  formValues: WaitTimeAccessorialFormValues,
  uuid: string,
  isAuthoCodeRequired: boolean,
): UpdateAccessorialInput => {
  // This should never happen since these must be validated beforehand.
  if (
    formValues.rate === null ||
    formValues.name === null ||
    formValues.waitTimeChargePeriod === null ||
    formValues.waitTimeFreeMinutes === null
  ) {
    throw new Error(
      'Name or rate or charge period or free minutes is null after validation',
    );
  }
  return {
    accessorialUpdateInput: {
      waitTimeAccessorialUpdateInput: {
        maximumCharge: formValues.maximumCharge,
        minimumCharge: formValues.minimumCharge,
        name: formValues.name,
        rate: formValues.rate,
        waitTimeFreeMinutes: formValues.waitTimeFreeMinutes,
        waitTimeChargePeriod: formValues.waitTimeChargePeriod,
        uuid,
      },
      percentForSettlement: formValues.percentForSettlement,
      terminalUuid:
        formValues.terminalUuid === ALL_TERMINALS
          ? null
          : formValues.terminalUuid,
      code: formValues.code,
      ediCode: formValues.ediCode,
      fuelProfileUuid:
        formValues.fuelProfileUuid === NO_FUEL_PROFILE
          ? null
          : formValues.fuelProfileUuid,
      isAuthoCodeRequired,
      generalLedgerCodeId: formValues.generalLedgerCodeId,
      invoiceDisplayName: formValues.invoiceDisplayName,
    },
  };
};

const useFormValues = (
  uuid: string | undefined,
): [
  WaitTimeAccessorialFormValues,
  React.Dispatch<React.SetStateAction<WaitTimeAccessorialFormValues>>,
] => {
  const [formValues, setFormValues] = useState<WaitTimeAccessorialFormValues>({
    name: null,
    rate: null,
    maximumCharge: null,
    minimumCharge: null,
    waitTimeChargePeriod: null,
    waitTimeFreeMinutes: null,
    percentForSettlement: 100,
    terminalUuid: ALL_TERMINALS,
    fuelProfileUuid: NO_FUEL_PROFILE,
    code: null,
    ediCode: null,
    invoiceDisplayName: null,
    generalLedgerCodeId: null,
  });
  const [accessorialQuery] = useAccessorialLazyQuery();

  useEffect(() => {
    if (uuid !== undefined) {
      accessorialQuery({ variables: { uuid: uuid ?? '' } }).then((response) => {
        const accessorial = response.data?.accessorial;
        if (!isNil(accessorial)) {
          const isWaitTime =
            accessorial.__typename === 'WaitTimeAccessorialEntity';
          const terminalUuid = isWaitTime ? accessorial?.terminal?.uuid : null;
          const fuelProfileUuid = isWaitTime
            ? accessorial?.fuelProfile?.uuid
            : null;
          const {
            name,
            code,
            ediCode,
            percentForSettlement,
            invoiceDisplayName,
            generalLedgerCode,
          } = accessorial;
          let rate;
          let waitTimeChargePeriod;
          let waitTimeFreeMinutes;
          let minimumCharge;
          let maximumCharge;
          if (isWaitTime) {
            rate = accessorial.rate;
            waitTimeChargePeriod = accessorial.waitTimeChargePeriod;
            waitTimeFreeMinutes = accessorial.waitTimeFreeMinutes;
            minimumCharge = accessorial.minimumCharge;
            maximumCharge = accessorial.maximumCharge;
          }
          setFormValues({
            name,
            rate: rate ?? null,
            minimumCharge: minimumCharge ?? null,
            maximumCharge: maximumCharge ?? null,
            waitTimeChargePeriod: waitTimeChargePeriod ?? null,
            waitTimeFreeMinutes: waitTimeFreeMinutes ?? null,
            percentForSettlement: percentForSettlement ?? null,
            terminalUuid: isEmpty(terminalUuid) ? ALL_TERMINALS : terminalUuid,
            fuelProfileUuid: isEmpty(fuelProfileUuid)
              ? NO_FUEL_PROFILE
              : fuelProfileUuid,
            code: code ?? null,
            ediCode: ediCode ?? null,
            invoiceDisplayName: invoiceDisplayName ?? null,
            generalLedgerCodeId: generalLedgerCode?.id ?? null,
          });
        }
      });
    }
  }, [accessorialQuery, uuid]);

  return [formValues, setFormValues];
};

type WaitTimeAccessorialFormProps = {
  readonly mode: FormMode;
  readonly uuid: string | undefined;
  readonly contactUuid: string | undefined;
  readonly templateUuid?: string | undefined;
  readonly isAuthoCodeRequired: boolean;
};

// Existing accessorial contained in edit / view mode
const WaitTimeAccessorialForm = ({
  mode,
  uuid,
  contactUuid,
  templateUuid,
  isAuthoCodeRequired,
}: WaitTimeAccessorialFormProps) => {
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState<WaitTimeAccessorialFormErrors>(
    {},
  );
  const [formValues, setFormValues] = useFormValues(
    templateUuid === undefined ? uuid : templateUuid,
  );

  const { terminalsEnabled, terminals, terminalsLoading } = useTerminals({
    includeInactiveTerminals: false,
  });
  const { data: fuelProfilesData, loading: fuelProfilesLoading } =
    useFuelProfilesQuery();

  const [createAccessorial] = useCreateAccessorialMutation();
  const [updateAccessorial] = useUpdateAccessorialMutation();

  const onChange = (
    field: WaitTimeAccessorialFormFieldName,
    value: string | number | boolean | null,
  ) => {
    setFormValues({ ...formValues, [field]: value });
  };

  const onBlur = (field: WaitTimeAccessorialFormFieldName) => {
    const validationResult = validateField(field, formValues[field]);
    if (validationResult.valid) {
      setFormErrors({ ...formErrors, [field]: undefined });
    } else {
      setFormErrors({ ...formErrors, [field]: validationResult.explanation });
    }
  };

  const onSave = async () => {
    const invalidFields = validateAllFields(formValues);
    const invalidFieldNames = objectKeys(invalidFields);
    if (invalidFieldNames.length === 0) {
      if (mode === FormMode.CREATE) {
        const input = makeCreateInput(
          formValues,
          contactUuid,
          templateUuid,
          isAuthoCodeRequired,
        );
        // TODO: handle the errors from this don't just assume it passes
        await createAccessorial(input);
        navigate(getBackUrl(contactUuid));
      } else if (mode === FormMode.EDIT) {
        // This should never happen
        if (isNil(uuid)) {
          throw new Error('uuid is not defined but in edit mode');
        }
        const input = makeUpdateInput(formValues, uuid, isAuthoCodeRequired);
        // TODO: handle the errors from this don't just assume it passes
        await updateAccessorial({
          variables: {
            input,
          },
        });

        navigate(getBackUrl(contactUuid));
      }
    } else {
      for (const field of invalidFieldNames) {
        const validationResultExplanation =
          invalidFields[field]?.explanation ?? '';
        setFormErrors({
          ...formErrors,
          [field]: validationResultExplanation,
        });
      }
    }
  };

  return (
    <Box sx={muiStyles.pageContainer}>
      <Box sx={muiStyles.buttonRow}>
        {(mode === FormMode.CREATE || mode === FormMode.EDIT) && (
          <Button variant="contained" onClick={onSave}>
            Save
          </Button>
        )}
      </Box>
      <Box sx={muiStyles.pageContent}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <WaitTimeAccessorialTextField
              fullWidth
              mode={mode}
              disabled={!isNil(contactUuid)}
              value={formValues.name}
              error={formErrors?.name}
              type="text"
              name="name"
              label="Name"
              onBlur={onBlur}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={4}>
            <WaitTimeAccessorialTextField
              isPercent
              fullWidth
              mode={mode}
              value={formValues.percentForSettlement}
              error={formErrors?.percentForSettlement}
              name="percentForSettlement"
              label="Settlement Rate"
              type="number"
              onBlur={onBlur}
              onChange={onChange}
            />
          </Grid>
          {mode === FormMode.CREATE ? (
            <Grid item xs={4}>
              <WaitTimeAccessorialTextField
                fullWidth
                mode={mode}
                value={formValues.rate}
                error={formErrors?.rate}
                name="rate"
                label="Rate"
                type="number"
                onBlur={onBlur}
                onChange={onChange}
              />
            </Grid>
          ) : (
            <Grid item xs={4} />
          )}
          <Grid item xs={4}>
            <WaitTimeAccessorialTextField
              fullWidth
              mode={mode}
              value={formValues.maximumCharge}
              error={formErrors?.maximumCharge}
              name="maximumCharge"
              label="Maximum Charge"
              type="number"
              onBlur={onBlur}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={4}>
            <WaitTimeAccessorialTextField
              fullWidth
              mode={mode}
              value={formValues.minimumCharge}
              error={formErrors?.minimumCharge}
              name="minimumCharge"
              label="Minimum Charge"
              type="number"
              onBlur={onBlur}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <WaitTimeAccessorialTextField
              fullWidth
              mode={mode}
              value={formValues.waitTimeChargePeriod}
              error={formErrors?.waitTimeChargePeriod}
              name="waitTimeChargePeriod"
              label="Charge period (minutes)"
              type="number"
              onBlur={onBlur}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={4}>
            <WaitTimeAccessorialTextField
              fullWidth
              mode={mode}
              value={formValues.waitTimeFreeMinutes}
              error={formErrors?.waitTimeFreeMinutes}
              name="waitTimeFreeMinutes"
              label="Free time (minutes)"
              type="number"
              onBlur={onBlur}
              onChange={onChange}
            />
          </Grid>
          {terminalsEnabled && (
            <Grid item xs={3}>
              <FormControl
                sx={{ width: '100%' }}
                error={!isNil(formErrors.terminalUuid)}
              >
                <InputLabel
                  shrink={
                    !isNil(formValues.terminalUuid) &&
                    !isEmpty(formValues.terminalUuid)
                  }
                  id="select-terminal-label"
                >
                  Terminal
                </InputLabel>
                <Select
                  fullWidth
                  labelId="select-terminal-label"
                  id="select-terminal"
                  label="Terminal"
                  value={formValues.terminalUuid}
                  disabled={terminalsLoading}
                  size="small"
                  onChange={(event) => {
                    if (typeof event.target.value === 'string') {
                      setFormValues({
                        ...formValues,
                        terminalUuid: event.target.value,
                      });
                    }
                  }}
                >
                  <MenuItem key={ALL_TERMINALS} value={ALL_TERMINALS}>
                    {ALL_TERMINALS}
                  </MenuItem>
                  {terminals?.map((terminal) => (
                    <MenuItem key={terminal.uuid} value={terminal.uuid}>
                      {`${terminal.name} (${terminal.code})`}
                    </MenuItem>
                  ))}
                </Select>
                {!isNil(formErrors.terminalUuid) && (
                  <FormHelperText error>
                    {formErrors.terminalUuid}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
          {mode === FormMode.CREATE && (
            <Grid item xs={4}>
              <FormControl
                sx={{ width: '100%' }}
                error={!isNil(formErrors.fuelProfileUuid)}
              >
                <InputLabel
                  shrink={
                    !isNil(formValues.fuelProfileUuid) &&
                    !isEmpty(formValues.fuelProfileUuid)
                  }
                  id="select-fuel-profile-label"
                >
                  Fuel Profile
                </InputLabel>
                <Select
                  fullWidth
                  labelId="select-fuel-profile-label"
                  id="select-fuel-profile"
                  label="Fuel Profile"
                  value={formValues.fuelProfileUuid}
                  disabled={fuelProfilesLoading}
                  size="small"
                  onChange={(event) => {
                    if (typeof event.target.value === 'string') {
                      setFormValues({
                        ...formValues,
                        fuelProfileUuid: event.target.value,
                      });
                    }
                  }}
                >
                  <MenuItem key={NO_FUEL_PROFILE} value={NO_FUEL_PROFILE}>
                    {NO_FUEL_PROFILE}
                  </MenuItem>
                  {fuelProfilesData?.fuelProfiles?.map((fuelProfile) => (
                    <MenuItem key={fuelProfile.uuid} value={fuelProfile.uuid}>
                      {fuelProfile.name}
                    </MenuItem>
                  ))}
                </Select>
                {!isNil(formErrors.fuelProfileUuid) && (
                  <FormHelperText error>
                    {formErrors.fuelProfileUuid}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
          <Grid item xs={3}>
            <WaitTimeAccessorialTextField
              fullWidth
              mode={mode}
              disabled={!isNil(contactUuid)}
              value={formValues.code}
              error={formErrors?.code}
              type="text"
              name="code"
              label="Accessorial Code"
              onBlur={onBlur}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={3}>
            <WaitTimeAccessorialTextField
              fullWidth
              mode={mode}
              disabled={!isNil(contactUuid)}
              value={formValues.ediCode}
              error={formErrors?.ediCode}
              type="text"
              name="ediCode"
              label="EDI code"
              onBlur={onBlur}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={3}>
            <WaitTimeAccessorialTextField
              fullWidth
              mode={mode}
              disabled={!isNil(contactUuid)}
              value={formValues.invoiceDisplayName}
              error={formErrors?.invoiceDisplayName}
              type="text"
              name="invoiceDisplayName"
              label="Display on invoices as"
              onBlur={onBlur}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={3}>
            <GeneralLedgerCodeAutocomplete
              value={formValues.generalLedgerCodeId}
              formError={formErrors.generalLedgerCodeId ?? null}
              setValue={(newValue: string | null) => {
                setFormValues({
                  ...formValues,
                  generalLedgerCodeId: newValue,
                });
              }}
              disabled={!isNil(contactUuid)}
              size="small"
            />
          </Grid>
          {!isNil(uuid) && (
            <Grid item xs={12}>
              <AccessorialPrices
                accessorialUuid={uuid}
                accessorialType={AccessorialType.WaitTime}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default WaitTimeAccessorialForm;
