import { isEmpty, isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { transformDateToDateString } from '../../../common/utils/prettyPrintUtils';
import {
  DocumentType,
  type ShallowRoutesWithDocumentsQuery,
  StopStatus,
} from '../../../generated/graphql';

export type Route = {
  uuid: string;
  name: string;
  driver: string;
  numberOfStops: number;
  startDate: string;
  numberOfDocumentsUploaded: number;
  numberOfRemainingStops: number;
  numberOfCompleteStops: number;
  numberOfAttemptedStops: number;
  lastLocation: string;
  nextLocation: string;
};

const useTableData = ({
  routesData,
}: {
  routesData: ShallowRoutesWithDocumentsQuery | null;
}) => {
  const [tableData, setTableData] = useState([] as Route[]);
  useEffect(() => {
    const newTableData: Route[] = [];
    for (const route of routesData?.routes ?? []) {
      let driverNames = route.drivers
        .map((driver) => `${driver.firstName} ${driver.lastName}`)
        .join(', ');
      const numberOfDocumentsUploaded = route.slots.reduce((prev, curr) => {
        const shipment = curr.stops[0]?.leg.shipment;
        const hasEndOfDayDocuments = shipment?.documents?.some(
          (document) => document.type === DocumentType.EndOfDayDocument,
        );
        return hasEndOfDayDocuments === true ? prev + 1 : prev;
      }, 0);
      // Assuming no grouped stops in route.
      const numberOfCompleteStops = route.slots.reduce((prev, curr) => {
        if (isNil(curr.stops) || isEmpty(curr.stops)) {
          return prev;
        }
        const isComplete = curr.stops.every(
          (stop) => stop.status === StopStatus.Completed,
        );
        return isComplete ? prev + 1 : prev;
      }, 0);
      const numberOfAttemptedStops = route.slots.reduce((prev, curr) => {
        const isComplete = curr.stops.every(
          (stop) => stop.status === StopStatus.Failed,
        );
        return isComplete ? prev + 1 : prev;
      }, 0);
      const numberOfRemainingStops = route.slots.reduce((prev, curr) => {
        const isRemaining = curr.stops.every(
          (stop) =>
            stop.status === StopStatus.NotArrived ||
            stop.status === StopStatus.Arrived,
        );
        return isRemaining ? prev + 1 : prev;
      }, 0);
      const lastCompleteSlot = route.slots.find((slot) =>
        slot.stops.every((stop) => stop.status === StopStatus.Completed),
      );
      const lastCompletedStop = lastCompleteSlot?.stops[0];
      const firstIncompleteSlot = route.slots.find((slot) =>
        slot.stops.every((stop) => stop.status === StopStatus.NotArrived),
      );
      const firstIncompleteStop = firstIncompleteSlot?.stops[0];
      driverNames = driverNames.length > 0 ? driverNames : 'Not assigned yet';
      const data = {
        uuid: route.uuid,
        name: route.name,
        driver: driverNames,
        numberOfStops: route.slots.length,
        startDate: transformDateToDateString(route.date),
        numberOfDocumentsUploaded,
        numberOfRemainingStops,
        numberOfCompleteStops,
        numberOfAttemptedStops,
        lastLocation: lastCompletedStop?.address.city ?? 'Warehouse',
        nextLocation: firstIncompleteStop?.address.city ?? 'None',
      };
      newTableData.push(data);
    }
    setTableData(newTableData);
  }, [routesData]);
  return tableData;
};

export default useTableData;
