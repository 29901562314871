import { Stack, type SxProps } from '@mui/material';
import type { FunctionComponent, PropsWithChildren } from 'react';
import React from 'react';
import { ORDER_PAGE_CONTAINER_TEST_ID } from '../../../../../constants';
import theme from '../../../../theme';

type OrderPageToolbarProps = PropsWithChildren & {
  readonly id?: string;
  readonly sx?: SxProps;
};

const OrderPageToolbar: FunctionComponent<OrderPageToolbarProps> = ({
  id,
  sx,
  children,
}) => (
  <Stack
    id={id}
    sx={{
      flexShrink: 0,
      minHeight: '56px',
      flexDirection: 'row',
      gap: '10px',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      p: '7px 10px 7px 7px',
      backgroundColor: 'white',
      borderBottom: `1px solid ${theme.palette.borderColor.main}`,
      flexWrap: 'wrap',
      ...sx,
    }}
    data-testid={ORDER_PAGE_CONTAINER_TEST_ID}
  >
    {children}
  </Stack>
);

export default React.memo(OrderPageToolbar);
