import { Check, ExpandMore } from '@mui/icons-material';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import AutocompleteFuzzy from '../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';

import { type Option } from '../filters/types';
import useDrivers from '../react-hooks/use-drivers';
import useStyles from './general-styles';
import MultiselectFilterButton, {
  getMultiselectFilterLabel,
} from './multiselect-filter-button';

const CACHE_PREFIX = 'DRIVER_FILTER';

type DriverFilterButtonProps = {
  // Single select
  readonly selectedOption?: Option | null | undefined;
  readonly handleChange?: (option: Option | null | undefined) => void;

  // Multi-select
  readonly selectedOptionsMultiselect?: Option[] | null | undefined;
  readonly handleChangeMultiselect?: (
    options: Option[] | null | undefined,
  ) => void;

  readonly prefixText: string;
  readonly displayNoneOptionMultiselect?: boolean; // Only supported for multiselect right now
  readonly cacheId?: string;
};

const DriverFilterButton = ({
  cacheId,
  prefixText,
  selectedOption,
  handleChange,
  selectedOptionsMultiselect,
  handleChangeMultiselect,
  displayNoneOptionMultiselect = false,
}: DriverFilterButtonProps) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const styles = useStyles();
  const isAllSelected = selectedOption === undefined;
  const { drivers, getDriverName } = useDrivers();

  useEffect(() => {
    if (!isNil(cacheId) && !isNil(handleChange)) {
      const cachedLabel = localStorage.getItem(
        `${CACHE_PREFIX}_LABEL_${cacheId}`,
      );
      const cachedValue = localStorage.getItem(
        `${CACHE_PREFIX}_VALUE_${cacheId}`,
      );
      if (!isNil(cachedLabel) && !isNil(cachedValue)) {
        handleChange({
          label: cachedLabel,
          value: cachedValue,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const driverOptions = useMemo(
    () =>
      drivers?.map((driver) => ({
        label: getDriverName(driver.uuid),
        value: driver.uuid,
      })) ?? [],
    [drivers, getDriverName],
  );

  if (isNil(handleChange) && isNil(handleChangeMultiselect)) {
    return <Box />;
  }

  const filterLabel = getMultiselectFilterLabel(
    // eslint-disable-next-line no-nested-ternary
    isNil(selectedOptionsMultiselect)
      ? isNil(selectedOption)
        ? null
        : [selectedOption]
      : selectedOptionsMultiselect,
    displayNoneOptionMultiselect,
  );

  if (!isNil(handleChangeMultiselect)) {
    return (
      <MultiselectFilterButton
        selectedOptions={selectedOptionsMultiselect}
        options={driverOptions}
        handleChange={handleChangeMultiselect}
        prefixText={prefixText}
        displayNoneOption={displayNoneOptionMultiselect}
        // isSmall={isSmall}
        filterLabel={filterLabel}
        cachePrefix={CACHE_PREFIX}
        cacheId={cacheId}
      />
    );
  }

  const onChange = (option: Option | null | undefined) => {
    if (!isNil(handleChange)) {
      if (!isNil(cacheId)) {
        if (!isNil(option) && !isNil(option.label) && !isNil(option.value)) {
          localStorage.setItem(
            `${CACHE_PREFIX}_LABEL_${cacheId}`,
            option.label,
          );
          localStorage.setItem(
            `${CACHE_PREFIX}_VALUE_${cacheId}`,
            option.value,
          );
        } else {
          localStorage.removeItem(`${CACHE_PREFIX}_LABEL_${cacheId}`);
          localStorage.removeItem(`${CACHE_PREFIX}_VALUE_${cacheId}`);
        }
      }
      handleChange(option);
    }
  };

  return (
    <Box>
      <Button
        size="large"
        variant="outlined"
        sx={[styles.filterButton]}
        onClick={(e) => {
          setMenuAnchorEl(e.currentTarget);
        }}
      >
        <Box
          sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}
        >
          <Typography sx={styles.filterTitle}>{prefixText}:</Typography>
          <Typography sx={styles.filterValue}>
            {selectedOption?.label ?? 'All'}
          </Typography>
          <ExpandMore fontSize="small" sx={{ mr: 0 }} />
        </Box>
      </Button>
      <Menu
        anchorEl={menuAnchorEl}
        id="driver-menu"
        open={Boolean(menuAnchorEl)}
        sx={{
          '& .MuiMenu-paper': { overflow: 'visible' },
          top: '3px',
        }}
        onClose={() => {
          setMenuAnchorEl(null);
        }}
      >
        <MenuList
          dense
          sx={{
            p: 0,
          }}
        >
          <MenuItem
            key="all"
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'visible',
              pl: '10px',
            }}
            onClick={() => {
              onChange(undefined);
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Check
                sx={{
                  visibility:
                    selectedOption === undefined ? undefined : 'hidden',
                  fontSize: '14px',
                  ml: 0,
                  mr: '6px',
                }}
              />
              <Typography sx={styles.menuText}>All</Typography>
            </Stack>
          </MenuItem>
          <MenuItem
            key="custom"
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'visible',
              pl: '10px',
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Check
                sx={{
                  visibility: isAllSelected ? 'hidden' : undefined,
                  fontSize: '14px',
                  ml: 0,
                  mr: '6px',
                }}
              />
              <AutocompleteFuzzy
                size="small"
                sx={{ backgroundColor: 'white', width: '200px' }}
                value={selectedOption}
                options={driverOptions}
                matchSortOptions={{ keys: ['label'] }}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    size="small"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    onKeyDown={(e) => {
                      e.stopPropagation();
                    }}
                  />
                )}
                renderOption={(props, option) => {
                  const driver = drivers?.find((d) => d.uuid === option.value);
                  return (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <li {...props} key={option.value}>
                      <Stack>
                        <Typography sx={{ fontSize: '14px' }}>
                          {option.label}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          {driver?.driverReferenceNumber}
                        </Typography>
                      </Stack>
                    </li>
                  );
                }}
                onClick={(e) => {
                  e.preventDefault();
                }}
                onChange={(_, option) => {
                  onChange(option);
                }}
              />
            </Stack>
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default DriverFilterButton;
