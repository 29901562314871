import {
  Card,
  CardContent,
  Menu,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import type * as React from 'react';
import useInvoicesStore from '../../../invoices-store';
import FileDownloadCard from './file-download-card';

type FileDownloadsMenuProps = {
  readonly open: boolean;
  readonly setOpen: (isOpen: boolean) => void;
  readonly anchorEl: React.RefObject<HTMLAnchorElement>;
};

const CardContentSpecialPadding = styled(CardContent)(`
  padding: 10px;
  &:last-child {
    padding-bottom: 5px;
  }
`);

const FileDownloadsMenu = ({
  open,
  setOpen,
  anchorEl,
}: FileDownloadsMenuProps) => {
  const invoiceDownloads = useInvoicesStore((state) => state.invoiceDownloads);

  return (
    <Menu
      id="file-downloads-menu"
      anchorEl={anchorEl.current}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Card sx={{ border: 'none', boxShadow: 'none', width: '400px' }}>
        <CardContentSpecialPadding>
          <Stack direction="column" spacing={2}>
            {!isNil(invoiceDownloads) && invoiceDownloads.length > 0 ? (
              [...invoiceDownloads]
                .reverse()
                .map((fileDownload) => (
                  <FileDownloadCard fileDownload={fileDownload} />
                ))
            ) : (
              <Typography color="text.secondary">No Files</Typography>
            )}
          </Stack>
        </CardContentSpecialPadding>
      </Card>
    </Menu>
  );
};

export default FileDownloadsMenu;
