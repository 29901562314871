import { Warning } from '@mui/icons-material';
// eslint-disable-next-line no-restricted-imports
import { Grid, Stack, Tooltip, styled } from '@mui/material';
import {
  type ColDef,
  type ICellRendererParams,
  type ValueGetterParams,
} from 'ag-grid-community';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCurrentTimeDefaultTimezone } from 'shared/time';
import useWarehouses from '../../../common/react-hooks/use-warehouses';
import {
  FilterViewPage,
  type FindStorageOrderStorageUnitsFragment,
  type StorageOrderFragment,
  useCompanyConfigurationQuery,
} from '../../../generated/graphql';
import {
  BooleanFilter,
  DateSelectFilter,
} from '../../ag-grid/filter-components';
import StorageOrdersTableWithFiltersAgGrid from '../../ag-grid/wms/storage-orders/storage-orders-table-with-filters-ag-grid';
import { ExcludeFromHiding } from '../../orders/components/enums/order-filters';
import {
  StorageOrdersFilterField,
  StorageOrdersTableField,
  getStorageOrdersTableField,
  getStorageOrdersTableFieldCopy,
} from '../utils';
import StorageOrdersMenu from './storage-orders-menu';

const StorageOrdersViewContainer = styled(Grid)`
  padding: 24px;
`;

const STORAGE_ORDERS_PAGE_SIZE = 15;

const StorageOrdersView = () => {
  const [storageUnitLabelsModalOpen, setStorageUnitLabelsModalOpen] =
    useState(false);
  const [selectedStorageOrderForLabels, setSelectedStorageOrderForLabels] =
    useState<StorageOrderFragment | undefined>();
  const [selectedStorageUnitsForLabels, setSelectedStorageUnitsForLabels] =
    useState<FindStorageOrderStorageUnitsFragment[]>([]);

  const navigate = useNavigate();
  const { data: companyConfigurationData } = useCompanyConfigurationQuery();
  const wmsStorageOrdersEnabled =
    companyConfigurationData?.companyConfiguration?.wmsStorageOrdersEnabled;
  const { warehouses } = useWarehouses();

  useEffect(() => {
    if (wmsStorageOrdersEnabled === false) {
      navigate(`/warehouse/settings`);
    }
  }, [wmsStorageOrdersEnabled, navigate]);

  const filterColumns = [
    {
      field: StorageOrdersFilterField.OSD,
      hide: true,
      filter: BooleanFilter,
      filterParams: {
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
    {
      field: StorageOrdersFilterField.EXPECTED_AT,
      hide: true,
      filter: DateSelectFilter,
      filterParams: {
        values: {
          startDate: getCurrentTimeDefaultTimezone().startOf('day').toDate(),
          endDate: getCurrentTimeDefaultTimezone().endOf('day').toDate(),
        },
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
    {
      field: StorageOrdersFilterField.ARRIVED_AT,
      hide: true,
      filter: DateSelectFilter,
      filterParams: {
        values: {
          startDate: getCurrentTimeDefaultTimezone().startOf('day').toDate(),
          endDate: getCurrentTimeDefaultTimezone().endOf('day').toDate(),
        },
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
  ];
  filterColumns.sort((a, b) =>
    a.field.toString().localeCompare(b.field.toString()),
  );
  const columns = [
    ...filterColumns,
    {
      field: StorageOrdersTableField.CONTACT,
      headerName: getStorageOrdersTableFieldCopy(
        StorageOrdersTableField.CONTACT,
      ),
      resizable: true,
      valueGetter: (params: ValueGetterParams<StorageOrderFragment>) => {
        return getStorageOrdersTableField(
          StorageOrdersTableField.CONTACT,
          params.data,
        );
      },
    },
    {
      field: StorageOrdersTableField.REFERENCE_NUMBER,
      headerName: getStorageOrdersTableFieldCopy(
        StorageOrdersTableField.REFERENCE_NUMBER,
      ),
      resizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cellRenderer: (params: ValueGetterParams<StorageOrderFragment>) => {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            {getStorageOrdersTableField(
              StorageOrdersTableField.REFERENCE_NUMBER,
              params.data,
            )}
            {params.data?.isOSD === true && (
              <Tooltip title="Marked OSD" placement="top">
                <Warning color="warning" />
              </Tooltip>
            )}
          </Stack>
        );
      },
    },
    {
      field: StorageOrdersTableField.ARRIVED_AT,
      headerName: getStorageOrdersTableFieldCopy(
        StorageOrdersTableField.ARRIVED_AT,
      ),
      resizable: true,
      valueGetter: (params: ValueGetterParams<StorageOrderFragment>) => {
        return getStorageOrdersTableField(
          StorageOrdersTableField.ARRIVED_AT,
          params.data,
        );
      },
    },
    {
      field: StorageOrdersTableField.WAREHOUSE,
      headerName: getStorageOrdersTableFieldCopy(
        StorageOrdersTableField.WAREHOUSE,
      ),
      resizable: true,
      valueGetter: (params: ValueGetterParams<StorageOrderFragment>) => {
        return getStorageOrdersTableField(
          StorageOrdersTableField.WAREHOUSE,
          params.data,
        );
      },
      hide: (warehouses?.length ?? 0) < 2,
    },
    {
      field: StorageOrdersTableField.PURCHASE_ORDER_NUMBER,
      headerName: getStorageOrdersTableFieldCopy(
        StorageOrdersTableField.PURCHASE_ORDER_NUMBER,
      ),
      resizable: true,
      valueGetter: (params: ValueGetterParams<StorageOrderFragment>) => {
        return getStorageOrdersTableField(
          StorageOrdersTableField.PURCHASE_ORDER_NUMBER,
          params.data,
        );
      },
    },
    {
      field: StorageOrdersTableField.CARRIER,
      headerName: getStorageOrdersTableFieldCopy(
        StorageOrdersTableField.CARRIER,
      ),
      resizable: true,
      valueGetter: (params: ValueGetterParams<StorageOrderFragment>) => {
        return getStorageOrdersTableField(
          StorageOrdersTableField.CARRIER,
          params.data,
        );
      },
    },
    {
      field: StorageOrdersTableField.TRACKING_NUMBER,
      headerName: getStorageOrdersTableFieldCopy(
        StorageOrdersTableField.TRACKING_NUMBER,
      ),
      resizable: true,
      valueGetter: (params: ValueGetterParams<StorageOrderFragment>) => {
        return getStorageOrdersTableField(
          StorageOrdersTableField.TRACKING_NUMBER,
          params.data,
        );
      },
    },
    {
      field: StorageOrdersTableField.SUPPLIER_COMPANY_NAME,
      headerName: getStorageOrdersTableFieldCopy(
        StorageOrdersTableField.SUPPLIER_COMPANY_NAME,
      ),
      resizable: true,
      valueGetter: (params: ValueGetterParams<StorageOrderFragment>) => {
        return getStorageOrdersTableField(
          StorageOrdersTableField.SUPPLIER_COMPANY_NAME,
          params.data,
        );
      },
    },
    {
      field: StorageOrdersTableField.LOT_NUMBER,
      headerName: getStorageOrdersTableFieldCopy(
        StorageOrdersTableField.LOT_NUMBER,
      ),
      resizable: true,
      valueGetter: (params: ValueGetterParams<StorageOrderFragment>) => {
        return getStorageOrdersTableField(
          StorageOrdersTableField.LOT_NUMBER,
          params.data,
        );
      },
    },
    {
      field: StorageOrdersTableField.CONSIGNEE_NAME,
      headerName: getStorageOrdersTableFieldCopy(
        StorageOrdersTableField.CONSIGNEE_NAME,
      ),
      resizable: true,
      valueGetter: (params: ValueGetterParams<StorageOrderFragment>) => {
        return getStorageOrdersTableField(
          StorageOrdersTableField.CONSIGNEE_NAME,
          params.data,
        );
      },
    },
    {
      field: ExcludeFromHiding.BUTTON,
      headerName: '',
      maxWidth: 50,
      cellStyle: { display: 'flex', justifyContent: 'center' },
      // eslint-disable-next-line react/no-unstable-nested-components
      cellRenderer: (params: ICellRendererParams<StorageOrderFragment>) => {
        const storageOrder = params.data;
        return isNil(storageOrder) ? null : (
          <StorageOrdersMenu
            storageOrder={storageOrder}
            setSelectedStorageOrderForLabels={setSelectedStorageOrderForLabels}
            setStorageUnitLabelsModalOpen={setStorageUnitLabelsModalOpen}
            setSelectedStorageUnitsForLabels={setSelectedStorageUnitsForLabels}
          />
        );
      },
    },
  ];

  const [columnDefs, setColumnDefs] = // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useState<Array<ColDef<StorageOrderFragment>>>(columns);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setColumnDefs(columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StorageOrdersViewContainer container overflow="auto" height="100%">
      <Grid item xs={12}>
        <StorageOrdersTableWithFiltersAgGrid
          shouldRememberFilters
          columnDefinitions={columnDefs}
          pageSize={STORAGE_ORDERS_PAGE_SIZE}
          pageType={FilterViewPage.StorageOrders}
          storageUnitLabelsModalOpen={storageUnitLabelsModalOpen}
          setStorageUnitLabelsModalOpen={setStorageUnitLabelsModalOpen}
          selectedStorageOrderForLabels={selectedStorageOrderForLabels}
          selectedStorageUnitsForLabels={selectedStorageUnitsForLabels}
        />
      </Grid>
    </StorageOrdersViewContainer>
  );
};

export default StorageOrdersView;
