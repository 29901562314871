import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  InputLabel,
  TextField,
  Stack,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { type Dispatch, type SetStateAction } from 'react';
import { Controller, type SubmitHandler } from 'react-hook-form';
import {
  MasterAccountsDocument,
  useCreateMasterAccountMutation,
} from '../../../../../generated/graphql';
import { type MasterAccountFormValues } from './forms/types';
import useCreateMasterAccountForm from './forms/use-create-master-account-form';

type CreateMasterAccountModalProps = {
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
  readonly setSuccessSnackbarVisible: Dispatch<SetStateAction<boolean>>;
  readonly setErrorSnackbarVisible: Dispatch<SetStateAction<boolean>>;
};

const CreateMasterAccountModal = ({
  open,
  setOpen,
  setSuccessSnackbarVisible,
  setErrorSnackbarVisible,
}: CreateMasterAccountModalProps) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useCreateMasterAccountForm();

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const [createMasterAccount, { loading: createMasterAccountLoading }] =
    useCreateMasterAccountMutation({
      refetchQueries: [MasterAccountsDocument],
    });

  const onSubmit: SubmitHandler<MasterAccountFormValues> = async (data) => {
    const { name } = data;
    try {
      const response = await createMasterAccount({
        variables: {
          createMasterAccountInput: {
            name,
          },
        },
      });
      if (response?.data?.createMasterAccount?.success === true) {
        setSuccessSnackbarVisible(true);
      } else {
        setErrorSnackbarVisible(true);
      }
      handleClose();
    } catch {
      setErrorSnackbarVisible(true);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Create Master Account</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Controller
              name="name"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <InputLabel>Name</InputLabel>
                  <TextField
                    required
                    size="small"
                    value={value}
                    error={!isNil(errors.name)}
                    helperText={errors?.name?.message}
                    sx={{ width: '100%' }}
                    onChange={onChange}
                  />
                </>
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" gap={1}>
          <Button
            variant="outlined"
            disabled={createMasterAccountLoading}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={createMasterAccountLoading}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default CreateMasterAccountModal;
