import { isNil } from 'lodash';
import { useEffect } from 'react';
import useLocalStorageState from 'use-local-storage-state';
import { ORDERS_TABLE_TABS_ORDER_LOCAL_STORAGE_KEY } from '../../../../../common/local-storage/keys';
import { type SavedFilterViewFragment } from '../../../../../generated/graphql';
import { type DefaultFilterTabsConfigs } from '../../types';

const useTabOrder = <DefaultFilterTabsType>(
  defaultFilterTabsConfigs: DefaultFilterTabsConfigs<DefaultFilterTabsType>,
  savedViews: SavedFilterViewFragment[] | undefined,
) => {
  const [tabOrder, setTabOrder] = useLocalStorageState<
    Array<string | DefaultFilterTabsType>
  >(ORDERS_TABLE_TABS_ORDER_LOCAL_STORAGE_KEY, {
    defaultValue: [],
  });

  // Append new default tabs or views to tab order
  // This is important because we persist the tab order in local storage
  // even when the user logs out
  useEffect(() => {
    if (isNil(savedViews)) {
      // We don't want to filter out saved views if they're still loading
      return;
    }
    const newTabOrder = [...tabOrder];
    for (const tab of defaultFilterTabsConfigs.tabs) {
      if (!newTabOrder.includes(tab.value)) {
        newTabOrder.push(tab.value);
      }
    }
    for (const view of savedViews ?? []) {
      if (!newTabOrder.includes(view.uuid)) {
        newTabOrder.push(view.uuid);
      }
    }
    setTabOrder(newTabOrder);
  }, [defaultFilterTabsConfigs.tabs, tabOrder, setTabOrder, savedViews]);

  // Filter out items that are no longer in the default tabs or saved views
  // This is important because we persist the tab order in local storage
  // even when the user logs out
  useEffect(() => {
    if (isNil(savedViews)) {
      // We don't want to filter out saved views if they're still loading
      return;
    }
    setTabOrder((prev) =>
      prev.filter(
        (tab) =>
          defaultFilterTabsConfigs.tabs.some((t) => t.value === tab) ||
          savedViews?.some((v) => v.uuid === tab),
      ),
    );
  }, [defaultFilterTabsConfigs.tabs, setTabOrder, savedViews]);

  const reorderTabs = (sourceIndex: number, destinationIndex: number) => {
    if (
      sourceIndex < 0 ||
      sourceIndex >= tabOrder.length ||
      destinationIndex < 0 ||
      destinationIndex >= tabOrder.length
    ) {
      return;
    }
    const newTabOrder = [...tabOrder];
    const [reorderedItem] = newTabOrder.splice(sourceIndex, 1);
    if (isNil(reorderedItem)) {
      return;
    }
    newTabOrder.splice(destinationIndex, 0, reorderedItem);
    setTabOrder(newTabOrder);
  };

  return { tabOrder, reorderTabs };
};

export default useTabOrder;
