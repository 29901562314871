import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DownloadIcon from '@mui/icons-material/Download';
import { Box, Button, Stack, Typography } from '@mui/material';

import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { memo, useMemo, useState } from 'react';
import {
  type DetailedLineHaulManifestFragment,
  type ShallowLineHaulSegmentFragment,
} from '../../../../generated/graphql';
import useLineHaulDispatchStore from '../../store/line-haul-dispatch-store';
import PrintLineHaulManifestsModal from '../print-manifests/print-line-haul-manifests-modal';
import OpenedManifestContextMenu from './opened-manifest-context-menu';

const OpenedManifestToolbar = ({
  manifest,
  unmanifestedSegment,
}: {
  readonly manifest: DetailedLineHaulManifestFragment | undefined;
  readonly unmanifestedSegment: ShallowLineHaulSegmentFragment | undefined;
}) => {
  const [planningDate] = useLineHaulDispatchStore((state) => [
    state.planningDate,
  ]);

  const [setOpenedManifest, setOpenedUnmanifestedSegment] =
    useLineHaulDispatchStore((state) => [
      state.setOpenedManifest,
      state.setOpenedUnmanifestedSegment,
    ]);
  const [showPrintManifestsModal, setShowPrintManifestsModal] =
    useState<boolean>(false);

  const refNumber = useMemo(() => {
    return isNil(manifest) ? 'N/A' : `${manifest?.referenceNumber}`;
  }, [manifest, unmanifestedSegment]);

  return (
    <>
      <PrintLineHaulManifestsModal
        open={showPrintManifestsModal}
        setOpen={setShowPrintManifestsModal}
        manifestUuids={isNil(manifest) ? [] : [manifest.uuid]}
      />
      <Stack
        direction="row"
        alignItems="center"
        p={2}
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <Button
            onClick={() => {
              setOpenedManifest(undefined);
              setOpenedUnmanifestedSegment(undefined);
            }}
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
          <Stack>
            <Typography fontSize={18} variant="subtitle2">
              {/* {dayjs(planningDate).format('MM/DD')} {title}{' '}
              {!isNil(loadNumber) && `(load ${loadNumber})`} */}
              {refNumber}
            </Typography>
            <Typography color="grey" fontSize={12}>
              Depart date: {dayjs(planningDate).format('MM/DD')}
            </Typography>
          </Stack>
        </Stack>

        {!isNil(manifest) && (
          <Stack direction="row" gap={1}>
            {/* <Tooltip
              disableHoverListener={isPrintableManifest.result}
              title={isPrintableManifest.reason}
            > */}
            <Box>
              <Button
                variant="contained"
                onClick={() => {
                  setShowPrintManifestsModal(true);
                }}
                endIcon={<DownloadIcon />}
                size="small"
                // disabled={!isPrintableManifest.result}
              >
                Print
              </Button>
            </Box>
            {/* </Tooltip> */}
            <OpenedManifestContextMenu openedManifest={manifest} />
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default memo(OpenedManifestToolbar);
