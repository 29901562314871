import { joiResolver } from '@hookform/resolvers/joi';
import joi from 'joi';
import { useForm } from 'react-hook-form';
import { type OrderForPackingSlipFragment } from '../../../../generated/graphql';

export type PackingSlipFormValues = {
  order?: OrderForPackingSlipFragment;
  searchText?: string;
};

const usePackingSlipForm = () => {
  const schema = joi.object({
    order: joi
      .object({
        uuid: joi.string().required(),
        billingPartyContact: joi.object({
          uuid: joi.string().required(),
        }),
      })
      .optional()
      .unknown(),
    searchText: joi.string().optional(),
  });

  return useForm<PackingSlipFormValues>({
    resolver: joiResolver(schema),
  });
};

export default usePackingSlipForm;
