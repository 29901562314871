import {
  Button,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { isNil } from 'lodash';
import { useMemo, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../../../common/react-hooks/use-user-roles';
import {
  type CreditTypeFragment,
  CreditTypesDocument,
  PermissionResource,
  useCreditTypesQuery,
  useRemoveCreditTypeMutation,
} from '../../../../../generated/graphql';
import CreateOrEdit from '../../../enums/create-or-edit';
import AddOrEditCreditTypeModal from './add-or-edit-credit-type-modal';
import CreditTypeRow from './credit-type-row';

const CreditTypes = () => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteCompanyBilling } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyBilling,
  );

  const [addOrEditCreditTypeModalOpen, setAddOrEditCreditTypeModalOpen] =
    useState(false);

  const { data: creditTypesData, loading } = useCreditTypesQuery();
  const [deleteCreditType] = useRemoveCreditTypeMutation({
    refetchQueries: [CreditTypesDocument],
  });
  const [currentCreditType, setCurrentCreditType] =
    useState<CreditTypeFragment>();

  const handleClickAddCreditType = () => {
    setCurrentCreditType(undefined);
    setAddOrEditCreditTypeModalOpen(true);
  };
  const handleClickEditCreditType = (creditType: CreditTypeFragment) => {
    setCurrentCreditType(creditType);
    setAddOrEditCreditTypeModalOpen(true);
  };

  const sortedCreditTypes = useMemo(() => {
    return creditTypesData?.creditTypes
      .slice()
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [creditTypesData?.creditTypes]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Stack direction="column" alignItems="flex-end">
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '50%' }}>Name</TableCell>
              <TableCell>Color</TableCell>
              <TableCell>GL Code</TableCell>
              <TableCell align="right">
                <Button
                  sx={{ marginBottom: '16px' }}
                  variant="contained"
                  disabled={!canWriteCompanyBilling}
                  onClick={handleClickAddCreditType}
                >
                  Add Credit Type
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedCreditTypes?.map((creditType) => (
              <CreditTypeRow
                key={creditType.uuid}
                creditType={creditType}
                onDelete={async () => {
                  await deleteCreditType({
                    variables: {
                      uuid: creditType.uuid,
                    },
                  });
                }}
                onClickEdit={() => {
                  handleClickEditCreditType(creditType);
                }}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AddOrEditCreditTypeModal
        open={addOrEditCreditTypeModalOpen}
        setOpen={(open: boolean) => {
          setAddOrEditCreditTypeModalOpen(open);
        }}
        createOrEdit={
          isNil(currentCreditType) ? CreateOrEdit.Create : CreateOrEdit.Edit
        }
        creditType={currentCreditType}
      />
    </Stack>
  );
};

export default CreditTypes;
