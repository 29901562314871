import {
  Box,
  Button,
  TextField,
  Typography,
  Snackbar,
  Alert,
} from '@mui/material';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  MeDocument,
  PermissionResource,
  useAccessorialsQuery,
  useBulkUpdateAccessorialMappingsMutation,
  useMeQuery,
  useUpdateCompanyMutation,
} from '../../../generated/graphql';
import AccessorialMapping from '../../contacts/components/accessorial-mapping';

const QuickbooksChargeMappings = () => {
  const { data: companyData } = useMeQuery({
    fetchPolicy: 'cache-first',
  });
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteSettingsQuickbooks } = getPermissionsFlags(
    userPermissions,
    PermissionResource.SettingsQuickbooks,
  );

  const { data: accessorialData } = useAccessorialsQuery({
    variables: { onlyShowCompanyAccessorials: true },
  });
  const [updateCompany] = useUpdateCompanyMutation({
    refetchQueries: [MeDocument],
  });
  const [updateAccessorials] = useBulkUpdateAccessorialMappingsMutation({
    refetchQueries: [MeDocument],
  });
  const [freightChargeMapping, setFreightChargeMapping] = useState('');
  const [fuelChargeMapping, setFuelChargeMapping] = useState('');
  const [accessorialChargeMapping, setAccessorialChargeMapping] = useState('');
  const [pickupChargeMapping, setPickupChargeMapping] = useState('');
  const [accessorialsToUpdate, setAccessorialsToUpdate] = useState<
    Record<string, string>
  >({});
  const [showSuccessfulSave, setShowSuccessfulSave] = useState(false);
  const [showUnsuccessfulSave, setShowUnsuccessfulSave] = useState(false);

  useEffect(() => {
    const quickbooksFileMappingFreightChargeName =
      companyData?.me?.company.quickbooksFileMappingFreightChargeName;
    const quickbooksFileMappingFuelChargeName =
      companyData?.me?.company.quickbooksFileMappingFuelChargeName;
    const quickbooksFileMappingAccessorialChargeName =
      companyData?.me?.company.quickbooksFileMappingAccessorialChargeName;
    const quickbooksFileMappingPickupChargeName =
      companyData?.me?.company.quickbooksFileMappingPickupChargeName;
    setFreightChargeMapping(quickbooksFileMappingFreightChargeName ?? '');
    setFuelChargeMapping(quickbooksFileMappingFuelChargeName ?? '');
    setAccessorialChargeMapping(
      quickbooksFileMappingAccessorialChargeName ?? '',
    );
    setPickupChargeMapping(quickbooksFileMappingPickupChargeName ?? '');
  }, [companyData?.me?.company]);

  useEffect(() => {
    const accessorialState: Record<string, string> = {};
    for (const accessorial of accessorialData?.accessorials ?? []) {
      accessorialState[accessorial.uuid] =
        accessorial.quickbooksFileMappingChargeName ?? '';
    }
    setAccessorialsToUpdate(accessorialState);
  }, [accessorialData?.accessorials.length]);

  const onSave = async () => {
    const updateCompanyRes = await updateCompany({
      variables: {
        updateCompanyInput: {
          quickbooksFileMappingFreightChargeName: freightChargeMapping,
          quickbooksFileMappingFuelChargeName: fuelChargeMapping,
          quickbooksFileMappingAccessorialChargeName: accessorialChargeMapping,
          quickbooksFileMappingPickupChargeName: pickupChargeMapping,
        },
      },
    });
    const updateAccessorialRes = await updateAccessorials({
      variables: {
        bulkAccessorialUpdateInput: {
          accessorialToMappingInputs: Object.keys(accessorialsToUpdate).map(
            (accessorialUuid) => ({
              uuid: accessorialUuid,
              fileMapping: accessorialsToUpdate[accessorialUuid] ?? '',
            }),
          ),
        },
      },
    });
    if (
      !isNil(updateCompanyRes.data?.updateCompany.uuid) &&
      !isNil(updateAccessorialRes.data?.bulkUpdateAccessorialMappings.length)
    ) {
      setShowSuccessfulSave(true);
    } else {
      setShowUnsuccessfulSave(true);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showSuccessfulSave}
        onClose={() => {
          setShowSuccessfulSave(false);
        }}
      >
        <Alert>Successfully saved Quickbooks mappings</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showUnsuccessfulSave}
        onClose={() => {
          setShowUnsuccessfulSave(false);
        }}
      >
        <Alert severity="error">Failed to save</Alert>
      </Snackbar>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }}>Charge mappings</Typography>
        <Button
          variant="contained"
          disabled={!canWriteSettingsQuickbooks}
          onClick={onSave}
        >
          Save
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Typography>Freight charge mapping</Typography>
        <TextField
          value={freightChargeMapping}
          size="small"
          onChange={(e) => {
            setFreightChargeMapping(e.target.value);
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Typography>Fuel charge mapping</Typography>
        <TextField
          value={fuelChargeMapping}
          size="small"
          disabled={!canWriteSettingsQuickbooks}
          onChange={(e) => {
            setFuelChargeMapping(e.target.value);
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Typography>Accessorial charge mapping</Typography>
        <TextField
          value={accessorialChargeMapping}
          size="small"
          disabled={!canWriteSettingsQuickbooks}
          onChange={(e) => {
            setAccessorialChargeMapping(e.target.value);
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Typography>Pickup charge mapping</Typography>
        <TextField
          value={pickupChargeMapping}
          size="small"
          disabled={!canWriteSettingsQuickbooks}
          onChange={(e) => {
            setPickupChargeMapping(e.target.value);
          }}
        />
      </Box>
      {accessorialData?.accessorials
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((accessorial) => (
          <AccessorialMapping
            key={accessorial.uuid}
            uuid={accessorial.uuid}
            mapping={accessorialsToUpdate[accessorial.uuid] ?? ''}
            name={accessorial.name}
            setAccessorialsToUpdate={setAccessorialsToUpdate}
            disabled={!canWriteSettingsQuickbooks}
          />
        ))}
    </Box>
  );
};

export default QuickbooksChargeMappings;
