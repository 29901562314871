import styled, { type CSSObject } from '@emotion/styled';
import {
  type DateRange,
  DesktopDateRangePicker,
  type DesktopDateRangePickerProps,
  LocalizationProvider,
  SingleInputDateRangeField,
} from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { type Dayjs } from 'dayjs';

type StyledDesktopDateRangePickerProps = {
  readonly inputBaseStyles?: CSSObject;
} & DesktopDateRangePickerProps<Dayjs>;

const StyledDesktopDateRangePicker = styled(
  DesktopDateRangePicker<Dayjs>,
)<StyledDesktopDateRangePickerProps>((props) => ({
  ...(props.inputBaseStyles && {
    '& .MuiInputBase-root': props.inputBaseStyles,
  }),
}));

type PalletDateRangePickerProps = {
  readonly value: DateRange<Dayjs>;
  readonly onChange: (value: DateRange<Dayjs>) => void;
  readonly placeholder?: string;
} & StyledDesktopDateRangePickerProps;

/**
 * A date range picker component that is styled to match the pallet design system.
 * @param value - The date range currently selected. The value is represented in the form [startDate, endDate].
 * @param onChange - The callback function that is called when the date range picker value changes.
 */
const PalletDateRangePicker = ({
  value,
  onChange,
  placeholder = 'Select values...',
  inputBaseStyles,
}: PalletDateRangePickerProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledDesktopDateRangePicker
        value={value}
        inputBaseStyles={inputBaseStyles}
        slots={{ field: SingleInputDateRangeField }}
        slotProps={{
          textField: {
            size: 'small',
            placeholder,
          },
        }}
        onChange={onChange}
      />
    </LocalizationProvider>
  );
};

export default PalletDateRangePicker;
