import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import { Box, TableCell, TableRow } from '@mui/material';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { isNilOrEmptyString } from '../../../../common/utils/utils';

type CustomerListRowProps = {
  readonly openedCustomerUuid: string | undefined;
  readonly uuid: string;
  readonly contactName: string;
  readonly contactIdentifier: string | null | undefined;
  readonly hasCreditHold: boolean;
};

const CustomerListRow = ({
  openedCustomerUuid,
  uuid,
  contactName,
  contactIdentifier,
  hasCreditHold,
}: CustomerListRowProps) => {
  const [, setSearchParams] = useSearchParams();

  const handleRowClick = () => {
    setSearchParams((sp) => {
      const newParams = new URLSearchParams(sp);
      if (uuid === openedCustomerUuid) {
        newParams.delete('customerUuid');
      } else {
        newParams.set('customerUuid', uuid);
      }
      return newParams;
    });
  };

  return (
    <TableRow
      hover
      selected={uuid === openedCustomerUuid}
      sx={{
        cursor: 'pointer',
      }}
      onClick={handleRowClick}
    >
      <TableCell>
        {isNilOrEmptyString(contactIdentifier) ? '-' : contactIdentifier}
      </TableCell>
      <TableCell>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {contactName}
          {hasCreditHold && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span
                style={{
                  color: 'gray',
                  paddingRight: '5px',
                }}
              >
                Hold
              </span>
              <CreditCardOffIcon color="error" />
            </Box>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default React.memo(CustomerListRow);
