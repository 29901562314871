import ClearIcon from '@mui/icons-material/Clear';
import { IconButton } from '@mui/material';
import React from 'react';

type ClearTextFieldButtonProps = {
  readonly searchText: string;
  readonly handleClearSearchText: () => void;
};
const ClearTextFieldButton = ({
  searchText,
  handleClearSearchText,
}: ClearTextFieldButtonProps) => (
  <IconButton
    size="small"
    sx={{ visibility: searchText.length > 0 ? 'visible' : 'hidden' }}
    onClick={() => {
      handleClearSearchText();
    }}
  >
    <ClearIcon />
  </IconButton>
);

export default ClearTextFieldButton;
