import { Box, Button, Typography } from '@mui/material';
import type React from 'react';
import {
  type Accept,
  type DropEvent,
  type FileRejection,
  useDropzone,
} from 'react-dropzone';

export const dropzoneStyles = {
  dropzone: {
    textAlign: 'center' as const,
    padding: '20px',
    width: '100%',
    margin: 'auto',
    display: 'flex',
    height: '300px',
    alignItems: 'center',
    justifyContent: 'center',
    border: '3px blue dashed',
    borderColor: '#253052',
  },
  dropzoneContent: {
    color: 'black',
  },
  outerDropzone: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
} as Record<string, React.CSSProperties>;

export type DropZoneProps = {
  readonly onDrop?: (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => void;
  readonly errorMessage: string | undefined;
  readonly maxFiles?: number;
  readonly accept?: Accept;
  readonly hideUpload?: boolean;
};

export default function Dropzone({
  onDrop,
  maxFiles,
  accept,
  hideUpload,
}: DropZoneProps) {
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
    accept: accept ?? {
      'application/pdf': [],
    },
    maxFiles,
  });

  return (
    <Box style={dropzoneStyles.outerDropzone}>
      <div
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...getRootProps({ className: 'dropzone' })}
        style={dropzoneStyles.dropzone}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <input className="input-zone" {...getInputProps()} />
        <div className="text-center">
          {isDragActive ? (
            <p
              className="dropzone-content"
              style={dropzoneStyles.dropzoneContent}
            >
              Release to drop the files here
            </p>
          ) : (
            <p
              className="dropzone-content"
              style={dropzoneStyles.dropzoneContent}
            >
              Drag and drop files here
            </p>
          )}
        </div>
      </div>
      {hideUpload !== true && (
        <>
          <Typography sx={{ marginTop: '2%', marginBottom: '2%' }}>
            Or
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: 20,
            }}
          >
            <Button
              variant="contained"
              className="button"
              color="secondary"
              style={{}}
              onClick={open}
            >
              Choose file
            </Button>
          </div>
        </>
      )}
    </Box>
  );
}
