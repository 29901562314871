import { useParams } from 'react-router-dom';
import { isNotNilOrEmptyString } from 'shared/string';
import CustomerLoadPage from '../domains/customer-portal/components/customer-portal-order/customer-load-page';

export const CustomerPortalLoadPage = () => {
  const { loadUuid } = useParams();
  if (typeof loadUuid === 'string' && isNotNilOrEmptyString(loadUuid)) {
    return <CustomerLoadPage isAuthenticated loadUuid={loadUuid} />;
  }
  return null;
};
