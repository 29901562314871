/* eslint-disable no-param-reassign */
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

type Company = {
  uuid: string;
  name: string;
};
type CustomerPortalStoreState = {
  company: Company | null;
};
type CustomerPortalStoreActions = {
  setCompany: (companyUuid: Company | null) => void;
};

const useCustomerPortalStore = create(
  immer<CustomerPortalStoreState & CustomerPortalStoreActions>((set) => ({
    company: null,
    setCompany: (company) => {
      set((state) => {
        state.company = company;
      });
    },
  })),
);

export default useCustomerPortalStore;
