import { Box, Checkbox, Tooltip, Typography } from '@mui/material';
import type { ICellRendererParams } from 'ag-grid-community';
import { isNil } from 'lodash';
import type { StopsTableElement } from '../../dispatch/types/stops';
import { isRecurringRunHeaderFragment } from '../../dispatch/utils';

// Note this is not a React component.
export const fullWidthRecurringRunRenderer = ({
  node,
  data,
}: ICellRendererParams<StopsTableElement>) => {
  if (!isRecurringRunHeaderFragment(data)) {
    return null;
  }

  const isSelected = node.isSelected() ?? false;

  return (
    <Box
      sx={{
        height: '100%',
        backgroundColor: '#F5F5F5',
        paddingLeft: '3px',
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
      }}
    >
      <Checkbox
        checked={isSelected}
        color="info"
        sx={{
          color: 'var(--ag-checkbox-unchecked-color)',
        }}
        onChange={() => {
          // Distinguish between user actions and programmatically selecting so we can update child stop rows.
          node.setSelected(!isSelected, false, false, 'checkboxSelected');
        }}
      />
      <Tooltip title="Recurring run">
        <Typography
          component="span"
          sx={{
            fontWeight: 500,
            fontSize: '13px',
            // Ag Grid's z-index property on some element blocks this element from being visible when the row is selected unless it's positioned.
            // Probably has to do with https://www.ag-grid.com/react-data-grid/row-selection/#checkbox-selection
            position: 'relative',
          }}
        >
          {data.name}
          {!isNil(data.driver) && (
            <>
              {' '}
              / {data.driver.firstName} {data.driver.lastName}
            </>
          )}
          {!isNil(data.vehicleType) && <> / {data.vehicleType.name}</>}
        </Typography>
      </Tooltip>
    </Box>
  );
};
