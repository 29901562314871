import type { ComponentType, FunctionComponent, ReactNode } from 'react';
import { Outlet, type LoaderFunction, useRouteError } from 'react-router-dom';
import type { SetRequired } from 'type-fest';
import { AuthenticationGuard } from './common/components/authentication-guard';
import AppointmentDecisionCompletePage from './domains/appointments/components/appointment-decision-complete-view';
import DispatchAppointmentsPage from './domains/appointments/components/appointments-view';
import AddContactPage from './domains/contacts/components/add-contact-page';
import ContactsPage from './domains/contacts/components/contacts-page';
import CreateCustomerPortalAccountPage from './domains/customer-portal/components/create-customer-portal-account';
import CustomerLoadsPage from './domains/customer-portal/components/customer-loads-view';
import { CustomerPortalView } from './domains/customer-portal/components/customer-portal-view';
import CustomerPortalInventoryPage from './domains/customer-portal/components/inventory/customer-portal-inventory';
import DailyControlCenterPage from './domains/daily-control-center';
import DispatchPage from './domains/dispatch';
import DriverSettlementPage from './domains/driver-settlement/driver-settlement-page';
import ConfigureEmailIngestionToolPage from './domains/email-ingestion-tool/configure/configure-email-ingestion-tool-page';
import ReviewIngestedEmailsPage from './domains/email-ingestion-tool/review/review-ingested-emails-page';
import EndOfDayPage from './domains/end-of-day/end-of-day-page';
import InventoryPage from './domains/inventory/components/inventory-page';
import InvoicesPage from './domains/invoices/invoices-page';
import LineHaulPage from './domains/line-haul';
import ManagementPage from './domains/management';
import CreateLineHaulLanePage from './domains/management/components/line-haul/create-line-haul-lane-page';
import IngestedEmailsPage from './domains/orders/components/email-ingestion/ingested-emails-page';
import InboundMessagesPage from './domains/orders/components/inbound-messages/inbound-messages-page';
import { NotFound } from './domains/orders/components/not-found';
import ViewAllScannedOrdersPage from './domains/orders/components/view-all-scanned-orders-page';
import ViewQuotesPage from './domains/quotes/view-quotes';
import ReportsPage from './domains/reports/reports-page';
import { ReservationSystemView } from './domains/reservation-system/components/reservation-system-view';
import SettingsPage from './domains/settings/index';
import CreateStorageOrderForm from './domains/storage-orders/components/create-storage-order/create-storage-order-form';
import StorageOrdersPage from './domains/storage-orders/components/storage-orders-view';
import TariffZonesPage from './domains/tariff-zones/tariff-zones-page';
import WarehouseReportsPage from './domains/warehouse-reports/components/warehouse-reports-view';
import WarehouseSettingsPage from './domains/warehouse-settings/components/warehouse-settings-view';
import DashboardLayout from './layouts/dashboard/dashboard-layout';
import { DashboardPage } from './layouts/dashboard/types';
import ChooseAppointmentPage from './pages/choose-appt';
import CommitPage from './pages/commit';
import ConfirmAppointmentPage from './pages/confirm-appt';
import {
  ConsigneeShipmentsPage,
  ShipmentProfileLoadPage,
} from './pages/consignee';
import {
  AddContactAccessorialPage,
  ContactAccessorialPage,
  ContactPage,
} from './pages/contacts';
import { CustomerPortalLoadPage } from './pages/customer-portal';
import EnvPage from './pages/env';
import Home from './pages/home';
import {
  AccessorialPage,
  AddAccessorialPage,
  AddDriverPage,
  AddEquipmentPage,
  AddPackageSpecPage,
  AddRecurringOrderTemplatePage,
  AddVehicleTypePage,
  DriverPage,
  EquipmentPage,
  LineHaulLanePage,
  PackageSpecPage,
  RecurringOrderTemplatePage,
  TerminalPage,
  VehicleTypePage,
} from './pages/management';
import { AddOrderPage, ApproveQuotePage, OrderPage } from './pages/order-entry';
import {
  AddQuotePage,
  ApproveQuotePage as OrdersApproveQuotePage,
  EmailProcessingStatusPage,
  OrdersPage,
  QuotePage,
} from './pages/orders';
import { QuickbooksErrorPage, QuickbooksSuccessPage } from './pages/quickbooks';
import { SignDigitalDocumentPage } from './pages/sign-digital-document';
import { TrackingWidgetPage } from './pages/tracking-widget';
import {
  AddWarehousePage,
  ItemPage as WarehouseItemPage,
  StorageOrderPage,
  WarehousePage,
  WarehouseRedirectPage,
} from './pages/warehouse';

// See https://github.com/remix-run/react-router/discussions/10166
// All top-level routes should have this set as the error boundary.
const ErrorBoundary: FunctionComponent = () => {
  throw useRouteError();
};

// Copied from react-router-dom with some simplifications and changes to require consistency.
type RouteObject = IndexRouteObject | NonIndexRouteObject;

// See https://reactrouter.com/how-to/error-boundary
type RootRouteObject =
  | SetRequired<IndexRouteObject, 'ErrorBoundary'>
  | SetRequired<NonIndexRouteObject, 'ErrorBoundary'>;

type IndexRouteObject = {
  index: true;
  caseSensitive?: boolean;
  element: ReactNode;
  ErrorBoundary?: ComponentType;
  loader?: LoaderFunction;
};

type NonIndexRouteObject = {
  path?: string;
  caseSensitive?: boolean;
  children?: RouteObject[];
  element?: ReactNode;
  ErrorBoundary?: ComponentType;
  loader?: LoaderFunction;
};

export const routes: RootRouteObject[] = [
  {
    index: true,
    element: <Home />,
    ErrorBoundary,
  },
  {
    path: 'accounting',
    element: (
      <AuthenticationGuard>
        <DashboardLayout page={DashboardPage.ACCOUNTING}>
          <Outlet />
        </DashboardLayout>
      </AuthenticationGuard>
    ),
    children: [
      {
        index: true,
        element: <InvoicesPage />,
      },
      {
        path: 'driver-settlement',
        element: <DriverSettlementPage />,
      },
    ],
    ErrorBoundary,
  },
  {
    path: 'choose-appt/:stopUuid',
    element: <ChooseAppointmentPage />,
    ErrorBoundary,
  },
  {
    path: 'commit',
    element: <CommitPage />,
    ErrorBoundary,
  },
  {
    path: 'confirm-appt',
    children: [
      {
        path: ':appointmentUuid',
        element: <ConfirmAppointmentPage />,
      },
      {
        path: 'decision-complete',
        element: <AppointmentDecisionCompletePage />,
      },
    ],
    ErrorBoundary,
  },
  {
    path: 'consignee',
    children: [
      {
        path: 'shipment-profile/:loadUuid',
        element: <ShipmentProfileLoadPage />,
      },
      {
        path: 'shipments',
        children: [
          {
            path: ':companyUuid',
            element: <ConsigneeShipmentsPage />,
          },
        ],
      },
    ],
    ErrorBoundary,
  },
  {
    path: 'contacts',
    element: (
      <AuthenticationGuard>
        <DashboardLayout page={DashboardPage.CONTACTS}>
          <Outlet />
        </DashboardLayout>
      </AuthenticationGuard>
    ),
    children: [
      {
        index: true,
        element: <ContactsPage />,
      },
      {
        path: ':contactUuid',
        element: <ContactPage />,
      },
      {
        path: 'accessorials',
        children: [
          {
            path: 'add',
            element: <AddContactAccessorialPage />,
          },
          {
            path: ':accessorialUuid',
            element: <ContactAccessorialPage />,
          },
        ],
      },
      {
        path: 'add',
        element: <AddContactPage />,
      },
    ],
    ErrorBoundary,
  },
  {
    // The customer portal creation page is maintained separately as the authentication guard does not apply to it.
    // Users should be able to create a customer portal account without being logged in.
    path: 'customer-portal/create',
    element: <CreateCustomerPortalAccountPage />,
    ErrorBoundary,
  },
  {
    path: 'customer-portal',
    element: (
      <AuthenticationGuard>
        <Outlet />
      </AuthenticationGuard>
    ),
    children: [
      {
        index: true,
        element: <CustomerPortalView />,
      },
      {
        path: 'inventory',
        element: <CustomerPortalInventoryPage />,
      },
      {
        path: 'orders',
        children: [
          {
            index: true,
            element: <CustomerLoadsPage />,
          },
          {
            path: ':loadUuid',
            element: <CustomerPortalLoadPage />,
          },
        ],
      },
    ],
    ErrorBoundary,
  },
  {
    path: 'dispatch',
    element: (
      <AuthenticationGuard>
        <DashboardLayout page={DashboardPage.TRANSIT}>
          <Outlet />
        </DashboardLayout>
      </AuthenticationGuard>
    ),
    children: [
      {
        index: true,
        element: <DispatchPage />,
      },
      {
        path: 'appointments',
        element: <DispatchAppointmentsPage />,
      },
      {
        path: 'daily-control-center',
        element: <DailyControlCenterPage />,
      },
      {
        path: 'end-of-day',
        element: <EndOfDayPage />,
      },
      {
        path: 'line-haul',
        element: <LineHaulPage />,
      },
      {
        path: 'reservation-system',
        element: <ReservationSystemView />,
      },
      {
        path: 'routes',
        element: <DispatchPage />,
      },
    ],
    ErrorBoundary,
  },
  {
    path: 'email-ingestion',
    element: (
      <AuthenticationGuard>
        <DashboardLayout page={DashboardPage.EMAIL_INGESTION}>
          <Outlet />
        </DashboardLayout>
      </AuthenticationGuard>
    ),
    children: [
      {
        index: true,
        element: <ReviewIngestedEmailsPage />,
      },
      {
        path: 'configure',
        element: <ConfigureEmailIngestionToolPage />,
      },
      {
        path: 'review',
        element: <ReviewIngestedEmailsPage />,
      },
    ],
    ErrorBoundary,
  },
  {
    path: 'env',
    element: <EnvPage />,
    ErrorBoundary,
  },
  {
    path: 'health',
    element: null,
    ErrorBoundary,
  },
  {
    path: 'management',
    element: (
      <AuthenticationGuard>
        <DashboardLayout page={DashboardPage.COMPANYSETUP}>
          <Outlet />
        </DashboardLayout>
      </AuthenticationGuard>
    ),
    children: [
      {
        index: true,
        element: <ManagementPage />,
      },
      {
        path: 'accessorials',
        children: [
          {
            path: 'add',
            element: <AddAccessorialPage />,
          },
          {
            path: ':accessorialUuid',
            element: <AccessorialPage />,
          },
        ],
      },
      {
        path: 'drivers',
        children: [
          {
            path: 'add',
            element: <AddDriverPage />,
          },
          {
            path: ':driverUuid',
            element: <DriverPage />,
          },
        ],
      },
      {
        path: 'equipments',
        children: [
          {
            path: 'add',
            element: <AddEquipmentPage />,
          },
          {
            path: ':equipmentUuid',
            element: <EquipmentPage />,
          },
        ],
      },
      {
        path: 'line-haul',
        children: [
          {
            path: 'create',
            element: <CreateLineHaulLanePage />,
          },
          {
            path: ':laneUuid',
            element: <LineHaulLanePage />,
          },
        ],
      },
      {
        path: 'package-types',
        children: [
          {
            path: 'add',
            element: <PackageSpecPage />,
          },
          {
            path: ':packageTypeId',
            element: <AddPackageSpecPage />,
          },
        ],
      },
      {
        path: 'recurring-orders',
        children: [
          {
            path: 'add',
            element: <AddRecurringOrderTemplatePage />,
          },
          {
            path: ':templateUuid',
            element: <RecurringOrderTemplatePage />,
          },
        ],
      },
      {
        path: 'tariff-zones',
        children: [
          {
            path: 'edit',
            element: <TariffZonesPage />,
          },
        ],
      },
      {
        path: 'terminals',
        children: [
          {
            path: ':terminalUuid',
            element: <TerminalPage />,
          },
        ],
      },
      {
        path: 'vehicle-types',
        children: [
          {
            path: 'add',
            element: <AddVehicleTypePage />,
          },
          {
            path: ':vehicleTypeUuid',
            element: <VehicleTypePage />,
          },
        ],
      },
    ],
    ErrorBoundary,
  },
  {
    path: 'order-entry',
    element: (
      <AuthenticationGuard>
        <DashboardLayout page={DashboardPage.ORDERS}>
          <Outlet />
        </DashboardLayout>
      </AuthenticationGuard>
    ),
    children: [
      {
        path: 'add',
        element: <AddOrderPage />,
      },
      {
        path: 'quotes',
        children: [
          {
            index: true,
            element: <ViewQuotesPage />,
          },
          {
            path: 'add',
            element: <AddQuotePage />,
          },
          {
            path: ':quoteUuid',
            element: <QuotePage />,
            children: [
              {
                path: 'approve',
                element: <ApproveQuotePage />,
              },
            ],
          },
        ],
      },
      {
        path: ':orderUuid',
        element: <OrderPage />,
      },
    ],
    ErrorBoundary,
  },
  {
    path: 'orders',
    element: (
      <AuthenticationGuard>
        <DashboardLayout page={DashboardPage.ORDERS}>
          <Outlet />
        </DashboardLayout>
      </AuthenticationGuard>
    ),
    children: [
      {
        index: true,
        element: <OrdersPage />,
      },
      {
        path: 'inbound-messages',
        children: [
          {
            index: true,
            element: <InboundMessagesPage />,
          },
          {
            path: 'email',
            children: [
              {
                index: true,
                element: <IngestedEmailsPage />,
              },
              {
                path: 'processing-status/:emailUuid',
                element: <EmailProcessingStatusPage />,
              },
            ],
          },
        ],
      },
      {
        path: 'quotes',
        children: [
          {
            index: true,
            element: <ViewQuotesPage />,
          },
          {
            path: 'add',
            element: <AddQuotePage />,
          },
          {
            path: ':quoteUuid',
            children: [
              {
                index: true,
                element: <QuotePage />,
              },
              {
                path: 'approve',
                element: <OrdersApproveQuotePage />,
              },
            ],
          },
        ],
      },
      {
        path: 'scans',
        element: <ViewAllScannedOrdersPage />,
      },
    ],
    ErrorBoundary,
  },
  {
    path: 'quickbooks',
    children: [
      {
        path: 'error',
        element: <QuickbooksErrorPage />,
      },
      {
        path: 'success',
        element: <QuickbooksSuccessPage />,
      },
    ],
    ErrorBoundary,
  },
  {
    path: 'reports',
    element: (
      <AuthenticationGuard>
        <DashboardLayout page={DashboardPage.REPORTS}>
          <ReportsPage />
        </DashboardLayout>
      </AuthenticationGuard>
    ),
    ErrorBoundary,
  },
  {
    path: 'settings',
    element: (
      <AuthenticationGuard>
        <DashboardLayout page={DashboardPage.SETTINGS}>
          <SettingsPage />
        </DashboardLayout>
      </AuthenticationGuard>
    ),
    ErrorBoundary,
  },
  {
    path: 'sign-digital-document',
    element: <SignDigitalDocumentPage />,
    ErrorBoundary,
  },
  {
    path: 'tracking-widget/:companyUuid',
    element: <TrackingWidgetPage />,
    ErrorBoundary,
  },
  {
    path: 'warehouse',
    element: (
      <AuthenticationGuard>
        <DashboardLayout page={DashboardPage.WAREHOUSE}>
          <Outlet />
        </DashboardLayout>
      </AuthenticationGuard>
    ),
    children: [
      {
        index: true,
        element: <WarehouseRedirectPage />,
      },
      {
        path: 'inventory',
        element: <InventoryPage />,
      },
      {
        path: 'reports',
        element: <WarehouseReportsPage />,
      },
      {
        path: 'settings',
        children: [
          {
            index: true,
            element: <WarehouseSettingsPage />,
          },
          {
            path: 'items/:itemUuid',
            element: <WarehouseItemPage />,
          },
          {
            path: 'warehouses',
            children: [
              {
                path: 'add',
                element: <AddWarehousePage />,
              },
              {
                path: ':warehouseUuid',
                element: <WarehousePage />,
              },
            ],
          },
        ],
      },
      {
        path: 'storage-orders',
        children: [
          {
            index: true,
            element: <StorageOrdersPage />,
          },
          {
            path: 'create',
            element: <CreateStorageOrderForm />,
          },
          {
            path: ':orderUuid',
            element: <StorageOrderPage />,
          },
        ],
      },
    ],
    ErrorBoundary,
  },
  {
    path: '*',
    element: <NotFound />,
    ErrorBoundary,
  },
];
