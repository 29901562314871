import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { type Dayjs } from 'dayjs';
import { isEmpty, isNil } from 'lodash';
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';
import TabPanel from '../../../../common/components/tab-panel/tab-panel';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import useMe from '../../../../common/react-hooks/use-me';
import useTerminals from '../../../../common/react-hooks/use-terminals';
import useWarehouses from '../../../../common/react-hooks/use-warehouses';
import {
  type DeadlineType,
  type GetOrderTableFieldValuesInput,
  OrderAction,
  Segment,
  type StopAction,
  ShipmentType,
  useBulkEditOrdersMutation,
  useOrderPodReportsLazyQuery,
  useOrdersForCoverSheetsLazyQuery,
  useOrdersForInventoryLabelsLazyQuery,
  StopType,
} from '../../../../generated/graphql';
import { DocumentAttachments } from '../../../invoice-old/components/download-documents';
import { DownloadAction, StopTypeGroup } from '../enums/bulk-actions';
import {
  INBOUND_STOP_IDX,
  OUTBOUND_STOP_IDX,
} from '../order-form/components/constants';
import { isServiceDateFieldDisabled } from '../order-form/forms/service-date-utils';
import { useGetOrderFormEditAccess } from '../order-form/forms/use-order-form-edit-access';
import {
  downloadMultipleCoverSheets,
  downloadMultipleLotLabels,
  downloadMultipleOutboundLabels,
  downloadMultiplePodReport,
  getFilteredOrdersForOrderAction,
  getFilteredOrdersForStopAction,
  getShipmentForStopType,
} from '../utils';
import EditStops from './bulk-actions-edit-stops';
import BulkActionsPreview from './bulk-actions-preview';
import { useOrdersForBulkEdit } from './useOrdersForBulkEdit';

type BulkActionsModalProps = {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly onConfirm: (edited: boolean, downloaded: boolean) => void;
  readonly getOrderTableFieldValuesInput:
    | GetOrderTableFieldValuesInput
    | undefined;
  readonly selectedOrderUuids: string[];
  readonly searchText: string;
};

const BulkActionsModal = ({
  isOpen,
  setIsOpen,
  onConfirm,
  getOrderTableFieldValuesInput,
  selectedOrderUuids,
  searchText,
}: BulkActionsModalProps) => {
  const { getOrderFormEditAccess } = useGetOrderFormEditAccess();
  const { warehouses } = useWarehouses();
  const { segment, companyConfiguration } = useMe();
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [getOrdersForCoverSheets] = useOrdersForCoverSheetsLazyQuery();
  const [getShipmentsForPodReports] = useOrderPodReportsLazyQuery();
  const [getOrdersForInventoryLabels] = useOrdersForInventoryLabelsLazyQuery();
  const [bulkEditOrders, { loading: loadingBulkEditMutation }] =
    useBulkEditOrdersMutation();

  const [warehouseUuid, setWarehouseUuid] = useState<string>();
  const [mawb, setMawb] = useState<string>();
  const [secondaryRefNumber, setSecondaryRefNumber] = useState<string>();
  const [stopTypeGroup, setStopTypeGroup] = useState<StopTypeGroup>();
  const [stopTypes, setStopTypes] = useState<StopType[]>();
  const [deadlineType, setDeadlineType] = useState<DeadlineType>();
  const [deadlineDate, setDeadlineDate] = useState<Dayjs>();
  const [serviceDate, setServiceDate] = useState<Dayjs>();
  const [podName, setPodName] = useState<string>();
  const [completedAt, setCompletedAt] = useState<Date>();
  const [downloadActions, setDownloadActions] = useState<DownloadAction[]>([]);
  const [showCharges, setShowCharges] = useState(false);
  const [includeAttachedDocuments, setincludeAttachedDocuments] =
    useState(false);
  const [combinedCoverSheets, setCombineCoverSheets] = useState(true);
  const [combinedLabels, setCombinedLabels] = useState(true);
  const [orderAction, setOrderAction] = useState<OrderAction>();
  const [stopAction, setStopAction] = useState<StopAction>();
  const ffAppendPieceIdToLotLabel = useFeatureFlag(
    FeatureFlag.FF_APPEND_PIECE_ID_STRING_TO_LOT_LABEL,
  );

  const { orders, loading: loadingOrderTableFieldValues } =
    useOrdersForBulkEdit({
      isOpen,
      selectedOrderUuids,
      getOrderTableFieldValuesInput,
      searchText,
    });

  useEffect(() => {
    if (stopTypeGroup === StopTypeGroup.PickupRecovery) {
      setStopTypes([StopType.Pickup, StopType.Recovery]);
    } else if (stopTypeGroup === StopTypeGroup.DeliveryTransfer) {
      setStopTypes([StopType.Delivery, StopType.Transfer]);
    } else {
      setStopTypes(undefined);
    }
  }, [stopTypeGroup]);

  const handleClose = () => {
    setTabIndex(0);
    setWarehouseUuid(undefined);
    setMawb(undefined);
    setSecondaryRefNumber(undefined);
    setStopTypeGroup(undefined);
    setDeadlineDate(undefined);
    setDeadlineType(undefined);
    setServiceDate(undefined);
    setPodName(undefined);
    setCompletedAt(undefined);
    setDownloadActions([]);
    setOrderAction(undefined);
    setStopAction(undefined);
    setIsOpen(false);
  };

  const downloadPodReports = async () => {
    const res = await getShipmentsForPodReports({
      variables: {
        getShipmentsInput: {
          uuids: orders.flatMap((order) =>
            order.shipments.map((shipment) => shipment.uuid),
          ),
        },
      },
    });

    const shipments = res.data?.shipments.filter(
      (shipment) => shipment.shipmentType === ShipmentType.Regular,
    );

    if (!isNil(shipments)) {
      let attachments = DocumentAttachments.NoAttachments;
      if (
        downloadActions.includes(
          DownloadAction.DownloadPodReportWithUnsignedPods,
        )
      ) {
        attachments = DocumentAttachments.IncludeUnsignedPoDs;
      } else if (includeAttachedDocuments) {
        attachments = DocumentAttachments.IncludeAll;
      }
      downloadMultiplePodReport(segment, shipments, attachments, showCharges);
    }
  };

  const getStopUuidsForServiceDateUpdate = () =>
    orders
      .filter((order) => {
        const editAccess = getOrderFormEditAccess({
          detailedStatus: order.detailedStatusV2,
        });
        const inboundOrOutboundIndex =
          stopTypeGroup === StopTypeGroup.PickupRecovery
            ? INBOUND_STOP_IDX
            : OUTBOUND_STOP_IDX;
        const shipment = getShipmentForStopType(order, inboundOrOutboundIndex);
        return !isServiceDateFieldDisabled({
          stopStatus: shipment?.legs[0]?.endStop.status,
          editAccess,
        });
      })
      .flatMap((order) =>
        order.shipments
          .filter((shipment) => {
            const stopType = shipment.legs[0]?.endStop.stopType;
            return (
              !isNil(stopType) &&
              !isNil(stopTypes) &&
              stopTypes.includes(stopType)
            );
          })
          .map((shipment) => shipment.legs[0]?.endStop.uuid),
      )
      .filter((uuid): uuid is string => !isNil(uuid));

  const downloadCoverSheets = async () => {
    const responses = await getOrdersForCoverSheets({
      variables: {
        uuids: orders.map((order) => order.uuid),
      },
    });

    const ordersForCoverSheets = responses.data?.ordersByUuids ?? [];

    downloadMultipleCoverSheets(
      segment,
      companyConfiguration,
      ordersForCoverSheets,
      terminalsEnabled,
      companyConfiguration?.logisticsSectionEnabled ?? false,
      combinedCoverSheets,
    );
  };

  const downloadOutboundLabels = async () => {
    const { data } = await getOrdersForInventoryLabels({
      variables: {
        uuids: selectedOrderUuids,
      },
      fetchPolicy: 'cache-first',
    });

    return downloadMultipleOutboundLabels(data?.ordersByUuids, combinedLabels);
  };

  const downloadLotLabels = async () => {
    const { data } = await getOrdersForInventoryLabels({
      variables: {
        uuids: selectedOrderUuids,
      },
      fetchPolicy: 'cache-first',
    });
    return downloadMultipleLotLabels(
      data?.ordersByUuids,
      ffAppendPieceIdToLotLabel,
    );
  };

  const confirm = async () => {
    handleClose();

    const affectedShipments = orders
      .flatMap((order) => order.shipments)
      .filter((shipment) => {
        const stopType = shipment.legs[0]?.endStop.stopType;
        return (
          !isNil(stopTypes) && !isNil(stopType) && stopTypes.includes(stopType)
        );
      });

    const orderUuidsForAction = getFilteredOrdersForOrderAction(
      orders,
      orderAction,
    ).map((order) => order.uuid);

    const stopUuidsForAction =
      getFilteredOrdersForStopAction(orders, stopTypes, stopAction)
        .flatMap((order) =>
          order.shipments
            .filter((shipment) => {
              const stopType = shipment.legs[0]?.endStop.stopType;
              return (
                !isNil(stopType) &&
                !isNil(stopTypes) &&
                stopTypes.includes(stopType)
              );
            })
            .map((shipment) => shipment.legs[0]?.endStop.uuid),
        )
        .filter((uuid) => !isNil(uuid)) ?? [];

    await bulkEditOrders({
      variables: {
        bulkEditOrdersInput: {
          orderUuids: orders.map((order) => order.uuid),
          shipmentUuids: affectedShipments.map((shipment) => shipment.uuid),
          orderUuidsForAction,
          stopUuidsForAction,
          stopUuidsForServiceDate: getStopUuidsForServiceDateUpdate(),
          orderAction,
          stopAction,
          warehouseUuid,
          masterAirwayBillOfLadingNumber: mawb,
          secondaryRefNumber,
          deadlineType,
          deadlineDate: deadlineDate?.toDate(),
          serviceDate: serviceDate?.toDate(),
          podName,
          completedAt,
        },
      },
    });

    if (!isEmpty(downloadActions)) {
      if (
        downloadActions.includes(
          DownloadAction.DownloadPodReportWithUnsignedPods,
        ) ||
        downloadActions.includes(DownloadAction.DownloadPodReport)
      ) {
        downloadPodReports();
      }
      if (downloadActions.includes(DownloadAction.DownloadCoverSheet)) {
        downloadCoverSheets();
      }
      if (downloadActions.includes(DownloadAction.DownloadOutboundLabels)) {
        downloadOutboundLabels();
      }
      if (downloadActions.includes(DownloadAction.DownloadLotLabels)) {
        downloadLotLabels();
      }
    }

    onConfirm(true, !isEmpty(downloadActions));
  };

  const loading = loadingOrderTableFieldValues;

  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleClose}
    >
      <Box
        sx={{
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          height: '85vh',
          width: '90vw',
          boxShadow: 24,
          p: 3,
          overflow: 'scroll',
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            {tabIndex === 0 ? (
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  setTabIndex(0);
                }}
              >
                Back
              </Button>
            )}
          </Grid>
          <Grid item xs={6}>
            <Stepper activeStep={tabIndex}>
              <Step key={0}>
                <StepLabel>Edit</StepLabel>
              </Step>
              <Step key={1}>
                <StepLabel>Confirm</StepLabel>
              </Step>
            </Stepper>
          </Grid>
          <Grid item xs={3}>
            {tabIndex === 0 ? (
              <Button
                variant="contained"
                sx={{ float: 'right' }}
                disabled={loading}
                onClick={() => {
                  setTabIndex(1);
                }}
              >
                Next
              </Button>
            ) : (
              <Button
                variant="contained"
                sx={{ float: 'right' }}
                color="info"
                startIcon={
                  loadingBulkEditMutation && <CircularProgress size={20} />
                }
                onClick={confirm}
              >
                Confirm
              </Button>
            )}
          </Grid>
          <Grid item xs={12}>
            <TabPanel selectedValue={tabIndex} panelValue={0}>
              <Grid container spacing={5}>
                <Grid item xs>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        Edit Orders ({loading ? '-' : orders.length})
                      </Typography>
                    </Grid>
                    {loading ? (
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        sx={{ margin: 2 }}
                        width="100%"
                        height="500px"
                      />
                    ) : (
                      <>
                        <Grid item xs={12}>
                          <FormControl sx={{ width: '80%' }}>
                            <InputLabel id="download-multiple-checkbox-label">
                              Download
                            </InputLabel>
                            <Select
                              multiple
                              labelId="download-multiple-checkbox-label"
                              id="download-multiple-checkbox"
                              value={downloadActions}
                              label="Download"
                              renderValue={(selected) =>
                                selected.map((x) => x).join(', ')
                              }
                              onChange={(e) => {
                                setDownloadActions(
                                  e.target.value as DownloadAction[],
                                );
                              }}
                            >
                              {Object.values(DownloadAction).map(
                                (downloadAction) => (
                                  <MenuItem
                                    key={downloadAction}
                                    value={downloadAction}
                                  >
                                    <Checkbox
                                      checked={downloadActions.includes(
                                        downloadAction,
                                      )}
                                    />
                                    <ListItemText primary={downloadAction} />
                                  </MenuItem>
                                ),
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl sx={{ width: '80%' }}>
                            <InputLabel id="warehouse-select-label">
                              Select Warehouse
                            </InputLabel>
                            <Select
                              labelId="warehouse-select-label"
                              id="warehouse-select"
                              value={warehouseUuid ?? ''}
                              label="Select Warehouse"
                              onChange={(e) => {
                                setWarehouseUuid(
                                  isEmpty(e.target.value)
                                    ? undefined
                                    : e.target.value,
                                );
                              }}
                            >
                              <MenuItem value="">None</MenuItem>
                              {warehouses?.map((warehouse) => (
                                <MenuItem
                                  key={warehouse.uuid}
                                  value={warehouse.uuid}
                                >
                                  {warehouse.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        {segment === Segment.Cartage && (
                          <Grid item xs={12}>
                            <FormControl sx={{ width: '80%' }}>
                              <TextField
                                label="Master Airway Bill Number"
                                value={mawb ?? ''}
                                onChange={(event) => {
                                  setMawb(
                                    isEmpty(event.target.value)
                                      ? undefined
                                      : event.target.value,
                                  );
                                }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <FormControl sx={{ width: '80%' }}>
                            <TextField
                              label={
                                segment === Segment.Cartage
                                  ? 'Reference Number'
                                  : 'Secondary Reference Number'
                              }
                              value={secondaryRefNumber ?? ''}
                              onChange={(event) => {
                                setSecondaryRefNumber(
                                  isEmpty(event.target.value)
                                    ? undefined
                                    : event.target.value,
                                );
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl>
                            <FormLabel id="order-radio-buttons-group-label">
                              <Typography>Actions</Typography>
                            </FormLabel>
                            <RadioGroup
                              aria-labelledby="order-radio-buttons-group-label"
                              name="radio-buttons-group"
                              value={orderAction ?? ''}
                              onChange={(e) => {
                                setOrderAction(e.target.value as OrderAction);
                              }}
                            >
                              {Object.values(OrderAction).map((action) => (
                                <FormControlLabel
                                  key={action}
                                  value={action}
                                  control={<Radio />}
                                  label={sentenceCase(action)}
                                />
                              ))}
                            </RadioGroup>

                            {!isNil(orderAction) && (
                              <Button
                                variant="contained"
                                color="error"
                                size="small"
                                onClick={() => {
                                  setOrderAction(undefined);
                                }}
                              >
                                Clear Selection
                              </Button>
                            )}
                          </FormControl>
                        </Grid>
                      </>
                    )}
                    {(downloadActions.includes(
                      DownloadAction.DownloadPodReport,
                    ) ||
                      downloadActions.includes(
                        DownloadAction.DownloadPodReportWithUnsignedPods,
                      )) && (
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                          }}
                        >
                          <FormLabel>
                            <Typography>Report Formatting</Typography>
                          </FormLabel>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <Checkbox
                              checked={showCharges}
                              onChange={(e) => {
                                setShowCharges(e.target.checked);
                              }}
                            />
                            <Typography>Show charges on PoD report</Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <Checkbox
                              checked={includeAttachedDocuments}
                              onChange={(e) => {
                                setincludeAttachedDocuments(e.target.checked);
                              }}
                            />
                            <Typography>Include all attachments</Typography>
                          </Box>
                        </Box>
                      </Grid>
                    )}
                    {downloadActions.includes(
                      DownloadAction.DownloadCoverSheet,
                    ) && (
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                          }}
                        >
                          <FormLabel>
                            <Typography>Cover Sheet Formatting</Typography>
                          </FormLabel>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <Checkbox
                              checked={combinedCoverSheets}
                              onChange={(e) => {
                                setCombineCoverSheets(e.target.checked);
                              }}
                            />
                            <Typography>
                              Combine cover sheets in one PDF
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    )}
                    {downloadActions.includes(
                      DownloadAction.DownloadOutboundLabels,
                    ) && (
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                          }}
                        >
                          <FormLabel>
                            <Typography>Outbound Label Formatting</Typography>
                          </FormLabel>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <Checkbox
                              checked={combinedLabels}
                              onChange={(e) => {
                                setCombinedLabels(e.target.checked);
                              }}
                            />
                            <Typography>
                              Combine outbound labels in one PDF
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Divider
                  flexItem
                  orientation="vertical"
                  sx={{ marginTop: 4 }}
                />
                <EditStops
                  stopTypeGroup={stopTypeGroup}
                  setStopTypeGroup={setStopTypeGroup}
                  deadlineType={deadlineType}
                  setDeadlineType={setDeadlineType}
                  deadlineDate={deadlineDate}
                  setDeadlineDate={setDeadlineDate}
                  serviceDate={serviceDate}
                  setServiceDate={setServiceDate}
                  podName={podName}
                  setPodName={setPodName}
                  completedAt={completedAt}
                  setCompletedAt={setCompletedAt}
                  orders={orders}
                  loading={loading}
                  stopAction={stopAction}
                  setStopAction={setStopAction}
                />
              </Grid>
            </TabPanel>
            <TabPanel selectedValue={tabIndex} panelValue={1}>
              <BulkActionsPreview
                orders={orders}
                actionData={{
                  warehouseUuid,
                  mawb,
                  secondaryReferenceNumber: secondaryRefNumber,
                  stopTypeGroup,
                  stopTypes,
                  deadlineType,
                  deadlineDate,
                  orderAction,
                  stopAction,
                  downloadActions,
                  serviceDate,
                }}
              />
            </TabPanel>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default BulkActionsModal;
