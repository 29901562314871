import { isNil } from 'lodash';
import { type ReportGroupConfigurationDriver } from '../types/report-types';

export const REPORT_Y_AXIS_LIMIT = 40;

export const getReportLimitMessage = ({
  reportType,
}: {
  reportType: string;
}) => {
  return `You can only view up to ${REPORT_Y_AXIS_LIMIT} ${reportType} in a graph. You can still view all ${reportType} in the table.`;
};

export const getDriverNameLabel = (
  driver: ReportGroupConfigurationDriver | null | undefined,
) => {
  if (isNil(driver)) {
    return '';
  }
  return `${driver.firstName} ${driver.lastName}`;
};
