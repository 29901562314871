import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Fade,
  FormControl,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import currency from 'currency.js';
import { isEmpty, isNil, unionBy, uniq } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  Controller,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { filterNotNil } from 'shared/array';
import { CUSTOM_CHARGE_TOTAL_TEST_ID } from '../../../../../../../../../constants';
import { getStopCustomChargeTestIds } from '../../../../../../../../../utils';
import { isNilOrEmptyString } from '../../../../../../../../common/utils/utils';
import {
  type AccessorialsForCustomChargeRowQuery,
  CustomChargeBillingMethod,
  ShipmentType,
  SpecialAccessorialType,
  useAccessorialQuery,
  useTariffLazyQuery,
} from '../../../../../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { PalletTooltip } from '../../../../../../../../pallet-ui/tooltip/pallet-tooltip';
import { getAccessorialTypeFromTypename } from '../../../../../../../management/components/accessorials/common';
import { useOrderFormEditAccess } from '../../../../contexts/order-form-edit-access-context';
import {
  type CustomChargeValues,
  type OrderFormFieldValues,
} from '../../../../forms/types';
import { useShouldRateOrder } from '../../../../hooks/use-should-rate-order';
import {
  isValidStopTypeForSpecialAccessorialCharges,
  normalizedStringEquals,
} from '../../../../utils';
import FormNumberInput from '../../../common/form-number-input';
import { calculateWaitTime } from '../../../overview/stops/utils';
import AuthoCodeComponent, {
  MT_IF_HAS_AUTHO_CODE_STYLES,
} from '../autho-code-component';
import DescriptionComponent, {
  MT_IF_HAS_DESCRIPTION_STYLES,
} from '../description-component';
import MilesComponent from '../miles-component';
import OrderFormChargesContext from '../order-form-charges-context';
import CustomChargeRateInput from './custom-charge-rate-input';
import { AD_HOC_CUSTOM_CHARGE_OPTION_LABEL, CUSTOM_CHARGE_KEY } from './labels';

type CustomChargeRowProps = {
  readonly stopIdx: number;
  readonly customChargeIdx: number;
  readonly accessorials: AccessorialsForCustomChargeRowQuery['accessorialsByBillingContact'];
  readonly onRemoved: () => void;
  readonly inBillingReview: boolean;
  readonly newlyAddedCharge: boolean;
  readonly setNewlyAddedCharge: (newlyAddedCharge: boolean) => void;
};

const CustomChargeRow = ({
  stopIdx,
  customChargeIdx,
  accessorials,
  onRemoved,
  inBillingReview,
  newlyAddedCharge,
  setNewlyAddedCharge,
}: CustomChargeRowProps) => {
  const theme = useTheme();
  const {
    isRatingAccessorials,
    isSwitchingAccessorial,
    setIsSwitchingAccessorial,
  } = useContext(OrderFormChargesContext);

  const [getTariffByUuid] = useTariffLazyQuery();

  const { setValue, setError, clearErrors, control, getValues } =
    useFormContext<OrderFormFieldValues>();
  const { errors } = useFormState({ control });

  const { disabledIfFinalizedOrLater, disabledIfInvoicePosted } =
    useOrderFormEditAccess();

  const detailedStatus = useWatch({ control, name: 'detailedStatus' });
  const { shouldRateOrder } = useShouldRateOrder({ detailedStatus });

  const stopType = useWatch({ control, name: `stops.${stopIdx}.stopType` });
  const [isHovering, setIsHovering] = useState(false);

  const tariffUuid = useWatch({
    control,
    name: `stops.${stopIdx}.freightCharge.tariffUuid`,
  });

  const customChargeKey = `stops.${stopIdx}.customCharges.${customChargeIdx}`;
  const zoneBasedAccessorialZone = useWatch({
    control,
    name: `stops.${stopIdx}.customCharges.${customChargeIdx}.zoneUuid`,
  });
  const zoneBasedAccessorialZoneError =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    errors?.stops?.[stopIdx]?.customCharges?.[customChargeIdx]?.zoneUuid;
  const authoCode = useWatch({
    control,
    name: `stops.${stopIdx}.customCharges.${customChargeIdx}.authoCode`,
  });
  const customCharge = useWatch({
    control,
    name: `stops.${stopIdx}.customCharges.${customChargeIdx}`,
  }) as CustomChargeValues | null;

  const postedFuelSurchargeRate = useWatch({
    control,
    name: `stops.${stopIdx}.customCharges.${customChargeIdx}.postedFuelSurchargeRate`,
  });
  const fuelSurchargeRate = useWatch({
    control,
    name: `stops.${stopIdx}.customCharges.${customChargeIdx}.fuelSurchargePercentageRate`,
  });

  const arrivedAt = useWatch({
    control,
    name: `stops.${stopIdx}.arrivedAt`,
  });

  const completedAt = useWatch({
    control,
    name: `stops.${stopIdx}.completedAt`,
  });

  const accessorialUuid = customCharge?.accessorialUuid;

  const terminalUuid = useWatch({
    control,
    name: `stops.${stopIdx}.terminalUuid`,
  });

  // Fetch detailed info about the current accessorial. Rates, ranges, etc.
  const { data: currentAccessorialData, loading } = useAccessorialQuery(
    isNil(accessorialUuid)
      ? {
          skip: true,
        }
      : {
          variables: {
            uuid: accessorialUuid,
          },
        },
  );

  const currentAccessorial = currentAccessorialData?.accessorial;

  const loadingCurrentAccessorial =
    loading || isSwitchingAccessorial || isRatingAccessorials;

  const accessorialsToUse = useMemo(() => {
    let mergedAccessorials: Array<{ name: string; uuid: string }> =
      accessorials.filter((accessorial) => {
        if (
          accessorial.__typename === 'SpecialAccessorialEntity' &&
          !isValidStopTypeForSpecialAccessorialCharges(stopType)
        ) {
          return false;
        }
        return true;
      });

    if (
      !accessorials.some((a) => a.uuid === accessorialUuid) && // Manually add it to the list of options. This might happen if the
      // accessorial was archived, is for another terminal, contact, etc.
      !isNil(currentAccessorial)
    ) {
      mergedAccessorials = unionBy<{ name: string; uuid: string }>(
        accessorials,
        [{ uuid: currentAccessorial.uuid, name: currentAccessorial.name }],
        (acc) => acc.uuid,
      );
    }
    return mergedAccessorials;
  }, [accessorials, currentAccessorial, accessorialUuid, stopType]);

  useEffect(() => {
    if (!isNil(currentAccessorial)) {
      if (currentAccessorial.__typename !== 'SpecialAccessorialEntity') {
        setValue(
          `stops.${stopIdx}.customCharges.${customChargeIdx}.specialAccessorialMatrixItemUuid`,
          null,
        );
      }
      if (currentAccessorial.__typename !== 'ZoneBasedAccessorialEntity') {
        setValue(
          `stops.${stopIdx}.customCharges.${customChargeIdx}.zoneBasedAccessorialMatrixItemUuid`,
          null,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccessorial]);

  const accessorialOptions = useMemo(() => {
    let ord = 1;
    let options: Array<{
      value: string;
      label: string;
    }> =
      accessorialsToUse.map((accessorial) => ({
        value: accessorial.uuid,
        label: accessorial.name,
      })) ?? [];
    options = options.map((option) => {
      if (
        options.find(
          (o) => o.label === option.label && o.value !== option.value,
        )
      ) {
        const newOption = { ...option };
        newOption.label = `${option.label} (${ord})`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ord += 1;
        return newOption;
      }
      return option;
    });
    return filterNotNil(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessorialsToUse, terminalUuid]);

  let zoneOptions =
    currentAccessorial?.__typename === 'ZoneBasedAccessorialEntity'
      ? currentAccessorial.zones
          .map((zone) => ({
            value: zone.uuid,
            label: zone.name,
          }))
          .sort((a, b) => a.label.localeCompare(b.label))
      : [];
  zoneOptions =
    currentAccessorial?.__typename === 'SpecialAccessorialEntity'
      ? currentAccessorial.zones.map((zone) => ({
          value: zone.uuid,
          label: zone.name,
        }))
      : zoneOptions;
  const zoneUuid = customCharge?.zoneUuid;
  const chargeGroupUuid = customCharge?.chargeGroupUuid;

  const updateTariffZone = async () => {
    if (
      !isNil(tariffUuid) &&
      !isEmpty(tariffUuid) &&
      currentAccessorial?.__typename === 'ZoneBasedAccessorialEntity' &&
      isEmpty(zoneBasedAccessorialZone)
    ) {
      const message = 'Please select a zone';
      const selectedTariffZone = (
        await getTariffByUuid({
          variables: {
            uuid: tariffUuid,
          },
        })
      ).data?.tariff.tariffZone;
      if (isNil(selectedTariffZone)) {
        setError(`stops.${stopIdx}.customCharges.${customChargeIdx}.zoneUuid`, {
          message,
        });
      } else {
        const matchingZoneOption = zoneOptions.find((zoneOption) =>
          normalizedStringEquals(
            zoneOption.label,
            selectedTariffZone?.name ?? '',
          ),
        );
        if (isNil(matchingZoneOption)) {
          setError(
            `stops.${stopIdx}.customCharges.${customChargeIdx}.zoneUuid`,
            {
              message,
            },
          );
        } else {
          setValue(
            `stops.${stopIdx}.customCharges.${customChargeIdx}.zoneUuid`,
            matchingZoneOption?.value,
          );
          clearErrors(
            `stops.${stopIdx}.customCharges.${customChargeIdx}.zoneUuid`,
          );
        }
      }
    } else {
      clearErrors(`stops.${stopIdx}.customCharges.${customChargeIdx}.zoneUuid`);
    }
  };

  useEffect(() => {
    updateTariffZone();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tariffUuid, currentAccessorial]);

  const isZoneBased =
    currentAccessorial?.__typename === 'ZoneBasedAccessorialEntity';
  const isSpecial =
    currentAccessorial?.__typename === 'SpecialAccessorialEntity';
  const isMileageBasedSpecial =
    isSpecial &&
    currentAccessorial?.specialAccessorialType ===
      SpecialAccessorialType.MileageBased;
  const isSpecialOrZoneBasedAccessorial = isZoneBased || isSpecial;

  useEffect(() => {
    if (!shouldRateOrder || isNil(customCharge)) {
      return;
    }
    if (isSpecialOrZoneBasedAccessorial) {
      const zoneMatrixItems =
        currentAccessorial?.__typename === 'ZoneBasedAccessorialEntity'
          ? currentAccessorial.matrixItems
          : [];
      const specialMatrixItems =
        currentAccessorial?.__typename === 'SpecialAccessorialEntity'
          ? currentAccessorial.matrixItems
          : [];
      const zoneMatrixItem = zoneMatrixItems.find(
        (itrItem) =>
          itrItem.chargeGroupUuid === chargeGroupUuid &&
          itrItem.zoneUuid === zoneUuid,
      );
      const specialMatrixItem = specialMatrixItems.find(
        (itrItem) =>
          itrItem.chargeGroupUuid === chargeGroupUuid &&
          itrItem.zoneUuid === zoneUuid,
      );
      if (isZoneBased) {
        setValue(
          `stops.${stopIdx}.customCharges.${customChargeIdx}.zoneBasedAccessorialMatrixItemUuid`,
          zoneMatrixItem?.uuid ?? null,
        );
        setValue(
          `stops.${stopIdx}.customCharges.${customChargeIdx}.rate`,
          zoneMatrixItem?.rate ?? null,
        );
      }

      if (isSpecial) {
        setValue(
          `stops.${stopIdx}.customCharges.${customChargeIdx}.specialAccessorialMatrixItemUuid`,
          specialMatrixItem?.uuid ?? null,
        );
        setValue(
          `stops.${stopIdx}.customCharges.${customChargeIdx}.rate`,
          specialMatrixItem?.rate ?? null,
        );
        setValue(
          `stops.${stopIdx}.customCharges.${customChargeIdx}.useAccessorialRate`,
          true,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chargeGroupUuid, zoneUuid, currentAccessorial]);

  const deletedAutoAppliedAccessorials = useWatch({
    control,
    name: 'deletedAutoAppliedAccessorials',
  });

  const deleteCharge = () => {
    if (!isNil(customCharge)) {
      setValue(
        `stops.${stopIdx}.customCharges`,
        getValues(`stops.${stopIdx}.customCharges`)?.filter(
          (c) => c.uuid !== customCharge.uuid,
        ),
      );
      if (customCharge.isAutoApplied && !isNil(customCharge.accessorialUuid)) {
        deletedAutoAppliedAccessorials.push(customCharge.accessorialUuid);
        setValue(
          'deletedAutoAppliedAccessorials',
          uniq(deletedAutoAppliedAccessorials),
        );
      }
      onRemoved();
    }
  };

  const {
    customChargeAccessorialSelectTestId,
    customChargeAccessorialSelectInputTestId,
    customChargeAccessorialZoneSelectTestId,
    customChargeAccessorialZoneSelectInputTestId,
    customChargeNameInputTestId,
  } = getStopCustomChargeTestIds({ stopIdx, customChargeIdx });

  // #region Tooltip Management
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false);
  const [isTooltipHovered, setIsTooltipHovered] = useState(false);
  useEffect(() => {
    // HACK: Assuming about 15 characters is the max length for the accessorial
    // name. We don't bother to fully check overflow because it's rather
    // difficult.
    const value = getValues(
      `stops.${stopIdx}.customCharges.${customChargeIdx}.name`,
    );
    const overflowed = isNil(value) || value.length > 15;

    // Tooltip should be open iff:
    // 1. the text overflows
    // 2. the autocomplete is not open
    // 3. the tooltip is hovered
    // 4. the input is enabled
    setIsTooltipOpen(
      overflowed &&
        !isAutocompleteOpen &&
        isTooltipHovered &&
        !disabledIfFinalizedOrLater,
    );
  }, [
    isAutocompleteOpen,
    isTooltipHovered,
    disabledIfFinalizedOrLater,
    getValues,
    stopIdx,
    customChargeIdx,
  ]);
  // #endregion Tooltip Management

  const AccessorialAutocomplete = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',
        gap: '5px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
          flex: '1',
        }}
      >
        <AutocompleteFuzzy
          sx={{ ...MT_IF_HAS_DESCRIPTION_STYLES }}
          size="small"
          key={customCharge?.uuid}
          disableClearable
          disabled={disabledIfFinalizedOrLater}
          autoHighlight
          autoSelect
          openOnFocus
          onOpen={() => {
            setIsAutocompleteOpen(true);
          }}
          onClose={() => {
            setIsAutocompleteOpen(false);
            setNewlyAddedCharge(false);
          }}
          value={
            customCharge?.billingMethod ===
            CustomChargeBillingMethod.Accessorial
              ? isNil(accessorialUuid)
                ? undefined
                : {
                    value: accessorialUuid,
                    label:
                      accessorialOptions?.find(
                        (option) => option.value === accessorialUuid,
                      )?.label ?? '',
                  }
              : {
                  value: CUSTOM_CHARGE_KEY,
                  label: sentenceCase(CUSTOM_CHARGE_KEY),
                }
          }
          data-testid={customChargeAccessorialSelectTestId}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderOption={(props, option) => {
            return option.value === CUSTOM_CHARGE_KEY ? (
              <li
                {...props}
                style={{
                  fontWeight: 'bold',
                  borderBottom: '1px solid grey',
                }}
              >
                {option.label}
              </li>
            ) : (
              <li {...props}>{option.label}</li>
            );
          }}
          options={accessorialOptions}
          stickyOptionProps={{
            stickyOption: {
              value: CUSTOM_CHARGE_KEY,
              label: AD_HOC_CUSTOM_CHARGE_OPTION_LABEL,
            },
            filterStickyOption: true,
          }}
          matchSortOptions={{ keys: ['label'] }}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <PalletTooltip
              title={params.inputProps.value}
              open={isTooltipOpen}
              onMouseEnter={() => {
                setIsTooltipHovered(true);
              }}
              onMouseLeave={() => {
                setIsTooltipHovered(false);
              }}
            >
              <TextField
                {...params}
                size="small"
                sx={{ width: '100%' }}
                inputProps={{
                  ...params.inputProps,
                  'data-testid': customChargeAccessorialSelectInputTestId,
                }}
                label="Accessorial"
                autoFocus={newlyAddedCharge}
              />
            </PalletTooltip>
          )}
          onChange={async (_event, option) => {
            // If the custom charge was an auto-applied accessorial and changed to a different accessorial, consider it a deleted auto-applied accessorial
            if (
              customCharge?.isAutoApplied === true &&
              !isNil(customCharge?.accessorialUuid)
            ) {
              deletedAutoAppliedAccessorials.push(
                customCharge?.accessorialUuid,
              );
              setValue(
                'deletedAutoAppliedAccessorials',
                uniq(deletedAutoAppliedAccessorials),
              );
              setValue(
                `stops.${stopIdx}.customCharges.${customChargeIdx}.isAutoApplied`,
                false,
              );
            }

            let customChargeBillingMethod = CustomChargeBillingMethod.AdHoc;
            if (option.value === CUSTOM_CHARGE_KEY) {
              customChargeBillingMethod = CustomChargeBillingMethod.AdHoc;
              setValue(
                `stops.${stopIdx}.customCharges.${customChargeIdx}.accessorialUuid`,
                null,
              );
              setValue(
                `stops.${stopIdx}.customCharges.${customChargeIdx}.accessorialName`,
                null,
              );
              setValue(
                `stops.${stopIdx}.customCharges.${customChargeIdx}.name`,
                null,
              );
              setValue(
                `stops.${stopIdx}.customCharges.${customChargeIdx}.rate`,
                null,
              );
              setValue(
                `stops.${stopIdx}.customCharges.${customChargeIdx}.quantity`,
                1,
              );
            } else {
              customChargeBillingMethod = CustomChargeBillingMethod.Accessorial;
              setValue(
                `stops.${stopIdx}.customCharges.${customChargeIdx}.accessorialUuid`,
                option.value,
              );
              setValue(
                `stops.${stopIdx}.customCharges.${customChargeIdx}.accessorialName`,
                option.label,
              );
              setValue(
                `stops.${stopIdx}.customCharges.${customChargeIdx}.name`,
                option.label,
              );
              setValue(
                `stops.${stopIdx}.customCharges.${customChargeIdx}.rate`,
                null,
              );

              const optionTypename = accessorials.find((accessorial) => {
                return accessorial.uuid === option.value;
              })?.__typename;

              if (!isNil(optionTypename)) {
                setValue(
                  `stops.${stopIdx}.customCharges.${customChargeIdx}.accessorialType`,
                  getAccessorialTypeFromTypename(optionTypename),
                );
              }

              // Autopopulate new wait time accessorials with driver wait times
              if (optionTypename === 'WaitTimeAccessorialEntity') {
                const { waitTimeHours, waitTimeMinutes } = calculateWaitTime({
                  arrivedAt,
                  completedAt,
                });

                const quantity =
                  !isNil(waitTimeHours) && !isNil(waitTimeMinutes)
                    ? waitTimeHours * 60 + waitTimeMinutes
                    : 1;

                setValue(
                  `stops.${stopIdx}.customCharges.${customChargeIdx}.quantity`,
                  quantity,
                );
              }
            }
            setValue(
              `stops.${stopIdx}.customCharges.${customChargeIdx}.billingMethod`,
              customChargeBillingMethod,
            );
            setValue(
              `stops.${stopIdx}.customCharges.${customChargeIdx}.useAccessorialRate`,
              true,
            );
            setIsSwitchingAccessorial(true);
          }}
        />
        <DescriptionComponent
          keyString={customChargeKey}
          disabled={disabledIfInvoicePosted}
        />
      </Box>
      {isSpecialOrZoneBasedAccessorial && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          <AutocompleteFuzzy
            size="small"
            sx={{
              mt: isMileageBasedSpecial ? '16px' : undefined,
            }}
            disabled={disabledIfFinalizedOrLater}
            data-testid={customChargeAccessorialZoneSelectTestId}
            value={
              isNil(zoneUuid)
                ? null
                : {
                    value: zoneUuid,
                    label:
                      zoneOptions?.find((option) => option.value === zoneUuid)
                        ?.label ?? '',
                  }
            }
            options={zoneOptions}
            matchSortOptions={{ keys: ['label'] }}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label={isMileageBasedSpecial ? 'Mile range' : 'Zone'}
                sx={{ width: '150px' }}
                error={!isNil(zoneBasedAccessorialZoneError)}
                helperText={zoneBasedAccessorialZoneError?.message ?? ''}
                inputProps={{
                  ...params.inputProps,
                  'data-testid': customChargeAccessorialZoneSelectInputTestId,
                }}
              />
            )}
            onChange={(_, option) => {
              clearErrors(
                `stops.${stopIdx}.customCharges.${customChargeIdx}.zoneUuid`,
              );
              setValue(
                `stops.${stopIdx}.customCharges.${customChargeIdx}.zoneUuid`,
                option?.value ?? null,
              );
            }}
          />
          {isMileageBasedSpecial && (
            <MilesComponent
              context={{ shipmentType: ShipmentType.Regular, stopIdx }}
              disabled={disabledIfFinalizedOrLater}
              shouldSetFreightChargeQuantity={false}
            />
          )}
        </Box>
      )}
      {customCharge?.billingMethod === CustomChargeBillingMethod.AdHoc && (
        <Controller
          name={`stops.${stopIdx}.customCharges.${customChargeIdx}.name`}
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControl
              fullWidth
              sx={{
                marginTop: '16px',
              }}
            >
              <TextField
                disabled={disabledIfFinalizedOrLater}
                label="Name"
                size="small"
                value={value}
                onChange={onChange}
                inputProps={{ 'data-testid': customChargeNameInputTestId }}
                // Use helperText to align fields: https://v5.mui.com/material-ui/react-text-field/#helper-text
                helperText=" "
              />
            </FormControl>
          )}
        />
      )}
    </Box>
  );

  const hasNotSelectedAccessorial =
    customCharge?.billingMethod === CustomChargeBillingMethod.Accessorial &&
    isNilOrEmptyString(customCharge.accessorialUuid);

  const { customChargeQuantityInputTestId } = getStopCustomChargeTestIds({
    stopIdx,
    customChargeIdx,
  });

  return (
    <TableRow
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <TableCell data-testid="custom-charge-row">
        {AccessorialAutocomplete}
      </TableCell>
      <TableCell sx={{ minWidth: 180 }}>
        <Box sx={{ mt: inBillingReview ? undefined : '16px' }}>
          <CustomChargeRateInput
            isStopCustomCharge
            customCharge={customCharge}
            stopIdx={stopIdx as 0 | 1}
            customChargeIdx={customChargeIdx}
            currentAccessorial={currentAccessorial}
            disabled={disabledIfFinalizedOrLater || isSwitchingAccessorial}
          />
        </Box>
        {!inBillingReview && (
          <Typography color={theme.palette.grey[500]}>
            Fuel surcharge:{' '}
            {loadingCurrentAccessorial
              ? '-'
              : (postedFuelSurchargeRate ?? fuelSurchargeRate ?? 0)}
            %
          </Typography>
        )}
      </TableCell>
      <TableCell>
        <FormNumberInput
          fieldName={`stops.${stopIdx}.customCharges.${customChargeIdx}.quantity`}
          label={
            currentAccessorial?.__typename === 'WaitTimeAccessorialEntity'
              ? 'Minutes'
              : 'Quantity'
          }
          nonNegativeInteger
          disabled={
            disabledIfFinalizedOrLater ||
            isSwitchingAccessorial ||
            hasNotSelectedAccessorial
          }
          inputProps={{ 'data-testid': customChargeQuantityInputTestId }}
        />
      </TableCell>
      <TableCell>
        <Stack>
          <Typography
            sx={MT_IF_HAS_AUTHO_CODE_STYLES}
            data-testid={CUSTOM_CHARGE_TOTAL_TEST_ID}
          >
            {!isNil(customCharge) && !loadingCurrentAccessorial
              ? currency(customCharge.totalCharge).format()
              : '-'}
          </Typography>
          <AuthoCodeComponent
            authoCode={authoCode ?? ''}
            keyString={customChargeKey}
            disabled={disabledIfInvoicePosted}
            required={currentAccessorial?.isAuthoCodeRequired ?? false}
            error={
              errors?.stops?.[stopIdx]?.customCharges?.[customChargeIdx]
                ?.authoCode
            }
          />
        </Stack>
      </TableCell>
      <TableCell>
        {!disabledIfFinalizedOrLater && (
          <Fade in={isHovering}>
            <IconButton
              onClick={() => {
                deleteCharge();
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Fade>
        )}
      </TableCell>
    </TableRow>
  );
};

export default CustomChargeRow;
