import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { type Stringified } from 'type-fest';
import type { RootState } from '../../../redux/store';
import { type ContactValues } from './contact-values-slice';

export type ContactErrors = Stringified<
  Partial<Omit<ContactValues, 'uuid'>>
> & { uuid: string };

const contactErrorsAdapter = createEntityAdapter<ContactErrors>({
  selectId: (contactErrors) => contactErrors.uuid,
});

export const contactErrorsSlice = createSlice({
  name: 'contactErrors',
  initialState: contactErrorsAdapter.getInitialState(),
  reducers: {
    upsertOneContactErrors: contactErrorsAdapter.upsertOne,
  },
});

export const { selectById: selectContactErrorsById } =
  contactErrorsAdapter.getSelectors((state: RootState) => state.contactErrors);

const contactErrorsReducer = contactErrorsSlice.reducer;

export default contactErrorsReducer;

export const { upsertOneContactErrors } = contactErrorsSlice.actions;
