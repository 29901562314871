import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import { isNil } from 'lodash';
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ContactAutocompleteComponent from '../../../../../common/components/contact-autocomplete-component';
import {
  InvoicesByUuidsDocument,
  useCompleteRebillMutation,
  useCreateDuplicateOrderForRebillMutation,
  useOrderForRebillLazyQuery,
} from '../../../../../generated/graphql';
import { OrderDialog } from '../../../../orders/components/order-dialog';

// duplicate the order. When the order uuid is returned, show the order page.
const RebillOrderModal = ({
  open,
  setOpen,
  orderUuid,
  invoiceUuid,
  setRebillSuccessAlertInvoiceName,
}: {
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
  readonly orderUuid: string;
  readonly invoiceUuid: string;
  readonly setRebillSuccessAlertInvoiceName: Dispatch<
    SetStateAction<string | undefined>
  >;
}) => {
  const [contactUuid, setContactUuid] = useState<string | undefined>(undefined);
  const [duplicatedOrderUuid, setDuplicatedOrderUuid] = useState<
    string | undefined
  >(undefined);
  const [getOrder, { data: orderData }] = useOrderForRebillLazyQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isNil(orderUuid) && open) {
      getOrder({
        variables: {
          uuid: orderUuid,
        },
      });
    }
  }, [orderUuid, open, getOrder]);

  useEffect(() => {
    if (!isNil(orderData)) {
      setContactUuid(orderData.order?.invoice?.billToContact.uuid);
    }
  }, [orderData]);

  const [completeRebillMutation, { loading: rebillOngoing, data: newInvoice }] =
    useCompleteRebillMutation({
      refetchQueries: [InvoicesByUuidsDocument],
    });

  const [
    createDuplicateOrderForRebill,
    { loading: duplicateOngoing, data: duplicatedOrder },
  ] = useCreateDuplicateOrderForRebillMutation();

  const handleClose = () => {
    if (duplicateOngoing) return;
    setOpen(false);
    setDuplicatedOrderUuid(undefined);
    setContactUuid(undefined);
    setRebillSuccessAlertInvoiceName(undefined);
  };

  useEffect(() => {
    if (
      !isNil(
        duplicatedOrder?.createDuplicateOrderForRebill.duplicatedOrder.uuid,
      )
    ) {
      setDuplicatedOrderUuid(
        duplicatedOrder?.createDuplicateOrderForRebill.duplicatedOrder.uuid,
      );
    }
  }, [duplicatedOrder?.createDuplicateOrderForRebill.duplicatedOrder.uuid]);

  const getBody = () => {
    if (isNil(orderData?.order)) {
      return <CircularProgress />;
    }
    if (duplicateOngoing || rebillOngoing) {
      return <CircularProgress />;
    }

    return (
      <Stack gap={3} width="100%">
        {/* <Stack
          direction="row"
          alignItems="center"
          gap={2}
          width="100%"
          // height={40}
        > */}
        This will put the order {orderData?.order?.name} on a new invoice and
        apply a credit of ${orderData?.order?.totalCharge} to the original
        invoice ({orderData?.order?.invoice?.name}){/* </Stack> */}
        <Stack gap={1}>
          Select billing party for the new invoice:
          <ContactAutocompleteComponent
            billingPartyContactUuid={contactUuid}
            onChange={(value: string | undefined) => {
              setContactUuid(value);
            }}
          />
        </Stack>
      </Stack>
    );
  };

  const completeRebill = async ({
    newContactUuid,
  }: {
    newContactUuid: string;
  }) => {
    if (isNil(duplicatedOrderUuid)) return;

    const data = await completeRebillMutation({
      variables: {
        completeRebillInput: {
          newContactUuid,
          originalOrderUuid: orderUuid,
          originalInvoiceUuid: invoiceUuid,
          rebillOrderUuid: duplicatedOrderUuid,
        },
      },
    });

    setRebillSuccessAlertInvoiceName(
      data.data?.completeRebill.invoiceForRebill.name,
    );
    navigate(
      `/accounting/?invoiceUuid=${data.data?.completeRebill.invoiceForRebill.uuid}&invoiceName=${data.data?.completeRebill.invoiceForRebill.name}`,
    );
  };

  const handlePrimaryClick = async () => {
    if (!isNil(orderUuid) && !isNil(contactUuid)) {
      await createDuplicateOrderForRebill({
        variables: {
          input: {
            originalOrderUuid: orderUuid,
            newContactUuid: contactUuid,
          },
        },
      });
    }
  };

  if (!isNil(duplicatedOrderUuid)) {
    return (
      <OrderDialog
        rebillMode
        open={open}
        orderUuid={duplicatedOrderUuid}
        completeRebillAfterSave={completeRebill}
        onClose={handleClose}
      />
    );
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
    >
      <Box
        sx={{
          p: '20px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          //   height: 200,
          width: 600,
        }}
      >
        <DialogTitle>
          <Stack
            direction="row"
            alignItems="center"
            gap={2}
            width="100%"
            height={40}
          >
            Rebill order
          </Stack>
        </DialogTitle>
        <DialogContent>{getBody()}</DialogContent>
        <DialogActions
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Button
            variant="outlined"
            disabled={rebillOngoing}
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
          {isNil(newInvoice) && (
            <Button
              variant="contained"
              disabled={rebillOngoing}
              onClick={handlePrimaryClick}
            >
              Rebill order
            </Button>
          )}
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default RebillOrderModal;
