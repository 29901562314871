import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  type SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { flatten, isNil } from 'lodash';
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';
import { filterNotNil } from 'shared/array';
import { safeDivide, safeMultiply } from 'shared/math';
import {
  GetAppointmentsDocument,
  RoutesDocument,
  useCalculateRouteEtasMutation,
  useEquipmentsQuery,
  useRoutesWithEtaQuery,
  useUpdateShipmentMutation,
  useUpdateStopMutation,
} from '../../../../generated/graphql';
import {
  type FormattedRoute,
  type Slot,
  SlotTypes,
  formattedRouteSlotData,
} from '../utils/utils';
import EditStopRouteRow from './edit-stop-route-row';
import ScheduleAppointmentRow from './schedule-appointment-row';

const styles = {
  modalInnerContainer: {
    bgcolor: 'background.paper',
    boxShadow: 24,
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
    p: 4,
  } as SxProps,
};

const ScheduleAppointmentsModal = ({
  initialRouteUuid,
  open,
  setIsOpen,
  planningDate,
}: {
  readonly initialRouteUuid: string | null;
  readonly open: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly planningDate: Date | undefined;
}) => {
  const { data, loading } = useRoutesWithEtaQuery({
    variables: { date: planningDate },
  });
  const { data: equipmentsData } = useEquipmentsQuery();
  const [selectedRouteUuid, setSelectedRouteUuid] = useState<string | null>(
    initialRouteUuid,
  );
  const routes: FormattedRoute[] = formattedRouteSlotData(undefined, data);
  const [routeData, setRouteData] = useState(routes);
  const routeSlots: Slot[] | undefined = routeData.find(
    (route) => route.id === selectedRouteUuid,
  )?.slots;
  const [modifiedRouteSlotUuids, setModifiedRouteSlotUuids] = useState<
    string[]
  >([]);
  const [updateShipment] = useUpdateShipmentMutation();
  const [updateStop] = useUpdateStopMutation({
    refetchQueries: [RoutesDocument, GetAppointmentsDocument],
  });
  const [isAutogenerateLoading, setIsAutogenerateLoading] = useState(false);
  const [refreshEtasForRoutes] = useCalculateRouteEtasMutation({
    refetchQueries: [RoutesDocument, GetAppointmentsDocument],
  });

  useEffect(() => {
    setRouteData(routes);
  }, [routes.length]);

  useEffect(() => {
    const firstRouteUuid = routes[0]?.id;
    if (!isNil(initialRouteUuid)) {
      setSelectedRouteUuid(initialRouteUuid);
    } else if (!isNil(firstRouteUuid)) {
      setSelectedRouteUuid(firstRouteUuid);
    }
  }, [initialRouteUuid, routes.length]);

  // Have to force the modal to re-render because of issues with the time-picker not re-rendering when `stop.appointmentTime` changes
  useEffect(() => {
    setTimeout(() => {
      setIsAutogenerateLoading(false);
    }, 1);
  }, [isAutogenerateLoading]);

  const markAsChanged = (slot: Slot) => {
    setModifiedRouteSlotUuids((prevState) => {
      if (
        slot.__typename === SlotTypes.StandardStop &&
        !prevState.includes(slot.id)
      ) {
        return [...prevState, slot.id];
      }
      return prevState;
    });
  };

  const updateSlot = ({
    stopId,
    appointmentTime,
    endAppointmentTime,
    overrideServiceTimeInMinutes,
  }: {
    stopId: string;
    appointmentTime?: string | null | undefined;
    endAppointmentTime?: string | null | undefined;
    overrideServiceTimeInMinutes?: number;
  }) => {
    setRouteData((prevRoutes) => {
      return prevRoutes.map((route) => {
        if (route.id === selectedRouteUuid) {
          const newSlots = (routeSlots ?? []).map((slot) => {
            if (
              slot.id === stopId &&
              slot.__typename === SlotTypes.StandardStop
            ) {
              markAsChanged(slot);
              return {
                ...slot,
                appointmentWindow: isNil(appointmentTime)
                  ? slot.appointmentWindow
                  : appointmentTime,
                endAppointmentTime: isNil(endAppointmentTime)
                  ? slot.endAppointmentTime
                  : endAppointmentTime,
                overrideServiceTimeInMinutes: isNil(
                  overrideServiceTimeInMinutes,
                )
                  ? slot.overrideServiceTimeInMinutes
                  : overrideServiceTimeInMinutes,
              };
            }
            return slot;
          });
          return { ...route, slots: newSlots };
        }
        return route;
      });
    });
  };

  const autogenerateAppointments = (singleAppointmentUuid?: string) => {
    setRouteData((prevRoutes) => {
      return prevRoutes.map((route) => {
        const newSlots = (route.slots ?? []).map((slot) => {
          if (
            !isNil(singleAppointmentUuid) &&
            singleAppointmentUuid !== slot.id
          ) {
            return slot;
          }
          // Only generate appointments for stops without appointments
          if (
            slot.__typename === SlotTypes.StandardStop &&
            isNil(slot.appointmentWindow) &&
            isNil(slot.endAppointmentTime) &&
            !isNil(slot.etaArrivalTime)
          ) {
            const timeToBaseWindowFrom = dayjs(slot.etaArrivalTime);
            const roundedDownMinutes = safeMultiply(
              Math.floor(safeDivide(timeToBaseWindowFrom.minute(), 30)),
              30,
            );
            const appointment = timeToBaseWindowFrom.set(
              'minute',
              roundedDownMinutes,
            );
            markAsChanged(slot);
            const endAppointment = appointment.add(3, 'hour');
            return {
              ...slot,
              appointmentWindow: appointment.toISOString(),
              endAppointmentTime: endAppointment.toISOString(),
            };
          }
          return slot;
        });
        return { ...route, slots: newSlots };
      });
    });
    setIsAutogenerateLoading(true);
  };

  const handleSave = async () => {
    const allRouteSlots = flatten(routeData.map((route) => route.slots));
    // Only save the route slots that have been modified.
    const modifiedRouteSlots = allRouteSlots.filter((slot) =>
      modifiedRouteSlotUuids.includes(slot.id),
    );
    await Promise.all(
      (modifiedRouteSlots ?? []).map(async (slot) => {
        if (slot.__typename === SlotTypes.StandardStop) {
          if (!isNil(slot.shipmentId) && !isNil(slot.shipmentFieldsId)) {
            await updateShipment({
              variables: {
                updateShipmentInput: {
                  shipmentUpdateInput: {
                    uuid: slot.shipmentId,
                    standardShipmentFieldsUpdateInput: {
                      deliveryDate: planningDate,
                      uuid: slot.shipmentFieldsId,
                    },
                  },
                },
              },
            });
          }
          return updateStop({
            variables: {
              updateStopInput: {
                stopUpdateInput: {
                  uuid: slot.id,
                  appointmentTime:
                    !isNil(slot.appointmentWindow) &&
                    slot.appointmentWindow.length > 0
                      ? slot.appointmentWindow
                      : null,
                  endAppointmentTime:
                    !isNil(slot.endAppointmentTime) &&
                    slot.endAppointmentTime.length > 0
                      ? slot.endAppointmentTime
                      : null,
                  overrideServiceTimeInMinutes: isNil(
                    slot.overrideServiceTimeInMinutes,
                  )
                    ? undefined
                    : slot.overrideServiceTimeInMinutes,
                },
              },
            },
          });
        }
        return null;
      }),
    );
    const routeUuidsToRefresh = filterNotNil(
      modifiedRouteSlots.map((slot) =>
        slot.__typename === SlotTypes.StandardStop ? slot.routeId : undefined,
      ),
    );
    await refreshEtasForRoutes({ variables: { uuids: routeUuidsToRefresh } });
    setIsOpen(false);
  };

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="xl"
      onClose={() => {
        setIsOpen(false);
      }}
    >
      {!isNil(selectedRouteUuid) &&
        !isNil(routeSlots) &&
        !isAutogenerateLoading && (
          <Box sx={styles.modalInnerContainer}>
            <Typography variant="h5">Edit routes</Typography>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                gap: '10px',
              }}
            >
              <Button
                sx={{ ml: 'auto' }}
                variant="contained"
                onClick={() => {
                  autogenerateAppointments();
                }}
              >
                Create appointments
              </Button>
              <Button variant="contained" onClick={handleSave}>
                Save changes
              </Button>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
              <Box sx={{ width: '30%' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Route</TableCell>
                      <TableCell># Appts</TableCell>
                      <TableCell>Total</TableCell>
                      <TableCell>Vehicle</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {routeData.map((route) => (
                      <EditStopRouteRow
                        key={route.id}
                        selectedRouteUuid={selectedRouteUuid}
                        route={route}
                        setSelectedRouteUuid={setSelectedRouteUuid}
                        equipmentsData={equipmentsData}
                      />
                    ))}
                  </TableBody>
                </Table>
              </Box>
              <Divider flexItem orientation="vertical" />
              <Box sx={{ width: '70%' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Order name</TableCell>
                      <TableCell>City</TableCell>
                      <TableCell>Start appointment</TableCell>
                      <TableCell>End appointment</TableCell>
                      <TableCell>ETA</TableCell>
                      <TableCell>Address</TableCell>
                      <TableCell>Service</TableCell>
                      <TableCell>Service time (min)</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {routeSlots.map((slot) => (
                      <ScheduleAppointmentRow
                        key={slot.id}
                        slot={slot}
                        updateSlot={updateSlot}
                        autogenerateAppointment={() => {
                          autogenerateAppointments(slot.id);
                        }}
                      />
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </Box>
        )}
      {(isAutogenerateLoading || loading) && (
        <Box sx={styles.modalInnerContainer}>
          <CircularProgress />
        </Box>
      )}
      {!loading && data?.routes.length === 0 && (
        <Box sx={styles.modalInnerContainer}>
          <DialogTitle>No appointments on route</DialogTitle>
          <Typography>
            No routes are scheduled for this day. To schedule appointments that
            are not on routes, use the orders page.
          </Typography>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Box>
      )}
    </Dialog>
  );
};

export default ScheduleAppointmentsModal;
