import {
  createEntityAdapter,
  createSelector,
  createSlice,
  type EntityId,
  type EntityState,
} from '@reduxjs/toolkit';
import { type SpecialDayOfWeek } from '../../../generated/graphql';
import type { RootState } from '../../../redux/store';

type SpecialAccessorialChargeGroupFields = {
  uuid: string;
  dayOfWeek: SpecialDayOfWeek;
  startTime: Date;
  endTime: Date;
};

type AccessorialChargeGroupStoreState = SpecialAccessorialChargeGroupFields;

const accessorialsChargeGroupsAdapter =
  createEntityAdapter<AccessorialChargeGroupStoreState>({
    selectId: (chargeGroup) => chargeGroup.uuid,
  });

export const specialAccessorialChargeGroupsSlice = createSlice({
  name: 'accessorialChargeGroups',
  initialState: accessorialsChargeGroupsAdapter.getInitialState(),
  reducers: {
    addOneAccessorialChargeGroup: accessorialsChargeGroupsAdapter.addOne,
    updateAccessorialChargeGroup: accessorialsChargeGroupsAdapter.updateOne,
    deleteAccessorialChargeGroup: accessorialsChargeGroupsAdapter.removeOne,
    updateAccessorialChargeGroups: accessorialsChargeGroupsAdapter.updateMany,
    setAllAccessorialChargeGroups: accessorialsChargeGroupsAdapter.setAll,
    upsertManySpecialAccessorialChargeGroups:
      accessorialsChargeGroupsAdapter.upsertMany,
  },
});

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectById: selectSpecialAccessorialChargeGroupById,
  // Pass in a selector that returns the posts slice of state
} = accessorialsChargeGroupsAdapter.getSelectors(
  (state: RootState) => state.specialAccessorialChargeGroups,
);

const accessorialChargeGroupsSelector = (state: RootState) =>
  state.specialAccessorialChargeGroups;

export const selectSpecialAccessorialChargeGroupsByIds = createSelector(
  accessorialChargeGroupsSelector,
  (state: RootState, ids: EntityId[]) => ids,
  (
    state: EntityState<AccessorialChargeGroupStoreState>,
    entityIds: EntityId[],
  ) => {
    const entityResults: AccessorialChargeGroupStoreState[] = [];
    for (const id of entityIds) {
      const match = accessorialsChargeGroupsAdapter
        .getSelectors()
        .selectById(state, id);
      if (match) {
        entityResults.push(match);
      }
    }
    return entityResults;
  },
);

export const {
  addOneAccessorialChargeGroup,
  updateAccessorialChargeGroup,
  deleteAccessorialChargeGroup,
  setAllAccessorialChargeGroups,
  upsertManySpecialAccessorialChargeGroups,
} = specialAccessorialChargeGroupsSlice.actions;

export default specialAccessorialChargeGroupsSlice.reducer;
