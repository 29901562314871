import { Alert, Menu, MenuItem, Snackbar, Tooltip } from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { filterNotNil } from 'shared/array';
import { exhaustive } from 'shared/switch';
import { ORDER_PAGE_MARK_AS_COMPLETE_BUTTON_TEST_ID } from '../../../../../../../constants';
import { MarkOrderAsReadyToInvoiceDialog } from '../../../../../../common/components/modals/mark-order-as-ready-to-invoice-dialog';
import { MarkOrderAsRefusedDialog } from '../../../../../../common/components/modals/mark-order-as-refused-dialog';
import { FeatureFlag } from '../../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../../common/react-hooks/use-feature-flag';
import useHoldReasons from '../../../../../../common/react-hooks/use-hold-reasons';
import useTerminals from '../../../../../../common/react-hooks/use-terminals';
import { isNilOrEmptyString } from '../../../../../../common/utils/utils';
import {
  OrderDetailedStatus,
  OrderStatus,
  useMarkOrderAsCancelledMutation,
  useMarkOrderAsNotCompleteMutation,
  useMarkOrderAsNotOnHandMutation,
  useMarkOrderAsNotReceivedAtOriginMutation,
  useMarkOrderAsOnHandMutation,
  useMarkOrderAsOnHoldMutation,
  useMarkOrderAsOsdMutation,
  useMarkOrderAsCompleteForceMutation,
  useMarkOrderAsReceivedAtOriginMutation,
  useRemoveOrderHoldMutation,
  useUncancelOrderMutation,
  useMarkOrderAsNotOsdMutation,
  useOrderWithPaperworkQuery,
  OrderWithPaperworkDocument,
  useUpdatePaperworkCompletedForStopsMutation,
} from '../../../../../../generated/graphql';
import { useOrderFormEditAccess } from '../../contexts/order-form-edit-access-context';
import { StopType } from '../../forms/stop-type';
import { type OrderFormValues } from '../../forms/types';
import {
  getCanMarkOrderAsNotComplete,
  getCanMarkReceivedAtOrigin,
  getCannotCompleteOrder,
  getPickupOrDelivery,
} from '../../forms/utils';
import { useUpdateAndRefetchOrder } from '../../hooks/use-update-and-refetch-order';
import { type OnSubmitParams } from '../../types';
import MarkOrderOnHoldModal from './modals/mark-order-on-hold-modal';

export const canBeMarkedAsOnHold = (
  status: OrderStatus | null | undefined,
): boolean => {
  switch (status) {
    case OrderStatus.Created:
    case OrderStatus.InProgress: {
      return true;
    }
    case OrderStatus.Delivered:
    case OrderStatus.Finalized:
    case OrderStatus.Cancelled:
    case OrderStatus.HasIssue:
    case OrderStatus.Invoiced:
    case OrderStatus.OnHold:
    case null:
    case undefined: {
      return false;
    }
    default: {
      return exhaustive(status);
    }
  }
};

// This means "completed", not "finalized"
export const canBeMarkedAsReadyToInvoice = (
  status: OrderStatus | null | undefined,
): boolean => {
  switch (status) {
    case OrderStatus.Created:
    case OrderStatus.InProgress: {
      return true;
    }
    case OrderStatus.Delivered:
    case OrderStatus.Finalized:
    case OrderStatus.Cancelled:
    case OrderStatus.HasIssue:
    case OrderStatus.Invoiced:
    case OrderStatus.OnHold:
    case null:
    case undefined: {
      return false;
    }
    default: {
      return exhaustive(status);
    }
  }
};

type MarkAsMenuProps = {
  readonly isEditMode: boolean;
  readonly buttonRef: HTMLButtonElement | null;
  readonly setShowContextMenu: Dispatch<SetStateAction<boolean>>;
  readonly showContextMenu: boolean;
  readonly onSubmit: (params: OnSubmitParams) => Promise<boolean>;

  readonly setCannotCompleteOrderModalOpen: Dispatch<SetStateAction<boolean>>;
  readonly setCannotCompleteOrderModalMessage: Dispatch<
    SetStateAction<string | undefined>
  >;
};

const MarkAsMenu = ({
  isEditMode,
  buttonRef,
  setShowContextMenu,
  showContextMenu,
  onSubmit,
  setCannotCompleteOrderModalOpen,
  setCannotCompleteOrderModalMessage,
}: MarkAsMenuProps) => {
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const { holdReasons } = useHoldReasons();
  const ffRequireTransferAddress = useFeatureFlag(
    FeatureFlag.FF_REQUIRE_TRANSFER_ADDRESS_ON_COMPLETION,
  );

  const { disabledIfFinalizedOrLater: editingDisabled } =
    useOrderFormEditAccess();

  const { updateAndRefetchOrder } = useUpdateAndRefetchOrder();
  const { control, setValue } = useFormContext<OrderFormValues>();
  const ref = useRef<HTMLUListElement>(null);
  const orderUuid = useWatch({ control, name: 'uuid' });
  const pieceCount = useWatch({ control, name: 'pieceCount' });
  const status = useWatch({ control, name: 'status' });
  const detailedStatus = useWatch({ control, name: 'detailedStatus' });
  const osd = useWatch({ control, name: 'osd' });
  const refusedBy = useWatch({ control, name: 'refusedBy' });
  const onHand = useWatch({ control, name: 'onHand' });
  const receivedAtOriginDate = useWatch({
    control,
    name: 'receivedAtOriginDate',
  });
  const pickedDate = useWatch({ control, name: 'pickedDate' });
  const notes = useWatch({ control, name: 'notes' });
  const shipperBillOfLadingNumber = useWatch({
    control,
    name: 'shipperBillOfLadingNumber',
  });
  const stops = useWatch({ control, name: 'stops' });
  const canBeMarkedAsNotComplete = getCanMarkOrderAsNotComplete({
    orderStatus: status,
    stops,
  });
  const [markAsErrorMessage, setMarkAsErrorMessage] = useState<
    string | undefined
  >(undefined);
  const [showMarkOrderOnHoldModal, setShowMarkOrderOnHoldModal] =
    useState(false);
  const [showMarkAsRefusedDialog, setShowMarkAsRefusedDialog] = useState(false);
  const [showMarkAsReadyToInvoiceDialog, setShowMarkAsReadyToInvoiceDialog] =
    useState(false);

  const canMarkReceivedAtOrigin = getCanMarkReceivedAtOrigin({
    stops: stops ?? [],
  });

  const [markAsOnHandMutation, { loading: markOrderAsOnHandLoading }] =
    useMarkOrderAsOnHandMutation();
  const [markOrderAsNotOnHand, { loading: markOrderAsNotOnHandLoading }] =
    useMarkOrderAsNotOnHandMutation();
  const [
    markOrderAsReceivedAtOrigin,
    { loading: markOrderAsReceivedAtOriginLoading },
  ] = useMarkOrderAsReceivedAtOriginMutation();
  const [
    markOrderAsNotReceivedAtOrigin,
    { loading: markOrderAsNotReceivedAtOriginLoading },
  ] = useMarkOrderAsNotReceivedAtOriginMutation();
  const [markOrderAsOsd, { loading: markOrderAsOsdLoading }] =
    useMarkOrderAsOsdMutation();
  const [markOrderAsNotOsd, { loading: markOrderAsNotOsdLoading }] =
    useMarkOrderAsNotOsdMutation();
  const [
    markOrderAsReadyToInvoice,
    { loading: markOrderAsReadyToInvoiceLoading },
  ] = useMarkOrderAsCompleteForceMutation();
  const [markOrderAsNotComplete, { loading: markOrderAsNotCompleteLoading }] =
    useMarkOrderAsNotCompleteMutation();
  const [markOrderAsOnHold, { loading: markOrderAsOnHoldLoading }] =
    useMarkOrderAsOnHoldMutation();
  const [removeOrderHold, { loading: removeOrderHoldLoading }] =
    useRemoveOrderHoldMutation();
  const [markOrderAsCancelled, { loading: markOrderAsCancelledLoading }] =
    useMarkOrderAsCancelledMutation();
  const [uncancelOrder, { loading: uncancelOrderLoading }] =
    useUncancelOrderMutation({});
  const [
    updatePaperworkCompletedForStops,
    { loading: updatePaperworkCompletedForStopsLoading },
  ] = useUpdatePaperworkCompletedForStopsMutation({
    refetchQueries: [OrderWithPaperworkDocument],
  });

  const { data: paperworkCompleteData, loading: paperworkCompleteLoading } =
    useOrderWithPaperworkQuery(
      isNilOrEmptyString(orderUuid)
        ? { skip: true }
        : { variables: { uuid: orderUuid } },
    );
  const paperworkComplete =
    paperworkCompleteData?.order?.paperwork.paperworkComplete ?? false;

  const isLoading =
    markOrderAsOnHandLoading ||
    markOrderAsNotOnHandLoading ||
    markOrderAsReceivedAtOriginLoading ||
    markOrderAsNotReceivedAtOriginLoading ||
    markOrderAsOsdLoading ||
    markOrderAsNotOsdLoading ||
    markOrderAsReadyToInvoiceLoading ||
    markOrderAsNotCompleteLoading ||
    markOrderAsOnHoldLoading ||
    removeOrderHoldLoading ||
    markOrderAsCancelledLoading ||
    uncancelOrderLoading ||
    updatePaperworkCompletedForStopsLoading ||
    paperworkCompleteLoading;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        ref.current !== null &&
        !ref.current.contains(event.target as Node) &&
        buttonRef?.contains(event.target as Node) === false
      ) {
        setShowContextMenu(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const markAllPiecesAsPicked = async () => {
    if (isEditMode) {
      setValue('pickedDate', new Date());
      setValue('piecesPicked', pieceCount);
      // onSubmit will already update pickedDate and piecesPicked so there's no need to call a separate update fn
      await updateAndRefetchOrder({
        additionalUpdateFns: [],
        onSubmit,
        actionString: 'marking all pieces as picked',
      });
    }
  };

  const markAsOnHand = async () => {
    if (isEditMode) {
      await updateAndRefetchOrder({
        additionalUpdateFns: [
          {
            fn: markAsOnHandMutation,
            vars: {
              markAsOnHandInput: {
                uuid: orderUuid,
                pieceCount: undefined,
                sentFromMobileApplication: false,
              },
            },
          },
        ],
        onSubmit,
        actionString: 'marking as on hand',
      });
    } else {
      setValue('detailedStatus', OrderDetailedStatus.OnHand);
      setValue('status', OrderStatus.InProgress);
      setValue('onHand', true);
      setValue('receivedDate', new Date());
    }
  };

  const markNotOnHand = async () => {
    if (isEditMode) {
      await updateAndRefetchOrder({
        additionalUpdateFns: [
          {
            fn: markOrderAsNotOnHand,
            vars: {
              markAsNotOnHandInput: {
                uuid: orderUuid,
              },
            },
          },
        ],
        onSubmit,
        actionString: 'marking as not on hand',
      });
    } else {
      if (detailedStatus?.toLowerCase() === 'On hand'.toLowerCase()) {
        setValue(
          'detailedStatus',
          isNil(receivedAtOriginDate)
            ? OrderDetailedStatus.Creating
            : OrderDetailedStatus.ReceivedAtOrigin,
        );
      }
      setValue('status', OrderStatus.Created);
      setValue('onHand', false);
      setValue('receivedDate', undefined);
      setValue('pieceCount', undefined);
    }
  };

  const markReceivedAtOrigin = async () => {
    if (isEditMode) {
      await updateAndRefetchOrder({
        additionalUpdateFns: [
          {
            fn: markOrderAsReceivedAtOrigin,
            vars: {
              markAsReceivedAtOriginInput: {
                uuid: orderUuid,
                sentFromMobileApplication: false,
              },
            },
          },
        ],
        onSubmit,
        actionString: 'marking as received at origin',
      });
    } else {
      if (onHand !== true) {
        setValue('detailedStatus', OrderDetailedStatus.ReceivedAtOrigin);
      }
      setValue('status', OrderStatus.InProgress);
      setValue('receivedAtOriginDate', new Date());
    }
  };

  const markNotReceivedAtOrigin = async () => {
    if (isEditMode) {
      await updateAndRefetchOrder({
        additionalUpdateFns: [
          {
            fn: markOrderAsNotReceivedAtOrigin,
            vars: {
              markAsNotReceivedAtOriginInput: {
                uuid: orderUuid,
              },
            },
          },
        ],
        onSubmit,
        actionString: 'marking as not received at origin',
      });
    } else {
      if (
        detailedStatus?.toLowerCase() === 'Received at origin'.toLowerCase()
      ) {
        setValue(
          'detailedStatus',
          onHand === true
            ? OrderDetailedStatus.OnHand
            : OrderDetailedStatus.Creating,
        );
      }
      setValue('status', OrderStatus.Created);
      setValue('receivedAtOriginDate', undefined);
    }
  };

  const onMarkOsd = async () => {
    if (isEditMode) {
      await updateAndRefetchOrder({
        additionalUpdateFns: [
          {
            fn: markOrderAsOsd,
            vars: {
              markOsdInput: {
                uuid: orderUuid,
                notes: isEmpty(notes) ? undefined : notes,
              },
            },
          },
        ],
        onSubmit,
        actionString: 'marking OSD',
      });
    }
  };

  const onMarkNotOsd = async () => {
    if (isEditMode) {
      await updateAndRefetchOrder({
        additionalUpdateFns: [
          {
            fn: markOrderAsNotOsd,
            vars: {
              markAsNotOsd: {
                uuid: orderUuid,
              },
            },
          },
        ],
        onSubmit,
        actionString: 'marking as not OSD',
      });
    }
  };

  const onMarkReadyToInvoice = async (refused?: boolean) => {
    if (isEditMode) {
      const cannotCompleteOrderMessage = getCannotCompleteOrder({
        stops,
        terminalsEnabled,
        ffRequireTransferAddress,
      });
      if (!isNil(cannotCompleteOrderMessage)) {
        setCannotCompleteOrderModalMessage(cannotCompleteOrderMessage);
        setCannotCompleteOrderModalOpen(true);
        return;
      }
      if (refused === true) {
        setShowMarkAsRefusedDialog(true);
        return;
      }
      const everyStopCompleted =
        stops?.every((s) => !isNil(s.completedAt)) ?? false;
      if (!everyStopCompleted) {
        setShowMarkAsReadyToInvoiceDialog(true);
        return;
      }
      await updateAndRefetchOrder({
        additionalUpdateFns: [
          {
            fn: markOrderAsReadyToInvoice,
            vars: {
              markOrderAsCompleteForceInput: {
                uuid: orderUuid,
                refuseOrderInput: null,
              },
            },
          },
        ],
        onSubmit,
        actionString: 'marking order as ready to invoice',
      });
    }
  };

  const onMarkOrderAsNotComplete = async () => {
    if (isEditMode) {
      await updateAndRefetchOrder({
        additionalUpdateFns: [
          {
            fn: markOrderAsNotComplete,
            vars: {
              uuid: orderUuid,
            },
          },
        ],
        onSubmit,
        actionString: 'marking order as not complete',
      });
    }
  };

  const onMarkOrderAsNotRefused = async () => {
    if (isEditMode) {
      setValue('refusedBy', null);
      setValue('refusedDate', null);
      // onSubmit will already set refusedBy and refusedDate to null so there's no need to call a separate update fn
      await updateAndRefetchOrder({
        additionalUpdateFns: [],
        onSubmit,
        actionString: 'marking order as not refused',
      });
    }
  };

  const onMarkOrderAsOnHold = async () => {
    if (!isEmpty(holdReasons)) {
      setShowMarkOrderOnHoldModal(true);
      return;
    }
    setValue('holdReasonUuid', null);
    setValue('holdReasonName', null);
    if (isEditMode) {
      await updateAndRefetchOrder({
        additionalUpdateFns: [
          {
            fn: markOrderAsOnHold,
            vars: {
              markAsOnHoldInput: {
                uuid: orderUuid,
                notes: undefined,
              },
            },
          },
        ],
        onSubmit,
        actionString: 'marking order as on hold',
      });
    } else {
      setValue('detailedStatus', OrderDetailedStatus.OnHold);
      setValue('status', OrderStatus.OnHold);
    }
  };

  const onRemoveOrderHold = async () => {
    if (isEditMode) {
      await updateAndRefetchOrder({
        additionalUpdateFns: [
          {
            fn: removeOrderHold,
            vars: {
              removeOrderHoldInput: {
                uuid: orderUuid,
                sentFromMobileApplication: false,
              },
            },
          },
        ],
        onSubmit,
        actionString: 'removing order hold',
      });
    } else {
      setValue('detailedStatus', OrderDetailedStatus.Creating);
      setValue('status', OrderStatus.Created);
      setValue('holdReasonUuid', null);
      setValue('holdReasonName', null);
    }
  };

  const onCancelOrder = async () => {
    if (isEditMode) {
      await updateAndRefetchOrder({
        additionalUpdateFns: [
          {
            fn: markOrderAsCancelled,
            vars: {
              uuid: orderUuid,
            },
          },
        ],
        onSubmit,
        actionString: 'marking as cancelled',
      });
    }
  };

  const onUncancelOrder = async () => {
    if (isEditMode) {
      await updateAndRefetchOrder({
        additionalUpdateFns: [
          {
            fn: uncancelOrder,
            vars: {
              uuid: orderUuid,
            },
          },
        ],
        onSubmit,
        actionString: 'uncancelling order',
      });
    }
  };

  const updatePaperworkCompletedStatusForStops = async (
    paperworkCompleted: boolean,
  ) => {
    if (!isNil(stops) && isEditMode) {
      await updateAndRefetchOrder({
        additionalUpdateFns: [
          {
            fn: updatePaperworkCompletedForStops,
            vars: {
              updatePaperworkCompleteInput: {
                uuid: orderUuid,
                paperworkCompleted,
              },
            },
          },
        ],
        onSubmit,
        actionString: `marking all paperwork ${
          paperworkCompleted ? 'complete' : 'incomplete'
        }`,
      });
    }
  };

  const orderActionsDisabled = isLoading || !isEditMode || editingDisabled;

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
        open={!isNil(markAsErrorMessage)}
        onClose={() => {
          setMarkAsErrorMessage(undefined);
        }}
      >
        <Alert severity="error">{markAsErrorMessage}</Alert>
      </Snackbar>
      <MarkOrderOnHoldModal
        isEditMode={isEditMode}
        open={showMarkOrderOnHoldModal}
        setOpen={setShowMarkOrderOnHoldModal}
        orderUuid={orderUuid}
        onSave={onSubmit}
      />
      <MarkOrderAsReadyToInvoiceDialog
        handleClose={() => {
          setShowMarkAsReadyToInvoiceDialog(false);
          setShowMarkAsRefusedDialog(false);
        }}
        open={showMarkAsReadyToInvoiceDialog}
        stops={filterNotNil(
          (stops ?? []).map((stop) => {
            if (stop.stopType === StopType.None) {
              return null;
            }
            return {
              uuid: stop.uuid,
              pickupOrDelivery: getPickupOrDelivery(stop.stopType),
              addressName: stop.address?.name ?? '',
              routeDate: stop.routeDate ?? undefined,
              status: stop.status,
              completedAt: stop.completedAt ?? undefined,
              stopType: stop.stopType,
            };
          }),
        )}
        orderUuid={orderUuid}
        markAsRefused={showMarkAsRefusedDialog}
        onSave={onSubmit}
      />
      <MarkOrderAsRefusedDialog
        orderUuid={orderUuid}
        open={showMarkAsRefusedDialog}
        onClose={(data) => {
          setShowMarkAsRefusedDialog(false);
          if (!isNil(data)) {
            setValue('refusedBy', data.refusedBy);
            setValue('refusedDate', data.refusedDate);
          }
        }}
      />
      <Menu
        anchorEl={buttonRef}
        open={showContextMenu}
        onClose={() => {
          setShowContextMenu(false);
        }}
      >
        {/* eslint-disable-next-line no-nested-ternary */}
        {isNil(receivedAtOriginDate) ? (
          canMarkReceivedAtOrigin ? (
            <MenuItem
              disabled={orderActionsDisabled}
              onClick={markReceivedAtOrigin}
            >
              Mark as received at origin
            </MenuItem>
          ) : null
        ) : (
          <MenuItem
            disabled={orderActionsDisabled}
            onClick={markNotReceivedAtOrigin}
          >
            Mark as not received at origin
          </MenuItem>
        )}
        {onHand === true ? (
          <MenuItem disabled={orderActionsDisabled} onClick={markNotOnHand}>
            Mark as not on hand
          </MenuItem>
        ) : (
          <MenuItem disabled={orderActionsDisabled} onClick={markAsOnHand}>
            Mark as on hand
          </MenuItem>
        )}
        <MenuItem
          disabled={
            orderActionsDisabled || onHand === false || !isNil(pickedDate)
          }
          onClick={markAllPiecesAsPicked}
        >
          Mark all pieces as picked
        </MenuItem>
        {canBeMarkedAsOnHold(status) && (
          <MenuItem
            disabled={orderActionsDisabled}
            onClick={osd === true ? onMarkNotOsd : onMarkOsd}
          >
            {osd === true ? 'Mark as not OSD' : 'Mark OSD'}
          </MenuItem>
        )}
        {canBeMarkedAsReadyToInvoice(status) && (
          <Tooltip
            title={
              isNilOrEmptyString(shipperBillOfLadingNumber)
                ? `Must set HAWB to mark as complete`
                : ''
            }
          >
            <span>
              <MenuItem
                disabled={
                  orderActionsDisabled ||
                  isNilOrEmptyString(shipperBillOfLadingNumber)
                }
                data-testid={ORDER_PAGE_MARK_AS_COMPLETE_BUTTON_TEST_ID}
                onClick={() => {
                  onMarkReadyToInvoice(false);
                }}
              >
                Mark as complete
              </MenuItem>
            </span>
          </Tooltip>
        )}
        {isNil(refusedBy) ? (
          <MenuItem
            disabled={orderActionsDisabled}
            onClick={() => {
              setShowMarkAsRefusedDialog(true);
            }}
          >
            Mark as refused
          </MenuItem>
        ) : (
          <MenuItem
            disabled={orderActionsDisabled}
            onClick={onMarkOrderAsNotRefused}
          >
            Mark as not refused
          </MenuItem>
        )}
        {canBeMarkedAsNotComplete && (
          <MenuItem
            disabled={orderActionsDisabled}
            onClick={onMarkOrderAsNotComplete}
          >
            Mark as not completed
          </MenuItem>
        )}
        {canBeMarkedAsOnHold(status) && (
          <MenuItem
            disabled={orderActionsDisabled}
            onClick={onMarkOrderAsOnHold}
          >
            Mark as on hold
          </MenuItem>
        )}
        {status === OrderStatus.OnHold && (
          <MenuItem disabled={orderActionsDisabled} onClick={onRemoveOrderHold}>
            Remove hold
          </MenuItem>
        )}
        {status === OrderStatus.Cancelled ? (
          <MenuItem disabled={orderActionsDisabled} onClick={onUncancelOrder}>
            Uncancel order
          </MenuItem>
        ) : (
          <MenuItem disabled={orderActionsDisabled} onClick={onCancelOrder}>
            Mark as cancelled
          </MenuItem>
        )}
        <MenuItem
          disabled={orderActionsDisabled}
          onClick={() => {
            updatePaperworkCompletedStatusForStops(!paperworkComplete);
          }}
        >
          Mark all paperwork {paperworkComplete ? 'incomplete' : 'complete'}
        </MenuItem>
      </Menu>
    </>
  );
};

export default MarkAsMenu;
