import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { type FunctionComponent, useEffect, useState } from 'react';
import {
  type QueueAppointmentSchedulingProposalsSuccessOutput,
  type StopFragment,
  useQueueAppointmentSchedulingProposalsMutation,
} from '../../../generated/graphql';
import { ConfigureProposals } from './configure-proposals';
import { ProposeTimesResults } from './propose-times-results';

type ProposeTimesDialogProps = {
  readonly open: boolean;
  readonly stops: StopFragment[];
  readonly onClose: () => void;
  readonly onSent: () => void;
};

export const ProposeTimesDialog: FunctionComponent<ProposeTimesDialogProps> = ({
  open,
  stops,
  onClose,
  onSent,
}) => {
  const [results, setResults] =
    useState<QueueAppointmentSchedulingProposalsSuccessOutput | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [enableText, setEnableText] = useState(true);
  const [enableCall, setEnableCall] = useState(false);
  const [queueProposals, { loading: sending }] =
    useQueueAppointmentSchedulingProposalsMutation({
      onCompleted: (data) => {
        if (
          data.queueAppointmentSchedulingProposals.__typename ===
          'QueueAppointmentSchedulingProposalsSuccessOutput'
        ) {
          setResults(data.queueAppointmentSchedulingProposals);
          onSent();
        } else {
          setErrorMessage(data.queueAppointmentSchedulingProposals.message);
        }
      },
    });

  useEffect(() => {
    if (!open) {
      setResults(null);
      setErrorMessage(null);
    }
  }, [open]);

  const onSave = () => {
    setErrorMessage(null);
    queueProposals({
      variables: {
        input: {
          stopUuids: stops.map(({ stop }) => stop.uuid),
          enableText,
          enableCall,
        },
      },
    });
  };

  return (
    <Dialog
      open={open}
      PaperProps={{ sx: { width: '850px', maxWidth: '98vw' } }}
      onClose={onClose}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={2}
      >
        <Typography variant="h4" fontSize="20px" lineHeight={1}>
          Propose times
        </Typography>
        <IconButton
          disabled={sending}
          style={{ width: '30px', height: '30px' }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider />
      {!isNil(errorMessage) && (
        <Typography color="error" mt={2} mx={2}>
          {errorMessage}
        </Typography>
      )}
      <Box maxHeight="80vh" overflow="auto">
        {isNil(results) ? (
          <ConfigureProposals
            stops={stops}
            enableText={enableText}
            setEnableText={setEnableText}
            enableCall={enableCall}
            setEnableCall={setEnableCall}
          />
        ) : (
          <ProposeTimesResults
            stopUuids={results.stopUuids}
            errorMessages={results.errorMessages}
          />
        )}
      </Box>
      <Divider />
      <Stack direction="row" justifyContent="flex-end" p={2} gap={2}>
        <Button
          variant="text"
          color="inherit"
          disabled={sending}
          onClick={onClose}
        >
          {isNil(results) ? 'Cancel' : 'Close'}
        </Button>
        {isNil(results) && (
          <Button variant="contained" disabled={sending} onClick={onSave}>
            Send / call
            {sending && (
              <CircularProgress size={15} sx={{ color: 'inherit', ml: 2 }} />
            )}
          </Button>
        )}
      </Stack>
    </Dialog>
  );
};
