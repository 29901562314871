import InfoIcon from '@mui/icons-material/Info';
import { Stack, Typography, useTheme } from '@mui/material';
import React from 'react';

const InfoBanner = ({ message }: { readonly message: string }) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      width="100%"
      sx={{
        p: '10px',
        backgroundColor: theme.palette.blueBanner.main,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '10px',
      }}
    >
      <InfoIcon sx={{ color: theme.palette.blueBanner.light }} />
      <Typography color={theme.palette.blueBanner.dark}>{message}</Typography>
    </Stack>
  );
};

export default React.memo(InfoBanner);
