import { Box, Tooltip, type TooltipProps, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { type FormattedOrderFragment } from '../../../../generated/graphql';

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    padding: '15px 20px',
    fontSize: '14px',
  },
}));

const TagsCellNew = ({
  order,
}: {
  readonly order: FormattedOrderFragment | undefined;
}) => {
  const tags = order?.tagsField?.orderTableTags ?? [];
  return (
    <Box
      display="flex"
      flexDirection="row"
      gap="5px"
      height="100%"
      minHeight="25px"
      p={0}
    >
      {tags.map((tag) => (
        <BootstrapTooltip key={tag.value} arrow title={tag.value}>
          <Box
            sx={{
              borderRadius: '7.5px',
              backgroundColor: tag.color,
              color: 'white',
              textAlign: 'center',
              whiteSpace: 'nowrap',
              maxWidth: '90px',
              overflowX: 'hidden',
              textOverflow: 'ellipsis',
              width: '12.5px',
            }}
          />
        </BootstrapTooltip>
      ))}
    </Box>
  );
};

export default TagsCellNew;
