import { z } from 'zod';

export const addressSchema = z.object({
  uuid: z.string().uuid(),
  city: z.string().min(1, 'Missing city'),
  country: z.string().optional(),
  line1: z.string().min(1, 'Missing address line 1'),
  line2: z.string().nullish(),
  name: z.string().min(1, 'Missing name'),
  state: z.string().min(1, 'Missing state'),
  zip: z.string().min(1, 'Missing zip'),
  longitude: z.number().nullish(),
  latitude: z.number().nullish(),
  preventCoordRecompute: z.boolean().nullish(),
  specialInstructions: z.string().nullish(),
  internalNotes: z.string().nullish(),
  iataCode: z.string().nullish(),
});

/** @see {addressSchema} with all fields optional except for uuid */
export const addressSchemaWithAllOptionalFields = addressSchema
  .partial()
  .extend({
    uuid: z.string().uuid(),
  });
