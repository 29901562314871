import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  // eslint-disable-next-line no-restricted-imports
  Grid,
} from '@mui/material';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import React, {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { type FieldValues, useFormContext } from 'react-hook-form';
import useContacts from '../../../../../../../common/react-hooks/use-contacts';
import useMe from '../../../../../../../common/react-hooks/use-me';
import useServices from '../../../../../../../common/react-hooks/use-services';
import useTerminals from '../../../../../../../common/react-hooks/use-terminals';
import { OrderDocumentType } from '../../../../../../../generated/graphql';
import GeneratedQuoteAuthoSheetPdf from '../../../../../../generated-documents/components/generated-quote-autho-sheet-pdf';
import {
  convertOrderFormValuesToAuthoSheetData,
  type OrderAuthoSheetData,
} from '../../../../../../generated-documents/utils';
import EmailOrderDocuments from './email-order-documents-component';

const PrintQuoteAuthoSheetModal = ({
  isOpen,
  setIsOpen,
}: {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { companyData } = useMe();
  const { getServiceName } = useServices();
  const { getContactName } = useContacts();
  const { getTerminalCode, terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const { getValues } = useFormContext();
  const order: FieldValues = getValues();

  const orderForAuthoSheet: OrderAuthoSheetData = useMemo(
    () =>
      convertOrderFormValuesToAuthoSheetData({
        order,
        getTerminalCode,
        getContactName,
        getServiceName,
        companyName: companyData?.name,
        companyAddress: companyData?.defaultAddress,
        companyPhone: companyData?.phone,
        companyInvoiceEmail: companyData?.invoiceEmail,
        terminalsEnabled,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order, companyData, terminalsEnabled],
  );

  const fileName = `quote-autho-sheet-${orderForAuthoSheet.quoteNumber}.pdf`;
  // eslint-disable-next-line react/jsx-no-useless-fragment
  const [generatedPdf, setGeneratedPdf] = useState<JSX.Element>(<></>);
  useEffect(() => {
    if (isOpen) {
      setGeneratedPdf(
        <GeneratedQuoteAuthoSheetPdf order={orderForAuthoSheet} />,
      );
    }
  }, [orderForAuthoSheet, isOpen]);

  const close = () => {
    setIsOpen(false);
  };

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={close}>
      <DialogTitle
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        Autho Sheet
      </DialogTitle>
      <DialogContent sx={{ pt: '10px !important' }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <PDFViewer showToolbar style={{ width: '100%', height: '65vh' }}>
              {generatedPdf}
            </PDFViewer>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button variant="contained">
              <PDFDownloadLink document={generatedPdf} fileName={fileName}>
                {({ loading }) => (loading ? 'Loading...' : 'Download')}
              </PDFDownloadLink>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <EmailOrderDocuments
              isQuote
              documentType={OrderDocumentType.AuthoSheet}
              generatedPdf={generatedPdf}
              isOpen={isOpen}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PrintQuoteAuthoSheetModal;
