import { Box } from '@mui/material';
import { captureException } from '@sentry/react';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import { Marker } from 'react-map-gl';
import { type AddressFragment } from '../../../generated/graphql';
import CircleIcon from './components/circle-icon';
import { SameDayDriverPopup } from './components/same-day-driver-popup';
import { SameDayStopPopup } from './components/same-day-stop-popup';
import TruckIcon from './components/truck-icon';

type SameDayDispatchMarkerProps = {
  address: AddressFragment | null;
  appointmentWindow: string | null;
  color: string;
  driverName: string | null;
  icon: 'stop' | 'driver';
  isSelected: boolean;
  lat: number;
  long: number;
  orderName: string | null;
  ordinal?: number;
  setSelected: () => void;
};

/**
 * A marker for both the driver and stops on the same day dispatch page.
 *
 * @param address
 * @param appointmentWindow
 * @param driverName
 * @param color
 * @param icon
 * @param isSelected
 * @param lat
 * @param long
 * @param orderName
 * @param ordinal
 * @param setSelected
 * @constructor
 */
const SameDayDispatchMarker = ({
  address,
  appointmentWindow,
  driverName,
  color,
  icon,
  isSelected,
  lat,
  long,
  orderName,
  ordinal,
  setSelected,
}: SameDayDispatchMarkerProps) => {
  const [showStopPopup, setShowStopPopup] = useState(false);
  const [showDriverPopup, setShowDriverPopup] = useState(false);
  try {
    const markerColor = isSelected ? 'black' : color;
    const strokeColor = 'white';

    return (
      <>
        {showStopPopup &&
          !isNil(address) &&
          !isNil(appointmentWindow) &&
          !isNil(orderName) && (
            <SameDayStopPopup
              address={address}
              appointmentWindow={appointmentWindow}
              latitude={lat}
              longitude={long}
              orderName={orderName}
            />
          )}
        {showDriverPopup && !isNil(driverName) && (
          <SameDayDriverPopup
            driverName={driverName}
            latitude={lat}
            longitude={long}
          />
        )}
        <Box
          onClick={setSelected}
          onMouseEnter={() => {
            if (icon === 'stop') {
              setShowStopPopup(true);
            } else if (icon === 'driver') {
              setShowDriverPopup(true);
            }
          }}
          onMouseLeave={() => {
            if (icon === 'stop') {
              setShowStopPopup(false);
            } else if (icon === 'driver') {
              setShowDriverPopup(false);
            }
          }}
        >
          <Marker longitude={long} latitude={lat} anchor="bottom">
            {icon === 'stop' ? (
              <CircleIcon
                color={markerColor}
                diameterPx={isSelected ? '20px' : '17px'}
                strokeColor={strokeColor}
                text={ordinal?.toString()}
                style={{ cursor: 'pointer' }}
              />
            ) : (
              <TruckIcon
                color={markerColor}
                diameterPx={isSelected ? '25px' : '20px'}
              />
            )}
          </Marker>
        </Box>
      </>
    );
  } catch (error) {
    captureException(error);
    return null;
  }
};

export default SameDayDispatchMarker;
