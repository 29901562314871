import {
  Document,
  Page,
  StyleSheet,
  Text,
  View,
  Image,
  Font,
} from '@react-pdf/renderer';
import { type CustomDriverFormFragment } from '../../../generated/graphql';
import { type DocViewerDocument } from '../types/doc-viewer-document';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-regular-webfont.ttf',
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-bold-webfont.ttf',
      fontWeight: 700,
    },
  ],
});

const GeneratedCustomFormPdf = ({
  signatureDocument,
  driverFormTemplate,
}: {
  readonly signatureDocument: DocViewerDocument;
  readonly driverFormTemplate: CustomDriverFormFragment;
}) => {
  const styles = StyleSheet.create({
    page: {
      padding: 24,
      fontFamily: 'Roboto',
      fontSize: '12px',
      flexDirection: 'column',
    },
    titleBold: {
      fontSize: '24px',
      fontWeight: 600,
      marginBottom: '20px',
    },
  });

  return (
    <Document title={driverFormTemplate.title}>
      <Page size="LETTER" style={styles.page}>
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            marginTop: '32px',
            marginBottom: '20px',
            alignItems: 'center',
          }}
        >
          <Text style={styles.titleBold}>{driverFormTemplate.title}</Text>

          <Text>{driverFormTemplate.formText}</Text>
        </View>
        <View
          style={{
            alignSelf: 'flex-start',
          }}
        >
          <Text>Signature:</Text>
        </View>

        <View
          style={{
            width: '33%',
            alignSelf: 'flex-start',
            borderTop: '1px solid #000',
          }}
        >
          <Image src={signatureDocument.preSignedGetUrl} />
        </View>
      </Page>
    </Document>
  );
};

export default GeneratedCustomFormPdf;
