import { type SxProps, Typography } from '@mui/material';
import { type FunctionComponent } from 'react';

type OrderFormCardTitleProps = {
  readonly title: string;
  readonly sx?: SxProps;
};

export const OrderFormCardTitle: FunctionComponent<OrderFormCardTitleProps> = ({
  title,
  sx,
}) => (
  <Typography
    variant="h6"
    sx={{ marginBottom: '5px', fontSize: '16px', ...sx }}
    data-testid="order-form-card-title"
  >
    {title}
  </Typography>
);
