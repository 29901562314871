import { isNil } from 'lodash';
import { useMemo } from 'react';
import { shallow } from 'zustand/shallow';
import { type RouteFragment, StopStatus } from '../../../generated/graphql';
import useDispatchStore from '../dispatch-store';
import { type TableStopOnRoute } from '../routes/route-card-stops-list-columns';
import { useDispatchViewSettings } from './use-dispatch-view-settings';

const useStopsOnRoute = ({ route }: { route: RouteFragment }) => {
  const { showCompleteStops } = useDispatchViewSettings();
  const [stopTypeFilter] = useDispatchStore(
    (state) => [state.stopTypeFilter],
    shallow,
  );

  const stopsData = useMemo<TableStopOnRoute[]>(() => {
    const data: TableStopOnRoute[] = [];
    let index = 0;
    for (const slot of route.slots) {
      const stop = slot.stops[0];
      if (
        !isNil(stop) &&
        (showCompleteStops || stop.status !== StopStatus.Completed) &&
        (isNil(stopTypeFilter) || stop.stopType === stopTypeFilter)
      ) {
        data.push({ ...stop, index });
        // eslint-disable-next-line no-plusplus
        ++index;
      }
    }
    return data;
  }, [route.slots, stopTypeFilter, showCompleteStops]);

  return stopsData;
};

export { useStopsOnRoute };
