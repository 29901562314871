import { type ApolloQueryResult } from '@apollo/client/core/types';
import styled from '@emotion/styled';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Dialog,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Paper,
  Stack,
  Tab,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { type Dictionary, isEmpty, isNil, keyBy } from 'lodash';
import React, {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, type SubmitHandler } from 'react-hook-form';
import { getPermissionsFlags } from 'shared/roles';
import { isNilOrEmptyString } from 'shared/string';
import { PaddingTabPanel } from '../../../common/components/tab-panel';
import { FeatureFlag } from '../../../common/feature-flags';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import useMe from '../../../common/react-hooks/use-me';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  AccessLevel,
  type Exact,
  type FindRoleInput,
  type FindRolePermissionFragment,
  PermissionResource,
  type RoleQuery,
  RolesDocument,
  useCreateRoleMutation,
  useRoleQuery,
  useUpdateRoleMutation,
} from '../../../generated/graphql';
import CreateOrEdit from '../../management/enums/create-or-edit';
import PermissionRow from '../../permissions/components/permission-row';
import {
  ACCOUNTING_PERMISSION_RESOURCES,
  COMPANY_PERMISSION_RESOURCES,
  CONTACTS_PERMISSION_RESOURCES,
  DISPATCH_PERMISSION_RESOURCES,
  GENERAL_PERMISSION_RESOURCES,
  ORDERS_PERMISSION_RESOURCES,
  PERMISSIONS_DEPENDENT_ON_BILLING_REVIEW_WRITE,
  PERMISSIONS_DEPENDENT_ON_FINALIZE_CHARGES_WRITE,
  REPORTS_PERMISSION_RESOURCES,
  SAVED_VIEWS_PERMISSION_RESOURCES,
  SETTINGS_PERMISSION_RESOURCES,
  WAREHOUSE_PERMISSION_RESOURCES,
} from '../../permissions/constants';
import useRoleForm, { type RoleFormValues } from '../forms/use-role-form';
import AddUserRow from './users/add-user-row';
import UserRow from './users/user-row';

const RolesModalTabPanel = styled(PaddingTabPanel)`
  min-height: 550px;
  max-height: 550px;
  width: 100%;
  overflow-y: scroll;
`;

type RolePermissionsSectionProps = {
  readonly name: string;
  readonly roleUuid: string;
  readonly getDisabledPermissionProps: (
    permissionResource: PermissionResource,
  ) => {
    disabled: boolean;
    disabledReason: string | null;
  };
  readonly refetchRole: (
    variables?: Partial<Exact<{ findRoleInput?: FindRoleInput | undefined }>>,
  ) => Promise<ApolloQueryResult<RoleQuery>>;
  readonly permissionsByResource: Dictionary<FindRolePermissionFragment>;
  readonly permissionResources: PermissionResource[];
};

const RolePermissionsSection = ({
  name,
  roleUuid,
  getDisabledPermissionProps,
  refetchRole,
  permissionsByResource,
  permissionResources,
}: RolePermissionsSectionProps) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <strong>{name}</strong>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer>
          <Table>
            <TableHead>
              <TableCell>Resource</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Access Level</TableCell>
            </TableHead>
            {permissionResources.map((permissionResource) => {
              const retrievedPermission =
                permissionsByResource?.[permissionResource];
              const { disabled, disabledReason } =
                getDisabledPermissionProps(permissionResource);
              return (
                <PermissionRow
                  key={permissionResource}
                  roleUuid={roleUuid}
                  permissionResource={permissionResource}
                  permission={retrievedPermission}
                  refetchRole={refetchRole}
                  disabled={disabled}
                  disabledReason={disabledReason}
                />
              );
            })}
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

type RolesModalProps = {
  readonly createOrEdit: CreateOrEdit;

  readonly setCreateOrEdit: Dispatch<SetStateAction<CreateOrEdit>>;
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
  readonly selectedRoleUuid: string | undefined;

  readonly setSelectedRoleUuid: Dispatch<SetStateAction<string | undefined>>;

  readonly setSuccessSnackbarVisible: Dispatch<SetStateAction<boolean>>;
  readonly setErrorSnackbarVisible: Dispatch<SetStateAction<boolean>>;
};

const RolesModal = ({
  createOrEdit,
  setCreateOrEdit,
  open,
  setOpen,
  selectedRoleUuid,
  setSelectedRoleUuid,
  setSuccessSnackbarVisible,
  setErrorSnackbarVisible,
}: RolesModalProps) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const { userPermissions } = useUserRoles();
  const { hasMasterPermission } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Master,
  );

  const ffEnableNewTableFunctions = useFeatureFlag(
    FeatureFlag.FF_ENABLE_NEW_TABLE_FUNCTIONS,
  );

  const { companyConfiguration } = useMe();

  const {
    data: roleData,
    refetch: refetchRole,
    loading: roleLoading,
  } = useRoleQuery(
    isNilOrEmptyString(selectedRoleUuid)
      ? { skip: true }
      : { variables: { findRoleInput: { uuid: selectedRoleUuid } } },
  );

  const role = roleData?.role?.role;
  const roleUuid = role?.uuid;
  const roleUsers = role?.users;

  const roleCanWritePermissionResource = (
    permissionResource: PermissionResource,
  ) =>
    role?.permissions.some(
      (permission) =>
        permission.accessLevel === AccessLevel.Write &&
        permission.permissionResource === permissionResource,
    ) ?? false;

  const roleCanWriteBillingReview = roleCanWritePermissionResource(
    PermissionResource.BillingReview,
  );
  const roleCanWriteFinalizeCharges = roleCanWritePermissionResource(
    PermissionResource.FinalizeChargesOrders,
  );

  const [createRole, { loading: createRoleLoading }] = useCreateRoleMutation({
    refetchQueries: [RolesDocument],
  });
  const [updateRole, { loading: updateRoleLoading }] = useUpdateRoleMutation({
    refetchQueries: [RolesDocument],
  });

  const [isAddingUser, setIsAddingUser] = useState(false);

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useRoleForm();

  const name = watch('name');

  useEffect(() => {
    if (!isNil(role)) {
      reset({
        name: role.name,
      });
    }
  }, [reset, role]);

  const handleClose = () => {
    reset({
      name: '',
    });
    setSelectedRoleUuid(undefined);
    setOpen(false);
  };

  const onSubmit: SubmitHandler<RoleFormValues> = async (data) => {
    const { name: formName } = data;
    try {
      if (createOrEdit === CreateOrEdit.Create) {
        const resp = await createRole({
          variables: {
            createRoleInput: {
              name: formName,
              isInternalRole: false,
            },
          },
        });
        const uuid = resp.data?.createRole?.role?.uuid;
        if (isNil(uuid)) {
          setErrorSnackbarVisible(true);
        } else {
          setSuccessSnackbarVisible(true);
          setCreateOrEdit(CreateOrEdit.Edit);
          setSelectedRoleUuid(uuid);
        }
      } else {
        if (isNil(role)) {
          return;
        }
        const { uuid } = role;
        const resp = await updateRole({
          variables: {
            updateRoleInput: {
              uuid,
              name,
              isInternalRole: false,
            },
          },
        });
        if (resp?.data?.updateRole?.success === true) {
          setSuccessSnackbarVisible(true);
          handleClose();
        } else {
          setErrorSnackbarVisible(true);
        }
      }
    } catch {
      setErrorSnackbarVisible(true);
    }
  };

  const permissionsByResource = useMemo(() => {
    if (!isNil(role)) {
      return keyBy(role.permissions, 'permissionResource');
    }
    return {};
  }, [role]);

  const getDisabledPermissionProps = useCallback(
    (permissionResource: PermissionResource) => {
      if (
        !roleCanWriteBillingReview &&
        PERMISSIONS_DEPENDENT_ON_BILLING_REVIEW_WRITE.includes(
          permissionResource,
        )
      ) {
        return {
          disabled: true,
          disabledReason:
            'Modifying this permission requires write access to Billing Review',
        };
      }
      if (
        !roleCanWriteFinalizeCharges &&
        PERMISSIONS_DEPENDENT_ON_FINALIZE_CHARGES_WRITE.includes(
          permissionResource,
        )
      ) {
        return {
          disabled: true,
          disabledReason:
            'Modifying this permission requires write access to Finalize order charges',
        };
      }
      return { disabled: false, disabledReason: null };
    },
    [roleCanWriteBillingReview, roleCanWriteFinalizeCharges],
  );

  const body = roleLoading ? (
    <Grid
      item
      xs={12}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <>
      <Grid item xs={3}>
        <Controller
          name="name"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              required
              name="name"
              size="small"
              label="Name"
              value={value}
              error={!isNil(errors.name)}
              helperText={errors.name?.message}
              sx={{ width: '250px' }}
              onChange={onChange}
            />
          )}
        />
      </Grid>
      {!isNil(roleUuid) && createOrEdit === CreateOrEdit.Edit && (
        <Grid item xs={12}>
          <Tabs
            value={selectedTab}
            onChange={(e, newValue) => {
              setSelectedTab(newValue);
            }}
          >
            <Tab label="Permissions" />
            <Tab label="Users" />
          </Tabs>
          <RolesModalTabPanel panelValue={0} selectedValue={selectedTab}>
            {hasMasterPermission && (
              <RolePermissionsSection
                name="General"
                roleUuid={roleUuid}
                getDisabledPermissionProps={getDisabledPermissionProps}
                refetchRole={refetchRole}
                permissionsByResource={permissionsByResource}
                permissionResources={GENERAL_PERMISSION_RESOURCES}
              />
            )}
            <RolePermissionsSection
              name="Orders"
              roleUuid={roleUuid}
              getDisabledPermissionProps={getDisabledPermissionProps}
              refetchRole={refetchRole}
              permissionsByResource={permissionsByResource}
              permissionResources={ORDERS_PERMISSION_RESOURCES}
            />
            <RolePermissionsSection
              name="Dispatch"
              roleUuid={roleUuid}
              getDisabledPermissionProps={getDisabledPermissionProps}
              refetchRole={refetchRole}
              permissionsByResource={permissionsByResource}
              permissionResources={DISPATCH_PERMISSION_RESOURCES}
            />
            <RolePermissionsSection
              name="Accounting"
              roleUuid={roleUuid}
              getDisabledPermissionProps={getDisabledPermissionProps}
              refetchRole={refetchRole}
              permissionsByResource={permissionsByResource}
              permissionResources={ACCOUNTING_PERMISSION_RESOURCES}
            />
            {ffEnableNewTableFunctions && (
              <RolePermissionsSection
                name="Saved Views"
                roleUuid={roleUuid}
                getDisabledPermissionProps={getDisabledPermissionProps}
                refetchRole={refetchRole}
                permissionsByResource={permissionsByResource}
                permissionResources={SAVED_VIEWS_PERMISSION_RESOURCES}
              />
            )}
            <RolePermissionsSection
              name="Reports"
              roleUuid={roleUuid}
              getDisabledPermissionProps={getDisabledPermissionProps}
              refetchRole={refetchRole}
              permissionsByResource={permissionsByResource}
              permissionResources={REPORTS_PERMISSION_RESOURCES}
            />
            <RolePermissionsSection
              name="Contacts"
              roleUuid={roleUuid}
              getDisabledPermissionProps={getDisabledPermissionProps}
              refetchRole={refetchRole}
              permissionsByResource={permissionsByResource}
              permissionResources={CONTACTS_PERMISSION_RESOURCES}
            />
            {companyConfiguration?.wmsEnabled === true && (
              <RolePermissionsSection
                name="Warehouse"
                roleUuid={roleUuid}
                getDisabledPermissionProps={getDisabledPermissionProps}
                refetchRole={refetchRole}
                permissionsByResource={permissionsByResource}
                permissionResources={WAREHOUSE_PERMISSION_RESOURCES}
              />
            )}
            <RolePermissionsSection
              name="Company"
              roleUuid={roleUuid}
              getDisabledPermissionProps={getDisabledPermissionProps}
              refetchRole={refetchRole}
              permissionsByResource={permissionsByResource}
              permissionResources={COMPANY_PERMISSION_RESOURCES}
            />
            <RolePermissionsSection
              name="Settings"
              roleUuid={roleUuid}
              getDisabledPermissionProps={getDisabledPermissionProps}
              refetchRole={refetchRole}
              permissionsByResource={permissionsByResource}
              permissionResources={SETTINGS_PERMISSION_RESOURCES}
            />
          </RolesModalTabPanel>
          <RolesModalTabPanel panelValue={1} selectedValue={selectedTab}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Button
                  size="small"
                  variant="outlined"
                  disabled={isAddingUser}
                  onClick={() => {
                    setIsAddingUser(true);
                  }}
                >
                  Add
                </Button>
              </Grid>
              {isAddingUser && !isNil(roleUuid) && (
                <Grid item xs={12}>
                  <AddUserRow
                    roleUuid={roleUuid}
                    setIsEditing={setIsAddingUser}
                    refetchRole={refetchRole}
                    setSuccessSnackbarVisible={setSuccessSnackbarVisible}
                    setErrorSnackbarVisible={setErrorSnackbarVisible}
                  />
                </Grid>
              )}
              {!isEmpty(roleUsers) && (
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableCell>Email</TableCell>
                        <TableCell />
                      </TableHead>
                      {!isNil(roleUuid) &&
                        roleUsers?.map((user) => {
                          return (
                            <UserRow
                              key={user.uuid}
                              roleUuid={roleUuid}
                              user={user}
                              refetchRole={refetchRole}
                              setSuccessSnackbarVisible={
                                setSuccessSnackbarVisible
                              }
                              setErrorSnackbarVisible={setErrorSnackbarVisible}
                            />
                          );
                        })}
                    </Table>
                  </TableContainer>
                </Grid>
              )}
            </Grid>
          </RolesModalTabPanel>
        </Grid>
      )}
      <Grid item xs={12}>
        <Stack
          direction="row"
          alignItems="flex-end"
          justifyContent="right"
          gap={1}
        >
          <Button
            variant="outlined"
            disabled={createRoleLoading || updateRoleLoading}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={createRoleLoading || updateRoleLoading}
            onClick={handleSubmit(onSubmit)}
          >
            Confirm
          </Button>
        </Stack>
      </Grid>
    </>
  );

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        <Grid item xs={12}>
          <Typography variant="h6">{createOrEdit} Role</Typography>
        </Grid>
        {body}
      </Grid>
    </Dialog>
  );
};

export default RolesModal;
