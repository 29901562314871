import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {
  IconButton,
  MenuItem,
  Menu,
  Button,
  Stack,
  useTheme,
} from '@mui/material';
import { differenceBy, isEmpty } from 'lodash';
import React, { useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { ColoredChip } from '../../../../../common/components/colored-chip';
import useTags from '../../../../../common/react-hooks/use-tags';
import { Size } from '../../../../../common/types';
import { useOrderFormEditAccess } from '../contexts/order-form-edit-access-context';
import { type OrderFormValues } from '../forms/types';

const Tags = () => {
  const theme = useTheme();

  const { tags: tagsList } = useTags();
  const { setValue, control } = useFormContext<OrderFormValues>();
  const tags = useWatch({ control, name: 'tags' }) ?? [];
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [showContextMenu, setShowContextMenu] = useState(false);

  const { disabledIfInvoicePosted: disabled } = useOrderFormEditAccess();

  if (isEmpty(tagsList)) {
    return null;
  }

  const tagOptions = differenceBy(tagsList, tags, ({ uuid }) => uuid);

  return (
    <>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '15px',
          flexWrap: 'wrap',
        }}
      >
        {tags.map((tag) => (
          <Stack key={tag.uuid} direction="row" alignItems="center">
            <ColoredChip
              size={Size.sm}
              label={tag.value ?? ''}
              color={tag.color ?? 'black'}
            />
            {!disabled && (
              <IconButton
                size="small"
                onClick={() => {
                  setValue(
                    'tags',
                    tags.filter((t) => t.uuid !== tag.uuid),
                  );
                }}
              >
                <CloseIcon sx={{ fontSize: '20px' }} />
              </IconButton>
            )}
          </Stack>
        ))}
        <Button
          ref={buttonRef}
          variant="contained"
          disabled={disabled || tagOptions.length === 0}
          startIcon={<AddIcon />}
          sx={{
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.default,
            boxShadow: 'none',
            '&:hover': {
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.hoverColor.main,
              boxShadow: 'none',
            },
          }}
          onClick={() => {
            setShowContextMenu(!showContextMenu);
          }}
        >
          Tag
        </Button>
      </Stack>
      <Menu
        anchorEl={buttonRef.current}
        open={showContextMenu}
        onClose={() => {
          setShowContextMenu(false);
        }}
      >
        {tagOptions.map((tag) => (
          <MenuItem
            key={tag.uuid}
            onClick={() => {
              setValue('tags', [...tags, tag]);
              setShowContextMenu(false);
            }}
          >
            {tag.value}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default React.memo(Tags);
