import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useHoldReasons from '../../../../common/react-hooks/use-hold-reasons';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import {
  type HoldReasonFragment,
  HoldReasonsDocument,
  PermissionResource,
  useUpdateHoldReasonMutation,
} from '../../../../generated/graphql';
import { ArchiveableEntity } from '../common/archive-action-component';
import ArchiveEntityModal from '../common/archive-entity-modal';

type HoldReasonRowProps = {
  readonly holdReason: HoldReasonFragment;
};

const HoldReasonRow = ({ holdReason }: HoldReasonRowProps) => {
  const { refetchHoldReasons } = useHoldReasons();
  const { userPermissions } = useUserRoles();

  const { canWrite: canWriteHoldReasons } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyHoldReasons,
  );

  const [updateHoldReason] = useUpdateHoldReasonMutation({
    refetchQueries: [HoldReasonsDocument],
  });
  const [isHovering, setIsHovering] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [nameInput, setNameInput] = useState(holdReason.name);
  const [showArchiveModal, setShowArchiveModal] = useState<boolean>(false);

  const update = async () => {
    if (!isEmpty(nameInput)) {
      await updateHoldReason({
        variables: {
          holdReasonUpdateInput: {
            uuid: holdReason.uuid,
            name: nameInput,
          },
        },
      });
      refetchHoldReasons();
    }
  };

  const onArchiveOrUnarchive = async () => {
    await updateHoldReason({
      variables: {
        holdReasonUpdateInput: {
          uuid: holdReason.uuid,
          isArchived: holdReason.isArchived !== true,
        },
      },
    });
  };

  return (
    <TableRow
      hover
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
      }}
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <ArchiveEntityModal
        entityType={ArchiveableEntity.HOLD_REASON}
        entityIdentifier={holdReason.name}
        open={showArchiveModal}
        setOpen={setShowArchiveModal}
        isCurrentlyArchived={holdReason.isArchived === true}
        handleArchive={onArchiveOrUnarchive}
        handleUnarchive={onArchiveOrUnarchive}
      />
      <TableCell>
        <Stack direction="row" alignItems="center" spacing={2}>
          {showInput ? (
            <TextField
              required
              size="small"
              label="Name"
              value={nameInput}
              disabled={!canWriteHoldReasons}
              onChange={(e) => {
                setNameInput(e.target.value);
              }}
              onBlur={() => {
                update();
                setShowInput(false);
              }}
            />
          ) : (
            <>
              <Typography>{nameInput}</Typography>
              {isHovering && (
                <IconButton
                  disabled={!canWriteHoldReasons}
                  onClick={() => {
                    setShowInput(true);
                  }}
                >
                  <EditIcon />
                </IconButton>
              )}
            </>
          )}
        </Stack>
      </TableCell>
      <TableCell align="right">
        <Button
          sx={{ my: 1 }}
          size="small"
          variant="contained"
          color="error"
          onClick={() => {
            setShowArchiveModal(true);
          }}
        >
          {holdReason.isArchived === true ? 'Unarchive' : 'Archive'}
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default HoldReasonRow;
