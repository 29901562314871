import {
  MenuItem,
  Select,
  TableCell,
  TableRow,
  InputLabel,
  FormControl,
} from '@mui/material';
import currency from 'currency.js';
import { isNil } from 'lodash';
import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { safeMultiply, safeDivide } from 'shared/math';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../../../../../common/react-hooks/use-user-roles';
import { BillingMethod } from '../../../../../../../common/types';
import { PermissionResource } from '../../../../../../../generated/graphql';
import {
  type FuelChargeValues,
  type OrderFormValues,
} from '../../../forms/types';
import { type OrderShipmentContext } from '../../../types';
import { contextToNamePrefix } from '../../../utils';
import { DriverSettlementFuelChargeRateField } from './driver-settlement-fuel-charge-rate-field';

const DriverSettlementFuelChargeRowImpl = ({
  namePrefix,
  fuelCharge,
}: {
  readonly namePrefix: `stops.${number}` | `orderChargesShipment`;
  readonly fuelCharge: FuelChargeValues;
}) => {
  const { userPermissions } = useUserRoles();

  const { canWrite: canWriteOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Orders,
  );

  const { setValue } = useFormContext<OrderFormValues>();

  const billingMethod =
    fuelCharge.settlementBillingMethod ?? BillingMethod.Percentage;

  useEffect(() => {
    if (billingMethod === BillingMethod.FlatRate) {
      setValue(
        `${namePrefix}.freightCharge.fuelCharge.deductionTotal`,
        fuelCharge.settlementFlatRate ?? 0,
      );
    }
    if (billingMethod === BillingMethod.Percentage) {
      setValue(
        `${namePrefix}.freightCharge.fuelCharge.deductionTotal`,
        safeMultiply(
          safeDivide(fuelCharge.settlementPercentageRate ?? 0, 100, 5),
          fuelCharge.totalCharge ?? 0,
          5,
        ),
      );
    }
  }, [
    billingMethod,
    setValue,
    namePrefix,
    fuelCharge.settlementFlatRate,
    fuelCharge.settlementPercentageRate,
    fuelCharge.totalCharge,
  ]);

  return (
    <TableRow>
      <TableCell>
        <FormControl>
          <InputLabel>Fuel charge</InputLabel>
          <Select
            label="Fuel charge"
            value={billingMethod}
            size="small"
            disabled={!canWriteOrders}
            onChange={(e) => {
              if (e.target.value === BillingMethod.FlatRate) {
                setValue(
                  `${namePrefix}.freightCharge.fuelCharge.settlementPercentageRate`,
                  null,
                );
                setValue(
                  `${namePrefix}.freightCharge.fuelCharge.settlementFlatRate`,
                  0,
                );
                setValue(
                  `${namePrefix}.freightCharge.fuelCharge.settlementBillingMethod`,
                  BillingMethod.FlatRate,
                );
              }
              if (e.target.value === BillingMethod.Percentage) {
                setValue(
                  `${namePrefix}.freightCharge.fuelCharge.settlementPercentageRate`,
                  0,
                );
                setValue(
                  `${namePrefix}.freightCharge.fuelCharge.settlementFlatRate`,
                  null,
                );
                setValue(
                  `${namePrefix}.freightCharge.fuelCharge.settlementBillingMethod`,
                  BillingMethod.Percentage,
                );
              }
            }}
          >
            <MenuItem value={BillingMethod.FlatRate}>Flat rate</MenuItem>
            <MenuItem value={BillingMethod.Percentage}>Percentage</MenuItem>
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>
        <DriverSettlementFuelChargeRateField
          namePrefix={namePrefix}
          disabled={!canWriteOrders}
        />
      </TableCell>
      <TableCell>{currency(fuelCharge.deductionTotal ?? 0).format()}</TableCell>
    </TableRow>
  );
};

const DriverSettlementFuelChargeRow = ({
  context,
}: {
  readonly context: OrderShipmentContext;
}) => {
  const namePrefix = contextToNamePrefix(context);
  const { control } = useFormContext<OrderFormValues>();
  const fuelCharge = useWatch({
    control,
    name: `${namePrefix}.freightCharge.fuelCharge`,
  });

  if (isNil(fuelCharge)) {
    return null;
  }

  return (
    <DriverSettlementFuelChargeRowImpl
      namePrefix={namePrefix}
      fuelCharge={fuelCharge}
    />
  );
};

export default React.memo(DriverSettlementFuelChargeRow);
