import { Description } from '@mui/icons-material';
// eslint-disable-next-line no-restricted-imports
import { Card, CardContent, Grid, styled, Typography } from '@mui/material';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import { useCustomDriverSignableFormQuery } from '../../../generated/graphql';
import useStyles from '../end-of-day-styles';
import { type DocViewerDocument } from '../types/doc-viewer-document';
import CustomFormDocumentViewerModal from './custom-form-document-modal';

type AllDocumentsViewerProps = {
  readonly doc: DocViewerDocument;
  readonly driverFormTemplateUuid: string;
  readonly colSize?: number;
  readonly cardHeight?: string;
};

const CardContentSpecialPadding = styled(CardContent)(`
  padding: 10px;
`);

const CustomFormDocumentViewer = ({
  doc,
  driverFormTemplateUuid,
  colSize = 2,
  cardHeight = '180px',
}: AllDocumentsViewerProps) => {
  const styles = useStyles();
  const [showDocViewerModal, setShowDocViewerModal] = useState<boolean>(false);

  const { data: driverFormTemplateData } = useCustomDriverSignableFormQuery({
    variables: {
      uuid: driverFormTemplateUuid,
    },
  });

  const handleOpenModal = () => {
    setShowDocViewerModal(true);
  };

  const formTemplate = driverFormTemplateData?.customDriverForm;

  if (isNil(formTemplate)) {
    return null;
  }
  return (
    <Grid key={doc.uuid} item xs={colSize}>
      <Card
        variant="outlined"
        sx={[styles.cardHover, { height: cardHeight }]}
        onClick={() => {
          handleOpenModal();
        }}
      >
        <CardContentSpecialPadding>
          <Grid container direction="column" gap="10px">
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: '10px',
                  color: 'gray',
                  marginBottom: '5px',
                }}
              >
                {formTemplate.title}
              </Typography>
            </Grid>
            <Grid item alignSelf="center">
              <Description sx={{ fontSize: '60px', color: 'text.secondary' }} />
            </Grid>
          </Grid>
        </CardContentSpecialPadding>
      </Card>
      <CustomFormDocumentViewerModal
        showDocViewerModal={showDocViewerModal}
        setShowDocViewerModal={setShowDocViewerModal}
        doc={doc}
        driverFormTemplate={formTemplate}
      />
    </Grid>
  );
};

export default CustomFormDocumentViewer;
