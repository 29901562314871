import { TextField } from '@mui/material';
import { sentenceCase } from 'change-case';
import { isEmpty, isNil } from 'lodash';
import React from 'react';
import {
  ORDER_PAGE_SERVICE_SELECT_INPUT_TEST_ID,
  ORDER_PAGE_SERVICE_SELECT_TEST_ID,
} from '../../../constants';
import {
  type ShallowContactFragment,
  useShallowContactQuery,
} from '../../generated/graphql';
import AutocompleteFuzzy from '../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';

import useServices from '../react-hooks/use-services';

const MINIMIZED_FONT_SIZE = 12;

const ServiceLevelAutocompleteComponent = ({
  contactUuid,
  contact,
  isMinimized = false,
  selectedServiceUuid,
  onChange,
  disableClearable = false,
  width,
  disabled,
  error,
  label,
  required,
}: {
  readonly contactUuid: string | undefined;
  readonly contact: ShallowContactFragment | undefined;
  readonly isMinimized?: boolean;
  readonly selectedServiceUuid: string | null;
  readonly onChange: (
    service: ShallowContactFragment['services'][0] | null,
  ) => void;
  readonly disableClearable?: boolean;
  readonly width?: number;
  readonly disabled?: boolean;
  readonly error?: boolean;
  readonly label?: string;
  readonly required?: boolean;
}) => {
  const { services } = useServices();

  const { data: contactData } = useShallowContactQuery(
    !isNil(contact) || isNil(contactUuid)
      ? {
          skip: true,
        }
      : {
          variables: {
            uuid: contactUuid,
          },
        },
  );

  const contactServices = contact?.services ?? contactData?.contact?.services;

  const serviceLevels = isEmpty(contactServices) ? services : contactServices;

  const serviceLevelOptions =
    serviceLevels
      ?.map((service) => ({
        value: service.uuid,
        label: sentenceCase(service.name),
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) ?? [];

  return (
    <AutocompleteFuzzy
      size="small"
      disabled={disabled}
      disableClearable={disableClearable}
      data-testid={ORDER_PAGE_SERVICE_SELECT_TEST_ID}
      value={
        isNil(selectedServiceUuid)
          ? null
          : {
              value: selectedServiceUuid,
              label:
                serviceLevelOptions.find(
                  (service) => service.value === selectedServiceUuid,
                )?.label ?? '',
            }
      }
      sx={{ width }}
      matchSortOptions={{ keys: ['label'] }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      options={serviceLevelOptions}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField
          className={isMinimized ? 'minimized-autocomplete' : undefined}
          {...params}
          label={label}
          inputProps={{
            ...params.inputProps,
            'data-testid': ORDER_PAGE_SERVICE_SELECT_INPUT_TEST_ID,
          }}
          required={required}
          error={error}
        />
      )}
      ListboxProps={isMinimized ? { style: { maxHeight: 300 } } : {}}
      renderOption={(props, option) => (
        <li
          {...props}
          key={option.value}
          style={
            isMinimized
              ? {
                  fontSize: MINIMIZED_FONT_SIZE,
                  paddingLeft: 10,
                  paddingRight: 10,
                }
              : {}
          }
        >
          {option.label}
        </li>
      )}
      onChange={(_event, option) => {
        const serviceLevel = serviceLevels?.find(
          (s) => s.uuid === option?.value,
        );
        onChange(serviceLevel ?? null);
      }}
    />
  );
};

export const ServiceLevelAutocomplete = React.memo(
  ServiceLevelAutocompleteComponent,
);
