import { isNil } from 'lodash';
import { filterNotNil } from 'shared/array';
import { useShallow } from 'zustand/react/shallow';
import { type FilterViewPage } from '../../generated/graphql';
import useFilterStore from './filter-store';
import { type OrderTableFilterModel } from './orders/types';
import {
  isLegacyFilterModel,
  isLegacyFilterModelValue,
  migrateLegacyFilterModelKeys,
} from './orders/utils';
import { useNewTableFunctionsFeatureFlag } from './use-new-table-functions-feature-flag';

const useRememberedOrdersFilters: (
  pageType: FilterViewPage,
) => OrderTableFilterModel | null = (pageType) => {
  const { ffEnableNewTableFunctions } =
    useNewTableFunctionsFeatureFlag(pageType);
  const allRememberedFiltersRaw = useFilterStore(
    useShallow((state) => state.filters),
  );
  let rememberedFilters = JSON.parse(
    allRememberedFiltersRaw[pageType] ?? 'null',
  ) as OrderTableFilterModel | null;

  if (!ffEnableNewTableFunctions || isNil(rememberedFilters)) {
    return rememberedFilters;
  }

  if (isLegacyFilterModel(rememberedFilters)) {
    // Even if the feature flag is on, existing views in the DB
    // or local storage might not have been migrated
    rememberedFilters = migrateLegacyFilterModelKeys(rememberedFilters);
  }

  // Drop any legacy filter model values
  rememberedFilters = Object.fromEntries(
    filterNotNil(
      Object.entries(rememberedFilters).map(([key, value]) =>
        isLegacyFilterModelValue(value) ? null : [key, value],
      ),
    ),
  );
  return rememberedFilters;
};

export { useRememberedOrdersFilters };
