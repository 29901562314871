import { Document, Page, Text, View } from '@react-pdf/renderer';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import { safeDivide } from 'shared/math';
import {
  type CompanyFragment,
  ReportType,
} from '../../../../generated/graphql';
import { type ReportDateFilterType } from '../../../invoices/components/invoices/download/components/report-date-filter-type-button';
import { getDateRangeLabel } from '../../report-group-configuration-converter';
import {
  CUSTOMER_HEAD_CELLS,
  DRIVER_HEAD_CELLS,
  ORDER_HEAD_CELLS,
  SERVICE_LEVEL_HEAD_CELLS,
  TERMINAL_HEAD_CELLS,
} from '../../types/report-configs';
import { type ReportGroupConfiguration } from '../../types/report-types';
import { formatMoney, type ReportBucketData } from '../../utils';
import { GENERATED_REVENUE_REPORT_COLS, TEXT_PADDING } from './constants';
import GeneratedRevenueReportRow from './generated-revenue-report-row';
import styles from './generated-revenue-report.styles';

const getRowLabelHeaders = (reportType: ReportType | undefined): string[] => {
  switch (reportType) {
    case ReportType.Customer: {
      return [CUSTOMER_HEAD_CELLS[0]?.label ?? ''];
    }
    case ReportType.Driver: {
      return [
        `${DRIVER_HEAD_CELLS[0]?.label ?? ''} ${
          isNil(DRIVER_HEAD_CELLS[1]?.label)
            ? ''
            : `(${DRIVER_HEAD_CELLS[1]?.label})`
        }`,
      ];
    }
    case ReportType.Order: {
      return [ORDER_HEAD_CELLS[0]?.label ?? ''];
    }
    case ReportType.Terminal: {
      return [TERMINAL_HEAD_CELLS[0]?.label ?? ''];
    }
    case ReportType.ServiceLevel: {
      return [SERVICE_LEVEL_HEAD_CELLS[0]?.label ?? ''];
    }
    default: {
      return [''];
    }
  }
};

export type GeneratedRevenueReportProps = {
  readonly companyData: CompanyFragment | undefined;
  readonly reportData: ReportBucketData[];
  readonly reportGroupConfiguration: ReportGroupConfiguration | undefined;
  readonly terminalsEnabled: boolean;
} & (
  | {
      isIncomeAnalysisReport: true;
      isRevenueReport: false;
      reportDateFilterType: ReportDateFilterType;
      invoiceStatus: string;
    }
  | {
      isRevenueReport: true;
      isIncomeAnalysisReport: false;
    }
);

// Also used for income analysis report
const GeneratedRevenueReport = (props: GeneratedRevenueReportProps) => {
  const {
    companyData,
    reportData,
    reportGroupConfiguration,
    terminalsEnabled,
    isIncomeAnalysisReport,
    isRevenueReport,
  } = props;

  let reportDateFilterType;
  let invoiceStatus;
  if (isIncomeAnalysisReport) {
    ({ reportDateFilterType, invoiceStatus } = props); // Destructuring assignment
  }

  const headers = getRowLabelHeaders(
    reportGroupConfiguration?.defaultReportType,
  );

  const totals = GENERATED_REVENUE_REPORT_COLS.map((col) =>
    reportData.reduce(
      (prev, curr) =>
        prev +
        Number.parseFloat(
          (curr[col as keyof ReportBucketData] ?? 0).toString(),
        ),
      0,
    ),
  );

  let sortedReportData = reportData;
  if (isIncomeAnalysisReport) {
    sortedReportData = reportData.sort(
      (a, b) => (b.totalRevenue ?? 0) - (a.totalRevenue ?? 0),
    );
  }

  let reportName;
  if (isRevenueReport) {
    reportName = 'Revenue';
  } else if (isIncomeAnalysisReport) {
    reportName = 'Income Analysis';
  }
  return (
    <Document
      title={`${reportName} Report by ${sentenceCase(
        reportGroupConfiguration?.defaultReportType ?? '-',
      )}`}
    >
      <Page orientation="landscape" size="LETTER" style={styles.page}>
        <View
          style={[
            styles.rowWithSpaceBetween,
            {
              marginTop: '10px',
              fontSize: '12px',
            },
          ]}
        >
          <Text style={styles.header1}>{`${reportName} Report by ${sentenceCase(
            reportGroupConfiguration?.defaultReportType ?? '-',
          )}`}</Text>
        </View>
        <View
          style={[
            styles.rowWithSpaceBetween,
            {
              fontSize: '12px',
            },
          ]}
        >
          <Text style={styles.header2}>{companyData?.name}</Text>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '10px',
            fontSize: '12px',
          }}
        >
          <View style={styles.rowWithSpaceBetween}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontSize: '10px',
              }}
            >
              <Text style={styles.filterLabelText}>
                {reportDateFilterType ?? 'Date'}
              </Text>
              <Text style={{ marginLeft: '10px' }}>
                {getDateRangeLabel(reportGroupConfiguration)}
              </Text>
            </View>
          </View>
          {isRevenueReport && (
            <View style={styles.rowWithSpaceBetween}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  fontSize: '10px',
                }}
              >
                <Text style={styles.filterLabelText}>Revenue Type</Text>
                <Text style={{ marginLeft: '10px' }}>
                  {sentenceCase(
                    reportGroupConfiguration?.reportRevenueType ?? '',
                  )}
                </Text>
              </View>
            </View>
          )}
          {isIncomeAnalysisReport && (
            <View style={styles.rowWithSpaceBetween}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  fontSize: '10px',
                }}
              >
                <Text style={styles.filterLabelText}>Invoice status</Text>
                <Text style={{ marginLeft: '10px' }}>
                  {sentenceCase(invoiceStatus ?? '')}
                </Text>
              </View>
            </View>
          )}
          {reportGroupConfiguration?.defaultReportType !==
            ReportType.Customer && (
            <View style={styles.rowWithSpaceBetween}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  fontSize: '10px',
                }}
              >
                <Text style={styles.filterLabelText}>Customer</Text>
                <Text style={{ marginLeft: '10px' }}>
                  {reportGroupConfiguration?.contact?.displayName ?? 'All'}
                </Text>
              </View>
            </View>
          )}
          {terminalsEnabled &&
            reportGroupConfiguration?.defaultReportType !==
              ReportType.Terminal && (
              <View style={styles.rowWithSpaceBetween}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: '10px',
                  }}
                >
                  <Text style={styles.filterLabelText}>Terminal</Text>
                  <Text style={{ marginLeft: '10px' }}>
                    {reportGroupConfiguration?.terminal?.code ?? 'All'}
                  </Text>
                </View>
              </View>
            )}
        </View>
        <View style={[styles.reportRow, styles.reportHeader]}>
          <View style={styles.reportRowLeftSection}>
            {headers.map((header) => (
              <Text
                key={header}
                style={{
                  width: `${safeDivide(100, headers.length)}%`,
                  paddingRight: TEXT_PADDING,
                }}
              >
                {header}
              </Text>
            ))}
          </View>
          <View style={styles.reportRowRightSection}>
            <Text style={styles.revenueCol}>Pickup $</Text>
            <Text style={styles.revenueCol}>Delivery $</Text>
            <Text style={styles.revenueCol}>Transfer $</Text>
            <Text style={styles.revenueCol}>Line haul $</Text>
            <Text style={styles.revenueCol}>Surcharge $</Text>
            <Text style={styles.revenueCol}>Special $</Text>
            <Text style={styles.revenueCol}>Fuel $</Text>
            <Text style={styles.revenueCol}>Total $</Text>
          </View>
        </View>
        {sortedReportData.map((reportRow) => {
          return (
            <GeneratedRevenueReportRow
              key={reportRow.uuid}
              reportRow={reportRow}
              reportGroupConfiguration={reportGroupConfiguration}
            />
          );
        })}
        <View style={styles.reportRow}>
          <View
            style={[
              styles.reportRowLeftSection,
              { justifyContent: 'flex-end' },
            ]}
          >
            <Text style={{ fontWeight: 'bold' }}>Report totals: </Text>
          </View>
          <View style={styles.reportRowRightSection}>
            {totals.map((total) => (
              <Text key={total} style={styles.revenueCol}>
                {formatMoney({ amount: total })}
              </Text>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default GeneratedRevenueReport;
