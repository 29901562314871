import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { isEmpty, values } from 'lodash';

export enum ReportDateFilterType {
  INVOICE_DATE = 'Invoice date',
  SERVICE_DATE = 'Service date',
}

export type ReportDateFilterTypeButtonProps = {
  readonly value: ReportDateFilterType;
  readonly onChange: (val: ReportDateFilterType) => void;
  readonly allowedOptions?: ReportDateFilterType[]; // Used to restrict the options available
};
const ReportDateFilterTypeButton = ({
  value,
  onChange,
  allowedOptions,
}: ReportDateFilterTypeButtonProps) => {
  return (
    <Stack direction="row" gap={1} alignItems="center">
      <Typography variant="body2" style={{ fontWeight: 'bold' }}>
        Filter by
      </Typography>
      <ToggleButtonGroup
        exclusive
        color="primary"
        size="small"
        value={value}
        defaultValue={ReportDateFilterType.INVOICE_DATE}
        aria-label="Report date filter type"
        onChange={(_, val) => {
          if (isEmpty(val)) {
            onChange(ReportDateFilterType.INVOICE_DATE);
          } else {
            onChange(val as ReportDateFilterType);
          }
        }}
      >
        {(allowedOptions ?? values(ReportDateFilterType)).map((option) => (
          <ToggleButton
            key={option}
            value={option}
            defaultChecked={option === ReportDateFilterType.INVOICE_DATE}
            size="small"
          >
            {option.toString()}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Stack>
  );
};

export default ReportDateFilterTypeButton;
