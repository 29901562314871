import { CircularProgress, Divider, Stack } from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import * as React from 'react';
import { useContext } from 'react';
import {
  DocumentType,
  useCompletedStopsNotOnRoutesQuery,
} from '../../../generated/graphql';
import EndOfDayContext from '../end-of-day-context';
import { type Route } from '../hooks/use-table-data';
import RoutesCard from './routes-card';

export default function EndOfDayRoutesSidebar({
  routeData,
}: {
  readonly routeData: Route[];
}) {
  const {
    selectedRouteUuid,
    setSelectedRouteUuid,
    completedStopsNotOnRoutesSelected,
    setCompletedStopsNotOnRoutesSelected,
    currentDate,
  } = useContext(EndOfDayContext);

  const {
    loading: completedStopsNotOnRoutesLoading,
    data: completedStopsNotOnRoutesData,
  } = useCompletedStopsNotOnRoutesQuery({
    variables: { date: currentDate.toDate() },
  });

  const numberOfDocumentsUploaded =
    completedStopsNotOnRoutesData?.completedStopsNotOnRoutes?.reduce(
      (prev, curr) => {
        const { shipment } = curr.leg;
        const hasEndOfDayDocuments = shipment?.documents?.some(
          (document) => document.type === DocumentType.EndOfDayDocument,
        );
        return hasEndOfDayDocuments ? prev + 1 : prev;
      },
      0,
    );

  return (
    <Stack sx={{ width: '100%' }} spacing={1}>
      {/* eslint-disable-next-line no-nested-ternary */}
      {completedStopsNotOnRoutesLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CircularProgress />
        </div>
      ) : !isNil(completedStopsNotOnRoutesData) &&
        !isEmpty(completedStopsNotOnRoutesData?.completedStopsNotOnRoutes) ? (
        <React.Fragment key="completed-stops-not-on-route">
          <RoutesCard
            key="completed-stops-not-on-route-card"
            showTotalStops
            style={{
              marginLeft: '10px',
              marginRight: '10px',
            }}
            routeName="Completed Stops Not on a Route"
            driverName={null}
            numberOfDocumentsUploaded={numberOfDocumentsUploaded ?? 0}
            numberOfStops={
              completedStopsNotOnRoutesData?.completedStopsNotOnRoutes
                ?.length ?? 0
            }
            isSelected={completedStopsNotOnRoutesSelected}
            onClick={() => {
              setCompletedStopsNotOnRoutesSelected(true);
              setSelectedRouteUuid(undefined);
            }}
          />
          <Divider key="completed-stops-without-route-divider" />
        </React.Fragment>
      ) : null}
      {routeData.map((data, index) => (
        <React.Fragment key={data.uuid}>
          <RoutesCard
            key={`${data.uuid}-routes-card`}
            style={{
              marginLeft: '10px',
              marginRight: '10px',
              marginTop: index === 0 ? '10px' : undefined,
            }}
            routeName={data.name}
            driverName={data.driver}
            numberOfDocumentsUploaded={data.numberOfDocumentsUploaded}
            numberOfCompleteStops={data.numberOfCompleteStops}
            numberOfAttemptedStops={data.numberOfAttemptedStops}
            numberOfStops={data.numberOfStops}
            isSelected={selectedRouteUuid === data.uuid}
            onClick={() => {
              setSelectedRouteUuid(data.uuid);
              setCompletedStopsNotOnRoutesSelected(false);
            }}
          />
          <Divider key={`${data.uuid}-divider`} />
        </React.Fragment>
      ))}
    </Stack>
  );
}
