import { Button, Container, Stack, Typography } from '@mui/material';
import { useRef } from 'react';
import { ErrorsAlert } from '../../../../common/components/errors-alert';
import { useErrors } from '../../../../common/react-hooks/use-errors';
import {
  type ContactCommunicationConfigsQuery,
  type ContactEmailsQuery,
  ContactEmailType,
  NotificationSubscriberType,
  type OrderEventNotificationSubscriptionsQuery,
  useContactCommunicationConfigsQuery,
  useContactEmailsQuery,
  useOrderEventNotificationSubscriptionsQuery,
  type UserContactNotificationSubscriptionQuery,
  useUpdateContactEmailsMutation,
  useUpdateOrderEventNotificationSubscriptionsMutation,
  useUpsertUserContactNotificationSubscriptionMutation,
  useUserContactNotificationSubscriptionQuery,
} from '../../../../generated/graphql';
import { GeneralNotifications } from './general-notifications';
import { OrderPartyNotifications } from './order-party-notifications';
import { OrderSubscribersNotifications } from './order-subscribers-notifications';
import {
  type NotificationsForm,
  useNotificationsForm,
} from './use-notifications-form';

const resetGeneralForm = (
  form: NotificationsForm,
  userContactSub: UserContactNotificationSubscriptionQuery['userContactNotificationSubscription'],
) => {
  form.general.resetApproveOrderNotification(
    userContactSub?.approveOrderNotification ?? false,
  );
};

const resetOrderEventNotificationSubscriptions = (
  form: NotificationsForm,
  subscriptions: OrderEventNotificationSubscriptionsQuery['orderEventNotificationSubscriptions'],
) => {
  form.orderContact.resetSubscriptions(
    subscriptions.filter(
      (sub) =>
        sub.subscriberType === NotificationSubscriberType.DynamicOrderContact,
    ),
  );
  form.pickupContact.resetSubscriptions(
    subscriptions.filter(
      (sub) =>
        sub.subscriberType ===
        NotificationSubscriberType.DynamicOrderInboundContact,
    ),
  );
  form.deliveryContact.resetSubscriptions(
    subscriptions.filter(
      (sub) =>
        sub.subscriberType ===
        NotificationSubscriberType.DynamicOrderOutboundContact,
    ),
  );
};

const resetOrderSubscribersEmails = (
  form: NotificationsForm,
  contactEmails: ContactEmailsQuery['contactEmails'],
) => {
  form.orderSubscribers.resetEmails(
    contactEmails
      ?.filter(({ type }) => type === ContactEmailType.OrderUpdate)
      .map(({ email }) => email) ?? [],
  );
};

const resetOrderSubscribersEvents = (
  form: NotificationsForm,
  contactCommunicationConfigs: ContactCommunicationConfigsQuery['contactCommunicationConfigs'],
) => {
  form.orderSubscribers.resetEvents(
    contactCommunicationConfigs
      ?.filter(({ active }) => active)
      .map(({ type }) => type) ?? [],
  );
};

const NotificationsPanel = ({
  contactUuid,
}: {
  readonly contactUuid: string;
}) => {
  const { errors, onError, clearErrors } = useErrors();
  const form = useNotificationsForm();

  const {
    data: userContactNotificationSubscriptionData,
    loading: userContactNotificationSubscriptionLoading,
  } = useUserContactNotificationSubscriptionQuery({
    onError,
    fetchPolicy: 'cache-and-network',
    variables: { contactUuid },
    onCompleted: (data) => {
      resetGeneralForm(form, data.userContactNotificationSubscription);
    },
  });
  const { loading: orderEventNotificationSubscriptionsLoading } =
    useOrderEventNotificationSubscriptionsQuery({
      onError,
      fetchPolicy: 'cache-and-network',
      variables: { contactUuid },
      onCompleted: (data) => {
        resetOrderEventNotificationSubscriptions(
          form,
          data.orderEventNotificationSubscriptions,
        );
      },
    });
  const { loading: contactEmailsLoading } = useContactEmailsQuery({
    onError,
    variables: { contactUuid },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      resetOrderSubscribersEmails(form, data.contactEmails);
    },
  });
  const { loading: contactCommunicationConfigsLoading } =
    useContactCommunicationConfigsQuery({
      onError,
      fetchPolicy: 'cache-and-network',
      variables: { contactUuid },
      onCompleted: (data) => {
        resetOrderSubscribersEvents(form, data.contactCommunicationConfigs);
      },
    });

  // Ref because changing this isn't meant to trigger a render. This is purely for the onCompleted handlers to
  // know when to reset the hasUnsavedChanges flag.
  const pendingUpdates = useRef<{
    generalSection?: boolean;
    orderPartySections?: boolean;
    orderSubscribersSection?: boolean;
  }>({});
  // Calls the form's resetHasUnsavedChanges if all sections have been saved.
  const resetHasUnsavedChanges = () => {
    if (
      pendingUpdates.current.generalSection !== true &&
      pendingUpdates.current.orderPartySections !== true &&
      pendingUpdates.current.orderSubscribersSection !== true
    ) {
      form.resetHasUnsavedChanges();
    }
  };

  const [upsertSubscription, { loading: upsertSubscriptionLoading }] =
    useUpsertUserContactNotificationSubscriptionMutation({
      onError,
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        // This is the legacy style API where a GraphQL error would bubble up in case of an error, so if we're here
        // then we know the mutation succeeded.
        pendingUpdates.current.generalSection = false;
        resetHasUnsavedChanges();
        resetGeneralForm(form, data.upsertUserContactNotificationSubscription);
      },
    });
  const [
    updateOrderEventNotificationSubscriptions,
    { loading: updateOrderEventNotificationSubscriptionsLoading },
  ] = useUpdateOrderEventNotificationSubscriptionsMutation({
    onError,
    onCompleted: (data) => {
      if (
        data.updateOrderEventNotificationSubscriptions.__typename ===
        'UpdateOrderEventNotificationSubscriptionsSuccessOutput'
      ) {
        pendingUpdates.current.orderPartySections = false;
        resetHasUnsavedChanges();
        resetOrderEventNotificationSubscriptions(
          form,
          data.updateOrderEventNotificationSubscriptions.subscriptions,
        );
      } else {
        // Let the orderSubscribersSection flag stay as pending so the user can try saving again.
        onError(data.updateOrderEventNotificationSubscriptions);
      }
    },
  });
  const [updateContactEmails, { loading: updateContactEmailsLoading }] =
    useUpdateContactEmailsMutation({
      onError,
      onCompleted: (data) => {
        if (
          data.updateContactEmails.__typename ===
          'UpdateContactEmailsSuccessOutput'
        ) {
          pendingUpdates.current.orderSubscribersSection = false;
          resetHasUnsavedChanges();
          resetOrderSubscribersEmails(
            form,
            data.updateContactEmails.contactEmails,
          );
          resetOrderSubscribersEvents(
            form,
            data.updateContactEmails.contactCommunicationConfigs,
          );
        } else {
          // Let the orderSubscribersSection flag stay as pending so the user can try saving again.
          onError(data.updateContactEmails);
        }
      },
    });

  const handleSave = () => {
    clearErrors();
    pendingUpdates.current = {
      generalSection: true,
      orderPartySections: true,
      orderSubscribersSection: true,
    };
    upsertSubscription({
      variables: {
        input: {
          // Note the uuid field isn't required. (The API should be redesigned to take a contact UUID and a user UUID instead.)
          uuid: userContactNotificationSubscriptionData
            ?.userContactNotificationSubscription?.uuid,
          contactUuid,
          approveOrderNotification: form.general.approveOrderNotification,
        },
      },
    });
    updateOrderEventNotificationSubscriptions({
      variables: {
        input: {
          contactUuid,
          subscriptions: [
            ...form.orderContact.subscriptions.map(
              ({ eventType, channel }) => ({
                eventType,
                channel,
                subscriberType: NotificationSubscriberType.DynamicOrderContact,
              }),
            ),
            ...form.pickupContact.subscriptions.map(
              ({ eventType, channel }) => ({
                eventType,
                channel,
                subscriberType:
                  NotificationSubscriberType.DynamicOrderInboundContact,
              }),
            ),
            ...form.deliveryContact.subscriptions.map(
              ({ eventType, channel }) => ({
                eventType,
                channel,
                subscriberType:
                  NotificationSubscriberType.DynamicOrderOutboundContact,
              }),
            ),
          ],
        },
      },
    });
    updateContactEmails({
      variables: {
        input: {
          contactUuid,
          communicationTypes: form.orderSubscribers.events,
          orderUpdateEmails: form.orderSubscribers.emails,
        },
      },
    });
  };

  const loading =
    userContactNotificationSubscriptionLoading ||
    orderEventNotificationSubscriptionsLoading ||
    contactEmailsLoading ||
    contactCommunicationConfigsLoading;
  const saving =
    upsertSubscriptionLoading ||
    updateOrderEventNotificationSubscriptionsLoading ||
    updateContactEmailsLoading;

  return (
    <Stack direction="column" alignItems="center" gap={2}>
      <Container
        maxWidth="md"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography fontWeight={500}>Notifications</Typography>
        <Button
          variant="contained"
          size="small"
          disabled={loading || saving || !form.hasUnsavedChanges}
          onClick={handleSave}
        >
          {saving ? 'Saving...' : 'Save'}
        </Button>
      </Container>
      <Container maxWidth="md">
        <ErrorsAlert errors={errors} onClear={clearErrors} />
        <GeneralNotifications form={form.general} />
        <OrderPartyNotifications
          form={form.orderContact}
          heading="Order contact"
          subheading="Notifications to the person or group responsible for placing the order"
        />
        <OrderPartyNotifications
          form={form.pickupContact}
          heading="Pickup contact"
          subheading="Notifications to the person or group responsible for offloading freight at pickup"
        />
        <OrderPartyNotifications
          form={form.deliveryContact}
          heading="Delivery contact"
          subheading="Notifications to the person or group responsible for receiving freight at delivery"
        />
        <OrderSubscribersNotifications form={form.orderSubscribers} />
      </Container>
    </Stack>
  );
};

export default NotificationsPanel;
