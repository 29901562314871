import ArrowForward from '@mui/icons-material/ArrowForward';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { isNil, last } from 'lodash';
import { type FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  plainDateTimeToString,
  plainTimeHumanReadable,
} from 'shared/plain-date-time';
import { omitTypename } from '../../../common/utils/graphql-input';
import {
  type AppointmentOptionsQuery,
  type PlainDateTimeInput,
  useAppointmentOptionsQuery,
  useChooseAppointmentOptionMutation,
  useMakeAppointmentConfirmationDecisionMutation,
} from '../../../generated/graphql';
import { ConfirmRescheduleAppointment } from '../../appointments/components/confirm-reschedule-appointment';

type QueryOption =
  AppointmentOptionsQuery['appointmentOptions']['options'][number];

type ChooseAppointmentViewProps = {
  readonly stopUuid: string;
};

export const ChooseAppointmentView: FunctionComponent<
  ChooseAppointmentViewProps
> = ({ stopUuid }) => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [from, setFrom] = useState<PlainDateTimeInput | null>(null);
  const { data, loading } = useAppointmentOptionsQuery({
    variables: {
      input: {
        stopUuid,
        from,
      },
    },
    onError: (error) => {
      setErrorMessage(error.message);
    },
  });
  const [
    chooseAppointment,
    { data: chooseAppointmentData, loading: loadingChooseAppointment },
  ] = useChooseAppointmentOptionMutation({
    onCompleted: ({ chooseAppointmentOption }) => {
      if (
        chooseAppointmentOption.__typename !==
        'ChooseAppointmentOptionSuccessOutput'
      ) {
        setErrorMessage(chooseAppointmentOption.message);
      }
    },
    onError: (error) => {
      setErrorMessage(error.message);
    },
  });
  const [
    makeAppointmentDecision,
    { loading: loadingMakeAppointmentConfirmationDecision },
  ] = useMakeAppointmentConfirmationDecisionMutation({
    onCompleted: (makeDecisionData) => {
      if (
        makeDecisionData.makeAppointmentConfirmationDecision.__typename ===
        'AppointmentConfirmationInfoOutput'
      ) {
        const accepted =
          !makeDecisionData.makeAppointmentConfirmationDecision
            .rescheduleRequested;
        navigate(`/confirm-appt/decision-complete?accepted=${accepted}`);
      } else {
        setErrorMessage(
          makeDecisionData.makeAppointmentConfirmationDecision.message,
        );
      }
    },
  });

  const [showReschedule, setShowReschedule] = useState(false);
  const [confirmSelectedOption, setConfirmSelectedOption] =
    useState<QueryOption | null>(null);

  const handleChooseOption = (option: QueryOption) => {
    setConfirmSelectedOption(option);
  };

  const handleConfirm = ({ date, startTime, endTime }: QueryOption) => {
    chooseAppointment({
      variables: {
        input: {
          stopUuid,
          // Omit __typename for PlainDate and PlainTime inputs.
          date: {
            year: date.year,
            month: date.month,
            day: date.day,
          },
          startTime: {
            hour: startTime.hour,
            minute: startTime.minute,
          },
          endTime: {
            hour: endTime.hour,
            minute: endTime.minute,
          },
        },
      },
    });
  };

  const handleAppointmentReschedule = () => {
    makeAppointmentDecision({
      variables: {
        input: {
          stopUuid,
          accepted: false,
        },
      },
    });
  };

  const options = data?.appointmentOptions;

  const chooseSuccessOutput =
    chooseAppointmentData?.chooseAppointmentOption.__typename ===
    'ChooseAppointmentOptionSuccessOutput'
      ? chooseAppointmentData.chooseAppointmentOption
      : null;

  return (
    <Stack pt={4} pb={2} px={3} alignItems="center">
      <Stack width="510px" maxWidth="100%" gap={3} alignItems="center">
        {loading && (
          <Stack alignItems="center" my={2}>
            <CircularProgress />
          </Stack>
        )}
        {!isNil(errorMessage) && (
          <Typography color="error" mt={3}>
            {errorMessage}
          </Typography>
        )}
        {isNil(chooseSuccessOutput) ? (
          !isNil(options) &&
          (options.appointmentExists ? (
            <>
              <Typography fontWeight={500}>{options.message}</Typography>
              {options.rescheduleRequested && (
                <Typography fontWeight={500}>
                  Your request to reschedule your appointment has been
                  submitted.
                </Typography>
              )}
            </>
          ) : (
            <>
              {options.options.length > 0 && (
                <>
                  <Typography
                    variant="h1"
                    fontWeight={500}
                    fontSize="large"
                    mb={1}
                  >
                    Please select a time window for your appointment
                  </Typography>
                  {isNil(confirmSelectedOption) ? (
                    options.options.map((option) => (
                      <Button
                        key={plainDateTimeToString(
                          option.date,
                          option.startTime,
                        )}
                        variant="outlined"
                        sx={{
                          flexDirection: 'column',
                          gap: '5px',
                          padding: '10px 12px',
                          background: 'white',
                          width: '100%',
                          maxWidth: '320px',
                        }}
                        disabled={loadingChooseAppointment}
                        onClick={() => {
                          handleChooseOption(option);
                        }}
                      >
                        <Typography component="span">
                          {option.humanDate}
                        </Typography>
                        <Typography component="span" fontWeight={500}>
                          {plainTimeHumanReadable(option.startTime)} –{' '}
                          {plainTimeHumanReadable(option.endTime)}
                        </Typography>
                      </Button>
                    ))
                  ) : (
                    <Stack gap={3}>
                      <Typography>
                        You’ve selected {confirmSelectedOption.humanDate} at{' '}
                        {plainTimeHumanReadable(
                          confirmSelectedOption.startTime,
                        )}
                        {' – '}
                        {plainTimeHumanReadable(confirmSelectedOption.endTime)}
                      </Typography>
                      <Button
                        variant="outlined"
                        sx={{
                          padding: '10px 12px',
                          background: 'white',
                          width: '100%',
                          maxWidth: '320px',
                          alignSelf: 'center',
                        }}
                        onClick={() => {
                          handleConfirm(confirmSelectedOption);
                        }}
                      >
                        Confirm
                      </Button>
                      <Button
                        variant="text"
                        sx={{
                          padding: '10px 12px',
                          width: '100%',
                          maxWidth: '320px',
                          alignSelf: 'center',
                        }}
                        onClick={() => {
                          setConfirmSelectedOption(null);
                        }}
                      >
                        Select another time
                      </Button>
                    </Stack>
                  )}
                  {isNil(confirmSelectedOption) && (
                    // If the user has clicked on an option, we'd be showing a confirmation button. But here we show
                    // the "See more times" button if there are more potentially more options to show, and we show
                    // the "Back to beginning" button if the user has clicked past the first page of options.
                    <Stack gap={2} alignItems="center">
                      {options.options.length >= options.pageSize && (
                        <Button
                          disabled={loadingChooseAppointment}
                          endIcon={<ArrowForward />}
                          onClick={() => {
                            const lastOption = last(options.options);
                            if (!isNil(lastOption)) {
                              setFrom({
                                date: omitTypename(lastOption.date),
                                time: omitTypename(lastOption.endTime),
                              });
                            }
                          }}
                        >
                          See more times
                        </Button>
                      )}
                      {!isNil(from) && (
                        <Button
                          onClick={() => {
                            setFrom(null);
                          }}
                        >
                          Back to beginning
                        </Button>
                      )}
                    </Stack>
                  )}
                </>
              )}
              <Typography fontSize="14.5px" mt={2}>
                {options.message ??
                  (options.options.length === 0 &&
                    'No appointment options were found.')}
              </Typography>
              {options.options.length === 0 && !isNil(from) && (
                // The user has clicked past the first page of options.
                <Button
                  onClick={() => {
                    setFrom(null);
                  }}
                >
                  Back to beginning
                </Button>
              )}
            </>
          ))
        ) : (
          <Typography>
            Your appointment is scheduled for{' '}
            {chooseSuccessOutput.appointmentDateTime}
          </Typography>
        )}
        {(!isNil(chooseSuccessOutput) || options?.appointmentExists === true) &&
          !(options?.rescheduleRequested ?? false) &&
          (showReschedule ? (
            <ConfirmRescheduleAppointment
              loading={loadingMakeAppointmentConfirmationDecision}
              handleAppointmentReschedule={handleAppointmentReschedule}
              onBack={() => {
                setShowReschedule(false);
              }}
            />
          ) : (
            <Button
              sx={{
                mt: 2,
              }}
              variant="text"
              onClick={() => {
                setShowReschedule(true);
              }}
            >
              Reschedule
            </Button>
          ))}
      </Stack>
    </Stack>
  );
};
