import { Box, useTheme } from '@mui/material';
import { captureException } from '@sentry/react';
import { isNil } from 'lodash';
import React, { type Dispatch, type SetStateAction } from 'react';
import ReactMapGL, { type MapRef, NavigationControl } from 'react-map-gl';
import { COLORS } from '../../../common/constants';
import { EnvironmentVariables } from '../../../environment-variables';
import { type AddressFragment } from '../../../generated/graphql';
import SameDayDispatchMapLegend, {
  type SameDayDispatchMapLegendRoute,
} from './same-day-dispatch-map-legend';
import SameDayDispatchMarker from './same-day-dispatch-marker';
import 'mapbox-gl/dist/mapbox-gl.css';

export type SameDayDispatchDriverPoint = {
  driverName: string;
  isSelected: boolean;
  lat: number | null | undefined;
  long: number | null | undefined;
  routeIndex: number;
  routeUuid: string;
};

export type SameDayDispatchRoutePoint = {
  address: AddressFragment;
  appointmentWindow: string;
  id: string;
  isSelected: boolean;
  orderName: string;
  ordinal: number;
  lat: number;
  long: number;
  routeIndex: number;
  routeUuid: string;
};

type SameDayDispatchMapProps = {
  driverPoints: SameDayDispatchDriverPoint[];
  routePoints: SameDayDispatchRoutePoint[][];
  setRef: Dispatch<SetStateAction<MapRef | null>>;
  setSelectedRoute: (routeUuid: string) => void;
};

const SameDayDispatchMap = ({
  driverPoints,
  routePoints,
  setRef,
  setSelectedRoute,
}: SameDayDispatchMapProps) => {
  const theme = useTheme();
  const legendRoutes: SameDayDispatchMapLegendRoute[] = driverPoints.map(
    (point) => {
      return {
        driverName: point.driverName,
        color: point.isSelected
          ? 'black'
          : (COLORS[point.routeIndex % COLORS.length] ??
            theme.palette.primary.main),
        routeUuid: point.routeUuid,
        isSelected: point.isSelected,
      };
    },
  );

  try {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          width: '100%',
          height: 'calc(100vh - 100px)',
        }}
      >
        <SameDayDispatchMapLegend routes={legendRoutes} />
        <ReactMapGL
          ref={setRef}
          mapboxAccessToken={EnvironmentVariables.VITE_MAPBOX_ACCESS_TOKEN}
          style={{ position: 'relative', height: '100%', width: '100%' }}
          mapStyle="mapbox://styles/mapbox/streets-v9"
          initialViewState={{
            longitude: -122.380_091,
            latitude: 37.824_052_9,
            zoom: 7,
          }}
        >
          <NavigationControl position="bottom-right" />
          {driverPoints.map((point) => {
            if (isNil(point.lat) || isNil(point.long)) {
              return null;
            }
            const color =
              COLORS[point.routeIndex % COLORS.length] ??
              theme.palette.primary.main;
            return (
              <SameDayDispatchMarker
                key={point.routeUuid}
                address={null}
                appointmentWindow={null}
                driverName={point.driverName}
                color={color}
                icon="driver"
                lat={point.lat}
                long={point.long}
                isSelected={point.isSelected}
                orderName={null}
                setSelected={() => {
                  setSelectedRoute(point.routeUuid);
                }}
              />
            );
          })}
          {routePoints.map((rps) => {
            return rps.reverse().map((point) => {
              const color =
                COLORS[point.routeIndex % COLORS.length] ??
                theme.palette.primary.main;
              return (
                <SameDayDispatchMarker
                  key={point.id}
                  address={point.address}
                  appointmentWindow={point.appointmentWindow}
                  driverName={null}
                  color={color}
                  icon="stop"
                  lat={point.lat}
                  long={point.long}
                  isSelected={point.isSelected}
                  orderName={point.orderName}
                  ordinal={point.ordinal + 1}
                  setSelected={() => {
                    setSelectedRoute(point.routeUuid);
                  }}
                />
              );
            });
          })}
        </ReactMapGL>
      </Box>
    );
  } catch (error) {
    captureException(error);
    return <Box>There was an error rendering the map: {String(error)}.</Box>;
  }
};

export default SameDayDispatchMap;
