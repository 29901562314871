import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import { pdf } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { isNil } from 'lodash';
import React, { type Dispatch, type SetStateAction, useState } from 'react';
import { exhaustive } from 'shared/switch';
import CSVDownloadButton from '../../../../../common/components/buttons/csv-download-button';
import CustomerFilterButton from '../../../../../common/components/customer-filter-button';
import DateDropdownPicker, {
  type DateOption,
  defaultPast1WeekDateRangeOption,
} from '../../../../../common/components/date-dropdown-picker';
import DownloadTypeSelection, {
  DownloadType,
} from '../../../../../common/components/download-type-selection';
import TerminalFilterButton from '../../../../../common/components/terminal-filter-button';
import { type Option } from '../../../../../common/filters/types';
import useMe from '../../../../../common/react-hooks/use-me';
import { isProduction } from '../../../../../environment-variables';
import {
  type OrderRevenueReportInput,
  type RevenueReportOrderDataFragment,
  useOrderRevenueReportLazyQuery,
} from '../../../../../generated/graphql';
import useInvoicesStore from '../../../invoices-store';
import styles from '../../../styles';
import { convertUnbilledRevenueDataToCSV } from '../../../utils';
import { accountingReportDefaultDateOption } from '../../accounting-reports/constants';
import GeneratedUnbilledRevenueReport, {
  ReportType,
} from './generated-unbilled-revenue-report';

type DownloadUnbilledAndUninvoicedOrdersReportModalProps = {
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
  readonly reportType: ReportType;
};

const DownloadUnbilledAndUninvoicedOrdersReportModal = ({
  open,
  setOpen,
  reportType,
}: DownloadUnbilledAndUninvoicedOrdersReportModalProps) => {
  const me = useMe();
  const ffShowTerminalsUi =
    me.companyConfiguration?.terminalsEnabled === true || !isProduction();
  const ffShowLineHaulUi =
    me.companyConfiguration?.lineHaulEnabled === true || !isProduction();
  const [customerOption, setCustomerOption] = useState<Option | undefined>();
  const [terminalOption, setTerminalOption] = useState<Option | undefined>();
  const [dateOption, setDateOption] = useState<DateOption>(
    defaultPast1WeekDateRangeOption,
  );
  const [isLineHaul, setIsLineHaul] = useState<boolean | undefined>();
  const [downloadType, setDownloadType] = useState<DownloadType>(
    DownloadType.PDF,
  );

  const createFileDownload = useInvoicesStore(
    (state) => state.createFileDownload,
  );

  const [getOrders, { loading }] = useOrderRevenueReportLazyQuery();

  const orderRevenueReportInput: OrderRevenueReportInput = {
    contactUuid: customerOption?.value,
    serviceDateFilter: {
      gte: dateOption.startDate,
      lte: dateOption.endDate,
    },
    onlyCompletedOrders: reportType === ReportType.UninvoicedOrders,
    terminalUuid: terminalOption?.value,
    isLineHaul,
  };

  const getData = async () => {
    switch (reportType) {
      case ReportType.UnbilledRevenue: {
        let orders: RevenueReportOrderDataFragment[] = [];
        let after: string | undefined;
        do {
          const res = await getOrders({
            variables: {
              orderRevenueReportInput: {
                ...orderRevenueReportInput,
                first: 100,
                after,
              },
            },
          });

          const edges = res.data?.orderRevenueReport.edges;

          if (!isNil(edges)) {
            orders = [...orders, ...edges.map(({ node }) => node)];
          }

          after =
            res.data?.orderRevenueReport.pageInfo.hasNextPage === true &&
            !isNil(res.data?.orderRevenueReport.pageInfo.endCursor)
              ? res.data?.orderRevenueReport.pageInfo.endCursor
              : undefined;
        } while (!isNil(after));
        return orders;
      }
      case ReportType.UninvoicedOrders: {
        let orders: RevenueReportOrderDataFragment[] = [];
        let after: string | undefined;
        do {
          const res = await getOrders({
            variables: {
              orderRevenueReportInput: {
                ...orderRevenueReportInput,
                first: 100,
                after,
              },
            },
          });

          const edges = res.data?.orderRevenueReport.edges;

          if (!isNil(edges)) {
            orders = [...orders, ...edges.map(({ node }) => node)];
          }

          after =
            res.data?.orderRevenueReport.pageInfo.hasNextPage === true &&
            !isNil(res.data?.orderRevenueReport.pageInfo.endCursor)
              ? res.data?.orderRevenueReport.pageInfo.endCursor
              : undefined;
        } while (!isNil(after));
        return orders;
      }
      default: {
        return exhaustive(reportType);
      }
    }
  };

  const resetFilters = () => {
    setCustomerOption(undefined);
    setTerminalOption(undefined);
    setDateOption(accountingReportDefaultDateOption);
    setIsLineHaul(undefined);
  };

  const onClose = () => {
    setOpen(false);
    resetFilters();
  };

  const getFileName = () => {
    let name = '';
    switch (reportType) {
      case ReportType.UnbilledRevenue: {
        name = 'Unbilled Revenue Report';
        break;
      }
      case ReportType.UninvoicedOrders: {
        name = 'Uninvoiced Orders Report';
        break;
      }
      default: {
        exhaustive(reportType);
      }
    }
    return `${name}${
      isNil(customerOption?.label) ? '' : `-${customerOption?.label}`
    }${isNil(terminalOption?.label) ? '' : `-${terminalOption?.label}`}${
      isNil(dateOption)
        ? ''
        : `-${dayjs(dateOption.startDate).format('MM/DD/YY')}-to-${dayjs(
            dateOption.endDate,
          ).format('MM/DD/YY')}`
    }${isLineHaul === true ? `-LHonly` : ''}.${(
      downloadType ?? DownloadType.PDF
    )?.toLowerCase()}`;
  };

  const getCacheId = () => {
    switch (reportType) {
      case ReportType.UnbilledRevenue: {
        return 'UNBILLED_REVENUE_REPORT';
      }
      case ReportType.UninvoicedOrders: {
        return 'UNBILLED_ORDERS_REPORT';
      }
      default: {
        return exhaustive(reportType);
      }
    }
  };

  const getModalName = () => {
    switch (reportType) {
      case ReportType.UnbilledRevenue: {
        return 'Unbilled Revenue Report';
      }
      case ReportType.UninvoicedOrders: {
        return 'Uninvoiced Orders Report';
      }
      default: {
        return exhaustive(reportType);
      }
    }
  };

  const getCSVData = async () => {
    const completeDownload = createFileDownload();
    const data = await getData();
    if (isNil(data)) {
      completeDownload({
        alertSeverity: 'error',
        message: 'Download failed',
      });
      onClose();
      return null;
    }
    const orders = convertUnbilledRevenueDataToCSV(data);
    completeDownload();
    return orders;
  };

  const downloadPDF = async () => {
    const completeDownload = createFileDownload();
    const data = await getData();
    if (isNil(data)) {
      completeDownload({
        alertSeverity: 'error',
        message: 'Download failed',
      });
      resetFilters();
      return;
    }

    const blob = await pdf(
      <GeneratedUnbilledRevenueReport
        companyData={me}
        ordersData={data}
        dateOption={dateOption}
        customerOption={customerOption}
        terminalOption={terminalOption}
        isLineHaul={isLineHaul ?? false}
        reportType={reportType}
      />,
    ).toBlob();
    saveAs(blob, getFileName());

    completeDownload();
    onClose();
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={() => {
        if (downloadType === DownloadType.CSV && loading) return;
        onClose();
      }}
    >
      <Box
        sx={[
          styles.modal,
          {
            height: 'fit-content',
            width: '400px',
          },
        ]}
      >
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={10}>
            <Typography sx={{ fontWeight: 'bold' }}>
              {getModalName()}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              sx={{ float: 'right' }}
              disabled={loading}
              onClick={() => {
                onClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} sx={{ height: '100%', mb: 2 }}>
            <Stack direction="column" spacing={1}>
              <Box>
                <DateDropdownPicker
                  filterTitle="Service Date"
                  dateOption={dateOption}
                  setDateOption={setDateOption}
                />
              </Box>
              <CustomerFilterButton
                selectedOption={customerOption}
                handleChange={(option: Option | undefined) => {
                  setCustomerOption(option);
                }}
              />
              {ffShowTerminalsUi && (
                <TerminalFilterButton
                  selectedOption={terminalOption}
                  prefixText="Controlling terminal"
                  handleChange={(option: Option | null | undefined) => {
                    setTerminalOption(option ?? undefined);
                  }}
                  includeInactiveTerminals={false}
                />
              )}
              {ffShowLineHaulUi && (
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isLineHaul ?? false}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setIsLineHaul(true);
                          } else {
                            setIsLineHaul(undefined);
                          }
                        }}
                      />
                    }
                    label="Only show line haul orders"
                  />
                </FormControl>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Stack justifyContent="space-between" direction="row" width="100%">
              <DownloadTypeSelection
                labelText="File type"
                cacheId={getCacheId()}
                selectedOption={downloadType}
                handleChange={(selectedDownloadType: DownloadType) => {
                  setDownloadType(selectedDownloadType);
                }}
              />
              {downloadType === DownloadType.CSV ? (
                <CSVDownloadButton
                  label="Download"
                  getData={getCSVData}
                  filename={getFileName()}
                  buttonProps={{
                    variant: 'contained',
                    color: 'info',
                    disabled: loading,
                  }}
                  reportType={reportType}
                  dataForTimingLog={orderRevenueReportInput}
                  onDownloadComplete={() => {
                    onClose();
                  }}
                />
              ) : (
                <Button
                  variant="contained"
                  color="info"
                  disabled={loading}
                  onClick={downloadPDF}
                >
                  Download
                </Button>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default DownloadUnbilledAndUninvoicedOrdersReportModal;
