import {
  Box,
  Button,
  Dialog,
  Stack,
  type SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { useCallback, useState } from 'react';
import AddressAutocompleteForm from '../../../../common/components/address-autocomplete-form';
import { ErrorsAlert } from '../../../../common/components/errors-alert';
import { FeatureFlag } from '../../../../common/feature-flags';
import { useErrors } from '../../../../common/react-hooks/use-errors';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import {
  TerminalsDocument,
  useCreateAddressMutation,
  useCreateTerminalMutation,
  type TerminalEntity,
} from '../../../../generated/graphql';
import theme from '../../../../theme';
import { type AddressFormField } from '../../../addresses/redux/addresses-values-slice';
import {
  type ServiceRegionOption,
  ServiceRegionSelect,
} from '../service-region-select/service-region-select';

const styles = {
  modalInnerContainer: {
    bgcolor: 'background.paper',
    boxShadow: 24,
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    p: 4,
  } as SxProps,
};

const CreateTerminalModal = ({
  open,
  onCreated,
  onCancel,
}: {
  readonly open: boolean;
  readonly onCreated: (terminal: Pick<TerminalEntity, 'uuid'>) => void;
  readonly onCancel: () => void;
}) => {
  const ffLineHaulNetworks = useFeatureFlag(FeatureFlag.FF_LINE_HAUL_NETWORKS);
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [serviceRegion, setServiceRegion] =
    useState<ServiceRegionOption | null>(null);
  const [currentAddress, setCurrentAddress] = useState<
    AddressFormField | undefined
  >(undefined);

  const resetState = () => {
    setCode('');
    setName('');
    setServiceRegion(null);
    setCurrentAddress(undefined);
  };

  const { errors, onError, clearErrors } = useErrors();
  const [createTerminal, { loading: createTerminalLoading }] =
    useCreateTerminalMutation({
      onError,
      refetchQueries: [TerminalsDocument],
      onCompleted: (data) => {
        if (
          data.createTerminalV2.__typename === 'CreateTerminalSuccessOutput'
        ) {
          onCreated({ uuid: data.createTerminalV2.terminal.uuid });
          resetState();
        } else {
          onError(data.createTerminalV2);
        }
      },
    });

  const [createAddress, { loading: createAddressLoading }] =
    useCreateAddressMutation({
      onError,
    });

  const handleAddressChange = useCallback(
    (_isAutofillChange: boolean, newAddress?: AddressFormField) => {
      if (isNil(newAddress)) {
        setCurrentAddress(undefined);
      } else {
        setCurrentAddress(newAddress);
      }
    },
    [],
  );

  const handleSave = async () => {
    clearErrors();
    if (isEmpty(code)) {
      onError('Please enter a terminal code.');
      return;
    }
    if (isEmpty(name)) {
      onError('Please enter a terminal name.');
      return;
    }
    if (ffLineHaulNetworks && isNil(serviceRegion)) {
      onError('Please select a service region.');
      return;
    }
    if (isNil(currentAddress)) {
      onError('Please enter an address.');
      return;
    }

    try {
      const { data: addressData } = await createAddress({
        variables: {
          input: {
            addressCreateInput: {
              name: currentAddress.name ?? '',
              line1: currentAddress.line1 ?? '',
              city: currentAddress.city ?? '',
              state: currentAddress.state ?? '',
              zip: currentAddress.zip ?? '',
              country: currentAddress.country ?? '',
            },
          },
        },
      });
      if (isNil(addressData)) {
        onError('An error occurred. Please try again.');
        return;
      }
      void createTerminal({
        variables: {
          input: {
            code,
            name,
            serviceRegionId: serviceRegion?.id,
            addressUuid: addressData.createAddress.uuid,
          },
        },
      });
    } catch {
      onError('An error occurred. Please try again.');
    }
  };

  const handleClose = () => {
    resetState();
    onCancel();
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <Box sx={styles.modalInnerContainer}>
        <Typography variant="h6">Create terminal</Typography>
        <ErrorsAlert errors={errors} onClear={clearErrors} />
        <Stack direction="column" gap={theme.spacing(2)}>
          <Stack direction="row" gap={1}>
            <TextField
              label="Terminal Code"
              helperText="A unique identifier, e.g., GSO"
              size="small"
              value={code}
              onChange={(e) => {
                setCode(e.target.value.trim().toUpperCase());
              }}
            />
            <TextField
              label="Terminal Name"
              size="small"
              value={name}
              sx={{ flexGrow: 1 }}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </Stack>
          {ffLineHaulNetworks && (
            <ServiceRegionSelect
              value={serviceRegion}
              onChange={setServiceRegion}
              onError={onError}
            />
          )}
          <AddressAutocompleteForm
            currentAddress={currentAddress}
            handleChange={handleAddressChange}
          />
        </Stack>
        <Box>
          <Button
            variant="contained"
            disabled={createAddressLoading || createTerminalLoading}
            sx={{ float: 'right' }}
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CreateTerminalModal;
