import { Box, type SxProps, Typography } from '@mui/material';

const styles = {
  unauthorized: {
    p: 5,
    textAlign: 'center',
  } as SxProps,
};

const UnauthorizedUserPermissions = () => {
  return (
    <Box sx={styles.unauthorized}>
      <Typography variant="h2">Unauthorized access</Typography>
      <Typography variant="h3">
        You do not have permission to view this page.
      </Typography>
    </Box>
  );
};

export default UnauthorizedUserPermissions;
