import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import React, {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useState,
} from 'react';
import { getNoonOfDay } from 'shared/date';
import {
  type AccessorialDateRangeConfigFragment,
  AccessorialType,
  useCreateAccessorialDateRangeConfigMutation,
  useUpdateAccessorialDateRangeConfigMutation,
} from '../../../../../generated/graphql';
import { getErrorStringForPricingPeriodDateRanges } from '../utils';

type AddAccessorialPriceModalProps = {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly accessorialUuid: string;
  readonly accessorialType: AccessorialType;
  readonly refetchAccessorialDateRangeConfigs: () => Promise<void>;
  readonly accessorialDateRangeConfigs: AccessorialDateRangeConfigFragment[];
};

const AddAccessorialPriceModal = ({
  isOpen,
  setIsOpen,
  accessorialUuid,
  accessorialType,
  refetchAccessorialDateRangeConfigs,
  accessorialDateRangeConfigs,
}: AddAccessorialPriceModalProps) => {
  const [
    showCreateAccessorialDateRangeSuccessMessage,
    setShowCreateAccessorialDateRangeSuccessMessage,
  ] = useState<boolean>(false);
  const [
    createAccessorialDateRangeErrorMessage,
    setCreateAccessorialDateRangeErrorMessage,
  ] = useState<string | undefined>(undefined);
  const [createAccessorialDateRangeConfig] =
    useCreateAccessorialDateRangeConfigMutation();
  const [updateAccessorialDateRangeConfig] =
    useUpdateAccessorialDateRangeConfigMutation();
  const [
    accessorialDateRangeConfigToCopyUuid,
    setAccessorialDateRangeConfigToCopyUuid,
  ] = useState<string | undefined>();
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(null);

  const onClose = () => {
    setEndDate(null);
    setAccessorialDateRangeConfigToCopyUuid(undefined);
    setIsOpen(false);
  };

  const onCreate = async () => {
    if (
      isNil(accessorialDateRangeConfigToCopyUuid) &&
      accessorialType === AccessorialType.Special
    ) {
      setCreateAccessorialDateRangeErrorMessage(
        'You must select a pricing period to copy the rates from',
      );
      return;
    }
    const errorString = getErrorStringForPricingPeriodDateRanges({
      accessorialDateRangeConfigs,
      startDate,
      endDate,
    });
    if (!isNil(errorString)) {
      setCreateAccessorialDateRangeErrorMessage(errorString);
      return;
    }

    const mostRecentAccessorialDateRange = accessorialDateRangeConfigs[0];

    if (
      !isNil(mostRecentAccessorialDateRange) &&
      isNil(mostRecentAccessorialDateRange.endDate)
    ) {
      // Set the end date for the most recent accessorial date range.
      await updateAccessorialDateRangeConfig({
        variables: {
          updateAccessorialDateRangeConfigInput: {
            uuid: mostRecentAccessorialDateRange.uuid,
            accessorialUuid,
            endDate: getNoonOfDay(dayjs(startDate).subtract(1, 'day').toDate()),
          },
        },
      });
    }

    await createAccessorialDateRangeConfig({
      variables: {
        createAccessorialDateRangeConfigInput: {
          accessorialUuid,
          startDate: getNoonOfDay(startDate),
          endDate: isNil(endDate) ? null : getNoonOfDay(endDate),
          accessorialDateRangeConfigToCopyUuid,
        },
      },
    });

    await refetchAccessorialDateRangeConfigs();
    setShowCreateAccessorialDateRangeSuccessMessage(true);
    onClose();
  };

  useEffect(() => {
    const currentAccessorialDateRangeConfigEndDate =
      accessorialDateRangeConfigs[0]?.endDate;

    setStartDate(
      isNil(currentAccessorialDateRangeConfigEndDate)
        ? dayjs().add(1, 'day').toDate()
        : dayjs(currentAccessorialDateRangeConfigEndDate)
            .add(1, 'day')
            .toDate(),
    );
  }, [accessorialDateRangeConfigs, isOpen]);

  return (
    <>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showCreateAccessorialDateRangeSuccessMessage}
        onClose={() => {
          setShowCreateAccessorialDateRangeSuccessMessage(false);
        }}
      >
        <Alert severity="success">
          Successfully created accessorial values
        </Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!isNil(createAccessorialDateRangeErrorMessage)}
        onClose={() => {
          setCreateAccessorialDateRangeErrorMessage(undefined);
        }}
      >
        <Alert severity="error">{createAccessorialDateRangeErrorMessage}</Alert>
      </Snackbar>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <DialogTitle>Set a new price</DialogTitle>
        <DialogContent>
          <Typography>Choose a pricing period</Typography>
          <Typography variant="caption" color="text.secondary">
            This price will apply to all shipments with a service date between
            these days
          </Typography>
          <Grid container sx={{ mt: 2 }} spacing={1}>
            <Grid item xs={6}>
              <DatePicker
                label="Start date"
                value={startDate}
                renderInput={(props) => <TextField size="small" {...props} />}
                onChange={(newDate) => {
                  if (isNil(newDate)) return;
                  setStartDate(newDate);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Stack>
                <DatePicker
                  label="End date"
                  value={endDate}
                  renderInput={(props) => <TextField size="small" {...props} />}
                  onChange={(newDate) => {
                    setEndDate(newDate);
                  }}
                />
                <Stack direction="row" alignItems="center">
                  <Checkbox
                    checked={isNil(endDate)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setEndDate(null);
                      } else {
                        setEndDate(new Date());
                      }
                    }}
                  />
                  <Typography>Is ongoing</Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1}>
                <Typography>
                  Select a pricing period to copy the rates from
                </Typography>
                <Typography color="text.secondary">
                  {accessorialType === AccessorialType.Special
                    ? '(required)'
                    : '(optional)'}
                </Typography>
              </Stack>
              <Typography variant="caption" color="text.secondary">
                You can edit the rates after creating the new price
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl sx={{ width: '50%' }} size="small">
                <InputLabel id="accessorialDateRangeToCopyLabel">
                  Select pricing period
                </InputLabel>
                <Select
                  label="Select pricing period"
                  labelId="accessorialDateRangeToCopyLabel"
                  value={accessorialDateRangeConfigToCopyUuid}
                  onChange={(e) => {
                    if (e.target.value === 'None') {
                      setAccessorialDateRangeConfigToCopyUuid(undefined);
                    } else {
                      setAccessorialDateRangeConfigToCopyUuid(e.target.value);
                    }
                  }}
                >
                  {accessorialType !== AccessorialType.Special && (
                    <MenuItem value="None">None</MenuItem>
                  )}
                  {accessorialDateRangeConfigs.map(
                    (accessorialDateRangeConfig) => (
                      <MenuItem
                        key={accessorialDateRangeConfig.uuid}
                        value={accessorialDateRangeConfig.uuid}
                      >
                        {dayjs(accessorialDateRangeConfig.startDate).format(
                          'MM/DD/YYYY',
                        )}{' '}
                        -{' '}
                        {isNil(accessorialDateRangeConfig.endDate)
                          ? 'Now'
                          : dayjs(accessorialDateRangeConfig.endDate).format(
                              'MM/DD/YYYY',
                            )}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <DialogActions>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={onCreate}>
              Confirm
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddAccessorialPriceModal;
