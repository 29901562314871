import { exhaustive } from 'shared/switch';
import {
  type NotificationChannel,
  OrderEventNotificationType,
} from '../../../../generated/graphql';
import type { OrderEventNotificationsSubscription } from './use-notifications-form';

// All OrderEventNotificationTypes, explicitly listed to keep a specific order in the UI.
export const orderEventNotificationTypes = [
  OrderEventNotificationType.OrderCreated,
  OrderEventNotificationType.PickupNextStop,
  OrderEventNotificationType.PickupCompleted,
  OrderEventNotificationType.DeliveryNextStop,
  OrderEventNotificationType.DeliveryCompleted,
] as const;

// Returns true if and only if the given subscriptions array includes an element for all event types
// for the specified channel.
export const allOrderEventTypesSelectedForChannel = (
  subscriptions: OrderEventNotificationsSubscription[],
  channel: NotificationChannel,
): boolean =>
  orderEventNotificationTypes.every((eventType) =>
    subscriptions.some(
      (sub) => sub.eventType === eventType && sub.channel === channel,
    ),
  );

// Returns the human-readable label for a given OrderEventNotificationType.
export const orderEventTypeHumanReadable = (
  eventType: OrderEventNotificationType,
) => {
  switch (eventType) {
    case OrderEventNotificationType.OrderCreated: {
      return 'Order is submitted';
    }
    case OrderEventNotificationType.PickupNextStop: {
      return 'Next stop for driver is pickup';
    }
    case OrderEventNotificationType.PickupCompleted: {
      return 'Pickup is completed';
    }
    case OrderEventNotificationType.DeliveryNextStop: {
      return 'Next stop for driver is delivery';
    }
    case OrderEventNotificationType.DeliveryCompleted: {
      return 'Delivery is completed';
    }
    default: {
      return exhaustive(eventType);
    }
  }
};
