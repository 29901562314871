import dayjs from 'dayjs';
import { StopStatus } from '../../generated/graphql';

export const titleCase = (s: string) =>
  s
    .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()) // Initial char (after -/_)
    .replaceAll(/[-_]+(.)/g, (_, c) => ` ${c.toUpperCase()}`)
    .replaceAll(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();
    }); // First char after each -/_

export const isToday = (day: string) => {
  const today = dayjs();
  const appointment = dayjs(day);
  return (
    appointment.day() === today.day() &&
    appointment.month() === today.month() &&
    appointment.year() === today.year()
  );
};

export const stopIsComplete = (
  status: StopStatus | null | undefined,
  // eslint-disable-next-line consistent-return
): boolean => {
  // eslint-disable-next-line default-case
  switch (status) {
    case StopStatus.Arrived: {
      return false;
    }
    case StopStatus.Cancelled: {
      return true;
    }
    case StopStatus.Completed: {
      return true;
    }
    case StopStatus.Failed: {
      return true;
    }
    case StopStatus.NotArrived: {
      return false;
    }
    case null:
    case undefined: {
      return true;
    }
  }
};

// Covers the Bay Area from SF to San Jose when no bounds can be calculated
const DEFAULT_BOUNDS = {
  bottomRightX: -121.872_895_5,
  bottomRightY: 37.776_891_55,
  topLeftX: -122.469_451_343_932,
  topLeftY: 37.270_709_5,
};

/**
 * @param coordinates
 * @param minDistance Helps avoid hyper zooming in if there's only one
 * coordinate
 */
export const calculateBoundsFromCoordinatesToShowAllMarkers = (
  coordinates: Array<{ latitude: number; longitude: number }>,
  minDistance = 0.1,
) => {
  if (coordinates.length === 0) {
    return DEFAULT_BOUNDS;
  }
  let topLeftX = Number.POSITIVE_INFINITY;
  let bottomRightX = Number.NEGATIVE_INFINITY;
  let topLeftY = Number.POSITIVE_INFINITY;
  let bottomRightY = Number.NEGATIVE_INFINITY;
  for (const coordinate of coordinates) {
    topLeftX = Math.min(topLeftX, coordinate.longitude);
    bottomRightX = Math.max(bottomRightX, coordinate.longitude);
    topLeftY = Math.min(topLeftY, coordinate.latitude);
    bottomRightY = Math.max(bottomRightY, coordinate.latitude);
  }
  if (Math.abs(topLeftX - bottomRightX) < minDistance) {
    topLeftX -= minDistance / 2;
    bottomRightX += minDistance / 2;
  }
  if (Math.abs(topLeftY - bottomRightY) < minDistance) {
    topLeftY -= minDistance / 2;
    bottomRightY += minDistance / 2;
  }
  return { topLeftX, topLeftY, bottomRightX, bottomRightY };
};
