import { styled, TableCell, TableRow } from '@mui/material';
import { sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import React from 'react';
import { getAppointmentTimeString } from 'shared/copy';
import { shallow } from 'zustand/shallow';
import { type GenerateRoutesPreviewRouteFragment } from '../../../generated/graphql';
import useDispatchStore from '../dispatch-store';

const TableCellCondensed = styled(TableCell)(`
  font-size: 12px;
  padding: 0px;
  padding-top: 2px;
  padding-bottom: 2px;
`);

const GenerateRouteResultStopRow = ({
  index,
  stopData,
}: {
  readonly index: number;
  readonly stopData: GenerateRoutesPreviewRouteFragment['stops'][0];
}) => {
  const [isHovered, setHoveredOrderUuid, setHoveredStopUuid] = useDispatchStore(
    (state) => [
      state.hoveredOrderUuid === stopData.stop.shipment?.order?.uuid,
      state.setHoveredOrderUuid,
      state.setHoveredStopUuid,
    ],
    shallow,
  );

  const { stop, eta } = stopData;

  return (
    <TableRow
      key={stop.uuid}
      sx={{
        backgroundColor: isHovered ? '#e1e1e1' : undefined,
      }}
      onMouseEnter={() => {
        setHoveredStopUuid(stop.uuid);
        setHoveredOrderUuid(stop.shipment?.order?.uuid);
      }}
      onMouseLeave={() => {
        setHoveredStopUuid(undefined);
        setHoveredOrderUuid(undefined);
      }}
    >
      <TableCellCondensed sx={{ pl: 0.5 }} width={20}>
        {index + 1}
      </TableCellCondensed>
      <TableCellCondensed width={60}>
        {sentenceCase(stop.stopType ?? '')}
      </TableCellCondensed>
      <TableCellCondensed>
        {stop.address.line1}, {stop.address.city} {stop.address.zip}
      </TableCellCondensed>
      <TableCellCondensed width={50}>
        {stop.shipment?.order?.weight} lb
      </TableCellCondensed>
      <TableCellCondensed width={80}>
        {getAppointmentTimeString({
          stop,
          deliveryDate: new Date(),
          useMinimizedAppointmentTime: true,
        })}
      </TableCellCondensed>
      <TableCellCondensed sx={{ pr: 0.5 }}>
        {dayjs(eta).format('HH:mm')}
      </TableCellCondensed>
    </TableRow>
  );
};

export default React.memo(GenerateRouteResultStopRow);
