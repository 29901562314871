import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  InputLabel,
  TextField,
  Stack,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { type Dispatch, type SetStateAction, useEffect } from 'react';
import { Controller, type SubmitHandler } from 'react-hook-form';
import {
  MasterAccountsDocument,
  type MasterAccountFragment,
  useRemoveMasterAccountMutation,
  useUpdateMasterAccountMutation,
} from '../../../../../generated/graphql';
import { type MasterAccountFormValues } from './forms/types';
import useUpdateMasterAccountForm from './forms/use-update-master-account-form';

type UpdateMasterAccountModalProps = {
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
  readonly setSuccessSnackbarVisible: Dispatch<SetStateAction<boolean>>;
  readonly setErrorSnackbarVisible: Dispatch<SetStateAction<boolean>>;

  readonly selectedMasterAccount: MasterAccountFragment | undefined;
};

const UpdateMasterAccountModal = ({
  open,
  setOpen,
  setSuccessSnackbarVisible,
  setErrorSnackbarVisible,
  selectedMasterAccount,
}: UpdateMasterAccountModalProps) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useUpdateMasterAccountForm();

  useEffect(() => {
    if (!isNil(selectedMasterAccount)) {
      reset({
        name: selectedMasterAccount.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMasterAccount]);

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const [updateMasterAccount, { loading: updateMasterAccountLoading }] =
    useUpdateMasterAccountMutation({
      refetchQueries: [MasterAccountsDocument],
    });
  const [removeMasterAccount] = useRemoveMasterAccountMutation({
    refetchQueries: [MasterAccountsDocument],
  });

  const onSubmit: SubmitHandler<MasterAccountFormValues> = async (data) => {
    const { name } = data;
    const uuid = selectedMasterAccount?.uuid;
    if (isNil(uuid)) {
      setErrorSnackbarVisible(true);
      return;
    }
    try {
      const response = await updateMasterAccount({
        variables: {
          updateMasterAccountInput: {
            uuid,
            name,
          },
        },
      });
      if (response?.data?.updateMasterAccount?.success === true) {
        setSuccessSnackbarVisible(true);
      } else {
        setErrorSnackbarVisible(true);
      }
      handleClose();
    } catch {
      setErrorSnackbarVisible(true);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <span>Update Master Account</span>
          <Button
            variant="contained"
            color="error"
            onClick={async () => {
              if (!isNil(selectedMasterAccount)) {
                await removeMasterAccount({
                  variables: {
                    uuid: selectedMasterAccount.uuid,
                  },
                });
              }
              handleClose();
            }}
          >
            Delete
          </Button>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Controller
              name="name"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <InputLabel>Name</InputLabel>
                  <TextField
                    required
                    size="small"
                    value={value}
                    error={!isNil(errors.name)}
                    helperText={errors?.name?.message}
                    sx={{ width: '100%' }}
                    onChange={onChange}
                  />
                </>
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" gap={1}>
          <Button
            variant="outlined"
            disabled={updateMasterAccountLoading}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={updateMasterAccountLoading}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateMasterAccountModal;
