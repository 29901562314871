import { isNil } from 'lodash';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { isNilOrEmptyString } from 'shared/string';
import { v4 } from 'uuid';
import { FeatureFlag } from '../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../common/react-hooks/use-feature-flag';
import {
  type DefaultPackageSpec,
  useGetContactDefaultPackageSpec,
} from '../../../../../common/react-hooks/use-get-contact-default-package-spec';
import useMe from '../../../../../common/react-hooks/use-me';
import {
  type CompanyFragment,
  FulfillmentType,
  InboundMethod,
  type ShallowContactFragment,
  type StandardOrderFragmentFragment,
  useStandardOrderLazyQuery,
} from '../../../../../generated/graphql';
import { INBOUND_STOP_IDX, OUTBOUND_STOP_IDX } from '../components/constants';
import { StopType } from '../forms/stop-type';
import { type OrderFormFieldValues } from '../forms/types';
import {
  convertStandardOrderPayloadToFormData,
  initializeOrderToFormData,
} from '../forms/utils';
import { useOrderFormContact } from './use-order-form-contact';

const forceRecoveryToPickupAndTransferToDelivery = (
  formData: OrderFormFieldValues,
) => {
  if (formData.stops?.[INBOUND_STOP_IDX]?.stopType === StopType.Recovery) {
    // eslint-disable-next-line no-param-reassign
    formData.stops[INBOUND_STOP_IDX].stopType = StopType.Pickup;
    if (!isNil(formData.stops[INBOUND_STOP_IDX].address)) {
      // Provide a new uuid in case the order is saved.
      // eslint-disable-next-line no-param-reassign
      formData.stops[INBOUND_STOP_IDX].address = {
        ...formData.stops[INBOUND_STOP_IDX].address,
        uuid: v4(),
      };
    }
    // eslint-disable-next-line no-param-reassign
    formData.stops[INBOUND_STOP_IDX].outboundMethod = undefined;
  }
  if (formData.stops?.[OUTBOUND_STOP_IDX]?.stopType === StopType.Transfer) {
    // eslint-disable-next-line no-param-reassign
    formData.stops[OUTBOUND_STOP_IDX].stopType = StopType.Delivery;
    if (!isNil(formData.stops[OUTBOUND_STOP_IDX].address)) {
      // Provide a new uuid in case the order is saved.
      // eslint-disable-next-line no-param-reassign
      formData.stops[OUTBOUND_STOP_IDX].address = {
        ...formData.stops[OUTBOUND_STOP_IDX].address,
        uuid: v4(),
      };
    }
    // eslint-disable-next-line no-param-reassign
    formData.stops[OUTBOUND_STOP_IDX].inboundMethod = InboundMethod.Other;
  }
};

// If a company is provided, it is token instead of reading from the Me query.
const useLoadOrderForm = (
  providedCompany?: Pick<CompanyFragment, 'configuration'> | null,
) => {
  const { companyData: meCompany, companyConfiguration } = useMe();
  const terminalsEnabled = companyConfiguration?.terminalsEnabled === true;
  const { reset, control, getValues } = useFormContext<OrderFormFieldValues>();
  const { contactUuid, refetch: refetchContact } = useOrderFormContact();
  const getContactDefaultPackageSpec = useGetContactDefaultPackageSpec();
  const formOrderUuid = useWatch({ control, name: 'uuid' });
  const ffNoRecoveryTransfer = useFeatureFlag(
    FeatureFlag.FF_NO_RECOVERY_TRANSFER,
  );

  const companyData = providedCompany ?? meCompany;

  // For recording the snapshot diff. The snapshot in the db can be stale if
  // other actions were taken since
  const [initialOrder, setInitialOrder] =
    useState<StandardOrderFragmentFragment>();

  const [getStandardOrder] = useStandardOrderLazyQuery();

  /**
   * @param uuid
   * @param refetchOrderAfterSave
   * @param duplicate
   * @param standardOrderFragment Pass in the already queried data to avoid
   * needing to query it twice
   */
  const fetchData = async ({
    uuid,
    refetchOrderAfterSave,
    duplicate,
    standardOrderFragment,
  }: {
    uuid: string;
    refetchOrderAfterSave?: boolean;
    duplicate?: string | null;
    standardOrderFragment?: StandardOrderFragmentFragment;
  }) => {
    let order = standardOrderFragment;
    if (isNil(order)) {
      const res = await getStandardOrder({ variables: { uuid } });
      order = res.data?.standardOrder ?? undefined;
    }
    if (!isNil(order)) {
      setInitialOrder(order);
      let contactData: ShallowContactFragment | undefined;
      let contactDefaultPackageSpec: DefaultPackageSpec | undefined;
      if (!isNil(contactUuid)) {
        const [resContact, packageSpec] = await Promise.all([
          refetchContact({
            uuid: contactUuid,
          }),
          getContactDefaultPackageSpec(contactUuid),
        ]);
        contactData = resContact.data?.contact;
        contactDefaultPackageSpec = packageSpec;
      }

      const formData = await convertStandardOrderPayloadToFormData({
        standardOrder: order,
        terminalsEnabled,
        defaultPackageSpec: contactDefaultPackageSpec,
        // we only care to duplicate if it's the first time the form is being loaded
        duplicate: isNilOrEmptyString(formOrderUuid) ? duplicate : undefined,
        contactUuid: order.billingPartyContact.uuid,
        companyData,
        contact: contactData,
        refetchOrderAfterSave,
        useCentimeters: getValues('useCentimeters'),
        useKilograms: getValues('useKilograms'),
      });
      if (ffNoRecoveryTransfer) {
        // Force Recovery to be Pickup and Transfer to be Delivery.
        // This is a little hacky, not meant to be production code (it's for a demo).
        forceRecoveryToPickupAndTransferToDelivery(formData);
      }

      reset(formData);
    }
  };

  const initializeForm = async ({
    orderUuid,
    recurringTemplate,
    duplicate,
  }: {
    orderUuid: string | undefined | null;
    recurringTemplate: boolean;
    duplicate: string | null | undefined;
  }) => {
    if (!isNil(orderUuid)) {
      await fetchData({ uuid: orderUuid, duplicate });
    } else if (!isNil(companyData)) {
      let contactData: ShallowContactFragment | undefined;
      let contactDefaultPackageSpec: DefaultPackageSpec | undefined;
      if (!isNil(contactUuid)) {
        const [resContact, packageSpec] = await Promise.all([
          refetchContact({
            uuid: contactUuid,
          }),
          getContactDefaultPackageSpec(contactUuid),
        ]);
        contactData = resContact.data?.contact;
        contactDefaultPackageSpec = packageSpec;
      }
      const formData = await initializeOrderToFormData({
        contactUuid,
        companyData,
        contact: contactData,
        terminalsEnabled,
        defaultPackageSpec: contactDefaultPackageSpec,
        recurringTemplate,
        fulfillmentType: FulfillmentType.MultiTrip,
        ffNoRecoveryTransfer,
      });
      if (ffNoRecoveryTransfer) {
        // Force Recovery to be Pickup and Transfer to be Delivery.
        // This is a little hacky, not meant to be production code (it's for a demo).
        forceRecoveryToPickupAndTransferToDelivery(formData);
      }

      reset(formData);
    }
  };

  return { initialOrder, initializeForm, fetchData };
};

export { useLoadOrderForm };
