function Commit() {
  // eslint-disable-next-line no-console
  console.log(
    'VITE_RENDER_GIT_COMMIT:',
    import.meta.env.VITE_RENDER_GIT_COMMIT,
  );
  return import.meta.env.VITE_RENDER_GIT_COMMIT;
}

export default Commit;
