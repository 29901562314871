import { Document, Image, Page, StyleSheet, View } from '@react-pdf/renderer';
import { isNil, range } from 'lodash';
import React from 'react';
import { generateBarcode } from '../../../../common/utils/barcode';
import { type WarehouseLocationsLocationFragment } from '../../../../generated/graphql';

type WarehouseLocationsA4PdfProps = {
  readonly warehouseLocations: WarehouseLocationsLocationFragment[];
};

const WarehouseLocationsA4Pdf = ({
  warehouseLocations,
}: WarehouseLocationsA4PdfProps) => {
  const styles = StyleSheet.create({
    page: {
      padding: 2,
      fontFamily: 'Roboto',
      fontSize: '10px',
      flexDirection: 'row',
    },
    outerCell: {
      width: '50%',
      display: 'flex',
      flexDirection: 'column',
    },
    locationLabelCell: {
      width: '100%',
      height: '14%',
      display: 'flex',
      padding: '3px',
    },
  });

  const TOTAL_WAREHOUSE_LOCATIONS = warehouseLocations.length;
  const NUMBER_OF_BARCODES_PER_PAGE = 14;
  const NUM_PAGES = Math.ceil(
    TOTAL_WAREHOUSE_LOCATIONS / NUMBER_OF_BARCODES_PER_PAGE,
  );

  let count = 0;
  return (
    <Document>
      {range(NUM_PAGES).map((page) => {
        return (
          <Page key={page} size="A4" orientation="portrait" style={styles.page}>
            <View style={styles.outerCell}>
              {range(NUMBER_OF_BARCODES_PER_PAGE / 2).map((i) => {
                if (count >= warehouseLocations.length) {
                  return null;
                }
                const data = warehouseLocations?.[count]?.name ?? '-';
                const barcodeData = generateBarcode({
                  data,
                  width: 4,
                  height: 40,
                  displayValue: true,
                });
                if (isNil(barcodeData)) {
                  return null;
                }
                count += 1;
                return (
                  <View key={i} style={styles.locationLabelCell}>
                    <Image
                      source={{
                        uri: barcodeData,
                        method: 'GET',
                        headers: {},
                        body: '',
                      }}
                    />
                  </View>
                );
              })}
            </View>
            <View style={styles.outerCell}>
              {range(
                NUMBER_OF_BARCODES_PER_PAGE / 2,
                NUMBER_OF_BARCODES_PER_PAGE,
              ).map((i) => {
                if (count >= warehouseLocations.length) {
                  return null;
                }
                const data = warehouseLocations?.[count]?.name ?? '-';
                const barcodeData = generateBarcode({
                  data,
                  width: 4,
                  height: 40,
                  displayValue: true,
                });
                if (isNil(barcodeData)) {
                  return null;
                }
                count += 1;

                return (
                  <View key={i} style={styles.locationLabelCell}>
                    <Image
                      source={{
                        uri: barcodeData,
                        method: 'GET',
                        headers: {},
                        body: '',
                      }}
                    />
                  </View>
                );
              })}
            </View>
          </Page>
        );
      })}
    </Document>
  );
};

export default WarehouseLocationsA4Pdf;
