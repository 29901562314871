import { useCallback, useEffect } from 'react';
import { useBeforeUnload, useBlocker } from 'react-router-dom';

const useWarningOnExit = (
  shouldWarn: boolean,
  warningMessage = 'Are you sure that you want to leave?',
) => {
  const { state, proceed } = useBlocker(shouldWarn);
  const handleBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (shouldWarn) {
        event.preventDefault();
      }
    },
    [shouldWarn],
  );
  useBeforeUnload(handleBeforeUnload);

  useEffect(() => {
    if (
      state === 'blocked' && // eslint-disable-next-line no-alert
      globalThis.confirm(warningMessage)
    ) {
      proceed();
    }
  }, [warningMessage, state, proceed]);
};

export { useWarningOnExit };
