import {
  type FetchResult,
  type InternalRefetchQueriesInclude,
} from '@apollo/client';
import { isNil } from 'lodash';
import { useCallback, useState } from 'react';
import {
  DOCUMENT_TYPES_FOR_SELECTION,
  DOCUMENT_TYPES_FOR_SELECTION_WITH_ESIGN_FLOW,
} from 'shared/constants';
import {
  type AttachMobileSignedDocumentToOrderAndReplaceOriginalMutation,
  type DocumentType,
  useAttachMobileSignedDocumentToOrderAndReplaceOriginalMutation,
  useGenerateMobileSignedDocumentPreSignedPutUrlMutation,
} from '../../generated/graphql';
import { getDocumentTypeCopyUtil } from '../utils/utils';
import useMe from './use-me';

type UseDocumentsOutput = {
  uploadSignedDocumentToAwsAndReplaceOriginal: (args: {
    orderUuid: string;
    originalDocumentUuid: string;
    fileName: string;
    fileType: string;
    blob: Blob;
    refetchQueries?: InternalRefetchQueriesInclude;
  }) => Promise<
    | {
        newDocumentUuid: string | undefined;
        errorMessage: string | undefined;
      }
    | undefined
  >;
  uploadInProgress: boolean;
} & (
  | {
      enableDigitalSignatureFlow: boolean;
      loading: false;
      documentTypesForSelection: DocumentType[];
      getDocumentTypeCopy: (args: { documentType: DocumentType }) => string;
    }
  | {
      enableDigitalSignatureFlow: null;
      loading: true;
      documentTypesForSelection: null;
      getDocumentTypeCopy: null;
    }
);

const useDocuments = (): UseDocumentsOutput => {
  const [generateMobileSignedOrderPreSignedPutUrl] =
    useGenerateMobileSignedDocumentPreSignedPutUrlMutation();
  const [attachMobileSignedDocumentToOrderAndReplaceOriginal] =
    useAttachMobileSignedDocumentToOrderAndReplaceOriginalMutation();
  const { companyConfiguration, loading, user } = useMe();

  const [uploadInProgress, setUploadInProgress] = useState(false);
  const uploadSignedDocumentToAwsAndReplaceOriginal = async ({
    orderUuid,
    originalDocumentUuid,
    fileName,
    fileType,
    blob,
    refetchQueries,
  }: {
    orderUuid: string;
    originalDocumentUuid: string;
    fileName: string;
    fileType: string;
    blob: Blob;
    refetchQueries?:
      | InternalRefetchQueriesInclude
      | ((
          result: FetchResult<AttachMobileSignedDocumentToOrderAndReplaceOriginalMutation>,
        ) => InternalRefetchQueriesInclude)
      | undefined;
  }): Promise<
    | { newDocumentUuid: string | undefined; errorMessage: string | undefined }
    | undefined
  > => {
    setUploadInProgress(true);
    if (!isNil(orderUuid) && typeof orderUuid === 'string') {
      // if the file type is not pdf, we need to change the file extension to pdf
      // because our e-sign library in SignDigitalDocumentView gives us a pdf blob
      const fileExtension = fileName.split('.').pop();
      let newFileName = fileName;

      if (fileExtension !== 'pdf') {
        newFileName = `${fileName.slice(0, Math.max(0, fileName.lastIndexOf('.')))}.pdf`;
      }

      const putUrl = (
        await generateMobileSignedOrderPreSignedPutUrl({
          variables: {
            input: {
              fileName: newFileName,
              fileType,
              orderUuid,
              originalDocumentUuid,
            },
          },
        })
      ).data?.generateMobileSignedDocumentPreSignedPutUrl;

      if (isNil(putUrl)) {
        setUploadInProgress(false);
        return undefined;
      }

      const options = { headers: { 'Content-Type': 'application/pdf' } };
      const awsRes = await fetch(putUrl, {
        method: 'PUT',
        body: blob,
        ...options,
      });

      if (awsRes.status === 200) {
        const res = await attachMobileSignedDocumentToOrderAndReplaceOriginal({
          variables: {
            input: {
              originalDocumentUuid,
              fileName: newFileName,
              fileType,
              orderUuid,
            },
          },
          refetchQueries,
        });
        const createOrderRes =
          res.data?.attachMobileSignedDocumentToOrderAndReplaceOriginal;

        setUploadInProgress(false);
        return {
          newDocumentUuid: createOrderRes?.document?.uuid,
          errorMessage: createOrderRes?.errorMessage ?? undefined,
        };
      }
    }
    setUploadInProgress(false);
    return undefined;
  };

  const getDocumentTypeCopy = useCallback(
    ({ documentType }: { documentType: DocumentType }) => {
      return getDocumentTypeCopyUtil({
        documentType,
        enableDigitalSignatureFlow:
          companyConfiguration?.enableDigitalSignatureFlow ?? false,
      });
    },
    [companyConfiguration?.enableDigitalSignatureFlow],
  );

  const isMissingCompanyConfiguration =
    isNil(companyConfiguration) && !isNil(user);

  /**
   * Ensure that a loading state is returned for missing company configurations in regular user contexts.
   */
  if (loading || isMissingCompanyConfiguration) {
    return {
      uploadSignedDocumentToAwsAndReplaceOriginal,
      uploadInProgress,
      getDocumentTypeCopy: null,
      enableDigitalSignatureFlow: null,
      loading: true,
      documentTypesForSelection: null,
    };
  }

  return {
    uploadSignedDocumentToAwsAndReplaceOriginal,
    uploadInProgress,
    getDocumentTypeCopy,
    enableDigitalSignatureFlow:
      companyConfiguration?.enableDigitalSignatureFlow ?? false,
    loading: false,
    documentTypesForSelection:
      companyConfiguration?.enableDigitalSignatureFlow === true
        ? DOCUMENT_TYPES_FOR_SELECTION_WITH_ESIGN_FLOW
        : DOCUMENT_TYPES_FOR_SELECTION,
  };
};

export default useDocuments;
