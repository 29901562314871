// eslint-disable-next-line no-restricted-imports
import { FormControl, Grid, InputAdornment, TextField } from '@mui/material';
import currency from 'currency.js';
import { isNil } from 'lodash';
import { type ChangeEvent, useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { getValueWithinBounds } from './store/tariff-group-controller';
import useTariffGroupStore from './store/tariff-group-state-store';

const TariffGroupFlatRateEditor = () => {
  const [amountInputValue, setAmountInputValue] = useState<number>();
  const [rateMatrix, rateMultiplier, setRateMatrixSingleCoordinate] =
    useTariffGroupStore(
      (state) => [
        state.rateMatrix,
        state.rateMultiplier,
        state.setRateMatrixSingleCoordinate,
      ],
      shallow,
    );
  useEffect(() => {
    if (!isNil(rateMatrix)) {
      const firstColumn = rateMatrix[0];
      if (!isNil(firstColumn)) {
        setAmountInputValue(firstColumn[0]);
      }
    }
  }, [rateMatrix]);

  const rateOnChangeOrBlur = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const parsed = Number.parseFloat(event.target.value);
    if (Number.isNaN(parsed)) {
      setRateMatrixSingleCoordinate(0, 0, undefined);
    } else {
      const rate = currency(getValueWithinBounds(parsed), {
        precision: 2,
      }).value;
      setRateMatrixSingleCoordinate(0, 0, rate);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            size="small"
            label="Flat Rate"
            value={
              isNil(rateMultiplier)
                ? amountInputValue
                : (amountInputValue ?? 0) * ((rateMultiplier ?? 100) / 100)
            }
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            onBlur={rateOnChangeOrBlur}
            onChange={(event) => {
              setAmountInputValue(Number.parseFloat(event.target.value));
            }}
            onWheel={(e) => {
              (e.target as HTMLTextAreaElement).blur();
            }}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default TariffGroupFlatRateEditor;
