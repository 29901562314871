import {
  Button,
  Chip,
  CircularProgress,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from '@mui/material';
import { capitalCase, sentenceCase } from 'change-case';
import { isEmpty, isNil } from 'lodash';
import React, {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useState,
} from 'react';
import { filterNotNil } from 'shared/array';
import ClearTextFieldButton from '../../../../common/components/clear-text-field-button';
import { chooseForegroundColor } from '../../../../common/utils/colors';
import {
  type PaymentFragment,
  SortDirection,
  PaymentTableField,
  PaymentType,
  useCreditTypesQuery,
  usePaymentsByContactUuidLazyQuery,
  PaymentRail,
} from '../../../../generated/graphql';
import { getPaymentTypeColorForChip } from '../../utils';
import { PaymentAppliedStatus } from './constants';
import ContactPaymentListRow from './contact-payment-list-row';
import { formatPaymentRail, getPaymentRailColorForChip } from './utils';

const ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100];
const DEFAULT_ROWS_PER_PAGE = 10;

const ContactPaymentsList = ({
  contactUuid,
  paymentsListLoading,
  setPaymentsListLoading,
  refetchKey,
}: {
  readonly contactUuid: string;
  readonly paymentsListLoading: boolean;
  readonly setPaymentsListLoading: Dispatch<SetStateAction<boolean>>;
  readonly refetchKey?: number; // used to allow parent component to force a refetch
}) => {
  const [searchTextInput, setSearchTextInput] = useState<string>('');
  const [searchText, setSearchText] = useState<string>();
  const [paymentType, setPaymentType] = useState<PaymentType | string>('All');
  const [creditTypeUuid, setCreditTypeUuid] = useState<string | undefined>();
  const [paymentRail, setPaymentRail] = useState<PaymentRail | undefined>();
  const [sortByOption, setSortByOption] = useState<PaymentTableField>(
    PaymentTableField.Date,
  );
  const [sortByDirection, setSortByDirection] = useState<SortDirection>(
    SortDirection.Desc,
  );
  const [paymentAppliedStatus, setPaymentAppliedStatus] =
    useState<PaymentAppliedStatus>(PaymentAppliedStatus.ALL);
  const [payments, setPayments] = useState<PaymentFragment[] | undefined>();
  const [rowsPerPage, setRowsPerPage] = useState<number>(DEFAULT_ROWS_PER_PAGE);
  const [page, setPage] = useState<number>(0);

  const { data: creditTypesData } = useCreditTypesQuery();
  const [getPaymentsByContactUuid, { data, loading }] =
    usePaymentsByContactUuidLazyQuery();

  const fetchPayments = async ({
    first,
    after,
    last,
    before,
  }: {
    first?: number | null | undefined;
    after?: string | null | undefined;
    last?: number | null | undefined;
    before?: string | null | undefined;
  }) => {
    setPaymentsListLoading(true);
    let isFullyApplied;
    if (paymentAppliedStatus === PaymentAppliedStatus.APPLIED) {
      isFullyApplied = true;
    }
    if (paymentAppliedStatus === PaymentAppliedStatus.UNAPPLIED) {
      isFullyApplied = false;
    }

    const res = await getPaymentsByContactUuid({
      variables: {
        args: {
          first,
          after,
          last,
          before,
          contactUuid,
          paymentType:
            paymentType === 'All' ? undefined : (paymentType as PaymentType),
          creditTypeUuid,
          paymentRails: filterNotNil([paymentRail]),
          searchText,
          isFullyApplied,
          includeCount: true,
          sorts: [
            {
              sortBy: sortByOption,
              sortDirection: sortByDirection,
            },
          ],
        },
      },
    });
    setPayments(res.data?.paymentsV2.edges.map((edge) => edge.node));
    setPaymentsListLoading(false);
  };

  const prev = async () => {
    await fetchPayments({
      last: rowsPerPage,
      before: data?.paymentsV2.pageInfo.startCursor ?? undefined,
    });
  };
  const next = async () => {
    await fetchPayments({
      first: rowsPerPage,
      after: data?.paymentsV2.pageInfo.endCursor ?? undefined,
    });
  };

  const refresh = async (newRowsPerPage?: number) => {
    fetchPayments({ first: newRowsPerPage ?? rowsPerPage });
  };

  const handleRowsPerPageChange = (val: number) => {
    setRowsPerPage(val);
    setPage(0);
    refresh(val);
  };

  useEffect(() => {
    fetchPayments({ first: rowsPerPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contactUuid,
    sortByOption,
    sortByDirection,
    paymentType,
    creditTypeUuid,
    paymentRail,
    searchText,
    paymentAppliedStatus,
  ]);

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchKey]);

  const onTableCellClick = (field: PaymentTableField) => {
    setSortByDirection(
      sortByDirection === SortDirection.Asc
        ? SortDirection.Desc
        : SortDirection.Asc,
    );
    setSortByOption(field);
  };

  return (
    <Stack height="100%">
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" m={1} gap={1}>
          <TextField
            size="small"
            label="Search order or payment"
            InputProps={{
              style: { backgroundColor: 'white' },
              endAdornment: (
                <ClearTextFieldButton
                  searchText={searchTextInput}
                  handleClearSearchText={() => {
                    setSearchTextInput('');
                    setSearchText('');
                  }}
                />
              ),
            }}
            value={searchTextInput}
            sx={{ width: '250px' }}
            onKeyDown={async (e) => {
              if (e.key === 'Enter') {
                setSearchText(searchTextInput);
              }
            }}
            onChange={(e) => {
              setSearchTextInput(e.target.value);
            }}
          />
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              setSearchText(searchTextInput);
            }}
          >
            Search
          </Button>
          <FormControl sx={{ minWidth: 150 }}>
            <InputLabel id="payments-label">Payments</InputLabel>
            <Select
              required
              labelId="payments-label"
              label="Payments"
              value={paymentAppliedStatus}
              size="small"
              sx={{ backgroundColor: 'white' }}
              onChange={(e) => {
                setPaymentAppliedStatus(e.target.value as PaymentAppliedStatus);
              }}
            >
              {Object.values(PaymentAppliedStatus).map((balanceStatus) => (
                <MenuItem
                  key={balanceStatus}
                  id={balanceStatus}
                  value={balanceStatus}
                >
                  {sentenceCase(balanceStatus)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          labelRowsPerPage="Show"
          component="div"
          count={data?.paymentsV2.totalCount ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            disabled: loading || page === 0,
          }}
          nextIconButtonProps={{
            disabled:
              loading ||
              data?.paymentsV2.totalCount === 0 ||
              page + 1 ===
                Math.ceil((data?.paymentsV2.totalCount ?? 0) / rowsPerPage),
          }}
          onPageChange={(e, newPage: number) => {
            refresh();
            if (newPage > page) {
              next();
            } else {
              prev();
            }
            setPage(newPage);
          }}
          onRowsPerPageChange={(e) => {
            handleRowsPerPageChange(Number(e.target.value));
          }}
        />
      </Stack>
      <TableContainer sx={{ flex: 1 }}>
        <Table stickyHeader aria-label="invoice-preview-table" size="small">
          <TableHead>
            <TableRow>
              <TableCell width={70}>
                {loading && <CircularProgress size={18} />}
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortByOption === PaymentTableField.Date}
                  direction={
                    sortByDirection === SortDirection.Asc ? 'asc' : 'desc'
                  }
                  onClick={() => {
                    onTableCellClick(PaymentTableField.Date);
                  }}
                >
                  Date
                </TableSortLabel>
              </TableCell>
              <TableCell align="center">
                <Select
                  value={paymentType}
                  sx={{
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                  }}
                  size="small"
                  renderValue={(value) => {
                    return (
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="subtitle2">Transaction</Typography>
                        <Chip
                          size="small"
                          label={capitalCase(value)}
                          color={getPaymentTypeColorForChip(value)}
                        />
                      </Stack>
                    );
                  }}
                  onChange={(e) => {
                    setPaymentType(e.target.value);
                  }}
                >
                  <MenuItem value="All">
                    <Chip size="small" label="All" color="default" />
                  </MenuItem>
                  {Object.values(PaymentType).map((type) => (
                    <MenuItem key={type} value={type}>
                      <Chip
                        size="small"
                        label={capitalCase(type)}
                        color={getPaymentTypeColorForChip(type)}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell align="center">
                <Select
                  // Empty because the value comes from creditTypeUuid or paymentRail
                  displayEmpty
                  value=""
                  sx={{
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                  }}
                  size="small"
                  renderValue={() => {
                    let labelText = 'Credit type/Method';
                    let filterComponent: JSX.Element | undefined;
                    if (!isNil(creditTypeUuid)) {
                      const creditType = creditTypesData?.creditTypes.find(
                        (ct) => ct.uuid === creditTypeUuid,
                      );
                      const creditTypeColor = creditType?.color;
                      if (!isNil(creditType)) {
                        labelText = 'Category';
                        filterComponent = (
                          <Chip
                            size="small"
                            label={creditType?.name}
                            sx={{
                              backgroundColor: creditTypeColor,
                              color: isNil(creditTypeColor)
                                ? undefined
                                : chooseForegroundColor(creditTypeColor),
                            }}
                            color={
                              isNil(creditTypeColor) ? 'default' : undefined
                            }
                          />
                        );
                      }
                    } else if (!isNil(paymentRail)) {
                      labelText = 'Method';
                      filterComponent = (
                        <Chip
                          size="small"
                          label={formatPaymentRail(paymentRail)}
                          color={getPaymentRailColorForChip(paymentRail)}
                        />
                      );
                    }
                    return (
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="subtitle2">{labelText}</Typography>
                        {!isNil(filterComponent) && filterComponent}
                      </Stack>
                    );
                  }}
                >
                  <MenuItem
                    value="All"
                    onClick={() => {
                      setPaymentRail(undefined);
                      setCreditTypeUuid(undefined);
                    }}
                  >
                    All
                  </MenuItem>
                  <ListSubheader>Credit type</ListSubheader>
                  {creditTypesData?.creditTypes.map((creditType) => (
                    <MenuItem
                      key={creditType.uuid}
                      value={creditType.uuid}
                      onClick={() => {
                        setCreditTypeUuid(creditType.uuid);
                        setPaymentRail(undefined);
                      }}
                    >
                      <Chip
                        size="small"
                        label={creditType.name}
                        sx={{
                          backgroundColor: creditType.color,
                          color: isNil(creditType?.color)
                            ? undefined
                            : chooseForegroundColor(creditType.color),
                        }}
                        color={isNil(creditType.color) ? 'default' : undefined}
                      />
                    </MenuItem>
                  ))}
                  <ListSubheader>Payment method</ListSubheader>
                  {Object.values(PaymentRail).map((method) => (
                    <MenuItem
                      key={method}
                      value={method}
                      onClick={() => {
                        setPaymentRail(method as PaymentRail);
                        setCreditTypeUuid(undefined);
                      }}
                    >
                      <Chip
                        size="small"
                        label={formatPaymentRail(method)}
                        color={getPaymentRailColorForChip(method)}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell>Ref number</TableCell>
              <TableCell>Comments</TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortByOption === PaymentTableField.Amount}
                  direction={
                    sortByDirection === SortDirection.Asc ? 'asc' : 'desc'
                  }
                  onClick={() => {
                    onTableCellClick(PaymentTableField.Amount);
                  }}
                >
                  Total
                </TableSortLabel>
              </TableCell>
              <TableCell>Unapplied</TableCell>
              <TableCell>GL Terminal</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentsListLoading &&
              isNil(payments) &&
              [...Array.from({ length: 8 }).keys()].map((i) => (
                <TableRow key={i} sx={{ height: 75 }}>
                  <TableCell width={70} />
                  {[...Array.from({ length: 8 }).keys()].map((j) => (
                    <TableCell key={j}>
                      <Skeleton />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            {!paymentsListLoading && isEmpty(payments) && (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  <Typography>No payments</Typography>
                </TableCell>
              </TableRow>
            )}
            {payments?.map((payment) => (
              <ContactPaymentListRow
                key={payment.uuid}
                payment={payment}
                refetchPayments={refresh}
                setPaymentsListLoading={setPaymentsListLoading}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default ContactPaymentsList;
