import { Add, RemoveCircleOutline } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  type Dispatch,
  type SetStateAction,
  type ReactNode,
  useMemo,
  useRef,
} from 'react';
import SecondaryButton from '../../../../../common/components/SecondaryButton';
import {
  type FilterConstructionType,
  type GroupFilterConstructionType,
  FilterGroupOperator,
  EMPTY_GROUP_FILTER_CONSTRUCTION_TYPE,
} from '../../../../../common/filters/types';
import {
  getFilterGroupOperator,
  isFilterConstructionTypePartiallyEmpty,
  isGroupFilterConstructionType,
} from '../../../../../common/filters/utils';
import PalletButtonGroup from '../../../../../pallet-ui/button-group/pallet-button-group';
import { OrderTableFilterGroup } from './order-table-filter-group';

const POPOVER_CONTENT_WIDTH = '850px';

const TOP_LEVEL_OPERATOR_OPTIONS = [
  { value: FilterGroupOperator.AND, label: 'All of' },
  { value: FilterGroupOperator.OR, label: 'At least one of' },
];

export enum FilterGroupEditMode {
  Simple = 'SIMPLE',
  Advanced = 'ADVANCED',
}

/** If there are >1 filter groups within the top-level filter group, show the "advanced" UI */
export const getFilterGroupEditMode = (
  topLevelFilterGroup: GroupFilterConstructionType,
) => {
  const operator = getFilterGroupOperator(topLevelFilterGroup);
  const numFilterGroups = topLevelFilterGroup[operator]?.length ?? 0;
  return numFilterGroups > 1
    ? FilterGroupEditMode.Advanced
    : FilterGroupEditMode.Simple;
};

type EditOrderTableFiltersProps = {
  readonly tempFilter: FilterConstructionType[];
  readonly setTempFilter: Dispatch<SetStateAction<FilterConstructionType[]>>;
  readonly onClose: () => void;
  readonly onSave: () => void;
};

const EditOrderTableFilters = ({
  tempFilter,
  setTempFilter,
  onClose,
  onSave,
}: EditOrderTableFiltersProps) => {
  // There should be one top-level filter group; if there isn't, we'll make one
  const topLevelFilterGroup: GroupFilterConstructionType = useMemo(
    () => tempFilter.find(isGroupFilterConstructionType) ?? { and: tempFilter },
    [tempFilter],
  );
  const topLevelOperator = getFilterGroupOperator(topLevelFilterGroup);

  const filterGroupRef = useRef<HTMLDivElement>(null);

  // If there are any orphaned (non-group) filters, create a group for them
  const filterGroups = useMemo(
    () =>
      topLevelFilterGroup[topLevelOperator]?.map((f) =>
        isGroupFilterConstructionType(f) ? f : { and: [f] },
      ) ?? [],
    [topLevelFilterGroup, topLevelOperator],
  );

  const setTopLevelOperator = (newOperator: FilterGroupOperator) => {
    setTempFilter([{ [newOperator]: filterGroups }]);
  };

  const updateFilterGroup =
    (groupIndex: number) => (updatedGroup: FilterConstructionType) => {
      setTempFilter([
        {
          [topLevelOperator]: filterGroups.map((f, i) =>
            i === groupIndex ? updatedGroup : f,
          ),
        },
      ]);
    };

  const onAddFilterGroup = () => {
    setTempFilter([
      {
        [topLevelOperator]: [
          ...filterGroups,
          EMPTY_GROUP_FILTER_CONSTRUCTION_TYPE,
        ],
      },
    ]);
    // We use a timeout because the scrollHeight is not updated immediately after adding a new group
    setTimeout(() => {
      if (filterGroupRef.current) {
        filterGroupRef.current.scrollTo({
          top: filterGroupRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }
    }, 100);
  };

  const onDeleteFilterGroup = (groupIndex: number) => {
    if (filterGroups.length === 1) {
      setTempFilter([EMPTY_GROUP_FILTER_CONSTRUCTION_TYPE]);
    } else {
      setTempFilter([
        { [topLevelOperator]: filterGroups.filter((_, i) => i !== groupIndex) },
      ]);
    }
  };

  const isApplyDisabled =
    isFilterConstructionTypePartiallyEmpty(topLevelFilterGroup);

  const onApply = () => {
    onSave();
    onClose();
  };

  const mode = getFilterGroupEditMode(topLevelFilterGroup);

  let filtersContent: ReactNode;
  if (mode === FilterGroupEditMode.Simple) {
    const filterGroup = filterGroups[0];
    filtersContent = (
      <>
        <Typography variant="h6">Find orders</Typography>
        {filterGroup && (
          <OrderTableFilterGroup
            filterGroup={filterGroup}
            setFilterGroup={updateFilterGroup(0)}
          />
        )}
      </>
    );
  } else {
    filtersContent = (
      <>
        <Typography variant="h6">Find orders that match...</Typography>
        <Stack gap={1} direction="row" alignItems="center">
          <PalletButtonGroup
            options={TOP_LEVEL_OPERATOR_OPTIONS}
            value={topLevelOperator}
            onChange={setTopLevelOperator}
          />
          <Typography>these groups of filters:</Typography>
        </Stack>
        {filterGroups.map((filterGroup, groupIndex) => (
          <Stack
            // eslint-disable-next-line react/no-array-index-key
            key={groupIndex}
            direction="row"
            gap={1}
            alignItems="start"
          >
            <Box
              padding={1}
              borderRadius={1}
              border={1}
              borderColor={(theme) => theme.palette.borderColor.main}
              sx={{
                backgroundColor: (theme) => theme.palette.background.default,
              }}
              flexGrow={1}
            >
              <OrderTableFilterGroup
                filterGroup={filterGroup}
                setFilterGroup={updateFilterGroup(groupIndex)}
              />
            </Box>
            <IconButton
              size="small"
              sx={{ marginY: 1 }}
              onClick={() => {
                onDeleteFilterGroup(groupIndex);
              }}
            >
              <RemoveCircleOutline />
            </IconButton>
          </Stack>
        ))}
      </>
    );
  }

  return (
    <Stack maxHeight="80vh" maxWidth="80vw" width={POPOVER_CONTENT_WIDTH}>
      <Stack
        ref={filterGroupRef}
        gap={2}
        padding={2}
        sx={{ overflowY: 'auto' }}
      >
        {filtersContent}
      </Stack>
      <Stack>
        <Divider />
        <Stack
          direction="row"
          justifyContent="space-between"
          py={1.5}
          px={2}
          gap={2}
        >
          <Tooltip title="Add more conditions to find specific orders">
            <SecondaryButton
              variant="outlined"
              startIcon={<Add />}
              onClick={onAddFilterGroup}
            >
              Add filter group
            </SecondaryButton>
          </Tooltip>
          <Stack direction="row" gap={2}>
            <SecondaryButton sx={{ boxShadow: 'none' }} onClick={onClose}>
              Cancel
            </SecondaryButton>
            <Tooltip
              title={
                isApplyDisabled && 'Select operators and values for all filters'
              }
            >
              <span>
                <Button
                  variant="contained"
                  disabled={isApplyDisabled}
                  onClick={onApply}
                >
                  Apply
                </Button>
              </span>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export { EditOrderTableFilters };
