import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { isNil } from 'lodash';
import { useMemo, useState } from 'react';
import { exhaustive } from 'shared/switch';
import useMe from '../../../../../common/react-hooks/use-me';
import {
  type OrderForConsolidationFlowFragment,
  useOrderInConsolidationFlowLazyQuery,
  useOrderInConsolidationFlowQuery,
  useProrateOrdersMutation,
} from '../../../../../generated/graphql';
import { OrderDialog } from '../../../../orders/components/order-dialog';
import useBillingReviewActions from '../../../hooks/use-billing-review-actions';
import ConsolidatedOrderInformation from './consolidated-order-information';
import ConsolidatedOrderLeftSidebar from './consolidated-order-left-sidebar';
import SelectChargesToConsolidate, {
  type ChargesToConsolidate,
} from './select-charges-to-consolidate';

const ConsolidateOrderFlow = ({
  initialOrderUuid,
  onClose,
}: {
  readonly initialOrderUuid: string;
  readonly onClose: ({
    orderUuidsToRefetch,
  }: {
    orderUuidsToRefetch: string[];
  }) => Promise<void>;
}) => {
  const [openedOrderUuid, setOpenedOrderUuid] = useState<string | null>(null);

  // a running list of which order uuids have been edited since opening the
  // consolidate flow. This can be used to trigger re-loading the previewed
  // prorate in the child and also to tell the billing review component
  // that it needs to refresh these items in cache.
  const [orderUuidsToRefresh, setOrderUuidsToRefresh] = useState<string[]>([]);
  const [selectedChargesToConsolidate, setSelectedChargesToConsolidate] =
    useState<Record<ChargesToConsolidate, boolean> | null>(null);
  const theme = useTheme();
  const [searchedOrders, setSearchedOrders] = useState<
    OrderForConsolidationFlowFragment[]
  >([]);
  const [checkedNonPrimaryOrderUuids, setCheckedNonPrimaryOrderUuids] =
    useState<string[]>([]);

  const [disableConsolidateButton, setDisableConsolidateButton] =
    useState<boolean>(true);

  const [isClosingAfterProrate, setIsClosingAfterProrate] = useState(false);

  const [
    errorMessageForConsolidateAction,
    setErrorMessageForConsolidateAction,
  ] = useState<string>();
  const [refetchOrder, { loading: orderFetchLoading }] =
    useOrderInConsolidationFlowLazyQuery();

  const { fetchOrderCacheFirst } = useBillingReviewActions();

  const handleCloseConsolidateFlow = async () => {
    setIsClosingAfterProrate(true);
    try {
      // callback to tell underlying BRM to refetch orders that were checked since charges would change.
      await onClose({
        orderUuidsToRefetch: [...checkedNonPrimaryOrderUuids, initialOrderUuid],
      });
    } finally {
      setIsClosingAfterProrate(false);
    }
  };

  const [prorateOrders, { loading: prorateOrdersLoading }] =
    useProrateOrdersMutation({
      onCompleted: ({ prorateOrders: prorateOrdersResult }) => {
        const { __typename: typename } = prorateOrdersResult;
        switch (typename) {
          case 'ProrateOrdersSuccessOutput': {
            handleCloseConsolidateFlow();
            break;
          }
          case 'MutationErrorOutput': {
            setErrorMessageForConsolidateAction(prorateOrdersResult.message);
            break;
          }
          case undefined: {
            break;
          }
          default: {
            exhaustive(typename);
          }
        }
      },
    });

  const { companyConfiguration } = useMe();

  const {
    data: initialOrderData,
    loading: initialOrderLoading,
    refetch: refetchInitialOrder,
  } = useOrderInConsolidationFlowQuery({
    variables: { uuid: initialOrderUuid },
  });

  // when we want to refetch an order in the consolidate flow
  // we need to refetch it and make sure it gets set in the state variables
  // (like searchedOrders)
  const refetchOrderData = async (orderUuid: string) => {
    // this refetches the order in billing review so the cached
    // value is overwritten.
    fetchOrderCacheFirst({ orderUuid, bypassCache: true });

    if (orderUuid === initialOrderUuid) {
      await refetchInitialOrder();
    }
    // if the order opened was a searched one, refetch and set it in searched orders
    else if (searchedOrders.map((o) => o.uuid).includes(orderUuid)) {
      const res = await refetchOrder({
        variables: {
          uuid: orderUuid,
        },
      });

      const standardOrder = res.data?.standardOrder;
      if (!isNil(standardOrder)) {
        setSearchedOrders((searchedOrders_) =>
          searchedOrders_.map((o) => {
            if (o.uuid === openedOrderUuid) return standardOrder;
            return o;
          }),
        );
      }
    }
  };

  // when closing the order form, this callback is called
  const handleCloseOrderFormModal = async () => {
    if (!isNil(openedOrderUuid)) {
      // setOrderUuidsToRefresh tells the child component to refresh the prorating preview.
      setOrderUuidsToRefresh([...orderUuidsToRefresh, openedOrderUuid]);
      await refetchOrderData(openedOrderUuid);
    }
    setOpenedOrderUuid(null);
  };

  const checkedNonPrimaryOrders = useMemo(() => {
    return searchedOrders.filter(
      (o) => o?.uuid != null && checkedNonPrimaryOrderUuids.includes(o.uuid),
    );
  }, [checkedNonPrimaryOrderUuids, searchedOrders]);

  const handleClickConsolidate = async () => {
    await prorateOrders({
      variables: {
        input: {
          primaryOrderUuid: initialOrderUuid,
          ordersToConsolidateWithUuids: checkedNonPrimaryOrders.map(
            (o) => o.uuid,
          ),
          shouldProrateInbound: selectedChargesToConsolidate?.INBOUND ?? false,
          shouldProrateOutbound:
            selectedChargesToConsolidate?.OUTBOUND ?? false,
        },
      },
    });
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!isNil(errorMessageForConsolidateAction)}
      >
        <Alert
          severity="error"
          onClose={() => {
            setErrorMessageForConsolidateAction(undefined);
          }}
        >
          {errorMessageForConsolidateAction}
        </Alert>
      </Snackbar>
      <OrderDialog
        open={!isNil(openedOrderUuid)}
        orderUuid={openedOrderUuid}
        onClose={handleCloseOrderFormModal}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          p: '20px',
          pb: '10px',
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6" sx={{ fontSize: '18px' }}>
            Consolidate{' '}
            {initialOrderData?.standardOrder?.billingPartyContact.displayName ??
              '-'}{' '}
            orders
          </Typography>
          <Stack direction="row" gap={2}>
            <Button
              sx={{ color: theme.palette.grey[500] }}
              onClick={handleCloseConsolidateFlow}
            >
              Cancel
            </Button>
            <Button
              disabled={
                disableConsolidateButton ||
                prorateOrdersLoading ||
                isClosingAfterProrate
              }
              variant="contained"
              onClick={handleClickConsolidate}
            >
              Consolidate
            </Button>
          </Stack>
        </Stack>
        <Stack
          height="85vh"
          direction="row"
          sx={{ backgroundColor: theme.palette.grey[200] }}
        >
          {!isNil(initialOrderData?.standardOrder) && (
            <>
              {(initialOrderLoading || orderFetchLoading) && (
                <CircularProgress />
              )}
              {!initialOrderLoading &&
                !orderFetchLoading &&
                !isNil(initialOrderData?.standardOrder) && (
                  <ConsolidatedOrderLeftSidebar
                    primaryOrder={initialOrderData?.standardOrder}
                    searchedOrders={searchedOrders}
                    setSearchedOrders={setSearchedOrders}
                    checkedOrderUuids={checkedNonPrimaryOrderUuids}
                    setCheckedOrderUuids={setCheckedNonPrimaryOrderUuids}
                    setOpenedOrderUuid={(uuid: string) => {
                      setOpenedOrderUuid(uuid);
                    }}
                    lineHaulEnabled={
                      companyConfiguration?.lineHaulEnabled ?? false
                    }
                  />
                )}
              <Stack width="50%" padding={2} gap={2} height="80vh">
                <SelectChargesToConsolidate
                  selectedChargesToConsolidate={selectedChargesToConsolidate}
                  setSelectedChargesToConsolidate={
                    setSelectedChargesToConsolidate
                  }
                  lineHaulEnabled={
                    companyConfiguration?.lineHaulEnabled ?? false
                  }
                  primaryOrder={initialOrderData?.standardOrder}
                />
                {!isNil(selectedChargesToConsolidate) && (
                  <ConsolidatedOrderInformation
                    primaryOrderUuid={initialOrderUuid}
                    ordersToConsolidateWithUuids={checkedNonPrimaryOrders.map(
                      (o) => o.uuid,
                    )}
                    selectedChargesToConsolidate={selectedChargesToConsolidate}
                    orderUuidsToRefresh={orderUuidsToRefresh}
                    setOpenedOrderUuid={(uuid: string) => {
                      setOpenedOrderUuid(uuid);
                    }}
                    setDisableConsolidateButton={(disabled: boolean) => {
                      setDisableConsolidateButton(disabled);
                    }}
                  />
                )}
              </Stack>
            </>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default ConsolidateOrderFlow;
