import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { type Dispatch, type SetStateAction, useEffect } from 'react';
import {
  type FindStorageOrderStorageUnitsFragment,
  type StorageOrderFragment,
  useStorageOrderStorageUnitsQuery,
} from '../../../generated/graphql';

type StorageOrdersMenuProps = {
  readonly storageOrder: StorageOrderFragment;

  readonly setStorageUnitLabelsModalOpen: Dispatch<SetStateAction<boolean>>;

  readonly setSelectedStorageOrderForLabels: Dispatch<
    SetStateAction<StorageOrderFragment | undefined>
  >;

  readonly setSelectedStorageUnitsForLabels: Dispatch<
    SetStateAction<FindStorageOrderStorageUnitsFragment[]>
  >;
};
const StorageOrdersMenu = ({
  storageOrder,
  setStorageUnitLabelsModalOpen,
  setSelectedStorageOrderForLabels,
  setSelectedStorageUnitsForLabels,
}: StorageOrdersMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data: storageOrderStorageUnitsData } =
    useStorageOrderStorageUnitsQuery({
      variables: {
        findStorageOrderStorageUnitsInput: {
          storageOrderUuid: storageOrder.uuid,
        },
      },
    });

  useEffect(() => {
    if (open) {
      setSelectedStorageOrderForLabels(storageOrder);
    }
  }, [open]);

  const storageUnits =
    storageOrderStorageUnitsData?.storageOrderStorageUnits?.storageUnits ?? [];

  return (
    <>
      <Tooltip arrow title="View options">
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="storage-unit-details-options-menu"
        MenuListProps={{
          'aria-labelledby': 'storage-unit-details-options-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          key="print-label"
          onClick={() => {
            if (!isEmpty(storageUnits)) {
              setSelectedStorageUnitsForLabels(storageUnits);
              setStorageUnitLabelsModalOpen(true);
            }
          }}
        >
          Print labels
        </MenuItem>
      </Menu>
    </>
  );
};

export default StorageOrdersMenu;
