import { zodResolver } from '@hookform/resolvers/zod';
import { isNil } from 'lodash';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { v4 } from 'uuid';
import {
  type CsvOrderMappingDetailedFragment,
  CsvOrderMappingsForEmailToolDocument,
  StopImportType,
  useCreateCsvOrderMappingMutation,
  useUpdateCsvOrderMappingMutation,
} from '../../../../generated/graphql';
import {
  type CsvImportConfigurationFormData,
  csvImportConfigurationSchema,
  REQUIRED_TARGET_FIELDS,
} from './csv-import-configuration-form-schema';

export const DEFAULT_CSV_IMPORT_CONFIGURATION_FORM_DATA: CsvImportConfigurationFormData =
  {
    uuid: v4(),
    name: '',
    stopImportType: StopImportType.DynamicInboundOrOutbound,
    fieldMappings: REQUIRED_TARGET_FIELDS.map((field) => ({
      sourceField: '',
      targetField: field,
    })),
    defaultDeadlineType: null,
    defaultDeadlineDate: null,
    defaultStopType: null,
    defaultFreightBillingMethod: null,
    contactUuid: null,
    defaultPickupOrDelivery: null,
  };

const useCsvImportConfigurationForm = ({
  isEditMode,
  companyUuid,
  onSuccessfulCreate,
}: {
  isEditMode: boolean;
  companyUuid: string;
  onSuccessfulCreate: (uuid: string) => void;
}) => {
  const form = useForm<CsvImportConfigurationFormData>({
    resolver: zodResolver(csvImportConfigurationSchema), // Apply the zodResolver
    defaultValues: DEFAULT_CSV_IMPORT_CONFIGURATION_FORM_DATA,
  });

  const [updateCsvOrderMapping] = useUpdateCsvOrderMappingMutation({
    refetchQueries: [CsvOrderMappingsForEmailToolDocument],
  });
  const [createCsvOrderMapping] = useCreateCsvOrderMappingMutation({
    refetchQueries: [CsvOrderMappingsForEmailToolDocument],
  });

  const repopulateForm = (data: CsvOrderMappingDetailedFragment) => {
    const { reset } = form;

    let stopImportType: StopImportType =
      StopImportType.DynamicInboundOrOutbound;
    if (data.enableDynamicInboundOrOutboundStop) {
      stopImportType = StopImportType.DynamicInboundOrOutbound;
    } else if (data.enableInboundStop && data.enableOutboundStop) {
      stopImportType = StopImportType.Both;
    } else if (data.enableInboundStop) {
      stopImportType = StopImportType.InboundOnly;
    } else if (data.enableOutboundStop) {
      stopImportType = StopImportType.OutboundOnly;
    }

    reset({
      uuid: data.uuid,
      name: data.name,
      stopImportType,
      fieldMappings: data.csvOrderFieldMappings.map((fieldMapping) => ({
        sourceField: fieldMapping.sourceColumnName,
        targetField: fieldMapping.targetFieldType,
      })),
      defaultDeadlineType: data.deadlineTypeDefaultValue ?? null,
      defaultDeadlineDate: data.deadlineDateDefaultValue ?? null,
      defaultStopType: data.stopTypeDefaultValue ?? null,
      defaultFreightBillingMethod:
        data.freightBillingMethodDefaultValue ?? null,
      defaultPickupOrDelivery: data.pickupOrDeliveryDefaultValue ?? null,
      contactUuid: data.contact?.uuid ?? null,
    });
  };

  const onSubmit: SubmitHandler<CsvImportConfigurationFormData> = async (
    data: CsvImportConfigurationFormData,
  ) => {
    // by this point the form has been validated.
    if (isEditMode) {
      await updateCsvOrderMapping({
        variables: {
          updateCsvOrderMappingInput: {
            uuid: data.uuid,
            name: data.name,
            stopImportType: data.stopImportType,
            contactUuid: data.contactUuid,
            deadlineTypeDefaultValue: data.defaultDeadlineType,
            freightBillingMethodDefaultValue: data.defaultFreightBillingMethod,
            pickupOrDeliveryDefaultValue: data.defaultPickupOrDelivery,
            csvOrderFieldMappingCreateInputs: data.fieldMappings.map(
              (fieldMapping) => ({
                csvOrderMappingUuid: data.uuid,
                sourceColumnName: fieldMapping.sourceField,
                targetFieldType: fieldMapping.targetField,
              }),
            ),
          },
        },
      });
    } else {
      const res = await createCsvOrderMapping({
        variables: {
          createCsvOrderMappingInput: {
            name: data.name,
            companyUuid,
            stopImportType: data.stopImportType,
            enableDynamicInboundOrOutboundStop:
              data.stopImportType === StopImportType.DynamicInboundOrOutbound,
            enableInboundStop:
              data.stopImportType === StopImportType.InboundOnly ||
              data.stopImportType === StopImportType.Both,
            enableOutboundStop:
              data.stopImportType === StopImportType.OutboundOnly ||
              data.stopImportType === StopImportType.Both,
            contactUuid: data.contactUuid,
            deadlineTypeDefaultValue: data.defaultDeadlineType,
            freightBillingMethodDefaultValue: data.defaultFreightBillingMethod,
            pickupOrDeliveryDefaultValue: data.defaultPickupOrDelivery,
            csvOrderFieldMappingCreateInputs: data.fieldMappings.map(
              (fieldMapping) => ({
                csvOrderMappingUuid: data.uuid,
                sourceColumnName: fieldMapping.sourceField,
                targetFieldType: fieldMapping.targetField,
              }),
            ),
          },
        },
      });

      if (!isNil(res.data?.createCsvOrderMapping.uuid)) {
        onSuccessfulCreate(res.data.createCsvOrderMapping.uuid);
      }
    }
  };

  const handleSaveForm = () => {
    form.handleSubmit(onSubmit)();
  };

  return { form, repopulateForm, handleSaveForm };
};

export default useCsvImportConfigurationForm;
