import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { ReportAggregationPeriod } from '../../generated/graphql';
import {
  type CustomerReportBucketData,
  type DriverReportBucketData,
  type OrderReportBucketData,
  type ServiceLevelReportBucketData,
  type StationReportBucketData,
  type TerminalReportBucketData,
} from './types/report-types';

export type ReportBucketData = Partial<OrderReportBucketData> &
  Partial<DriverReportBucketData> &
  Partial<CustomerReportBucketData> &
  Partial<ServiceLevelReportBucketData> &
  Partial<TerminalReportBucketData> &
  Partial<StationReportBucketData>;

export function descendingComparator(
  a: ReportBucketData,
  b: ReportBucketData,
  orderBy: keyof ReportBucketData | undefined,
): number {
  if (!isNil(orderBy)) {
    const first = a[orderBy]?.toString();
    const second = b[orderBy]?.toString();
    if (!isNil(first) && !isNil(second)) {
      if (orderBy === 'startDate') {
        return Date.parse(first) > Date.parse(second) ? -1 : 1;
      }
      if (
        orderBy === 'driverName' ||
        orderBy === 'driverType' ||
        orderBy === 'displayName' ||
        orderBy === 'terminalName'
      ) {
        return first.localeCompare(second);
      }
      return Number.parseFloat(second) - Number.parseFloat(first);
    }
  }
  return 0;
}

export enum Order {
  ASC = 'asc',
  DESC = 'desc',
}

export function getComparator(
  order: Order,
  orderBy: keyof ReportBucketData | undefined,
) {
  return order === Order.DESC
    ? (a: ReportBucketData, b: ReportBucketData) =>
        descendingComparator(a, b, orderBy)
    : (a: ReportBucketData, b: ReportBucketData) =>
        -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const getOrderReportRowLabel = (
  reportAggregationPeriod: ReportAggregationPeriod | undefined,
  row: ReportBucketData,
): string | undefined => {
  switch (reportAggregationPeriod) {
    case ReportAggregationPeriod.Day: {
      return row.startDateLabel;
    }
    case ReportAggregationPeriod.Week: {
      return `${row.startDateLabel} - ${row.endDateLabel}`;
    }
    case ReportAggregationPeriod.Month: {
      return `${row.monthLabel} ${dayjs(row.startDate).utc().year()}`;
    }
    case ReportAggregationPeriod.Year: {
      return row.yearLabel;
    }
    default: {
      return undefined;
    }
  }
};

export type HeadCell = {
  key: keyof ReportBucketData;
  label: string;
  numeric: boolean;
};

/**
 * Given a numeric monetary amount in US dollars, returns a formatted version of the amount in US dollars.
 * @param amount
 */
export function formatMoney({
  amount,
}: {
  amount: number | null | undefined;
}): string {
  if (isNil(amount)) {
    return '-';
  }
  const usDollarFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return usDollarFormat.format(amount);
}

/**
 * Given a numeric weight value, returns a string representation of the weight rounded to the nearest pound.
 * @param weight
 */
export function formatWeight({
  weight,
}: {
  weight: number | null | undefined;
}): string {
  if (isNil(weight)) {
    return '-';
  }
  return Math.round(weight).toString();
}
