import {
  Box,
  Button,
  Dialog,
  FormLabel,
  Stack,
  type SxProps,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import AddressAutocompleteForm from '../../../common/components/address-autocomplete-form';
import useMe from '../../../common/react-hooks/use-me';
import {
  type CreateRecoveryTerminalMutation,
  RecoveryTerminalsDocument,
  type RecoveryTerminalFragment,
  useCreateAddressMutation,
  useCreateRecoveryTerminalMutation,
  useUpdateAddressMutation,
  useUpdateRecoveryTerminalMutation,
} from '../../../generated/graphql';
import { type AddressFormField } from '../../addresses/redux/addresses-values-slice';
import CreateOrEdit from '../enums/create-or-edit';

const styles = {
  modalInnerContainer: {
    bgcolor: 'background.paper',
    boxShadow: 24,
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    p: 4,
  } as SxProps,
};

type CreatedRecoveryTerminal =
  CreateRecoveryTerminalMutation['createAirportInfo'];

const RecoveryTerminalsModal = ({
  createOrEdit,
  open,
  onClose,
  selectedRecoveryTerminal,
  onCreated,
}: {
  readonly createOrEdit: CreateOrEdit;
  readonly open: boolean;
  readonly onClose: () => void;
  readonly selectedRecoveryTerminal: RecoveryTerminalFragment | undefined;
  readonly onCreated?: (recoveryTerminal: CreatedRecoveryTerminal) => void;
}) => {
  const theme = useTheme();
  const [currentAddress, setCurrentAddress] = useState<
    AddressFormField | undefined
  >(undefined);

  const [iataCode, setIataCode] = useState('');
  const [terminal, setTerminal] = useState('');

  const { companyUuid } = useMe();

  const [createRecoveryTerminal] = useCreateRecoveryTerminalMutation({
    refetchQueries: [RecoveryTerminalsDocument],
    onCompleted: (data) => {
      onCreated?.(data.createAirportInfo);
    },
  });

  const [updateRecoveryTerminal] = useUpdateRecoveryTerminalMutation({
    refetchQueries: [RecoveryTerminalsDocument],
  });

  const [createAddress] = useCreateAddressMutation();
  const [updateAddress] = useUpdateAddressMutation({
    refetchQueries: [RecoveryTerminalsDocument],
  });

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (isNil(selectedRecoveryTerminal)) {
      setIataCode('');
      setTerminal('');
      setCurrentAddress(undefined);
    } else {
      setIataCode(selectedRecoveryTerminal.iataCode);
      setTerminal(selectedRecoveryTerminal.terminal);
      setCurrentAddress({
        ...selectedRecoveryTerminal.address,
        isLocal: true,
      });
    }
  }, [selectedRecoveryTerminal]);

  const handleAddressChange = useCallback(
    (_isAutofillChange: boolean, newAddress?: AddressFormField) => {
      if (isNil(newAddress)) {
        setCurrentAddress(undefined);
      } else {
        setCurrentAddress(newAddress);
      }
    },
    [],
  );

  const handleReset = () => {
    onClose();
    setIataCode('');
    setTerminal('');
    setCurrentAddress(undefined);
  };

  const validateInputs = useCallback(() => {
    if (isEmpty(terminal)) {
      setErrorMessage('Please enter a name for this terminal.');
      return false;
    }

    if (isNil(currentAddress)) {
      setErrorMessage('Please enter an address.');
      return false;
    }
    return true;
  }, [terminal, currentAddress]);

  const handleSave = async () => {
    const isValid = validateInputs();
    if (!isValid) {
      return;
    }

    try {
      if (createOrEdit === CreateOrEdit.Create) {
        const { data: addressData } = await createAddress({
          variables: {
            input: {
              addressCreateInput: {
                name: currentAddress?.name ?? '',
                line1: currentAddress?.line1 ?? '',
                line2: currentAddress?.line2 ?? '',
                city: currentAddress?.city ?? '',
                state: currentAddress?.state ?? '',
                zip: currentAddress?.zip ?? '',
                country: currentAddress?.country ?? '',
              },
            },
          },
        });
        await createRecoveryTerminal({
          variables: {
            iataCode,
            terminal,
            addressUuid: addressData?.createAddress.uuid ?? '',
            companyUuid: companyUuid ?? '',
          },
        });
      }
      if (
        createOrEdit === CreateOrEdit.Edit &&
        !isNil(selectedRecoveryTerminal)
      ) {
        let addressData;
        if (currentAddress?.uuid === selectedRecoveryTerminal.address.uuid) {
          await updateAddress({
            variables: {
              input: {
                addressUpdateInput: {
                  uuid: selectedRecoveryTerminal.address.uuid,
                  name: currentAddress?.name ?? '',
                  line1: currentAddress?.line1 ?? '',
                  line2: currentAddress.line2 ?? '',
                  city: currentAddress?.city ?? '',
                  state: currentAddress?.state ?? '',
                  zip: currentAddress?.zip ?? '',
                  country: currentAddress?.country ?? '',
                  preventCoordRecompute: false,
                  latitude: null,
                  longitude: null,
                },
              },
            },
          });
        } else {
          const { data } = await createAddress({
            variables: {
              input: {
                addressCreateInput: {
                  name: currentAddress?.name ?? '',
                  line1: currentAddress?.line1 ?? '',
                  line2: currentAddress?.line2 ?? '',
                  city: currentAddress?.city ?? '',
                  state: currentAddress?.state ?? '',
                  zip: currentAddress?.zip ?? '',
                  country: currentAddress?.country ?? '',
                },
              },
            },
          });
          addressData = data;
        }
        await updateRecoveryTerminal({
          variables: {
            uuid: selectedRecoveryTerminal.uuid,
            iataCode,
            terminal,
            addressUuid:
              addressData?.createAddress.uuid ??
              selectedRecoveryTerminal.address.uuid,
          },
        });
      }
    } catch {
      setErrorMessage(
        'An error occurred when submitting the form, please try again.',
      );
      return;
    }
    handleReset();
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <Box sx={styles.modalInnerContainer}>
        <Typography variant="h6">{createOrEdit} Recovery Terminal</Typography>
        <Stack direction="column" spacing={1}>
          <Stack direction="row" spacing={1}>
            <TextField
              fullWidth
              label="Terminal name"
              size="small"
              value={terminal}
              onChange={(e) => {
                setTerminal(e.target.value);
                setErrorMessage('');
              }}
            />
            <TextField
              fullWidth
              label="IATA code (optional)"
              helperText="3-letter identifier for an airport. Ex: PHX"
              size="small"
              value={iataCode.toUpperCase()}
              onChange={(e) => {
                setIataCode(e.target.value.trim().toUpperCase());
                setErrorMessage('');
              }}
            />
          </Stack>
          <FormLabel component="legend">Address</FormLabel>
          <AddressAutocompleteForm
            newStyling
            currentAddress={currentAddress}
            handleChange={handleAddressChange}
          />
        </Stack>
        <Box>
          <Button
            variant="contained"
            sx={{ float: 'right' }}
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>
        {!isEmpty(errorMessage) && (
          <Typography fontSize="15px" color={theme.palette.error.main}>
            {errorMessage}
          </Typography>
        )}
      </Box>
    </Dialog>
  );
};

export default RecoveryTerminalsModal;
