import { Alert, Box, Divider, Snackbar, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CUSTOMER_PAGE_UUID_STORAGE_KEY } from './constants';
import CustomerProfile from './customer';
import CustomerList from './customer-list';

const Customers = () => {
  const theme = useTheme();
  const [showSuccessfulPaymentCreation, setShowSuccessfulPaymentCreation] =
    useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openedCustomerUuid, setOpenedCustomerUuid] = useState<
    string | undefined
  >();

  useEffect(() => {
    // Attempt to retrieve the customer UUID from localStorage when the component mounts
    // so that we can redirect to the customer profile page automatically
    const storedUuid = localStorage.getItem(CUSTOMER_PAGE_UUID_STORAGE_KEY);
    if (!isNil(storedUuid)) {
      setOpenedCustomerUuid(storedUuid);
      setSearchParams((sp) => {
        const newParams = new URLSearchParams(sp);
        newParams.set('customerUuid', storedUuid);
        return newParams;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customerUuid = searchParams.get('customerUuid');
  useEffect(() => {
    if (typeof customerUuid === 'string') {
      setOpenedCustomerUuid(customerUuid);
      localStorage.setItem(CUSTOMER_PAGE_UUID_STORAGE_KEY, customerUuid);
    } else {
      setOpenedCustomerUuid(undefined);
    }
  }, [customerUuid]);

  return (
    <Stack spacing={2} sx={{ height: '100%', backgroundColor: 'white' }}>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showSuccessfulPaymentCreation}
        onClose={() => {
          setShowSuccessfulPaymentCreation(false);
        }}
      >
        <Alert>Successfully created payment</Alert>
      </Snackbar>
      <Stack height="100%">
        <Divider sx={{ zIndex: 1 }} />
        <Stack flexDirection="row" height="100%">
          <Box
            sx={{
              borderRight: 1,
              borderRightColor: theme.palette.borderColor.main,
              height: '100%',
              transition: '0.15s',
              flex: 1,
            }}
          >
            <CustomerList openedCustomerUuid={openedCustomerUuid} />
          </Box>
          {!isNil(openedCustomerUuid) && (
            <CustomerProfile openedCustomerUuid={openedCustomerUuid} />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Customers;
