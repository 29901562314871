import { Typography, useTheme } from '@mui/material';
import { isEmpty } from 'lodash';
import Pluralize from 'pluralize';
import { useState } from 'react';
import { type FormattedOrderFragment } from '../../../../../generated/graphql';
import PhotosModalNew from './photos-modal-new';

const PhotosComponentNew = ({
  order,
}: {
  readonly order: FormattedOrderFragment | undefined;
}) => {
  const theme = useTheme();
  const [showPhotosModal, setShowPhotosModal] = useState(false);
  const photos = order?.photosField?.orderTablePhotos ?? [];
  return (
    <>
      <PhotosModalNew
        open={showPhotosModal}
        setOpen={setShowPhotosModal}
        order={order}
      />
      <Typography
        sx={
          isEmpty(photos)
            ? { fontSize: '12px' }
            : {
                color: theme.palette.primary.main,
                fontSize: '12px',
                textDecoration: 'underline',
                cursor: 'pointer',
              }
        }
        onClick={() => {
          isEmpty(photos) ? undefined : setShowPhotosModal(true);
        }}
      >
        {photos.length} {Pluralize('docs', photos.length)}
      </Typography>
    </>
  );
};

export default PhotosComponentNew;
