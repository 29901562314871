import { CircularProgress, TextField } from '@mui/material';
import { isNil } from 'lodash';
import React, { useEffect, useState, memo } from 'react';
import { shallow } from 'zustand/shallow';
import { TariffModalMode } from '../store/tariff-group-controller';
import useTariffGroupStore from '../store/tariff-group-state-store';

const NameField = () => {
  const [name, setName, modalMode] = useTariffGroupStore(
    (state) => [state.name, state.setName, state.modalMode],
    shallow,
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (modalMode === TariffModalMode.CREATE || (!isNil(name) && loading)) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, modalMode]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <TextField
      fullWidth
      size="small"
      id="outlined-basic"
      variant="outlined"
      label="Name"
      value={name ?? ''}
      onChange={(event) => {
        setName(event.target.value);
      }}
    />
  );
};

export default memo(NameField);
