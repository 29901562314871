import { type CSSObject } from 'styled-components';
import theme from '../../../../theme';

type BadgePosition = `${string}px` | `${string}%`;

// Style for the cutout effect
export const getCutoutStyle = (
  color: string,
  position: BadgePosition,
): CSSObject => ({
  background: `radial-gradient(circle at calc(100% - ${position}) calc(0% + ${position}), transparent 6px, ${color} 6px)`,
});

// Style for the badge indicator
const getBadgeIndicatorStyle = (position: BadgePosition): CSSObject => ({
  '&:before': {
    content: '""',
    position: 'absolute',
    top: position,
    right: position,
    width: '7.5px',
    height: '7.5px',
    backgroundColor: theme.palette.airfreightBlue[60],
    borderRadius: '50%',
    transform: 'translate(50%, -50%)',
  },
});

export const getBadgeStyle = (
  backgroundColor: string,
  /** How far to inset the badge relative to the top right corner */
  badgePosition: BadgePosition,
): CSSObject => ({
  ...getBadgeIndicatorStyle(badgePosition),
  ...getCutoutStyle(backgroundColor, badgePosition),
});
