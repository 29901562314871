import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  type SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isEmpty, isNil, isString } from 'lodash';
import { type FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonLink from '../../../../common/components/buttons/button-link';
import PhoneInputMask from '../../../../common/components/phone/phone-input-mask';
import { FeatureFlag } from '../../../../common/feature-flags';
import useDrivers from '../../../../common/react-hooks/use-drivers';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import useMe from '../../../../common/react-hooks/use-me';
import useTerminals from '../../../../common/react-hooks/use-terminals';
import {
  isNilOrEmptyString,
  validatePhoneNumber,
} from '../../../../common/utils/utils';
import {
  type DriverCreateInput,
  DriverPayType,
  type DriverQuery,
  DriverType,
  useArchiveDriverMutation,
  useCreateDriverMutation,
  useDriverByPhoneNumberLazyQuery,
  useDriverQualificationsQuery,
  useDriverQuery,
  useEquipmentsQuery,
  useRestoreDriverMutation,
  useUpdateDriverMutation,
} from '../../../../generated/graphql';
import ArchiveActionComponent, {
  ArchiveableEntity,
} from '../common/archive-action-component';
import { DriverSettlementSection } from './demo/driver-settlement-section';
import { DRIVER_PAY_TYPES, type DriverPayTypeDisplay } from './drivers-table';

const allDriverTypes = Object.values(DriverType);

const THIS_FIELD_IS_REQUIRED = 'This field is required';
const POSITIVE_NUMBER_REQUIRED = 'Must be a positive number';
const INVALID_PHONE_NUMBER = 'Invalid phone number';

const ALL_TERMINALS_ITEM = 'ALL_TERMINALS';

const styles: Record<string, SxProps> = {
  card: {
    my: 3,
    p: 3,
    width: '100%',
  },
  backButton: {
    p: 0,
    minWidth: 'max-content',
    mb: 1,
  },
  title: {
    fontSize: '15px',
    fontWeight: 700,
  },
  generalInformationContainer: {
    display: 'grid',
    gridTemplateColumns: {
      xs: '1fr',
      md: '1fr 1fr 1fr 1fr',
    },
    gap: '20px',
  },
};

type DriverFormData = {
  active: boolean;
  defaultVehicleUuid: string | null;
  driverPayType?: string | null;
  driverType?: string | null;
  driverReferenceNumber?: string | null;
  emailAddress?: string | null;
  firstName: string;
  hourlyRate?: number | null;
  lastName: string;
  password?: string | null;
  perMileRate?: number | null;
  percentGrossRevenueRate?: number | null;
  phoneNumber: string;
  notes: string;
  defaultFuelSettlementPercentageRate?: number | null;
  terminalUuid?: string | null;
  driverQualificationIds: string[];
  sendRouteUpdateEmails?: boolean | null;
};

type DriverFormErrors = {
  [key in keyof DriverFormData]?: string;
};

const requiredFields: Array<keyof DriverFormData> = [
  'firstName',
  'lastName',
  'phoneNumber',
  'password',
  'driverType',
];

type DriverPageCreateMode = { mode: 'create' };
type DriverPageEditMode = { mode: 'edit'; uuid: string };
type DriverPageViewMode = { mode: 'view'; uuid: string };

export type DriverPageMode =
  | DriverPageCreateMode
  | DriverPageEditMode
  | DriverPageViewMode;

type DriverBodyProps = {
  readonly pageMode: DriverPageMode;
  readonly initialData?: DriverQuery['driver'];
  readonly onSave: (driver: DriverCreateInput) => Promise<void>;
  readonly saving: boolean;
  // If an onToggleArchive function is provided, an archive/un-archive button will be shown.
  readonly onToggleArchive?: () => void;
  readonly togglingArchive?: boolean;
};

const DriverBody: FunctionComponent<DriverBodyProps> = ({
  pageMode,
  initialData,
  onSave,
  saving,
  onToggleArchive,
  togglingArchive = false,
}) => {
  const ffDemoLoadManagement = useFeatureFlag(
    FeatureFlag.FF_DEMO_LOAD_MANAGEMENT,
  );
  const ffDemoDriverTariffs = useFeatureFlag(
    FeatureFlag.FF_DEMO_DRIVER_TARIFFS,
  );
  const [showSnackbarError, setShowSnackbarError] = useState<boolean>(false);

  const { terminals, terminalsLoading, terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });

  const [driverFormData, setDriverFormData] = useState<DriverFormData>({
    active: true,
    defaultVehicleUuid: null,
    driverPayType: undefined,
    driverType: null,
    driverReferenceNumber: undefined,
    emailAddress: '',
    firstName: '',
    hourlyRate: undefined,
    lastName: '',
    password: undefined,
    perMileRate: undefined,
    percentGrossRevenueRate: undefined,
    phoneNumber: '',
    notes: '',
    defaultFuelSettlementPercentageRate: undefined,
    terminalUuid: null,
    driverQualificationIds: [],
  });

  const [driverFormErrors, setDriverFormErrors] = useState<DriverFormErrors>(
    {},
  );

  const {
    data: driverQualificationsData,
    loading: loadingDriverQualifications,
  } = useDriverQualificationsQuery({
    fetchPolicy: 'cache-and-network',
  });
  const { data: equipmentsData, loading: loadingEquipmentsData } =
    useEquipmentsQuery();
  const { companyUuid } = useMe();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [getDriversWithPhoneNumber] = useDriverByPhoneNumberLazyQuery();
  const [uniquePhoneNumberError, setUniquePhoneNumberError] = useState<
    string | null
  >(null);

  useEffect(() => {
    setDriverFormData((prevFormData) => ({
      ...prevFormData,
      ...initialData,
      driverType: initialData?.driverType,
      defaultVehicleUuid: initialData?.defaultVehicle?.uuid ?? null,
      terminalUuid: initialData?.terminal?.uuid ?? null,
      driverQualificationIds:
        initialData?.qualifications?.map(({ id }) => id) ?? [],
      sendRouteUpdateEmails: initialData?.sendRouteUpdateEmails,
    }));
  }, [initialData]);

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbarError(false);
  };

  const handleSave = async () => {
    let hasError = false;
    const newErrors: DriverFormErrors = {};

    // First, check all required fields.
    for (const field of requiredFields) {
      const value = driverFormData[field];
      if (isNil(value) || (isString(value) && value.length === 0)) {
        newErrors[field] = THIS_FIELD_IS_REQUIRED;
        hasError = true;
      }
    }

    // Check the Driver Pay Type field.
    let payTypeFieldToVerify: keyof DriverFormErrors | null = null;
    if (driverFormData.driverPayType === DriverPayType.PerMile) {
      payTypeFieldToVerify = 'perMileRate';
    } else if (
      driverFormData.driverPayType === DriverPayType.PercentGrossRevenue
    ) {
      payTypeFieldToVerify = 'percentGrossRevenueRate';
    }

    if (!isNil(payTypeFieldToVerify)) {
      const requiredPaymentValue = driverFormData[payTypeFieldToVerify];
      if (Number.isNaN(requiredPaymentValue) || isNil(requiredPaymentValue)) {
        newErrors[payTypeFieldToVerify] = THIS_FIELD_IS_REQUIRED;
        hasError = true;
      } else if (!isNil(requiredPaymentValue) && requiredPaymentValue <= 0) {
        newErrors[payTypeFieldToVerify] = POSITIVE_NUMBER_REQUIRED;
        hasError = true;
      }
    }

    if (
      isNilOrEmptyString(driverFormData.phoneNumber) ||
      !validatePhoneNumber(driverFormData.phoneNumber)
    ) {
      hasError = true;
      newErrors.phoneNumber = INVALID_PHONE_NUMBER;
    } else if (
      !isNil(uniquePhoneNumberError) &&
      uniquePhoneNumberError.length > 0
    ) {
      hasError = true;
      newErrors.phoneNumber = uniquePhoneNumberError;
    }

    setDriverFormErrors(newErrors);
    if (hasError) {
      setShowSnackbarError(true);
      return;
    }

    await onSave({
      driverPayType: isNil(driverFormData.driverPayType)
        ? null
        : (driverFormData?.driverPayType as DriverPayType),
      driverType: isEmpty(driverFormData.driverType)
        ? null
        : (driverFormData?.driverType as DriverType),
      driverReferenceNumber: isEmpty(driverFormData.driverReferenceNumber)
        ? null
        : driverFormData.driverReferenceNumber,
      emailAddress: driverFormData.emailAddress,
      phoneNumber: driverFormData.phoneNumber,
      equipmentUuid: driverFormData.defaultVehicleUuid,
      firstName: driverFormData.firstName,
      fuelCardNumber: '',
      hourlyRate: driverFormData.hourlyRate,
      lastName: driverFormData.lastName,
      notes: driverFormData.notes,
      password: driverFormData.password,
      perMileRate: driverFormData?.perMileRate ?? null,
      percentGrossRevenueRate: driverFormData.percentGrossRevenueRate,
      defaultFuelSettlementPercentageRate:
        driverFormData.defaultFuelSettlementPercentageRate ?? null,
      terminalUuid: isEmpty(driverFormData.terminalUuid)
        ? null
        : driverFormData.terminalUuid,
      driverQualificationIds: driverFormData.driverQualificationIds,
      sendRouteUpdateEmails: driverFormData.sendRouteUpdateEmails,
    });
  };

  const driverLabel = ffDemoLoadManagement ? 'Driver/agent' : 'Driver';
  const driverTypes = ffDemoLoadManagement
    ? ['Agent', ...allDriverTypes]
    : allDriverTypes;

  return (
    <Box sx={{ p: 2 }} height="100%" overflow="auto">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Box>
          <ButtonLink
            href="/management/?tab=4"
            variant="text"
            size="small"
            sx={styles.backButton}
          >
            Back
          </ButtonLink>
          <Typography variant="h3" sx={styles.title}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {pageMode.mode === 'create'
              ? `Add ${driverLabel}`
              : pageMode.mode === 'edit'
                ? `Edit ${driverLabel}`
                : driverLabel}
          </Typography>
        </Box>
        <Button
          disabled={
            saving ||
            togglingArchive ||
            loadingDriverQualifications ||
            loadingEquipmentsData
          }
          variant="contained"
          onClick={handleSave}
        >
          Save
        </Button>
      </Stack>
      <Card sx={styles.card}>
        <Stack spacing={2}>
          <Box>
            <Typography variant="h6">General Information</Typography>
          </Box>
          <Box sx={styles.generalInformationContainer}>
            <TextField
              required
              size="small"
              sx={{ width: '80%' }}
              label="First name"
              error={!isNil(driverFormErrors.firstName)}
              helperText={driverFormErrors.firstName}
              value={driverFormData.firstName}
              onChange={(event) => {
                setDriverFormData({
                  ...driverFormData,
                  firstName: event.target.value,
                });
              }}
            />
            <TextField
              required
              size="small"
              sx={{ width: '80%' }}
              label="Last name"
              error={!isNil(driverFormErrors.lastName)}
              helperText={driverFormErrors.lastName}
              value={driverFormData.lastName}
              onChange={(event) => {
                setDriverFormData({
                  ...driverFormData,
                  lastName: event.target.value,
                });
              }}
            />
            <TextField
              required
              size="small"
              sx={{ width: '80%' }}
              label="Phone number"
              error={!isNil(driverFormErrors.phoneNumber)}
              helperText={driverFormErrors.phoneNumber}
              InputProps={{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                inputComponent: PhoneInputMask as any,
              }}
              value={driverFormData.phoneNumber}
              onChange={async (event) => {
                setDriverFormData({
                  ...driverFormData,
                  phoneNumber: event.target.value,
                });
                let newUniquePhoneNumberError: string | null = null;
                if (
                  !isNilOrEmptyString(event.target.value) &&
                  validatePhoneNumber(event.target.value)
                ) {
                  const res = await getDriversWithPhoneNumber({
                    variables: {
                      phoneNumber: event.target.value,
                    },
                  });
                  const otherDriver = res.data?.driverByPhoneNumber;
                  if (
                    !isNil(otherDriver) &&
                    (pageMode.mode === 'create' ||
                      otherDriver.uuid !== pageMode.uuid)
                  ) {
                    if (otherDriver?.company.uuid === companyUuid) {
                      newUniquePhoneNumberError = `Phone number already in use by ${otherDriver?.firstName} ${otherDriver?.lastName}`;
                    } else if (!isNil(otherDriver)) {
                      newUniquePhoneNumberError = 'Phone number already in use';
                    }
                  }
                }
                setUniquePhoneNumberError(newUniquePhoneNumberError);
                setDriverFormErrors((prevFormErrors) => ({
                  ...prevFormErrors,
                  phoneNumber: newUniquePhoneNumberError ?? undefined,
                }));
              }}
            />
            <TextField
              size="small"
              sx={{ width: '80%' }}
              label="Email address"
              autoComplete="off"
              error={!isNil(driverFormErrors.emailAddress)}
              helperText={driverFormErrors.emailAddress}
              value={driverFormData.emailAddress}
              onChange={(event) => {
                setDriverFormData({
                  ...driverFormData,
                  emailAddress: event.target.value,
                });
              }}
            />
            <TextField
              size="small"
              sx={{ width: '80%' }}
              label="Reference number"
              autoComplete="off"
              error={!isNil(driverFormErrors.driverReferenceNumber)}
              helperText={driverFormErrors.driverReferenceNumber}
              value={driverFormData.driverReferenceNumber}
              onChange={(event) => {
                setDriverFormData({
                  ...driverFormData,
                  driverReferenceNumber: event.target.value,
                });
              }}
            />
            <FormControl
              sx={{ width: '80%' }}
              error={!isNil(driverFormErrors.defaultVehicleUuid)}
            >
              <InputLabel id="select-driver-default-vehicle-label">
                Default vehicle
              </InputLabel>
              <Select
                size="small"
                labelId="select-driver-default-vehicle-label"
                id="select-driver-default-vehicle"
                label="Default Vehicle"
                value={driverFormData.defaultVehicleUuid ?? ''}
                onChange={(event) => {
                  if (typeof event.target.value === 'string') {
                    const newDefaultVehicle =
                      event.target.value === 'clear'
                        ? null
                        : event.target.value;
                    setDriverFormData({
                      ...driverFormData,
                      defaultVehicleUuid: newDefaultVehicle,
                    });
                  }
                }}
              >
                {equipmentsData?.equipments.map((equipmentData) => (
                  <MenuItem key={equipmentData.uuid} value={equipmentData.uuid}>
                    {`${equipmentData.name} (Type: ${
                      equipmentData.type
                    }, Vehicle Type: ${equipmentData.vehicleType?.name ?? ''})`}
                  </MenuItem>
                ))}
                <MenuItem key="clear" value="clear">
                  Clear selection
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl
              required
              sx={{ width: '80%' }}
              error={!isNil(driverFormErrors.driverType)}
            >
              <InputLabel id="select-driver-type-label">Type</InputLabel>
              <Select
                size="small"
                label="Type"
                labelId="select-driver-type-label"
                id="select-driver-type"
                value={driverFormData.driverType ?? ''}
                onChange={(event) => {
                  if (typeof event.target.value === 'string') {
                    setDriverFormData({
                      ...driverFormData,
                      driverType: event.target.value,
                    });
                  }
                }}
              >
                {driverTypes.map((driverType) => (
                  <MenuItem key={driverType} value={driverType}>
                    {sentenceCase(driverType)}
                  </MenuItem>
                ))}
              </Select>
              {!isNil(driverFormErrors.driverType) && (
                <FormHelperText error>
                  {driverFormErrors.driverType}
                </FormHelperText>
              )}
            </FormControl>
            {terminalsEnabled && (
              <FormControl
                sx={{ width: '80%' }}
                error={!isNil(driverFormErrors.terminalUuid)}
              >
                <InputLabel id="select-driver-terminal-label">
                  Terminal
                </InputLabel>
                <Select
                  size="small"
                  labelId="select-driver-terminal-label"
                  id="select-driver-terminal"
                  label="Terminal"
                  value={driverFormData.terminalUuid ?? ALL_TERMINALS_ITEM}
                  disabled={terminalsLoading}
                  onChange={(event) => {
                    const newTerminal =
                      event.target.value === ALL_TERMINALS_ITEM
                        ? null
                        : event.target.value;
                    if (typeof event.target.value === 'string') {
                      setDriverFormData({
                        ...driverFormData,
                        terminalUuid: newTerminal,
                      });
                    }
                  }}
                >
                  <MenuItem key={ALL_TERMINALS_ITEM} value={ALL_TERMINALS_ITEM}>
                    All
                  </MenuItem>
                  {terminals?.map((terminal) => (
                    <MenuItem key={terminal.uuid} value={terminal.uuid}>
                      {`${terminal.name} (${terminal.code})`}
                    </MenuItem>
                  ))}
                </Select>
                {!isNil(driverFormErrors.terminalUuid) && (
                  <FormHelperText error>
                    {driverFormErrors.terminalUuid}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          </Box>
        </Stack>
      </Card>
      <Card sx={styles.card}>
        <Stack spacing={1}>
          <Typography variant="h6">Settlement</Typography>
          {ffDemoDriverTariffs ? (
            <DriverSettlementSection
              driverFormData={driverFormData}
              setDriverFormData={setDriverFormData}
              driverFormErrors={driverFormErrors}
            />
          ) : (
            <Stack
              gap={2}
              direction={{
                xs: 'column',
                md: 'row',
              }}
            >
              <FormControl
                sx={{ width: '240px', maxWidth: '100%' }}
                error={!isNil(driverFormErrors.driverPayType)}
              >
                <InputLabel id="select-driver-pay-type-label">
                  Pay type
                </InputLabel>
                <Select
                  size="small"
                  labelId="select-driver-pay-type-label"
                  label="Pay type"
                  id="select-driver-pay-type"
                  value={driverFormData.driverPayType ?? ''}
                  onChange={(event) => {
                    if (typeof event.target.value === 'string') {
                      setDriverFormData({
                        ...driverFormData,
                        driverPayType: event.target.value,
                      });
                    }
                  }}
                >
                  {DRIVER_PAY_TYPES.map(
                    (driverPayType: DriverPayTypeDisplay) => (
                      <MenuItem
                        key={driverPayType.driverPayTypeName}
                        value={driverPayType.driverPayTypeName}
                      >
                        {driverPayType.displayName}
                      </MenuItem>
                    ),
                  )}
                </Select>
                {!isNil(driverFormErrors.driverPayType) && (
                  <FormHelperText error>
                    {driverFormErrors.driverPayType}
                  </FormHelperText>
                )}
              </FormControl>
              {driverFormData.driverPayType === DriverPayType.PerMile && (
                <TextField
                  size="small"
                  sx={{ width: '200px', maxWidth: '100%' }}
                  label="Per Mile Rate"
                  type="number"
                  error={!isNil(driverFormErrors.perMileRate)}
                  helperText={driverFormErrors.perMileRate}
                  value={driverFormData.perMileRate}
                  onChange={(event) => {
                    setDriverFormData({
                      ...driverFormData,
                      perMileRate: Number.isNaN(event.target.value)
                        ? 0
                        : Number.parseFloat(event.target.value),
                    });
                  }}
                />
              )}
              {driverFormData.driverPayType ===
                DriverPayType.PercentGrossRevenue && (
                <TextField
                  required
                  size="small"
                  sx={{ width: '200px', maxWidth: '100%' }}
                  label="Percent of Gross Revenue to Pay"
                  type="number"
                  error={!isNil(driverFormErrors.percentGrossRevenueRate)}
                  helperText={driverFormErrors.percentGrossRevenueRate}
                  value={driverFormData.percentGrossRevenueRate}
                  onChange={(event) => {
                    setDriverFormData({
                      ...driverFormData,
                      percentGrossRevenueRate: Number.isNaN(event.target.value)
                        ? 0
                        : Number.parseFloat(event.target.value),
                    });
                  }}
                />
              )}
              <TextField
                size="small"
                sx={{ width: '200px', maxWidth: '100%' }}
                label="Fuel Settlement Rate"
                type="number"
                error={
                  !isNil(driverFormErrors.defaultFuelSettlementPercentageRate)
                }
                helperText={
                  driverFormErrors.defaultFuelSettlementPercentageRate
                }
                value={driverFormData.defaultFuelSettlementPercentageRate ?? ''}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                onChange={(event) => {
                  setDriverFormData({
                    ...driverFormData,
                    defaultFuelSettlementPercentageRate: Number.isNaN(
                      event.target.value,
                    )
                      ? null
                      : Number.parseFloat(event.target.value),
                  });
                }}
              />
            </Stack>
          )}
        </Stack>
      </Card>
      <Card sx={styles.card}>
        <Stack spacing={2}>
          <Box>
            <Typography variant="h6">Login Credentials</Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap="12px">
            <Box>
              <Typography sx={{ fontWeight: 'bold' }}>Email Address</Typography>
              <Typography>
                {isNilOrEmptyString(driverFormData.emailAddress)
                  ? '(None)'
                  : driverFormData.emailAddress}
              </Typography>
            </Box>
            <TextField
              required
              size="small"
              sx={{ width: '80%' }}
              label="Password"
              error={!isNil(driverFormErrors.password)}
              helperText={driverFormErrors.password}
              type={showPassword ? 'text' : 'password'}
              value={driverFormData.password ?? ''}
              InputProps={{
                autoComplete: 'new-password',
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
              onChange={(event) => {
                setDriverFormData({
                  ...driverFormData,
                  password: event.target.value,
                });
              }}
            />
          </Box>
        </Stack>
      </Card>
      <Card sx={styles.card}>
        <Stack spacing={1}>
          <Typography variant="h6">Notification Settings</Typography>
          <FormControlLabel
            label="Send route update emails"
            labelPlacement="end"
            control={
              <Checkbox
                checked={driverFormData.sendRouteUpdateEmails ?? false}
                onChange={(event) => {
                  setDriverFormData({
                    ...driverFormData,
                    sendRouteUpdateEmails: event.target.checked,
                  });
                }}
              />
            }
          />
        </Stack>
      </Card>
      <Card sx={styles.card}>
        <Stack spacing={2}>
          <Box>
            <Typography variant="h6">Notes</Typography>
          </Box>
          <Box>
            <FormControl sx={{ width: '80%' }}>
              <TextField
                multiline
                size="small"
                error={!isNil(driverFormErrors.notes)}
                helperText={driverFormErrors.notes}
                value={driverFormData.notes}
                sx={{ width: '100%' }}
                rows={3}
                placeholder="Enter notes"
                onChange={(event) => {
                  setDriverFormData({
                    ...driverFormData,
                    notes: event.target.value,
                  });
                }}
              />
            </FormControl>
          </Box>
        </Stack>
      </Card>
      <Card sx={styles.card}>
        <Stack spacing={2}>
          <Box>
            <Typography variant="h6">Driver Qualifications</Typography>
          </Box>
          {loadingDriverQualifications ? (
            <CircularProgress />
          ) : (
            !isNil(driverQualificationsData) &&
            (driverQualificationsData.driverQualifications.length > 0 ? (
              <Stack
                direction="row"
                flexWrap="wrap"
                maxWidth="100%"
                columnGap={2}
              >
                {driverQualificationsData.driverQualifications.map(
                  ({ id, name }) => (
                    <Stack
                      key={id}
                      direction="row"
                      alignItems="center"
                      height="44px"
                      width="260px"
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={driverFormData.driverQualificationIds.includes(
                              id,
                            )}
                            onChange={({ target }) => {
                              if (target.checked) {
                                setDriverFormData({
                                  ...driverFormData,
                                  driverQualificationIds: [
                                    ...driverFormData.driverQualificationIds,
                                    id,
                                  ],
                                });
                              } else {
                                setDriverFormData({
                                  ...driverFormData,
                                  driverQualificationIds:
                                    driverFormData.driverQualificationIds.filter(
                                      (driverQualificationId) =>
                                        driverQualificationId !== id,
                                    ),
                                });
                              }
                            }}
                          />
                        }
                        label={name}
                      />
                    </Stack>
                  ),
                )}
              </Stack>
            ) : (
              <Typography>
                You do not have any driver qualifications configured yet.
              </Typography>
            ))
          )}
        </Stack>
      </Card>
      {!isNil(onToggleArchive) && (
        <Card sx={styles.card}>
          <ArchiveActionComponent
            entityType={ArchiveableEntity.DRIVER}
            isActive={driverFormData.active}
            handleArchive={onToggleArchive}
            handleUnarchive={onToggleArchive}
          />
        </Card>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        open={showSnackbarError}
        onClose={handleCloseSnackbar}
      >
        <Alert
          severity="error"
          sx={{ width: '100%' }}
          onClose={handleCloseSnackbar}
        >
          Error saving driver.
        </Alert>
      </Snackbar>
    </Box>
  );
};

type EditDriverProps = {
  readonly pageMode: DriverPageEditMode | DriverPageViewMode;
  readonly onSave: (driver: DriverCreateInput) => Promise<void>;
  readonly saving: boolean;
};

const EditDriver: FunctionComponent<EditDriverProps> = ({
  pageMode,
  onSave,
  saving,
}) => {
  const { uuid } = pageMode;
  const { data, loading } = useDriverQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      uuid,
    },
  });

  const [archiveDriver, { loading: archiving }] = useArchiveDriverMutation({
    fetchPolicy: 'network-only',
  });
  const [restoreDriver, { loading: restoring }] = useRestoreDriverMutation({
    fetchPolicy: 'network-only',
  });

  const onArchive = async () =>
    archiveDriver({
      fetchPolicy: 'network-only',
      variables: {
        uuid,
      },
    });

  const onRestore = async () =>
    restoreDriver({
      fetchPolicy: 'network-only',
      variables: {
        uuid,
      },
    });

  const onToggleArchive = () => {
    if (data) {
      if (data.driver.active) {
        onArchive();
      } else {
        onRestore();
      }
    }
  };

  if (loading && isNil(data)) {
    return <CircularProgress size={15} />;
  }

  return (
    <DriverBody
      pageMode={pageMode}
      initialData={data?.driver}
      saving={saving}
      togglingArchive={archiving || restoring}
      onSave={onSave}
      onToggleArchive={onToggleArchive}
    />
  );
};

type DriverProps = {
  readonly pageMode: DriverPageMode;
};

const Driver: FunctionComponent<DriverProps> = ({ pageMode }) => {
  const navigate = useNavigate();
  const [updateDriver, { loading: updating }] = useUpdateDriverMutation({
    fetchPolicy: 'network-only',
  });
  const [createDriver, { loading: creating }] = useCreateDriverMutation({
    fetchPolicy: 'network-only',
  });
  const { loadDrivers } = useDrivers();

  const onSave: DriverBodyProps['onSave'] = async (data) => {
    if (pageMode.mode === 'create') {
      await createDriver({
        variables: {
          input: {
            driverCreateInput: data,
          },
        },
        onCompleted: (resultData) => {
          if (!isNil(resultData?.createDriver)) {
            navigate(-1);
          }
        },
      });

      // Reload drivers to ensure the new driver is displayed
      // in other parts of the app
      await loadDrivers();
    } else {
      await updateDriver({
        variables: {
          input: {
            driverUpdateInput: {
              uuid: pageMode.uuid,
              ...data,
            },
          },
        },
        onCompleted: (resultData) => {
          if (!isNil(resultData?.updateDriver)) {
            navigate(-1);
          }
        },
      });
    }
  };

  if (pageMode.mode === 'create') {
    return <DriverBody pageMode={pageMode} saving={creating} onSave={onSave} />;
  }

  return (
    <EditDriver
      key={pageMode.uuid}
      pageMode={pageMode}
      saving={updating}
      onSave={onSave}
    />
  );
};

export default Driver;
