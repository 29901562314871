// eslint-disable no-case-declarations
// eslint-disable-next-line no-restricted-imports
import { Card, Grid, Stack, Typography } from '@mui/material';
import { sentenceCase } from 'change-case';
import { isEmpty, isNil } from 'lodash';
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';
import { exhaustive } from 'shared/switch';
import useStyles from '../../../../common/components/general-styles';
import useWarehouses from '../../../../common/react-hooks/use-warehouses';
import {
  type OrderForBulkEditFragment,
  Segment,
  useMeQuery,
} from '../../../../generated/graphql';
import { type BulkActionData, StopTypeGroup } from '../enums/bulk-actions';
import {
  INBOUND_STOP_IDX,
  OUTBOUND_STOP_IDX,
} from '../order-form/components/constants';
import { isServiceDateFieldDisabled } from '../order-form/forms/service-date-utils';
import { useGetOrderFormEditAccess } from '../order-form/forms/use-order-form-edit-access';
import {
  getFilteredOrdersForOrderAction,
  getFilteredOrdersForStopAction,
  getShipmentForStopType,
} from '../utils';
import BulkActionsPreviewTable from './bulk-actions-preview-table';

type BulkActionsPreviewProps = {
  readonly orders: OrderForBulkEditFragment[];
  readonly actionData: BulkActionData;
};

enum BulkEditAction {
  Warehouse = 'WAREHOUSE',
  Mawb = 'MAWB',
  SecondaryReferenceNumber = 'SECONDARY_REFERENCE_NUMBER',
  DownloadAction = 'DOWNLOAD_ACTION',
  OrderStateAction = 'ORDER_STATE_ACTION',
  DeadlineType = 'DEADLINE_TYPE',
  DeadlineDate = 'DEADLINE_DATE',
  StopStateAction = 'STOP_STATE_ACTION',
  ServiceDate = 'SERVICE_DATE',
}

const BulkActionsPreviewActionCard = ({
  text,
  action,
  selectedAction,
  setSelectedAction,
}: {
  readonly text: string;
  readonly action: BulkEditAction;
  readonly selectedAction: BulkEditAction | undefined;
  readonly setSelectedAction: Dispatch<
    SetStateAction<BulkEditAction | undefined>
  >;
}) => {
  const styles = useStyles();

  return (
    <Card
      variant="outlined"
      sx={[
        styles.cardHover,
        {
          background:
            selectedAction === action ? 'rgba(37, 48, 82, 0.08)' : undefined,
        },
      ]}
      onClick={() => {
        setSelectedAction(action);
      }}
    >
      <Typography sx={{ color: 'gray', padding: 1 }}>{text}</Typography>
    </Card>
  );
};

const BulkActionsPreview = ({
  orders,
  actionData,
}: BulkActionsPreviewProps) => {
  const { warehouses } = useWarehouses();
  const { data: companyData } = useMeQuery({ fetchPolicy: 'cache-first' });
  const [currentAction, setCurrentAction] = useState<BulkEditAction>();
  const [affectedOrderCount, setAffectedOrderCount] = useState<number>();
  const [affectedStopCount, setAffectedStopCount] = useState<number>();
  const [affectedOrders, setAffectedOrders] =
    useState<OrderForBulkEditFragment[]>();
  const { getOrderFormEditAccess } = useGetOrderFormEditAccess();

  const segment = companyData?.me?.company.segment;

  const updateAffectedOrdersData = (action: BulkEditAction | undefined) => {
    setAffectedStopCount(undefined);
    let filteredOrders: OrderForBulkEditFragment[] = [];
    if (isNil(action)) {
      return;
    }
    switch (action) {
      case BulkEditAction.Warehouse: {
        filteredOrders = orders.filter(
          (order) => order.warehouse?.uuid !== actionData.warehouseUuid,
        );
        break;
      }
      case BulkEditAction.Mawb: {
        filteredOrders = orders.filter(
          (order) =>
            order.standardOrderFields.masterAirwayBillOfLadingNumber !==
            actionData.mawb,
        );
        break;
      }
      case BulkEditAction.SecondaryReferenceNumber: {
        filteredOrders = orders.filter(
          (order) =>
            order.secondaryRefNumber !== actionData.secondaryReferenceNumber,
        );
        break;
      }
      case BulkEditAction.DownloadAction: {
        filteredOrders = orders;
        break;
      }
      case BulkEditAction.OrderStateAction: {
        filteredOrders = getFilteredOrdersForOrderAction(
          orders,
          actionData.orderAction,
        );
        break;
      }
      case BulkEditAction.DeadlineType: {
        filteredOrders = orders.filter((order) =>
          order.shipments.some((shipment) => {
            const stopType = shipment.legs[0]?.endStop.stopType;
            return (
              shipment.standardShipmentFields?.deadlineType !==
                actionData.deadlineType &&
              !isNil(stopType) &&
              actionData.stopTypes?.includes(stopType)
            );
          }),
        );
        setAffectedStopCount(filteredOrders.length);
        break;
      }
      case BulkEditAction.DeadlineDate: {
        filteredOrders = orders.filter((order) =>
          order.shipments.some((shipment) => {
            const stopType = shipment.legs[0]?.endStop.stopType;
            return (
              shipment.standardShipmentFields?.deadlineType !==
                actionData.deadlineDate &&
              !isNil(stopType) &&
              actionData.stopTypes?.includes(stopType)
            );
          }),
        );
        setAffectedStopCount(filteredOrders.length);
        break;
      }
      case BulkEditAction.StopStateAction: {
        filteredOrders = getFilteredOrdersForStopAction(
          orders,
          actionData.stopTypes,
          actionData.stopAction,
        );
        setAffectedStopCount(filteredOrders.length);
        break;
      }
      case BulkEditAction.ServiceDate: {
        filteredOrders = orders.filter((order) => {
          const editAccess = getOrderFormEditAccess({
            detailedStatus: order.detailedStatusV2,
          });
          const inboundOrOutboundIndex =
            actionData.stopTypeGroup === StopTypeGroup.PickupRecovery
              ? INBOUND_STOP_IDX
              : OUTBOUND_STOP_IDX;
          const shipment = getShipmentForStopType(
            order,
            inboundOrOutboundIndex,
          );
          return !isServiceDateFieldDisabled({
            stopStatus: shipment?.legs[0]?.endStop.status,
            editAccess,
          });
        });
        break;
      }
      default: {
        exhaustive(action);
      }
    }
    setAffectedOrderCount(filteredOrders.length);
    setAffectedOrders(filteredOrders);
  };

  useEffect(() => {
    updateAffectedOrdersData(currentAction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAction]);

  return (
    <Grid container sx={{ height: '75vh', overflowY: 'scroll' }}>
      <Grid item xs={12}>
        <Stack spacing={1}>
          {!isNil(actionData.warehouseUuid) && (
            <BulkActionsPreviewActionCard
              text={`Update Warehouse to ${
                warehouses?.find(
                  (warehouse) => warehouse.uuid === actionData.warehouseUuid,
                )?.name
              }`}
              action={BulkEditAction.Warehouse}
              selectedAction={currentAction}
              setSelectedAction={setCurrentAction}
            />
          )}
          {!isNil(actionData.mawb) && (
            <BulkActionsPreviewActionCard
              text={`Update MAWB to ${actionData.mawb}`}
              action={BulkEditAction.Mawb}
              selectedAction={currentAction}
              setSelectedAction={setCurrentAction}
            />
          )}
          {!isNil(actionData.secondaryReferenceNumber) && (
            <BulkActionsPreviewActionCard
              text={`Update ${
                segment === Segment.Cartage
                  ? 'Reference Number'
                  : 'Secondary Reference Number'
              } to ${actionData.secondaryReferenceNumber}`}
              action={BulkEditAction.SecondaryReferenceNumber}
              selectedAction={currentAction}
              setSelectedAction={setCurrentAction}
            />
          )}
          {!isEmpty(actionData.downloadActions) && (
            <BulkActionsPreviewActionCard
              text={actionData.downloadActions.join(', ')}
              action={BulkEditAction.DownloadAction}
              selectedAction={currentAction}
              setSelectedAction={setCurrentAction}
            />
          )}
          {!isNil(actionData.orderAction) && (
            <BulkActionsPreviewActionCard
              text={`Order Action: ${sentenceCase(actionData.orderAction)}`}
              action={BulkEditAction.OrderStateAction}
              selectedAction={currentAction}
              setSelectedAction={setCurrentAction}
            />
          )}
          {!isNil(actionData.deadlineType) &&
            !isNil(actionData.stopTypeGroup) && (
              <BulkActionsPreviewActionCard
                text={`${
                  actionData.stopTypeGroup
                } | Update Deadline Type to ${actionData.deadlineType?.replace(
                  '_',
                  ' ',
                )}`}
                action={BulkEditAction.DeadlineType}
                selectedAction={currentAction}
                setSelectedAction={setCurrentAction}
              />
            )}
          {!isNil(actionData.deadlineDate) &&
            !isNil(actionData.stopTypeGroup) && (
              <BulkActionsPreviewActionCard
                text={`${
                  actionData.stopTypeGroup
                } | Update Deadline Date to ${actionData.deadlineDate?.format(
                  'MM/DD hh:mm a',
                )}`}
                action={BulkEditAction.DeadlineDate}
                selectedAction={currentAction}
                setSelectedAction={setCurrentAction}
              />
            )}
          {!isNil(actionData.stopAction) &&
            !isNil(actionData.stopTypeGroup) && (
              <BulkActionsPreviewActionCard
                text={`${
                  actionData.stopTypeGroup
                } | Stop Action: ${sentenceCase(actionData.stopAction)}`}
                action={BulkEditAction.StopStateAction}
                selectedAction={currentAction}
                setSelectedAction={setCurrentAction}
              />
            )}
          {!isNil(actionData.serviceDate) && (
            <BulkActionsPreviewActionCard
              text={`Update Service Date to ${actionData.serviceDate?.format(
                'MM/DD hh:mm a',
              )}`}
              action={BulkEditAction.ServiceDate}
              selectedAction={currentAction}
              setSelectedAction={setCurrentAction}
            />
          )}
        </Stack>

        {!isNil(currentAction) && (
          <BulkActionsPreviewTable
            affectedOrderCount={affectedOrderCount}
            affectedStopCount={affectedStopCount}
            affectedStopTypes={actionData.stopTypes}
            orders={affectedOrders ?? []}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default BulkActionsPreview;
