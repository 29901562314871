import {
  Box,
  Button,
  CircularProgress,
  Fade,
  TableCell,
  TableRow,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isEmpty, isNil } from 'lodash';
import React, { useState } from 'react';
import { safeDivide } from 'shared/math';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import { transformDateToDateString } from '../../../../common/utils/prettyPrintUtils';
import {
  type PaymentForInvoiceFragment,
  useAllInvoicesForPaymentApplicationLazyQuery,
} from '../../../../generated/graphql';
import CreatePaymentModal from '../customers/create-payment-modal';
import { CreateOrEdit } from './enums';

type InvoicePaymentsListRowProps = {
  readonly payment: PaymentForInvoiceFragment;
  readonly refresh?: () => void;
  readonly inCustomersTab?: boolean;
};

const InvoicePaymentListRow = ({
  payment,
  refresh,
  inCustomersTab = false,
}: InvoicePaymentsListRowProps) => {
  const ffHideInvoicesFilter = useFeatureFlag(
    FeatureFlag.FF_HIDE_INVOICES_FILTER_IN_PAYMENT_APPLICATION,
  );

  const [isHovering, setIsHovering] = useState(false);
  const [showEditPaymentsModal, setShowEditPaymentsModal] = useState(false);

  const masterAccountUuid = payment.contact.masterAccount?.uuid;
  const [
    fetchAllInvoicesForPaymentApplication,
    { data: allInvoicesForPaymentApplication, loading: allInvoicesDataLoading },
  ] = useAllInvoicesForPaymentApplicationLazyQuery({
    variables: {
      masterAccountUuid,
      billingPartyContactUuid: isNil(masterAccountUuid)
        ? payment.contact.uuid
        : undefined,
    },
  });

  const handleClickModifyApplication = () => {
    setShowEditPaymentsModal(true);
    if (ffHideInvoicesFilter) return; // If the invoices filter is hidden, no need to fetch invoices for it
    fetchAllInvoicesForPaymentApplication();
  };

  const handleClosePaymentModal = () => {
    if (!isNil(refresh)) refresh();
    setShowEditPaymentsModal(false);
  };

  return (
    <>
      {showEditPaymentsModal && !isNil(allInvoicesForPaymentApplication) && (
        <CreatePaymentModal
          open={showEditPaymentsModal}
          contactUuid={payment.contact.uuid}
          paymentUuid={payment.uuid}
          createOrEdit={CreateOrEdit.Edit}
          type={payment.paymentType}
          allInvoicesForPaymentApplication={allInvoicesForPaymentApplication}
          onClose={handleClosePaymentModal}
        />
      )}
      <TableRow
        onMouseEnter={() => {
          setIsHovering(true);
        }}
        onMouseLeave={() => {
          setIsHovering(false);
        }}
      >
        <TableCell>
          {isEmpty(payment.referenceNumber) ? 'None' : payment.referenceNumber}
        </TableCell>
        <TableCell>
          {transformDateToDateString(new Date(payment.date).toISOString())}
        </TableCell>
        <TableCell>{sentenceCase(payment.paymentType)}</TableCell>
        <TableCell>{payment.contact.displayName}</TableCell>
        <TableCell>
          ${safeDivide(payment.amountInCents, 100).toFixed(2)}
        </TableCell>
        {inCustomersTab && (
          <TableCell>
            <Fade in={isHovering}>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                <Button
                  variant="contained"
                  startIcon={
                    allInvoicesDataLoading && (
                      <CircularProgress size={15} sx={{ color: 'gray' }} />
                    )
                  }
                  onClick={handleClickModifyApplication}
                >
                  Modify application
                </Button>
              </Box>
            </Fade>
          </TableCell>
        )}
      </TableRow>
    </>
  );
};

export default InvoicePaymentListRow;
