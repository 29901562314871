import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { isEmpty, isNil } from 'lodash';
import { transformDateTimeToDateTimeString } from '../../../common/utils/prettyPrintUtils';
import { isInboundShipment } from '../../../common/utils/stops';
import {
  OrderEventType,
  type OrderPodReportShipmentFragment,
  Segment,
} from '../../../generated/graphql';
import DisplayAddress from './display-address';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-regular-webfont.ttf',
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-bold-webfont.ttf',
      fontWeight: 700,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: 24,
    fontFamily: 'Roboto',
    fontSize: '11px',
    flexDirection: 'column',
  },
  titleBold: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  headerText: {
    fontSize: '12px',
    fontWeight: 'bold',
  },
  subHeaderTextSize: {
    fontSize: '10px',
  },
  subHeaderText: {
    fontSize: '10px',
    fontWeight: 'bold',
  },
  rowWithPadding: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '5px',
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  cell: {
    borderWidth: 1,
    borderColor: 'black',
    width: '100%',
    display: 'flex',
    padding: '5px',
  },
});

const APPT_TIME_FORMAT = 'MM/DD HH:mm';

export const PodReportComponent = ({
  segment,
  shipment,
  showCharges,
}: {
  readonly segment: Segment | undefined;
  readonly shipment: OrderPodReportShipmentFragment;
  readonly showCharges: boolean;
}) => {
  const { order } = shipment;
  const endStop = shipment.legs[0]?.endStop;
  const route = endStop?.routeSlot?.route;
  const driver = route?.drivers[0];
  const isPickup = isInboundShipment(shipment);

  const createdOrderEvent = order?.orderEvents.find(
    (event) => event.eventType === OrderEventType.OrderCreated,
  );

  const customCharges =
    shipment.order?.shipments.flatMap((s) => s.customCharges) ?? [];

  let appointmentTime = isNil(shipment.standardShipmentFields?.deadlineDate)
    ? null
    : dayjs(shipment.standardShipmentFields?.deadlineDate)
        .set('hour', 0)
        .set('minutes', 0);
  let endAppointmentTime = isNil(shipment.standardShipmentFields?.deadlineDate)
    ? null
    : dayjs(shipment.standardShipmentFields?.deadlineDate)
        .set('hour', 0)
        .set('minutes', 0);
  const freightAuthoCode = shipment.charges.find(
    (c) => c.__typename === 'FreightChargeEntity' && !isNil(c.authoCode),
  )?.authoCode;
  let fuelAuthoCode;
  for (const c of shipment.charges) {
    if (c.__typename === 'FreightChargeEntity') {
      fuelAuthoCode = c.fuelCharge?.authoCode;
    }
  }
  if (
    !isNil(endStop) &&
    !isNil(appointmentTime) &&
    !isNil(endStop.appointmentTime)
  ) {
    appointmentTime = appointmentTime
      .set('hour', new Date(endStop.appointmentTime).getHours())
      .set('minutes', new Date(endStop.appointmentTime).getMinutes());
  }
  if (
    !isNil(endStop) &&
    !isNil(endAppointmentTime) &&
    !isNil(endStop.endAppointmentTime)
  ) {
    endAppointmentTime = endAppointmentTime
      .set('hour', new Date(endStop.endAppointmentTime).getHours())
      .set('minutes', new Date(endStop.endAppointmentTime).getMinutes());
  }

  return (
    <Page size="LETTER" style={styles.page}>
      <View>
        <View>
          <Text style={styles.titleBold}>POD Report</Text>
        </View>
        <View
          style={[
            {
              flexDirection: 'row',
              marginTop: '20px',
            },
            styles.cell,
          ]}
        >
          <View
            style={{
              width: '50%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Text style={styles.titleBold}>{order?.company.name}</Text>
          </View>
          <View
            style={{
              width: '50%',
              display: 'flex',
              flexDirection: 'row-reverse',
            }}
          >
            <Text>{dayjs().format('M/D/YYYY hh:MM')}</Text>
            <View style={{ marginRight: '3%' }}>
              <Text style={styles.titleBold}>Print Date:</Text>
            </View>
          </View>
        </View>
      </View>
      <View
        style={[styles.cell, { flexDirection: 'column', borderTopWidth: 0 }]}
      >
        <View style={styles.rowWithPadding}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100% ',
            }}
          >
            <Text style={styles.headerText}>
              {order?.billingPartyContact?.displayName}
            </Text>
          </View>
        </View>
        <View style={styles.rowWithPadding}>
          <View
            style={{ width: '40%', display: 'flex', flexDirection: 'column' }}
          >
            <View style={styles.row}>
              <View style={{ width: '40%' }}>
                <Text style={styles.subHeaderText}>
                  {segment === Segment.Cartage ? 'HAWB #' : 'Pro #'}
                </Text>
              </View>
              <View style={{ width: '60%' }}>
                <Text style={{ fontSize: '10px' }}>
                  {order?.standardOrderFields.shipperBillOfLadingNumber}
                </Text>
              </View>
            </View>
            {segment === Segment.Cartage && (
              <View style={styles.row}>
                <View style={{ width: '40%' }}>
                  <Text style={styles.subHeaderText}>MAWB #</Text>
                </View>
                <View style={{ width: '60%' }}>
                  <Text style={{ fontSize: '10px' }}>
                    {order?.standardOrderFields.masterAirwayBillOfLadingNumber}
                  </Text>
                </View>
              </View>
            )}
            <View style={styles.row}>
              <View style={{ width: '40%' }}>
                <Text style={styles.subHeaderText}>
                  {segment === Segment.Cartage
                    ? 'Reference #'
                    : 'Secondary Ref #'}
                </Text>
              </View>
              <View style={{ width: '60%' }}>
                <Text style={{ fontSize: '10px' }}>
                  {order?.secondaryRefNumber}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              width: '40%',
              display: 'flex',
            }}
          >
            <View style={styles.row}>
              <View style={{ width: '40%' }}>
                <Text style={styles.subHeaderText}>Service Date:</Text>
              </View>
              <View style={{ width: '60%' }}>
                <Text style={{ fontSize: '10px' }}>
                  {isNil(appointmentTime)
                    ? ''
                    : appointmentTime.format('MM/DD')}
                </Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={{ width: '40%' }}>
                <Text style={styles.subHeaderText}>Dest</Text>
              </View>
              <View style={{ width: '60%' }}>
                <Text style={{ fontSize: '10px' }}>
                  {endStop?.address?.state}
                </Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={{ width: '40%' }}>
                <Text style={styles.subHeaderText}>Service Type: </Text>
              </View>
              <View style={{ width: '60%' }}>
                <Text style={{ fontSize: '10px' }}>
                  {shipment.legs[0]?.endStop.stopType}
                </Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={{ width: '40%' }}>
                <Text style={styles.subHeaderText}>Service Level: </Text>
              </View>
              <View style={{ width: '60%' }}>
                <Text style={{ fontSize: '10px' }}>
                  {shipment.order?.service?.name ?? ''}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              width: '30%',
              display: 'flex',
              flexDirection: 'column-reverse',
            }}
          >
            <View style={styles.rowWithPadding}>
              <View style={{ width: '25%' }}>
                <Text style={styles.subHeaderText}>Weight:</Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text style={{ fontSize: '10px' }}>
                  {shipment.packages.reduce(
                    (acc, pkg) => acc + (pkg?.weight ?? 0),
                    0,
                  )}
                </Text>
              </View>
            </View>
            <View style={styles.rowWithPadding}>
              <View style={{ width: '25%' }}>
                <Text style={styles.subHeaderText}>Pieces:</Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text style={{ fontSize: '10px' }}>
                  {shipment.packages.reduce(
                    (acc, pkg) => acc + pkg.quantity,
                    0,
                  )}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
      <View style={[styles.cell, { flexDirection: 'row', borderTopWidth: 0 }]}>
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '10px',
          }}
        >
          <View style={styles.rowWithPadding}>
            <View style={{ width: '20%', display: 'flex' }}>
              <Text style={styles.subHeaderText}>Address:</Text>
            </View>
            <View style={{ width: '80%', display: 'flex' }}>
              {!isNil(endStop) && (
                <DisplayAddress fontSize="10px" address={endStop?.address} />
              )}
            </View>
          </View>
          <View style={styles.rowWithPadding}>
            <View style={{ width: '20%' }}>
              <Text style={styles.subHeaderText}>Phone:</Text>
            </View>
            <View style={{ width: '80%' }}>
              <Text style={styles.subHeaderTextSize}>
                {endStop?.contactPerson?.phone}
              </Text>
            </View>
          </View>
          <View style={styles.rowWithPadding}>
            <View style={{ width: '20%' }}>
              <Text style={styles.subHeaderText}>Contact:</Text>
            </View>
            <View style={{ width: '80%' }}>
              <Text style={styles.subHeaderTextSize}>
                {!isNil(endStop?.contactPerson?.firstName) &&
                  endStop?.contactPerson?.firstName}{' '}
                {!isNil(endStop?.contactPerson?.lastName) &&
                  endStop?.contactPerson?.lastName}
              </Text>
            </View>
          </View>
          <View style={styles.rowWithPadding}>
            <View style={{ width: '20%' }}>
              <Text style={styles.subHeaderText}>Delivery Instructions:</Text>
            </View>
            <View style={{ width: '80%' }}>
              <Text style={styles.subHeaderTextSize}>
                {!isNil(endStop?.specialInstructions) &&
                  endStop?.specialInstructions}
              </Text>
            </View>
          </View>
          <View style={styles.rowWithPadding}>
            <View style={{ width: '20%' }}>
              <Text style={styles.subHeaderText}>POD Full Signee Name:</Text>
            </View>
            <View style={{ width: '80%' }}>
              <Text style={styles.subHeaderTextSize}>
                {!isNil(endStop?.proofOfDeliverySignee) &&
                  endStop?.proofOfDeliverySignee}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={[styles.cell, { flexDirection: 'row', borderTopWidth: 0 }]}>
        <View
          style={{
            borderRightWidth: 1,
            width: '33%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <View style={styles.rowWithPadding}>
            <View style={{ width: '50%', display: 'flex' }}>
              <Text style={styles.subHeaderText}>Pickup Appt:</Text>
            </View>
            <View style={{ width: '50%', display: 'flex' }}>
              {isPickup && !isNil(appointmentTime) && (
                <Text style={styles.subHeaderTextSize}>
                  {appointmentTime.format(APPT_TIME_FORMAT)}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.rowWithPadding}>
            <View style={{ width: '50%', display: 'flex' }}>
              <Text style={styles.subHeaderText}>Pickup Deadline:</Text>
            </View>
            <View style={{ width: '50%', display: 'flex' }}>
              {isPickup && !isNil(endAppointmentTime) && (
                <Text style={styles.subHeaderTextSize}>
                  {dayjs(endAppointmentTime).format(APPT_TIME_FORMAT)}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.rowWithPadding}>
            <View style={{ width: '50%', display: 'flex' }}>
              <Text style={styles.subHeaderText}>Pickup Driver:</Text>
            </View>
            <View style={{ width: '50%', display: 'flex' }}>
              {isPickup && !isNil(driver) && (
                <Text style={styles.subHeaderTextSize}>
                  {driver.firstName} {driver.lastName}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.rowWithPadding}>
            <View style={{ width: '50%', display: 'flex' }}>
              <Text style={styles.subHeaderText}>Completed time:</Text>
            </View>
            <View style={{ width: '50%', display: 'flex' }}>
              {isPickup && !isNil(endStop) && !isNil(endStop.completedAt) && (
                <Text style={styles.subHeaderTextSize}>
                  {transformDateTimeToDateTimeString(endStop.completedAt)}
                </Text>
              )}
            </View>
          </View>
        </View>
        <View
          style={{
            borderRightWidth: 1,
            paddingLeft: '10px',
            width: '33%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <View style={styles.rowWithPadding}>
            <View style={{ width: '50%', display: 'flex' }}>
              <Text style={styles.subHeaderText}>Delivery Appt:</Text>
            </View>
            <View style={{ width: '50%', display: 'flex' }}>
              {!isPickup && !isNil(appointmentTime) && (
                <Text style={styles.subHeaderTextSize}>
                  {dayjs(appointmentTime).format(APPT_TIME_FORMAT)}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.rowWithPadding}>
            <View style={{ width: '50%', display: 'flex' }}>
              <Text style={styles.subHeaderText}>Delivery Deadline:</Text>
            </View>
            <View style={{ width: '50%', display: 'flex' }}>
              {!isPickup && !isNil(endAppointmentTime) && (
                <Text style={styles.subHeaderTextSize}>
                  {dayjs(endAppointmentTime).format(APPT_TIME_FORMAT)}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.rowWithPadding}>
            <View style={{ width: '50%', display: 'flex' }}>
              <Text style={styles.subHeaderText}>Delivery Driver:</Text>
            </View>
            <View style={{ width: '50%', display: 'flex' }}>
              {!isPickup && !isNil(driver) && (
                <Text style={styles.subHeaderTextSize}>
                  {driver.firstName} {driver.lastName}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.rowWithPadding}>
            <View style={{ width: '50%', display: 'flex' }}>
              <Text style={styles.subHeaderText}>Completed time:</Text>
            </View>
            <View style={{ width: '50%', display: 'flex' }}>
              {!isPickup && !isNil(endStop) && !isNil(endStop.completedAt) && (
                <Text style={styles.subHeaderTextSize}>
                  {transformDateTimeToDateTimeString(endStop.completedAt)}
                </Text>
              )}
            </View>
          </View>
        </View>
        <View
          style={{
            width: '33%',
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '10px',
          }}
        >
          <View style={styles.rowWithPadding}>
            <View style={{ width: '30%', display: 'flex' }}>
              <Text style={styles.subHeaderText}>Route:</Text>
            </View>
            <View style={{ width: '70%', display: 'flex' }}>
              <Text style={styles.subHeaderTextSize}>{route?.name}</Text>
            </View>
          </View>
        </View>
      </View>
      <View
        style={[styles.cell, { flexDirection: 'column', borderTopWidth: 0 }]}
      >
        <View style={styles.rowWithPadding}>
          <View style={{ width: '100%' }}>
            <Text style={styles.subHeaderText}>Packages</Text>
          </View>
        </View>
        <View style={{ width: '100%', display: 'flex' }}>
          {shipment.packages.map((pkg) => (
            // eslint-disable-next-line react/jsx-key
            <View style={styles.rowWithPadding}>
              <View style={{ width: '30%' }}>
                <Text style={styles.subHeaderText}>{pkg.description}</Text>
              </View>
              <View style={{ width: '30%' }}>
                {!isNil(createdOrderEvent) && (
                  <Text style={styles.subHeaderTextSize}>
                    {pkg.quantity} @ {pkg.length ?? '-'} x {pkg.width ?? '-'} x{' '}
                    {pkg.height ?? '-'}
                  </Text>
                )}
              </View>
              <View style={{ width: '40%' }}>
                {!isNil(createdOrderEvent) && (
                  <Text style={styles.subHeaderTextSize}>
                    Weight: {pkg.weight}lbs
                  </Text>
                )}
              </View>
            </View>
          ))}
        </View>
      </View>
      {showCharges && (
        <View
          style={[styles.cell, { flexDirection: 'column', borderTopWidth: 0 }]}
        >
          <View style={styles.rowWithPadding}>
            <Text style={styles.subHeaderText}>Charges</Text>
          </View>
          <View style={styles.rowWithPadding}>
            <View style={{ width: '50%' }}>
              <Text style={styles.subHeaderText}>Freight</Text>
              <Text style={styles.subHeaderText}>Fuel</Text>
              {customCharges.map((charge) => (
                // eslint-disable-next-line react/jsx-key
                <Text style={styles.subHeaderText}>
                  {isEmpty(charge?.name)
                    ? charge?.accessorialTemplate?.name
                    : charge?.name}
                </Text>
              ))}
            </View>
            <View style={{ width: '10%' }}>
              <Text style={[styles.subHeaderTextSize, { textAlign: 'right' }]}>
                {shipment.order?.totalFreightChargesAmount?.toFixed(2)}
              </Text>
              <Text style={[styles.subHeaderTextSize, { textAlign: 'right' }]}>
                {shipment.order?.totalFuelChargesAmount?.toFixed(2)}
              </Text>
              {/* <Text */}
              {/*  style={[styles.subHeaderTextSize, { textAlign: 'right' }]} */}
              {/* > */}
              {/*  {shipment.order?.totalCustomChargesAmount?.toFixed(2)} */}
              {/* </Text> */}
              {customCharges.map((charge) => (
                // eslint-disable-next-line react/jsx-key
                <Text
                  style={[styles.subHeaderTextSize, { textAlign: 'right' }]}
                >
                  {charge.total.toFixed(2)}
                </Text>
              ))}
            </View>
            <View style={{ width: '10%' }}>
              <Text style={[styles.subHeaderTextSize, { textAlign: 'right' }]}>
                {!isEmpty(freightAuthoCode) && !isNil(freightAuthoCode)
                  ? `Autho: ${freightAuthoCode}`
                  : ''}
              </Text>
              <Text style={[styles.subHeaderTextSize, { textAlign: 'right' }]}>
                {!isEmpty(fuelAuthoCode) && !isNil(fuelAuthoCode)
                  ? `Autho: ${fuelAuthoCode}`
                  : ''}
              </Text>
              {customCharges.map((charge) => (
                // eslint-disable-next-line react/jsx-key
                <Text
                  style={[styles.subHeaderTextSize, { textAlign: 'right' }]}
                >
                  {!isEmpty(charge.authoCode) && !isNil(charge.authoCode)
                    ? `Autho: ${charge.authoCode}`
                    : ''}
                </Text>
              ))}
            </View>
          </View>
          <View style={styles.rowWithPadding}>
            <View style={{ width: '60%', borderBottomWidth: 1 }} />
          </View>
          <View style={styles.rowWithPadding}>
            <View style={{ width: '50%' }}>
              <Text style={styles.subHeaderText}>Total Charges</Text>
            </View>
            <View style={{ width: '10%' }}>
              <Text style={[styles.subHeaderTextSize, { textAlign: 'right' }]}>
                {shipment.order?.totalCharge?.toFixed(2)}
              </Text>
            </View>
          </View>
        </View>
      )}
    </Page>
  );
};

const GeneratedPodReportPdf = ({
  segment,
  shipments,
  showCharges,
}: {
  readonly segment: Segment | undefined;
  readonly shipments: OrderPodReportShipmentFragment[];
  readonly showCharges: boolean;
}) => {
  const shipment = shipments[0];
  if (isNil(shipment) || isNil(shipment.legs) || isNil(shipment.charges))
    return <Document />;
  return (
    <Document title="POD Report">
      <PodReportComponent
        segment={segment}
        showCharges={showCharges}
        shipment={shipment}
      />
    </Document>
  );
};

export default GeneratedPodReportPdf;
