import { isNil } from 'lodash';
import React, { useState } from 'react';
import { shallow } from 'zustand/shallow';
import useDispatchStore from '../../dispatch-store';
import useAssignStopsRouteActions from '../../hooks/use-assign-stops-route-actions';
import RouteSelectionDropdown from './route-selection-dropdown';

const RouteStopAssignInput = ({
  stopUuids,
  currentRouteUuid,
  onAssign,
}: {
  readonly stopUuids: Array<string | undefined>;
  readonly currentRouteUuid?: string;
  readonly onAssign?: () => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const routes = useDispatchStore(
    (state) => (isOpen ? state.routes : []),
    shallow,
  );

  const [setShouldRefreshGrid, setSelectedStopUuids, setErrorMessage] =
    useDispatchStore(
      (state) => [
        state.setShouldRefreshGrid,
        state.setSelectedStopUuids,
        state.setErrorMessage,
      ],
      shallow,
    );

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { assignStop, reassignStop } = useAssignStopsRouteActions();

  const assignStops = async (route: { uuid: string }) => {
    setIsSaving(true);

    const reassignRes = await Promise.all(
      stopUuids.map(async (stopUuid) => {
        const oldSlotUuid = routes
          ?.find((r) => r.uuid === currentRouteUuid)
          ?.slots.find((slot) => slot.stops[0]?.uuid === stopUuid)?.uuid;
        if (!isNil(oldSlotUuid)) {
          return reassignStop({
            routeUuid: route.uuid,
            slotUuid: oldSlotUuid,
          });
        }
        return null;
      }),
    );

    if (reassignRes?.some((res) => res === false)) {
      setErrorMessage(
        `Failed to reassign ${
          reassignRes.filter((res) => res === false).length
        } stop(s).`,
      );
    } else if (reassignRes?.some((res) => isNil(res))) {
      const stopUuidsNotNull: string[] = [];

      for (const stopUuid of stopUuids) {
        if (!isNil(stopUuid)) {
          stopUuidsNotNull.push(stopUuid);
        }
      }

      const routeSlots = await assignStop({
        routeUuid: route.uuid,
        stopUuids: stopUuidsNotNull,
        emitMultiplayerEvent: true,
      });

      if (routeSlots?.some((routeSlot) => isNil(routeSlot)) === true) {
        setErrorMessage(
          `Failed to assign ${
            routeSlots.filter((routeSlot) => isNil(routeSlot)).length
          } stop(s). They may already have been assigned.`,
        );
      }
    }
    setIsSaving(false);
    if (stopUuids.length > 1) {
      setShouldRefreshGrid(true);
      setSelectedStopUuids([]);
    }
    if (!isNil(onAssign)) {
      onAssign();
    }
  };

  return (
    <RouteSelectionDropdown
      routes={routes}
      disabled={isSaving}
      onSelectRoute={assignStops}
      onClose={() => {
        setIsOpen(false);
      }}
      onOpen={() => {
        setIsOpen(true);
      }}
    />
  );
};

export default React.memo(RouteStopAssignInput);
