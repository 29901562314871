import { Box, Snackbar, Alert, Stack } from '@mui/material';
import {
  type ICellRendererParams,
  type ValueFormatterParams,
  type ValueGetterParams,
} from 'ag-grid-community';
import { capitalCase, sentenceCase } from 'change-case';
import dayjs, { type Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isEmpty, isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { getNoonOfDay } from 'shared/date';
import { exhaustive } from 'shared/switch';
import { v4 } from 'uuid';
import useInterval from '../../../common/react-hooks/use-interval';
import {
  AppointmentCallStatus,
  type AppointmentFragment,
  AppointmentTextStatus,
  GetAppointmentsDocument,
  type GetAppointmentsQuery,
  PickupOrDelivery,
  StandardStopType,
  useGetAppointmentsLazyQuery,
  useUpdateStopMutation,
} from '../../../generated/graphql';
import {
  BooleanFilter,
  MultiSelectFilter,
  SingleSelectFilter,
  TextFilter,
} from '../../ag-grid/filter-components';
import { formattedDateAbbreviated } from '../../dispatch/utils';
import AppointmentFilterField from '../enums/appointment-filters';
import AppointmentsTableTabs from '../types/appointments-table-tabs';
import {
  type PhoneNumberData,
  type UpdateRowPhoneNumber,
} from '../types/types';
import { AppointmentsTableWithFiltersAgGrid } from './appointments-table/appointments-table-ag-grid';
import PhoneNumberTextField from './phone-number-field';

dayjs.extend(utc);
dayjs.extend(timezone);

const UNCONFIRMED_STATUSES: Array<
  AppointmentTextStatus | AppointmentCallStatus | undefined | null
> = [
  AppointmentTextStatus.Sent,
  AppointmentCallStatus.InProgress,
  AppointmentCallStatus.Called,
  AppointmentCallStatus.Busy,
  AppointmentCallStatus.Failed,
  AppointmentCallStatus.Rejected,
  AppointmentCallStatus.NoAnswer,
  AppointmentCallStatus.Cancelled,
];

const UNSCHEDULED_STATUSES: Array<
  AppointmentTextStatus | AppointmentCallStatus | undefined | null
> = [
  AppointmentTextStatus.NotSent,
  AppointmentCallStatus.NotCalled,
  null,
  undefined,
];

const CONFIRMED_STATUSES: Array<
  AppointmentTextStatus | AppointmentCallStatus | undefined | null
> = [AppointmentTextStatus.Confirmed, AppointmentCallStatus.Confirmed];

const REJECTED_STATUSES: Array<
  AppointmentTextStatus | AppointmentCallStatus | undefined | null
> = [AppointmentTextStatus.Rejected, AppointmentCallStatus.Rejected];

const showInView = (
  view: AppointmentsTableTabs,
  appointment: AppointmentFragment,
) => {
  const isPickupOrDelivery =
    appointment.leg.shipment.standardShipmentFields?.pickupOrDelivery ===
      PickupOrDelivery.Delivery ||
    appointment.leg.shipment.standardShipmentFields?.pickupOrDelivery ===
      PickupOrDelivery.Pickup;
  const hasAppointment =
    !isNil(appointment.appointmentTime) ||
    !isNil(appointment.endAppointmentTime);
  const hasContact = !isNil(appointment.contactPerson?.phone);
  const containsUnscheduledStatus =
    UNSCHEDULED_STATUSES.includes(appointment.appointmentTextStatus) ||
    UNSCHEDULED_STATUSES.includes(appointment.appointmentCallStatus);
  const containsUnconfirmedStatus =
    UNCONFIRMED_STATUSES.includes(appointment.appointmentTextStatus) ||
    UNCONFIRMED_STATUSES.includes(appointment.appointmentCallStatus);
  const containsConfirmedStatus =
    CONFIRMED_STATUSES.includes(appointment.appointmentTextStatus) ||
    CONFIRMED_STATUSES.includes(appointment.appointmentCallStatus);
  const containsRejectedStatus =
    REJECTED_STATUSES.includes(appointment.appointmentTextStatus) ||
    REJECTED_STATUSES.includes(appointment.appointmentCallStatus);
  const isManuallyConfirmed = appointment.appointmentManuallyConfirmed === true;
  switch (view) {
    case AppointmentsTableTabs.Appointments: {
      return true;
    }
    case AppointmentsTableTabs.Unscheduled: {
      return (
        isPickupOrDelivery &&
        hasContact &&
        hasAppointment &&
        containsUnscheduledStatus &&
        !containsUnconfirmedStatus &&
        !containsConfirmedStatus &&
        !containsRejectedStatus &&
        !isManuallyConfirmed
      );
    }
    case AppointmentsTableTabs.Unconfirmed: {
      return (
        isPickupOrDelivery &&
        hasContact &&
        hasAppointment &&
        containsUnconfirmedStatus &&
        !containsConfirmedStatus &&
        !containsRejectedStatus &&
        !isManuallyConfirmed
      );
    }
    case AppointmentsTableTabs.Confirmed: {
      return (
        isPickupOrDelivery &&
        hasContact &&
        hasAppointment &&
        (containsConfirmedStatus || isManuallyConfirmed) &&
        !containsRejectedStatus
      );
    }
    case AppointmentsTableTabs.RescheduleRequested: {
      return (
        isPickupOrDelivery &&
        hasContact &&
        hasAppointment &&
        !containsConfirmedStatus &&
        containsRejectedStatus &&
        !isManuallyConfirmed
      );
    }
    case AppointmentsTableTabs.WithoutAppointments: {
      return !hasAppointment;
    }
    case AppointmentsTableTabs.WithoutContacts: {
      return !hasContact;
    }
    default: {
      return exhaustive(view);
    }
  }
};

const defaultFilterTabConfigs = {
  defaultTab: AppointmentsTableTabs.Unscheduled,
  baseTab: AppointmentsTableTabs.Unscheduled,
  tabs: [
    {
      value: AppointmentsTableTabs.Appointments,
      label: 'Stops',
      filterFunction: (
        appointments: AppointmentFragment[],
      ): AppointmentFragment[] => {
        return appointments;
      },
      shouldShowCheckboxes: false,
    },
    {
      value: AppointmentsTableTabs.Unscheduled,
      label: 'Unnotified',
      filterFunction: (
        appointments: AppointmentFragment[],
      ): AppointmentFragment[] => {
        return appointments.filter((appt) =>
          showInView(AppointmentsTableTabs.Unscheduled, appt),
        );
      },
      shouldShowCheckboxes: true,
    },
    {
      value: AppointmentsTableTabs.Unconfirmed,
      label: 'Unconfirmed',
      filterFunction: (
        appointments: AppointmentFragment[],
      ): AppointmentFragment[] => {
        return appointments.filter((appt) =>
          showInView(AppointmentsTableTabs.Unconfirmed, appt),
        );
      },
      shouldShowCheckboxes: true,
    },
    {
      value: AppointmentsTableTabs.RescheduleRequested,
      label: 'Reschedule requested',
      filterFunction: (
        appointments: AppointmentFragment[],
      ): AppointmentFragment[] => {
        return appointments.filter((appt) =>
          showInView(AppointmentsTableTabs.RescheduleRequested, appt),
        );
      },
      shouldShowCheckboxes: true,
    },
    {
      value: AppointmentsTableTabs.Confirmed,
      label: 'Confirmed',
      filterFunction: (
        appointments: AppointmentFragment[],
      ): AppointmentFragment[] => {
        return appointments.filter((appt) =>
          showInView(AppointmentsTableTabs.Confirmed, appt),
        );
      },
      shouldShowCheckboxes: false,
    },
    {
      value: AppointmentsTableTabs.WithoutContacts,
      label: 'Stops without contacts',
      filterFunction: (
        appointments: AppointmentFragment[],
      ): AppointmentFragment[] => {
        return appointments.filter((appt) =>
          showInView(AppointmentsTableTabs.WithoutContacts, appt),
        );
      },
      shouldShowCheckboxes: false,
    },
    {
      value: AppointmentsTableTabs.WithoutAppointments,
      label: 'Stops without appointments',
      filterFunction: (
        appointments: AppointmentFragment[],
      ): AppointmentFragment[] => {
        return appointments.filter((appt) =>
          showInView(AppointmentsTableTabs.WithoutAppointments, appt),
        );
      },
      shouldShowCheckboxes: false,
    },
  ],
};
const AppointmentsView = () => {
  const localDate = localStorage.getItem('appointmentDate');
  const [selectedDate, setSelectedDate] = useState<Dayjs>(
    isNil(localDate) ? dayjs().startOf('day') : dayjs(localDate),
  );

  // Query appointments data for the selected date
  const [getAppointments, { data: appointmentsData }] =
    useGetAppointmentsLazyQuery();
  const [updateStop] = useUpdateStopMutation({
    refetchQueries: [GetAppointmentsDocument],
  });

  const [data, setData] = useState<GetAppointmentsQuery | undefined>(undefined);
  const [showSuccessfulSave, setShowSuccessfulSave] = useState(false);
  const [filterTabConfigs, setFilterTabConfigs] = useState(
    defaultFilterTabConfigs,
  );

  const fetchAppointments = () => {
    getAppointments({
      variables: {
        deliveryDate: dayjs(getNoonOfDay(selectedDate)),
      },
    });
  };

  useInterval(() => {
    fetchAppointments();
  }, 10_000);

  useEffect(() => {
    setFilterTabConfigs(defaultFilterTabConfigs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilterTabConfigs.tabs.length]);

  useEffect(() => {
    localStorage.setItem('appointmentDate', selectedDate.toISOString());
    setFilterTabConfigs((prevState) => {
      return {
        ...prevState,
        tabs: prevState.tabs.map((tab) => {
          if (tab.value === AppointmentsTableTabs.Appointments) {
            return {
              ...tab,
              label: `Stops for ${formattedDateAbbreviated(selectedDate)}`,
            };
          }
          return tab;
        }),
      };
    });
  }, [selectedDate]);

  useEffect(() => {
    fetchAppointments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);
  // Set appointment table rows data for the selected date
  useEffect(() => {
    setData(appointmentsData);
  }, [appointmentsData]);

  const filterColumns = [
    {
      field: 'Consignee',
      minWidth: 120,
      valueGetter: (params: ValueGetterParams) => {
        if (isEmpty(params.data.shipmentConsigneeAddress.name)) {
          return 'NA';
        }
        return params.data.shipmentConsigneeAddress.name;
      },
      headerName: 'Consignee',
      filter: TextFilter,
    },
    {
      headerName: 'Text status',
      valueGetter: (params: ValueGetterParams) => {
        return capitalCase(params.data.appointmentTextStatus ?? 'Not sent');
      },
      filter: SingleSelectFilter,
      field: AppointmentFilterField.TEXT_STATUS,
      filterParams: {
        values: Object.values(AppointmentTextStatus).map((status) => ({
          actualValue: status,
          displayValue: sentenceCase(status),
        })),
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        keyCreator: (params: ValueFormatterParams) => params.value.uuid,
        valueFormatter: (params: ValueFormatterParams) =>
          sentenceCase(params.value.displayValue),
      },
    },
    {
      headerName: 'Call status',
      valueGetter: (params: ValueGetterParams) => {
        return capitalCase(params.data.appointmentCallStatus ?? 'Not called');
      },
      filter: SingleSelectFilter,
      field: AppointmentFilterField.CALL_STATUS,
      filterParams: {
        values: [
          AppointmentCallStatus.Called,
          AppointmentCallStatus.Confirmed,
          AppointmentCallStatus.Failed,
          AppointmentCallStatus.InProgress,
          AppointmentCallStatus.Rejected,
        ].map((status) => ({
          actualValue: status,
          displayValue: sentenceCase(status),
        })),
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        keyCreator: (params: ValueFormatterParams) => params.value.uuid,
        valueFormatter: (params: ValueFormatterParams) =>
          sentenceCase(params.value.displayValue),
      },
    },
    {
      headerName: 'Service level',
      valueGetter: (params: ValueGetterParams) => {
        return capitalCase(
          params.data.leg?.shipment?.standardShipmentFields?.service?.name ??
            'None',
        );
      },
      filter: MultiSelectFilter,
      field: AppointmentFilterField.SERVICE,
      filterParams: {
        values: [{}],
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        keyCreator: (params: ValueFormatterParams) => params.value.uuid,
        valueFormatter: (params: ValueFormatterParams) =>
          sentenceCase(params.value.displayValue),
      },
    },
    {
      field: AppointmentFilterField.PHONE,
      minWidth: 120,
      cellRenderer: PhoneNumberTextField,
      filter: TextFilter,
    },
    {
      field: AppointmentFilterField.APPOINTMENT_CONTACT,
      headerName: 'Contact',
      filter: TextFilter,
      valueGetter: (params: ValueGetterParams) => {
        if (
          isNil(params.data.contactPerson) ||
          (isEmpty(params.data.contactPerson.firstName) &&
            isEmpty(params.data.contactPerson.lastName))
        ) {
          return 'None';
        }
        return capitalCase(
          `${params.data.contactPerson.firstName ?? ''} ${
            params.data.contactPerson.lastName ?? ''
          }`,
        );
      },
    },
    {
      field: AppointmentFilterField.ADDRESS_TYPE,
      headerName: 'Address type',
      filter: SingleSelectFilter,
      hide: true,
      valueGetter: (params: ValueGetterParams) => {
        return params.data.standardStopType;
      },
      filterParams: {
        values: [StandardStopType.Commercial, StandardStopType.Residential].map(
          (type) => ({
            actualValue: type,
            displayValue: sentenceCase(type),
          }),
        ),
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        keyCreator: (params: ValueFormatterParams) => params.value.uuid,
        valueFormatter: (params: ValueFormatterParams) =>
          sentenceCase(params.value.displayValue),
      },
    },
    {
      field: AppointmentFilterField.APPOINTMENT_REQUIRED,
      headerName: 'Appt required',
      filter: BooleanFilter,
      valueGetter: (params: ValueGetterParams) => {
        return params.data.appointmentRequired === true ? 'Yes' : 'No';
      },
      filterParams: {
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        keyCreator: (params: ValueFormatterParams) => params.value.uuid,
        valueFormatter: (params: ValueFormatterParams) =>
          sentenceCase(params.value.displayValue),
      },
    },
    {
      field: AppointmentFilterField.CUSTOMER,
      headerName: 'Customer',
      hide: true,
      filter: SingleSelectFilter,
      filterParams: {
        values: [{}],
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        keyCreator: (params: ValueFormatterParams) => params.value.uuid,
        valueFormatter: (params: ValueFormatterParams) =>
          sentenceCase(params.value.displayName),
      },
    },
  ];

  filterColumns.sort((a, b) =>
    a.field.toString().localeCompare(b.field.toString()),
  );

  const columnDefs = [
    {
      field: 'checkbox',
      headerName: '',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      maxWidth: 50,
      lockPosition: 'left',
    },
    {
      field: 'Client reference number',
      headerName: 'Order #',
      valueGetter: (params: ValueGetterParams) => {
        return (
          params.data.leg.shipment.order?.standardOrderFields
            .shipperBillOfLadingNumber ?? params.data.leg.shipment.order?.name
        );
      },
    },
    {
      field: 'Consignee',
      minWidth: 120,
      valueGetter: (params: ValueGetterParams) => {
        if (isEmpty(params.data.shipmentConsigneeAddress.name)) {
          return 'NA';
        }
        return params.data.shipmentConsigneeAddress.name;
      },
      headerName: 'Consignee',
      filter: TextFilter,
    },
    {
      field: 'Appointment',
      headerName: 'Appointment',
      minWidth: 150,
      valueGetter: (params: ValueGetterParams) => {
        let appointmentString = '';
        const { appointmentTime } = params.data;
        const { endAppointmentTime } = params.data;
        if (!isNil(appointmentTime)) {
          appointmentString += `${dayjs(appointmentTime).format('hh:mm a')}`;
        }
        if (!isNil(appointmentTime) && !isNil(endAppointmentTime)) {
          appointmentString += ' - ';
        }
        if (!isNil(endAppointmentTime)) {
          appointmentString += `${dayjs(endAppointmentTime).format('hh:mm a')}`;
        }
        if (isNil(appointmentTime) && isNil(endAppointmentTime)) {
          appointmentString = 'No appointment';
        }
        return appointmentString;
      },
    },
    {
      field: 'City',
      headerName: 'City',
      valueGetter: (params: ValueGetterParams) => {
        return capitalCase(params.data.address.city);
      },
    },
    {
      field: 'Manually confirmed',
      headerName: 'Manually confirmed',
      // minWidth: '100',
      valueGetter: (params: ValueGetterParams) => {
        return capitalCase(
          params.data.appointmentManuallyConfirmed === true ? 'Yes' : 'No',
        );
      },
    },
    {
      field: 'Stop type',
      headerName: 'Stop type',
      // minWidth: '10',
      valueGetter: (params: ValueGetterParams) => {
        return capitalCase(
          params.data.leg?.shipment?.standardShipmentFields?.pickupOrDelivery ??
            'NA',
        );
      },
    },
    {
      field: 'Text responses',
      headerName: 'Text responses',
      valueGetter: (params: ValueGetterParams) => {
        return params.data.textResponses ?? 'None';
      },
    },
    {
      headerName: 'Text status',
      valueGetter: (params: ValueGetterParams) => {
        return capitalCase(params.data.appointmentTextStatus ?? 'Not sent');
      },
      filter: SingleSelectFilter,
      field: AppointmentFilterField.TEXT_STATUS,
      filterParams: {
        values: Object.values(AppointmentTextStatus).map((status) => ({
          actualValue: status,
          displayValue: sentenceCase(status),
        })),
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        keyCreator: (params: ValueFormatterParams) => params.value.uuid,
        valueFormatter: (params: ValueFormatterParams) =>
          sentenceCase(params.value.displayValue),
      },
    },
    {
      headerName: 'Call status',
      valueGetter: (params: ValueGetterParams) => {
        return capitalCase(params.data.appointmentCallStatus ?? 'Not called');
      },
      filter: SingleSelectFilter,
      field: AppointmentFilterField.CALL_STATUS,
      filterParams: {
        values: [
          AppointmentCallStatus.Called,
          AppointmentCallStatus.Confirmed,
          AppointmentCallStatus.Failed,
          AppointmentCallStatus.InProgress,
          AppointmentCallStatus.Rejected,
        ].map((status) => ({
          actualValue: status,
          displayValue: sentenceCase(status),
        })),
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        keyCreator: (params: ValueFormatterParams) => params.value.uuid,
        valueFormatter: (params: ValueFormatterParams) =>
          sentenceCase(params.value.displayValue),
      },
    },
    {
      headerName: 'Service level',
      valueGetter: (params: ValueGetterParams) => {
        return capitalCase(
          params.data.leg?.shipment?.standardShipmentFields?.service?.name ??
            'None',
        );
      },
      filter: MultiSelectFilter,
      field: AppointmentFilterField.SERVICE,
      filterParams: {
        values: [{}],
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        keyCreator: (params: ValueFormatterParams) => params.value.uuid,
        valueFormatter: (params: ValueFormatterParams) =>
          sentenceCase(params.value.displayValue),
      },
    },
    {
      field: 'Driver',
      headerName: 'Driver',
      valueGetter: (params: ValueGetterParams) => {
        const driver = params.data.routeSlot?.route?.drivers[0];
        const route = params.data.routeSlot?.route;
        if (!isNil(driver)) {
          return `${driver.firstName ?? 'Driver'} ${driver.lastName ?? ''}`;
        }
        if (!isNil(route)) {
          return 'No driver';
        }
        return 'No route';
      },
    },
    {
      field: 'Address',
      minWidth: 160,
      // eslint-disable-next-line react/no-unstable-nested-components
      cellRenderer: (params: ICellRendererParams) => {
        const { address } = params.data;
        return (
          <>
            <div>{capitalCase(address.line1)}, </div>
            <div>{!isEmpty(address.zip) && address.zip}</div>
          </>
        );
      },
    },
    {
      field: AppointmentFilterField.PHONE,
      minWidth: 120,
      cellRenderer: PhoneNumberTextField,
      filter: TextFilter,
    },
    {
      field: AppointmentFilterField.APPOINTMENT_CONTACT,
      headerName: 'Contact',
      filter: TextFilter,
      valueGetter: (params: ValueGetterParams) => {
        if (
          isNil(params.data.contactPerson) ||
          (isEmpty(params.data.contactPerson.firstName) &&
            isEmpty(params.data.contactPerson.lastName))
        ) {
          return 'None';
        }
        return capitalCase(
          `${params.data.contactPerson.firstName ?? ''} ${
            params.data.contactPerson.lastName ?? ''
          }`,
        );
      },
    },
    {
      field: AppointmentFilterField.ADDRESS_TYPE,
      headerName: 'Address type',
      filter: SingleSelectFilter,
      hide: true,
      valueGetter: (params: ValueGetterParams) => {
        return params.data.standardStopType;
      },
      filterParams: {
        values: [StandardStopType.Commercial, StandardStopType.Residential].map(
          (type) => ({
            actualValue: type,
            displayValue: sentenceCase(type),
          }),
        ),
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        keyCreator: (params: ValueFormatterParams) => params.value.uuid,
        valueFormatter: (params: ValueFormatterParams) =>
          sentenceCase(params.value.displayValue),
      },
    },
    {
      field: AppointmentFilterField.APPOINTMENT_REQUIRED,
      headerName: 'Appt required',
      filter: BooleanFilter,
      valueGetter: (params: ValueGetterParams) => {
        return params.data.appointmentRequired === true ? 'Yes' : 'No';
      },
      filterParams: {
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        keyCreator: (params: ValueFormatterParams) => params.value.uuid,
        valueFormatter: (params: ValueFormatterParams) =>
          sentenceCase(params.value.displayValue),
      },
    },
    {
      field: AppointmentFilterField.CUSTOMER,
      headerName: 'Customer',
      hide: true,
      filter: SingleSelectFilter,
      filterParams: {
        values: [{}],
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        keyCreator: (params: ValueFormatterParams) => params.value.uuid,
        valueFormatter: (params: ValueFormatterParams) =>
          sentenceCase(params.value.displayName),
      },
    },
  ] as const;

  const handleSavePhoneNumbers = async (
    numbersToUpdate: PhoneNumberData[],
    updateRowPhoneNumbersCallback: (updates: UpdateRowPhoneNumber[]) => void,
  ) => {
    try {
      const results = await Promise.all(
        numbersToUpdate.map(async (numberInfo) => {
          if (!isNil(numberInfo.stopUuid)) {
            return updateStop({
              variables: {
                updateStopInput: {
                  stopUpdateInput: {
                    uuid: numberInfo.stopUuid,
                    contactPersonUpsertInput: {
                      uuid: numberInfo.contactPersonUuid ?? v4(),
                      phone: numberInfo.phoneNumber,
                    },
                  },
                },
              },
            });
          }
          return null;
        }),
      );

      const convertToUpdateObject: UpdateRowPhoneNumber[] = [];
      for (const result of results) {
        const stopUuid = result?.data?.updateStop.uuid;
        const contactPerson = result?.data?.updateStop.contactPerson;
        const phone = contactPerson?.phone;
        if (!isNil(stopUuid) && !isNil(contactPerson) && !isNil(phone)) {
          convertToUpdateObject.push({
            stopUuid,
            contactPerson: {
              uuid: contactPerson.uuid,
              phone,
            },
          });
        }
      }
      updateRowPhoneNumbersCallback(convertToUpdateObject);
      setShowSuccessfulSave(true);
    } catch (error) {
      console.error('Error saving phone numbers', error);
    }
  };

  return (
    <Stack height="100%" flexDirection="column">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          width: '100%',
          position: 'relative',
        }}
      />
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showSuccessfulSave}
      >
        <Alert>Successfully saved</Alert>
      </Snackbar>
      <Box flex={1} minHeight={0}>
        <AppointmentsTableWithFiltersAgGrid<AppointmentsTableTabs>
          rowSelectionEnabled
          appointmentsData={data}
          columnDefinitions={columnDefs}
          filterColumns={filterColumns}
          pageSize={10}
          handleSavePhoneNumbers={handleSavePhoneNumbers}
          defaultFilterTabsConfigs={filterTabConfigs}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      </Box>
    </Stack>
  );
};

export default AppointmentsView;
