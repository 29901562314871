import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { type DateOption } from '../../../../../common/components/date-dropdown-picker';

export const formatDateOption = (dateOption: DateOption) => {
  if (isNil(dateOption.startDate) && isNil(dateOption.endDate)) {
    return 'All';
  }
  return `${isNil(dateOption.startDate) ? 'All' : dayjs(dateOption.startDate).format('MM/DD')} - ${
    isNil(dateOption.endDate)
      ? 'All'
      : dayjs(dateOption.endDate).format('MM/DD/YY')
  }`;
};
