import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Divider,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import { useState } from 'react';
import CSVDownloadButton from '../../../common/components/buttons/csv-download-button';
import CustomerFilterButton from '../../../common/components/customer-filter-button';
import DateDropdownPicker, {
  type DateOption,
  defaultPast1WeekDateRangeOption,
} from '../../../common/components/date-dropdown-picker';
import TerminalFilterButton from '../../../common/components/terminal-filter-button';
import { type Option } from '../../../common/filters/types';
import useMe from '../../../common/react-hooks/use-me';
import {
  type DockScanReportInput,
  useDockScanReportLazyQuery,
} from '../../../generated/graphql';
import { formatDateOption } from '../../invoices/components/invoices/download/utils';
import styles from '../../invoices/styles';
import { convertDockScanReportToCsv } from '../utils';

type DownloadDockScanReportProps = {
  readonly onClose: () => void;
};

const DownloadDockScanReport = ({ onClose }: DownloadDockScanReportProps) => {
  const [customerOption, setCustomerOption] = useState<Option | undefined>();
  const [terminalOption, setTerminalOption] = useState<Option | undefined>();
  const { companyConfiguration } = useMe();
  const [dateOption, setDateOption] = useState<DateOption>(
    defaultPast1WeekDateRangeOption,
  );

  const [getDockScanReport] = useDockScanReportLazyQuery();

  const dockScanReportInput: DockScanReportInput = {
    contactUuid: customerOption?.value,
    startDate: dateOption.startDate,
    endDate: dateOption.endDate,
    terminalUuid: terminalOption?.value,
  };

  const downloadCSV = async () => {
    const res = await getDockScanReport({
      variables: {
        dockScanReportInput,
      },
    });

    if (isNil(res.data)) return null;
    const csvData = convertDockScanReportToCsv(
      res.data,
      companyConfiguration?.terminalsEnabled,
    );
    return csvData;
  };

  return (
    <Box
      sx={[
        styles.modal,
        {
          height: 'fit-content',
          width: '400px',
        },
      ]}
    >
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={10}>
          <Typography sx={{ fontWeight: 'bold' }}>
            Download dock scan report
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <IconButton sx={{ float: 'right' }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} sx={{ height: '100%', mb: 2 }}>
          <Stack direction="column" spacing={1}>
            <Box>
              <DateDropdownPicker
                filterTitle="Scan date"
                dateOption={dateOption}
                setDateOption={setDateOption}
              />
            </Box>
            <CustomerFilterButton
              selectedOption={customerOption}
              handleChange={(option: Option | undefined) => {
                setCustomerOption(option);
              }}
            />
            <TerminalFilterButton
              selectedOption={terminalOption}
              handleChange={(option: Option | null | undefined) => {
                setTerminalOption(option ?? undefined);
              }}
              prefixText="Terminal"
              includeInactiveTerminals={false}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="end" alignContent="center">
            <CSVDownloadButton
              getData={downloadCSV}
              buttonProps={{
                sx: { width: 100 },
                variant: 'contained',
                color: 'info',
                disabled: false,
              }}
              filename={`dock-scan-report-${formatDateOption(dateOption)}.csv`}
              label="Download"
              reportType="dock-scan"
              dataForTimingLog={dockScanReportInput}
            />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DownloadDockScanReport;
