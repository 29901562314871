import { createContext, type Dispatch } from 'react';

const ErrorContext = createContext<{
  errorMessage: string | null;
  setErrorMessage: Dispatch<React.SetStateAction<string | null>>;
}>({
  errorMessage: null,
  setErrorMessage: () => {},
});

export default ErrorContext;
