// eslint-disable-next-line no-restricted-imports
import { Box, Grid, type SxProps, Typography } from '@mui/material';
import React, { type ReactNode } from 'react';
import { isNilOrEmptyString } from '../../../../common/utils/utils';

type CustomerLoadPageFieldProps = {
  readonly spacing?: number;
  readonly title: string;
  readonly value?: ReactNode | string | null;
  readonly defaultValue: string;

  readonly helperText?: ReactNode;
};

const styles = {
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
  },
  bold: {
    fontWeight: 600,
  } as SxProps,
  gridItem: {
    textAlign: 'left',
    whiteSpace: 'pre-line',
  } as SxProps,
};

const CustomerLoadPageField = ({
  spacing,
  title,
  value,
  defaultValue,
  helperText,
}: CustomerLoadPageFieldProps) => {
  return (
    <Grid item xs={spacing}>
      <Box sx={styles.gridItem}>
        <Box sx={styles.titleContainer}>
          <Typography sx={styles.bold}>{title}</Typography>
          {helperText}
        </Box>
        <Typography>
          {isNilOrEmptyString(value) ? defaultValue : value}
        </Typography>
      </Box>
    </Grid>
  );
};

export default CustomerLoadPageField;
