import { isNil } from 'lodash';
import { useLocation } from 'react-router-dom';
import { exhaustive } from 'shared/switch';
import useGlobalStore from '../../../layouts/dashboard/global-store';

export enum PageMode {
  EDIT,
  CREATE,
  DUPLICATE,
  VIEW,
  LOADING,
}

export const useQuotePageMode = ({
  forceEditMode = false,
}: {
  forceEditMode?: boolean;
}) => {
  const { pathname } = useLocation();
  if (pathname.includes('/add') && !forceEditMode) {
    return PageMode.CREATE;
  }

  return PageMode.EDIT;
};

// @deprecated
const usePageMode = (): PageMode => {
  const { pathname } = useLocation();
  const currentOrderUuid = useGlobalStore((state) => state.currentOrderUuid);

  if (pathname.includes('duplicate')) {
    return PageMode.DUPLICATE;
  }
  if (pathname.includes('add')) {
    return PageMode.CREATE;
  }
  if (isNil(currentOrderUuid)) {
    return PageMode.LOADING;
  }
  return PageMode.EDIT;
};

export const useIsEditingDisabled = () => {
  const pageMode = usePageMode();
  if (isNil(pageMode)) {
    return true;
  }

  switch (pageMode) {
    case PageMode.EDIT:
    case PageMode.CREATE:
    case PageMode.DUPLICATE:
    case PageMode.LOADING: {
      return false;
    }
    case PageMode.VIEW: {
      return true;
    }
    default: {
      return exhaustive(pageMode);
    }
  }
};

export default usePageMode;
