import currency from 'currency.js';
import { isNil } from 'lodash';
import { type ChangeEvent, useState } from 'react';
import { MAX_INTEGER } from 'shared/math';
import { RangeInput } from './range-input';

export type RangeCellValues = Array<number | undefined>;

type RangeCellProps = {
  readonly rangeValues: RangeCellValues;
  readonly rangeIndex: number;
  readonly inputLabel: string;
  readonly disabled?: boolean;
  readonly editable?: boolean;
  readonly onChange?: (value: RangeCellValues) => void;
};

const RangeCell = ({
  rangeValues: range,
  rangeIndex,
  inputLabel,
  onChange: propsOnChange,
  disabled,
  editable = true,
}: RangeCellProps) => {
  const [error, setError] = useState<string | undefined>();

  const prevValue =
    Number.isNaN(range[rangeIndex - 1]) ||
    range[rangeIndex - 1] === MAX_INTEGER ||
    isNil(range[rangeIndex - 1])
      ? undefined
      : range[rangeIndex - 1];

  const isInOrder = (value: number) => {
    let leftValue = 0;
    let rightValue = MAX_INTEGER + 1;
    for (let i = rangeIndex - 1; i >= 0; i -= 1) {
      const currValue = range[i];
      if (!Number.isNaN(currValue) && !isNil(currValue)) {
        leftValue = currValue;
        break;
      }
    }

    for (let i = rangeIndex + 1; i < range.length; i += 1) {
      const currValue = range[i];
      if (!Number.isNaN(currValue) && !isNil(currValue)) {
        rightValue = currValue;
        break;
      }
    }
    return value > leftValue && value < rightValue;
  };

  const onChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const amount = Number.parseFloat(event.target.value);
    const newRange = [...range];
    newRange[rangeIndex] = Number.isNaN(amount) ? undefined : amount;
    propsOnChange?.(newRange);
  };

  const onBlur = () => {
    setError(undefined);
    let amount = range[rangeIndex];
    if (Number.isNaN(amount) || isNil(amount)) {
      return;
    }

    amount = Math.max(amount, 0);
    let rate = currency(amount, { precision: 3 }).value;
    if (rate > MAX_INTEGER) {
      rate = MAX_INTEGER;
    }

    if (!isInOrder(rate)) {
      setError('Invalid Range');
    }
    const newRange = [...range];
    newRange[rangeIndex] = rate ?? 0;
    propsOnChange?.(newRange);
  };

  const displayValue =
    range[rangeIndex] === MAX_INTEGER || isNil(range[rangeIndex])
      ? '?'
      : range[rangeIndex];

  return (
    <RangeInput
      rangeIndex={rangeIndex}
      prevValue={prevValue}
      rangeLength={range.length}
      disabled={disabled}
      error={error}
      inputLabel={inputLabel}
      displayValue={displayValue}
      editable={editable}
      onBlur={onBlur}
      onChange={onChange}
    />
  );
};

export default RangeCell;
