import { z } from 'zod';
import { userSchema } from './user-schema';
import { zDateOrDatetimeString } from './zod-utils';

export const orderCommentSchema = z.object({
  uuid: z.string().uuid().nullish(),
  authorName: z.string().nullish(),
  user: userSchema.nullish(),
  comment: z.string().nullish(),
  updatedAt: zDateOrDatetimeString().nullish(),
  showOnInvoice: z.boolean().nullish(),
});
