import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { filterNotNil } from 'shared/array';
import { type PartialDeep } from 'type-fest';
import { type DeepStringified } from '../../../common/types';
import type { RootState } from '../../../redux/store';
import { type ContactPersonFormField } from './contact-persons-values-slice';

export type ContactPersonFormFieldError = DeepStringified<
  PartialDeep<Omit<ContactPersonFormField, 'uuid'>> & { uuid: string }
>;
type ContactPersonErrorsStoreState = ContactPersonFormFieldError;

const contactPersonsErrorsAdapter =
  createEntityAdapter<ContactPersonErrorsStoreState>({
    selectId: (contactPerson) => contactPerson.uuid,
  });

export const contactPersonsErrorsSlice = createSlice({
  name: 'contactPersonsErrors',
  initialState: contactPersonsErrorsAdapter.getInitialState(),
  reducers: {
    addContactPersonErrors: contactPersonsErrorsAdapter.addOne,
    upsertContactPersonErrors: contactPersonsErrorsAdapter.upsertOne,
    updateContactPersonErrors: contactPersonsErrorsAdapter.updateOne,
  },
});

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectEntities: selectContactPersonErrorEntities,
  selectById: selectContactPersonErrorsById,
  // Pass in a selector that returns the posts slice of state
} = contactPersonsErrorsAdapter.getSelectors(
  (state: RootState) => state.orderFormContactPersonsErrors,
);

export const selectContactPersonErrorsByIds = createSelector(
  selectContactPersonErrorEntities,
  (state: RootState, ids: string[]) => ids,
  (entities, ids) => filterNotNil(ids.map((id) => entities[id])),
);

export const {
  addContactPersonErrors,
  upsertContactPersonErrors,
  updateContactPersonErrors,
} = contactPersonsErrorsSlice.actions;

/**
 * END THUNKS
 */

export default contactPersonsErrorsSlice.reducer;
