import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import pluralize from 'pluralize';
import React, {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useState,
} from 'react';
import { filterNotNil } from 'shared/array';
import { shallow } from 'zustand/shallow';
import useQuickbooksDesktopData from '../../../../../common/react-hooks/use-quickbooks-desktop-data';
import {
  type UpdateContactQuickbooksIdInput,
  useAddQuickbooksDesktopCustomersMutation,
  useBulkUpdateContactQuickbooksIdsMutation,
  useShallowContactsByUuidsQuery,
} from '../../../../../generated/graphql';
import QuickbooksDesktopMappingAutocomplete from '../../../../settings/components/quickbooks-desktop-mapping-autocomplete';
import useInvoicesStore from '../../../invoices-store';
import { type MissingCustomerMapping } from './types';

const CREATE_NEW_CONTACT_KEY = 'CREATE_NEW_CONTACT';

const QuickbooksDesktopErrorRow = ({
  component,
}: {
  readonly component: JSX.Element;
}) => {
  return (
    <Stack direction="row" gap={1} mb={1}>
      <ErrorOutlineIcon color="error" fontSize="small" />
      {component}
    </Stack>
  );
};

const ExportToQuickbooksDesktopModal = ({
  open,
  setOpen,
  missingFreightChargeMappings,
  missingFuelChargeMappings,
  missingAccessorialMappings,
  missingCustomerMappings,
  duplicateInvoiceErrors,
  conductorUserFacingErrors,
  exportFailed,
  failedExportCount,
  totalInvoiceCount,
  onExport,
}: {
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
  readonly missingFreightChargeMappings: string[];
  readonly missingFuelChargeMappings: string[];
  readonly missingAccessorialMappings: string[];
  readonly missingCustomerMappings: MissingCustomerMapping[];
  readonly duplicateInvoiceErrors: string[];
  readonly conductorUserFacingErrors: string[];
  readonly exportFailed: boolean;
  readonly failedExportCount: number | null;
  readonly totalInvoiceCount: number | null;
  readonly onExport: (uuids: string[]) => void;
}) => {
  const [setShouldRefreshInvoiceList, selectAllInvoiceUuids] = useInvoicesStore(
    (state) => [state.setShouldRefreshInvoiceList, state.selectAllInvoiceUuids],
    shallow,
  );

  const noErrors =
    !exportFailed &&
    missingCustomerMappings.length === 0 &&
    missingFuelChargeMappings.length === 0 &&
    missingAccessorialMappings.length === 0 &&
    missingFreightChargeMappings.length === 0 &&
    duplicateInvoiceErrors.length === 0 &&
    conductorUserFacingErrors.length === 0;

  const hasMissingChargeMappingErrors =
    missingFuelChargeMappings.length > 0 ||
    missingAccessorialMappings.length > 0 ||
    missingFreightChargeMappings.length > 0;

  const uuidsToRetry = missingCustomerMappings.flatMap(
    (cus) => cus.invoiceUuids,
  );

  const {
    quickbooksCustomers,
    quickbooksCustomersLoading,
    refetchQuickbooksCustomers,
  } = useQuickbooksDesktopData();

  const { data: contactsWithMissingMappings, loading: contactsLoading } =
    useShallowContactsByUuidsQuery({
      variables: {
        uuids: missingCustomerMappings.map((cus) => cus.uuid),
      },
    });
  const [contactsToUpdate, setContactsToUpdate] = useState<
    Record<string, string | undefined>
  >({});

  const [
    bulkUpdateContactQuickbooksIds,
    { loading: bulkUpdateContactQuickbooksIdsLoading },
  ] = useBulkUpdateContactQuickbooksIdsMutation();
  const [
    addQuickbooksDesktopCustomers,
    { loading: addQuickbooksDesktopCustomersLoading },
  ] = useAddQuickbooksDesktopCustomersMutation();

  const mapAndRetryInProgress: boolean =
    addQuickbooksDesktopCustomersLoading ||
    bulkUpdateContactQuickbooksIdsLoading;

  const [errorAddingQBDCustomers, setErrorAddingQBDCustomers] =
    useState<string>('');

  const onClose = (doNotRefresh?: boolean) => {
    if (!mapAndRetryInProgress) {
      setOpen(false);
      if (doNotRefresh !== true) {
        setShouldRefreshInvoiceList(true);
      }
    }
  };

  useEffect(() => {
    if (!isNil(quickbooksCustomers?.error)) {
      refetchQuickbooksCustomers();
    }
  }, []);

  const onMapContactsAndRetry = async () => {
    const updateQuickbooksIdInputs: UpdateContactQuickbooksIdInput[] = [];
    const contactUuidsToAdd: string[] = [];
    for (const contactUuid of Object.keys(contactsToUpdate)) {
      if (isNil(contactsToUpdate[contactUuid])) {
        contactUuidsToAdd.push(contactUuid);
      } else {
        updateQuickbooksIdInputs.push({
          uuid: contactUuid,
          quickbooksContactId: contactsToUpdate[contactUuid] ?? null,
        });
      }
    }

    if (updateQuickbooksIdInputs.length > 0) {
      await bulkUpdateContactQuickbooksIds({
        variables: {
          bulkUpdateContactQuickbooksId: {
            updateQuickbooksIdInputs,
          },
        },
      });
    }
    if (contactUuidsToAdd.length > 0) {
      const res = await addQuickbooksDesktopCustomers({
        variables: {
          contactUuids: contactUuidsToAdd,
        },
      });
      const errors = filterNotNil(
        res.data?.addQuickbooksDesktopCustomers.map((cus) => cus.error) ?? [],
      );
      if (errors.length > 0) {
        setErrorAddingQBDCustomers(errors.join('. '));
      }
    }
    refetchQuickbooksCustomers();

    selectAllInvoiceUuids(
      uuidsToRetry.map((uuid) => ({ uuid, isUnfinalized: false })),
    );
    onExport(uuidsToRetry);
    onClose(true);
  };

  useEffect(() => {
    const contactState: Record<string, string | undefined> = {};
    for (const contact of contactsWithMissingMappings?.contactsByUuids ?? []) {
      contactState[contact.uuid] =
        contact.quickbooksDesktopContactId ?? undefined;
    }
    setContactsToUpdate(contactState);
  }, [contactsWithMissingMappings]);

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="md"
      onClose={() => {
        onClose();
      }}
    >
      <Snackbar
        autoHideDuration={4000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={errorAddingQBDCustomers.length > 0}
        onClose={() => {
          setErrorAddingQBDCustomers('');
        }}
      >
        <Alert severity="error">
          Error adding customers in QBD: {errorAddingQBDCustomers}
        </Alert>
      </Snackbar>
      <IconButton
        aria-label="close"
        sx={{ padding: 0, float: 'right', m: '10px', ml: 'auto' }}
        disabled={mapAndRetryInProgress}
        onClick={() => {
          onClose();
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle textAlign="center">
        {noErrors
          ? `Successfully exported ${totalInvoiceCount ?? 0} invoices to QB Desktop`
          : `${!isNil(failedExportCount) && !isNil(totalInvoiceCount) ? `${failedExportCount} / ${totalInvoiceCount} invoices` : 'Invoices'} failed to export to
          QB Desktop`}
      </DialogTitle>
      <DialogContent>
        <Stack direction="column">
          {conductorUserFacingErrors.length > 0 &&
            QuickbooksDesktopErrorRow({
              component: (
                <List
                  disablePadding
                  dense
                  sx={{
                    listStyleType: 'disc',
                    listStylePosition: 'inside',
                  }}
                >
                  <Typography>
                    There were errors exporting invoices to Quickbooks Desktop.
                  </Typography>
                  {conductorUserFacingErrors.map((err) => (
                    // eslint-disable-next-line react/jsx-key
                    <ListItem sx={{ display: 'list-item', p: 0.5 }}>
                      {err}
                    </ListItem>
                  ))}
                </List>
              ),
            })}
          {hasMissingChargeMappingErrors &&
            QuickbooksDesktopErrorRow({
              component: (
                <List
                  disablePadding
                  dense
                  sx={{
                    listStyleType: 'disc',
                    listStylePosition: 'inside',
                  }}
                >
                  <Typography>
                    Some charges could not be mapped to charges in Quickbooks
                    Desktop. Go to Settings / Quickbooks Desktop to fix this.
                  </Typography>
                  {missingFreightChargeMappings.length > 0 && (
                    <ListItem sx={{ display: 'list-item', p: 0.5 }}>
                      Freight charge: {missingFreightChargeMappings.join(', ')}
                    </ListItem>
                  )}
                  {missingFuelChargeMappings.length > 0 && (
                    <ListItem sx={{ display: 'list-item', p: 0.5 }}>
                      Fuel charge
                    </ListItem>
                  )}
                  {missingAccessorialMappings.length > 0 && (
                    <ListItem sx={{ display: 'list-item', p: 0.5 }}>
                      Accessorial charge:{' '}
                      {missingAccessorialMappings.join(', ')}
                    </ListItem>
                  )}
                </List>
              ),
            })}
          {duplicateInvoiceErrors.length > 0 &&
            QuickbooksDesktopErrorRow({
              component: (
                <Typography>
                  The following duplicate invoices were found in Quickbooks
                  Desktop:{' '}
                  <Typography fontWeight="bold" display="inline">
                    {duplicateInvoiceErrors.join(', ')}
                  </Typography>
                </Typography>
              ),
            })}
          {missingCustomerMappings.length > 0 &&
            QuickbooksDesktopErrorRow({
              component: (
                <Stack direction="column" width="100%">
                  <Typography>
                    There{' '}
                    {missingCustomerMappings.length === 1 ? 'was' : 'were'}{' '}
                    <Typography fontWeight="bold" display="inline">
                      {missingCustomerMappings.length}{' '}
                      {pluralize('contact', missingCustomerMappings.length)}
                    </Typography>{' '}
                    that {missingCustomerMappings.length === 1 ? 'was' : 'were'}{' '}
                    not mapped to customers in Quickbooks Desktop.
                  </Typography>
                  {quickbooksCustomersLoading || contactsLoading ? (
                    <CircularProgress />
                  ) : (
                    <Stack
                      direction="column"
                      p={1}
                      border={1}
                      borderColor="lightgray"
                      width="100%"
                    >
                      {contactsWithMissingMappings?.contactsByUuids
                        .slice()
                        .sort((a, b) =>
                          a.displayName.localeCompare(b.displayName),
                        )
                        .map((contact) => (
                          <Stack
                            key={contact.uuid}
                            direction="row"
                            justifyContent="space-between"
                          >
                            <Typography fontWeight="bold">
                              {contact.displayName}
                            </Typography>
                            <QuickbooksDesktopMappingAutocomplete
                              currentMappingId={contactsToUpdate[contact.uuid]}
                              quickbooksData={quickbooksCustomers}
                              nullOption={{
                                label: `Add customer: ${contact.displayName}`,
                                value: CREATE_NEW_CONTACT_KEY,
                              }}
                              width="400px"
                              onChange={(value) => {
                                setContactsToUpdate((prevState) => ({
                                  ...prevState,
                                  [contact.uuid]: value,
                                }));
                              }}
                            />
                          </Stack>
                        ))}
                      <DialogActions
                        sx={{
                          pr: 0,
                        }}
                      >
                        <Button
                          variant="contained"
                          disabled={mapAndRetryInProgress}
                          onClick={onMapContactsAndRetry}
                        >
                          {mapAndRetryInProgress && (
                            <CircularProgress size={10} />
                          )}
                          Map{' '}
                          {pluralize('contact', missingCustomerMappings.length)}{' '}
                          and retry {uuidsToRetry.length}{' '}
                          {pluralize('invoice', uuidsToRetry.length)}
                        </Button>
                      </DialogActions>
                    </Stack>
                  )}
                </Stack>
              ),
            })}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ExportToQuickbooksDesktopModal;
