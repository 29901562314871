import {
  Alert,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { isNil, values } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import { type FunctionComponent, useState } from 'react';
import { shallow } from 'zustand/shallow';
import ButtonLink from '../../../../common/components/buttons/button-link';
import { isNilOrEmptyString } from '../../../../common/utils/utils';
import {
  AccessorialType,
  useAccessorialQuery,
  useDefaultGlobalSpecialAccessorialsQuery,
  useFlipIsArchivedMutation,
} from '../../../../generated/graphql';
import { muiStyles } from '../accessorial.styles';
import { FormMode, getAccessorialTypeFromTypename, getBackUrl } from './common';
import QuantityBasedAccessorialForm from './quantity-based-accessorial-form';
import WeightBasedAccessorialForm from './range-based-accessorial-form';
import SpecialAccessorialForm from './special-accessorial-form';
import StandardAccessorialForm from './standard-accessorial-form';
import useAccessorialEditorStore from './use-accessorial-editor-store';
import WaitTimeAccessorialForm from './wait-time-accessorial-form';
import ZoneBasedAccessorialForm from './zone-based-accessorial-form';

type AccessorialProps = {
  readonly uuid: string | null;
  readonly contactUuid: string | null;
};

const Accessorial: FunctionComponent<AccessorialProps> = ({
  uuid,
  contactUuid,
}) => {
  const [
    showAccessorialLoadErrorMessage,
    setShowAccessorialLoadErrorMessage,
    showUpdateAccessorialDateRangeSuccessMessage,
    setShowUpdateAccessorialDateRangeSuccessMessage,
    showUpdateAccessorialDateRangeErrorMessage,
    setShowUpdateAccessorialDateRangeErrorMessage,
  ] = useAccessorialEditorStore(
    (state) => [
      state.showAccessorialLoadErrorMessage,
      state.setShowAccessorialLoadErrorMessage,
      state.showUpdateAccessorialDateRangeSuccessMessage,
      state.setShowUpdateAccessorialDateRangeSuccessMessage,
      state.showUpdateAccessorialDateRangeErrorMessage,
      state.setShowUpdateAccessorialDateRangeErrorMessage,
    ],
    shallow,
  );
  const mode = isNil(uuid) ? FormMode.CREATE : FormMode.EDIT;
  const [accessorialType, setAccessorialType] = useState<AccessorialType>(
    AccessorialType.Standard,
  );
  const [isArchived, setIsArchived] = useState(false);
  const [isAuthoCodeRequired, setIsAuthoCodeRequired] = useState(false);
  const [isDefaultGlobalSpecial, setIsDefaultGlobalSpecial] = useState(false);
  const [flipIsArchived] = useFlipIsArchivedMutation();
  const confirm = useConfirm();

  const { data: accessorialData } = useAccessorialQuery({
    ...(isNil(uuid) ? { skip: true } : { variables: { uuid } }),
    onCompleted: ({ accessorial }) => {
      setIsArchived(accessorial.isArchived ?? false);
      setIsAuthoCodeRequired(accessorial.isAuthoCodeRequired);
      setIsDefaultGlobalSpecial(accessorial.isDefaultGlobalSpecial);
      setAccessorialType(
        getAccessorialTypeFromTypename(accessorial.__typename),
      );
    },
  });
  const { data: defaultGlobalSpecialsData } =
    useDefaultGlobalSpecialAccessorialsQuery({
      fetchPolicy: 'cache-and-network',
    });
  const existingGlobalSpecials =
    defaultGlobalSpecialsData?.defaultGlobalSpecialAccessorials ?? [];
  const currentSpecialIsGlobal =
    !isNilOrEmptyString(uuid) &&
    existingGlobalSpecials.some(
      (special) =>
        special.__typename === 'SpecialAccessorialEntity' &&
        special?.uuid === uuid,
    );
  const defaultGlobalCheckboxDisabled =
    existingGlobalSpecials.length > 0 && !currentSpecialIsGlobal;
  const defaultGlobalCheckboxDisabledMessage = defaultGlobalCheckboxDisabled
    ? `Un-assign ${existingGlobalSpecials
        .filter((s) => s.__typename === 'SpecialAccessorialEntity')
        .map((s) => s.name)
        .join(',')} to set this accessorial as the default special`
    : '';

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      overflow="auto"
      height="100%"
    >
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showAccessorialLoadErrorMessage}
        onClose={() => {
          setShowAccessorialLoadErrorMessage(false);
        }}
      >
        <Alert severity="error">Failed to load accessorial values</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showUpdateAccessorialDateRangeSuccessMessage}
        onClose={(_, reason) => {
          if (reason === 'clickaway') {
            return;
          }
          setShowUpdateAccessorialDateRangeSuccessMessage(false);
        }}
      >
        <Alert severity="success">Successfully saved accessorial values</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showUpdateAccessorialDateRangeErrorMessage}
        onClose={(_, reason) => {
          if (reason === 'clickaway') {
            return;
          }
          setShowUpdateAccessorialDateRangeErrorMessage(false);
        }}
      >
        <Alert severity="error">Failed to save accessorial values</Alert>
      </Snackbar>
      <Grid item xs={12} justifyContent="center" sx={{ m: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <ButtonLink
              href={getBackUrl(contactUuid)}
              variant="outlined"
              color="primary"
            >
              Back
            </ButtonLink>
          </Grid>
          <Grid item xs={4}>
            <Grid item xs={12} sx={muiStyles.centeredRow}>
              <Typography sx={{ color: 'black' }} variant="h6">{`${
                mode === FormMode.CREATE ? 'Add' : 'Edit'
              }${
                isNil(contactUuid) ? '' : ' Contact'
              } Accessorial`}</Typography>
            </Grid>
            {!isNil(accessorialData?.accessorial.contact) && (
              <Grid item xs={12} sx={muiStyles.centeredRow}>
                <Typography sx={{ textAlign: 'center' }}>
                  {accessorialData.accessorial.contact.displayName}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {mode === FormMode.CREATE && isNil(contactUuid) && (
        <>
          <Grid item xs={4} />
          <Grid item xs={4} sx={muiStyles.centeredRow}>
            <Select
              value={accessorialType}
              onChange={async (e) => {
                const newAccessorialType = e.target.value as AccessorialType;
                setAccessorialType(newAccessorialType);
                if (newAccessorialType !== AccessorialType.Special) {
                  setIsDefaultGlobalSpecial(false);
                }
              }}
            >
              {values(AccessorialType).map((at) => {
                return (
                  <MenuItem key={at} value={at}>
                    {at}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>{' '}
          <Grid item xs={4}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAuthoCodeRequired}
                    onChange={() => {
                      setIsAuthoCodeRequired(!isAuthoCodeRequired);
                    }}
                  />
                }
                label="Autho required"
              />
            </FormControl>
            {accessorialType === AccessorialType.Special && (
              <Tooltip
                placement="top"
                title={defaultGlobalCheckboxDisabledMessage}
              >
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isDefaultGlobalSpecial}
                        disabled={defaultGlobalCheckboxDisabled || isArchived}
                        onChange={async () => {
                          setIsDefaultGlobalSpecial(!isDefaultGlobalSpecial);
                        }}
                      />
                    }
                    label="Default global special"
                  />
                </FormControl>
              </Tooltip>
            )}
          </Grid>
        </>
      )}
      {!isNil(uuid) && (
        <Grid item xs={12} sx={muiStyles.centeredRow}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '5px',
              alignItems: 'center',
            }}
          >
            <FormControl>
              <FormControlLabel
                label="Archived"
                control={
                  <Checkbox
                    checked={isArchived}
                    onChange={async () => {
                      // If not already archived, confirm before archiving
                      if (isArchived) {
                        setIsArchived(false);
                        flipIsArchived({ variables: { uuid } });
                      } else {
                        await confirm({
                          title:
                            'Are you sure you want to archive this accessorial?',
                          content: (
                            <Stack sx={{ pt: 1 }} spacing={2}>
                              <Typography>
                                This accessorial will still appear in reports
                                and and be applied to orders with finalized
                                charges.
                                {isDefaultGlobalSpecial
                                  ? ' There will also no longer be a set default global special.'
                                  : ''}
                              </Typography>
                              {isDefaultGlobalSpecial && (
                                <Typography>
                                  To continue applying a default global special
                                  to stops, choose a new special accessorial to
                                  set as the global special.
                                </Typography>
                              )}
                            </Stack>
                          ),
                          cancellationText: 'No, cancel',
                          confirmationText: 'Yes, archive',
                        }).then(async () => {
                          setIsArchived(true);
                          await flipIsArchived({ variables: { uuid } });
                          setIsDefaultGlobalSpecial(false);
                        });
                      }
                    }}
                  />
                }
              />
            </FormControl>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAuthoCodeRequired}
                    onChange={() => {
                      setIsAuthoCodeRequired(!isAuthoCodeRequired);
                    }}
                  />
                }
                label="Autho required"
              />
            </FormControl>
            {isNilOrEmptyString(contactUuid) &&
              accessorialType === AccessorialType.Special && (
                <Tooltip
                  placement="top"
                  title={defaultGlobalCheckboxDisabledMessage}
                >
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isDefaultGlobalSpecial}
                          disabled={defaultGlobalCheckboxDisabled || isArchived}
                          onChange={async () => {
                            setIsDefaultGlobalSpecial(!isDefaultGlobalSpecial);
                          }}
                        />
                      }
                      label="Default global special"
                    />
                  </FormControl>
                </Tooltip>
              )}
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        {accessorialType === AccessorialType.Standard && (
          <StandardAccessorialForm
            mode={mode}
            uuid={uuid ?? undefined}
            contactUuid={contactUuid ?? undefined}
            isAuthoCodeRequired={isAuthoCodeRequired}
          />
        )}
        {(accessorialType === AccessorialType.Unit ||
          accessorialType === AccessorialType.Skid) && (
          <QuantityBasedAccessorialForm
            mode={mode}
            accessorialType={accessorialType}
            uuid={uuid ?? undefined}
            contactUuid={contactUuid ?? undefined}
            isAuthoCodeRequired={isAuthoCodeRequired}
          />
        )}
        {accessorialType === AccessorialType.Weight && (
          <WeightBasedAccessorialForm
            mode={mode}
            accessorialType={accessorialType}
            uuid={uuid ?? undefined}
            contactUuid={contactUuid ?? undefined}
            isAuthoCodeRequired={isAuthoCodeRequired}
          />
        )}
        {accessorialType === AccessorialType.ZoneBased && (
          <ZoneBasedAccessorialForm
            mode={mode}
            uuid={uuid ?? undefined}
            contactUuid={contactUuid ?? undefined}
            isAuthoCodeRequired={isAuthoCodeRequired}
          />
        )}
        {accessorialType === AccessorialType.WaitTime && (
          <WaitTimeAccessorialForm
            mode={mode}
            uuid={uuid ?? undefined}
            contactUuid={contactUuid ?? undefined}
            isAuthoCodeRequired={isAuthoCodeRequired}
          />
        )}
        {accessorialType === AccessorialType.Special && (
          <SpecialAccessorialForm
            mode={mode}
            uuid={uuid ?? undefined}
            contactUuid={contactUuid ?? undefined}
            isAuthoCodeRequired={isAuthoCodeRequired}
            isDefaultGlobalSpecial={isDefaultGlobalSpecial}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Accessorial;
