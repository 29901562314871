import { createContext, useContext } from 'react';
import { type UserPermissionWithAccessLevel } from '../utils/roles';

export type UserPermissionsContext = UserPermissionWithAccessLevel[] | null;

const context = createContext<UserPermissionsContext>(null);

export const useUserPermissionsContext = (): UserPermissionsContext =>
  useContext(context);

export const UserPermissionsProvider = context.Provider;
