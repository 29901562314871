import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import {
  Box,
  Checkbox,
  FormControlLabel,
  styled,
  Typography,
} from '@mui/material';
import MuiAccordion, { type AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  type AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { type Dispatch, type SetStateAction } from 'react';

const SummaryTypography = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
`;
const DetailsContainer = styled(Box)<{ wrapContent: boolean }>`
  padding-left: 28px;
  display: flex;
  align-items: center;
  ${({ wrapContent }) =>
    wrapContent &&
    `
    flex-grow: 1;
    flex-basis: 50%;`}
`;
const DetailsTypography = styled(Typography)`
  font-size: 14px;
  color: #5d6578;
  font-weight: medium;
  white-space: nowrap;
`;

type AccordionField<T> = {
  value: T;
  label: string;
  isSelected: boolean;
};

export type AccordionState<T> = Array<{
  name: string;
  fields: Array<AccordionField<T>>;
  isOpen: boolean;
}>;

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters square elevation={0} {...props} />
))(() => ({
  border: 'none',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<KeyboardArrowRightRoundedIcon style={{ fontSize: '16px' }} />}
    sx={{
      color: '#0000008A',
      minHeight: '32px',
    }}
    {...props}
  />
))(() => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)<{ wrapContent: boolean }>`
  padding: 0;
  ${({ wrapContent }) =>
    wrapContent
      ? `
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;`
      : ''}
`;

type AccordionDropdownProps<T extends string> = {
  readonly accordionState: AccordionState<T>;
  readonly setAccordionState: Dispatch<SetStateAction<AccordionState<T>>>;
  readonly toggleSelection: (field: T) => void;
  /** Instead of a single column, show the details in rows that wrap into multiple columns */
  readonly wrapContent?: boolean;
};

const AccordionDropdown = <T extends string>({
  accordionState,
  setAccordionState,
  toggleSelection,
  wrapContent = false,
}: AccordionDropdownProps<T>) => {
  const handleAccordionChange = (groupName: string) => {
    setAccordionState(
      accordionState.map((group) => {
        if (group.name === groupName) {
          return {
            ...group,
            isOpen: !group.isOpen,
          };
        }
        return group;
      }),
    );
  };

  return (
    <Box>
      {accordionState.map(({ name, fields, isOpen }) => {
        return (
          <Accordion
            key={name}
            expanded={isOpen}
            sx={{ background: '#EDEFF2' }}
            onChange={() => {
              handleAccordionChange(name);
            }}
          >
            <AccordionSummary sx={{ marginLeft: '4px' }}>
              <SummaryTypography>{name}</SummaryTypography>
            </AccordionSummary>
            <AccordionDetails wrapContent={wrapContent}>
              {fields.map(({ value, label, isSelected }) => (
                <DetailsContainer key={value} wrapContent={wrapContent}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isSelected}
                        onClick={() => {
                          toggleSelection(value);
                        }}
                      />
                    }
                    label={<DetailsTypography>{label}</DetailsTypography>}
                  />
                </DetailsContainer>
              ))}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
};

export default AccordionDropdown;
