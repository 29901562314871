import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Button,
  Fade,
  Snackbar,
  Stack,
  type SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useMemo, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import {
  PermissionResource,
  useWarehouseEmployeesQuery,
  type WarehouseEmployeeFragment,
} from '../../../../generated/graphql';
import CreateWarehouseEmployeeModal from './create-warehouse-employee-modal';
import UpdateWarehouseEmployeeModal from './update-warehouse-employee-modal';

const styles = {
  stackView: {
    display: 'flex',
  } as SxProps,
  warehouseEmployeeButton: {
    mx: 1.5,
  } as SxProps,
};

const WarehouseEmployeesTable = () => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteWarehouseEmployees } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyWarehouseEmployees,
  );

  const { data: warehouseEmployeesData } = useWarehouseEmployeesQuery();
  const [
    createWarehouseEmployeeModalOpen,
    setCreateWarehouseEmployeeModalOpen,
  ] = useState(false);
  const [
    updateWarehouseEmployeeModalOpen,
    setUpdateWarehouseEmployeeModalOpen,
  ] = useState(false);
  const [hoveredWarehouseEmployeeUuid, setHoveredWarehouseEmployeeUuid] =
    useState<string>();
  const [selectedWarehouseEmployee, setSelectedWarehouseEmployee] = useState<
    WarehouseEmployeeFragment | undefined
  >();

  const [successSnackbarVisible, setSuccessSnackbarVisible] = useState(false);
  const [errorSnackbarVisible, setErrorSnackbarVisible] = useState(false);

  const formatWarehouseEmployeeName = (
    warehouseEmployee: WarehouseEmployeeFragment,
  ): string => {
    return `${warehouseEmployee.firstName} ${warehouseEmployee.lastName}`;
  };

  const sortedWarehouseEmployees = useMemo(() => {
    return warehouseEmployeesData?.warehouseEmployees?.warehouseEmployees
      ?.slice()
      .sort((a, b) => {
        const aName = formatWarehouseEmployeeName(a);
        const bName = formatWarehouseEmployeeName(b);
        return aName.localeCompare(bName);
      });
  }, [warehouseEmployeesData]);

  return (
    <Stack direction="column" alignItems="flex-end" sx={styles.stackView}>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={successSnackbarVisible}
        onClose={() => {
          setSuccessSnackbarVisible(false);
        }}
      >
        <Alert severity="success">Saved warehouse employee</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={errorSnackbarVisible}
        onClose={() => {
          setErrorSnackbarVisible(false);
        }}
      >
        <Alert severity="error">Error saving warehouse employee</Alert>
      </Snackbar>
      <CreateWarehouseEmployeeModal
        open={createWarehouseEmployeeModalOpen}
        setOpen={setCreateWarehouseEmployeeModalOpen}
        setSuccessSnackbarVisible={setSuccessSnackbarVisible}
        setErrorSnackbarVisible={setErrorSnackbarVisible}
      />
      <UpdateWarehouseEmployeeModal
        open={updateWarehouseEmployeeModalOpen}
        setOpen={setUpdateWarehouseEmployeeModalOpen}
        setSuccessSnackbarVisible={setSuccessSnackbarVisible}
        setErrorSnackbarVisible={setErrorSnackbarVisible}
        selectedWarehouseEmployee={selectedWarehouseEmployee}
      />
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Active</TableCell>
              <TableCell align="right">
                <Button
                  variant="contained"
                  disabled={!canWriteWarehouseEmployees}
                  onClick={() => {
                    setCreateWarehouseEmployeeModalOpen(true);
                  }}
                >
                  Add Warehouse Employee
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedWarehouseEmployees?.map(
              (warehouseEmployee: WarehouseEmployeeFragment) => (
                <TableRow
                  key={warehouseEmployee.uuid}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  onMouseEnter={() => {
                    setHoveredWarehouseEmployeeUuid(warehouseEmployee.uuid);
                  }}
                  onMouseLeave={() => {
                    setHoveredWarehouseEmployeeUuid(undefined);
                  }}
                >
                  <TableCell component="th" scope="row">
                    {formatWarehouseEmployeeName(warehouseEmployee)}
                  </TableCell>
                  <TableCell>{warehouseEmployee.phoneNumber}</TableCell>
                  <TableCell>
                    {isEmpty(warehouseEmployee.emailAddress)
                      ? '-'
                      : warehouseEmployee.emailAddress}
                  </TableCell>
                  <TableCell>
                    {warehouseEmployee.active ? (
                      <CheckIcon color="success" />
                    ) : (
                      <CloseIcon color="error" />
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <Fade
                      in={
                        hoveredWarehouseEmployeeUuid === warehouseEmployee.uuid
                      }
                    >
                      <Button
                        variant="contained"
                        disabled={!canWriteWarehouseEmployees}
                        onClick={() => {
                          setSelectedWarehouseEmployee(warehouseEmployee);
                          setUpdateWarehouseEmployeeModalOpen(true);
                        }}
                      >
                        Edit
                      </Button>
                    </Fade>
                  </TableCell>
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default WarehouseEmployeesTable;
