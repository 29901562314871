import { Print } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  // eslint-disable-next-line no-restricted-imports
  ButtonGroup,
  Card,
  CircularProgress,
  Divider,
  Fade,
  FormHelperText,
  // eslint-disable-next-line
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { isEmpty, isNil } from 'lodash';
import { type FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Controller, type SubmitHandler } from 'react-hook-form';
import { type DateObject } from 'react-multi-date-picker';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ContactAutocompleteComponent from '../../../../common/components/contact-autocomplete-component';
import useWarehouses from '../../../../common/react-hooks/use-warehouses';
import {
  type DocumentType,
  StorageOrderDocument,
  type StorageOrderStorageUnitFragment,
  StorageUnitsPickedFilterType,
  useContactItemsLazyQuery,
  useCreateStorageOrderDocumentMutation,
  useDeleteDocumentMutation,
  useDeleteStorageOrderMutation,
  useGenerateStorageOrderPreSignedPutUrlMutation,
  useStorageOrderQuery,
  useUpdateStorageOrderMutation,
} from '../../../../generated/graphql';
import StorageOrderAuditLogList from '../../../orders/components/standard/components/version-history/components/storage-order-audit-log-list';
import useEditStorageOrderForm, {
  type EditStorageOrderFormValues,
} from '../../forms/storage-orders/use-edit-storage-order-form';
import ConfirmDeleteStorageUnitModal from '../common/modals/confirm-delete-storage-unit-modal';
import StorageUnitLabelsModal from '../common/modals/storage-unit-labels-modal';
import StorageOrderDocumentsViewer from '../common/storage-order-documents-viewer';
import StorageUnitCreateRow from '../common/storage-unit/storage-unit-create-row';
import StorageUnitDetails from '../common/storage-unit/storage-unit-details';
import StorageUnitRow from '../common/storage-unit/storage-unit-row';
import StyledDatePicker from '../common/styled-date-picker';
import WarehouseSelector from '../common/warehouse-selector';

const OPTIONAL_STORAGE_ORDER_FIELDS: Array<
  [keyof EditStorageOrderFormValues, string]
> = [
  ['purchaseOrderNumber', 'Purchase order number'],
  ['carrier', 'Carrier'],
  ['trackingNumber', 'Tracking number'],
  ['supplierCompanyName', 'Supplier company'],
  ['lotNumber', 'Lot number'],
  ['consigneeName', 'Consignee'],
];

const StorageUnitTable = styled(Table)`
  min-height: 50px;
  max-height: 50px;
  overflow-y: scroll;
`;

const StorageOrderSectionCard = styled(Card)`
  padding: 12px;
`;

const StorageOrderFormFieldContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const useStyles = () => ({
  buttonRowContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 1,
    margin: '8px 12px 0 12px',
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'end',
    gap: 1,
  },
  centeredRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

type EditStorageOrderFormProps = {
  readonly uuid: string;
};

const EditStorageOrderForm: FunctionComponent<EditStorageOrderFormProps> = ({
  uuid,
}) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useEditStorageOrderForm();

  const [
    updateStorageOrderSuccessMessageVisible,
    setUpdateStorageOrderSuccessMessageVisible,
  ] = useState(false);

  const [
    updateStorageOrderErrorMessageVisible,
    setUpdateStorageOrderErrorMessageVisible,
  ] = useState(false);

  const [
    deleteStorageOrderSuccessMessageVisible,
    setDeleteStorageOrderSuccessMessageVisible,
  ] = useState(false);

  const [
    deleteStorageOrderErrorMessageVisible,
    setDeleteStorageOrderErrorMessageVisible,
  ] = useState(false);

  const [
    createStorageOrderDocumentSuccessMessageVisible,
    setCreateStorageOrderDocumentSuccessMessageVisible,
  ] = useState(false);

  const [
    createStorageOrderDocumentErrorMessageVisible,
    setCreateStorageOrderDocumentErrorMessageVisible,
  ] = useState(false);

  const [
    deleteStorageOrderDocumentSuccessMessageVisible,
    setDeleteStorageOrderDocumentSuccessMessageVisible,
  ] = useState(false);

  const [
    deleteStorageOrderDocumentErrorMessageVisible,
    setDeleteStorageOrderDocumentErrorMessageVisible,
  ] = useState(false);

  const [
    createStorageUnitSuccessMessageVisible,
    setCreateStorageUnitSuccessMessageVisible,
  ] = useState(false);

  const [
    createStorageUnitErrorMessageVisible,
    setCreateStorageUnitErrorMessageVisible,
  ] = useState(false);

  const [
    updateStorageUnitSuccessMessageVisible,
    setUpdateStorageUnitSuccessMessageVisible,
  ] = useState(false);

  const [
    updateStorageUnitErrorMessageVisible,
    setUpdateStorageUnitErrorMessageVisible,
  ] = useState(false);

  const [
    deleteStorageUnitSuccessMessageVisible,
    setDeleteStorageUnitSuccessMessageVisible,
  ] = useState(false);

  const [
    deleteStorageUnitErrorMessageVisible,
    setDeleteStorageUnitErrorMessageVisible,
  ] = useState(false);

  const [
    createInventoryItemsSuccessMessageVisible,
    setCreateInventoryItemsSuccessMessageVisible,
  ] = useState(false);

  const [
    createInventoryItemsErrorMessageVisible,
    setCreateInventoryItemsErrorMessageVisible,
  ] = useState(false);

  const [
    updateInventoryItemsSuccessMessageVisible,
    setUpdateInventoryItemsSuccessMessageVisible,
  ] = useState(false);

  const [
    updateInventoryItemsErrorMessageVisible,
    setUpdateInventoryItemsErrorMessageVisible,
  ] = useState(false);

  const [
    deleteInventoryItemsSuccessMessageVisible,
    setDeleteInventoryItemsSuccessMessageVisible,
  ] = useState(false);

  const [
    deleteInventoryItemsErrorMessageVisible,
    setDeleteInventoryItemsErrorMessageVisible,
  ] = useState(false);

  const [storageUnitLabelsModalOpen, setStorageUnitLabelsModalOpen] =
    useState(false);
  const [selectedStorageUnitsForLabels, setSelectedStorageUnitsForLabels] =
    useState<StorageOrderStorageUnitFragment[]>([]);

  const [deleteStorageUnitModalOpen, setDeleteStorageUnitModalOpen] =
    useState(false);
  const [storageUnitToDeleteUuid, setStorageUnitToDeleteUuid] = useState<
    string | undefined
  >();

  const [isCreatingStorageUnit, setIsCreatingStorageUnit] = useState(false);

  const [containerQuantityFilterType, setContainerQuantityFilterType] =
    useState(StorageUnitsPickedFilterType.Available);
  const [
    getContactItems,
    { data: contactItemsData, loading: contactItemsLoading },
  ] = useContactItemsLazyQuery();
  const {
    data: storageOrderData,
    loading: storageOrderLoading,
    previousData: previousStorageOrderData,
  } = useStorageOrderQuery({
    variables: {
      findStorageOrderInput: { uuid },
      storageUnitsInput: {
        pickedFilter: containerQuantityFilterType,
      },
    },
    fetchPolicy: 'cache-first',
  });
  const { warehouses } = useWarehouses();
  const [updateStorageOrder, { loading: updateStorageOrderLoading }] =
    useUpdateStorageOrderMutation();
  const [deleteStorageOrder, { loading: deleteStorageOrderLoading }] =
    useDeleteStorageOrderMutation();
  const [createStorageOrderDocument] = useCreateStorageOrderDocumentMutation({
    refetchQueries: [StorageOrderDocument],
  });
  const [generateStorageOrderPreSignedPutUrl] =
    useGenerateStorageOrderPreSignedPutUrlMutation();
  const [deleteDocumentMutation] = useDeleteDocumentMutation({
    refetchQueries: [StorageOrderDocument],
  });

  // storageOrderData changes to undefined when variables change and the new data is loading, so fall back to previousData until the new data is available https://github.com/apollographql/apollo-client/issues/6866#issuecomment-700295108
  const storageOrder =
    storageOrderData?.storageOrder.storageOrder ??
    previousStorageOrderData?.storageOrder.storageOrder;

  const storageUnits = useMemo(() => {
    return storageOrder?.storageUnits ?? [];
  }, [storageOrder]);

  const [selectedStorageUnitUuid, setSelectedStorageUnitUuid] = useState<
    string | null
  >(storageUnits[0]?.uuid ?? null);

  // if no storage unit is selected, always select the first one. It should never be null if there is at least one storage unit
  useEffect(() => {
    if (isNil(selectedStorageUnitUuid)) {
      setSelectedStorageUnitUuid(storageUnits[0]?.uuid ?? null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageUnits]);

  const handleChangeContainerQuantityFilterType = (
    newContainerQuantityFilterType: StorageUnitsPickedFilterType,
  ) => {
    if (newContainerQuantityFilterType === containerQuantityFilterType) {
      return;
    }
    setContainerQuantityFilterType(newContainerQuantityFilterType);
    setSelectedStorageUnitUuid(null);
  };

  const selectedStorageUnit = useMemo(() => {
    return (
      storageUnits.find(
        (storageUnit) => storageUnit.uuid === selectedStorageUnitUuid,
      ) ?? null
    );
  }, [selectedStorageUnitUuid, storageUnits]);

  useEffect(() => {
    if (!isNil(storageOrder)) {
      const {
        referenceNumber,
        purchaseOrderNumber,
        warehouse,
        carrier,
        trackingNumber,
        supplierCompanyName,
        lotNumber,
        consigneeName,
        contact,
        notes,
        pickupDate,
        expectedAt,
        arrivedAt,
      } = storageOrder;
      reset({
        contactUuid: contact.uuid,
        referenceNumber,
        warehouse: {
          label: warehouse.name,
          value: warehouse.uuid,
        },
        purchaseOrderNumber,
        carrier,
        trackingNumber,
        supplierCompanyName,
        lotNumber,
        consigneeName,
        notes,
        pickupDate,
        expectedAt,
        arrivedAt,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageOrderData]);

  useEffect(() => {
    const contactUuid = storageOrder?.contact?.uuid;
    if (!isNil(contactUuid) && !isEmpty(contactUuid)) {
      getContactItems({
        variables: {
          findContactItemsInput: {
            contactUuid,
            warehouseUuid: storageOrder?.warehouse.uuid,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageOrderData]);

  const storageOrderUuid = useMemo(() => {
    return storageOrder?.uuid;
  }, [storageOrder]);

  const deleteDocument = async (documentId: string) => {
    try {
      await deleteDocumentMutation({
        variables: { uuid: documentId },
      });
      setDeleteStorageOrderDocumentSuccessMessageVisible(true);
    } catch {
      setDeleteStorageOrderDocumentErrorMessageVisible(true);
    }
  };

  const updateStorageOrderWithDocument = async (
    file: File,
    fileName: string,
    documentType: DocumentType,
    name: string | null,
    fileType: string,
  ) => {
    try {
      await createStorageOrderDocument({
        variables: {
          createStorageOrderDocumentInput: {
            documentType,
            fileName,
            fileType,
            name,
            storageOrderUuid: uuid,
          },
        },
      });
      setCreateStorageOrderDocumentSuccessMessageVisible(true);
    } catch {
      setCreateStorageOrderDocumentErrorMessageVisible(true);
    }
  };

  const getStorageOrderAwsUrl = async (fileName: string, fileType: string) => {
    const response = await generateStorageOrderPreSignedPutUrl({
      variables: {
        generateStorageOrderPreSignedPutUrlInput: {
          fileName,
          fileType,
          storageOrderUuid: uuid,
        },
      },
    });
    return response?.data?.generateStorageOrderPreSignedPutUrl?.preSignedPutUrl;
  };

  const goToStorageOrdersPage = () => {
    navigate('/warehouse/storage-orders');
  };

  const handleDelete = async () => {
    try {
      if (isNil(uuid) || typeof uuid !== 'string') {
        setDeleteStorageOrderErrorMessageVisible(true);
        return;
      }
      const response = await deleteStorageOrder({
        variables: {
          deleteStorageOrderInput: {
            uuid,
          },
        },
      });
      const success = response?.data?.deleteStorageOrder?.success;
      if (success === true) {
        setDeleteStorageOrderSuccessMessageVisible(true);
        goToStorageOrdersPage();
      } else {
        setDeleteStorageOrderErrorMessageVisible(true);
      }
    } catch {
      setDeleteStorageOrderErrorMessageVisible(true);
    }
  };

  const onSubmit: SubmitHandler<EditStorageOrderFormValues> = async (
    data: EditStorageOrderFormValues,
  ) => {
    const {
      contactUuid,
      referenceNumber,
      purchaseOrderNumber,
      carrier,
      trackingNumber,
      supplierCompanyName,
      lotNumber,
      consigneeName,
      notes,
      pickupDate,
      expectedAt,
      arrivedAt,
    } = data;
    try {
      await updateStorageOrder({
        variables: {
          updateStorageOrderInput: {
            uuid,
            contactUuid:
              contactUuid ===
              storageOrderData?.storageOrder.storageOrder.contact?.uuid
                ? undefined
                : contactUuid,
            referenceNumber,
            purchaseOrderNumber,
            carrier,
            trackingNumber,
            supplierCompanyName,
            lotNumber,
            consigneeName,
            notes,
            pickupDate,
            expectedAt,
            arrivedAt,
          },
        },
      });
      setUpdateStorageOrderSuccessMessageVisible(true);
      goToStorageOrdersPage();
    } catch {
      setUpdateStorageOrderErrorMessageVisible(true);
    }
  };

  if (
    (storageOrderLoading && storageOrder == null) ||
    storageOrder == null ||
    (contactItemsLoading && contactItemsData == null) ||
    contactItemsData == null
  ) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <Grid
      container
      height="100%"
      spacing={2}
      sx={{ backgroundColor: 'white', overflow: 'auto' }}
    >
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={updateStorageOrderSuccessMessageVisible}
        onClose={() => {
          setUpdateStorageOrderSuccessMessageVisible(false);
        }}
      >
        <Alert>Successfully updated receipt</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={updateStorageOrderErrorMessageVisible}
        onClose={() => {
          setUpdateStorageOrderErrorMessageVisible(false);
        }}
      >
        <Alert severity="error">Error updating receipt</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={deleteStorageOrderSuccessMessageVisible}
        onClose={() => {
          setDeleteStorageOrderSuccessMessageVisible(false);
        }}
      >
        <Alert>Successfully deleted receipt</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={deleteStorageOrderErrorMessageVisible}
        onClose={() => {
          setDeleteStorageOrderErrorMessageVisible(false);
        }}
      >
        <Alert severity="error">Error deleting receipt</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={createStorageOrderDocumentSuccessMessageVisible}
        onClose={() => {
          setCreateStorageOrderDocumentSuccessMessageVisible(false);
        }}
      >
        <Alert>Successfully created document</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={createStorageOrderDocumentErrorMessageVisible}
        onClose={() => {
          setCreateStorageOrderDocumentErrorMessageVisible(false);
        }}
      >
        <Alert severity="error">Error creating document</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={deleteStorageOrderDocumentSuccessMessageVisible}
        onClose={() => {
          setDeleteStorageOrderDocumentSuccessMessageVisible(false);
        }}
      >
        <Alert>Successfully deleted document</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={deleteStorageOrderDocumentErrorMessageVisible}
        onClose={() => {
          setDeleteStorageOrderDocumentErrorMessageVisible(false);
        }}
      >
        <Alert severity="error">Error deleting document</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={createStorageUnitSuccessMessageVisible}
        onClose={() => {
          setCreateStorageUnitSuccessMessageVisible(false);
        }}
      >
        <Alert>Successfully created container</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={createStorageUnitErrorMessageVisible}
        onClose={() => {
          setCreateStorageUnitErrorMessageVisible(false);
        }}
      >
        <Alert severity="error">Error creating container</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={updateStorageUnitSuccessMessageVisible}
        onClose={() => {
          setUpdateStorageUnitSuccessMessageVisible(false);
        }}
      >
        <Alert>Saved container</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={updateStorageUnitErrorMessageVisible}
        onClose={() => {
          setUpdateStorageUnitErrorMessageVisible(false);
        }}
      >
        <Alert severity="error">Error saving container</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={deleteStorageUnitSuccessMessageVisible}
        onClose={() => {
          setDeleteStorageUnitSuccessMessageVisible(false);
        }}
      >
        <Alert>Deleted container</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={deleteStorageUnitErrorMessageVisible}
        onClose={() => {
          setDeleteStorageUnitErrorMessageVisible(false);
        }}
      >
        <Alert severity="error">Error deleting container</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={createInventoryItemsSuccessMessageVisible}
        onClose={() => {
          setCreateInventoryItemsSuccessMessageVisible(false);
        }}
      >
        <Alert>Successfully created items</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={createInventoryItemsErrorMessageVisible}
        onClose={() => {
          setCreateInventoryItemsErrorMessageVisible(false);
        }}
      >
        <Alert severity="error">Error creating items</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={updateInventoryItemsSuccessMessageVisible}
        onClose={() => {
          setUpdateInventoryItemsSuccessMessageVisible(false);
        }}
      >
        <Alert>Successfully updated items</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={updateInventoryItemsErrorMessageVisible}
        onClose={() => {
          setUpdateInventoryItemsErrorMessageVisible(false);
        }}
      >
        <Alert severity="error">Error updating items</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={deleteInventoryItemsSuccessMessageVisible}
        onClose={() => {
          setDeleteInventoryItemsSuccessMessageVisible(false);
        }}
      >
        <Alert>Successfully deleted items</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={deleteInventoryItemsErrorMessageVisible}
        onClose={() => {
          setDeleteInventoryItemsErrorMessageVisible(false);
        }}
      >
        <Alert severity="error">Error deleting items</Alert>
      </Snackbar>
      <Grid
        item
        xs={12}
        sx={{ ...styles.buttonRowContainer, justifyContent: 'space-between' }}
      >
        <Button
          variant="outlined"
          disabled={updateStorageOrderLoading || deleteStorageOrderLoading}
          onClick={() => {
            if (globalThis.history.length > 1) {
              navigate(-1);
            } else {
              goToStorageOrdersPage();
            }
          }}
        >
          Back
        </Button>
        <Stack direction="row" gap={1}>
          <Button
            variant="contained"
            color="secondary"
            disabled={updateStorageOrderLoading || deleteStorageOrderLoading}
            onClick={handleDelete}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            disabled={updateStorageOrderLoading || deleteStorageOrderLoading}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <StorageOrderSectionCard>
          <Grid container spacing={2}>
            <StorageOrderFormFieldContainer item xs={3}>
              <Controller
                name="contactUuid"
                control={control}
                render={({ field: { onChange, value: contactUuidValue } }) => (
                  <>
                    <ContactAutocompleteComponent
                      disabled
                      billingPartyContactUuid={contactUuidValue}
                      label="Customer"
                      onChange={(value: string | undefined) => {
                        onChange(value);
                      }}
                    />
                    {!isNil(errors.contactUuid) && (
                      <FormHelperText sx={{ color: '#D32F2F' }}>
                        {errors.contactUuid?.message ?? ''}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </StorageOrderFormFieldContainer>
            {(warehouses?.length ?? 0) > 1 && (
              <StorageOrderFormFieldContainer item xs={3}>
                <Controller
                  name="warehouse"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <WarehouseSelector
                        whiteBackground
                        disabled
                        value={value}
                        error={!isNil(errors.warehouse)}
                        width="100%"
                        onChange={onChange}
                      />
                      {!isNil(errors.arrivedAt) && (
                        <FormHelperText sx={{ color: '#D32F2F' }}>
                          {errors.warehouse?.message}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </StorageOrderFormFieldContainer>
            )}
            <StorageOrderFormFieldContainer item xs={3}>
              <Controller
                name="referenceNumber"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <TextField
                      required
                      label="Reference number"
                      name="referenceNumber"
                      size="small"
                      value={value}
                      error={!isNil(errors.referenceNumber)}
                      sx={{ width: '100%' }}
                      onChange={onChange}
                    />
                    {!isNil(errors.referenceNumber) && (
                      <FormHelperText sx={{ color: '#D32F2F' }}>
                        {errors.referenceNumber?.message ?? ''}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </StorageOrderFormFieldContainer>
            <StorageOrderFormFieldContainer item xs={3}>
              <Controller
                name="arrivedAt"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <StyledDatePicker
                      required
                      title="Arrived at"
                      value={value}
                      containerStyle={{ width: '100%' }}
                      error={!isNil(errors.arrivedAt)}
                      name="Arrived at"
                      format="MM/DD/YYYY"
                      onChange={(newDate) => {
                        onChange(
                          newDate?.isValid
                            ? dayjs(newDate.toDate())
                                .startOf('day')
                                .add(12, 'hours')
                                .toISOString()
                            : null,
                        );
                      }}
                    />
                    {!isNil(errors.arrivedAt) && (
                      <FormHelperText sx={{ color: '#B00020' }}>
                        {errors.arrivedAt?.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </StorageOrderFormFieldContainer>
            {OPTIONAL_STORAGE_ORDER_FIELDS.map(([fieldName, label]) => (
              <StorageOrderFormFieldContainer key={fieldName} item xs={3}>
                <Controller
                  name={fieldName}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <TextField
                        label={label}
                        name={fieldName}
                        size="small"
                        value={value}
                        error={!isNil(errors[fieldName])}
                        sx={{ width: '100%' }}
                        onChange={onChange}
                      />
                      {!isNil(errors[fieldName]) && (
                        <FormHelperText sx={{ color: '#D32F2F' }}>
                          {errors[fieldName]?.message ?? ''}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </StorageOrderFormFieldContainer>
            ))}
          </Grid>
        </StorageOrderSectionCard>
      </Grid>
      <Grid item xs={12}>
        <StorageOrderSectionCard>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h6">Containers</Typography>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    ...styles.rowContainer,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Stack direction="row" gap={1} alignItems="center">
                    <ButtonGroup
                      disableElevation
                      variant="outlined"
                      size="small"
                      aria-label="invoice-status-filters"
                    >
                      <Button
                        variant={
                          containerQuantityFilterType ===
                          StorageUnitsPickedFilterType.Available
                            ? 'contained'
                            : 'outlined'
                        }
                        onClick={() => {
                          handleChangeContainerQuantityFilterType(
                            StorageUnitsPickedFilterType.Available,
                          );
                        }}
                      >
                        Available
                      </Button>
                      <Button
                        variant={
                          containerQuantityFilterType ===
                          StorageUnitsPickedFilterType.Picked
                            ? 'contained'
                            : 'outlined'
                        }
                        onClick={() => {
                          handleChangeContainerQuantityFilterType(
                            StorageUnitsPickedFilterType.Picked,
                          );
                        }}
                      >
                        Picked
                      </Button>
                    </ButtonGroup>
                  </Stack>
                  <Stack direction="row" gap={1} alignItems="center">
                    <Button
                      size="small"
                      aria-label="add-storage-unit"
                      disabled={isCreatingStorageUnit}
                      variant="outlined"
                      onClick={() => {
                        setIsCreatingStorageUnit(true);
                      }}
                    >
                      Add
                    </Button>
                    <Button
                      size="small"
                      aria-label="delete-storage-unit"
                      disabled={isNil(selectedStorageUnit)}
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        if (isNil(selectedStorageUnit)) {
                          return;
                        }
                        setStorageUnitToDeleteUuid(selectedStorageUnit.uuid);
                        setDeleteStorageUnitModalOpen(true);
                      }}
                    >
                      Delete
                    </Button>
                    <Tooltip arrow title="Print labels">
                      <IconButton
                        size="small"
                        disabled={
                          isCreatingStorageUnit || isEmpty(storageUnits)
                        }
                        onClick={() => {
                          if (isEmpty(storageUnits)) {
                            return;
                          }
                          setSelectedStorageUnitsForLabels(storageUnits);
                          setStorageUnitLabelsModalOpen(true);
                        }}
                      >
                        <Print />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Grid>
                {isEmpty(storageUnits) &&
                  !isCreatingStorageUnit &&
                  !storageOrderLoading && (
                    <Typography
                      variant="body2"
                      sx={{ padding: '8px', margin: 'auto' }}
                    >
                      No{' '}
                      {containerQuantityFilterType ===
                      StorageUnitsPickedFilterType.Picked
                        ? 'picked'
                        : 'available'}{' '}
                      containers to display
                    </Typography>
                  )}
                {(!isEmpty(storageUnits) || isCreatingStorageUnit) && (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      ...styles.centeredRow,
                      marginTop: '5px',
                    }}
                  >
                    <TableContainer
                      component={Paper}
                      style={{ maxHeight: '500px', overflowY: 'scroll' }}
                    >
                      <StorageUnitTable size="small">
                        <TableHead>
                          <TableCell>Name</TableCell>
                          <TableCell>Location</TableCell>
                          <TableCell />
                        </TableHead>
                        {isCreatingStorageUnit && !isNil(storageOrderUuid) && (
                          <StorageUnitCreateRow
                            storageOrderUuid={storageOrderUuid}
                            setCreateStorageUnitSuccessMessageVisible={
                              setCreateStorageUnitSuccessMessageVisible
                            }
                            setCreateStorageUnitErrorMessageVisible={
                              setCreateStorageUnitErrorMessageVisible
                            }
                            setIsCreatingStorageUnit={setIsCreatingStorageUnit}
                            onSelectStorageUnit={setSelectedStorageUnitUuid}
                          />
                        )}
                        {storageUnits?.map((storageUnit) => (
                          <StorageUnitRow
                            key={storageUnit.uuid}
                            storageOrder={storageOrder}
                            storageUnit={storageUnit}
                            setUpdateStorageUnitSuccessMessageVisible={
                              setUpdateStorageUnitSuccessMessageVisible
                            }
                            setUpdateStorageUnitErrorMessageVisible={
                              setUpdateStorageUnitErrorMessageVisible
                            }
                            setDeleteStorageUnitModalOpen={
                              setDeleteStorageUnitModalOpen
                            }
                            setStorageUnitToDeleteUuid={
                              setStorageUnitToDeleteUuid
                            }
                            selectedStorageUnit={selectedStorageUnit}
                            onSelectStorageUnit={setSelectedStorageUnitUuid}
                          />
                        ))}
                      </StorageUnitTable>
                    </TableContainer>
                  </Grid>
                )}
              </Grid>
              <Divider flexItem orientation="vertical" />
            </Grid>
            {!isNil(selectedStorageUnit) && (
              <Fade in={!isNil(selectedStorageUnit)}>
                <Grid item xs={6}>
                  <Stack
                    direction="row"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography variant="h6">Container details</Typography>
                    <Tooltip title="Print label">
                      <IconButton
                        aria-label="print"
                        disabled={isNil(selectedStorageUnit)}
                        onClick={() => {
                          if (isNil(selectedStorageUnit)) {
                            return;
                          }
                          setSelectedStorageUnitsForLabels([
                            selectedStorageUnit,
                          ]);
                          setStorageUnitLabelsModalOpen(true);
                        }}
                      >
                        <Print />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                  <StorageUnitDetails
                    storageOrder={storageOrder}
                    storageUnit={selectedStorageUnit}
                    items={contactItemsData.contactItems.items}
                    setUpdateStorageUnitSuccessMessageVisible={
                      setUpdateStorageUnitSuccessMessageVisible
                    }
                    setUpdateStorageUnitErrorMessageVisible={
                      setUpdateStorageUnitErrorMessageVisible
                    }
                  />
                </Grid>
              </Fade>
            )}
          </Grid>
        </StorageOrderSectionCard>
      </Grid>
      <Grid item xs={6}>
        <StorageOrderSectionCard>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Documents</Typography>
            </Grid>
            <Grid item xs={12}>
              <Accordion
                defaultExpanded
                TransitionProps={{ unmountOnExit: true }}
                sx={{
                  width: '100%',
                  boxShadow: 'none',
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    border: 'none',
                    justifyContent: 'center',
                    minHeight: '0px',
                  }}
                >
                  <Typography>Uploaded documents</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ border: 'none' }}>
                  <StorageOrderDocumentsViewer
                    getAwsUrl={getStorageOrderAwsUrl}
                    documents={
                      storageOrderData?.storageOrder.storageOrder.documents ??
                      []
                    }
                    onUploadDocument={updateStorageOrderWithDocument}
                    onDeleteDocument={deleteDocument}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </StorageOrderSectionCard>
      </Grid>
      <Grid item xs={6}>
        <StorageOrderSectionCard>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Audit log</Typography>
            </Grid>
            <StorageOrderFormFieldContainer item xs={12}>
              <StorageOrderAuditLogList storageOrderUuid={storageOrder.uuid} />
            </StorageOrderFormFieldContainer>
          </Grid>
        </StorageOrderSectionCard>
      </Grid>
      <Grid item xs={6}>
        <StorageOrderSectionCard>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Notes</Typography>
            </Grid>
            <StorageOrderFormFieldContainer item xs={12}>
              <Controller
                name="notes"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <TextField
                      required
                      multiline
                      name="notes"
                      size="small"
                      value={value}
                      error={!isNil(errors.notes)}
                      sx={{ width: '100%' }}
                      rows={3}
                      onChange={onChange}
                    />
                    {!isNil(errors.notes) && (
                      <FormHelperText sx={{ color: '#D32F2F' }}>
                        {errors.notes?.message ?? ''}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </StorageOrderFormFieldContainer>
          </Grid>
        </StorageOrderSectionCard>
      </Grid>
      <ConfirmDeleteStorageUnitModal
        open={deleteStorageUnitModalOpen}
        storageUnitUuid={storageUnitToDeleteUuid}
        setStorageUnitToDeleteUuid={setStorageUnitToDeleteUuid}
        handleClose={() => {
          setDeleteStorageUnitModalOpen(false);
        }}
        setDeleteStorageUnitSuccessMessageVisible={
          setDeleteStorageUnitSuccessMessageVisible
        }
        setDeleteStorageUnitErrorMessageVisible={
          setDeleteStorageUnitErrorMessageVisible
        }
        onSelectStorageUnit={setSelectedStorageUnitUuid}
      />
      {!isNil(storageOrder) && (
        <StorageUnitLabelsModal
          open={storageUnitLabelsModalOpen}
          storageOrder={storageOrder}
          storageUnits={selectedStorageUnitsForLabels}
          onClose={() => {
            setStorageUnitLabelsModalOpen(false);
          }}
        />
      )}
    </Grid>
  );
};

export default EditStorageOrderForm;
