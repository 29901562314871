import {
  type OrderTableField,
  type OrderTableFieldHeaderFragment,
} from '../../../../../generated/graphql';
import { getOrderTableFieldHeader } from '../../../../orders/components/utils';
import {
  type GroupedOrderTableFields,
  SORTABLE_GROUPED_ORDER_TABLE_FIELDS,
} from '../../../../orders/constants';

/*
 Mui Autocomplete format requires a 'groupBy' field to group the options
 https://mui.com/material-ui/react-autocomplete/#grouped
*/
export type MuiGroupedOption = {
  label: string;
  value: string;
  groupBy: string;
};

/**
 * Converts grouped table fields to MUI Autocomplete options
 * @param visibleGroupedTableFields grouped, sortable table fields that are visible in the table
 * @returns group table fields in MUI autocomplete format
 */
export function convertGroupTableFieldsToMUIAutocompleteOptions(
  visibleGroupedTableFields: GroupedOrderTableFields[],
  orderTableFieldHeaders: OrderTableFieldHeaderFragment[],
): MuiGroupedOption[] {
  return visibleGroupedTableFields.flatMap((group) =>
    group.fields.map((field) => ({
      label: getOrderTableFieldHeader({
        orderTableField: field,
        orderTableFieldHeaders,
      }),
      value: field,
      groupBy: group.name,
    })),
  );
}

/**
 *
 * @param visibleColumns columns that are visible in the table
 * @returns filtered grouped table fields that are sortable and visible in the table
 */
export function getVisibleSortableGroupedTableFields(
  visibleColumns: OrderTableField[],
): GroupedOrderTableFields[] {
  return SORTABLE_GROUPED_ORDER_TABLE_FIELDS.map((group) => {
    const visibleFields = group.fields.filter((field) =>
      visibleColumns.includes(field),
    );
    return {
      ...group,
      fields: visibleFields,
    };
  });
}

/**
 * @param visibleColumns columns that are visible in the table
 * @returns returns the group table fields that are visible in the table in the format required by MUI group Autocomplete
 */
export function getSortableGroupedTableFieldOptions(
  visibleColumns: OrderTableField[],
  orderTableFieldHeaders: OrderTableFieldHeaderFragment[],
): MuiGroupedOption[] {
  const visibleSortableGroupedTableFields =
    getVisibleSortableGroupedTableFields(visibleColumns);
  const options = convertGroupTableFieldsToMUIAutocompleteOptions(
    visibleSortableGroupedTableFields,
    orderTableFieldHeaders,
  );
  return options;
}
