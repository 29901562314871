import { Box, Button, Typography } from '@mui/material';
import React from 'react';

const ConfirmationMessage = ({
  numberOfFiles,
  closeModal,
}: {
  readonly numberOfFiles: number;
  readonly closeModal: () => void;
}) => {
  const fileCopy = numberOfFiles > 1 ? 'files' : 'file';
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <Typography variant="h4" sx={{ textAlign: 'center' }}>
        Upload successful
      </Typography>
      <Typography variant="body1" sx={{ textAlign: 'center', marginTop: '1%' }}>
        You successfully uploaded {numberOfFiles} {fileCopy}. Check the results
        page in 5-10 minutes for the uploaded orders.
      </Typography>
      <Button variant="contained" sx={{ marginTop: '5%' }} onClick={closeModal}>
        Confirm
      </Button>
    </Box>
  );
};

export default ConfirmationMessage;
