import { Box } from '@mui/material';
import { isNil } from 'lodash';
import React from 'react';
import { type PointLike, Popup } from 'react-map-gl';
import {
  type StopFragment,
  type StopOnRouteFragment,
} from '../../../../generated/graphql';
import RouteStopCard from '../../routes/route-stop-card';

const StopMarkerPopup = ({
  stop,
  markerSize,
}: {
  readonly stop: StopOnRouteFragment | StopFragment;
  readonly markerSize: number;
}) => {
  const POPUP_OFFSETS: Record<string, PointLike> = {
    bottom: [0, -2 * markerSize],
    'bottom-left': [0, -markerSize],
    'bottom-right': [0, -markerSize],
  };

  const { address } =
    stop.__typename === 'StandardStopEntity' ? stop : stop.stop;
  const latitude = address?.latitude;
  const longitude = address?.longitude;
  if (isNil(latitude) || isNil(longitude)) {
    return <Box />;
  }

  return (
    <Popup
      longitude={Number(longitude)}
      latitude={Number(latitude)}
      closeButton={false}
      offset={POPUP_OFFSETS}
      maxWidth="400px"
    >
      <RouteStopCard
        locked
        noBorder
        stop={stop}
        index={undefined}
        width={350}
      />
    </Popup>
  );
};

export default StopMarkerPopup;
