import {
  type AutocompleteProps,
  Stack,
  TextField,
  Typography,
  type UseAutocompleteProps,
} from '@mui/material';
import { isNil } from 'lodash';
import { useMemo } from 'react';
import useMe from '../../../../common/react-hooks/use-me';
import { getDriverName } from '../../../../common/utils/utils';
import { type RouteFragment } from '../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { type RouteOption } from '../../types/routes';

const getDriverNameForDropdown = (
  route: RouteFragment,
  useDriverNumberForDispatch: boolean | undefined,
) => {
  if (isNil(route.drivers[0])) {
    return 'No driver assigned';
  }
  if (useDriverNumberForDispatch === true) {
    return `${route.drivers[0]?.driverReferenceNumber} - ${getDriverName(route.drivers[0])}`;
  }
  return getDriverName(route.drivers[0]);
};
type RouteSelectionDropdownProps = {
  readonly routes: RouteFragment[];
  readonly onSelectRoute: (route: RouteFragment) => void;
  readonly disabled?: boolean;
} & Partial<AutocompleteProps<unknown, false, false, false>>;
const RouteSelectionDropdown = ({
  routes,
  onSelectRoute,
  ...autocompleteProps
}: RouteSelectionDropdownProps) => {
  const { companyConfiguration } = useMe();
  const dropdownOptions = useMemo(() => {
    const nonEmptyRoutes = routes?.filter((r) => !isNil(r));
    return nonEmptyRoutes.map((route) => ({
      value: route.uuid,
      label: route.name,
      driverName: getDriverNameForDropdown(
        route,
        companyConfiguration?.useDriverNumberForDispatch,
      ),
    }));
  }, [routes, companyConfiguration]);

  const handleChange: UseAutocompleteProps<
    RouteOption,
    false,
    false,
    false
  >['onChange'] = (e, newValue) => {
    const route = routes.find((r) => r.uuid === newValue?.value);
    if (route) {
      onSelectRoute(route);
    }
    e.stopPropagation();
  };

  return (
    <AutocompleteFuzzy
      fullWidth
      size="small"
      noOptionsText="No Routes"
      matchSortOptions={{ keys: ['label'] }}
      options={dropdownOptions}
      disabled={autocompleteProps.disabled}
      componentsProps={{
        popper: { style: { width: 'fit-content' } },
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.value}>
            <Stack>
              <Typography fontSize="14px">{option.label}</Typography>
              <Typography variant="caption" color="text.secondary">
                {option.driverName}
              </Typography>
            </Stack>
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          sx={{
            '.MuiInputBase-input': { fontSize: '0.8rem' },
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        />
      )}
      onChange={handleChange}
      onClose={autocompleteProps.onClose}
      onOpen={autocompleteProps.onOpen}
    />
  );
};

export default RouteSelectionDropdown;
