import { Button, ButtonGroup } from '@mui/material';
import { type Dispatch, type SetStateAction } from 'react';

export enum ActiveOrArchivedTab {
  ACTIVE = 'Active',
  ARCHIVED = 'Archived',
}

export const ActiveArchivedButtonGroup = ({
  activeTab,
  setTab,
}: {
  readonly activeTab: ActiveOrArchivedTab;
  readonly setTab: Dispatch<SetStateAction<ActiveOrArchivedTab>>;
}) => {
  return (
    <ButtonGroup
      disableElevation
      variant="outlined"
      size="small"
      aria-label="document-status-filters"
    >
      <Button
        variant={
          activeTab === ActiveOrArchivedTab.ACTIVE ? 'contained' : 'outlined'
        }
        onClick={() => {
          setTab(ActiveOrArchivedTab.ACTIVE);
        }}
      >
        {ActiveOrArchivedTab.ACTIVE}
      </Button>
      <Button
        variant={
          activeTab === ActiveOrArchivedTab.ARCHIVED ? 'contained' : 'outlined'
        }
        onClick={() => {
          setTab(ActiveOrArchivedTab.ARCHIVED);
        }}
      >
        {ActiveOrArchivedTab.ARCHIVED}
      </Button>
    </ButtonGroup>
  );
};
