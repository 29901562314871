import { MenuItem, MenuList } from '@mui/material';
import { type CSSProperties, type Dispatch, type SetStateAction } from 'react';
import type React from 'react';
import { useEffect, useRef } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import {
  type AppointmentFragment,
  GetAppointmentsDocument,
  PermissionResource,
  useBulkConfirmAppointmentsMutation,
} from '../../../../generated/graphql';

const AppointmentContextMenu = ({
  menuStyling,
  setShowContextMenu,
  buttonRef,
  setShowRemoveFromRoutesConfirmation,
  selectedStops,
}: {
  readonly menuStyling: CSSProperties;
  readonly setShowContextMenu: Dispatch<SetStateAction<boolean>>;
  readonly buttonRef: React.RefObject<HTMLButtonElement>;
  readonly setShowRemoveFromRoutesConfirmation: Dispatch<
    SetStateAction<boolean>
  >;
  readonly selectedStops: AppointmentFragment[];
}) => {
  const { userPermissions } = useUserRoles();

  const { canWrite: canWriteAppointments } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Appointments,
  );

  const ref = useRef<HTMLUListElement>(null);

  const [bulkUpdateConfirmAppts] = useBulkConfirmAppointmentsMutation({
    refetchQueries: [GetAppointmentsDocument],
  });
  const onBulkConfirm = async () => {
    await bulkUpdateConfirmAppts({
      variables: {
        bulkConfirmAppointmentsArgs: {
          uuids: selectedStops.map((stop) => stop.uuid),
        },
      },
    });
    setShowContextMenu(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        ref.current !== null &&
        !ref.current.contains(event.target as Node) &&
        buttonRef.current?.contains(event.target as Node) === false
      ) {
        setShowContextMenu(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [setShowContextMenu, buttonRef]);

  return (
    <MenuList ref={ref} sx={menuStyling}>
      <MenuItem
        disabled={!canWriteAppointments}
        onClick={() => {
          setShowRemoveFromRoutesConfirmation(true);
        }}
      >
        Remove appointments from route
      </MenuItem>
      <MenuItem
        disabled={!canWriteAppointments}
        onClick={async () => onBulkConfirm()}
      >
        Mark {selectedStops.length > 0 ? selectedStops.length : ''} stops as
        confirmed
      </MenuItem>
    </MenuList>
  );
};

export default AppointmentContextMenu;
