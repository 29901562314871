import { Box, Button, Dialog, type SxProps, Typography } from '@mui/material';
import { type Dispatch, type SetStateAction } from 'react';
import { type ArchiveableEntity } from './archive-action-component';

const styles = {
  modalInnerContainer: {
    bgcolor: 'background.paper',
    boxShadow: 24,
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
    gap: '20px',
    p: 4,
  } as SxProps,
};

const ArchiveEntityModal = ({
  entityType,
  entityIdentifier,
  open,
  setOpen,
  isCurrentlyArchived,
  handleArchive,
  handleUnarchive,
}: {
  readonly entityType: ArchiveableEntity;
  readonly entityIdentifier: string;
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
  readonly isCurrentlyArchived: boolean;
  readonly handleArchive: () => void;
  readonly handleUnarchive: () => void;
}) => {
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={() => setOpen(false)}>
      <Box sx={styles.modalInnerContainer}>
        <Typography variant="h6">
          {isCurrentlyArchived ? 'Unarchive' : 'Archive'} {entityType}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: 1,
            width: '100%',
          }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>
            {entityIdentifier}
          </Typography>
          {isCurrentlyArchived ? (
            <Typography>Make this {entityType} active again.</Typography>
          ) : (
            <Typography>
              This {entityType} will still show up in reports, but will no
              longer be seen in views like dispatch, orders, etc. You can always
              un-archive by coming back to this page.
            </Typography>
          )}
        </Box>
        <Box>
          <Button
            sx={{ float: 'right' }}
            color="error"
            onClick={async () => {
              if (isCurrentlyArchived) {
                await handleUnarchive();
                setOpen(false);
              } else {
                await handleArchive();
                setOpen(false);
              }
            }}
          >
            {isCurrentlyArchived ? 'Unarchive' : 'Archive'}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ArchiveEntityModal;
