import { Alert, Snackbar } from '@mui/material';
import { shallow } from 'zustand/shallow';
import useGlobalStore from '../../../layouts/dashboard/global-store';

/**
 * A global error alert that can be used across use-cases. The mutator methods
 * for the alert (displaying the alert and the message in the alert can be accessed via
 * the global zustand store.
 *
 */
const GlobalErrorSnackbar = () => {
  const [errorMessage, showErrorMessage, setShowErrorMessage] = useGlobalStore(
    (state) => [
      state.errorMessage,
      state.showErrorMessage,
      state.setShowErrorMessage,
    ],
    shallow,
  );
  return (
    <Snackbar
      autoHideDuration={3000}
      open={showErrorMessage}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      color="error"
      onClose={() => {
        setShowErrorMessage(false);
      }}
    >
      <Alert>{errorMessage}</Alert>
    </Snackbar>
  );
};

export default GlobalErrorSnackbar;
