import {
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Typography,
  Stack,
  TextField,
  InputAdornment,
} from '@mui/material';
import React from 'react';
import { type LocationZoneData } from '../store/tariff-group-controller';
import { AmountInputType } from '../types';

type TariffZoneOverageRowProps = {
  readonly zone: LocationZoneData;
  readonly rowIndex: number;
  readonly mileOverageRate: number | null | undefined;
  readonly mileOverageFlatRate: number | null | undefined;
  readonly mileOverageApplicableAbove: number | null | undefined;
  readonly pieceOverageRate: number | null | undefined;
  readonly pieceOverageFlatRate: number | null | undefined;
  readonly pieceOverageApplicableAbove: number | null | undefined;
  readonly weightOverageRate: number | null | undefined;
  readonly weightOverageFlatRate: number | null | undefined;
  readonly weightOverageApplicableAbove: number | null | undefined;
  readonly updateMileOverageRate: (
    rowIndex: number,
    mileOverageRate: number,
  ) => void;
  readonly updateMileOverageFlatRate: (
    rowIndex: number,
    mileOverageFlatRate: number,
  ) => void;
  readonly updateMileOverageApplicableAbove: (
    rowIndex: number,
    mileOverageApplicableAbove: number,
  ) => void;
  readonly updatePieceOverageRate: (
    rowIndex: number,
    pieceOverageRate: number,
  ) => void;
  readonly updatePieceOverageFlatRate: (
    rowIndex: number,
    pieceOverageFlatRate: number,
  ) => void;
  readonly updatePieceOverageApplicableAbove: (
    rowIndex: number,
    pieceOverageApplicableAbove: number,
  ) => void;
  readonly updateWeightOverageRate: (
    rowIndex: number,
    weightOverageRate: number,
  ) => void;
  readonly updateWeightOverageFlatRate: (
    rowIndex: number,
    weightOverageFlatRate: number,
  ) => void;
  readonly updateWeightOverageApplicableAbove: (
    rowIndex: number,
    weightOverageApplicableAbove: number,
  ) => void;
  readonly validateAndUpdateRate: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowIndex: number,
  ) => void;
};

const TariffZoneOverageRow = ({
  zone,
  rowIndex,
  mileOverageRate,
  mileOverageFlatRate,
  mileOverageApplicableAbove,
  pieceOverageRate,
  pieceOverageFlatRate,
  pieceOverageApplicableAbove,
  weightOverageRate,
  weightOverageFlatRate,
  weightOverageApplicableAbove,
  updateMileOverageRate,
  updateMileOverageFlatRate,
  updateMileOverageApplicableAbove,
  updatePieceOverageRate,
  updatePieceOverageFlatRate,
  updatePieceOverageApplicableAbove,
  updateWeightOverageRate,
  updateWeightOverageFlatRate,
  updateWeightOverageApplicableAbove,
  validateAndUpdateRate,
}: TariffZoneOverageRowProps) => {
  return (
    <Grid
      key={zone.uuid}
      container
      mt={2}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item mr={5}>
        <Typography variant="subtitle1">
          {zone.name}
          {zone.isLhLaneActive === false && (
            <Typography
              component="span"
              sx={{ fontSize: '10px', display: 'block' }}
            >
              (Archived)
            </Typography>
          )}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Stack direction="row" spacing={4}>
          <Stack direction="row" flex={1} spacing={2}>
            <TextField
              sx={{ flex: 1 }}
              InputLabelProps={{ shrink: true }}
              name={AmountInputType.MileOverage}
              size="small"
              label="Mile Overage Rate"
              value={mileOverageRate ?? null}
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              onBlur={(event) => {
                validateAndUpdateRate(event, rowIndex);
              }}
              onChange={(event) => {
                updateMileOverageRate(
                  rowIndex,
                  Number.parseFloat(event.target.value),
                );
              }}
              onWheel={(e) => {
                (e.target as HTMLTextAreaElement).blur();
              }}
            />
            <TextField
              sx={{ flex: 1 }}
              InputLabelProps={{ shrink: true }}
              name={AmountInputType.MileOverageFlatRate}
              size="small"
              label="Mile Ovg. Flat Rate"
              value={mileOverageFlatRate ?? null}
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              onBlur={(event) => {
                validateAndUpdateRate(event, rowIndex);
              }}
              onChange={(event) => {
                updateMileOverageFlatRate(
                  rowIndex,
                  Number.parseFloat(event.target.value),
                );
              }}
              onWheel={(e) => {
                (e.target as HTMLTextAreaElement).blur();
              }}
            />
            <TextField
              sx={{ flex: 1 }}
              InputLabelProps={{ shrink: true }}
              name={AmountInputType.MileOverageApplicableAbove}
              size="small"
              label="Applicable Above (Optional)"
              value={mileOverageApplicableAbove ?? null}
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">miles</InputAdornment>
                ),
              }}
              onBlur={(event) => {
                validateAndUpdateRate(event, rowIndex);
              }}
              onChange={(event) => {
                updateMileOverageApplicableAbove(
                  rowIndex,
                  Number.parseFloat(event.target.value),
                );
              }}
              onWheel={(e) => {
                (e.target as HTMLTextAreaElement).blur();
              }}
            />
          </Stack>
          <Stack direction="row" flex={1} spacing={2}>
            <TextField
              sx={{ flex: 1 }}
              InputLabelProps={{ shrink: true }}
              name={AmountInputType.PieceOverage}
              size="small"
              label="Piece Count Overage Rate"
              value={pieceOverageRate ?? null}
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              onBlur={(event) => {
                validateAndUpdateRate(event, rowIndex);
              }}
              onChange={(event) => {
                updatePieceOverageRate(
                  rowIndex,
                  Number.parseFloat(event.target.value),
                );
              }}
              onWheel={(e) => {
                (e.target as HTMLTextAreaElement).blur();
              }}
            />
            <TextField
              sx={{ flex: 1 }}
              InputLabelProps={{ shrink: true }}
              name={AmountInputType.PieceOverageFlatRate}
              size="small"
              label="Piece Count Ovg. Flat Rate"
              value={pieceOverageFlatRate ?? null}
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              onBlur={(event) => {
                validateAndUpdateRate(event, rowIndex);
              }}
              onChange={(event) => {
                updatePieceOverageFlatRate(
                  rowIndex,
                  Number.parseFloat(event.target.value),
                );
              }}
              onWheel={(e) => {
                (e.target as HTMLTextAreaElement).blur();
              }}
            />
            <TextField
              sx={{ flex: 1 }}
              InputLabelProps={{ shrink: true }}
              name={AmountInputType.PieceOverageApplicableAbove}
              size="small"
              label="Applicable Above (Optional)"
              value={pieceOverageApplicableAbove ?? null}
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">pieces</InputAdornment>
                ),
              }}
              onBlur={(event) => {
                validateAndUpdateRate(event, rowIndex);
              }}
              onChange={(event) => {
                updatePieceOverageApplicableAbove(
                  rowIndex,
                  Number.parseFloat(event.target.value),
                );
              }}
              onWheel={(e) => {
                (e.target as HTMLTextAreaElement).blur();
              }}
            />
          </Stack>
          <Stack direction="row" flex={1} spacing={2}>
            <TextField
              sx={{ flex: 1 }}
              InputLabelProps={{ shrink: true }}
              name={AmountInputType.WeightOverage}
              size="small"
              label="Weight Overage Rate"
              value={weightOverageRate ?? null}
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              onBlur={(event) => {
                validateAndUpdateRate(event, rowIndex);
              }}
              onChange={(event) => {
                updateWeightOverageRate(
                  rowIndex,
                  Number.parseFloat(event.target.value),
                );
              }}
              onWheel={(e) => {
                (e.target as HTMLTextAreaElement).blur();
              }}
            />
            <TextField
              sx={{ flex: 1 }}
              InputLabelProps={{ shrink: true }}
              name={AmountInputType.WeightOverageFlatRate}
              size="small"
              label="Weight Ovg. Flat Rate"
              value={weightOverageFlatRate ?? null}
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              onBlur={(event) => {
                validateAndUpdateRate(event, rowIndex);
              }}
              onChange={(event) => {
                updateWeightOverageFlatRate(
                  rowIndex,
                  Number.parseFloat(event.target.value),
                );
              }}
              onWheel={(e) => {
                (e.target as HTMLTextAreaElement).blur();
              }}
            />
            <TextField
              sx={{ flex: 1 }}
              InputLabelProps={{ shrink: true }}
              name={AmountInputType.WeightOverageApplicableAbove}
              size="small"
              label="Applicable Above (Optional)"
              value={weightOverageApplicableAbove ?? null}
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">pounds</InputAdornment>
                ),
              }}
              onBlur={(event) => {
                validateAndUpdateRate(event, rowIndex);
              }}
              onChange={(event) => {
                updateWeightOverageApplicableAbove(
                  rowIndex,
                  Number.parseFloat(event.target.value),
                );
              }}
              onWheel={(e) => {
                (e.target as HTMLTextAreaElement).blur();
              }}
            />
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default React.memo(TariffZoneOverageRow);
