import { Done as DoneIcon, Error as ErrorIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Stack,
} from '@mui/material';
import { isNil } from 'lodash';
import pluralize from 'pluralize';
import { type Dispatch, type SetStateAction, useState } from 'react';
import { exhaustive } from 'shared/switch';
import { shallow } from 'zustand/shallow';
import {
  useFinalizeInvoicesMutation,
  CurrentBillingPeriodInvoiceDocument,
  InvoicesByUuidsDocument,
} from '../../../../generated/graphql';
import useInvoicesStore from '../../invoices-store';

enum LoadingState {
  HasNotStarted,
  Loading,
  Error,
  Success,
}

const ShowPostWithoutSendingModal = ({
  open,
  setOpen,
  selectedUnfinalizedInvoiceUuids,
}: {
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
  readonly selectedUnfinalizedInvoiceUuids: string[];
}) => {
  const [loadingState, setLoadingState] = useState(LoadingState.HasNotStarted);

  const [finalizeInvoicesMutation] = useFinalizeInvoicesMutation({
    refetchQueries: [
      CurrentBillingPeriodInvoiceDocument,
      InvoicesByUuidsDocument,
    ],
  });
  const [setShouldRefreshInvoiceList, deselectAllInvoiceUuids] =
    useInvoicesStore(
      (state) => [
        state.setShouldRefreshInvoiceList,
        state.deselectAllInvoiceUuids,
      ],
      shallow,
    );

  const handleClose = (deselectInvoices?: boolean) => {
    if (loadingState === LoadingState.Loading) return;
    setOpen(false);
    if (deselectInvoices === true) deselectAllInvoiceUuids();
    setLoadingState(LoadingState.HasNotStarted);
  };

  const finalizeInvoices = async () => {
    setLoadingState(LoadingState.Loading);
    const res = await finalizeInvoicesMutation({
      variables: {
        finalizeInvoicesInput: {
          uuids: selectedUnfinalizedInvoiceUuids,
        },
      },
    });
    if (isNil(res.errors) && !isNil(res.data?.finalizeInvoices.count)) {
      setLoadingState(LoadingState.Success);
    } else {
      setLoadingState(LoadingState.Error);
    }
    setShouldRefreshInvoiceList(true);
  };

  const getTitle = () => {
    switch (loadingState) {
      case LoadingState.HasNotStarted: {
        return `Post ${selectedUnfinalizedInvoiceUuids.length} ${pluralize(
          'invoice',
          selectedUnfinalizedInvoiceUuids.length,
        )}?`;
      }
      case LoadingState.Loading: {
        return `Posting ${selectedUnfinalizedInvoiceUuids.length} ${pluralize(
          'invoice',
          selectedUnfinalizedInvoiceUuids.length,
        )}`;
      }
      case LoadingState.Error: {
        return `There was an error posting ${
          selectedUnfinalizedInvoiceUuids.length
        } ${pluralize('invoice', selectedUnfinalizedInvoiceUuids.length)}`;
      }
      case LoadingState.Success: {
        return `Successfully posted ${
          selectedUnfinalizedInvoiceUuids.length
        } ${pluralize('invoice', selectedUnfinalizedInvoiceUuids.length)}`;
      }
      default: {
        return exhaustive(loadingState);
      }
    }
  };
  const getBody = () => {
    switch (loadingState) {
      case LoadingState.HasNotStarted: {
        return null;
      }
      case LoadingState.Loading: {
        return <CircularProgress />;
      }
      case LoadingState.Error: {
        return <ErrorIcon color="error" />;
      }
      case LoadingState.Success: {
        return <DoneIcon color="success" />;
      }
      default: {
        return exhaustive(loadingState);
      }
    }
  };
  const getPrimaryButtonLabel = () => {
    switch (loadingState) {
      case LoadingState.HasNotStarted:
      case LoadingState.Loading: {
        return 'Confirm';
      }
      case LoadingState.Error:
      case LoadingState.Success: {
        return 'Close';
      }
      default: {
        return exhaustive(loadingState);
      }
    }
  };
  const handlePrimaryClick = () => {
    switch (loadingState) {
      case LoadingState.HasNotStarted:
      case LoadingState.Loading: {
        finalizeInvoices();
        break;
      }
      case LoadingState.Error:
      case LoadingState.Success: {
        handleClose(true);
        break;
      }
      default: {
        exhaustive(loadingState);
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
    >
      <Box
        sx={{
          p: '20px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: 200,
          width: 500,
        }}
      >
        <DialogTitle>
          <Stack
            direction="row"
            alignItems="center"
            gap={2}
            width="100%"
            height={40}
          >
            {getTitle()}
            {getBody()}
          </Stack>
        </DialogTitle>
        <DialogActions
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Button
            variant="outlined"
            disabled={loadingState === LoadingState.Loading}
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={loadingState === LoadingState.Loading}
            onClick={handlePrimaryClick}
          >
            {getPrimaryButtonLabel()}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ShowPostWithoutSendingModal;
