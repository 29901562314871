// eslint-disable-next-line no-restricted-imports
import { Box, Button, Grid, Modal, Stack, Typography } from '@mui/material';
import { isNil } from 'lodash';
import React, {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useState,
} from 'react';
import TerminalPicker from '../../../common/components/terminal-picker';
import useTerminals from '../../../common/react-hooks/use-terminals';
import {
  TariffZoneType,
  TariffZoneLocationType,
  useCreateTariffZoneMutation,
  useDeleteTariffZoneMutation,
  useFindTariffZoneByUuidLazyQuery,
  useUpdateTariffZoneMutation,
} from '../../../generated/graphql';
import styles from '../styles';

const TerminalTariffZoneModal = ({
  tariffZoneUuid,
  contactUuid,
  open,
  setOpen,
}: {
  readonly tariffZoneUuid?: string;
  readonly contactUuid: string | null;
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { getTerminalName } = useTerminals({
    includeInactiveTerminals: false,
  });
  const [findTariffZoneByUuid] = useFindTariffZoneByUuidLazyQuery();
  const [createTariffZone] = useCreateTariffZoneMutation();
  const [updateTariffZone] = useUpdateTariffZoneMutation();
  const [deleteTariffZone] = useDeleteTariffZoneMutation();
  const [originTerminalUuid, setOriginTerminalUuid] = useState<string>();
  const [destinationTerminalUuid, setDestinationTerminalUuid] =
    useState<string>();

  const resetState = () => {
    setOriginTerminalUuid(undefined);
    setDestinationTerminalUuid(undefined);
  };

  useEffect(() => {
    resetState();
    if (open && !isNil(tariffZoneUuid)) {
      findTariffZoneByUuid({
        variables: { uuid: tariffZoneUuid },
      }).then((res) => {
        setOriginTerminalUuid(
          res.data?.findTariffZoneByUuidOrThrow.terminal?.uuid,
        );
        setDestinationTerminalUuid(
          res.data?.findTariffZoneByUuidOrThrow.destinationTerminal?.uuid,
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const getTerminalTariffName = (
    origTerminalUuid: string | undefined,
    destTerminalUuid: string | undefined,
  ) => {
    return `${
      isNil(origTerminalUuid) ? 'All' : getTerminalName(origTerminalUuid)
    } - ${isNil(destTerminalUuid) ? 'All' : getTerminalName(destTerminalUuid)}`;
  };

  const handleDelete = async () => {
    if (!isNil(tariffZoneUuid)) {
      await deleteTariffZone({ variables: { uuid: tariffZoneUuid } });
      setOpen(false);
    }
  };

  const handleSave = async () => {
    if (isNil(contactUuid) || typeof contactUuid === 'string') {
      await (isNil(tariffZoneUuid)
        ? createTariffZone({
            variables: {
              tariffZoneCreateInput: {
                terminalUuid: originTerminalUuid,
                destinationTerminalUuid,
                name: getTerminalTariffName(
                  originTerminalUuid,
                  destinationTerminalUuid,
                ),
                type: TariffZoneType.Location,
                tariffZoneLocationType: TariffZoneLocationType.Terminal,
              },
              contactUuid,
            },
          })
        : updateTariffZone({
            variables: {
              tariffZoneUpdateInput: {
                terminalUuid: originTerminalUuid,
                destinationTerminalUuid,
                name: getTerminalTariffName(
                  originTerminalUuid,
                  destinationTerminalUuid,
                ),
                uuid: tariffZoneUuid,
                tariffZoneLocationType: TariffZoneLocationType.Terminal,
              },
              contactUuid,
            },
          }));
      setOpen(false);
      resetState();
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Box sx={{ ...styles.modal, width: '550px', height: '175px' }}>
        <Grid container spacing={3} sx={{ height: '100%' }}>
          <Grid item xs={4}>
            <Button variant="contained" color="error" onClick={handleDelete}>
              Delete
            </Button>
          </Grid>
          <Grid item xs={4} sx={styles.center}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Edit Tariff Zone
            </Typography>
          </Grid>
          <Grid item xs={4} justifyContent="flex-end">
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Button variant="contained" color="primary" onClick={handleSave}>
                Save
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
              <TerminalPicker
                label="Origin Terminal"
                terminalUuid={originTerminalUuid}
                setTerminalUuid={setOriginTerminalUuid}
                includeInactiveTerminals={false}
              />
              <TerminalPicker
                label="Destination Terminal"
                terminalUuid={destinationTerminalUuid}
                setTerminalUuid={setDestinationTerminalUuid}
                includeInactiveTerminals={false}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default TerminalTariffZoneModal;
