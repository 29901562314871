import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Divider,
  FormControl,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isEmpty, isNil } from 'lodash';
import React, {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CSVLink } from 'react-csv';
import BusinessDivisionFilterButton, {
  type BusinessDivisionOption,
} from '../../../../../common/components/business-division-filter-button';
import CustomerFilterButton from '../../../../../common/components/customer-filter-button';
import DateDropdownPicker, {
  type DateOption,
  defaultPast1WeekDateRangeOption,
} from '../../../../../common/components/date-dropdown-picker';
import DownloadTypeSelection, {
  DownloadType,
} from '../../../../../common/components/download-type-selection';
import { type Option } from '../../../../../common/filters/types';
import { useMeasureExecutionTime } from '../../../../../common/react-hooks/use-measure-execution-time';
import {
  InvoiceStatus,
  useBusinessDivisionsQuery,
  useMeQuery,
  useOpenInvoiceReportLazyQuery,
} from '../../../../../generated/graphql';
import useInvoicesStore from '../../../invoices-store';
import styles from '../../../styles';
import { InvoiceStatusTab } from '../../../types/types';
import { downloadOpenInvoicesReport } from '../../../utils';
import { accountingReportDefaultDateOption } from '../../accounting-reports/constants';

type DownloadOpenInvoicesReportModalProps = {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const DownloadOpenInvoicesReportModal = ({
  isOpen,
  setIsOpen,
}: DownloadOpenInvoicesReportModalProps) => {
  const [dateOption, setDateOption] = useState<DateOption>(
    defaultPast1WeekDateRangeOption,
  );
  const [customerOption, setCustomerOption] = useState<Option | undefined>();
  const [businessDivisionOption, setBusinessDivisionOption] = useState<
    BusinessDivisionOption | undefined
  >();
  const [downloadType, setDownloadType] = useState<DownloadType>(
    DownloadType.PDF,
  );

  const { data: companyData } = useMeQuery({
    fetchPolicy: 'cache-first',
  });
  const [getOpenInvoices, { loading: invoicesDataLoading }] =
    useOpenInvoiceReportLazyQuery();
  const [statusTab, setStatusTab] = useState<InvoiceStatusTab>(
    InvoiceStatusTab.ALL,
  );
  const createFileDownload = useInvoicesStore(
    (state) => state.createFileDownload,
  );
  const [csvReportData, setCSVReportData] = useState<{
    fileName: string;
    csvData: Array<string[] | Array<string | number | undefined>>;
  }>({ fileName: '', csvData: [] });
  const csvLink = useRef<{ link: HTMLAnchorElement }>(null);

  const { data: businessDivisionsData } = useBusinessDivisionsQuery({
    fetchPolicy: 'cache-first',
  });
  const businessDivisions =
    businessDivisionsData?.businessDivisions?.businessDivisions ?? [];

  const resetFilters = useCallback(() => {
    setDateOption(accountingReportDefaultDateOption);
    setBusinessDivisionOption(undefined);
    setCustomerOption(undefined);
    setStatusTab(InvoiceStatusTab.ALL);
  }, [
    setDateOption,
    setBusinessDivisionOption,
    setCustomerOption,
    setStatusTab,
  ]);

  const invoicesInputs = useMemo(() => {
    let statuses = [
      InvoiceStatus.NotFinalized,
      InvoiceStatus.ReadyToInvoice,
      InvoiceStatus.Invoiced,
    ];
    if (statusTab === InvoiceStatusTab.NOT_POSTED) {
      statuses = [InvoiceStatus.NotFinalized];
    } else if (statusTab === InvoiceStatusTab.POSTED) {
      statuses = [InvoiceStatus.ReadyToInvoice, InvoiceStatus.Invoiced];
    }

    return {
      statuses,
      invoiceStartDate: dateOption.startDate,
      invoiceEndDate: dateOption.endDate,
      billingPartyContactUuid: customerOption?.value ?? undefined,
      businessDivisionUuid: businessDivisionOption?.value ?? undefined,
    };
  }, [dateOption, businessDivisionOption, customerOption, statusTab]);

  const startDownload = async () => {
    // Can't close modal until download is complete so CSV link can be clicked
    if (downloadType !== DownloadType.CSV) setIsOpen(false);
    const completeDownload = createFileDownload();

    const res = await getOpenInvoices({
      variables: {
        openInvoiceReportArgs: {
          findInvoicesArgs: invoicesInputs,
        },
      },
    });
    const openInvoicesData = res.data?.openInvoiceReport ?? [];
    const csvDataRes = await downloadOpenInvoicesReport({
      companyData,
      startDate: dateOption.startDate,
      endDate: dateOption.endDate,
      status: statusTab,
      invoices: openInvoicesData,
      businessDivisionOption,
      customerOption,
      downloadType: downloadType ?? DownloadType.PDF,
    });

    if (downloadType === DownloadType.CSV) {
      if (!isNil(csvDataRes)) {
        setCSVReportData(csvDataRes);
      }
    } else if (downloadType === DownloadType.PDF) {
      resetFilters();
    }
    completeDownload();
  };

  const startDownloadWithMeasurement = useMeasureExecutionTime({
    fn: startDownload,
    rumLabel: 'download-open-invoices-report',
    logData: {
      ...invoicesInputs,
      downloadType,
    },
  });

  useEffect(() => {
    if (!isEmpty(csvReportData)) {
      csvLink.current?.link.click();
      setIsOpen(false);
      resetFilters();
    }
  }, [csvReportData, setIsOpen, resetFilters]);

  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={() => {
        if (!invoicesDataLoading || downloadType !== DownloadType.CSV) {
          setIsOpen(false);
        }
      }}
    >
      <Box sx={[styles.modal, { height: '300px', width: '500px' }]}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={10}>
            <Typography sx={{ fontWeight: 'bold' }}>
              Download Open Invoices Report
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              sx={{ float: 'right' }}
              disabled={
                invoicesDataLoading && downloadType === DownloadType.CSV
              }
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} display="flex" flexDirection="row" gap={1}>
            <DateDropdownPicker
              filterTitle="Invoice Date"
              dateOption={dateOption}
              setDateOption={setDateOption}
            />
            <FormControl sx={{ width: '35%' }}>
              <InputLabel id="invoice-status-label">Invoice Status</InputLabel>
              <Select
                required
                labelId="invoice-status-label"
                label="Invoice Status"
                value={statusTab}
                size="small"
                sx={{ backgroundColor: 'white' }}
                onChange={(e) => {
                  setStatusTab(e.target.value as InvoiceStatusTab);
                }}
              >
                {Object.values(InvoiceStatusTab).map((status) => (
                  <MenuItem key={status} id={status} value={status}>
                    {sentenceCase(status)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="row"
            gap={1}
            flexWrap="wrap"
          >
            <CustomerFilterButton
              selectedOption={customerOption}
              handleChange={(option: Option | undefined) => {
                setCustomerOption(option);
              }}
            />
            {!isEmpty(businessDivisions) && (
              <BusinessDivisionFilterButton
                prefixText="Business division"
                selectedOption={businessDivisionOption}
                handleChange={(option: BusinessDivisionOption | undefined) => {
                  setBusinessDivisionOption(option);
                }}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{ float: 'left' }}
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={1}
            >
              <DownloadTypeSelection
                labelText="File type"
                cacheId="INVOICE_OPEN_INVOICES_REPORT"
                selectedOption={downloadType}
                handleChange={(selectedDownloadType: DownloadType) => {
                  setDownloadType(selectedDownloadType);
                }}
              />
            </Box>
            <Box sx={{ float: 'right' }}>
              <Button
                sx={{ width: '100px' }}
                variant="contained"
                color="info"
                disabled={
                  invoicesDataLoading && downloadType === DownloadType.CSV
                }
                onClick={startDownloadWithMeasurement}
              >
                Download
              </Button>
            </Box>
          </Grid>
          <CSVLink
            ref={csvLink}
            data={csvReportData.csvData}
            filename={csvReportData.fileName}
            className="hidden"
            target="_blank"
          />
        </Grid>
      </Box>
    </Modal>
  );
};

export default DownloadOpenInvoicesReportModal;
