// eslint-disable-next-line
import { Button, CircularProgress, Grid, Toolbar } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { pdf } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { isNil } from 'lodash';
import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import useMe from '../../../common/react-hooks/use-me';
import useTerminals from '../../../common/react-hooks/use-terminals';
import { ReportType } from '../../../generated/graphql';
import { type ReportGroupConfiguration } from '../types/report-types';
import {
  formatMoney,
  formatWeight,
  getComparator,
  getOrderReportRowLabel,
  type HeadCell,
  Order,
  type ReportBucketData,
  stableSort,
} from '../utils';
import GeneratedRevenueReport from './download/generated-revenue-report';
import ReportDownloadTypeSelection, {
  ReportDownloadType,
} from './download/report-download-type-selection';

type EnhancedTableProps = {
  readonly onRequestSort: (
    event: React.MouseEvent,
    property: keyof ReportBucketData,
  ) => void;
  readonly order: Order;
  readonly orderBy: keyof ReportBucketData | undefined;
  readonly headCells: readonly HeadCell[];
};

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { order, orderBy, headCells, onRequestSort } = props;
  const createSortHandler =
    (property: keyof ReportBucketData) => (event: React.MouseEvent) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.label}
            align={headCell.numeric ? 'right' : 'left'}
          >
            <TableSortLabel
              active={orderBy === headCell.key}
              direction={orderBy === headCell.key ? order : 'asc'}
              onClick={createSortHandler(headCell.key)}
            >
              {headCell.label}
              {orderBy === headCell.key ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

type OrderReportTableProps = {
  readonly isLoading: boolean;
  readonly reportData: ReportBucketData[];
  readonly reportGroupConfiguration: ReportGroupConfiguration | undefined;
  readonly headCells: readonly HeadCell[];
  readonly csvHeaders: HeadCell[];
  readonly defaultRowsPerPage?: number | undefined;
  readonly defaultOrder?: Order | undefined;
};

const ReportTable = ({
  isLoading,
  reportData,
  reportGroupConfiguration,
  headCells,
  csvHeaders,
  defaultRowsPerPage = 15,
  defaultOrder = Order.ASC,
}: OrderReportTableProps) => {
  const [order, setOrder] = React.useState<Order>(defaultOrder);
  const [orderBy, setOrderBy] = React.useState<
    keyof ReportBucketData | undefined
  >();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage);
  const [rowData, setRowData] = useState<ReportBucketData[]>([]);
  const [downloadType, setDownloadType] = useState<ReportDownloadType>(
    ReportDownloadType.CSV,
  );
  const csvLink = useRef();
  const { companyData } = useMe();
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });

  useEffect(() => {
    setRowData(reportData);
    setOrderBy(headCells[0]?.key);
  }, [headCells, reportData]);

  const handleRequestSort = (
    _: React.MouseEvent<unknown>,
    property: keyof ReportBucketData,
  ) => {
    const isAsc = orderBy === property && order === Order.ASC;
    setOrder(isAsc ? Order.DESC : Order.ASC);
    setOrderBy(property);
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(Number.parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowData.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(rowData, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rowData],
  );

  const csvFormattedRows = useMemo(() => {
    return stableSort(rowData, getComparator(order, orderBy)).map((row) => {
      return {
        ...row,
        startDate: dayjs(row.startDate).format('MM/DD/YYYY'),
        endDate: dayjs(row.endDate).format('MM/DD/YYYY'),
        totalRevenue: formatMoney({ amount: row.totalRevenue }),
        freightRevenue: formatMoney({ amount: row.freightRevenue }),
        customChargeRevenue: formatMoney({ amount: row.customChargeRevenue }),
        fuelRevenue: formatMoney({ amount: row.fuelRevenue }),
        totalWeight: formatWeight({ weight: row.totalWeight }),
      };
    });
  }, [rowData, order, orderBy]);

  const startDownload = async () => {
    if (downloadType === ReportDownloadType.CSV) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      csvLink.current?.link.click();
    } else if (downloadType === ReportDownloadType.PDF_REVENUE) {
      const blob = await pdf(
        <GeneratedRevenueReport
          isRevenueReport
          companyData={companyData}
          reportData={reportData}
          reportGroupConfiguration={reportGroupConfiguration}
          terminalsEnabled={terminalsEnabled}
          isIncomeAnalysisReport={false}
        />,
      ).toBlob();
      const fileName = `revenue-report-${reportGroupConfiguration?.defaultReportType}.pdf`;
      saveAs(blob, fileName);
    }
  };
  return (
    <Paper sx={{ width: '100%', mb: 2, height: '100%' }}>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Grid item xs={6} sx={{ paddingTop: 0 }} />
        <Grid
          item
          xs={6}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="end"
          gap={1}
        >
          <ReportDownloadTypeSelection
            labelText="Format"
            selectedOption={downloadType}
            handleChange={(selectedDownloadType: ReportDownloadType) => {
              setDownloadType(selectedDownloadType);
            }}
          />
          <Button variant="contained" onClick={startDownload}>
            Export
          </Button>
          <CSVLink
            ref={csvLink}
            data={csvFormattedRows}
            headers={csvHeaders}
            filename={`${reportGroupConfiguration?.name}-data.csv`}
            className="hidden"
            target="_blank"
          />
        </Grid>
      </Toolbar>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="reportTableTitle"
          size="small"
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            headCells={headCells}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {!isNil(visibleRows) &&
              visibleRows.map((row) => {
                return (
                  <TableRow key={`${row.id}-row`} hover tabIndex={-1}>
                    {isLoading && (
                      <TableCell component="th" scope="row">
                        <CircularProgress size={15} />
                      </TableCell>
                    )}
                    {!isLoading &&
                      reportGroupConfiguration?.defaultReportType ===
                        ReportType.Order && (
                        <TableCell component="th" scope="row">
                          {getOrderReportRowLabel(
                            reportGroupConfiguration?.reportAggregationPeriod,
                            row,
                          )}
                        </TableCell>
                      )}
                    {!isLoading &&
                      reportGroupConfiguration?.defaultReportType ===
                        ReportType.Driver && (
                        <>
                          <TableCell component="th" scope="row">
                            {row.driverName}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.driverType}
                          </TableCell>
                        </>
                      )}
                    {!isLoading &&
                      reportGroupConfiguration?.defaultReportType ===
                        ReportType.Customer && (
                        <TableCell component="th" scope="row">
                          {row.displayName}
                        </TableCell>
                      )}
                    {!isLoading &&
                      reportGroupConfiguration?.defaultReportType ===
                        ReportType.ServiceLevel && (
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                      )}
                    {!isLoading &&
                      reportGroupConfiguration?.defaultReportType ===
                        ReportType.Station && (
                        <TableCell component="th" scope="row">
                          {row.stationName}
                        </TableCell>
                      )}
                    {!isLoading &&
                      reportGroupConfiguration?.defaultReportType ===
                        ReportType.Terminal && (
                        <TableCell component="th" scope="row">
                          {row.terminalName}
                        </TableCell>
                      )}
                    <TableCell align="right">
                      {formatMoney({ amount: row.totalRevenue })}
                    </TableCell>
                    {reportGroupConfiguration?.defaultReportType ===
                    ReportType.Terminal ? (
                      <>
                        <TableCell align="right">
                          {formatMoney({ amount: row.pickupRevenue })}
                        </TableCell>
                        <TableCell align="right">
                          {formatMoney({ amount: row.deliveryRevenue })}
                        </TableCell>
                        <TableCell align="right">
                          {formatMoney({ amount: row.transferRevenue })}
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell align="right">
                          {formatMoney({ amount: row.freightRevenue })}
                        </TableCell>
                        <TableCell align="right">
                          {formatMoney({ amount: row.customChargeRevenue })}
                        </TableCell>
                        <TableCell align="right">
                          {formatMoney({ amount: row.fuelRevenue })}
                        </TableCell>
                      </>
                    )}
                    <TableCell align="right">
                      {formatWeight({ weight: row.totalWeight })}
                    </TableCell>
                    <TableCell align="right">{row.numberOfOrders}</TableCell>
                    <TableCell align="right">{row.numberOfPackages}</TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 33 * emptyRows,
                }}
              >
                <TableCell colSpan={8} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[15, 40, 100]}
        component="div"
        count={rowData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default ReportTable;
