import Decimal from 'decimal.js';
import { isNil } from 'lodash';
import { filterNotNil } from 'shared/array';
import { safeDivide, safeMultiply } from 'shared/math';
import { isNilOrEmptyString } from 'shared/string';
import { exhaustive } from 'shared/switch';
import {
  CustomChargeBillingMethod,
  type RateOrderCustomChargeInput,
  type RateOrderFreightChargeInput,
  type RateOrderFuelChargeInput,
  type RateOrderOrderChargesShipmentAndChargesInput,
  type RateOrderLineHaulShipmentAndChargesInput,
  type RateOrderRegularShipmentAndChargesInput,
  ShipmentType,
  StopType,
  type FulfillmentType,
} from '../../generated/graphql';
import { StopType as OrderFormStopType } from './components/order-form/forms/stop-type';
import {
  type CustomChargeValues,
  type FreightChargeValues,
  type FuelChargeValues,
  type LineHaulShipmentValues,
  type OrderChargesShipmentValues,
  type StopValues,
} from './components/order-form/forms/types';
import {
  getExpectedLineHaulShipmentStatus,
  getExpectedOrderChargesShipmentStatus,
  getExpectedRegularShipmentsStatus,
} from './components/order-form/utils';

/**
 * Converts the stop type from the order form to the stop type for rating.
 * Note that there is another function with a similar name in `use-order-form.tsx`
 * but that function does not map partner carrier stops to the correct stop type for rating.
 * Hence, we need a separate function that handles the stop type mapping for rating.
 * @param stopType
 * @returns
 */
export function convertOrderFormStopTypeToStopTypeForRating(
  stopType: OrderFormStopType | null | undefined,
): StopType | null {
  if (isNil(stopType)) {
    return null;
  }
  switch (stopType) {
    case OrderFormStopType.Pickup: {
      return StopType.Pickup;
    }
    case OrderFormStopType.PartnerCarrierDropoff: {
      return StopType.PartnerCarrierDropoff;
    }
    case OrderFormStopType.Delivery: {
      return StopType.Delivery;
    }
    case OrderFormStopType.PartnerCarrierPickup: {
      return StopType.PartnerCarrierPickup;
    }
    case OrderFormStopType.Recovery: {
      return StopType.Recovery;
    }
    case OrderFormStopType.Transfer: {
      return StopType.Transfer;
    }
    case OrderFormStopType.None: {
      return null;
    }
    default: {
      return exhaustive(stopType);
    }
  }
}

/**
 * Given the freight charge values from the order form, converts it into the shape
 * expected by the rateOrder query
 * @param freightCharge
 */
export function convertFreightChargeToQueryInput({
  freightCharge,
  isLocal,
}: {
  freightCharge: FreightChargeValues | null | undefined;
  isLocal: boolean | null | undefined;
}): RateOrderFreightChargeInput | null {
  if (isNil(freightCharge)) {
    return null;
  }
  const { billingMethod, tariffUuid, rate, quantity } = freightCharge;
  if (isNil(rate) || isNil(quantity)) {
    return null;
  }

  const existingFreightChargeUuid =
    isLocal === true ? null : freightCharge.uuid;

  return {
    existingFreightChargeUuid,
    billingMethod,
    tariffUuid,
    rateUsdCents: null, // TODO: Remove once field is fully deprecated on the backend.
    rateUsdDecimalCents: new Decimal(rate).mul(100),
    quantityInHundreds: safeMultiply(quantity, 100),
    settlementFlatRate: freightCharge.settlementFlatRate,
    settlementPercentageRate: freightCharge.settlementPercentageRate,
  };
}

/**
 * Given the fuel charge values from the order form, converts it into the shape
 * expected by the rateOrder query
 * @param fuelCharge
 */
export function convertFuelChargeToQueryInput({
  fuelCharge,
  isLocal,
}: {
  fuelCharge: FuelChargeValues | null | undefined;
  isLocal: boolean | null | undefined;
}): RateOrderFuelChargeInput | null {
  if (isNil(fuelCharge)) {
    return null;
  }
  const { billingMethod, flatRateDollars, surchargeRate } = fuelCharge;

  if (isNil(flatRateDollars) && isNil(surchargeRate)) {
    return null;
  }

  const existingFuelChargeUuid = isLocal === true ? null : fuelCharge.uuid;

  return {
    existingFuelChargeUuid,
    type: billingMethod,
    flatRateUsdCents: isNil(flatRateDollars)
      ? null
      : safeMultiply(flatRateDollars, 100),
    surchargeRate: safeDivide(surchargeRate ?? 0, 100, 10),
    settlementFlatRate: fuelCharge.settlementFlatRate,
    settlementPercentageRate: fuelCharge.settlementPercentageRate,
  };
}

/**
 * Given the custom charge values from the order form, converts it into the shape
 * expected by the rateOrder query
 * @param customCharge
 */
export function convertCustomChargesToQueryInput({
  customCharge,
  isLocal,
}: {
  customCharge: CustomChargeValues;
  isLocal: boolean | null | undefined;
}): RateOrderCustomChargeInput | null {
  const {
    uuid,
    billingMethod,
    quantity,
    rate,
    accessorialUuid,
    useAccessorialRate,
    zoneBasedAccessorialMatrixItemUuid,
    specialAccessorialMatrixItemUuid,
    chargeGroupUuid: specialAccessorialChargeGroupUuid,
    fuelSurchargePercentageRate,
    settlementFlatRate,
    settlementPercentageRate,
  } = customCharge;

  if (
    isNil(quantity) ||
    (billingMethod === CustomChargeBillingMethod.Accessorial &&
      isNil(accessorialUuid))
  ) {
    return null;
  }

  const existingCustomChargeUuid = isLocal === true ? null : uuid;

  return {
    existingCustomChargeUuid,
    billingMethod,
    quantity,
    rateUsdDecimalCents: isNil(rate) ? null : safeMultiply(rate, 100, 10),
    accessorialUuid,
    useAccessorialRate,
    zoneBasedAccessorialMatrixItemUuid,
    specialAccessorialMatrixItemUuid,
    specialAccessorialChargeGroupUuid,
    fuelSurchargeRate: isNil(fuelSurchargePercentageRate)
      ? null
      : safeDivide(fuelSurchargePercentageRate, 100, 10),
    settlementFlatRate,
    settlementPercentageRate,
  };
}

/**
 * Given the stop values from the order form, converts it into the shape
 * expected by the rateOrder query
 * @param stop
 */
export function convertStopToQueryInput({
  stop,
  fulfillmentType,
}: {
  stop: StopValues;
  fulfillmentType?: FulfillmentType | null | undefined;
}): RateOrderRegularShipmentAndChargesInput | null {
  const stopType = convertOrderFormStopTypeToStopTypeForRating(stop?.stopType);
  if (isNil(stopType)) {
    return null;
  }

  const response = getExpectedRegularShipmentsStatus({
    stopType: stop?.stopType,
    fulfillmentType,
  });
  const shouldIncludeFreightAndFuelCharge =
    response.present && response.freightChargePresent;

  return {
    charges: {
      freightCharge: shouldIncludeFreightAndFuelCharge
        ? convertFreightChargeToQueryInput({
            freightCharge: stop.freightCharge,
            isLocal: stop.isLocal,
          })
        : null,
      fuelCharge: shouldIncludeFreightAndFuelCharge
        ? convertFuelChargeToQueryInput({
            fuelCharge: stop.freightCharge?.fuelCharge,
            isLocal: stop.isLocal,
          })
        : null,
      customCharges: filterNotNil(
        stop.customCharges?.map((customCharge) =>
          convertCustomChargesToQueryInput({
            customCharge,
            isLocal: stop.isLocal,
          }),
        ) ?? [],
      ),
    },
    shipmentDetails: {
      shipmentType: ShipmentType.Regular,
      overridePackageWeight: false,
      stop: {
        stopType,
        // Need to exclude excess properties because passing all of address will fail
        // at runtime despite no typeerror due to the lack of exact types
        address: isNil(stop.address)
          ? null
          : {
              line1: stop.address.line1,
              line2: stop.address.line2,
              city: stop.address.city,
              state: stop.address.state,
              zip: stop.address.zip,
              country: stop.address.country,
              latitude: stop.address.latitude,
              longitude: stop.address.longitude,
            },
        serviceDate: stop.serviceDate,
        appointmentDate: stop.appointmentTime, // TODO: Fix this
        completedAt: stop.completedAt,
        miles: stop.miles,
        terminalUuid: stop.terminalUuid,
      },
    },
    // TODO(shanktt BKO-1306): Implement
    independentSettlementBillLineItems: [],
    dependentSettlementBillLineItems: [],
  };
}

/**
 * Given the line haul shipment values from the order form, converts it into the shape
 * expected by the rateOrder query
 * @param lineHaul The line haul shipment values from the order form
 * @param lineHaulLaneUuid The line haul lane uuid from the order form
 */
export function convertLineHaulShipmentToQueryInput({
  lineHaulShipment,
  lineHaulLaneUuid,
  originTerminalUuid,
  destinationTerminalUuid,
  inboundDate,
  outboundDate,
}: {
  lineHaulShipment: LineHaulShipmentValues;
  lineHaulLaneUuid?: string | null;
  originTerminalUuid?: string | null;
  destinationTerminalUuid?: string | null;
  inboundDate?: Date | null;
  outboundDate?: Date | null;
}): RateOrderLineHaulShipmentAndChargesInput | null {
  if (isNilOrEmptyString(lineHaulLaneUuid)) {
    return null;
  }

  const { freightCharge } = lineHaulShipment;

  const response = getExpectedLineHaulShipmentStatus(true); // If we get to this point, we know that the line haul lane exists and can simply pass in true for the parameter
  const shouldIncludeFreightAndFuelCharge =
    response.present && response.freightChargePresent;

  // Note that dateForFuelCharge is poorly named. It is actually the lineHaulCompletedDate
  const serviceDate =
    lineHaulShipment.dateForFuelCharge ??
    inboundDate ??
    outboundDate ??
    new Date();

  return {
    charges: {
      freightCharge: shouldIncludeFreightAndFuelCharge
        ? convertFreightChargeToQueryInput({
            freightCharge,
            isLocal: lineHaulShipment.isLocal,
          })
        : null,
      fuelCharge: shouldIncludeFreightAndFuelCharge
        ? convertFuelChargeToQueryInput({
            fuelCharge: freightCharge?.fuelCharge,
            isLocal: lineHaulShipment.isLocal,
          })
        : null,
      customCharges: [],
    },
    shipmentDetails: {
      shipmentType: ShipmentType.LineHaul,
      laneUuid: lineHaulLaneUuid,
      completedAt: lineHaulShipment.dateForFuelCharge,
      originTerminalUuid,
      destinationTerminalUuid,
      serviceDate,
    },
    // TODO(shanktt BKO-1306): Implement
    independentSettlementBillLineItems: [],
    dependentSettlementBillLineItems: [],
  };
}

/**
 * Given the order charges shipment values from the order form, converts it into the shape
 * expected by the rateOrder query
 * @param orderCharges
 */
export function convertOrderChargesShipmentToQueryInput({
  orderCharges,
  fulfillmentType,
  inboundStopType,
  outboundStopType,
}: {
  orderCharges: OrderChargesShipmentValues;
  inboundStopType: OrderFormStopType | null | undefined;
  outboundStopType: OrderFormStopType | null | undefined;
  fulfillmentType: FulfillmentType | null | undefined;
}): RateOrderOrderChargesShipmentAndChargesInput | null {
  const { freightCharge, customCharges } = orderCharges;

  const response = getExpectedOrderChargesShipmentStatus({
    inboundStopType,
    outboundStopType,
    fulfillmentType,
  });
  const shouldIncludeFreightAndFuelCharge =
    response.present && response.freightChargePresent;

  return {
    charges: {
      freightCharge: shouldIncludeFreightAndFuelCharge
        ? convertFreightChargeToQueryInput({
            freightCharge,
            isLocal: orderCharges.isLocal,
          })
        : null,
      fuelCharge: shouldIncludeFreightAndFuelCharge
        ? convertFuelChargeToQueryInput({
            fuelCharge: freightCharge?.fuelCharge,
            isLocal: orderCharges.isLocal,
          })
        : null,
      customCharges: filterNotNil(
        customCharges?.map((customCharge) =>
          convertCustomChargesToQueryInput({
            customCharge,
            isLocal: orderCharges.isLocal,
          }),
        ) ?? [],
      ),
    },
    shipmentDetails: {
      shipmentType: ShipmentType.OrderCharges,
    },
    // TODO(shanktt BKO-1306): Implement
    independentSettlementBillLineItems: [],
    dependentSettlementBillLineItems: [],
  };
}
