import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Tooltip,
} from '@mui/material';
import useHotkeys from '@reecelucas/react-use-hotkeys';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { exhaustive } from 'shared/switch';
import { shallow } from 'zustand/shallow';
import {
  ORDER_PAGE_SAVE_BUTTON_TEST_ID,
  ORDER_PAGE_SAVE_AND_EXIT_BUTTON_TEST_ID,
  ORDER_PAGE_SAVE_BUTTON_LOADING_SPINNER_TEST_ID,
  ORDER_PAGE_SAVE_AND_EXIT_BUTTON_LOADING_SPINNER_TEST_ID,
} from '../../../../../../constants';
import { OrderDetailedStatus } from '../../../../../generated/graphql';
import useOrderFormStore from '../../../order-form-store';
import { useOrderFormEditAccess } from '../contexts/order-form-edit-access-context';
import { type OrderFormValues } from '../forms/types';
import { type SaveButtonProps } from '../types';

const getWarningModalStatusCopy = (
  detailedStatus:
    | OrderDetailedStatus.ChargesFinalized
    | OrderDetailedStatus.OnInvoice
    | OrderDetailedStatus.InvoicePosted
    | null
    | undefined,
) => {
  if (isNil(detailedStatus)) {
    return '';
  }
  switch (detailedStatus) {
    case OrderDetailedStatus.ChargesFinalized: {
      return 'This order has already been finalized';
    }
    case OrderDetailedStatus.OnInvoice: {
      return 'This order is already on an invoice';
    }
    case OrderDetailedStatus.InvoicePosted: {
      return 'This order is already on an invoice that has been posted';
    }
    default: {
      return exhaustive(detailedStatus);
    }
  }
};

const SaveButton = ({
  onSubmit,
  isEditMode,
  rebillMode = false,
  hideSaveButton = false,
}: SaveButtonProps) => {
  const { control } = useFormContext<OrderFormValues>();
  const detailedStatus = useWatch({ control, name: 'detailedStatus' });
  const [justFinalizedCharges, setJustFinalizedCharges] = useOrderFormStore(
    (state) => [state.justFinalizedCharges, state.setJustFinalizedCharges],
    shallow,
  );

  const shouldShowWarningModal =
    detailedStatus === OrderDetailedStatus.ChargesFinalized ||
    detailedStatus === OrderDetailedStatus.OnInvoice ||
    detailedStatus === OrderDetailedStatus.InvoicePosted;

  const { disabledIfNoAccess } = useOrderFormEditAccess();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [noRedirectOnWarningModal, setNoRedirectOnWarningModal] =
    useState(false);

  const handleSave = async ({
    calledFromWarningModal,
    noRedirect,
  }: {
    calledFromWarningModal?: boolean;
    noRedirect: boolean;
  }) => {
    setIsSubmitting(true);
    if (
      shouldShowWarningModal &&
      calledFromWarningModal !== true &&
      !justFinalizedCharges
    ) {
      setShowWarningModal(true);
      setNoRedirectOnWarningModal(noRedirect);
      setIsSubmitting(false);
    } else {
      await onSubmit({
        noRedirect,
      });
      setIsSubmitting(false);
      setJustFinalizedCharges(false);
    }
  };

  useHotkeys(['Meta+s', 'Control+s'], async (e) => {
    e.preventDefault();
    handleSave({ noRedirect: true });
  });

  const saveCopy = rebillMode ? 'Save and rebill' : 'Save';
  const saveAndExitCopy = 'Save and exit';

  return (
    <>
      {shouldShowWarningModal && (
        <Dialog
          open={showWarningModal}
          onClose={() => {
            setShowWarningModal(false);
          }}
        >
          <Box>
            <DialogTitle>
              Warning: {getWarningModalStatusCopy(detailedStatus)}
            </DialogTitle>
            <DialogActions>
              <Button
                variant="outlined"
                onClick={() => {
                  setShowWarningModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={disabledIfNoAccess}
                onClick={async () => {
                  setShowWarningModal(false);
                  handleSave({
                    calledFromWarningModal: true,
                    noRedirect: noRedirectOnWarningModal,
                  });
                }}
              >
                {saveCopy}
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      )}
      <Tooltip
        title={
          rebillMode
            ? 'This will move the order to a new invoice for the desired contact and charge off the existing order'
            : null
        }
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
          {isEditMode && !hideSaveButton && (
            <Button
              startIcon={
                isSubmitting && (
                  <CircularProgress
                    data-testid={ORDER_PAGE_SAVE_BUTTON_LOADING_SPINNER_TEST_ID}
                    size={10}
                  />
                )
              }
              sx={{ float: 'right' }}
              variant="outlined"
              disabled={isSubmitting || disabledIfNoAccess}
              data-testid={ORDER_PAGE_SAVE_BUTTON_TEST_ID}
              onClick={() => {
                handleSave({ noRedirect: true });
              }}
            >
              {saveCopy}
            </Button>
          )}
          {!rebillMode && (
            <Button
              startIcon={
                isSubmitting && (
                  <CircularProgress
                    data-testid={
                      ORDER_PAGE_SAVE_AND_EXIT_BUTTON_LOADING_SPINNER_TEST_ID
                    }
                    size={10}
                  />
                )
              }
              sx={{ float: 'right' }}
              variant="contained"
              disabled={isSubmitting || disabledIfNoAccess}
              data-testid={ORDER_PAGE_SAVE_AND_EXIT_BUTTON_TEST_ID}
              onClick={() => {
                handleSave({ noRedirect: false });
              }}
            >
              {saveAndExitCopy}
            </Button>
          )}
        </Box>
      </Tooltip>
    </>
  );
};

export default React.memo(SaveButton);
