/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { isNil } from 'lodash';
import { type LegFormField } from 'shared/types';
import { objectKeys } from 'tsafe';
import { v4 } from 'uuid';
import { type ErrorResponse } from '../../../common/form/formValidators';
import {
  AppointmentTextStatus,
  type LegUpsertInput,
  type OrderSegmentType,
  type PickupOrDelivery,
  type Segment,
  type StandardShipmentFragment,
  StandardStopType,
  StopStatus,
  type StopType,
  type LegCreateInput,
} from '../../../generated/graphql';
import type { RootState } from '../../../redux/store';
import {
  upsertAddressForStop,
  upsertConsigneeAddressForStop,
  upsertShipperAddressForStop,
  upsertTransferAddressForStop,
} from '../../addresses/redux/addresses-values-thunks';
import {
  upsertConsigneeContactPersonForStop,
  upsertContactPersonForStop,
  upsertShipperContactPersonForStop,
} from '../../contact-persons/redux/contact-person-values-thunks';
import { upsertOneDriverValues } from '../../routes/redux/driver-values-slice';
import { upsertOneEquipmentValues } from '../../routes/redux/equipment-values-slice';
import { addOneRouteValues } from '../../routes/redux/routes-values-slice';
import {
  selectStandardShipmentValuesById,
  updateStandardShipmentValues,
} from '../../shipments/redux/standard-shipments-values-slice';
import {
  addOneStopValues,
  upsertOneStopValues,
} from '../../stops/redux/stop-values-slice';
import {
  type StopErrorsResponse,
  createStandardStopCreateInput,
  createStopUpsertInput,
  getStopErrors,
  shallowCopyStop,
} from '../../stops/redux/stop-values-thunks';
import { addOneLeg, selectLegValuesById, upsertLeg } from './leg-values-slice';

export type LegFormSchema = Omit<
  { [key in keyof Required<LegFormField>]: boolean },
  'uuid' | '__typename' | 'shipmentUuid' | 'previousStopAttempts'
>;

const legFieldIsRequired: LegFormSchema = {
  legDriverMaps: false,
  endStopUuid: false,
  driver: false,
  isLocal: true,
  status: false,
  miles: false,
  shipment: false,
  drivers: false,
  endStop: false,
  flatRate: false,
  name: false,
  scheduledDate: false,
  tags: false,
};

type ValidateLegArgs = {
  legId: string;
  segment?: Segment;
  orderSegmentType?: OrderSegmentType;
  shouldValidateStopAddress?: boolean;
  isQuote?: boolean;

  forceValidateAddress?: boolean;
};

export type LegErrorsResponse = {
  isValid: boolean;
  errors: ErrorResponse[];
  endStopErrorsResponse: StopErrorsResponse | null;
};

export const getLegErrors = createAsyncThunk<
  LegErrorsResponse,
  ValidateLegArgs,
  { state: RootState }
>('legs/getLegErrors', async (args, thunkAPI): Promise<LegErrorsResponse> => {
  const {
    legId,
    segment,
    orderSegmentType,
    shouldValidateStopAddress,
    isQuote,
    forceValidateAddress,
  } = args;
  const legValues = selectLegValuesById(thunkAPI.getState(), legId);
  const legErrorsResponse: LegErrorsResponse = {
    endStopErrorsResponse: null,
    errors: [],
    isValid: true,
  };
  if (isNil(legValues)) {
    return legErrorsResponse;
  }

  await Promise.all(
    objectKeys(legFieldIsRequired).map(async (field) => {
      // eslint-disable-next-line default-case
      switch (field) {
        case 'endStopUuid': {
          const stopErrorsResponse = await thunkAPI
            .dispatch(
              getStopErrors({
                stopUuid: legValues.endStopUuid,
                shipmentUuid: legValues.shipmentUuid,
                segment,
                orderSegmentType,
                shouldValidateStopAddress:
                  shouldValidateStopAddress === true && isQuote !== true,
                forceValidateAddress,
              }),
            )
            .unwrap();
          legErrorsResponse.isValid =
            legErrorsResponse.isValid && stopErrorsResponse.isValid;
          legErrorsResponse.endStopErrorsResponse = stopErrorsResponse;
          break;
        }
        case 'driver': {
          break;
        }
      }
    }),
  );

  return legErrorsResponse;
});

type CreateStandardLegCreateInputArg = {
  legUuid: string;
  pickupOrDelivery: PickupOrDelivery | undefined;
};

export const createStandardLegCreateInput = createAsyncThunk<
  LegCreateInput,
  CreateStandardLegCreateInputArg,
  { state: RootState }
>(
  'legs/createStandardLegCreateInput',
  async (arg, thunkAPI): Promise<LegCreateInput> => {
    const legValues = selectLegValuesById(thunkAPI.getState(), arg.legUuid);
    if (isNil(legValues)) {
      throw new Error(`Invalid leg uuid: ${arg.legUuid}`);
    }

    return {
      endStopCreateInput: await thunkAPI
        .dispatch(
          createStandardStopCreateInput({
            stopUuid: legValues.endStopUuid,
            // eslint-disable-next-line custom-rules/no-pickup-or-delivery-use
            pickupOrDelivery: arg.pickupOrDelivery,
          }),
        )
        .unwrap(),
      status: undefined,
      miles: legValues.miles,
    };
  },
);

type LegAndShipmentUuidArg = {
  segment: Segment;
  shipmentUuid: string;
  legUuid: string;
  ordinal?: number;
};

// Moved here to avoid circular import
export const addLegToShipment = createAsyncThunk<
  void,
  LegAndShipmentUuidArg,
  { state: RootState }
>('legs/addLegToShipment', async (arg, thunkAPI): Promise<void> => {
  // TODO: Unified shipment entity
  const shipment = await selectStandardShipmentValuesById(
    thunkAPI.getState(),
    arg.shipmentUuid,
  );
  if (shipment === undefined) {
    throw new Error(`Invalid shipment uuid: ${arg.shipmentUuid}`);
  }
  const { legUuid } = arg;
  thunkAPI.dispatch(
    updateStandardShipmentValues({
      id: arg.shipmentUuid,
      changes: {
        firstLegUuid: legUuid,
      },
    }),
  );
});

/**
 *
 * @property endStopUuid - Provide a default ending stop, the fields are shallow copied
 */
type CreateNewLegArg = {
  segment: Segment;
  shipmentUuid: string;
  endStopUuid?: string;
  ordinal?: number;
  standardStopType?: StandardStopType;
  defaultManuallyConfirmed?: boolean | undefined;
  stopType: StopType;
};

export const createNewLeg = createAsyncThunk<
  string,
  CreateNewLegArg,
  { state: RootState }
>('legs/createNewLeg', async (arg, thunkAPI) => {
  const initialLegUuid = v4();
  const endStopUuid: string = isNil(arg.endStopUuid)
    ? thunkAPI.dispatch(
        addOneStopValues({
          addressUuid: undefined,
          appointmentTime: undefined,
          contactPersonUuid: undefined,
          appointmentTextStatus: AppointmentTextStatus.NotSent,
          serviceDate: undefined,
          standardStopType:
            arg.standardStopType ?? StandardStopType.Residential,
          appointmentManuallyConfirmed: arg.defaultManuallyConfirmed,
          uuid: v4(),
          stopType: arg.stopType,
          status: StopStatus.NotArrived,
          inboundMethod: undefined,
          outboundMethod: undefined,
        }),
      ).payload.uuid
    : await thunkAPI
        .dispatch(shallowCopyStop({ stopUuid: arg.endStopUuid }))
        .unwrap();

  thunkAPI.dispatch(
    addOneLeg({
      shipmentUuid: arg.shipmentUuid,
      driver: undefined,
      endStopUuid,
      isLocal: true,
      status: undefined,
      uuid: initialLegUuid,
      miles: undefined,
    }),
  );

  await thunkAPI.dispatch(
    addLegToShipment({
      shipmentUuid: arg.shipmentUuid,
      legUuid: initialLegUuid,
      segment: arg.segment,
      ordinal: arg.ordinal,
    }),
  );

  return initialLegUuid;
});

type CreateLegUpsertInputArg = {
  legUuid: string;
};

export const createLegUpsertInput = createAsyncThunk<
  LegUpsertInput,
  CreateLegUpsertInputArg,
  { state: RootState }
>(
  'legs/createLegUpsertInput',
  async (arg, thunkAPI): Promise<LegUpsertInput> => {
    const leg = selectLegValuesById(thunkAPI.getState(), arg.legUuid);
    if (isNil(leg)) {
      throw new Error(
        `[createLegUpsertInput] invalid leg uuid: ${arg.legUuid}`,
      );
    }
    return {
      endStopUpsertInput: await thunkAPI
        .dispatch(createStopUpsertInput({ stopUuid: leg.endStopUuid }))
        .unwrap(),
      uuid: leg.uuid,
      miles: leg.miles,
    };
  },
);

export const upsertLegsForShipment = createAsyncThunk<
  string[],
  {
    shipment: StandardShipmentFragment;
    isDuplicate: boolean;
  },
  { state: RootState }
>('legs/upsertLegsForShipment', async (arg, thunkAPI): Promise<string[]> => {
  const { shipment } = arg;
  const legUuids = await Promise.all(
    shipment.legs.map(async (leg) => {
      const stop = leg.endStop;
      let contactPersonUuid = stop.contactPerson?.uuid ?? v4();
      let shipperContactPersonUuid = stop.shipperContactPerson?.uuid ?? v4();
      let consigneeContactUuid = stop.consigneeContactPerson?.uuid ?? v4();
      let addressUuid = stop.address.uuid;
      let shipperAddressUuid = stop.shipperAddress?.uuid ?? v4();
      let consigneeAddressUuid = stop.consigneeAddress?.uuid ?? v4();
      let transferAddressUuid = stop.transferAddress?.uuid ?? null;
      if (arg.isDuplicate) {
        contactPersonUuid = v4();
        shipperContactPersonUuid = v4();
        consigneeContactUuid = v4();
        addressUuid = v4();
        shipperAddressUuid = v4();
        consigneeAddressUuid = v4();
        transferAddressUuid = isNil(transferAddressUuid) ? null : v4();
      }
      await thunkAPI.dispatch(
        upsertContactPersonForStop({ stop, contactPersonUuid }),
      );
      await thunkAPI.dispatch(
        upsertShipperContactPersonForStop({ stop, shipperContactPersonUuid }),
      );
      await thunkAPI.dispatch(
        upsertConsigneeContactPersonForStop({ stop, consigneeContactUuid }),
      );
      await thunkAPI.dispatch(upsertAddressForStop({ stop, addressUuid }));
      await thunkAPI.dispatch(
        upsertShipperAddressForStop({ stop, shipperAddressUuid }),
      );
      await thunkAPI.dispatch(
        upsertConsigneeAddressForStop({ stop, consigneeAddressUuid }),
      );
      if (!isNil(transferAddressUuid)) {
        await thunkAPI.dispatch(
          upsertTransferAddressForStop({ stop, transferAddressUuid }),
        );
      }
      const firstDriver = stop.routeSlot?.route?.drivers[0];
      await thunkAPI.dispatch(
        upsertOneDriverValues({
          uuid: firstDriver?.uuid ?? '',
          firstName: firstDriver?.firstName,
          lastName: firstDriver?.lastName,
        }),
      );
      await Promise.all(
        (stop.routeSlot?.route?.equipments ?? []).map((equipment) =>
          thunkAPI.dispatch(
            upsertOneEquipmentValues({
              uuid: equipment.uuid,
              name: equipment.name,
            }),
          ),
        ),
      );
      const route = stop.routeSlot?.route;
      if (route !== undefined && route !== null) {
        const routeSlotUuids = route.slots.map((slot) => slot.uuid);
        const driverUuids = route.drivers.map((driver) => driver.uuid);
        const equipmentUuids = route.equipments.map(
          (equipment) => equipment.uuid,
        );
        thunkAPI.dispatch(
          addOneRouteValues({
            uuid: route.uuid,
            date: route.date,
            name: route.name,
            routeSlotUuids,
            driverUuids,
            equipmentUuids,
          }),
        );
      }

      await thunkAPI.dispatch(
        upsertOneStopValues({
          addressUuid,
          appointmentTime: stop.appointmentTime,
          appointmentManuallyConfirmed: stop.appointmentManuallyConfirmed,
          consigneeAddressUuid,
          consigneeContactPersonUuid: consigneeContactUuid,
          routeUuid: stop.routeSlot?.route?.uuid,
          driverCompletedAt: arg.isDuplicate
            ? undefined
            : stop.driverCompletedAt,
          arrivedAt: arg.isDuplicate ? undefined : stop.arrivedAt,
          completedAt: arg.isDuplicate ? undefined : stop.completedAt,
          endAppointmentTime: stop.endAppointmentTime,
          appointmentCallStatus: stop.appointmentCallStatus,
          contactPersonUuid,
          appointmentTextStatus: stop.appointmentTextStatus,
          notes: stop.notes,
          serviceDate: stop.serviceDate,
          standardStopType: stop.standardStopType ?? undefined,
          stopType: stop.stopType ?? null,
          uuid: stop.uuid,
          shipperAddressUuid,
          shipperContactPersonUuid,
          specialInstructions: stop.specialInstructions,
          driverUuid: firstDriver?.uuid,
          status: stop.status,
          routeSlotUuid: stop.routeSlot?.uuid,
          equipmentUuid: stop.routeSlot?.route?.equipments[0]?.uuid,
          appointmentRequired: stop.appointmentRequired,
          proofOfDeliverySignee: stop.proofOfDeliverySignee,
          inboundMethod: stop.inboundMethod,
          expectedInboundArrivalDate: stop.expectedInboundArrivalDate,
          outboundMethod: stop.outboundMethod,
          incomingCarrier: stop.incomingCarrier,
          outboundCarrier: stop.outboundCarrier,
          destinationAirport: stop.destinationAirport,
          isSpecial: stop.isSpecial ?? false,
          terminalUuid: stop.terminal?.uuid,
          transferAddressUuid,
        }),
      );
      const legUuid = arg.isDuplicate ? v4() : leg.uuid;
      await thunkAPI.dispatch(
        upsertLeg({
          shipmentUuid: shipment.uuid,
          isLocal: false,
          uuid: legUuid,
          endStopUuid: leg.endStop.uuid,
          status: undefined,
          miles: leg.miles ?? 0,
        }),
      );
      return legUuid;
    }),
  );

  return legUuids;
});
