import {
  Box,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { type FunctionComponent, type ReactNode } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import ButtonLink from '../../../../common/components/buttons/button-link';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import {
  PackageSpecStatus,
  PermissionResource,
  usePackageSpecsMinimalQuery,
} from '../../../../generated/graphql';

type RowProps = {
  readonly name: string;
  readonly status: PackageSpecStatus;
  readonly openButton?: ReactNode;
};

const Row: FunctionComponent<RowProps> = ({ name, status, openButton }) => (
  <TableRow
    hover
    sx={{
      '&:last-child td, &:last-child th': { border: 0 },
      '& button': {
        visibility: 'hidden',
      },
      '&:hover button': {
        visibility: 'visible',
      },
    }}
  >
    <TableCell>
      <Stack flexDirection="row" gap={2} alignItems="center">
        <Typography>{name}</Typography>
        {status !== PackageSpecStatus.Active && (
          <Typography color="text.secondary" fontSize="small">
            ({status})
          </Typography>
        )}
      </Stack>
    </TableCell>
    <TableCell align="right">{openButton}</TableCell>
  </TableRow>
);

const PackageSpecsTable: FunctionComponent = () => {
  const { userPermissions } = useUserRoles();
  const { canRead, canWrite } = getPermissionsFlags(
    userPermissions,
    // TODO: Replace with a package-spec-specific permission.
    PermissionResource.CompanyEquipment,
  );
  const { data, loading } = usePackageSpecsMinimalQuery();

  const packageSpecsArchivedLast = data?.packageSpecs.packageSpecs
    .slice()
    // We don't need to do name comparisons here because sort does a stable sort.
    .sort((a, b) => {
      if (a.status === PackageSpecStatus.Archived) {
        return b.status === PackageSpecStatus.Archived ? 0 : 1;
      }
      if (b.status === PackageSpecStatus.Archived) {
        return -1;
      }
      return 0;
    });

  if (loading) {
    return (
      <Box p={3}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">
              <ButtonLink
                href="/management/package-types/add"
                variant="contained"
                disabled={!canWrite}
              >
                Add package type
              </ButtonLink>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {packageSpecsArchivedLast?.map(({ id, name, status }) => (
            <Row
              key={id}
              name={name}
              status={status}
              openButton={
                canRead || canWrite ? (
                  <ButtonLink
                    variant="contained"
                    href={`/management/package-types/${id}`}
                  >
                    {canWrite ? 'Edit' : 'View'}
                  </ButtonLink>
                ) : null
              }
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PackageSpecsTable;
