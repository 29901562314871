import { createAsyncThunk } from '@reduxjs/toolkit';
import { isNil } from 'lodash';
import {
  convertBillingMethodToInputBillingMethod,
  convertInputBillingMethodToBillingMethod,
} from 'shared/billing';

import { safeDivide } from 'shared/math';
import { objectKeys } from 'tsafe';
import { v4 } from 'uuid';
import apolloClient from '../../../apollo-client';
import {
  type ErrorResponse,
  type ValidationResponse,
} from '../../../common/form/formValidators';
import {
  ContactDefaultSurchargeDocument,
  type ContactDefaultSurchargeQuery,
  type ContactDefaultSurchargeQueryVariables,
  CustomChargeBillingMethod,
  FreightBillingMethod,
  type FreightChargeCreateInput,
  type FreightChargeUpdateInput,
  type FreightChargeUpsertInput,
  FuelBillingMethod,
  FuelProfilesDocument,
  type FuelProfilesQuery,
  type FuelProfilesQueryVariables,
  type MeQuery,
  PickupOrDelivery,
  type StandardShipmentFragment,
} from '../../../generated/graphql';
import type { RootState } from '../../../redux/store';
import {
  selectFuelChargeById,
  updateFuelCharge,
  upsertFuelCharge,
} from '../../fuel-charges/redux/fuel-charges-values-slice';
import { createInitialFuelCharge } from '../../fuel-charges/redux/fuel-charges-values-thunks';
import { getFuelProfileDateRangeToUse } from '../../orders/components/order-form/components/charges/utils';
import { addCustomCharge } from '../../orders/redux/custom-charges-values-slice';
import { selectAllStandardOrderValues } from '../../orders/redux/standard/standard-orders-values-slice';
import {
  selectStandardShipmentValuesById,
  updateStandardShipmentValues,
} from '../../shipments/redux/standard-shipments-values-slice';
import { upsertFreightChargeErrors } from './freight-charges-errors-slice';
import {
  addFreightCharge,
  freightChargeFieldIsRequired,
  selectFreightChargeById,
  updateFreightCharge,
  upsertFreightCharge,
} from './freight-charges-values-slice';

type InitNewFreightChargeArg = {
  shipmentUuid: string;
  defaultFuelSurcharge?: number | undefined;
  defaultFuelSurchargeBillingMethod?: FuelBillingMethod | undefined;
};

export const createInitialFreightCharge = createAsyncThunk<
  string,
  InitNewFreightChargeArg,
  { state: RootState }
>('freightCharges/createInitialFreightCharge ', async (arg, thunkAPI) => {
  const newFreightChargeUuid = v4();
  const fuelChargeUuid = await thunkAPI
    .dispatch(
      createInitialFuelCharge({
        defaultFuelSurcharge: arg.defaultFuelSurcharge,
        defaultFuelSurchargeBillingMethod:
          arg.defaultFuelSurchargeBillingMethod,
      }),
    )
    .unwrap();

  thunkAPI.dispatch(
    addFreightCharge({
      quantity: 1,
      billingMethod: FreightBillingMethod.FlatRate,
      description: undefined,
      fuelChargeId: fuelChargeUuid,
      rate: 0,
      shipmentUuid: arg.shipmentUuid,
      uuid: newFreightChargeUuid,
      tariffUuid: undefined,
    }),
  );

  return newFreightChargeUuid;
});

type ValidateFreightChargeThunkArg = {
  freightChargeId: string;
};

export type CsvDataType = {
  pro: string;
  fuelCharge: number;
  accessorials: Array<{ name: string; amount: number }>;
  split: number;
};

type ParseCsvArgs = {
  csvData: CsvDataType[];
};

export type FreightChargeErrorsResponse = {
  isValid: boolean;
  errors: ErrorResponse[];
};

export const parseCsvData = createAsyncThunk<
  FreightChargeErrorsResponse,
  ParseCsvArgs,
  { state: RootState }
>(
  'freightCharges/parseCsv',
  async (arg, thunkAPI): Promise<FreightChargeErrorsResponse> => {
    const { csvData } = arg;
    const allStandardOrderValues = selectAllStandardOrderValues(
      thunkAPI.getState(),
    );
    let lastNineButNotAllDigitsOfCsvEqualsSystem = false;
    for (const row of csvData) {
      const matchingStandardOrder = allStandardOrderValues.find((values) => {
        if (row.pro === values.shipperBillOfLadingNumber?.trim()) {
          return true;
        }
        // If the last nine digits on the CSV equals the entire reference number, record that.
        if (row.pro.slice(1) === values?.shipperBillOfLadingNumber?.trim()) {
          lastNineButNotAllDigitsOfCsvEqualsSystem = true;
          return true;
        }
        return false;
      });
      if (isNil(matchingStandardOrder)) {
        continue;
      }
      const shipmentUuid = matchingStandardOrder?.shipmentUuids?.[0];
      const shipment = selectStandardShipmentValuesById(
        thunkAPI.getState(),
        shipmentUuid ?? '',
      );
      if (
        lastNineButNotAllDigitsOfCsvEqualsSystem &&
        shipment?.pickupOrDelivery !== PickupOrDelivery.Pickup
      ) {
        continue;
      }
      const accessorialTotal = row.accessorials.reduce(
        (acc, curr) => acc + curr.amount,
        0,
      );
      const { fuelCharge } = row;
      const freightChargeTotal = row.split - accessorialTotal - fuelCharge;
      const freightCharge = selectFreightChargeById(
        thunkAPI.getState(),
        shipment?.freightChargeId ?? '',
      );
      thunkAPI.dispatch(
        updateFreightCharge({
          id: shipment?.freightChargeId ?? '',
          changes: {
            rate: Number.parseFloat(freightChargeTotal.toFixed(2)),
          },
        }),
      );
      if (fuelCharge > 0) {
        thunkAPI.dispatch(
          updateFuelCharge({
            id: freightCharge?.fuelChargeId ?? '',
            changes: {
              type: FuelBillingMethod.FlatRate,
              flatRate: fuelCharge,
            },
          }),
        );
      } else {
        thunkAPI.dispatch(
          updateFuelCharge({
            id: freightCharge?.fuelChargeId ?? '',
            changes: {
              type: FuelBillingMethod.None,
            },
          }),
        );
      }
      const customChargeList: string[] = [];
      for (const accessorial of row.accessorials) {
        const newChargeId = v4();
        thunkAPI.dispatch(
          addCustomCharge({
            uuid: newChargeId,
            rate: accessorial.amount,
            quantity: 1,
            isLocal: true,
            billingMethod: CustomChargeBillingMethod.AdHoc,
            name: accessorial.name,
            adhocChargeName: undefined,
            accessorialId: undefined,
            zoneBasedAccessorialZoneId: undefined,
            zoneBasedAccessorialChargeGroupId: undefined,
            specialAccessorialTariffZoneId: undefined,
            specialAccessorialChargeGroupId: undefined,
            accessorialRangeId: undefined,
            description: undefined,
            total: undefined,
          }),
        );
        customChargeList.push(newChargeId);
      }
      thunkAPI.dispatch(
        updateStandardShipmentValues({
          id: shipmentUuid ?? '',
          changes: {
            customChargeIds: customChargeList,
          },
        }),
      );
    }
    return { isValid: true, errors: [] };
  },
);

export const getFreightChargeErrors = createAsyncThunk<
  FreightChargeErrorsResponse,
  ValidateFreightChargeThunkArg,
  { state: RootState }
>(
  'freightCharges/getFreightChargeErrors',
  async (arg, thunkAPI): Promise<FreightChargeErrorsResponse> => {
    const freightChargeValues = selectFreightChargeById(
      thunkAPI.getState(),
      arg.freightChargeId,
    );
    if (isNil(freightChargeValues)) {
      throw new Error(`Could not find freight charge: ${arg.freightChargeId}`);
    }
    const shipmentValues = selectStandardShipmentValuesById(
      thunkAPI.getState(),
      freightChargeValues.shipmentUuid ?? '',
    );
    const freightChargeErrorsResponse: FreightChargeErrorsResponse = {
      errors: [],
      isValid: true,
    };

    if (
      shipmentValues?.pickupOrDelivery === PickupOrDelivery.Recovery ||
      shipmentValues?.pickupOrDelivery === PickupOrDelivery.Transfer
    ) {
      return freightChargeErrorsResponse;
    }

    await Promise.all(
      objectKeys(freightChargeFieldIsRequired).map(async (key) => {
        let validationResponse: ValidationResponse | null = null;
        let userFacingFieldName = '';
        const isRequired = freightChargeFieldIsRequired[key];
        const value = freightChargeValues[key];
        const requiredValExists =
          isRequired && !isNil(value) && !Number.isNaN(value);

        // Currently leaving all fields optional so the user can create an order without filling out charges
        switch (key) {
          case 'uuid': {
            break;
          }
          case 'billingMethod': {
            break;
          }
          case 'description': {
            break;
          }
          case 'quantity': {
            break;
          }
          case 'fuelChargeId': {
            break;
          }
          case 'rate': {
            userFacingFieldName = 'Rate';
            validationResponse = {
              valid: requiredValExists ?? false,
              explanation: `${key} is required`,
            };
            break;
          }
          case 'tariffUuid': {
            break;
          }
          default: {
            break;
          }
        }

        // Currently doesn't validate any freight charge fields
        if (validationResponse?.valid === true) {
          thunkAPI.dispatch(
            upsertFreightChargeErrors({
              uuid: arg.freightChargeId,
              [key]: undefined,
            }),
          );
        } else if (validationResponse?.valid === false) {
          freightChargeErrorsResponse.isValid = false;
          freightChargeErrorsResponse.errors.push({
            field: userFacingFieldName,
            validationResponse,
          });
          thunkAPI.dispatch(
            upsertFreightChargeErrors({
              uuid: arg.freightChargeId,
              [key]: validationResponse?.explanation,
            }),
          );
        }
      }),
    );

    return freightChargeErrorsResponse;
  },
);

type CreateFreightChargeCreateInputArg = {
  freightChargeId: string;
};

export const createFreightChargeCreateInput = createAsyncThunk<
  FreightChargeCreateInput,
  CreateFreightChargeCreateInputArg,
  { state: RootState }
>(
  'freightCharges/createFreightChargeCreateInput',
  async (arg, thunkAPI): Promise<FreightChargeCreateInput> => {
    const freightChargeValues = selectFreightChargeById(
      thunkAPI.getState(),
      arg.freightChargeId,
    );
    if (isNil(freightChargeValues)) {
      throw new Error(`Could not find freight charge: ${arg.freightChargeId}`);
    }

    const fuelChargeValues = selectFuelChargeById(
      thunkAPI.getState(),
      freightChargeValues.fuelChargeId ?? '',
    );

    return {
      quantity: freightChargeValues.quantity,
      billingMethod:
        convertInputBillingMethodToBillingMethod(
          freightChargeValues.billingMethod,
        ) ?? FreightBillingMethod.FlatRate,
      description: freightChargeValues.description,
      fuelChargeCreateInput:
        !isNil(fuelChargeValues) &&
        !isNil(fuelChargeValues.surchargeRate) &&
        !Number.isNaN(fuelChargeValues.surchargeRate) &&
        String(fuelChargeValues.surchargeRate) !== 'NaN'
          ? {
              surchargeRate: Number.parseFloat(
                String(fuelChargeValues.surchargeRate ?? 0),
              ),
              description: fuelChargeValues.description,
              type: fuelChargeValues.type ?? FuelBillingMethod.FlatRate,
              flatRate: fuelChargeValues.flatRate,
            }
          : undefined,
      rate: freightChargeValues.rate ?? 0,
      tariffUuid: freightChargeValues.tariffUuid ?? undefined,
      settlementFlatRate: freightChargeValues.settlementFlatRate,
      settlementPercentageRate: freightChargeValues.settlementPercentageRate,
    };
  },
);

type CreateFreightChargeUpdateInputArg = {
  freightChargeId: string;
};

export const createFreightChargeUpdateInput = createAsyncThunk<
  FreightChargeUpdateInput,
  CreateFreightChargeUpdateInputArg,
  { state: RootState }
>(
  'freightCharges/createFreightChargeUpdateInput',
  async (arg, thunkAPI): Promise<FreightChargeUpdateInput> => {
    const freightChargeValues = selectFreightChargeById(
      thunkAPI.getState(),
      arg.freightChargeId,
    );
    if (isNil(freightChargeValues)) {
      throw new Error(`Could not find freight charge: ${arg.freightChargeId}`);
    }

    const fuelChargeValues = selectFuelChargeById(
      thunkAPI.getState(),
      freightChargeValues.fuelChargeId ?? '',
    );

    let fuelChargeUpdateInput;
    if (!isNil(fuelChargeValues)) {
      fuelChargeUpdateInput = {
        uuid: fuelChargeValues.uuid,
        surchargeRate: Number.parseFloat(
          String(fuelChargeValues.surchargeRate ?? 0),
        ),
        description: fuelChargeValues.description,
        type: fuelChargeValues.type,
        flatRate: fuelChargeValues.flatRate,
      };
    }
    return {
      quantity: freightChargeValues.quantity,
      billingMethod: convertInputBillingMethodToBillingMethod(
        freightChargeValues.billingMethod,
      ),
      description: freightChargeValues.description,
      fuelChargeUpdateInput,
      rate: freightChargeValues.rate,
      uuid: freightChargeValues.uuid,
      tariffUuid: freightChargeValues.tariffUuid,
      settlementFlatRate: freightChargeValues.settlementFlatRate,
      settlementPercentageRate: freightChargeValues.settlementPercentageRate,
    };
  },
);

type CreateFreightChargeUpsertInputArg = {
  freightChargeId: string;
};

export const createFreightChargeUpsertInput = createAsyncThunk<
  FreightChargeUpsertInput,
  CreateFreightChargeUpsertInputArg,
  { state: RootState }
>(
  'freightCharges/createFreightChargeUpsertInput',
  async (arg, thunkAPI): Promise<FreightChargeUpsertInput> => {
    const freightChargeValues = selectFreightChargeById(
      thunkAPI.getState(),
      arg.freightChargeId,
    );
    if (isNil(freightChargeValues)) {
      throw new Error(`Could not find freight charge: ${arg.freightChargeId}`);
    }
    if (isNil(freightChargeValues.rate)) {
      throw new Error(
        `Freight charge rate is undefined: ${arg.freightChargeId}`,
      );
    }

    const fuelChargeValues = selectFuelChargeById(
      thunkAPI.getState(),
      freightChargeValues.fuelChargeId ?? '',
    );
    let fuelChargeUpsertInput;
    if (
      (isNil(fuelChargeValues?.surchargeRate) ||
        String(fuelChargeValues?.surchargeRate ?? '') === 'NaN') &&
      (isNil(fuelChargeValues?.flatRate) ||
        String(fuelChargeValues?.flatRate ?? '') === 'NaN')
    ) {
      fuelChargeUpsertInput = null;
    } else if (!isNil(fuelChargeValues)) {
      fuelChargeUpsertInput = {
        uuid: fuelChargeValues.uuid,
        surchargeRate: Number.parseFloat(
          String(fuelChargeValues.surchargeRate ?? 0),
        ),
        description: fuelChargeValues.description,
        type: fuelChargeValues.type ?? FuelBillingMethod.FlatRate,
        flatRate: fuelChargeValues.flatRate,
        settlementFlatRate: fuelChargeValues.settlementFlatRate,
        settlementPercentageRate: fuelChargeValues.settlementPercentageRate,
      };
    }
    return {
      quantity: freightChargeValues.quantity,
      billingMethod:
        convertInputBillingMethodToBillingMethod(
          freightChargeValues.billingMethod,
        ) ?? FreightBillingMethod.FlatRate,
      description: freightChargeValues.description,
      rate: freightChargeValues.rate,
      uuid: freightChargeValues.uuid,
      tariffUuid: freightChargeValues.tariffUuid ?? undefined,
      fuelChargeUpsertInput,
      settlementFlatRate: freightChargeValues.settlementFlatRate,
      settlementPercentageRate: freightChargeValues.settlementPercentageRate,
    };
  },
);

export const upsertFreightChargesForShipment = createAsyncThunk<
  string | undefined,
  {
    shipment: StandardShipmentFragment;
    companyData: MeQuery;
    isDuplicate: boolean;
    billingPartyUuid: string;
  },
  { state: RootState }
>(
  'freightCharges/upsertFreightChargesForShipment',
  async (arg, thunkAPI): Promise<string | undefined> => {
    const { shipment, companyData, billingPartyUuid } = arg;
    const stop = shipment.legs[0]?.endStop;
    const fuelChargeId = arg.isDuplicate
      ? v4()
      : (shipment.freightCharge?.fuelCharge?.uuid ?? v4());
    let freightChargeId = arg.isDuplicate
      ? v4()
      : (shipment.freightCharge?.uuid ?? v4());
    const billingMethod = convertBillingMethodToInputBillingMethod(
      shipment.freightCharge?.billingMethod,
      shipment.freightCharge?.tariff,
    );
    if (isNil(shipment.freightCharge)) {
      freightChargeId = await thunkAPI
        .dispatch(
          createInitialFreightCharge({
            shipmentUuid: shipment.uuid,
            defaultFuelSurcharge:
              companyData.me?.company.configuration?.defaultFuelSurcharge ??
              undefined,
            defaultFuelSurchargeBillingMethod:
              companyData.me?.company.configuration
                ?.defaultFuelSurchargeBillingMethod,
          }),
        )
        .unwrap();
    } else {
      await thunkAPI.dispatch(
        upsertFreightCharge({
          quantity: shipment.freightCharge.quantity ?? undefined,
          billingMethod: billingMethod ?? undefined,
          description: shipment.freightCharge.description ?? undefined,
          fuelChargeId,
          rate: shipment.freightCharge.rate,
          uuid: freightChargeId,
          shipmentUuid: shipment.uuid,
          tariffUuid: shipment.freightCharge.tariff?.uuid ?? undefined,
          settlementFlatRate: shipment.freightCharge.settlementFlatRate,
          settlementPercentageRate:
            shipment.freightCharge.settlementPercentageRate,
        }),
      );
      const fuelProfileData = await apolloClient.query<
        FuelProfilesQuery,
        FuelProfilesQueryVariables
      >({
        query: FuelProfilesDocument,
      });
      const contactDefaultFuelSurcharge = await apolloClient.query<
        ContactDefaultSurchargeQuery,
        ContactDefaultSurchargeQueryVariables
      >({
        query: ContactDefaultSurchargeDocument,
        variables: {
          uuid: billingPartyUuid,
        },
      });
      const fuelProfileDateRangeToUse = getFuelProfileDateRangeToUse({
        contactUuid: billingPartyUuid,
        fuelProfiles: fuelProfileData.data.fuelProfiles,
        defaultContactFuelSurcharge:
          contactDefaultFuelSurcharge.data.contact.__typename ===
          'CustomerContactEntity'
            ? contactDefaultFuelSurcharge.data.contact.defaultFuelSurcharge
            : undefined,
        serviceDate: stop?.serviceDate,
        appointmentDate: shipment.fields?.deliveryDate,
        stopCompletedDate: stop?.completedAt,
        tariff: undefined,
      });
      let fuelProfileSurchargeRate;
      let fuelProfileFlatRate;
      if (
        shipment.freightCharge.fuelCharge?.type ===
          FuelBillingMethod.AutoCalculate &&
        !isNil(fuelProfileDateRangeToUse)
      ) {
        fuelProfileFlatRate = isNil(fuelProfileDateRangeToUse.flatRateUsdCents)
          ? undefined
          : safeDivide(fuelProfileDateRangeToUse.flatRateUsdCents, 100);
        fuelProfileSurchargeRate =
          fuelProfileDateRangeToUse.surchargeRate ?? undefined;
      }
      await thunkAPI.dispatch(
        upsertFuelCharge({
          uuid: fuelChargeId,
          surchargeRate:
            shipment.freightCharge.fuelCharge?.type ===
              FuelBillingMethod.AutoCalculate ||
            shipment.freightCharge.fuelCharge?.type ===
              FuelBillingMethod.Percentage
              ? fuelProfileSurchargeRate
              : shipment.freightCharge.fuelCharge?.surchargeRate,
          description:
            shipment.freightCharge.fuelCharge?.description ?? undefined,
          flatRate:
            shipment.freightCharge.fuelCharge?.type ===
            FuelBillingMethod.AutoCalculate
              ? fuelProfileFlatRate
              : shipment.freightCharge.fuelCharge?.flatRate,
          type:
            shipment.freightCharge.fuelCharge?.type ??
            FuelBillingMethod.FlatRate,
          settlementFlatRate:
            shipment.freightCharge.fuelCharge?.settlementFlatRate,
          settlementPercentageRate:
            shipment.freightCharge.fuelCharge?.settlementPercentageRate,
        }),
      );
    }
    return freightChargeId;
  },
);
