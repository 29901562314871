import WarningIcon from '@mui/icons-material/Warning';
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { type EntityId } from '@reduxjs/toolkit';
import { isNil } from 'lodash';
import pluralize from 'pluralize';
import { useEffect, useRef, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import { shallow } from 'zustand/shallow';
import useUserRoles from '../../../../../common/react-hooks/use-user-roles';
import { PermissionResource } from '../../../../../generated/graphql';
import useGlobalStore from '../../../../../layouts/dashboard/global-store';
import { useAppSelector } from '../../../../../redux/hooks';
import {
  type StandardOrderValues,
  selectStandardOrdersByIds,
} from '../../../redux/standard/standard-orders-values-slice';
import EdiSelectedOrderAccordion from './edi-selected-order-accordion';
import MergeEdiOrdersModal from './merge-edi-order-modal';

const EdiSelectedOrdersDetails = ({
  selectedOrderUuids,
  orderUuidsWithPendingChanges,
  orderUuidsBeingEdited,
  loadingOrders,
  handleAcceptOrders,
  handleAcceptSingleOrder,
  handleMergeOrder,
  setOrderHasChanges,
  updateOrderBeingEdited,
  openRejectOrderModalForUuid,
}: {
  readonly selectedOrderUuids: string[];
  readonly orderUuidsWithPendingChanges: string[];
  readonly orderUuidsBeingEdited: string[];
  readonly loadingOrders: boolean;
  readonly handleAcceptOrders: () => void;
  readonly handleAcceptSingleOrder: (orderUuid: string) => void;
  readonly handleMergeOrder: (
    orderToMerge: StandardOrderValues,
  ) => Promise<void>;
  readonly setOrderHasChanges: (orderUuid: string, hasChanges: boolean) => void;
  readonly updateOrderBeingEdited: (
    orderUuid: string,
    isEditing: boolean,
  ) => void;
  readonly openRejectOrderModalForUuid: (orderUuid: string) => void;
}) => {
  const theme = useTheme();
  const { userPermissions } = useUserRoles();
  const [loadingReduxOrder] = useGlobalStore(
    (state) => [state.loadingReduxOrder],
    shallow,
  );
  const { canWrite: canWriteEdiApiOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.EdiApiOrders,
  );

  const ref = useRef<HTMLDivElement>(null);
  const ordersValues = useAppSelector((state) =>
    selectStandardOrdersByIds(state, selectedOrderUuids as EntityId[]),
  );
  const ediCancelledOrders = ordersValues
    .filter((order) => order.hasEdiCancellation)
    .map((order) => order.name);
  const [mergeOrdersModalValues, setMergeOrdersModalValues] = useState<
    StandardOrderValues[] | undefined
  >(undefined);

  const handleCreateDuplicate = (
    orderToCreateDuplicate: StandardOrderValues,
  ) => {
    handleAcceptSingleOrder(orderToCreateDuplicate.uuid);
  };

  const handleClickAccept = () => {
    const selectedOrdersThatNeedMerge = ordersValues.filter((order) => {
      return (order.ordersWithSameBillOfLadingNumber?.length ?? 0) > 0;
    });
    if (selectedOrdersThatNeedMerge.length > 0) {
      // open a modal that gives them the option to merge/duplicate
      setMergeOrdersModalValues(selectedOrdersThatNeedMerge);
    } else {
      handleAcceptOrders();
    }
  };

  const handleMerge = async (orderToMerge: StandardOrderValues) => {
    await handleMergeOrder(orderToMerge);
    setMergeOrdersModalValues(
      mergeOrdersModalValues?.filter((val) => val.uuid !== orderToMerge.uuid),
    );
  };

  useEffect(() => {
    if (selectedOrderUuids.length > 0) {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [selectedOrderUuids.length]);

  return (
    // <Stack flex="1" alignItems="flex-start" gap="15px">
    <Stack
      width="100%"
      flex="1"
      alignItems="flex-start"
      gap="10px"
      sx={{
        overflowY: 'scroll',
        borderBottom: '0.5px solid grey',
        borderTop: '0.5px solid grey',
        padding: '10px',
        height: '100%',
      }}
    >
      {selectedOrderUuids.map((uuid) => {
        return (
          <EdiSelectedOrderAccordion
            key={uuid}
            orderUuid={uuid}
            orderHasChanges={orderUuidsWithPendingChanges.includes(uuid)}
            setOrderHasChanges={(hasChanges: boolean) => {
              setOrderHasChanges(uuid, hasChanges);
            }}
            updateOrderBeingEdited={(isEditing: boolean) => {
              updateOrderBeingEdited(uuid, isEditing);
            }}
            openRejectOrderModalForUuid={openRejectOrderModalForUuid}
          />
        );
      })}
      <div ref={ref} />
      {selectedOrderUuids.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignSelf: 'center',
            position: 'fixed',
            bottom: '5vh',
          }}
        >
          <Tooltip
            placement="top"
            title={
              orderUuidsBeingEdited.length > 0
                ? `Please save ${orderUuidsBeingEdited.length} ${pluralize('order', orderUuidsBeingEdited.length)} to accept all pending EDI orders`
                : null
            }
          >
            <span>
              <Button
                variant="contained"
                disabled={
                  loadingOrders ||
                  !canWriteEdiApiOrders ||
                  loadingReduxOrder ||
                  orderUuidsBeingEdited.length > 0
                }
                startIcon={loadingReduxOrder && <CircularProgress size={20} />}
                onClick={handleClickAccept}
              >
                Accept all orders ({selectedOrderUuids.length})
              </Button>
            </span>
          </Tooltip>
          {ediCancelledOrders.length > 0 && (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                mt: '5px',
              }}
            >
              <WarningIcon
                sx={{
                  fontSize: '15px',
                  color: theme.palette.paleRed.main,
                  mr: '5px',
                }}
              />
              <Typography
                sx={{ fontSize: '15px', color: theme.palette.paleRed.main }}
              >
                The followed orders have already been cancelled via EDI:{' '}
                {ediCancelledOrders.join(',')}
              </Typography>
            </Box>
          )}
        </Box>
      )}
      <MergeEdiOrdersModal
        open={!isNil(mergeOrdersModalValues)}
        orders={mergeOrdersModalValues ?? []}
        handleMerge={handleMerge}
        handleCreateDuplicate={handleCreateDuplicate}
        onClose={() => {
          setMergeOrdersModalValues(undefined);
        }}
      />
    </Stack>
  );
};

export default EdiSelectedOrdersDetails;
