import { Box, Stack, Typography } from '@mui/material';
import { isNil } from 'lodash';
import pluralize from 'pluralize';
import * as React from 'react';
import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import {
  type OrderProratedWithFragment,
  type OutstandingOrderFragmentFragment,
} from '../../../../generated/graphql';
import theme from '../../../../theme';
import useBillingReviewStore from '../../billing-review-store';
import useBillingReviewActions from '../../hooks/use-billing-review-actions';
import OutstandingOrdersSidebarListItem, {
  OutstandingOrdersSidebarListItemComponent,
} from './outstanding-orders-sidebar-list-item';

type SidebarListProratedOrderItemButtonProps = {
  readonly isSelected: boolean;
  readonly isDisabled: boolean;
  readonly order: OrderProratedWithFragment;
  readonly parentOrderUuid: string;
};

const SidebarListProratedOrderItemButton = ({
  isSelected,
  isDisabled,
  order,
  parentOrderUuid,
}: SidebarListProratedOrderItemButtonProps) => {
  const [queriedOrder] = useBillingReviewStore(
    (state) => [
      state.searchedOrders
        .flatMap((o) => o.queriedProratedOrdersWith)
        .find((o) => o?.uuid === order.uuid) ??
        state.outstandingOrdersInPage
          .flatMap((o) => o.queriedProratedOrdersWith)
          .find((o) => o?.uuid === order.uuid),
    ],
    shallow,
  );
  const { fetchProratedOrderCacheFirst } = useBillingReviewActions();

  const fetchData = async (orderUuidToFetch: string) => {
    await fetchProratedOrderCacheFirst({
      orderUuid: orderUuidToFetch,
      parentOrderUuid,
    });
  };

  useEffect(() => {
    // when we query an order that bypasses the cache
    // it resets the queried orders.
    // so we need to refetch the order
    if (isNil(queriedOrder)) {
      fetchData(order.uuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.uuid, isNil(queriedOrder)]);

  if (isNil(queriedOrder)) {
    return (
      <OutstandingOrdersSidebarListItemComponent
        isSelected={isSelected}
        isDisabled={isDisabled}
        // when clicked, and the order is still not in queried orders, fetch it.
        onClick={() => {
          fetchData(order.uuid);
        }}
        hawb={order.standardOrderFields?.shipperBillOfLadingNumber ?? undefined}
        name={order.name}
        status={order.status}
        isSelectingOrdersToSend={false}
        isSelectedToSendV2={false}
        onToggleOrder={() => {}}
      />
    );
  }

  return (
    <OutstandingOrdersSidebarListItem
      isSelected={isSelected}
      isDisabled={isDisabled}
      order={queriedOrder}
    />
  );
};

type OutstandingOrdersSidebarListProratedOrderGroupProps = {
  readonly firstOrder: {
    order: OutstandingOrderFragmentFragment;
    isDisabled: boolean;
    isSelected: boolean;
  };
  readonly otherOrdersInGroup: Array<{
    order: OrderProratedWithFragment;
    isDisabled: boolean;
    isSelected: boolean;
  }>;
};

const OutstandingOrdersSidebarListProratedOrderGroup = ({
  firstOrder,
  otherOrdersInGroup,
}: OutstandingOrdersSidebarListProratedOrderGroupProps) => {
  return (
    <Stack
      sx={{
        border: `0.5px solid ${theme.palette.grey[500]}`,
        boxShadow: theme.shadows[2],
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={1}
        sx={{
          backgroundColor: theme.palette.grey[100],
        }}
      >
        <Stack direction="row" spacing={1}>
          <Typography
            variant="body2"
            fontWeight="medium"
            color={theme.palette.grey[600]}
          >
            Consolidation
          </Typography>
          <Typography variant="body2" color={theme.palette.grey[600]}>
            Pro-rate
          </Typography>
        </Stack>

        <Typography variant="body2" color={theme.palette.grey[600]}>
          {otherOrdersInGroup.length + 1}{' '}
          {pluralize('order', otherOrdersInGroup.length + 1)}
        </Typography>
      </Box>
      <OutstandingOrdersSidebarListItem
        key={firstOrder.order.uuid}
        isSelected={firstOrder.isSelected}
        isDisabled={firstOrder.isDisabled}
        order={firstOrder.order}
      />
      {otherOrdersInGroup.map((o) => (
        <SidebarListProratedOrderItemButton
          key={o.order.uuid}
          isSelected={o.isSelected}
          isDisabled={o.isDisabled}
          order={o.order}
          parentOrderUuid={firstOrder.order.uuid}
        />
      ))}
    </Stack>
  );
};

export default React.memo(OutstandingOrdersSidebarListProratedOrderGroup);
