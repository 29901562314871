import { Button, CircularProgress, Box } from '@mui/material';
import { type Dispatch, type SetStateAction } from 'react';
import {
  SameDayDispatchRoutesDocument,
  useCalculateRouteEtasMutation,
} from '../../../generated/graphql';

const CalculateEtaRouteButton = ({
  routeUuids,
  setShowSuccessfulCalculate,
}: {
  readonly routeUuids: string[];
  readonly setShowSuccessfulCalculate: Dispatch<SetStateAction<boolean>>;
}) => {
  const [calculateEtas, { loading }] = useCalculateRouteEtasMutation({
    refetchQueries: [SameDayDispatchRoutesDocument],
  });
  const onCalculateEtas = async () => {
    const res = await calculateEtas({ variables: { uuids: routeUuids } });
    if ((res.data?.calculateRouteEtas.length ?? -1) >= 0) {
      setShowSuccessfulCalculate(true);
    }
  };

  return (
    <Button variant="contained" disabled={loading} onClick={onCalculateEtas}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '5px',
          alignItems: 'center',
        }}
      >
        {loading && <CircularProgress size={15} />}
        Calculate ETAs
      </Box>
    </Button>
  );
};

export default CalculateEtaRouteButton;
