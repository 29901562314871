import { type Position } from 'geojson';
import { Layer, Source } from 'react-map-gl';

const Square = ({
  id,
  coordinates,
  color,
}: {
  readonly id: string;
  readonly coordinates: Position[][];
  readonly color: string;
}) => {
  return (
    <Source
      type="geojson"
      data={{
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Polygon',
          coordinates,
        },
      }}
    >
      <Layer
        id={id}
        type="fill"
        paint={{
          'fill-color': color,
          'fill-opacity': 0.8,
        }}
      />
    </Source>
  );
};

export default Square;
