import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { capitalCase, sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import { isEmpty, isNil, sumBy, truncate } from 'lodash';
import { filterNotNil } from 'shared/array';
import { getWeightUnitText } from '../../../common/utils/utils';
import { INBOUND_STOP_IDX } from '../../orders/components/order-form/components/constants';
import { StopType } from '../../orders/components/order-form/forms/stop-type';
import { dummyStopTypes } from '../../orders/lib/stop-types';
import { type OrderAuthoSheetData } from '../utils';
import DisplayAddress from './display-address';
import { OrderCharges } from './reusable-pdf-components';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-regular-webfont.ttf',
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-bold-webfont.ttf',
      fontWeight: 700,
    },
  ],
});
Font.registerHyphenationCallback((word) => [word]);

const styles = StyleSheet.create({
  page: {
    padding: 24,
    fontFamily: 'Roboto',
    fontSize: '11px',
    flexDirection: 'column',
    gap: '10px',
  },
  largeText: {
    fontSize: '30px',
    fontWeight: 'bold',
  },
  titleBold: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: '12px',
  },
  headerText: {
    fontSize: '12px',
    fontWeight: 'bold',
  },
  subHeaderTextSize: {
    fontSize: '10px',
  },
  subHeaderText: {
    fontSize: '10px',
    fontWeight: 'bold',
  },
  metadataText: {
    fontSize: '8px',
    color: 'gray',
  },
  refNumText: {
    fontWeight: 'bold',
  },
  labelText: {
    fontWeight: 'bold',
    width: '20%',
  },
  infoText: {
    width: '80%',
  },
  smallLabelText: {
    fontWeight: 'bold',
    width: '30%',
  },
  smallInfoText: {
    width: '70%',
  },
  formLabelText: {
    fontWeight: 'bold',
    width: '25%',
    textAlign: 'right',
  },
  formLineTop: {
    borderTopWidth: 1,
    borderTopColor: 'black',
  },
  formLineBottom: {
    borderBottomWidth: 1,
    borderBottomColor: 'black',
  },
  rowWithPadding: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '5px',
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  rowFlexStart: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  rowSpaceBetween: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
  },
  colSpaceBetween: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  colWithPadding: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '5px',
  },
  outerCell: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cell: {
    borderWidth: 1,
    borderColor: 'black',
    padding: '5px',
    width: '100%',
  },
  footer: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
  },
});

const GeneratedQuoteAuthoSheetPdf = ({
  order,
}: {
  readonly order: OrderAuthoSheetData;
}) => {
  const serviceDate =
    order.inboundShipment?.serviceDate ?? order.outboundShipment?.serviceDate;
  const shipments = [order.inboundShipment, order.outboundShipment];
  const otherShipments = filterNotNil([
    isNil(order.orderChargesShipment)
      ? null
      : { ...order.orderChargesShipment, stopType: 'Order charges' },
    isNil(order.lineHaulShipment)
      ? null
      : { ...order.lineHaulShipment, stopType: 'Line haul' },
  ]);
  const chargeableShipments = filterNotNil([
    ...shipments.filter(
      (sh) => !dummyStopTypes.includes(sh?.stopType ?? StopType.None),
    ),
    ...otherShipments,
  ]).filter((sh) => sh.hideFromBilling !== true);

  const totalPieces = sumBy(order.packages, 'quantity');
  const totalWeight = sumBy(order.packages, 'weight');
  const weightUnitText = getWeightUnitText({
    useKilograms: order.useKilograms,
  });

  return (
    <Document title={`Order Autho Sheet - ${order?.quoteNumber}`}>
      <Page size="LETTER" style={styles.page}>
        <View wrap={false} style={styles.outerCell}>
          <View style={[styles.cell]}>
            <View style={styles.rowSpaceBetween}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Text style={styles.titleBold}>{order.companyName}</Text>
                <DisplayAddress address={order.companyAddress} />
                <View style={styles.row}>
                  <Text style={{ width: '130px' }}>
                    <Text style={styles.refNumText}>Phone: </Text>
                    {order.companyPhone}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'right',
                  alignItems: 'flex-end',
                }}
              >
                <Text>
                  <Text style={styles.refNumText}>Printed at: </Text>
                  {dayjs().format('MM/DD/YYYY HH:mm')}
                </Text>
                <Text style={styles.largeText}>QUOTE</Text>
              </View>
            </View>
          </View>
          <View style={[styles.cell]}>
            <View style={styles.row}>
              <Text style={{ fontSize: '12px' }}>
                <Text style={styles.refNumText}>Customer: </Text>
                {order.contactDisplayName}
              </Text>
            </View>
            <View style={styles.row}>
              <View style={[styles.col, { width: '50%' }]}>
                <Text style={{ fontSize: '12px' }}>
                  <Text style={styles.refNumText}>Quote #: </Text>
                  {order.quoteNumber}
                </Text>
              </View>
              <View style={[styles.col, { width: '50%' }]}>
                <Text>
                  <Text style={styles.refNumText}>Service date: </Text>
                  {isNil(serviceDate)
                    ? '-'
                    : dayjs(serviceDate).format('MM/DD/YY')}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.row}>
            {shipments.map((stop, idx) => (
              <View
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
                style={[styles.cell, { borderBottomWidth: 0, marginBottom: 0 }]}
              >
                <View style={styles.rowSpaceBetween}>
                  <Text
                    style={[styles.headerText, { textDecoration: 'underline' }]}
                  >
                    {capitalCase(stop?.stopType ?? 'Stop')}
                  </Text>
                  <Text>
                    <Text style={styles.refNumText}>
                      {idx === INBOUND_STOP_IDX ? 'Orig: ' : 'Dest: '}
                    </Text>
                    {stop?.terminalCode?.toUpperCase()}
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.labelText}>Address: </Text>
                  <View style={styles.infoText}>
                    <DisplayAddress address={stop?.address} />
                  </View>
                </View>
                <View style={styles.row}>
                  <Text style={styles.labelText}>Phone: </Text>
                  <Text style={{ width: '30%' }}>{stop?.contactPhone}</Text>
                  <Text style={styles.labelText}>Contact: </Text>
                  <Text style={{ width: '30%' }}>
                    {stop?.contactPersonName}
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text>
                    <Text style={styles.refNumText}>
                      Special instructions:{' '}
                    </Text>
                    {truncate(stop?.instructions ?? '', {
                      length: 200,
                    })}
                  </Text>
                </View>
              </View>
            ))}
          </View>
          <View style={styles.row}>
            {shipments.map((stop, idx) => (
              <View
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
                style={[
                  styles.cell,
                  { borderTopWidth: 0, marginTop: 0, paddingTop: 5 },
                ]}
              >
                <View style={styles.rowSpaceBetween}>
                  <Text>
                    <Text style={styles.refNumText}>Appt: </Text>
                    {stop?.appointmentDateString}
                  </Text>
                  <Text>
                    <Text style={styles.refNumText}>Deadline: </Text>
                    {isNil(stop?.deadlineDate)
                      ? ''
                      : dayjs(stop?.deadlineDate).format('MM/DD')}
                  </Text>
                </View>
              </View>
            ))}
          </View>
          <View style={[styles.cell]}>
            <View style={styles.row}>
              <Text style={styles.headerText}>Packages</Text>
            </View>
            <View style={[styles.rowSpaceBetween, { paddingBottom: 5 }]}>
              <View style={[styles.col, { width: '50%' }]}>
                <View style={styles.row}>
                  <Text style={styles.smallLabelText}>Total pieces:</Text>
                  <Text style={styles.smallInfoText}>{totalPieces}</Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.smallLabelText}>Total weight:</Text>
                  <Text style={styles.smallInfoText}>
                    {totalWeight} {weightUnitText}
                  </Text>
                </View>
              </View>
              <View style={[styles.col]}>
                <View style={[styles.row, { textAlign: 'right' }]}>
                  <Text>
                    {!isEmpty(order.serviceName) && (
                      <Text style={styles.refNumText}>Service: </Text>
                    )}
                    {sentenceCase(order.serviceName ?? '')}
                  </Text>
                </View>
              </View>
            </View>
            {order.packages.map((pkg, idx) => (
              <View
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
                style={styles.rowWithPadding}
              >
                <View style={{ width: '30%' }}>
                  <Text style={styles.subHeaderTextSize}>
                    {pkg.quantity} @ {pkg.length ?? '-'} x {pkg.width ?? '-'} x{' '}
                    {pkg.height ?? '-'}
                  </Text>
                </View>
                <View style={{ width: '30%' }}>
                  <Text style={styles.subHeaderTextSize}>
                    Weight: {pkg.weight} {weightUnitText}s
                  </Text>
                </View>
                <View style={{ width: '40%' }}>
                  <Text style={styles.subHeaderTextSize}>
                    {pkg.description}
                  </Text>
                </View>
              </View>
            ))}
          </View>
          {OrderCharges({ chargeableShipments, styles })}
        </View>
        <Text fixed style={styles.footer}>
          {
            '*****QUOTE ONLY*****\nRates are based on information provided. Any changes may affect the rate.'
          }
        </Text>
      </Page>
    </Document>
  );
};
export default GeneratedQuoteAuthoSheetPdf;
