import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Button, Stack } from '@mui/material';
import { flatten, isEmpty, isNil } from 'lodash';
import { LngLat, LngLatBounds } from 'mapbox-gl';
import React, { memo, useCallback, useEffect, useState } from 'react';
import ReactMapGL, {
  type MapRef,
  NavigationControl,
  type ViewStateChangeEvent,
} from 'react-map-gl';
import useLocalStorageState from 'use-local-storage-state';
import { COLORS } from '../../../common/constants';
import { lightenHexColor } from '../../../common/utils/colors';
import { type RouteFragment } from '../../../generated/graphql';
import useDispatchStore from '../dispatch-store';
import { MapRoutePathType } from '../types/routes';
import { calculateBoundsFromSlotsToShowAllMarkers } from '../utils';
import Line from './components/line';
import StopMarker from './components/stop-marker';

const OptimizedRouteMap = ({
  routes,
}: {
  readonly routes: RouteFragment[];
}) => {
  const [mapRef, setMapRef] = useState<MapRef | null>(null);
  const mapRoutePathType = useDispatchStore((state) => state.mapRoutePathType);
  const [viewState, setViewState] = useLocalStorageState(
    'optimized_route_map_initial_view_state',
    {
      defaultValue: {
        longitude: 0,
        latitude: 0,
        zoom: 0,
      },
    },
  );
  const getRouteColor = (routeUuid: string, lighten = false) => {
    if (lighten) {
      return lightenHexColor(
        COLORS[routes.findIndex((r) => r.uuid === routeUuid) % COLORS.length] ??
          'black',
        0.2,
      );
    }
    return (
      COLORS[routes.findIndex((r) => r.uuid === routeUuid) % COLORS.length] ??
      'black'
    );
  };

  const handleMapMove = useCallback(
    (e: ViewStateChangeEvent) => {
      setViewState(e.viewState);
    },
    [setViewState],
  );

  const handleMapRef = useCallback((node: MapRef) => {
    setMapRef(node);
  }, []);

  const resetZoom = () => {
    if (!isNil(mapRef)) {
      const stops = flatten(
        routes?.map((route) => route.slots.map((slot) => slot.stops[0])),
      );
      if (!isEmpty(stops)) {
        const bounds = calculateBoundsFromSlotsToShowAllMarkers(stops ?? []);
        const sw: LngLat = new LngLat(bounds.bottomRightX, bounds.bottomRightY);
        const ne = new LngLat(bounds.topLeftX, bounds.topLeftY);
        const boundsObject = new LngLatBounds(sw, ne);
        mapRef.getMap().fitBounds(boundsObject, {
          padding: {
            left: 100,
            right: 100,
            top: 100,
            bottom: 100,
          },
        });
        mapRef.getMap().resize({ innerWidth: 1000, innerHeight: 1000 });
      }
    }
  };

  useEffect(() => {
    resetZoom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef]);

  return (
    <ReactMapGL
      ref={handleMapRef}
      latitude={viewState.latitude}
      longitude={viewState.longitude}
      zoom={viewState.zoom}
      mapStyle="mapbox://styles/mapbox/streets-v9"
      mapboxAccessToken={import.meta.env.VITE_MAPBOX_ACCESS_TOKEN}
      doubleClickZoom={false}
      scrollZoom={false}
      onMove={handleMapMove}
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack sx={{ p: 1 }}>
          <Button
            variant="contained"
            startIcon={<RestartAltIcon />}
            onClick={() => {
              resetZoom();
            }}
          >
            Reset Zoom
          </Button>
        </Stack>
      </Stack>
      <NavigationControl position="bottom-right" />
      {routes.map((route) => {
        return route.slots.map((slot, idx) => {
          const stop = slot.stops[0];

          if (!isNil(stop)) {
            return (
              <StopMarker
                key={stop.uuid}
                color={getRouteColor(route.uuid)}
                stop={stop}
                ordinal={idx + 1}
              />
            );
          }
          // eslint-disable-next-line react/jsx-no-useless-fragment
          return <></>;
        });
      })}
      {mapRoutePathType !== MapRoutePathType.NONE &&
        routes.map((route) => {
          const coordinates: number[][] = [];

          for (const slot of route.slots) {
            const address = slot.stops[0]?.address;
            const latitude = address?.latitude;
            const longitude = address?.longitude;
            if (!isNil(latitude) && !isNil(longitude)) {
              coordinates.push([longitude, latitude]);
            }
          }

          return (
            <Line
              key={route.uuid}
              id={route.uuid}
              coordinates={coordinates}
              color={getRouteColor(route.uuid)}
            />
          );
        })}
    </ReactMapGL>
  );
};

export default memo(OptimizedRouteMap);
