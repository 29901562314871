import { exhaustive } from 'shared/switch';
import {
  DispatchTableField,
  type FindStopsFiltersInput,
  OrderStatus,
  StopStatus,
  StopType,
} from '../../../generated/graphql';

export const DEFAULT_UNASSIGNED_STOP_QUERY_VARIABLES: FindStopsFiltersInput = {
  statuses: [StopStatus.NotArrived, StopStatus.Arrived],
  orderStatuses: [
    OrderStatus.Created,
    OrderStatus.InProgress,
    OrderStatus.OnHold,
  ],
  isAssigned: false,
};

export const DEFAULT_UNASSIGNED_STOP_TABLE_FIELDS: DispatchTableField[] = [
  DispatchTableField.OrderName,
  DispatchTableField.StopType,
  DispatchTableField.ServiceDate,
  DispatchTableField.City,
  DispatchTableField.Address,
  DispatchTableField.Appointment,
  DispatchTableField.ServiceLevel,
  DispatchTableField.Terminal,
];

export enum StopsTab {
  AllStops = 'All',
  Recoveries = 'Recoveries',
  Transfers = 'Transfers',
  Pickups = 'Pickups',
  Deliveries = 'Deliveries',
  DueToday = 'Due today',
}

export const inboundStopTypes = [
  StopType.Pickup,
  StopType.Recovery,
  StopType.PartnerCarrierDropoff,
];
export const outboundStopTypes = [
  StopType.Delivery,
  StopType.Transfer,
  StopType.PartnerCarrierPickup,
];

// These must have different values than DispatchTableField in the backend or AGGrid will break since there cannot be duplicate fields.
export enum StopFilterField {
  CITY = 'City', // Commercial or residential
  ADDRESS = 'Address',
  ORDER_NAME = 'Order name',
  ADDRESS_TYPE = 'Address type filter', // Commercial or residential
  APPOINTMENT_DATE = 'Appointment date',
  APPOINTMENT_REQUIRED = 'Requires Appointment',
  // Poorly named, this means appointment confirmed
  APPOINTMENT_SCHEDULED = 'Appointment scheduled filter',
  BUSINESS_DIVISION = 'Company division',
  CUSTOMER = 'Contact', // Customer
  CREATED_DATE = 'Created date',
  DEADLINE_DATE = 'Deadline date',
  HAS_APPOINTMENT_DATE = 'Has appointment date',
  HAS_APPOINTMENT = 'Has appointment',
  HAS_APPOINTMENT_OPTIONS_PROPOSED = 'Options proposed',
  ON_HAND = 'On hand',
  ORDER_STATUS = 'Order status',
  IS_PICKED = 'Is picked',
  IS_REFUSED = 'Is refused',
  IS_SPECIAL = 'Is special',
  TAG = 'Tag',
  SERVICE_LEVEL = 'Service',
  PREVIOUSLY_ATTEMPTED = 'Previously attempted',
  TRANSFER_PENDING = 'Transfer pending',
  STOP_TYPE = 'Stop details', // Pickup / delivery
  ROUTE_NAME = 'Route name filter',
  CAN_DISPATCH = 'Can dispatch',
}

export const getDispatchHeaderCopy = (
  field: DispatchTableField,
  {
    ffDemoLoadManagement = false,
  }: {
    ffDemoLoadManagement?: boolean;
  } = {},
) => {
  switch (field) {
    case DispatchTableField.Eta: {
      return 'ETA';
    }
    case DispatchTableField.State: {
      return 'State';
    }
    case DispatchTableField.OrderName: {
      if (ffDemoLoadManagement) {
        return 'Auth';
      }
      return 'HAWB';
    }
    case DispatchTableField.OrderNumber: {
      return 'Order Name';
    }
    case DispatchTableField.OrderStatus: {
      return 'Status';
    }
    case DispatchTableField.Mawb: {
      return 'MAWB';
    }
    case DispatchTableField.RefNumber: {
      return 'Ref Numbers';
    }
    case DispatchTableField.City: {
      return 'City';
    }
    case DispatchTableField.DueDate: {
      return 'Due Date';
    }
    case DispatchTableField.ServiceDate: {
      return 'Service Date';
    }
    case DispatchTableField.Address: {
      return 'Address';
    }
    case DispatchTableField.AddressType: {
      return 'Address Type';
    }
    case DispatchTableField.Customer: {
      return 'Customer';
    }
    case DispatchTableField.Consignee: {
      return 'Address Name';
    }
    case DispatchTableField.Appointment: {
      return 'Appointment';
    }
    case DispatchTableField.AppointmentScheduled: {
      return 'Appointment Confirmed';
    }
    case DispatchTableField.AppointmentRequired: {
      return 'Appointment Required';
    }
    case DispatchTableField.StopType: {
      return 'Stop Details';
    }
    case DispatchTableField.InboundMethod: {
      return 'Inbound Method';
    }
    case DispatchTableField.OutboundMethod: {
      return 'Outbound Method';
    }
    case DispatchTableField.InboundTerminal: {
      return 'Inbound Terminal';
    }
    case DispatchTableField.OutboundTerminal: {
      return 'Outbound Terminal';
    }
    case DispatchTableField.InboundZip: {
      return 'Inbound Zip';
    }
    case DispatchTableField.OutboundZip: {
      return 'Outbound Zip';
    }
    case DispatchTableField.InboundCity: {
      return 'Inbound City';
    }
    case DispatchTableField.OutboundCity: {
      return 'Outbound City';
    }
    case DispatchTableField.InboundPaperwork: {
      return 'Inbound Paperwork';
    }
    case DispatchTableField.OutboundPaperwork: {
      return 'Outbound Paperwork';
    }
    case DispatchTableField.ServiceLevel: {
      return 'Service Level';
    }
    case DispatchTableField.ServiceTime: {
      return 'Service Time';
    }
    case DispatchTableField.BusinessDivision: {
      return 'Business Division';
    }
    case DispatchTableField.Special: {
      return 'Special';
    }
    case DispatchTableField.RouteName: {
      return 'Route Name';
    }
    case DispatchTableField.Terminal: {
      return 'Terminal';
    }
    case DispatchTableField.TotalCharges: {
      return 'Total Charges';
    }
    case DispatchTableField.InboundName: {
      return 'Inbound';
    }
    case DispatchTableField.OutboundName: {
      return 'Outbound';
    }
    case DispatchTableField.SpecialInstructions: {
      return 'Special Instructions';
    }
    case DispatchTableField.OrderNotes: {
      return 'Order Notes';
    }
    case DispatchTableField.PieceCount: {
      return 'Piece Count';
    }
    case DispatchTableField.Dims: {
      return 'Dims';
    }
    case DispatchTableField.VehicleTypeName: {
      return 'Vehicle Type';
    }
    case DispatchTableField.Weight: {
      return 'Weight';
    }
    case DispatchTableField.PrimaryConsignee: {
      return 'Primary Consignee';
    }
    case DispatchTableField.ZipCode: {
      return 'Zip';
    }
    case DispatchTableField.Tags: {
      return 'Tags';
    }
    default: {
      return exhaustive(field);
    }
  }
};
