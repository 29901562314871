import { joiResolver } from '@hookform/resolvers/joi';
import joi from 'joi';
import { useForm } from 'react-hook-form';
import { type BaseStorageUnitFormValues } from '../types';

export type CreateStorageUnitFormValues = BaseStorageUnitFormValues;
const useCreateStorageUnitForm = () => {
  const schema = joi.object({
    name: joi
      .string()
      .required()
      .messages({ 'any.required': 'Name is required' }),
    unitOfMeasure: joi
      .string()
      .required()
      .messages({ 'any.required': 'Unit of Measure is required' }),
  });
  return useForm<CreateStorageUnitFormValues>({
    resolver: joiResolver(schema),
  });
};

export default useCreateStorageUnitForm;
