import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  type EntityId,
  type EntityState,
} from '@reduxjs/toolkit';
import _ from 'lodash';
import { type CustomChargeFormField } from 'shared/types';
import { v4 } from 'uuid';
import { CustomChargeBillingMethod } from '../../../generated/graphql';
import type { RootState } from '../../../redux/store';

type CustomChargeValuesStoreState = CustomChargeFormField;

const customChargesValuesAdapter =
  createEntityAdapter<CustomChargeValuesStoreState>({
    selectId: (customCharge) => customCharge.uuid,
  });

export const customChargesValuesSlice = createSlice({
  name: 'customChargesValues',
  initialState: customChargesValuesAdapter.getInitialState(),
  reducers: {
    addCustomCharge: customChargesValuesAdapter.addOne,
    updateCustomCharge: customChargesValuesAdapter.updateOne,
    deleteCustomCharge: customChargesValuesAdapter.removeOne,
    updateCustomCharges: customChargesValuesAdapter.updateMany,
    loadedCustomCharges: customChargesValuesAdapter.setAll,
    upsertCustomCharge: customChargesValuesAdapter.upsertOne,
  },
});

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectById: selectCustomChargeById,
  selectIds: selectCustomChargeIds,
  selectAll: selectCustomCharges,
  // Pass in a selector that returns the posts slice of state
} = customChargesValuesAdapter.getSelectors(
  (state: RootState) => state.orderFormCustomChargesValues,
);

const customChargeValuesSelector = (state: RootState) =>
  state.orderFormCustomChargesValues;

export const selectCustomChargesByIds = createSelector(
  customChargeValuesSelector,
  (state: RootState, ids: EntityId[]) => ids,
  (state: EntityState<CustomChargeFormField>, entityIds: EntityId[]) => {
    const entityResults: CustomChargeFormField[] = [];
    for (const id of entityIds) {
      const match = customChargesValuesAdapter
        .getSelectors()
        .selectById(state, id);
      if (!_.isNil(match)) {
        entityResults.push(match);
      }
    }
    return entityResults;
  },
);

type CustomChargeSchema = {
  [k in keyof Required<CustomChargeFormField>]: boolean;
};

export const customChargeFieldIsRequired: CustomChargeSchema = {
  description: false,
  quantity: true,
  rate: true,
  uuid: true,
  billingMethod: true,
  adhocChargeName: false,
  fuelSurchargePercentageRate: false,
  accessorialId: true,
  total: false,
  name: false,
  isAutoApplied: false,
  isLocal: false,
  zoneBasedAccessorialZoneId: false,
  zoneBasedAccessorialChargeGroupId: false,
  zoneBasedAccessorialMatrixItemUuid: false,
  specialAccessorialTariffZoneId: false,
  specialAccessorialChargeGroupId: false,
  specialAccessorialMatrixItemUuid: false,
  accessorialRangeId: false,
  accessorialRange: false,
  settlementFlatRate: false,
  settlementPercentageRate: false,
  authoCode: false,
};

export const {
  addCustomCharge,
  updateCustomCharge,
  loadedCustomCharges,
  deleteCustomCharge,
  upsertCustomCharge,
} = customChargesValuesSlice.actions;

export const createInitialCustomCharge = createAsyncThunk<
  string,
  void,
  { state: RootState }
>('orders/createInitialCustomCharges', async (unusedArg, thunkAPI) => {
  const newCustomChargeUuid = v4();
  const initialCustomCharges: CustomChargeFormField = {
    accessorialId: undefined,
    adhocChargeName: undefined,
    description: undefined,
    isLocal: true,
    name: '',
    total: undefined,
    zoneBasedAccessorialChargeGroupId: undefined,
    zoneBasedAccessorialZoneId: undefined,
    specialAccessorialChargeGroupId: undefined,
    specialAccessorialTariffZoneId: undefined,
    accessorialRangeId: undefined,
    uuid: newCustomChargeUuid,
    rate: 0,
    quantity: 1,
    billingMethod: CustomChargeBillingMethod.Accessorial,
  };
  thunkAPI.dispatch(addCustomCharge(initialCustomCharges));
  return newCustomChargeUuid;
});

export default customChargesValuesSlice.reducer;
