import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { isNil } from 'lodash';
import { type Dispatch, type SetStateAction } from 'react';
import { type CsvOrderMappingShallowFragment } from '../../../../generated/graphql';

const CsvOrderMappingsList = ({
  mappings,
  locallyCreatedMapping,
  selectedMappingUuid,
  setSelectedMappingUuid,
}: {
  readonly mappings: CsvOrderMappingShallowFragment[];
  readonly locallyCreatedMapping: { name: string; uuid: string } | null;
  readonly selectedMappingUuid: string | null;
  readonly setSelectedMappingUuid: Dispatch<SetStateAction<string | null>>;
}) => {
  return (
    <List sx={{ backgroundColor: 'white' }}>
      {mappings.map((mapping) => (
        <ListItem key={mapping.uuid} disablePadding>
          <ListItemButton
            selected={selectedMappingUuid === mapping.uuid}
            onClick={() => {
              setSelectedMappingUuid(mapping.uuid);
            }}
          >
            <ListItemText
              primary={mapping.name}
              secondary={`${mapping.csvOrderFieldMappings.length} mapped fields`}
            />
          </ListItemButton>
        </ListItem>
      ))}
      {!isNil(locallyCreatedMapping) && (
        <ListItem key={locallyCreatedMapping.uuid} disablePadding>
          <ListItemButton
            selected={selectedMappingUuid === locallyCreatedMapping.uuid}
          >
            <ListItemText
              primary={locallyCreatedMapping.name}
              secondary="creating..."
            />
          </ListItemButton>
        </ListItem>
      )}
    </List>
  );
};

export default CsvOrderMappingsList;
