import { Add } from '@mui/icons-material';
import { Button, Menu, Stack, useTheme } from '@mui/material';
import { type AgGridReact } from 'ag-grid-react';
import { isEmpty } from 'lodash';
import React from 'react';
import { getFilterColumnName } from '../../../dispatch/hooks/use-stop-columns';
import { type StopsTableElement } from '../../../dispatch/types/stops';
import SelectedFilterButtonForToolbar from '../../../orders/components/selected-filter-button-for-toolbar';
import { type StopFilterField, type StopsTab } from '../constants';
import { type DefaultStopsFilterTab } from '../types';

const DispatchAppliedFiltersComponent = ({
  currentDefaultTab,
  gridRef,
  handleDeleteFilter,
  handleClickSelectedFilter,
  showMenu = false,
}: {
  readonly currentDefaultTab: DefaultStopsFilterTab<StopsTab> | undefined;
  readonly gridRef: React.RefObject<AgGridReact<StopsTableElement>>;
  readonly handleDeleteFilter: (field: StopFilterField) => void;
  readonly handleClickSelectedFilter: (field: StopFilterField) => void;
  readonly showMenu?: boolean;
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const filters = Object.keys(gridRef.current?.api?.getFilterModel() ?? {});

  const addFilterButton = (
    <Button
      size="small"
      startIcon={<Add />}
      variant="outlined"
      sx={{
        borderRadius: '4px',
        backgroundColor: theme.palette.primary.light,
      }}
      onClick={(_e) => {
        gridRef.current?.api.openToolPanel('filters');
      }}
    >
      Filter
    </Button>
  );

  const appliedFilters = filters.map((key) => (
    <SelectedFilterButtonForToolbar
      key={key}
      keyName={key}
      prependText={undefined}
      currentDefaultTabFilterModel={currentDefaultTab?.filterModel}
      filterModel={gridRef.current?.api?.getFilterModel()}
      filterName={getFilterColumnName(key)}
      handleDelete={() => {
        handleDeleteFilter(key as StopFilterField);
      }}
      handleSelect={
        showMenu
          ? () => {}
          : () => {
              handleClickSelectedFilter(key as StopFilterField);
            }
      }
    />
  ));

  if (showMenu) {
    return (
      <>
        {addFilterButton}
        {!isEmpty(filters) && (
          <Button
            color="info"
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
          >
            Filters ({filters.length})
          </Button>
        )}
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={() => {
            setAnchorEl(null);
          }}
        >
          <Stack sx={{ px: 1 }} spacing={0.5}>
            {appliedFilters}
          </Stack>
        </Menu>
      </>
    );
  }

  return (
    <>
      {addFilterButton}
      <Stack direction="row" sx={{ px: 1 }} spacing={0.5}>
        {appliedFilters}
      </Stack>
    </>
  );
};

export const DispatchTableFilters = React.memo(DispatchAppliedFiltersComponent);
