import {
  Badge,
  Card,
  CardContent,
  Chip,
  Menu,
  Paper,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
} from '@mui/material';
import { capitalCase } from 'change-case';
import { isNil } from 'lodash';
import type * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { getCurrentTimeDefaultTimezone } from 'shared/time';
import TabPanel from '../../../common/components/tab-panel/tab-panel';
import {
  ScannedOrderResultType,
  SignedPodMatchStatus,
  useScannedOrderResultsLazyQuery,
} from '../../../generated/graphql';
import EndOfDayContext from '../end-of-day-context';
import useStyles from '../end-of-day-styles';

type UploadedPodsMenuProps = {
  readonly open: boolean;
  readonly setOpen: (isOpen: boolean) => void;
  readonly anchorEl: React.RefObject<HTMLAnchorElement>;
};

const CardContentSpecialPadding = styled(CardContent)(`
  padding: 10px;
  padding-left: 0px;
  padding-right: 0px;
  &:last-child {
    padding-bottom: 0px;
  }
`);

const SignedPodMatchStatusTagVariant: Record<
  SignedPodMatchStatus,
  'success' | 'error' | 'warning' | 'default' | 'info' | undefined
> = {
  [SignedPodMatchStatus.InProgress]: 'default',
  [SignedPodMatchStatus.Failed]: 'error',
  [SignedPodMatchStatus.Matched]: 'success',
  [SignedPodMatchStatus.Unmatched]: 'warning',
};

const UploadedPodsMenu = ({
  open,
  setOpen,
  anchorEl,
}: UploadedPodsMenuProps) => {
  const styles = useStyles();
  const { scannedPodResults, setScannedPodResults, setShouldLoadRoutes } =
    useContext(EndOfDayContext);
  const [numFailed, setNumFailed] = useState<number>();
  const [numInProgress, setNumInProgress] = useState<number>();
  const [numMatched, setNumMatched] = useState<number>();
  const [numUnmatched, setNumUnmatched] = useState<number>();

  const [getScannedOrderResults] = useScannedOrderResultsLazyQuery();
  const [currentTab, setCurrentTab] = useState<SignedPodMatchStatus>(
    SignedPodMatchStatus.InProgress,
  );

  useEffect(() => {
    let [numF, numIP, numM, numUM] = [0, 0, 0, 0];
    for (const data of scannedPodResults) {
      switch (data.signedPodMatchStatus) {
        case SignedPodMatchStatus.Failed: {
          numF += 1;
          break;
        }
        case SignedPodMatchStatus.InProgress: {
          numIP += 1;
          break;
        }
        case SignedPodMatchStatus.Unmatched: {
          numUM += 1;
          break;
        }
        case SignedPodMatchStatus.Matched: {
          numM += 1;
          break;
        }
        default: {
          break;
        }
      }
    }
    setNumFailed(numF);
    setNumInProgress(numIP);
    setNumMatched(numM);
    setNumUnmatched(numUM);
  }, [scannedPodResults]);

  const loadScannedPods = () => {
    getScannedOrderResults({
      variables: {
        createdAtAfter: getCurrentTimeDefaultTimezone().startOf('day').toDate(),
        type: ScannedOrderResultType.SignedPod,
        signedPodMatchStatuses: Object.values(SignedPodMatchStatus),
      },
    }).then((res) => {
      const signedPodResults = res.data?.scannedOrderResults;
      if (!isNil(signedPodResults)) {
        setScannedPodResults(
          signedPodResults.edges.map((edge) => edge.node) ?? [],
        );
        if (signedPodResults.edges.length !== scannedPodResults.length) {
          setShouldLoadRoutes(true);
        }
      }
    });
  };

  useEffect(() => {
    if (open) {
      const intervalId = setInterval(() => {
        loadScannedPods();
      }, 5000);
      return () => {
        clearInterval(intervalId);
      };
    }
    return () => {};
  }, [open]);

  const getMatchStatusTag = (
    status: SignedPodMatchStatus | null | undefined,
  ) => {
    if (isNil(SignedPodMatchStatus)) {
      return ' - ';
    }
    return (
      <Chip
        label={
          status === SignedPodMatchStatus.Unmatched
            ? 'No Match'
            : capitalCase(status ?? '-')
        }
        color={
          SignedPodMatchStatusTagVariant[
            status ?? SignedPodMatchStatus.InProgress
          ]
        }
      />
    );
  };

  return (
    <Menu
      id="uploaded-pod-menu"
      anchorEl={anchorEl.current}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Card sx={{ border: 'none', boxShadow: 'none' }}>
        <CardContentSpecialPadding>
          <Tabs
            orientation="horizontal"
            variant="scrollable"
            value={currentTab}
            aria-label="end of day tabs"
            sx={{
              float: 'right',
              marginTop: '-10px',
              paddingLeft: '20px',
              paddingRight: '20px',
            }}
            onChange={(e, newTab) => {
              setCurrentTab(newTab);
            }}
          >
            <Tab
              label={
                <Badge
                  badgeContent={numInProgress}
                  color="primary"
                  sx={styles.badge}
                >
                  In Progress
                </Badge>
              }
              value={SignedPodMatchStatus.InProgress}
            />
            <Tab
              label={
                <Badge
                  badgeContent={numMatched}
                  color="primary"
                  sx={styles.badge}
                >
                  Matched
                </Badge>
              }
              value={SignedPodMatchStatus.Matched}
            />
            <Tab
              label={
                <Badge
                  badgeContent={numUnmatched}
                  color="warning"
                  sx={styles.badge}
                >
                  No Match
                </Badge>
              }
              value={SignedPodMatchStatus.Unmatched}
            />
            <Tab
              label={
                <Badge badgeContent={numFailed} color="error" sx={styles.badge}>
                  Failed
                </Badge>
              }
              value={SignedPodMatchStatus.Failed}
            />
          </Tabs>
          {[
            SignedPodMatchStatus.InProgress,
            SignedPodMatchStatus.Matched,
            SignedPodMatchStatus.Unmatched,
            SignedPodMatchStatus.Failed,
          ].map((status) => (
            <TabPanel
              key={status}
              panelValue={status}
              selectedValue={currentTab}
              sx={{ marginTop: 6 }}
            >
              <TableContainer sx={{ height: '35vh' }} component={Paper}>
                <Table stickyHeader size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>File</TableCell>
                      {status === SignedPodMatchStatus.Matched && (
                        <TableCell>Bill of Lading</TableCell>
                      )}
                      {status === SignedPodMatchStatus.Failed && (
                        <TableCell>Failure Reason</TableCell>
                      )}
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {scannedPodResults
                      .filter((data) => data.signedPodMatchStatus === status)
                      .map((data) => (
                        <TableRow key={data.uuid}>
                          <TableCell>{data.filename}</TableCell>
                          {status === SignedPodMatchStatus.Matched && (
                            <TableCell>
                              {
                                data.podOrder?.standardOrderFields
                                  .shipperBillOfLadingNumber
                              }
                            </TableCell>
                          )}
                          {status === SignedPodMatchStatus.Failed && (
                            <TableCell>{data.failureReason}</TableCell>
                          )}
                          <TableCell>
                            {getMatchStatusTag(data.signedPodMatchStatus)}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
          ))}
        </CardContentSpecialPadding>
      </Card>
    </Menu>
  );
};

export default UploadedPodsMenu;
