import { Button, CircularProgress } from '@mui/material';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import { uniqueNotNilVals } from 'shared/array';
import { shallow } from 'zustand/shallow';
import { useExportInvoicesToQuickbooksDesktopMutation } from '../../../../../generated/graphql';
import useInvoicesStore from '../../../invoices-store';
import ExportToQuickbooksDesktopModal from './export-to-quickbooks-desktop-modal';
import { type MissingCustomerMapping } from './types';

const ExportToQuickbooksDesktop = () => {
  const [selectedInvoiceUuids] = useInvoicesStore(
    (state) => [state.selectedInvoiceUuids],
    shallow,
  );

  const [exportInvoicesToQuickbooksDesktop, { loading }] =
    useExportInvoicesToQuickbooksDesktopMutation();
  const [isOpen, setIsOpen] = useState(false);
  const [missingFreightChargeMappings, setMissingFreightChargeMappings] =
    useState<string[]>([]);
  const [missingFuelChargeMappings, setMissingFuelChargeMappings] = useState<
    string[]
  >([]);
  const [missingAccessorialMappings, setMissingAccessorialMappings] = useState<
    string[]
  >([]);
  const [missingCustomerMappings, setMissingCustomerMappings] = useState<
    MissingCustomerMapping[]
  >([]);
  const [duplicateInvoiceErrors, setDuplicateInvoiceErrors] = useState<
    string[]
  >([]);
  const [conductorUserFacingErrors, setConductorUserFacingErrors] = useState<
    string[]
  >([]);
  const [exportFailed, setExportFailed] = useState<boolean>(false);
  const [failedExportCount, setFailedExportCount] = useState<number | null>(
    null,
  );
  const [totalInvoiceCount, setTotalInvoiceCount] = useState<number | null>(
    null,
  );

  const onExport = async (uuids: string[]) => {
    const res = await exportInvoicesToQuickbooksDesktop({
      variables: {
        exportInvoicesToQuickbooksInput: {
          invoiceUuids: uuids,
        },
      },
    });
    if (isNil(res.errors)) {
      const exportInvoicesRes = res?.data?.exportInvoicesToQuickbooksDesktop;
      if (isNil(exportInvoicesRes)) {
        setExportFailed(true);
      }
      const customersMissingMappings: MissingCustomerMapping[] = [];
      exportInvoicesRes
        ?.filter(
          (invoiceRes) =>
            !isNil(invoiceRes.missingCustomerName) &&
            !isNil(invoiceRes.missingCustomerUuid),
        )
        ?.forEach((invoiceRes) => {
          if (
            isNil(invoiceRes.missingCustomerName) ||
            isNil(invoiceRes.missingCustomerUuid)
          ) {
            return;
          }
          const existingCustomer = customersMissingMappings.find(
            (cus) => cus.uuid === invoiceRes.missingCustomerUuid,
          );

          if (isNil(existingCustomer)) {
            customersMissingMappings.push({
              name: invoiceRes.missingCustomerName,
              uuid: invoiceRes.missingCustomerUuid,
              invoiceUuids: [invoiceRes.failedInvoiceUuid],
            });
          } else {
            existingCustomer?.invoiceUuids.push(invoiceRes.failedInvoiceUuid);
          }
        });

      setMissingCustomerMappings(customersMissingMappings);

      setMissingFreightChargeMappings(
        uniqueNotNilVals(
          exportInvoicesRes?.flatMap(
            (invoiceRes) => invoiceRes.missingFreightChargeMappings,
          ) ?? [],
        ),
      );
      setMissingFuelChargeMappings(
        uniqueNotNilVals(
          exportInvoicesRes?.flatMap(
            (invoiceRes) => invoiceRes.missingFuelChargeMappings,
          ) ?? [],
        ),
      );
      setMissingAccessorialMappings(
        uniqueNotNilVals(
          exportInvoicesRes?.flatMap(
            (invoiceRes) => invoiceRes.missingAccessorialMappings,
          ) ?? [],
        ),
      );
      setDuplicateInvoiceErrors(
        uniqueNotNilVals(
          exportInvoicesRes?.map(
            (invoiceRes) => invoiceRes.duplicateInvoiceError,
          ) ?? [],
        ),
      );
      setConductorUserFacingErrors(
        uniqueNotNilVals(
          res?.data?.exportInvoicesToQuickbooksDesktop.flatMap(
            (invoiceRes) => invoiceRes.conductorUserFacingErrors,
          ) ?? [],
        ),
      );
      setFailedExportCount(
        res?.data?.exportInvoicesToQuickbooksDesktop.reduce(
          (prev, curr) => prev + (curr.success ? 0 : 1),
          0,
        ) ?? 0,
      );
      setTotalInvoiceCount(
        res?.data?.exportInvoicesToQuickbooksDesktop.length ?? 0,
      );
    } else {
      setFailedExportCount(null);
      setTotalInvoiceCount(null);
      setConductorUserFacingErrors(res.errors.map((e) => e.message));
      setDuplicateInvoiceErrors([]);
      setMissingAccessorialMappings([]);
      setMissingCustomerMappings([]);
      setMissingFreightChargeMappings([]);
      setMissingFuelChargeMappings([]);
    }

    setIsOpen(true);
  };

  return (
    <>
      {isOpen && (
        <ExportToQuickbooksDesktopModal
          open={isOpen}
          setOpen={setIsOpen}
          missingFreightChargeMappings={missingFreightChargeMappings}
          missingFuelChargeMappings={missingFuelChargeMappings}
          missingAccessorialMappings={missingAccessorialMappings}
          missingCustomerMappings={missingCustomerMappings}
          duplicateInvoiceErrors={duplicateInvoiceErrors}
          conductorUserFacingErrors={conductorUserFacingErrors}
          exportFailed={exportFailed}
          failedExportCount={failedExportCount}
          totalInvoiceCount={totalInvoiceCount}
          onExport={onExport}
        />
      )}
      <Button
        disabled={selectedInvoiceUuids.length === 0 || loading}
        variant="contained"
        color="success"
        onClick={async () => onExport(selectedInvoiceUuids)}
      >
        {loading && <CircularProgress size={10} />}
        {selectedInvoiceUuids.length > 0
          ? `Export to QB Desktop (${selectedInvoiceUuids.length})`
          : 'Export to QB Desktop'}
      </Button>
    </>
  );
};

export default ExportToQuickbooksDesktop;
