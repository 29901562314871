import { Alert, Stack } from '@mui/material';
import pluralize from 'pluralize';
import { type Dispatch, type SetStateAction } from 'react';
import { type InvoiceSendJobBatchFragment } from '../../../../../generated/graphql';
import PalletLink from '../../../../../pallet-ui/links/link/pallet-link';
import useInvoiceSendJobRecentFailures from './use-invoice-send-job-recent-failures';

const RecentFailedSendJobBatchesAlert = ({
  setOpenedInvoiceSendJobBatch,
}: {
  readonly setOpenedInvoiceSendJobBatch: Dispatch<
    SetStateAction<InvoiceSendJobBatchFragment | undefined>
  >;
}) => {
  const { recentFailedSendJobBatches, acknowledgeInvoiceSendJobFailures } =
    useInvoiceSendJobRecentFailures();
  if (
    (recentFailedSendJobBatches?.invoiceSendJobBatches.totalCount ?? 0) === 0
  ) {
    return null;
  }
  return (
    <Stack direction="row" spacing={1} p={1}>
      {recentFailedSendJobBatches?.invoiceSendJobBatches.edges.map(
        ({ node: invoiceSendJobBatch }) => (
          <Alert key={invoiceSendJobBatch.uuid} severity="error">
            {invoiceSendJobBatch.invoiceSendJobBatchProgress.failCount}{' '}
            {pluralize(
              'invoice',
              invoiceSendJobBatch.invoiceSendJobBatchProgress.failCount,
            )}
            failed to send. Click{' '}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <PalletLink
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                setOpenedInvoiceSendJobBatch(invoiceSendJobBatch);
                acknowledgeInvoiceSendJobFailures({
                  invoiceSendJobBatchUuid: invoiceSendJobBatch.uuid,
                });
              }}
            >
              here
            </PalletLink>{' '}
            to view.
          </Alert>
        ),
      )}
    </Stack>
  );
};

export default RecentFailedSendJobBatchesAlert;
