/* eslint-disable import/prefer-default-export */
import { type FetchPolicy } from '@apollo/client';
import { isNil, isString } from 'lodash';
import { useCallback } from 'react';
import {
  findCorrespondingPackageSpec,
  findCorrespondingPackageType,
} from '../../domains/orders/components/order-form/components/packages/util';
import {
  type ContactPackageSpecsQuery,
  type PackageSpecEntity,
  PackageSpecStatus,
  PackageType,
  useContactPackageSpecsLazyQuery,
  usePackageSpecsMinimalLazyQuery,
} from '../../generated/graphql';
import { FeatureFlag } from '../feature-flags';
import useFeatureFlag from './use-feature-flag';

const packageSpecIsActive = (ps: Pick<PackageSpecEntity, 'status'>) =>
  ps.status === PackageSpecStatus.Active;

type Contact = ContactPackageSpecsQuery['contact'];

export type DefaultPackageSpec = {
  packageSpecId: string | null;
  type: PackageType;
};

type GetContactDefaultPackageSpec = (
  // If a Contact is already available, pass it in to avoid an additional query.
  contactOrContactUuid: Contact | string,
) => Promise<DefaultPackageSpec>;

/**
 * Returns a function that can be used to get the packageSpecId and type to use when adding a package to an order or to
 * a quote for a given contact.
 */
export const useGetContactDefaultPackageSpec = ({
  fetchPolicy = 'cache-first',
}: {
  fetchPolicy?: FetchPolicy;
} = {}): GetContactDefaultPackageSpec => {
  const ffPackageSpecs = useFeatureFlag(FeatureFlag.FF_PACKAGE_SPECS);
  const [getAllPackageSpecs] = usePackageSpecsMinimalLazyQuery({
    fetchPolicy,
  });
  const [getContact] = useContactPackageSpecsLazyQuery({
    fetchPolicy,
  });

  return useCallback<GetContactDefaultPackageSpec>(
    async (contactOrContactUuid) => {
      const [{ data: allPackageSpecsData }, { data: contactData }] =
        await Promise.all([
          getAllPackageSpecs(),
          isString(contactOrContactUuid)
            ? getContact({
                variables: {
                  contactUuid: contactOrContactUuid,
                },
              })
            : Promise.resolve({ data: { contact: contactOrContactUuid } }),
        ]);

      const activeSpecs =
        contactData?.contact.__typename === 'CustomerContactEntity' &&
        contactData.contact.useCustomPackageSpecs
          ? contactData.contact.packageSpecs.filter(packageSpecIsActive)
          : allPackageSpecsData?.packageSpecs.packageSpecs.filter(
              packageSpecIsActive,
            );

      let packageSpecId: string | null;
      let type: PackageType;

      if (ffPackageSpecs) {
        const defaultSpec = activeSpecs?.[0];
        if (isNil(defaultSpec)) {
          packageSpecId = null;
          type = PackageType.Piece;
        } else {
          packageSpecId = defaultSpec.id;
          type = findCorrespondingPackageType(defaultSpec) ?? PackageType.Piece;
        }
      } else {
        packageSpecId =
          findCorrespondingPackageSpec(PackageType.Piece, activeSpecs ?? [])
            ?.id ?? null;
        type = PackageType.Piece;
      }

      return {
        packageSpecId,
        type,
      };
    },
    [ffPackageSpecs, getContact, getAllPackageSpecs],
  );
};
