import { isNil } from 'lodash';
import { shallow } from 'zustand/shallow';
import {
  type TariffZonesQueryVariables,
  useTariffZonesLazyQuery,
} from '../../../../../generated/graphql';
import useTariffGroupStore, {
  convertTariffGroupTypeToTariffZoneLocationType,
} from '../store/tariff-group-state-store';

const useTariffGroupActions = () => {
  const [setLocationZones, tariffZoneType, tariffGroupType] =
    useTariffGroupStore(
      (state) => [
        state.setLocationZonesData,
        state.zoneType,
        state.tariffGroupType,
      ],
      shallow,
    );

  const [getTariffZones] = useTariffZonesLazyQuery();

  const refetchAndSetLocationZones = async ({
    variables,
  }: {
    variables: TariffZonesQueryVariables | null;
  }) => {
    // if no variables passed in, use what's in the store as vars
    const variablesToUse = isNil(variables)
      ? {
          tariffZoneType,
          tariffZoneLocationType:
            convertTariffGroupTypeToTariffZoneLocationType(tariffGroupType),
        }
      : variables;

    const res = await getTariffZones({
      variables: variablesToUse,
    });

    let zonesToUse = res.data?.tariffZones ?? [];
    const isLineHaulLaneZone = !isNil(res.data?.tariffZones[0]?.lineHaulLane);

    // Exclude archived line haul lanes when creating a new linehaul tariff group
    if (isLineHaulLaneZone) {
      zonesToUse = zonesToUse.filter(
        (zone) => zone.lineHaulLane?.isActive === true,
      );
    }

    const zonesSorted =
      zonesToUse
        .map((zone) => {
          return { uuid: zone.uuid, name: zone.name };
        })
        .sort((a, b) => a.name.localeCompare(b.name)) ?? [];

    setLocationZones(zonesSorted);
  };

  return {
    refetchAndSetLocationZones,
  };
};

export default useTariffGroupActions;
