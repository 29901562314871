import { Add } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  useTheme,
} from '@mui/material';
import type {
  BodyScrollEndEvent,
  ColDef,
  ColumnResizedEvent,
  GridReadyEvent,
  IRowNode,
  IServerSideDatasource,
  PaginationChangedEvent,
  RowSelectedEvent,
  SideBarDef,
  SortModelItem,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { isEmpty, isNil, unionBy } from 'lodash';
import {
  type FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import useStateRef from 'react-usestateref';
import { filterNotNil } from 'shared/array';
import { useDebouncedCallback } from 'use-debounce';
import useLocalStorageState from 'use-local-storage-state';
import apolloClient from '../../../apollo-client';
import { RESERVATION_SYSTEM_COLUMN_WIDTHS_KEY } from '../../../common/local-storage/keys';
import useBusinessDivisions from '../../../common/react-hooks/use-business-divisions';
import useMe from '../../../common/react-hooks/use-me';
import useServices from '../../../common/react-hooks/use-services';
import {
  DispatchTableField,
  FilterOperator,
  type FindStopsFiltersInput,
  NumberOfStopsDocument,
  type NumberOfStopsQuery,
  type NumberOfStopsQueryVariables,
  OrderStatus,
  PickupOrDelivery,
  type StopFragment,
  StopStatus,
  type StopSortDirection,
  type StopsQueryVariables,
  useContactsSimpleQuery,
  useRouteNamesQuery,
} from '../../../generated/graphql';
import useGlobalStore from '../../../layouts/dashboard/global-store';
import { StopFilterField } from '../../ag-grid/dispatch-stops/constants';
import { addFilterFromFilterModelToResult } from '../../ag-grid/dispatch-stops/utils';
import useFetchStops from '../../dispatch/hooks/use-fetch-stops';
import { useReservationSystemColumns } from '../../dispatch/hooks/use-reservation-system-columns';
import {
  AGGridFilterType,
  BooleanFilterOption,
  type FilterModel,
} from '../../orders/components/enums/order-filters';
import { OrderDialog } from '../../orders/components/order-dialog';
import SelectedFilterButtonForToolbar from '../../orders/components/selected-filter-button-for-toolbar';
import { ProposeTimesDialog } from './propose-times-dialog';
import 'ag-grid-enterprise';

enum ReservationSystemTab {
  ReadyToSchedule = 'Ready to schedule',
  OptionsProposed = 'Options proposed',
  Confirmed = 'Confirmed',
  NeedsReview = 'Needs review',
}

const isReservationSystemTab = (
  value: unknown,
): value is ReservationSystemTab =>
  Object.values(ReservationSystemTab).includes(value as never);

type TabConfig = {
  key: ReservationSystemTab;
  filterModel: FilterModel;
};

const tabs = Object.freeze<[TabConfig, ...TabConfig[]]>([
  {
    key: ReservationSystemTab.ReadyToSchedule,
    filterModel: {
      [StopFilterField.APPOINTMENT_REQUIRED]: {
        value: BooleanFilterOption.YES,
        filterType: AGGridFilterType.BOOLEAN,
      },
      [StopFilterField.HAS_APPOINTMENT]: {
        value: BooleanFilterOption.NO,
        filterType: AGGridFilterType.BOOLEAN,
      },
      [StopFilterField.HAS_APPOINTMENT_OPTIONS_PROPOSED]: {
        value: BooleanFilterOption.NO,
        filterType: AGGridFilterType.BOOLEAN,
      },
      [StopFilterField.STOP_TYPE]: {
        filterOperator: FilterOperator.Or,
        values: [
          { actualValue: PickupOrDelivery.Pickup, displayValue: 'Pickup' },
          { actualValue: PickupOrDelivery.Delivery, displayValue: 'Delivery' },
        ],
        filterType: AGGridFilterType.MULTI_SELECT,
      },
    },
  },
  {
    key: ReservationSystemTab.OptionsProposed,
    filterModel: {
      [StopFilterField.APPOINTMENT_REQUIRED]: {
        value: BooleanFilterOption.YES,
        filterType: AGGridFilterType.BOOLEAN,
      },
      [StopFilterField.HAS_APPOINTMENT]: {
        value: BooleanFilterOption.NO,
        filterType: AGGridFilterType.BOOLEAN,
      },
      [StopFilterField.HAS_APPOINTMENT_OPTIONS_PROPOSED]: {
        value: BooleanFilterOption.YES,
        filterType: AGGridFilterType.BOOLEAN,
      },
      [StopFilterField.STOP_TYPE]: {
        filterOperator: FilterOperator.Or,
        values: [
          { actualValue: PickupOrDelivery.Pickup, displayValue: 'Pickup' },
          { actualValue: PickupOrDelivery.Delivery, displayValue: 'Delivery' },
        ],
        filterType: AGGridFilterType.MULTI_SELECT,
      },
    },
  },
  {
    key: ReservationSystemTab.Confirmed,
    filterModel: {
      [StopFilterField.APPOINTMENT_REQUIRED]: {
        value: BooleanFilterOption.YES,
        filterType: AGGridFilterType.BOOLEAN,
      },
      [StopFilterField.HAS_APPOINTMENT]: {
        value: BooleanFilterOption.YES,
        filterType: AGGridFilterType.BOOLEAN,
      },
      [StopFilterField.HAS_APPOINTMENT_OPTIONS_PROPOSED]: {
        value: BooleanFilterOption.YES,
        filterType: AGGridFilterType.BOOLEAN,
      },
      [StopFilterField.APPOINTMENT_SCHEDULED]: {
        value: BooleanFilterOption.YES,
        filterType: AGGridFilterType.BOOLEAN,
      },
      [StopFilterField.STOP_TYPE]: {
        filterOperator: FilterOperator.Or,
        values: [
          { actualValue: PickupOrDelivery.Pickup, displayValue: 'Pickup' },
          { actualValue: PickupOrDelivery.Delivery, displayValue: 'Delivery' },
        ],
        filterType: AGGridFilterType.MULTI_SELECT,
      },
    },
  },
  {
    key: ReservationSystemTab.NeedsReview,
    filterModel: {
      [StopFilterField.APPOINTMENT_REQUIRED]: {
        value: BooleanFilterOption.YES,
        filterType: AGGridFilterType.BOOLEAN,
      },
      [StopFilterField.HAS_APPOINTMENT]: {
        value: BooleanFilterOption.YES,
        filterType: AGGridFilterType.BOOLEAN,
      },
      [StopFilterField.HAS_APPOINTMENT_OPTIONS_PROPOSED]: {
        value: BooleanFilterOption.YES,
        filterType: AGGridFilterType.BOOLEAN,
      },
      [StopFilterField.APPOINTMENT_SCHEDULED]: {
        value: BooleanFilterOption.NO,
        filterType: AGGridFilterType.BOOLEAN,
      },
      [StopFilterField.STOP_TYPE]: {
        filterOperator: FilterOperator.Or,
        values: [
          { actualValue: PickupOrDelivery.Pickup, displayValue: 'Pickup' },
          { actualValue: PickupOrDelivery.Delivery, displayValue: 'Delivery' },
        ],
        filterType: AGGridFilterType.MULTI_SELECT,
      },
    },
  },
]);

const defaultColDef: ColDef<StopFragment> = {
  wrapHeaderText: true,
  resizable: true,
  suppressMenu: true,
  unSortIcon: false,
};

const sideBar: SideBarDef = {
  toolPanels: [
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
      toolPanelParams: {
        suppressExpandAll: false,
        suppressFilterSearch: false,
      },
    },
  ],
  defaultToolPanel: 'filters',
  position: 'left',
};

const buildStopsFilters = (
  filterModel: FilterModel,
): FindStopsFiltersInput[] => {
  const filters: FindStopsFiltersInput[] = [
    {
      statuses: [StopStatus.NotArrived, StopStatus.Arrived],
      orderStatuses: [OrderStatus.Created, OrderStatus.InProgress],
      isAssigned: false,
    },
  ];

  if (!isEmpty(filterModel)) {
    let conjunctionFilterSet: FindStopsFiltersInput = {};
    let disjunctionFilterSet: FindStopsFiltersInput = {};
    for (const [key, value] of Object.entries(filterModel)) {
      if (
        'filterOperator' in value &&
        value.filterOperator === FilterOperator.Or
      ) {
        disjunctionFilterSet = addFilterFromFilterModelToResult(
          disjunctionFilterSet,
          key as StopFilterField,
          value,
          undefined,
        );
      } else {
        conjunctionFilterSet = addFilterFromFilterModelToResult(
          conjunctionFilterSet,
          key as StopFilterField,
          value,
          undefined,
        );
      }
    }
    if (!isEmpty(conjunctionFilterSet)) {
      conjunctionFilterSet.filterOperator = FilterOperator.And;
      filters.push(conjunctionFilterSet);
    }
    if (!isEmpty(disjunctionFilterSet)) {
      disjunctionFilterSet.filterOperator = FilterOperator.Or;
      filters.push(disjunctionFilterSet);
    }
  }

  return filters;
};

const buildStopsSorts = (
  sortModel: SortModelItem[],
): StopsQueryVariables['sorts'] =>
  filterNotNil(
    sortModel.map((sort) =>
      Object.values(DispatchTableField).includes(sort.colId as never)
        ? {
            sortBy: sort.colId as DispatchTableField,
            sortDirection: sort.sort as StopSortDirection,
          }
        : null,
    ),
  );

const fetchNumberOfStops = async (
  filters: Partial<NumberOfStopsQueryVariables>,
) => {
  // Using ApolloClient query because of this issue: https://github.com/apollographql/apollo-client/issues/9755
  const res = await apolloClient.query<
    NumberOfStopsQuery,
    NumberOfStopsQueryVariables
  >({
    query: NumberOfStopsDocument,
    variables: {
      ...filters,
      includeCount: true,
      useCache: false,
      hideOnHold: true,
      hideStopsWithoutAddress: true,
    },
  });
  return res.data.numberOfStops;
};

const stopsPerPage = 50;

type TableState = {
  searchText: string;
  terminalUuid: string | undefined;
  tab: TabConfig;
  currentCursor: string | null | undefined;
  columnDefs: Array<ColDef<StopFragment>>;
  topScrollPosition: number;
  totalCount: number | undefined;
  datasourceVersionId: number;
};

const initialTableState: Readonly<TableState> = {
  searchText: '',
  terminalUuid: undefined,
  tab: tabs[0],
  currentCursor: null,
  columnDefs: [],
  topScrollPosition: 0,
  totalCount: undefined,
  datasourceVersionId: 0,
};

export const ReservationSystemView: FunctionComponent = () => {
  const theme = useTheme();
  const { isCourier } = useMe();
  const selectedTerminalUuid = useGlobalStore(
    (state) => state.selectedTerminalUuid,
  );
  const { services } = useServices();
  const { businessDivisions } = useBusinessDivisions();
  const { data: contactsData } = useContactsSimpleQuery({
    fetchPolicy: 'cache-first',
    variables: {
      excludeNonActive: true,
    },
  });
  const { data: routeNamesData } = useRouteNamesQuery({
    fetchPolicy: 'cache-first',
  });
  const [columnWidths, setColumnWidths] = useLocalStorageState<
    Array<{ width: number | undefined; colId: string }>
  >(RESERVATION_SYSTEM_COLUMN_WIDTHS_KEY, {
    defaultValue: [],
  });
  const [proposeTimesDialogOpen, setProposeTimesDialogOpen] = useState(false);
  const [openedOrderUuid, setOpenedOrderUuid] = useState<string | undefined>(
    undefined,
  );

  const gridRef = useRef<AgGridReact<StopFragment>>(null);
  const toolPanelVisibleRef = useRef(false);
  const columnDefinitions = useReservationSystemColumns({
    isCourier,
    setOpenedOrderUuid,
  });
  const [, setState, stateRef] = useStateRef<TableState>(initialTableState);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [selectedStops, setSelectedStops] = useState<StopFragment[]>([]);
  const [stopsCountByTab, setStopsCountByTab] = useState<
    Array<{ tab: ReservationSystemTab; numberOfStops: number }>
  >([]);

  const getNumberOfStopsForAllTabs = useDebouncedCallback(async () => {
    setStopsCountByTab([]);
    const results = await Promise.allSettled(
      tabs.map(
        async ({ key: tab, filterModel }) =>
          ({
            tab,
            numberOfStops: await fetchNumberOfStops({
              filters: buildStopsFilters(filterModel),
              terminalUuid: stateRef.current.terminalUuid,
            }),
          }) as const,
      ),
    );
    setStopsCountByTab(
      filterNotNil<{ tab: ReservationSystemTab; numberOfStops: number }>(
        [...results].map((result) =>
          result.status === 'fulfilled' ? result.value : null,
        ),
      ),
    );
  }, 200);

  const { fetchStops } = useFetchStops();

  const onGridReady = useCallback(
    ({ api }: GridReadyEvent) => {
      api.setFilterModel(stateRef.current.tab.filterModel);
      api.onFilterChanged();

      const datasource: IServerSideDatasource = {
        getRows(params) {
          const { datasourceVersionId } = stateRef.current;
          fetchStops({
            variables: {
              filters: buildStopsFilters(api.getFilterModel()),
              sorts: buildStopsSorts(params.request.sortModel),
              includeCount: true,
              searchText: stateRef.current.searchText.trim(),
              terminalUuid: stateRef.current.terminalUuid,
            },
            first: stopsPerPage,
            after: stateRef.current.currentCursor,
            cacheStartIndex: params.request.startRow,
            // The set of stops shown changes after proposals are sent. Make sure we always have fresh data.
            useCache: false,
          }).then((data) => {
            if (stateRef.current.datasourceVersionId !== datasourceVersionId) {
              params.fail();
              return;
            }

            setState((prevState) => ({
              ...prevState,
              totalCount: data.stops.totalCount ?? undefined,
              currentCursor: data.stops.pageInfo.endCursor,
            }));

            params.success({
              rowData: data.stops.edges.map((edge) => edge.node),
              rowCount: data.stops.totalCount ?? undefined,
            });

            const nodesToSelect: Array<IRowNode<StopFragment>> = [];
            setSelectedStops((prevSelectedStops) => {
              if (prevSelectedStops.length > 0) {
                const freshStopsSetSelected: StopFragment[] = [];
                gridRef.current?.api.forEachNode((node) => {
                  const { data: stop } = node;
                  if (
                    !isNil(stop) &&
                    prevSelectedStops.some(
                      (prevSelectedStop) =>
                        prevSelectedStop.stop.uuid === stop.stop.uuid,
                    )
                  ) {
                    nodesToSelect.push(node);
                    freshStopsSetSelected.push(stop);
                  }
                });
                if (freshStopsSetSelected.length > 0) {
                  return unionBy<StopFragment>(
                    freshStopsSetSelected,
                    prevSelectedStops,
                    ({ stop }) => stop.uuid,
                  );
                }
              }
              return prevSelectedStops;
            });
            for (const node of nodesToSelect) {
              node.setSelected(true);
            }
          });
        },
      };
      api.setServerSideDatasource(datasource);
      api.closeToolPanel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setState, stateRef],
  );

  const destroyFilter = useCallback((field: StopFilterField) => {
    gridRef.current?.api.destroyFilter(field);
  }, []);

  const handleDeleteFilter = (field: StopFilterField) => {
    // reset the filter
    destroyFilter(field);

    // collapse that filter in the panel.
    const toolPanel = gridRef.current?.api.getToolPanelInstance('filters');
    toolPanel?.collapseFilters([field]);
  };
  const handleClickSelectedFilter = (field: StopFilterField) => {
    // Open the tool panel and show that filter
    const toolPanel = gridRef.current?.api.getToolPanelInstance('filters');
    toolPanel?.expandFilters([field]);
    toolPanel?.setFilterLayout([
      stateRef.current.columnDefs.find((colDef) => colDef.field === field) ??
        {},
    ]);
    gridRef.current?.api.openToolPanel('filters');
  };

  const handleRowSelected = (event: RowSelectedEvent<StopFragment>) => {
    const { data: eventStop, node } = event;
    if (!isNil(eventStop)) {
      setSelectedStops((prevSelectedStops) =>
        !isNil(node.rowIndex) && node.isSelected() === true
          ? unionBy<StopFragment>(
              [eventStop],
              prevSelectedStops,
              ({ stop }) => stop.uuid,
            )
          : prevSelectedStops.filter(
              ({ stop }) => stop.uuid !== eventStop.stop.uuid,
            ),
      );
    }
  };

  const handleScrollEnd = (event: BodyScrollEndEvent) => {
    setState((prevState) => ({
      ...prevState,
      topScrollPosition: event.top,
    }));
  };

  const handleColumnResized = (e: ColumnResizedEvent) => {
    const columnState = gridRef.current?.columnApi.getColumnState();

    if (e.finished && !isNil(columnState)) {
      setColumnWidths(
        columnState.map((col) => ({ colId: col.colId, width: col.width })),
      );
    }
  };

  const handlePaginationChanged = ({
    api: gridApi,
  }: PaginationChangedEvent<StopFragment>) => {
    const stopsToSelect: StopFragment[] = [];
    gridApi.forEachNode((node) => {
      if (!isNil(node.data)) {
        if (allSelected) {
          stopsToSelect.push(node.data);
          node.setSelected(true);
        } else {
          node.setSelected(false);
        }
      }
    });
    setSelectedStops(stopsToSelect);
  };

  const refreshGrid = useCallback(
    (
      { purge } = {
        purge: true,
      },
    ) => {
      setState((prevState) => ({
        ...prevState,
        currentCursor: null,
        totalCount: undefined,
        datasourceVersionId: prevState.datasourceVersionId + 1,
      }));
      const gridApi = gridRef.current?.api;
      if (!isNil(gridApi)) {
        gridApi.paginationGoToFirstPage();
        gridApi.refreshServerSide({ purge });
        gridApi.deselectAll();
      }
    },
    [setState],
  );

  useEffect(() => {
    setState((prevState) => ({ ...prevState, columnDefs: columnDefinitions }));
    gridRef.current?.api?.redrawRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnDefinitions]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      terminalUuid: selectedTerminalUuid,
    }));
    getNumberOfStopsForAllTabs();
    refreshGrid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTerminalUuid]);

  useEffect(() => {
    const updatedColumnDefs = [...stateRef.current.columnDefs];

    // CONTACTS
    const customerColumnDefIndex = updatedColumnDefs.findIndex(
      ({ field }) => field === StopFilterField.CUSTOMER,
    );
    const customerColumnDef = updatedColumnDefs[customerColumnDefIndex];
    if (!isNil(customerColumnDef) && !isNil(customerColumnDef.filterParams)) {
      updatedColumnDefs[customerColumnDefIndex] = {
        ...customerColumnDef,
        filterParams: {
          ...customerColumnDef.filterParams,
          values:
            contactsData?.contacts.map(({ displayName, uuid }) => ({
              actualValue: uuid,
              displayValue: displayName,
            })) ?? [],
        },
      };
    }

    // SERVICES
    const serviceColumnDefIndex = updatedColumnDefs.findIndex(
      ({ field }) => field === StopFilterField.SERVICE_LEVEL,
    );
    const serviceColumnDef = updatedColumnDefs[serviceColumnDefIndex];
    if (!isNil(serviceColumnDef) && !isNil(serviceColumnDef.filterParams)) {
      updatedColumnDefs[serviceColumnDefIndex] = {
        ...serviceColumnDef,
        filterParams: {
          ...serviceColumnDef.filterParams,
          values: services.map(({ name, uuid }) => ({
            actualValue: uuid,
            displayValue: name,
          })),
        },
      };
    }

    // BUSINESS DIVISIONS
    const businessDivisionColumnDefIndex = updatedColumnDefs.findIndex(
      ({ field }) => field === StopFilterField.BUSINESS_DIVISION,
    );
    const businessDivisionColumnDef =
      updatedColumnDefs[businessDivisionColumnDefIndex];
    if (
      !isNil(businessDivisionColumnDef) &&
      !isNil(businessDivisionColumnDef.filterParams)
    ) {
      updatedColumnDefs[businessDivisionColumnDefIndex] = {
        ...businessDivisionColumnDef,
        filterParams: {
          ...businessDivisionColumnDef.filterParams,
          values:
            businessDivisions?.map(({ name, uuid }) => ({
              actualValue: uuid,
              displayValue: name,
            })) ?? [],
        },
      };
    }

    // ROUTE NAME
    const routeNameColumnDefIndex = updatedColumnDefs.findIndex(
      ({ field }) => field === StopFilterField.ROUTE_NAME,
    );
    const routeNameColumnDef = updatedColumnDefs[routeNameColumnDefIndex];
    if (!isNil(routeNameColumnDef) && !isNil(routeNameColumnDef.filterParams)) {
      updatedColumnDefs[routeNameColumnDefIndex] = {
        ...routeNameColumnDef,
        filterParams: {
          ...routeNameColumnDef.filterParams,
          values:
            routeNamesData?.routeNames.map(({ name, uuid }) => ({
              actualValue: uuid,
              displayValue: name,
            })) ?? [],
        },
      };
    }

    setState((prevState) => ({
      ...prevState,
      columnDefs: updatedColumnDefs,
    }));

    // Note that columnWidths is not a dependency because it is available from the initial render, and after reading it here we
    // only update it because AgGrid maintains a copy of the state.
    gridRef.current?.columnApi?.setColumnWidths(
      columnWidths.map((columnWidth) => ({
        newWidth: columnWidth.width ?? 0,
        key: columnWidth.colId,
      })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services, businessDivisions, contactsData, routeNamesData]);

  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      const gridApi = gridRef.current?.api;
      const isToolPanelClicked = (event.target as HTMLElement | null)?.closest(
        '.ag-filter-toolpanel',
      );
      const isDateRangePickerClicked = (
        event.target as HTMLElement | null
      )?.closest('.rmdp-wrapper');

      if (
        gridApi &&
        !isToolPanelClicked &&
        !isDateRangePickerClicked &&
        toolPanelVisibleRef.current
      ) {
        const filterToolPanel =
          gridRef.current?.api.getToolPanelInstance('filters');
        filterToolPanel?.collapseFilters();
        // Reset filters to all before closing.
        filterToolPanel?.refresh();
        filterToolPanel?.setFilterLayout(stateRef.current.columnDefs);
        gridRef.current?.api.closeToolPanel();
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleProposalsSent = () => {
    refreshGrid({ purge: false });
    // A timeout helps give the unassigned stops cache time to update.
    setTimeout(() => {
      getNumberOfStopsForAllTabs();
    }, 100);
  };

  const filterModel = gridRef.current?.api?.getFilterModel();

  return (
    <>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!isNil(snackbarMessage)}
        onClose={() => {
          setSnackbarMessage(null);
        }}
      >
        <Alert>{snackbarMessage}</Alert>
      </Snackbar>
      <Stack height="100%">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            backgroundColor: 'white',
            borderTop: '1px solid',
            borderColor: 'divider',
            marginTop: '20px',
            pr: 2,
          }}
        >
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={stateRef.current.tab.key}
            onChange={(_, value) => {
              if (isReservationSystemTab(value)) {
                const tab = tabs.find(({ key }) => key === value);
                if (!isNil(tab)) {
                  setState((prevState) => ({
                    ...prevState,
                    tab,
                    currentCursor: null,
                  }));
                  // TODO: See why a filter from one tab isn't being removed when switching to a tab that doesn't have that filter.
                  const gridApi = gridRef.current?.api;
                  if (!isNil(gridApi)) {
                    gridApi.setFilterModel(tab.filterModel);
                  }
                  refreshGrid();
                }
              }
            }}
          >
            {tabs.map(({ key }) => (
              <Tab
                key={key}
                value={key}
                label={`${key} (${
                  key === stateRef.current.tab.key
                    ? `${
                        isNil(stateRef.current.totalCount)
                          ? '-'
                          : stateRef.current.totalCount
                      }`
                    : (stopsCountByTab.find((data) => data.tab === key)
                        ?.numberOfStops ?? '-')
                })`}
              />
            ))}
          </Tabs>
          {(stateRef.current.tab.key === ReservationSystemTab.ReadyToSchedule ||
            stateRef.current.tab.key ===
              ReservationSystemTab.OptionsProposed) && (
            <Button
              disabled={selectedStops.length === 0}
              variant="contained"
              onClick={() => {
                setProposeTimesDialogOpen(true);
              }}
            >
              Propose times
              {selectedStops.length > 1 && ` (${selectedStops.length})`}
            </Button>
          )}
        </Stack>
        <Stack direction="column" flexGrow={1}>
          <Stack
            direction="row"
            sx={{
              width: '100%',
              backgroundColor: 'white',
              borderTop: '1px solid',
              borderBottom: '1px solid',
              borderColor: 'divider',
              p: '7px',
              marginBottom: '14px',
              minHeight: 'max-content',
            }}
            alignItems="center"
            gap={2}
          >
            <Button
              size="small"
              startIcon={<Add />}
              variant="outlined"
              sx={{
                borderRadius: '4px',
                backgroundColor: theme.palette.primary.light,
              }}
              onClick={(_e) => {
                gridRef.current?.api.openToolPanel('filters');
              }}
            >
              Filter
            </Button>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
                flex: 1,
                overflowX: 'scroll',
              }}
            >
              <Box
                sx={{
                  whiteSpace: 'nowrap',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '5px',
                }}
              >
                {Object.keys(filterModel ?? {}).map((key) => (
                  <SelectedFilterButtonForToolbar
                    key={key}
                    keyName={key}
                    prependText={undefined}
                    filterModel={filterModel}
                    filterName={
                      columnDefinitions.find((col) => col.field === key)
                        ?.headerName
                    }
                    currentDefaultTabFilterModel={
                      stateRef.current.tab.filterModel
                    }
                    handleDelete={() => {
                      handleDeleteFilter(key as StopFilterField);
                    }}
                    handleSelect={() => {
                      handleClickSelectedFilter(key as StopFilterField);
                    }}
                  />
                ))}
              </Box>
            </Box>
            {/* TODO */}
            {/* <Tooltip title="Columns"> */}
            {/*  <IconButton */}
            {/*    color="primary" */}
            {/*    onClick={() => setShowColumnsModal(true)} */}
            {/*  > */}
            {/*    <ViewWeekIcon sx={{ fontSize: '15px' }} /> */}
            {/*  </IconButton> */}
            {/* </Tooltip> */}
          </Stack>
          <Box
            flexGrow={1}
            className="unassigned-stops unassigned-stops-v2 ag-theme-material ag-non-compact"
          >
            <AgGridReact<StopFragment>
              ref={gridRef}
              enableCellChangeFlash
              suppressCellFocus
              rowMultiSelectWithClick
              animateRows
              columnDefs={stateRef.current.columnDefs}
              defaultColDef={defaultColDef}
              rowModelType="serverSide"
              serverSideInitialRowCount={stopsPerPage}
              cacheBlockSize={stopsPerPage}
              maxConcurrentDatasourceRequests={1}
              rowBuffer={50}
              headerHeight={38}
              rowHeight={30}
              rowSelection="multiple"
              sideBar={sideBar}
              getRowId={(params) => params.data.stop.uuid}
              onGridReady={onGridReady}
              onBodyScrollEnd={handleScrollEnd}
              onColumnResized={handleColumnResized}
              onSortChanged={() => {
                refreshGrid();
              }}
              onToolPanelVisibleChanged={({ api }) => {
                toolPanelVisibleRef.current = api.isToolPanelShowing();
              }}
              onRowSelected={handleRowSelected}
              onSelectionChanged={(e) => {
                if (e.source === 'uiSelectAllCurrentPage') {
                  setAllSelected((prevAllSelected) => !prevAllSelected);
                }
              }}
              onPaginationChanged={handlePaginationChanged}
            />
          </Box>
        </Stack>
      </Stack>
      <ProposeTimesDialog
        open={proposeTimesDialogOpen}
        stops={selectedStops}
        onClose={() => {
          setProposeTimesDialogOpen(false);
        }}
        onSent={handleProposalsSent}
      />
      {!isNil(openedOrderUuid) && (
        <OrderDialog
          open
          orderUuid={openedOrderUuid}
          onClose={() => {
            setOpenedOrderUuid(undefined);
          }}
        />
      )}
    </>
  );
};
