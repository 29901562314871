import { Alert, Button, Snackbar, TableCell, TableRow } from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import React, { useContext, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  type DocumentUpdateInput,
  type OrderForOrderMatchingFragment,
  PermissionResource,
  PickupOrDelivery,
  useDetachDocumentFromShipmentMutation,
  useUpdateDocumentsMutation,
} from '../../../generated/graphql';
import EndOfDayContext from '../end-of-day-context';
import { type FetchCompanyDocumentsParams } from '../types/company-documents';
import { DOCUMENT_PAGE_SIZE } from './bulk-change-document-type';

type OrderMatchingRowProps = {
  readonly data: OrderForOrderMatchingFragment;
  readonly refreshOrders: () => void;

  readonly fetchCompanyDocuments: (params: FetchCompanyDocumentsParams) => void;
};

const OrderMatchingRow = ({
  data,
  refreshOrders,
  fetchCompanyDocuments,
}: OrderMatchingRowProps) => {
  const {
    setShouldLoadDocuments,
    selectedDocumentUuids,
    setSelectedDocumentUuids,
  } = useContext(EndOfDayContext);

  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteEndOfDay } = getPermissionsFlags(
    userPermissions,
    PermissionResource.EndOfDay,
  );

  const [detachDocumentFromShipment] = useDetachDocumentFromShipmentMutation();
  const [showMatchErrorSnackbar, setShowMatchErrorSnackbar] =
    useState<boolean>(false);
  const [showMatchSuccessSnackbar, setShowMatchSuccessSnackbar] =
    useState<boolean>(false);
  const [lastMatchedDocumentUuid, setLastMatchedDocumentUuid] = useState<
    string | undefined
  >();

  const [updateDocuments, { loading: updateDocumentsLoading }] =
    useUpdateDocumentsMutation();

  const handleMatchDocument = async () => {
    try {
      const orderUuid = data.uuid;
      const shipmentToMatch =
        data.shipments?.find(
          (sh) =>
            sh.standardShipmentFields?.pickupOrDelivery ===
              PickupOrDelivery.Pickup ||
            sh.standardShipmentFields?.pickupOrDelivery ===
              PickupOrDelivery.Delivery,
        ) ?? data?.shipments?.at(0);
      const shipmentUuid = shipmentToMatch?.uuid;
      if (isNil(shipmentUuid)) {
        setShowMatchErrorSnackbar(true);
        return;
      }
      const documentUpdateInputs: DocumentUpdateInput[] =
        selectedDocumentUuids.map((uuid) => {
          return {
            uuid,
            orderUuid,
            shipmentUuid,
          };
        });
      // Update the BOL document to set the type to bill of lading.
      await updateDocuments({
        variables: {
          updateDocumentsInput: {
            documentUpdateInputs,
          },
        },
      });
      fetchCompanyDocuments({ first: DOCUMENT_PAGE_SIZE });
      setShowMatchSuccessSnackbar(true);
      setSelectedDocumentUuids([]);
    } catch {
      setShowMatchErrorSnackbar(true);
    }
  };

  const handleUnmatchDocument = async () => {
    if (!isNil(lastMatchedDocumentUuid)) {
      const res = await detachDocumentFromShipment({
        variables: { uuid: lastMatchedDocumentUuid },
      });
      if (isNil(res.errors)) {
        setLastMatchedDocumentUuid(undefined);
        setShouldLoadDocuments(true);
        setShowMatchSuccessSnackbar(false);
        refreshOrders();
      } else {
        setShowMatchErrorSnackbar(true);
      }
    }
  };

  return (
    <TableRow
      hover
      sx={{
        '& > *': { borderBottom: 'unset' },
      }}
    >
      <TableCell align="left">{data.name}</TableCell>
      <TableCell align="left">
        {data.standardOrderFields?.shipperBillOfLadingNumber}
      </TableCell>
      <TableCell>
        <Button
          variant="contained"
          size="small"
          color="info"
          disabled={
            isEmpty(selectedDocumentUuids) ||
            updateDocumentsLoading ||
            !canWriteEndOfDay
          }
          onClick={handleMatchDocument}
        >
          Match
        </Button>
      </TableCell>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showMatchErrorSnackbar}
        autoHideDuration={3000}
        onClose={() => {
          setShowMatchErrorSnackbar(false);
        }}
      >
        <Alert
          severity="error"
          onClose={() => {
            setShowMatchErrorSnackbar(false);
          }}
        >
          An error occurred while matching.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showMatchSuccessSnackbar}
        autoHideDuration={6000}
        onClose={() => {
          setShowMatchSuccessSnackbar(false);
        }}
      >
        <Alert
          severity="success"
          action={
            <Button
              color="inherit"
              size="small"
              disabled={!canWriteEndOfDay}
              onClick={handleUnmatchDocument}
            >
              UNDO
            </Button>
          }
          onClose={() => {
            setShowMatchSuccessSnackbar(false);
          }}
        >
          Successfully matched order.
        </Alert>
      </Snackbar>
    </TableRow>
  );
};

export default OrderMatchingRow;
