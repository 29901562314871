import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import React, { type Dispatch, type SetStateAction } from 'react';

type ChangeSettlementStatusModalProps = {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly onConfirm: () => void;
  readonly finalizingAll?: boolean;
  readonly unfinalizing?: boolean;
};

const ChangeSettlementStatusModal = ({
  isOpen,
  setIsOpen,
  onConfirm,
  finalizingAll,
  unfinalizing,
}: ChangeSettlementStatusModalProps) => {
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="change-settlement-status-dialog-title"
      onClose={handleClose}
    >
      <DialogTitle id="change-settlement-status-dialog-title">
        {unfinalizing === true ? 'Unfinalize' : 'Finalize'} Driver Settlement
        {finalizingAll === true ? 's' : ''}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to {unfinalizing === true ? 'un' : ''}finalize
          the driver settlement
          {finalizingAll === true ? 's' : ''}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onConfirm}>
          {unfinalizing === true ? 'Unfinalize' : 'Finalize'}
          {finalizingAll === true ? ' All' : ''}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeSettlementStatusModal;
