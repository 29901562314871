import {
  Box,
  Button,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import currency from 'currency.js';
import { isNil } from 'lodash';
import { type ChangeEvent, type Dispatch, type SetStateAction } from 'react';
import { shallow } from 'zustand/shallow';
import { getValueWithinBounds } from './store/tariff-group-controller';
import useTariffGroupStore from './store/tariff-group-state-store';
import styles from './styles';

enum AmountInputType {
  Minimum = 'MINIMUM',
  Maximum = 'MAXIMUM',
}

const TariffUniversalRangeModal = ({
  universalRangeModalOpen,
  setUniversalRangeModalOpen,
}: {
  readonly universalRangeModalOpen: boolean;
  readonly setUniversalRangeModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const [
    minMaxAmountValues,
    settlementPercentageRateValues,
    setMinMaxAmountValue,
    setSettlementPercentageRateValue,
  ] = useTariffGroupStore(
    (state) => [
      state.minMaxAmountValues,
      state.settlementPercentageRateValues,
      state.setMinMaxAmountValue,
      state.setSettlementPercentageRateValue,
    ],
    shallow,
  );
  const updateMinMaxAmount = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowIndex: number,
    rate: number | undefined,
  ) => {
    const minMaxAmount = { ...minMaxAmountValues[rowIndex] };
    if (!isNil(minMaxAmount)) {
      if (event.target.name === AmountInputType.Minimum) {
        minMaxAmount.min = rate;
      } else {
        minMaxAmount.max = rate;
      }
    }
    setMinMaxAmountValue(rowIndex, minMaxAmount);
  };

  const validateAndUpdateRate = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowIndex: number,
  ) => {
    const parsed = Number.parseFloat(event.target.value);
    const rate = Number.isNaN(parsed)
      ? undefined
      : getValueWithinBounds(currency(parsed, { precision: 2 }).value);
    updateMinMaxAmount(event, rowIndex, rate);
  };

  const updateSettlementPercentageRate = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowIndex: number,
  ) => {
    const parsed = Number.parseFloat(event.target.value);
    const newSettlementRate = Number.isNaN(parsed) ? null : parsed;
    setSettlementPercentageRateValue(rowIndex, newSettlementRate);
  };

  return (
    <Modal
      open={universalRangeModalOpen}
      onClose={() => {
        setUniversalRangeModalOpen(false);
      }}
    >
      <Box sx={[styles.modal, { height: '20vh', width: '60vh' }]}>
        <Grid container spacing={3} sx={{ height: '100%' }}>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6">Edit Universal Ranges</Typography>
              <Button
                variant="contained"
                onClick={() => {
                  setUniversalRangeModalOpen(false);
                }}
              >
                Save
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} sx={styles.center}>
            {!isNil(minMaxAmountValues) && (
              <Stack
                spacing={2}
                sx={{ width: '100%' }}
                justifyContent="center"
                direction="row"
              >
                <TextField
                  name={AmountInputType.Minimum}
                  size="small"
                  label="Minimum Amount"
                  value={minMaxAmountValues[0]?.min ?? ''}
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  onBlur={(event) => {
                    validateAndUpdateRate(event, 0);
                  }}
                  onChange={(event) => {
                    updateMinMaxAmount(
                      event,
                      0,
                      Number.parseFloat(event.target.value),
                    );
                  }}
                  onWheel={(e) => {
                    (e.target as HTMLTextAreaElement).blur();
                  }}
                />
                <TextField
                  name={AmountInputType.Maximum}
                  size="small"
                  label="Maximum Amount"
                  value={minMaxAmountValues[0]?.max ?? ''}
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  onBlur={(event) => {
                    validateAndUpdateRate(event, 0);
                  }}
                  onChange={(event) => {
                    updateMinMaxAmount(
                      event,
                      0,
                      Number.parseFloat(event.target.value),
                    );
                  }}
                  onWheel={(e) => {
                    (e.target as HTMLTextAreaElement).blur();
                  }}
                />
                <TextField
                  size="small"
                  label="Settlement Rate"
                  value={
                    settlementPercentageRateValues[0]?.amount.toNumber() ?? ''
                  }
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  onBlur={(event) => {
                    updateSettlementPercentageRate(event, 0);
                  }}
                  onChange={(event) => {
                    updateSettlementPercentageRate(event, 0);
                  }}
                  onWheel={(e) => {
                    (e.target as HTMLTextAreaElement).blur();
                  }}
                />
              </Stack>
            )}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default TariffUniversalRangeModal;
