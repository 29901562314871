import { Box, type SxProps, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useGetDefaultTextsQuery } from '../../../generated/graphql';
import theme from '../../../theme';
import { type TextPreviewData } from '../types/text-preview-data';

const LEFT_RATIO = 7;
const RIGHT_RATIO = 5;

const GREEN_SELECTION_COLOR = '#E2FAE5';

const styles: Record<string, SxProps> = {
  viewContainer: {
    my: 1,
    borderRadius: '10px',
    height: '100%',
    overflow: 'scroll',
    padding: '12px',
  },
  appointmentItem: {
    width: '100%',
  },
  boldTitle: {
    fontWeight: 'bold',
    padding: '12px',
  },
};

type SendTextsViewProps = {
  readonly textPreviewData: TextPreviewData[];
};
const SendTextsView = ({ textPreviewData }: SendTextsViewProps) => {
  const [selectedPreviewIndex, setSelectedPreviewIndex] = useState(0);
  const { data: defaultTextData } = useGetDefaultTextsQuery({
    variables: {
      generateDefaultAppointmentTextsArgs: {
        appointmentUuids: textPreviewData.map((preview) => preview.uuid),
      },
    },
  });

  const stagedTextPreviewData = useMemo(
    () =>
      textPreviewData.map((txt) => ({
        ...txt,
        text:
          defaultTextData?.generateDefaultAppointmentTexts?.find(
            (d) => d.appointmentUuid === txt.uuid,
          )?.message ?? '',
      })),
    [textPreviewData, defaultTextData?.generateDefaultAppointmentTexts],
  );

  const handleSelectAppointment = (index: number) => {
    setSelectedPreviewIndex(index);
  };

  const selectedTextMessage = useMemo(
    () =>
      stagedTextPreviewData?.[selectedPreviewIndex]?.text ??
      'No message selected',
    [stagedTextPreviewData, selectedPreviewIndex],
  );

  return (
    <Box
      display="flex"
      minWidth="50vw"
      marginTop={2}
      flex={1}
      gap={1}
      flexWrap="wrap"
      minHeight={0}
    >
      <Box flex={1} minHeight={0} height="100%">
        <Box display="flex" flexDirection="column" height="100%">
          <Typography variant="h6">Appointments to Schedule</Typography>
          <Box
            sx={{ ...styles.viewContainer, border: '0.5px solid grey' }}
            flex={1}
            overflow="scroll"
          >
            <Box display="flex" flexDirection="row">
              <Typography
                sx={styles.boldTitle}
                flex={LEFT_RATIO}
                textAlign="left"
              >
                Consignee Name
              </Typography>
              <Typography
                sx={styles.boldTitle}
                flex={RIGHT_RATIO}
                textAlign="left"
              >
                Appointment
              </Typography>
            </Box>
            {stagedTextPreviewData.map((textPreview, index) => (
              <Box
                key={textPreview.uuid}
                display="flex"
                flexDirection="row"
                p="12px"
                borderRadius="10px"
                sx={{
                  backgroundColor:
                    index === selectedPreviewIndex
                      ? GREEN_SELECTION_COLOR
                      : 'initial',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  handleSelectAppointment(index);
                }}
              >
                <Box flex={LEFT_RATIO}>
                  <Typography
                    fontSize="13px"
                    fontWeight={
                      index === selectedPreviewIndex ? 'bold' : 'normal'
                    }
                  >
                    {textPreview.consigneeName}
                  </Typography>
                </Box>
                <Box flex={RIGHT_RATIO}>
                  <Typography
                    fontSize="13px"
                    fontWeight={
                      index === selectedPreviewIndex ? 'bold' : 'normal'
                    }
                  >
                    {textPreview.appointment}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box flex={1} minHeight={0}>
        <Box display="flex" flexDirection="column" height="100%">
          <Typography variant="h6">Text Preview</Typography>
          <Box
            sx={{
              ...styles.viewContainer,
              backgroundColor: theme.palette.background.default,
            }}
            flex={1}
          >
            <Typography>{selectedTextMessage}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SendTextsView;
