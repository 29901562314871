import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import {
  CircularProgress,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useEffect } from 'react';
import usePaginatedEmailProcessingHistory from './email-processing-history-store';

const useStyles = () => ({
  headerRow: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
});

export const EmailProcessingHistoryTablePagination = () => {
  const {
    emailProcessingHistoryLoading,
    hasNextPage,
    nextPage,
    hasPrevPage,
    prevPage,
  } = usePaginatedEmailProcessingHistory();
  return (
    <Stack direction="row">
      {emailProcessingHistoryLoading && <CircularProgress size={20} />}
      <IconButton
        disabled={emailProcessingHistoryLoading || hasPrevPage === false}
        onClick={async () => {
          prevPage();
        }}
      >
        <ChevronLeft />
      </IconButton>
      <IconButton
        disabled={emailProcessingHistoryLoading || hasNextPage === false}
        onClick={async () => {
          nextPage();
        }}
      >
        <ChevronRight />
      </IconButton>
    </Stack>
  );
};

const EmailProcessingHistoryTable = () => {
  const { emailProcessingHistoryData, refresh } =
    usePaginatedEmailProcessingHistory();
  const styles = useStyles();

  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <TableContainer sx={{ mt: '0!important' }}>
      <Table
        stickyHeader
        padding="normal"
        aria-label="email-processing-history-table"
        sx={{
          overflowY: 'scroll',
          border: '1px solid #e0e0e0',
          borderRadius: '5px',
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell sx={styles.headerRow}>Sender</TableCell>
            <TableCell sx={styles.headerRow}>Subject</TableCell>
            <TableCell sx={styles.headerRow}>Received at</TableCell>
            {/* <TableCell sx={styles.headerRow}>
              <Select
                value={statusFilter}
                onChange={(e) => {
                  setStatusFilterAndRefresh(
                    e.target.value as
                      | IngestedOutlookEmailAttachmentProcessingStatus
                      | 'All',
                  );
                }}
                sx={{
                  boxShadow: 'none',
                  '.MuiOutlinedInput-notchedOutline': { border: 0 },
                }}
                size="small"
                renderValue={(value) => {
                  return (
                    <Stack direction="column" alignItems="center" gap={1}>
                      <Typography variant="subtitle2">
                        Processing Status
                      </Typography>{' '}
                      {value !== 'All' && (
                        <IngestedOutlookEmailAttachmentProcessingStatusChip
                          status={value}
                        />
                      )}
                    </Stack>
                  );
                }}
              >
                {(
                  [
                    ...Object.values(
                      IngestedOutlookEmailAttachmentProcessingStatus,
                    ),
                    'All',
                  ] as const
                ).map((txnStatus) => (
                  <MenuItem key={txnStatus} value={txnStatus}>
                    <IngestedOutlookEmailAttachmentProcessingStatusChip
                      status={txnStatus}
                    />
                  </MenuItem>
                ))}
              </Select>
            </TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {emailProcessingHistoryData?.ingestedOutlookEmails.edges.map(
            (edge) => {
              return (
                <TableRow key={edge.node.uuid}>
                  <TableCell>{edge.node.effectiveSenderAddress}</TableCell>
                  <TableCell>{edge.node.subject}</TableCell>
                  <TableCell>
                    {new Date(
                      edge.node.outlookReceivedDateTime,
                    ).toLocaleString()}
                  </TableCell>
                </TableRow>
              );
            },
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EmailProcessingHistoryTable;
