import { Box, Checkbox, Stack, TextField } from '@mui/material';
import { union } from 'lodash';
import { memo, useMemo } from 'react';
import {
  DispatchTableColorField,
  type DispatchTableColorFragment,
} from '../../../generated/graphql';
import AutocompleteFuzzy from '../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import SettingsColorPicker from '../../daily-control-center/components/settings-color-picker';
import { ColorSettingFieldRow } from './color-setting-row-field';
import { type FieldOption, fieldOptions, SpecialField } from './field-options';

const ColorSettingRow = ({
  dispatchTableColor,
  onChange,
  onDelete,
}: {
  readonly dispatchTableColor: DispatchTableColorFragment;
  readonly onChange: (newData: DispatchTableColorFragment) => void;
  readonly onDelete: () => void;
}) => {
  const { fields } = dispatchTableColor;

  const filteredFieldOptions = useMemo(
    () =>
      fieldOptions.filter(({ value }) => {
        switch (value) {
          case SpecialField.HasAppointmentDateTrue:
          case SpecialField.HasAppointmentDateFalse: {
            return !fields.includes(DispatchTableColorField.HasAppointmentDate);
          }
          default: {
            return !fields.includes(value);
          }
        }
      }),
    [fields],
  );

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      gap={2}
    >
      <Checkbox
        checked={dispatchTableColor.active}
        onChange={(e) => {
          onChange({
            ...dispatchTableColor,
            active: e.target.checked,
          });
        }}
      />
      <Box sx={{ width: '20px' }}>
        <SettingsColorPicker
          currentColor={dispatchTableColor.color}
          setColor={(newColor) => {
            onChange({
              ...dispatchTableColor,
              color: newColor,
            });
          }}
          onChangeComplete={(newColor) => {
            onChange({
              ...dispatchTableColor,
              color: newColor,
            });
          }}
        />
      </Box>
      <Stack flexWrap="wrap" direction="row" columnGap={2} rowGap={1}>
        {fields.map((field, index) => (
          <ColorSettingFieldRow
            key={field}
            index={index}
            dispatchTableColor={dispatchTableColor}
            field={field}
            onChange={onChange}
            onDelete={() => {
              const newFields = fields.filter((f) => f !== field);
              if (newFields.length > 0) {
                onChange({
                  ...dispatchTableColor,
                  fields: newFields,
                });
              } else {
                onDelete();
              }
            }}
          />
        ))}
        <AutocompleteFuzzy<FieldOption, false, true, false>
          size="small"
          sx={{
            width: '190px',
          }}
          options={filteredFieldOptions}
          matchSortOptions={{ keys: ['label'] }}
          // @ts-expect-error -- This is needed to make the input controlled.
          value={null}
          blurOnSelect
          disableClearable
          renderInput={(params) => (
            <TextField
              {...params}
              label="Add condition"
              onClick={(e) => {
                e.stopPropagation();
              }}
              size="small"
            />
          )}
          onChange={(_event, option) => {
            let field: DispatchTableColorField;
            let hasAppointmentDate: boolean | undefined;
            if (option.value === SpecialField.HasAppointmentDateTrue) {
              field = DispatchTableColorField.HasAppointmentDate;
              hasAppointmentDate = true;
            } else if (option.value === SpecialField.HasAppointmentDateFalse) {
              field = DispatchTableColorField.HasAppointmentDate;
              hasAppointmentDate = false;
            } else {
              field = option.value;
            }
            onChange({
              ...dispatchTableColor,
              hasAppointmentDate,
              fields: union(fields, [field]),
            });
          }}
        />
      </Stack>
    </Stack>
  );
};

export default memo(ColorSettingRow);
