import { Box, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { BillingMethod } from '../../../../../../../common/types';
import { type OrderFormValues } from '../../../forms/types';

const RateField = ({
  idx,
  disabled,
}: {
  readonly idx: number;
  readonly disabled?: boolean;
}) => {
  const { control, setValue } = useFormContext<OrderFormValues>();
  const settlementDeductionPercentageRate = useWatch({
    control,
    name: `stops.${idx}.settlementDeductionPercentageRate`,
  });
  const settlementDeductionFlatRate = useWatch({
    control,
    name: `stops.${idx}.settlementDeductionFlatRate`,
  });
  const rate = settlementDeductionPercentageRate ?? settlementDeductionFlatRate;
  const [rateInput, setRateInput] = useState(rate?.toString() ?? '');
  const billingMethod =
    useWatch({ control, name: `stops.${idx}.settlementBillingMethod` }) ??
    BillingMethod.Percentage;

  useEffect(() => {
    setRateInput(rate?.toString() ?? '');
  }, [billingMethod, rate]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
      <TextField
        size="small"
        value={rateInput}
        disabled={disabled}
        onChange={(e) => {
          setRateInput(e.target.value);
          const parsedFloat = Number.parseFloat(e.target.value);
          if (billingMethod === BillingMethod.FlatRate) {
            if (Number.isNaN(parsedFloat)) {
              setValue(`stops.${idx}.settlementDeductionFlatRate`, 0);
            } else {
              setValue(`stops.${idx}.settlementDeductionFlatRate`, parsedFloat);
            }
          }
          if (billingMethod === BillingMethod.Percentage) {
            if (Number.isNaN(parsedFloat)) {
              setValue(`stops.${idx}.settlementDeductionPercentageRate`, 0);
            } else {
              setValue(
                `stops.${idx}.settlementDeductionPercentageRate`,
                parsedFloat,
              );
            }
          }
        }}
      />
    </Box>
  );
};

export const DriverSettlementDeductionRateField = React.memo(RateField);
