import {
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  // eslint-disable-next-line no-restricted-imports
  Grid,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { MultiInputTimeRangeField } from '@mui/x-date-pickers-pro/MultiInputTimeRangeField';
import { sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import { isNil, isNull } from 'lodash';
import { useState } from 'react';
import { exhaustive } from 'shared/switch';
import useMe from '../../../../../common/react-hooks/use-me';
import { SpecialDayOfWeek } from '../../../../../generated/graphql';
import { type SpecialAccessorialChargeGroupParams, FormMode } from '../common';

type SpecialAccessorialChargeGroupFieldProps = {
  readonly mode: FormMode;
  readonly chargeGroupParams: SpecialAccessorialChargeGroupParams;
  readonly onChange: (value: SpecialAccessorialChargeGroupParams) => void;
};

export const SpecialAccessorialChargeGroupField = ({
  mode,
  onChange,
  chargeGroupParams,
}: SpecialAccessorialChargeGroupFieldProps) => {
  const [currentParams, setCurrentParams] =
    useState<SpecialAccessorialChargeGroupParams>(chargeGroupParams);

  const { companyTimezone } = useMe();

  switch (mode) {
    case FormMode.EDIT:
    case FormMode.CREATE: {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{ position: 'relative', width: '40%', paddingRight: '20px' }}
            >
              <Typography sx={{ mb: '5px', textAlign: 'center' }}>
                Day of Week
              </Typography>
              <FormControl sx={{ width: '100%' }}>
                <Select
                  required
                  value={
                    isNil(currentParams.dayOfWeek)
                      ? ''
                      : sentenceCase(currentParams.dayOfWeek as string)
                  }
                  size="small"
                  sx={{ backgroundColor: 'white' }}
                  onChange={(e) => {
                    const newParams = {
                      ...currentParams,
                      dayOfWeek:
                        SpecialDayOfWeek[
                          e.target.value as keyof typeof SpecialDayOfWeek
                        ],
                    };
                    setCurrentParams(newParams);
                    onChange(newParams);
                  }}
                >
                  {Object.keys(SpecialDayOfWeek).map((identifier) => (
                    <MenuItem
                      key={identifier}
                      id={identifier}
                      value={identifier}
                    >
                      {sentenceCase(identifier)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ position: 'relative', width: '60%' }}>
              <Typography sx={{ mb: '5px', textAlign: 'center' }}>
                Time Range
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MultiInputTimeRangeField
                  slotProps={{
                    textField: ({ position }) => ({
                      label: position === 'start' ? 'From' : 'To',
                    }),
                  }}
                  ampm={false}
                  format="HH:mm"
                  defaultValue={[
                    dayjs(currentParams.startTime).tz(companyTimezone),
                    dayjs(currentParams.endTime).tz(companyTimezone),
                  ]}
                  onChange={(dateRange, context) => {
                    if (context.validationError.every(isNull)) {
                      const startTime = dateRange[0];
                      const endTime = dateRange[1];
                      const newParams = {
                        ...currentParams,
                        startTime:
                          !isNil(startTime) && !Number.isNaN(startTime)
                            ? dayjs(startTime).tz(companyTimezone).toDate()
                            : null,
                        endTime:
                          !isNil(endTime) && !Number.isNaN(endTime)
                            ? dayjs(endTime).tz(companyTimezone).toDate()
                            : null,
                      };
                      setCurrentParams(newParams);
                      onChange(newParams);
                    }
                  }}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
        </Grid>
      );
    }
    default: {
      return exhaustive(mode);
    }
  }
};
