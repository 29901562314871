import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import currency from 'currency.js';
import dayjs from 'dayjs';
import { isNil, sortBy, sum } from 'lodash';
import { transformAddressToFullAddressString } from 'shared/copy';
import {
  type InvoiceForOrderSummaryReportFragment,
  type MeQuery,
} from '../../../../../generated/graphql';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    paddingLeft: 23,
    paddingRight: 23,
    fontFamily: 'Roboto',
  },
  header1: {
    fontSize: '24px',
  },
  header2: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
});

const CELL_MARGIN = '5px';

export type GeneratedInvoiceOrderSummaryReportProps = {
  readonly companyData: MeQuery | undefined;
  readonly invoice: InvoiceForOrderSummaryReportFragment;
};

const GeneratedInvoiceRegisterReport = ({
  companyData,
  invoice,
}: GeneratedInvoiceOrderSummaryReportProps) => {
  const sortedShipments = sortBy(
    invoice.shipments,
    'order.standardOrderFields.shipperBillOfLadingNumber',
  );

  const companyAddress = companyData?.me?.company.defaultAddress;
  const contactAddress = invoice.billToContact.defaultAddress;
  const contactPerson = invoice.billToContact.defaultContactPerson;

  return (
    <Document title="Invoice Register Report">
      <Page size="LETTER" style={styles.page}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            fontSize: '12px',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text style={styles.header1}>Invoice Summary</Text>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '10px',
            fontSize: '12px',
          }}
        >
          <View>
            <Text style={styles.header2}>{companyData?.me?.company.name}</Text>
            <Text>
              {isNil(companyAddress)
                ? ''
                : transformAddressToFullAddressString(companyAddress)}
            </Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: CELL_MARGIN,
              justifyContent: 'space-between',
            }}
          >
            <View style={{ display: 'flex', flexDirection: 'column' }}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ fontWeight: 'bold' }}>
                  {invoice.billToContact.displayName}
                </Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Text>
                  {isNil(contactAddress)
                    ? ''
                    : transformAddressToFullAddressString(contactAddress)}
                </Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ marginLeft: '10px' }}>
                  {contactPerson?.firstName} {contactPerson?.lastName}
                </Text>
                {!isNil(contactPerson?.phone) && (
                  <View>
                    {' '}
                    <Text style={{ marginLeft: '15px' }}>Phone: </Text>
                    <Text style={{ marginLeft: '10px' }}>
                      {contactPerson?.phone}
                    </Text>
                  </View>
                )}
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Text>Email: </Text>
                <Text style={{ marginLeft: '10px' }}>
                  {invoice.billToContact.billingContactEmail}
                </Text>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ fontWeight: 'bold' }}>Date</Text>
                <Text style={{ marginLeft: '10px' }}>
                  {dayjs(invoice.date).format('MM/DD/YY')}
                </Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ fontWeight: 'bold' }}>Invoice #</Text>
                <Text style={{ marginLeft: '10px' }}>{invoice.name}</Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ fontWeight: 'bold' }}>Journal #</Text>
                <Text style={{ marginLeft: '10px' }}>
                  {invoice.journalNumber}
                </Text>
              </View>
            </View>
          </View>
          <View style={{ border: '1pt solid #181818', marginTop: '10px' }}>
            <View
              style={{
                fontSize: '8px',
                display: 'flex',
                flexDirection: 'row',
                fontWeight: 'bold',
                backgroundColor: '#BEBEBE',
                color: '#181818',
                paddingTop: '0px',
                padding: '5px',
              }}
            >
              <Text style={{ width: '90px' }}>Date</Text>
              <Text style={{ width: '150px' }}>HAWB</Text>
              <Text
                style={{
                  width: '130px',
                }}
              >
                Ref Number(s)
              </Text>
              <Text
                style={{
                  width: '200px',
                  marginLeft: CELL_MARGIN,
                }}
              >
                Shipper/Consignee
              </Text>
              <Text
                style={{
                  width: '100px',
                  marginLeft: CELL_MARGIN,
                }}
              >
                Stop Type
              </Text>
              <Text
                style={{
                  width: '30px',
                  marginLeft: CELL_MARGIN,
                }}
              >
                PC
              </Text>
              <Text
                style={{
                  width: '50px',
                  marginLeft: CELL_MARGIN,
                }}
              >
                Weight
              </Text>
              <Text
                style={{
                  width: '60px',
                  marginLeft: CELL_MARGIN,
                }}
              >
                Dim.
              </Text>
              <Text
                style={{
                  width: '90px',
                  marginLeft: CELL_MARGIN,
                }}
              >
                Total
              </Text>
              <Text
                style={{
                  width: '90px',
                  marginLeft: CELL_MARGIN,
                }}
              >
                Balance
              </Text>
            </View>
            {sortedShipments?.map((shipment) => {
              return (
                <View
                  key={shipment.uuid}
                  style={{
                    fontSize: '8px',
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '5px',
                    borderBottom: '1pt solid #BEBEBE',
                  }}
                >
                  <Text style={{ width: '90px' }}>
                    {isNil(shipment.legs?.at(0)?.endStop.completedAt)
                      ? '-'
                      : dayjs(shipment.legs?.at(0)?.endStop.completedAt).format(
                          'MM/DD/YY',
                        )}
                  </Text>
                  <Text style={{ width: '150px' }}>
                    {
                      shipment.order?.standardOrderFields
                        .shipperBillOfLadingNumber
                    }
                  </Text>
                  <Text
                    style={{
                      width: '130px',
                    }}
                  >
                    {shipment.order?.refNumbers.join(', ')}
                  </Text>
                  <Text
                    style={{
                      width: '200px',
                      marginLeft: CELL_MARGIN,
                    }}
                  >
                    {shipment.legs?.at(0)?.endStop.address.name}
                  </Text>
                  <Text
                    style={{
                      width: '100px',
                      marginLeft: CELL_MARGIN,
                    }}
                  >
                    {shipment.legs?.at(0)?.endStop.stopType}
                  </Text>
                  <Text
                    style={{
                      width: '30px',
                      marginLeft: CELL_MARGIN,
                    }}
                  >
                    {sum(shipment.order?.packages.map((pkg) => pkg.quantity))}
                  </Text>
                  <Text
                    style={{
                      width: '50px',
                      marginLeft: CELL_MARGIN,
                    }}
                  >
                    {shipment.order?.weight}
                  </Text>
                  <Text
                    style={{
                      width: '60px',
                      marginLeft: CELL_MARGIN,
                    }}
                  >
                    {shipment.order?.dimWeight}
                  </Text>
                  <Text
                    style={{
                      width: '90px',
                      marginLeft: CELL_MARGIN,
                    }}
                  >
                    {currency(shipment.totalChargesAmount).format()}
                  </Text>
                  <Text
                    style={{
                      width: '90px',
                      marginLeft: CELL_MARGIN,
                    }}
                  >
                    {currency(shipment.order?.balanceInCents ?? 0, {
                      fromCents: true,
                    }).format()}
                  </Text>
                </View>
              );
            })}
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: CELL_MARGIN,
                color: '#181818',
                padding: '10px',
              }}
            >
              <View
                style={{
                  fontSize: '8px',
                  display: 'flex',
                  flexDirection: 'row',
                  padding: '5px',
                  borderBottom: '1pt solid #BEBEBE',
                }}
              >
                <Text style={{ width: '860px' }} />
                <Text
                  style={{
                    width: '100px',
                    marginLeft: CELL_MARGIN,
                  }}
                >
                  {invoice.invoiceTotal}
                </Text>
                <Text
                  style={{
                    width: '90px',
                    marginLeft: CELL_MARGIN,
                  }}
                >
                  {currency(invoice.totalOpenAmount).format()}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default GeneratedInvoiceRegisterReport;
