import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  Typography,
  TableBody,
  Radio,
  IconButton,
  Collapse,
  Tooltip,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import currency from 'currency.js';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import React, {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  FuelProfileType,
  FuelProfilesDocument,
  useFuelProfilesLazyQuery,
  useUpdateFuelProfileMutation,
  type FuelProfileFragment,
  FuelBillingMethod,
} from '../../../generated/graphql';

type FuelProfileRowProps = {
  readonly fuelProfile: FuelProfileFragment;

  readonly selectedFuelProfileUuid: string | undefined;

  readonly setSelectedFuelProfileUuid: Dispatch<
    SetStateAction<string | undefined>
  >;
};
const FuelProfileRow = ({
  fuelProfile,
  selectedFuelProfileUuid,
  setSelectedFuelProfileUuid,
}: FuelProfileRowProps) => {
  const [openDateRanges, setOpenDateRanges] = React.useState(false);

  return (
    <>
      <TableRow
        key={fuelProfile.uuid}
        sx={{
          '&:last-child td, &:last-child th': { border: 0 },
        }}
      >
        <TableCell>
          <Radio
            checked={selectedFuelProfileUuid === fuelProfile.uuid}
            onChange={() => {
              setSelectedFuelProfileUuid(fuelProfile.uuid);
            }}
          />
        </TableCell>
        <TableCell>
          <Box>
            <Typography variant="body1">
              {fuelProfile.name ?? 'None'}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Box>
            <Typography variant="body1">
              {sentenceCase(fuelProfile.billingType)}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Tooltip arrow title="View date ranges">
            <IconButton
              aria-label="expand fuelProfile"
              size="small"
              onClick={() => {
                setOpenDateRanges(!openDateRanges);
              }}
            >
              {openDateRanges ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse unmountOnExit in={openDateRanges} timeout="auto">
            <Box sx={{ margin: 1 }}>
              <Typography gutterBottom variant="h6" component="div">
                Date Ranges
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Start Date</TableCell>
                    <TableCell>Expiration Date</TableCell>
                    <TableCell>Rate</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fuelProfile?.fuelProfileDateRanges?.map(
                    (fuelProfileDateRange) => {
                      return (
                        <TableRow key={fuelProfileDateRange.uuid}>
                          <TableCell>
                            {dayjs(fuelProfileDateRange.startDate).format(
                              'MM/DD/YYYY',
                            )}
                          </TableCell>
                          <TableCell>
                            {dayjs(fuelProfileDateRange.expirationDate).format(
                              'MM/DD/YYYY',
                            )}
                          </TableCell>
                          <TableCell>
                            {fuelProfile.billingType ===
                            FuelBillingMethod.Percentage
                              ? `${fuelProfileDateRange.surchargeRate ?? '-'}%`
                              : currency(
                                  fuelProfileDateRange.flatRateUsdCents ?? 0,
                                  { fromCents: true },
                                ).format()}
                          </TableCell>
                        </TableRow>
                      );
                    },
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const AddFuelProfileForContact = ({
  open,
  setOpen,
  contactUuid,
}: {
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
  readonly contactUuid: string;
}) => {
  const [selectedFuelProfileUuid, setSelectedFuelProfileUuid] =
    useState<string>();
  const [getFuelProfiles, { data: fuelProfilesToSelect }] =
    useFuelProfilesLazyQuery();
  const [updateFuelProfile] = useUpdateFuelProfileMutation({
    refetchQueries: [FuelProfilesDocument],
  });
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    getFuelProfiles({
      variables: {
        excludeContactUuid: contactUuid,
        type: FuelProfileType.ContactSpecific,
      },
    });
  }, [contactUuid]);

  const applyFuelProfile = async () => {
    if (isNil(selectedFuelProfileUuid)) {
      setErrorMessage('Select a fuel profile to apply');
    } else {
      const res = await updateFuelProfile({
        variables: { input: { uuid: selectedFuelProfileUuid, contactUuid } },
      });
      if (isNil(res.data?.updateFuelProfile)) {
        setErrorMessage('Overlapping with another fuel profile applied here.');
      } else {
        setOpen(false);
      }
    }
  };

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="md"
      onClose={() => {
        setOpen(false);
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          p: '20px',
        }}
      >
        <DialogTitle>Select fuel profile to apply</DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {fuelProfilesToSelect?.fuelProfiles.map((fuelProfile) => {
                  return (
                    <FuelProfileRow
                      fuelProfile={fuelProfile}
                      selectedFuelProfileUuid={selectedFuelProfileUuid}
                      setSelectedFuelProfileUuid={setSelectedFuelProfileUuid}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setOpen(false);
            }}
          >
            Close
          </Button>
          <Button variant="contained" onClick={applyFuelProfile}>
            Save
          </Button>
        </DialogActions>
        {errorMessage.length > 0 && (
          <Typography sx={{ color: 'red' }}>{errorMessage}</Typography>
        )}
      </Box>
    </Dialog>
  );
};

export default AddFuelProfileForContact;
