import {
  Alert,
  Button,
  Card,
  FormHelperText,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { Controller, type SubmitHandler } from 'react-hook-form';
import { type DateObject } from 'react-multi-date-picker';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ContactAutocompleteComponent from '../../../../common/components/contact-autocomplete-component';
import useContacts from '../../../../common/react-hooks/use-contacts';
import useWarehouses from '../../../../common/react-hooks/use-warehouses';
import { useCreateStorageOrderMutation } from '../../../../generated/graphql';
import useCreateStorageOrderForm, {
  type CreateStorageOrderFormValues,
} from '../../forms/storage-orders/use-create-storage-order-form';
import StyledDatePicker from '../common/styled-date-picker';
import WarehouseSelector from '../common/warehouse-selector';

const OPTIONAL_STORAGE_ORDER_FIELDS: Array<
  [keyof CreateStorageOrderFormValues, string]
> = [
  ['purchaseOrderNumber', 'Purchase order number'],
  ['carrier', 'Carrier'],
  ['trackingNumber', 'Tracking number'],
  ['supplierCompanyName', 'Supplier company'],
  ['lotNumber', 'Lot number'],
  ['consigneeName', 'Consignee'],
];

const StorageOrderSectionCard = styled(Card)`
  padding: 12px;
`;

const StorageOrderFormFieldContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const useStyles = () => ({
  buttonRowContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 1,
    margin: '8px 12px 0 12px',
  },
});

const CreateStorageOrderForm = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const { contacts } = useContacts();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useCreateStorageOrderForm();

  const [
    createStorageOrderSuccessMessageVisible,
    setCreateStorageOrderSuccessMessageVisible,
  ] = useState(false);

  const [
    createStorageOrderErrorMessageVisible,
    setCreateStorageOrderErrorMessageVisible,
  ] = useState(false);

  const [createStorageOrder, { loading: createStorageOrderLoading }] =
    useCreateStorageOrderMutation();
  const { warehouses } = useWarehouses();

  useEffect(() => {
    reset({
      arrivedAt: new Date(),
      warehouse:
        warehouses?.length === 1 && !isNil(warehouses[0])
          ? {
              label: warehouses[0].name,
              value: warehouses[0].uuid,
            }
          : undefined,
    });
  }, [contacts, reset, warehouses]);

  const onSubmit: SubmitHandler<CreateStorageOrderFormValues> = async (
    data: CreateStorageOrderFormValues,
  ) => {
    const {
      contactUuid,
      referenceNumber,
      purchaseOrderNumber,
      carrier,
      trackingNumber,
      supplierCompanyName,
      lotNumber,
      consigneeName,
      notes,
      pickupDate,
      expectedAt,
      arrivedAt,
    } = data;
    try {
      const response = await createStorageOrder({
        variables: {
          createStorageOrderInput: {
            contactUuid,
            warehouseUuid: data.warehouse.value,
            referenceNumber,
            purchaseOrderNumber,
            carrier,
            trackingNumber,
            supplierCompanyName,
            lotNumber,
            consigneeName,
            notes,
            pickupDate,
            expectedAt,
            arrivedAt,
          },
        },
      });
      setCreateStorageOrderSuccessMessageVisible(true);
      const storageOrderUuid =
        response?.data?.createStorageOrder?.storageOrder?.uuid;
      navigate(`/warehouse/storage-orders/${storageOrderUuid}`, {
        replace: true,
      });
    } catch {
      setCreateStorageOrderErrorMessageVisible(true);
    }
  };

  return (
    <Grid container spacing={2} sx={{ backgroundColor: 'white', flex: 1 }}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={createStorageOrderSuccessMessageVisible}
      >
        <Alert
          onClose={() => {
            setCreateStorageOrderSuccessMessageVisible(false);
          }}
        >
          Successfully created receipt
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={createStorageOrderErrorMessageVisible}
      >
        <Alert
          color="error"
          onClose={() => {
            setCreateStorageOrderErrorMessageVisible(false);
          }}
        >
          Error creating receipt
        </Alert>
      </Snackbar>
      <Grid
        item
        xs={12}
        sx={{ ...styles.buttonRowContainer, justifyContent: 'space-between' }}
      >
        <Button
          variant="outlined"
          disabled={createStorageOrderLoading}
          onClick={() => {
            if (globalThis.history.length > 1) {
              navigate(-1);
            } else {
              navigate('/warehouse/storage-orders');
            }
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          disabled={createStorageOrderLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </Button>
      </Grid>
      <Grid item xs={12}>
        <StorageOrderSectionCard>
          <Grid container spacing={2}>
            <StorageOrderFormFieldContainer item xs={3}>
              <Controller
                name="contactUuid"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <ContactAutocompleteComponent
                      required
                      billingPartyContactUuid={value}
                      label="Customer"
                      onChange={onChange}
                    />
                    {!isNil(errors.contactUuid) && (
                      <FormHelperText sx={{ color: '#D32F2F' }}>
                        {errors.contactUuid?.message ?? ''}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </StorageOrderFormFieldContainer>
            {(warehouses?.length ?? 0) > 1 && (
              <StorageOrderFormFieldContainer item xs={3}>
                <Controller
                  name="warehouse"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <WarehouseSelector
                        whiteBackground
                        required
                        value={value}
                        error={!isNil(errors.warehouse)}
                        width="100%"
                        onChange={onChange}
                      />
                      {!isNil(errors.warehouse) && (
                        <FormHelperText sx={{ color: '#D32F2F' }}>
                          {errors.warehouse?.message}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </StorageOrderFormFieldContainer>
            )}
            <StorageOrderFormFieldContainer item xs={3}>
              <Controller
                name="referenceNumber"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <TextField
                      required
                      name="referenceNumber"
                      label="Reference number"
                      size="small"
                      value={value}
                      error={!isNil(errors.referenceNumber)}
                      sx={{ width: '100%' }}
                      onChange={onChange}
                    />
                    {!isNil(errors.referenceNumber) && (
                      <FormHelperText sx={{ color: '#D32F2F' }}>
                        {errors.referenceNumber?.message ?? ''}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </StorageOrderFormFieldContainer>
            <StorageOrderFormFieldContainer item xs={3}>
              <Controller
                name="arrivedAt"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <StyledDatePicker
                      required
                      title="Arrived at"
                      value={value}
                      containerStyle={{ width: '100%' }}
                      error={!isNil(errors.arrivedAt)}
                      name="Arrived At"
                      format="MM/DD/YYYY"
                      onChange={(newDate) => {
                        onChange(
                          newDate?.isValid
                            ? dayjs(newDate.toDate())
                                .startOf('day')
                                .add(12, 'hours')
                                .toISOString()
                            : null,
                        );
                      }}
                    />
                    {!isNil(errors.arrivedAt) && (
                      <FormHelperText sx={{ color: '#D32F2F' }}>
                        {errors.arrivedAt?.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </StorageOrderFormFieldContainer>
            {OPTIONAL_STORAGE_ORDER_FIELDS.map(([fieldName, label]) => (
              <StorageOrderFormFieldContainer key={fieldName} item xs={3}>
                <Controller
                  name={fieldName}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <TextField
                        name={fieldName}
                        label={label}
                        size="small"
                        value={value}
                        error={!isNil(errors[fieldName])}
                        sx={{ width: '100%' }}
                        onChange={onChange}
                      />
                      {!isNil(errors[fieldName]) && (
                        <FormHelperText sx={{ color: '#D32F2F' }}>
                          {errors[fieldName]?.message ?? ''}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </StorageOrderFormFieldContainer>
            ))}
          </Grid>
        </StorageOrderSectionCard>
      </Grid>
      <Grid item xs={6}>
        <StorageOrderSectionCard>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Notes</Typography>
            </Grid>
            <StorageOrderFormFieldContainer item xs={12}>
              <Controller
                name="notes"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <TextField
                      required
                      multiline
                      name="notes"
                      size="small"
                      value={value}
                      error={!isNil(errors.notes)}
                      sx={{ width: '100%' }}
                      rows={3}
                      onChange={onChange}
                    />
                    {!isNil(errors.notes) && (
                      <FormHelperText sx={{ color: '#D32F2F' }}>
                        {errors.notes?.message ?? ''}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </StorageOrderFormFieldContainer>
          </Grid>
        </StorageOrderSectionCard>
      </Grid>
    </Grid>
  );
};

export default CreateStorageOrderForm;
