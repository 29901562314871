// eslint-disable-next-line no-restricted-imports
import { Grid } from '@mui/material';
import React from 'react';
import { type BatchAddShipmentsToInvoicesPreviewFragment } from '../../../../generated/graphql';
import ContactPreviewCard from './contact-preview-card';

const BatchInvoicesPreview = ({
  serviceUuid,
  batchAddShipmentsToInvoicesPreview,
}: {
  readonly serviceUuid: string | undefined;
  readonly batchAddShipmentsToInvoicesPreview: BatchAddShipmentsToInvoicesPreviewFragment[];
}) => {
  return (
    <Grid container sx={{ height: '100%' }}>
      <Grid item xs={12} sx={{ height: '70vh', overflowY: 'scroll' }}>
        {batchAddShipmentsToInvoicesPreview.map((contactPreview) => (
          <ContactPreviewCard
            key={contactPreview.contactUuid}
            serviceUuid={serviceUuid}
            contactPreview={contactPreview}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default BatchInvoicesPreview;
