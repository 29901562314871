import {
  Alert,
  Box,
  Button,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getPermissionsFlags } from 'shared/roles';
import { shallow } from 'zustand/shallow';
import { FeatureFlag } from '../../../common/feature-flags';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  PermissionResource,
  useFindDefaultTariffGroupForCompanyQuery,
  useFindDefaultTransferTariffGroupForCompanyQuery,
} from '../../../generated/graphql';
import PalletRouterLink from '../../../pallet-ui/links/router-link/pallet-router-link';
import CreateTariffGroupModal from './tariffs/create-tariff-group-modal';
import EditTariffGroupModal from './tariffs/edit-tariff-group-modal';
import useTariffPageStore from './tariffs/store/tariff-page-store';
import AllTariffsTable from './tariffs/tariffs-table/all-tariffs-table';
import { DemoAllTariffsTable } from './tariffs/tariffs-table/demo/demo-all-tariffs-table';

const TariffsPage = () => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteCompanyTariffs } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyTariffs,
  );
  const ffDemoSterlingTariffs = useFeatureFlag(
    FeatureFlag.FF_DEMO_STERLING_TARIFFS,
  );

  const navigate = useNavigate();
  const [
    showCreateDefaultTariffGroupModal,
    setShowCreateDefaultTariffGroupModal,
  ] = useState<boolean>(false);
  const [
    showCreateDefaultTransferTariffGroupModal,
    setShowCreateDefaultTransferTariffGroupModal,
  ] = useState<boolean>(false);
  const [showCreateTariffGroupModal, setShowCreateTariffGroupModal] =
    useState<boolean>(false);
  const [
    showEditTransferTariffGroupModal,
    setShowEditTransferTariffGroupModal,
  ] = useState<boolean>(false);
  const [showEditTariffGroupModal, setShowEditTariffGroupModal] =
    useState<boolean>(false);
  const { data: defaultTariffForCompany, loading } =
    useFindDefaultTariffGroupForCompanyQuery();

  const {
    data: defaultTransferTariffForCompany,
    loading: loadingDefaultTransferTariff,
  } = useFindDefaultTransferTariffGroupForCompanyQuery();

  const [errorMessage, setErrorMessage] = useTariffPageStore(
    (state) => [state.errorMessage, state.setErrorMessage],
    shallow,
  );

  return (
    <Box sx={{ px: 2 }}>
      <Grid container spacing="2" rowSpacing={5}>
        <Grid item xs={12}>
          <Grid container spacing="2">
            <Grid item xs={4}>
              <Typography variant="h6">Tariffs</Typography>
            </Grid>
            <Grid item xs={8}>
              <Stack direction="row" spacing={2} sx={{ float: 'right' }}>
                {isNil(
                  defaultTariffForCompany?.findDefaultTariffGroupForCompany,
                ) &&
                  !loading && (
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={!canWriteCompanyTariffs}
                      onClick={() => {
                        setShowCreateDefaultTariffGroupModal(true);
                      }}
                    >
                      Create Default Tariff
                    </Button>
                  )}
                {!isNil(
                  defaultTariffForCompany?.findDefaultTariffGroupForCompany,
                ) && (
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={!canWriteCompanyTariffs}
                    onClick={() => {
                      setShowEditTariffGroupModal(true);
                    }}
                  >
                    Edit Default Tariff
                  </Button>
                )}
                {isNil(
                  defaultTransferTariffForCompany?.findDefaultTransferTariffGroupForCompany,
                ) &&
                  !loadingDefaultTransferTariff && (
                    <Button
                      variant="outlined"
                      disabled={!canWriteCompanyTariffs}
                      onClick={() => {
                        setShowCreateDefaultTransferTariffGroupModal(true);
                      }}
                    >
                      Create Default Transfer Tariff
                    </Button>
                  )}
                {!isNil(
                  defaultTransferTariffForCompany?.findDefaultTransferTariffGroupForCompany,
                ) &&
                  !loadingDefaultTransferTariff && (
                    <Button
                      variant="outlined"
                      disabled={!canWriteCompanyTariffs}
                      onClick={() => {
                        setShowEditTransferTariffGroupModal(true);
                      }}
                    >
                      Edit Default Transfer Tariff
                    </Button>
                  )}
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!canWriteCompanyTariffs}
                  onClick={() => {
                    setShowCreateTariffGroupModal(true);
                  }}
                >
                  Create New Tariff
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        {ffDemoSterlingTariffs ? <DemoAllTariffsTable /> : <AllTariffsTable />}
        <Grid item xs={9}>
          <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
            Please note - zone configuration has moved to the{' '}
            <PalletRouterLink to="/management?tab=19">Zones</PalletRouterLink>{' '}
            tab.
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Stack direction="row" spacing={2} sx={{ float: 'right' }}>
            <Button
              variant="outlined"
              color="primary"
              disabled={!canWriteCompanyTariffs}
              onClick={() => {
                navigate(`/management/tariff-zones/edit?isTerminal=true`);
              }}
            >
              Edit Terminal Tariff Zones
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <CreateTariffGroupModal
        isCompanyDefaultTariff
        isOpen={showCreateDefaultTariffGroupModal}
        setIsOpen={setShowCreateDefaultTariffGroupModal}
      />
      <CreateTariffGroupModal
        isCompanyDefaultTariff
        isTransferTariff
        isOpen={showCreateDefaultTransferTariffGroupModal}
        setIsOpen={setShowCreateDefaultTransferTariffGroupModal}
      />
      <CreateTariffGroupModal
        isOpen={showCreateTariffGroupModal}
        setIsOpen={setShowCreateTariffGroupModal}
      />
      {!isNil(
        defaultTariffForCompany?.findDefaultTariffGroupForCompany?.uuid,
      ) && (
        <EditTariffGroupModal
          isCompanyDefaultTariff
          isOpen={showEditTariffGroupModal}
          setIsOpen={setShowEditTariffGroupModal}
          tariffGroupUuid={
            defaultTariffForCompany?.findDefaultTariffGroupForCompany?.uuid ??
            ''
          }
        />
      )}
      {!isNil(
        defaultTransferTariffForCompany
          ?.findDefaultTransferTariffGroupForCompany?.uuid,
      ) && (
        <EditTariffGroupModal
          isCompanyDefaultTariff
          isTransferTariff
          isOpen={showEditTransferTariffGroupModal}
          setIsOpen={setShowEditTransferTariffGroupModal}
          tariffGroupUuid={
            defaultTransferTariffForCompany
              ?.findDefaultTransferTariffGroupForCompany?.uuid ?? ''
          }
        />
      )}
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!isNil(errorMessage)}
        onClose={() => {
          setErrorMessage(null);
        }}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
    </Box>
  );
};

export default TariffsPage;
