import { isNil } from 'lodash';
import { type Resolver, useForm } from 'react-hook-form';
import {
  AutoApplyAccessorialRuleType,
  PickupOrDelivery,
} from '../../../generated/graphql';

export type EditAutoApplyAccessorialRuleFormValues = {
  accessorialUuids: string[];

  ruleType: AutoApplyAccessorialRuleType;

  serviceUuid: string | null;

  stopType: PickupOrDelivery;

  contactUuid: string | null;

  contactsToIncludeUuids: string[];

  contactsToExcludeUuids: string[];
};

const resolver: Resolver<EditAutoApplyAccessorialRuleFormValues> = async (
  formValues,
) => {
  const errors: {
    [property in keyof EditAutoApplyAccessorialRuleFormValues]?: {
      type: string;
      message: string;
    };
  } = {};

  if (formValues.accessorialUuids.length === 0) {
    errors.accessorialUuids = {
      type: 'required',
      message: 'Accessorial is required',
    };
  }

  if (isNil(formValues.ruleType)) {
    errors.ruleType = {
      type: 'required',
      message: 'Rule type is required',
    };
  }

  if (
    formValues.ruleType === AutoApplyAccessorialRuleType.Service &&
    isNil(formValues.serviceUuid)
  ) {
    errors.serviceUuid = {
      type: 'required',
      message: 'Service is required',
    };
  }

  if (
    formValues.ruleType === AutoApplyAccessorialRuleType.StopType &&
    isNil(formValues.stopType)
  ) {
    errors.stopType = {
      type: 'required',
      message: 'Stop type is required',
    };
  }

  if (
    formValues.ruleType === AutoApplyAccessorialRuleType.Contact &&
    isNil(formValues.contactUuid)
  ) {
    errors.contactUuid = {
      type: 'required',
      message: 'Contact is required',
    };
  }

  return {
    values: formValues,
    errors,
  };
};

const useEditAutoApplyAccessorialRuleForm = () => {
  return useForm({
    resolver,
    defaultValues: {
      accessorialUuids: [],
      ruleType: AutoApplyAccessorialRuleType.Contact,
      serviceUuid: null,
      stopType: PickupOrDelivery.Transfer,
      contactUuid: null,
      contactsToIncludeUuids: [],
      contactsToExcludeUuids: [],
    },
  });
};

export default useEditAutoApplyAccessorialRuleForm;
