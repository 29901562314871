import {
  Box,
  Button,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  type SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import React, {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useMemo,
} from 'react';
import { COLORS } from '../../../common/constants';
import {
  EquipmentType,
  type SameDayDispatchCellHeader,
  type SameDayDispatchConfigFragment,
  type SameDayDispatchRouteFragment,
  StopStatus,
  useRouteEtaQuery,
} from '../../../generated/graphql';
import { stopIsComplete } from '../utils';
import DailyControlCenterStopItem from './daily-control-center-stop-item';

const useStyles = (): Record<string, SxProps> => {
  const theme = useTheme();
  return {
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      '&:hover': {
        backgroundColor: '#f7f7f7',
      },
    },
    driverNameText: {
      color: '#000',
      fontWeight: 700,
      fontSize: '14px',
    },
    stopsStatusesRow: {
      display: 'flex',
      flexDirection: 'column',
    },
    stopStatusText: {
      fontSize: '12px',
    },
    helperText: {
      p: 0,
      fontSize: '10px',
      color: '#000',
    },
    blueLink: {
      color: theme.palette.blueLink.main,
      fontWeight: 700,
      fontSize: '14px',
    },
    stopsSection: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingTop: 1,
      paddingBottom: 1,
      overflowX: 'scroll',
    },
    viewDetailsButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
};

type DailyControlCenterRowProps = {
  readonly handleClick: () => void;
  readonly isSelected: boolean;
  readonly route: SameDayDispatchRouteFragment;
  readonly routeIndex: number;
  readonly nextSlotUuid: string | undefined;
  readonly warehouseUuid: string | undefined;
  readonly sameDayDispatchCellHeader: SameDayDispatchCellHeader;
  readonly colorCodingConfig: SameDayDispatchConfigFragment | null | undefined;
  readonly setRouteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly setModalRoute: Dispatch<
    SetStateAction<SameDayDispatchRouteFragment | undefined>
  >;
  readonly setEtaErrorMsg: Dispatch<SetStateAction<string | undefined>>;
  readonly showVehicle: boolean;
};

export const stopIsCompleted = (stopStatus: StopStatus | null | undefined) => {
  if (isNil(stopStatus)) {
    return false;
  }
  return [
    StopStatus.Completed,
    StopStatus.Failed,
    StopStatus.Cancelled,
  ].includes(stopStatus);
};

const DailyControlCenterRow = ({
  handleClick,
  isSelected,
  route,
  routeIndex,
  nextSlotUuid,
  warehouseUuid,
  sameDayDispatchCellHeader,
  colorCodingConfig,
  setRouteModalOpen,
  setModalRoute,
  showVehicle,
  setEtaErrorMsg,
}: DailyControlCenterRowProps) => {
  const theme = useTheme();
  const { data: routeEtaData, error } = useRouteEtaQuery({
    variables: {
      uuid: route.uuid,
    },
  });
  const styles = useStyles();
  const routeDriver = route.drivers[0];
  const driverName = isNil(routeDriver)
    ? 'No driver'
    : `${routeDriver.firstName} ${routeDriver.lastName}`;
  const helperNames = route.helpers
    .map((helper) => `${helper.firstName} ${helper.lastName}`)
    .join(', ');
  let vehicle: { uuid: string; name: string } | undefined =
    route.equipments.find(
      (equipment) => equipment.type === EquipmentType.Vehicle,
    );
  if (isNil(vehicle)) {
    const defaultVehicle = route.drivers[0]?.defaultVehicle;
    vehicle = isNil(defaultVehicle)
      ? undefined
      : { uuid: defaultVehicle.uuid, name: defaultVehicle.name };
  }

  const color = isSelected
    ? 'black'
    : (COLORS[routeIndex % COLORS.length] ?? theme.palette.primary.main);

  const notCompletedRouteSlots = useMemo(
    () =>
      route.slots.filter((slot) => {
        const stop = slot.stops[0];
        const order = stop?.leg.shipment?.order;
        if (!isEmpty(warehouseUuid)) {
          return (
            !stopIsComplete(stop?.status) &&
            order?.warehouse?.uuid === warehouseUuid
          );
        }
        return !stopIsComplete(stop?.status);
      }),
    [route, warehouseUuid],
  );

  const successfulCompletionStops = useMemo(() => {
    return route.slots.filter((slot) => {
      const stop = slot.stops[0];
      const order = stop?.leg.shipment?.order;
      if (!isEmpty(warehouseUuid)) {
        return (
          stop?.status === StopStatus.Completed &&
          order?.warehouse?.uuid === warehouseUuid
        );
      }
      return stop?.status === StopStatus.Completed;
    });
  }, [route, warehouseUuid]);

  const attemptedStops = useMemo(() => {
    return route.slots.filter((slot) => {
      const stop = slot.stops[0];
      const order = stop?.leg.shipment?.order;
      if (!isEmpty(warehouseUuid)) {
        return (
          stop?.status === StopStatus.Failed &&
          order?.warehouse?.uuid === warehouseUuid
        );
      }
      return stop?.status === StopStatus.Failed;
    });
  }, [route, warehouseUuid]);

  const filteredRouteSlots = useMemo(() => {
    return route.slots.filter((slot) => {
      const stop = slot.stops[0];
      const order = stop?.leg.shipment?.order;
      return isEmpty(warehouseUuid)
        ? true
        : order?.warehouse?.uuid === warehouseUuid;
    });
  }, [route, warehouseUuid]);

  const completedStops =
    filteredRouteSlots.length - notCompletedRouteSlots.length;

  const handleViewDetails = () => {
    setModalRoute(route);
    setRouteModalOpen(true);
  };

  let cumulativeDurationSeconds = 0;
  let cumulativeDurationMeters = 0;

  useEffect(() => {
    if (!isNil(error)) {
      setEtaErrorMsg(`Failed to fetch etas for route ${route.name}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <Grid
      container
      sx={{
        ...styles.container,
        backgroundColor: isSelected ? '#E5FFF4' : undefined,
        cursor: 'pointer',
      }}
      spacing={0}
      onClick={handleClick}
    >
      <Grid
        item
        xs={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 1,
          position: 'relative',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {route.nameManuallyChanged && (
              <Typography sx={styles.driverNameText}>
                {route.name}&nbsp;-
              </Typography>
            )}
            <Typography sx={styles.driverNameText}>{driverName}</Typography>
          </Box>
          <Box
            sx={{
              width: 10,
              height: 10,
              borderRadius: '50%',
              backgroundColor: color,
              mr: '8px',
            }}
          />
        </Box>
        {helperNames.length > 0 && (
          <Typography sx={styles.helperText}>
            Helper(s): {helperNames}
          </Typography>
        )}
        {!isNil(vehicle) && showVehicle && (
          <Typography sx={styles.helperText}>
            Vehicle: {vehicle.name}
          </Typography>
        )}
      </Grid>
      <Grid item xs={2} sx={styles.stopsStatusesRow}>
        <Typography sx={{ ...styles.blueLink, width: '100%' }} align="center">
          {completedStops} / {filteredRouteSlots.length} complete
        </Typography>
        <Typography sx={styles.stopStatusText} align="center">
          {successfulCompletionStops.length} successful
        </Typography>
        <Typography sx={styles.stopStatusText} align="center">
          {attemptedStops.length} attempt
        </Typography>
      </Grid>
      <Grid item xs={6} sx={styles.stopsSection}>
        {route.slots
          .filter((slot) => {
            const stop = slot.stops[0];
            const order = stop?.leg.shipment?.order;
            if (!isEmpty(warehouseUuid)) {
              return (
                !stopIsComplete(stop?.status) &&
                order?.warehouse?.uuid === warehouseUuid
              );
            }
            return !stopIsComplete(stop?.status);
          })
          .map((routeSlot) => {
            cumulativeDurationSeconds +=
              routeSlot.previousSlotDurationSeconds ?? 0;
            cumulativeDurationMeters +=
              routeSlot.previousSlotDistanceMeters ?? 0;
            const idxOfSlot = route.slots.findIndex(
              (slot) => slot.uuid === routeSlot.uuid,
            );
            const eta = routeEtaData?.route.etas[idxOfSlot];
            return (
              <DailyControlCenterStopItem
                key={routeSlot.uuid}
                isNext={nextSlotUuid === routeSlot.uuid}
                cumulativeDurationSeconds={cumulativeDurationSeconds}
                cumulativeDistanceMeters={cumulativeDurationMeters}
                routeSlot={routeSlot}
                sameDayDispatchCellHeader={sameDayDispatchCellHeader}
                colorCodingConfig={colorCodingConfig}
                arrivalTime={eta?.arrivalTime}
                finishTime={eta?.finishTime}
                idxOfSlot={idxOfSlot}
              />
            );
          })}
      </Grid>
      {isSelected && (
        <Grid item xs={2} sx={styles.viewDetailsButtonContainer}>
          <Button variant="contained" size="small" onClick={handleViewDetails}>
            View Details
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
export default React.memo(DailyControlCenterRow);
