import { ExpandMore } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { isNil } from 'lodash';
import { useState } from 'react';

type OutstandingOrderStatusBannerProps = {
  readonly text: string;
  readonly buttonLabel?: string;
  readonly textIsBold?: boolean;
  readonly onClick?: () => void;
  readonly dropdownLabel?: string;
  readonly dropdownMenuItems?: Array<{
    label: string;
    onClick: () => void;
  }>;
};

const OutstandingOrderStatusBanner = ({
  text,
  buttonLabel,
  textIsBold,
  onClick,
  dropdownLabel,
  dropdownMenuItems,
}: OutstandingOrderStatusBannerProps) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const theme = useTheme();
  return (
    <Box
      sx={{
        p: '10px',
        backgroundColor: theme.palette.blueBanner.main,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        gap: '10px',
      }}
    >
      <Stack direction="row" gap={1}>
        <InfoIcon sx={{ color: theme.palette.blueBanner.light }} />
      </Stack>
      <Stack alignItems="flex-start" gap={1}>
        <Typography
          sx={{
            fontWeight: textIsBold === true ? 'bold' : 'normal',
            fontSize: '16px',
            color: theme.palette.blueBanner.dark,
          }}
        >
          {text}
        </Typography>
        {!isNil(onClick) && !isNil(buttonLabel) && (
          <Button onClick={onClick}>{buttonLabel}</Button>
        )}
        {!isNil(dropdownLabel) && !isNil(dropdownMenuItems) && (
          <>
            <Button
              sx={{ p: 0 }}
              onClick={(e) => {
                setMenuAnchorEl(e.currentTarget);
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                }}
                color={theme.palette.blueBanner.light}
              >
                <Typography fontWeight={500}>{dropdownLabel}</Typography>
                <ExpandMore fontSize="small" sx={{ mr: 0 }} />
              </Box>
            </Button>
            <Menu
              anchorEl={menuAnchorEl}
              id="outstanding-order-status-menu"
              data-testid="outstanding-order-status-menu"
              open={Boolean(menuAnchorEl)}
              sx={{
                '& .MuiMenu-paper': { overflow: 'visible' },
                top: '3px',
              }}
              onClose={() => {
                setMenuAnchorEl(null);
              }}
            >
              <MenuList>
                {dropdownMenuItems?.map((menuItem) => (
                  <MenuItem key={null} onClick={menuItem.onClick}>
                    <Stack direction="row" gap={2}>
                      <Typography color={theme.palette.primary.main}>
                        {menuItem.label}
                      </Typography>
                      <OpenInNewIcon color="primary" />
                    </Stack>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </>
        )}
      </Stack>
    </Box>
  );
};

export default OutstandingOrderStatusBanner;
