import { Tab, Tabs, Box } from '@mui/material';
import { useState } from 'react';
import TabPanel from '../../../common/components/tab-panel/tab-panel';
import EquipmentTable from './equipment-table';
import VehicleTypes from './vehicle-types/vehicle-types';

const EquipmentTab = () => {
  const [tabIndex, setTabIndex] = useState<number>(0);

  return (
    <Box>
      <Tabs
        orientation="horizontal"
        variant="scrollable"
        value={tabIndex}
        aria-label="equipment tabs"
        sx={{
          mt: '-10px',
          ml: 1,
        }}
        onChange={(e, newIndex) => {
          setTabIndex(newIndex);
        }}
      >
        <Tab label="Equipment" />
        <Tab label="Vehicle Types" />
      </Tabs>
      <TabPanel selectedValue={tabIndex} panelValue={0}>
        <EquipmentTable />
      </TabPanel>
      <TabPanel selectedValue={tabIndex} panelValue={1}>
        <VehicleTypes />
      </TabPanel>
    </Box>
  );
};

export default EquipmentTab;
