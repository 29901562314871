import { type AgGridReactProps } from 'ag-grid-react';
import { isNil } from 'lodash';
import useLocalStorageState from 'use-local-storage-state';
import { type FilterViewPage } from '../../../generated/graphql';

type ColumnWidthState = Record<string, number | undefined>;

type SaveColumnWidthParams = {
  pageType: FilterViewPage;
};
const useSaveColumnWidth = ({ pageType }: SaveColumnWidthParams) => {
  const [columnWidths, setColumnWidths] =
    useLocalStorageState<ColumnWidthState>(`${pageType}_table_column_widths`, {
      defaultValue: {},
    });

  const onColumnResized: AgGridReactProps['onColumnResized'] = (event) => {
    const columnState = event.columnApi.getColumnState();
    const widths = columnState.reduce<ColumnWidthState>((acc, column) => {
      acc[column.colId] = column.width;
      return acc;
    }, {});

    setColumnWidths(widths);
  };

  const onGridReady: AgGridReactProps['onGridReady'] = (event) => {
    const columnState = event.columnApi.getColumnState();
    const columnStateWithWidths = columnState.map((column) => {
      const columnCopy = { ...column };
      const width = columnWidths[column.colId];
      if (!isNil(width)) {
        columnCopy.width = width;
      }
      return columnCopy;
    });
    event.columnApi.applyColumnState({
      state: columnStateWithWidths,
      applyOrder: true,
    });
  };

  return {
    onColumResizedWidthSave: onColumnResized,
    onGridReadyWidthSave: onGridReady,
  };
};

export default useSaveColumnWidth;
