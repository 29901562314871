import { Stack } from '@mui/material';
import {
  type ColDef,
  type ICellRendererParams,
  type ValueGetterParams,
} from 'ag-grid-community';
import { type ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { isNil } from 'lodash';
import { LinehaulDispatchTableField } from '../../../generated/graphql';
import PalletLink from '../../../pallet-ui/links/link/pallet-link';
import { singleLineTruncatedCellProps } from '../../ag-grid/utils';
import { type SegmentedLineHaulOrder } from '../types';
import {
  getLineHaulOrdersTableField,
  getLineHaulOrdersTableFieldCopy,
} from '../utils';

// Round to 2 decimal places. Omit trailing zeros.
const numberFmt = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
});

const COLUMNS: Array<ColDef<SegmentedLineHaulOrder>> = [
  {
    field: LinehaulDispatchTableField.Name,
    headerName: getLineHaulOrdersTableFieldCopy(
      LinehaulDispatchTableField.Name,
    ),
    minWidth: 100,
    maxWidth: 150,
    cellRenderer: (params: ICellRendererParams<SegmentedLineHaulOrder>) => {
      const order = params.data?.order;
      if (isNil(order)) {
        return null;
      }
      const { name } = order;
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <PalletLink
          sx={{
            cursor: 'pointer',
            padding: 0,
          }}
          underline="hover"
          onClick={(e) => {
            e.stopPropagation();
            const url = `/orders/?orderUuid=${order.uuid}&goBackAfterSave=true`;
            window.open(url, '_blank');
          }}
        >
          <Stack alignItems="center" spacing={1} direction="row">
            {name}
          </Stack>
        </PalletLink>
      );
    },
  },
  {
    field: LinehaulDispatchTableField.Hawb,
    headerName: getLineHaulOrdersTableFieldCopy(
      LinehaulDispatchTableField.Hawb,
    ),
    minWidth: 100,
    cellRenderer: (params: ICellRendererParams<SegmentedLineHaulOrder>) => {
      return getLineHaulOrdersTableField(
        LinehaulDispatchTableField.Hawb,
        params.data,
      );
    },
  },
  {
    field: LinehaulDispatchTableField.Mawb,
    headerName: getLineHaulOrdersTableFieldCopy(
      LinehaulDispatchTableField.Mawb,
    ),
    minWidth: 100,
    cellRenderer: (params: ICellRendererParams<SegmentedLineHaulOrder>) => {
      return getLineHaulOrdersTableField(
        LinehaulDispatchTableField.Mawb,
        params.data,
      );
    },
  },
  {
    field: LinehaulDispatchTableField.Segment,
    headerName: getLineHaulOrdersTableFieldCopy(
      LinehaulDispatchTableField.Segment,
    ),
    minWidth: 140,
    cellRenderer: (params: ICellRendererParams<SegmentedLineHaulOrder>) => {
      return getLineHaulOrdersTableField(
        LinehaulDispatchTableField.Segment,
        params.data,
      );
    },
  },
  {
    field: 'segment.startTerminal.code',
    headerName: 'Inbound terminal',
    minWidth: 100,
    valueGetter: ({ node }) => {
      if (node?.group === true) {
        return node.key;
      }
      return node?.data?.segment.startTerminal.code ?? '(None)';
    },
  },
  {
    field: LinehaulDispatchTableField.Service,
    headerName: getLineHaulOrdersTableFieldCopy(
      LinehaulDispatchTableField.Service,
    ),
    minWidth: 140,
    valueGetter: (params: ValueGetterParams<SegmentedLineHaulOrder>) => {
      return getLineHaulOrdersTableField(
        LinehaulDispatchTableField.Service,
        params.data,
      );
    },
  },
  {
    field: LinehaulDispatchTableField.ServiceDate,
    headerName: getLineHaulOrdersTableFieldCopy(
      LinehaulDispatchTableField.ServiceDate,
    ),
    sortable: true,
    minWidth: 100,
    valueGetter: (params: ValueGetterParams<SegmentedLineHaulOrder>) => {
      return getLineHaulOrdersTableField(
        LinehaulDispatchTableField.ServiceDate,
        params.data,
      );
    },
  },
  {
    field: LinehaulDispatchTableField.Weight,
    headerName: getLineHaulOrdersTableFieldCopy(
      LinehaulDispatchTableField.Weight,
    ),
    minWidth: 126,
    valueGetter: (params: ValueGetterParams<SegmentedLineHaulOrder>) => {
      return params.data?.order.weight ?? 0;
    },
    valueFormatter: (params: ValueFormatterParams<SegmentedLineHaulOrder>) =>
      `${numberFmt.format(
        (
          params as {
            value: number;
          }
        ).value,
      )} lbs`,
    aggFunc: 'sum',
  },
  {
    field: LinehaulDispatchTableField.DimWeight,
    headerName: getLineHaulOrdersTableFieldCopy(
      LinehaulDispatchTableField.DimWeight,
    ),
    minWidth: 125,
    valueGetter: (params: ValueGetterParams<SegmentedLineHaulOrder>) => {
      return getLineHaulOrdersTableField(
        LinehaulDispatchTableField.DimWeight,
        params.data,
      );
    },
  },
  {
    field: LinehaulDispatchTableField.InboundAddressName,
    headerName: getLineHaulOrdersTableFieldCopy(
      LinehaulDispatchTableField.InboundAddressName,
    ),
    minWidth: 120,
    ...singleLineTruncatedCellProps,
    valueGetter: (params: ValueGetterParams<SegmentedLineHaulOrder>) => {
      return getLineHaulOrdersTableField(
        LinehaulDispatchTableField.InboundAddressName,
        params.data,
      );
    },
  },
  {
    field: LinehaulDispatchTableField.OutboundAddressName,
    headerName: getLineHaulOrdersTableFieldCopy(
      LinehaulDispatchTableField.OutboundAddressName,
    ),
    minWidth: 120,
    ...singleLineTruncatedCellProps,
    valueGetter: (params: ValueGetterParams<SegmentedLineHaulOrder>) => {
      return getLineHaulOrdersTableField(
        LinehaulDispatchTableField.OutboundAddressName,
        params.data,
      );
    },
  },
  {
    field: LinehaulDispatchTableField.CustomerName,
    headerName: getLineHaulOrdersTableFieldCopy(
      LinehaulDispatchTableField.CustomerName,
    ),
    sortable: true,
    minWidth: 150,
    ...singleLineTruncatedCellProps,
    valueGetter: (params: ValueGetterParams<SegmentedLineHaulOrder>) => {
      return getLineHaulOrdersTableField(
        LinehaulDispatchTableField.CustomerName,
        params.data,
      );
    },
  },
  {
    field: LinehaulDispatchTableField.IsSpecial,
    headerName: getLineHaulOrdersTableFieldCopy(
      LinehaulDispatchTableField.IsSpecial,
    ),
    minWidth: 100,
    cellRenderer: (params: ICellRendererParams<SegmentedLineHaulOrder>) => {
      return getLineHaulOrdersTableField(
        LinehaulDispatchTableField.IsSpecial,
        params.data,
      );
    },
  },
  {
    field: LinehaulDispatchTableField.IsHazmat,
    headerName: getLineHaulOrdersTableFieldCopy(
      LinehaulDispatchTableField.IsHazmat,
    ),
    minWidth: 100,
    cellRenderer: (params: ICellRendererParams<SegmentedLineHaulOrder>) => {
      return getLineHaulOrdersTableField(
        LinehaulDispatchTableField.IsHazmat,
        params.data,
      );
    },
  },
  {
    field: LinehaulDispatchTableField.Lane,
    headerName: getLineHaulOrdersTableFieldCopy(
      LinehaulDispatchTableField.Lane,
    ),
    minWidth: 116,
    valueGetter: (params: ValueGetterParams<SegmentedLineHaulOrder>) => {
      return getLineHaulOrdersTableField(
        LinehaulDispatchTableField.Lane,
        params.data,
      );
    },
  },
  {
    field: LinehaulDispatchTableField.PieceCount,
    headerName: getLineHaulOrdersTableFieldCopy(
      LinehaulDispatchTableField.PieceCount,
    ),
    minWidth: 120,
    valueGetter: (params: ValueGetterParams<SegmentedLineHaulOrder>) => {
      return getLineHaulOrdersTableField(
        LinehaulDispatchTableField.PieceCount,
        params.data,
      );
    },
    aggFunc: 'sum',
  },
  {
    field: LinehaulDispatchTableField.Tags,
    headerName: getLineHaulOrdersTableFieldCopy(
      LinehaulDispatchTableField.Tags,
    ),
    minWidth: 120,
    cellStyle: { textAlign: 'center' },
    cellRenderer: (params: ICellRendererParams<SegmentedLineHaulOrder>) => {
      return getLineHaulOrdersTableField(
        LinehaulDispatchTableField.Tags,
        params.data,
      );
    },
  },
  {
    field: LinehaulDispatchTableField.DeadlineDate,
    headerName: getLineHaulOrdersTableFieldCopy(
      LinehaulDispatchTableField.DeadlineDate,
    ),
    minWidth: 120,
    valueGetter: (params: ValueGetterParams<SegmentedLineHaulOrder>) => {
      return getLineHaulOrdersTableField(
        LinehaulDispatchTableField.DeadlineDate,
        params.data,
      );
    },
  },
];

export default COLUMNS;
