import { Chip } from '@mui/material';
import React from 'react';
import { type StopType } from '../../generated/graphql';

export type StopTypeChipNewProps = {
  readonly stopType: StopType;
};
const StopTypeChipNew = ({ stopType }: StopTypeChipNewProps) => {
  return (
    <Chip
      key={stopType}
      size="small"
      sx={{ height: '20px' }}
      label={stopType}
    />
  );
};

export default StopTypeChipNew;
