import { Menu, MenuItem, Tooltip } from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { type Dispatch, type SetStateAction, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getPermissionsFlags } from 'shared/roles';
import { exhaustive } from 'shared/switch';
import { MarkOrderAsReadyToInvoiceDialog } from '../../../../../../common/components/modals/mark-order-as-ready-to-invoice-dialog';
import { MarkOrderAsRefusedDialog } from '../../../../../../common/components/modals/mark-order-as-refused-dialog';
import { FeatureFlag } from '../../../../../../common/feature-flags';
import { useAllowOpenInNewTab } from '../../../../../../common/react-hooks/use-allow-open-in-new-tab';
import useFeatureFlag from '../../../../../../common/react-hooks/use-feature-flag';
import useHoldReasons from '../../../../../../common/react-hooks/use-hold-reasons';
import useTerminals from '../../../../../../common/react-hooks/use-terminals';
import useUserRoles from '../../../../../../common/react-hooks/use-user-roles';
import {
  convertOrdersToStopsForMarkReadyToInvoiceDialog,
  isNilOrEmptyString,
} from '../../../../../../common/utils/utils';
import {
  OrderStatus,
  PermissionResource,
  StandardOrderForContextMenuDocument,
  StopType,
  useMarkOrderAsCancelledMutation,
  useMarkOrderAsCompleteForceMutation,
  useMarkOrderAsNotCompleteMutation,
  useMarkOrderAsNotOnHandMutation,
  useMarkOrderAsOnHandMutation,
  useMarkOrderAsOnHoldMutation,
  useMarkOrderAsOsdMutation,
  useRemoveOrderHoldMutation,
  useStandardOrderForContextMenuQuery,
  useUncancelOrderMutation,
  useUnfinalizeOrderMutation,
  useUpdateStandardOrderMutation,
} from '../../../../../../generated/graphql';
import { canBeMarkedAsOnHold } from '../../../order-form/components/overview/mark-as-menu';
import MarkOrderOnHoldModal from '../../../order-form/components/overview/modals/mark-order-on-hold-modal';
import {
  getDisabledForEditAccess,
  useGetOrderFormEditAccess,
} from '../../../order-form/forms/use-order-form-edit-access';
import OrderAuditLogModal from '../version-history/order-audit-log-modal';

const OrderRowContextMenu = ({
  orderUuid,
  buttonRef,
  showContextMenu,
  onClose,
  onAction,
  setSuccessMessage,
}: {
  readonly orderUuid: string;
  readonly buttonRef: HTMLButtonElement | null;
  readonly showContextMenu: boolean;
  readonly onClose: () => void;
  readonly onAction: () => void;
  readonly setSuccessMessage: Dispatch<SetStateAction<string>>;
}) => {
  const { pathname } = useLocation();
  const { userPermissions } = useUserRoles();
  const { canWrite: canFinalizeOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.FinalizeChargesOrders,
  );
  const { holdReasons } = useHoldReasons();
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const ffRequireTransferAddress = useFeatureFlag(
    FeatureFlag.FF_REQUIRE_TRANSFER_ADDRESS_ON_COMPLETION,
  );

  const { allowOpenInNewTab } = useAllowOpenInNewTab();
  const [showOrderAuditLogModal, setShowOrderAuditLogModal] =
    useState<boolean>(false);

  const duplicateEverything = async (e: React.MouseEvent) => {
    allowOpenInNewTab(
      e,
      `/orders/?orderUuid=${orderUuid}&duplicate=everything`,
    );
    onClose();
  };
  const duplicateBillingParty = async (e: React.MouseEvent) => {
    allowOpenInNewTab(e, `/orders/?orderUuid=${orderUuid}&duplicate=nothing`);
    onClose();
  };

  const { data: orderData } = useStandardOrderForContextMenuQuery({
    variables: { uuid: orderUuid },
  });
  const pieceCount = orderData?.standardOrder?.pieceCount;
  const status = orderData?.standardOrder?.status;

  const detailedStatus = orderData?.standardOrder?.detailedStatusV2;
  const { getOrderFormEditAccess } = useGetOrderFormEditAccess();
  const editAccess = getOrderFormEditAccess({ detailedStatus });
  const { disabledIfNoAccess, disabledIfFinalizedOrLater } =
    getDisabledForEditAccess({ editAccess });

  const onHand = orderData?.standardOrder?.onHand;
  const pickedDate = orderData?.standardOrder?.pickedDate;
  const refusedBy = orderData?.standardOrder?.refusedBy;
  const notes = orderData?.standardOrder?.notes;
  const shipperBillOfLadingNumber =
    orderData?.standardOrder?.standardOrderFields.shipperBillOfLadingNumber;
  const stops = convertOrdersToStopsForMarkReadyToInvoiceDialog(
    orderData?.standardOrder ?? null,
  );
  const everyStopCompleted =
    stops?.every((s) => !isNil(s?.completedAt)) ?? false;
  const canBeMarkedAsNotComplete =
    status === OrderStatus.Delivered && !everyStopCompleted;
  const getCannotCompleteOrderMessage = (): string | null => {
    if (isNilOrEmptyString(shipperBillOfLadingNumber)) {
      return `Must set HAWB to mark as complete`;
    }
    if (orderData?.standardOrder?.endStop?.stopType === StopType.Transfer) {
      if (
        ffRequireTransferAddress &&
        isEmpty(orderData?.standardOrder?.endStop?.address?.line1)
      ) {
        return `Must set transfer address to mark as complete`;
      }
      if (
        terminalsEnabled &&
        isNil(orderData?.standardOrder?.endStop?.terminal?.uuid)
      ) {
        return `Must set transfer destination to mark as complete`;
      }
    }
    return null;
  };

  const [showMarkOrderOnHoldModal, setShowMarkOrderOnHoldModal] =
    useState<boolean>(false);
  const [showMarkAsRefusedDialog, setShowMarkAsRefusedDialog] =
    useState<boolean>(false);
  const [showMarkAsReadyToInvoiceDialog, setShowMarkAsReadyToInvoiceDialog] =
    useState<boolean>(false);

  const [unfinalizeOrder] = useUnfinalizeOrderMutation({
    refetchQueries: [StandardOrderForContextMenuDocument],
  });
  const [markAsOnHandMutation] = useMarkOrderAsOnHandMutation({
    refetchQueries: [StandardOrderForContextMenuDocument],
  });
  const [updateStandardOrder] = useUpdateStandardOrderMutation({
    refetchQueries: [StandardOrderForContextMenuDocument],
  });
  const [markOrderAsNotOnHand] = useMarkOrderAsNotOnHandMutation({
    refetchQueries: [StandardOrderForContextMenuDocument],
  });
  const [markOrderAsOsd] = useMarkOrderAsOsdMutation({
    refetchQueries: [StandardOrderForContextMenuDocument],
  });
  const [markOrderAsCompleteForce] = useMarkOrderAsCompleteForceMutation({
    refetchQueries: [StandardOrderForContextMenuDocument],
  });
  const [markOrderAsNotComplete] = useMarkOrderAsNotCompleteMutation({
    refetchQueries: [StandardOrderForContextMenuDocument],
  });
  const [markOrderAsOnHold] = useMarkOrderAsOnHoldMutation({
    refetchQueries: [StandardOrderForContextMenuDocument],
  });
  const [removeOrderHold] = useRemoveOrderHoldMutation({
    refetchQueries: [StandardOrderForContextMenuDocument],
  });
  const [markOrderAsCancelled] = useMarkOrderAsCancelledMutation({
    refetchQueries: [StandardOrderForContextMenuDocument],
  });
  const [uncancelOrder] = useUncancelOrderMutation({});

  const canBeMarkedReadyToInvoice = (
    orderStatus: OrderStatus | null | undefined,
  ): boolean => {
    switch (orderStatus) {
      case OrderStatus.Created:
      case OrderStatus.InProgress:
      case OrderStatus.Delivered: {
        return true;
      }
      case OrderStatus.Finalized:
      case OrderStatus.Cancelled:
      case OrderStatus.HasIssue:
      case OrderStatus.Invoiced:
      case OrderStatus.OnHold:
      case null:
      case undefined: {
        return false;
      }
      default: {
        return exhaustive(orderStatus);
      }
    }
  };

  /**
   * Be sure to call onAction() after a successful mutation to trigger a refetch of the order and display the success message!
   */

  const markAsOnHand = async () => {
    const res = await markAsOnHandMutation({
      variables: {
        markAsOnHandInput: {
          uuid: orderUuid,
          pieceCount: undefined,
          sentFromMobileApplication: false,
        },
      },
    });
    if (!isNil(res.data?.markOrderAsOnHand.uuid)) {
      setSuccessMessage('Successfully marked as on hand');
      onAction();
    }
  };

  const markAllPiecesAsPicked = async () => {
    const newPickedDate = new Date();
    const res = await updateStandardOrder({
      variables: {
        updateStandardOrderInput: {
          orderUpdateInput: {
            uuid: orderUuid,
            piecesPicked: pieceCount,
            pickedDate: newPickedDate,
          },
        },
      },
    });
    if (!isNil(res.data?.updateStandardOrder.uuid)) {
      setSuccessMessage('Successfully marked pieces as picked');
      onAction();
    }
  };

  const markNotOnHand = async () => {
    const res = await markOrderAsNotOnHand({
      variables: {
        markAsNotOnHandInput: {
          uuid: orderUuid,
        },
      },
    });
    if (!isNil(res.data?.markOrderAsNotOnHand.uuid)) {
      setSuccessMessage('Successfully marked as not on hand');
      onAction();
    }
  };

  const onMarkOsd = async () => {
    const newNotes = isEmpty(notes) ? undefined : notes;
    const res = await markOrderAsOsd({
      variables: {
        markOsdInput: {
          uuid: orderUuid,
          notes: newNotes,
        },
      },
    });
    if (!isNil(res.data?.markOsd.uuid)) {
      setSuccessMessage('Successfully marked as OSD');
      onAction();
    }
  };

  const onMarkReadyToInvoice = async (isRefused?: boolean) => {
    const everyStopHasDateAndSignee = stops.every(
      (stop) => !isNil(stop.proofOfDeliverySignee),
    );
    if (!everyStopCompleted && !everyStopHasDateAndSignee) {
      if (isRefused === true) {
        setShowMarkAsRefusedDialog(true);
      } else {
        setShowMarkAsReadyToInvoiceDialog(true);
      }
      return;
    }

    const res = await markOrderAsCompleteForce({
      variables: {
        markOrderAsCompleteForceInput: {
          uuid: orderUuid,
        },
      },
    });
    if (!isNil(res.data?.markOrderAsCompleteForce.uuid)) {
      setSuccessMessage('Successfully marked as completed');
      onAction();
    }
  };

  const onMarkOrderAsNotComplete = async () => {
    const res = await markOrderAsNotComplete({
      variables: {
        uuid: orderUuid,
      },
    });
    if (!isNil(res.data?.markOrderAsNotComplete.uuid)) {
      setSuccessMessage('Successfully marked as not complete');
      onAction();
    }
  };

  const onMarkAsNotRefused = async () => {
    await updateStandardOrder({
      variables: {
        updateStandardOrderInput: {
          orderUpdateInput: {
            uuid: orderUuid,
            refusedBy: null,
            refusedDate: null,
          },
        },
      },
    });
    onAction();
  };

  const onMarkOrderAsOnHold = async () => {
    if (!isEmpty(holdReasons)) {
      setShowMarkOrderOnHoldModal(true);
      return;
    }
    const res = await markOrderAsOnHold({
      variables: {
        markAsOnHoldInput: {
          uuid: orderUuid,
          notes: undefined,
        },
      },
    });
    if (!isNil(res.data?.markAsOnHold.uuid)) {
      setSuccessMessage('Successfully marked as on hold');
      onAction();
    }
  };

  const onRemoveOrderHold = async () => {
    const res = await removeOrderHold({
      variables: {
        removeOrderHoldInput: {
          uuid: orderUuid,
          sentFromMobileApplication: false,
        },
      },
    });
    if (!isNil(res.data?.removeOrderHold.uuid)) {
      setSuccessMessage('Successfully removed hold');
      onAction();
    }
  };

  const onCancelOrder = async () => {
    const res = await markOrderAsCancelled({
      variables: { uuid: orderUuid },
    });
    if (!isNil(res.data?.markAsCancelled.uuid)) {
      setSuccessMessage('Successfully marked as cancelled');
      onAction();
    }
  };

  const onUncancelOrder = async () => {
    const res = await uncancelOrder({ variables: { uuid: orderUuid } });
    if (!isNil(res.data?.uncancelOrder.uuid)) {
      setSuccessMessage('Successfully uncancelled');
      onAction();
    }
  };

  const onUnfinalizeCharges = async () => {
    const res = await unfinalizeOrder({
      variables: {
        uuid: orderUuid,
      },
    });
    if (!isNil(res.data?.unfinalizeOrder.uuid)) {
      setSuccessMessage('Successfully unfinalized charges');
      onAction();
    }
  };

  return (
    <>
      <MarkOrderAsReadyToInvoiceDialog
        handleClose={() => {
          setShowMarkAsReadyToInvoiceDialog(false);
          setShowMarkAsRefusedDialog(false);
          onAction();
        }}
        open={showMarkAsReadyToInvoiceDialog}
        stops={convertOrdersToStopsForMarkReadyToInvoiceDialog(
          orderData?.standardOrder ?? null,
        )}
        orderUuid={orderUuid}
        markAsRefused={showMarkAsRefusedDialog}
        onSave={null} // we are not in form context
      />
      <MarkOrderOnHoldModal
        isEditMode
        open={showMarkOrderOnHoldModal}
        setOpen={setShowMarkOrderOnHoldModal}
        orderUuid={orderUuid}
        onSave={null} // we are not in form context
      />
      <MarkOrderAsRefusedDialog
        orderUuid={orderUuid}
        open={showMarkAsRefusedDialog}
        onClose={() => {
          setShowMarkAsRefusedDialog(false);
          onAction();
        }}
      />
      <Menu
        anchorEl={buttonRef}
        open={showContextMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            transform: 'translateY(15%)',
          },
        }}
        onClose={onClose}
      >
        <MenuItem
          onClick={() => {
            setShowOrderAuditLogModal(true);
          }}
        >
          View audit log
        </MenuItem>
        {pathname.startsWith('/order') && (
          <MenuItem onClick={duplicateEverything}>
            Duplicate entire order
          </MenuItem>
        )}
        {pathname.startsWith('/order') && (
          <MenuItem onClick={duplicateBillingParty}>
            Duplicate Customer
          </MenuItem>
        )}
        {onHand ? (
          <MenuItem
            disabled={isNil(onHand) || !onHand || disabledIfFinalizedOrLater}
            onClick={markNotOnHand}
          >
            Mark as not on hand
          </MenuItem>
        ) : (
          <MenuItem
            disabled={
              Boolean(onHand) ||
              !isNil(pickedDate) ||
              disabledIfFinalizedOrLater
            }
            onClick={markAsOnHand}
          >
            Mark as on hand
          </MenuItem>
        )}
        <MenuItem
          disabled={
            onHand === false || !isNil(pickedDate) || disabledIfFinalizedOrLater
          }
          onClick={markAllPiecesAsPicked}
        >
          Mark all pieces as picked
        </MenuItem>
        {canBeMarkedAsOnHold(status) && (
          <MenuItem disabled={disabledIfFinalizedOrLater} onClick={onMarkOsd}>
            Mark OSD
          </MenuItem>
        )}
        {canBeMarkedReadyToInvoice(status) && (
          <Tooltip title={getCannotCompleteOrderMessage() ?? ''}>
            <span>
              <MenuItem
                disabled={
                  !isNil(getCannotCompleteOrderMessage()) ||
                  disabledIfFinalizedOrLater
                }
                onClick={() => {
                  onMarkReadyToInvoice();
                }}
              >
                Mark as complete
              </MenuItem>
            </span>
          </Tooltip>
        )}
        {isNil(refusedBy) ? (
          <MenuItem
            disabled={disabledIfFinalizedOrLater}
            onClick={() => {
              setShowMarkAsRefusedDialog(true);
            }}
          >
            Mark as refused
          </MenuItem>
        ) : (
          <MenuItem
            disabled={disabledIfFinalizedOrLater}
            onClick={onMarkAsNotRefused}
          >
            Mark as not refused
          </MenuItem>
        )}
        {canBeMarkedAsNotComplete && (
          <MenuItem
            disabled={disabledIfFinalizedOrLater}
            onClick={onMarkOrderAsNotComplete}
          >
            Mark order as not completed
          </MenuItem>
        )}
        {canBeMarkedAsOnHold(status) && (
          <MenuItem
            disabled={disabledIfFinalizedOrLater}
            onClick={onMarkOrderAsOnHold}
          >
            Mark as on hold
          </MenuItem>
        )}
        {status === OrderStatus.OnHold && (
          <MenuItem
            disabled={disabledIfFinalizedOrLater}
            onClick={onRemoveOrderHold}
          >
            Remove Hold
          </MenuItem>
        )}
        {status === OrderStatus.Cancelled ? (
          <MenuItem
            disabled={disabledIfFinalizedOrLater}
            onClick={onUncancelOrder}
          >
            Uncancel Order
          </MenuItem>
        ) : (
          <MenuItem
            disabled={disabledIfFinalizedOrLater}
            onClick={onCancelOrder}
          >
            Mark as cancelled
          </MenuItem>
        )}
        {status === OrderStatus.Finalized && (
          <MenuItem
            disabled={disabledIfNoAccess || !canFinalizeOrders}
            onClick={onUnfinalizeCharges}
          >
            Mark charges as unfinalized
          </MenuItem>
        )}
      </Menu>
      <OrderAuditLogModal
        isOpen={showOrderAuditLogModal}
        setIsOpen={setShowOrderAuditLogModal}
        orderUuid={orderUuid}
      />
    </>
  );
};

export default OrderRowContextMenu;
