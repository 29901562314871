import {
  type FieldPath,
  type FieldPathValue,
  type FieldValues,
  useFormContext,
} from 'react-hook-form';

const useFormUtils = <TFieldValues extends FieldValues>() => {
  const { setValue, getValues } = useFormContext<TFieldValues>();
  const setValueIfMatching = <
    TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
    UFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  >(
    name: TFieldName,
    value: FieldPathValue<TFieldValues, TFieldName>,
    condition: {
      comparePath: UFieldName;
      value: FieldPathValue<TFieldValues, UFieldName>;
    },
    options?: {
      shouldDirty?: boolean;
      shouldTouch?: boolean;
      shouldValidate?: boolean;
    },
  ) => {
    if (getValues(condition.comparePath) === condition.value) {
      setValue(name, value, options);
    }
  };

  return { setValueIfMatching };
};

export default useFormUtils;
