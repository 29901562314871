import { Drawer, IconButton, Stack, Tooltip } from '@mui/material';
import React from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useLocalStorageState from 'use-local-storage-state';
import { SIDEBAR_OPEN_KEY } from '../../common/local-storage/keys';
import useMe from '../../common/react-hooks/use-me';
import useUserRoles from '../../common/react-hooks/use-user-roles';
import { PermissionResource } from '../../generated/graphql';
import LeftPanelClose from '../../icons/left-panel-close.svg?react';
import theme from '../../theme';
import NavbarAddOrderItem from './components/navbar-add-order-item';
import NavbarNotificationItem from './components/navbar-notification-item';
import NavbarSearchItem from './components/navbar-search-item';
import { SidebarDrawerItemGroup } from './components/sidebar-drawer-item';
import { UserMenu } from './components/user-menu';
import PalletLogo from './icons/pallet-logo-white.svg?react';
import useDrawerItems from './use-drawer-items';

const TRANSITION_DURATION = 225;
const DRAWER_WIDTH = 240;

const Sidebar = React.memo(() => {
  const [sidebarOpen, setSidebarOpen] = useLocalStorageState(SIDEBAR_OPEN_KEY, {
    defaultValue: true,
  });
  const { companyConfiguration } = useMe();
  const notificationsEnabled =
    companyConfiguration?.notificationsEnabled === true;
  const { drawerItems } = useDrawerItems();
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Orders,
  );

  return (
    <Drawer
      hideBackdrop
      open
      PaperProps={{
        style: {
          backgroundColor: theme.palette.airfreightBlue[90],
          transition: `width ${TRANSITION_DURATION}ms ease-in-out`,
          width: sidebarOpen ? DRAWER_WIDTH : 0,
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          overflowX: 'hidden',
          border: 'none',
        },
      }}
      sx={{
        width: sidebarOpen ? DRAWER_WIDTH : 0,
        minHeight: '100%',
        transition: `width ${TRANSITION_DURATION}ms ease-in-out`,
        '& .MuiDrawer-paper': {
          transition: `width ${TRANSITION_DURATION}ms ease-in-out`,
          overflowX: 'hidden',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        },
      }}
      variant="persistent"
      anchor="left"
    >
      <Stack sx={{ flexGrow: 1, p: 2, width: DRAWER_WIDTH }} spacing={3}>
        <Stack spacing={1}>
          <Stack direction="row" justifyContent="space-between">
            <PalletLogo />
            <Stack direction="row">
              <Tooltip title="Collapse sidebar">
                <IconButton
                  size="small"
                  onClick={() => {
                    setSidebarOpen(false);
                  }}
                >
                  <LeftPanelClose fill="white" />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
          <NavbarSearchItem />
          {canWriteOrders && <NavbarAddOrderItem />}
          {notificationsEnabled && <NavbarNotificationItem />}
        </Stack>
        <Stack spacing={2}>
          {drawerItems.map((drawerItem) => (
            <SidebarDrawerItemGroup
              key={drawerItem.name}
              drawerItem={drawerItem}
            />
          ))}
        </Stack>
      </Stack>
      <Stack
        sx={{
          borderTop: `1px solid ${theme.palette.grey[700]}`,
          position: 'sticky',
          bottom: 0,
          backgroundColor: theme.palette.airfreightBlue[90],
        }}
      >
        <UserMenu />
      </Stack>
    </Drawer>
  );
});

export { Sidebar };
