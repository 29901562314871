import { z } from 'zod';
import { PackageType } from '../../../../../generated/graphql';

export const packageSchema = z.object({
  uuid: z.string().uuid(),
  description: z.string().nullish(),
  height: z.number().nullish(),
  length: z.number().nullish(),
  width: z.number().nullish(),
  weight: z.number().nullish(),
  quantity: z.number({
    required_error: 'Quantity is required',
    invalid_type_error: 'Quantity is required',
  }),
  type: z.nativeEnum(PackageType).nullish(),
  packageSpecId: z.string().nullish(),
  warehouseLocationName: z.string().nullish(),
  warehouseLocationUuid: z.string().uuid().nullish(),
});
