import {
  Box,
  Checkbox,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isEmpty, isNil } from 'lodash';
import type React from 'react';
import { exhaustive } from 'shared/switch';
import { objectKeys } from 'tsafe';
// eslint-disable-next-line import/no-cycle
import {
  type ValidationResponse,
  validateString,
} from '../../../../common/form/formValidators';
// eslint-disable-next-line import/no-cycle
import { isNilOrEmptyString } from '../../../../common/utils/utils';
import {
  type AccessorialQuery,
  AccessorialType,
  type SpecialAccessorialType,
  type SpecialDayOfWeek,
  type TariffZoneType,
} from '../../../../generated/graphql';
import { type RangeCellValues } from '../common/range-cell';

dayjs.extend(utc);
dayjs.extend(timezone);

export const getAccessorialTypeFromTypename = (
  typename: AccessorialQuery['accessorial']['__typename'],
) => {
  switch (typename) {
    case 'StandardAccessorialEntity': {
      return AccessorialType.Standard;
    }
    case 'WeightBasedAccessorialEntity': {
      return AccessorialType.Weight;
    }
    case 'ZoneBasedAccessorialEntity': {
      return AccessorialType.ZoneBased;
    }
    case 'WaitTimeAccessorialEntity': {
      return AccessorialType.WaitTime;
    }
    case 'UnitBasedAccessorialEntity': {
      return AccessorialType.Unit;
    }
    case 'SkidBasedAccessorialEntity': {
      return AccessorialType.Skid;
    }
    case 'SpecialAccessorialEntity': {
      return AccessorialType.Special;
    }
    default: {
      return exhaustive(typename);
    }
  }
};

export enum FormMode {
  CREATE,
  EDIT,
}

export type StandardAccessorialFormValues = {
  name: string | null;
  rate: number | null;
  percentForSettlement: number | null;
  terminalUuid?: string | null;
  fuelProfileUuid?: string | null;
  code: string | null;
  ediCode: string | null;
  invoiceDisplayName: string | null;
  generalLedgerCodeId: string | null;
};

export type WaitTimeAccessorialFormValues = {
  name: string | null;
  rate: number | null;
  minimumCharge: number | null;
  maximumCharge: number | null;
  waitTimeFreeMinutes: number | null;
  waitTimeChargePeriod: number | null;
  percentForSettlement: number | null;
  terminalUuid?: string | null;
  fuelProfileUuid?: string | null;
  code: string | null;
  ediCode: string | null;
  invoiceDisplayName: string | null;
  generalLedgerCodeId: string | null;
};

export type WaitTimeAccessorialFormErrors = {
  [key in keyof WaitTimeAccessorialFormValues]?: string;
};

export type WaitTimeAccessorialFormFieldName =
  keyof WaitTimeAccessorialFormValues;

export type StandardAccessorialFormErrors = {
  [key in keyof StandardAccessorialFormValues]?: string;
};

export type StandardAccessorialFormFieldName =
  keyof StandardAccessorialFormValues;

export type QuantityBasedAccessorialFormValues = {
  name: string | null;
  rate: number | null;
  percentForSettlement: number | null;
  terminalUuid?: string | null;
  fuelProfileUuid?: string | null;
  code: string | null;
  ediCode: string | null;
  invoiceDisplayName: string | null;
  maximumCharge: number | null;
  minimumCharge: number | null;
  generalLedgerCodeId: string | null;
};

export type QuantityBasedAccessorialFormErrors = {
  [key in keyof QuantityBasedAccessorialFormValues]?: string;
};

export type QuantityBasedAccessorialFormFieldName =
  keyof QuantityBasedAccessorialFormValues;

export type AccessorialRange = {
  uuid: string | null;
  rate: number | null;
  lessThanOrEqualToValue: number | null;
};

export type WeightBasedAccessorialFormValues = {
  name?: string | null;
  ranges: AccessorialRange[];
  maximumCharge?: number | null;
  minimumCharge?: number | null;
  percentForSettlement?: number | null;
  terminalUuid?: string | null;
  fuelProfileUuid?: string | null;
  code?: string | null;
  ediCode?: string | null;
  invoiceDisplayName?: string | null;
  generalLedgerCodeId?: string | null;
};

export type WeightBasedAccessorialFormErrors = {
  [key in keyof WeightBasedAccessorialFormValues]?: string[];
};

export type WeightBasedAccessorialFormFieldName =
  keyof WeightBasedAccessorialFormValues;

export type ZoneBasedAccessorialZone = {
  name: string;
  uuid: string | null;
  toBeCreated: boolean | null;
  isUpdated: boolean | null;
};

export type ZoneBasedAccessorialChargeGroup = {
  name: string;
  uuid: string | null;
  toBeCreated: boolean | null;
  isUpdated: boolean | null;
};

export type ZoneBasedAccessorialMatrixItem = {
  uuid: string | null;
  rate: number;
  toBeCreated: boolean | null;
  isUpdated: boolean | null;
};

export type ZoneBasedAccessorialFormValues = {
  name: string;
  zones: ZoneBasedAccessorialZone[];
  chargeGroups: ZoneBasedAccessorialChargeGroup[];
  matrixItems: Array<Array<ZoneBasedAccessorialMatrixItem | null>>;
  percentForSettlement: number | null;
  terminalUuid?: string | null;
  fuelProfileUuid?: string | null;
  code: string | null;
  ediCode: string | null;
  invoiceDisplayName: string | null;
  generalLedgerCodeId: string | null;
};

export type ZoneBasedAccessorialFormErrors = {
  [key in keyof ZoneBasedAccessorialFormValues]?: string[];
};

export type SpecialAccessorialTariffZoneFragment = {
  uuid: string;
  name: string;
  type: TariffZoneType;
};

export type SpecialAccessorialChargeGroup = {
  uuid: string | null;
  toBeCreated: boolean | null;
  isUpdated: boolean | null;
  dayOfWeek: SpecialDayOfWeek | null;
  startTime: Date | null;
  endTime: Date | null;
};

export type SpecialAccessorialMatrixItem = {
  uuid: string | null;
  rate: number;

  toBeCreated: boolean | null;

  isUpdated: boolean | null;
};

export const getNewSpecialAccessorialMatrixItem =
  (): SpecialAccessorialMatrixItem => ({
    rate: 0,
    toBeCreated: true,
    isUpdated: null,
    uuid: null,
  });

export type SpecialAccessorialFormValues = {
  name: string;
  specialAccessorialType: SpecialAccessorialType | null;
  serviceLevelUuid?: string | null;
  chargeGroups: SpecialAccessorialChargeGroup[];
  mileRangeEnds: RangeCellValues;
  matrixItems: SpecialAccessorialMatrixItem[][];
  percentForSettlement?: number | null;
  terminalUuid?: string | null;
  tariffZoneGroupId: string | null;
  fuelProfileUuid?: string | null;
  code?: string | null;
  ediCode?: string | null;
  invoiceDisplayName?: string | null;
  generalLedgerCodeId: string | null;
};

export type SpecialAccessorialFormErrors = {
  [key in keyof SpecialAccessorialFormValues]?: string[];
};

export type BaseTextFormFieldProps = {
  readonly mode: FormMode;
  readonly value: number | string | null | undefined;
  readonly error?: string | undefined;
  readonly type: 'number' | 'text';
  readonly disabled?: boolean | undefined;
  readonly label: string;
  // eslint-disable-next-line react/no-unused-prop-types
  readonly isPercent?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  readonly isCurrency?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  readonly isWeight?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  readonly key?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  readonly fullWidth?: boolean;
};

export type WaitTimeAccessorialFormTextFieldProps = {
  readonly name: WaitTimeAccessorialFormFieldName;
  readonly onChange: (
    field: WaitTimeAccessorialFormFieldName,
    value: string | number | null,
  ) => void;
  readonly onBlur: (field: WaitTimeAccessorialFormFieldName) => void;
} & BaseTextFormFieldProps;

export type StandardAccessorialFormTextFieldProps = {
  readonly name: StandardAccessorialFormFieldName;
  readonly onChange: (
    field: StandardAccessorialFormFieldName,
    value: string | number,
  ) => void;
  readonly onBlur: (field: StandardAccessorialFormFieldName) => void;
} & BaseTextFormFieldProps;

export type QuantityBasedAccessorialFormTextFieldProps = {
  readonly name: QuantityBasedAccessorialFormFieldName;
  readonly onChange: (
    field: QuantityBasedAccessorialFormFieldName,
    value: string | number | null,
  ) => void;
  readonly onBlur: (field: QuantityBasedAccessorialFormFieldName) => void;
} & BaseTextFormFieldProps;

export type WeightBasedAccessorialFormTextFieldProps = {
  readonly name: WeightBasedAccessorialFormFieldName;
  readonly onChange: (
    field: WeightBasedAccessorialFormFieldName,
    value: string | number | null,
  ) => void;
  readonly onBlur: (field: WeightBasedAccessorialFormFieldName) => void;
} & BaseTextFormFieldProps;

export type ZoneBasedAccessorialZoneTextFieldProps = {
  readonly name: string;
  readonly onChange: (value: string) => void;
  readonly onBlur: (value: string) => void;
} & BaseTextFormFieldProps;

export type ZoneBasedAccessorialChargeGroupTextFieldProps = {
  readonly name: string;
  readonly onChange: (value: string) => void;
  readonly onBlur: (value: string) => void;
} & BaseTextFormFieldProps;

export type ZoneBasedAccessorialMatrixItemTextFieldProps = {
  readonly name: string;
  readonly onChange: (value: number) => void;
  readonly onBlur: (value: number) => void;
} & BaseTextFormFieldProps;

export type SpecialAccessorialChargeGroupParams = {
  dayOfWeek: SpecialDayOfWeek | null;
  startTime: Date | null;
  endTime: Date | null;
};

export type AccessorialDateRangeFormFieldName =
  keyof StandardAccessorialFormValues;
export type AccessorialRateTextFieldProps = {
  readonly name: AccessorialDateRangeFormFieldName;
  readonly onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  readonly onBlur: any;
} & Omit<BaseTextFormFieldProps, 'mode'>;

export const AccessorialRateTextField = (
  props: AccessorialRateTextFieldProps,
) => {
  const {
    value,
    error,
    type,
    name,
    label,
    disabled,
    onChange,
    onBlur,
    isPercent,
    isCurrency,
    isWeight,
    key,
  } = props;
  return (
    <TextField
      key={key}
      sx={{ width: '100%', maxWidth: '200px' }}
      disabled={disabled}
      error={!isNilOrEmptyString(error)}
      type={type}
      helperText={error}
      label={label}
      name={name}
      value={value}
      InputProps={{
        endAdornment:
          // eslint-disable-next-line no-nested-ternary
          isPercent === true ? (
            <InputAdornment position="end">%</InputAdornment>
          ) : isWeight === true ? (
            <InputAdornment position="end">lbs</InputAdornment>
          ) : undefined,
        startAdornment:
          isCurrency === true ? (
            <InputAdornment position="end">$</InputAdornment>
          ) : undefined,
      }}
      size="small"
      onChange={onChange}
      onBlur={onBlur}
      onWheel={(e) => {
        (e.target as HTMLTextAreaElement).blur();
      }}
    />
  );
};

export const WaitTimeAccessorialTextField = (
  props: WaitTimeAccessorialFormTextFieldProps,
) => {
  const {
    mode,
    value,
    error,
    type,
    name,
    label,
    onChange,
    onBlur,
    isPercent,
    disabled,
    fullWidth,
  } = props;
  // eslint-disable-next-line default-case
  switch (mode) {
    case FormMode.EDIT:
    case FormMode.CREATE: {
      return (
        <TextField
          disabled={disabled}
          error={!isNil(error)}
          type={type}
          helperText={error}
          label={label}
          name={name}
          value={value ?? ''}
          InputProps={
            isPercent === true
              ? {
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }
              : {}
          }
          size="small"
          fullWidth={fullWidth}
          onChange={(e) => {
            const targetValue = e.target.value;
            if (type === 'number') {
              onChange(
                name,
                isEmpty(targetValue) ? null : Number.parseFloat(targetValue),
              );
            } else {
              onChange(name, targetValue);
            }
          }}
          onBlur={() => {
            onBlur(name);
          }}
        />
      );
    }
    default: {
      return exhaustive(mode);
    }
  }
};

export const StandardAccessorialTextField = (
  props: StandardAccessorialFormTextFieldProps,
) => {
  const {
    mode,
    value,
    error,
    type,
    name,
    label,
    disabled,
    onChange,
    onBlur,
    isPercent,
    fullWidth,
  } = props;
  // eslint-disable-next-line default-case
  switch (mode) {
    case FormMode.EDIT:
    case FormMode.CREATE: {
      return (
        <TextField
          disabled={disabled}
          error={!isNil(error)}
          type={type}
          helperText={error}
          label={label}
          name={name}
          value={value ?? ''}
          InputProps={
            isPercent === true
              ? {
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }
              : {}
          }
          size="small"
          fullWidth={fullWidth}
          onChange={(e) => {
            if (type === 'number') {
              onChange(name, Number.parseFloat(e.target.value));
            } else {
              onChange(name, e.target.value);
            }
          }}
          onBlur={() => {
            onBlur(name);
          }}
        />
      );
    }
    default: {
      return exhaustive(mode);
    }
  }
};

export const QuantityBasedAccessorialTextField = (
  props: QuantityBasedAccessorialFormTextFieldProps,
) => {
  const {
    mode,
    value,
    error,
    type,
    name,
    label,
    disabled,
    onChange,
    onBlur,
    isPercent,
    fullWidth,
  } = props;
  // eslint-disable-next-line default-case
  switch (mode) {
    case FormMode.EDIT:
    case FormMode.CREATE: {
      return (
        <TextField
          disabled={disabled}
          error={!isNil(error)}
          type={type}
          helperText={error}
          label={label}
          name={name}
          value={value ?? ''}
          InputProps={
            isPercent === true
              ? {
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }
              : {}
          }
          size="small"
          fullWidth={fullWidth}
          onChange={(e) => {
            const targetValue = e.target.value;
            if (type === 'number') {
              onChange(
                name,
                isEmpty(targetValue) ? null : Number.parseFloat(targetValue),
              );
            } else {
              onChange(name, e.target.value);
            }
          }}
          onBlur={() => {
            onBlur(name);
          }}
        />
      );
    }
    default: {
      return exhaustive(mode);
    }
  }
};

export const WeightBasedAccessorialTextField = (
  props: WeightBasedAccessorialFormTextFieldProps,
) => {
  const {
    mode,
    value,
    error,
    type,
    name,
    label,
    disabled,
    onChange,
    onBlur,
    isPercent,
    isCurrency,
    isWeight,
    key,
    fullWidth,
  } = props;
  // eslint-disable-next-line default-case
  switch (mode) {
    case FormMode.EDIT:
    case FormMode.CREATE: {
      return (
        <TextField
          key={key}
          disabled={disabled}
          error={!isNilOrEmptyString(error)}
          type={type}
          helperText={error}
          label={label}
          name={name}
          value={value ?? ''}
          InputProps={{
            endAdornment:
              // eslint-disable-next-line no-nested-ternary
              isPercent === true ? (
                <InputAdornment position="end">%</InputAdornment>
              ) : isWeight === true ? (
                <InputAdornment position="end">lbs</InputAdornment>
              ) : undefined,
            startAdornment:
              isCurrency === true ? (
                <InputAdornment position="end">$</InputAdornment>
              ) : undefined,
          }}
          size="small"
          fullWidth={fullWidth}
          onChange={(e) => {
            const targetValue = e.target.value;
            if (type === 'number') {
              onChange(
                name,
                isEmpty(targetValue) ? null : Number.parseFloat(targetValue),
              );
            } else {
              onChange(name, e.target.value);
            }
          }}
          onBlur={() => {
            onBlur(name);
          }}
        />
      );
    }
    default: {
      return exhaustive(mode);
    }
  }
};

export const ZoneBasedAccessorialZoneTextField = (
  props: ZoneBasedAccessorialZoneTextFieldProps,
) => {
  const { mode, value, error, type, name, label, disabled, onChange, onBlur } =
    props;
  // eslint-disable-next-line default-case
  switch (mode) {
    case FormMode.EDIT:
    case FormMode.CREATE: {
      return (
        <TextField
          fullWidth
          disabled={disabled}
          error={!isNil(error)}
          type={type}
          helperText={error}
          label={label}
          name={name}
          value={value ?? ''}
          size="small"
          onChange={(e) => {
            onChange(e.target.value);
          }}
          onBlur={() => {
            onBlur(value as string);
          }}
        />
      );
    }
    default: {
      return exhaustive(mode);
    }
  }
};

export const ZoneBasedAccessorialChargeGroupTextField = (
  props: ZoneBasedAccessorialChargeGroupTextFieldProps,
) => {
  const { mode, value, error, type, name, label, disabled, onChange, onBlur } =
    props;
  // eslint-disable-next-line default-case
  switch (mode) {
    case FormMode.EDIT:
    case FormMode.CREATE: {
      return (
        <TextField
          disabled={disabled}
          error={!isNil(error)}
          type={type}
          helperText={error}
          label={label}
          name={name}
          value={value ?? ''}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          onBlur={() => {
            onBlur(value as string);
          }}
        />
      );
    }
    default: {
      return exhaustive(mode);
    }
  }
};

export const validateField = (
  field:
    | QuantityBasedAccessorialFormFieldName
    | StandardAccessorialFormFieldName,
  value: string | number | boolean | null | undefined,
) => {
  switch (field) {
    case 'name': {
      return validateString(value as string, true);
    }
    case 'maximumCharge':
    case 'minimumCharge':
    case 'terminalUuid':
    case 'fuelProfileUuid':
    case 'code':
    case 'ediCode':
    case 'generalLedgerCodeId':
    case 'invoiceDisplayName': {
      return { valid: true, explanation: '' };
    }
    case 'rate':
    case 'percentForSettlement': {
      const valid = value !== null;
      const explanation = valid ? '' : 'field is required';
      return { valid, explanation };
    }
    default: {
      return exhaustive(field);
    }
  }
};

export const validateAllFields = (
  formValues:
    | QuantityBasedAccessorialFormValues
    | StandardAccessorialFormValues
    | WeightBasedAccessorialFormValues,
) => {
  const invalidFields: Partial<
    Record<QuantityBasedAccessorialFormFieldName, ValidationResponse>
  > = {};
  for (const fieldName of objectKeys(formValues)) {
    const result = validateField(fieldName, formValues[fieldName]);
    if (!result.valid) {
      invalidFields[fieldName] = result;
    }
  }
  return invalidFields;
};

export const ZoneBasedAccessorialMatrixItemTextField = (
  props: ZoneBasedAccessorialMatrixItemTextFieldProps,
) => {
  const { mode, value, error, type, name, label, disabled, onChange, onBlur } =
    props;
  // eslint-disable-next-line default-case
  switch (mode) {
    case FormMode.EDIT:
    case FormMode.CREATE: {
      return (
        <TextField
          disabled={disabled}
          error={!isNil(error)}
          type={type}
          helperText={error}
          label={label}
          name={name}
          value={value ?? ''}
          onChange={(e) => {
            onChange(Number.parseFloat(e.target.value));
          }}
          onBlur={() => {
            onBlur(value as number);
          }}
        />
      );
    }
    default: {
      return exhaustive(mode);
    }
  }
};

export const AccessorialIsSpecialCheckboxField = ({
  checked,
  onChange,
}: {
  readonly checked: boolean;
  readonly onChange: (checked: boolean) => void;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
        flexBasis: 0,
      }}
    >
      <Checkbox
        checked={checked}
        onChange={(e) => {
          onChange(e.target.checked);
        }}
      />
      <Typography>Is special </Typography>
    </Box>
  );
};

export const getBackUrl = (contactUuid?: string | null | string[]) => {
  if (isNil(contactUuid)) {
    return '/management?tab=0';
  }
  return `/contacts/${contactUuid}/?edit=true&tab=BILLING&billingTab=ACCESSORIALS`;
};

// Default value used for the accessorial terminal. NOT PASSED TO THE BACKEND.
export const ALL_TERMINALS = 'All Terminals';
export const NO_FUEL_PROFILE = 'None';
