/* eslint-disable no-param-reassign */
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

type AccessorialEditorState = {
  shouldSaveDateRangeConfig: boolean;
  showAccessorialLoadErrorMessage: boolean;
  showUpdateAccessorialDateRangeSuccessMessage: boolean;
  showUpdateAccessorialDateRangeErrorMessage: boolean;
};

type AccessorialEditorActions = {
  setShouldSaveDateRangeConfig: (shouldSave: boolean) => void;
  setShowAccessorialLoadErrorMessage: (show: boolean) => void;
  setShowUpdateAccessorialDateRangeSuccessMessage: (show: boolean) => void;
  setShowUpdateAccessorialDateRangeErrorMessage: (show: boolean) => void;
};

const useAccessorialEditorStore = create(
  immer<AccessorialEditorState & AccessorialEditorActions>((set) => ({
    shouldSaveDateRangeConfig: false,
    showAccessorialLoadErrorMessage: false,
    showUpdateAccessorialDateRangeSuccessMessage: false,
    showUpdateAccessorialDateRangeErrorMessage: false,
    setShouldSaveDateRangeConfig: (shouldSave) => {
      set((state) => {
        state.shouldSaveDateRangeConfig = shouldSave;
      });
    },
    setShowAccessorialLoadErrorMessage: (show) => {
      set((state) => {
        state.showAccessorialLoadErrorMessage = show;
      });
    },
    setShowUpdateAccessorialDateRangeSuccessMessage: (show: boolean) => {
      set((state) => {
        state.showUpdateAccessorialDateRangeSuccessMessage = show;
      });
    },
    setShowUpdateAccessorialDateRangeErrorMessage: (show: boolean) => {
      set((state) => {
        state.showUpdateAccessorialDateRangeErrorMessage = show;
      });
    },
  })),
);

export default useAccessorialEditorStore;
