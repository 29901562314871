import { type AgGridReact, type AgGridReactProps } from 'ag-grid-react';
import { isNil } from 'lodash';
import { type RefObject, useRef, useEffect } from 'react';
import invokeAll from './invoke-all';

const AG_GRID_TOOL_PANEL_CLASS = '.ag-filter-toolpanel';

/**
 * AgGrid doesn't provide a way to close the filter tool panel when clicking
 * outside of the grid. This hook listens for clicks outside of the grid and
 * closes the filter tool panel when necessary.
 */
const useCloseToolPanelOnClickOutside = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  gridRef: RefObject<AgGridReact>,
) => {
  const toolPanelVisibleRef = useRef(false);
  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      const gridApi = gridRef.current?.api;
      // Check if the grid API is available and the tool panel is visible
      if (
        gridApi == null ||
        event.target == null ||
        !toolPanelVisibleRef.current
      ) {
        return;
      }

      // Check if the click event is outside of the panel popover
      const isToolPanelClicked = (event.target as Element).closest(
        AG_GRID_TOOL_PANEL_CLASS,
      );
      if (isToolPanelClicked !== null) {
        return;
      }

      // Check if the filter tool panel is open and close it
      const filterToolPanel = gridApi.getToolPanelInstance('filters');
      if (isNil(filterToolPanel)) {
        return;
      }

      filterToolPanel.collapseFilters();
      filterToolPanel.refresh();
      filterToolPanel.setFilterLayout(gridApi.getColumnDefs() ?? []);
      gridApi.closeToolPanel();
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return toolPanelVisibleRef;
};

type OnToolPanelVisibleChanged = AgGridReactProps['onToolPanelVisibleChanged'];

const useOnToolPanelVisibleChanged = (
  onToolPanelVisibleChangedPropsFunction: OnToolPanelVisibleChanged,
  gridRef: RefObject<AgGridReact>,
): OnToolPanelVisibleChanged => {
  const toolPanelRef = useCloseToolPanelOnClickOutside(gridRef);
  const onToolPanelVisibleChanged = () => {
    toolPanelRef.current = !toolPanelRef.current;
  };

  return invokeAll(
    onToolPanelVisibleChanged,
    onToolPanelVisibleChangedPropsFunction,
  );
};

export default useOnToolPanelVisibleChanged;
