import {
  Alert,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Snackbar,
  Stack,
} from '@mui/material';
import { isNil } from 'lodash';
import {
  type Dispatch,
  type SetStateAction,
  useContext,
  useState,
} from 'react';
import { transformAddressToFullAddressString } from 'shared/copy';
import { getPermissionsFlags } from 'shared/roles';
import { isNilOrEmptyString } from 'shared/string';
import TabPanel from '../../../common/components/tab-panel/tab-panel';
import useDocuments from '../../../common/react-hooks/use-documents';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import useWindowDimensions from '../../../common/react-hooks/use-window-dimensions';
import {
  DocumentType,
  DocumentsByOrderDocument,
  DocumentsByShipmentDocument,
  OrderWithPaperworkDocument,
  PermissionResource,
  RouteDocument,
  useCreateShipmentDocumentMutation,
  useDocumentsByOrderQuery,
  useGenerateShipmentPreSignedPutUrlMutation,
} from '../../../generated/graphql';
import PalletButtonGroup from '../../../pallet-ui/button-group/pallet-button-group';
import { useAppSelector } from '../../../redux/hooks';
import { selectAddresses } from '../../addresses/redux/addresses-values-slice';
import { transformAppointmentStatus } from '../../orders/components/utils';
import { selectStandardShipmentValuesById } from '../../shipments/redux/standard-shipments-values-slice';
import { selectStopValuesById } from '../../stops/redux/stop-values-slice';
import EndOfDayContext from '../end-of-day-context';
import { getAppointmentTimeStringEOD } from '../end-of-day-utils';
import DocumentsCards from './documents-cards';
import EndOfDayDocumentViewer from './end-of-day-document-viewer';
import EndOfDayStopDetailsCard from './end-of-day-stop-details-card';

const DOCUMENT_TYPE_TABS = [DocumentType.EndOfDayDocument];

export default function EndOfDayDocuments({
  setTableIsMinimized,
}: {
  readonly setTableIsMinimized: Dispatch<SetStateAction<boolean>>;
}) {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteEndOfDay } = getPermissionsFlags(
    userPermissions,
    PermissionResource.EndOfDay,
  );

  const { height } = useWindowDimensions();
  const { selectedStopUuid, setSelectedStopUuid, setShouldLoadRoutes } =
    useContext(EndOfDayContext);
  const [showUploadErrorSnackbar, setShowUploadErrorSnackbar] =
    useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<DocumentType | string>('ALL');
  const stop = useAppSelector((state) =>
    selectStopValuesById(state, selectedStopUuid ?? ''),
  );
  const shipment = useAppSelector((state) =>
    selectStandardShipmentValuesById(state, stop?.shipmentUuid ?? ''),
  );
  const addresses = useAppSelector((state) => selectAddresses(state));
  const currentAddress = addresses.find(
    (address) => address.uuid === stop?.addressUuid,
  );

  const [addShipmentDocument] = useCreateShipmentDocumentMutation({
    refetchQueries: [
      RouteDocument,
      OrderWithPaperworkDocument,
      DocumentsByShipmentDocument,
      DocumentsByOrderDocument,
    ],
  });
  const [generateShipmentPreSignedPutUrl] =
    useGenerateShipmentPreSignedPutUrlMutation();

  const { getDocumentTypeCopy, loading } = useDocuments();

  const orderUuid = shipment?.orderUuid;
  const { data: documentsQueryData } = useDocumentsByOrderQuery(
    isNilOrEmptyString(orderUuid)
      ? { skip: true }
      : { variables: { orderUuid } },
  );

  const updateShipmentWithDocument = async (
    file: File,
    fileName: string,
    documentType: DocumentType,
    name: string | null,
    fileType: string,
  ) => {
    if (!isNil(shipment)) {
      const res = await addShipmentDocument({
        variables: {
          createShipmentDocumentInput: {
            documentType,
            fileName,
            fileType,
            name,
            shipmentUuid: shipment.uuid,
            isNewlyCreatedShipment: false,
          },
        },
      });

      const createShipmentDocument = res.data?.createShipmentDocument;
      if (isNil(res.errors) && !isNil(createShipmentDocument)) {
        setShouldLoadRoutes(true);
      } else {
        setShowUploadErrorSnackbar(true);
      }
    }
  };

  const getAwsUrl = async (fileName: string, fileType: string) => {
    const res = await generateShipmentPreSignedPutUrl({
      variables: {
        generateShipmentPreSignedPutUrlInput: {
          fileName,
          fileType,
          shipmentUuid: shipment?.uuid ?? '',
        },
      },
    });
    return res.data?.generateShipmentPreSignedPutUrl;
  };

  const addressString = transformAddressToFullAddressString({
    ...currentAddress,
    line1: currentAddress?.line1 ?? null,
    city: currentAddress?.city ?? null,
    zip: currentAddress?.zip ?? null,
  });
  const timeString = getAppointmentTimeStringEOD(
    stop?.appointmentTime,
    stop?.endAppointmentTime,
  );

  const configuredTimeString = `${timeString} ${transformAppointmentStatus(
    stop?.appointmentTextStatus ?? '',
  )}`;

  if (loading) {
    return null;
  }

  return (
    <Stack
      sx={{
        padding: '10px',
        height: height - 180,
        overflow: 'scroll',
      }}
      spacing={1}
    >
      <Grid container>
        <Grid item xs={12}>
          <EndOfDayStopDetailsCard
            addressString={addressString}
            timeString={configuredTimeString}
            specialInstructions={stop?.specialInstructions}
            driverNotes={stop?.notes}
            onClose={() => {
              setTableIsMinimized(false);
              setSelectedStopUuid(undefined);
            }}
          />
        </Grid>
        <Grid item xs={12} />
      </Grid>
      <PalletButtonGroup
        size="small"
        aria-label="document-type-filters"
        value={currentTab}
        options={[
          {
            value: 'ALL',
            label: 'All',
          },
          ...DOCUMENT_TYPE_TABS.map((documentType) => ({
            value: documentType,
            label: getDocumentTypeCopy({ documentType }),
          })),
        ]}
        onChange={setCurrentTab}
      />
      <TabPanel key="ALL" panelValue="ALL" selectedValue={currentTab}>
        <DocumentsCards
          getAwsUrl={getAwsUrl}
          docs={
            documentsQueryData?.documents
              .filter((document) => !document.isDeleted)
              .map((document) => ({
                preSignedGetUrl: document.preSignedGetUrl,
                fileType: document.fileType,
                fileName: document.fileName,
                uuid: document.uuid,
                pageNumber: document.pageNumber,
                docType: document.type,
                driverFormTemplateUuid: document.driverFormTemplate?.uuid,
                notes: document.notes,
              })) ?? []
          }
          colSize={4}
          uploadDisabled={!canWriteEndOfDay}
          canModifyDocuments={canWriteEndOfDay}
          onUploadDocument={updateShipmentWithDocument}
        />
      </TabPanel>
      {DOCUMENT_TYPE_TABS.map((documentType) => (
        <TabPanel
          key={documentType}
          panelValue={documentType}
          selectedValue={currentTab}
        >
          <EndOfDayDocumentViewer
            shipmentUuid={shipment?.uuid}
            docType={documentType}
            docs={
              documentsQueryData?.documents
                .filter((document) => document.type === documentType)
                .map((document) => ({
                  preSignedGetUrl: document.preSignedGetUrl,
                  fileType: document.fileType,
                  fileName: document.fileName,
                  uuid: document.uuid,
                  pageNumber: document.pageNumber,
                  docType: documentType,
                  driverFormTemplateUuid: document.driverFormTemplate?.uuid,
                  notes: document.notes,
                })) ?? []
            }
          />
        </TabPanel>
      ))}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showUploadErrorSnackbar}
        autoHideDuration={3000}
        onClose={() => {
          setShowUploadErrorSnackbar(false);
        }}
      >
        <Alert
          severity="error"
          onClose={() => {
            setShowUploadErrorSnackbar(false);
          }}
        >
          Error uploading document - please try again.
        </Alert>
      </Snackbar>
    </Stack>
  );
}
