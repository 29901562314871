import { Stack, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import ErrorBanner from '../../../../../common/components/error-banner';
import {
  type GeneralLedgerCodeFragment,
  GeneralLedgerConfigurationDocument,
  useDeleteGeneralLedgerCodeMutation,
} from '../../../../../generated/graphql';
import PalletModal from '../../../../../pallet-ui/modal/pallet-modal-old';

const DeleteGLCodeModal = ({
  open,
  setOpen,
  glCode,
}: {
  readonly open: boolean;
  readonly setOpen: (open: boolean) => void;
  readonly glCode: GeneralLedgerCodeFragment | undefined;
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const handleClose = () => {
    setOpen(false);
    setErrorMessage('');
  };

  const [deleteGeneralLedgerCode] = useDeleteGeneralLedgerCodeMutation({
    refetchQueries: [GeneralLedgerConfigurationDocument],
    onError: ({ message }) => {
      setErrorMessage(message);
    },
    onCompleted: ({
      deleteGeneralLedgerCode: deleteGeneralLedgerCodeResult,
    }) => {
      if (deleteGeneralLedgerCodeResult.__typename === 'MutationErrorOutput') {
        setErrorMessage(deleteGeneralLedgerCodeResult.message);
        return;
      }
      handleClose();
    },
  });

  if (glCode == null) {
    return null;
  }

  return (
    <PalletModal
      open={open}
      title="Delete GL Code"
      actions={{
        bottomRight: [
          {
            onClick: () => {
              deleteGeneralLedgerCode({
                variables: {
                  input: {
                    id: glCode.id,
                  },
                },
              });
            },
            children: 'Delete',
            color: 'error',
          },
        ],
      }}
      onClose={handleClose}
    >
      <Stack gap={2}>
        <Typography>
          Are you sure you want to delete{' '}
          <b>
            {glCode.code} - {glCode.description}
          </b>
          ?
        </Typography>
        {!isEmpty(glCode.accessorials) && (
          <Typography>
            The following accessorials will no longer be associated with this
            code:
            <ul>
              {glCode.accessorials.map((accessorial) => (
                <li key={accessorial.uuid}>{accessorial.name}</li>
              ))}
            </ul>
          </Typography>
        )}
        {!isEmpty(glCode.creditTypes) && (
          <Typography>
            The following credit types will no longer be associated with this
            code:
            <ul>
              {glCode.creditTypes.map((creditType) => (
                <li key={creditType.uuid}>{creditType.name}</li>
              ))}
            </ul>
          </Typography>
        )}

        {!isEmpty(errorMessage) && <ErrorBanner errorMessage={errorMessage} />}
      </Stack>
    </PalletModal>
  );
};

export default DeleteGLCodeModal;
