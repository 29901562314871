/* eslint-disable no-case-declarations */
import { isNil } from 'lodash';
import { exhaustive } from 'shared/switch';
import { shallow } from 'zustand/shallow';
import {
  ReportType,
  useOrderReportLazyQuery,
  useCustomerReportLazyQuery,
  useDriverReportLazyQuery,
  useServiceLevelReportLazyQuery,
  useStationReportLazyQuery,
  useTerminalReportLazyQuery,
  useCreateReportGroupConfigurationMutation,
  useDeleteReportGroupConfigurationMutation,
  useUpdateReportGroupConfigurationMutation,
} from '../../../generated/graphql';
import useGlobalStore from '../../../layouts/dashboard/global-store';
import { convertReportGroupConfigurationToUpdateInputType } from '../report-group-configuration-converter';
import {
  convertReportGroupConfigurationToCustomerReportArgsType,
  convertReportGroupConfigurationToDriverReportArgsType,
  convertReportGroupConfigurationToOrderReportArgsType,
  convertReportGroupConfigurationToServiceLevelReportArgsType,
  convertReportGroupConfigurationToStationReportArgsType,
  convertReportGroupConfigurationToTerminalReportArgsType,
} from '../reports-converter';
import useReportsStore from '../reports-store';
import { initialCreateInput } from '../types/report-types';

const useReport = () => {
  const [getOrderReport] = useOrderReportLazyQuery();
  const [getDriverReport] = useDriverReportLazyQuery();
  const [getCustomerReport] = useCustomerReportLazyQuery();
  const [getServiceLevelReport] = useServiceLevelReportLazyQuery();
  const [getStationReport] = useStationReportLazyQuery();
  const [getTerminalReport] = useTerminalReportLazyQuery();
  const [createReportGroupConfigurationMutation] =
    useCreateReportGroupConfigurationMutation();
  const [updateReportGroupConfigurationMutation] =
    useUpdateReportGroupConfigurationMutation();
  const [deleteReportGroupConfigurationMutation] =
    useDeleteReportGroupConfigurationMutation();
  const selectedTerminalUuid = useGlobalStore(
    (state) => state.selectedTerminalUuid,
  );
  const [
    setOrderReport,
    setDriverReport,
    setCustomerReport,
    setServiceLevelReport,
    setStationReport,
    setTerminalReport,
    create,
    remove,
    setError,
    setIsLoading,
  ] = useReportsStore(
    (state) => [
      state.setOrderReport,
      state.setDriverReport,
      state.setCustomerReport,
      state.setServiceLevelReport,
      state.setStationReport,
      state.setTerminalReport,
      state.createReportGroupConfiguration,
      state.deleteReportGroupConfiguration,
      state.setError,
      state.setIsLoading,
    ],
    shallow,
  );

  const loadReport = async (reportIndex: number) => {
    setIsLoading(true);

    const reportGroupConfiguration =
      useReportsStore.getState().reportGroupConfigurations[reportIndex];

    try {
      const reportType = reportGroupConfiguration?.defaultReportType;
      if (isNil(reportGroupConfiguration) || isNil(reportType)) {
        setIsLoading(false);
        return;
      }

      switch (reportType) {
        case ReportType.Order: {
          const resOrder = await getOrderReport({
            variables: {
              args: convertReportGroupConfigurationToOrderReportArgsType(
                reportGroupConfiguration,
              ),
            },
          });
          setOrderReport(resOrder.data);
          break;
        }
        case ReportType.Customer: {
          const resCustomer = await getCustomerReport({
            variables: {
              args: convertReportGroupConfigurationToCustomerReportArgsType(
                reportGroupConfiguration,
              ),
            },
          });
          setCustomerReport(resCustomer.data);
          break;
        }
        case ReportType.Driver: {
          const resDriver = await getDriverReport({
            variables: {
              args: convertReportGroupConfigurationToDriverReportArgsType(
                reportGroupConfiguration,
              ),
            },
          });
          setDriverReport(resDriver.data);
          break;
        }
        case ReportType.ServiceLevel: {
          const resServiceLevel = await getServiceLevelReport({
            variables: {
              args: convertReportGroupConfigurationToServiceLevelReportArgsType(
                reportGroupConfiguration,
              ),
            },
          });
          setServiceLevelReport(resServiceLevel.data);
          break;
        }
        case ReportType.Station: {
          const stationReportArgs =
            convertReportGroupConfigurationToStationReportArgsType(
              reportGroupConfiguration,
            );

          if (isNil(stationReportArgs)) {
            setStationReport({ stationReport: [] });
            setError('Please select a contact to generate a station report.');
            setIsLoading(false);
            return;
          }

          const resStation = await getStationReport({
            variables: {
              args: stationReportArgs,
            },
          });
          setStationReport(resStation.data);
          break;
        }
        case ReportType.Terminal: {
          const resTerminal = await getTerminalReport({
            variables: {
              args: convertReportGroupConfigurationToTerminalReportArgsType(
                reportGroupConfiguration,
              ),
            },
          });
          setTerminalReport(resTerminal.data, selectedTerminalUuid);
          break;
        }
        default: {
          exhaustive(reportType);
        }
      }
    } catch (error) {
      console.error('Error loading report', error);
      setError('An error occurred while generating the report.');
    } finally {
      setIsLoading(false);
    }
  };

  const createReportGroupConfiguration = async () => {
    const res = await createReportGroupConfigurationMutation({
      variables: {
        createReportGroupConfigurationInput: initialCreateInput,
      },
    });
    if (isNil(res.errors)) {
      create(res.data);
    } else {
      console.error(
        `unable to create report group configuration: ${res.errors}`,
      );
    }
  };

  const updateReportGroupConfiguration = async (reportIndex: number) => {
    const reportGroupConfiguration =
      useReportsStore.getState().reportGroupConfigurations[reportIndex];
    if (!isNil(reportGroupConfiguration)) {
      await updateReportGroupConfigurationMutation({
        variables: {
          updateReportGroupConfigurationInput:
            convertReportGroupConfigurationToUpdateInputType(
              reportGroupConfiguration,
            ),
        },
      });
    }
  };

  const deleteReportGroupConfiguration = async (reportIndex: number) => {
    const reportGroupConfiguration =
      useReportsStore.getState().reportGroupConfigurations[reportIndex];
    if (!isNil(reportGroupConfiguration)) {
      const res = await deleteReportGroupConfigurationMutation({
        variables: {
          uuid: reportGroupConfiguration.uuid,
        },
      });
      if (isNil(res.errors)) {
        remove();
      } else {
        console.error(
          `unable to delete report group configuration: ${res.errors}`,
        );
      }
    }
  };

  return {
    loadReport,
    createReportGroupConfiguration,
    updateReportGroupConfiguration,
    deleteReportGroupConfiguration,
  };
};

export default useReport;
