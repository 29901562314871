import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import { isNil, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { isNilOrEmptyString } from 'shared/string';
import { v4 } from 'uuid';
import { shallow } from 'zustand/shallow';
import CommentInput from '../../../../common/components/comments/order-comments/comment-input';
import CommentsList from '../../../../common/components/comments/order-comments/comments-list';
import useMe from '../../../../common/react-hooks/use-me';
import {
  useCreateContactCommentMutation,
  type ContactCommentEntity,
  useUpdateOrderCommentMutation,
  useDeleteOrderCommentMutation,
} from '../../../../generated/graphql';
import useGlobalStore from '../../../../layouts/dashboard/global-store';
import useContact from '../../../contacts/hooks/use-contact';
import { OrderFormEditAccessProvider } from '../../../orders/components/order-form/contexts/order-form-edit-access-context';
import { OrderFormEditAccess } from '../../../orders/components/order-form/forms/use-order-form-edit-access';
import styles from '../../styles';

type CustomerNotesModalProps = {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly contactUuid: string;
};

const CustomerNotesModal = ({
  open,
  onClose,
  contactUuid,
}: CustomerNotesModalProps) => {
  const [
    setSuccessMessage,
    setShowSuccessMessage,
    setErrorMessage,
    setShowErrorMessage,
  ] = useGlobalStore(
    (state) => [
      state.setSuccessMessage,
      state.setShowSuccessMessage,
      state.setErrorMessage,
      state.setShowErrorMessage,
    ],
    shallow,
  );

  const { data: contact } = useContact({ uuid: contactUuid });

  const { userUuid } = useMe();
  const [createComment] = useCreateContactCommentMutation();
  const [updateOrderComment] = useUpdateOrderCommentMutation();
  const [deleteOrderComment] = useDeleteOrderCommentMutation();

  const [newComment, setNewComment] = useState('');
  const [commentList, setCommentList] = useState<ContactCommentEntity[]>(
    contact?.contactComments ?? [],
  );

  useEffect(() => {
    setCommentList(contact?.contactComments ?? []);
  }, [contact]);

  const onCommentSubmit = async () => {
    if (isNil(userUuid) || isEmpty(newComment.trim())) {
      return;
    }

    const optimisticComment: ContactCommentEntity = {
      uuid: v4(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      comment: newComment,
      authorName: '',
    };

    setCommentList((prev) => [optimisticComment, ...prev]);
    setNewComment('');

    const res = await createComment({
      variables: {
        createContactCommentInput: {
          contactUuid,
          comment: newComment,
          userUuid,
        },
      },
    });

    const responseUuid = res.data?.createContactComment.uuid;
    if (isNil(responseUuid)) {
      return;
    }
    setCommentList((prev) => {
      return prev.map((comment) => {
        if (comment?.uuid === optimisticComment.uuid) {
          return { ...comment, uuid: responseUuid };
        }
        return comment;
      });
    });
  };

  const onEditOrderComment = async (
    commentUuid: string,
    newOrderComment: string,
  ) => {
    const showUpdateOrderCommentErrorMessage = () => {
      setErrorMessage(`Error updating order comment`);
      setShowErrorMessage(true);
    };

    if (isNilOrEmptyString(commentUuid)) {
      showUpdateOrderCommentErrorMessage();
      return;
    }

    try {
      await updateOrderComment({
        variables: {
          updateOrderCommentInput: {
            uuid: commentUuid,
            comment: newOrderComment,
          },
        },
      });
      const newCommentList = commentList.map((comment) => {
        if (comment?.uuid === commentUuid) {
          return {
            ...comment,
            comment: newOrderComment,
          };
        }

        return comment;
      });
      setCommentList(newCommentList);
      setSuccessMessage('Successfully updated order comment');
      setShowSuccessMessage(true);
    } catch {
      showUpdateOrderCommentErrorMessage();
    }
  };

  const onCheckShowOnInvoice = async (commentUuid: string, value: boolean) => {
    await updateOrderComment({
      variables: {
        updateOrderCommentInput: {
          uuid: commentUuid,
          showOnInvoice: value,
        },
      },
    });
    const updatedComments = commentList.map((comment) => {
      if (comment?.uuid === commentUuid) {
        return {
          ...comment,
          showOnInvoice: value,
        };
      }
      return comment;
    });
    setCommentList(updatedComments);
  };

  const onDeleteOrderComment = async (commentUuid: string) => {
    const showDeleteOrderCommentErrorMessage = () => {
      setErrorMessage(`Error deleting order comment`);
      setShowErrorMessage(true);
    };

    if (isNilOrEmptyString(commentUuid)) {
      showDeleteOrderCommentErrorMessage();
      return;
    }

    try {
      const deleteOrderCommentResponse = await deleteOrderComment({
        variables: {
          deleteOrderCommentInput: {
            uuid: commentUuid,
          },
        },
      });
      if (
        deleteOrderCommentResponse.data?.deleteOrderComment.__typename ===
        'DeleteOrderCommentSuccessOutput'
      ) {
        const updatedComments = commentList.filter(
          (comment) => comment?.uuid !== commentUuid,
        );
        setCommentList(updatedComments);
        setSuccessMessage('Successfully deleted order comment');
        setShowSuccessMessage(true);
      } else {
        showDeleteOrderCommentErrorMessage();
      }
    } catch {
      showDeleteOrderCommentErrorMessage();
    }
  };

  if (isNil(contact)) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={onClose}
    >
      <Box sx={styles.modal} display="flex" flexDirection="column">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Notes for {contact.displayName}</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box flexGrow={1} display="flex" flexDirection="column" minHeight={0}>
          <CommentInput
            value={newComment}
            onChange={(e) => {
              setNewComment(e.target.value);
            }}
            onSubmit={onCommentSubmit}
          />
          <OrderFormEditAccessProvider value={OrderFormEditAccess.All}>
            <CommentsList
              comments={commentList}
              onEditComment={onEditOrderComment}
              onCheckShowOnInvoice={onCheckShowOnInvoice}
              onDeleteComment={onDeleteOrderComment}
            />
          </OrderFormEditAccessProvider>
        </Box>
      </Box>
    </Modal>
  );
};

export default CustomerNotesModal;
