import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import { type Dispatch, type SetStateAction, useState } from 'react';
import { assertNotNil } from 'shared/optional';
import DocumentTypeSelector from '../../../../../common/components/document-type-selector';
import useDocuments from '../../../../../common/react-hooks/use-documents';
import useMe from '../../../../../common/react-hooks/use-me';
import {
  type DocumentType,
  type InvoiceForDownloadFragmentFragment,
  InvoiceSendJobBatchType,
  InvoiceTransmissionMethod,
  InvoiceType,
  useContactQuery,
  useDownloadInvoicesMutation,
  useInvoicesByUuidsForDownloadLazyQuery,
  useSendInvoicesMutation,
} from '../../../../../generated/graphql';
import PalletLink from '../../../../../pallet-ui/links/link/pallet-link';
import { convertInvoiceDataToSpreadsheets } from '../../../../invoice-old/utils';
import useInvoicesStore from '../../../invoices-store';
import styles from '../../../styles';

enum PdfDownloadFormat {
  CombinedFile = 'Combined PDF',
  SeparateFilesZipped = 'Separate PDFs (Zipped)',
}
type DownloadInvoiceModalProps = {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly invoiceUuids: string[];
  readonly contactUuid: string | undefined;

  readonly setDownloadErrorSnackbarVisible: Dispatch<SetStateAction<boolean>>;
};

const DownloadInvoiceModal = ({
  isOpen,
  setIsOpen,
  invoiceUuids,
  contactUuid,
  setDownloadErrorSnackbarVisible,
}: DownloadInvoiceModalProps) => {
  const confirm = useConfirm();
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
  const [getInvoicesByUuidForDownload] =
    useInvoicesByUuidsForDownloadLazyQuery();
  const [sendInvoices] = useSendInvoicesMutation();
  const [downloadInvoices] = useDownloadInvoicesMutation();
  const setShowInvoiceSendMenu = useInvoicesStore(
    (state) => state.setShowInvoiceSendMenu,
  );
  const downloadInvoiceBatchDocument = useInvoicesStore(
    (state) => state.downloadInvoiceBatchDocument,
  );
  const [invoiceType, setInvoiceType] = useState<InvoiceType>(
    InvoiceType.PdfItemized,
  );
  const [invoiceAttachments, setInvoiceAttachments] = useState<DocumentType[]>(
    [],
  );
  const [pdfDownloadFormat, setPdfDownloadFormat] = useState<PdfDownloadFormat>(
    PdfDownloadFormat.CombinedFile,
  );
  const { companyData } = useMe();
  const { documentTypesForSelection, loading: useDocumentsLoading } =
    useDocuments();
  useContactQuery({
    variables: { uuid: contactUuid ?? '' },
    fetchPolicy: 'cache-first',
    skip: isNil(contactUuid),
    onCompleted: (contactData) => {
      if (contactData?.contact.__typename === 'CustomerContactEntity') {
        if (!isNil(contactData?.contact.defaultInvoiceType)) {
          setInvoiceType(contactData?.contact.defaultInvoiceType);
        }
        setInvoiceAttachments(
          contactData?.contact.defaultInvoiceDownloadDocuments,
        );
      }
    },
  });

  const downloadExcelInvoices = async ({
    invoicesForDownload,
  }: {
    invoicesForDownload: InvoiceForDownloadFragmentFragment[];
  }) => {
    const spreadsheets = await convertInvoiceDataToSpreadsheets(
      invoicesForDownload,
      companyData,
      invoiceType,
    );

    for (const sheet of spreadsheets) {
      if (!isNil(sheet.url)) {
        fetch(sheet.url).then((response) => {
          response.blob().then((blob) => {
            // Creating new object of excel file
            const fileURL = globalThis.URL.createObjectURL(blob);
            // Setting various property values
            const alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = `invoice-${sheet.invoiceName}.xlsx`;
            alink.click();
          });
        });
      }
    }
  };

  const startDownload = async () => {
    setLoadingDownload(true);
    try {
      assertNotNil(documentTypesForSelection);

      const invoiceByUuidForDownloadResponse =
        await getInvoicesByUuidForDownload({
          variables: {
            uuids: invoiceUuids,
            includeDocuments: true,
          },
        });
      const invoices = invoiceByUuidForDownloadResponse.data?.invoicesByUuids;

      if (isNil(invoices)) {
        setDownloadErrorSnackbarVisible(true);
        return;
      }

      if (
        invoiceType === InvoiceType.ExcelItemized ||
        invoiceType === InvoiceType.ExcelSummarized
      ) {
        await downloadExcelInvoices({
          invoicesForDownload: invoices,
        });
        setLoadingDownload(false);
        setIsOpen(false);
        return;
      }

      const invoicesToDownload = invoices.map((invoice) => ({
        uuid: invoice.uuid,
        invoiceTransmissionMethod: InvoiceTransmissionMethod.Download,
        invoiceType,
        attachments: invoiceAttachments,
      }));

      if (invoicesToDownload.length <= 5) {
        const downloadRes = await downloadInvoices({
          variables: {
            downloadInvoicesInput: {
              invoicesToDownload,
            },
          },
        });

        const document =
          pdfDownloadFormat === PdfDownloadFormat.CombinedFile
            ? downloadRes.data?.downloadInvoices.combinedDocument
            : downloadRes.data?.downloadInvoices.zipDocument;

        const fileName = invoices.length === 1 ? invoices[0]?.name : undefined;

        if (!isNil(downloadRes.errors) || isNil(document)) {
          setDownloadErrorSnackbarVisible(true);
        } else {
          downloadInvoiceBatchDocument(document, fileName);
        }
        setLoadingDownload(false);
        setIsOpen(false);
      }

      const sendRes = await sendInvoices({
        variables: {
          sendInvoicesInput: {
            invoicesToSend: invoicesToDownload,
            invoiceSendJobBatchType: InvoiceSendJobBatchType.Download,
          },
        },
      });

      if (isNil(sendRes.errors)) {
        confirm({
          title: '',
          description: (
            <Typography>
              Please wait while we generate your invoices. You can check the
              progress and download invoices{' '}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <PalletLink
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setShowInvoiceSendMenu(true);
                }}
              >
                here
              </PalletLink>
            </Typography>
          ),
          hideCancelButton: true,
          confirmationText: `Done`,
        }).then(() => {});
      } else {
        setDownloadErrorSnackbarVisible(true);
      }

      setLoadingDownload(false);
      setIsOpen(false);
    } catch {
      setDownloadErrorSnackbarVisible(true);
      captureException('[download-invoice-modal] Error downloading invoices', {
        level: 'error',
        extra: {
          invoiceUuids,
          invoiceType,
          invoiceAttachments,
        },
      });
    }
  };

  if (useDocumentsLoading) {
    return null;
  }

  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <Box sx={[styles.modal, { height: 'auto', width: '400px' }]}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: 'bold' }}>
              Download Invoice{invoiceUuids.length > 1 && 's'}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <IconButton
              sx={{ float: 'right' }}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} sx={{ height: '100%' }}>
            <Stack direction="column" spacing={2}>
              <FormControl>
                <InputLabel id="invoice-type-label">Invoice type</InputLabel>
                <Select
                  required
                  labelId="invoice-type-label"
                  label="Invoice type"
                  value={invoiceType ?? ''}
                  size="small"
                  sx={{ backgroundColor: 'white' }}
                  onChange={(e) => {
                    setInvoiceType(e.target.value as InvoiceType);
                  }}
                >
                  {Object.values(InvoiceType).map((type) => (
                    <MenuItem key={type} id={type} value={type}>
                      {sentenceCase(type)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {[InvoiceType.PdfItemized, InvoiceType.PdfSummarized].includes(
                invoiceType,
              ) && (
                <>
                  <FormControl>
                    <DocumentTypeSelector
                      label="Invoice attachments"
                      value={invoiceAttachments}
                      onChange={setInvoiceAttachments}
                    />
                  </FormControl>
                  <FormControl>
                    <InputLabel id="invoice-type-label">Download as</InputLabel>
                    <Select
                      required
                      labelId="invoice-type-label"
                      label="Download Format"
                      value={pdfDownloadFormat}
                      size="small"
                      sx={{ backgroundColor: 'white' }}
                      onChange={(e) => {
                        setPdfDownloadFormat(
                          e.target.value as PdfDownloadFormat,
                        );
                      }}
                    >
                      {Object.values(PdfDownloadFormat).map((format) => (
                        <MenuItem key={format} id={format} value={format}>
                          {format}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Button
              sx={{ float: 'right' }}
              variant="contained"
              color="info"
              disabled={loadingDownload || useDocumentsLoading}
              endIcon={loadingDownload && <CircularProgress size={15} />}
              onClick={startDownload}
            >
              Download
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default DownloadInvoiceModal;
