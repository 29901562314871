import { inches } from '@buge/ts-units/length';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { isNil, isString } from 'lodash';
import { type FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { pounds } from 'shared/units/rates';
import { isValidNonNegativeNumber } from '../../../../../utils';
import ButtonLink from '../../../../common/components/buttons/button-link';
import { useHasUnsavedChanges } from '../../../../common/react-hooks/use-has-unsaved-changes';
import {
  type CreatePackageSpecInput,
  type PackageSpecFragment,
  PackageSpecStatus,
  useArchivePackageSpecMutation,
  useCreatePackageSpecMutation,
  usePackageSpecQuery,
  useRestorePackageSpecMutation,
  useUpdatePackageSpecMutation,
} from '../../../../generated/graphql';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    background: 'white',
    width: '100%',
    borderBottom: '1px solid',
    borderColor: 'divider',
    p: 2,
  },
  backButton: {
    p: 0,
    minWidth: 'max-content',
    mb: 1,
  },
  title: {
    fontSize: '15px',
    fontWeight: 700,
  },
  sectionHeading: {
    fontSize: '14.5px',
    fontWeight: 500,
  },
};

type PackageSpecPageCreateMode = { mode: 'create' };
type PackageSpecPageEditMode = { mode: 'edit'; id: string };
type PackageSpecPageViewMode = { mode: 'view'; id: string };

export type PackageSpecPageMode =
  | PackageSpecPageCreateMode
  | PackageSpecPageEditMode
  | PackageSpecPageViewMode;

type PackageSpecFormData = {
  name: string;
  weightPounds: string | null;
  lengthInches: string | null;
  widthInches: string | null;
  heightInches: string | null;
};

type PackageSpecFormErrors = {
  [key in keyof PackageSpecFormData]?: string;
};

const requiredFields: Array<keyof PackageSpecFormData> = ['name'];

type PackageSpecBodyProps = {
  readonly pageMode: PackageSpecPageMode;
  readonly initialData?: PackageSpecFragment;
  readonly onSave: (packageSpec: CreatePackageSpecInput) => Promise<void>;
  readonly saving: boolean;
  // If an onToggleArchive function is provided, an archive/un-archive button will be shown.
  readonly onToggleArchive?: () => void;
  readonly togglingArchive?: boolean;
};

const PackageSpecBody: FunctionComponent<PackageSpecBodyProps> = ({
  pageMode,
  initialData,
  onSave,
  saving,
  onToggleArchive,
  togglingArchive = false,
}) => {
  const {
    hasUnsavedChanges,
    triggerHasUnsavedChanges,
    resetHasUnsavedChanges,
  } = useHasUnsavedChanges();
  const [formData, setFormData] = useState<PackageSpecFormData>({
    name: '',
    weightPounds: null,
    lengthInches: null,
    widthInches: null,
    heightInches: null,
  });

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      name: initialData?.name ?? '',
      weightPounds: initialData?.weight?.in(pounds).amount.toString() ?? null,
      lengthInches: initialData?.length?.in(inches).amount.toString() ?? null,
      widthInches: initialData?.width?.in(inches).amount.toString() ?? null,
      heightInches: initialData?.height?.in(inches).amount.toString() ?? null,
    }));
  }, [initialData]);

  const [formErrors, setFormErrors] = useState<PackageSpecFormErrors>({});

  const handleSave = async () => {
    let hasError = false;
    const newErrors: PackageSpecFormErrors = {};
    for (const field of requiredFields) {
      const value = formData[field];
      if (isNil(value) || (isString(value) && value.length === 0)) {
        newErrors[field] = 'This field is required';
        hasError = true;
      }
    }
    const weightPounds = formData.weightPounds?.trim();
    if (
      !isNil(weightPounds) &&
      weightPounds.length > 0 &&
      !isValidNonNegativeNumber(weightPounds)
    ) {
      newErrors.weightPounds = 'Please enter a valid weight';
      hasError = true;
    }
    const lengthInches = formData.lengthInches?.trim();
    if (
      !isNil(lengthInches) &&
      lengthInches.length > 0 &&
      !isValidNonNegativeNumber(lengthInches)
    ) {
      newErrors.lengthInches = 'Please enter a valid length';
      hasError = true;
    }
    const widthInches = formData.widthInches?.trim();
    if (
      !isNil(widthInches) &&
      widthInches.length > 0 &&
      !isValidNonNegativeNumber(widthInches)
    ) {
      newErrors.widthInches = 'Please enter a valid width';
      hasError = true;
    }
    const heightInches = formData.heightInches?.trim();
    if (
      !isNil(heightInches) &&
      heightInches.length > 0 &&
      !isValidNonNegativeNumber(heightInches)
    ) {
      newErrors.heightInches = 'Please enter a valid height';
      hasError = true;
    }
    setFormErrors(newErrors);
    if (!hasError) {
      await onSave({
        name: formData.name,
        weight:
          isNil(weightPounds) || weightPounds.length === 0
            ? null
            : pounds(Number(weightPounds)),
        length:
          isNil(lengthInches) || lengthInches.length === 0
            ? null
            : inches(Number(lengthInches)),
        width:
          isNil(widthInches) || widthInches.length === 0
            ? null
            : inches(Number(widthInches)),
        height:
          isNil(heightInches) || heightInches.length === 0
            ? null
            : inches(Number(heightInches)),
      });
      resetHasUnsavedChanges();
    }
  };

  const status = !isNil(initialData) && initialData.status;

  return (
    <>
      <Box sx={styles.container} gap={1}>
        <Stack
          pb={2}
          justifyContent="space-between"
          direction="row"
          alignItems="start"
        >
          <Box>
            <ButtonLink
              disabled={saving || togglingArchive}
              href="/management"
              variant="text"
              size="small"
              sx={styles.backButton}
            >
              Back
            </ButtonLink>
            <Typography variant="h3" sx={styles.title}>
              {/* eslint-disable-next-line no-nested-ternary */}
              {pageMode.mode === 'create'
                ? 'Add Package Type'
                : pageMode.mode === 'edit'
                  ? 'Edit Package Type'
                  : 'Package Type'}
            </Typography>
          </Box>
          {pageMode.mode !== 'view' && (
            <Button
              disabled={saving || togglingArchive || !hasUnsavedChanges}
              variant="contained"
              onClick={handleSave}
            >
              Save
            </Button>
          )}
        </Stack>
        <FormControl
          required
          disabled={pageMode.mode === 'view'}
          error={'name' in formErrors}
          sx={{ width: 200 }}
        >
          <TextField
            id="package-type-name"
            label="Name"
            value={formData.name}
            error={'name' in formErrors}
            style={{ width: '280px' }}
            onChange={({ target }) => {
              setFormData((prevFormData) => ({
                ...prevFormData,
                name: target.value,
              }));
              setFormErrors(({ name: _ignored, ...prevFormErrors }) => ({
                ...prevFormErrors,
              }));
              triggerHasUnsavedChanges();
            }}
          />
          {'name' in formErrors && (
            <FormHelperText error>{formErrors.name}</FormHelperText>
          )}
        </FormControl>
      </Box>
      <Box sx={styles.container}>
        <Typography sx={styles.sectionHeading}>Defaults</Typography>
        <Stack direction="row" alignItems="center" gap={2} mt={2}>
          <InputLabel style={{ width: '100px' }}>Dimensions</InputLabel>
          <FormControl
            disabled={pageMode.mode === 'view'}
            error={'lengthInches' in formErrors}
          >
            <TextField
              id="package-type-length"
              label="Length"
              value={formData.lengthInches ?? ''}
              error={'lengthInches' in formErrors}
              style={{ width: '80px' }}
              onChange={({ target }) => {
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  lengthInches: target.value,
                }));
                setFormErrors(
                  ({ lengthInches: _ignored, ...prevFormErrors }) => ({
                    ...prevFormErrors,
                  }),
                );
                triggerHasUnsavedChanges();
              }}
            />
          </FormControl>
          <FormControl
            disabled={pageMode.mode === 'view'}
            error={'widthInches' in formErrors}
          >
            <TextField
              id="package-type-width"
              label="Width"
              value={formData.widthInches ?? ''}
              error={'widthInches' in formErrors}
              style={{ width: '80px' }}
              onChange={({ target }) => {
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  widthInches: target.value,
                }));
                setFormErrors(
                  ({ widthInches: _ignored, ...prevFormErrors }) => ({
                    ...prevFormErrors,
                  }),
                );
                triggerHasUnsavedChanges();
              }}
            />
          </FormControl>
          <FormControl
            disabled={pageMode.mode === 'view'}
            error={'heightInches' in formErrors}
          >
            <TextField
              id="package-type-height"
              label="Height"
              value={formData.heightInches ?? ''}
              error={'heightInches' in formErrors}
              style={{ width: '80px' }}
              onChange={({ target }) => {
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  heightInches: target.value,
                }));
                setFormErrors(
                  ({ heightInches: _ignored, ...prevFormErrors }) => ({
                    ...prevFormErrors,
                  }),
                );
                triggerHasUnsavedChanges();
              }}
            />
          </FormControl>
          <Typography variant="body1" color="text.secondary">
            inches<sup>3</sup>
          </Typography>
        </Stack>
        {'lengthInches' in formErrors && (
          <FormHelperText error>{formErrors.lengthInches}</FormHelperText>
        )}
        {'widthInches' in formErrors && (
          <FormHelperText error>{formErrors.widthInches}</FormHelperText>
        )}
        {'heightInches' in formErrors && (
          <FormHelperText error>{formErrors.heightInches}</FormHelperText>
        )}
        <Stack direction="row" alignItems="center" gap={2} mt={2}>
          <InputLabel htmlFor="package-type-weight" style={{ width: '100px' }}>
            Weight
          </InputLabel>
          <TextField
            id="package-type-weight"
            sx={{ width: '85px' }}
            error={'weightPounds' in formErrors}
            value={formData.weightPounds ?? ''}
            onChange={({ target }) => {
              setFormData((prevFormData) => ({
                ...prevFormData,
                weightPounds: target.value,
              }));
              setFormErrors(
                ({ weightPounds: _ignored, ...prevFormErrors }) => ({
                  ...prevFormErrors,
                }),
              );
              triggerHasUnsavedChanges();
            }}
          />
          <Typography variant="body1" color="text.secondary">
            pounds
          </Typography>
        </Stack>
        {'weightPounds' in formErrors && (
          <FormHelperText error>{formErrors.weightPounds}</FormHelperText>
        )}
      </Box>
      {!isNil(onToggleArchive) && (
        <Box sx={styles.container} gap={2}>
          <Box>
            <Typography sx={styles.sectionHeading}>
              Archive this package type
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {status === PackageSpecStatus.Archived
                ? 'This package type is archived and is not available for new orders.'
                : 'Archiving this package type will make it unavailable for new orders.'}
            </Typography>
          </Box>
          {/* This wrapping box is here to keep the button from being stretched to full width. */}
          <Box>
            <Button
              variant="outlined"
              color={status === PackageSpecStatus.Active ? 'error' : undefined}
              disabled={togglingArchive}
              onClick={onToggleArchive}
            >
              {status === PackageSpecStatus.Archived ? 'Un-archive' : 'Archive'}
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

type EditPackageSpecProps = {
  readonly pageMode: PackageSpecPageEditMode | PackageSpecPageViewMode;
  readonly onSave: (packageSpec: CreatePackageSpecInput) => Promise<void>;
  readonly saving: boolean;
};

const EditPackageSpec: FunctionComponent<EditPackageSpecProps> = ({
  pageMode,
  onSave,
  saving,
}) => {
  const { id } = pageMode;
  const { data, loading } = usePackageSpecQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id,
    },
  });

  const [archivePackageSpec, { loading: archiving }] =
    useArchivePackageSpecMutation({
      fetchPolicy: 'network-only',
    });
  const [restorePackageSpec, { loading: restoring }] =
    useRestorePackageSpecMutation({
      fetchPolicy: 'network-only',
    });

  const onArchive = async () =>
    archivePackageSpec({
      fetchPolicy: 'network-only',
      variables: {
        id,
      },
    });

  const onRestore = async () =>
    restorePackageSpec({
      fetchPolicy: 'network-only',
      variables: {
        id,
      },
    });

  const onToggleArchive = () => {
    if (data) {
      if (data.packageSpec.status === PackageSpecStatus.Archived) {
        onRestore();
      } else {
        onArchive();
      }
    }
  };

  if (loading && isNil(data)) {
    return <CircularProgress size={15} />;
  }

  return (
    <PackageSpecBody
      pageMode={pageMode}
      initialData={data?.packageSpec}
      saving={saving}
      togglingArchive={archiving || restoring}
      onSave={onSave}
      onToggleArchive={onToggleArchive}
    />
  );
};

type PackageSpecProps = {
  readonly pageMode: PackageSpecPageMode;
};

const PackageSpec: FunctionComponent<PackageSpecProps> = ({ pageMode }) => {
  const navigate = useNavigate();
  const [updatePackageSpec, { loading: updating }] =
    useUpdatePackageSpecMutation({
      fetchPolicy: 'network-only',
    });
  const [createPackageSpec, { loading: creating }] =
    useCreatePackageSpecMutation({
      fetchPolicy: 'network-only',
    });

  const onSave: PackageSpecBodyProps['onSave'] = async (data) => {
    await (pageMode.mode === 'create'
      ? createPackageSpec({
          variables: {
            createPackageSpecInput: data,
          },
          onCompleted: (resultData) => {
            navigate(
              `/management/package-types/${resultData.createPackageSpec.packageSpec.id}`,
            );
          },
        })
      : updatePackageSpec({
          variables: {
            updatePackageSpecInput: {
              id: pageMode.id,
              ...data,
            },
          },
        }));
  };

  if (pageMode.mode === 'create') {
    return (
      <PackageSpecBody pageMode={pageMode} saving={creating} onSave={onSave} />
    );
  }

  return (
    <EditPackageSpec
      key={pageMode.id}
      pageMode={pageMode}
      saving={updating}
      onSave={onSave}
    />
  );
};

export default PackageSpec;
