import SyncIcon from '@mui/icons-material/Sync';
import {
  CircularProgress,
  IconButton,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  tooltipClasses,
  type TooltipProps,
  Typography,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { sentenceCase } from 'change-case';
import currency from 'currency.js';
import { isEmpty, isNil, sumBy } from 'lodash';
import { exhaustive } from 'shared/switch';
import { percent } from 'shared/units/scalar';
import { shallow } from 'zustand/shallow';
import { FeatureFlag } from '../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../common/react-hooks/use-feature-flag';
import {
  CustomChargeBillingMethod,
  type FormattedOrderFragment,
  FreightBillingMethod,
  FuelBillingMethod,
  OrderTableField,
  type OrderTableFieldItemizedChargesFragment,
  ShipmentType,
  StopType,
  useRateOrderByUuidLazyQuery,
} from '../../../../../generated/graphql';
import useGlobalStore from '../../../../../layouts/dashboard/global-store';
import { getShipmentTypeCopy } from '../../../../invoices/utils';
import { useOrderItemizedCharges } from '../../../hooks/use-order-itemized-charges';
import { getOrderTableFieldValue } from '../../utils';

const TCell = styled(TableCell)(({ theme }) => ({
  padding: 0,
  paddingLeft: 3,
  paddingRight: 3,
  fontSize: 12,
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
  },
  borderBottom: 'none',
}));

const formatFuelChargeRate = (
  shipmentCharge: OrderTableFieldItemizedChargesFragment['shipmentCharges'][0],
) => {
  if (isNil(shipmentCharge.fuelBillingMethod)) return '-';
  switch (shipmentCharge.fuelBillingMethod) {
    case FuelBillingMethod.FlatRate: {
      return shipmentCharge.fuelFlatRate?.format();
    }
    case FuelBillingMethod.None: {
      return '-';
    }
    case FuelBillingMethod.AutoCalculate:
    case FuelBillingMethod.Percentage: {
      return shipmentCharge.fuelSurchargeRate?.in(percent).toString();
    }
    default: {
      return exhaustive(shipmentCharge.fuelBillingMethod);
    }
  }
};

const getRatedShipmentCharge = ({
  shipmentType,
  stopType,
  rateOrderOutput,
}: {
  shipmentType: ShipmentType | null | undefined;
  stopType: StopType | null | undefined;
  rateOrderOutput:
    | OrderTableFieldItemizedChargesFragment['rateOrderOutput']
    | null
    | undefined;
}) => {
  if (
    rateOrderOutput?.__typename === 'RateOrderErrorOutput' ||
    isNil(shipmentType)
  )
    return null;

  switch (shipmentType) {
    case ShipmentType.OrderCharges: {
      return rateOrderOutput?.orderCharges;
    }
    case ShipmentType.LineHaul: {
      return rateOrderOutput?.lineHaulCharges;
    }
    case ShipmentType.Regular: {
      if (
        !isNil(stopType) &&
        [StopType.Recovery, StopType.Pickup].includes(stopType)
      ) {
        return rateOrderOutput?.inboundShipmentCharges;
      }
      return rateOrderOutput?.outboundShipmentCharges;
    }

    default: {
      return exhaustive(shipmentType);
    }
  }
};

export const ChargesTooltipComponent = styled(
  ({ className, ...props }: TooltipProps) => (
    /* eslint-disable react/jsx-props-no-spreading */
    <Tooltip {...props} classes={{ popper: className }} placement="left" />
  ),
)(({ theme: tooltipTheme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    minWidth: 475,
    fontSize: tooltipTheme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    // paddingTop: '10px',
    // paddingBottom: '10px',
    gap: '5px',
  },
}));

const ChargesTooltipContent = ({
  order,
}: {
  readonly order: FormattedOrderFragment;
}) => {
  const { itemizedChargesField, loading } = useOrderItemizedCharges(order);
  const ffNewItemizedChargesTooltip = useFeatureFlag(
    FeatureFlag.FF_NEW_ITEMIZED_CHARGES_TOOLTIP,
  );

  if (loading) {
    return (
      <Stack sx={{ width: '100%' }} alignItems="center" justifyContent="center">
        <CircularProgress />
      </Stack>
    );
  }

  if (isNil(itemizedChargesField)) {
    return <Typography>Itemized charges not found.</Typography>;
  }

  const accessorials = itemizedChargesField.customCharges.filter(
    (charge) => charge.billingMethod === CustomChargeBillingMethod.Accessorial,
  );
  const customCharges = itemizedChargesField.customCharges.filter(
    (charge) => charge.billingMethod === CustomChargeBillingMethod.AdHoc,
  );
  const shipmentCharges = itemizedChargesField?.shipmentCharges;
  const rateOrderOutput = itemizedChargesField?.rateOrderOutput;

  if (ffNewItemizedChargesTooltip) {
    return (
      <Stack sx={{ backgroundColor: 'white', p: 1 }}>
        <Table>
          {shipmentCharges.map((shipmentCharge) => {
            const { tariff } = shipmentCharge;
            const ccTotal = sumBy(
              shipmentCharge.customCharges,
              (cc) => cc.total,
            );
            const shipmentChargeTotal = currency(ccTotal)
              .add(shipmentCharge.freightTotalAmount ?? 0)
              .add(shipmentCharge.fuelTotalAmount ?? 0);
            const ratedShipmentCharge = getRatedShipmentCharge({
              shipmentType: shipmentCharge.shipmentType,
              stopType: shipmentCharge.stopType,
              rateOrderOutput,
            });
            let differentFreightCharge = false;
            let differentFuelCharge = false;
            let differentTotal = false;
            if (rateOrderOutput?.__typename === 'RateOrderSuccessOutput') {
              differentFreightCharge =
                shipmentCharge.freightTotalAmount?.value !==
                ratedShipmentCharge?.freightCharge?.totalDollars.value;
              differentFuelCharge =
                (shipmentCharge.fuelBillingMethod ===
                  FuelBillingMethod.AutoCalculate ||
                  shipmentCharge.fuelBillingMethod ===
                    FuelBillingMethod.Percentage) &&
                ratedShipmentCharge?.fuelCharge?.surchargeRatePercentage?.value() !==
                  shipmentCharge.fuelSurchargeRate?.value();
              differentTotal =
                !isNil(ratedShipmentCharge) &&
                ratedShipmentCharge?.total.value !== shipmentChargeTotal.value;
            }

            return (
              <>
                <TableHead>
                  <TCell sx={{ fontWeight: 'bold', width: '50%' }}>
                    {getShipmentTypeCopy({
                      shipmentType: shipmentCharge.shipmentType,
                      legs: [
                        {
                          endStop: {
                            stopType: shipmentCharge.stopType,
                          },
                        },
                      ],
                    })}
                  </TCell>
                  <TCell sx={{ fontWeight: 'bold' }}>Rate </TCell>
                  <TCell sx={{ fontWeight: 'bold' }}>Total </TCell>
                </TableHead>
                <TableBody>
                  {!isNil(shipmentCharge.freightBillingMethod) && (
                    <TableRow>
                      <TCell>
                        {sentenceCase(shipmentCharge.freightBillingMethod)}
                        {shipmentCharge.freightBillingMethod ===
                        FreightBillingMethod.Tariff
                          ? ` - ${tariff?.tariffGroup?.name ?? 'None'}${isNil(tariff) ? '' : `- ${tariff.tariffZone.name}`}`
                          : ''}
                      </TCell>
                      <TCell>{shipmentCharge.freightRate?.format()}</TCell>
                      <TCell>
                        <Stack direction="row" spacing={0.5}>
                          <Typography variant="caption">
                            {shipmentCharge.freightTotalAmount?.format()}
                          </Typography>
                          {differentFreightCharge && (
                            <Typography
                              sx={{ color: 'orange' }}
                              variant="caption"
                            >
                              →{' '}
                              {ratedShipmentCharge?.freightCharge?.totalDollars?.format()}
                            </Typography>
                          )}
                        </Stack>
                      </TCell>
                    </TableRow>
                  )}
                  {!isNil(shipmentCharge.fuelBillingMethod) && (
                    <TableRow>
                      <TCell>
                        Fuel - {sentenceCase(shipmentCharge.fuelBillingMethod)}
                      </TCell>
                      <TCell>
                        <Stack direction="row" spacing={0.5}>
                          <Typography variant="caption">
                            {formatFuelChargeRate(shipmentCharge)}
                          </Typography>
                          {differentFuelCharge && (
                            <Typography
                              sx={{ color: 'orange' }}
                              variant="caption"
                            >
                              →{' '}
                              {ratedShipmentCharge?.fuelCharge?.surchargeRatePercentage?.toString()}
                            </Typography>
                          )}
                        </Stack>
                      </TCell>
                      <TCell>
                        <Stack direction="row" spacing={0.5}>
                          <Typography variant="caption">
                            {shipmentCharge.fuelTotalAmount?.format()}
                          </Typography>
                          {differentFuelCharge &&
                            ratedShipmentCharge?.fuelCharge?.totalDollars
                              ?.value !==
                              shipmentCharge.fuelTotalAmount?.value && (
                              <Typography
                                sx={{ color: 'orange' }}
                                variant="caption"
                              >
                                →{' '}
                                {ratedShipmentCharge?.fuelCharge?.totalDollars?.format()}
                              </Typography>
                            )}
                        </Stack>
                      </TCell>
                    </TableRow>
                  )}
                  {shipmentCharge.customCharges.map((cc, ccIndex) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <TableRow key={ccIndex}>
                      <TCell>
                        {isEmpty(cc.name) ? 'Custom charge' : cc.name}
                      </TCell>
                      <TCell />
                      <TCell>{currency(cc.total).format()}</TCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TCell colSpan={2} />
                    <TCell>
                      <Stack direction="row" spacing={0.5}>
                        <Typography variant="caption">
                          {shipmentChargeTotal.format()}
                        </Typography>
                        {differentTotal && (
                          <Typography
                            sx={{ color: 'orange' }}
                            variant="caption"
                          >
                            → {ratedShipmentCharge?.total?.format()}
                          </Typography>
                        )}
                      </Stack>
                    </TCell>
                  </TableRow>
                </TableBody>
              </>
            );
          })}
        </Table>
      </Stack>
    );
  }

  return (
    <Stack
      sx={{
        backgroundColor: 'white',
        width: '100%',
        padding: '15px',
        gap: '5px',
      }}
    >
      <Typography sx={{ fontWeight: 'bold', fontSize: '13px' }}>
        Charges summary
      </Typography>
      <Typography variant="caption">
        Total freight charge:{' '}
        {currency(itemizedChargesField.freightChargeTotal).format()}
      </Typography>
      <Typography variant="caption">
        Total fuel charge:{' '}
        {currency(itemizedChargesField.fuelChargeTotal).format()}
      </Typography>
      {!isEmpty(accessorials) && (
        <Typography sx={{ fontWeight: 'bold', fontSize: '13px' }}>
          Accessorials
        </Typography>
      )}
      {accessorials?.map((charge) => {
        return (
          <Typography key={charge.name} variant="caption">
            {sentenceCase(charge.name ?? '')}: ${charge.total.toFixed(2)}
          </Typography>
        );
      })}
      {!isEmpty(customCharges) && (
        <Typography sx={{ fontWeight: 'bold', fontSize: '13px' }}>
          Custom charges
        </Typography>
      )}
      {customCharges?.map((charge) => {
        return (
          <Typography key={charge.name} variant="caption">
            {sentenceCase(charge.name)}: ${charge.total.toFixed(2)}
          </Typography>
        );
      })}
    </Stack>
  );
};

export const ChargesComponent = ({
  order,
}: {
  readonly order: FormattedOrderFragment;
}) => {
  const ffNewItemizedChargesTooltip = useFeatureFlag(
    FeatureFlag.FF_NEW_ITEMIZED_CHARGES_TOOLTIP,
  );
  const [setOrderUuidToRefetch] = useGlobalStore(
    (state) => [state.setOrderUuidToRefetch],
    shallow,
  );
  const [rateOrderByUuid, { loading }] = useRateOrderByUuidLazyQuery();
  const total = getOrderTableFieldValue({
    order,
    orderTableField: OrderTableField.Charges,
  });
  const totalCurrency = currency(total);
  let ratedTotal;
  const rateOrderOutput = order?.itemizedChargesField?.rateOrderOutput;
  if (
    !isNil(rateOrderOutput) &&
    rateOrderOutput.__typename === 'RateOrderSuccessOutput'
  ) {
    ratedTotal = rateOrderOutput.totalDollars;
  }
  const rateOrder = async () => {
    if (isNil(order)) return;
    await rateOrderByUuid({
      variables: {
        uuid: order.uuid,
        persist: true,
      },
    });
    setOrderUuidToRefetch(order.uuid);
  };

  return (
    <ChargesTooltipComponent title={<ChargesTooltipContent order={order} />}>
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <Typography
          sx={{
            fontSize: '13px',
            textUnderlineOffset: '5px',
            textDecoration: 'underline',
            textDecorationStyle: 'dotted',
          }}
        >
          {total}
        </Typography>
        {!isNil(ratedTotal) &&
          ratedTotal.value !== totalCurrency.value &&
          ffNewItemizedChargesTooltip && (
            <>
              <Typography sx={{ fontSize: '13px', color: 'orange' }}>
                → {ratedTotal.format()}
              </Typography>
              <Tooltip title="Save new rate">
                <IconButton
                  size="small"
                  sx={{ p: '4px' }}
                  onClick={async (e) => {
                    e.stopPropagation();
                    await rateOrder();
                  }}
                >
                  <SyncIcon
                    sx={{
                      fontSize: 18,
                      animation: loading
                        ? 'spin 0.5s linear infinite'
                        : undefined,
                      '@keyframes spin': {
                        '0%': {
                          transform: 'rotate(360deg)',
                        },
                        '100%': {
                          transform: 'rotate(0deg)',
                        },
                      },
                    }}
                  />
                </IconButton>
              </Tooltip>
            </>
          )}
      </Stack>
    </ChargesTooltipComponent>
  );
};
