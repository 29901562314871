import { Box, Button, FormGroup, Stack, TextField } from '@mui/material';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getPermissionsFlags } from 'shared/roles';
import CSVDownloadButton from '../../../common/components/buttons/csv-download-button';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  type ContactsPageListingQuery,
  PermissionResource,
  useContactsPageListingQuery,
  useContactsReportLazyQuery,
} from '../../../generated/graphql';
import {
  ActiveArchivedButtonGroup,
  ActiveOrArchivedTab,
} from '../../management/components/common/active-archived-tab-panel';
import { formatContactsReportToCSV } from '../utils';

const ContactsPage = () => {
  const { userPermissions } = useUserRoles();

  const { canWrite: canWriteContacts } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Contacts,
  );

  const navigate = useNavigate();
  const { data: contactsData, loading } = useContactsPageListingQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [getContactsReport] = useContactsReportLazyQuery();

  const [searchText, setSearchText] = useState('');

  const [isActiveOrArchived, setIsActiveOrArchived] =
    useState<ActiveOrArchivedTab>(ActiveOrArchivedTab.ACTIVE);

  const contactsFiltered = (contactsData?.contacts ?? []).filter(
    (contact) =>
      (contact.displayName.toLowerCase().startsWith(searchText.toLowerCase()) ||
        contact.type.toLowerCase().startsWith(searchText.toLowerCase()) ||
        (contact.contactReferenceNumber ?? '')
          .toLowerCase()
          .startsWith(searchText.toLowerCase())) &&
      (isActiveOrArchived === ActiveOrArchivedTab.ACTIVE
        ? contact.isActive
        : !contact.isActive),
  );
  const headerTemplate = () => {
    return (
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={1}>
          <ActiveArchivedButtonGroup
            activeTab={isActiveOrArchived}
            setTab={setIsActiveOrArchived}
          />
          <FormGroup>
            <TextField
              sx={{ minWidth: 250 }}
              placeholder="Search"
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </FormGroup>
        </Stack>
        <Stack justifyContent="flex-end" direction="row" width="100%" gap={1}>
          <CSVDownloadButton
            getData={async () => {
              const { data } = await getContactsReport();
              return formatContactsReportToCSV(data);
            }}
            filename="contacts.csv"
            label="Download CSV"
            buttonProps={{ variant: 'contained' }}
            reportType="contacts"
            dataForTimingLog={{}}
          />
          <Button
            variant="contained"
            disabled={!canWriteContacts}
            onClick={() => {
              navigate('/contacts/add');
            }}
          >
            Add Customer
          </Button>
        </Stack>
      </Stack>
    );
  };

  const editButtonTemplate = (
    data: ContactsPageListingQuery['contacts'][0],
  ) => {
    return (
      <Button
        variant="contained"
        onClick={() => {
          navigate(`/contacts/${data.uuid}?edit=true`);
        }}
      >
        Edit
      </Button>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      <DataTable
        scrollable
        value={contactsFiltered}
        size="small"
        scrollHeight="flex"
        emptyMessage="No contacts"
        loading={loading}
        header={headerTemplate}
        onRowDoubleClick={(event) => {
          navigate(`/contacts/${event.data.uuid}?edit=true`);
        }}
      >
        <Column
          sortable
          field="displayName"
          header="Name"
          style={{ width: '40%' }}
        />
        <Column
          sortable
          field="contactReferenceNumber"
          header="Account ID"
          style={{ width: '40%' }}
        />
        <Column field="" header="" body={editButtonTemplate} align="right" />
      </DataTable>
    </Box>
  );
};

export default ContactsPage;
