import { Popover } from '@mui/material';
import { type Dispatch, memo, type SetStateAction } from 'react';
import CreateMultipleRoutes from './create-multiple-routes';

const CreateMultipleRoutesModal = ({
  open,
  setOpen,
  anchorEl,
  stopUuidsToAssign,
  onCreate,
}: {
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
  readonly anchorEl: Element | null;
  readonly stopUuidsToAssign?: string[];
  readonly onCreate?: () => void;
}) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={() => {
        setOpen(false);
      }}
    >
      <CreateMultipleRoutes
        open={open}
        stopUuidsToAssign={stopUuidsToAssign}
        onCreate={() => {
          onCreate?.();
          setOpen(false);
        }}
      />
    </Popover>
  );
};

export default memo(CreateMultipleRoutesModal);
