import { Document } from '@react-pdf/renderer';
import {
  type OrderPodReportShipmentFragment,
  type Segment,
} from '../../../generated/graphql';
import { PodReportComponent } from './generated-pod-report-pdf';

const GeneratedCustomerChargesReportPdf = ({
  segment,
  shipments,
}: {
  readonly segment: Segment | undefined;
  readonly shipments: OrderPodReportShipmentFragment[];
}) => {
  return (
    <Document title="POD Report">
      {shipments.map((shipment) => {
        return (
          <PodReportComponent
            showCharges
            segment={segment}
            shipment={shipment}
          />
        );
      })}
    </Document>
  );
};

export default GeneratedCustomerChargesReportPdf;
