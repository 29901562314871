import { isEmpty, isNil } from 'lodash';
import { findWithIndex } from 'shared/array';
import { type BillingReviewOrder } from '../billing-review-store';
import { type PaginationAction } from '../types/constants';

/**
 * Find the previous order in a given list of orders given the current order uuid.
 *
 * If the current order is not in the list passed in we return null.
 *
 * Otherwise:
 *
 * * Get the previous order in the list.
 * * If that order has prorated orders, return that order's LAST prorated order
 * * Otherwise, return the previous order in the list
 * * If there is no previous order, we return the fallback that is passed in. Usually this is PREV_PAGE but could be another uuid.
 */
export const findPreviousUuidInListOfOrders = ({
  orders,
  currentUuid,
  fallbackIfNoPrevOrder,
  ignoreProratedOrders,
}: {
  orders: BillingReviewOrder[];
  currentUuid: string;
  fallbackIfNoPrevOrder: PaginationAction.PREV_PAGE | string | null;
  ignoreProratedOrders?: boolean;
}): PaginationAction.PREV_PAGE | string | null => {
  const order = findWithIndex(orders, (o) => o.uuid === currentUuid);

  if (!isNil(order)) {
    const { index } = order;
    // Check for prorated orders first
    const prevOrder = orders[index - 1];
    if (
      !isEmpty(prevOrder?.queriedProratedOrdersWith) &&
      !isNil(prevOrder?.queriedProratedOrdersWith) &&
      ignoreProratedOrders !== true
    ) {
      return prevOrder.queriedProratedOrdersWith.at(-1)?.uuid ?? null;
    }

    // Return previous order in the list
    if (index > 0) {
      return orders[index - 1]?.uuid ?? null;
    }
    return fallbackIfNoPrevOrder;
  }
  return null;
};

/**
 * Find the previous prorated order in a given list of orders given the current order uuid.
 *
 * If the current order is not a prorated order of one of the orders in the list we return null.
 *
 * Otherwise:
 *
 * * If the current order is a prorated order in one of the orders in the list, we return the previous prorated order.
 * * If there is no previous prorated order, we recursively call findPreviousUuidInListOfOrders to get the previous order in the list as if we were
 * * navigating from the parent of the prorated order (we pass in ignoreProratedOrders: true to avoid an infinite loop)
 */
export const findPrevProratedUuidInListOfOrders = ({
  orders,
  currentUuid,
  fallbackIfNoPrevOrder,
}: {
  orders: BillingReviewOrder[];
  currentUuid: string;
  fallbackIfNoPrevOrder: PaginationAction.PREV_PAGE | string | null;
}): PaginationAction.PREV_PAGE | string | null => {
  const parentOrder = orders.find((o) =>
    o.queriedProratedOrdersWith?.some((p) => p.uuid === currentUuid),
  );

  if (!isNil(parentOrder?.queriedProratedOrdersWith)) {
    const { index } =
      findWithIndex(
        parentOrder.queriedProratedOrdersWith,
        (p) => p.uuid === currentUuid,
      ) ?? {};

    if (!isNil(index)) {
      if (index === 0) {
        return parentOrder.uuid;
      }
      return parentOrder.queriedProratedOrdersWith[index - 1]?.uuid ?? null;
    }
    return findPreviousUuidInListOfOrders({
      orders,
      currentUuid: parentOrder.uuid,
      fallbackIfNoPrevOrder,
      ignoreProratedOrders: true,
    });
  }
  return null;
};

/**
 * Find the next order in a given list of orders given the current order uuid.
 *
 * If the current order is not in the list passed in we return null.
 *
 * Otherwise:
 *
 * * If the current order is an order that is a 'parent' of prorated orders, we return the first
 * * prorated order (granted ignoreProratedOrders is not true)
 *
 * * Finally, if the current order is not a prorated order, we just return the next order in the list.
 * * If there is no next order, we return the fallback that is passed in. Usually this is 'NEXT_PAGE'.
 *
 * @param orders
 * @param currentUuid
 * @param fallbackIfNoPrevOrder
 * @param ignoreProratedOrders
 * @returns
 */
export const findNextUuidInListOfOrders = ({
  orders,
  currentUuid,
  fallbackIfNoNextOrder,
  ignoreProratedOrders,
}: {
  orders: BillingReviewOrder[];
  currentUuid: string;
  fallbackIfNoNextOrder: PaginationAction.NEXT_PAGE | string | null;
  ignoreProratedOrders?: boolean;
}): PaginationAction.NEXT_PAGE | string | null => {
  const order = findWithIndex(orders, (o) => o.uuid === currentUuid);

  if (!isNil(order)) {
    const { index, value } = order;
    // Check for prorated orders first
    if (
      !isEmpty(value.queriedProratedOrdersWith) &&
      !isNil(value.queriedProratedOrdersWith) &&
      ignoreProratedOrders !== true
    ) {
      return value.queriedProratedOrdersWith[0]?.uuid ?? null;
    }

    // Return next order in the list
    if (index < orders.length - 1) {
      return orders[index + 1]?.uuid ?? null;
    }
    return fallbackIfNoNextOrder;
  }
  return null;
};

/**
 * Find the next prorated order in a given list of orders given the current order uuid.
 *
 * If the current order is not a prorated order of one of the orders in the list we return null.
 *
 * Otherwise:
 *
 * * If the current order is a prorated order in one of the orders in the list, we return the next prorated order.
 * * If there is no next prorated order, we recursively call findNextUuidInListOfOrders to get the next order in the list as if we were
 * * navigating from the parent of the prorated order (we pass in ignoreProratedOrders: true to avoid an infinite loop)
 */
export const findNextProratedUuidInListOfOrders = ({
  orders,
  currentUuid,
  fallbackIfNoNextOrder,
}: {
  orders: BillingReviewOrder[];
  currentUuid: string;
  fallbackIfNoNextOrder: PaginationAction.NEXT_PAGE | string | null;
}): PaginationAction.NEXT_PAGE | string | null => {
  const parentOrder = orders.find((o) =>
    o.queriedProratedOrdersWith?.some((p) => p.uuid === currentUuid),
  );

  if (!isNil(parentOrder?.queriedProratedOrdersWith)) {
    const { index } =
      findWithIndex(
        parentOrder.queriedProratedOrdersWith,
        (p) => p.uuid === currentUuid,
      ) ?? {};

    if (
      !isNil(index) &&
      index < parentOrder.queriedProratedOrdersWith.length - 1
    ) {
      return parentOrder.queriedProratedOrdersWith[index + 1]?.uuid ?? null;
    }
    return findNextUuidInListOfOrders({
      orders,
      currentUuid: parentOrder.uuid,
      fallbackIfNoNextOrder,
      ignoreProratedOrders: true,
    });
  }
  return null;
};
