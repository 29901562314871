import { ArrowRightAlt } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { isNil, uniqBy } from 'lodash';
import {
  type Dispatch,
  type SetStateAction,
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { shallow } from 'zustand/shallow';
import { type Option } from '../../../common/filters/types';
import useLineHaulSegments from '../../../common/react-hooks/use-line-haul-segments';
import useLineHaulDispatchActions from '../hooks/use-line-haul-dispatch-actions';
import useLineHaulDispatchStore from '../store/line-haul-dispatch-store';

const CreateManifestModal = ({
  open,
  setOpen,
  originTerminalOptionFromFilter,
  destinationTerminalOptionFromFilter,
}: {
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
  readonly originTerminalOptionFromFilter: Option | undefined;
  readonly destinationTerminalOptionFromFilter: Option | undefined;
}) => {
  const { createNewManifest } = useLineHaulDispatchActions();

  const [originTerminalUuid, setOriginTerminalUuid] = useState<
    string | undefined
  >();

  const [showOriginSelect, setShowOriginSelect] = useState<boolean>(false);

  const [destinationTerminalUuid, setDestinationTerminalUuid] = useState<
    string | undefined
  >();
  const [showDestinationSelect, setShowDestinationSelect] =
    useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );

  const handleClose = () => {
    setOpen(false);
    setDestinationTerminalUuid(undefined);
    setOriginTerminalUuid(undefined);
    setErrorMessage(undefined);
    setShowOriginSelect(false);
    setShowDestinationSelect(false);
  };

  const { uniqueSegments } = useLineHaulSegments();

  const [planningDate, setSnackbarSuccessMessage] = useLineHaulDispatchStore(
    (state) => [state.planningDate, state.setSnackbarSuccessMessage],
    shallow,
  );

  useEffect(() => {
    if (!isNil(originTerminalOptionFromFilter) && open) {
      setOriginTerminalUuid(originTerminalOptionFromFilter.value);
    }
    if (open) {
      setShowOriginSelect(true);
    }
  }, [originTerminalOptionFromFilter, open]);

  useEffect(() => {
    if (!isNil(destinationTerminalOptionFromFilter) && open) {
      setDestinationTerminalUuid(destinationTerminalOptionFromFilter.value);
    }
    if (open) {
      setShowDestinationSelect(true);
    }
  }, [destinationTerminalOptionFromFilter, open]);

  const validStartTerminals = useMemo(() => {
    if (isNil(destinationTerminalUuid)) {
      return uniqBy(
        uniqueSegments
          .map((s) => s.startTerminal)
          .sort((a, b) => a.code.localeCompare(b.code)),
        (t) => t.uuid,
      );
    }
    return uniqBy(
      uniqueSegments
        ?.filter((s) => s.endTerminal.uuid === destinationTerminalUuid)
        .map((s) => s.startTerminal)
        .sort((a, b) => a.code.localeCompare(b.code)),
      (t) => t.uuid,
    );
  }, [destinationTerminalUuid, uniqueSegments]);

  const validEndTerminals = useMemo(() => {
    // if we don't have the origin terminal selected, show all valid destinations
    // for segments.
    if (isNil(originTerminalUuid)) {
      return uniqBy(
        uniqueSegments
          .map((s) => s.endTerminal)
          .sort((a, b) => a.code.localeCompare(b.code)),
        (t) => t.uuid,
      );
    }
    // if there is an origin selected then we should filter segments which start with that
    return uniqBy(
      uniqueSegments
        ?.filter((s) => s.startTerminal.uuid === originTerminalUuid)
        .map((s) => s.endTerminal)
        .sort((a, b) => a.code.localeCompare(b.code)),
      (t) => t.uuid,
    );
  }, [originTerminalUuid, uniqueSegments]);

  const handleConfirm = async () => {
    if (isNil(destinationTerminalUuid) && isNil(originTerminalUuid)) {
      setErrorMessage('Please select a start and end terminal');
      return;
    }
    if (isNil(originTerminalUuid)) {
      setErrorMessage('Please select a start terminal');
      return;
    }
    if (isNil(destinationTerminalUuid)) {
      setErrorMessage('Please select an end terminal');
      return;
    }
    const res = await createNewManifest({
      input: {
        lineHaulManifestCreateInput: {
          departDate: planningDate,
          startTerminalUuid: originTerminalUuid,
          endTerminalUuid: destinationTerminalUuid,
          referenceNumber: '',
        },
      },
    });
    const segment = uniqueSegments.find(
      (s) =>
        s.startTerminal.uuid === originTerminalUuid &&
        s.endTerminal.uuid === destinationTerminalUuid,
    );
    setSnackbarSuccessMessage(
      `Created new ${segment?.startTerminal.code}-${segment?.endTerminal.code} manifest (${res?.referenceNumber})`,
    );

    // await fetchManifests();
    handleClose();
  };

  return (
    <Dialog sx={{ minWidth: '40vw' }} open={open} onClose={handleClose}>
      <DialogTitle>
        <Typography variant="h6">Create a new manifest</Typography>
      </DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          <Typography>Date: {planningDate?.format('MM/DD')}</Typography>

          <Stack direction="row" alignItems="center">
            {showOriginSelect && (
              <Select
                fullWidth
                value={originTerminalUuid}
                size="small"
                onChange={(e) => {
                  setOriginTerminalUuid(e.target.value);
                }}
              >
                {validStartTerminals?.map((t) => {
                  return (
                    <MenuItem key={t.uuid} value={t.uuid}>
                      {t.code}
                    </MenuItem>
                  );
                })}
              </Select>
            )}

            <ArrowRightAlt />
            {showDestinationSelect && (
              <Select
                fullWidth
                value={destinationTerminalUuid}
                size="small"
                onChange={(e) => {
                  setDestinationTerminalUuid(e.target.value);
                }}
              >
                {validEndTerminals?.map((t) => {
                  return (
                    <MenuItem key={t.uuid} value={t.uuid}>
                      {t.code}
                    </MenuItem>
                  );
                })}
              </Select>
            )}
          </Stack>
          <Typography color="red">{errorMessage}</Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleConfirm}>
          Create
        </Button>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(CreateManifestModal);
