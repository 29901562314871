import { Alert, Box, Dialog, Snackbar, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import React, {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useState,
} from 'react';
import CSVDownloadButton from '../../../../common/components/buttons/csv-download-button';
import GeneralDatePicker from '../../../../common/components/date-picker';
import DriverFilterButton from '../../../../common/components/driver-filter-button';
import { type Option } from '../../../../common/filters/types';
import useDrivers from '../../../../common/react-hooks/use-drivers';
import {
  StopType,
  type DriverCompletedStopsLogQuery,
  useDriverCompletedStopsLogLazyQuery,
  type DriverCompletedStopsLogQueryVariables,
} from '../../../../generated/graphql';

const modalInnerContainerStyle = {
  bgcolor: 'background.paper',
  boxShadow: 24,
  color: 'black',
  display: 'flex',
  flexDirection: 'column',
  padding: 4,
  gap: 2,
};

const DRIVER_COMPLETED_STOPS_REPORT_CSV_HEADERS = [
  'Order #',
  'Stop Type',
  'Service Date',
  'Service Level',
  'Customer Name',
  'Origin',
  'Destination',
  'HAWB',
  'MAWB',
  'Reference #',
  'Pieces',
  'Weight',
  'Total Charges',
  'Driver Name',
];

type DriverCompletedStopsReportModalProps = {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
};
const DriverCompletedStopsReportModal = ({
  isOpen,
  setIsOpen,
}: DriverCompletedStopsReportModalProps) => {
  const [selectedDriverOption, setSelectedDriverOption] = useState<
    Option | null | undefined
  >(undefined);
  const [getDriverCompletedStopsLog, { loading }] =
    useDriverCompletedStopsLogLazyQuery();
  const { drivers, getDriverName } = useDrivers();
  const [startDate, setStartDate] = useState<dayjs.Dayjs | undefined>(
    undefined,
  );
  const [endDate, setEndDate] = useState<dayjs.Dayjs | undefined>(undefined);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [showDownloadingSnackbar, setShowDownloadingSnackbar] = useState(false);

  useEffect(() => {
    if (
      drivers.length > 0 &&
      isNil(selectedDriverOption) &&
      !isNil(drivers[0])
    ) {
      setSelectedDriverOption({
        label: getDriverName(drivers[0].uuid),
        value: drivers[0].uuid,
      });
    }
  }, [drivers, getDriverName, selectedDriverOption]);

  const convertToCSVData = (data: DriverCompletedStopsLogQuery) => {
    const driverMaps = data.driverCompletedStopsLog.stopDriverMaps;
    const result = driverMaps.map((orderMap) => {
      const isInboundStop =
        !isNil(orderMap?.stopType) &&
        [StopType.Pickup, StopType.Recovery].includes(orderMap?.stopType);

      const isOutboundStop =
        !isNil(orderMap?.stopType) &&
        [StopType.Delivery, StopType.Transfer].includes(orderMap?.stopType);

      let origin = '';
      if (orderMap.isLineHaul === true) {
        origin = orderMap.linehaulStartTerminal?.code ?? 'N/A';
      } else if (isInboundStop) {
        origin = orderMap?.order?.startStop?.terminal?.name ?? 'N/A';
      } else {
        origin = 'N/A';
      }

      let destination = '';
      if (orderMap.isLineHaul === true) {
        destination = orderMap.linehaulEndTerminal?.code ?? 'N/A';
      } else if (isOutboundStop) {
        destination = orderMap?.order?.endStop?.terminal?.name ?? 'N/A';
      } else {
        destination = 'N/A';
      }

      const orderNumber = orderMap?.order?.name ?? '';
      const stopType =
        orderMap.isLineHaul === true ? 'LINEHAUL' : (orderMap?.stopType ?? '');
      const serviceDate = isNil(orderMap?.serviceDate)
        ? ''
        : dayjs(orderMap.serviceDate).format('MM/DD/YYYY');
      const serviceLevel = isNil(orderMap?.order?.service?.name)
        ? ''
        : (orderMap?.order?.service?.name ?? '');
      const customerName =
        orderMap?.order?.billingPartyContact.displayName ?? '';
      const hawb =
        orderMap?.order?.standardOrderFields.shipperBillOfLadingNumber ?? '';
      const mawb =
        orderMap?.order?.standardOrderFields.masterAirwayBillOfLadingNumber ??
        '';
      const referenceNumbers = orderMap?.order?.refNumbers.join(', ') ?? '';
      const pieces = isNil(orderMap?.order?.pieceCount)
        ? ''
        : `${orderMap?.order?.pieceCount}`;
      const weight = isNil(orderMap?.order?.weight)
        ? ''
        : `${orderMap?.order?.weight}`;
      const totalCharge = isNil(orderMap?.order?.totalCharge)
        ? ''
        : `$${orderMap?.order?.totalCharge}`;

      const driverName = isNil(orderMap?.driver?.uuid)
        ? ''
        : getDriverName(orderMap?.driver?.uuid);

      return {
        orderNumber,
        stopType,
        serviceDate,
        serviceLevel,
        customerName,
        origin,
        destination,
        hawb,
        mawb,
        referenceNumbers,
        pieces,
        weight,
        totalCharge,
        driverName,
      };
    });
    return result;
  };

  const downloadVariables: DriverCompletedStopsLogQueryVariables = {
    driverUuid: selectedDriverOption?.value,
    startDate,
    endDate,
  };

  const handleDownload = async () => {
    if (isNil(startDate) || isNil(endDate)) return null;
    setShowDownloadingSnackbar(true);
    const res = await getDriverCompletedStopsLog({
      variables: downloadVariables,
    });
    if (res.error || !res.data) {
      setShowErrorSnackbar(true);
      return null;
    }
    const { data } = res;
    const csvData = convertToCSVData(data);
    const rows: string[][] = [DRIVER_COMPLETED_STOPS_REPORT_CSV_HEADERS];
    for (const row of csvData) {
      rows.push([
        row.orderNumber,
        row.stopType,
        row.serviceDate,
        row.serviceLevel,
        row.customerName,
        row.origin,
        row.destination,
        row.hawb,
        row.mawb,
        row.referenceNumbers,
        row.pieces,
        row.weight,
        row.totalCharge,
        row.driverName,
      ]);
    }
    setShowDownloadingSnackbar(false);
    return rows;
  };

  const isDownloadDisabled = isNil(startDate) || isNil(endDate) || loading;
  return (
    <Dialog
      maxWidth="xs"
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <Box sx={modalInnerContainerStyle}>
        <Typography variant="h6">Driver completed stops report</Typography>
        <DriverFilterButton
          cacheId="DRIVER_COMPLETED_STOPS_REPORT"
          prefixText="Driver"
          selectedOption={selectedDriverOption}
          handleChange={(option: Option | null | undefined) => {
            setSelectedDriverOption(option);
          }}
        />
        <GeneralDatePicker
          date={startDate}
          width="100%"
          setDate={setStartDate}
          text="Start"
        />
        <GeneralDatePicker
          date={endDate}
          width="100%"
          setDate={setEndDate}
          text="End"
        />
        <Box>
          <CSVDownloadButton
            getData={handleDownload}
            filename={`${selectedDriverOption?.label ?? 'all-drivers'}-completed-stops-csv-report.csv`}
            label="Download CSV"
            buttonProps={{ variant: 'contained', disabled: isDownloadDisabled }}
            reportType="driver-completed-stops"
            dataForTimingLog={downloadVariables}
          />
        </Box>
        <Snackbar open={showErrorSnackbar}>
          <Alert
            severity="error"
            onClose={() => {
              setShowErrorSnackbar(false);
            }}
          >
            Failed to download CSV report.
          </Alert>
        </Snackbar>
        <Snackbar open={showDownloadingSnackbar}>
          <Alert
            severity="success"
            onClose={() => {
              setShowDownloadingSnackbar(false);
            }}
          >
            Downloading...
          </Alert>
        </Snackbar>
      </Box>
    </Dialog>
  );
};

export default DriverCompletedStopsReportModal;
