import { captureException } from '@sentry/react';
import dayjs from 'dayjs';
import { isNil, round } from 'lodash';
import { getNoonOfDay } from 'shared/date';
import { safeDivide } from 'shared/math';
import { isNilOrEmptyString } from 'shared/string';
import { exhaustive } from 'shared/switch';
import {
  type FuelProfileDateRangeFragment,
  type FuelProfileFragment,
  FuelProfileType,
  OrderDetailedStatus,
  type TariffFragment,
  TariffGroupScope,
  TariffGroupType,
} from '../../../../../../generated/graphql';
import { type OrderFormValues } from '../../forms/types';

export const NEW_FREIGHT_PROFILE_AUTO_APPLIED_MESSAGE_PREFIX =
  'Tariff was changed.';

export function buildNewTariffAppliedMessage({
  originalTariff,
}: {
  originalTariff: string;
}): string {
  return `${NEW_FREIGHT_PROFILE_AUTO_APPLIED_MESSAGE_PREFIX} Please save to apply. (Original: ${originalTariff})`;
}

export const NEW_FUEL_PROFILE_AUTO_APPLIED_MESSAGE_PREFIX =
  'New fuel profile auto-applied.';

export function buildNewFuelProfileAppliedMessage({
  originalSurchargeRate,
}: {
  originalSurchargeRate: string;
}): string {
  return `${NEW_FUEL_PROFILE_AUTO_APPLIED_MESSAGE_PREFIX} Please save to apply. (Original: ${originalSurchargeRate})`;
}

const dateIsWithinFuelProfileDateRange = (
  date: Date,
  fuelProfileDateRange: FuelProfileDateRangeFragment,
) => {
  return dayjs(date).isBetween(
    dayjs(fuelProfileDateRange.startDate),
    dayjs(fuelProfileDateRange.expirationDate),
    'day',
    '[]',
  );
};

export const getFuelProfileDateRangeToUse = ({
  contactUuid,
  fuelProfiles,
  defaultContactFuelSurcharge,
  serviceDate,
  appointmentDate,
  stopCompletedDate,
  tariff,
}: {
  contactUuid: string | undefined;
  fuelProfiles: FuelProfileFragment[];
  defaultContactFuelSurcharge: number | null | undefined;
  serviceDate: Date | null | undefined;
  appointmentDate: Date | null | undefined;
  stopCompletedDate: Date | null | undefined;
  tariff: TariffFragment | undefined | null;
}): FuelProfileDateRangeFragment | undefined => {
  const dateToUse =
    serviceDate ??
    appointmentDate ??
    stopCompletedDate ??
    getNoonOfDay(dayjs());

  const contactSpecificFuelProfile = fuelProfiles.find(
    (fuelProfile) =>
      !isNil(fuelProfile.contacts) &&
      fuelProfile.contacts.some(
        (contact) =>
          contact.uuid === contactUuid &&
          fuelProfile.type === FuelProfileType.ContactSpecific &&
          fuelProfile.fuelProfileDateRanges.some((fuelProfileDateRange) =>
            dateIsWithinFuelProfileDateRange(dateToUse, fuelProfileDateRange),
          ),
      ),
  );
  const contactSpecificDateRange =
    contactSpecificFuelProfile?.fuelProfileDateRanges?.find(
      (fuelProfileDateRange) =>
        dateIsWithinFuelProfileDateRange(dateToUse, fuelProfileDateRange),
    );
  if (!isNil(contactSpecificDateRange)) {
    return contactSpecificDateRange;
  }

  const tariffSpecificFuelProfile =
    tariff?.tariffGroup?.fuelProfile?.fuelProfileDateRanges.some(
      (fuelProfileDateRange) =>
        dateIsWithinFuelProfileDateRange(dateToUse, fuelProfileDateRange),
    ) === true
      ? tariff?.tariffGroup.fuelProfile
      : undefined;
  const tariffSpecificDateRange =
    tariffSpecificFuelProfile?.fuelProfileDateRanges?.find(
      (fuelProfileDateRange) =>
        dayjs(dateToUse).isBetween(
          dayjs(fuelProfileDateRange.startDate),
          dayjs(fuelProfileDateRange.expirationDate),
          'day',
          '[]',
        ),
    );
  if (!isNil(tariffSpecificDateRange)) {
    return tariffSpecificDateRange;
  }

  const companyFuelProfile = fuelProfiles.find(
    (fuelProfile) =>
      fuelProfile?.contacts?.length === 0 &&
      fuelProfile.type === FuelProfileType.Global &&
      fuelProfile.fuelProfileDateRanges.some((fuelProfileDateRange) =>
        dateIsWithinFuelProfileDateRange(dateToUse, fuelProfileDateRange),
      ),
  );

  const companyFuelProfileDateRange =
    companyFuelProfile?.fuelProfileDateRanges?.find((fuelProfileDateRange) =>
      dateIsWithinFuelProfileDateRange(dateToUse, fuelProfileDateRange),
    );
  if (
    !isNil(companyFuelProfileDateRange) &&
    isNil(defaultContactFuelSurcharge)
  ) {
    return companyFuelProfileDateRange;
  }
  return undefined;
};

export function shouldUpdateTotalCharge({
  orderValues,
}: {
  orderValues: OrderFormValues;
}) {
  const { detailedStatus } = orderValues;
  if (isNil(detailedStatus)) {
    captureException(
      `Detailed status should NOT be null when updating an order`,
      {
        tags: {
          orderUuid: orderValues.uuid,
          contactUuid: orderValues.contactUuid,
        },
      },
    );
  }
  return (
    !isNil(detailedStatus) &&
    detailedStatus !== OrderDetailedStatus.InvoicePosted
  );
}

export const formatTariffRateString = ({
  tariff,
  rate,
  total,
}: {
  tariff: TariffFragment | undefined;
  rate: number | null | undefined;
  total: number | null | undefined;
}) => {
  const isMinimum =
    !isNil(tariff?.minimumUsdCents) &&
    safeDivide(tariff?.minimumUsdCents ?? 0, 100) >= (total ?? 0);
  const isMaximum =
    !isNil(total) &&
    !isNil(tariff?.maximumUsdCents) &&
    safeDivide(tariff?.maximumUsdCents ?? 0, 100) <= total;
  let minMaxText = isMinimum ? 'Minimum' : undefined;
  minMaxText = isMaximum ? 'Maximum' : minMaxText;

  let tariffRateText = 'No rate';
  if (isMinimum || isMaximum) {
    tariffRateText = `${minMaxText ?? 'No rate'}`;
  } else if (!isNil(rate)) {
    tariffRateText = `${round(rate, 3)}`;
  }

  return isNil(tariff)
    ? `None`
    : `${tariff.tariffZone.name} - ${tariffRateText}`;
};

export const getTariffGroupNameCopy = ({
  tariffGroupScope,
  tariffGroupName,
  tariffGroupType,
}: {
  tariffGroupScope: TariffGroupScope | null | undefined;
  tariffGroupName: string | null | undefined;
  tariffGroupType: TariffGroupType | null | undefined;
  // eslint-disable-next-line consistent-return
}): string | null => {
  if (!isNilOrEmptyString(tariffGroupName)) {
    return tariffGroupName;
  }

  // We can't derive a name for a non-default tariff
  // And we can't derive a default if the type of a default tariff is nil
  if (
    tariffGroupScope === TariffGroupScope.Combination ||
    isNil(tariffGroupType)
  ) {
    return null;
  }

  switch (tariffGroupType) {
    case TariffGroupType.Ordinary: {
      return 'Default Tariff';
    }
    case TariffGroupType.Transfer: {
      return 'Default Transfer Tariff';
    }
    case TariffGroupType.LineHaul: {
      return 'Default Linehaul Tariff';
    }
    case TariffGroupType.PointToPoint: {
      return 'Default Point-to-Point Tariff';
    }
    default: {
      exhaustive(tariffGroupType);
    }
  }
};
