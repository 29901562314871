// eslint-disable-next-line no-restricted-imports
import { CircularProgress, Grid, TextField, Stack } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import React, {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useDebounce } from 'use-debounce';
import { useContactsForInvoicesLazyQuery } from '../../../../generated/graphql';
import ContactCard from './contact-settings-card';

const DEFAULT_CONTACTS_PAGE_SIZE = 25;

const ContactSettings = ({
  setIsSaving,
}: {
  readonly setIsSaving: Dispatch<SetStateAction<boolean>>;
}) => {
  const [searchText, setSearchText] = useState<string>('');
  const [debouncedSearchText] = useDebounce(searchText, 200);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(
    DEFAULT_CONTACTS_PAGE_SIZE,
  );
  const [page, setPage] = useState<number>(0);
  const [getContacts, { data: contactsData, loading }] =
    useContactsForInvoicesLazyQuery();

  const fetchContacts = async ({
    first,
    after,
    last,
    before,
  }: {
    first?: number | null | undefined;
    after?: string | null | undefined;
    last?: number | null | undefined;
    before?: string | null | undefined;
  }) => {
    await getContacts({
      variables: {
        first,
        after,
        last,
        before,
        searchText,
        sortByContactName: true,
      },
    });
  };

  const prev = async () => {
    await fetchContacts({
      last: rowsPerPage,
      before: contactsData?.paginatedContacts.pageInfo.startCursor ?? undefined,
    });
  };
  const next = async () => {
    await fetchContacts({
      first: rowsPerPage,
      after: contactsData?.paginatedContacts.pageInfo.endCursor ?? undefined,
    });
  };

  const refresh = () => {
    fetchContacts({ first: rowsPerPage });
    setPage(0);
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText]);

  return (
    <Grid container sx={{ height: '100%' }}>
      <Grid item xs={12} sx={{ paddingBottom: '10px' }}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <TextField
              sx={{ width: '250px' }}
              size="small"
              label="Search Customers"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
            {loading && <CircularProgress size={20} />}
          </Stack>
          <TablePagination
            rowsPerPageOptions={[]}
            labelRowsPerPage="Show"
            component="div"
            count={contactsData?.paginatedContacts.totalCount ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              disabled: loading || page === 0,
            }}
            nextIconButtonProps={{
              disabled:
                loading ||
                contactsData?.paginatedContacts.totalCount === 0 ||
                page + 1 ===
                  Math.ceil(
                    (contactsData?.paginatedContacts.totalCount ?? 0) /
                      rowsPerPage,
                  ),
            }}
            onPageChange={(e, newPage: number) => {
              if (newPage > page) {
                next();
              } else {
                prev();
              }
              setPage(newPage);
            }}
            onRowsPerPageChange={(e) => {
              setRowsPerPage(Number(e.target.value));
            }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} sx={{ height: '70vh', overflowY: 'scroll' }}>
        {contactsData?.paginatedContacts.edges.map(({ node: contact }) => (
          <ContactCard
            key={contact.uuid}
            contact={contact}
            setIsSaving={setIsSaving}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default ContactSettings;
