import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Divider,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import { pdf } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { isEmpty, isNil } from 'lodash';
import { type Dispatch, type SetStateAction, useMemo, useState } from 'react';
import CSVDownloadButton from '../../../../../common/components/buttons/csv-download-button';
import CustomerFilterButton from '../../../../../common/components/customer-filter-button';
import DateDropdownPicker, {
  type DateOption,
  defaultPast1WeekDateRangeOption,
} from '../../../../../common/components/date-dropdown-picker';
import DownloadTypeSelection, {
  DownloadType,
} from '../../../../../common/components/download-type-selection';
import TerminalFilterButton from '../../../../../common/components/terminal-filter-button';
import { type Option } from '../../../../../common/filters/types';
import useTerminals from '../../../../../common/react-hooks/use-terminals';
import {
  useMeQuery,
  useNetSalesSummaryReportLazyQuery,
} from '../../../../../generated/graphql';
import {
  EmptyReportError,
  useDownloadReport,
} from '../../../../reports/hooks/use-download-report';
import styles from '../../../styles';
import { convertNetSalesSummaryReportDataToCSV } from '../../../utils';
import { accountingReportDefaultDateOption } from '../../accounting-reports/constants';
import GeneratedNetSalesReport from './generated-net-sales-report';
import { formatDateOption } from './utils';

type DownloadNetSalesReportModalProps = {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const DownloadNetSalesReportModal = ({
  isOpen,
  setIsOpen,
}: DownloadNetSalesReportModalProps) => {
  const [dateOption, setDateOption] = useState<DateOption>(
    defaultPast1WeekDateRangeOption,
  );
  const [terminalOption, setTerminalOption] = useState<Option | undefined>();
  const [customerOptions, setCustomerOptions] = useState<
    Option[] | undefined
  >();
  const [downloadType, setDownloadType] = useState<DownloadType>(
    DownloadType.PDF,
  );
  const { data: companyData } = useMeQuery({
    fetchPolicy: 'cache-first',
  });
  const [getNetSalesSummaryReport, { loading }] =
    useNetSalesSummaryReportLazyQuery();

  const netSalesSummaryReportInput = useMemo(() => {
    return {
      invoiceStartDate: dateOption.startDate,
      invoiceEndDate: dateOption.endDate,
      terminalUuid: terminalOption?.value,
      customerUuids: customerOptions?.map((option) => option.value),
    };
  }, [dateOption, terminalOption, customerOptions]);

  const getNetSalesSummaryReportData = async () => {
    const res = await getNetSalesSummaryReport({
      variables: {
        netSalesSummaryReportInput,
      },
    });
    return res.data?.netSalesSummaryReport;
  };

  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });

  const resetFilters = () => {
    setDateOption(accountingReportDefaultDateOption);
    setCustomerOptions(undefined);
    setTerminalOption(undefined);
  };

  const getDateString = () => {
    if (isNil(dateOption.startDate) || isNil(dateOption.endDate)) {
      return 'all-time';
    }
    return `${dayjs(dateOption.startDate).format('MM/DD/YYYY')}-${dayjs(
      dateOption.endDate,
    ).format('MM/DD/YYYY')}`;
  };

  const getCSVData = async () => {
    const res = await getNetSalesSummaryReportData();
    if (isNil(res)) {
      throw new Error(
        'There was an error fetching the net sales summary report',
      );
    }
    if (isEmpty(res.contactRows)) {
      throw new EmptyReportError('No data found for net sales summary report');
    }
    const csvRows = convertNetSalesSummaryReportDataToCSV(res.contactRows);
    return csvRows;
  };

  const downloadPDF = async () => {
    setIsOpen(false);
    const res = await getNetSalesSummaryReportData();
    if (isNil(res)) {
      throw new Error(
        'There was an error fetching the net sales summary report',
      );
    }
    if (isEmpty(res.contactRows)) {
      throw new EmptyReportError('No data found for net sales summary report');
    }
    const dateString = getDateString();
    const blob = await pdf(
      <GeneratedNetSalesReport
        companyData={companyData}
        data={res}
        dateString={dateString}
        terminalCode={terminalOption?.label}
      />,
    ).toBlob();
    saveAs(blob, `net-sales-summary-report-${dateString}.pdf`);
  };

  const startDownloadPDFWithMeasurement = useDownloadReport({
    name: 'Net sales summary',
    rumLabel: 'download-net-sales-report-pdf',
    filters: netSalesSummaryReportInput,
    downloadType,
    downloadReport: downloadPDF,
    onComplete: resetFilters,
  });

  const getCSVDataWithMeasurement = useDownloadReport({
    name: 'Net sales summary',
    rumLabel: 'download-net-sales-report-csv',
    filters: netSalesSummaryReportInput,
    downloadType,
    downloadReport: getCSVData,
    onComplete: resetFilters,
  });

  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={() => {
        if (loading && downloadType === DownloadType.CSV) return;
        resetFilters();
        setIsOpen(false);
      }}
    >
      <Box sx={[styles.modal, { height: 'fit-content', width: '500px' }]}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={10}>
            <Typography sx={{ fontWeight: 'bold' }}>
              Download Net Sales Summary Report
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              sx={{ float: 'right' }}
              disabled={loading && downloadType === DownloadType.CSV}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} display="flex" flexDirection="column" gap={1}>
            <Box>
              <DateDropdownPicker
                filterTitle="Invoice date"
                dateOption={dateOption}
                setDateOption={setDateOption}
              />
            </Box>
            <Stack display="flex" flexDirection="row" spacing={1}>
              {terminalsEnabled && (
                <TerminalFilterButton
                  displayCode
                  prefixText="Terminal"
                  selectedOption={terminalOption}
                  handleChange={(option: Option | null | undefined) => {
                    setTerminalOption(option ?? undefined);
                  }}
                  includeInactiveTerminals={false}
                />
              )}
              <CustomerFilterButton<false>
                selectedOptionsMultiselect={customerOptions}
                handleChangeMultiselect={(
                  options: Option[] | null | undefined,
                ) => {
                  setCustomerOptions(options ?? undefined);
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              gap={1}
            >
              <DownloadTypeSelection
                labelText="File type"
                cacheId="NET_SALES_SUMMARY_REPORT"
                selectedOption={downloadType}
                handleChange={(selectedDownloadType: DownloadType) => {
                  setDownloadType(selectedDownloadType);
                }}
              />
              {downloadType === DownloadType.PDF ? (
                <Button
                  sx={{ width: '100px' }}
                  variant="contained"
                  color="info"
                  disabled={loading}
                  onClick={startDownloadPDFWithMeasurement}
                >
                  Download
                </Button>
              ) : (
                <CSVDownloadButton
                  getData={getCSVDataWithMeasurement}
                  filename={`net-sales-summary-report-${formatDateOption(dateOption)}.csv`}
                  label="Download"
                  buttonProps={{
                    sx: { width: '100px' },
                    variant: 'contained',
                    color: 'info',
                    disabled: loading,
                  }}
                  reportType="net-sales-report"
                  dataForTimingLog={netSalesSummaryReportInput}
                  onDownloadComplete={() => {
                    resetFilters();
                    setIsOpen(false);
                  }}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default DownloadNetSalesReportModal;
