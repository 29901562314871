import {
  Card,
  Divider,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { getEnumKeys } from 'shared/enum';
import ContactAutocompleteComponent from '../../../../common/components/contact-autocomplete-component';
import {
  DeadlineType,
  FreightBillingMethod,
  PickupOrDelivery,
  StopImportType,
} from '../../../../generated/graphql';
import { type CsvImportConfigurationFormData } from '../form/csv-import-configuration-form-schema';

const NonFieldMappingRules = () => {
  const { control } = useFormContext<CsvImportConfigurationFormData>();

  const stopImportType = useWatch({
    control,
    name: 'stopImportType',
  });

  const shouldShowDefaultStopType =
    stopImportType === StopImportType.DynamicInboundOrOutbound;

  return (
    <Card sx={{ p: 2, boxShadow: 0, border: 'solid 0.5px grey' }}>
      <Stack sx={{ gap: 1 }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">Settings</Typography>
        </Stack>
        <Divider />
        <Stack gap={3}>
          <FormControl>
            <Controller
              control={control}
              name="stopImportType"
              render={({ field }) => (
                <RadioGroup {...field} row sx={{ gap: 2 }}>
                  <FormControlLabel
                    value={StopImportType.DynamicInboundOrOutbound}
                    control={<Radio />}
                    label={
                      <Typography variant="body1">
                        Import inbound or outbound dynamically
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value={StopImportType.InboundOnly}
                    control={<Radio />}
                    label={
                      <Typography variant="body1">
                        Import inbound stop only
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value={StopImportType.OutboundOnly}
                    control={<Radio />}
                    label={
                      <Typography variant="body1">
                        Import outbound stop only
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value={StopImportType.Both}
                    control={<Radio />}
                    label={
                      <Typography variant="body1">Import both stops</Typography>
                    }
                  />
                </RadioGroup>
              )}
            />
          </FormControl>

          <Stack direction="row" gap={4}>
            {/* first column */}
            <Stack flex={1} gap={2}>
              <Stack direction="row" gap={1} alignItems="center">
                <Typography variant="subtitle1" flex={1}>
                  Contact
                </Typography>
                <FormControl sx={{ flex: 1 }}>
                  <Controller
                    name="contactUuid"
                    control={control}
                    render={({ field }) => (
                      <ContactAutocompleteComponent
                        billingPartyContactUuid={field.value ?? undefined}
                        onChange={(value: string | undefined) => {
                          field.onChange(value ?? null);
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Stack>
              <Stack direction="row" gap={1} alignItems="center">
                <Typography variant="subtitle1" flex={1}>
                  Default deadline type
                </Typography>
                <FormControl sx={{ flex: 1 }}>
                  <Controller
                    name="defaultDeadlineType"
                    control={control}
                    render={({ field }) => (
                      <Select size="small" sx={{ flex: 1 }} {...field}>
                        {getEnumKeys(DeadlineType).map((option) => (
                          <MenuItem key={option} value={DeadlineType[option]}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Stack>
              {/* don't need deadline date for now */}
              {/* <Stack direction="row" gap={1} alignItems="center">
              <Typography variant="subtitle1" flex={1}>
                Default deadline date
              </Typography>
              <FormControl sx={{ flex: 1 }}>
                <Controller
                  name="defaultDeadlineDate"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      renderInput={(props) => (
                        <TextField size="small" {...props} />
                      )}
                    />
                  )}
                />
              </FormControl>
            </Stack> */}
              {/* don't need stop type for now */}
              {/* <Stack
              direction="row"
              gap={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="subtitle1" flex={1}>
                Default stop type
              </Typography>
              <FormControl sx={{ flex: 1 }}>
                <Controller
                  name="defaultStopType"
                  control={control}
                  render={({ field }) => (
                    <Select size="small" sx={{ flex: 1 }} {...field}>
                      {getEnumKeys(StandardStopType).map((option) => (
                        <MenuItem key={option} value={StandardStopType[option]}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Stack> */}
            </Stack>
            {/* second column */}
            <Stack flex={1} gap={2}>
              {shouldShowDefaultStopType && (
                <Stack direction="row" gap={1} alignItems="center">
                  <Typography variant="subtitle1" flex={1}>
                    Default stop type
                  </Typography>
                  <FormControl sx={{ flex: 1 }}>
                    <Controller
                      name="defaultPickupOrDelivery"
                      control={control}
                      render={({ field }) => (
                        <Select size="small" sx={{ flex: 1 }} {...field}>
                          {getEnumKeys(PickupOrDelivery).map((option) => (
                            <MenuItem
                              key={option}
                              value={PickupOrDelivery[option]}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Stack>
              )}
              <Stack
                direction="row"
                gap={1}
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="subtitle1" flex={1}>
                  Default freight billing method
                </Typography>
                <FormControl sx={{ flex: 1 }}>
                  <Controller
                    name="defaultFreightBillingMethod"
                    control={control}
                    render={({ field }) => (
                      <Select size="small" sx={{ flex: 1 }} {...field}>
                        {getEnumKeys(FreightBillingMethod).map((option) => (
                          <MenuItem
                            key={option}
                            value={FreightBillingMethod[option]}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};

export default NonFieldMappingRules;
