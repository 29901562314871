import {
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  CircularProgress,
  Chip,
  Select,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import TablePagination from '@mui/material/TablePagination';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import {
  ReportSendJobStatus,
  useReportSendJobsLazyQuery,
} from '../../../../generated/graphql';
import ReportSendJobListRow from './report-send-job-list-row';
import ReportSendJobStatusChip from './report-send-job-status-chip';

const DEFAULT_ROWS_PER_PAGE = 10;
const ROWS_PER_PAGE_OPTIONS = [10, 25, 100];

const ReportSendJobList = ({
  selectedReportSendJobBatchId,
  initialStatus,
}: {
  readonly selectedReportSendJobBatchId: string;
  readonly initialStatus?: ReportSendJobStatus;
}) => {
  const [status, setStatus] = useState<ReportSendJobStatus | 'All'>(
    initialStatus ?? 'All',
  );
  const [searchText, setSearchText] = useState<string>('');
  const [debouncedSearchText] = useDebounce(searchText, 300);
  const [rowsPerPage, setRowsPerPage] = useState<number>(DEFAULT_ROWS_PER_PAGE);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [
    getInvoiceSendJobs,
    { data: reportSendJobsData, startPolling, stopPolling, loading },
  ] = useReportSendJobsLazyQuery();

  const fetchReportSendJobs = async ({
    first,
    after,
    last,
    before,
  }: {
    first?: number | null | undefined;
    after?: string | null | undefined;
    last?: number | null | undefined;
    before?: string | null | undefined;
  }) => {
    const res = await getInvoiceSendJobs({
      variables: {
        findReportSendJobsInput: {
          first,
          after,
          last,
          before,
          reportSendJobBatchId: selectedReportSendJobBatchId,
          reportSendJobStatus: status === 'All' ? undefined : status,
        },
      },
    });
    setTotalCount(
      res.data?.reportSendJobs.reportSendJobConnection.totalCount ?? 0,
    );
  };

  const refresh = () => {
    fetchReportSendJobs({
      first: rowsPerPage,
    });
    setPage(0);
  };

  const prev = async (newPage: number) => {
    await fetchReportSendJobs({
      last: rowsPerPage,
      before:
        reportSendJobsData?.reportSendJobs.reportSendJobConnection.pageInfo
          ?.startCursor ?? undefined,
    });
    setPage(newPage);
  };
  const next = async (newPage: number) => {
    await fetchReportSendJobs({
      first: rowsPerPage,
      after:
        reportSendJobsData?.reportSendJobs.reportSendJobConnection.pageInfo
          ?.endCursor ?? undefined,
    });
    setPage(newPage);
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText, rowsPerPage, status]);

  useEffect(() => {
    startPolling(5000);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  return (
    <TableContainer sx={{ height: '80vh' }}>
      <Grid container alignItems="center" sx={{ paddingBottom: 1 }}>
        <Grid item xs={6}>
          <Stack direction="row" spacing={1} alignItems="center">
            <TextField
              size="small"
              label="Search Invoices"
              InputProps={{ style: { backgroundColor: 'white' } }}
              value={searchText}
              sx={{ maxWidth: '400px' }}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
            {loading && <CircularProgress size={20} />}
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <TablePagination
            labelRowsPerPage="Show"
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              disabled: loading || page === 0,
            }}
            nextIconButtonProps={{
              disabled:
                loading || page + 1 === Math.ceil(totalCount / rowsPerPage),
            }}
            onPageChange={(e, newPage: number) => {
              if (newPage > page) {
                next(newPage);
              } else {
                prev(newPage);
              }
            }}
            onRowsPerPageChange={(e) => {
              setRowsPerPage(Number(e.target.value));
            }}
          />
        </Grid>
      </Grid>

      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCell>Customer</TableCell>
            <TableCell align="center">
              <Select
                value={status}
                sx={{
                  boxShadow: 'none',
                  '.MuiOutlinedInput-notchedOutline': { border: 0 },
                }}
                size="small"
                renderValue={(value) => {
                  return (
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography variant="subtitle2">Status</Typography>{' '}
                      {value === 'All' ? (
                        <Chip size="small" label="All" />
                      ) : (
                        <ReportSendJobStatusChip status={value} />
                      )}
                    </Stack>
                  );
                }}
                onChange={(e) => {
                  setStatus(e.target.value as ReportSendJobStatus | 'All');
                }}
              >
                {Object.values(ReportSendJobStatus).map((txnStatus) => (
                  <MenuItem key={txnStatus} value={txnStatus}>
                    <ReportSendJobStatusChip status={txnStatus} />
                  </MenuItem>
                ))}
                <MenuItem key="All" value="All">
                  <Chip size="small" label="All" />
                </MenuItem>
              </Select>
            </TableCell>
            <TableCell>Emails</TableCell>
            <TableCell>Error</TableCell>
            <TableCell align="right">Document</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reportSendJobsData?.reportSendJobs.reportSendJobConnection.edges.map(
            ({ node: reportSendJob }) => (
              <ReportSendJobListRow
                key={reportSendJob.id}
                reportSendJob={reportSendJob}
              />
            ),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReportSendJobList;
