import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { sentenceCase } from 'change-case';
import currency from 'currency.js';
import dayjs from 'dayjs';
import { sumBy, isEmpty, isNil } from 'lodash';
import { type BusinessDivisionOption } from '../../../../../common/components/business-division-filter-button';
import { type Option } from '../../../../../common/filters/types';
import {
  type InvoiceForOpenInvoiceReportFragment,
  InvoiceStatus,
  type MeQuery,
} from '../../../../../generated/graphql';

const TEXT_PADDING = '3px';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 25,
    fontFamily: 'Roboto',
  },
  header1: {
    fontSize: '18px',
  },
  header2: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  rowWithSpaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  filterLabelText: {
    fontWeight: 'bold',
    width: '100px',
  },
  contactBlock: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12px',
    marginTop: 4,
  },
  contactBlockHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#f0eded',
    padding: 4,
  },
  invoicesTable: {
    width: '100%',
  },
  invoicesTableRow: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 2,
    paddingBottom: 2,
    justifyContent: 'space-between',
    fontSize: '8px',
    width: '100%',
    textAlign: 'center',
  },
  invoicesTableHeader: {
    borderTop: 'none',
    fontWeight: 'bold',
    borderBottom: '1px solid black',
  },
  invoicesTableRowLeftSection: {
    display: 'flex',
    flexDirection: 'row',
    width: '60%',
    textAlign: 'left',
  },
  invoicesTableRowRightSection: {
    display: 'flex',
    flexDirection: 'row',
    width: '40%',
    textAlign: 'right',
  },
  statusCol: {
    width: '20%',
    paddingRight: TEXT_PADDING,
  },
  invoiceRightFillerCol: {
    width: '100%',
    paddingRight: TEXT_PADDING,
  },
  dateCol: {
    width: '20%',
    paddingRight: TEXT_PADDING,
  },
  referenceInfoCol: {
    width: '20%',
    paddingRight: TEXT_PADDING,
  },
  terminalCol: {
    width: '10%',
    paddingRight: TEXT_PADDING,
  },
  numberCol: {
    width: '20%',
    paddingRight: TEXT_PADDING,
  },
  currencyCol: {
    width: '30%',
    paddingRight: TEXT_PADDING,
  },
});

const OpenInvoicesTable = ({
  invoices,
  totalCents,
  balanceCents,
  groupLabel,
  terminalsEnabled,
}: {
  readonly invoices: InvoiceForOpenInvoiceReportFragment[];
  readonly totalCents: number;
  readonly balanceCents: number;
  readonly groupLabel: string;
  readonly terminalsEnabled: boolean;
}) => {
  let totalPieces = 0;
  let totalWeight = 0;

  return (
    <View style={styles.invoicesTable}>
      <View style={[styles.invoicesTableRow, styles.invoicesTableHeader]}>
        <View style={styles.invoicesTableRowLeftSection}>
          <Text style={styles.dateCol}>Invoice date</Text>
          <Text style={styles.dateCol}>Due date</Text>
          <Text style={styles.referenceInfoCol}>Invoice name</Text>
          <Text style={styles.referenceInfoCol}>Journal no</Text>
          <Text style={styles.statusCol}>Status</Text>
        </View>
        <View style={styles.invoicesTableRowRightSection}>
          <Text style={styles.invoiceRightFillerCol} />
        </View>
      </View>

      {invoices.map((invoice) => {
        const invoiceTotalPieces = invoice.orders.reduce(
          (prev, curr) => prev + curr.pieces ?? 0,
          0,
        );
        totalPieces += invoiceTotalPieces;
        const invoiceTotalWeight = invoice.orders.reduce(
          (prev, curr) => prev + curr.weight ?? 0,
          0,
        );
        totalWeight += invoiceTotalWeight;
        return (
          <>
            <View
              key={invoice.uuid}
              style={[styles.invoicesTableRow, styles.contactBlockHeader]}
            >
              <View style={styles.invoicesTableRowLeftSection}>
                <Text style={styles.dateCol}>
                  {dayjs(invoice.date).format('MM/DD/YY')}
                </Text>
                <Text style={styles.dateCol}>
                  {isNil(invoice.dueDate)
                    ? '-'
                    : dayjs(invoice.dueDate).format('MM/DD/YY')}
                </Text>
                <Text style={styles.referenceInfoCol}>{invoice.name}</Text>
                <Text style={styles.referenceInfoCol}>
                  {invoice.journalNumber}
                </Text>
                <Text style={styles.statusCol}>
                  {invoice.status === InvoiceStatus.NotFinalized
                    ? 'Not posted'
                    : 'Posted'}
                </Text>
              </View>
              <View style={styles.invoicesTableRowRightSection}>
                <Text style={styles.invoiceRightFillerCol} />
              </View>
            </View>
            <View style={[styles.invoicesTableRow, styles.invoicesTableHeader]}>
              <View style={styles.invoicesTableRowLeftSection}>
                <Text style={styles.dateCol}>Service date</Text>
                <Text style={styles.referenceInfoCol}>HAWB</Text>
                <Text style={styles.referenceInfoCol}>MAWB</Text>
                <Text style={styles.referenceInfoCol}>Order name</Text>
                {terminalsEnabled && (
                  <Text style={styles.terminalCol}>Orig</Text>
                )}
                {terminalsEnabled && (
                  <Text style={styles.terminalCol}>Dest</Text>
                )}
              </View>
              <View style={styles.invoicesTableRowRightSection}>
                <Text style={styles.numberCol}>Pieces</Text>
                <Text style={styles.numberCol}>Weight</Text>
                <Text style={styles.currencyCol}>Total</Text>
                <Text style={styles.currencyCol}>Balance</Text>
              </View>
            </View>
            {isEmpty(invoice.orders) ? (
              <View style={styles.invoicesTableRow}>
                <Text>No orders found</Text>
              </View>
            ) : (
              invoice.orders.map((order) => {
                return (
                  <View key={order.orderUuid} style={styles.invoicesTableRow}>
                    <View style={styles.invoicesTableRowLeftSection}>
                      <Text style={styles.dateCol}>
                        {isNil(order.serviceDate)
                          ? '-'
                          : dayjs(order.serviceDate).format('MM/DD/YY')}
                      </Text>
                      <Text style={styles.referenceInfoCol}>
                        {order.shipperBillOfLadingNumber ?? ''}
                      </Text>
                      <Text style={styles.referenceInfoCol}>
                        {order.masterAirwayBillOfLadingNumber ?? ''}
                      </Text>
                      <Text style={styles.referenceInfoCol}>
                        {order.orderName ?? ''}
                      </Text>
                      {terminalsEnabled && (
                        <Text style={styles.terminalCol}>
                          {order.originTerminalCode ?? ''}
                        </Text>
                      )}
                      {terminalsEnabled && (
                        <Text style={styles.terminalCol}>
                          {order.destinationTerminalCode ?? ''}
                        </Text>
                      )}
                    </View>
                    <View style={styles.invoicesTableRowRightSection}>
                      <Text style={styles.numberCol}>{order?.pieces}</Text>
                      <Text style={styles.numberCol}>
                        {Math.round(order.weight)}
                      </Text>
                      <Text style={styles.currencyCol}>
                        {currency(order.orderTotalCents, {
                          fromCents: true,
                        }).format()}
                      </Text>
                      <Text style={styles.currencyCol}>
                        {currency(order.orderBalanceCents, {
                          fromCents: true,
                        }).format()}
                      </Text>
                    </View>
                  </View>
                );
              })
            )}
            <View style={styles.invoicesTableRow}>
              <View
                style={[
                  styles.invoicesTableRowLeftSection,
                  { justifyContent: 'flex-end' },
                ]}
              >
                <Text style={{ fontWeight: 'bold' }}>
                  {invoice.name} totals:{' '}
                </Text>
              </View>
              <View style={styles.invoicesTableRowRightSection}>
                <Text style={styles.numberCol}>{invoiceTotalPieces}</Text>
                <Text style={styles.numberCol}>
                  {Math.round(invoiceTotalWeight)}
                </Text>
                <Text style={styles.currencyCol}>
                  {currency(invoice.invoiceTotalCents, {
                    fromCents: true,
                  }).format()}
                </Text>
                <Text style={styles.currencyCol}>
                  {currency(invoice.invoiceBalanceCents, {
                    fromCents: true,
                  }).format()}
                </Text>
              </View>
            </View>
          </>
        );
      })}
      <View style={styles.invoicesTableRow}>
        <View
          style={[
            styles.invoicesTableRowLeftSection,
            { justifyContent: 'flex-end' },
          ]}
        >
          <Text style={{ fontWeight: 'bold' }}>{groupLabel} totals: </Text>
        </View>
        <View style={styles.invoicesTableRowRightSection}>
          <Text style={styles.numberCol}>{totalPieces}</Text>
          <Text style={styles.numberCol}>{Math.round(totalWeight)}</Text>
          <Text style={styles.currencyCol}>
            {currency(totalCents, { fromCents: true }).format()}
          </Text>
          <Text style={styles.currencyCol}>
            {currency(balanceCents, { fromCents: true }).format()}
          </Text>
        </View>
      </View>
    </View>
  );
};

export type GeneratedOpenInvoicesReportProps = {
  readonly companyData: MeQuery | undefined;
  readonly status: string;
  readonly dateString: string;
  readonly groupedInvoices: Record<
    string,
    InvoiceForOpenInvoiceReportFragment[]
  >;
  readonly businessDivisionOption?: BusinessDivisionOption | null | undefined;
  readonly customerOption?: Option | null | undefined;
};

const GeneratedOpenInvoicesReport = ({
  companyData,
  status,
  dateString,
  groupedInvoices,
  businessDivisionOption,
  customerOption,
}: GeneratedOpenInvoicesReportProps) => {
  const terminalsEnabled =
    companyData?.me?.company.configuration?.terminalsEnabled === true;
  let reportTotalCents = 0;
  let reportBalanceCents = 0;
  return (
    <Document title="Open Invoices Report">
      <Page orientation="landscape" size="LETTER" style={styles.page}>
        <View
          style={[
            styles.rowWithSpaceBetween,
            {
              marginTop: '10px',
              fontSize: '12px',
            },
          ]}
        >
          <Text style={styles.header1}>Open Invoices Report</Text>
        </View>
        <View
          style={[
            styles.rowWithSpaceBetween,
            {
              fontSize: '12px',
            },
          ]}
        >
          <Text style={styles.header2}>{companyData?.me?.company.name}</Text>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '10px',
            fontSize: '12px',
          }}
        >
          <View style={styles.rowWithSpaceBetween}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontSize: '10px',
              }}
            >
              <Text style={styles.filterLabelText}>Invoice status</Text>
              <Text style={{ marginLeft: '10px' }}>{sentenceCase(status)}</Text>
            </View>
          </View>
          <View style={styles.rowWithSpaceBetween}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontSize: '10px',
              }}
            >
              <Text style={styles.filterLabelText}>Invoice date</Text>
              <Text style={{ marginLeft: '10px' }}>{dateString}</Text>
            </View>
          </View>
          <View style={styles.rowWithSpaceBetween}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontSize: '10px',
              }}
            >
              <Text style={styles.filterLabelText}>Customer</Text>
              <Text style={{ marginLeft: '10px' }}>
                {customerOption?.label ?? 'All'}
              </Text>
            </View>
          </View>
          <View style={styles.rowWithSpaceBetween}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontSize: '10px',
              }}
            >
              <Text style={styles.filterLabelText}>Business division</Text>
              <Text style={{ marginLeft: '10px' }}>
                {businessDivisionOption?.label ?? 'All'}
              </Text>
            </View>
          </View>
        </View>
        {Object.entries(groupedInvoices)
          .sort((a, b) => a[0].localeCompare(b[0]))
          .map(([groupLabel, groupInvoices]) => {
            const totalCents = sumBy(
              groupInvoices,
              (invoice) => invoice.invoiceTotalCents,
            );
            reportTotalCents += totalCents;
            const balanceCents = sumBy(
              groupInvoices,
              (invoice) => invoice.invoiceBalanceCents,
            );
            reportBalanceCents += balanceCents;

            return (
              // eslint-disable-next-line react/jsx-key
              <View style={styles.contactBlock}>
                <View style={styles.contactBlockHeader}>
                  <Text style={{ fontWeight: 'bold' }}>{groupLabel}</Text>
                </View>
                <OpenInvoicesTable
                  invoices={groupInvoices}
                  totalCents={totalCents}
                  balanceCents={balanceCents}
                  groupLabel={groupLabel}
                  terminalsEnabled={terminalsEnabled}
                />
              </View>
            );
          })}
        <View style={styles.contactBlock}>
          <View style={styles.invoicesTable}>
            <View style={styles.invoicesTableRow}>
              <View
                style={[
                  styles.invoicesTableRowLeftSection,
                  { justifyContent: 'flex-end' },
                ]}
              >
                <Text style={{ fontWeight: 'bold' }}>Report totals: </Text>
              </View>
              <View style={styles.invoicesTableRowRightSection}>
                <Text style={styles.numberCol} />
                <Text style={styles.numberCol} />
                <Text style={styles.currencyCol}>
                  {currency(reportTotalCents, {
                    fromCents: true,
                  }).format()}
                </Text>
                <Text style={styles.currencyCol}>
                  {currency(reportBalanceCents, {
                    fromCents: true,
                  }).format()}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default GeneratedOpenInvoicesReport;
