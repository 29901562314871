import { type LazyQueryExecFunction } from '@apollo/client';
// eslint-disable-next-line no-restricted-imports
import { Alert, Box, Divider, Grid, Snackbar } from '@mui/material';
import type React from 'react';
import { useContext, useEffect, useState } from 'react';
import {
  type Exact,
  FindCompanyDocumentStatusFilter,
  type OrderQuery,
} from '../../../generated/graphql';
import EndOfDayContext from '../end-of-day-context';
import useStyles from '../end-of-day-styles';
import { type FetchCompanyDocumentsParams } from '../types/company-documents';
import EndOfDayMatchedDocumentsTable from './end-of-day-matched-documents-table';
import EndOfDayOrderMatchingTable from './end-of-day-order-matching-table';
import EndOfDayPodDocumentViewer from './end-of-day-pod-document-viewer';
import EndOfDayPodFilesSidebar from './end-of-day-pod-files-sidebar';

type EndOfDayMatchDocumentsProps = {
  // setShowSpecifyBolNumberPagesModal: React.Dispatch<
  //   React.SetStateAction<boolean>
  // >;

  // setShowMatchOrderSuccessMessage: React.Dispatch<
  //   React.SetStateAction<boolean>
  // >;
  //
  // setShowMatchOrderErrorMessage: React.Dispatch<React.SetStateAction<boolean>>;

  readonly searchText: string | undefined;

  readonly setSearchText: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  readonly fetchCompanyDocuments: (
    params: FetchCompanyDocumentsParams,
  ) => Promise<void>;
  readonly companyDocumentsLoading: boolean;
  readonly getOrder: LazyQueryExecFunction<
    OrderQuery,
    Exact<{
      uuid: string;
    }>
  >;
  readonly orderData: OrderQuery | undefined;
};

const EndOfDayMatchDocuments = ({
  searchText,
  setSearchText,
  fetchCompanyDocuments,
  companyDocumentsLoading,
  getOrder,
  orderData,
}: EndOfDayMatchDocumentsProps) => {
  const {
    documentStatusFilter,
    setSelectedDocumentUuids,
    setSelectedOrderToMatchUuid,
    setSelectedMatchedOrderUuid,
    currentDate,
  } = useContext(EndOfDayContext);

  useEffect(() => {
    setSelectedDocumentUuids([]);
    setSelectedOrderToMatchUuid(undefined);
    setSelectedMatchedOrderUuid(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate]);

  const styles = useStyles();

  const [showUnmatchOrderSuccessMessage, setShowUnmatchOrderSuccessMessage] =
    useState(false);
  const [showUnmatchOrderErrorMessage, setShowUnmatchOrderErrorMessage] =
    useState(false);

  return (
    <Box sx={{ flexDirection: 'column' }}>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showUnmatchOrderSuccessMessage}
        onClose={() => {
          setShowUnmatchOrderSuccessMessage(false);
        }}
      >
        <Alert severity="success">
          Successfully unmatched document(s) from order
        </Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showUnmatchOrderErrorMessage}
        onClose={() => {
          setShowUnmatchOrderErrorMessage(false);
        }}
      >
        <Alert severity="error">Failed to unmatch document(s) from order</Alert>
      </Snackbar>
      <Divider sx={{ zIndex: 1 }} />
      <Grid container>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            ...styles.borderRightDivider,

            borderRightColor: styles.borderColor,
          }}
        >
          <EndOfDayPodFilesSidebar
            searchText={searchText}
            setSearchText={setSearchText}
            fetchCompanyDocuments={fetchCompanyDocuments}
            companyDocumentsLoading={companyDocumentsLoading}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                ...styles.borderRightDivider,

                borderRightColor: styles.borderColor,
                overflowY: 'auto',
                padding: 0,
                transition: '0.15s',
              }}
            >
              <EndOfDayPodDocumentViewer
              // setShowMatchOrderSuccessMessage={
              //   setShowMatchOrderSuccessMessage
              // }
              // setShowMatchOrderErrorMessage={setShowMatchOrderErrorMessage}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {documentStatusFilter ===
              FindCompanyDocumentStatusFilter.Matched ? (
                <EndOfDayMatchedDocumentsTable
                  setShowUnmatchOrderSuccessMessage={
                    setShowUnmatchOrderSuccessMessage
                  }
                  setShowUnmatchOrderErrorMessage={
                    setShowUnmatchOrderErrorMessage
                  }
                  fetchCompanyDocuments={fetchCompanyDocuments}
                  getOrder={getOrder}
                  orderData={orderData}
                />
              ) : (
                <EndOfDayOrderMatchingTable
                  // setShowSpecifyBolNumberPagesModal={
                  //   setShowSpecifyBolNumberPagesModal
                  // }
                  fetchCompanyDocuments={fetchCompanyDocuments}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EndOfDayMatchDocuments;
