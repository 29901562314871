import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { sentenceCase } from 'change-case';
import currency from 'currency.js';
import dayjs from 'dayjs';
import { isEmpty, isNil } from 'lodash';
import { transformAddressToFullAddressString } from 'shared/copy';
import {
  type MeQuery,
  type PaymentForMemoReportFragment,
  PaymentType,
  Segment,
} from '../../../../../generated/graphql';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    paddingLeft: 23,
    paddingRight: 23,
    fontFamily: 'Roboto',
  },
  header1: {
    fontSize: '24px',
  },
  header2: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
});

const CELL_MARGIN = '10px';

export type GeneratedMemoReportProps = {
  readonly companyData: MeQuery | undefined;
  readonly payment: PaymentForMemoReportFragment;
};

const GeneratedMemoReport = ({
  companyData,
  payment,
}: GeneratedMemoReportProps) => {
  const bolString =
    companyData?.me?.company.segment === Segment.Cartage ? 'HAWB' : 'Pro #';

  const companyAddress = companyData?.me?.company.defaultAddress;
  const contactAddress = payment.contact.defaultAddress;
  const contactPerson = payment.contact.defaultContactPerson;

  return (
    <Document title="Credit Memo">
      <Page size="LETTER" style={styles.page}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            fontSize: '12px',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <View>
            <Text style={styles.header1}>
              {sentenceCase(payment.paymentType)} Memo
            </Text>
            <Text style={styles.header2}>{companyData?.me?.company.name}</Text>
            <Text>
              {isNil(companyAddress)
                ? ''
                : transformAddressToFullAddressString(companyAddress)}
            </Text>
          </View>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '10px',
            fontSize: '12px',
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: CELL_MARGIN,
              justifyContent: 'space-between',
            }}
          >
            <View style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ fontWeight: 'bold' }}>
                {payment.contact.displayName}
              </Text>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Text>
                  {isNil(contactAddress)
                    ? ''
                    : transformAddressToFullAddressString(contactAddress)}
                </Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ marginLeft: '10px' }}>
                  {contactPerson?.firstName} {contactPerson?.lastName}
                </Text>
                {!isNil(contactPerson?.phone) && (
                  <View>
                    {' '}
                    <Text style={{ marginLeft: '15px' }}>Phone: </Text>
                    <Text style={{ marginLeft: '10px' }}>
                      {contactPerson?.phone}
                    </Text>
                  </View>
                )}
              </View>
              {!isEmpty(payment.contact.billingContactEmail) && (
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Text>Email: </Text>
                  <Text style={{ marginLeft: '10px' }}>
                    {payment.contact.billingContactEmail}
                  </Text>
                </View>
              )}
            </View>{' '}
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ fontWeight: 'bold' }}>Date</Text>
                <Text style={{ marginLeft: '10px' }}>
                  {dayjs(payment.date).format('MM/DD/YYYY')}
                </Text>
              </View>
              {payment.paymentType === PaymentType.Credit && (
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Text style={{ fontWeight: 'bold' }}>Credit #</Text>
                  <Text style={{ marginLeft: '10px' }}>
                    {payment.creditNumber}
                  </Text>
                </View>
              )}
              {!isEmpty(payment.creditType?.name) &&
                (payment.paymentType === PaymentType.Credit ||
                  payment.paymentType === PaymentType.Debit) && (
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text style={{ fontWeight: 'bold' }}>Credit Type:</Text>
                    <Text style={{ marginLeft: '10px' }}>
                      {payment.creditType?.name}
                    </Text>
                  </View>
                )}
              {!isEmpty(payment.referenceNumber) && (
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Text style={{ fontWeight: 'bold' }}>Reference #</Text>
                  <Text style={{ marginLeft: '10px' }}>
                    {payment.referenceNumber}
                  </Text>
                </View>
              )}
              <View style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <Text style={{ fontWeight: 'bold' }}>
                  Total amount:{' '}
                  {payment.paymentType === PaymentType.Debit ? '-' : ''}
                  {currency(payment.amountInCents, {
                    fromCents: true,
                  }).format()}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              marginTop: '10px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {payment.invoicesAppliedTo.map((invoice) => {
              return (
                <View
                  key={invoice.uuid}
                  style={{
                    fontSize: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    paddingBottom: '5px',
                  }}
                >
                  <View style={{ width: '100%', backgroundColor: 'gainsboro' }}>
                    <Text style={{ fontWeight: 'bold' }}>{invoice.name}</Text>
                    <hr />
                  </View>
                  <View
                    style={{
                      fontSize: '10px',
                      display: 'flex',
                      flexDirection: 'row',
                      borderBottom: '1pt solid #BEBEBE',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      justifyContent: 'space-between',
                    }}
                  >
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                      <View
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <Text style={{ fontWeight: 'bold' }}>Invoice #: </Text>
                        <Text style={{ fontWeight: 'bold' }}>Journal #: </Text>
                        <Text style={{ fontWeight: 'bold' }}>Date: </Text>
                        <Text style={{ fontWeight: 'bold' }}>Total: </Text>
                        <Text style={{ fontWeight: 'bold' }}>Balance: </Text>
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginLeft: '10px',
                        }}
                      >
                        <Text>{invoice.name}</Text>
                        <Text>{invoice.journalNumber}</Text>
                        <Text>{dayjs(invoice.date).format('MM/DD/YYYY')}</Text>
                        <Text>{invoice.invoiceTotal}</Text>
                        <Text>
                          {currency(invoice.balanceInCents, {
                            fromCents: true,
                          }).format()}
                        </Text>
                      </View>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'column' }}>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          borderBottom: '1pt solid darkgray',
                        }}
                      >
                        <Text style={{ fontWeight: 'bold', width: '100px' }}>
                          {bolString}
                        </Text>
                        <Text style={{ fontWeight: 'bold', width: '80px' }}>
                          Total
                        </Text>
                        <Text style={{ fontWeight: 'bold', width: '80px' }}>
                          Balance
                        </Text>
                        <View>
                          <Text
                            style={{
                              fontWeight: 'bold',
                              width: '80px',
                            }}
                          >
                            Amount
                          </Text>
                        </View>
                      </View>
                      {payment.paymentToOrderMappings
                        .filter((paymentToOrderMapping) =>
                          paymentToOrderMapping.order.shipments.some(
                            (shipment) =>
                              shipment.invoice?.uuid === invoice.uuid,
                          ),
                        )
                        .map((paymentToOrderMapping) => (
                          <View
                            key={paymentToOrderMapping.uuid}
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                            }}
                          >
                            <Text style={{ width: '100px' }}>
                              {
                                paymentToOrderMapping.order.standardOrderFields
                                  .shipperBillOfLadingNumber
                              }
                            </Text>
                            <Text style={{ width: '80px' }}>
                              {currency(
                                paymentToOrderMapping.order.totalCharge,
                              ).format()}
                            </Text>
                            <Text style={{ width: '80px' }}>
                              {currency(
                                paymentToOrderMapping.order.balanceInCents,
                                { fromCents: true },
                              ).format()}
                            </Text>
                            <View>
                              <Text
                                style={{
                                  width: '80px',
                                }}
                              >
                                {payment.paymentType === PaymentType.Debit
                                  ? '-'
                                  : ''}
                                {currency(paymentToOrderMapping.amountInCents, {
                                  fromCents: true,
                                }).format()}
                              </Text>
                            </View>
                          </View>
                        ))}
                    </View>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      paddingTop: '5px',
                    }}
                  >
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                      {!isEmpty(payment.comment) && (
                        <>
                          <Text style={{ fontWeight: 'bold' }}>Comment:</Text>
                          <Text style={{ marginLeft: '10px' }}>
                            {payment.comment}
                          </Text>
                        </>
                      )}
                    </View>
                  </View>
                </View>
              );
            })}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default GeneratedMemoReport;
