import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import dayjs, { type Dayjs } from 'dayjs';
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';
import { getNoonOfDay } from 'shared/date';
import GeneralDatePicker from '../../../../../common/components/date-picker';
import useMe from '../../../../../common/react-hooks/use-me';
import {
  useUpdateServiceDateForAssignedStopsMutation,
  useUpdateStopsMutation,
} from '../../../../../generated/graphql';

const ServiceDateModal = ({
  open,
  setOpen,
  stopUuids,
  onConfirm,
  isAssigned = false,
}: {
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
  readonly stopUuids: string[];
  readonly onConfirm: () => void;
  readonly isAssigned?: boolean;
}) => {
  const { companyTimezone } = useMe();
  const [updateStops] = useUpdateStopsMutation();
  const [updateServiceDateForAssignedStops] =
    useUpdateServiceDateForAssignedStopsMutation();
  const [date, setDate] = useState<Dayjs>(dayjs());

  useEffect(() => {
    setDate(dayjs());
  }, []);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Stack sx={{ pb: 1, pr: 1 }}>
        <DialogTitle>Edit Service Date</DialogTitle>
        <DialogContent>
          <GeneralDatePicker date={date} setDate={setDate} text="" />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              if (isAssigned) {
                updateServiceDateForAssignedStops({
                  variables: {
                    updateServiceDateForAssignedStopsInput: {
                      serviceDate: getNoonOfDay(date, companyTimezone),
                      stopUuids,
                    },
                  },
                });
              }
              await updateStops({
                variables: {
                  updateStopsInput: {
                    stopUpdateInputs: stopUuids.map((stopUuid) => ({
                      uuid: stopUuid,
                      serviceDate: getNoonOfDay(date, companyTimezone),
                    })),
                  },
                },
              });
              onConfirm();
              setOpen(false);
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default ServiceDateModal;
