import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { isNil } from 'lodash';
import { type AccessorialDateRangeConfigFragment } from '../../../../generated/graphql';

dayjs.extend(isBetween);

const dateRangeOverlaps = (
  existingStart: Date,
  existingEnd: Date | null,
  newStart: Date,
  newEnd: Date | null,
) => {
  if (isNil(existingEnd) || isNil(newEnd)) {
    return !(
      dayjs(newStart).isAfter(existingEnd ?? existingStart) ||
      dayjs(newEnd).isBefore(existingStart)
    );
  }

  if (
    dayjs(newStart).isBetween(existingStart, existingEnd) ||
    dayjs(newEnd).isBetween(existingStart, existingEnd)
  ) {
    return true;
  }

  return false;
};

export const getErrorStringForPricingPeriodDateRanges = ({
  accessorialDateRangeConfigs,
  startDate,
  endDate,
}: {
  accessorialDateRangeConfigs: AccessorialDateRangeConfigFragment[];
  startDate: Date | null;
  endDate: Date | null;
}) => {
  if (isNil(startDate)) {
    return 'Start date is required';
  }
  if (!isNil(endDate) && startDate >= endDate) {
    return 'Start date cannot be after end date';
  }
  if (
    accessorialDateRangeConfigs.some((accessorialDateRangeConfig) =>
      dateRangeOverlaps(
        accessorialDateRangeConfig.startDate,
        accessorialDateRangeConfig.endDate,
        startDate,
        endDate,
      ),
    )
  ) {
    return 'Date range overlaps with existing range';
  }

  return null;
};
