import LogoutIcon from '@mui/icons-material/Logout';
import { AppBar, Box, Button, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import type React from 'react';
import { useLocation } from 'react-router-dom';
import ButtonLink from '../../../common/components/buttons/button-link';
import useLogout from '../../../common/react-hooks/use-logout';
import { useWmsContactsQuery } from '../../../generated/graphql';
import {
  CUSTOMER_PORTAL_INVENTORY_PATH,
  CUSTOMER_PORTAL_ORDERS_PATH,
} from '../constants';

const styles = {
  appBar: {
    position: 'sticky',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingY: 2,
    paddingX: 4,
    gap: 4,
    marginBottom: 2,
    height: 64,
  },
  appBarTypography: {
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '29px',
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
};

type CustomerPortalAppBarProps = {
  readonly children: React.ReactNode;
};
const CustomerPortalAppBar = ({ children }: CustomerPortalAppBarProps) => {
  const { pathname } = useLocation();
  const logout = useLogout();
  const { data: wmsContacts } = useWmsContactsQuery({
    fetchPolicy: 'cache-and-network',
  });
  const hasInventory = !isEmpty(wmsContacts?.wmsContacts ?? []);

  return (
    <AppBar color="primary" sx={styles.appBar}>
      {hasInventory && (
        <>
          <ButtonLink
            href={CUSTOMER_PORTAL_ORDERS_PATH}
            sx={{
              justifySelf: 'flex-end',
              color: '#fff',
              whiteSpace: 'nowrap',
              textDecoration: pathname.includes(CUSTOMER_PORTAL_ORDERS_PATH)
                ? 'underline'
                : 'none',
            }}
          >
            Orders
          </ButtonLink>
          <ButtonLink
            href={CUSTOMER_PORTAL_INVENTORY_PATH}
            sx={{
              justifySelf: 'flex-end',
              color: '#fff',
              whiteSpace: 'nowrap',
              textDecoration: pathname.includes(CUSTOMER_PORTAL_INVENTORY_PATH)
                ? 'underline'
                : 'none',
            }}
          >
            Inventory
          </ButtonLink>
        </>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <Typography sx={styles.appBarTypography}>{children}</Typography>
      </Box>
      <Button
        startIcon={<LogoutIcon />}
        sx={{ justifySelf: 'flex-end', color: '#fff' }}
        onClick={logout}
      >
        Logout
      </Button>
    </AppBar>
  );
};

export default CustomerPortalAppBar;
