import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  InputLabel,
  TextField,
  Stack,
  Typography,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import React, {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useMemo,
} from 'react';
import { Controller, type SubmitHandler } from 'react-hook-form';
import useWarehouses from '../../../../common/react-hooks/use-warehouses';
import {
  useCreateWarehouseLocationMutation,
  useUpdateWarehouseLocationMutation,
  WarehouseLocationsDocument,
  type WarehouseLocationsLocationFragment,
} from '../../../../generated/graphql';
import WarehouseSelector from '../../../storage-orders/components/common/warehouse-selector';
import useWarehouseLocationForm, {
  type WarehouseLocationFormValues,
} from '../../forms/use-warehouse-location-form';

type WarehouseLocationModalProps = {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly setSuccessSnackbarVisible: Dispatch<SetStateAction<boolean>>;
  readonly setErrorSnackbarVisible: Dispatch<SetStateAction<boolean>>;

  readonly selectedWarehouseLocation:
    | WarehouseLocationsLocationFragment
    | undefined;

  readonly setSelectedWarehouseLocation: Dispatch<
    SetStateAction<WarehouseLocationsLocationFragment | undefined>
  >;
};

const WarehouseLocationModal = ({
  open,
  onClose,
  setSuccessSnackbarVisible,
  setErrorSnackbarVisible,
  selectedWarehouseLocation,
  setSelectedWarehouseLocation,
}: WarehouseLocationModalProps) => {
  const {
    watch,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setError: setWarehouseLocationFormError,
  } = useWarehouseLocationForm();
  const { warehouses } = useWarehouses();
  const location1InputValue = watch('location1');
  const location2InputValue = watch('location2');
  const location3InputValue = watch('location3');
  const name = useMemo(() => {
    return [location1InputValue, location2InputValue, location3InputValue]
      .filter((field) => !isNil(field) && !isEmpty(field))
      .join('-');
  }, [location1InputValue, location2InputValue, location3InputValue]);

  const handleClose = () => {
    reset();
    onClose();
    setSelectedWarehouseLocation(undefined);
  };

  const [createWarehouseLocation, { loading: createWarehouseLocationLoading }] =
    useCreateWarehouseLocationMutation({
      refetchQueries: [WarehouseLocationsDocument],
    });

  const [updateWarehouseLocation, { loading: updateWarehouseLocationLoading }] =
    useUpdateWarehouseLocationMutation({
      refetchQueries: [WarehouseLocationsDocument],
    });

  useEffect(() => {
    let selectedWarehouse = selectedWarehouseLocation?.warehouse;
    if (isNil(selectedWarehouse) && warehouses?.length === 1) {
      [selectedWarehouse] = warehouses;
    }
    reset({
      warehouse: {
        label: selectedWarehouse?.name,
        value: selectedWarehouse?.uuid,
      },
      location1: selectedWarehouseLocation?.location1 ?? '',
      location2: selectedWarehouseLocation?.location2 ?? '',
      location3: selectedWarehouseLocation?.location3 ?? '',
    });
  }, [reset, selectedWarehouseLocation, warehouses]);

  const onCreateSubmit: SubmitHandler<WarehouseLocationFormValues> = async (
    data,
  ) => {
    const { location1, location2, location3, warehouse } = data;
    if (isEmpty(location1) && isEmpty(location2) && isEmpty(location3)) {
      setWarehouseLocationFormError('root', {
        message: 'At least one location field must be filled out.',
      });
      return;
    }
    try {
      const response = await createWarehouseLocation({
        variables: {
          createWarehouseLocationInput: {
            name,
            location1,
            location2,
            location3,
            warehouseUuid: warehouse.value,
          },
        },
      });
      if (!isNil(response.errors)) {
        setWarehouseLocationFormError('root', {
          message: 'There was an error creating the warehouse location.',
        });
      }
      if (
        !isNil(response?.data?.createWarehouseLocation.errors) &&
        !isEmpty(response?.data?.createWarehouseLocation.errors)
      ) {
        setWarehouseLocationFormError('root', {
          message: response?.data?.createWarehouseLocation.errors[0],
        });
      } else {
        setSuccessSnackbarVisible(true);
        handleClose();
      }
    } catch {
      setErrorSnackbarVisible(true);
    }
  };

  const onUpdateSubmit: SubmitHandler<WarehouseLocationFormValues> = async (
    data,
  ) => {
    if (isNil(selectedWarehouseLocation)) {
      return;
    }
    const { location1, location2, location3, warehouse } = data;
    if (isEmpty(location1) && isEmpty(location2) && isEmpty(location3)) {
      setWarehouseLocationFormError('root', {
        message: 'At least one location field must be filled out.',
      });
      return;
    }
    try {
      const response = await updateWarehouseLocation({
        variables: {
          updateWarehouseLocationInput: {
            uuid: selectedWarehouseLocation.uuid,
            name,
            location1,
            location2,
            location3,
            warehouseUuid: warehouse.value,
          },
        },
      });
      if (!isNil(response.errors)) {
        setWarehouseLocationFormError('root', {
          message: 'There was an error updating the warehouse location.',
        });
      }
      if (
        !isNil(response.data?.updateWarehouseLocation.errors) &&
        !isEmpty(response.data?.updateWarehouseLocation.errors)
      ) {
        setWarehouseLocationFormError('root', {
          message: response.data?.updateWarehouseLocation.errors[0],
        });
      } else {
        setSuccessSnackbarVisible(true);
        handleClose();
      }
    } catch {
      setErrorSnackbarVisible(true);
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <DialogTitle>{`${
        isNil(selectedWarehouseLocation) ? 'Create' : 'Update'
      } warehouse location`}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {(warehouses?.length ?? 0) > 1 && (
              <Controller
                name="warehouse"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <InputLabel>Warehouse</InputLabel>
                    <WarehouseSelector
                      whiteBackground
                      noLabel
                      value={value}
                      width="100%"
                      error={!isNil(errors.warehouse)}
                      onChange={(newWarehouse) => {
                        onChange(newWarehouse);
                      }}
                    />
                    {!isNil(
                      errors?.warehouse?.message ??
                        errors?.warehouse?.value?.message,
                    ) && (
                      <Typography color="error">
                        {errors?.warehouse?.message ??
                          errors?.warehouse?.value?.message ??
                          ''}
                      </Typography>
                    )}
                  </>
                )}
              />
            )}
            <Controller
              name="location1"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <InputLabel>Location 1</InputLabel>
                  <TextField
                    size="small"
                    value={value}
                    error={!isNil(errors.location1)}
                    helperText={errors?.location1?.message?.toString() ?? ''}
                    sx={{ width: '100%', mb: 2 }}
                    onChange={onChange}
                  />
                </>
              )}
            />
            <Controller
              name="location2"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <InputLabel>Location 2</InputLabel>
                  <TextField
                    size="small"
                    value={value}
                    error={!isNil(errors.location2)}
                    helperText={errors?.location2?.message?.toString() ?? ''}
                    sx={{ width: '100%', mb: 2 }}
                    onChange={onChange}
                  />
                </>
              )}
            />
            <Controller
              name="location3"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <InputLabel>Location 3</InputLabel>
                  <TextField
                    size="small"
                    value={value}
                    error={!isNil(errors.location3)}
                    helperText={errors?.location3?.message?.toString() ?? ''}
                    sx={{ width: '100%', mb: 2 }}
                    onChange={onChange}
                  />
                </>
              )}
            />
            <Typography
              sx={{ visibility: isEmpty(name) ? 'hidden' : 'visible' }}
            >
              This location will be displayed as <strong>{name}</strong>
            </Typography>
            {!isNil(errors.root?.message) && (
              <Typography color="error">{errors.root?.message}</Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" gap={1}>
          <Button
            variant="outlined"
            disabled={
              Boolean(createWarehouseLocationLoading) ||
              updateWarehouseLocationLoading
            }
            size="small"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={
              Boolean(createWarehouseLocationLoading) ||
              updateWarehouseLocationLoading
            }
            size="small"
            onClick={
              isNil(selectedWarehouseLocation)
                ? handleSubmit(onCreateSubmit)
                : handleSubmit(onUpdateSubmit)
            }
          >
            Save
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default WarehouseLocationModal;
