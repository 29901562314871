import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { type FreightChargeFormField } from 'shared/types';
import type { RootState } from '../../../redux/store';

export type FreightChargeError = Partial<
  Omit<FreightChargeFormField, 'uuid'>
> & { uuid: string };
type FreightChargeErrorsStoreState = FreightChargeError;

const freightChargesErrorsAdapter =
  createEntityAdapter<FreightChargeErrorsStoreState>({
    selectId: (freightCharge) => freightCharge.uuid,
  });

export const freightChargesErrorsSlice = createSlice({
  name: 'freightChargesErrors',
  initialState: freightChargesErrorsAdapter.getInitialState(),
  reducers: {
    addFreightChargeErrors: freightChargesErrorsAdapter.addOne,
    upsertFreightChargeErrors: freightChargesErrorsAdapter.upsertOne,
    deleteFreightChargeErrors: freightChargesErrorsAdapter.removeOne,
    updateFreightChargesErrors: freightChargesErrorsAdapter.updateMany,
  },
});

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectById: selectFreightChargeErrorsById,
  // Pass in a selector that returns the posts slice of state
} = freightChargesErrorsAdapter.getSelectors(
  (state: RootState) => state.orderFormFreightChargesErrors,
);

export const { upsertFreightChargeErrors } = freightChargesErrorsSlice.actions;

export default freightChargesErrorsSlice.reducer;
