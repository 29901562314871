import { ChevronRight, Warning } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {
  FormHelperText,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import { isNil } from 'lodash';
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';
import { Controller, type SubmitHandler } from 'react-hook-form';
import styled from 'styled-components';
import WarehouseLocationSelector from '../../../../../common/components/forms/warehouse-location-selector';
import {
  type FindStorageOrderFragment,
  StorageOrderDocument,
  type StorageOrderStorageUnitFragment,
  useUpdateStorageUnitMutation,
} from '../../../../../generated/graphql';
import theme from '../../../../../theme';
import useEditStorageUnitForm, {
  type EditStorageUnitFormValues,
} from '../../../forms/storage-units/use-edit-storage-unit-form';

type StorageUnitRowProps = {
  readonly storageOrder: FindStorageOrderFragment;

  readonly storageUnit: StorageOrderStorageUnitFragment;

  readonly setUpdateStorageUnitSuccessMessageVisible: Dispatch<
    SetStateAction<boolean>
  >;

  readonly setUpdateStorageUnitErrorMessageVisible: Dispatch<
    SetStateAction<boolean>
  >;

  readonly setDeleteStorageUnitModalOpen: Dispatch<SetStateAction<boolean>>;

  readonly setStorageUnitToDeleteUuid: Dispatch<
    SetStateAction<string | undefined>
  >;

  readonly selectedStorageUnit: StorageOrderStorageUnitFragment | null;

  readonly onSelectStorageUnit: (newStorageUnitUuid: string | null) => void;
};

const SelectableRow = styled(TableRow)<{ $selected: boolean }>`
  cursor: pointer;
  background: ${(props) => (props.selected === true ? '#F7F7F7' : '#FFFFFF')};
`;

const StorageUnitRow = ({
  storageOrder,
  storageUnit,
  setUpdateStorageUnitSuccessMessageVisible,
  setUpdateStorageUnitErrorMessageVisible,
  setDeleteStorageUnitModalOpen,
  setStorageUnitToDeleteUuid,
  selectedStorageUnit,
  onSelectStorageUnit,
}: StorageUnitRowProps) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useEditStorageUnitForm();
  useEffect(() => {
    reset({
      name: storageUnit.name,
      location: storageUnit.warehouseLocation?.uuid,
    });
  }, [storageUnit]);

  const [updateStorageUnit, { loading: updateStorageUnitLoading }] =
    useUpdateStorageUnitMutation({
      refetchQueries: [StorageOrderDocument],
      awaitRefetchQueries: true,
    });

  const onSubmit: SubmitHandler<EditStorageUnitFormValues> = async (data) => {
    try {
      await updateStorageUnit({
        variables: {
          updateStorageUnitInput: {
            uuid: storageUnit.uuid,
            name: data.name,
            warehouseLocationUuid: data.location,
          },
        },
      });
      setUpdateStorageUnitSuccessMessageVisible(true);
      setIsEditMode(false);
    } catch {
      setUpdateStorageUnitErrorMessageVisible(true);
    }
  };

  const handleCancel = () => {
    reset();
    setIsEditMode(false);
  };

  const handleRowClick = () => {
    onSelectStorageUnit(storageUnit.uuid);
  };

  const editStorageUnitForm = (
    <SelectableRow
      $selected={storageUnit.uuid === selectedStorageUnit?.uuid}
      selected={storageUnit.uuid === selectedStorageUnit?.uuid}
      onClick={handleRowClick}
    >
      <TableCell
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Controller
          name="name"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <TextField
                required
                name="name"
                size="small"
                value={value}
                error={!isNil(errors.name)}
                sx={{ width: '50%' }}
                onChange={onChange}
              />
              {!isNil(errors.name) && (
                <FormHelperText sx={{ color: '#D32F2F' }}>
                  {errors.name?.message ?? ''}
                </FormHelperText>
              )}
            </>
          )}
        />
      </TableCell>
      <TableCell
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Controller
          name="location"
          control={control}
          render={({ field: { onChange, value } }) => (
            <WarehouseLocationSelector
              warehouseUuid={storageOrder.warehouse.uuid}
              selectedWarehouseLocationUuid={value}
              error={errors.location?.message}
              onChange={(option) => {
                onChange(option.value);
              }}
            />
          )}
        />
      </TableCell>
      <TableCell
        align="right"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <IconButton
          color="primary"
          onClick={() => {
            setStorageUnitToDeleteUuid(storageUnit.uuid);
            setDeleteStorageUnitModalOpen(true);
            setIsEditMode(false);
          }}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
        <IconButton
          disabled={updateStorageUnitLoading}
          color="primary"
          onClick={handleSubmit(onSubmit)}
        >
          <CheckIcon fontSize="small" />
        </IconButton>
        <IconButton
          disabled={updateStorageUnitLoading}
          color="primary"
          onClick={handleCancel}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </TableCell>
    </SelectableRow>
  );

  if (isEditMode) {
    return editStorageUnitForm;
  }

  return (
    <SelectableRow
      $selected={storageUnit.uuid === selectedStorageUnit?.uuid}
      selected={storageUnit.uuid === selectedStorageUnit?.uuid}
      onClick={handleRowClick}
    >
      <TableCell>
        <Stack direction="row" alignItems="center" gap={1}>
          {storageUnit.name}
          {!isNil(storageUnit.markedOsdAt) && (
            <Tooltip title={storageUnit.osdReason ?? 'Marked OSD'}>
              <Warning color="warning" />
            </Tooltip>
          )}
        </Stack>
      </TableCell>
      <TableCell sx={{ minWidth: theme.spacing(15) }}>
        {storageUnit.warehouseLocation?.name ?? '-'}
      </TableCell>
      <TableCell align="right">
        <IconButton
          disabled={updateStorageUnitLoading}
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsEditMode(true);
          }}
        >
          <ModeEditIcon fontSize="small" />
        </IconButton>
        {!isNil(selectedStorageUnit) && (
          <Tooltip arrow title="Show details">
            <IconButton sx={{ padding: '5px' }} onClick={handleRowClick}>
              <ChevronRight />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </SelectableRow>
  );
};

export default StorageUnitRow;
