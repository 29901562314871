import { type SxProps, useTheme } from '@mui/material';

const useStyles = () => {
  const theme = useTheme();
  return {
    fieldRow: {
      display: 'flex',
      flexDirection: 'row',
      gap: 1,
      my: 1.5,
      alignItems: 'center',
      justifyContent: 'space-around',
      width: '100%',
    },
    formField: {
      width: 200,
    },
    verticalFormField: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    boxRow: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      rowGap: theme.spacing(3),
      columnGap: theme.spacing(10),
      width: '100%',
    } as SxProps,
    card: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: 1,
      p: 2,
      width: '100%',
      // height: '100%',
      overflow: 'visible',
      border: 1,
      borderColor: theme.palette.borderColor.main,
      boxShadow: 'none',
    } as SxProps,
    modal: {
      position: 'absolute' as const,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
      height: '70vh',
      width: '60vw',
      boxShadow: 24,
      p: 4,
      display: 'flex',
      flexDirection: 'column' as const,
      alignItems: 'space-between',
      justifyContent: 'space-between',
    },
    grayCard: {
      backgroundColor: theme.palette.background.default,
      borderRadius: '0 0 10px 10px',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: 2,
      p: 2,
      width: '100%',
      overflow: 'visible',
    } as SxProps,
    collapsedContainer: {
      backgroundColor: '#F7F7F7',
      borderRadius: '10px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      py: 1,
      pl: 5,
      pr: 4,
    } as SxProps,
    expandedContainerHeader: {
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      py: 1,
      pl: 5,
      pr: 4,
    } as SxProps,
    legCard: {
      border: '1px solid #C7C7C7',
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'row',
      columnGap: 4,
      p: 2,
      width: '100%',
      overflow: 'visible',
    } as SxProps,
    legField: { backgroundColor: 'white' },
    legStopBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      maxWidth: '600px',
      gap: 2,
    },
    centeredGridItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    shipmentDetailsBox: {
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: 2,
      p: 2,
      justifyContent: 'space-between',
      width: '100%',
    },
    standardOrderTypeOption: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      alignItems: 'center',
    },
    transportationTypeCard: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      gap: 2,
      p: 2,
      width: '100%',
    } as SxProps,
  };
};

export default useStyles;
