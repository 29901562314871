import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  // eslint-disable-next-line no-restricted-imports
  Grid,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import React from 'react';
import {
  type SameDayDispatchCellHeader,
  type SameDayDispatchRouteFragment,
} from '../../../generated/graphql';
import DriverRouteStopCard from './driver-route-stop-card';

const useStyles = () => ({
  centeredRow: {
    display: 'flex',
    justifyContent: 'center',
  },
  cardRow: {
    display: 'flex',
    justifyContent: 'center',
    gap: 4,
  },
});

type DailyControlCenterDriverRouteModalProps = {
  readonly route: SameDayDispatchRouteFragment | undefined;
  readonly open: boolean;
  readonly onClose: () => void;

  readonly warehouseUuid: string | undefined;

  readonly sameDayDispatchCellHeader: SameDayDispatchCellHeader;
};

const DailyControlCenterDriverRouteModal = ({
  route,
  open,
  onClose,
  warehouseUuid,
  sameDayDispatchCellHeader,
}: DailyControlCenterDriverRouteModalProps) => {
  const styles = useStyles();
  return (
    <Dialog fullWidth open={open} maxWidth="xl" onClose={onClose}>
      <DialogTitle sx={styles.centeredRow}>{route?.name ?? ''}</DialogTitle>
      <DialogContent>
        <Grid container sx={styles.cardRow}>
          {route?.slots
            .filter((slot) => {
              const stop = slot.stops[0];
              const order = stop?.leg.shipment?.order;
              return isEmpty(warehouseUuid)
                ? true
                : order?.warehouse?.uuid === warehouseUuid;
            })
            .map((routeSlot) => {
              const stop = routeSlot.stops[0];
              if (isNil(stop)) {
                console.error(
                  `[Daily control center] route slot ${routeSlot.uuid} has nil stop`,
                );
                return null;
              }
              return (
                <DriverRouteStopCard
                  key={routeSlot.uuid}
                  route={route}
                  routeSlotUuid={routeSlot.uuid}
                  stop={stop}
                  sameDayDispatchCellHeader={sameDayDispatchCellHeader}
                />
              );
            })}
        </Grid>
      </DialogContent>
      <DialogActions style={styles.centeredRow}>
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DailyControlCenterDriverRouteModal;
