import { type Dayjs } from 'dayjs';
import { isNil } from 'lodash';
import { shallow } from 'zustand/shallow';
import {
  type CreateLineHaulManifestInput,
  type CreateManifestFromUnmanifestedSegmentInput,
  type DetailedLineHaulManifestFragment,
  type ShallowLineHaulSegmentFragment,
  useAddOrdersToManifestMutation,
  useCreateLineHaulManifestMutation,
  useCreateManifestFromUnmanifestedSegmentMutation,
  useLineHaulManifestsLazyQuery,
  useMarkManifestAsArrivedMutation,
  useMarkManifestAsDepartedMutation,
  useMarkManifestAsPlanningMutation,
  useMarkUnmanifestedSegmentAsDepartedMutation,
  usePostponeOrdersOnManifestMutation,
  useRemoveLineHaulManifestMutation,
  useRemoveOrdersFromManifestMutation,
  useUpdateManifestDriverMutation,
  useUpdateManifestVehicleMutation,
} from '../../../generated/graphql';
import useLineHaulDispatchStore from '../store/line-haul-dispatch-store';

const useLineHaulDispatchActions = () => {
  const [
    planningDate,
    manifests,
    unmanifestedSegments,
    addManifestFromUnmanifestedSegment,
    setManifest,
    setShouldRefreshGrid,
    setSelectingManifests,
    deselectAllManifestAndUnmanifestedSegmentUuids,
    setPlanningDate,
    addManifest,
    setOpenedManifest,
    deleteManifest,
    setManifests,
    setUnmanifestedSegments,
  ] = useLineHaulDispatchStore(
    (state) => [
      state.planningDate,
      state.manifests,
      state.unmanifestedSegments,
      state.addManifestFromUnmanifestedSegment,
      state.setManifest,
      state.setShouldRefreshGrid,
      state.setSelectingManifests,
      state.deselectAllManifestAndUnmanifestedSegmentUuids,
      state.setPlanningDate,
      state.addManifest,
      state.setOpenedManifest,
      state.deleteManifest,
      state.setManifests,
      state.setUnmanifestedSegments,
    ],
    shallow,
  );

  const [createManifestFromUnmanifestedSegment] =
    useCreateManifestFromUnmanifestedSegmentMutation();

  const [updateManifestDriver] = useUpdateManifestDriverMutation();
  const [updateManifestVehicle] = useUpdateManifestVehicleMutation();
  const [addOrdersToManifest] = useAddOrdersToManifestMutation();
  const [removeOrdersFromManifest] = useRemoveOrdersFromManifestMutation();
  const [markManifestAsDeparted] = useMarkManifestAsDepartedMutation();
  const [markUnmanifestedSegmentAsDeparted] =
    useMarkUnmanifestedSegmentAsDepartedMutation();
  const [markManifestAsArrived] = useMarkManifestAsArrivedMutation();
  const [markManifestAsPlanning] = useMarkManifestAsPlanningMutation();
  const [postponeOrdersOnManifest] = usePostponeOrdersOnManifestMutation();
  const [createManifest] = useCreateLineHaulManifestMutation();
  const [removeManifest] = useRemoveLineHaulManifestMutation();
  const [getLineHaulManifests] = useLineHaulManifestsLazyQuery();

  const changePlanningDate = ({
    newPlanningDate,
  }: {
    newPlanningDate: Dayjs;
  }) => {
    setSelectingManifests(false);
    deselectAllManifestAndUnmanifestedSegmentUuids();
    setPlanningDate(newPlanningDate);
  };

  const stopSelectingManifests = () => {
    setSelectingManifests(false);
    deselectAllManifestAndUnmanifestedSegmentUuids();
  };

  const fetchManifests = async () => {
    if (!isNil(planningDate)) {
      const res = await getLineHaulManifests({
        variables: { date: planningDate },
      });

      setManifests(res.data?.lineHaulManifests.lineHaulManifests);
      setUnmanifestedSegments(res.data?.lineHaulManifests.unmanifestedSegments);
    }
  };

  const addSingleOrderToOpenedManifest = async ({
    orderUuid,
    openedManifestUuid,
  }: {
    orderUuid: string;
    openedManifestUuid: string | undefined;
  }): Promise<string | null> => {
    if (!isNil(openedManifestUuid)) {
      const res = await addOrdersToManifest({
        variables: {
          addOrdersToManifestInput: {
            uuid: openedManifestUuid,
            orderUuids: [orderUuid],
          },
        },
      });
      const createdManifest = res.data?.addOrdersToManifest.lineHaulManifest;
      const errorMessage = res.data?.addOrdersToManifest.errorMessage;

      if (!isNil(createdManifest) && isNil(errorMessage)) {
        setManifest(createdManifest);
        return createdManifest.uuid;
      }
    }
    return null;
  };

  const addMultipleOrdersToOpenedManifest = async ({
    orderUuids,
    openedManifestUuid,
  }: {
    orderUuids: string[];
    openedManifestUuid: string | undefined;
  }): Promise<string | null> => {
    if (!isNil(openedManifestUuid)) {
      const res = await addOrdersToManifest({
        variables: {
          addOrdersToManifestInput: {
            uuid: openedManifestUuid,
            orderUuids,
          },
        },
      });
      const createdManifest = res.data?.addOrdersToManifest.lineHaulManifest;
      const errorMessage = res.data?.addOrdersToManifest.errorMessage;

      if (!isNil(createdManifest) && isNil(errorMessage)) {
        setManifest(createdManifest);
        return createdManifest.uuid;
      }
    }

    return null;
  };

  const addSingleOrderToManifest = async ({
    orderUuid,
    manifest,
  }: {
    orderUuid: string;
    manifest: DetailedLineHaulManifestFragment;
  }): Promise<string | null> => {
    const res = await addOrdersToManifest({
      variables: {
        addOrdersToManifestInput: {
          uuid: manifest.uuid,
          orderUuids: [orderUuid],
        },
      },
    });
    const createdManifest = res.data?.addOrdersToManifest.lineHaulManifest;
    const errorMessage = res.data?.addOrdersToManifest.errorMessage;

    if (!isNil(createdManifest) && isNil(errorMessage)) {
      setManifest(createdManifest);
      return createdManifest.uuid;
    }
    return null;
  };

  const addSingleOrderToMatchingManifest = async ({
    orderUuid,
    upcomingSegmentOfOrder,
  }: {
    orderUuid: string;
    upcomingSegmentOfOrder: ShallowLineHaulSegmentFragment;
  }): Promise<string | null> => {
    const matchingManifest = manifests?.find(
      (m) =>
        m.startTerminal.uuid === upcomingSegmentOfOrder.startTerminal.uuid &&
        m.endTerminal.uuid === upcomingSegmentOfOrder.endTerminal.uuid,
    );
    const matchingUnmanifestedSegment = unmanifestedSegments?.find(
      (m) =>
        m.startTerminal.uuid === upcomingSegmentOfOrder.startTerminal.uuid &&
        m.endTerminal.uuid === upcomingSegmentOfOrder.endTerminal.uuid,
    );
    if (!isNil(matchingManifest)) {
      const res = await addOrdersToManifest({
        variables: {
          addOrdersToManifestInput: {
            uuid: matchingManifest.uuid,
            orderUuids: [orderUuid],
          },
        },
      });
      const createdManifest = res.data?.addOrdersToManifest.lineHaulManifest;
      const errorMessage = res.data?.addOrdersToManifest.errorMessage;

      if (!isNil(createdManifest) && isNil(errorMessage)) {
        setManifest(createdManifest);
        return createdManifest.uuid;
      }
    }
    if (!isNil(matchingUnmanifestedSegment)) {
      // create a manifest
      const res = await createManifestFromUnmanifestedSegment({
        variables: {
          createManifestFromUnmanifestedSegmentInput: {
            unmanifestedSegmentUuid: matchingUnmanifestedSegment.uuid,
            departDate: planningDate,
            orderUuids: [orderUuid],
          },
        },
      });
      const createdManifest =
        res.data?.createManifestFromUnmanifestedSegment.lineHaulManifest;
      const errorMessage =
        res.data?.createManifestFromUnmanifestedSegment.errorMessage;

      if (!isNil(createdManifest) && isNil(errorMessage)) {
        addManifestFromUnmanifestedSegment(
          createdManifest,
          matchingUnmanifestedSegment.uuid,
        );
        return createdManifest.uuid;
      }
    }
    return null;
  };

  const createNewManifestFromUnmanifestedSegment = async ({
    input,
    unmanifestedSegmentUuid,
    openManifestAfter = false,
  }: {
    input: CreateManifestFromUnmanifestedSegmentInput;
    unmanifestedSegmentUuid: string;
    openManifestAfter?: boolean;
  }): Promise<DetailedLineHaulManifestFragment | null> => {
    const res = await createManifestFromUnmanifestedSegment({
      variables: {
        createManifestFromUnmanifestedSegmentInput: input,
      },
    });
    const createdManifest =
      res.data?.createManifestFromUnmanifestedSegment.lineHaulManifest;
    const errorMessage =
      res.data?.createManifestFromUnmanifestedSegment.errorMessage;

    if (!isNil(createdManifest) && isNil(errorMessage)) {
      addManifestFromUnmanifestedSegment(
        createdManifest,
        unmanifestedSegmentUuid,
        openManifestAfter,
      );

      return createdManifest;
    }
    return null;
  };

  const createNewManifest = async ({
    input,
  }: {
    input: CreateLineHaulManifestInput;
  }): Promise<DetailedLineHaulManifestFragment | null> => {
    const res = await createManifest({
      variables: {
        createLineHaulManifestInput: input,
      },
    });
    const createdManifest = res.data?.createLineHaulManifest;

    if (!isNil(createdManifest)) {
      addManifest(createdManifest);
      setOpenedManifest(createdManifest);
      return createdManifest;
    }
    return null;
  };

  const updateDriverOnManifest = async ({
    uuid,
    driverUuid,
  }: {
    uuid: string;
    driverUuid: string;
  }) => {
    const res = await updateManifestDriver({
      variables: {
        updateManifestDriverInput: {
          uuid,
          driverUuid,
        },
      },
    });

    const updatedManifest = res.data?.updateManifestDriver;
    if (!isNil(updatedManifest)) {
      setManifest(updatedManifest);
    }
  };

  const updateVehicleOnManifest = async ({
    uuid,
    equipmentUuid,
  }: {
    uuid: string;
    equipmentUuid: string;
  }) => {
    const res = await updateManifestVehicle({
      variables: {
        updateManifestVehicleInput: {
          uuid,
          equipmentUuid,
        },
      },
    });
    const updatedManifest = res.data?.updateManifestVehicle;
    if (!isNil(updatedManifest)) {
      setManifest(updatedManifest);
    }
  };

  const departManifest = async ({
    uuid,
    refreshGridAfter,
  }: {
    uuid: string;
    refreshGridAfter: boolean;
  }) => {
    const res = await markManifestAsDeparted({
      variables: {
        markManifestAsDepartedInput: {
          uuid,
        },
      },
    });
    const updatedManifest = res.data?.markManifestAsDeparted.lineHaulManifest;
    if (!isNil(updatedManifest)) {
      setManifest(updatedManifest);
      if (refreshGridAfter) setShouldRefreshGrid(true);
    }
  };

  const departManifests = async ({ uuids }: { uuids: string[] }) => {
    await Promise.all(
      uuids.map(async (uuid) =>
        departManifest({ uuid, refreshGridAfter: false }),
      ),
    );
    setShouldRefreshGrid(true);
  };

  const departUnmanifestedSegment = async ({
    unmanifestedSegmentUuid,
  }: {
    unmanifestedSegmentUuid: string;
  }) => {
    const res = await markUnmanifestedSegmentAsDeparted({
      variables: {
        markUnmanifestedSegmentAsDepartedInput: {
          unmanifestedSegmentUuid,
          departDate: planningDate,
        },
      },
    });
    const createdManifest =
      res.data?.markUnmanifestedSegmentAsDeparted.lineHaulManifest;
    if (!isNil(createdManifest)) {
      addManifestFromUnmanifestedSegment(
        createdManifest,
        unmanifestedSegmentUuid,
      );
      setShouldRefreshGrid(true);
    }
  };

  const arriveManifest = async ({ uuid }: { uuid: string }) => {
    const res = await markManifestAsArrived({
      variables: {
        markManifestAsArrivedInput: {
          uuid,
          fromDispatch: true,
        },
      },
    });
    const updatedManifest = res.data?.markManifestAsArrived.lineHaulManifest;
    if (!isNil(updatedManifest)) {
      setManifest(updatedManifest);
      setShouldRefreshGrid(true);
    }
  };

  const markManifestAsBeingPlanned = async ({ uuid }: { uuid: string }) => {
    const res = await markManifestAsPlanning({
      variables: {
        markManifestAsPlanningInput: {
          uuid,
        },
      },
    });
    const updatedManifest = res.data?.markManifestAsPlanning.lineHaulManifest;
    if (!isNil(updatedManifest)) {
      setManifest(updatedManifest);
      setShouldRefreshGrid(true);
    }
  };

  const removeMultipleOrdersFromManifest = async ({
    uuid,
    orderUuids,
  }: {
    uuid: string;
    orderUuids: string[];
  }) => {
    const res = await removeOrdersFromManifest({
      variables: {
        removeOrdersFromManifestInput: {
          uuid,
          orderUuids,
        },
      },
    });
    const updatedManifest = res.data?.removeOrdersFromManifest.lineHaulManifest;
    if (!isNil(updatedManifest)) {
      setManifest(updatedManifest);
      setShouldRefreshGrid(true);
    }
  };

  const removeSingleOrderFromManifest = async ({
    uuid,
    orderUuid,
  }: {
    uuid: string;
    orderUuid: string;
  }) => {
    const res = await removeOrdersFromManifest({
      variables: {
        removeOrdersFromManifestInput: {
          uuid,
          orderUuids: [orderUuid],
        },
      },
    });
    const updatedManifest = res.data?.removeOrdersFromManifest.lineHaulManifest;
    if (!isNil(updatedManifest)) {
      setManifest(updatedManifest);
      setShouldRefreshGrid(true);
    }
  };

  const postponeSingleOrderOnManifest = async ({
    currentManifestUuid,
    orderUuid,
    newDepartDate,
  }: {
    currentManifestUuid: string;
    orderUuid: string;
    newDepartDate: Dayjs;
  }) => {
    const res = await postponeOrdersOnManifest({
      variables: {
        postponeOrdersOnManifestInput: {
          manifestUuid: currentManifestUuid,
          orderUuids: [orderUuid],
          newDepartDate,
        },
      },
    });
    const updatedManifest =
      res.data?.postponeOrdersOnManifest.oldLineHaulManifest;
    if (!isNil(updatedManifest)) {
      setManifest(updatedManifest);
      setShouldRefreshGrid(true);
    }
  };

  const postponeMultipleOrdersOnManifest = async ({
    currentManifestUuid,
    orderUuids,
    newDepartDate,
  }: {
    currentManifestUuid: string;
    orderUuids: string[];
    newDepartDate: Dayjs;
  }) => {
    const res = await postponeOrdersOnManifest({
      variables: {
        postponeOrdersOnManifestInput: {
          manifestUuid: currentManifestUuid,
          orderUuids,
          newDepartDate,
        },
      },
    });
    const updatedManifest =
      res.data?.postponeOrdersOnManifest.oldLineHaulManifest;
    if (!isNil(updatedManifest)) {
      setManifest(updatedManifest);
      setShouldRefreshGrid(true);
    }
  };

  const removeAllOrdersFromManifest = async ({
    manifest,
  }: {
    manifest: DetailedLineHaulManifestFragment;
  }) => {
    const orderUuids = manifest.orderSegments.map((o) => o.order.uuid);
    await removeMultipleOrdersFromManifest({ uuid: manifest.uuid, orderUuids });
  };

  const deleteSingleManifest = async ({
    manifestUuid,
    refreshGridAfter,
  }: {
    manifestUuid: string;
    refreshGridAfter: boolean;
  }) => {
    const deletedManifest = await removeManifest({
      variables: {
        uuid: manifestUuid,
      },
    });

    if (!isNil(deletedManifest.data?.removeLineHaulManifest)) {
      deleteManifest(manifestUuid);
      if (refreshGridAfter) setShouldRefreshGrid(true);
    }
  };

  const deleteMultipleManifests = async ({
    manifestUuids,
  }: {
    manifestUuids: string[];
  }) => {
    await Promise.all(
      manifestUuids.map(async (uuid) =>
        deleteSingleManifest({ manifestUuid: uuid, refreshGridAfter: false }),
      ),
    );
    setShouldRefreshGrid(true);
  };

  const addTruckload = async ({
    manifest,
    unmanifestedSegment,
    departDate,
    openNewLoad,
  }: {
    manifest: DetailedLineHaulManifestFragment | undefined;
    unmanifestedSegment: ShallowLineHaulSegmentFragment | undefined;
    departDate: Dayjs;
    openNewLoad: boolean;
  }) => {
    if (!isNil(unmanifestedSegment)) {
      // need to manifest the unmanifested segment
      const newManifest = await createNewManifestFromUnmanifestedSegment({
        input: {
          unmanifestedSegmentUuid: unmanifestedSegment.uuid,
          departDate,
        },
        unmanifestedSegmentUuid: unmanifestedSegment.uuid,
        openManifestAfter: false,
      });

      // then create another manifest
      if (!isNil(newManifest)) {
        await createNewManifest({
          input: {
            lineHaulManifestCreateInput: {
              departDate,
              startTerminalUuid: newManifest?.startTerminal.uuid,
              endTerminalUuid: newManifest.endTerminal.uuid,
              referenceNumber: '',
            },
          },
        });
      }
      if (!isNil(newManifest) && openNewLoad) {
        setOpenedManifest(newManifest);
      }
    } else if (!isNil(manifest)) {
      const newManifest = await createNewManifest({
        input: {
          lineHaulManifestCreateInput: {
            departDate,
            startTerminalUuid: manifest?.startTerminal.uuid,
            endTerminalUuid: manifest.endTerminal.uuid,
            referenceNumber: '',
          },
        },
      });
      if (!isNil(newManifest) && openNewLoad) {
        setOpenedManifest(newManifest);
      }
    }
  };

  return {
    addSingleOrderToManifest,
    addSingleOrderToMatchingManifest,
    addSingleOrderToOpenedManifest,
    addMultipleOrdersToOpenedManifest,
    addTruckload,
    arriveManifest,
    changePlanningDate,
    createNewManifest,
    createNewManifestFromUnmanifestedSegment,
    deleteMultipleManifests,
    deleteSingleManifest,
    departManifest,
    departManifests,
    departUnmanifestedSegment,
    fetchManifests,
    markManifestAsBeingPlanned,
    postponeMultipleOrdersOnManifest,
    postponeSingleOrderOnManifest,
    removeAllOrdersFromManifest,
    removeMultipleOrdersFromManifest,
    removeSingleOrderFromManifest,
    stopSelectingManifests,
    updateDriverOnManifest,
    updateVehicleOnManifest,
  };
};

export default useLineHaulDispatchActions;
