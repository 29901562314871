import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Checkbox,
  CircularProgress,
  Collapse,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { capitalCase } from 'change-case';
import currency from 'currency.js';
import dayjs from 'dayjs';
import { isNil, truncate } from 'lodash';
import React, { useEffect, useState } from 'react';
import { safeDivide, safeMultiply } from 'shared/math';
import { shallow } from 'zustand/shallow';
import StopTypeChip from '../../../../common/components/stop-type-chip';
import {
  DriverSettlementBillByUuidDocument,
  DriverSettlementBillsDocument,
  type StopDriverMapSummaryFragment,
  useDeleteStopDriverMapMutation,
  useInvoiceShipmentByStopUuidLazyQuery,
  useUpdateStopDriverMapMutation,
} from '../../../../generated/graphql';
import OpenOrderButton from '../../../ag-grid/open-order-button';
import InvoiceShipmentChargesTable from '../../../invoices/components/invoices/invoice-shipment-charges-table';
import useDriverSettlementStore from '../../driver-settlement-store';
import SettlementChargesTable from './settlement-charges-table';

type StopDriverMapsTableRowProps = {
  readonly isChecked?: boolean;
  readonly settlementUuid?: string;
  readonly settlementIsFinalized?: boolean;
  readonly refresh?: () => void;
  readonly enableCheckbox?: boolean;
  readonly stopDriverMap: StopDriverMapSummaryFragment;
  readonly showDriverType?: boolean;
  readonly showSettledStops?: boolean;
};

const StopDriverMapsTableRow = ({
  isChecked,
  settlementUuid,
  settlementIsFinalized,
  refresh,
  enableCheckbox,
  stopDriverMap,
  showDriverType = true,
  showSettledStops,
}: StopDriverMapsTableRowProps) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [selectStopUuid, deselectStopUuid] = useDriverSettlementStore(
    (state) => [state.selectStopUuid, state.deselectStopUuid],
    shallow,
  );
  const [getInvoiceShipmentByStopUuid, { data: stopData, loading }] =
    useInvoiceShipmentByStopUuidLazyQuery();
  const [updateStopDriverMap] = useUpdateStopDriverMapMutation();
  const [deleteStopDriverMap] = useDeleteStopDriverMapMutation();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isHovering, setIsHovering] = useState<boolean>(false);

  const stopDriverMapUuid = stopDriverMap.uuid;
  const onRowClick = (checked: boolean) => {
    if (isNil(stopDriverMapUuid)) return;
    if (checked) {
      selectStopUuid(stopDriverMapUuid);
    } else {
      deselectStopUuid(stopDriverMapUuid);
    }
  };

  const removeFromSettlement = async () => {
    if (isNil(stopDriverMap.stop.shipment)) {
      // deleting adjustment instead of soft removing it
      await deleteStopDriverMap({
        variables: {
          uuid: stopDriverMapUuid,
        },
        refetchQueries: [
          DriverSettlementBillsDocument,
          DriverSettlementBillByUuidDocument,
        ],
      });
    } else {
      await updateStopDriverMap({
        variables: {
          input: {
            stopDriverMapUpdateInput: {
              uuid: stopDriverMapUuid,
              driverSettlementBillUuid: null,
            },
          },
        },
        refetchQueries: [
          DriverSettlementBillsDocument,
          DriverSettlementBillByUuidDocument,
        ],
      });
    }
    if (!isNil(refresh)) {
      refresh();
    }
  };

  useEffect(() => {
    const stopUuid = stopDriverMap.stop?.uuid;
    if (isExpanded && isNil(stopData) && !isNil(stopUuid)) {
      getInvoiceShipmentByStopUuid({
        variables: {
          uuid: stopUuid,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded]);

  const orderUuid = stopDriverMap.stop?.shipment?.order?.uuid;
  const shipmentWithCharges = stopData?.stop.shipment;
  const stopDate =
    showSettledStops === true
      ? stopDriverMap.driverSettlementBill?.settlementDate
      : stopDriverMap.stop?.completedAt;

  return (
    <>
      <TableRow
        hover
        onMouseEnter={() => {
          setIsHovering(true);
        }}
        onMouseLeave={() => {
          setIsHovering(false);
        }}
        onClick={() => {
          if (enableCheckbox === true && !isNil(isChecked)) {
            onRowClick(!isChecked);
          }
        }}
      >
        {enableCheckbox === true && (
          <TableCell>
            <Checkbox checked={isChecked} />
          </TableCell>
        )}
        {!isNil(settlementUuid) && (
          <TableCell>
            {!isNil(stopDriverMap.stop?.shipment) && (
              <IconButton
                size="small"
                onClick={() => {
                  setIsExpanded(!isExpanded);
                }}
              >
                {isExpanded ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowRightIcon />
                )}
              </IconButton>
            )}
          </TableCell>
        )}
        <TableCell>
          {isNil(stopDate) ? '-' : dayjs(stopDate).format('MM/DD/YY hh:mm a')}
        </TableCell>
        {isNil(settlementUuid) && (
          <TableCell>
            {stopDriverMap.driver?.firstName ?? ''}{' '}
            {stopDriverMap.driver?.lastName ?? ''}
          </TableCell>
        )}
        {isNil(settlementUuid) && showDriverType && (
          <TableCell>
            {capitalCase(stopDriverMap.driver?.driverType ?? '')}
          </TableCell>
        )}
        <TableCell>
          {stopDriverMap.stop?.shipment?.order?.billingPartyContact.displayName}
        </TableCell>
        <TableCell>
          <StopTypeChip
            shipmentType={stopDriverMap.stop?.shipment?.shipmentType}
            // If no shipment on the stop, the stop driver map is an adjustment
            stopType={
              isNil(stopDriverMap.stop.shipment)
                ? 'Adjustment'
                : (stopDriverMap.stop?.stopType ?? undefined)
            }
          />
        </TableCell>
        <TableCell>
          {stopDriverMap.stop?.shipment?.order?.standardOrderFields
            .shipperBillOfLadingNumber ?? stopDriverMap.name}
        </TableCell>
        <TableCell>{stopDriverMap.stop?.shipment?.order?.name}</TableCell>
        {isNil(settlementUuid) && (
          <>
            <TableCell>
              {truncate(
                (stopDriverMap.stop?.shipment?.order?.refNumbers ?? []).join(
                  ', ',
                ),
                {
                  length: 15,
                },
              )}
            </TableCell>
            <TableCell>
              {truncate(stopDriverMap.stop?.address.name, {
                length: 10,
              })}
            </TableCell>
          </>
        )}
        {showSettledStops === true && (
          <TableCell>{stopDriverMap.driverSettlementBill?.name}</TableCell>
        )}
        {!isNil(stopDriverMap.driverSettlementTotalAmounts) && (
          <>
            <TableCell align="right">
              {currency(
                stopDriverMap.driverSettlementTotalAmounts
                  .totalChargesForSettlement ?? 0,
              ).format()}
            </TableCell>
            <TableCell align="right">
              {currency(
                stopDriverMap.driverSettlementTotalAmounts
                  .totalEligibleAmountForStop ?? 0,
              ).format()}
            </TableCell>
            <TableCell align="right">
              {currency(
                stopDriverMap.driverSettlementTotalAmounts.totalDriverPayout ??
                  0,
              ).format()}
            </TableCell>
            {showSettledStops === true && (
              <TableCell align="right">
                {safeMultiply(
                  safeDivide(
                    stopDriverMap.driverSettlementTotalAmounts
                      .totalDriverPayout ?? 0,
                    stopDriverMap.driverSettlementTotalAmounts
                      .totalEligibleAmountForStop ?? 0,
                  ),
                  100,
                )}
                %
              </TableCell>
            )}
          </>
        )}
        <TableCell align="right">
          <Fade in={isHovering}>
            <Stack
              direction="row"
              spacing={1}
              sx={{ float: 'right' }}
              alignItems="center"
            >
              {!isNil(orderUuid) && (
                <OpenOrderButton
                  openInCurrentPage
                  params={{ data: { uuid: orderUuid } }}
                  setSuccessMessage={() => null}
                  includeContextMenu={false}
                />
              )}
              {settlementIsFinalized === false && (
                <>
                  <IconButton
                    size="small"
                    id="more-menu-button"
                    aria-controls={menuAnchorEl ? 'more-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={menuAnchorEl ? 'true' : undefined}
                    onClick={(e) => {
                      setMenuAnchorEl(e.currentTarget);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="more-menu"
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    MenuListProps={{
                      'aria-labelledby': 'more-menu-button',
                    }}
                    onClose={() => {
                      setMenuAnchorEl(null);
                    }}
                  >
                    <MenuItem onClick={removeFromSettlement}>Remove</MenuItem>
                  </Menu>
                </>
              )}
            </Stack>
          </Fade>
        </TableCell>
      </TableRow>
      {!isNil(settlementUuid) && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse unmountOnExit in={isExpanded} timeout="auto">
              {loading && <CircularProgress size={20} />}
              {!isNil(shipmentWithCharges) && (
                <Stack sx={{ py: 1, px: 5 }} spacing={1}>
                  <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                    Charges
                  </Typography>
                  <InvoiceShipmentChargesTable shipment={shipmentWithCharges} />
                  <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                    Driver Settlement
                  </Typography>
                  <SettlementChargesTable
                    stopDriverMap={stopDriverMap}
                    shipment={shipmentWithCharges}
                  />
                </Stack>
              )}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default React.memo(StopDriverMapsTableRow);
