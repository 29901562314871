import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../../redux/store';
import { type FuelChargeError } from '../../orders/redux/drayage/order-form-types';

type FuelChargeErrorsStoreState = FuelChargeError;

const fuelChargesErrorsAdapter =
  createEntityAdapter<FuelChargeErrorsStoreState>({
    selectId: (fuelCharge) => fuelCharge.uuid,
  });

export const fuelChargesErrorsSlice = createSlice({
  name: 'fuelChargesErrors',
  initialState: fuelChargesErrorsAdapter.getInitialState(),
  reducers: {
    addFuelChargeErrors: fuelChargesErrorsAdapter.addOne,
    upsertFuelChargeErrors: fuelChargesErrorsAdapter.upsertOne,
    deleteFuelChargeErrors: fuelChargesErrorsAdapter.removeOne,
    updateFuelChargesErrors: fuelChargesErrorsAdapter.updateMany,
  },
});

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectById: selectFuelChargeErrorsById,
  // Pass in a selector that returns the posts slice of state
} = fuelChargesErrorsAdapter.getSelectors(
  (state: RootState) => state.orderFormFuelChargesErrors,
);

export const { upsertFuelChargeErrors } = fuelChargesErrorsSlice.actions;

export default fuelChargesErrorsSlice.reducer;
