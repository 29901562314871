import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import dayjs, { type Dayjs } from 'dayjs';
import { isNil } from 'lodash';
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';
import { getNoonOfDay } from 'shared/date';
import { shallow } from 'zustand/shallow';
import GeneralDatePicker from '../../../../../common/components/date-picker';
import {
  useUpdateRouteMutation,
  RouteDocument,
  DriversForHelpersDocument,
  DriversWithRoutesDocument,
  RoutesDocument,
} from '../../../../../generated/graphql';
import useDispatchStore from '../../../dispatch-store';

const RouteDateModal = ({
  open,
  setOpen,
  routeUuid,
  currentDate,
}: {
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
  readonly routeUuid: string;
  readonly currentDate: Date;
}) => {
  const [planningDate, deleteRoute] = useDispatchStore(
    (state) => [state.planningDate, state.deleteRoute],
    shallow,
  );
  const [updateRoute] = useUpdateRouteMutation({
    refetchQueries: [
      RouteDocument,
      DriversForHelpersDocument,
      DriversWithRoutesDocument,
      RoutesDocument,
    ],
  });
  const [date, setDate] = useState<Dayjs>(dayjs());

  useEffect(() => {
    setDate(dayjs(currentDate));
  }, [currentDate]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Stack sx={{ pb: 1, pr: 1 }}>
        <DialogTitle>Edit Route Date</DialogTitle>
        <DialogContent>
          <GeneralDatePicker date={date} setDate={setDate} text="" />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              await updateRoute({
                variables: {
                  updateRouteInput: {
                    routeUpdateInput: {
                      uuid: routeUuid,
                      date: getNoonOfDay(date.toDate()),
                    },
                  },
                },
              });
              if (
                !isNil(planningDate) &&
                !planningDate.isSame(dayjs(date), 'day')
              ) {
                deleteRoute(routeUuid);
              }
              setOpen(false);
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default RouteDateModal;
