import { Box } from '@mui/material';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import { type RefObject } from 'react';
import { type StopOnRouteFragment } from '../../../../generated/graphql';
import RouteStopCard from '../route-stop-card';

const RouteStopCardPopup = ({
  open,
  anchorRef,
  stop,
  idx,
}: {
  readonly open: boolean;
  readonly anchorRef: RefObject<HTMLElement | null>;
  readonly stop: StopOnRouteFragment;
  readonly idx: number;
}) => {
  return (
    <Popper
      transition
      open={open}
      anchorEl={anchorRef.current}
      placement="left"
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} timeout={100}>
          <Box sx={{ minWidth: '350px' }}>
            <RouteStopCard
              stop={stop}
              index={idx}
              locked={false}
              width={350}
              allowHover={false}
            />
          </Box>
        </Grow>
      )}
    </Popper>
  );
};

export default RouteStopCardPopup;
