import { Stack, Typography } from '@mui/material';
import pluralize from 'pluralize';
import { type FunctionComponent } from 'react';
import type { QueueAppointmentSchedulingProposalsSuccessOutput } from '../../../generated/graphql';

type ProposeTimesResultsProps =
  QueueAppointmentSchedulingProposalsSuccessOutput;

export const ProposeTimesResults: FunctionComponent<
  ProposeTimesResultsProps
> = ({ stopUuids, errorMessages }) => (
  <Stack p={2} gap={2}>
    <Typography fontWeight={700}>
      Sent a text or called for {stopUuids.length}{' '}
      {pluralize('stop', stopUuids.length)}
    </Typography>
    {errorMessages.length > 0 && (
      <Typography fontWeight={700} color="error">
        Encountered {errorMessages.length}{' '}
        {pluralize('error', errorMessages.length)}: {errorMessages.join(', ')}
      </Typography>
    )}
  </Stack>
);
