import { union, unionWith } from 'lodash';
import { type Dispatch, type SetStateAction, useMemo, useState } from 'react';
import { useHasUnsavedChanges } from '../../../../common/react-hooks/use-has-unsaved-changes';
import type {
  ContactCommunicationType,
  NotificationChannel,
} from '../../../../generated/graphql';
import { OrderEventNotificationType } from '../../../../generated/graphql';
import { allOrderEventTypesSelectedForChannel } from './util';

export type OrderEventNotificationsSubscription = {
  eventType: OrderEventNotificationType;
  channel: NotificationChannel;
};

export type OrderPartySubscriptionsForm = {
  subscriptions: OrderEventNotificationsSubscription[];
  addSubscription: (
    eventType: OrderEventNotificationType,
    channel: NotificationChannel,
  ) => void;
  removeSubscription: (
    eventType: OrderEventNotificationType,
    channel: NotificationChannel,
  ) => void;
  toggleSelectAll: (channel: NotificationChannel) => void;
  // Resets the value of subscriptions without triggering the unsaved changes flag.
  resetSubscriptions: (
    subscriptions: OrderEventNotificationsSubscription[],
  ) => void;
};

export type NotificationsForm = {
  general: {
    approveOrderNotification: boolean;
    setApproveOrderNotification: (value: boolean) => void;
    // Resets the value of approveOrderNotification without triggering the unsaved changes flag.
    resetApproveOrderNotification: (value: boolean) => void;
  };
  orderSubscribers: {
    emails: string[];
    addEmail: (email: string) => void;
    removeEmail: (email: string) => void;
    // Resets the value of emails without triggering the unsaved changes flag.
    resetEmails: (emails: string[]) => void;
    events: ContactCommunicationType[];
    addEvent: (event: ContactCommunicationType) => void;
    removeEvent: (event: ContactCommunicationType) => void;
    // Resets the value of events without triggering the unsaved changes flag.
    resetEvents: (events: ContactCommunicationType[]) => void;
  };
  orderContact: OrderPartySubscriptionsForm;
  pickupContact: OrderPartySubscriptionsForm;
  deliveryContact: OrderPartySubscriptionsForm;
  hasUnsavedChanges: boolean;
  resetHasUnsavedChanges: () => void;
};

const buildOrderPartySubscriptionsForm = (
  subscriptions: OrderEventNotificationsSubscription[],
  setSubscriptions: Dispatch<
    SetStateAction<OrderEventNotificationsSubscription[]>
  >,
  triggerHasUnsavedChanges: () => void,
): OrderPartySubscriptionsForm => ({
  subscriptions,
  addSubscription: (eventType, channel) => {
    setSubscriptions((prevSubscriptions) =>
      union(prevSubscriptions, [{ eventType, channel }]),
    );
    triggerHasUnsavedChanges();
  },
  removeSubscription: (eventType, channel) => {
    setSubscriptions((prevSubscriptions) =>
      prevSubscriptions.filter(
        (sub) => sub.eventType !== eventType || sub.channel !== channel,
      ),
    );
    triggerHasUnsavedChanges();
  },
  toggleSelectAll: (channel) => {
    setSubscriptions((prevSubscriptions) => {
      if (allOrderEventTypesSelectedForChannel(prevSubscriptions, channel)) {
        // Unselect all.
        return prevSubscriptions.filter((sub) => sub.channel !== channel);
      }
      // Select all.
      return unionWith(
        prevSubscriptions,
        Object.values(OrderEventNotificationType).map((eventType) => ({
          eventType,
          channel,
        })),
        (subA, subB) =>
          subA.eventType === subB.eventType && subA.channel === subB.channel,
      );
    });
    triggerHasUnsavedChanges();
  },
  resetSubscriptions: setSubscriptions,
});

export const useNotificationsForm = (): NotificationsForm => {
  const {
    hasUnsavedChanges,
    triggerHasUnsavedChanges,
    resetHasUnsavedChanges,
  } = useHasUnsavedChanges();
  // General
  const [approveOrderNotification, setApproveOrderNotification] =
    useState(false);
  // Order contact
  const [orderContactSubscriptions, setOrderContactSubscriptions] = useState<
    OrderEventNotificationsSubscription[]
  >([]);
  // Pickup contact
  const [pickupContactSubscriptions, setPickupContactSubscriptions] = useState<
    OrderEventNotificationsSubscription[]
  >([]);
  // Delivery contact
  const [deliveryContactSubscriptions, setDeliveryContactSubscriptions] =
    useState<OrderEventNotificationsSubscription[]>([]);
  // Order subscribers
  const [orderSubscriberEmails, setOrderSubscriberEmails] = useState<string[]>(
    [],
  );
  const [orderSubscriberEvents, setOrderSubscriberEvents] = useState<
    ContactCommunicationType[]
  >([]);

  return useMemo<NotificationsForm>(
    () => ({
      general: {
        approveOrderNotification,
        setApproveOrderNotification: (value: boolean) => {
          setApproveOrderNotification(value);
          triggerHasUnsavedChanges();
        },
        resetApproveOrderNotification: setApproveOrderNotification,
      },
      orderContact: buildOrderPartySubscriptionsForm(
        orderContactSubscriptions,
        setOrderContactSubscriptions,
        triggerHasUnsavedChanges,
      ),
      pickupContact: buildOrderPartySubscriptionsForm(
        pickupContactSubscriptions,
        setPickupContactSubscriptions,
        triggerHasUnsavedChanges,
      ),
      deliveryContact: buildOrderPartySubscriptionsForm(
        deliveryContactSubscriptions,
        setDeliveryContactSubscriptions,
        triggerHasUnsavedChanges,
      ),
      orderSubscribers: {
        emails: orderSubscriberEmails,
        addEmail: (email: string) => {
          setOrderSubscriberEmails([...orderSubscriberEmails, email]);
          triggerHasUnsavedChanges();
        },
        removeEmail: (email: string) => {
          setOrderSubscriberEmails(
            orderSubscriberEmails.filter((e) => e !== email),
          );
          triggerHasUnsavedChanges();
        },
        resetEmails: setOrderSubscriberEmails,
        events: orderSubscriberEvents,
        addEvent: (event: ContactCommunicationType) => {
          setOrderSubscriberEvents((prevEvents) => union(prevEvents, [event]));
          triggerHasUnsavedChanges();
        },
        removeEvent: (event: ContactCommunicationType) => {
          setOrderSubscriberEvents(
            orderSubscriberEvents.filter((e) => e !== event),
          );
          triggerHasUnsavedChanges();
        },
        resetEvents: setOrderSubscriberEvents,
      },
      hasUnsavedChanges,
      resetHasUnsavedChanges,
    }),
    [
      approveOrderNotification,
      orderContactSubscriptions,
      pickupContactSubscriptions,
      deliveryContactSubscriptions,
      orderSubscriberEmails,
      orderSubscriberEvents,
      hasUnsavedChanges,
      resetHasUnsavedChanges,
      triggerHasUnsavedChanges,
    ],
  );
};
