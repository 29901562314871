import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  Modal,
  Snackbar,
  Step,
  StepLabel,
  Stepper,
  Switch,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useState,
  memo,
  type FunctionComponent,
} from 'react';
import TabPanel from '../../../../common/components/tab-panel/tab-panel';
import useMe from '../../../../common/react-hooks/use-me';
import {
  type BatchAddShipmentsToInvoicesInput,
  type BatchAddShipmentsToInvoicesResultInvoiceFragment,
  useBatchAddShipmentsToInvoicesMultipleMutation,
  useOutstandingShipmentsUuidsLazyQuery,
  usePreviewBatchAddShipmentsToInvoicesMultipleLazyQuery,
} from '../../../../generated/graphql';
import styles from '../../styles';
import type { OrderToSendToBilling } from '../../types/types';
import { groupInvoicesByContactUuid } from '../../utils';
import BatchInvoicesPreview from './batch-invoices-preview';
import BatchInvoicesResult from './batch-invoices-result';

export type SelectedOrders = SelectedOrdersQueryAll | SelectedOrdersSelection;

// Select shipments by querying for all orders.
// Optional contactUuid and serviceUuid parameters can be passed in to PostFinalizedModal as props.
type SelectedOrdersQueryAll = {
  retrievalMethod: 'queryAll';
};

// Select shipments from specific orders.
type SelectedOrdersSelection = {
  retrievalMethod: 'selection';
  orders: OrderToSendToBilling[];
};

type PostFinalizedModalProps = {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly onClose: () => void;
  readonly onSubmit?: () => void;
  readonly serviceUuid?: string | undefined;
  readonly selectedOrderUuids: string[];
};

const PostFinalizedModalV2: FunctionComponent<PostFinalizedModalProps> = ({
  isOpen,
  setIsOpen,
  onClose,
  onSubmit,
  serviceUuid,
  selectedOrderUuids,
}) => {
  const { companyConfiguration } = useMe();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [total, setTotal] = useState<number | undefined>();
  const [addOrdersToOpenInvoices, setAddOrdersToOpenInvoices] =
    useState<boolean>(companyConfiguration?.addOrdersToOpenInvoices === true);
  const [showIsPostingSnackbar, setShowIsPostingSnackbar] =
    useState<boolean>(false);
  const [postFailedSnackbarMessage, setPostFailedSnackbarMessage] = useState<
    string | undefined
  >();
  const [isPosting, setIsPosting] = useState<boolean>(false);

  const [previewLoading, setPreviewLoading] = useState(false);
  const [
    getBatchAddShipmentsToInvoicesPreview,
    { data: batchAddShipmentsPreviewData },
  ] = usePreviewBatchAddShipmentsToInvoicesMultipleLazyQuery();
  const batchAddShipmentsToInvoicesPreview =
    batchAddShipmentsPreviewData?.previewBatchAddShipmentsToInvoicesMultiple;
  const [batchAddShipmentsToInvoices] =
    useBatchAddShipmentsToInvoicesMultipleMutation();
  const [
    getOutstandingShipmentUuids,
    { loading: outstandingShipmentUuidsLoading },
  ] = useOutstandingShipmentsUuidsLazyQuery();
  const [postedInvoicesByContactUuid, setPostedInvoicesByContactUuid] =
    useState<
      | Record<string, BatchAddShipmentsToInvoicesResultInvoiceFragment[]>
      | undefined
    >();

  const getFinalizedShipments = async () => {
    const res = await getOutstandingShipmentUuids({
      variables: {
        isFinalized: true,
        orderUuids: selectedOrderUuids,
        noMaximum: true,
      },
    });
    return res.data?.outstandingShipments.edges ?? [];
  };

  const getBatchAddShipmentsToInvoicesInputs = async () => {
    const shipmentUuidsByContactUuid = new Map<string, Set<string>>();
    const orderUuids = new Set<string>();
    const shipments = await getFinalizedShipments();
    for (const { node: shipment } of shipments) {
      const { order } = shipment;
      if (!isNil(order)) {
        orderUuids.add(order.uuid);
        const shipmentUuids =
          shipmentUuidsByContactUuid.get(order.billingPartyContact.uuid) ??
          new Set<string>();

        shipmentUuids.add(shipment.uuid);

        shipmentUuidsByContactUuid.set(
          order.billingPartyContact.uuid,
          shipmentUuids,
        );
      }
    }

    setTotal(orderUuids.size);

    const inputs: BatchAddShipmentsToInvoicesInput[] = [];
    for (const [uuid, shipmentUuids] of shipmentUuidsByContactUuid.entries()) {
      if (shipmentUuids.size > 0) {
        inputs.push({
          contactUuid: uuid,
          shipmentUuids: [...shipmentUuids],
          addToExistingInvoices: addOrdersToOpenInvoices,
        });
      }
    }
    return inputs;
  };

  const getPreview = async () => {
    setPreviewLoading(true);
    const shipmentsToAdd = await getBatchAddShipmentsToInvoicesInputs();
    await getBatchAddShipmentsToInvoicesPreview({
      variables: {
        batchAddShipmentsToInvoicesInputMultiple: {
          batchAddShipmentsToInvoicesInputs: shipmentsToAdd,
        },
      },
    });
    setPreviewLoading(false);
  };

  useEffect(() => {
    if (isOpen) {
      getPreview();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, addOrdersToOpenInvoices]);

  const postOrders = async () => {
    setIsPosting(true);
    setShowIsPostingSnackbar(true);
    const res = await batchAddShipmentsToInvoices({
      variables: {
        batchAddShipmentsToInvoicesInputMultiple: {
          batchAddShipmentsToInvoicesInputs:
            await getBatchAddShipmentsToInvoicesInputs(),
        },
      },
    });

    if (!isNil(res.errors) && !isNil(res.errors[0])) {
      setPostFailedSnackbarMessage(res.errors[0].message);
      setIsPosting(false);
    } else if (!isNil(res.data)) {
      setPostedInvoicesByContactUuid(
        groupInvoicesByContactUuid(
          res.data?.batchAddShipmentsToInvoicesMultiple ?? [],
        ),
      );
      setShowIsPostingSnackbar(false);
      setIsPosting(false);
      setIsOpen(true);
      setTabIndex(1);
    }
  };

  const handleClose = () => {
    if (tabIndex === 1 && !isNil(onSubmit)) {
      onSubmit();
    }
    setTabIndex(0);
    setIsOpen(false);
    setTotal(undefined);
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleClose}
    >
      <Box sx={[styles.modal, { width: '90vw' }]}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={6}>
            <Stepper activeStep={tabIndex}>
              <Step key={0}>
                <StepLabel>Review Invoices</StepLabel>
              </Step>
              <Step key={1}>
                <StepLabel>Result</StepLabel>
              </Step>
            </Stepper>
          </Grid>
          <Grid item xs={3}>
            {tabIndex === 1 && (
              <Button
                variant="contained"
                sx={{ float: 'right' }}
                onClick={handleClose}
              >
                Finish
              </Button>
            )}
          </Grid>
          <Grid item xs={12}>
            <TabPanel selectedValue={tabIndex} panelValue={0}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <FormGroup
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          checked={addOrdersToOpenInvoices}
                          onChange={(e) => {
                            setAddOrdersToOpenInvoices(e.target.checked);
                          }}
                        />
                      }
                      label={
                        <Typography sx={{ fontSize: '15px' }}>
                          Add to existing unposted invoices
                        </Typography>
                      }
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    startIcon={
                      isPosting ? <CircularProgress size={15} /> : null
                    }
                    disabled={
                      isNil(total) ||
                      total === 0 ||
                      isPosting ||
                      previewLoading ||
                      isNil(batchAddShipmentsToInvoicesPreview) ||
                      batchAddShipmentsToInvoicesPreview.length === 0
                    }
                    variant="contained"
                    sx={{ float: 'right' }}
                    onClick={postOrders}
                  >
                    Send Orders to Billing ({total ?? '-'})
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  {!isNil(batchAddShipmentsToInvoicesPreview) &&
                  batchAddShipmentsToInvoicesPreview.length > 0 ? (
                    <BatchInvoicesPreview
                      serviceUuid={serviceUuid}
                      batchAddShipmentsToInvoicesPreview={
                        batchAddShipmentsToInvoicesPreview
                      }
                    />
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        height: '50vh',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {outstandingShipmentUuidsLoading || previewLoading ? (
                        <CircularProgress />
                      ) : (
                        batchAddShipmentsToInvoicesPreview?.length === 0 && (
                          <Typography color="text.secondary">
                            No Finalized Orders
                          </Typography>
                        )
                      )}
                    </Box>
                  )}
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel selectedValue={tabIndex} panelValue={1}>
              <BatchInvoicesResult
                batchAddShipmentsToInvoicesPreview={
                  batchAddShipmentsToInvoicesPreview
                }
                postedInvoicesByContactUuid={postedInvoicesByContactUuid}
              />
            </TabPanel>
          </Grid>
        </Grid>
        <Snackbar
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={showIsPostingSnackbar}
          onClose={() => {
            setShowIsPostingSnackbar(false);
          }}
        >
          <Alert severity="info">
            Your orders are being posted to invoices. This can take up to two
            minutes for a large volume of orders
          </Alert>
        </Snackbar>
        <Snackbar
          autoHideDuration={5000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={!isNil(postFailedSnackbarMessage)}
          onClose={() => {
            setPostFailedSnackbarMessage(undefined);
          }}
        >
          <Alert severity="error">{postFailedSnackbarMessage}</Alert>
        </Snackbar>
      </Box>
    </Modal>
  );
};

export default memo(PostFinalizedModalV2);
