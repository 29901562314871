import { Button, Dialog, Stack, type SxProps, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import {
  FindDefaultTariffGroupForCompanyDocument,
  type FindTariffGroupsWithContactAndServicesCombinationOutput,
  TariffGroupScope,
  TariffGroupsDocument,
  TariffGroupType,
  TariffZoneLocationType,
  TariffZoneType,
  useCreateTariffGroupMutation,
  useFindTariffGroupsWithContactAndServiceCombinationLazyQuery,
} from '../../../../generated/graphql';
import WarningDuplicateContactServicesModal from '../warning-duplicate-contact-service-modal';
import useTariffGroupActions from './hooks/use-tariff-group-actions';
import { TariffModalMode } from './store/tariff-group-controller';
import useTariffGroupStore from './store/tariff-group-state-store';
import useTariffPageStore from './store/tariff-page-store';
import TariffModalContent from './tariff-modal-content/tariff-modal-content';

const styles = {
  modalInnerContainer: {
    bgcolor: 'background.paper',
    boxShadow: 24,
    color: 'black',
    gap: '20px',
    p: 3,
    width: '100%',
  } as SxProps,
  selectDropdownContainer: {
    alignItems: 'center',
    width: '50%',
    gap: '10px',
  },
  stepLabel: {
    marginTop: '5px',
  },
};

const CreateTariffGroupModal = ({
  isOpen,
  setIsOpen,
  isCompanyDefaultTariff,
  isTransferTariff,
}: {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly isCompanyDefaultTariff?: boolean;
  readonly isTransferTariff?: boolean;
}) => {
  const [setErrorMessage] = useTariffPageStore(
    (state) => [state.setErrorMessage],
    shallow,
  );
  const zoneGroupsEnabled = useFeatureFlag(FeatureFlag.FF_ZONE_GROUPS_ENABLED);

  const [warningModalContent, setWarningModalContent] = useState<
    FindTariffGroupsWithContactAndServicesCombinationOutput[] | undefined
  >(undefined);

  const [findMatchingTariffGroups] =
    useFindTariffGroupsWithContactAndServiceCombinationLazyQuery();

  const { refetchAndSetLocationZones } = useTariffGroupActions();
  const [
    setTariffGroupType,
    setTariffGroupScope,
    buildCreateTariffGroupInputFromState,
    resetStore,
    setModalMode,
    setUseZoneGroups,
  ] = useTariffGroupStore(
    (state) => [
      state.setTariffGroupType,
      state.setTariffGroupScope,
      state.buildCreateTariffGroupInputFromState,
      state.resetStore,
      state.setModalMode,
      state.setUseZoneGroups,
    ],
    shallow,
  );

  const handleClose = () => {
    resetStore();
    setIsOpen(false);
  };

  const [createTariffGroup] = useCreateTariffGroupMutation({
    onError: ({ message }) => {
      setErrorMessage(`Failed to create tariff: ${message}`);
    },
    onCompleted: () => {
      handleClose();
    },
  });

  useEffect(() => {
    if (isOpen) {
      setUseZoneGroups(zoneGroupsEnabled);

      if (!isNil(isCompanyDefaultTariff) && isCompanyDefaultTariff) {
        setTariffGroupScope(TariffGroupScope.Default);
      }
      if (isTransferTariff === true) {
        setTariffGroupType(TariffGroupType.Transfer);
      }
      setModalMode(TariffModalMode.CREATE);
      if (!zoneGroupsEnabled) {
        refetchAndSetLocationZones({
          variables: {
            tariffZoneType: TariffZoneType.Location,
            tariffZoneLocationType:
              isTransferTariff === true
                ? TariffZoneLocationType.Terminal
                : TariffZoneLocationType.Zipcode,
            contactUuid: null,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleSave = async () => {
    const createTariffGroupInput = buildCreateTariffGroupInputFromState();

    const existingTariffGroupsWithTheseCombinations =
      await findMatchingTariffGroups({
        variables: {
          mappingsList:
            createTariffGroupInput.contactToServicesMappings?.map((m) => {
              return {
                contactUuid: m.contactUuid,
                serviceUuids: m.serviceUuids,
              };
            }) ?? [],
        },
      });
    const data =
      existingTariffGroupsWithTheseCombinations.data
        ?.findTariffGroupsWithContactAndServiceCombination;

    const cleanedUpContent = data?.filter(
      (d) => d.matchingTariffGroups.length > 0,
    );

    // TODO(Dwayne): Disabled temporarily, seems to false positive sometimes
    if ((cleanedUpContent?.length ?? 0) > 100_000) {
      setWarningModalContent(data);
    } else {
      await createTariffGroup({
        variables: {
          createTariffGroupInput,
        },
        refetchQueries: [
          TariffGroupsDocument,
          FindDefaultTariffGroupForCompanyDocument,
        ],
      });
    }
  };

  return (
    <Dialog fullWidth maxWidth={false} open={isOpen} onClose={handleClose}>
      <Stack
        direction="column"
        justifyContent="center"
        sx={styles.modalInnerContainer}
      >
        <Typography variant="h6">
          {' '}
          {!isNil(isCompanyDefaultTariff) && isCompanyDefaultTariff
            ? 'Set up default tariff'
            : 'New tariff'}{' '}
        </Typography>
        <TariffModalContent isCompanyDefaultTariff={isCompanyDefaultTariff} />
        <Stack
          direction="row"
          width="100%"
          // alignSelf={'flex-end'}
          justifyContent="flex-end"
          gap="10px"
        >
          <Button
            sx={{ pl: 4, pr: 4 }}
            variant="contained"
            onClick={handleSave}
          >
            Save
          </Button>
        </Stack>

        {/* <Typography sx={{ color: 'red' }}>{errorMessage}</Typography> */}
      </Stack>
      <WarningDuplicateContactServicesModal
        warningModalContent={warningModalContent}
        closeWarningModal={() => {
          setWarningModalContent(undefined);
        }}
      />
    </Dialog>
  );
};

export default CreateTariffGroupModal;
