// eslint-disable-next-line
import { Grid, InputLabel, TextField } from '@mui/material';
import { isNil } from 'lodash';
import { type Dispatch, type SetStateAction } from 'react';
import { Controller, type SubmitHandler } from 'react-hook-form';
import {
  BusinessDivisionsDocument,
  useCreateBusinessDivisionMutation,
} from '../../../../generated/graphql';
import PalletModal from '../../../../pallet-ui/modal/pallet-modal-old';
import { type BusinessDivisionFormValues } from './forms/types';
import useCreateBusinessDivisionForm from './forms/use-create-business-division-form';

type CreateBusinessDivisionModalProps = {
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
  readonly setSuccessSnackbarVisible: Dispatch<SetStateAction<boolean>>;
  readonly setErrorSnackbarVisible: Dispatch<SetStateAction<boolean>>;
};

const CreateBusinessDivisionModal = ({
  open,
  setOpen,
  setSuccessSnackbarVisible,
  setErrorSnackbarVisible,
}: CreateBusinessDivisionModalProps) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useCreateBusinessDivisionForm();

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const [createBusinessDivision, { loading: createBusinessDivisionLoading }] =
    useCreateBusinessDivisionMutation({
      refetchQueries: [BusinessDivisionsDocument],
    });

  const onSubmit: SubmitHandler<BusinessDivisionFormValues> = async (data) => {
    const { name } = data;
    try {
      const response = await createBusinessDivision({
        variables: {
          createBusinessDivisionInput: {
            name,
          },
        },
      });
      if (response?.data?.createBusinessDivision?.success === true) {
        setSuccessSnackbarVisible(true);
      } else {
        setErrorSnackbarVisible(true);
      }
      handleClose();
    } catch {
      setErrorSnackbarVisible(true);
    }
  };

  return (
    <PalletModal
      open={open}
      title="Create business division"
      actions={{
        bottomRight: [
          {
            children: 'Save',
            onClick: handleSubmit(onSubmit),
            disabled: createBusinessDivisionLoading,
          },
        ],
      }}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                <InputLabel>Name</InputLabel>
                <TextField
                  required
                  size="small"
                  value={value}
                  error={!isNil(errors.name)}
                  helperText={errors?.name?.message}
                  sx={{ width: '100%' }}
                  onChange={onChange}
                />
              </>
            )}
          />
        </Grid>
      </Grid>
    </PalletModal>
  );
};

export default CreateBusinessDivisionModal;
