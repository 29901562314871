import { Button, Box, CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useGetInvoiceCsvsLazyQuery } from '../../../../generated/graphql';
import ExportToQuickbooksModal from './export-to-quickbooks-modal';

const ExportToQuickbooks = ({
  selectedInvoiceUuids,
}: {
  readonly selectedInvoiceUuids: string[];
}) => {
  const [getInvoiceCsvs, { data, loading }] = useGetInvoiceCsvsLazyQuery();
  const [isExportedModalOpen, setIsExportedModalOpen] = useState(false);

  const containsMissingMappings =
    (data?.getInvoiceCsvs?.missingCustomerMappings.length ?? 0) > 0 ||
    (data?.getInvoiceCsvs?.missingFreightChargeMappings.length ?? 0) > 0 ||
    (data?.getInvoiceCsvs?.missingFuelChargeMappings.length ?? 0) > 0 ||
    (data?.getInvoiceCsvs?.missingAccessorialMappings.length ?? 0) > 0;
  useEffect(() => {
    getInvoiceCsvs({
      variables: { getInvoiceCsvsInput: { uuids: selectedInvoiceUuids } },
    });
  }, [selectedInvoiceUuids]);

  return (
    <>
      {isExportedModalOpen && (
        <ExportToQuickbooksModal
          open={isExportedModalOpen}
          setOpen={setIsExportedModalOpen}
          missingCustomerMappings={
            data?.getInvoiceCsvs?.missingCustomerMappings ?? []
          }
          missingFreightChargeMappings={
            data?.getInvoiceCsvs?.missingFreightChargeMappings ?? []
          }
          missingFuelChargeMappings={
            data?.getInvoiceCsvs?.missingFuelChargeMappings ?? []
          }
          missingAccessorialMappings={
            data?.getInvoiceCsvs?.missingAccessorialMappings ?? []
          }
        />
      )}
      <CSVLink
        data={data?.getInvoiceCsvs?.csvItems ?? []}
        filename={`invoices-${dayjs().format('MM-DD')}.csv`}
      >
        <Button
          disabled={loading || isNil(data) || selectedInvoiceUuids.length === 0}
          color="success"
          variant="contained"
          onClick={() => {
            if (containsMissingMappings) {
              setIsExportedModalOpen(true);
            }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              alignItems: 'center',
            }}
          >
            {loading && <CircularProgress size={15} />}
            Export to Quickbooks{' '}
            {selectedInvoiceUuids.length > 0
              ? `(${selectedInvoiceUuids.length})`
              : ``}
          </Box>
        </Button>
      </CSVLink>
    </>
  );
};

export default ExportToQuickbooks;
