import { joiResolver } from '@hookform/resolvers/joi';
import joi from 'joi';
import { useForm } from 'react-hook-form';
import { type Option } from '../../../common/filters/types';

export type WarehouseLocationFormValues = {
  warehouse: Option;
  location1?: string;
  location2?: string;
  location3?: string;
};

const useWarehouseLocationForm = () => {
  const schema = joi.object({
    warehouse: joi
      .object({
        value: joi.string().required().messages({
          'any.required': 'Warehouse is required',
          'object.base': 'Warehouse is required',
          'string.empty': 'Warehouse is required',
        }),
        label: joi.string().required().messages({
          'any.required': 'Warehouse is required',
          'object.base': 'Warehouse is required',
          'string.empty': 'Warehouse is required',
        }),
      })
      .required()
      .messages({
        'any.required': 'Warehouse is required',
        'object.base': 'Warehouse is required',
      })
      .unknown(),
    location1: joi.string().optional().allow(''),
    location2: joi.string().optional().allow(''),
    location3: joi.string().optional().allow(''),
  });
  return useForm<WarehouseLocationFormValues>({
    resolver: joiResolver(schema),
  });
};

export default useWarehouseLocationForm;
