import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { Controller, type SubmitHandler, useForm } from 'react-hook-form';
import ErrorBanner from '../../../../../common/components/error-banner';
import {
  type GeneralLedgerCodeFragment,
  GeneralLedgerCodesDocument,
  GeneralLedgerConfigurationDocument,
  useCreateGeneralLedgerCodeMutation,
  useUpdateGeneralLedgerCodeMutation,
} from '../../../../../generated/graphql';
import CreateOrEdit from '../../../enums/create-or-edit';

type AddGeneralLedgerCodeFormData = {
  code: string;
  description: string;
};

const AddOrEditGeneralLedgerCodeModal = ({
  open,
  setOpen,
  configurationId,
  createOrEdit,
  glCode,
}: {
  readonly open: boolean;
  readonly setOpen: (open: boolean) => void;
  readonly configurationId: string;
  readonly createOrEdit: CreateOrEdit;
  readonly glCode: GeneralLedgerCodeFragment | undefined;
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset: resetForm,
    setValue,
  } = useForm({
    defaultValues: {
      code: '',
      description: '',
    },
  });
  const [errorMessage, setErrorMessage] = useState('');
  const handleClose = () => {
    resetForm();
    setOpen(false);
    setErrorMessage('');
  };

  useEffect(() => {
    if (!isNil(glCode) && createOrEdit === CreateOrEdit.Edit && open) {
      setValue('code', glCode.code);
      setValue('description', glCode.description);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrEdit, glCode, open]);

  const [createGeneralLedgerCode] = useCreateGeneralLedgerCodeMutation({
    refetchQueries: [
      GeneralLedgerConfigurationDocument,
      GeneralLedgerCodesDocument,
    ],
    onError: ({ message }) => {
      setErrorMessage(message);
    },
    onCompleted: ({
      createGeneralLedgerCode: createGeneralLedgerCodeResult,
    }) => {
      if (createGeneralLedgerCodeResult.__typename === 'MutationErrorOutput') {
        setErrorMessage(createGeneralLedgerCodeResult.message);
        return;
      }
      handleClose();
    },
  });
  const [updateGeneralLedgerCode] = useUpdateGeneralLedgerCodeMutation({
    refetchQueries: [
      GeneralLedgerConfigurationDocument,
      GeneralLedgerCodesDocument,
    ],
    onError: ({ message }) => {
      setErrorMessage(message);
    },
    onCompleted: ({
      updateGeneralLedgerCode: updateGeneralLedgerCodeResult,
    }) => {
      if (updateGeneralLedgerCodeResult.__typename === 'MutationErrorOutput') {
        setErrorMessage(updateGeneralLedgerCodeResult.message);
        return;
      }
      handleClose();
    },
  });

  const validateNotEmpty = (value: string) => {
    return value.trim() !== '' || 'This field cannot be empty';
  };

  const onSubmit: SubmitHandler<AddGeneralLedgerCodeFormData> = async (
    data: AddGeneralLedgerCodeFormData,
  ) => {
    if (createOrEdit === CreateOrEdit.Create) {
      await createGeneralLedgerCode({
        variables: {
          input: {
            configurationId,
            code: data.code,
            description: data.description,
          },
        },
      });
    } else if (!isNil(glCode)) {
      await updateGeneralLedgerCode({
        variables: {
          input: {
            id: glCode.id,
            code: data.code,
            description: data.description,
          },
        },
      });
    }
  };

  return (
    <Dialog fullWidth open={open} maxWidth="xs" onClose={handleClose}>
      <DialogTitle>
        {createOrEdit === CreateOrEdit.Create ? 'Add code' : 'Edit code'}
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Stack noValidate component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Controller<AddGeneralLedgerCodeFormData>
            name="code"
            control={control}
            rules={{ required: 'Code is required', validate: validateNotEmpty }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                required
                label="Code"
                variant="outlined"
                margin="normal"
                error={Boolean(errors.code)}
                helperText={errors.code?.message}
              />
            )}
          />
          <Controller<AddGeneralLedgerCodeFormData>
            name="description"
            control={control}
            rules={{
              required: 'Description is required',
              validate: validateNotEmpty,
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                required
                label="Description"
                variant="outlined"
                margin="normal"
                error={Boolean(errors.description)}
                helperText={errors.description?.message}
              />
            )}
          />
          {!isEmpty(errorMessage) && (
            <ErrorBanner errorMessage={errorMessage} />
          )}
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default AddOrEditGeneralLedgerCodeModal;
