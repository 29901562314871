import { centimeters, inches } from '@buge/ts-units/length';
import { kilograms } from '@buge/ts-units/mass';
// eslint-disable-next-line no-restricted-imports
import { Grid, TextField, styled } from '@mui/material';
import { isNaN, isNil } from 'lodash';
import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { pounds } from 'shared/units/rates';
import { useDebounce } from 'use-debounce';
import DimensionsInput from '../../../../../common/components/measurements/dimensions-input';
import LengthUnitSelector from '../../../../../common/components/measurements/measurement-unit-selector';
import WeightUnitSelector from '../../../../../common/components/measurements/weight-unit-selector';
import {
  StorageOrderDocument,
  type StorageOrderStorageUnitFragment,
  useUpdateStorageUnitMutation,
} from '../../../../../generated/graphql';
import useStorageUnitSpecsForm from '../../../forms/storage-units/use-storage-unit-specs-form';

const WeightInput = styled(TextField)`
  width: 100px;
`;

type StorageUnitSpecsProps = {
  readonly storageUnit: StorageOrderStorageUnitFragment;
};

const StorageUnitSpecs = ({ storageUnit }: StorageUnitSpecsProps) => {
  const { control, watch, setValue, getValues } =
    useStorageUnitSpecsForm(storageUnit);
  const [updateStorageUnit] = useUpdateStorageUnitMutation({
    refetchQueries: [StorageOrderDocument],
  });

  const useKilograms = watch('useKilograms');
  const weight = watch('weight');

  const [debouncedFormValues] = useDebounce(watch(), 500);

  useEffect(() => {
    const useCentimeters = getValues('useCentimeters');
    const length = useCentimeters
      ? centimeters(debouncedFormValues.length ?? 0)
      : inches(debouncedFormValues.length ?? 0);
    const width = useCentimeters
      ? centimeters(debouncedFormValues.width ?? 0)
      : inches(debouncedFormValues.width ?? 0);
    const height = useCentimeters
      ? centimeters(debouncedFormValues.height ?? 0)
      : inches(debouncedFormValues.height ?? 0);

    updateStorageUnit({
      variables: {
        updateStorageUnitInput: {
          uuid: storageUnit.uuid,
          weight: getValues('useKilograms')
            ? kilograms(Number.parseFloat(debouncedFormValues.weight ?? ''))
            : pounds(Number.parseFloat(debouncedFormValues.weight ?? '')),
          length,
          width,
          height,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedFormValues.weight,
    debouncedFormValues.length,
    debouncedFormValues.width,
    debouncedFormValues.height,
  ]);

  useEffect(() => {
    // change from pounds to kilograms or vice versa
    if (isNil(weight)) return;
    const weightValue = Number.parseFloat(weight);
    if (isNaN(weightValue)) return;
    if (useKilograms) {
      setValue('weight', pounds(weightValue).in(kilograms).amount.toString());
    } else {
      setValue('weight', kilograms(weightValue).in(pounds).amount.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useKilograms]);

  return (
    <>
      <Grid item xs={4} flexDirection="row" alignItems="center" display="flex">
        <Controller
          name="weight"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <WeightInput
                label="Weight"
                size="small"
                value={value ?? ''}
                type="number"
                defaultValue=""
                onChange={onChange}
              />
            );
          }}
        />
        <Controller
          name="useKilograms"
          control={control}
          render={({ field: { value, onChange } }) => (
            <WeightUnitSelector useKilograms={value} onChange={onChange} />
          )}
        />
      </Grid>
      <Grid item xs={8} display="flex" flexDirection="row" alignItems="center">
        <DimensionsInput
          showLabel
          useCentimeters={watch('useCentimeters')}
          length={watch('length')}
          width={watch('width')}
          height={watch('height')}
          onChangeLength={(length) => {
            setValue('length', length);
          }}
          onChangeWidth={(width) => {
            setValue('width', width);
          }}
          onChangeHeight={(height) => {
            setValue('height', height);
          }}
        />
        <Controller
          name="useCentimeters"
          control={control}
          render={({ field: { value, onChange } }) => (
            <LengthUnitSelector useCentimeters={value} onChange={onChange} />
          )}
        />
      </Grid>
    </>
  );
};

export default StorageUnitSpecs;
