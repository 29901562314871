import { Dialog } from '@mui/material';
import { type Dispatch, memo, type SetStateAction } from 'react';
import CreateMultipleRoutes from './create-multiple-routes';

const CreateMultipleRoutesModal = ({
  open,
  setOpen,
  stopUuidsToAssign,
  onCreate,
}: {
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
  readonly stopUuidsToAssign?: string[];
  readonly onCreate?: () => void;
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <CreateMultipleRoutes
        open={open}
        stopUuidsToAssign={stopUuidsToAssign}
        onCreate={() => {
          onCreate?.();
          setOpen(false);
        }}
      />
    </Dialog>
  );
};

export default memo(CreateMultipleRoutesModal);
