import {
  Accordion,
  AccordionDetails,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';
import type { FunctionComponent } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import { PermissionResource } from '../../../../generated/graphql';
import { AccordionSummary } from '../../../../pallet-ui/accordion/accordion-summary';
import { GridDivider } from './grid-divider';
import type { NotificationsForm } from './use-notifications-form';

type GeneralNotificationsProps = {
  readonly form: NotificationsForm['general'];
};

export const GeneralNotifications: FunctionComponent<
  GeneralNotificationsProps
> = ({ form: { approveOrderNotification, setApproveOrderNotification } }) => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteContacts } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Contacts,
  );

  return (
    <Accordion>
      <AccordionSummary
        heading="General"
        subheading="Notifications you see while logged in and using Pallet"
      />
      <AccordionDetails>
        <Box
          display="grid"
          gridTemplateColumns="1fr minmax(130px, max-content)"
          alignItems="center"
          gap={1}
        >
          <Typography color="gray" fontSize="14px">
            Notify me when...
          </Typography>
          <Box /* Future: select all checkbox here */ />
          <GridDivider span={2} />
          <Typography fontSize="14px">
            New orders via EDI, API and document scanning are ready for approval
          </Typography>
          <Box>
            <FormControlLabel
              control={<Checkbox />}
              label="Pallet website"
              sx={{ marginRight: 0 }}
              checked={approveOrderNotification ?? false}
              disabled={!canWriteContacts}
              onChange={(_e, checked) => {
                setApproveOrderNotification(checked);
              }}
            />
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
