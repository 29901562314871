import {
  Button,
  Fade,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { type Dayjs } from 'dayjs';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import TimePickerComponent from '../../../../common/components/time-picker-component';
import {
  transformAddressToFullAddressStringWithoutCity,
  transformTimeToTimeString,
} from '../../../../common/utils/prettyPrintUtils';
import { type FormattedStop, type Slot } from '../utils/utils';
import GenerateStopInformationFullDetails from './generate-stop-information-full-details';

const ScheduleAppointmentRow = ({
  slot,
  updateSlot,
  autogenerateAppointment,
}: {
  readonly slot: Slot;
  readonly updateSlot: ({
    stopId,
    appointmentTime,
    endAppointmentTime,
    overrideServiceTimeInMinutes,
  }: {
    stopId: string;
    appointmentTime?: string | null | undefined;
    endAppointmentTime?: string | null | undefined;
    overrideServiceTimeInMinutes?: number;
  }) => void;
  readonly autogenerateAppointment: () => void;
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const stop: FormattedStop | undefined = slot;

  if (isNil(stop)) {
    return (
      <Typography>
        There was an error in displaying the grouped stop.
      </Typography>
    );
  }

  let formattedEta =
    !isNil(stop) && !isNil(stop.etaArrivalTime) && !isNil(stop.etaFinishTime)
      ? `${transformTimeToTimeString(
          stop.etaArrivalTime,
        )} - ${transformTimeToTimeString(stop.etaFinishTime)}`
      : '-';
  if (
    !isNil(stop) &&
    !isNil(stop.etaArrivalTime) &&
    (isNil(stop.etaFinishTime) ||
      transformTimeToTimeString(stop.etaArrivalTime) ===
        transformTimeToTimeString(stop.etaFinishTime))
  ) {
    formattedEta = transformTimeToTimeString(stop.etaArrivalTime);
  }

  return (
    <TableRow
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <TableCell>
        <Tooltip title={<GenerateStopInformationFullDetails stop={stop} />}>
          <Typography variant="body2">
            {stop.clientReferenceNumber ?? stop.orderName}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell>{stop.location.city ?? '-'}</TableCell>
      <TableCell>
        <TimePickerComponent
          hideClearable
          appointmentTime={stop.appointmentWindow}
          updateAppointmentTime={(time: Dayjs | undefined | null) => {
            if (!isNil(stop)) {
              updateSlot({
                stopId: stop.id,
                appointmentTime: time === null ? null : time?.toISOString(),
              });
            }
          }}
        />
      </TableCell>
      <TableCell>
        <TimePickerComponent
          hideClearable
          appointmentTime={stop.endAppointmentTime}
          updateAppointmentTime={(time: Dayjs | undefined | null) => {
            if (!isNil(stop)) {
              updateSlot({
                stopId: stop.id,
                endAppointmentTime: time === null ? null : time?.toISOString(),
              });
            }
          }}
        />
      </TableCell>
      <TableCell>{formattedEta}</TableCell>
      <TableCell>
        {transformAddressToFullAddressStringWithoutCity({
          ...stop.location,
          city: stop.location.city ?? null,
          line1: stop.location.line1 ?? null,
          zip: stop.location.zip ?? null,
        })}
      </TableCell>
      <TableCell>{sentenceCase(stop.serviceName ?? 'None')}</TableCell>
      <TableCell>
        <TextField
          value={stop.overrideServiceTimeInMinutes ?? 0}
          size="small"
          onChange={(e) => {
            const parsed = Number.parseFloat(e.target.value);
            if (!Number.isNaN(parsed) && !isNil(stop)) {
              updateSlot({
                stopId: stop.id,
                overrideServiceTimeInMinutes: parsed,
              });
            }
          }}
        />
      </TableCell>
      <TableCell>
        <Fade in={isHovering}>
          <Button
            sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}
            variant="contained"
            onClick={autogenerateAppointment}
          >
            Auto-schedule
          </Button>
        </Fade>
      </TableCell>
    </TableRow>
  );
};

export default ScheduleAppointmentRow;
