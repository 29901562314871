import { Clear, Add } from '@mui/icons-material';
import {
  Button,
  FormControl,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';

const ReferenceNumberLabels = ({
  refNumberLabels,
  handleChange,
  handleAddLabel,
  handleDeleteLabel,
}: {
  readonly refNumberLabels: string[];
  readonly handleChange: (index: number, value: string) => void;
  readonly handleAddLabel: () => void;
  readonly handleDeleteLabel: (index: number) => void;
}) => {
  return (
    <Grid item xs={12} md={3} alignItems="center">
      <Grid container spacing={1} alignItems="center">
        <Grid item xs>
          <Typography sx={{ mb: '20px', color: 'black', fontWeight: 'bold' }}>
            Label Reference Numbers
          </Typography>
        </Grid>
      </Grid>
      <FormControl sx={{ width: '100%' }}>
        {refNumberLabels.map((label, index) => (
          <TextField
            // eslint-disable-next-line react/no-array-index-key
            key={`ref-number-label-${index}`}
            size="small"
            label={`Reference Number Label ${index + 1}`}
            value={label}
            sx={{ mb: '10px', minWidth: '400px' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      handleDeleteLabel(index);
                    }}
                  >
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              handleChange(index, e.target.value);
            }}
          />
        ))}
        <Button
          sx={{ justifyContent: 'flex-start' }}
          startIcon={<Add />}
          onClick={handleAddLabel}
        >
          Add Label
        </Button>
      </FormControl>
    </Grid>
  );
};

export default ReferenceNumberLabels;
