export enum DispatchMultiplayerAction {
  EDITING = 'EDITING',
  REORDERING = 'REORDERING',
  LEAVE = 'LEAVE',
}

export type DispatchUserLocationPayload = {
  connectionId: string; // used to tell difference between yourself and other connections
  name: string;
  email: string;
  action?: DispatchMultiplayerAction;
  routeUuid?: string | undefined;
  stopUuid?: string | undefined;
  ts: Date;
};
