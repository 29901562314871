import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import { type FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { getPermissionsFlags } from 'shared/roles';
import * as yup from 'yup';
import TagsDropdownField from '../../../common/components/tags-dropdown-field';
import useDrivers from '../../../common/react-hooks/use-drivers';
import useTerminals from '../../../common/react-hooks/use-terminals';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import { type Option, Size } from '../../../common/types';
import { isNilOrEmptyString } from '../../../common/utils/utils';
import {
  PermissionResource,
  RecurringOrderTemplatesDocument,
  RecurringRunsDocument,
  type RecurringRunTemplateFragment,
  useCreateRecurringRunMutation,
  useRecurringOrderTemplatesQuery,
  useUpdateRecurringRunMutation,
  useVehicleTypesMinimalQuery,
} from '../../../generated/graphql';
import AutocompleteFuzzy from '../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import RecurringOrderTable from './recurring-order-template-table-component';

const formSchema = yup.object({
  name: yup.string().required(),
  orderUuids: yup.array().of(yup.string().required()).required(),
  terminalUuid: yup.string().optional().nullable(),
  driverUuid: yup.string().optional().nullable(),
  vehicleTypeUuid: yup.string().optional().nullable(),
  tagUuids: yup.array().of(yup.string().required()).required(),
});

type RecurringRunCreateFormValues = yup.InferType<typeof formSchema>;

type RecurringRunModalProps = {
  readonly open: boolean;
  readonly onClose: () => void;
  // Pass in a selectedRecurringRun to edit it. Otherwise this modal will create a new run.
  readonly selectedRecurringRun: RecurringRunTemplateFragment | undefined;
};

export const RecurringRunModal: FunctionComponent<RecurringRunModalProps> = ({
  open,
  onClose,
  selectedRecurringRun,
}) => {
  const { drivers, getDriverName } = useDrivers();
  const { data: vehicleTypesData, loading: vehicleTypesLoading } =
    useVehicleTypesMinimalQuery({
      fetchPolicy: 'cache-and-network',
    });
  const { userPermissions } = useUserRoles();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const {
    data: recurringOrderTemplatesData,
    loading: recurringOrderTemplatesLoading,
  } = useRecurringOrderTemplatesQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [createRecurringRun, { loading: createRecurringRunLoading }] =
    useCreateRecurringRunMutation({
      refetchQueries: [RecurringRunsDocument],
      onCompleted: () => {
        onClose();
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  const [updateRecurringRun, { loading: updateRecurringRunLoading }] =
    useUpdateRecurringRunMutation({
      refetchQueries: [RecurringRunsDocument, RecurringOrderTemplatesDocument],
      onCompleted: () => {
        onClose();
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  const { terminals, terminalsLoading, terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const form = useForm({
    resolver: yupResolver(formSchema),
    mode: 'all',
  });

  const { terminalUuid, driverUuid } = form.watch();

  const terminalOptions = useMemo<Option[]>(
    () =>
      terminals.map((terminal) => ({
        value: terminal.uuid,
        label: `${terminal.name} (${terminal.code})`,
      })),
    [terminals],
  );
  const driverOptions = useMemo<Option[]>(() => {
    const filteredDrivers =
      drivers.filter(
        // Include a driver if either the driver isn't scoped to a specific terminal, or there's no terminal selected,
        // or a terminal is set to the same terminal as the driver, or the driver is already selected.
        (driver) =>
          isNil(driver.terminal) ||
          isNil(terminalUuid) ||
          driver.terminal.uuid === terminalUuid ||
          (!isNil(driverUuid) && driverUuid === driver.uuid),
      ) ?? [];
    return filteredDrivers.map((driver) => ({
      value: driver.uuid,
      label: `${
        isNilOrEmptyString(driver.driverReferenceNumber)
          ? ''
          : `${driver.driverReferenceNumber} - `
      }${getDriverName(driver.uuid)}`,
    }));
  }, [drivers, getDriverName, terminalUuid, driverUuid]);
  const vehicleTypeOptions = useMemo<Option[]>(
    () =>
      vehicleTypesData?.vehicleTypes.map(({ uuid, name }) => ({
        value: uuid,
        label: name,
      })) ?? [],
    [vehicleTypesData],
  );

  const { errors } = form.formState;

  useEffect(() => {
    setErrorMessage(null);
    if (isNil(selectedRecurringRun)) {
      form.reset({
        name: '',
        orderUuids: [],
        terminalUuid: null,
        driverUuid: null,
        vehicleTypeUuid: null,
      });
    } else {
      form.reset({
        name: selectedRecurringRun.name,
        orderUuids: selectedRecurringRun.recurringOrderTemplates.map(
          ({ uuid }) => uuid,
        ),
        terminalUuid: selectedRecurringRun.terminal?.uuid ?? null,
        driverUuid: selectedRecurringRun.driver?.uuid ?? null,
        vehicleTypeUuid: selectedRecurringRun.vehicleType?.uuid ?? null,
        tagUuids: selectedRecurringRun.tags?.map((tag) => tag.uuid),
      });
    }
  }, [selectedRecurringRun, open, form]);

  const { canWrite: canWriteCompanyRecurringOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyRecurringOrders,
  );

  const onSubmit = (values: RecurringRunCreateFormValues) => {
    setErrorMessage(null);
    if (isNil(selectedRecurringRun)) {
      createRecurringRun({
        variables: {
          input: {
            name: values.name,
            orderUuids: values.orderUuids,
            terminalUuid: values.terminalUuid,
            driverUuid: values.driverUuid,
            vehicleTypeUuid: values.vehicleTypeUuid,
            tagUuids: values.tagUuids,
          },
        },
      });
    } else {
      updateRecurringRun({
        variables: {
          input: {
            uuid: selectedRecurringRun.uuid,
            name: values.name,
            orderUuids: values.orderUuids,
            terminalUuid: values.terminalUuid,
            driverUuid: values.driverUuid,
            vehicleTypeUuid: values.vehicleTypeUuid,
            tagUuids: values.tagUuids,
          },
        },
      });
    }
  };

  const recurringOrderTemplates =
    recurringOrderTemplatesData?.recurringOrderTemplates?.map((template) => {
      // If a run is selected, disable rows that are under a different run.
      // If no run is selected, disable rows that are under any run.
      const disabled =
        (!isNil(selectedRecurringRun) &&
          !isNil(template.run) &&
          selectedRecurringRun.uuid !== template.run.uuid) ||
        (isNil(selectedRecurringRun) && !isNil(template.run));

      return {
        ...template,
        disabled,
      };
    });

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          height: 'max-content',
          maxHeight: '90vh',
          minHeight: 350,
          minWidth: 820,
          maxWidth: '90vw',
        },
      }}
      onClose={onClose}
    >
      <DialogTitle>
        {isNil(selectedRecurringRun) ? 'Create' : 'Edit'} a route template
      </DialogTitle>
      {!isNil(errorMessage) && (
        <Alert severity="error" sx={{ mb: 1, mx: 2 }}>
          {errorMessage}
        </Alert>
      )}
      <FormProvider {...form}>
        <DialogContent>
          <Stack direction="row" gap={2} sx={{ mt: 1, mb: 2 }}>
            <Controller
              name="name"
              control={form.control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  required
                  name="name"
                  label="Route template name"
                  value={value}
                  error={!isNil(errors.name)}
                  size="small"
                  sx={{
                    flexGrow: 1,
                  }}
                  onChange={onChange}
                />
              )}
            />
            {terminalsEnabled && (
              <Controller
                name="terminalUuid"
                control={form.control}
                render={({ field: { onChange, value } }) => (
                  <AutocompleteFuzzy
                    size="small"
                    value={
                      isNil(value)
                        ? null
                        : (terminalOptions.find(
                            (terminal) => terminal.value === value,
                          ) ?? null)
                    }
                    options={terminalOptions}
                    matchSortOptions={{ keys: ['label'] }}
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => (
                      <li {...props} key={option.value}>
                        <Typography sx={{ fontSize: '14px' }}>
                          {option.label}
                        </Typography>
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} size="small" label="Terminal" />
                    )}
                    sx={{
                      flexBasis: '220px',
                    }}
                    loading={terminalsLoading}
                    onChange={(_event, option) => {
                      onChange(option?.value ?? null);
                    }}
                  />
                )}
              />
            )}
            <Controller
              name="driverUuid"
              control={form.control}
              render={({ field: { onChange, value } }) => (
                <AutocompleteFuzzy
                  size="small"
                  value={
                    isNil(value)
                      ? null
                      : (driverOptions.find(
                          (driver) => driver.value === value,
                        ) ?? null)
                  }
                  options={driverOptions}
                  matchSortOptions={{ keys: ['label'] }}
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option) => (
                    <li {...props} key={option.value}>
                      <Typography sx={{ fontSize: '14px' }}>
                        {option.label}
                      </Typography>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} size="small" label="Driver" />
                  )}
                  sx={{
                    flexBasis: '220px',
                  }}
                  onChange={(_event, option) => {
                    onChange(option?.value ?? null);
                  }}
                />
              )}
            />
            <Controller
              name="vehicleTypeUuid"
              control={form.control}
              render={({ field: { onChange, value } }) => (
                <AutocompleteFuzzy
                  size="small"
                  value={
                    isNil(value)
                      ? null
                      : (vehicleTypeOptions.find((vt) => vt.value === value) ??
                        null)
                  }
                  options={vehicleTypeOptions}
                  matchSortOptions={{ keys: ['label'] }}
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option) => (
                    <li {...props} key={option.value}>
                      <Typography sx={{ fontSize: '14px' }}>
                        {option.label}
                      </Typography>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} size="small" label="Vehicle type" />
                  )}
                  sx={{
                    flexBasis: '220px',
                  }}
                  loading={vehicleTypesLoading}
                  onChange={(_event, option) => {
                    onChange(option?.value ?? null);
                  }}
                />
              )}
            />
          </Stack>
          <Controller
            name="tagUuids"
            control={form.control}
            render={({ field: { onChange, value } }) => (
              <TagsDropdownField
                size={Size.sm}
                disabled={false}
                tagUuids={value}
                onChange={onChange}
              />
            )}
          />
          {recurringOrderTemplatesLoading ? (
            <Stack alignItems="center" my={3}>
              <CircularProgress />
            </Stack>
          ) : !isNil(recurringOrderTemplates) &&
            recurringOrderTemplates.length > 0 ? (
            <>
              <Typography>
                {recurringOrderTemplates.some((tmpl) => !tmpl.disabled)
                  ? 'Select recurring orders to include under this route template.'
                  : 'There are no recurring orders that can be selected for this route template.'}
              </Typography>
              <RecurringOrderTable
                hideEdit
                selectedTemplateUuids={form.watch('orderUuids') ?? []}
                setSelectedTemplateUuids={(uuids) => {
                  form.setValue('orderUuids', uuids, {
                    shouldDirty: true,
                    shouldTouch: true,
                  });
                }}
                recurringOrderTemplatesData={recurringOrderTemplates}
                loadingCreateOrders={false}
                canWriteCompanyRecurringOrders={canWriteCompanyRecurringOrders}
                hideCheckBox={false}
              />
            </>
          ) : (
            <Typography mt={3}>
              There are no recurring orders. Please create a recurring order to
              add it to this route template.
            </Typography>
          )}
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            variant="outlined"
            disabled={createRecurringRunLoading || updateRecurringRunLoading}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={createRecurringRunLoading || updateRecurringRunLoading}
            onClick={form.handleSubmit(onSubmit)}
          >
            {isNil(selectedRecurringRun) ? 'Create' : 'Save'}
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};
