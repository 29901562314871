import { type ApolloQueryResult } from '@apollo/client/core/types';
import {
  Alert,
  Checkbox,
  MenuItem,
  Select,
  Snackbar,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isEmpty, isNil, values } from 'lodash';
import { useState } from 'react';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  AccessLevel,
  type Exact,
  type FindRoleInput,
  type FindRolePermissionFragment,
  type PermissionResource,
  type RoleQuery,
  useCreatePermissionMutation,
  useDeletePermissionMutation,
  useUpdatePermissionAccessLevelMutation,
} from '../../../generated/graphql';
import { getPermissionLabel } from '../utils';

type PermissionRowProps = {
  readonly roleUuid: string;
  readonly permissionResource: PermissionResource;
  readonly permission?: FindRolePermissionFragment | undefined;
  readonly refetchRole: (
    variables?: Partial<Exact<{ findRoleInput?: FindRoleInput | undefined }>>,
  ) => Promise<ApolloQueryResult<RoleQuery>>;
  readonly disabled: boolean;
  readonly disabledReason: string | null;
};

const PermissionRow = ({
  roleUuid,
  permissionResource,
  permission,
  refetchRole,
  disabled,
  disabledReason,
}: PermissionRowProps) => {
  const { refetchUserRoles } = useUserRoles();

  const [successSnackbarVisible, setSuccessSnackbarVisible] = useState(false);
  const [errorSnackbarVisible, setErrorSnackbarVisible] = useState(false);

  const [
    deletePermissionSuccessSnackbarVisible,
    setDeletePermissionSuccessSnackbarVisible,
  ] = useState(false);
  const [
    deletePermissionErrorSnackbarVisible,
    setDeletePermissionErrorSnackbarVisible,
  ] = useState(false);

  const [createPermission, { loading: createPermissionLoading }] =
    useCreatePermissionMutation();

  const [deletePermission, { loading: deletePermissionLoading }] =
    useDeletePermissionMutation();

  const [
    updatePermissionAccessLevel,
    { loading: updatePermissionAccessLevelLoading },
  ] = useUpdatePermissionAccessLevelMutation();

  const handleUpdatePermissionAccessLevel = async (
    accessLevel: AccessLevel,
  ) => {
    const uuid = permission?.uuid;
    if (isNil(uuid)) {
      return;
    }
    try {
      const resp = await updatePermissionAccessLevel({
        variables: {
          updatePermissionAccessLevelInput: {
            uuid,
            accessLevel,
          },
        },
      });
      if (resp.data?.updatePermissionAccessLevel.success === true) {
        await refetchRole({ findRoleInput: { uuid: roleUuid } });
        refetchUserRoles();
        setSuccessSnackbarVisible(true);
      } else {
        setErrorSnackbarVisible(true);
      }
    } catch {
      setErrorSnackbarVisible(true);
    }
  };

  const handlePermissionChange = async (checked: boolean) => {
    if (checked) {
      const resp = await createPermission({
        variables: {
          createPermissionInput: {
            roleUuid,
            permissionResource,
            accessLevel: AccessLevel.Read,
          },
        },
      });
      if (resp.data?.createPermission.success === true) {
        await refetchRole({ findRoleInput: { uuid: roleUuid } });
        refetchUserRoles();
        setSuccessSnackbarVisible(true);
      } else {
        setErrorSnackbarVisible(true);
      }
    } else {
      const uuid = permission?.uuid;
      if (isNil(uuid) || isEmpty(uuid)) {
        return;
      }
      const resp = await deletePermission({
        variables: {
          deletePermissionInput: {
            uuid,
          },
        },
      });
      if (resp.data?.deletePermission.success === true) {
        await refetchRole({ findRoleInput: { uuid: roleUuid } });
        refetchUserRoles();
        setDeletePermissionSuccessSnackbarVisible(true);
      } else {
        setDeletePermissionErrorSnackbarVisible(true);
      }
    }
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={successSnackbarVisible}
      >
        <Alert
          severity="success"
          onClose={() => {
            setSuccessSnackbarVisible(false);
          }}
        >
          Successfully saved permission
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={errorSnackbarVisible}
      >
        <Alert
          severity="error"
          onClose={() => {
            setErrorSnackbarVisible(false);
          }}
        >
          Error saving permission
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={deletePermissionSuccessSnackbarVisible}
      >
        <Alert
          severity="success"
          onClose={() => {
            setDeletePermissionSuccessSnackbarVisible(false);
          }}
        >
          Successfully removed permission
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={deletePermissionErrorSnackbarVisible}
      >
        <Alert
          severity="error"
          onClose={() => {
            setDeletePermissionErrorSnackbarVisible(false);
          }}
        >
          Error removing permission
        </Alert>
      </Snackbar>
      <TableRow sx={{ height: '50%' }}>
        <TableCell>{getPermissionLabel(permissionResource)}</TableCell>
        <TableCell>
          <Tooltip title={disabledReason}>
            {/* <span> is needed for the tooltip when the checkbox is disabled */}
            <span>
              <Checkbox
                size="small"
                checked={!isNil(permission)}
                disabled={
                  createPermissionLoading || deletePermissionLoading || disabled
                }
                onChange={async (e) => handlePermissionChange(e.target.checked)}
              />
            </span>
          </Tooltip>
        </TableCell>
        {!isNil(permission) && (
          <TableCell>
            <Tooltip title={disabledReason}>
              <span>
                <Select<AccessLevel>
                  variant="standard"
                  size="small"
                  value={permission?.accessLevel}
                  disabled={updatePermissionAccessLevelLoading || disabled}
                  onChange={async (e) =>
                    handleUpdatePermissionAccessLevel(
                      e.target.value as AccessLevel,
                    )
                  }
                >
                  {values(AccessLevel).map((accessLevel) => (
                    <MenuItem key={accessLevel} value={accessLevel}>
                      {sentenceCase(accessLevel)}
                    </MenuItem>
                  ))}
                </Select>
              </span>
            </Tooltip>
          </TableCell>
        )}
      </TableRow>
    </>
  );
};

export default PermissionRow;
