import { isEmpty } from 'lodash';
import { matchSorter } from 'match-sorter';
import { useCallback, useState, useEffect } from 'react';
import { type AccordionState } from '../../../../common/components/accordion-dropdown';
import useOrderTableFieldHeaders from '../../../../common/react-hooks/orders/use-order-table-field-headers';
import {
  type OrderTableField,
  type OrderTableFieldHeaderFragment,
} from '../../../../generated/graphql';
import { getOrderTableFieldHeader } from '../../../orders/components/utils';
import { type GroupedOrderTableFields } from '../../../orders/constants';
import { useFilterEligibleOrderTableFields } from './use-filter-eligible-order-table-fields';

/**
 * Filters the grouped table fields based on the search term.
 * This function uses the match-sorter library to perform fuzzy search.
 * We match on the user-friendly header, not the field itself.
 */
const filterGroupedTableFields = (
  groups: GroupedOrderTableFields[],
  searchTerm: string,
  orderTableFieldHeaders: OrderTableFieldHeaderFragment[],
): GroupedOrderTableFields[] => {
  return groups.map((group) => {
    const fieldToHeader = group.fields.map((field) => ({
      field,
      // This must match the keys in the matchSorter call below.
      header: getOrderTableFieldHeader({
        orderTableField: field,
        orderTableFieldHeaders,
      }),
    }));

    const matchedFields = matchSorter(fieldToHeader, searchTerm, {
      keys: ['header'],
    }).map(({ field }) => field);

    return {
      ...group,
      fields: matchedFields,
    };
  });
};

/**
 * Hook that manages the state of the order table fields accordion, including filtering and selection.
 */
const useOrderTableFieldsAccordionState = (
  groupedOrderTableFields: GroupedOrderTableFields[],
  selectedFields?: OrderTableField[],
) => {
  const { orderTableFieldHeaders } = useOrderTableFieldHeaders();
  const filterEligibleOrderTableFields = useFilterEligibleOrderTableFields();
  // We maintain the filter state in the hook so it's not an external dependency that we'd want a useEffect for.
  const [accordionSearchText, setAccordionSearchText] = useState<string>('');

  const [accordionState, setAccordionState] = useState<
    AccordionState<OrderTableField>
  >(
    groupedOrderTableFields
      .map((group) => ({
        ...group,
        isOpen: false,
        fields: group.fields
          .filter(filterEligibleOrderTableFields)
          .map((field) => ({
            value: field,
            label: getOrderTableFieldHeader({
              orderTableField: field,
              orderTableFieldHeaders,
            }),
            isSelected: selectedFields?.includes(field) ?? false,
          })),
      }))
      .filter((group) => !isEmpty(group.fields)),
  );

  useEffect(() => {
    setAccordionState((prev) =>
      prev.map((group) => ({
        ...group,
        fields: group.fields
          .filter((field) => filterEligibleOrderTableFields(field.value))
          .map((field) => ({
            value: field.value,
            label: getOrderTableFieldHeader({
              orderTableField: field.value,
              orderTableFieldHeaders,
            }),
            isSelected: selectedFields?.includes(field.value) ?? false,
          })),
      })),
    );
  }, [selectedFields, filterEligibleOrderTableFields, orderTableFieldHeaders]);

  const setFilter = useCallback(
    (newFilter: string) => {
      setAccordionSearchText(newFilter);
      const updatedGroups = filterGroupedTableFields(
        groupedOrderTableFields,
        newFilter,
        orderTableFieldHeaders,
      );
      setAccordionState(
        updatedGroups.map((group) => ({
          ...group,
          fields: group.fields
            .filter(filterEligibleOrderTableFields)
            .map((field) => ({
              value: field,
              label: getOrderTableFieldHeader({
                orderTableField: field,
                orderTableFieldHeaders,
              }),
              isSelected: selectedFields?.includes(field) ?? false,
            })),
          isOpen: newFilter.length > 0,
        })),
      );
    },
    [
      setAccordionSearchText,
      filterEligibleOrderTableFields,
      groupedOrderTableFields,
      orderTableFieldHeaders,
      selectedFields,
    ],
  );

  return { accordionState, setAccordionState, accordionSearchText, setFilter };
};

export { useOrderTableFieldsAccordionState };
