import { Alert, Snackbar } from '@mui/material';
import { type ColDef } from 'ag-grid-community';
import { type AgGridReact } from 'ag-grid-react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isNil } from 'lodash';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { useEffect, useMemo, useRef, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { FeatureFlag } from '../../common/feature-flags';
import useFeatureFlag from '../../common/react-hooks/use-feature-flag';
import { useTableFields } from '../../common/react-hooks/use-table-fields';
import LineHaulDispatchTableAgGrid from '../ag-grid/line-haul/line-haul-dispatch-table-ag-grid';
import { ExcludeFromHiding } from '../orders/components/enums/order-filters';
import LineHaulDispatchManifestsSection from './components/line-haul-dispatch-manifests-section';
import { DemoLineHaulView } from './demo/demo-line-haul-view';
import COLUMNS from './grid-columns';
import useLineHaulDispatchStore from './store/line-haul-dispatch-store';
import { type SegmentedLineHaulOrder } from './types';
import { canAddOrderToManifest } from './utils';

dayjs.extend(utc);
dayjs.extend(timezone);

const LineHaulView = () => {
  const { lineHaulTableFields } = useTableFields();
  const gridRef = useRef<AgGridReact<SegmentedLineHaulOrder>>(null);
  const [selectedUuids, setSelectedUuids] = useState<string[]>([]);
  const [isHeaderCheckboxSelected, setIsHeaderCheckboxSelected] =
    useState(false);
  const ffDemoSterlingLineHaul = useFeatureFlag(
    FeatureFlag.FF_DEMO_STERLING_LINE_HAUL,
  );

  const [
    currentManifestTab,
    openedManifest,
    snackbarSuccessMessage,
    setSnackbarSuccessMessage,
  ] = useLineHaulDispatchStore(
    (state) => [
      state.currentManifestTab,
      state.openedManifest,
      state.snackbarSuccessMessage,
      state.setSnackbarSuccessMessage,
    ],
    shallow,
  );

  useEffect(() => {
    gridRef.current?.columnApi?.setColumnVisible(
      ExcludeFromHiding.EMPTY_SPACE,
      canAddOrderToManifest(openedManifest, currentManifestTab),
    );
  }, [currentManifestTab, openedManifest]);

  const columnsOrderedByConfig: Array<ColDef<SegmentedLineHaulOrder>> =
    useMemo(() => {
      let orderedColumns: Array<ColDef<SegmentedLineHaulOrder>>;

      orderedColumns = isNil(lineHaulTableFields)
        ? COLUMNS
        : (lineHaulTableFields
            .map((header) => COLUMNS.find((column) => column.field === header))
            .filter((column) => !isNil(column)) as Array<
            ColDef<SegmentedLineHaulOrder>
          >);

      // If we add filters, remember to add them here
      return [
        ...orderedColumns,
        {
          headerCheckboxSelection: true,
          checkboxSelection: true,
          maxWidth: 50,
          field: ExcludeFromHiding.EMPTY_SPACE,
          headerName: '',
          pinned: 'left' as const,
        },
      ];
    }, [lineHaulTableFields]);

  if (ffDemoSterlingLineHaul) {
    return <DemoLineHaulView />;
  }

  return (
    <>
      <Splitter
        stateStorage="local"
        stateKey="line-haul-splitter-state"
        style={{ height: '100%' }}
      >
        <SplitterPanel style={{ height: '100%' }}>
          <LineHaulDispatchTableAgGrid
            tableHeaders={lineHaulTableFields}
            columnDefinitions={columnsOrderedByConfig}
            selectedUuids={selectedUuids}
            setSelectedUuids={setSelectedUuids}
            isHeaderCheckboxSelected={isHeaderCheckboxSelected}
            setIsHeaderCheckboxSelected={setIsHeaderCheckboxSelected}
            gridRef={gridRef}
          />
        </SplitterPanel>
        <SplitterPanel>
          <LineHaulDispatchManifestsSection />
        </SplitterPanel>
      </Splitter>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
        open={!isNil(snackbarSuccessMessage)}
        onClose={() => {
          setSnackbarSuccessMessage(undefined);
        }}
      >
        <Alert>{snackbarSuccessMessage}</Alert>
      </Snackbar>
    </>
  );
};

export default LineHaulView;
