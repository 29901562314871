/* eslint-disable react/jsx-props-no-spreading */
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Typography,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { isNil } from 'lodash';
import type React from 'react';
import { useCallback, useState, useMemo } from 'react';
import { type FileRejection } from 'react-dropzone';
import ConfirmationMessage from '../../../common/components/upload/confirmation-message';
import Dropzone from '../../../common/components/upload/dropzone';
import {
  ImageGrid,
  type ImageInformation,
} from '../../../common/components/upload/image-grid';
import { grabFileType } from '../../../common/utils/file';
import {
  useCompanySchemasQuery,
  useContactsSimpleQuery,
} from '../../../generated/graphql';
import useSubmitDocumentModal from '../hooks/useSubmitDocumentModal';

const FILE_TYPE = new Set(['application/pdf']);

export const styles = {
  outerBox: {
    borderRadius: '10px',
    marginTop: '25px',
    padding: '40px',
    backgroundColor: 'white',
  },
} as Record<string, React.CSSProperties>;
const UploadContent = ({
  moveToConfirmationMessage,
  uploadSignedPODs,
}: {
  readonly moveToConfirmationMessage: (numberOfFiles: number) => void;
  readonly uploadSignedPODs: boolean;
}) => {
  const [images, setImages] = useState<ImageInformation[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [shouldShowLoader, setShouldShowLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { submitDocuments, loading: submitIsLoading } =
    useSubmitDocumentModal();

  const { data: contactsData } = useContactsSimpleQuery({
    fetchPolicy: 'cache-first',
  });
  const { data: companySchemaData } = useCompanySchemasQuery({
    fetchPolicy: 'cache-first',
  });
  const contactsWithSchemas = useMemo(() => {
    if (!isNil(contactsData) && !isNil(companySchemaData)) {
      return contactsData.contacts.filter((contact) =>
        companySchemaData.companySchemas.some(
          (schema) => schema.contactUuid === contact.uuid,
        ),
      );
    }
    return [];
  }, [contactsData, companySchemaData]);

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (fileRejections.length > 0) {
        const newErrorMessage =
          'Please upload a PDF file. You uploaded a file type we do not accept.';
        setErrorMessage(newErrorMessage);
        return [];
      }
      let fileFinishedNumber = 0;
      setShouldShowLoader(true);
      acceptedFiles.map((file) => {
        setErrorMessage('');
        if (!FILE_TYPE.has(file.type)) {
          const fileType = grabFileType(file.name);
          const newErrorMessage =
            fileType !== undefined && fileType.length > 0
              ? `Please upload a PDF file. We do not accept ${fileType} files`
              : `Please upload a PDF file. You uploaded a file type we do not accept.`;
          setErrorMessage(newErrorMessage);
          setShouldShowLoader(false);
          return [];
        }

        const reader = new FileReader();
        reader.addEventListener('load', (e) => {
          fileFinishedNumber += 1;
          if (fileFinishedNumber === acceptedFiles.length) {
            setShouldShowLoader(false);
          }
          setImages((prevState) => [
            ...prevState,
            {
              src: e?.target?.result ?? '',
              name: file.name,
              type: file.type,
            },
          ]);
        });
        reader.readAsDataURL(file);
        setFiles((prevState) => [...prevState, file]);
        return file;
      });
      setShouldShowLoader(false);
      return [];
    },
    [],
  );

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Typography>
        Contacts with supported documents:{' '}
        {contactsWithSchemas.map((contact) => contact.displayName).join(', ')}
      </Typography>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
        }}
      >
        <Dropzone errorMessage={errorMessage} onDrop={onDrop} />
        <ImageGrid
          shouldShowLoader={shouldShowLoader}
          images={images}
          setImages={setImages}
          files={files}
          setFiles={setFiles}
        />
      </Box>
      <Box sx={{ justifyContent: 'center', display: 'flex' }}>
        {images.length > 0 && (
          <Button
            startIcon={submitIsLoading && <CircularProgress size={15} />}
            variant="contained"
            className="button"
            disabled={shouldShowLoader || submitIsLoading}
            style={{ marginTop: '20px' }}
            onClick={async () => {
              try {
                await submitDocuments(
                  files,
                  moveToConfirmationMessage,
                  uploadSignedPODs,
                );
              } catch (error) {
                captureException(error);
                setErrorMessage(
                  'Error in uploading documents. Please contact support.',
                );
              }
            }}
          >
            Submit Orders
          </Button>
        )}
      </Box>
      {errorMessage !== undefined && errorMessage.length > 0 && (
        <Typography
          sx={{
            color: 'red',
            textAlign: 'center',
            fontSize: '18px',
            mt: '10px',
          }}
        >
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
};

const UploadModal = ({
  open,
  setOpen,
  uploadSignedPODs,
}: {
  readonly open: boolean;
  readonly setOpen: (isOpen: boolean) => void;
  readonly uploadSignedPODs?: boolean | undefined;
}) => {
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
  const [numberOfFiles, setNumberOfFiles] = useState(0);
  const moveToConfirmationMessage = (updatedNumberOfFiles: number) => {
    setNumberOfFiles(updatedNumberOfFiles);
    setShowConfirmationMessage(true);
  };
  const closeModal = () => {
    setOpen(false);
    setShowConfirmationMessage(false);
  };
  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="md"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styles.outerBox}>
        <IconButton
          sx={{
            position: 'absolute',
            top: '1%',
            right: '1%',
            cursor: 'pointer',
          }}
          onClick={closeModal}
        >
          <CloseIcon />
        </IconButton>
        {showConfirmationMessage ? (
          <ConfirmationMessage
            numberOfFiles={numberOfFiles}
            closeModal={closeModal}
          />
        ) : (
          <UploadContent
            moveToConfirmationMessage={moveToConfirmationMessage}
            uploadSignedPODs={uploadSignedPODs ?? false}
          />
        )}
      </Box>
    </Dialog>
  );
};

export default UploadModal;
