import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { isNil } from 'lodash';
import {
  type Dispatch,
  type SetStateAction,
  useContext,
  useState,
} from 'react';
import useDocuments from '../../../common/react-hooks/use-documents';
import {
  type DocumentType,
  useUpdateDocumentsMutation,
} from '../../../generated/graphql';
import EndOfDayContext from '../end-of-day-context';
import { type FetchCompanyDocumentsParams } from '../types/company-documents';

export const DOCUMENT_PAGE_SIZE = 100;

const BulkChangeDocumentType = ({
  open,
  setOpen,
  fetchCompanyDocuments,
}: {
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
  readonly fetchCompanyDocuments: (
    params: FetchCompanyDocumentsParams,
  ) => Promise<void>;
}) => {
  const [bulkDocumentType, setBulkDocumentType] = useState<DocumentType>();
  const { selectedDocumentUuids } = useContext(EndOfDayContext);
  const [updateDocuments] = useUpdateDocumentsMutation();
  const { documentTypesForSelection, getDocumentTypeCopy, loading } =
    useDocuments();

  const onConfirm = async () => {
    await updateDocuments({
      variables: {
        updateDocumentsInput: {
          documentUpdateInputs: selectedDocumentUuids.map((uuid) => ({
            uuid,
            type: bulkDocumentType,
          })),
        },
      },
    });
    fetchCompanyDocuments({ first: DOCUMENT_PAGE_SIZE });
    setOpen(false);
  };

  if (loading) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          p: '20px',
          textAlign: 'center',
        }}
      >
        <DialogTitle>
          Change document type for {selectedDocumentUuids.length} documents
        </DialogTitle>
        <DialogContent>
          <FormControl
            sx={{ fontSize: '12px', ml: 'auto', mr: '10px', mt: '5px' }}
          >
            <InputLabel shrink id="bulk-document-select-label">
              Document type
            </InputLabel>
            <Select
              notched
              size="small"
              label="Document type"
              labelId="bulk-document-select-label"
              value={bulkDocumentType}
              sx={{ minWidth: '250px' }}
              onChange={(e) => {
                setBulkDocumentType(e.target.value as DocumentType);
              }}
            >
              {documentTypesForSelection.map((documentType) => (
                <MenuItem key={documentType} value={documentType}>
                  {getDocumentTypeCopy({
                    documentType,
                  })}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={isNil(bulkDocumentType)}
            variant="contained"
            onClick={onConfirm}
          >
            Confirm
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default BulkChangeDocumentType;
