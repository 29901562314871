import {
  ArrowUpwardRounded,
  ArrowDownwardRounded,
  Close,
} from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Stack,
  styled,
  TextField,
} from '@mui/material';
import { type ColumnState } from 'ag-grid-community';
import { isNil } from 'lodash';
import useOrderTableFieldHeaders from '../../../../../common/react-hooks/orders/use-order-table-field-headers';
import {
  type OrderTableField,
  SortDirection,
} from '../../../../../generated/graphql';
import AutocompleteFuzzy, {
  type AutocompleteFuzzyProps,
} from '../../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import theme from '../../../../../theme';
import {
  getSortableGroupedTableFieldOptions,
  type MuiGroupedOption,
} from './utils';

export const SORT_ROW_HEIGHT = '35px';

const StyledToggleButton = styled(Button)`
  min-width: unset;
  border: none;
  padding: 0;
  :hover {
    background-color: unset;
  }
`;

const SelectedContainer = styled('div')<{ selected: boolean }>`
  transition: all 0.2s;
  display: flex;
  height: 100%;
  aspect-ratio: 1;
  align-items: center;
  justify-content: center;
  padding: 4px;
  color: ${theme.palette.text.secondary};
  ${({ selected }) =>
    selected &&
    `
    background: #ffffff;
    box-shadow:
    0px 0px 0px 0.5px rgba(0, 0, 0, 0.08),
    0px 2px 6px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    color: ${theme.palette.primary.main};
  `}
`;

type ConditionalSelectedContainerProps = {
  readonly selected: boolean;
  readonly direction: SortDirection;
};
const ConditionalSelectedContainer = ({
  selected,
  direction,
}: ConditionalSelectedContainerProps) => {
  const arrowIcon =
    direction === SortDirection.Asc ? (
      <ArrowUpwardRounded />
    ) : (
      <ArrowDownwardRounded />
    );
  return <SelectedContainer selected={selected}>{arrowIcon}</SelectedContainer>;
};

type SortRowProps = {
  readonly columnState: ColumnState;
  readonly setColumnState: React.Dispatch<React.SetStateAction<ColumnState[]>>;
  readonly visibleColumns: OrderTableField[];
  readonly unappliedColumnState: ColumnState[];
  readonly index: number;
};
const SortRow = ({
  columnState,
  setColumnState,
  visibleColumns,
  unappliedColumnState,
  index,
}: SortRowProps) => {
  const orderTableFieldsNotSorted = visibleColumns.filter(
    (field) =>
      // If the field is already in the sort, we don't want to show it in the dropdown
      // unless it's the current field we're editing
      !unappliedColumnState.some((state) => state.colId === field) ||
      columnState.colId === field,
  );

  const onChange: AutocompleteFuzzyProps<MuiGroupedOption>['onChange'] = (
    _,
    option,
  ) => {
    if (isNil(option)) return;

    setColumnState((state) => {
      const columnStateToUpdate = state[index];
      if (isNil(columnStateToUpdate)) return state;

      const updatedState = [...state];
      updatedState[index] = {
        ...columnStateToUpdate,
        colId: option.value,
      };

      return updatedState;
    });
  };

  const { orderTableFieldHeaders } = useOrderTableFieldHeaders();
  const fields = getSortableGroupedTableFieldOptions(
    orderTableFieldsNotSorted,
    orderTableFieldHeaders,
  );

  const onChangeDirection = (direction: SortDirection) => {
    const directionAgGridFormat =
      direction === SortDirection.Asc ? 'asc' : 'desc';

    setColumnState((state) => {
      const columnStateToUpdate = state[index];
      if (isNil(columnStateToUpdate)) return state;

      const updatedState = [...state];
      updatedState[index] = {
        ...columnStateToUpdate,
        sort: directionAgGridFormat,
      };

      return updatedState;
    });
  };

  const removeRow = () => {
    setColumnState((state) => {
      const updatedState = [...state];
      updatedState.splice(index, 1);
      return updatedState;
    });
  };

  return (
    <Box display="flex" height={SORT_ROW_HEIGHT} gap={3}>
      <AutocompleteFuzzy
        sx={{ flex: 1 }}
        size="small"
        options={fields}
        groupBy={(option) => option.groupBy}
        value={fields.find((field) => field.value === columnState.colId)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search"
            placeholder="Sort by"
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
          />
        )}
        onChange={onChange}
      />
      <Stack direction="row">
        <Box
          sx={{ backgroundColor: '#F6F6F6' }}
          padding="2px"
          borderRadius="4px"
        >
          {Object.values(SortDirection).map((direction) => (
            <StyledToggleButton
              key={direction}
              size="small"
              value={direction}
              onClick={() => {
                onChangeDirection(direction);
              }}
            >
              <ConditionalSelectedContainer
                selected={columnState.sort === direction.toLocaleLowerCase()}
                direction={direction}
              />
            </StyledToggleButton>
          ))}
        </Box>
        <IconButton onClick={removeRow}>
          <Close />
        </IconButton>
      </Stack>
    </Box>
  );
};

export default SortRow;
