// eslint-disable-next-line no-restricted-imports
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { type UserPermissionWithAccessLevel } from 'shared/roles';
import { isNilOrEmptyString } from 'shared/string';
import UnauthorizedUserPermissions from '../../common/components/unauthorized-user-permissions';
import { type Me } from '../../common/react-hooks/use-me';
import useUserRoles from '../../common/react-hooks/use-user-roles';
import { findSettingsPagePermissionsWithAccessLevel } from '../../common/utils/roles';
import { AccessLevel, PermissionResource } from '../../generated/graphql';
import RolesTable from '../roles/components/roles-table';
import AccessorialsTab from './accessorials-tab';
import QuickbooksDesktopMappings from './components/quickbooks-desktop-mappings';
import DispatchSettings from './dispatch-settings';
import GeneralSettings from './general';
import OrderSettings from './order-settings';
import QuickbooksFileMapping from './quickbooks-file-mapping';
import SamsaraSettingsTab from './samsara-settings.tab';
import TagsTable from './tags-table';

const gridItemSx = {
  overflow: 'auto',
  backgroundColor: 'white',
};

const canRead =
  (resource: PermissionResource) =>
  (permissions: UserPermissionWithAccessLevel[]) =>
    permissions.some(
      (perm) =>
        perm.accessLevel === AccessLevel.Read &&
        perm.permissionResource === resource,
    );

type TabSpec = {
  label: string;
  slug: string;
  content: React.ReactNode;
  visibilityCondition: (
    permissions: UserPermissionWithAccessLevel[],
    me: Me,
  ) => boolean;
};

const tabs: TabSpec[] = [
  {
    label: 'Auto-Apply Accessorial Rules',
    slug: 'auto-apply',
    content: <AccessorialsTab />,
    visibilityCondition: canRead(
      PermissionResource.SettingsAutoApplyAccessorialRules,
    ),
  },
  {
    label: 'Tags',
    slug: 'tags',
    content: <TagsTable />,
    visibilityCondition: canRead(PermissionResource.SettingsTags),
  },
  {
    label: 'Samsara',
    slug: 'samsara',
    content: <SamsaraSettingsTab />,
    visibilityCondition: canRead(PermissionResource.SettingsSamsara),
  },
  {
    label: 'Quickbooks',
    slug: 'quickbooks',
    content: <QuickbooksFileMapping />,
    visibilityCondition: canRead(PermissionResource.SettingsQuickbooks),
  },
  {
    label: 'Dispatch',
    slug: 'dispatch',
    content: <DispatchSettings />,
    visibilityCondition: canRead(PermissionResource.SettingsDispatch),
  },
  {
    label: 'Roles',
    slug: 'roles',
    content: <RolesTable />,
    visibilityCondition: canRead(PermissionResource.SettingsRoles),
  },
  {
    label: 'Orders',
    slug: 'orders',
    content: <OrderSettings />,
    visibilityCondition: canRead(PermissionResource.SettingsOrders),
  },
  {
    label: 'Quickbooks Desktop',
    slug: 'quickbooks-desktop',
    content: <QuickbooksDesktopMappings />,
    visibilityCondition: (
      permissions: UserPermissionWithAccessLevel[],
      me: Me,
    ) =>
      me.quickbooksDesktopEnabled &&
      canRead(PermissionResource.SettingsQuickbooksDesktop)(permissions),
  },
  {
    label: 'General',
    slug: 'general',
    content: <GeneralSettings />,
    visibilityCondition: canRead(PermissionResource.SettingsGeneral),
  },
].sort((a, b) => a.label.localeCompare(b.label));

const SettingsView = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { userPermissions, loading: userRolesLoading } = useUserRoles();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSearchParams((sp) => {
      const newParams = new URLSearchParams(sp);
      newParams.set('tab', newValue);
      return newParams;
    });
  };

  const settingsPermissionResourcesWithReadAccess =
    findSettingsPagePermissionsWithAccessLevel(
      userPermissions,
      AccessLevel.Read,
    );

  const tab = searchParams.get('tab');

  useEffect(() => {
    const firstTab = tabs[0]?.slug;
    if (isNilOrEmptyString(tab) && !isNil(firstTab)) {
      setSearchParams((sp) => {
        const newParams = new URLSearchParams(sp);
        newParams.set('tab', firstTab);
        return newParams;
      });
    }
  }, [tab, setSearchParams]);

  const activeTab = tabs.find((tabInfo) => tabInfo.slug === tab);

  if (isEmpty(settingsPermissionResourcesWithReadAccess) && !userRolesLoading) {
    return <UnauthorizedUserPermissions />;
  }

  return (
    <Grid container height="100%">
      <Grid
        item
        xs={2}
        sx={gridItemSx}
        borderRight={1}
        borderColor="divider"
        height="100%"
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={tab}
          aria-label="settings-tabs"
          sx={{ pt: 3, height: '100%' }}
          onChange={handleChange}
        >
          {tabs.map((tabInfo) => (
            <Tab
              key={tabInfo.label}
              sx={{ alignItems: 'start' }}
              label={tabInfo.label}
              value={tabInfo.slug}
            />
          ))}
        </Tabs>
      </Grid>
      <Grid item xs={10} sx={gridItemSx} height="100%">
        <div
          role="tabpanel"
          aria-labelledby={`vertical-tab-${activeTab?.slug}`}
        >
          <Box sx={{ p: 3 }}>
            {activeTab?.content ?? <Typography>Page not found</Typography>}
          </Box>
        </div>
      </Grid>
    </Grid>
  );
};

export default SettingsView;
