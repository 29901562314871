import { isNil } from 'lodash';
import { assertNotNil } from 'shared/optional';
import useBillingReviewStore, {
  type BillingReviewOrder,
} from '../../../billing-review-store';

const useOrdersListToDisplayInSidebar = () => {
  const [outstandingOrdersInPage, searchedOrders, hasPreviousPage] =
    useBillingReviewStore((state) => [
      state.outstandingOrdersInPage,
      state.searchedOrders,
      state.paginationInfo.hasPrevPage,
    ]);

  let orders: BillingReviewOrder[] = [];
  orders = hasPreviousPage
    ? outstandingOrdersInPage
    : [...searchedOrders, ...outstandingOrdersInPage];

  try {
    const seenInProratedGroups = new Set<string>();

    assertNotNil(orders);

    const filteredOutUuids: string[] = [];
    // Filter the results in the returned page.
    const filteredResult = orders.filter((order) => {
      if (
        !isNil(order.ordersProratedWith) &&
        order.ordersProratedWith.length > 0
      ) {
        // This is a prorated group
        const groupOrderUuids = order.ordersProratedWith.map((o) => o.uuid);

        // If we haven't seen any order from this group, keep this one
        if (!groupOrderUuids.some((uuid) => seenInProratedGroups.has(uuid))) {
          // Mark all orders in this group as seen
          for (const uuid of groupOrderUuids) seenInProratedGroups.add(uuid);
          seenInProratedGroups.add(order.uuid);
          return true;
        }
        filteredOutUuids.push(order.uuid);
        return false;
      }
      // Keep non-prorated orders
      return true;
    });

    const finalList = filteredResult;

    return finalList;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return [];
  }
};

export default useOrdersListToDisplayInSidebar;
