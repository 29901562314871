import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import PanToolOutlinedIcon from '@mui/icons-material/PanToolOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Stack, Tooltip } from '@mui/material';
import { capitalCase } from 'change-case';
import { isNil } from 'lodash';
import React from 'react';
import { exhaustive } from 'shared/switch';
import { StopDetailsStatus } from '../../../../generated/graphql';

const StopDetailsStatusIcon = ({
  status,
  message,
  color,
}: {
  readonly status: StopDetailsStatus;
  readonly message: string | undefined | null;
  readonly color?: string;
}) => {
  let icon;
  switch (status) {
    case StopDetailsStatus.OnHand: {
      icon = (
        <PanToolOutlinedIcon
          color={isNil(color) ? 'success' : undefined}
          sx={{
            fontSize: '13px',
          }}
          htmlColor={color}
        />
      );
      break;
    }
    case StopDetailsStatus.OnRoute: {
      icon = (
        <EventAvailableOutlinedIcon
          color={isNil(color) ? 'info' : undefined}
          sx={{
            fontSize: '13px',
          }}
          htmlColor={color}
        />
      );
      break;
    }
    case StopDetailsStatus.InboundRequired: {
      icon = (
        <WarningAmberOutlinedIcon
          color={isNil(color) ? 'warning' : undefined}
          sx={{
            fontSize: '13px',
          }}
          htmlColor={color}
        />
      );
      break;
    }
    default: {
      return exhaustive(status);
    }
  }

  return (
    <Tooltip
      title={
        <Stack>
          <span>{capitalCase(status)}</span>
          <span>{message}</span>
        </Stack>
      }
    >
      {icon}
    </Tooltip>
  );
};

export default StopDetailsStatusIcon;
