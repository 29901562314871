import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { type Dispatch, type SetStateAction, useState } from 'react';
import {
  useUpdateUserMutation,
  LinehaulDispatchTableField,
  TableFieldsDocument,
} from '../../../../generated/graphql';
import AvailableFieldRow from './available-field-row';
import CurrentFieldRow from './current-field-row';

const ConfigureLinehaulDispatchTableColumns = ({
  userUuid,
  open,
  setOpen,
  tableFields,
}: {
  readonly userUuid: string;
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
  readonly tableFields: LinehaulDispatchTableField[];
}) => {
  const [updateUser] = useUpdateUserMutation({
    refetchQueries: [TableFieldsDocument],
  });

  const filterEligbleHeaders = (field: LinehaulDispatchTableField) => {
    return field !== LinehaulDispatchTableField.NextSegment;
  };

  const [currentlyShowingFields, setCurrentlyShowingFields] = useState<
    LinehaulDispatchTableField[]
  >(tableFields.filter((field) => filterEligbleHeaders(field)));

  const [availableFieldsToShow, setAvailableFieldsToShow] = useState(() =>
    Object.values(LinehaulDispatchTableField).filter(
      (linehaulDispatchTableField) =>
        !tableFields.includes(linehaulDispatchTableField) &&
        filterEligbleHeaders(linehaulDispatchTableField),
    ),
  );

  const onSave = async () => {
    await updateUser({
      variables: {
        updateUserInput: {
          uuid: userUuid,
          linehaulDispatchTableFields: currentlyShowingFields,
        },
      },
    });
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Stack justifyContent="center" p={2} alignItems="center">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '100%' }}
        >
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle>Edit Table Fields</DialogTitle>
          <Button variant="contained" onClick={onSave}>
            Save
          </Button>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Available fields</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {availableFieldsToShow
                    .sort((a, b) => a.localeCompare(b))
                    .map((field) => (
                      // eslint-disable-next-line react/jsx-key
                      <AvailableFieldRow
                        field={field}
                        setShowCurrentlyShowingFields={
                          setCurrentlyShowingFields
                        }
                        setShowAvailableFieldsToShow={setAvailableFieldsToShow}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Displayed fields</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentlyShowingFields.map((field, idx) => (
                    <CurrentFieldRow
                      key={field}
                      field={field}
                      setShowCurrentlyShowingFields={setCurrentlyShowingFields}
                      setShowAvailableFieldsToShow={setAvailableFieldsToShow}
                      idx={idx}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default ConfigureLinehaulDispatchTableColumns;
