import {
  type CellClickedEvent,
  type CellMouseOverEvent,
  type ColumnApi,
  type ColumnResizedEvent,
  type GridReadyEvent,
  type RowClassParams,
  type RowDoubleClickedEvent,
  type RowDragEndEvent,
  type RowDragEvent,
  type RowDropZoneParams,
  type RowStyle,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { findLastIndex, isNil } from 'lodash';
import { memo, startTransition, useCallback, useEffect, useRef } from 'react';
import { exhaustive } from 'shared/switch';
import { shallow } from 'zustand/shallow';
import { ROUTE_STOPS_COLUMN_WIDTHS_LOCAL_STORAGE_KEY } from '../../../common/local-storage/keys';
import useMultiplayer from '../../../common/multiplayer/use-multiplayer';
import useMe from '../../../common/react-hooks/use-me';
import {
  DispatchTableField,
  FulfillmentType,
  type RouteFragment,
  RouteStatus,
  type StopOnRouteFragment,
  StopStatus,
  useUpdateUserMutation,
} from '../../../generated/graphql';
import useDispatchStore from '../dispatch-store';
import useAssignStopsRouteActions from '../hooks/use-assign-stops-route-actions';
import useRouteActions from '../hooks/use-route-actions';
import { useRouteCardAgGridColumns } from '../hooks/use-route-card-ag-grid-columns';
import {
  type ColumnWidths,
  type RouteCardsColumnWidths,
} from '../hooks/use-route-cards-column-widths';
import {
  routeCardDefaultStyle,
  routeCardHighlightedStyle,
} from '../types/routes';
import type { TableStopOnRoute } from './route-card-stops-list-columns';

const rowHeight = 25;

const getRowStyle = (
  params: RowClassParams<StopOnRouteFragment>,
): RowStyle | undefined => {
  const { data } = params;
  if (isNil(data)) {
    return undefined;
  }

  const { showDriverModifiedAt, routeChangeAcknowledged } = data;

  if (isNil(showDriverModifiedAt) || routeChangeAcknowledged) {
    return undefined;
  }

  return {
    backgroundColor: '#FEF7DF',
    fontWeight: 500,
  };
};

const updateColumnWidths = (columnApi: ColumnApi, widths: ColumnWidths) => {
  columnApi.setColumnWidths(
    widths.map((columnWidth) => ({
      newWidth: columnWidth.width ?? 0,
      key: columnWidth.colId,
    })),
  );
};

const RouteCardStopsAgGrid = ({
  route,
  stops,
  numberOfStops,
  isMapView,
  columnWidths,
  setColumnWidths,
}: RouteCardsColumnWidths & {
  readonly stops: TableStopOnRoute[];
  readonly route: RouteFragment;
  readonly numberOfStops: number;
  readonly isMapView: boolean;
}) => {
  const { userUuid } = useMe();
  const gridRef = useRef<AgGridReact<StopOnRouteFragment>>(null);
  const { sendDispatchUserLeaveLocationEvent } = useMultiplayer();
  const { reassignStop } = useAssignStopsRouteActions();
  const { updateStopOrder } = useRouteActions();
  const [updateUser] = useUpdateUserMutation();

  const [
    setHoveredStopUuid,
    toggleSelectedStop,
    markRouteAsRendered,
    getRouteGridApis,
    setRouteGridApi,
    setOpenedOrderUuid,
  ] = useDispatchStore(
    (state) => [
      state.setHoveredStopUuid,
      state.toggleSelectedStopForBulkAction,
      state.markRouteAsRendered,
      state.getRouteGridApis,
      state.setRouteGridApi,
      state.setOpenedOrderUuid,
    ],
    shallow,
  );

  const columnDefs = useRouteCardAgGridColumns({
    isMapView,
    locked: route.locked,
  });

  useEffect(() => {
    const columnApi = gridRef.current?.columnApi;
    if (!isNil(columnWidths) && !isNil(columnApi)) {
      updateColumnWidths(columnApi, columnWidths);
    }
  }, [columnWidths]);

  const handleColumnResized = useCallback(
    async (e: ColumnResizedEvent) => {
      if (e.source === 'api' || !e.finished) {
        return;
      }
      const columnState = gridRef.current?.columnApi.getColumnState();
      if (!isNil(columnState)) {
        const newColumnWidths = columnState.map((col) => ({
          colId: col.colId,
          width: col.width,
        }));
        startTransition(() => {
          setColumnWidths(newColumnWidths);
        });
        localStorage.setItem(
          ROUTE_STOPS_COLUMN_WIDTHS_LOCAL_STORAGE_KEY,
          JSON.stringify(newColumnWidths),
        );
        if (!isNil(userUuid)) {
          await updateUser({
            variables: {
              updateUserInput: {
                uuid: userUuid,
                routeCardStopTableColumnWidths: newColumnWidths,
              },
            },
          });
        }
      }
    },
    [setColumnWidths, updateUser, userUuid],
  );

  const onDragWithinFinished = async (
    result: RowDragEvent<StopOnRouteFragment>,
  ) => {
    const destinationIndex = result.node.rowIndex;
    const { data } = result.node;
    const draggedUuid = data?.uuid;
    const sourceIndex = stops.findIndex((stop) => stop?.uuid === draggedUuid);
    if (
      !isNil(destinationIndex) &&
      data?.routeSlot?.route?.uuid === stops[0]?.routeSlot?.route?.uuid // ensure that we only rearrange when dragging within same route
    ) {
      const success = await updateStopOrder({
        routeUuid: route.uuid,
        startIndex: sourceIndex,
        endIndex: destinationIndex,
      });
      if (!success) {
        gridRef?.current?.api.setRowData(stops);
      }
    }
    sendDispatchUserLeaveLocationEvent();
  };

  const onCellMouseOver = (event: CellMouseOverEvent<StopOnRouteFragment>) => {
    if (route.locked) return;
    setHoveredStopUuid(event.data?.uuid);
    const orderUuid = event.data?.shipment?.order?.uuid;
    gridRef.current?.api.forEachNode((node) => {
      if (
        node.data?.shipment?.order?.uuid === orderUuid &&
        node.data?.shipment?.order?.fulfillmentType ===
          FulfillmentType.Dedicated &&
        node.data?.uuid !== event.data?.uuid
      ) {
        node.setSelected(true, false);
      }
    });
  };

  const onCellMouseOut = () => {
    setHoveredStopUuid(undefined);
    gridRef.current?.api.deselectAll();
  };

  const onRowDoubleClicked = (
    e: RowDoubleClickedEvent<StopOnRouteFragment>,
  ) => {
    setOpenedOrderUuid(e.data?.shipment?.order?.uuid);
  };

  const scrollStopIntoView = () => {
    // scroll relevant stop into view
    switch (route.routeInfo.status) {
      case RouteStatus.NotStarted: {
        gridRef.current?.api?.ensureIndexVisible(0);
        break;
      }
      case RouteStatus.InProgress: {
        // eslint-disable-next-line no-case-declarations
        const lastStopCompleteIndex = findLastIndex(
          route.slots,
          (slot) => slot.stops[0]?.status === StopStatus.Completed,
        );
        gridRef.current?.api?.ensureIndexVisible(
          Math.min(
            route.slots.length - 1,
            lastStopCompleteIndex + numberOfStops - 1,
          ),
        );
        break;
      }
      case RouteStatus.Complete: {
        // eslint-disable-next-line no-case-declarations
        const firstStopNotCompleteIndex = route.slots.findIndex(
          (slot) => slot.stops[0]?.status !== StopStatus.Completed,
        );
        gridRef.current?.api?.ensureIndexVisible(
          firstStopNotCompleteIndex === -1
            ? route.slots.length - 1
            : firstStopNotCompleteIndex,
        );
        break;
      }
      case RouteStatus.Incomplete: {
        // eslint-disable-next-line no-case-declarations
        const firstStopNotArrivedIndex = route.slots.findIndex(
          (slot) => slot.stops[0]?.status === StopStatus.NotArrived,
        );
        gridRef.current?.api?.ensureIndexVisible(firstStopNotArrivedIndex);
        break;
      }
      default: {
        exhaustive(route.routeInfo.status);
      }
    }
  };

  const onGridReady = (e: GridReadyEvent<StopOnRouteFragment>) => {
    setRouteGridApi({
      routeUuid: route.uuid,
      gridApi: e.api,
    });
    markRouteAsRendered(route.uuid);
    if (!isMapView) {
      scrollStopIntoView();
    }
    if (!isNil(columnWidths)) {
      updateColumnWidths(e.columnApi, columnWidths);
    }
  };

  const addOtherRouteDropZones = () => {
    if (route.locked) return;
    // add connections to be able to drop at specific index
    const routeGrids = getRouteGridApis();
    routeGrids.forEach((routeGrid) => {
      const rowDropZoneParams = routeGrid.api.getRowDropZoneParams({
        onDragStop: async (params: RowDragEndEvent<StopOnRouteFragment>) => {
          const { overNode, overIndex } = params;
          const routeSlot = params.node.data?.routeSlot;
          if (route.locked || isNil(routeSlot)) return;

          let newIndex;
          const tableDimensions = document
            .getElementById(`${routeGrid.routeUuid}-ag-grid-wrapper`)
            ?.getBoundingClientRect();

          if (
            !isNil(tableDimensions) &&
            !isNil(overNode) &&
            params.event.y >
              tableDimensions.y +
                (overNode.rowHeight ?? 0) * 1.5 +
                (overNode.rowTop ?? 0)
          ) {
            // Drop after the over node
            newIndex = overIndex + 1;
          } else {
            // Drop before the over node
            newIndex = overIndex;
          }

          reassignStop({
            routeUuid: routeGrid.routeUuid,
            slotUuid: routeSlot.uuid,
            endIndex: newIndex,
          });
        },
      });
      // if the other route apis are stale, this will error and break drag/drop which is why we add the try/catch statement
      try {
        gridRef?.current?.api.addRowDropZone(rowDropZoneParams);
      } catch {
        /**/
      }
    });
    // add connections to be able to drop in general card to end index
    const containers: NodeListOf<HTMLElement> =
      document.querySelectorAll('.route-card');
    containers.forEach((container: HTMLElement) => {
      const elem = container;
      if (elem.id === route.uuid) return;
      const dropZone: RowDropZoneParams = {
        getContainer: () => {
          return container;
        },
        onDragEnter: () => {
          Object.assign(elem.style, routeCardHighlightedStyle);
        },
        onDragLeave: () => {
          Object.assign(elem.style, routeCardDefaultStyle);
        },
        onDragStop: async (params) => {
          if (route.locked) return;
          reassignStop({
            routeUuid: elem.id,
            slotUuid: params.node.data.routeSlot.uuid,
          });
          Object.assign(elem.style, routeCardDefaultStyle);
        },
      };
      gridRef.current?.api?.addRowDropZone(dropZone);
    });
  };

  const onCellClicked = (event: CellClickedEvent<StopOnRouteFragment>) => {
    if (
      event.column.getColId() === 'actions' ||
      event.column.getColId() === DispatchTableField.OrderName ||
      isNil(event.data) ||
      route.locked ||
      event.data.status === StopStatus.Completed
    ) {
      return;
    }
    event.event?.stopPropagation();

    toggleSelectedStop(event.data);
  };

  return (
    <AgGridReact<StopOnRouteFragment>
      ref={gridRef}
      animateRows
      rowDragManaged
      suppressMoveWhenRowDragging
      enableCellChangeFlash
      suppressCellFocus
      suppressScrollOnNewData
      suppressMovableColumns
      defaultColDef={{
        resizable: true,
        suppressMenu: true,
        sortable: false,
      }}
      className={`${route.uuid} route-card-stops ag-non-compact`}
      getRowStyle={getRowStyle}
      rowData={stops}
      rowBuffer={60}
      headerHeight={rowHeight}
      rowHeight={rowHeight}
      rowDragEntireRow={!route.locked}
      columnDefs={columnDefs}
      onCellMouseOver={onCellMouseOver}
      onCellMouseOut={onCellMouseOut}
      onCellClicked={onCellClicked}
      onGridReady={onGridReady}
      onRowDragEnter={addOtherRouteDropZones}
      onRowDragEnd={onDragWithinFinished}
      onRowDoubleClicked={onRowDoubleClicked}
      onColumnResized={handleColumnResized}
    />
  );
};

export default memo(RouteCardStopsAgGrid);
