import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import useServices from '../../../common/react-hooks/use-services';
import { NONE_SERVICE } from '../contants';

const DefaultContactService = ({
  label,
  disabled,
  serviceUuid,
  handleChange,
}: {
  readonly label: string;
  readonly disabled: boolean;
  readonly serviceUuid: string | undefined | null;
  readonly handleChange: (event: SelectChangeEvent) => void;
}) => {
  const { getServiceName, services } = useServices();

  return (
    <FormControl sx={{ width: '50%' }}>
      <InputLabel>{label}</InputLabel>
      <Select
        size="small"
        label={label}
        disabled={disabled}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value={serviceUuid}
        renderValue={(selected) => {
          if (selected === NONE_SERVICE) {
            return <Typography>{sentenceCase(selected)}</Typography>;
          }
          return (
            <Typography>{sentenceCase(getServiceName(selected))} </Typography>
          );
        }}
        onChange={handleChange}
      >
        <MenuItem key={NONE_SERVICE} value={NONE_SERVICE}>
          {sentenceCase(NONE_SERVICE)}
        </MenuItem>
        {services?.map((service) => (
          <MenuItem key={service.uuid} value={service.uuid}>
            {sentenceCase(service.name)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DefaultContactService;
