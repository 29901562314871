import { ArrowRightAlt } from '@mui/icons-material';
import React from 'react';
import { TableCellCustom, TableRowCustom } from './common-table-components';
import StringDifferenceBlock from './string-difference-block';

type DifferenceRowProps = {
  readonly fieldName: string;
  readonly oldValue: string | undefined;
  readonly newValue: string | undefined;
  readonly wasEntireEntityAdded: boolean;
  readonly wasEntireEntityDeleted: boolean;
};

const DifferenceRow = ({
  fieldName,
  oldValue,
  newValue,
  wasEntireEntityAdded,
  wasEntireEntityDeleted,
}: DifferenceRowProps) => {
  return (
    <TableRowCustom>
      <TableCellCustom>{fieldName}</TableCellCustom>
      <TableCellCustom>
        <StringDifferenceBlock
          isOldValueBeingDisplayed
          oldValue={oldValue}
          newValue={newValue}
          wasEntireEntityAdded={wasEntireEntityAdded}
          wasEntireEntityDeleted={wasEntireEntityDeleted}
        />
      </TableCellCustom>
      <TableCellCustom>
        <ArrowRightAlt />
      </TableCellCustom>
      <TableCellCustom sx={{ flexWrap: 'wrap' }}>
        <StringDifferenceBlock
          oldValue={oldValue}
          newValue={newValue}
          isOldValueBeingDisplayed={false}
          wasEntireEntityAdded={wasEntireEntityAdded}
          wasEntireEntityDeleted={wasEntireEntityDeleted}
        />
      </TableCellCustom>
    </TableRowCustom>
  );
};

export default DifferenceRow;
