import { FullStory } from '@fullstory/browser';
import * as Sentry from '@sentry/react';
import { isNil } from 'lodash';
import React, { type PropsWithChildren, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isProduction } from '../../environment-variables';
import {
  useMeAsThirdPartyUserForAuthQuery,
  useMeForAuthQuery,
} from '../../generated/graphql';
import CenteredCircularProgress from './centered-circular-progress';
import UnauthorizedUserPermissions from './unauthorized-user-permissions';

const allowedThirdPartyPaths = ['/customer-portal'];

const identifyUser = ({
  email,
  company,
  companyUuid,
  id,
}: {
  email: string;
  company: string;
  companyUuid: string;
  id: string;
}) => {
  if (import.meta.env.PROD && isProduction()) {
    // We only initialize full story in prod, and this throws an error when not
    // initialized
    FullStory.identify(id, {
      email,
      company,
      companyUuid,
    });
  }
  Sentry.setUser({
    id,
    email,
  });
  Sentry.setTags({
    company,
  });
};

export const AuthenticationGuard = ({ children }: PropsWithChildren) => {
  const { data: meData, loading: meLoading } = useMeForAuthQuery();
  const { data: thirdPartyData, loading: thirdPartyLoading } =
    useMeAsThirdPartyUserForAuthQuery();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const me = meData?.me ?? null;
  const meAsThirdPartyUser = thirdPartyData?.meAsThirdPartyUser ?? null;

  const loading = meLoading || thirdPartyLoading;

  useEffect(() => {
    if (loading) {
      return;
    }
    if (!isNil(me)) {
      identifyUser({
        email: me.email,
        company: me.company.name,
        companyUuid: me.company.uuid,
        id: me.uuid,
      });
    } else if (
      !isNil(meAsThirdPartyUser) &&
      !isNil(meAsThirdPartyUser.contacts[0])
    ) {
      identifyUser({
        email: meAsThirdPartyUser.email,
        company: meAsThirdPartyUser.contacts[0].company.name,
        companyUuid: meAsThirdPartyUser.contacts[0].company.uuid,
        id: meAsThirdPartyUser.uuid,
      });
    } else {
      navigate('/');
    }
  }, [loading, me, meAsThirdPartyUser, navigate]);

  if (loading) {
    return <CenteredCircularProgress style={{ marginTop: '24px' }} />;
  }

  if (!isNil(me)) {
    return children;
  }

  if (!isNil(meAsThirdPartyUser)) {
    if (
      allowedThirdPartyPaths.some((allowedPath) =>
        pathname.startsWith(allowedPath),
      )
    ) {
      return children;
    }
    return <UnauthorizedUserPermissions />;
  }

  return null;
};
