import CloseIcon from '@mui/icons-material/Close';
import { IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import React, { memo } from 'react';
import DatePicker, { type DateObject } from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import { shallow } from 'zustand/shallow';
import useTariffGroupStore from '../store/tariff-group-state-store';

const StartEndDateField = () => {
  const [startDate, setStartDate] = useTariffGroupStore(
    (state) => [state.startDate, state.setStartDate],
    shallow,
  );
  const [endDate, setEndDate] = useTariffGroupStore(
    (state) => [state.endDate, state.setEndDate],
    shallow,
  );

  return (
    <Stack direction="column" gap={1}>
      <DatePicker
        hideOnScroll
        title="Start date"
        render={(value, openCalendar, handleValueChange) => (
          <TextField
            fullWidth
            size="small"
            label="Start date"
            value={value}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ marginRight: '4px' }}>
                  <IconButton
                    onClick={(e) => {
                      // stopPropagation prevents the calendar from opening when the 'x' is clicked
                      e.stopPropagation();
                      setStartDate(null);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onClick={openCalendar}
            onChange={handleValueChange}
          />
        )}
        value={startDate}
        plugins={[
          <TimePicker
            key="start-time-picker"
            hideSeconds
            position="right"
            format="hh:mma"
          />,
        ]}
        calendarPosition="right"
        style={{
          padding: '4px',
          height: '34px',
          fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
          fontSize: '1rem',
          color: 'rgba(0, 0, 0, 0.87)',
        }}
        placeholder="Start date"
        name="Start date"
        format="MM/DD/YYYY hh:mma"
        onChange={(newDate) => {
          if (newDate?.isValid) {
            setStartDate(newDate.toDate());
          }
        }}
      />
      <DatePicker
        hideOnScroll
        title="End date"
        value={endDate}
        plugins={[
          <TimePicker
            key="end-time-picker"
            hideSeconds
            position="right"
            format="hh:mma"
          />,
        ]}
        render={(value, openCalendar, handleValueChange) => (
          <TextField
            fullWidth
            size="small"
            label="End date"
            value={value}
            InputProps={{
              endAdornment: (
                // stopPropagation prevents the calendar from opening when the 'x' is clicked
                <InputAdornment position="end" sx={{ marginRight: '4px' }}>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setEndDate(null);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onClick={openCalendar}
            onChange={handleValueChange}
          />
        )}
        calendarPosition="right"
        style={{
          padding: '4px',
          height: '34px',
          fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
          fontSize: '1rem',
          color: 'rgba(0, 0, 0, 0.87)',
        }}
        placeholder="End date"
        name="End date"
        format="MM/DD/YYYY hh:mma"
        onChange={(newDate) => {
          if (newDate?.isValid) {
            setEndDate(newDate.toDate());
          }
        }}
      />
    </Stack>
  );
};

export default memo(StartEndDateField);
