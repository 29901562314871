import {
  Box,
  Button,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import currency from 'currency.js';
import { type ChangeEvent, type Dispatch, type SetStateAction } from 'react';
import { shallow } from 'zustand/shallow';
import { TariffZoneType } from '../../../../../generated/graphql';
import { getValueWithinBounds } from '../store/tariff-group-controller';
import useTariffGroupStore from '../store/tariff-group-state-store';
import styles from '../styles';
import { AmountInputType } from '../types';
import PalletModalNew from '../../../../../pallet-ui/modal/pallet-modal';

const TariffOverageRateModal = ({
  overageRateModalOpen,
  setOverageRateModalOpen,
}: {
  readonly overageRateModalOpen: boolean;
  readonly setOverageRateModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const [zoneType, globalOverageRates] = useTariffGroupStore(
    (state) => [state.zoneType, state.globalOverageRates],
    shallow,
  );

  const [
    setMileOverageRate,
    setMileOverageFlatRate,
    setMileOverageApplicableAbove,
    setPieceOverageRate,
    setPieceOverageFlatRate,
    setPieceOverageApplicableAbove,
    setWeightOverageRate,
    setWeightOverageFlatRate,
    setWeightOverageApplicableAbove,
    setUseGlobalOverageRates,
  ] = useTariffGroupStore(
    (state) => [
      state.setMileOverageRateValue,
      state.setMileOverageFlatRateValue,
      state.setMileOverageApplicableAboveValue,
      state.setPieceOverageRateValue,
      state.setPieceOverageFlatRateValue,
      state.setPieceOverageApplicableAboveValue,
      state.setWeightOverageRateValue,
      state.setWeightOverageFlatRateValue,
      state.setWeightOverageApplicableAboveValue,
      state.setUseGlobalOverageRates,
    ],
    shallow,
  );

  const validateAndUpdateRate = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const parsed = Number.parseFloat(event.target.value);
    let rate;
    if (!Number.isNaN(parsed)) {
      rate = getValueWithinBounds(currency(parsed, { precision: 2 }).value);
    }
    switch (event.target.name) {
      case AmountInputType.MileOverage: {
        setMileOverageRate(rate ?? null);

        break;
      }
      case AmountInputType.MileOverageFlatRate: {
        setMileOverageFlatRate(rate ?? null);

        break;
      }
      case AmountInputType.MileOverageApplicableAbove: {
        setMileOverageApplicableAbove(rate ?? null);

        break;
      }
      case AmountInputType.PieceOverage: {
        setPieceOverageRate(rate ?? null);

        break;
      }
      case AmountInputType.PieceOverageFlatRate: {
        setPieceOverageFlatRate(rate ?? null);

        break;
      }
      case AmountInputType.PieceOverageApplicableAbove: {
        setPieceOverageApplicableAbove(rate ?? null);

        break;
      }
      case AmountInputType.WeightOverage: {
        setWeightOverageRate(rate ?? null);

        break;
      }
      case AmountInputType.WeightOverageFlatRate: {
        setWeightOverageFlatRate(rate ?? null);

        break;
      }
      case AmountInputType.WeightOverageApplicableAbove: {
        setWeightOverageApplicableAbove(rate ?? null);

        break;
      }
      // No default
    }
  };

  return (
    <PalletModalNew
      hideCloseButton
      open={overageRateModalOpen}
      title="Edit Overage Rates"
      pinnedElements={{
        bottomRight: (
          <Stack direction="row" gap={2}>
            {zoneType !== TariffZoneType.Universal && (
              <Button
                variant="contained"
                onClick={() => {
                  setUseGlobalOverageRates(false);
                }}
              >
                Set Rates Per Zone
              </Button>
            )}
            <Button
              variant="contained"
              onClick={() => {
                setOverageRateModalOpen(false);
              }}
            >
              Save
            </Button>
          </Stack>
        ),
      }}
      onClose={() => {
        setOverageRateModalOpen(false);
      }}
    >
      <Stack gap={3} direction="column" height="20vh">
        <Stack gap={2} direction="row">
          <TextField
            sx={{ minWidth: 200 }}
            name={AmountInputType.MileOverage}
            size="small"
            label="Mile overage rate"
            value={globalOverageRates.mileOverageRate}
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            margin="dense"
            onBlur={validateAndUpdateRate}
            onChange={(event) => {
              setMileOverageRate(Number.parseFloat(event.target.value));
            }}
            onWheel={(e) => {
              (e.target as HTMLTextAreaElement).blur();
            }}
          />
          <TextField
            sx={{ minWidth: 200 }}
            name={AmountInputType.MileOverageFlatRate}
            size="small"
            label="Mile Overage Flat Rate"
            value={globalOverageRates.mileOverageFlatRate}
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            margin="dense"
            onBlur={validateAndUpdateRate}
            onChange={(event) => {
              setMileOverageFlatRate(Number.parseFloat(event.target.value));
            }}
            onWheel={(e) => {
              (e.target as HTMLTextAreaElement).blur();
            }}
          />
          <TextField
            sx={{ minWidth: 300 }}
            name={AmountInputType.MileOverageApplicableAbove}
            size="small"
            label="Applicable Above (Optional)"
            value={globalOverageRates.mileOverageApplicableAbove}
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">miles</InputAdornment>
              ),
            }}
            margin="dense"
            onBlur={validateAndUpdateRate}
            onChange={(event) => {
              setMileOverageApplicableAbove(
                Number.parseFloat(event.target.value),
              );
            }}
            onWheel={(e) => {
              (e.target as HTMLTextAreaElement).blur();
            }}
          />
        </Stack>
        <Stack gap={2} direction="row">
          <TextField
            sx={{ minWidth: 200 }}
            name={AmountInputType.PieceOverage}
            size="small"
            label="Pieces Overage Rate"
            value={globalOverageRates.pieceOverageRate}
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            onBlur={validateAndUpdateRate}
            onChange={(event) => {
              setPieceOverageRate(Number.parseFloat(event.target.value));
            }}
            onWheel={(e) => {
              (e.target as HTMLTextAreaElement).blur();
            }}
          />
          <TextField
            sx={{ minWidth: 200 }}
            name={AmountInputType.PieceOverageFlatRate}
            size="small"
            label="Piece Overage Flat Rate"
            value={globalOverageRates.pieceOverageFlatRate}
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            onBlur={validateAndUpdateRate}
            onChange={(event) => {
              setPieceOverageFlatRate(Number.parseFloat(event.target.value));
            }}
            onWheel={(e) => {
              (e.target as HTMLTextAreaElement).blur();
            }}
          />
          <TextField
            sx={{ minWidth: 300 }}
            name={AmountInputType.PieceOverageApplicableAbove}
            size="small"
            label="Applicable Above (Optional)"
            value={globalOverageRates.pieceOverageApplicableAbove}
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">pieces</InputAdornment>
              ),
            }}
            onBlur={validateAndUpdateRate}
            onChange={(event) => {
              setPieceOverageApplicableAbove(
                Number.parseFloat(event.target.value),
              );
            }}
            onWheel={(e) => {
              (e.target as HTMLTextAreaElement).blur();
            }}
          />
        </Stack>
        <Stack gap={2} direction="row">
          <TextField
            sx={{ minWidth: 200 }}
            name={AmountInputType.WeightOverage}
            size="small"
            label="Weight Overage Rate"
            value={globalOverageRates.weightOverageRate}
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            onBlur={validateAndUpdateRate}
            onChange={(event) => {
              setWeightOverageRate(Number.parseFloat(event.target.value));
            }}
            onWheel={(e) => {
              (e.target as HTMLTextAreaElement).blur();
            }}
          />
          <TextField
            sx={{ minWidth: 200 }}
            name={AmountInputType.WeightOverageFlatRate}
            size="small"
            label="Weight Overage Flat Rate"
            value={globalOverageRates.weightOverageFlatRate}
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            onBlur={validateAndUpdateRate}
            onChange={(event) => {
              setWeightOverageFlatRate(Number.parseFloat(event.target.value));
            }}
            onWheel={(e) => {
              (e.target as HTMLTextAreaElement).blur();
            }}
          />
          <TextField
            sx={{ minWidth: 300 }}
            name={AmountInputType.WeightOverageApplicableAbove}
            size="small"
            label="Applicable Above (Optional)"
            value={globalOverageRates.weightOverageApplicableAbove}
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">pounds</InputAdornment>
              ),
            }}
            onBlur={validateAndUpdateRate}
            onChange={(event) => {
              setWeightOverageApplicableAbove(
                Number.parseFloat(event.target.value),
              );
            }}
            onWheel={(e) => {
              (e.target as HTMLTextAreaElement).blur();
            }}
          />
        </Stack>
      </Stack>
    </PalletModalNew>
  );
};

export default TariffOverageRateModal;
