import { Box } from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { isNil } from 'lodash';
import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { type CategoricalChartState } from 'recharts/types/chart/types';
import { ReportAggregationPeriod } from '../../../../generated/graphql';
import useReportsStore from '../../reports-store';
import { type ReportStatisticConfig } from '../../types/report-statistic-config';
import { type OrderReportBucketData } from '../../types/report-types';
import CustomTooltip from './custom-tooltip';

dayjs.extend(utc);

type ReportBarChartProps = {
  readonly reportStatisticsConfig: ReportStatisticConfig[];
  readonly height: number | undefined;
  readonly yLabel?: string | null;
  readonly resetZoom: boolean;
  readonly setResetZoom: Dispatch<SetStateAction<boolean>>;
  readonly data: OrderReportBucketData[];
  readonly setData: Dispatch<SetStateAction<OrderReportBucketData[]>>;
};

export type ChartBounds = {
  refAreaLeft: number | undefined;
  refAreaRight: number | undefined;
};

const initialChartBounds = {
  refAreaLeft: undefined,
  refAreaRight: undefined,
};

const INTERVAL_SHRINK_FACTOR = 40;

const OrderReportBarChart = ({
  reportStatisticsConfig,
  height,
  yLabel,
  resetZoom,
  setResetZoom,
  data,
  setData,
}: ReportBarChartProps) => {
  const orderReportData = useReportsStore((state) => state.orderReportData);
  const reportGroupConfiguration = useReportsStore(
    (state) => state.reportGroupConfigurations[state.currentReportIndex],
  );

  const [chartBounds, setChartBounds] =
    useState<ChartBounds>(initialChartBounds);

  const interval = Math.floor(
    (isNil(data) ? 0 : data.length) / INTERVAL_SHRINK_FACTOR,
  );

  const reset = useCallback(() => {
    setChartBounds(initialChartBounds);
    setData(orderReportData);
  }, [orderReportData, setChartBounds, setData]);

  useEffect(() => {
    reset();
  }, [reset, orderReportData]);

  useEffect(() => {
    if (resetZoom) {
      reset();
      setResetZoom(false);
    }
  }, [reset, resetZoom, setResetZoom]);

  const zoom = () => {
    let { refAreaLeft, refAreaRight } = chartBounds;

    if (!isNil(refAreaLeft) && !isNil(refAreaRight)) {
      // xAxis domain
      if (refAreaLeft > refAreaRight)
        [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

      setChartBounds((prevState) => {
        const newState = { ...prevState };
        newState.refAreaLeft = undefined;
        newState.refAreaRight = undefined;
        return newState;
      });

      const startIndex = orderReportData.findIndex((d) => d.id === refAreaLeft);
      const endIndex = orderReportData.findIndex((d) => d.id === refAreaRight);
      const newData = orderReportData.slice(startIndex, endIndex + 1);
      if (newData.length > 0) {
        setData(newData);
      }
    }
  };

  const setReferenceArea = (e: CategoricalChartState, isLeft: boolean) => {
    setChartBounds((prevState) => {
      const newState = { ...prevState };
      if (!isNil(e) && !isNil(e.activePayload)) {
        if (isLeft) {
          newState.refAreaLeft = e.activePayload[0].payload?.id;
        } else {
          newState.refAreaRight = e.activePayload[0].payload?.id;
        }
      }
      return newState;
    });
  };

  return (
    <ResponsiveContainer width="99.9%" height={height}>
      <BarChart
        data={data}
        margin={{
          top: 5,
          left: 20,
        }}
        onMouseDown={(e) => {
          setReferenceArea(e, true);
        }}
        onMouseMove={(e) => {
          setReferenceArea(e, false);
        }}
        onMouseUp={zoom}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip
          content={
            <CustomTooltip
              reportGroupConfiguration={reportGroupConfiguration}
              reportStatisticsConfig={reportStatisticsConfig}
            />
          }
        />
        <XAxis hide dataKey="id" xAxisId={0} />
        <XAxis
          dataKey="startDateLabel"
          textAnchor="end"
          angle={-40}
          interval={interval}
          xAxisId={1}
          hide={
            reportGroupConfiguration?.reportAggregationPeriod !==
              ReportAggregationPeriod.Day &&
            reportGroupConfiguration?.reportAggregationPeriod !==
              ReportAggregationPeriod.Week
          }
        />
        <XAxis
          dataKey="monthLabel"
          xAxisId={2}
          tickLine={false}
          axisLine={false}
          interval={0}
          hide={
            reportGroupConfiguration?.reportAggregationPeriod ===
            ReportAggregationPeriod.Year
          }
          angle={
            !isNil(data) &&
            ((reportGroupConfiguration?.reportAggregationPeriod ===
              ReportAggregationPeriod.Month &&
              data.length > 30) ||
              (reportGroupConfiguration?.reportAggregationPeriod ===
                ReportAggregationPeriod.Day &&
                data.length > 1000))
              ? -40
              : 0
          }
          dy={5}
        />
        <XAxis
          dataKey="yearLabel"
          xAxisId={3}
          interval={0}
          axisLine={false}
          tickLine={false}
        />
        <YAxis
          type="number"
          label={{
            value: yLabel,
            angle: -90,
            position: 'left',
            textAnchor: 'middle',
          }}
        />
        {reportStatisticsConfig.map((statistic) => {
          if (
            !isNil(reportGroupConfiguration) &&
            reportGroupConfiguration.reportStatistics.includes(
              statistic.configKey,
            )
          ) {
            return (
              <Bar
                key={statistic.key}
                dataKey={statistic.key}
                name={statistic.name}
                fill={statistic.color}
              />
            );
          }
          return <Box key={statistic.key} />;
        })}
        {!isNil(chartBounds.refAreaLeft) &&
          !isNil(chartBounds.refAreaRight) && (
            <ReferenceArea
              x1={
                orderReportData.find((d) => d.id === chartBounds.refAreaLeft)
                  ?.id
              }
              x2={
                orderReportData.find((d) => d.id === chartBounds.refAreaRight)
                  ?.id
              }
              strokeOpacity={0.3}
            />
          )}
      </BarChart>
    </ResponsiveContainer>
  );
};
export default OrderReportBarChart;
