import { Stack, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import React from 'react';
import { shallow } from 'zustand/shallow';
import CenteredCircularProgress from '../../../../../../../common/components/centered-circular-progress';
import {
  OrderEventType,
  useOrderEventsQuery,
} from '../../../../../../../generated/graphql';
import useVersionHistoryStore from '../../../../../version-history-store';
import StorageOrderAuditLogCard from './storage-order-audit-log-card';

type StorageOrderAuditLogListProps = {
  readonly storageOrderUuid?: string;
};

const StorageOrderAuditLogList = ({
  storageOrderUuid,
}: StorageOrderAuditLogListProps) => {
  const [selectedVersionUuid] = useVersionHistoryStore(
    (state) => [state.selectedVersionUuid],
    shallow,
  );

  const { data: orderEventsData, loading: orderEventsLoading } =
    useOrderEventsQuery({
      variables: {
        storageOrderUuid,
      },
    });

  const filteredOrderEvents =
    orderEventsData?.orderEvents?.filter(
      (orderEvent) => orderEvent.eventType !== OrderEventType.OrderUpdated,
    ) ?? [];

  if (orderEventsLoading) {
    return <CenteredCircularProgress />;
  }

  if (isEmpty(filteredOrderEvents)) {
    return <Typography>No logs to display</Typography>;
  }

  return (
    <Stack spacing={2}>
      {filteredOrderEvents.map((orderEvent, i) => {
        const prevSnapshotIndex = filteredOrderEvents
          .map((o) => o.eventType)
          .indexOf(OrderEventType.OrderSnapshot, i + 1);
        return (
          <StorageOrderAuditLogCard
            key={orderEvent.uuid}
            eventType={orderEvent.eventType}
            selectedVersionUuid={selectedVersionUuid}
            orderEvent={orderEvent}
            previousOrderEvent={filteredOrderEvents[prevSnapshotIndex]}
          />
        );
      })}
    </Stack>
  );
};

export default React.memo(StorageOrderAuditLogList);
