import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { isNil, isString } from 'lodash';
import React, { type FunctionComponent, useEffect, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  PermissionResource,
  useSchedulingRegionsQuery,
} from '../../../generated/graphql';
import { CreateSchedulingRegionModal } from './create-scheduling-region-modal';
import { EditSchedulingRegion } from './edit-scheduling-region';

export const SchedulingPage: FunctionComponent = () => {
  const { userPermissions } = useUserRoles();
  const { canWrite } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyScheduling,
  );
  const { data, loading, error, refetch } = useSchedulingRegionsQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [selectedRegionId, setSelectedRegionId] = useState<string | null>(null);

  const regions = data?.schedulingRegions.schedulingRegions;

  useEffect(() => {
    const firstId = regions?.[0]?.id;
    if (isNil(selectedRegionId) && !isNil(firstId)) {
      setSelectedRegionId(firstId);
    }
  }, [selectedRegionId, regions]);

  const [createModalOpen, setCreateModalOpen] = useState(false);

  const noRegionsYet =
    !loading && isNil(error) && !isNil(regions) && regions.length === 0;

  return (
    <>
      <Stack direction="row" height="100%">
        <Box
          width={noRegionsYet ? '100%' : '280px'}
          minWidth="280px"
          borderRight={noRegionsYet ? undefined : 1}
          borderColor="divider"
          overflow="auto"
        >
          <Stack
            direction="row"
            sx={{
              p: 3,
              alignItems: 'center',
              justifyContent: 'space-between',
              borderColor: 'divider',
            }}
          >
            <Typography variant="h2" fontSize="18px" fontWeight="500">
              Scheduling
            </Typography>
            {canWrite && !noRegionsYet && (!loading || !isNil(regions)) && (
              <Button
                variant="outlined"
                sx={{
                  width: 'max-content',
                }}
                onClick={() => {
                  setCreateModalOpen(true);
                }}
              >
                Add
              </Button>
            )}
          </Stack>
          {!noRegionsYet && <Divider sx={{ borderBottomWidth: '2px' }} />}
          {loading && <CircularProgress sx={{ m: 3 }} />}
          {!isNil(error) && (
            // This isn't pretty but limiting scope.
            <Typography p={3}>Error: {error.message}</Typography>
          )}
          {noRegionsYet && (
            <>
              <Typography ml={3} mb={2}>
                You have not created any regions yet.
              </Typography>
              {canWrite && (
                <Button
                  variant="outlined"
                  sx={{
                    ml: 3,
                    width: 'max-content',
                  }}
                  onClick={() => {
                    setCreateModalOpen(true);
                  }}
                >
                  Add
                </Button>
              )}
            </>
          )}
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={selectedRegionId ?? false}
            scrollButtons="auto"
            sx={{ pt: 1 }}
            onChange={(_ev, newValue) => {
              if (isString(newValue)) {
                setSelectedRegionId(newValue);
              }
            }}
          >
            {regions?.map((region) => (
              <Tab
                key={region.id}
                value={region.id}
                label={region.name}
                sx={{ alignItems: 'start' }}
              />
            ))}
          </Tabs>
        </Box>
        {!noRegionsYet && (
          <Box flexGrow={1} overflow="auto">
            {!isNil(selectedRegionId) && (
              <EditSchedulingRegion id={selectedRegionId} mode="edit" />
            )}
          </Box>
        )}
      </Stack>
      <CreateSchedulingRegionModal
        open={createModalOpen}
        onCreated={(region) => {
          setCreateModalOpen(false);
          refetch();
          setSelectedRegionId(region.id);
        }}
        onClose={() => {
          setCreateModalOpen(false);
        }}
      />
    </>
  );
};
