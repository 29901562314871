import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Stack, Typography } from '@mui/material';
import { isNil } from 'lodash';
import React from 'react';
import { EmailTransactionLogStatus } from '../../../../../../generated/graphql';

export const failureEmailTransactionLogStatuses: EmailTransactionLogStatus[] = [
  EmailTransactionLogStatus.Blocked,
  EmailTransactionLogStatus.Dropped,
  EmailTransactionLogStatus.Bounced,
];
const InvoiceEmailTransactionLogStatuses = ({
  emailTransactionLogs,
}: {
  readonly emailTransactionLogs: Array<{
    uuid: string;
    __typename?: 'EmailTransactionLogEntity' | undefined;
    email: string;
    lastStatus?: EmailTransactionLogStatus | null | undefined;
    reason?: string | null | undefined;
  }>;
}) => {
  return (
    <Stack>
      {emailTransactionLogs.map((log) => (
        <Stack key={log.uuid} direction="row" alignItems="center" spacing={1}>
          {log.lastStatus === EmailTransactionLogStatus.Delivered && (
            <CheckCircleIcon sx={{ fontSize: '12px' }} color="success" />
          )}
          {(log.lastStatus === EmailTransactionLogStatus.Processed ||
            log.lastStatus === EmailTransactionLogStatus.Deferred ||
            isNil(log.lastStatus)) && (
            <MoreHorizIcon sx={{ fontSize: '12px' }} color="info" />
          )}
          {!isNil(log.lastStatus) &&
            failureEmailTransactionLogStatuses.includes(log.lastStatus) && (
              <ErrorIcon sx={{ fontSize: '12px' }} color="error" />
            )}
          <Typography sx={{ fontSize: '12px' }}>{log.email}</Typography>
        </Stack>
      ))}
    </Stack>
  );
};

export default InvoiceEmailTransactionLogStatuses;
