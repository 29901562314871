import {
  DragDropContext,
  Draggable,
  Droppable,
  type DropResult,
} from '@hello-pangea/dnd';
import { Add } from '@mui/icons-material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Divider,
  IconButton,
  Popover,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { ArrowDropDownIcon } from '@mui/x-date-pickers-pro';
import { isNil } from 'lodash';
import { useState } from 'react';
import SecondaryButton from '../../../../../common/components/SecondaryButton';
import OrderTableFunctionButton from '../order-table-function-button';

export type ViewTab<DefaultFilterTabsType> = {
  label: string;
  value: string | DefaultFilterTabsType;
  testId?: string;
  renderOverflowMenu?: (
    anchorEl: HTMLElement | null,
    onClose: () => void,
  ) => React.ReactNode;
};

type DraggableMenuOption<TOptionValue> = {
  value: TOptionValue;
  label: string;
  renderOverflowMenu?: (
    anchorEl: HTMLElement | null,
    onClose: () => void,
  ) => React.ReactNode;
};

type DraggableMenuItemProps<TOptionValue> = {
  readonly option: DraggableMenuOption<TOptionValue>;
  readonly index: number;
  readonly onClick: (e: React.MouseEvent<HTMLElement>) => void;
};

const DraggableMenuItem = <TOptionValue,>({
  option,
  index,
  onClick,
}: DraggableMenuItemProps<TOptionValue>) => {
  const { label, value, renderOverflowMenu } = option;
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onOverflowMenuIconClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
    e.stopPropagation();
  };
  return (
    <Draggable key={String(value)} draggableId={String(value)} index={index}>
      {(draggableProvided, { isDragging }) => (
        <Stack
          ref={draggableProvided.innerRef}
          direction="row"
          alignItems="center"
          padding={1}
          height="32.5px"
          justifyContent="space-between"
          onClick={onClick}
          {...draggableProvided.draggableProps}
          sx={{
            ...(isDragging && {
              background: theme.palette.background.default,
              boxShadow: 2,
              opacity: 1,
            }),
            cursor: 'pointer',
          }}
        >
          <Stack gap={1} direction="row" minWidth={0}>
            <Box
              {...draggableProvided.dragHandleProps}
              display="flex"
              alignItems="center"
            >
              <DragIndicatorIcon sx={{ color: theme.palette.text.secondary }} />
            </Box>
            <Typography noWrap>{label}</Typography>
          </Stack>
          {!isNil(renderOverflowMenu) && (
            <>
              <IconButton sx={{ padding: 0 }} onClick={onOverflowMenuIconClick}>
                <MoreVertIcon sx={{ color: theme.palette.text.secondary }} />
              </IconButton>
              {renderOverflowMenu(anchorEl, () => {
                setAnchorEl(null);
              })}
            </>
          )}
        </Stack>
      )}
    </Draggable>
  );
};

type AllViewsButtonProps<T> = {
  readonly viewTabs: Array<DraggableMenuOption<T>>;
  readonly onChange: (value: T) => void;
  readonly onDragEnd: (result: DropResult) => void;
  readonly onAddView: () => void;
};

const AllViewsButton = <T,>({
  viewTabs,
  onChange,
  onDragEnd,
  onAddView,
}: AllViewsButtonProps<T>) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);

  const openPopover = (e: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(e.currentTarget);
  };
  const onPopoverClose = () => {
    setMenuAnchorEl(null);
  };

  const onOptionClick = (option: DraggableMenuOption<T>) => {
    onChange(option.value);
    onPopoverClose();
  };

  return (
    <>
      <OrderTableFunctionButton
        endIcon={<ArrowDropDownIcon />}
        onClick={openPopover}
      >
        All views ({viewTabs.length})
      </OrderTableFunctionButton>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
        onClose={onPopoverClose}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="order-table-tabs-dropdown">
            {(droppableProvided) => (
              <Stack maxHeight="80vh" maxWidth="500px">
                <Box
                  ref={droppableProvided.innerRef}
                  flex="1"
                  minHeight={0}
                  overflow="auto"
                  display="block"
                  {...droppableProvided.droppableProps}
                >
                  {viewTabs.map((option, index) => (
                    <DraggableMenuItem
                      key={String(option.value)}
                      option={option}
                      index={index}
                      onClick={() => {
                        onOptionClick(option);
                      }}
                    />
                  ))}
                  {droppableProvided.placeholder}
                </Box>
                <Divider />
                <Box py={1.5} px={2}>
                  <SecondaryButton
                    variant="outlined"
                    startIcon={<Add />}
                    onClick={onAddView}
                  >
                    Add view
                  </SecondaryButton>
                </Box>
              </Stack>
            )}
          </Droppable>
        </DragDropContext>
      </Popover>
    </>
  );
};

export default AllViewsButton;
