import { Box, Tab, Tabs, useTheme } from '@mui/material';
import { useState } from 'react';
import OrderAuditLogList from '../../../standard/components/version-history/components/order-audit-log/order-audit-log-list';
import { type OnSubmitParams } from '../../types';
import Overview from '../overview';
import Documents from '../overview/documents';
import Comments from './comments';

enum SidebarTab {
  AuditLog = 'Audit Log',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Documents = 'Documents',
  Notes = 'Notes',
}

const RightSidebar = ({
  isEditMode,
  onSubmit,
  orderUuid,
}: {
  readonly isEditMode: boolean;
  readonly onSubmit: (params: OnSubmitParams) => Promise<boolean>;
  readonly orderUuid: string | null;
}) => {
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState(SidebarTab.Notes);

  return (
    <Box
      sx={{
        p: 2,
        width: '100%',
        background: 'white',
        borderBottom: `1px solid ${theme.palette.borderColor.main}`,
      }}
    >
      <Overview isEditMode={isEditMode} onSubmit={onSubmit} />
      <Tabs
        value={selectedTab}
        sx={{
          marginTop: '10px',
        }}
        onChange={(_, val) => {
          setSelectedTab(val);
        }}
      >
        <Tab label={SidebarTab.Notes} value={SidebarTab.Notes} />
        <Tab label={SidebarTab.Documents} value={SidebarTab.Documents} />
        <Tab label={SidebarTab.AuditLog} value={SidebarTab.AuditLog} />
      </Tabs>
      <Box mt="14px">
        {selectedTab === SidebarTab.Documents && (
          <Documents isEditMode={isEditMode} showHeader={false} />
        )}
        {selectedTab === SidebarTab.Notes && (
          <Comments isEditMode={isEditMode} showHeader={false} />
        )}
        {selectedTab === SidebarTab.AuditLog && (
          <OrderAuditLogList orderUuid={orderUuid} />
        )}
      </Box>
    </Box>
  );
};

export default RightSidebar;
