import { TextField } from '@mui/material';
import { type Option } from '../../../common/filters/types';
import { convertValueToOption } from '../../../common/utils/utils';
import AutocompleteFuzzy from '../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import {
  BaseFilterInputInputPropsSx,
  type BaseFilterInputProps,
} from '../orders/components/filters/filter-utils';

type FilterSingleSelectInputProps = {
  readonly filterOptionMappings: Option[];
  readonly disabled?: boolean;
} & BaseFilterInputProps;

const FilterSingleSelectInput = ({
  filterValueInput,
  filterNameInput,
  filterOperationInput,
  setFilter,
  filterOptionMappings,
}: FilterSingleSelectInputProps) => {
  return (
    <AutocompleteFuzzy
      sx={{ backgroundColor: 'white', width: '160px', minHeight: '32px' }}
      value={convertValueToOption({
        value: filterValueInput as string,
        optionsList: filterOptionMappings,
      })}
      options={filterOptionMappings ?? []}
      matchSortOptions={{ keys: ['label'] }}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          size="small"
          placeholder="Value"
          InputProps={{
            ...params.InputProps,
            sx: {
              ...BaseFilterInputInputPropsSx,
              marginLeft: '-2px',
            },
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        />
      )}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderOption={(props, option) => (
        <li {...props} style={{ padding: '4px' }}>
          {option.label}
        </li>
      )}
      onChange={(_, selected) => {
        setFilter({
          filter: filterNameInput,
          op: filterOperationInput,
          value: selected?.value ?? null,
        });
      }}
    />
  );
};

export default FilterSingleSelectInput;
