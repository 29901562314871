import { Box, Stack, Typography, useTheme } from '@mui/material';
import {
  type ColDef,
  type ICellRendererParams,
  type ValueGetterParams,
} from 'ag-grid-community';
import { sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isEmpty, isNil } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { getCurrentTimeDefaultTimezone } from 'shared/time';
import useMeAsThirdPartyUser from '../../../common/react-hooks/use-me-as-third-party-user';
import {
  type CustomerPortalOrderForTableFragment,
  FilterViewPage,
  PickupOrDelivery,
  Segment,
  useThirdPartyUserByUuidLazyQuery,
} from '../../../generated/graphql';
import CustomerPortalOrdersTableWithFiltersAgGrid from '../../ag-grid/customer-portal/customer-portal-orders-table-with-filters-ag-grid';
import OpenCustomerPortalOrderButton from '../../ag-grid/customer-portal/open-customer-portal-order-button';
import {
  BooleanFilter,
  DateSelectFilter,
  SingleSelectFilter,
} from '../../ag-grid/filter-components';
import { ExcludeFromHiding } from '../../orders/components/enums/order-filters';
import {
  CustomerPortalOrderFilterField,
  CustomerPortalOrderTableField,
  getCustomerPortalOrdersTableField,
  getCustomerPortalOrdersTableFieldCopy,
} from '../utils';
import CustomerPortalAppBar from './customer-portal-app-bar';

dayjs.extend(utc);
dayjs.extend(timezone);

const ORDERS_PAGE_SIZE = 15;

const useStyles = () => {
  const theme = useTheme();
  return {
    appBar: {
      position: 'sticky',
      flexDirection: 'row',
      height: 64,
      alignItems: 'center',
      justifyContent: 'center',
    },
    appBarTypography: {
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: 1,
      color: '#FFFFFF',
    },
    centeredHeaderRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    centeredRow: {
      display: 'flex',
      justifyContent: 'center',
    },
    tableRow: {
      display: 'flex',
      justifyContent: 'center',
    },
    filterButton: {
      backgroundColor: theme.palette.primary.light,
      borderRadius: '4px',
    },
    noContactsMessage: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      width: '100%',
      height: '50%',
      margin: '0 25%',
      boxSizing: 'border-box',
      backgroundColor: theme.palette.background.paper,
      borderRadius: 4,
      border: '2px solid black',
    },
  };
};

const CustomerLoadsView = () => {
  const styles = useStyles();
  const { thirdPartyUser } = useMeAsThirdPartyUser();
  const firstCompany = thirdPartyUser?.contacts?.[0]?.company;
  const segment = firstCompany?.segment ?? Segment.Cartage;
  const timeZone = firstCompany?.timeZone;

  // list of companies
  const distinctCompanies = useMemo(() => {
    const companies = thirdPartyUser?.contacts.map((c) => c.company);
    if (isNil(companies)) return [];
    return [
      ...companies
        .reduce((acc, current) => {
          if (!acc.has(current.uuid)) {
            acc.set(current.uuid, current);
          }
          return acc;
        }, new Map())
        .values(),
    ];
  }, [thirdPartyUser]);

  const filterColumns = [
    // Hidden columns used for filters.
    {
      field: CustomerPortalOrderFilterField.ORDER_TYPE,
      hide: true,
      filter: SingleSelectFilter,
      filterParams: {
        values: Object.values(PickupOrDelivery).map((val) => {
          return { actualValue: val, displayValue: sentenceCase(val) };
        }),
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        shouldShowSearch: false,
        hideFilterType: true,
      },
    },
    {
      field: CustomerPortalOrderFilterField.APPOINTMENT_DATE,
      hide: true,
      filter: DateSelectFilter,
      filterParams: {
        values: {
          startDate: getCurrentTimeDefaultTimezone().startOf('day').toDate(),
          endDate: getCurrentTimeDefaultTimezone().endOf('day').toDate(),
        },
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        hideFilterType: true,
      },
    },
    {
      field: CustomerPortalOrderFilterField.CREATED_DATE,
      hide: true,
      filter: DateSelectFilter,
      filterParams: {
        values: {
          startDate: getCurrentTimeDefaultTimezone().startOf('day').toDate(),
          endDate: getCurrentTimeDefaultTimezone().endOf('day').toDate(),
        },
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        hideFilterType: true,
      },
    },
    {
      field: CustomerPortalOrderFilterField.DATE_MARKED_ON_HAND,
      hide: true,
      filter: DateSelectFilter,
      filterParams: {
        values: {
          startDate: getCurrentTimeDefaultTimezone().startOf('day').toDate(),
          endDate: getCurrentTimeDefaultTimezone().endOf('day').toDate(),
        },
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        hideFilterType: true,
      },
    },
    {
      field: CustomerPortalOrderFilterField.DATE_PLANNED,
      hide: true,
      filter: DateSelectFilter,
      filterParams: {
        values: {
          startDate: getCurrentTimeDefaultTimezone().startOf('day').toDate(),
          endDate: getCurrentTimeDefaultTimezone().endOf('day').toDate(),
        },
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        hideFilterType: true,
      },
    },
    {
      field: CustomerPortalOrderFilterField.DISPATCHED,
      hide: true,
      filter: BooleanFilter,
      filterParams: {
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        hideFilterType: true,
      },
    },
    {
      field: CustomerPortalOrderFilterField.OFD_OFP,
      hide: true,
      filter: BooleanFilter,
      filterParams: {
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        hideFilterType: true,
      },
    },
    {
      field: CustomerPortalOrderFilterField.COMPLETED_DATE,
      hide: true,
      filter: DateSelectFilter,
      filterParams: {
        values: {
          startDate: getCurrentTimeDefaultTimezone().startOf('day').toDate(),
          endDate: getCurrentTimeDefaultTimezone().endOf('day').toDate(),
        },
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        hideFilterType: true,
      },
    },
  ];
  filterColumns.sort((a, b) =>
    a.field.toString().localeCompare(b.field.toString()),
  );
  const columns = [
    ...filterColumns,
    {
      field: CustomerPortalOrderTableField.BILL_OF_LADING_NUMBER,
      headerName: getCustomerPortalOrdersTableFieldCopy(
        CustomerPortalOrderTableField.BILL_OF_LADING_NUMBER,
        segment,
      ),
      resizable: true,
      minWidth: 140,
      valueGetter: (
        params: ValueGetterParams<CustomerPortalOrderForTableFragment>,
      ) => {
        return getCustomerPortalOrdersTableField(
          CustomerPortalOrderTableField.BILL_OF_LADING_NUMBER,
          params.data,
        );
      },
    },
    {
      field: CustomerPortalOrderTableField.COMPANY,
      headerName: getCustomerPortalOrdersTableFieldCopy(
        CustomerPortalOrderTableField.COMPANY,
        segment,
      ),
      hide: distinctCompanies.length === 1,
      resizable: true,
      minWidth: 140,
      valueGetter: (
        params: ValueGetterParams<CustomerPortalOrderForTableFragment>,
      ) => {
        return getCustomerPortalOrdersTableField(
          CustomerPortalOrderTableField.COMPANY,
          params.data,
        );
      },
    },
    {
      field: CustomerPortalOrderTableField.CONTACT,
      headerName: getCustomerPortalOrdersTableFieldCopy(
        CustomerPortalOrderTableField.CONTACT,
        segment,
      ),
      hide: thirdPartyUser?.contacts?.length === 1,
      resizable: true,
      minWidth: 140,
      valueGetter: (
        params: ValueGetterParams<CustomerPortalOrderForTableFragment>,
      ) => {
        return getCustomerPortalOrdersTableField(
          CustomerPortalOrderTableField.CONTACT,
          params.data,
        );
      },
    },
    {
      field: CustomerPortalOrderTableField.STATUS,
      headerName: getCustomerPortalOrdersTableFieldCopy(
        CustomerPortalOrderTableField.STATUS,
        segment,
      ),
      resizable: true,
      minWidth: 140,
      valueGetter: (
        params: ValueGetterParams<CustomerPortalOrderForTableFragment>,
      ) => {
        return getCustomerPortalOrdersTableField(
          CustomerPortalOrderTableField.STATUS,
          params.data,
        );
      },
    },
    {
      field: CustomerPortalOrderTableField.NEXT_ACTION,
      headerName: getCustomerPortalOrdersTableFieldCopy(
        CustomerPortalOrderTableField.NEXT_ACTION,
        segment,
      ),
      resizable: true,
      minWidth: 100,
      valueGetter: (
        params: ValueGetterParams<CustomerPortalOrderForTableFragment>,
      ) => {
        return getCustomerPortalOrdersTableField(
          CustomerPortalOrderTableField.NEXT_ACTION,
          params.data,
        );
      },
    },
    {
      field: CustomerPortalOrderTableField.APPOINTMENT,
      headerName: getCustomerPortalOrdersTableFieldCopy(
        CustomerPortalOrderTableField.APPOINTMENT,
        segment,
      ),
      resizable: true,
      minWidth: 140,
      valueGetter: (
        params: ValueGetterParams<CustomerPortalOrderForTableFragment>,
      ) => {
        return getCustomerPortalOrdersTableField(
          CustomerPortalOrderTableField.APPOINTMENT,
          params.data,
          timeZone,
        );
      },
    },
    {
      field: CustomerPortalOrderTableField.DEADLINE_DATE,
      headerName: getCustomerPortalOrdersTableFieldCopy(
        CustomerPortalOrderTableField.DEADLINE_DATE,
        segment,
      ),
      resizable: true,
      minWidth: 100,
      valueGetter: (
        params: ValueGetterParams<CustomerPortalOrderForTableFragment>,
      ) => {
        return getCustomerPortalOrdersTableField(
          CustomerPortalOrderTableField.DEADLINE_DATE,
          params.data,
        );
      },
    },
    {
      field: CustomerPortalOrderTableField.CONSIGNEE_NAME,
      headerName: getCustomerPortalOrdersTableFieldCopy(
        CustomerPortalOrderTableField.CONSIGNEE_NAME,
        segment,
      ),
      resizable: true,
      minWidth: 120,
      valueGetter: (
        params: ValueGetterParams<CustomerPortalOrderForTableFragment>,
      ) => {
        return getCustomerPortalOrdersTableField(
          CustomerPortalOrderTableField.CONSIGNEE_NAME,
          params.data,
        );
      },
    },
    {
      field: CustomerPortalOrderTableField.ADDRESS,
      headerName: getCustomerPortalOrdersTableFieldCopy(
        CustomerPortalOrderTableField.ADDRESS,
        segment,
      ),
      resizable: true,
      minWidth: 140,
      valueGetter: (
        params: ValueGetterParams<CustomerPortalOrderForTableFragment>,
      ) => {
        return getCustomerPortalOrdersTableField(
          CustomerPortalOrderTableField.ADDRESS,
          params.data,
        );
      },
    },
    {
      field: CustomerPortalOrderTableField.DATE_MARKED_ON_HAND,
      headerName: getCustomerPortalOrdersTableFieldCopy(
        CustomerPortalOrderTableField.DATE_MARKED_ON_HAND,
        segment,
      ),
      resizable: true,
      minWidth: 120,
      valueGetter: (
        params: ValueGetterParams<CustomerPortalOrderForTableFragment>,
      ) => {
        return getCustomerPortalOrdersTableField(
          CustomerPortalOrderTableField.DATE_MARKED_ON_HAND,
          params.data,
        );
      },
    },
    {
      field: CustomerPortalOrderTableField.DATE_PLANNED,
      headerName: getCustomerPortalOrdersTableFieldCopy(
        CustomerPortalOrderTableField.DATE_PLANNED,
        segment,
      ),
      resizable: true,
      minWidth: 120,
      valueGetter: (
        params: ValueGetterParams<CustomerPortalOrderForTableFragment>,
      ) => {
        return getCustomerPortalOrdersTableField(
          CustomerPortalOrderTableField.DATE_PLANNED,
          params.data,
        );
      },
    },
    {
      field: CustomerPortalOrderTableField.OSD,
      headerName: getCustomerPortalOrdersTableFieldCopy(
        CustomerPortalOrderTableField.OSD,
        segment,
      ),
      resizable: true,
      minWidth: 120,
      cellRenderer: (
        params: ICellRendererParams<CustomerPortalOrderForTableFragment>,
      ) => {
        return getCustomerPortalOrdersTableField(
          CustomerPortalOrderTableField.OSD,
          params.data,
        );
      },
    },
    {
      field: CustomerPortalOrderTableField.ON_HOLD,
      headerName: getCustomerPortalOrdersTableFieldCopy(
        CustomerPortalOrderTableField.ON_HOLD,
        segment,
      ),
      resizable: true,
      minWidth: 120,
      cellRenderer: (
        params: ICellRendererParams<CustomerPortalOrderForTableFragment>,
      ) => {
        return getCustomerPortalOrdersTableField(
          CustomerPortalOrderTableField.ON_HOLD,
          params.data,
        );
      },
    },
    {
      field: CustomerPortalOrderTableField.OFD_OFP,
      headerName: getCustomerPortalOrdersTableFieldCopy(
        CustomerPortalOrderTableField.OFD_OFP,
        segment,
      ),
      resizable: true,
      minWidth: 120,
      cellRenderer: (
        params: ICellRendererParams<CustomerPortalOrderForTableFragment>,
      ) => {
        return getCustomerPortalOrdersTableField(
          CustomerPortalOrderTableField.OFD_OFP,
          params.data,
          timeZone,
        );
      },
    },
    {
      headerName: '',
      field: ExcludeFromHiding.EMPTY_SPACE,
    },
    {
      field: ExcludeFromHiding.BUTTON,
      headerName: '',
      minWidth: 120,
      // eslint-disable-next-line react/no-unstable-nested-components
      cellRenderer: (params: ICellRendererParams) => {
        return <OpenCustomerPortalOrderButton params={params} />;
      },
      pinned: 'right',
    },
  ];

  const [columnDefs, setColumnDefs] = // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useState<Array<ColDef<CustomerPortalOrderForTableFragment>>>(columns);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setColumnDefs(columns);
    // Ensuring that the columns are updated when the segment or time zone changes. The timezone is a necessary dependency since some datetime fields rely on the timezone for proper formatting.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segment, timeZone]);

  const thirdPartyUserUuid = useMemo(() => {
    return thirdPartyUser?.uuid;
  }, [thirdPartyUser]);

  const [getThirdPartyUser, { data: thirdPartyUserData }] =
    useThirdPartyUserByUuidLazyQuery();

  useEffect(() => {
    if (!isNil(thirdPartyUserUuid) && !isEmpty(thirdPartyUserUuid)) {
      getThirdPartyUser({ variables: { uuid: thirdPartyUserUuid } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thirdPartyUserUuid]);

  const companyName = firstCompany?.name;
  const contactName =
    thirdPartyUserData?.thirdPartyUserByUuid?.contacts?.[0]?.displayName;
  return (
    <Stack
      direction="column"
      sx={{
        backgroundColor: '#F7F7F7',
        height: '100vh',
        overflowY: 'auto',
      }}
    >
      <CustomerPortalAppBar>
        <Typography sx={styles.appBarTypography}>
          {distinctCompanies.length === 1
            ? `Your orders from ${companyName}`
            : 'Your orders'}
        </Typography>
      </CustomerPortalAppBar>
      <Box
        sx={{
          height: 'calc(100dvh - 64px)',
          paddingTop: '16px',
          backgroundColor: '#fff',
        }}
      >
        {thirdPartyUser?.contacts?.length === 0 ? (
          <Box sx={styles.noContactsMessage}>
            <Typography>
              You currently do not have access to view customer orders.
              <br />
              Please provide your email address directly to the company to
              request access.
            </Typography>
          </Box>
        ) : (
          <>
            <Box sx={{ marginLeft: 4 }}>
              <Typography sx={{ fontSize: '16px', fontWeight: 500, mb: 1 }}>
                {thirdPartyUser?.contacts.length === 1 && (
                  <>Customer: {contactName}</>
                )}
              </Typography>
            </Box>
            {!isNil(thirdPartyUserUuid) && (
              <Box sx={{ marginLeft: 4, marginRight: 4, minHeight: '100%' }}>
                <CustomerPortalOrdersTableWithFiltersAgGrid
                  shouldRememberFilters
                  columnDefinitions={columnDefs}
                  pageSize={ORDERS_PAGE_SIZE}
                  pageType={FilterViewPage.CustomerPortalOrders}
                  companies={distinctCompanies}
                  contacts={thirdPartyUser?.contacts ?? []}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </Stack>
  );
};

export default CustomerLoadsView;
