import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import pluralize from 'pluralize';
import { useEffect, useMemo, useState } from 'react';
import { getDefaultDunningReportHeaderFooter } from 'shared/copy';
import {
  validateInvoiceEmailSubject,
  type InvoiceEmailSubjectValidationResponse,
} from 'shared/email';
import { MAX_INTEGER } from 'shared/math';
import { getPermissionsFlags } from 'shared/roles';
import useDocuments from '../../../../common/react-hooks/use-documents';
import useMe from '../../../../common/react-hooks/use-me';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import {
  type DocumentType,
  FuelBillingMethod,
  MeasurementUnits,
  MeDocument,
  PermissionResource,
  useDriverSettlementBillsCountQuery,
  useSenderIsVerifiedQuery,
  useUpdateCompanyConfigurationMutation,
  useUpdateCompanyMutation,
  useUpdateInvoiceEmailMutation,
  WeightUnits,
} from '../../../../generated/graphql';

const getValueWithinBounds = (amount: number, max?: number) => {
  if (amount < 0) {
    return 0;
  }
  if (!isNil(max) && amount > max) {
    return max;
  }
  if (amount > MAX_INTEGER) {
    return MAX_INTEGER;
  }
  return amount;
};

const Billing = () => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteCompanyBilling } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyBilling,
  );

  const [
    defaultFuelSurchargeBillingMethod,
    setDefaultFuelSurchargeBillingMethod,
  ] = useState<FuelBillingMethod | undefined>();
  const [defaultFuelSurcharge, setDefaultFuelSurcharge] = useState<
    number | undefined | null
  >(null);
  const [invoiceEmail, setInvoiceEmail] = useState<string | null>(null);
  const { data: companySenderIsVerified } = useSenderIsVerifiedQuery();
  const [updateInvoiceEmail, { loading: updateInvoiceEmailLoading }] =
    useUpdateInvoiceEmailMutation();
  const [
    showVerificationEmailSentSnackbar,
    setShowVerificationEmailSentSnackbar,
  ] = useState<boolean>(false);
  const [showVerificationErrorSnackbar, setShowVerificationErrorSnackbar] =
    useState<boolean>(false);
  const [
    defaultFuelSettlementPercentageRate,
    setDefaultFuelSettlementPercentageRate,
  ] = useState<number | undefined | null>(null);
  const [defaultDimFactor, setDefaultDimFactor] = useState<
    number | undefined | null
  >(null);
  const [defaultWeightUnits, setDefaultWeightUnits] =
    useState<WeightUnits | null>(null);
  const [defaultMeasurementUnits, setDefaultMeasurementUnits] =
    useState<MeasurementUnits | null>(null);
  const [startingInvoiceOrdinalNumber, setStartingInvoiceOrdinalNumber] =
    useState<number | undefined | null>(null);
  const [useJournalNumberForInvoice, setUseJournalNumberForInvoice] =
    useState<boolean>(false);
  const [defaultInvoiceDocumentsRequired, setDefaultInvoiceDocumentsRequired] =
    useState<DocumentType[]>([]);
  const [invoiceNotes, setInvoiceNotes] = useState<string>();
  const [invoiceEmailSubject, setInvoiceEmailSubject] = useState<string>();
  const [customDunningReportHeader, setCustomDunningReportHeader] = useState<
    string | null
  >();
  const [customDunningReportFooter, setCustomDunningReportFooter] = useState<
    string | null
  >();
  const [useDefaultDunningReportHeader, setUseDefaultDunningReportHeader] =
    useState<boolean>(true);
  const [useDefaultDunningReportFooter, setUseDefaultDunningReportFooter] =
    useState<boolean>(true);
  const [
    updateCompanyConfiguration,
    { loading: isSavingCompanyConfiguration },
  ] = useUpdateCompanyConfigurationMutation({
    refetchQueries: [MeDocument],
  });
  const [updateCompany, { loading: isSavingCompany }] =
    useUpdateCompanyMutation({
      refetchQueries: [MeDocument],
    });
  const isSaving = isSavingCompanyConfiguration || isSavingCompany;
  const {
    companyConfiguration,
    companyData,
    email,
    defaultWeightUnits: currentDefaultWeightUnits,
    defaultMeasurementUnits: currentDefaultMeasurementUnits,
    refetchMeData,
  } = useMe();

  const { data: driverSettlementBillsCountData } =
    useDriverSettlementBillsCountQuery();
  const driverSettlementBillsCount =
    driverSettlementBillsCountData?.driverSettlementBillsCountForCompany;
  const canNotTurnOffDriverSettlementInBilling =
    companyConfiguration?.showDriverSettlementInBilling === true &&
    driverSettlementBillsCount !== 0;

  const { documentTypesForSelection, getDocumentTypeCopy, loading } =
    useDocuments();

  enum InvoiceIdentifiers {
    InvoiceName = 'INVOICE_NAME',
    JournalNumber = 'JOURNAL_NUMBER',
  }

  useEffect(() => {
    setDefaultWeightUnits(currentDefaultWeightUnits ?? null);
    setDefaultMeasurementUnits(currentDefaultMeasurementUnits ?? null);
    setDefaultFuelSurchargeBillingMethod(
      companyConfiguration?.defaultFuelSurchargeBillingMethod,
    );
    setDefaultFuelSurcharge(
      companyConfiguration?.defaultFuelSurcharge ?? undefined,
    );
    setDefaultDimFactor(companyConfiguration?.defaultDimFactor ?? undefined);
    setDefaultFuelSettlementPercentageRate(
      companyConfiguration?.defaultFuelSettlementPercentageRate ?? undefined,
    );
    setStartingInvoiceOrdinalNumber(
      companyConfiguration?.startingInvoiceOrdinalNumber ?? undefined,
    );
    setUseJournalNumberForInvoice(
      companyConfiguration?.useJournalNumberForInvoice ?? false,
    );
    setDefaultInvoiceDocumentsRequired(
      companyConfiguration?.defaultInvoiceDocumentsRequired ?? [],
    );
    setInvoiceEmail(invoiceEmail ?? null);
    setInvoiceNotes(companyConfiguration?.invoiceNotes ?? undefined);
    setInvoiceEmailSubject(
      companyData?.defaultInvoiceEmailSubject ?? undefined,
    );
    setCustomDunningReportHeader(
      companyData?.customDunningReportHeader ?? null,
    );
    setCustomDunningReportFooter(
      companyData?.customDunningReportFooter ?? null,
    );
    setUseDefaultDunningReportHeader(
      isNil(companyData?.customDunningReportHeader),
    );
    setUseDefaultDunningReportFooter(
      isNil(companyData?.customDunningReportFooter),
    );
  }, [
    currentDefaultWeightUnits,
    currentDefaultMeasurementUnits,
    companyConfiguration,
    companyData,
    invoiceEmail,
  ]);

  const validationResponse =
    useMemo<InvoiceEmailSubjectValidationResponse>(() => {
      return validateInvoiceEmailSubject(invoiceEmailSubject ?? '');
    }, [invoiceEmailSubject]);

  const {
    header: defaultDunningReportHeader,
    footer: defaultDunningReportFooter,
  } = useMemo(() => {
    return getDefaultDunningReportHeaderFooter(email ?? '');
  }, [email]);

  const updateCompanyConfig = async () => {
    const configUuid = companyConfiguration?.uuid;
    if (isNil(configUuid)) {
      // eslint-disable-next-line no-console
      console.error('No company configuration found, could not save');
    } else {
      await updateCompanyConfiguration({
        variables: {
          updateCompanyConfigurationInput: {
            uuid: configUuid,
            defaultFuelSurchargeBillingMethod,
            defaultFuelSurcharge:
              isNil(defaultFuelSurcharge) ||
              defaultFuelSurchargeBillingMethod === FuelBillingMethod.FlatRate
                ? null
                : getValueWithinBounds(defaultFuelSurcharge),
            defaultDimFactor: isNil(defaultDimFactor)
              ? null
              : getValueWithinBounds(defaultDimFactor),
            defaultFuelSettlementPercentageRate: isNil(
              defaultFuelSettlementPercentageRate,
            )
              ? null
              : getValueWithinBounds(defaultFuelSettlementPercentageRate, 100),
            startingInvoiceOrdinalNumber: isNil(startingInvoiceOrdinalNumber)
              ? null
              : startingInvoiceOrdinalNumber,
            useJournalNumberForInvoice: isNil(useJournalNumberForInvoice)
              ? false
              : useJournalNumberForInvoice,
            defaultInvoiceDocumentsRequired,
            invoiceNotes,
          },
        },
        refetchQueries: [MeDocument],
      });
      refetchMeData();
    }
  };

  const toggleDefaultToAccessorial = async (checked: boolean) => {
    const configUuid = companyConfiguration?.uuid;
    if (!isNil(configUuid)) {
      await updateCompanyConfiguration({
        variables: {
          updateCompanyConfigurationInput: {
            uuid: configUuid,
            defaultToAccessorial: checked,
          },
        },
        refetchQueries: [MeDocument],
      });
      refetchMeData();
    }
  };

  const toggleAddOrdersToOpenInvoices = async (checked: boolean) => {
    const configUuid = companyConfiguration?.uuid;
    if (!isNil(configUuid)) {
      await updateCompanyConfiguration({
        variables: {
          updateCompanyConfigurationInput: {
            uuid: configUuid,
            addOrdersToOpenInvoices: checked,
          },
        },
        refetchQueries: [MeDocument],
      });
      refetchMeData();
    }
  };

  const saveInvoiceEmail = async () => {
    if (!isNil(invoiceEmail)) {
      const res = await updateInvoiceEmail({
        variables: {
          input: invoiceEmail,
        },
      });
      refetchMeData();
      if (isNil(res.data?.updateInvoiceEmail.sendgridSenderId)) {
        setShowVerificationErrorSnackbar(true);
      } else {
        setShowVerificationEmailSentSnackbar(true);
      }
    }
  };

  if (loading) {
    return null;
  }

  return (
    <Box sx={{ pt: 2 }}>
      <Stack direction="column" spacing={3}>
        <Grid container>
          <Grid item xs={4} sx={{ paddingRight: '20px' }}>
            <Typography sx={{ mb: '10px', color: 'black', fontWeight: 'bold' }}>
              Default Dim Factor
            </Typography>
            <FormControl sx={{ width: '100%' }}>
              <TextField
                size="small"
                inputProps={{ pattern: '[0-9]*' }}
                value={defaultDimFactor}
                disabled={!canWriteCompanyBilling}
                onChange={(e) => {
                  const parsed = Number.parseFloat(e.target.value);
                  if (Number.isNaN(parsed)) {
                    setDefaultDimFactor(undefined);
                  } else {
                    setDefaultDimFactor(parsed);
                  }
                }}
                onBlur={updateCompanyConfig}
              />
            </FormControl>
          </Grid>
          <Grid item xs={8}>
            <Typography
              sx={{ fontSize: '12px', color: 'gray', float: 'right' }}
            >
              {isSaving ? 'Saving...' : 'Saved'}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container>
          <Grid item md={4} xs={12} sx={{ pr: 2 }}>
            <Typography sx={{ mb: '10px', color: 'black', fontWeight: 'bold' }}>
              Default Fuel Surcharge Billing Method
            </Typography>
            <FormControl sx={{ width: '100%' }}>
              <Select
                required
                value={defaultFuelSurchargeBillingMethod ?? ''}
                size="small"
                sx={{ backgroundColor: 'white' }}
                disabled={!canWriteCompanyBilling}
                onChange={(e) => {
                  setDefaultFuelSurchargeBillingMethod(
                    e.target.value as FuelBillingMethod,
                  );
                }}
                onBlur={async () => updateCompanyConfig()}
              >
                {Object.values(FuelBillingMethod).map((method) => (
                  <MenuItem key={method} id={method} value={method}>
                    {sentenceCase(method)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12}>
            <Stack direction="row">
              <Typography
                sx={{ mb: '10px', color: 'black', fontWeight: 'bold' }}
              >
                Default Fuel Surcharge
              </Typography>
              <Tooltip
                arrow
                title="Does not apply if default billing method is set to flat rate"
                placement="right"
              >
                <InfoIcon sx={{ width: '16px', mx: 1, color: 'gray' }} />
              </Tooltip>
            </Stack>

            <FormControl sx={{ width: '100%' }}>
              <TextField
                size="small"
                type="number"
                disabled={
                  defaultFuelSurchargeBillingMethod !==
                    FuelBillingMethod.Percentage || !canWriteCompanyBilling
                }
                value={defaultFuelSurcharge}
                onWheel={(e) => {
                  (e.target as HTMLTextAreaElement).blur();
                }}
                onChange={(e) => {
                  const parsed = Number.parseFloat(e.target.value);
                  if (Number.isNaN(parsed)) {
                    setDefaultFuelSurcharge(undefined);
                  } else {
                    setDefaultFuelSurcharge(parsed);
                  }
                }}
                onBlur={updateCompanyConfig}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Divider />
        <Grid container>
          <Grid item md={4} xs={12} sx={{ pr: 2 }}>
            <Stack direction="row">
              <Typography
                sx={{ mb: '10px', color: 'black', fontWeight: 'bold' }}
              >
                Default Fuel Settlement Rate
              </Typography>
            </Stack>

            <FormControl sx={{ width: '100%' }}>
              <TextField
                size="small"
                type="number"
                value={defaultFuelSettlementPercentageRate}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                disabled={!canWriteCompanyBilling}
                onWheel={(e) => {
                  (e.target as HTMLTextAreaElement).blur();
                }}
                onChange={(e) => {
                  const parsed = Number.parseFloat(e.target.value);
                  if (Number.isNaN(parsed)) {
                    setDefaultFuelSettlementPercentageRate(undefined);
                  } else {
                    setDefaultFuelSettlementPercentageRate(parsed);
                  }
                }}
                onBlur={updateCompanyConfig}
              />
            </FormControl>
          </Grid>
        </Grid>
        {companyConfiguration?.showKilogramsAndCentimeters !== true && (
          <>
            <Divider />
            <Grid container>
              <Grid item md={4} xs={12} sx={{ paddingRight: '20px' }}>
                <Typography
                  sx={{ mb: '10px', color: 'black', fontWeight: 'bold' }}
                >
                  Default Weight Units
                </Typography>
                <FormControl sx={{ width: '100%' }}>
                  <Select
                    required
                    value={defaultWeightUnits ?? ''}
                    size="small"
                    sx={{ backgroundColor: 'white' }}
                    disabled={!canWriteCompanyBilling}
                    onChange={async (e) => {
                      await updateCompany({
                        variables: {
                          updateCompanyInput: {
                            defaultWeightUnits:
                              (e.target.value as WeightUnits) ?? undefined,
                            defaultMeasurementUnits:
                              defaultMeasurementUnits ?? undefined,
                          },
                        },
                      });
                    }}
                  >
                    {Object.values(WeightUnits).map((weightUnits) => (
                      <MenuItem
                        key={weightUnits}
                        id={weightUnits}
                        value={weightUnits}
                      >
                        {sentenceCase(weightUnits)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography
                  sx={{ mb: '10px', color: 'black', fontWeight: 'bold' }}
                >
                  Default Measurement Units
                </Typography>
                <FormControl sx={{ width: '100%' }}>
                  <Select
                    required
                    value={defaultMeasurementUnits ?? ''}
                    size="small"
                    sx={{ backgroundColor: 'white' }}
                    disabled={!canWriteCompanyBilling}
                    onChange={async (e) => {
                      await updateCompany({
                        variables: {
                          updateCompanyInput: {
                            defaultWeightUnits: defaultWeightUnits ?? undefined,
                            defaultMeasurementUnits:
                              (e.target.value as MeasurementUnits) ?? undefined,
                          },
                        },
                      });
                    }}
                  >
                    {Object.values(MeasurementUnits).map((measurementUnits) => (
                      <MenuItem
                        key={measurementUnits}
                        id={measurementUnits}
                        value={measurementUnits}
                      >
                        {sentenceCase(measurementUnits)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </>
        )}
        <Divider />
        <Grid container>
          <Grid item xs={12} md={4} sx={{ paddingRight: '20px' }}>
            <Typography sx={{ mb: '10px', color: 'black', fontWeight: 'bold' }}>
              Starting Journal Number
            </Typography>
            <FormControl sx={{ width: '100%' }}>
              <TextField
                size="small"
                inputProps={{ pattern: '[0-9]*' }}
                value={startingInvoiceOrdinalNumber}
                disabled={!canWriteCompanyBilling}
                onChange={(e) => {
                  const parsed = Number.parseFloat(e.target.value);
                  if (Number.isNaN(parsed)) {
                    setStartingInvoiceOrdinalNumber(undefined);
                  } else {
                    setStartingInvoiceOrdinalNumber(parsed);
                  }
                }}
                onBlur={updateCompanyConfig}
              />
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12} sx={{ paddingRight: '20px' }}>
            <Typography sx={{ mb: '10px', color: 'black', fontWeight: 'bold' }}>
              Invoice Identifier
            </Typography>
            <FormControl sx={{ width: '100%' }}>
              <Select
                required
                value={
                  useJournalNumberForInvoice
                    ? InvoiceIdentifiers.JournalNumber
                    : InvoiceIdentifiers.InvoiceName
                }
                size="small"
                sx={{ backgroundColor: 'white' }}
                disabled={!canWriteCompanyBilling}
                onChange={(e) => {
                  if (e.target.value === InvoiceIdentifiers.JournalNumber) {
                    setUseJournalNumberForInvoice(true);
                  } else {
                    setUseJournalNumberForInvoice(false);
                  }
                }}
                onBlur={updateCompanyConfig}
              >
                {Object.values(InvoiceIdentifiers).map((identifier) => (
                  <MenuItem key={identifier} id={identifier} value={identifier}>
                    {sentenceCase(identifier)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12} sx={{ paddingRight: '20px' }}>
            <Typography sx={{ mb: '10px', color: 'black', fontWeight: 'bold' }}>
              Required Documents
            </Typography>
            <FormControl sx={{ width: '100%' }}>
              <Select<DocumentType[]>
                multiple
                displayEmpty
                required
                notched
                value={defaultInvoiceDocumentsRequired}
                size="small"
                sx={{ backgroundColor: 'white' }}
                renderValue={(selected) =>
                  selected.length === 0 ? (
                    <Box>None</Box>
                  ) : (
                    <Box>
                      {selected.map((value) => (
                        <Chip key={value} label={sentenceCase(value)} />
                      ))}
                    </Box>
                  )
                }
                onChange={(e) => {
                  setDefaultInvoiceDocumentsRequired(
                    e.target.value as DocumentType[],
                  );
                }}
                onBlur={updateCompanyConfig}
              >
                {documentTypesForSelection.map((docType) => (
                  <MenuItem key={docType} id={docType} value={docType}>
                    {getDocumentTypeCopy({
                      documentType: docType,
                    })}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={12} xs={12}>
            <Typography sx={{ mb: '10px', color: 'black', fontWeight: 'bold' }}>
              Invoice Email
            </Typography>
            <Stack spacing={1}>
              <Stack direction="row" spacing={1}>
                <TextField
                  value={invoiceEmail}
                  size="small"
                  disabled={!canWriteCompanyBilling}
                  sx={{ width: '400px' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {companySenderIsVerified?.senderIsVerified ===
                        'true' ? (
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <Typography color="success" variant="caption">
                              Verified
                            </Typography>
                            <CheckCircleIcon
                              sx={{ fontSize: 15 }}
                              color="success"
                            />
                          </Stack>
                        ) : (
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <Typography color="error" variant="caption">
                              Not verified
                            </Typography>
                            <CloseIcon sx={{ fontSize: 15 }} color="error" />
                          </Stack>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    setInvoiceEmail(e.target.value);
                  }}
                />
                <Button
                  disabled={
                    !canWriteCompanyBilling || updateInvoiceEmailLoading
                  }
                  endIcon={
                    updateInvoiceEmailLoading && <CircularProgress size={15} />
                  }
                  variant="contained"
                  onClick={saveInvoiceEmail}
                >
                  Save and Verify
                </Button>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Stack direction="row">
              <Typography sx={{ color: 'black', fontWeight: 'bold' }}>
                Invoice Email Subject
              </Typography>
              <Tooltip
                arrow
                title="Default invoice email subject. Overridden by contact specific settings."
                placement="right"
              >
                <InfoIcon sx={{ width: '16px', mx: 1, color: 'gray' }} />
              </Tooltip>
            </Stack>
            <Typography variant="caption" sx={{ mb: '10px', color: 'gray' }}>
              Variables available: {'{{company}}'}, {'{{invoice}}'},{' '}
              {'{{customer}}'}
            </Typography>
            <TextField
              fullWidth
              sx={{ mt: '10px' }}
              value={invoiceEmailSubject}
              error={validationResponse.result === 'invalid'}
              helperText={
                validationResponse.result === 'invalid'
                  ? 'Invalid template format. Example: Invoice {{invoice}} from {{company}} for {{customer}}'
                  : ''
              }
              disabled={!canWriteCompanyBilling}
              onChange={(e) => {
                setInvoiceEmailSubject(e.target.value);
              }}
              onBlur={async () => {
                if (
                  validationResponse.result === 'valid' ||
                  validationResponse.result === 'empty'
                ) {
                  await updateCompany({
                    variables: {
                      updateCompanyInput: {
                        defaultInvoiceEmailSubject:
                          invoiceEmailSubject === ''
                            ? null
                            : invoiceEmailSubject,
                      },
                    },
                  });
                }
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Typography sx={{ mb: '10px', color: 'black', fontWeight: 'bold' }}>
              Invoice Notes
            </Typography>
            <TextField
              fullWidth
              multiline
              value={invoiceNotes}
              disabled={!canWriteCompanyBilling}
              maxRows={6}
              onChange={(e) => {
                setInvoiceNotes(e.target.value);
              }}
              onBlur={updateCompanyConfig}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            xl={12}
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              alignItems: 'center',
            }}
          >
            <Checkbox
              checked={companyConfiguration?.addOrdersToOpenInvoices === true}
              disabled={!canWriteCompanyBilling}
              onChange={async (e) => {
                await toggleAddOrdersToOpenInvoices(e.target.checked);
              }}
            />
            <Typography sx={{ color: 'black', fontWeight: 'bold' }}>
              Add orders to open invoices
            </Typography>
          </Grid>
          <Grid
            item
            xl={12}
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              alignItems: 'center',
            }}
          >
            <Checkbox
              checked={
                companyConfiguration?.includeHyphensInInvoiceName === true
              }
              disabled={!canWriteCompanyBilling}
              onChange={async (e) => {
                const configUuid = companyConfiguration?.uuid;
                if (!isNil(configUuid)) {
                  await updateCompanyConfiguration({
                    variables: {
                      updateCompanyConfigurationInput: {
                        uuid: configUuid,
                        includeHyphensInInvoiceName: e.target.checked,
                      },
                    },
                  });
                }
              }}
            />
            <Typography sx={{ color: 'black', fontWeight: 'bold' }}>
              Include hyphens in invoice name (e.g. ABC-INV-123 vs ABCINV123)
            </Typography>
          </Grid>
          <Grid
            item
            xl={12}
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              alignItems: 'center',
            }}
          >
            <Checkbox
              checked={companyConfiguration?.includeINVInInvoiceName === true}
              disabled={!canWriteCompanyBilling}
              onChange={async (e) => {
                const configUuid = companyConfiguration?.uuid;
                if (!isNil(configUuid)) {
                  await updateCompanyConfiguration({
                    variables: {
                      updateCompanyConfigurationInput: {
                        uuid: configUuid,
                        includeINVInInvoiceName: e.target.checked,
                      },
                    },
                  });
                }
              }}
            />
            <Typography sx={{ color: 'black', fontWeight: 'bold' }}>
              Include INV in invoice name (e.g. ABC-INV-123 vs ABC-123)
            </Typography>
          </Grid>
          <Grid
            item
            xl={12}
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              alignItems: 'center',
            }}
          >
            <Checkbox
              checked={companyData?.blockSendingZeroDollarInvoices === true}
              disabled={!canWriteCompanyBilling || isSavingCompany}
              onChange={async (e) => {
                await updateCompany({
                  variables: {
                    updateCompanyInput: {
                      blockSendingZeroDollarInvoices: e.target.checked,
                    },
                  },
                });
              }}
            />
            <Typography sx={{ color: 'black', fontWeight: 'bold' }}>
              Block sending $0 invoices
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container>
          <Grid item xs={12}>
            <Typography sx={{ mb: '10px', color: 'black', fontWeight: 'bold' }}>
              Customized Dunning Report
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '2px',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ color: 'black', fontWeight: 'bold' }}>
              Header
            </Typography>
            <Checkbox
              checked={useDefaultDunningReportHeader}
              disabled={!canWriteCompanyBilling || isSavingCompany}
              sx={{ ml: '5px' }}
              onChange={async (e) => {
                setUseDefaultDunningReportHeader(e.target.checked);
                const newHeader = e.target.checked
                  ? null
                  : defaultDunningReportHeader;
                setCustomDunningReportHeader(newHeader);
                await updateCompany({
                  variables: {
                    updateCompanyInput: {
                      customDunningReportHeader: newHeader,
                    },
                  },
                });
              }}
            />
            <Typography sx={{ color: 'black' }}>Use default</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              alignItems: 'center',
            }}
          >
            <TextField
              fullWidth
              multiline
              value={customDunningReportHeader ?? defaultDunningReportHeader}
              disabled={
                !canWriteCompanyBilling || useDefaultDunningReportHeader
              }
              onChange={async (e) => {
                setCustomDunningReportHeader(e.target.value);
              }}
            />
            <Button
              disabled={!canWriteCompanyBilling || isSavingCompany}
              variant="contained"
              onClick={async () => {
                await updateCompany({
                  variables: {
                    updateCompanyInput: {
                      customDunningReportHeader,
                    },
                  },
                });
              }}
            >
              Save
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '2px',
              mt: '10px',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ color: 'black', fontWeight: 'bold' }}>
              Footer
            </Typography>
            <Checkbox
              checked={useDefaultDunningReportFooter}
              disabled={!canWriteCompanyBilling || isSavingCompany}
              sx={{ ml: '5px' }}
              onChange={async (e) => {
                setUseDefaultDunningReportFooter(e.target.checked);
                const newFooter = e.target.checked
                  ? null
                  : defaultDunningReportFooter;
                setCustomDunningReportFooter(newFooter);
                await updateCompany({
                  variables: {
                    updateCompanyInput: {
                      customDunningReportFooter: newFooter,
                    },
                  },
                });
              }}
            />
            <Typography sx={{ color: 'black' }}>Use default</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              alignItems: 'center',
            }}
          >
            <TextField
              fullWidth
              multiline
              value={customDunningReportFooter ?? defaultDunningReportFooter}
              disabled={
                !canWriteCompanyBilling || useDefaultDunningReportFooter
              }
              onChange={async (e) => {
                setCustomDunningReportFooter(e.target.value);
              }}
            />
            <Button
              disabled={!canWriteCompanyBilling || isSavingCompany}
              variant="contained"
              onClick={async () => {
                await updateCompany({
                  variables: {
                    updateCompanyInput: {
                      customDunningReportFooter,
                    },
                  },
                });
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
        <Divider />
        <Grid container>
          <Grid
            item
            xl={4}
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              alignItems: 'center',
            }}
          >
            <Checkbox
              checked={companyConfiguration?.defaultToAccessorial === true}
              disabled={!canWriteCompanyBilling}
              onChange={async (e) => {
                await toggleDefaultToAccessorial(e.target.checked);
              }}
            />
            <Typography sx={{ color: 'black', fontWeight: 'bold' }}>
              Default custom charges to accessorial
            </Typography>
          </Grid>
          <Grid
            item
            xl={4}
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              alignItems: 'center',
            }}
          >
            <Tooltip
              title={
                canNotTurnOffDriverSettlementInBilling
                  ? `Must delete ${driverSettlementBillsCount} settlement ${pluralize('bill', driverSettlementBillsCount)} to disable`
                  : null
              }
              placement="top-start"
            >
              <div>
                <Checkbox
                  checked={
                    companyConfiguration?.showDriverSettlementInBilling === true
                  }
                  disabled={
                    !canWriteCompanyBilling ||
                    canNotTurnOffDriverSettlementInBilling
                  }
                  onChange={async (e) => {
                    const configUuid = companyConfiguration?.uuid;
                    if (!isNil(configUuid)) {
                      await updateCompanyConfiguration({
                        variables: {
                          updateCompanyConfigurationInput: {
                            uuid: configUuid,
                            showDriverSettlementInBilling: e.target.checked,
                          },
                        },
                      });
                    }
                  }}
                />
              </div>
            </Tooltip>
            <Typography sx={{ color: 'black', fontWeight: 'bold' }}>
              Show driver settlement in billing
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container>
          <Grid
            item
            xl={4}
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              alignItems: 'center',
            }}
          >
            <Tooltip
              title="Enables Sage BusinessWorks invoice CSV export"
              placement="top-start"
            >
              <div>
                <Checkbox
                  checked={companyData?.useSageBusinessWorks === true}
                  disabled={!canWriteCompanyBilling || isSavingCompany}
                  onChange={async (e) => {
                    await updateCompany({
                      variables: {
                        updateCompanyInput: {
                          useSageBusinessWorks: e.target.checked,
                        },
                      },
                    });
                  }}
                />
              </div>
            </Tooltip>
            <Typography sx={{ color: 'black', fontWeight: 'bold' }}>
              Use Sage BusinessWorks
            </Typography>
          </Grid>
        </Grid>
      </Stack>
      <Snackbar
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showVerificationEmailSentSnackbar}
        onClose={() => {
          setShowVerificationEmailSentSnackbar(false);
        }}
      >
        <Alert color="success">
          We have sent a verification email to {invoiceEmail}.<br />
          Please confirm to enable sending from this address.
        </Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showVerificationErrorSnackbar}
        onClose={() => {
          setShowVerificationErrorSnackbar(false);
        }}
      >
        <Alert color="error">
          An error occurred - failed to send verification email.
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Billing;
