import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { type StopOnRouteBySearchTextFragment } from '../../../../../generated/graphql';
import StopOnRouteFoundCard from './stop-on-route-found-card';

const StopsOnRoutesFoundDialog = ({
  stopsOnRoutes,
  onClose,
}: {
  readonly stopsOnRoutes: StopOnRouteBySearchTextFragment[];
  readonly onClose: () => void;
}) => {
  return (
    <Dialog open={!isEmpty(stopsOnRoutes)} onClose={onClose}>
      <DialogTitle>Found stops already on routes</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Stack spacing={1}>
            {stopsOnRoutes.map((stopOnRoute) => (
              <StopOnRouteFoundCard stop={stopOnRoute} />
            ))}
          </Stack>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default StopsOnRoutesFoundDialog;
