import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Divider,
  FormControl,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import React, {
  type Dispatch,
  type SetStateAction,
  useMemo,
  useState,
} from 'react';
import AccessorialFilterButton, {
  type AccessorialOption,
} from '../../../../../common/components/accessorial-filter-button';
import DateDropdownPicker, {
  type DateOption,
  defaultPast1WeekDateRangeOption,
} from '../../../../../common/components/date-dropdown-picker';
import TerminalFilterButton from '../../../../../common/components/terminal-filter-button';
import { type Option } from '../../../../../common/filters/types';
import { useMeasureExecutionTime } from '../../../../../common/react-hooks/use-measure-execution-time';
import useTerminals from '../../../../../common/react-hooks/use-terminals';
import {
  type AccessorialChargeDetailsDataFragment,
  FilterOperator,
  InvoiceStatus,
  useAccessorialChargeDetailsReportLazyQuery,
  useMeQuery,
} from '../../../../../generated/graphql';
import useInvoicesStore from '../../../invoices-store';
import styles from '../../../styles';
import { InvoiceStatusTab } from '../../../types/types';
import { downloadAccessorialDetailsReport } from '../../../utils';
import { accountingReportDefaultDateOption } from '../../accounting-reports/constants';

type DownloadAccessorialDetailsReportModalProps = {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const DownloadAccessorialDetailsReportModal = ({
  isOpen,
  setIsOpen,
}: DownloadAccessorialDetailsReportModalProps) => {
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const [dateOption, setDateOption] = useState<DateOption>(
    defaultPast1WeekDateRangeOption,
  );
  const [terminalOption, setTerminalOption] = useState<Option | undefined>();
  const [accessorialOption, setAccessorialOption] = useState<
    AccessorialOption | undefined
  >();

  // For customers with a large number of contacts, including all contacts could make the result unecessarily large
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [includeContactsWithNoShipments, setIncludeContactsWithNoShipments] =
    useState<boolean>(false);

  const { data: companyData } = useMeQuery({
    fetchPolicy: 'cache-first',
  });
  const [getAccessorialDetails] = useAccessorialChargeDetailsReportLazyQuery();
  const [statusTab, setStatusTab] = useState<InvoiceStatusTab>(
    InvoiceStatusTab.ALL,
  );
  const createFileDownload = useInvoicesStore(
    (state) => state.createFileDownload,
  );

  const downloadPDF = async (
    accessorialDetails: AccessorialChargeDetailsDataFragment[],
  ) => {
    await downloadAccessorialDetailsReport({
      companyData,
      startDate: dateOption.startDate,
      endDate: dateOption.endDate,
      status: statusTab,
      accessorialDetails,
      terminalOption,
      accessorialOption,
      terminalsEnabled,
    });

    setDateOption(accountingReportDefaultDateOption);
    setTerminalOption(undefined);
    setAccessorialOption(undefined);
    setStatusTab(InvoiceStatusTab.ALL);
  };

  const accessorialDetailsReportPdfInput = useMemo(() => {
    let statuses = [
      InvoiceStatus.NotFinalized,
      InvoiceStatus.ReadyToInvoice,
      InvoiceStatus.Invoiced,
    ];
    if (statusTab === InvoiceStatusTab.NOT_POSTED) {
      statuses = [InvoiceStatus.NotFinalized];
    } else if (statusTab === InvoiceStatusTab.POSTED) {
      statuses = [InvoiceStatus.ReadyToInvoice, InvoiceStatus.Invoiced];
    }

    return {
      invoiceStatuses: statuses,
      invoiceDateFilters: {
        filterOperator: FilterOperator.And,
        startFilterValue: dateOption.startDate,
        endFilterValue: dateOption.endDate,
      },
      terminalUuid: terminalOption?.value ?? undefined,
      accessorialUuid: accessorialOption?.value ?? undefined,
      includeContactsWithNoShipments,
    };
  }, [
    statusTab,
    dateOption,
    terminalOption,
    accessorialOption,
    includeContactsWithNoShipments,
  ]);

  const startDownload = async () => {
    setIsOpen(false);
    const completeDownload = createFileDownload();

    const res = await getAccessorialDetails({
      variables: {
        args: accessorialDetailsReportPdfInput,
      },
    });

    await downloadPDF(res.data?.accessorialChargeDetailsReport ?? []);
    completeDownload();
  };

  const startDownloadWithMeasurement = useMeasureExecutionTime({
    fn: startDownload,
    rumLabel: 'download-accessorial-details-report',
    logData: accessorialDetailsReportPdfInput,
  });

  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <Box sx={[styles.modal, { height: '320px', width: '500px' }]}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={11} display="flex" flexDirection="row">
            <Typography sx={{ fontWeight: 'bold' }}>
              Download Accessorial Details Report
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              sx={{ float: 'right' }}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} display="flex" flexDirection="row" gap={1}>
            {/* For customers with a large number of accessorials, including all accessorial charge details could make the result unecessarily large */}
            <AccessorialFilterButton
              cacheId="INVOICES"
              prefixText="Accessorial"
              selectedOption={accessorialOption}
              handleChange={(option: AccessorialOption | undefined) => {
                setAccessorialOption(option);
              }}
              allowSelectAll={false}
            />
          </Grid>
          {terminalsEnabled && (
            <Grid item xs={12} display="flex" flexDirection="row" gap={1}>
              <TerminalFilterButton
                displayCode
                prefixText="Terminal"
                selectedOption={terminalOption}
                handleChange={(option: Option | null | undefined) => {
                  setTerminalOption(option ?? undefined);
                }}
                includeInactiveTerminals={false}
              />
            </Grid>
          )}
          <Grid item xs={12} display="flex" flexDirection="row" gap={1}>
            <DateDropdownPicker
              filterTitle="Invoice Date"
              dateOption={dateOption}
              setDateOption={setDateOption}
            />
            <FormControl sx={{ width: '35%' }}>
              <InputLabel id="invoice-status-label">Invoice Status</InputLabel>
              <Select
                required
                labelId="invoice-status-label"
                label="Invoice Status"
                value={statusTab}
                size="small"
                sx={{ backgroundColor: 'white' }}
                onChange={(e) => {
                  setStatusTab(e.target.value as InvoiceStatusTab);
                }}
              >
                {Object.values(InvoiceStatusTab).map((status) => (
                  <MenuItem key={status} id={status} value={status}>
                    {sentenceCase(status)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            {/* <Box */}
            {/*  sx={{ */}
            {/*    display: 'flex', */}
            {/*    flexDirection: 'row', */}
            {/*    alignItems: 'center', */}
            {/*    float: 'left', */}
            {/*  }} */}
            {/* > */}
            {/*  <Checkbox */}
            {/*    checked={includeContactsWithNoShipments} */}
            {/*    onChange={() => */}
            {/*      setIncludeContactsWithNoShipments( */}
            {/*        !includeContactsWithNoShipments, */}
            {/*      ) */}
            {/*    } */}
            {/*  /> */}
            {/*  <Typography>Show customers with 0 shipments</Typography> */}
            {/* </Box> */}
            <Box sx={{ float: 'right' }}>
              <Button
                sx={{ width: '100px' }}
                variant="contained"
                color="info"
                disabled={isNil(accessorialOption)}
                onClick={startDownloadWithMeasurement}
              >
                Download
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default DownloadAccessorialDetailsReportModal;
