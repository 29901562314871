import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { type ChangeEvent, useEffect, useState } from 'react';

type EditSavedViewModalProps = {
  readonly open: boolean;
  readonly setOpen: (isOpen: boolean) => void;
  readonly currentName: string | undefined;
  readonly editSavedView: (displayName: string) => void;
  /**
   * Don't propagate the click event to the parent elements.
   * Useful when the modal is rendered by a menu that should remain open.
   */
  readonly shouldStopPropagation?: true;
};

const EditSavedViewModal = ({
  open,
  setOpen,
  currentName,
  editSavedView,
  shouldStopPropagation,
}: EditSavedViewModalProps) => {
  const [name, setName] = useState(currentName ?? '');

  useEffect(() => {
    setName(currentName ?? '');
  }, [currentName]);

  const closeModal = () => {
    setOpen(false);
  };

  const handleChangeName = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setName(e.target.value);
  };

  const handleClickSave = () => {
    if (isEmpty(name)) {
      return;
    }
    editSavedView(name);
    setName('');
    closeModal();
  };

  if (isNil(currentName)) {
    return null;
  }

  return (
    <Dialog
      disableRestoreFocus
      open={open}
      onClick={
        shouldStopPropagation
          ? (e) => {
              e.stopPropagation();
            }
          : undefined
      }
      // If this modal was rendered by a menu item click, we don't want that
      // menu item to retain focus when keydown events are propagated to it.
      onKeyDown={
        shouldStopPropagation
          ? (e) => {
              e.stopPropagation();
            }
          : undefined
      }
      // This allows autofocusing the text field
      onClose={closeModal}
    >
      <DialogTitle>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6">Rename view</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ pl: 5, pr: 5, pt: 2 }}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              size="small"
              label="Name"
              InputProps={{ style: { backgroundColor: 'white' } }}
              value={name}
              onChange={handleChangeName}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1} sx={{ p: 3 }}>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <Button
              sx={{ float: 'right' }}
              variant="contained"
              disabled={isEmpty(name)}
              onClick={handleClickSave}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default EditSavedViewModal;
