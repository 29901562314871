import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { type PackageValues } from 'shared/types';
import { type Stringified } from 'type-fest';
import type { RootState } from '../../../redux/store';

export type PackageErrors = Stringified<
  Partial<Omit<PackageValues, 'uuid'>>
> & { uuid: string };

const packageErrorsAdapter = createEntityAdapter<PackageErrors>({
  selectId: (packageError) => packageError.uuid,
});

export const packageErrorsSlice = createSlice({
  name: 'packageErrors',
  initialState: packageErrorsAdapter.getInitialState(),
  reducers: {
    upsertOnePackageErrors: packageErrorsAdapter.upsertOne,
  },
});

export const { selectById: selectPackageErrorsById } =
  packageErrorsAdapter.getSelectors((state: RootState) => state.packageErrors);

export const { upsertOnePackageErrors } = packageErrorsSlice.actions;

const packageErrorsReducer = packageErrorsSlice.reducer;

export default packageErrorsReducer;
