import { centimeters, inches } from '@buge/ts-units/length';
import { kilograms } from '@buge/ts-units/mass';
import { joiResolver } from '@hookform/resolvers/joi';
import joi from 'joi';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { pounds } from 'shared/units/rates';
import { type StorageOrderStorageUnitFragment } from '../../../../generated/graphql';

type StorageUnitSpecsFormValues = {
  useKilograms: boolean;
  useCentimeters: boolean;
  weight: string | null | undefined;
  length: number | null | undefined;
  width: number | null | undefined;
  height: number | null | undefined;
};

const useStorageUnitSpecsForm = (
  storageUnit: StorageOrderStorageUnitFragment,
) => {
  const schema = joi.object<StorageUnitSpecsFormValues>({
    useKilograms: joi.boolean().required(),
    useCentimeters: joi.boolean().required(),
    weight: joi.string().optional(),
    length: joi.string().optional(),
    width: joi.string().optional(),
    height: joi.string().optional(),
  });
  const form = useForm<StorageUnitSpecsFormValues>({
    resolver: joiResolver(schema),
  });

  useEffect(() => {
    const useKilograms = form.getValues('useKilograms');
    const useCentimeters = form.getValues('useCentimeters');

    const weight =
      (useKilograms
        ? storageUnit.weight?.in(kilograms)
        : storageUnit.weight?.in(pounds)
      )?.amount.toString() ?? null;

    const length =
      (useCentimeters
        ? storageUnit.length?.in(centimeters)
        : storageUnit.length?.in(inches)
      )?.amount ?? null;

    const width =
      (useCentimeters
        ? storageUnit.width?.in(centimeters)
        : storageUnit.width?.in(inches)
      )?.amount ?? null;

    const height =
      (useCentimeters
        ? storageUnit.height?.in(centimeters)
        : storageUnit.height?.in(inches)
      )?.amount ?? null;

    form.reset({
      weight,
      length,
      width,
      height,
      useCentimeters,
      useKilograms,
    });
  }, [storageUnit.uuid]);

  return form;
};

export default useStorageUnitSpecsForm;
