import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Alert,
  Box,
  Button,
  IconButton,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { isNil } from 'lodash';
import type React from 'react';
import { useEffect, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import { shallow } from 'zustand/shallow';
import TabPanel from '../../common/components/tab-panel/tab-panel';
import UnauthorizedUserPermissions from '../../common/components/unauthorized-user-permissions';
import useTerminals from '../../common/react-hooks/use-terminals';
import useUserRoles from '../../common/react-hooks/use-user-roles';
import {
  PermissionResource,
  useBusinessDivisionsQuery,
  useReportGroupConfigurationsQuery,
} from '../../generated/graphql';
import Report from './components/report';
import ReportEditModal from './components/report-edit-modal';
import ReportsContextMenu from './components/reports-context-menu';
import useReport from './hooks/use-report';
import useReportsStore from './reports-store';

const ReportsPage = () => {
  const theme = useTheme();
  const [reportEditModalOpen, setReportEditModalOpen] =
    useState<boolean>(false);
  const [moreOptionsAnchorEl, setMoreOptionsAnchorEl] =
    useState<null | HTMLElement>(null);
  const { data: configData } = useReportGroupConfigurationsQuery();
  const {
    createReportGroupConfiguration,
    deleteReportGroupConfiguration,
    loadReport,
  } = useReport();
  const [currentReportIndex, setCurrentReportIndex] = useReportsStore(
    (state) => [state.currentReportIndex, state.setCurrentReportIndex],
    shallow,
  );
  const [
    isLoading,
    error,
    reportGroupConfigurations,
    setReportGroupConfigurations,
  ] = useReportsStore(
    (state) => [
      state.isLoading,
      state.error,
      state.reportGroupConfigurations,
      state.setReportGroupConfigurations,
      state.setIsLoading,
      state.setError,
    ],
    shallow,
  );

  const { userPermissions } = useUserRoles();

  const { canRead: canReadReports } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Reports,
  );

  // eventually we should move this to global store
  const { data: businessDivisionsData } = useBusinessDivisionsQuery();
  const { terminals } = useTerminals({
    includeInactiveTerminals: true,
  });

  const businessDivisions =
    businessDivisionsData?.businessDivisions?.businessDivisions;

  useEffect(() => {
    setReportGroupConfigurations(configData);
    useReportsStore.setState({
      businessDivisions,
      terminals,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessDivisions, terminals, configData]);

  const handleMoreOptionsClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setMoreOptionsAnchorEl(event.currentTarget);
  };

  const handleMoreOptionsClose = () => {
    setMoreOptionsAnchorEl(null);
  };

  if (!canReadReports) {
    return <UnauthorizedUserPermissions />;
  }

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        gap: '15px',
        backgroundColor: 'white',
        padding: '20px',
      }}
    >
      <Box display="flex" flexDirection="row">
        <Tabs
          orientation="horizontal"
          variant="scrollable"
          value={currentReportIndex}
          aria-label="Report Groups"
          onChange={(e, newIndex) => {
            setCurrentReportIndex(newIndex);
            loadReport(newIndex);
          }}
        >
          {reportGroupConfigurations.map((data, idx) => (
            <Tab
              key={data.uuid}
              sx={{
                maxHeight: '10px',
                paddingLeft: '10px',
                paddingRight: '10px',
              }}
              label={
                <Stack direction="row" alignItems="center">
                  {data.name}
                  {idx === currentReportIndex && (
                    <IconButton
                      component="span"
                      onClick={() => {
                        setReportEditModalOpen(true);
                      }}
                    >
                      <EditIcon sx={{ fontSize: '16px' }} />
                    </IconButton>
                  )}
                </Stack>
              }
            />
          ))}
        </Tabs>
        <Stack
          direction="row"
          flexShrink={0}
          sx={{ float: 'right' }}
          spacing={2}
        >
          <Button
            variant="contained"
            color="error"
            onClick={async () =>
              deleteReportGroupConfiguration(currentReportIndex)
            }
          >
            Delete
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              createReportGroupConfiguration();
            }}
          >
            New Report
          </Button>
          <IconButton
            size="small"
            sx={{
              backgroundColor: 'white',
              border: `1px solid ${theme.palette.primary.main}`,
              borderRadius: '10px',
              padding: 1,
            }}
            onClick={handleMoreOptionsClick}
          >
            <MoreHorizIcon />
          </IconButton>
          <ReportsContextMenu
            anchorEl={moreOptionsAnchorEl}
            onClose={handleMoreOptionsClose}
          />
        </Stack>
      </Box>
      <Box flex="1" minHeight="0" overflow="auto">
        {reportGroupConfigurations.length > 0 ? (
          reportGroupConfigurations.map((data, index) => (
            <TabPanel
              key={data.uuid}
              selectedValue={currentReportIndex}
              panelValue={index}
            >
              <Report />
            </TabPanel>
          ))
        ) : (
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ color: 'black' }}>
              No Reports yet. Click New Report to add a report.
            </Typography>
          </Box>
        )}
      </Box>
      <Snackbar
        open={isLoading}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity="info">Loading...</Alert>
      </Snackbar>
      <Snackbar
        open={!isNil(error)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity="error">{error}</Alert>
      </Snackbar>
      <ReportEditModal
        reportEditModalOpen={reportEditModalOpen}
        setReportEditModalOpen={setReportEditModalOpen}
      />
    </Box>
  );
};

export default ReportsPage;
