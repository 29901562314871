import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Chip, Fade, Paper, Popper } from '@mui/material';

import pluralize from 'pluralize';

type NewStopsPopoverProps = {
  readonly newStopsCount: number;
  readonly anchorEl: HTMLElement | null;
  readonly onClickHandler: () => void;
};
const NewStopsPopover = ({
  newStopsCount,
  anchorEl,
  onClickHandler,
}: NewStopsPopoverProps) => {
  return (
    <Popper
      transition
      open={newStopsCount > 0}
      anchorEl={anchorEl}
      placement="top"
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, -70],
          },
        },
      ]}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={0}>
          <Paper sx={{ borderRadius: 10 }}>
            <Chip
              color="info"
              label={`${newStopsCount} new ${pluralize('stop', newStopsCount)}`}
              deleteIcon={<ArrowUpwardIcon />}
              onClick={onClickHandler}
              onDelete={() => {}}
            />
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

export default NewStopsPopover;
