import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { type ScannedOrderResultPageEntity } from '../../../generated/graphql';
import type { RootState } from '../../../redux/store';

export type ScannedOrderResultPage = Omit<
  ScannedOrderResultPageEntity,
  '__typename' | 'createdAt' | 'updatedAt' | 'scannedOrderResult'
>;

const scannedOrderResultPagesAdapter =
  createEntityAdapter<ScannedOrderResultPage>({
    selectId: (scannedOrderResultPage) => scannedOrderResultPage.uuid,
  });

export const scannedOrderResultPagesSlice = createSlice({
  name: 'scannedOrderResults',
  initialState: scannedOrderResultPagesAdapter.getInitialState(),
  reducers: {
    addOneScannedOrderResultPage: scannedOrderResultPagesAdapter.addOne,
    addManyScannedOrderResultPage: scannedOrderResultPagesAdapter.addMany,
    upsertOneScannedOrderResultPage: scannedOrderResultPagesAdapter.upsertOne,
    upsertManyScannedOrderResultPage: scannedOrderResultPagesAdapter.upsertMany,
  },
});

export const { selectById: selectScannedOrderResultPageById } =
  scannedOrderResultPagesAdapter.getSelectors(
    (state: RootState) => state.scannedOrderResultPages,
  );

export const {
  addOneScannedOrderResultPage,
  addManyScannedOrderResultPage,
  upsertOneScannedOrderResultPage,
  upsertManyScannedOrderResultPage,
} = scannedOrderResultPagesSlice.actions;

const scannedOrderResultPagesReducer = scannedOrderResultPagesSlice.reducer;

export default scannedOrderResultPagesReducer;
