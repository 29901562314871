import SellIcon from '@mui/icons-material/Sell';
import { Stack } from '@mui/material';
import React, { useState } from 'react';
import TagsDropdownField from '../../../../common/components/tags-dropdown-field';
import { Size } from '../../../../common/types';
import {
  type RouteFragment,
  RoutesDocument,
  useUpdateRouteMutation,
} from '../../../../generated/graphql';

const RouteTagsField = React.memo(
  ({ route }: { readonly route: RouteFragment }) => {
    const [tagUuids, setTagUuids] = useState<string[]>(
      route.tags.map((t) => t.uuid),
    );
    const [updateRoute] = useUpdateRouteMutation({
      refetchQueries: [RoutesDocument],
    });

    return (
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <SellIcon sx={{ fontSize: 15 }} color="primary" />
        <TagsDropdownField
          size={Size.xs}
          tagUuids={tagUuids}
          showLabel={false}
          onChange={async (newUuids) => {
            setTagUuids(newUuids);
            await updateRoute({
              variables: {
                updateRouteInput: {
                  routeUpdateInput: {
                    uuid: route.uuid,
                    tagUuids: newUuids,
                  },
                },
              },
            });
          }}
        />
      </Stack>
    );
  },
);

export { RouteTagsField };
