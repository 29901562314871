import { Box, TextField, Typography } from '@mui/material';
import { type Dispatch, type SetStateAction } from 'react';

const AccessorialMapping = ({
  uuid,
  name,
  setAccessorialsToUpdate,
  mapping,
  disabled,
}: {
  readonly uuid: string;
  readonly name: string;
  readonly setAccessorialsToUpdate: Dispatch<
    SetStateAction<Record<string, string>>
  >;
  readonly mapping: string;
  readonly disabled?: boolean;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
      }}
    >
      <Typography>{name}</Typography>
      <TextField
        value={mapping}
        size="small"
        disabled={disabled}
        onChange={(e) => {
          setAccessorialsToUpdate((prevState) => ({
            ...prevState,
            [uuid]: e.target.value,
          }));
        }}
      />
    </Box>
  );
};

export default AccessorialMapping;
