import {
  Box,
  Button,
  CircularProgress,
  Stack,
  type SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import {
  useApproveQuoteMutation,
  useCreateStandardOrderMutation,
  useOrderNameLazyQuery,
} from '../../../../generated/graphql';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  selectStandardOrderValuesById,
  updateOneStandardOrderValues,
} from '../../../orders/redux/standard/standard-orders-values-slice';
import {
  createStandardOrderCreateInput,
  initNewOrderFromQuote,
} from '../../../orders/redux/standard/standard-orders-values-thunks';
import QuoteErrorsResponseDialog from '../../quote-profile/components/quote-errors-response';
import {
  getQuoteErrorsResponse,
  initializeQuote,
  type QuoteErrorsResponse,
} from '../../redux/quote-value-thunks';
import { selectQuoteValuesById } from '../../redux/quote-values-slice';
import PackageRow from './package-row';
import ShipmentRow from './shipment-row';

const styles = {
  modalInnerContainer: {
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    p: 4,
  } as SxProps,
};

const ApproveQuoteArea = ({
  open,
  setOpen,
  quoteUuid,
  billingPartyContactUuid,
  isFromQuoteProfile,
}: {
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
  readonly quoteUuid: string;
  readonly billingPartyContactUuid: string;
  readonly isFromQuoteProfile: boolean;
}) => {
  const dispatch = useAppDispatch();
  const [convertToOrderButtonDisabled, setConvertToOrderButtonDisabled] =
    useState(false);
  const [createStandardOrder, { loading: createStandardOrderLoading }] =
    useCreateStandardOrderMutation();
  const [getOrderName] = useOrderNameLazyQuery();
  const [orderUuid, setOrderUuid] = useState<string | null>(null);
  const [approveQuote, { loading: approveQuoteLoading }] =
    useApproveQuoteMutation();
  const [errorResponse, setErrorResponse] =
    useState<QuoteErrorsResponse | null>(null);
  const order = useAppSelector((state) =>
    selectStandardOrderValuesById(state, orderUuid ?? ''),
  );
  const quote = useAppSelector((state) =>
    selectQuoteValuesById(state, quoteUuid),
  );
  const navigate = useNavigate();
  const ffPackageSpecs = useFeatureFlag(FeatureFlag.FF_PACKAGE_SPECS);
  const ffRecoveryTransferAddressOnly = useFeatureFlag(
    FeatureFlag.FF_RECOVERY_TRANSFER_ADDRESS_ONLY,
  );

  const initializeQuoteForModal = async () => {
    if (open) {
      dispatch(initializeQuote({ uuid: quoteUuid }));
      const name = (
        await getOrderName({
          variables: { billingPartyContactUuid },
        })
      ).data?.orderName;
      const uuid = await dispatch(
        initNewOrderFromQuote({ quoteUuid, name }),
      ).unwrap();
      setOrderUuid(uuid);
    }
  };

  const validateErrors = async (
    forceValidateAddress?: boolean,
  ): Promise<boolean> => {
    if (!isNil(quoteUuid)) {
      const quoteErrors = await dispatch(
        getQuoteErrorsResponse({
          quoteUuid,
          forceValidateAddress,
          ffRecoveryTransferAddressOnly,
        }),
      ).unwrap();
      if (!isNil(quoteErrors) && !quoteErrors.isValid) {
        setErrorResponse(quoteErrors);
        return false;
      }
      if (!isNil(quoteErrors) && quoteErrors.isValid) {
        return true;
      }
    }
    return false;
  };

  const saveOrder = async () => {
    setConvertToOrderButtonDisabled(true);
    if (!isNil(orderUuid)) {
      const res = await validateErrors(true);
      if (res) {
        const standardOrderCreateInput = await dispatch(
          createStandardOrderCreateInput({ orderUuid }),
        )?.unwrap();

        const result = await createStandardOrder({
          variables: {
            createStandardOrderInput: {
              orderCreateInput: standardOrderCreateInput,
            },
          },
        });
        const newUuid = result.data?.createStandardOrder.uuid;
        if (!isNil(newUuid)) {
          await approveQuote({
            variables: {
              approveQuoteInput: { orderUuid: newUuid, quoteUuid },
            },
          });
        }
        setOpen(false);
        setConvertToOrderButtonDisabled(false);
        navigate('/orders');
      }
    }
    setConvertToOrderButtonDisabled(false);
  };

  useEffect(() => {
    initializeQuoteForModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteUuid, dispatch, billingPartyContactUuid, isFromQuoteProfile, open]);

  if (isNil(orderUuid) || isNil(order) || isNil(quote)) {
    return (
      <Box
        sx={
          isFromQuoteProfile
            ? styles.modalInnerContainer
            : {
                ...styles.modalInnerContainer,
                bgcolor: 'background.paper',
                boxShadow: 24,
              }
        }
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Box
      sx={
        isFromQuoteProfile
          ? styles.modalInnerContainer
          : {
              ...styles.modalInnerContainer,
              bgcolor: 'background.paper',
              boxShadow: 24,
            }
      }
    >
      {errorResponse && (
        <QuoteErrorsResponseDialog
          errors={errorResponse}
          handleClose={() => {
            setErrorResponse(null);
          }}
          open={!isNil(errorResponse)}
        />
      )}
      <Typography variant="h5">Convert {quote.number} to order</Typography>
      <Stack>
        <Typography>HAWB</Typography>
        <TextField
          size="small"
          sx={{ width: '200px' }}
          value={order?.shipperBillOfLadingNumber}
          onChange={(e) =>
            dispatch(
              updateOneStandardOrderValues({
                id: order.uuid,
                changes: { shipperBillOfLadingNumber: e.target.value },
              }),
            )
          }
        />
        <Typography>MAWB</Typography>
        <TextField
          size="small"
          sx={{ width: '200px' }}
          value={order?.masterAirwayBillOfLadingNumber}
          onChange={(e) =>
            dispatch(
              updateOneStandardOrderValues({
                id: order.uuid,
                changes: { masterAirwayBillOfLadingNumber: e.target.value },
              }),
            )
          }
        />
      </Stack>
      <Stack spacing={1}>
        <Typography sx={{ fontWeight: 'bold' }}>Packages</Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Weight</TableCell>
                <TableCell>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {quote.packageUuids.map((packageUuid) => (
                <PackageRow
                  key={packageUuid}
                  packageUuid={packageUuid}
                  displayPackageSpecName={ffPackageSpecs}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography fontSize="14px">
          Total skids: {quote.totalSkids ?? 0}
        </Typography>
      </Stack>
      <Stack>
        <Typography sx={{ fontWeight: 'bold' }}>Stops</Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Appointment</TableCell>
                <TableCell>Charge</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {quote.shipmentUuids.map((shipmentUuid) => (
                <ShipmentRow key={shipmentUuid} shipmentUuid={shipmentUuid} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <Stack direction="row-reverse">
        <Button
          variant="contained"
          disabled={
            convertToOrderButtonDisabled ||
            createStandardOrderLoading ||
            approveQuoteLoading
          }
          onClick={saveOrder}
        >
          Convert to order
        </Button>
      </Stack>
    </Box>
  );
};

export default ApproveQuoteArea;
