import {
  Stack,
  Typography,
  Radio,
  FormControlLabel,
  RadioGroup as MuiRadioGroup,
  type StackProps,
} from '@mui/material';
import React from 'react';
import { isNilOrEmptyString } from '../../utils/utils';

type Option<T> = {
  value: T;
  label: string;
};

type PLTRadioGroupProps<T> = {
  readonly options: Array<Option<T>>;
  readonly selectedValue: T;
  readonly onChange: (value: T) => void;
  readonly label: string;
  readonly subLabel?: string;
  readonly direction?: StackProps['direction']; // 'row' for horizontal, 'column' for vertical
};

const PLTRadioGroup = <T extends string | number | boolean>({
  options,
  selectedValue,
  onChange,
  label,
  subLabel,
  direction = 'column',
}: PLTRadioGroupProps<T>) => {
  return (
    <Stack>
      <Typography variant="body2">{label}</Typography>
      {!isNilOrEmptyString(subLabel) && (
        <Typography variant="caption" color="text.secondary">
          {subLabel}
        </Typography>
      )}
      <MuiRadioGroup>
        <Stack direction={direction}>
          {options.map((option, idx) => (
            <FormControlLabel
              // eslint-disable-next-line react/no-array-index-key
              key={`${option.value}+${idx}`}
              value={option.value}
              control={<Radio />}
              label={option.label}
              checked={selectedValue === option.value}
              onClick={() => {
                onChange(option.value);
              }}
            />
          ))}
        </Stack>
      </MuiRadioGroup>
    </Stack>
  );
};

export { PLTRadioGroup };
