import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { isNil } from 'lodash';
import React from 'react';
import { generateBarcode } from '../../../../common/utils/barcode';
import { type WarehouseLocationsLocationFragment } from '../../../../generated/graphql';

type WarehouseLocationLabelsPdfProps = {
  readonly warehouseLocations: WarehouseLocationsLocationFragment[];
};
const WarehouseLocationLabelsPdf = ({
  warehouseLocations,
}: WarehouseLocationLabelsPdfProps) => {
  const styles = StyleSheet.create({
    page: {
      padding: 4,
      fontFamily: 'Roboto',
      fontSize: '10px',
      flexDirection: 'column',
    },
    titleBold: {
      fontSize: '48px',
      fontWeight: 'bold',
    },
    smallTitleBold: {
      fontSize: '36px',
      fontWeight: 'bold',
    },
    headerText: {
      fontSize: '16px',
      fontWeight: 'bold',
    },
    subHeaderTextSize: {
      fontSize: '10px',
    },
    subHeaderText: {
      fontSize: '10px',
      fontWeight: 'bold',
    },
    outerCell: {
      width: '100%',
      display: 'flex',
      padding: '3px',
    },
  });

  const WAREHOUSE_LOCATION_NAME_THRESHOLD = 12;

  return (
    <Document>
      {warehouseLocations.map((warehouseLocation) => {
        const barcodeData = generateBarcode({
          data: warehouseLocation.name,
          width: 3,
          height: 60,
          displayValue: false,
        });
        return (
          <Page
            key={warehouseLocation.uuid}
            size="A6"
            orientation="landscape"
            style={styles.page}
          >
            <View
              style={[
                {
                  flexDirection: 'column',
                  alignItems: 'center',
                },
                styles.outerCell,
              ]}
            >
              <View
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Text
                  style={
                    warehouseLocation.name.length >
                    WAREHOUSE_LOCATION_NAME_THRESHOLD
                      ? styles.smallTitleBold
                      : styles.titleBold
                  }
                >
                  {warehouseLocation.name}
                </Text>
              </View>
              {!isNil(barcodeData) && (
                <View>
                  <Image
                    source={{
                      uri: barcodeData,
                      method: 'GET',
                      headers: {},
                      body: '',
                    }}
                  />
                </View>
              )}
            </View>
          </Page>
        );
      })}
    </Document>
  );
};

export default WarehouseLocationLabelsPdf;
