import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { isNil } from 'lodash';
import React, { useEffect } from 'react';

const CACHE_PREFIX = 'REPORT_DOWNLOAD_TYPE';

export enum ReportDownloadType {
  CSV = 'CSV',
  PDF_REVENUE = 'PDF (Revenue Only)',
}

type ReportDownloadTypeSelectionProps = {
  readonly cacheId?: string;
  readonly selectedOption?: ReportDownloadType;
  readonly labelText: string;
  readonly handleChange: (selectedDownloadType: ReportDownloadType) => void;
};

const ReportDownloadTypeSelection = ({
  cacheId,
  labelText,
  selectedOption = ReportDownloadType.CSV,
  handleChange,
}: ReportDownloadTypeSelectionProps) => {
  useEffect(() => {
    if (!isNil(cacheId)) {
      const cachedType = localStorage.getItem(`${CACHE_PREFIX}_${cacheId}`);
      if (!isNil(cachedType)) {
        handleChange(cachedType as ReportDownloadType);
      }
    }
  }, []);

  const onChange = (downloadType: ReportDownloadType) => {
    if (!isNil(cacheId)) {
      if (isNil(downloadType)) {
        localStorage.removeItem(`${CACHE_PREFIX}_${cacheId}`);
      } else {
        localStorage.setItem(
          `${CACHE_PREFIX}_${cacheId}`,
          downloadType.toString(),
        );
      }
    }
    handleChange(downloadType);
  };

  return (
    <Box>
      <FormControl sx={{ display: 'flex' }}>
        <InputLabel id="report-download-type-selection-label">
          {labelText}
        </InputLabel>
        <Select
          labelId="report-download-type-selection-label"
          value={selectedOption}
          label={labelText}
          size="small"
          sx={{ backgroundColor: 'white' }}
          onChange={(e) => {
            onChange(
              (e.target.value as ReportDownloadType) ?? ReportDownloadType.CSV,
            );
          }}
        >
          {Object.values(ReportDownloadType).map((downloadType) => (
            <MenuItem key={downloadType} id={downloadType} value={downloadType}>
              {downloadType}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default ReportDownloadTypeSelection;
