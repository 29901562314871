import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import React, {
  type Dispatch,
  type SetStateAction,
  useState,
  useEffect,
} from 'react';
import { useDebounce } from 'use-debounce';
import useVersionHistoryStore from '../../../../version-history-store';
import useStyles from '../../../styles';
import OrderAuditLogList from './components/order-audit-log/order-audit-log-list';

type OrderAuditLogModalProps = {
  readonly orderUuid?: string;
  readonly isOpen: boolean;
  readonly setIsOpen:
    | Dispatch<SetStateAction<boolean>>
    | ((isOpen: boolean) => void);
};
const OrderAuditLogModal = ({
  orderUuid,
  isOpen,
  setIsOpen,
}: OrderAuditLogModalProps) => {
  const styles = useStyles();
  const setLogSearchInput = useVersionHistoryStore(
    (state) => state.setLogSearchInput,
  );
  const [searchText, setSearchText] = useState<string>('');
  const [debouncedSearchText] = useDebounce(searchText, 200);

  const onClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setLogSearchInput(debouncedSearchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText]);

  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={onClose}
    >
      <Box sx={[styles.modal, { height: '90vh' }]}>
        <Grid container alignItems="center">
          <Grid item xs={3} />
          <Grid item xs={6} sx={{ textAlign: 'center' }}>
            <Typography variant="h6">Order Audit Log</Typography>
          </Grid>
          <Grid item xs={3}>
            <IconButton sx={{ float: 'right' }} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              InputProps={{ style: { backgroundColor: 'white' } }}
              label="Search logs"
              value={searchText}
              sx={{ margin: 1, width: '50%' }}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ height: '75vh', overflowY: 'scroll', py: 2 }}
          >
            {!isNil(orderUuid) && <OrderAuditLogList orderUuid={orderUuid} />}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default React.memo(OrderAuditLogModal);
