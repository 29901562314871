import dayjs from 'dayjs';
import { flatten, isEmpty, isNil } from 'lodash';
import { useState, useEffect } from 'react';
import { filterNotNil } from 'shared/array';
import { transformAddressToFullAddressString } from 'shared/copy';
import { safeDivide } from 'shared/math';
import {
  transformDateToDateString,
  transformTimeToTimeString,
} from '../../../common/utils/prettyPrintUtils';
import { getDriverName } from '../../../common/utils/utils';
import {
  EquipmentType,
  type RouteFragment,
  type StopOnRouteFragment,
} from '../../../generated/graphql';
import {
  type NotifiedDriver,
  type TextDriversApiResponse,
} from '../types/routes';

const parseStopsIntoMessage = (
  stops: Array<StopOnRouteFragment | undefined>,
  date: Date,
  ffShowEtaInDriverText?: boolean,
  startTime?: Date,
  totalTime?: string,
  vehicle?: string,
  driverName?: string,
  helperNames?: string,
) => {
  const dateString = transformDateToDateString(date?.toString() ?? '');
  let message = `Route for ${dateString}\n--\n`;
  if (ffShowEtaInDriverText === true) {
    message += `Route starts at ${
      isNil(startTime)
        ? '07:00 am'
        : transformTimeToTimeString(dayjs(startTime).toISOString())
    }\n--\n`;
    if (!isNil(totalTime) && !isEmpty(totalTime)) {
      message += `Estimated route time: ${totalTime}\n--\n`;
    }
  }
  if (!isNil(driverName)) {
    message += `Driver name: ${driverName}\n--\n`;
  }
  if (!isNil(helperNames)) {
    message += `Helper name(s): ${helperNames}\n--\n`;
  }
  if (!isNil(vehicle)) {
    message += `Vehicle: ${vehicle}\n--\n`;
  }
  for (const [idx, stop] of stops.entries()) {
    if (!isNil(stop)) {
      const address = transformAddressToFullAddressString({
        ...stop.address,
        city: stop.address.city ?? null,
        zip: stop.address.zip ?? null,
        line1: stop.address.line1 ?? null,
      });
      message += `Load ${idx + 1}\n${address}\n--\n`;
    }
  }
  return message;
};

const useRouteTextDriversData = (
  routes: RouteFragment[] | null,
  ffShowEtaInDriverText?: boolean,
): TextDriversApiResponse => {
  const [notifiedDrivers, setNotifiedDrivers] = useState<NotifiedDriver[]>([]);

  useEffect(() => {
    if (!isNil(routes)) {
      const notifiedDriversArr: NotifiedDriver[] = routes
        .filter((route) => route.drivers.length > 0)
        .map((route) => {
          let totalTime = '';
          if (!isNil(route.totalTimeInSeconds)) {
            const totalTimeInMinutes = safeDivide(route.totalTimeInSeconds, 60);
            const totalTimeInHours = safeDivide(totalTimeInMinutes, 60);
            totalTime = `${Math.floor(totalTimeInHours)} hours, ${Math.floor(
              totalTimeInMinutes % 60,
            )} min`;
          }

          return {
            driverName: isNil(route.drivers[0])
              ? '-'
              : `${route.drivers[0].firstName} ${route.drivers[0].lastName}`,
            phoneNumber: route.drivers[0]?.phoneNumber ?? '',
            routeName: route.name,
            messageSummary: `Route assigned with ${route.slots.length} loads`,
            messageContents: parseStopsIntoMessage(
              route.slots.map((slot) => slot.stops[0]),
              route.date,
              ffShowEtaInDriverText,
              route.defaultStartTime,
              totalTime,
              route.equipments?.find(
                (equipment) => equipment.type === EquipmentType.Vehicle,
              )?.name,
              isNil(route.drivers[0])
                ? undefined
                : getDriverName(route.drivers[0]),
              (route.helpers?.length ?? -1) > 0
                ? route.helpers
                    ?.map((helper) => `${helper.firstName} ${helper.lastName}`)
                    .join(', ')
                : undefined,
            ),
            routeUuid: route.uuid,
            driverUuid: route.drivers[0]?.uuid ?? '',
          };
        });
      const notifiedHelpersArr: NotifiedDriver[] = filterNotNil(
        flatten(
          routes
            .filter((route) => (route.helpers?.length ?? -1) > 0)
            .map((route) =>
              route.helpers?.map((helper) => {
                let totalTime = '';
                if (!isNil(route.totalTimeInSeconds)) {
                  const totalTimeInMinutes = safeDivide(
                    route.totalTimeInSeconds,
                    60,
                  );
                  const totalTimeInHours = safeDivide(totalTimeInMinutes, 60);
                  totalTime = `${Math.floor(
                    totalTimeInHours,
                  )} hours, ${Math.floor(totalTimeInMinutes % 60)} min`;
                }

                return {
                  driverName: `${helper.firstName} ${helper.lastName}`,
                  phoneNumber: helper.phoneNumber,
                  routeName: route.name,
                  messageSummary: `Route assigned with ${route.slots.length} loads`,
                  messageContents: parseStopsIntoMessage(
                    route.slots.map((slot) => slot.stops[0]),
                    route.date,
                    ffShowEtaInDriverText,
                    route.defaultStartTime,
                    totalTime,
                    route.equipments?.find(
                      (equipment) => equipment.type === EquipmentType.Vehicle,
                    )?.name,
                    isNil(route.drivers[0])
                      ? undefined
                      : getDriverName(route.drivers[0]),
                    (route.helpers?.length ?? -1) > 0
                      ? route.helpers
                          ?.map(
                            (itrHelper) =>
                              `${itrHelper.firstName} ${itrHelper.lastName}`,
                          )
                          .join(', ')
                      : undefined,
                  ),
                  routeUuid: route.uuid,
                  driverUuid: helper.uuid,
                };
              }),
            ),
        ),
      );
      setNotifiedDrivers([...notifiedDriversArr, ...notifiedHelpersArr]);
    }
  }, [routes]);

  return { notifiedDrivers };
};

export default useRouteTextDriversData;
