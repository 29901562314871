import { Chip } from '@mui/material';
import { sentenceCase } from 'change-case';
import { ShipmentType, type StopType } from '../../generated/graphql';

const StopTypeChip = ({
  shipmentType,
  stopType,
}: {
  readonly shipmentType: ShipmentType | undefined;
  readonly stopType?: StopType | string | null;
}) => {
  if (
    shipmentType === ShipmentType.LineHaul ||
    shipmentType === ShipmentType.OrderCharges
  ) {
    return <Chip size="small" label={sentenceCase(shipmentType)} />;
  }
  return <Chip size="small" label={sentenceCase(stopType ?? 'Stop')} />;
};

export default StopTypeChip;
