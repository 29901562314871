import { Star } from '@mui/icons-material';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  MenuItem,
  Select,
  type SxProps,
  TextField,
  Typography,
  useTheme,
  Snackbar,
  Alert,
} from '@mui/material';
import { flatten, isEmpty, isNil } from 'lodash';
import { LngLat, LngLatBounds } from 'mapbox-gl';
import React, { useEffect, useMemo, useState } from 'react';
import { type MapRef } from 'react-map-gl';
import { filterNotNil } from 'shared/array';
import useInterval from '../../common/react-hooks/use-interval';
import useMe from '../../common/react-hooks/use-me';
import { isNilOrEmptyString } from '../../common/utils/utils';
import {
  StopType,
  EquipmentType,
  SameDayDispatchCellHeader,
  type SameDayDispatchRouteFragment,
  useMyWarehousesQuery,
  useSameDayDispatchConfigQuery,
  useSameDayDispatchRoutesLazyQuery,
  useSameDayDispatchRoutesWithDriverCoordsLazyQuery,
  useSamsaraVehicleStatsFeedLazyQuery,
} from '../../generated/graphql';
import useGlobalStore from '../../layouts/dashboard/global-store';
import CalculateRoutesButton from './components/calculate-routes-button';
import DailyControlCenterDriverRouteModal from './components/daily-control-center-driver-route-modal';
import DailyControlCenterRow from './components/daily-control-center-row';
import DailyControlCenterSettingsModal from './components/daily-control-center-settings-modal';
import SelectMultipleRoutes from './components/route-select';
import SameDayDispatchMap, {
  type SameDayDispatchDriverPoint,
  type SameDayDispatchRoutePoint,
} from './map/same-day-dispatch-map';
import {
  calculateBoundsFromCoordinatesToShowAllMarkers,
  stopIsComplete,
} from './utils';

const MOBILE_POLLING_INTERVAL = 10_000;

const useStyles = (): Record<string, SxProps> => {
  const theme = useTheme();
  return {
    outerBox: {
      backgroundColor: 'white',
      border: `1px solid ${theme.palette.borderColor.dark}`,
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'scroll',
      p: '10px',
      height: 'calc(100vh - 100px)',
      width: '60%',
    },
    table: {
      display: 'inline-block',
      height: 'calc(100vh - 130px)',
      py: '5px',
    },
    searchAndSettings: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  };
};

const CACHED_ROUTE_UUIDS_KEY = 'CACHED_ROUTE_UUIDS_KEY';

const DailyControlCenterView = () => {
  const [mapRef, setMapRef] = useState<MapRef | null>(null);
  const styles = useStyles();
  const { companyConfiguration, user } = useMe();
  const selectedTerminalUuid = useGlobalStore(
    (state) => state.selectedTerminalUuid,
  );
  const [selectedWarehouseUuid, setSelectedWarehouseUuid] = useState<
    string | undefined
  >(undefined);
  const warehousesList = useMyWarehousesQuery({
    variables: {},
  }).data?.me?.company.warehouses;

  const [samsaraVehicleIds, setSamsaraVehicleIds] = useState<string[]>([]);
  // It's jarring for the map to keep re-zooming, so it only sets the zoom once
  const [zoomedOnce, setZoomedOnce] = useState(false);
  const [selectedRouteUuid, setSelectedRouteUuid] = useState('');
  const [searchText, setSearchText] = useState('');
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [showSuccessfulRefresh, setShowSuccessfulRefresh] = useState(false);
  const [etaErrorMsg, setEtaErrorMsg] = useState<string | undefined>(undefined);
  const [routeModalOpen, setRouteModalOpen] = useState(false);
  const [modalRoute, setModalRoute] = useState<
    SameDayDispatchRouteFragment | undefined
  >();

  const { data: settingsData } = useSameDayDispatchConfigQuery();
  const [
    sameDayDispatchRoutesQuery,
    { data: routesData, loading: routesLoading },
  ] = useSameDayDispatchRoutesLazyQuery();

  const [samsaraVehicleStatsFeedQuery, { data: feedData }] =
    useSamsaraVehicleStatsFeedLazyQuery();
  const [getDriverCoordsData, { data: driverCoordsData }] =
    useSameDayDispatchRoutesWithDriverCoordsLazyQuery();

  const [routeUuidsToShow, setRouteUuidsToShow] = useState<string[]>([]);

  useInterval(() => {
    getDriverCoordsData({
      variables: {
        date: new Date(),
        searchText,
      },
    });
    samsaraVehicleStatsFeedQuery({
      variables: {
        samsaraVehicleIds,
      },
    });
  }, MOBILE_POLLING_INTERVAL);

  useEffect(() => {
    const cachedRouteUuids = localStorage.getItem(CACHED_ROUTE_UUIDS_KEY);
    if (!isNil(cachedRouteUuids) && cachedRouteUuids.length > 0) {
      setRouteUuidsToShow(cachedRouteUuids.split(','));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(CACHED_ROUTE_UUIDS_KEY, routeUuidsToShow.join(','));
  }, [routeUuidsToShow]);

  const fetchSameDayDispatchData = () => {
    sameDayDispatchRoutesQuery({
      variables: {
        date: new Date(),
        searchText,
        terminalUuid: selectedTerminalUuid,
      },
    });
  };

  useEffect(() => {
    if (!isNil(user)) {
      fetchSameDayDispatchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTerminalUuid, user]);

  useEffect(() => {
    const vehicleIds = filterNotNil(
      routesData?.routes.flatMap(
        (route) =>
          route.equipments.find(
            (equipment) => equipment.type === EquipmentType.Vehicle,
          )?.samsaraVehicleId ??
          route.drivers?.at(0)?.defaultVehicle?.samsaraVehicleId,
      ) ?? [],
    );
    setSamsaraVehicleIds(vehicleIds);
  }, [routesData]);

  const filteredRoutes = useMemo(() => {
    return (
      routesData?.routes
        .filter((route) => {
          return route.slots.filter((slot) => {
            const stop = slot.stops[0];
            const order = stop?.leg.shipment?.order;
            return isEmpty(selectedWarehouseUuid)
              ? true
              : order?.warehouse?.uuid === selectedWarehouseUuid;
          });
        })
        .filter((route) => {
          if (routeUuidsToShow.length > 0) {
            return routeUuidsToShow.includes(route.uuid);
          }
          return true;
        }) ?? []
    );
  }, [routesData, selectedWarehouseUuid, routeUuidsToShow]);

  const zoomToMarkerBounds = () => {
    if (!isNil(mapRef)) {
      const coordinates = flatten(
        filteredRoutes.map((route) => {
          return route.slots.map((slot) => {
            const stop = slot.stops[0];
            const address = stop?.address;
            const lat = address?.latitude;
            const long = address?.longitude;
            if (isNil(stop) || isNil(address) || isNil(lat) || isNil(long)) {
              return null;
            }
            return {
              latitude: lat,
              longitude: long,
            };
          });
        }),
      );
      const bounds = calculateBoundsFromCoordinatesToShowAllMarkers(
        filterNotNil(coordinates),
      );
      if (filteredRoutes.length > 0) {
        setZoomedOnce(true);
      }
      const sw: LngLat = new LngLat(bounds.bottomRightX, bounds.bottomRightY);
      const ne = new LngLat(bounds.topLeftX, bounds.topLeftY);
      const boundsObject = new LngLatBounds(sw, ne);
      mapRef.getMap().fitBounds(boundsObject, { padding: 60 });
    }
  };

  useEffect(() => {
    if (!zoomedOnce) {
      zoomToMarkerBounds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef, zoomedOnce, filteredRoutes.length]);

  const locationBySamsaraVehicleId = new Map(
    feedData?.samsaraVehicleStatsFeed?.data?.map((d) => {
      return [d.id, d];
    }) ?? [],
  );

  const inProgressRoutes = useMemo(() => {
    return filteredRoutes
      .map((route, idx) => {
        return { route, idx };
      })
      .filter(({ route }) => {
        if (isNil(route.drivers[0]?.defaultVehicle?.samsaraVehicleId)) {
          return false;
        }
        return route.slots
          .filter((slot) => {
            const stop = slot.stops[0];
            const order = stop?.leg.shipment?.order;
            return isEmpty(selectedWarehouseUuid)
              ? true
              : order?.warehouse?.uuid === selectedWarehouseUuid;
          })
          .some((slot) => {
            return !stopIsComplete(slot.stops[0]?.status);
          });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredRoutes]);

  const completedRoutes = useMemo(() => {
    return filteredRoutes
      .map((route, idx) => {
        return { route, idx };
      })
      .filter(({ route }) => {
        if (isNil(route.drivers[0]?.defaultVehicle?.samsaraVehicleId)) {
          return false;
        }
        return route.slots
          .filter((slot) => {
            const stop = slot.stops[0];
            const order = stop?.leg.shipment?.order;
            return isEmpty(selectedWarehouseUuid)
              ? true
              : order?.warehouse?.uuid === selectedWarehouseUuid;
          })
          .every((slot) => stopIsComplete(slot.stops[0]?.status));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredRoutes]);

  const routesWithoutVehicle = useMemo(() => {
    return filteredRoutes
      .map((route, idx) => {
        return { route, idx };
      })
      .filter(({ route }) => {
        return isNil(route.drivers[0]?.defaultVehicle?.samsaraVehicleId);
      });
  }, [filteredRoutes]);

  const points: SameDayDispatchDriverPoint[] = useMemo(() => {
    return filterNotNil(
      filteredRoutes.map((route, idx) => {
        const driver = route.drivers[0];
        const driverName = isNil(driver)
          ? route.name
          : `${driver.firstName} ${driver.lastName}`;
        const location = locationBySamsaraVehicleId.get(
          route.equipments.find(
            (equipment) =>
              equipment.type === EquipmentType.Vehicle &&
              equipment.samsaraIsDeactivated !== true,
          )?.samsaraVehicleId ??
            driver?.defaultVehicle?.samsaraVehicleId ??
            '',
        );
        const routeWithDriverCoords = driverCoordsData?.routes.find(
          (itrRoute) => itrRoute.uuid === route.uuid,
        );
        const driverWithCoords = routeWithDriverCoords?.drivers[0];
        const lat =
          companyConfiguration?.getDriverLocationDataFromMobile === true
            ? (driverWithCoords?.mobilePhoneLatitude ??
              location?.gps?.[0]?.latitude)
            : (location?.gps?.[0]?.latitude ??
              driverWithCoords?.mobilePhoneLatitude);
        const long =
          companyConfiguration?.getDriverLocationDataFromMobile === true
            ? (driverWithCoords?.mobilePhoneLongitude ??
              location?.gps?.[0]?.longitude)
            : (location?.gps?.[0]?.longitude ??
              driverWithCoords?.mobilePhoneLongitude);
        if (isNil(lat) || isNil(long)) {
          return null;
        }
        return {
          driverName,
          isSelected: selectedRouteUuid === route.uuid,
          lat,
          long,
          routeIndex: idx,
          routeUuid: route.uuid,
        };
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredRoutes, locationBySamsaraVehicleId, driverCoordsData?.routes]);

  const routePoints: SameDayDispatchRoutePoint[][] = useMemo(() => {
    return filteredRoutes.map((route, routeIdx) => {
      return filterNotNil(
        (route.slots ?? [])
          .filter((slot) => {
            const stop = slot.stops[0];
            const order = stop?.leg.shipment?.order;
            if (!isEmpty(selectedWarehouseUuid)) {
              return (
                order?.warehouse?.uuid === selectedWarehouseUuid &&
                !stopIsComplete(slot.stops[0]?.status)
              );
            }
            return !stopIsComplete(slot.stops[0]?.status);
          })
          .map((slot, idx) => {
            const stop = slot.stops[0];
            const address = stop?.address;
            const lat = address?.latitude;
            const long = address?.longitude;
            if (isNil(stop) || isNil(address) || isNil(lat) || isNil(long)) {
              return null;
            }
            const stopType = stop.stopType ?? StopType.Delivery;
            const billOfLadingNumber =
              stop.leg.shipment.order?.standardOrderFields
                .shipperBillOfLadingNumber;
            const secondaryRefNumber =
              stop.leg.shipment.order?.secondaryRefNumber ?? '';
            const referenceNumber =
              stopType === StopType.Delivery
                ? billOfLadingNumber
                : secondaryRefNumber;
            const orderName: string = stop.leg.shipment.order?.name ?? '';
            return {
              address,
              appointmentWindow: stop.appointmentTime,
              id: slot.uuid,
              isSelected: selectedRouteUuid === route.uuid,
              orderName: isNilOrEmptyString(referenceNumber)
                ? orderName
                : `${orderName} - ${referenceNumber}`,
              ordinal: idx,
              lat,
              long,
              routeIndex: routeIdx,
              routeUuid: route.uuid,
            };
          }),
      );
    });
  }, [filteredRoutes, selectedRouteUuid, selectedWarehouseUuid]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showSuccessfulRefresh}
        onClose={(e, reason) => {
          if (reason === 'clickaway') {
            return;
          }

          setShowSuccessfulRefresh(false);
        }}
      >
        <Alert
          severity="success"
          onClose={() => {
            setShowSuccessfulRefresh(false);
          }}
        >
          Route ETAs refreshed
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
        open={!isNil(etaErrorMsg)}
        onClose={() => {
          setEtaErrorMsg(undefined);
        }}
      >
        <Alert severity="error">{etaErrorMsg}</Alert>
      </Snackbar>
      <Box sx={styles.outerBox}>
        <Box sx={styles.searchAndSettings}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '5px',
              alignItems: 'center',
            }}
          >
            <TextField
              size="small"
              label="Search"
              sx={{ width: '200px' }}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
            <Button variant="outlined" onClick={fetchSameDayDispatchData}>
              Search
            </Button>
            <CalculateRoutesButton
              routeUuids={filteredRoutes.map((route) => route.uuid)}
              setShowSuccessfulCalculate={setShowSuccessfulRefresh}
            />
          </Box>
          {!isNil(warehousesList) && warehousesList.length > 1 && (
            <Select
              displayEmpty
              sx={{ width: '300px', height: '40px' }}
              value={
                warehousesList.find(
                  (warehouse) => warehouse.uuid === selectedWarehouseUuid,
                )?.name
              }
              renderValue={
                isNil(selectedWarehouseUuid)
                  ? () => <Typography color="grey"> All terminals </Typography>
                  : undefined
              }
              onChange={(event) => {
                setSelectedWarehouseUuid(
                  event.target.value === '' ? undefined : event.target.value,
                );
              }}
            >
              {[...warehousesList, undefined].map((warehouse) => {
                if (!isNil(warehouse)) {
                  return (
                    <MenuItem key={warehouse.uuid} value={warehouse.uuid}>
                      {warehouse.name}
                    </MenuItem>
                  );
                }
                if (!isNil(selectedWarehouseUuid)) {
                  return (
                    <MenuItem key="All" value="">
                      All terminals
                    </MenuItem>
                  );
                }
                return null;
              })}
            </Select>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              alignItems: 'center',
            }}
          >
            <Star />
            <Typography>Next stop</Typography>
          </Box>
          <SelectMultipleRoutes
            routeUuidsToShow={routeUuidsToShow}
            setRouteUuidsToShow={setRouteUuidsToShow}
            routesData={routesData}
          />
          <IconButton
            size="small"
            sx={{ gap: '5px' }}
            onClick={() => {
              setIsSettingsModalOpen(true);
            }}
          >
            <SettingsIcon sx={{ fontSize: 18 }} />
            Color coding
          </IconButton>
        </Box>
        <Box sx={styles.table}>
          {routesLoading && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                width: '100%',
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <Divider />
          {inProgressRoutes
            .concat(routesWithoutVehicle)
            .concat(completedRoutes)
            .map(({ route, idx }) => {
              return (
                <>
                  <DailyControlCenterRow
                    key={route.uuid}
                    handleClick={() => {
                      setSelectedRouteUuid(route.uuid);
                    }}
                    isSelected={route.uuid === selectedRouteUuid}
                    route={route}
                    nextSlotUuid={route.nextRouteSlot?.uuid}
                    routeIndex={idx}
                    warehouseUuid={selectedWarehouseUuid}
                    sameDayDispatchCellHeader={
                      companyConfiguration?.sameDayDispatchCellHeader ??
                      SameDayDispatchCellHeader.BillOfLadingNumber
                    }
                    colorCodingConfig={settingsData?.sameDayDispatchConfig}
                    setRouteModalOpen={setRouteModalOpen}
                    setModalRoute={setModalRoute}
                    showVehicle={
                      companyConfiguration?.showVehicleInDailyDispatch === true
                    }
                    setEtaErrorMsg={setEtaErrorMsg}
                  />
                  <Divider key={`${route.uuid}-divider`} />
                </>
              );
            })}
        </Box>
      </Box>
      <Box
        sx={{
          width: '40%',
        }}
      >
        <SameDayDispatchMap
          driverPoints={points}
          routePoints={routePoints}
          setRef={setMapRef}
          setSelectedRoute={(routeUuid: string) => {
            setSelectedRouteUuid(routeUuid);
          }}
        />
      </Box>
      <DailyControlCenterSettingsModal
        currentSettings={settingsData?.sameDayDispatchConfig}
        open={isSettingsModalOpen}
        setOpen={(shouldOpen: boolean) => {
          setIsSettingsModalOpen(shouldOpen);
        }}
      />
      <DailyControlCenterDriverRouteModal
        route={modalRoute}
        open={routeModalOpen}
        warehouseUuid={selectedWarehouseUuid}
        sameDayDispatchCellHeader={
          companyConfiguration?.sameDayDispatchCellHeader ??
          SameDayDispatchCellHeader.BillOfLadingNumber
        }
        onClose={() => {
          setRouteModalOpen(false);
        }}
      />
    </Box>
  );
};

export default DailyControlCenterView;
