import {
  Box,
  Button,
  Dialog,
  DialogActions,
  TextField,
  Typography,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import React, { type Dispatch, type SetStateAction, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import useHoldReasons from '../../../../../../../common/react-hooks/use-hold-reasons';
import {
  OrderDetailedStatus,
  OrderStatus,
  useMarkOrderAsOnHoldMutation,
} from '../../../../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { type OrderFormValues } from '../../../forms/types';
import { useUpdateAndRefetchOrder } from '../../../hooks/use-update-and-refetch-order';
import { type OnSubmitParams } from '../../../types';

const modalInnerContainerStyle = {
  bgcolor: 'background.paper',
  boxShadow: 24,
  color: 'black',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  p: 2,
};

const MarkOrderOnHoldModal = ({
  isEditMode,
  open,
  setOpen,
  orderUuid,
  onSave,
}: {
  readonly isEditMode: boolean;
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
  readonly orderUuid: string;
  /** If null, we are not in form context so we don't perform the save before these updates */
  readonly onSave: ((params: OnSubmitParams) => Promise<boolean>) | null;
}) => {
  const setValue = useFormContext<OrderFormValues>()?.setValue;
  const { getHoldReasonName, holdReasons } = useHoldReasons();
  const [holdReasonUuid, setHoldReasonUuid] = useState<string | undefined>(
    undefined,
  );
  const [markOrderAsOnHold] = useMarkOrderAsOnHoldMutation();
  const { updateAndRefetchOrder } = useUpdateAndRefetchOrder();

  const markAsOnHold = async () => {
    if (isEditMode) {
      if (isNil(onSave)) {
        // You're not in form context
        await markOrderAsOnHold({
          variables: {
            markAsOnHoldInput: {
              uuid: orderUuid,
              notes: undefined,
              holdReasonUuid,
            },
          },
        });
      } else {
        await updateAndRefetchOrder({
          additionalUpdateFns: [
            {
              fn: markOrderAsOnHold,
              vars: {
                markAsOnHoldInput: {
                  uuid: orderUuid,
                  notes: undefined,
                  holdReasonUuid,
                },
              },
            },
          ],
          onSubmit: onSave,
          actionString: 'marking as on hold',
        });
      }
    } else if (!isNil(setValue)) {
      setValue('detailedStatus', OrderDetailedStatus.OnHold);
      setValue('status', OrderStatus.OnHold);
      setValue('holdReasonUuid', holdReasonUuid);
      setValue('holdReasonName', getHoldReasonName(holdReasonUuid));
    }
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Box sx={modalInnerContainerStyle}>
        <Typography variant="h6" sx={{ mb: '20px' }}>
          Mark as on hold
        </Typography>
        <Typography color="text.secondary">Hold Reason (optional)</Typography>
        <AutocompleteFuzzy
          size="small"
          sx={{ width: '100%' }}
          value={
            !isEmpty(holdReasons) && !isNil(holdReasonUuid)
              ? {
                  value: holdReasonUuid,
                  label: getHoldReasonName(holdReasonUuid),
                }
              : { value: '', label: '' }
          }
          options={holdReasons.map((h) => ({
            value: h.uuid,
            label: h.name,
          }))}
          matchSortOptions={{ keys: ['label'] }}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              size="small"
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          )}
          onChange={(event, option) => {
            setHoldReasonUuid(option?.value);
          }}
        />
        <DialogActions>
          <Button variant="contained" sx={{ mt: 1 }} onClick={markAsOnHold}>
            Mark as on hold
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default React.memo(MarkOrderOnHoldModal);
