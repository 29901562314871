import { isBoolean, isNil } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import {
  type FieldPath,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { filterNotNil } from 'shared/array';
import { v4 } from 'uuid';
import useFormUtils from '../../../../../common/react-hooks/use-form-utils';
import {
  AccessorialType,
  CustomChargeBillingMethod,
  useSelectSpecialAccessorialLazyQuery,
} from '../../../../../generated/graphql';
import useOrderFormStore from '../../../order-form-store';
import {
  type CustomChargeValues,
  type FreightChargeValues,
  type OrderFormValues,
  type OrderFormFieldValues,
} from '../forms/types';

export const SPECIAL_LOADING_MESSAGE = 'Finding special...';

type SpecialChargeUpdate = {
  specialErrorMessage: string | null;
  // This function should be called if the user manually removes the special charge. In that case, unless the user
  // toggles the isSpecial checkbox, the special charge will not be re-added.
  onSpecialChargeManuallyRemoved: () => void;
  onCheckIsSpecial: (checked: boolean) => void;
  specialForStopLoading: boolean;
};

type SpecialChargeUpdateOptions = {
  /**
   * If true, the hook will only provide an updated specialErrorMessage as the form values change without changing
   * any values of the form in this hook. Defaults to false.
   */
  feedbackOnly?: boolean;
  dataLoading: boolean;
  stopIndex: number;
};

/**
 * Watch for relevant changes to the order and, as necessary, update the special charge for the stop or, if feedbackOnly
 * is true, only provide the specialErrorMessage string if we need to flag a potential issue.
 */
const useSpecialChargeUpdate = ({
  feedbackOnly = false,
  dataLoading,
  stopIndex,
}: SpecialChargeUpdateOptions): SpecialChargeUpdate => {
  const [
    selectSpecialAccessorial,
    { loading: selectSpecialAccessorialLoading },
  ] = useSelectSpecialAccessorialLazyQuery();

  const setIsOrderPageRating = useOrderFormStore(
    (state) => state.setIsOrderPageRating,
  );
  const { control } = useFormContext<OrderFormFieldValues>();
  const { dirtyFields, touchedFields } = useFormState({ control });
  const { setValueIfMatching } = useFormUtils<OrderFormFieldValues>();

  const orderUuid = useWatch({ control, name: 'uuid' });
  const contactUuid: string = useWatch({ control, name: 'contactUuid' });
  const isSpecial = useWatch({ control, name: `stops.${stopIndex}.isSpecial` });
  const appointmentTime = useWatch({
    control,
    name: `stops.${stopIndex}.appointmentTime`,
  });
  const endAppointmentTime = useWatch({
    control,
    name: `stops.${stopIndex}.endAppointmentTime`,
  });
  const serviceDate = useWatch({
    control,
    name: `stops.${stopIndex}.serviceDate`,
  });
  const deliveryDate = useWatch({
    control,
    name: `stops.${stopIndex}.deliveryDate`,
  });
  const address = useWatch({ control, name: `stops.${stopIndex}.address` });
  const miles = useWatch({ control, name: `stops.${stopIndex}.miles` });
  const freightChargeTariffUuid: FreightChargeValues['tariffUuid'] = useWatch({
    control,
    name: `stops.${stopIndex}.freightCharge.tariffUuid`,
  });
  const serviceUuid: OrderFormValues['serviceUuid'] = useWatch({
    control,
    name: 'serviceUuid',
  });
  const customCharges: CustomChargeValues[] = filterNotNil(
    useWatch({ control, name: `stops.${stopIndex}.customCharges` }) ?? [],
  );

  const comparePath: FieldPath<OrderFormFieldValues> = `uuid`;
  const compareCondition = {
    value: orderUuid,
    comparePath,
  };

  const abortController = useRef<AbortController | null>(null);

  const [specialErrorMessage, setSpecialErrorMessage] = useState<string | null>(
    null,
  );

  const stopDirtyFields = isBoolean(dirtyFields.stops)
    ? dirtyFields.stops
    : dirtyFields.stops?.[stopIndex];

  const anySpecialAccessorialFactorsDirty = isBoolean(stopDirtyFields)
    ? stopDirtyFields
    : stopDirtyFields?.isSpecial === true ||
      stopDirtyFields?.appointmentTime === true ||
      stopDirtyFields?.endAppointmentTime === true ||
      stopDirtyFields?.deliveryDate === true ||
      stopDirtyFields?.serviceDate === true;

  const isSpecialTouched = isBoolean(touchedFields.stops)
    ? touchedFields.stops
    : Boolean(touchedFields.stops?.[stopIndex]) ||
      touchedFields.stops?.[stopIndex]?.isSpecial === true;

  const [specialChargeManuallyRemoved, setSpecialChargeManuallyRemoved] =
    useState(false);

  useEffect(() => {
    // Watch changes to isSpecial for this stop so that we can reset specialChargeManuallyRemoved when it is checked.
    if (isSpecial === true) {
      setSpecialChargeManuallyRemoved(false);
    }
  }, [isSpecial]);

  const handleSpecialChargeUpdate = async (searchQuery: {
    startAppointment: Date;
    endAppointment: Date;
    deliveryDate: Date | null;
    serviceDate: Date;
    tariffZoneUuid: string;
    accessorialForContactUuid: string;
    serviceLevelUuid: string | null;
  }) => {
    setIsOrderPageRating(true);
    setSpecialErrorMessage(null);
    abortController.current?.abort();
    try {
      const newAbortController = new AbortController();
      abortController.current = newAbortController;

      const { startAppointment, endAppointment, accessorialForContactUuid } =
        searchQuery;

      const newSpecialData = await selectSpecialAccessorial({
        variables: {
          selectSpecialAccessorialInput: {
            contactUuid,
            startAppointment,
            endAppointment,
            miles,
            line1: address?.line1,
            city: address?.city,
            state: address?.state,
            zip: address?.zip,
            country: address?.country,
            serviceDate,
            serviceUuid,
            accessorialForContactUuid,
          },
        },
        context: {
          fetchOptions: {
            signal: newAbortController.signal,
          },
        },
      });

      const newSpecialCharge = newSpecialData?.data?.selectSpecialAccessorial;
      if (isNil(newSpecialCharge) || isNil(newSpecialCharge.accessorialUuid)) {
        setSpecialErrorMessage('No applicable special found');
      } else {
        setSpecialErrorMessage(null);
        if (!feedbackOnly) {
          const specialCharge = customCharges.find(
            (customCharge) =>
              !isNil(customCharge.specialAccessorialMatrixItemUuid),
          );
          if (isNil(specialCharge)) {
            const newCharge: CustomChargeValues = {
              uuid: v4(),
              deductionTotal: 0,
              quantity: 1,
              rate: 0,
              totalCharge: 0,
              isAutoApplied: false,
              accessorialUuid: newSpecialCharge.accessorialUuid ?? null,
              accessorialType: AccessorialType.Special,
              specialAccessorialMatrixItemUuid:
                newSpecialCharge.matrixItemUuid ?? null,
              zoneBasedAccessorialMatrixItemUuid: null,
              fuelSurchargePercentageRate: 0,
              billingMethod: CustomChargeBillingMethod.Accessorial,
              isLocal: true,
              zoneUuid: newSpecialCharge.tariffZoneUuid ?? null,
              chargeGroupUuid: newSpecialCharge.chargeGroupUuid ?? null,
              name: '',
              accessorialName: null,
              accessorialRangeUuid: null,
              postedFuelSurchargeRate: null,
              description: '',
              authoCode: null,
              settlementPercentageRate: null,
              settlementFlatRate: null,
              settlementBillingMethod: null,
              useAccessorialRate: true,
            };
            setValueIfMatching(
              `stops.${stopIndex}.customCharges`,
              [...customCharges, newCharge],
              compareCondition,
            );
          } else {
            const originalCustomChargeIndex = customCharges.findIndex(
              (customCharge) => customCharge.uuid === specialCharge.uuid,
            );
            if (originalCustomChargeIndex !== -1) {
              const newCharge: CustomChargeValues = {
                ...specialCharge,
                accessorialUuid: newSpecialCharge.accessorialUuid ?? null,
                zoneUuid: newSpecialCharge.tariffZoneUuid ?? null,
                chargeGroupUuid: newSpecialCharge.chargeGroupUuid ?? null,
                specialAccessorialMatrixItemUuid:
                  newSpecialCharge.matrixItemUuid ?? null,
              };
              setValueIfMatching(
                `stops.${stopIndex}.customCharges.${originalCustomChargeIndex}`,
                newCharge,
                compareCondition,
              );
            }
          }
        }
      }
    } finally {
      setIsOrderPageRating(false);
    }
  };

  useEffect(() => {
    if (
      isSpecial === true &&
      (anySpecialAccessorialFactorsDirty || isSpecialTouched) &&
      !dataLoading &&
      !specialChargeManuallyRemoved
    ) {
      if (
        !isNil(appointmentTime) &&
        !isNil(endAppointmentTime) &&
        !isNil(serviceDate)
      ) {
        handleSpecialChargeUpdate({
          startAppointment: appointmentTime,
          endAppointment: endAppointmentTime,
          deliveryDate: deliveryDate ?? null,
          serviceDate,
          tariffZoneUuid: freightChargeTariffUuid ?? '',
          accessorialForContactUuid: contactUuid,
          serviceLevelUuid: serviceUuid ?? null,
        });
      } else {
        setSpecialErrorMessage('Tariff or appt not found');
      }
    }
    if (!(isSpecial ?? false)) {
      setSpecialErrorMessage(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isSpecial,
    appointmentTime,
    endAppointmentTime,
    serviceDate,
    deliveryDate,
    serviceUuid,
    contactUuid,
    miles,
    freightChargeTariffUuid,
    anySpecialAccessorialFactorsDirty,
    isSpecialTouched,
    dataLoading,
    specialChargeManuallyRemoved,
  ]);

  const onSpecialChargeManuallyRemoved = () => {
    setSpecialChargeManuallyRemoved(true);
  };

  const onCheckIsSpecial = (checked: boolean) => {
    setSpecialErrorMessage('');
    setValueIfMatching(
      `stops.${stopIndex}.isSpecial`,
      checked,
      compareCondition,
      {
        shouldDirty: true,
        shouldTouch: true,
      },
    );
    const specialCharge = customCharges.find(
      (customCharge) => !isNil(customCharge.specialAccessorialMatrixItemUuid),
    );

    if (!checked && !isNil(specialCharge)) {
      setValueIfMatching(
        `stops.${stopIndex}.customCharges`,
        customCharges.filter(({ uuid }) => uuid !== specialCharge.uuid),
        compareCondition,
      );
    }
  };

  return {
    specialErrorMessage,
    specialForStopLoading: selectSpecialAccessorialLoading,
    onSpecialChargeManuallyRemoved,
    onCheckIsSpecial,
  };
};

export default useSpecialChargeUpdate;
