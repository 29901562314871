import { Check } from '@mui/icons-material';
import {
  Box,
  MenuItem,
  MenuList,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import React, { type ChangeEvent, useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { isNilOrEmptyString } from '../../../common/utils/utils';
import useReportsStore from '../reports-store';
import useStyles from '../reports-styles';
import { ReportFilterDateRangeType } from '../types/report-filters';
import DateRangeFilter from './date-range-filter';

const ReportFilterDateRangeMenuList = () => {
  const [currentDateRangeFilterType, setCurrentDateRangeFilterType] =
    useState<ReportFilterDateRangeType>();
  const styles = useStyles();

  const [reportGroupConfiguration, setCurrentReportGroupConfiguration] =
    useReportsStore(
      (state) => [
        state.reportGroupConfigurations[state.currentReportIndex],
        state.setCurrentReportGroupConfiguration,
      ],
      shallow,
    );

  useEffect(() => {
    if (!isNil(reportGroupConfiguration?.lastNumberOfDays)) {
      setCurrentDateRangeFilterType(
        ReportFilterDateRangeType.LAST_NUMBER_OF_DAYS,
      );
    } else if (!isNil(reportGroupConfiguration?.lastNumberOfWeeks)) {
      setCurrentDateRangeFilterType(
        ReportFilterDateRangeType.LAST_NUMBER_OF_WEEKS,
      );
    } else if (!isNil(reportGroupConfiguration?.lastNumberOfMonths)) {
      setCurrentDateRangeFilterType(
        ReportFilterDateRangeType.LAST_NUMBER_OF_MONTHS,
      );
    } else if (
      !isNil(reportGroupConfiguration?.startDate) ||
      !isNil(reportGroupConfiguration?.endDate)
    ) {
      setCurrentDateRangeFilterType(ReportFilterDateRangeType.RANGE);
    } else {
      setCurrentDateRangeFilterType(ReportFilterDateRangeType.ALL);
    }
  }, []);

  const changeFilterType = (type: ReportFilterDateRangeType) => {
    if (!isNil(reportGroupConfiguration)) {
      const data = { ...reportGroupConfiguration };
      if (type !== currentDateRangeFilterType) {
        data.lastNumberOfDays = undefined;
        data.lastNumberOfWeeks = undefined;
        data.lastNumberOfMonths = undefined;
        data.startDate =
          type === ReportFilterDateRangeType.RANGE ? new Date() : null;
        data.endDate =
          type === ReportFilterDateRangeType.RANGE ? new Date() : null;
        setCurrentDateRangeFilterType(type);
        setCurrentReportGroupConfiguration(data);
      }
    }
  };

  const handleChangeLastNumberOf = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const parsed: number | undefined = isNilOrEmptyString(e.target.value)
      ? undefined
      : Math.floor(Number.parseFloat(e.target.value));
    if (!isNil(reportGroupConfiguration)) {
      const data = { ...reportGroupConfiguration };
      switch (currentDateRangeFilterType) {
        case ReportFilterDateRangeType.LAST_NUMBER_OF_DAYS: {
          data.lastNumberOfDays = parsed;
          break;
        }
        case ReportFilterDateRangeType.LAST_NUMBER_OF_WEEKS: {
          data.lastNumberOfWeeks = parsed;
          break;
        }
        case ReportFilterDateRangeType.LAST_NUMBER_OF_MONTHS: {
          data.lastNumberOfMonths = parsed;
          break;
        }
        default: {
          break;
        }
      }
      setCurrentReportGroupConfiguration(data);
    }
  };

  const getUpdatedDates = (startDate: Date | null, endDate: Date | null) => {
    const newStartDate: Date = startDate ?? new Date();
    let newEndDate: Date = endDate ?? new Date();
    if (newStartDate > newEndDate) {
      newEndDate = dayjs(newStartDate).add(1, 'day').toDate();
    }
    return { startDate: newStartDate, endDate: newEndDate };
  };

  const handleStartDateChange = (date: Date | null) => {
    if (!isNil(reportGroupConfiguration) && !isNil(date)) {
      const data = { ...reportGroupConfiguration };
      const { startDate, endDate } = getUpdatedDates(date, data.endDate);
      data.startDate = startDate;
      data.endDate = endDate;
      setCurrentReportGroupConfiguration(data);
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (!isNil(reportGroupConfiguration) && !isNil(date)) {
      const data = { ...reportGroupConfiguration };
      const { startDate, endDate } = getUpdatedDates(data.startDate, date);
      data.startDate = startDate;
      data.endDate = endDate;
      setCurrentReportGroupConfiguration(data);
    }
  };

  return (
    <MenuList
      dense
      sx={{
        p: 0,
      }}
    >
      <MenuItem
        key={ReportFilterDateRangeType.ALL}
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'visible',
          pl: '10px',
        }}
        onClick={() => {
          changeFilterType(ReportFilterDateRangeType.ALL);
        }}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <Check
            sx={{
              visibility:
                currentDateRangeFilterType === ReportFilterDateRangeType.ALL
                  ? undefined
                  : 'hidden',
              fontSize: '14px',
              ml: 0,
              mr: '6px',
            }}
          />
          <Typography sx={styles.menuText}>All</Typography>
        </Stack>
      </MenuItem>
      <MenuItem
        key={ReportFilterDateRangeType.LAST_NUMBER_OF_DAYS}
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'visible',
          pl: '10px',
        }}
        onClick={() => {
          changeFilterType(ReportFilterDateRangeType.LAST_NUMBER_OF_DAYS);
        }}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <Check
            sx={{
              visibility:
                currentDateRangeFilterType ===
                ReportFilterDateRangeType.LAST_NUMBER_OF_DAYS
                  ? undefined
                  : 'hidden',
              fontSize: '14px',
              ml: 0,
              mr: '6px',
            }}
          />
          <Typography sx={styles.menuText}>Last </Typography>
          <TextField
            type="number"
            value={reportGroupConfiguration?.lastNumberOfDays}
            name="last_days"
            InputProps={{
              inputProps: { pattern: '[0-9]*', min: 0 },
            }}
            size="small"
            sx={{ width: 100 }}
            onWheel={(e) => {
              (e.target as HTMLTextAreaElement).blur();
            }}
            onChange={handleChangeLastNumberOf}
          />
          <Typography sx={styles.menuText}> Days</Typography>
        </Stack>
      </MenuItem>
      <MenuItem
        key={ReportFilterDateRangeType.LAST_NUMBER_OF_WEEKS}
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'visible',
          pl: '10px',
        }}
        onClick={() => {
          changeFilterType(ReportFilterDateRangeType.LAST_NUMBER_OF_WEEKS);
        }}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <Check
            sx={{
              visibility:
                currentDateRangeFilterType ===
                ReportFilterDateRangeType.LAST_NUMBER_OF_WEEKS
                  ? undefined
                  : 'hidden',
              fontSize: '14px',
              ml: 0,
              mr: '6px',
            }}
          />
          <Typography sx={styles.menuText}>Last </Typography>
          <TextField
            type="number"
            value={reportGroupConfiguration?.lastNumberOfWeeks}
            name="last_weeks"
            InputProps={{
              inputProps: { pattern: '[0-9]*', min: 0 },
            }}
            size="small"
            sx={{ width: 100 }}
            onWheel={(e) => {
              (e.target as HTMLTextAreaElement).blur();
            }}
            onChange={handleChangeLastNumberOf}
          />
          <Typography sx={styles.menuText}> Weeks</Typography>
        </Stack>
      </MenuItem>
      <MenuItem
        key={ReportFilterDateRangeType.LAST_NUMBER_OF_MONTHS}
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'visible',
          pl: '10px',
        }}
        onClick={() => {
          changeFilterType(ReportFilterDateRangeType.LAST_NUMBER_OF_MONTHS);
        }}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <Check
            sx={{
              visibility:
                currentDateRangeFilterType ===
                ReportFilterDateRangeType.LAST_NUMBER_OF_MONTHS
                  ? undefined
                  : 'hidden',
              fontSize: '14px',
              ml: 0,
              mr: '6px',
            }}
          />
          <Typography sx={styles.menuText}>Last </Typography>
          <TextField
            type="number"
            value={reportGroupConfiguration?.lastNumberOfMonths}
            name="last_months"
            InputProps={{
              inputProps: { pattern: '[0-9]*', min: 0 },
            }}
            size="small"
            sx={{ width: 100 }}
            onWheel={(e) => {
              (e.target as HTMLTextAreaElement).blur();
            }}
            onChange={handleChangeLastNumberOf}
          />
          <Typography sx={styles.menuText}> Months</Typography>
        </Stack>
      </MenuItem>
      <MenuItem
        key={ReportFilterDateRangeType.RANGE}
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'visible',
          pl: '10px',
        }}
        onClick={() => {
          changeFilterType(ReportFilterDateRangeType.RANGE);
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Check
            sx={{
              visibility:
                currentDateRangeFilterType === ReportFilterDateRangeType.RANGE
                  ? undefined
                  : 'hidden',
              fontSize: '14px',
              ml: 0,
              mr: '6px',
            }}
          />
          <DateRangeFilter
            startDate={reportGroupConfiguration?.startDate}
            endDate={reportGroupConfiguration?.endDate}
            handleStartDateChange={handleStartDateChange}
            handleEndDateChange={handleEndDateChange}
            allowNoLimit={false}
          />
        </Box>
      </MenuItem>
    </MenuList>
  );
};

export default ReportFilterDateRangeMenuList;
