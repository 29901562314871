import ModeIcon from '@mui/icons-material/Mode';
import {
  TextField,
  Typography,
  Box,
  FormControl,
  Stack,
  FormHelperText,
  Button,
  useTheme,
  type SxProps,
} from '@mui/material';
import { isNil } from 'lodash';
import { useState } from 'react';
import { Controller, type FieldError, useFormContext } from 'react-hook-form';
import { isNilOrEmptyString } from '../../../../../../../common/utils/utils';

/**
 * SxProps to add margin-top: '16px' if the element is siblings with
 * an AuthoCodeComponent, to keep the element vertically centered.
 */
export const MT_IF_HAS_AUTHO_CODE_STYLES: SxProps = {
  '&:has(+ .charge-autho-code-component)': {
    marginTop: '16px',
  },
};

const AuthoCodeComponent = ({
  keyString,
  authoCode,
  disabled,
  required,
  error,
}: {
  readonly keyString: string;
  readonly authoCode: string;
  readonly disabled?: boolean;
  readonly required?: boolean;
  readonly error?: FieldError;
}) => {
  const { control } = useFormContext();
  const theme = useTheme();
  const [isEditing, setIsEditing] = useState(false);
  const placeholder = `Add autho code${required === true ? ' *' : ''}`;

  if (disabled === true && isNilOrEmptyString(authoCode)) {
    return null;
  }

  return (
    <Box width={160} className="charge-autho-code-component">
      {isEditing ? (
        <Controller
          name={`${keyString}.authoCode`}
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControl>
              <TextField
                autoFocus
                size="small"
                value={value}
                variant="standard"
                placeholder={placeholder}
                disabled={disabled}
                onBlur={() => {
                  setIsEditing(false);
                }}
                onChange={onChange}
              />
            </FormControl>
          )}
        />
      ) : (
        <Stack direction="row" alignItems="center">
          <Button
            disabled={disabled}
            variant="text"
            size="small"
            endIcon={
              disabled !== true && (
                <ModeIcon
                  sx={{ fontSize: '16px', color: theme.palette.grey[500] }}
                />
              )
            }
            sx={{ ml: -0.5 }}
            onClick={() => {
              setIsEditing(true);
            }}
          >
            <Typography color={theme.palette.grey[500]}>
              {isNilOrEmptyString(authoCode) ? placeholder : authoCode}
            </Typography>
          </Button>
        </Stack>
      )}
      {!isNil(error) && (
        <FormHelperText sx={{ color: '#D32F2F' }}>
          {error?.message}
        </FormHelperText>
      )}
    </Box>
  );
};

export default AuthoCodeComponent;
