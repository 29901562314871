import { isNil } from 'lodash';
import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { getNoonOfDay } from 'shared/date';
import { INBOUND_STOP_IDX, OUTBOUND_STOP_IDX } from '../components/constants';
import { isServiceDateRequiredForStop } from '../forms/service-date-utils';
import { type OrderFormValues } from '../forms/types';

export const useServiceDateUtilities = () => {
  const { control, getValues, setValue } = useFormContext<OrderFormValues>();

  const inboundStopType = useWatch({
    control,
    name: `stops.${INBOUND_STOP_IDX}.stopType`,
  });

  const outboundStopType = useWatch({
    control,
    name: `stops.${OUTBOUND_STOP_IDX}.stopType`,
  });

  /**
   * A value that denotes whether the service date for the outbound stop is required.
   * See https://docs.google.com/spreadsheets/d/1MUouVYFLjKQP6VOR3BteO4GIEf-lr5qCrvN_fjF6Y8w/edit?usp=sharing
   */
  const outboundServiceDateRequired = useMemo(() => {
    return isServiceDateRequiredForStop({
      stopType: outboundStopType,
      otherStopType: inboundStopType,
    });
  }, [inboundStopType, outboundStopType]);

  /**
   * Service date is one of the values in this order of priority:
   * - Completed at
   * - Route date
   * - Appointment / delivery date
   * - Deadline date
   *
   * It defaults to the current date on creation, and freezes to the
   * completion date on completion. It can also be manually edited, which can
   * override the others (except for completion).
   *
   * Adding a stop to route is treated like a manual edit, in that removing
   * the stop from the route will not reset the service date (it will remain
   * the route date).
   *
   * Call this function after modifying delivery or deadline date from the
   * frontend.
   */
  const updateServiceDate = ({ stopIdx }: { stopIdx: number }) => {
    const completedAt = getValues(`stops.${stopIdx}.completedAt`);
    const routeDate = getValues(`stops.${stopIdx}.routeDate`);
    const deadlineDate = getValues(`stops.${stopIdx}.deadlineDate`);
    const deliveryDate = getValues(`stops.${stopIdx}.deliveryDate`);
    if (!isNil(completedAt) || !isNil(routeDate)) {
      return;
    }
    if (!isNil(deliveryDate)) {
      setValue(`stops.${stopIdx}.serviceDate`, getNoonOfDay(deliveryDate));
      return;
    }
    if (!isNil(deadlineDate)) {
      setValue(`stops.${stopIdx}.serviceDate`, getNoonOfDay(deadlineDate));
    }
  };

  return {
    updateServiceDate,
    outboundServiceDateRequired,
  };
};
