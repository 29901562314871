import { Add } from '@mui/icons-material';
// eslint-disable-next-line no-restricted-imports
import { Button, ButtonGroup, Grid, TextField, useTheme } from '@mui/material';
import {
  type ColDef,
  type FilterChangedEvent,
  type GridReadyEvent,
  type IServerSideGetRowsParams,
  type SideBarDef,
} from 'ag-grid-community';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import useStateRef from 'react-usestateref';
import styled from 'styled-components';
import apolloClient from '../../../../apollo-client';
import CSVDownloadButton from '../../../../common/components/buttons/csv-download-button';
import CustomerFilterButton from '../../../../common/components/customer-filter-button';
import { type DateOption } from '../../../../common/components/date-dropdown-picker';
import { type Option } from '../../../../common/filters/types';
import useMe from '../../../../common/react-hooks/use-me';
import {
  FilterOperator,
  type FilterViewPage,
  type FindItemGroupsFragment,
  ItemGroupQuantityFilterType,
  ItemGroupsDocument,
  type ItemGroupsQuery,
  type ItemGroupsQueryVariables,
  StringFilterType,
  useItemGroupsLazyQuery,
  useWarehouseLocationsLazyQuery,
  useWmsContactsQuery,
} from '../../../../generated/graphql';
import useCustomerPortalStore from '../../../customer-portal/use-customer-portal-store';
import {
  type DateFilterOption,
  type FilterModel,
  booleanFilterOptionToBooleanFilter,
  convertDateFilterOptionToDateFilters,
} from '../../../orders/components/enums/order-filters';
import SelectedFilterButtonForToolbar from '../../../orders/components/selected-filter-button-for-toolbar';
import WarehouseSelector from '../../../storage-orders/components/common/warehouse-selector';
import {
  InventoryFilterField,
  downloadLightingInventoryReportData,
  generateFullInventoryReportData,
} from '../../../storage-orders/utils';
import useFilterStore from '../../filter-store';

const LIGHTNING_EXPRESS_UUID = '99d3cf27-4222-451e-8522-c432b9a2c2b5';

const ItemGroupsFilterRow = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  mt: 10px;
  p: 7px;
  width: 100%;
`;

const useStyles = () => {
  const theme = useTheme();
  return {
    mainColor: {
      color: theme.palette.primary.main,
    },
    filterButton: {
      backgroundColor: theme.palette.primary.light,
      borderRadius: '4px',
    },
    menuText: {
      fontSize: '14px',
    },
    totalText: {
      ml: '4px',
      mb: '2px',
      color: 'black',
      fontSize: '15px',
    },
    filterTitle: {
      fontSize: '14px',
      ml: '3px',
    },
    filterValue: {
      fontSize: '14px',
      ml: '5px',
      fontWeight: 'bold',
    },
    createButtonRow: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: 1,
    },
  };
};

type State = {
  searchText: string;
  quantityFilterType: ItemGroupQuantityFilterType;
  currentCursor: string | null | undefined;
  customFilterModelJson: FilterModel;
  currentFilterViewUuid: string | null;
  currentFilterViewName: string | null;
  customerOption: Option | undefined;
  companyOption: Option | undefined;
  dateOption: DateOption | undefined;
  warehouseOption: Option | undefined;
};

const getFindItemGroupsQueryVariables = ({
  filterModel,
  customerOption,
  companyOption,
  warehouseOption,
}: {
  filterModel: FilterModel;
  customerOption?: Option;
  companyOption?: Option;
  warehouseOption?: Option;
}): ItemGroupsQueryVariables => {
  let result: ItemGroupsQueryVariables = {
    findItemGroupsInput: {
      companyUuidFilter: isNil(companyOption)
        ? undefined
        : {
            filterType: StringFilterType.Equals,
            value: companyOption.value,
            filterOperator: FilterOperator.And,
          },
      contactUuidFilter:
        customerOption?.value == null
          ? undefined
          : {
              filterType: StringFilterType.Equals,
              value: customerOption.value,
              filterOperator: FilterOperator.And,
            },
      warehouseUuidFilter:
        warehouseOption?.value == null
          ? undefined
          : {
              filterType: StringFilterType.Equals,
              value: warehouseOption.value,
              filterOperator: FilterOperator.And,
            },
    },
  };
  const addFilterFromFilterModelToResult = (
    currentResult: ItemGroupsQueryVariables,
    filterField: InventoryFilterField,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unused-vars
    filterModelValue: any,
  ): ItemGroupsQueryVariables => {
    switch (filterField) {
      case InventoryFilterField.DATE_MARKED_ON_HAND: {
        if (isNil(filterModelValue)) {
          return {
            ...currentResult,
          };
        }
        const currentFindItemGroupsInput = currentResult.findItemGroupsInput;
        return {
          findItemGroupsInput: {
            ...currentFindItemGroupsInput,
            markedOnHandAtDateFilters: convertDateFilterOptionToDateFilters({
              dateFilterOption: filterModelValue.value as DateFilterOption,
              startDate: filterModelValue.startDate as Date,
              endDate: filterModelValue.endDate as Date,
            }),
          },
        };
      }
      case InventoryFilterField.DATE_MARKED_OSD: {
        if (isNil(filterModelValue)) {
          return {
            ...currentResult,
          };
        }
        const currentFindItemGroupsInput = currentResult.findItemGroupsInput;
        return {
          findItemGroupsInput: {
            ...currentFindItemGroupsInput,
            markedOsdAtDateFilters: convertDateFilterOptionToDateFilters({
              dateFilterOption: filterModelValue.value as DateFilterOption,
              startDate: filterModelValue.startDate as Date,
              endDate: filterModelValue.endDate as Date,
            }),
          },
        };
      }
      case InventoryFilterField.DATE_PICKED: {
        if (isNil(filterModelValue)) {
          return {
            ...currentResult,
          };
        }
        const currentFindItemGroupsInput = currentResult.findItemGroupsInput;
        return {
          findItemGroupsInput: {
            ...currentFindItemGroupsInput,
            pickedAtDateFilters: convertDateFilterOptionToDateFilters({
              dateFilterOption: filterModelValue.value as DateFilterOption,
              startDate: filterModelValue.startDate as Date,
              endDate: filterModelValue.endDate as Date,
            }),
          },
        };
      }
      case InventoryFilterField.WAREHOUSE_LOCATION: {
        const { value } = filterModelValue;
        if (isNil(value)) {
          return {
            ...currentResult,
          };
        }
        const currentFindItemGroupsInput = currentResult.findItemGroupsInput;
        return {
          findItemGroupsInput: {
            ...currentFindItemGroupsInput,
            warehouseLocationUuidFilter: {
              filterType: StringFilterType.Equals,
              value,
            },
          },
        };
      }
      case InventoryFilterField.BILLED: {
        const { value } = filterModelValue;
        if (isNil(value)) {
          return {
            ...currentResult,
          };
        }
        const currentFindItemGroupsInput = currentResult.findItemGroupsInput;
        return {
          ...currentResult,
          findItemGroupsInput: {
            ...currentFindItemGroupsInput,
            billedFilter: {
              filterOperator: filterModelValue.filterOperator,
              value: booleanFilterOptionToBooleanFilter(filterModelValue.value),
            },
          },
        };
      }
      default: {
        return {
          ...currentResult,
        };
      }
    }
  };

  for (const key of Object.keys(filterModel)) {
    const value = filterModel[key];
    result = addFilterFromFilterModelToResult(
      result,
      key as InventoryFilterField,
      value,
    );
  }

  return result;
};

type InventoryTableProps = {
  readonly columnDefinitions: Array<ColDef<FindItemGroupsFragment>>;
  readonly pageSize: number;
  readonly pageType: FilterViewPage;
  readonly shouldRememberFilters?: boolean;

  readonly shouldRefreshGrid: boolean;

  readonly setShouldRefreshGrid: Dispatch<SetStateAction<boolean>>;
};

const InventoryTableWithFiltersAgGrid = ({
  columnDefinitions,
  pageSize,
  pageType,
  shouldRememberFilters = false,
  shouldRefreshGrid,
  setShouldRefreshGrid,
}: InventoryTableProps) => {
  const styles = useStyles();
  const gridRef = useRef<AgGridReact<FindItemGroupsFragment>>(null);
  const toolPanelVisibleRef = useRef(false);

  const [fetchWarehouseLocations] = useWarehouseLocationsLazyQuery();

  const {
    rememberedFilters,
    setRememberedFilters,
    rememberedSearch,
    setRememberedSearch,
  } = useFilterStore((state) => {
    return {
      rememberedSearch: state.search,
      rememberedFilters: state.filters,
      setRememberedFilters: state.setFilters,
      setRememberedSearch: state.setSearch,
    };
  });

  const [state, setState, stateRef] = useStateRef<State>({
    searchText: shouldRememberFilters ? (rememberedSearch[pageType] ?? '') : '',
    currentCursor: null,
    customFilterModelJson: {},
    currentFilterViewName: null,
    currentFilterViewUuid: null,
    customerOption: undefined,
    companyOption: undefined,
    dateOption: undefined,
    quantityFilterType: ItemGroupQuantityFilterType.NotZero,
    warehouseOption: undefined,
  });
  const [searching, setSearching] = useState<boolean>(false);
  const [columnDefs, setColumnDefs] =
    useState<Array<ColDef<FindItemGroupsFragment>>>(columnDefinitions);

  const customerPortalCompany = useCustomerPortalStore(
    ({ company }) => company,
  );
  useEffect(() => {
    setState((prevState) => {
      return {
        ...prevState,
        companyOption: isNil(customerPortalCompany)
          ? undefined
          : {
              value: customerPortalCompany?.uuid,
              label: customerPortalCompany?.name,
            },
      };
    });
    setShouldRefreshGrid(true);
  }, [customerPortalCompany, setState, setShouldRefreshGrid]);

  const { data: wmsContactsData } = useWmsContactsQuery({
    variables: {
      companyUuid: customerPortalCompany?.uuid,
    },
    fetchPolicy: 'cache-and-network',
  });
  useEffect(() => {
    if (
      wmsContactsData?.wmsContacts.find(
        (c) => c.uuid === state.customerOption?.value,
      ) === undefined
    ) {
      setState((prevState) => ({
        ...prevState,
        customerOption: undefined,
      }));
    }
  }, [wmsContactsData, state.customerOption, setState]);

  const [getItemGroupsInventoryForReport] = useItemGroupsLazyQuery();
  const [downloadInventoryReportLoading, setDownloadInventoryReportLoading] =
    useState(false);

  const createServerSideDatasource = () => {
    return {
      async getRows(params: IServerSideGetRowsParams<FindItemGroupsFragment>) {
        let variables = getFindItemGroupsQueryVariables({
          filterModel: params.request.filterModel,
          customerOption: stateRef.current.customerOption,
          companyOption: stateRef.current.companyOption,
          warehouseOption: stateRef.current.warehouseOption,
        });
        const { findItemGroupsInput } = variables;
        const newFindItemGroupsInput = {
          ...findItemGroupsInput,
          first: pageSize,
          after: stateRef.current.currentCursor,
          searchText: stateRef.current.searchText?.trim(),
          quantityFilter: {
            type: stateRef.current.quantityFilterType,
          },
        };
        variables = { findItemGroupsInput: newFindItemGroupsInput };
        const response = await apolloClient.query<ItemGroupsQuery>({
          query: ItemGroupsDocument,
          variables,
        });
        setState((prevState) => {
          return {
            ...prevState,
            currentCursor: response.data?.itemGroups.pageInfo.endCursor,
          };
        });
        params.success({
          rowData:
            response.data.itemGroups.edges.map((edge) => edge.node) ?? [],
          rowCount: response.data?.itemGroups.totalCount ?? undefined,
        });
      },
    };
  };

  const inputForInventoryExport = useMemo(() => {
    const filterModel = gridRef.current?.api?.getFilterModel();
    return getFindItemGroupsQueryVariables({
      filterModel: filterModel ?? {},
      customerOption: state.customerOption,
      companyOption: state.companyOption,
      warehouseOption: state.warehouseOption,
    });
  }, [state.companyOption, state.customerOption, state.warehouseOption]);

  const fetchItemGroupsForInventoryReport = async () => {
    const filterModel = gridRef.current?.api?.getFilterModel();
    let variables = getFindItemGroupsQueryVariables({
      filterModel: filterModel ?? {},
      customerOption: stateRef.current.customerOption,
      companyOption: stateRef.current.companyOption,
      warehouseOption: stateRef.current.warehouseOption,
    });

    const { findItemGroupsInput } = variables;
    const newFindItemGroupsInput = {
      ...findItemGroupsInput,
      searchText: stateRef.current.searchText?.trim(),
      noMaximum: true,
      quantityFilter: {
        type: stateRef.current.quantityFilterType,
      },
    };
    variables = { findItemGroupsInput: newFindItemGroupsInput };
    const res = await getItemGroupsInventoryForReport({
      variables,
    });
    return res.data?.itemGroups.edges.map(({ node: itemGroup }) => itemGroup);
  };

  // call this if we want to refresh the grid (filters change, etc.)
  const refreshGrid = (refreshServerSide = true) => {
    if (!isNil(gridRef.current?.api)) {
      gridRef.current?.api.showNoRowsOverlay();
      if (refreshServerSide) {
        gridRef.current?.api.refreshServerSide({ purge: true });
      }
      gridRef.current?.api.paginationGoToFirstPage();
      gridRef.current?.api.hideOverlay();
    }
  };

  useEffect(() => {
    if (shouldRefreshGrid) {
      setState((prevState) => {
        return {
          ...prevState,
          currentCursor: null,
        };
      });
      refreshGrid(true);
      setShouldRefreshGrid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefreshGrid]);

  /**
   * Restores any remembered filters.
   */
  const handleRememberedFilters = (params: GridReadyEvent) => {
    const rememberedFilter = rememberedFilters[pageType];
    if (!isNil(rememberedFilter)) {
      params.api.setFilterModel(JSON.parse(rememberedFilter));
    }
  };

  useEffect(() => {
    setColumnDefs(columnDefinitions);
    if (!isNil(gridRef.current?.api)) {
      gridRef.current?.api.sizeColumnsToFit();
    }
  }, [columnDefinitions]);

  const onGridReady = (params: GridReadyEvent) => {
    const datasource = createServerSideDatasource();
    params.api.setServerSideDatasource(datasource);
    params.api.sizeColumnsToFit();
    params.api.closeToolPanel();
    if (shouldRememberFilters) {
      handleRememberedFilters(params);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const closeFilterToolPanel = () => {
    const filterToolPanel =
      gridRef.current?.api.getToolPanelInstance('filters');
    filterToolPanel?.collapseFilters();
    // reset filters to all before closing.
    filterToolPanel?.refresh();
    filterToolPanel?.setFilterLayout(columnDefs);
    gridRef.current?.api.closeToolPanel();
  };

  const defaultColDef: ColDef<FindItemGroupsFragment> = useMemo(() => {
    return {
      flex: 1,
      autoHeight: true,
      wrapHeaderText: true,
      wrapText: true,
      resizable: false,
      suppressMenu: true,
    };
  }, []);

  const sideBar = useMemo<
    SideBarDef | string | string[] | boolean | null
  >(() => {
    return {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
          toolPanelParams: {
            suppressExpandAll: false,
            suppressFilterSearch: false,
          },
        },
      ],
      defaultToolPanel: 'filters',
      position: 'left',
    };
  }, []);

  // This closes the toolpanel if we click outside the filter toolpanel since AG Grid doesn't have an API for this
  useEffect(() => {
    const handleDocumentClick = (event: Event) => {
      const gridApi = gridRef.current?.api;
      const isToolPanelClicked = (event.target as Element).closest(
        '.ag-filter-toolpanel',
      );

      const isDateRangePickerClicked = (event.target as Element).closest(
        '.rmdp-wrapper',
      );

      if (
        gridApi &&
        !isToolPanelClicked &&
        !isDateRangePickerClicked &&
        toolPanelVisibleRef.current
      ) {
        closeFilterToolPanel();
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [closeFilterToolPanel]);

  useEffect(() => {
    const fetchDataAndPopulateColumnDefs = async () => {
      const warehouseLocations = await fetchWarehouseLocations({
        fetchPolicy: 'cache-first',
        variables: {
          companyUuid: customerPortalCompany?.uuid,
        },
      });

      setColumnDefs((prevColumnDefs) => {
        const updatedColumnDefs = [...prevColumnDefs];
        // WAREHOUSE LOCATION
        const prevIndex = prevColumnDefs.findIndex(
          (def) => def.field === InventoryFilterField.WAREHOUSE_LOCATION,
        );
        const updatedColumnDefWarehouseLocations = updatedColumnDefs[prevIndex];
        if (
          !isNil(updatedColumnDefWarehouseLocations) &&
          !isNil(updatedColumnDefWarehouseLocations.filterParams)
        ) {
          updatedColumnDefWarehouseLocations.filterParams.values =
            warehouseLocations.data?.warehouseLocations?.warehouseLocations.map(
              (warehouseLocation) => {
                return {
                  actualValue: warehouseLocation.uuid,
                  displayValue: warehouseLocation.name,
                };
              },
            ) ?? [];
          updatedColumnDefs[prevIndex] = updatedColumnDefWarehouseLocations;
        }

        return updatedColumnDefs;
      });
    };
    fetchDataAndPopulateColumnDefs();
  }, [columnDefinitions, customerPortalCompany, fetchWarehouseLocations]);

  const destroyFilter = useCallback((field: InventoryFilterField) => {
    gridRef.current?.api.destroyFilter(field);
  }, []);

  const handleDeleteFilter = async (field: InventoryFilterField) => {
    // reset the filter
    destroyFilter(field);

    // collapse that filter in the panel.
    const toolPanel = gridRef.current?.api.getToolPanelInstance('filters');
    toolPanel?.collapseFilters([field]);
  };
  const handleClickSelectedFilter = async (field: InventoryFilterField) => {
    // open the toolpanel and show that filter
    const toolPanel = gridRef.current?.api.getToolPanelInstance('filters');
    toolPanel?.expandFilters([field]);
    toolPanel?.setFilterLayout([
      columnDefs.find((colDef) => colDef.field === field) ?? {},
    ]);
    gridRef.current?.api.openToolPanel('filters');
  };

  // callback which AG Grid calls when a filter changes.
  const handleFilterChanged = (_event: FilterChangedEvent) => {
    const filterModel = gridRef.current?.api?.getFilterModel();

    if (shouldRememberFilters) {
      setRememberedFilters(JSON.stringify(filterModel ?? {}), pageType);
    }

    setState((prevState) => {
      return {
        ...prevState,
        currentCursor: null,
      };
    });
  };

  const handleSearch = () => {
    setSearching(true);
    setState((prevState) => {
      return {
        ...prevState,
        currentCursor: null,
      };
    });
    refreshGrid(true);
    setSearching(false);
  };
  const { companyUuid } = useMe();

  const handleDownloadInventoryReport = async () => {
    setDownloadInventoryReportLoading(true);
    try {
      const itemGroups = await fetchItemGroupsForInventoryReport();
      if (isNil(itemGroups)) return;
      await downloadLightingInventoryReportData(itemGroups);
    } finally {
      setDownloadInventoryReportLoading(false);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <TextField
          size="small"
          label="Search containers"
          InputProps={{ style: { backgroundColor: 'white' } }}
          value={stateRef.current.searchText}
          sx={{ width: '400px' }}
          onKeyDown={async (e) => {
            if (e.key === 'Enter') {
              handleSearch();
            }
          }}
          onChange={(e) => {
            setState((prevState: State) => {
              if (shouldRememberFilters) {
                setRememberedSearch(e.target.value, pageType);
              }
              return {
                ...prevState,
                searchText: e.target.value,
                currentCursor: null,
              };
            });
          }}
        />
        <Button variant="contained" disabled={searching} onClick={handleSearch}>
          Search
        </Button>
      </Grid>
      <Grid item xs={6} sx={styles.createButtonRow}>
        {(customerPortalCompany?.uuid ?? companyUuid) ===
          LIGHTNING_EXPRESS_UUID && (
          <Button
            disabled={downloadInventoryReportLoading}
            variant="contained"
            onClick={handleDownloadInventoryReport}
          >
            Inventory report
          </Button>
        )}
        <CSVDownloadButton
          getData={async () => {
            const itemGroups = await fetchItemGroupsForInventoryReport();
            if (isNil(itemGroups)) return [];
            return generateFullInventoryReportData(itemGroups);
          }}
          filename={`inventory-export-${dayjs().format('MM-DD')}.csv`}
          label="Data export"
          buttonProps={{ variant: 'contained' }}
          reportType="inventory-export"
          dataForTimingLog={{ ...inputForInventoryExport, downloadType: 'CSV' }}
        />
      </Grid>
      <ItemGroupsFilterRow item xs={12}>
        <ButtonGroup
          disableElevation
          variant="outlined"
          size="small"
          aria-label="invoice-status-filters"
        >
          <Button
            variant={
              state.quantityFilterType === ItemGroupQuantityFilterType.NotZero
                ? 'contained'
                : 'outlined'
            }
            onClick={() => {
              setState((prevState) => {
                return {
                  ...prevState,
                  quantityFilterType: ItemGroupQuantityFilterType.NotZero,
                };
              });
              setShouldRefreshGrid(true);
            }}
          >
            Available
          </Button>
          <Button
            variant={
              state.quantityFilterType === ItemGroupQuantityFilterType.OnlyZero
                ? 'contained'
                : 'outlined'
            }
            onClick={() => {
              setState((prevState) => {
                return {
                  ...prevState,
                  quantityFilterType: ItemGroupQuantityFilterType.OnlyZero,
                };
              });
              setShouldRefreshGrid(true);
            }}
          >
            Picked
          </Button>
        </ButtonGroup>
        <WarehouseSelector
          value={state.warehouseOption ?? null}
          cacheId="inventory"
          onChange={(option) => {
            setState((prevState) => ({
              ...prevState,
              warehouseOption: option ?? undefined,
            }));
            handleSearch();
          }}
        />
        {(wmsContactsData?.wmsContacts.length ?? 0) > 1 && (
          <CustomerFilterButton
            isSmall
            cacheId="inventory"
            contactsOverride={wmsContactsData?.wmsContacts}
            selectedOption={state.customerOption}
            handleChange={(option) => {
              setState((prevState) => ({
                ...prevState,
                customerOption: option,
              }));
              handleSearch();
            }}
          />
        )}
        <Button
          size="small"
          startIcon={<Add />}
          sx={styles.filterButton}
          variant="outlined"
          onClick={(_e) => {
            gridRef.current?.api.openToolPanel('filters');
          }}
        >
          Filter
        </Button>
        {Object.keys(gridRef.current?.api?.getFilterModel() ?? {}).map(
          (key) => {
            return (
              <SelectedFilterButtonForToolbar
                key={key}
                prependText={undefined}
                filterModel={gridRef.current?.api?.getFilterModel()}
                keyName={key}
                handleDelete={async () =>
                  handleDeleteFilter(key as InventoryFilterField)
                }
                handleSelect={async () =>
                  handleClickSelectedFilter(key as InventoryFilterField)
                }
              />
            );
          },
        )}
      </ItemGroupsFilterRow>
      <Grid item xs={12}>
        <div
          className="ag-theme-material ag-non-compact"
          style={{ height: '100%', width: '100%' }}
        >
          <AgGridReact<FindItemGroupsFragment>
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref={gridRef}
            pagination
            animateRows
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowModelType="serverSide"
            paginationPageSize={pageSize}
            cacheBlockSize={pageSize}
            domLayout="autoHeight"
            sideBar={sideBar}
            onGridReady={onGridReady}
            onFilterChanged={handleFilterChanged}
            onToolPanelVisibleChanged={() => {
              toolPanelVisibleRef.current = !toolPanelVisibleRef.current;
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default InventoryTableWithFiltersAgGrid;
