import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { type Resolver, useForm } from 'react-hook-form';

export type MarkStopAsCompletedFormValues = {
  completedDate: Date;
  podSigneeName: string;
};

export type MarkStopAsCompletedFormContext = {
  requirePODSignee?: boolean;
  defaultDate?: Date;
};

const resolver: Resolver<MarkStopAsCompletedFormValues> = async (
  formValues,
  context,
) => {
  const errors: {
    [property in keyof MarkStopAsCompletedFormValues]?: {
      type: string;
      message: string;
    };
  } = {};

  if (
    isEmpty(formValues.podSigneeName) &&
    Boolean((context as MarkStopAsCompletedFormContext).requirePODSignee)
  ) {
    errors.podSigneeName = {
      type: 'required',
      message: 'Name is required',
    };
  }

  if (!dayjs(formValues.completedDate).isValid()) {
    errors.completedDate = {
      type: 'required',
      message: 'Date Completed is required',
    };
  }

  return {
    values: formValues,
    errors,
  };
};

const useMarkStopAsCompletedForm = ({
  context,
}: {
  context: MarkStopAsCompletedFormContext;
}) => {
  return useForm({
    resolver,
    context,
    defaultValues: { completedDate: context.defaultDate },
  });
};

export default useMarkStopAsCompletedForm;
