import {
  type BodyScrollEndEvent,
  type ColDef,
  type ColumnMovedEvent,
  type FilterChangedEvent,
  type GetRowIdFunc,
  type GridReadyEvent,
  type IRowDragItem,
  type IServerSideGetRowsParams,
  type IsFullWidthRowParams,
  type LoadSuccessParams,
  type PaginationChangedEvent,
  type RowSelectedEvent,
  type SelectionChangedEvent,
  type SideBarDef,
  type SortModelItem,
} from 'ag-grid-community';
import { type AgGridReact } from 'ag-grid-react';
import { isEmpty, isNil } from 'lodash';
import pluralize from 'pluralize';
import React, {
  type Dispatch,
  forwardRef,
  type SetStateAction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { filterNotNil } from 'shared/array';
import useLocalStorageState from 'use-local-storage-state';
import { shallow } from 'zustand/shallow';
import apolloClient from '../../../apollo-client';
import { FeatureFlag } from '../../../common/feature-flags';
import { UNASSIGNED_STOPS_TABLE_COLUMNS_KEY } from '../../../common/local-storage/keys';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import useMe from '../../../common/react-hooks/use-me';
import {
  DispatchTableField,
  type FilterViewPage,
  type StopFragment,
  StopsOnRoutesBySearchTextDocument,
  type StopsOnRoutesBySearchTextQuery,
  type StopsOnRoutesBySearchTextQueryVariables,
  type StopsQueryVariables,
  TableFieldsDocument,
  useUpdateUserMutation,
} from '../../../generated/graphql';
import useDispatchStore from '../../dispatch/dispatch-store';
import useFetchStops from '../../dispatch/hooks/use-fetch-stops';
import type { StopsTableElement } from '../../dispatch/types/stops';
import {
  getStopsAndRecurringRunHeadersFromNodes,
  isRecurringRunHeaderFragmentNode,
  isStopFragment,
  isStopFragmentNode,
} from '../../dispatch/utils';
import { type FilterModel } from '../../orders/components/enums/order-filters';
import useFilterStore from '../filter-store';
import { type ReadOnlyRefObject } from '../orders/types';
import PalletAgGridReact from '../pallet-ag-grid/pallet-ag-grid-react';
import { useNewTableFunctionsFeatureFlag } from '../use-new-table-functions-feature-flag';
import { type StopsTab } from './constants';
import { fullWidthRecurringRunRenderer } from './full-width-recurring-run-renderer';
import 'ag-grid-enterprise';
import { useStopsPanelTabFunctions } from './hooks/use-stops-panel-tab-functions';
import NewStopsPopover from './new-stops-popover';
import {
  type DefaultFilterTabsConfigs,
  type DispatchSortV2,
  type DispatchTableFilterModel,
  type State,
} from './types';
import {
  getDispatchSortV2,
  getDispatchTableFilterModel,
  isLegacyFilterModel,
  migrateLegacyFilterModel,
} from './utils';

const DEFAULT_COL_DEF: Readonly<ColDef<StopsTableElement>> = {
  wrapHeaderText: false,
  resizable: true,
  suppressMenu: true,
  unSortIcon: false,
  wrapText: false,
};

const STOPS_PER_PAGE = 30;

const getElementUuid = (elem: StopsTableElement) =>
  isStopFragment(elem) ? elem.stop.uuid : elem.uuid;

const getRowId: GetRowIdFunc<StopsTableElement> = ({ data }) =>
  getElementUuid(data);

const isFullWidthRow = ({ rowNode }: IsFullWidthRowParams<StopsTableElement>) =>
  isRecurringRunHeaderFragmentNode(rowNode);

const SIDE_BAR: SideBarDef = {
  toolPanels: [
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
      toolPanelParams: {
        suppressExpandAll: false,
        suppressFilterSearch: false,
      },
    },
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressRowGroups: true,
        suppressValues: true,
        suppressPivots: true,
        suppressPivotMode: true,
        suppressColumnSelectAll: true,
        suppressColumnExpandAll: true,
      },
    },
  ],
  defaultToolPanel: 'filters',
  position: 'left',
};

type DispatchStopsTableProps = {
  readonly pageType: FilterViewPage;
  readonly onDataChanged?: (variables: StopsQueryVariables) => void;
  readonly sequentiallyLoadRouteCardsAfterLoad?: boolean;
  readonly setChangedSortModel: Dispatch<
    SetStateAction<DispatchSortV2[] | null>
  >;
  readonly setState: Dispatch<React.SetStateAction<State>>;
  readonly stateRef: ReadOnlyRefObject<State>;
  readonly onRowDragEnter: () => void;
  readonly defaultFilterTabsConfigs: DefaultFilterTabsConfigs<StopsTab>;
  readonly selectedTerminalUuid: string | undefined;
  readonly applyTableColumns: (tableFields: DispatchTableField[]) => void;
  readonly getFetchStopsVariables: ({
    filterModel,
    sortModel,
    includeCount,
  }: {
    filterModel: FilterModel;
    sortModel?: SortModelItem[];
    includeCount?: boolean;
  }) => StopsQueryVariables;
  readonly refreshGrid: ({
    shouldDeselect,
  }: {
    shouldDeselect?: boolean;
  }) => void;
  readonly computeNumSortsChanged: (
    sortModel: DispatchSortV2[] | null,
  ) => number;
  readonly computeNumFiltersChanged: (
    filterModel: DispatchTableFilterModel | FilterModel | undefined,
  ) => void;
};

const DispatchStopsTable = forwardRef<
  AgGridReact<StopsTableElement> | null,
  DispatchStopsTableProps
>(
  (
    {
      pageType,
      onDataChanged,
      sequentiallyLoadRouteCardsAfterLoad = false,
      setChangedSortModel,
      stateRef,
      setState,
      onRowDragEnter,
      defaultFilterTabsConfigs,
      selectedTerminalUuid,
      applyTableColumns,
      getFetchStopsVariables,
      refreshGrid,
      computeNumSortsChanged,
      computeNumFiltersChanged,
    },
    ref,
  ) => {
    const gridRef = useRef<AgGridReact<StopsTableElement>>(null);
    const { userUuid } = useMe();
    const includeRecurringRunHeaders = useFeatureFlag(
      FeatureFlag.FF_DISPATCH_STOPS_RECURRING_RUN_HEADERS,
    );
    const ffCustomizableColumns = useFeatureFlag(
      FeatureFlag.FF_CUSTOMIZABLE_ROUTE_COLUMNS,
    );
    const ffUseDispatchCache = useFeatureFlag(
      FeatureFlag.FF_USE_DISPATCH_CACHE,
    );
    const { ffEnableNewTableFunctions } =
      useNewTableFunctionsFeatureFlag(pageType);

    const ffDispatchTablePagination = useFeatureFlag(
      FeatureFlag.FF_DISPATCH_TABLE_PAGINATION,
    );

    const { getNumberOfStopsForCurrentTab } = useStopsPanelTabFunctions({
      stateRef,
      setState,
      defaultFilterTabsConfigs,
      selectedTerminalUuid,
    });

    const newStopsAnchorRef = React.useRef<HTMLDivElement>(null);
    const [
      setOpenedOrderUuid,
      setHoveredStopUuid,
      selectedStopUuids,
      setSelectedStopUuids,
      toggleSelectedStopUuid,
      setAllRouteUuidsLoadingStops,
      setUnrenderAllStops,
    ] = useDispatchStore(
      (state) => [
        state.setOpenedOrderUuid,
        state.setHoveredStopUuid,
        state.selectedStopUuids,
        state.setSelectedStopUuids,
        state.toggleSelectedStopUuid,
        state.setAllRouteUuidsLoadingStops,
        state.setUnrenderAllStops,
      ],
      shallow,
    );
    const [setRememberedFilters, rememberedTabs] = useFilterStore(
      (state) => [state.setFilters, state.tabs],
      shallow,
    );

    const { fetchStops } = useFetchStops();

    const [, setChangedDispatchTableFields] = useLocalStorageState<
      DispatchTableField[] | null
    >(UNASSIGNED_STOPS_TABLE_COLUMNS_KEY, { defaultValue: null });

    /**
     * We only want to update the user's personal list of dispatch table fields
     * if the current view is a default view.
     */
    const shouldUseUserTableFields =
      !ffEnableNewTableFunctions ||
      isNil(stateRef.current.currentFilterViewUuid);

    const [updateUser] = useUpdateUserMutation({
      refetchQueries: [TableFieldsDocument],
    });

    const removeDuplicateNodes = () => {
      const nodes = gridRef.current?.api?.getSelectedNodes();
      if (isNil(nodes)) return;
      // The UUIDs of all selected elements including stops and recurring run headers.
      const seenUuids = new Set<string>();
      for (const { data, rowIndex } of nodes) {
        // prioritize not deselecting nodes that are in view, determined by rowIndex being non null
        if (!isNil(data) && !isNil(rowIndex)) {
          seenUuids.add(getElementUuid(data));
        }
      }
      for (const node of nodes) {
        const { data, rowIndex } = node;
        // Notice this time we are checking if rowIndex is nil, which means the row isn't rendered.
        // We check if data is not nil just for TypeScript. It should always be set.
        if (!isNil(data) && isNil(rowIndex)) {
          const uuid = getElementUuid(data);
          if (seenUuids.has(uuid)) {
            node.setSelected(false);
          } else {
            seenUuids.add(uuid);
          }
        }
      }
    };

    const updateRecurringRunHeaderSelections = () => {
      const renderedNodes = gridRef.current?.api.getRenderedNodes() ?? [];
      // We can use the fact that recurring run headers are always placed at the beginning, and their child stops always
      // follow immediately. This way the looping over rows is minimal.
      /* eslint-disable no-plusplus */
      for (let i = 0; i < renderedNodes.length; ++i) {
        const node = renderedNodes[i];
        if (isRecurringRunHeaderFragmentNode(node)) {
          let childNodesCount = 0;
          let selectedChildNodesCount = 0;
          for (let childI = i + 1; childI < renderedNodes.length; ++childI) {
            const childNode = renderedNodes[childI];
            if (
              isStopFragmentNode(childNode) &&
              childNode.data.recurringRunUuid === node.data.uuid
            ) {
              ++childNodesCount;
              if (childNode.isSelected() === true) {
                ++selectedChildNodesCount;
              }
            } else {
              break;
            }
          }
          const shouldBeSelected =
            childNodesCount > 0 && childNodesCount === selectedChildNodesCount;
          if (shouldBeSelected !== node.isSelected()) {
            node.setSelected(shouldBeSelected);
            // Trigger the full-width row to be re-rendered.
            node.setData({ ...node.data });
          }
        }
      }
      /* eslint-enable no-plusplus */
    };

    const handleRowSelected = ({
      node,
      api,
      source,
    }: RowSelectedEvent<StopsTableElement>) => {
      if (isStopFragment(node.data)) {
        toggleSelectedStopUuid(node.data.stop.uuid, node.isSelected() === true);
      }
      // when selecting or unselecting nodes, need to select/unselect
      // all duplicates created by the reselection that was done above
      removeDuplicateNodes();

      // Update selection of recurring run headers or recurring run stops that are affected.
      // We do this only if a user action caused the selection so that our own programmatic changes
      // to selections don't cause further changes.
      if (source !== 'api') {
        if (isRecurringRunHeaderFragmentNode(node)) {
          // A recurring run header was toggled. Update its child stops.
          for (const iterNode of api.getRenderedNodes()) {
            if (
              isStopFragmentNode(iterNode) &&
              iterNode.data.recurringRunUuid === node.data.uuid
            ) {
              iterNode.setSelected(node.isSelected() ?? false);
            }
          }
          // Trigger the full-width row to be re-rendered.
          node.setData({ ...node.data });
        } else if (
          isStopFragmentNode(node) &&
          !isNil(node.data.recurringRunUuid)
        ) {
          // A stop that belongs to a recurring run was toggled.
          updateRecurringRunHeaderSelections();
        }
      }
    };

    const handleSelectionChanged = (
      e: SelectionChangedEvent<StopsTableElement>,
    ) => {
      // unselects all stops if deselecting the header checkbox.
      // We determine whether the header checkbox click was an unselect all action
      // by checking if the rendered nodes on the page are no longer selected
      // (I could not find an ag grid api field that exposed this state)
      if (
        e.source === 'uiSelectAllCurrentPage' &&
        e.api.getRenderedNodes().every((node) => node.isSelected() !== true)
      ) {
        setSelectedStopUuids([]);
      }
    };

    const handlePaginationChanged = (
      e: PaginationChangedEvent<StopsTableElement>,
    ) => {
      // select new stops that get rendered when loading new pages
      e.api.forEachNode((node) => {
        if (
          isStopFragment(node.data) &&
          (node.isSelected() === true ||
            selectedStopUuids.includes(node.data.stop.uuid))
        ) {
          toggleSelectedStopUuid(node.data.stop.uuid, true);
        }
      });
    };

    const handleSortChanged = () => {
      refreshGrid({});
      const dispatchSortV2 = ffEnableNewTableFunctions
        ? getDispatchSortV2(gridRef.current)
        : null;
      const changesFromOriginalSortModel = computeNumSortsChanged(
        dispatchSortV2 ?? null,
      );
      if (changesFromOriginalSortModel > 0) {
        setChangedSortModel(dispatchSortV2 ?? null);
      } else {
        setChangedSortModel(null);
      }
      setState((prevState) => {
        return {
          ...prevState,
          currentCursor: null,
        };
      });
    };

    const fetchStopsOnRoutes = async ({
      searchText,
    }: {
      searchText: string;
    }) => {
      const res = await apolloClient.query<
        StopsOnRoutesBySearchTextQuery,
        StopsOnRoutesBySearchTextQueryVariables
      >({
        query: StopsOnRoutesBySearchTextDocument,
        variables: {
          searchText,
        },
      });
      return res.data.stopsOnRoutesBySearchText;
    };

    const createServerSideDatasource = () => {
      return {
        getRows(params: IServerSideGetRowsParams<StopFragment>) {
          if (
            stateRef.current.firstRender &&
            sequentiallyLoadRouteCardsAfterLoad
          ) {
            setUnrenderAllStops(true);
          }
          const variables = getFetchStopsVariables({
            filterModel: params.request.filterModel ?? {},
            sortModel: params.request.sortModel,
            includeCount: ffUseDispatchCache,
          });

          onDataChanged?.(variables);

          const { datasourceVersionId } = stateRef.current;
          fetchStops({
            variables,
            first: STOPS_PER_PAGE,
            after: stateRef.current.currentCursor,
            cacheStartIndex: params.request.startRow,
            includeRecurringRunHeaders,
          }).then((data) => {
            if (stateRef.current.datasourceVersionId !== datasourceVersionId) {
              params.fail();
              return;
            }

            const totalCount = data?.stops.totalCount ?? undefined;
            setState((prevState) => {
              if (isNil(data)) {
                return {
                  ...prevState,
                  totalCount: undefined,
                  currentCursor: undefined,
                };
              }

              if (ffUseDispatchCache) {
                return {
                  ...prevState,
                  totalCount: isNil(totalCount)
                    ? totalCount
                    : totalCount - (data.stops.recurringRunsCount ?? 0),
                  currentCursor: data.stops.pageInfo.endCursor,
                };
              }

              return {
                ...prevState,
                currentCursor: data.stops.pageInfo.endCursor,
              };
            });

            const loadSuccessParams: LoadSuccessParams = ffUseDispatchCache
              ? {
                  rowData: data?.stops.edges.map((edge) => edge.node) ?? [],
                  rowCount: totalCount,
                }
              : {
                  rowData: data?.stops.edges.map((edge) => edge.node) ?? [],
                };

            params.success(loadSuccessParams);

            if (isEmpty(data?.stops.edges)) {
              fetchStopsOnRoutes({
                searchText: stateRef.current.searchText?.trim(),
              }).then((stops) => {
                setState((prevState) => {
                  return {
                    ...prevState,
                    stopsOnRoutes: stops,
                  };
                });
              });
            }

            const api = gridRef.current?.api;
            if (!isNil(api)) {
              const uuids = new Set(api.getSelectedRows().map(getElementUuid));
              api.forEachNode((node) => {
                if (!isNil(node.data)) {
                  const uuid = getElementUuid(node.data);
                  if (uuids.has(uuid)) {
                    node.setSelected(true);
                  }
                }
              });
            }

            if (
              stateRef.current.firstRender &&
              sequentiallyLoadRouteCardsAfterLoad
            ) {
              setUnrenderAllStops(false);
              setAllRouteUuidsLoadingStops();
            }
            setState((prevState) => ({ ...prevState, firstRender: false }));

            // Query the total counts separately for the normal stops query
            if (!ffUseDispatchCache) {
              getNumberOfStopsForCurrentTab();
            }
          });
        },
      };
    };

    const handleRememberedTabs = () => {
      const rememberedTab = rememberedTabs[pageType];
      if (!isNil(rememberedTab)) {
        if (!isNil(rememberedTab.default)) {
          const parsedTab = JSON.parse(rememberedTab.default);
          setState((prevState) => ({
            ...prevState,
            stopsTab: parsedTab.value as StopsTab,
            currentFilterViewUuid: null,
            currentFilterViewName: null,
          }));
        } else if (!isNil(rememberedTab.custom)) {
          const parsedTab = JSON.parse(rememberedTab.custom);
          setState((prevState) => ({
            ...prevState,
            stopsTab: parsedTab.uuid,
            currentFilterViewUuid: parsedTab.uuid,
            currentFilterViewName: parsedTab.displayName,
          }));
        }
      }
    };

    const onGridReady = (params: GridReadyEvent) => {
      const datasource = createServerSideDatasource();
      params.api.setServerSideDatasource(datasource);
      params.api.closeToolPanel();

      handleRememberedTabs();
    };

    useEffect(() => {
      /* this ensures selectedStopUuids is kept in sync with the ag grid nodes state
     1. If the node is in selectedStopUuids, ensures it is selected
     2. If a node is selected but not in selectedStopUuids, unselect it
     */
      gridRef.current?.api?.forEachNode((node) => {
        if (
          isStopFragment(node.data) &&
          selectedStopUuids.includes(node.data.stop.uuid)
        ) {
          node.setSelected(true);
        } else if (node.isSelected() === true) {
          node.setSelected(false);
        }
      });
    }, [selectedStopUuids, gridRef]);

    const handleScrollEnd = (event: BodyScrollEndEvent) => {
      setState((prevState) => ({
        ...prevState,
        topScrollPosition: event.top,
      }));
    };

    const handleFilterChanged = (event: FilterChangedEvent) => {
      let filterModel = ffEnableNewTableFunctions
        ? getDispatchTableFilterModel(gridRef.current)
        : gridRef.current?.api.getFilterModel();
      if (!isNil(filterModel) && isLegacyFilterModel(filterModel)) {
        // Even if the feature flag is on, existing views in the DB
        // or local storage might not have been migrated
        filterModel = migrateLegacyFilterModel(filterModel);
      }
      setRememberedFilters(JSON.stringify(filterModel ?? {}), pageType);
      // floating filters purely do client side filtering and don't query
      if (isNil(event.afterFloatingFilter) || !event.afterFloatingFilter) {
        computeNumFiltersChanged(filterModel);
        setState((prevState) => {
          return {
            ...prevState,
            currentCursor: null,
          };
        });
      }
    };

    // callback which AG Grid calls when a column moves.
    const handleColumnUpdate = async (e: ColumnMovedEvent) => {
      const columnState = gridRef.current?.columnApi.getColumnState();
      if (
        ((e.type === 'columnMoved' && e.finished) ||
          e.type === 'columnVisible') &&
        (e.source === 'toolPanelUi' || e.source === 'uiColumnMoved')
      ) {
        const columnStateHeaders =
          columnState
            ?.filter((def) => def.hide !== true)
            .map((def) => def.colId) ?? [];
        const updatedDispatchFields = filterNotNil(
          columnStateHeaders.map((header) => {
            return Object.values(DispatchTableField).find(
              (field) => field === header,
            );
          }),
        );
        if (!ffCustomizableColumns) {
          setUnrenderAllStops(true);
          setAllRouteUuidsLoadingStops();
        }
        if (ffEnableNewTableFunctions) {
          setChangedDispatchTableFields(updatedDispatchFields);
          if (!shouldUseUserTableFields) {
            // If this is a default view, columns will be re-applied when we
            // refetch the updated dispatch table fields, so we don't want to show
            // "N columns changed".
            applyTableColumns(updatedDispatchFields);
          }
        }
        if (shouldUseUserTableFields && !isNil(userUuid)) {
          await updateUser({
            variables: {
              updateUserInput: {
                uuid: userUuid,
                dispatchTableFields: updatedDispatchFields,
              },
            },
            refetchQueries: [TableFieldsDocument],
          });
        }
        setUnrenderAllStops(false);
      }
    };

    const rowDragText = useCallback((params: IRowDragItem): string => {
      const { stops, recurringRunHeaders } =
        getStopsAndRecurringRunHeadersFromNodes(params.rowNodes ?? []);

      const stopsText = `${stops.length} ${pluralize('stop', stops.length)}`;
      if (recurringRunHeaders.length === 0) {
        return stopsText;
      }
      const recurringRunsText = `${recurringRunHeaders.length} ${pluralize(
        'recurring run',
        recurringRunHeaders.length,
      )}`;
      return stops.length > 0
        ? `${recurringRunsText}, ${stopsText}`
        : recurringRunsText;
    }, []);

    /**
     * This function is called when the user clicks on the new stops popover.
     * It resets the number of new stops to 0 and scrolls to the top of the grid.
     */
    const onNewStopsPopoverClick = () => {
      setState((prevState) => ({
        ...prevState,
        numberNewStops: 0,
      }));
      gridRef.current?.api.ensureIndexVisible(0);
    };

    useImperativeHandle<
      AgGridReact<StopsTableElement> | null,
      AgGridReact<StopsTableElement> | null
    >(ref, () => gridRef.current);

    return (
      <div ref={newStopsAnchorRef} style={{ width: '100%', height: '100%' }}>
        <NewStopsPopover
          newStopsCount={stateRef.current.numberNewStops}
          anchorEl={newStopsAnchorRef.current}
          onClickHandler={onNewStopsPopoverClick}
        />
        <PalletAgGridReact<StopsTableElement>
          ref={gridRef}
          enableCellChangeFlash
          suppressCellFocus
          rowMultiSelectWithClick
          rowDragEntireRow
          rowDragMultiRow
          pageType={pageType}
          columnDefs={stateRef.current.columnDefs}
          isFullWidthRow={isFullWidthRow}
          fullWidthCellRenderer={fullWidthRecurringRunRenderer}
          defaultColDef={DEFAULT_COL_DEF}
          rowModelType="serverSide"
          serverSideInitialRowCount={STOPS_PER_PAGE}
          cacheBlockSize={STOPS_PER_PAGE}
          rowBuffer={ffDispatchTablePagination ? STOPS_PER_PAGE : 0}
          headerHeight={38}
          rowHeight={30}
          rowSelection="multiple"
          pagination={ffDispatchTablePagination ? true : undefined}
          paginationPageSize={
            ffDispatchTablePagination ? STOPS_PER_PAGE * 2 : undefined
          }
          sideBar={SIDE_BAR}
          getRowId={getRowId}
          rowDragText={rowDragText}
          onGridReady={onGridReady}
          onRowDragEnter={onRowDragEnter}
          onRowDoubleClicked={(e) => {
            if (isStopFragment(e.data)) {
              setOpenedOrderUuid(e.data.order?.uuid);
            }
          }}
          onBodyScrollEnd={handleScrollEnd}
          onFilterChanged={handleFilterChanged}
          onColumnVisible={handleColumnUpdate}
          onColumnMoved={handleColumnUpdate}
          onCellClicked={(e) => {
            if (
              e.column.getColId() === 'Route' ||
              e.column.getColId() === DispatchTableField.OrderName
            ) {
              e.node.setSelected(false);
            }
          }}
          onCellMouseOver={(e) => {
            if (isStopFragment(e.node.data)) {
              setHoveredStopUuid(e.node.data.stop.uuid);
            }
          }}
          onCellMouseOut={() => {
            setHoveredStopUuid(undefined);
          }}
          onRowSelected={handleRowSelected}
          onSelectionChanged={handleSelectionChanged}
          onPaginationChanged={handlePaginationChanged}
          onSortChanged={handleSortChanged}
        />
      </div>
    );
  },
);

export default React.memo(DispatchStopsTable);
