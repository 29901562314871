/* eslint-disable @typescript-eslint/no-explicit-any */
import { datadogRum } from '@datadog/browser-rum';
import { isNil } from 'lodash';
import pTimeout, { TimeoutError } from 'p-timeout';
import { useCallback } from 'react';
import { GRAPHQL_TIMEOUT_DURATION_MS } from '../../apollo/links/timeout/timeout-link';
import useMe from './use-me';

export type MeasureExecutionTimeParams<T> = {
  rumLabel: string;
  logData?: Record<string, any>;
  fn: () => Promise<T>;
};
/**
 * @description Use this to measure the execution time of an asynchronous function.
 * It will add a custom action to the RUM using the provided rumLabel. And
 * include the duration in milliseconds of function being called.
 */
export const useMeasureExecutionTime = <T>({
  fn,
  rumLabel,
  logData,
}: MeasureExecutionTimeParams<T>): (() => Promise<T | null>) => {
  const { user, companyData } = useMe();

  return useCallback(async () => {
    const startTime = new Date();
    let error: Error | null = null;
    let timedOut = false;

    try {
      return await pTimeout(fn(), {
        milliseconds: GRAPHQL_TIMEOUT_DURATION_MS,
      });
    } catch (error_) {
      if (error_ instanceof TimeoutError) {
        timedOut = true;
      } else {
        error = error_ instanceof Error ? error_ : new Error(String(error_));
      }
    } finally {
      const duration = Date.now() - startTime.getTime();

      datadogRum.addAction(`${rumLabel}`, {
        duration,
        timedOut,
        error: isNil(error) ? undefined : error.message,
        userId: user?.uuid,
        companyName: companyData?.name,
        companyUuid: companyData?.uuid,
        ...logData,
      });
    }
    return null;
  }, [fn, rumLabel, logData, user?.uuid, companyData?.uuid, companyData?.name]);
};
