import SwapVertIcon from '@mui/icons-material/SwapVert';
import { Menu, Stack } from '@mui/material';
import { type AgGridReact } from 'ag-grid-react';
import { sentenceCase } from 'change-case';
import { isEmpty, isNil } from 'lodash';
import { type RefObject } from 'react';
import type React from 'react';
import { useState } from 'react';
import { type OrderTableField } from '../../generated/graphql';
import SortPopover from './orders/components/sorts/sort-popover';
import { getAGGridSortModel } from './orders/utils';
import {
  TableConfigPillText,
  TableConfigPillIcon,
} from './table-configuration-pills';

type SortPillsProps = {
  readonly gridRef: RefObject<AgGridReact>;
  readonly orderTableFields: OrderTableField[];
};

const SortPills = ({ gridRef, orderTableFields }: SortPillsProps) => {
  // TODO: once we support multi sort, we'll need to handle the case where
  // we click on a sort pill, anchor to it, then remove the sort and no longer
  // have a valid anchorEl (see FilterPills for how to handle)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const sortStates = getAGGridSortModel(gridRef.current);
  if (isNil(sortStates) || isEmpty(sortStates)) {
    return null;
  }

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {/* TODO: flexWrap once we support multi sort */}
      <Stack direction="row" alignItems="start">
        <TableConfigPillIcon roundLeft>
          <SwapVertIcon />
        </TableConfigPillIcon>
        {sortStates.map((sortState) => (
          <TableConfigPillText
            key={sortState.colId}
            roundRight
            onClick={handleClick}
          >
            {/* TODO: this doesn't always match the column display name in the table */}
            {sentenceCase(sortState.colId)}
          </TableConfigPillText>
        ))}
      </Stack>
      <Menu
        open={!isNil(anchorEl)}
        anchorEl={anchorEl}
        MenuListProps={{ sx: { py: 0 } }}
        onClose={handleClose}
      >
        <SortPopover
          gridRef={gridRef}
          orderTableFields={orderTableFields}
          onClose={handleClose}
        />
      </Menu>
    </>
  );
};

export { SortPills };
