import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Fade,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { isNil } from 'lodash';
import { useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../../../common/react-hooks/use-user-roles';
import {
  type CreditTypeFragment,
  PermissionResource,
} from '../../../../../generated/graphql';
import SettingsColorPicker from '../../../../daily-control-center/components/settings-color-picker';

type CreditTypeRowProps = {
  readonly creditType: CreditTypeFragment;
  readonly onDelete: () => void;
  readonly onClickEdit: () => void;
};

const CreditTypeRow = ({
  creditType,
  onDelete,
  onClickEdit,
}: CreditTypeRowProps) => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteCompanyBilling } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyBilling,
  );
  const theme = useTheme();
  const [isHovering, setIsHovering] = useState(false);

  return (
    <TableRow
      hover
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
      }}
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <TableCell>
        <Typography>{creditType.name}</Typography>
      </TableCell>
      <TableCell>
        <SettingsColorPicker
          disabled
          currentColor={creditType.color}
          setColor={() => {}}
        />
      </TableCell>
      <TableCell>
        {isNil(creditType.generalLedgerCode) ? (
          <Typography sx={{ fontSize: '14px', color: theme.palette.grey[500] }}>
            none
          </Typography>
        ) : (
          <Stack direction="row" spacing={1}>
            <Typography sx={{ fontSize: '14px' }}>
              {creditType.generalLedgerCode?.code ?? ''}
            </Typography>
            <Typography
              sx={{ fontSize: '14px', color: theme.palette.grey[500] }}
            >
              {creditType.generalLedgerCode?.description ?? ''}
            </Typography>
          </Stack>
        )}
      </TableCell>
      <TableCell align="right">
        <Fade in={isHovering}>
          <Button
            variant="contained"
            disabled={!canWriteCompanyBilling}
            onClick={onClickEdit}
          >
            Edit
          </Button>
        </Fade>
      </TableCell>
      <TableCell align="right">
        <IconButton disabled={!canWriteCompanyBilling} onClick={onDelete}>
          <CloseIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default CreditTypeRow;
