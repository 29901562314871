import { Box, Typography } from '@mui/material';
import { useState } from 'react';

const SelectAllInvoices = ({
  pageSize,
  clearSelection,
  handleSelectAllInvoices,
  count,
}: {
  readonly pageSize: number;
  readonly clearSelection: () => void;
  readonly handleSelectAllInvoices: () => Promise<void>;
  readonly count: number | undefined;
}) => {
  const [allInvoicesSelected, setAllInvoicesSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#E6F6FD',
        pt: '20px',
        pb: '20px',
        width: '100%',
      }}
    >
      <Typography sx={{ color: '#104C67' }}>
        {allInvoicesSelected
          ? `All ${count} invoices are selected.`
          : `All ${Math.min(
              pageSize,
              count ?? 0,
            )} invoices on this page are selected.`}
      </Typography>
      <Typography
        sx={{
          textDecoration: 'underline',
          cursor: 'pointer',
          ml: '5px',
          color: '#0D8ED4',
          pointerEvents: isLoading ? 'none' : 'auto',
        }}
        onClick={async () => {
          setIsLoading(true);
          if (allInvoicesSelected) {
            clearSelection();
          } else {
            await handleSelectAllInvoices();
          }
          setAllInvoicesSelected(!allInvoicesSelected);
          setIsLoading(false);
        }}
      >
        {allInvoicesSelected ? `Clear selection` : `Select all invoices`}
      </Typography>
    </Box>
  );
};

export default SelectAllInvoices;
