import { Box, CircularProgress, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { type Dispatch, type SetStateAction, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { shallow } from 'zustand/shallow';
import useTerminals from '../../../../common/react-hooks/use-terminals';
import useGlobalStore from '../../../../layouts/dashboard/global-store';
import { type OrderFormValues } from '../../../orders/components/order-form/forms/types';
import { convertOutstandingOrderPayloadToFormData } from '../../../orders/components/order-form/forms/utils';
import useBillingReviewStore from '../../billing-review-store';
import useBillingReviewActions from '../../hooks/use-billing-review-actions';
import OutstandingOrderForm from './outstanding-order-form';
import OutstandingOrdersListSidebar from './outstanding-orders-list-sidebar';

type OutstandingOrderPageProps = {
  readonly setErrorMessage: Dispatch<SetStateAction<string | null>>;
};

const OutstandingOrderPage = ({
  setErrorMessage,
}: OutstandingOrderPageProps) => {
  const [
    openedOutstandingOrderUuid,
    orderUuidsToSave,
    setOutstandingOrderUuidInForm,
    openedOrderDataLoading,
    setOpenedOrderDataLoading,
    setInitialFormData,
  ] = useBillingReviewStore(
    (state) => [
      state.openedOutstandingOrderUuid,
      state.orderUuidsToSave,
      state.setOutstandingOrderUuidInForm,
      state.openedOrderDataLoading,
      state.setOpenedOrderDataLoading,
      state.setInitialFormData,
    ],
    shallow,
  );

  const [
    currentlyOpenedOrderUuidInOrderModal,
    orderUuidToRefetch,
    setOrderUuidToRefetch,
  ] = useGlobalStore(
    (state) => [
      state.currentOrderUuid,
      state.orderUuidToRefetch,
      state.setOrderUuidToRefetch,
    ],
    shallow,
  );

  const abortController = useRef<AbortController | null>(null);
  const form = useFormContext<OrderFormValues>();
  const formLoading = form.formState.isLoading;
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });

  const { fetchOrderCacheFirst } = useBillingReviewActions();

  const fetchData = async ({
    forceReset = false,
  }: {
    forceReset?: boolean;
  }) => {
    if (!isNil(openedOutstandingOrderUuid)) {
      setOpenedOrderDataLoading(true);
      abortController.current?.abort();
      const newAbortController = new AbortController();
      abortController.current = newAbortController;

      // if force reset, fetch order from backend directly
      const loadedOrder = await fetchOrderCacheFirst({
        orderUuid: openedOutstandingOrderUuid,
        bypassCache: forceReset,
      });

      const useCentimeters = form.getValues('useCentimeters');
      const useKilograms = form.getValues('useKilograms');
      if (!isNil(loadedOrder)) {
        return convertOutstandingOrderPayloadToFormData({
          standardOrder: loadedOrder,
          terminalsEnabled,
          useCentimeters,
          useKilograms,
        });
      }
    }
    return null;
  };

  const resetOrderForm = async ({ forceReset }: { forceReset?: boolean }) => {
    const formValues = await fetchData({ forceReset });
    if (!isNil(formValues)) {
      setInitialFormData(formValues);
      form.reset(formValues);
      setOutstandingOrderUuidInForm(formValues.uuid);
      setOpenedOrderDataLoading(false);
    }
  };

  useEffect(() => {
    resetOrderForm({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedOutstandingOrderUuid]);

  useEffect(() => {
    if (
      isNil(currentlyOpenedOrderUuidInOrderModal) &&
      !isNil(orderUuidToRefetch)
    ) {
      resetOrderForm({ forceReset: true });
      setOrderUuidToRefetch(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentlyOpenedOrderUuidInOrderModal, orderUuidToRefetch]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        height: '85vh',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '20%',
        }}
      >
        <OutstandingOrdersListSidebar />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '80%',
        }}
      >
        {!isNil(openedOutstandingOrderUuid) &&
        !orderUuidsToSave.includes(openedOutstandingOrderUuid) &&
        !openedOrderDataLoading &&
        !formLoading ? (
          <OutstandingOrderForm
            key={openedOutstandingOrderUuid}
            setErrorMessage={setErrorMessage}
          />
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {isNil(openedOutstandingOrderUuid) ? (
              <Typography>No order selected</Typography>
            ) : (
              <CircularProgress />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default OutstandingOrderPage;
