import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  TextField,
  Stack,
  Typography,
  InputAdornment,
} from '@mui/material';
import currency from 'currency.js';
import { isEmpty, isNil } from 'lodash';
import React, { type Dispatch, type SetStateAction, useState } from 'react';
import useDrivers from '../../../../common/react-hooks/use-drivers';
import useTerminals from '../../../../common/react-hooks/use-terminals';
import {
  DriverSettlementBillByUuidDocument,
  DriverSettlementBillsDocument,
  StopDriverMapsDocument,
  useCreateAdjustmentStopDriverMapMutation,
} from '../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';

type CreateAdjustmentSettlementModalProps = {
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
  readonly settlementUuid: string | undefined;
  readonly onSuccess: () => void;
};

const CreateAdjustmentSettlementModal = ({
  open,
  setOpen,
  settlementUuid,
  onSuccess,
}: CreateAdjustmentSettlementModalProps) => {
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const { drivers, getDriverName } = useDrivers();
  const [name, setName] = useState<string | undefined>(undefined);
  const [driverUuid, setDriverUuid] = useState<string>();
  const [revenueFlatRate, setRevenueFlatRate] = useState<number>();

  const [createAdjustmentStopDriverMap, { loading }] =
    useCreateAdjustmentStopDriverMapMutation();

  const onSave = async () => {
    if (isNil(driverUuid) && isNil(settlementUuid)) {
      return;
    }
    await createAdjustmentStopDriverMap({
      variables: {
        input: {
          driverUuid,
          driverSettlementBillUuid: settlementUuid,
          revenueFlatRate,
          name,
        },
      },
      refetchQueries: [
        DriverSettlementBillsDocument,
        DriverSettlementBillByUuidDocument,
        StopDriverMapsDocument,
      ],
    });
    setName(undefined);
    setDriverUuid(undefined);
    setRevenueFlatRate(undefined);
    onSuccess();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Create Adjustment</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} sx={{ pt: 1 }}>
          {isNil(settlementUuid) && (
            <Grid item xs={6}>
              <AutocompleteFuzzy
                size="small"
                value={
                  isNil(driverUuid)
                    ? null
                    : {
                        value: driverUuid,
                        label: getDriverName(driverUuid),
                        terminal: undefined,
                      }
                }
                options={
                  drivers?.map((driver) => ({
                    value: driver.uuid,
                    label: `${
                      isEmpty(driver.driverReferenceNumber)
                        ? ''
                        : `${driver.driverReferenceNumber} - `
                    }${getDriverName(driver.uuid)}`,
                    terminal:
                      driver.terminal?.name ??
                      (terminalsEnabled ? 'All terminals' : undefined),
                  })) ?? []
                }
                matchSortOptions={{ keys: ['label'] }}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => {
                  return (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <li {...props} key={option.value}>
                      <Stack>
                        <Typography sx={{ fontSize: '14px' }}>
                          {option.label}
                        </Typography>
                        {!isNil(option.terminal) && (
                          <Typography variant="caption" color="text.secondary">
                            {option.terminal}
                          </Typography>
                        )}
                      </Stack>
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" label="Driver" />
                )}
                onChange={(_, option) => {
                  setDriverUuid(option?.value);
                }}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <TextField
              size="small"
              label="Flat Rate"
              autoComplete="off"
              value={revenueFlatRate}
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              onBlur={(event) => {
                const parsed = Number.parseFloat(event.target.value);
                if (!Number.isNaN(parsed)) {
                  const rate = currency(parsed, { precision: 2 }).value;
                  setRevenueFlatRate(rate);
                }
              }}
              onChange={(event) => {
                setRevenueFlatRate(Number.parseFloat(event.target.value));
              }}
              onWheel={(e) => {
                (e.target as HTMLTextAreaElement).blur();
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              label="Name (Optional)"
              autoComplete="off"
              value={name}
              sx={{ width: '100%' }}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" gap={1}>
          <Button
            variant="outlined"
            disabled={loading}
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" disabled={loading} onClick={onSave}>
            Save
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default CreateAdjustmentSettlementModal;
