import {
  Box,
  Button,
  Card,
  Table,
  TableContainer,
  Typography,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import { useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  PermissionResource,
  useThirdPartyUsersByContactQuery,
} from '../../../generated/graphql';
import AddUserModal from './third-party-user/add-third-party-user-modal';
import ThirdPartyUserRow from './third-party-user-row';

const ThirdPartyUsersPanel = ({
  contactUuid,
}: {
  readonly contactUuid: string;
}) => {
  const { userPermissions } = useUserRoles();

  const { canWrite: canWriteContacts } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Contacts,
  );

  const { data } = useThirdPartyUsersByContactQuery({
    variables: { contactUuid },
  });
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      {isAddUserModalOpen && (
        <AddUserModal
          isAddUserModalOpen={isAddUserModalOpen}
          setIsAddUserModalOpen={setIsAddUserModalOpen}
          contactUuid={contactUuid}
        />
      )}
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          px: 2,
          py: 2,
          textAlign: 'center',
          width: '80%',
        }}
      >
        <Typography variant="h5">Third Party Users</Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>
                  <Button
                    sx={{ float: 'right' }}
                    variant="contained"
                    disabled={!canWriteContacts}
                    onClick={() => {
                      setIsAddUserModalOpen(true);
                    }}
                  >
                    Add user
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.thirdPartyUsersByContact.map((thirdPartyUser) => (
                <ThirdPartyUserRow
                  userUuid={thirdPartyUser.uuid}
                  email={thirdPartyUser.email}
                  contactUuid={contactUuid}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {data?.thirdPartyUsersByContact.length === 0 && (
          <Typography>No third party users</Typography>
        )}
      </Card>
    </Box>
  );
};

export default ThirdPartyUsersPanel;
