import { InputAdornment, TextField, Typography } from '@mui/material';
import { isNil } from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { DispatchMultiplayerAction } from '../../../../common/multiplayer/types/dispatch';
import useMultiplayer from '../../../../common/multiplayer/use-multiplayer';
import {
  type StopOnRouteFragment,
  useUpdateStopMutation,
} from '../../../../generated/graphql';
import useFetchRoutes from '../../hooks/use-fetch-routes';
import useRouteActions from '../../hooks/use-route-actions';

const RouteServiceTimeField = ({
  stop,
}: {
  readonly stop: StopOnRouteFragment;
}) => {
  const { sendDispatchUserLocationEvent } = useMultiplayer();
  const { fetchRoute } = useFetchRoutes();
  const { calculateRouteEta } = useRouteActions();
  const [updateStop] = useUpdateStopMutation();
  const [time, setTime] = useState<string>();

  useEffect(() => {
    setTime(stop.overrideServiceTimeInMinutes?.toString());
  }, [stop.overrideServiceTimeInMinutes]);

  const routeUuid = stop.routeSlot?.route?.uuid;

  return (
    <TextField
      size="small"
      variant="standard"
      type="number"
      value={time}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Typography variant="caption">min service time</Typography>
          </InputAdornment>
        ),
      }}
      sx={{ '.MuiInputBase-input': { fontSize: '0.8rem' } }}
      onChange={(e) => {
        setTime(e.target.value);
      }}
      onFocus={() => {
        sendDispatchUserLocationEvent({
          action: DispatchMultiplayerAction.EDITING,
          stopUuid: stop.uuid,
        });
      }}
      onBlur={async () => {
        await updateStop({
          variables: {
            updateStopInput: {
              stopUpdateInput: {
                uuid: stop.uuid,
                overrideServiceTimeInMinutes: isNil(time)
                  ? null
                  : Number.parseFloat(time),
              },
            },
          },
        });
        if (!isNil(routeUuid)) {
          await calculateRouteEta(routeUuid);
          fetchRoute(routeUuid);
        }
      }}
    />
  );
};

export default memo(RouteServiceTimeField);
