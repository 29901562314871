import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Alert,
  Box,
  Fade,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { useTheme } from '@mui/material/styles';
import { isEmpty, isNil } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getPermissionsFlags } from 'shared/roles';
import { shallow } from 'zustand/shallow';
import TabPanel from '../../common/components/tab-panel/tab-panel';
import useUserRoles from '../../common/react-hooks/use-user-roles';
import {
  type InvoiceSendJobBatchFragment,
  InvoiceSendJobStatus,
  PermissionResource,
} from '../../generated/graphql';
import useGlobalStore from '../../layouts/dashboard/global-store';
import { OrderDialog } from '../orders/components/order-dialog';
import AccountingReports from './components/accounting-reports/accounting-reports';
import BillingReview from './components/billing-review/billing-review';
import Customers from './components/customers/customers';
import EmailLogMenu from './components/invoices/download/email-log-menu';
import FileDownloadsMenu from './components/invoices/download/file-downloads-menu';
import InvoiceSendJobsModal from './components/invoices/invoice-send-jobs/invoice-send-jobs-modal';
import InvoiceSendMenu from './components/invoices/invoice-send-jobs/invoice-send-menu';
import InvoiceSendMenuIconWithCount from './components/invoices/invoice-send-jobs/invoice-send-menu-icon-with-count';
import RecentFailedSendJobBatchesAlert from './components/invoices/invoice-send-jobs/recent-failed-send-job-batches-alert';
import Invoices from './components/invoices/invoices';
import SettingsModal from './components/settings/settings-modal';
import useBillingReviewActions from './hooks/use-billing-review-actions';
import useInvoicesStore, { FileDownloadStatus } from './invoices-store';

const InvoicesPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const invoiceUuid = searchParams.get('invoiceUuid');
  const tab = searchParams.get('tab');
  const invoiceSendMenuButtonRef = useRef(null);
  const emailsMenuButtonRef = useRef(null);
  const filesMenuButtonRef = useRef(null);
  const [openedOrderUuid, setOpenedOrderUuid, setOrderUuidToRefetch] =
    useGlobalStore(
      (state) => [
        state.currentOrderUuid,
        state.setCurrentOrderUuid,
        state.setOrderUuidToRefetch,
      ],
      shallow,
    );
  const [
    downloadProgresses,
    deleteDownloadProgress,
    orderToRebillUuid,
    setOrderToRebillUuid,
  ] = useInvoicesStore(
    (state) => [
      state.downloadProgresses,
      state.deleteDownloadProgress,
      state.orderToRebillUuid,
      state.setOrderToRebillUuid,
    ],
    shallow,
  );
  const [fileDownloadSnackbarState, handleFileDownloadSnackbarClose] =
    useInvoicesStore((state) => state.fileDownloadsSnackbarState, shallow);
  const [showEmailsMenu, setShowEmailsMenu] = useState<boolean>(false);
  const [showInvoiceSendMenu, setShowInvoiceSendMenu] = useInvoicesStore(
    (state) => [state.showInvoiceSendMenu, state.setShowInvoiceSendMenu],
    shallow,
  );
  const [showFileDownloadsMenu, setShowFileDownloadsMenu] =
    useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
  const [openedInvoiceSendJobBatch, setOpenedInvoiceSendJobBatch] = useState<
    InvoiceSendJobBatchFragment | undefined
  >();

  const [
    showSendAccountingReportsSuccessMessage,
    setShowSendAccountingReportsSuccessMessage,
  ] = useState(false);
  const [
    showSendAccountingReportsErrorMessage,
    setShowSendAccountingReportsErrorMessage,
  ] = useState(false);

  useEffect(() => {
    if (typeof invoiceUuid === 'string' && !isNil(invoiceUuid)) {
      setTabIndex(1);
    }
  }, [invoiceUuid]);

  useEffect(() => {
    if (typeof tab === 'string' && !isNil(tab)) {
      setTabIndex(Number.parseInt(tab, 10));
    }
  }, [tab]);

  const { userPermissions } = useUserRoles();
  const { canRead: canReadBillingReview } = getPermissionsFlags(
    userPermissions,
    PermissionResource.BillingReview,
  );
  const { canRead: canReadInvoices, canWrite: canWriteInvoices } =
    getPermissionsFlags(userPermissions, PermissionResource.Invoices);
  const { canRead: canReadCustomers } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Customers,
  );
  const { canRead: canReadAccountingReports } = getPermissionsFlags(
    userPermissions,
    PermissionResource.AccountingReports,
  );
  const { refetchOrdersAndOverwriteCache } = useBillingReviewActions();

  let tabs: Array<{
    label: string;
    component: React.ReactNode;
    visibilityCondition?: boolean;
  }> = [
    {
      label: 'Billing Review',
      component: <BillingReview />,
      visibilityCondition: canReadBillingReview,
    },
    {
      label: 'Invoices',
      component: (
        <Invoices
          setShowSendAccountingReportsSuccessMessage={
            setShowSendAccountingReportsSuccessMessage
          }
          setShowSendAccountingReportsErrorMessage={
            setShowSendAccountingReportsErrorMessage
          }
        />
      ),
      visibilityCondition: canReadInvoices,
    },
    {
      label: 'Customers',
      component: <Customers />,
      visibilityCondition: canReadCustomers,
    },
    {
      label: 'Reports',
      component: (
        <AccountingReports
          setShowSendAccountingReportsSuccessMessage={
            setShowSendAccountingReportsSuccessMessage
          }
          setShowSendAccountingReportsErrorMessage={
            setShowSendAccountingReportsErrorMessage
          }
        />
      ),
      visibilityCondition: canReadAccountingReports,
    },
  ];
  tabs = tabs.filter(
    (t) => isNil(t.visibilityCondition) || t.visibilityCondition,
  );

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showSendAccountingReportsErrorMessage}
      >
        <Alert
          severity="error"
          onClose={() => {
            setShowSendAccountingReportsErrorMessage(false);
          }}
        >
          Error emailing reports. Please contact support
        </Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showSendAccountingReportsSuccessMessage}
        onClose={() => {
          setShowSendAccountingReportsSuccessMessage(false);
        }}
      >
        <Alert severity="success">Successfully began emailing reports</Alert>
      </Snackbar>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          backgroundColor: 'white',
        }}
      >
        <Grid
          container
          sx={{
            height: '100%',
            overflowY: 'scroll',
          }}
          alignItems="stretch"
        >
          <OrderDialog
            open={!isNil(openedOrderUuid)}
            rebillMode={!isNil(orderToRebillUuid)}
            orderUuid={openedOrderUuid ?? null}
            onClose={async () => {
              if (!isNil(openedOrderUuid)) {
                setOrderUuidToRefetch(openedOrderUuid);
                await refetchOrdersAndOverwriteCache([openedOrderUuid]);
              }
              setOpenedOrderUuid(undefined);
              setOrderToRebillUuid(undefined);
            }}
          />
          <Grid
            item
            xs={12}
            sx={{
              padding: 0,
              display: isNil(openedOrderUuid) ? 'flex' : 'none',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <Stack direction="column" spacing={1} height="100%">
              <Grid
                container
                spacing={0}
                sx={{ padding: '15px', paddingBottom: '0px' }}
              >
                <Grid item xs={8}>
                  <Tabs
                    orientation="horizontal"
                    variant="scrollable"
                    value={tabIndex}
                    aria-label="invoice page tabs"
                    sx={{
                      marginTop: '-10px',
                    }}
                    onChange={(e, newIndex) => {
                      setTabIndex(newIndex);
                      setSearchParams((sp) => {
                        const newParams = new URLSearchParams(sp);
                        newParams.set('tab', newIndex);
                        return newParams;
                      });
                    }}
                  >
                    {tabs.map(({ label }) => (
                      <Tab key={label} label={label} />
                    ))}
                  </Tabs>
                </Grid>
                <Grid item xs={4}>
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Tooltip title="Download & email send progress">
                      <IconButton
                        ref={invoiceSendMenuButtonRef}
                        sx={{ float: 'right' }}
                        onClick={() => {
                          setShowInvoiceSendMenu(true);
                        }}
                      >
                        <InvoiceSendMenuIconWithCount />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Settings">
                      <IconButton
                        sx={{ float: 'right' }}
                        disabled={!canWriteInvoices}
                        onClick={() => {
                          setShowSettingsModal(true);
                        }}
                      >
                        <SettingsIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Grid>
              </Grid>
              <RecentFailedSendJobBatchesAlert
                setOpenedInvoiceSendJobBatch={setOpenedInvoiceSendJobBatch}
              />
              {tabs.map((t, idx) => (
                <TabPanel
                  key={t.label}
                  selectedValue={tabIndex}
                  panelValue={idx}
                >
                  {t.component}
                </TabPanel>
              ))}
            </Stack>
          </Grid>
        </Grid>
        <SettingsModal
          isOpen={showSettingsModal}
          setIsOpen={setShowSettingsModal}
        />
        <InvoiceSendMenu
          open={showInvoiceSendMenu}
          setOpen={setShowInvoiceSendMenu}
          anchorEl={invoiceSendMenuButtonRef}
        />
        <EmailLogMenu
          open={showEmailsMenu}
          setOpen={setShowEmailsMenu}
          anchorEl={emailsMenuButtonRef}
        />
        <FileDownloadsMenu
          open={showFileDownloadsMenu}
          setOpen={setShowFileDownloadsMenu}
          anchorEl={filesMenuButtonRef}
        />
      </Box>
      <InvoiceSendJobsModal
        selectedInvoiceSendJobBatch={openedInvoiceSendJobBatch}
        setSelectedInvoiceSendJobBatch={setOpenedInvoiceSendJobBatch}
        initialStatus={InvoiceSendJobStatus.Failed}
      />
      <Fade in={!isEmpty(downloadProgresses)}>
        <Box
          sx={{
            position: 'absolute',
            right: 10,
            top: 10,
            backgroundColor: 'white',
            width: '320px',
            border: 1,
            borderColor: theme.palette.borderColor.main,
            p: 1,
            zIndex: 10_000,
            borderRadius: '4px',
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="caption">Downloading documents...</Typography>
            <IconButton
              size="small"
              onClick={() => {
                downloadProgresses.map((downloadProgress) => {
                  deleteDownloadProgress(downloadProgress.id);
                });
              }}
            >
              <CloseIcon sx={{ fontSize: '15px' }} />
            </IconButton>
          </Stack>
          <Stack spacing={2}>
            {downloadProgresses.map((downloadProgress) =>
              downloadProgress.failed === true ? (
                <Typography
                  key={downloadProgress.id}
                  variant="caption"
                  color="error"
                >
                  Download failed
                </Typography>
              ) : (
                <LinearProgress
                  key={downloadProgress.id}
                  sx={{ width: '300px' }}
                  color="info"
                  variant="determinate"
                  value={downloadProgress.progress}
                />
              ),
            )}
          </Stack>
        </Box>
      </Fade>
      <Snackbar
        open={fileDownloadSnackbarState.status !== FileDownloadStatus.Hidden}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={
          fileDownloadSnackbarState.status === FileDownloadStatus.Complete
            ? (fileDownloadSnackbarState.callbackArgs?.autoHideDuration ?? 5000)
            : undefined
        } // only enable auto hide when complete
        onClose={(_event, reason) => {
          if (reason === 'clickaway') return;
          handleFileDownloadSnackbarClose();
        }}
      >
        <Alert
          severity={
            fileDownloadSnackbarState.status === FileDownloadStatus.Complete
              ? (fileDownloadSnackbarState.callbackArgs?.alertSeverity ??
                'success')
              : 'info'
          }
          sx={{ width: '100%' }}
          onClose={() => {
            handleFileDownloadSnackbarClose();
          }}
        >
          {fileDownloadSnackbarState.status ===
            FileDownloadStatus.Downloading && 'Download in progress...'}
          {(fileDownloadSnackbarState.status === FileDownloadStatus.Complete &&
            fileDownloadSnackbarState.callbackArgs?.message) ??
            'Download complete'}
        </Alert>
      </Snackbar>
    </>
  );
};

export default React.memo(InvoicesPage);
