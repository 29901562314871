import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { objectKeys } from 'tsafe';
import GeneralLedgerCodeAutocomplete from '../../../../common/components/general-ledger-code-autocomplete';
import useTerminals from '../../../../common/react-hooks/use-terminals';
import {
  AccessorialType,
  useAccessorialLazyQuery,
  useCreateAccessorialMutation,
  useFuelProfilesQuery,
  useUpdateAccessorialMutation,
} from '../../../../generated/graphql';
import { muiStyles } from '../accessorial.styles';
import AccessorialPrices from './accessorial-prices/accessorial-prices';
import {
  ALL_TERMINALS,
  FormMode,
  getBackUrl,
  NO_FUEL_PROFILE,
  type StandardAccessorialFormErrors,
  type StandardAccessorialFormFieldName,
  type StandardAccessorialFormValues,
  StandardAccessorialTextField,
  validateAllFields,
  validateField,
} from './common';

const makeCreateInput = (
  formValues: StandardAccessorialFormValues,
  contactUuid: string | undefined,
  templateUuid: string | undefined,
  isAuthoCodeRequired: boolean,
) => {
  // This should never happen since these must be validated beforehand.
  if (formValues.rate === null || formValues.name === null) {
    throw new Error('Name or rate is null after validation');
  }
  return {
    variables: {
      input: {
        accessorialCreateInput: {
          standardAccessorialCreateInput: {
            name: formValues.name,
            rate: formValues.rate,
          },
          matchingGlobalAccessorial: templateUuid,
          contactUuid,
          percentForSettlement: formValues.percentForSettlement,
          terminalUuid:
            formValues.terminalUuid === ALL_TERMINALS
              ? null
              : formValues.terminalUuid,
          code: formValues.code,
          ediCode: formValues.ediCode,
          fuelProfileUuid:
            formValues.fuelProfileUuid === NO_FUEL_PROFILE
              ? null
              : formValues.fuelProfileUuid,
          isAuthoCodeRequired,
          invoiceDisplayName: formValues.invoiceDisplayName,
          generalLedgerCodeId: formValues.generalLedgerCodeId,
        },
      },
    },
  };
};

const makeUpdateInput = (
  formValues: StandardAccessorialFormValues,
  uuid: string,
  isAuthoCodeRequired: boolean,
) => {
  // This should never happen since these must be validated beforehand.
  if (formValues.rate === null || formValues.name === null) {
    throw new Error('Name or rate is null after validation');
  }
  return {
    variables: {
      input: {
        accessorialUpdateInput: {
          standardAccessorialUpdateInput: {
            name: formValues.name,
            rate: formValues.rate,
            uuid,
          },
          percentForSettlement: formValues.percentForSettlement,
          terminalUuid:
            formValues.terminalUuid === ALL_TERMINALS
              ? null
              : formValues.terminalUuid,
          code: formValues.code,
          ediCode: formValues.ediCode,
          fuelProfileUuid:
            formValues.fuelProfileUuid === NO_FUEL_PROFILE
              ? null
              : formValues.fuelProfileUuid,
          isAuthoCodeRequired,
          invoiceDisplayName: formValues.invoiceDisplayName,
          generalLedgerCodeId: formValues.generalLedgerCodeId,
        },
      },
    },
  };
};

const useFormValues = (
  uuid: string | undefined,
): [
  StandardAccessorialFormValues,
  React.Dispatch<React.SetStateAction<StandardAccessorialFormValues>>,
] => {
  const [formValues, setFormValues] = useState<StandardAccessorialFormValues>({
    name: null,
    rate: null,
    percentForSettlement: 100,
    terminalUuid: ALL_TERMINALS,
    fuelProfileUuid: NO_FUEL_PROFILE,
    code: null,
    ediCode: null,
    invoiceDisplayName: null,
    generalLedgerCodeId: null,
  });
  const [accessorialQuery] = useAccessorialLazyQuery();

  useEffect(() => {
    if (uuid !== undefined) {
      accessorialQuery({ variables: { uuid: uuid ?? '' } }).then((response) => {
        const accessorial = response.data?.accessorial;
        // TODO: handle these errors don't fail silently
        if (!isNil(accessorial)) {
          const isStandardAccessorial =
            accessorial.__typename === 'StandardAccessorialEntity';
          const { name, code, ediCode, percentForSettlement } = accessorial;
          const rate = isStandardAccessorial ? accessorial?.rate : null;
          const terminalUuid = isStandardAccessorial
            ? accessorial?.terminal?.uuid
            : null;
          const fuelProfileUuid = isStandardAccessorial
            ? accessorial?.fuelProfile?.uuid
            : null;
          setFormValues({
            name,
            rate,
            percentForSettlement: percentForSettlement ?? null,
            terminalUuid: isEmpty(terminalUuid) ? ALL_TERMINALS : terminalUuid,
            code: code ?? null,
            ediCode: ediCode ?? null,
            fuelProfileUuid: isEmpty(fuelProfileUuid)
              ? NO_FUEL_PROFILE
              : fuelProfileUuid,
            invoiceDisplayName: accessorial.invoiceDisplayName ?? null,
            generalLedgerCodeId: accessorial.generalLedgerCode?.id ?? null,
          });
        }
      });
    }
  }, [accessorialQuery, uuid]);

  return [formValues, setFormValues];
};

type StandardAccessorialFormProps = {
  readonly mode: FormMode;
  readonly uuid: string | undefined;
  readonly contactUuid: string | undefined;
  readonly templateUuid?: string | undefined;
  readonly isAuthoCodeRequired: boolean;
};

// Existing accessorial contained in edit / view mode
const StandardAccessorialForm = ({
  mode,
  uuid,
  contactUuid,
  templateUuid,
  isAuthoCodeRequired,
}: StandardAccessorialFormProps) => {
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState<StandardAccessorialFormErrors>(
    {},
  );
  const [formValues, setFormValues] = useFormValues(
    templateUuid === undefined ? uuid : templateUuid,
  );

  const { terminalsEnabled, terminals, terminalsLoading } = useTerminals({
    includeInactiveTerminals: false,
  });
  const { data: fuelProfilesData, loading: fuelProfilesLoading } =
    useFuelProfilesQuery();

  const [createAccessorial] = useCreateAccessorialMutation();
  const [updateAccessorial] = useUpdateAccessorialMutation();

  const onChange = (
    field: StandardAccessorialFormFieldName,
    value: string | number | boolean,
  ) => {
    setFormValues({ ...formValues, [field]: value });
  };

  const onBlur = (field: StandardAccessorialFormFieldName) => {
    const validationResult = validateField(field, formValues[field]);
    if (validationResult.valid) {
      setFormErrors({ ...formErrors, [field]: undefined });
    } else {
      setFormErrors({ ...formErrors, [field]: validationResult.explanation });
    }
  };

  const onSave = async () => {
    const invalidFields = validateAllFields(formValues);
    const invalidFieldNames = objectKeys(invalidFields);
    if (invalidFieldNames.length === 0) {
      if (mode === FormMode.CREATE) {
        const input = makeCreateInput(
          formValues,
          contactUuid,
          templateUuid,
          isAuthoCodeRequired,
        );
        // TODO: handle the errors from this don't just assume it passes
        await createAccessorial(input);
        navigate(getBackUrl(contactUuid));
      } else if (mode === FormMode.EDIT) {
        // This should never happen
        if (uuid === null) {
          throw new Error('uuid is not defined but in edit mode');
        }
        const input = makeUpdateInput(formValues, uuid!, isAuthoCodeRequired);
        // TODO: handle the errors from this don't just assume it passes
        await updateAccessorial(input);
        navigate(getBackUrl(contactUuid));
      }
    } else {
      for (const field of invalidFieldNames) {
        const validationResultExplanation =
          invalidFields[field]?.explanation ?? '';
        setFormErrors({
          ...formErrors,
          [field]: validationResultExplanation,
        });
      }
    }
  };

  return (
    <Box sx={muiStyles.pageContainer}>
      <Box sx={muiStyles.buttonRow}>
        {(mode === FormMode.CREATE || mode === FormMode.EDIT) && (
          <Button variant="contained" onClick={onSave}>
            Save
          </Button>
        )}
      </Box>
      <Box sx={muiStyles.pageContent}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <StandardAccessorialTextField
              fullWidth
              mode={mode}
              disabled={!isNil(contactUuid)}
              value={formValues.name}
              error={formErrors?.name}
              type="text"
              name="name"
              label="Name"
              onBlur={onBlur}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={3}>
            <StandardAccessorialTextField
              isPercent
              fullWidth
              mode={mode}
              value={formValues.percentForSettlement}
              error={formErrors?.percentForSettlement}
              name="percentForSettlement"
              label="Settlement Rate"
              type="number"
              onBlur={onBlur}
              onChange={onChange}
            />
          </Grid>
          {mode === FormMode.CREATE && (
            <Grid item xs={3}>
              <StandardAccessorialTextField
                fullWidth
                mode={mode}
                value={formValues.rate}
                error={formErrors?.rate}
                name="rate"
                label="Rate"
                type="number"
                onBlur={onBlur}
                onChange={onChange}
              />
            </Grid>
          )}
          {terminalsEnabled && (
            <Grid item xs={3}>
              <FormControl
                sx={{ width: '100%' }}
                error={!isNil(formErrors.terminalUuid)}
              >
                <InputLabel
                  shrink={
                    !isNil(formValues.terminalUuid) &&
                    !isEmpty(formValues.terminalUuid)
                  }
                  id="select-terminal-label"
                >
                  Terminal
                </InputLabel>
                <Select
                  labelId="select-terminal-label"
                  id="select-terminal"
                  label="Terminal"
                  value={formValues.terminalUuid}
                  disabled={terminalsLoading}
                  size="small"
                  onChange={(event) => {
                    if (typeof event.target.value === 'string') {
                      setFormValues({
                        ...formValues,
                        terminalUuid: event.target.value,
                      });
                    }
                  }}
                >
                  <MenuItem key={ALL_TERMINALS} value={ALL_TERMINALS}>
                    {ALL_TERMINALS}
                  </MenuItem>
                  {terminals?.map((terminal) => (
                    <MenuItem key={terminal.uuid} value={terminal.uuid}>
                      {`${terminal.name} (${terminal.code})`}
                    </MenuItem>
                  ))}
                </Select>
                {!isNil(formErrors.terminalUuid) && (
                  <FormHelperText error>
                    {formErrors.terminalUuid}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
          {mode === FormMode.CREATE && (
            <Grid item xs={3}>
              <FormControl
                sx={{ width: '100%' }}
                error={!isNil(formErrors.fuelProfileUuid)}
              >
                <InputLabel
                  shrink={
                    !isNil(formValues.fuelProfileUuid) &&
                    !isEmpty(formValues.fuelProfileUuid)
                  }
                  id="select-fuel-profile-label"
                >
                  Fuel Profile
                </InputLabel>
                <Select
                  fullWidth
                  labelId="select-fuel-profile-label"
                  id="select-fuel-profile"
                  label="Fuel Profile"
                  value={formValues.fuelProfileUuid}
                  disabled={fuelProfilesLoading}
                  size="small"
                  onChange={(event) => {
                    if (typeof event.target.value === 'string') {
                      setFormValues({
                        ...formValues,
                        fuelProfileUuid: event.target.value,
                      });
                    }
                  }}
                >
                  <MenuItem key={NO_FUEL_PROFILE} value={NO_FUEL_PROFILE}>
                    {NO_FUEL_PROFILE}
                  </MenuItem>
                  {fuelProfilesData?.fuelProfiles?.map((fuelProfile) => (
                    <MenuItem key={fuelProfile.uuid} value={fuelProfile.uuid}>
                      {fuelProfile.name}
                    </MenuItem>
                  ))}
                </Select>
                {!isNil(formErrors.fuelProfileUuid) && (
                  <FormHelperText error>
                    {formErrors.fuelProfileUuid}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
          <Grid item xs={3}>
            <StandardAccessorialTextField
              fullWidth
              mode={mode}
              disabled={!isNil(contactUuid)}
              value={formValues.code}
              error={formErrors?.code}
              type="text"
              name="code"
              label="Accessorial Code"
              onBlur={onBlur}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={3}>
            <StandardAccessorialTextField
              fullWidth
              mode={mode}
              disabled={!isNil(contactUuid)}
              value={formValues.ediCode}
              error={formErrors?.ediCode}
              type="text"
              name="ediCode"
              label="EDI Code"
              onBlur={onBlur}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={3}>
            <StandardAccessorialTextField
              fullWidth
              mode={mode}
              disabled={!isNil(contactUuid)}
              value={formValues.invoiceDisplayName}
              error={formErrors?.invoiceDisplayName}
              type="text"
              name="invoiceDisplayName"
              label="Display on invoices as"
              onBlur={onBlur}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={3}>
            <GeneralLedgerCodeAutocomplete
              value={formValues.generalLedgerCodeId}
              formError={formErrors.generalLedgerCodeId ?? null}
              setValue={(newValue: string | null) => {
                setFormValues({
                  ...formValues,
                  generalLedgerCodeId: newValue,
                });
              }}
              disabled={!isNil(contactUuid)}
              size="small"
            />
          </Grid>
          {!isNil(uuid) && (
            <Grid item xs={12}>
              <AccessorialPrices
                accessorialUuid={uuid}
                accessorialType={AccessorialType.Standard}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default React.memo(StandardAccessorialForm);
