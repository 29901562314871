import { useEffect, useMemo } from 'react';
import { ONE_MINUTE_IN_MS } from 'shared/constants';
import {
  NotificationType,
  useNotificationsQuery,
} from '../../generated/graphql';
import { FeatureFlag } from '../feature-flags';
import useFeatureFlag from './use-feature-flag';
import useMe from './use-me';

const useNotifications = () => {
  const { companyConfiguration } = useMe();
  const {
    data: notificationsData,
    startPolling: startPollingNotifications,
    stopPolling: stopPollingNotifications,
  } = useNotificationsQuery();
  const notificationsEnabled =
    companyConfiguration?.notificationsEnabled === true;

  const ffNotificationsEnabled = useFeatureFlag(
    FeatureFlag.FF_ENABLE_NOTIFICATIONS,
  );

  const ordersAwaitingApprovalNotifications = useMemo(() => {
    return notificationsData?.notifications.filter(
      (notification) =>
        notification.type === NotificationType.OrderAwaitingApproval,
    );
  }, [notificationsData]);

  useEffect(() => {
    if (notificationsEnabled && ffNotificationsEnabled) {
      startPollingNotifications(ONE_MINUTE_IN_MS);
    }
    return () => {
      stopPollingNotifications();
    };
  }, [
    startPollingNotifications,
    stopPollingNotifications,
    notificationsEnabled,
    ffNotificationsEnabled,
  ]);

  return {
    ordersAwaitingApprovalNotifications,
    notifications: notificationsData?.notifications,
    notificationsEnabled,
  };
};

export default useNotifications;
