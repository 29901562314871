import {
  Box,
  Card,
  CircularProgress,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { type CSSProperties, type ReactNode } from 'react';
import type React from 'react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getPermissionsFlags } from 'shared/roles';
import useMe from '../../../common/react-hooks/use-me';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import { PermissionResource } from '../../../generated/graphql';
import ItemsTable from '../../items/items-table';
import WarehouseConfigurations from '../../management/components/warehouse-configurations';
import WarehousesTable from '../../management/components/warehouses-table';
import WarehouseLocationsTable from './warehouse-locations/warehouse-locations-table';

const warehouseSettingsViewStyles = {
  warehouseSettingsType: {
    width: '100%',
    height: '100%',
    paddingLeft: 4,
    paddingRight: 4,
  } as CSSProperties,
  tabText: {
    fontSize: '24px',
    textTransform: 'none',
    fontWeight: 'normal',
    marginBottom: '20px',
    marginTop: '20px',
  } as CSSProperties,
};

type TabPanelProps = {
  readonly children?: React.ReactNode;
  readonly index: number;
  readonly value: number;
};

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const parseTabQueryParam = (tab: string | null): number => {
  if (isNil(tab)) {
    return 0;
  }
  return Number.parseInt(tab, 10);
};

const WarehouseSettingsView = () => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const { companyUuid, loading, companyConfiguration } = useMe();
  const { userPermissions } = useUserRoles();
  const [value, setValue] = useState(parseTabQueryParam(tab));
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { canRead: canReadWarehouses } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Warehouses,
  );

  const wmsStorageOrdersEnabled =
    companyConfiguration?.wmsStorageOrdersEnabled ?? false;

  // This nil check necessary. The optional chaining below throws an NPE in a
  // production build for some reason.
  if (loading || isNil(companyUuid) || isEmpty(companyUuid)) {
    return (
      <Box sx={warehouseSettingsViewStyles.warehouseSettingsType}>
        <CircularProgress />
      </Box>
    );
  }

  type TabContent = {
    label: string;
    component: ReactNode;
    tabVisibleCondition?: boolean;
  };

  const TAB_COMPONENTS: TabContent[] = [
    {
      label: 'Items',
      component: <ItemsTable />,
      tabVisibleCondition: wmsStorageOrdersEnabled,
    },
    {
      label: 'Warehouse locations',
      component: <WarehouseLocationsTable />,
    },
    {
      label: 'Warehouses',
      component: <WarehousesTable />,
      tabVisibleCondition: canReadWarehouses,
    },
    {
      label: 'Configurations',
      component: <WarehouseConfigurations />,
      tabVisibleCondition: canReadWarehouses,
    },
  ];

  const filteredTabComponents = TAB_COMPONENTS.filter(
    (tabComponent) => tabComponent.tabVisibleCondition !== false,
  );

  return (
    <Box sx={warehouseSettingsViewStyles.warehouseSettingsType}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Card
            sx={{ my: 5, width: '100%', height: '80vh', overflow: 'scroll' }}
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              scrollButtons="auto"
              aria-label="Vertical tabs example"
              sx={{ borderRight: 1, borderColor: 'divider' }}
              onChange={handleChange}
            >
              {filteredTabComponents.map((tabComponent, idx) => {
                return (
                  <Tab
                    key={tabComponent.label}
                    sx={warehouseSettingsViewStyles.tabText}
                    label={tabComponent.label}
                    tabIndex={idx}
                  />
                );
              })}
            </Tabs>
          </Card>
        </Grid>
        <Grid item xs={9}>
          <Card sx={{ my: 5, width: '100%', height: '80vh', overflow: 'auto' }}>
            {filteredTabComponents.map((tabComponent, idx) => {
              return (
                <TabPanel key={tabComponent.label} value={value} index={idx}>
                  {tabComponent.component}
                </TabPanel>
              );
            })}
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WarehouseSettingsView;
