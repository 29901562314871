import { Add } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Snackbar,
  Stack,
  Tooltip,
} from '@mui/material';
import { type ColDef } from 'ag-grid-community';
import { type AgGridReact } from 'ag-grid-react';
import { isEmpty, isNil, noop } from 'lodash';
import { type ReactNode, type SetStateAction } from 'react';
import type React from 'react';
import { useCallback, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import { isNilOrEmptyString } from 'shared/string';
import { useShallow } from 'zustand/react/shallow';
import { shallow } from 'zustand/shallow';
import CustomerFilterButton, {
  isPrefixOption,
  type PrefixOption,
} from '../../../../common/components/customer-filter-button';
import DateDropdownPicker, {
  type DateOption,
  type DatePickerFilterType,
  initialDateOption,
} from '../../../../common/components/date-dropdown-picker';
import TerminalFilterButton from '../../../../common/components/terminal-filter-button';
import { type Option } from '../../../../common/filters/types';
import useTerminals from '../../../../common/react-hooks/use-terminals';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import { downloadFile } from '../../../../common/utils/file';
import {
  type FilterViewPage,
  type FormattedOrderFragment,
  GetSavedFilterViewsDocument,
  type OrderTableField,
  type GetOrderTableFieldValuesQueryVariables,
  PermissionResource,
  type SavedFilterViewFragment,
  useCreateSavedFilterViewMutation,
  useDeleteSavedFilterViewMutation,
  useGetSavedFilterViewsQuery,
  useUpdateSavedFilterViewMutation,
  type CreateOrderTableReportMutationVariables,
  useCreateOrderTableReportMutation,
  type GetOrderTableFieldValuesInput,
} from '../../../../generated/graphql';
import AddOrdersOptionButton from '../../../orders/components/add-orders-options-button';
import BulkActionsModal from '../../../orders/components/bulk-action/bulk-actions-modal';
import {
  type FilterModel,
  type OrderFilterField,
} from '../../../orders/components/enums/order-filters';
import useOrdersPageStore from '../../../orders/orders-table-store';
import CreateNewSavedViewModal from '../../../saved-filter-views/components/create-new-saved-view-modal';
import useFilterStore from '../../filter-store';
import { useRememberedOrdersFilters } from '../../use-remembered-orders-filters';
import ViewChangedButton from '../../view-changed-button';
import { useStyles } from '../styles';
import {
  type DefaultFilterTabsConfigs,
  type ReadOnlyRefObject,
  type OrdersTableWithFiltersState,
} from '../types';
import FilterRow from './filters/filter-row';
import OrderTableSearchInput from './order-table-search-input';
import OrderTableTabs from './order-table-tabs';

export type OrdersTableFunctionsContainerProps<DefaultFilterTabsType> = {
  // General props
  readonly gridRef: React.RefObject<AgGridReact<FormattedOrderFragment>>;
  readonly stateRef: ReadOnlyRefObject<
    OrdersTableWithFiltersState<DefaultFilterTabsType>
  >;
  readonly setState: (
    value: React.SetStateAction<
      OrdersTableWithFiltersState<DefaultFilterTabsType>
    >,
  ) => void;
  readonly defaultFilterTabsConfigs: DefaultFilterTabsConfigs<DefaultFilterTabsType>;
  readonly orderTableFields: OrderTableField[];
  readonly columnDefs: Array<ColDef<FormattedOrderFragment>>;
  readonly getOrderTableFieldValuesInput:
    | GetOrderTableFieldValuesInput
    | undefined;
  readonly refreshGrid: (
    shouldDeselectCheckboxes?: boolean,
    refreshServerSide?: boolean,
  ) => void;
  readonly applyFilterModel: (filterModel: FilterModel | null) => void;
  readonly isTableLoading: boolean;
  readonly enableNumberOfOrdersQuery?: boolean;
  readonly controlBarSpacing?: boolean;
  readonly pageType: FilterViewPage;
  readonly topRightComponent?: ReactNode;
  readonly tabsNumberOfOrderData: Array<{
    tab: DefaultFilterTabsType;
    numberOfOrders: number | undefined;
  }>;
  readonly populateTableFromConfig: () => Promise<void>;
  // General filter props
  readonly handleSaveFiltersToExistingFilterView: ({
    isAutoSave,
  }: {
    isAutoSave?: boolean;
  }) => Promise<void>;
  readonly newFiltersApplied: number;
  readonly setNewFiltersApplied: React.Dispatch<SetStateAction<number>>;
  readonly filterViewSaveSuccessAlertText: string | undefined;
  readonly setFilterViewSaveSuccessAlertText: React.Dispatch<
    SetStateAction<string | undefined>
  >;
  readonly filterViewSaveFailedAlert: boolean;
  readonly setFilterViewSaveFailedAlert: React.Dispatch<
    SetStateAction<boolean>
  >;
  // Date filter props
  readonly shouldShowDatePicker?: boolean;
  readonly defaultDatePickerFilterType?: DatePickerFilterType;
  readonly handleDatePickerChange: (option: DateOption) => void;
  // Customer filter props
  readonly shouldShowCustomerFilter?: boolean;
  readonly shouldShowCustomerFilterMultiselect?: boolean;
  readonly handleCustomerChange: (
    option: Option | PrefixOption | undefined,
  ) => void;
  readonly handleCustomersChange: (
    options: Option[] | PrefixOption | null | undefined,
  ) => void;
  // Terminal filter props
  readonly shouldShowTerminalFilter?: boolean;
  readonly shouldShowNoTerminalOptionMultiselect?: boolean;
  readonly shouldShowOriginTerminalFilter?: boolean;
  readonly shouldShowDestinationTerminalFilter?: boolean;
  readonly handleTerminalsChange: (
    options: Option[] | null | undefined,
  ) => void;
  readonly handleOriginTerminalChange: (
    option: Option | null | undefined,
  ) => void;
  readonly handleDestinationTerminalChange: (
    option: Option | null | undefined,
  ) => void;
  readonly terminalFilterCacheId?: string;
  readonly originTerminalFilterCacheId?: string;
  readonly destinationTerminalFilterCacheId?: string;
  // Search props
  readonly handleSearch: (searchText?: string) => void;
  readonly multipleSearches: string[];
  readonly useMultiSearchText?: boolean;
  // Bulk actions props
  readonly bulkActionsEnabled?: boolean;
  readonly bulkActionsOverLimit: boolean;
  readonly selectedUuids: string[];
  readonly deselectAll: () => void;
  // Views props
  readonly setShowConfigureOrderHeaders: React.Dispatch<
    SetStateAction<boolean>
  >;
  readonly shouldAllowSavedFilterViews?: boolean;
  readonly shouldRememberFilters?: boolean;
  // Upload props
  readonly openUploadPdfsModal?: () => void;
  readonly openUploadCsvsModal?: () => void;
  readonly openBillingPartyModal?: () => void;
  // Report props
  readonly buildOrderTableReportInput: () => GetOrderTableFieldValuesQueryVariables;
  readonly shouldShowGenerateReportButtons?: boolean;
};
export const OrdersTableFunctionsContainer = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unnecessary-type-constraint
  DefaultFilterTabsType extends any,
>({
  gridRef,
  stateRef,
  setState,
  defaultFilterTabsConfigs,
  orderTableFields,
  columnDefs,
  getOrderTableFieldValuesInput,
  refreshGrid,
  applyFilterModel,
  isTableLoading,
  enableNumberOfOrdersQuery,
  controlBarSpacing = true,
  pageType,
  topRightComponent,
  tabsNumberOfOrderData,
  populateTableFromConfig,
  handleSaveFiltersToExistingFilterView,
  newFiltersApplied,
  setNewFiltersApplied,
  filterViewSaveSuccessAlertText,
  setFilterViewSaveSuccessAlertText,
  filterViewSaveFailedAlert,
  setFilterViewSaveFailedAlert,
  shouldShowDatePicker = false,
  defaultDatePickerFilterType,
  handleDatePickerChange,
  shouldShowCustomerFilter = false,
  shouldShowCustomerFilterMultiselect = false,
  handleCustomerChange,
  handleCustomersChange,
  shouldShowTerminalFilter = false,
  shouldShowNoTerminalOptionMultiselect = false,
  shouldShowOriginTerminalFilter = false,
  shouldShowDestinationTerminalFilter = false,
  handleTerminalsChange,
  handleOriginTerminalChange,
  handleDestinationTerminalChange,
  terminalFilterCacheId,
  originTerminalFilterCacheId,
  destinationTerminalFilterCacheId,
  handleSearch,
  multipleSearches,
  useMultiSearchText,
  bulkActionsEnabled,
  bulkActionsOverLimit,
  selectedUuids,
  deselectAll,
  setShowConfigureOrderHeaders,
  shouldAllowSavedFilterViews = false,
  shouldRememberFilters = false,
  openUploadPdfsModal,
  openUploadCsvsModal,
  openBillingPartyModal,
  buildOrderTableReportInput,
  shouldShowGenerateReportButtons = true,
}: OrdersTableFunctionsContainerProps<DefaultFilterTabsType>) => {
  const styles = useStyles();

  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Orders,
  );
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });

  const [updateSavedFilterViewMutation] = useUpdateSavedFilterViewMutation({
    refetchQueries: [
      { query: GetSavedFilterViewsDocument, variables: { pageType } },
    ],
  });
  const [createSavedFilterViewMutation] = useCreateSavedFilterViewMutation({
    refetchQueries: [
      { query: GetSavedFilterViewsDocument, variables: { pageType } },
    ],
  });
  const [deleteSavedFilterViewMutation] = useDeleteSavedFilterViewMutation({
    refetchQueries: [
      { query: GetSavedFilterViewsDocument, variables: { pageType } },
    ],
  });

  const [
    bulkActionEditConfirmAlertMessage,
    setBulkActionEditConfirmAlertMessage,
  ] = useState<string>();
  const [showCreateNewSavedViewModal, setShowCreateNewSavedViewModal] =
    useState(false);
  const [showBulkActionsModal, setShowBulkActionsModal] =
    useState<boolean>(false);
  const [newViewIsFromScratch, setNewViewIsFromScratch] = useState(true);

  const rememberedFilters = useRememberedOrdersFilters(pageType);
  const { setRememberedTabs } = useFilterStore(
    useShallow((state) => ({ setRememberedTabs: state.setTabs })),
  );

  const bulkActionsTotalCount = isEmpty(selectedUuids)
    ? stateRef.current.totalCount
    : selectedUuids.length;

  const handleResetFilterEdits = useCallback(() => {
    gridRef.current?.api.setFilterModel(
      stateRef?.current?.customFilterModelJson,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRef?.current?.customFilterModelJson]);

  const showCreateFilterViewModal = useCallback(
    async (viewIsFromScratch: boolean) => {
      setNewViewIsFromScratch(viewIsFromScratch);
      setShowCreateNewSavedViewModal(true);
    },
    [],
  );

  const handleCreateNewFilterView = async (
    displayName: string,
    viewIsFromScratch: boolean,
  ) => {
    const filterModel = viewIsFromScratch
      ? {}
      : (gridRef.current?.api?.getFilterModel() ?? {});
    try {
      const newViewInput = {
        displayName,
        filterModelJson: JSON.stringify(filterModel),
        savedViewPageType: pageType,
        orderTableFields,
      };
      const createView = await createSavedFilterViewMutation({
        variables: {
          createSavedFilterViewInput: {
            displayName,
            filterModelJson: JSON.stringify(filterModel),
            savedViewPageType: pageType,
            orderTableFields,
          },
        },
        refetchQueries: [GetSavedFilterViewsDocument],
      });
      const newUuid = createView.data?.createSavedFilterView.uuid;
      if (isNil(newUuid)) {
        throw new Error('[create new filter view] no UUID returned');
      }
      setNewFiltersApplied(0);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      onChangeTab({
        newTab: newUuid,
        newView: {
          uuid: newUuid,
          ...newViewInput,
          isShared: false,
          dispatchTableFields: [],
        },
      });
      setFilterViewSaveSuccessAlertText(`Created ${displayName} successfully`);
    } catch (error) {
      console.error('Error creating filter view', error);
      setFilterViewSaveFailedAlert(true);
    }
  };

  const handleEditFilterView = useCallback(
    async (displayName: string) => {
      try {
        if (
          isNil(stateRef?.current) ||
          isNil(stateRef.current.currentSavedViewUuid)
        ) {
          throw new Error(
            '[saving filter view] trying to save to null filter view UUID',
          );
        }
        await updateSavedFilterViewMutation({
          variables: {
            updateSavedFilterViewInput: {
              uuid: stateRef.current.currentSavedViewUuid,
              displayName,
            },
          },
          refetchQueries: [GetSavedFilterViewsDocument],
        });
        setState((prevState) => {
          return {
            ...prevState,
            currentSavedViewName: displayName,
            currentCursor: null,
          };
        });
        setFilterViewSaveSuccessAlertText(`Saved ${displayName} successfully`);
      } catch (error) {
        console.error('Error saving filter view', error);
        setFilterViewSaveFailedAlert(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stateRef?.current?.currentSavedViewUuid],
  );

  const saveFiltersToExistingView = useCallback(async () => {
    await handleSaveFiltersToExistingFilterView({
      isAutoSave: false,
    });
  }, [handleSaveFiltersToExistingFilterView]);

  const handleDeleteFilterView = useCallback(
    async (uuid: string) => {
      await deleteSavedFilterViewMutation({
        variables: {
          uuid,
        },
        refetchQueries: [GetSavedFilterViewsDocument],
      });
      setNewFiltersApplied(0);
      setState((prevState) => {
        return {
          ...prevState,
          ordersTab: defaultFilterTabsConfigs.defaultTab,
          currentTabIsNewView: false,
          currentTabIsSavedView: false,
          customFilterModelJson: {},
          currentSavedViewUuid: null,
          currentSavedViewName: null,
          currentCursor: null,
        };
      });
      populateTableFromConfig();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [populateTableFromConfig],
  );

  const destroyFilter = useCallback((field: OrderFilterField) => {
    gridRef.current?.api.destroyFilter(field);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteFilter = useCallback(
    async (field: OrderFilterField) => {
      // reset the filter
      destroyFilter(field);

      // collapse that filter in the panel.
      const toolPanel = gridRef.current?.api.getToolPanelInstance('filters');
      toolPanel?.collapseFilters([field]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [destroyFilter],
  );

  const handleClickSelectedFilter = useCallback(
    async (field: OrderFilterField) => {
      // open the toolpanel and show that filter
      const toolPanel = gridRef.current?.api.getToolPanelInstance('filters');
      toolPanel?.expandFilters([field]);
      toolPanel?.setFilterLayout([
        columnDefs.find((colDef) => colDef.field === field) ?? {},
      ]);
      gridRef.current?.api.openToolPanel('filters');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnDefs],
  );

  const onChangeTab = useCallback(
    ({
      newTab,
      newView,
      shouldApplyFilter = true,
    }: {
      newTab: string | DefaultFilterTabsType;
      newView: Omit<SavedFilterViewFragment, 'user'> | undefined;
      shouldApplyFilter?: boolean;
    }) => {
      if (shouldAllowSavedFilterViews && !isNil(newView)) {
        setState((prevState) => {
          return {
            ...prevState,
            currentTabIsSavedView: true,
            currentTabIsNewView: false,
            ordersTab: newTab,
            currentCursor: null,
            customFilterModelJson: JSON.parse(newView.filterModelJson),
            currentSavedViewUuid: newView.uuid,
            currentSavedViewName: newView.displayName,
          };
        });
        if (shouldRememberFilters) {
          setRememberedTabs(JSON.stringify(newView), pageType, 'custom');
        }

        if (shouldApplyFilter) {
          applyFilterModel(JSON.parse(newView.filterModelJson));
        }
      } else {
        const currentDefaultTab = defaultFilterTabsConfigs.tabs.find(
          (tab) => tab.value === newTab,
        );
        setState((prevState) => {
          return {
            ...prevState,
            currentTabIsSavedView: false,
            currentTabIsNewView: false,
            ordersTab: newTab,
            currentCursor: null,
            customFilterModelJson: currentDefaultTab?.filterModel ?? {},
            currentSavedViewUuid: null,
            currentSavedViewName: null,
          };
        });
        if (shouldRememberFilters) {
          setRememberedTabs(
            JSON.stringify(currentDefaultTab),
            pageType,
            'default',
          );
        }

        if (shouldApplyFilter) {
          applyFilterModel(currentDefaultTab?.filterModel ?? {});
        }
      }
    },
    [
      shouldAllowSavedFilterViews,
      shouldRememberFilters,
      defaultFilterTabsConfigs.tabs,
      pageType,
      applyFilterModel,
      setRememberedTabs,
      setState,
    ],
  );

  const { data: savedFilterViewsData } = useGetSavedFilterViewsQuery({
    variables: { pageType },
    onCompleted: (data) => {
      const savedViewTab: SavedFilterViewFragment | undefined =
        data?.getSavedFilterViews.find(
          (view) => view.uuid === stateRef.current.ordersTab,
        );

      onChangeTab({
        newTab: stateRef.current.ordersTab,
        newView: savedViewTab,
        shouldApplyFilter: isNil(rememberedFilters),
      });

      // Read unsaved filter model changes from local storage.
      // We wait to load the saved filter view data first so that we can
      // avoid overwriting the local storage changes when the view data loads
      if (!isNil(rememberedFilters)) {
        applyFilterModel(rememberedFilters);
      }
    },
  });

  const [createOrderTableReport, { loading: createOrderTableReportLoading }] =
    useCreateOrderTableReportMutation();

  const [setShowReportBeingEmailedMessage, setShowTableReportErrorMessage] =
    useOrdersPageStore(
      (state) => [
        state.setShowReportBeingEmailedMessage,
        state.setShowTableReportErrorMessage,
      ],
      shallow,
    );

  const fetchOrderTableReport = async () => {
    const variables = buildOrderTableReportInput();
    const orderTableReportArgs: CreateOrderTableReportMutationVariables = {
      createOrderTableReportInput: {
        ...variables.getOrderTableFieldValuesInput,
        orderTableFields,
        searchText:
          useMultiSearchText === true
            ? undefined
            : stateRef?.current?.searchText?.trim(),
        noMaximum: true,
        ...(selectedUuids.length > 0 && { uuids: selectedUuids }),
      },
    };

    try {
      const orderTableReportResponse = await createOrderTableReport({
        variables: orderTableReportArgs,
      });

      const orderTableReportResponseData =
        orderTableReportResponse.data?.createOrderTableReport;

      switch (orderTableReportResponseData?.__typename) {
        case 'OrderTableReportDownloadOutput': {
          const url = orderTableReportResponseData?.reportUrl;
          if (!isNilOrEmptyString(url)) {
            await downloadFile({ url });
          }

          break;
        }
        case 'OrderTableReportEmailToUserOutput': {
          setShowReportBeingEmailedMessage(true);

          break;
        }
        case 'MutationErrorOutput': {
          setShowTableReportErrorMessage(true);

          break;
        }
        // No default
      }
    } catch {
      setShowTableReportErrorMessage(true);
    }
  };

  return (
    <>
      {!isNil(bulkActionEditConfirmAlertMessage) && (
        <Snackbar
          autoHideDuration={5000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={!isNil(bulkActionEditConfirmAlertMessage)}
          onClose={() => {
            setBulkActionEditConfirmAlertMessage(undefined);
          }}
        >
          <Alert>{bulkActionEditConfirmAlertMessage}</Alert>
        </Snackbar>
      )}
      {filterViewSaveFailedAlert && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={filterViewSaveFailedAlert}
        >
          <Alert
            severity="error"
            onClose={() => {
              setFilterViewSaveFailedAlert(false);
            }}
          >
            Error saving filters
          </Alert>
        </Snackbar>
      )}
      {!isNil(filterViewSaveSuccessAlertText) && (
        <Snackbar
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={!isNil(filterViewSaveSuccessAlertText)}
          onClose={() => {
            setFilterViewSaveSuccessAlertText(undefined);
          }}
        >
          <Alert> {filterViewSaveSuccessAlertText}</Alert>
        </Snackbar>
      )}
      {showCreateNewSavedViewModal && (
        <CreateNewSavedViewModal
          open={showCreateNewSavedViewModal}
          setOpen={setShowCreateNewSavedViewModal}
          createNewSavedView={handleCreateNewFilterView}
          viewIsFromScratch={newViewIsFromScratch}
        />
      )}
      {showBulkActionsModal && (
        <BulkActionsModal
          isOpen={showBulkActionsModal}
          setIsOpen={setShowBulkActionsModal}
          getOrderTableFieldValuesInput={getOrderTableFieldValuesInput}
          selectedOrderUuids={selectedUuids}
          searchText={stateRef?.current?.searchText ?? ''}
          onConfirm={(edited, downloaded) => {
            if (edited) {
              if (downloaded) {
                setBulkActionEditConfirmAlertMessage(
                  'Successfully edited orders. Downloading files...',
                );
              } else {
                setBulkActionEditConfirmAlertMessage(
                  'Successfully edited orders.',
                );
              }
            }
            refreshGrid();
          }}
        />
      )}
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          p: 1,
          pl: controlBarSpacing ? 1 : 0,
          pt: controlBarSpacing ? 1 : 0,
        }}
      >
        <Stack direction="row" spacing={2}>
          {shouldShowDatePicker && (
            <DateDropdownPicker
              filterTitle="Service Date"
              dateOption={stateRef?.current?.dateOption ?? initialDateOption}
              setDateOption={handleDatePickerChange}
              defaultFilterType={defaultDatePickerFilterType}
            />
          )}
          {shouldShowCustomerFilter && (
            <CustomerFilterButton<true>
              enablePrefixSearch
              cacheId="ORDERS"
              selectedOption={
                isPrefixOption(stateRef?.current?.customerOptions)
                  ? stateRef?.current?.customerOptions
                  : stateRef?.current?.customerOptions?.[0]
              }
              handleChange={handleCustomerChange}
            />
          )}
          {!shouldShowCustomerFilter && shouldShowCustomerFilterMultiselect && (
            <CustomerFilterButton<true>
              enablePrefixSearch
              cacheId="ORDERS_MULTISELECT"
              selectedOptionsMultiselect={
                stateRef?.current?.customerOptions ?? undefined
              }
              handleChangeMultiselect={handleCustomersChange}
              disableButton={isTableLoading}
            />
          )}
          {shouldShowTerminalFilter && terminalsEnabled && (
            <TerminalFilterButton
              displayCode
              cacheId={terminalFilterCacheId}
              prefixText="Terminal"
              selectedOptionsMultiselect={stateRef?.current?.terminalOptions}
              handleChangeMultiselect={handleTerminalsChange}
              displayNoneOptionMultiselect={
                shouldShowNoTerminalOptionMultiselect
              }
              includeInactiveTerminals={false}
              disableButton={isTableLoading}
            />
          )}
          <OrderTableSearchInput
            initialSearchText={stateRef?.current?.searchText}
            currentViewName={stateRef?.current?.currentSavedViewName}
            currentTab={stateRef?.current?.ordersTab?.toString()}
            onSearch={handleSearch}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          {topRightComponent}
          {bulkActionsEnabled === true && selectedUuids.length > 0 && (
            <Tooltip title="Clear Selection">
              <IconButton
                color="error"
                size="small"
                sx={{ p: 0, pl: 0.5, fontSize: '10px' }}
                onClick={(e) => {
                  e.stopPropagation();
                  deselectAll();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          )}
          {bulkActionsEnabled === true && (
            <Button
              variant="contained"
              color="info"
              disabled={
                bulkActionsOverLimit ||
                bulkActionsTotalCount === 0 ||
                !canWriteOrders
              }
              onClick={() => {
                setShowBulkActionsModal(true);
              }}
            >
              Bulk Actions ({bulkActionsTotalCount ?? '-'})
            </Button>
          )}
          {!isNil(openUploadPdfsModal) && !isNil(openUploadCsvsModal) && (
            <AddOrdersOptionButton
              disabled={!canWriteOrders}
              onCreateFromPdfs={openUploadPdfsModal}
              onCreateFromCsvs={openUploadCsvsModal}
            />
          )}

          {!isNil(openBillingPartyModal) && (
            <Button
              variant="contained"
              sx={{ alignSelf: 'flex-end' }}
              data-cy="add-order-button"
              disabled={!canWriteOrders}
              onClick={() => {
                openBillingPartyModal();
              }}
            >
              Add order manually
            </Button>
          )}
        </Stack>
      </Box>
      {useMultiSearchText === true && (
        <Stack direction="row" spacing={1} sx={{ mb: 1, ml: 1 }}>
          {multipleSearches.map((search, existingIndex) => (
            <Chip
              // eslint-disable-next-line react/no-array-index-key
              key={`${search}-${existingIndex}`}
              label={search}
              onDelete={() => {
                setState((prevState) => ({
                  ...prevState,
                  multipleSearches: multipleSearches.filter(
                    (itrSearch, itrIdx) =>
                      itrSearch !== search && existingIndex !== itrIdx,
                  ),
                  currentCursor: null,
                  searchText: '',
                }));
                handleSearch();
              }}
            />
          ))}
          {multipleSearches.length > 0 && (
            <Button
              variant="outlined"
              sx={{ whiteSpace: 'no-wrap', textAlign: 'center' }}
              onClick={() => {
                setState((prevState) => ({
                  ...prevState,
                  multipleSearches: [],
                  currentCursor: null,
                  searchText: '',
                }));
                handleSearch();
              }}
            >
              Clear ({multipleSearches.length})
            </Button>
          )}
        </Stack>
      )}
      <OrderTableTabs
        pageType={pageType}
        canWriteOrders={canWriteOrders}
        savedViews={savedFilterViewsData?.getSavedFilterViews}
        currentTab={stateRef?.current?.ordersTab}
        currentSavedViewName={stateRef?.current?.currentSavedViewName ?? null}
        defaultFilterTabsConfigs={defaultFilterTabsConfigs}
        tabsNumberOfOrdersData={
          enableNumberOfOrdersQuery === true ? tabsNumberOfOrderData : undefined
        }
        shouldAllowSavedViews={shouldAllowSavedFilterViews}
        handleEditSavedViewName={handleEditFilterView}
        handleSetIsViewShared={noop}
        handleDeleteSavedView={handleDeleteFilterView}
        showCreateSavedViewModal={showCreateFilterViewModal}
        onChangeTab={onChangeTab}
      />
      <Stack
        direction="row"
        spacing={2}
        sx={{
          mr: '10px',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            border: '1px solid',
            borderColor: 'divider',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '10px',
            mb: '10px',
            p: '7px',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
            }}
          >
            {shouldShowOriginTerminalFilter && terminalsEnabled && (
              <TerminalFilterButton
                displayCode
                cacheId={originTerminalFilterCacheId}
                prefixText="Orig"
                selectedOption={stateRef?.current?.originTerminalOption}
                handleChange={handleOriginTerminalChange}
                includeInactiveTerminals={false}
              />
            )}
            {shouldShowDestinationTerminalFilter && terminalsEnabled && (
              <TerminalFilterButton
                displayCode
                cacheId={destinationTerminalFilterCacheId}
                prefixText="Dest"
                selectedOption={stateRef?.current?.destinationTerminalOption}
                handleChange={handleDestinationTerminalChange}
                includeInactiveTerminals={false}
              />
            )}
            <Button
              size="small"
              startIcon={<Add />}
              sx={styles.filterButton}
              variant="outlined"
              onClick={(_e) => {
                gridRef.current?.api.openToolPanel('filters');
              }}
            >
              Filter
            </Button>
            <FilterRow
              filterModel={gridRef.current?.api?.getFilterModel()}
              defaultFilterTabsConfigs={defaultFilterTabsConfigs}
              currentTab={stateRef?.current?.ordersTab}
              handleDeleteFilter={handleDeleteFilter}
              handleClickSelectedFilter={handleClickSelectedFilter}
              columnDefs={columnDefs}
            />
          </Box>
          <ViewChangedButton
            numFiltersChanged={newFiltersApplied}
            numSortsChanged={0}
            numColumnsChanged={0}
            resetView={handleResetFilterEdits}
            shouldAllowSavedViews={shouldAllowSavedFilterViews}
            saveView={saveFiltersToExistingView}
            showCreateViewModal={showCreateFilterViewModal}
            currentSavedViewUuid={stateRef.current.currentSavedViewUuid}
          />
        </Box>
        <Button
          variant="outlined"
          sx={{
            width: '150px',
            height: '45px',
          }}
          onClick={() => {
            setShowConfigureOrderHeaders(true);
          }}
        >
          Edit columns
        </Button>
        {shouldShowGenerateReportButtons && (
          <Button
            disabled={createOrderTableReportLoading}
            startIcon={
              createOrderTableReportLoading && (
                <CircularProgress size={15} sx={{ color: 'gray' }} />
              )
            }
            sx={{
              width: '200px',
              height: '45px',
            }}
            variant="contained"
            onClick={() => {
              fetchOrderTableReport();
            }}
          >
            Generate Report
          </Button>
        )}
      </Stack>
    </>
  );
};

export default OrdersTableFunctionsContainer;
