import {
  createEntityAdapter,
  createSelector,
  createSlice,
  type EntityId,
  type EntityState,
} from '@reduxjs/toolkit';
import { type PackageValues } from 'shared/types';
import type { RootState } from '../../../redux/store';

const packageValuesAdapter = createEntityAdapter<PackageValues>({
  selectId: (packageValue) => packageValue.uuid,
});

export const packageValuesSlice = createSlice({
  name: 'packageValues',
  initialState: packageValuesAdapter.getInitialState(),
  reducers: {
    addOnePackageValues: packageValuesAdapter.addOne,
    addManyPackageValues: packageValuesAdapter.addMany,
    updateOnePackageValues: packageValuesAdapter.updateOne,
    upsertOnePackageValues: packageValuesAdapter.upsertOne,
    upsertManyPackageValues: packageValuesAdapter.upsertMany,
    removeOnePackageValues: packageValuesAdapter.removeOne,
    removeManyPackageValues: packageValuesAdapter.removeMany,
  },
});

export const { selectById: selectPackageById, selectAll: selectAllPackages } =
  packageValuesAdapter.getSelectors((state: RootState) => state.packageValues);

export const {
  addOnePackageValues,
  addManyPackageValues,
  updateOnePackageValues,
  removeOnePackageValues,
  upsertManyPackageValues,
  upsertOnePackageValues,
  removeManyPackageValues,
} = packageValuesSlice.actions;

const packageValuesReducer = packageValuesSlice.reducer;

const packagesSelector = (state: RootState) => state.packageValues;

export const selectPackagesByIds = createSelector(
  packagesSelector,
  (state: RootState, ids: EntityId[]) => ids,
  (state: EntityState<PackageValues>, entityIds: EntityId[]) => {
    const entityResults: PackageValues[] = [];
    for (const id of entityIds) {
      const match = packageValuesAdapter.getSelectors().selectById(state, id);
      if (match) {
        entityResults.push(match);
      }
    }
    return entityResults;
  },
);

export default packageValuesReducer;
