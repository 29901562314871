import { PickupOrDelivery, StopType } from '../../../generated/graphql';

export const pinTextForStopType = (
  stopType: StopType | PickupOrDelivery | null | undefined,
): 'P' | 'D' | 'U' => {
  switch (stopType) {
    case StopType.Pickup:
    case PickupOrDelivery.Pickup: {
      return 'P';
    }
    case StopType.Delivery:
    case PickupOrDelivery.Delivery: {
      return 'D';
    }
    default: {
      return 'U';
    }
  }
};
