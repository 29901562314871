import { z } from 'zod';

export const userSchema = z.object({
  email: z.preprocess(
    // Preprocess empty strings to undefined
    // because email() doesn't accept empty strings
    (val) => (val === '' ? undefined : val),
    z.string().email({ message: 'Invalid email' }).nullish(),
  ),
  uuid: z.string().uuid().nullish(),
});
