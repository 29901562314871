import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import dayjs, { type Dayjs } from 'dayjs';
import { isNil } from 'lodash';
import { type InvoiceAgingReportDataFragment } from '../../../../../generated/graphql';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 25,
    fontFamily: 'Roboto',
  },
  header1: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  header2: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  rowWithSpaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contactBlock: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12px',
    marginTop: 4,
  },
  contactBlockHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#f0eded',
    padding: 4,
  },
  invoicesTable: {
    width: '100%',
  },
  invoicesTableRow: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 2,
    paddingBottom: 2,
    justifyContent: 'space-between',
    fontSize: '9px',
    width: '100%',
    textAlign: 'center',
  },
  invoicesTableHeader: {
    borderTop: 'none',
    fontWeight: 'bold',
    borderBottom: '1px solid black',
  },
  invoicesTableRowLeftSection: {
    display: 'flex',
    flexDirection: 'row',
    width: '35%',
  },
  invoicesTableRowRightSection: {
    display: 'flex',
    flexDirection: 'row',
    width: '60%',
    justifyContent: 'space-between',
    textAlign: 'center',
  },

  customerCol: {
    textAlign: 'left',
    width: '100%',
  },
  balanceCol: {
    width: '14%',
  },
  currentCol: {
    width: '14%',
  },
  col31to38: {
    width: '14%',
  },
  col39to45: {
    width: '14%',
  },
  col46to60: {
    width: '14%',
  },
  col61to75: {
    width: '14%',
  },
  colOver75: {
    width: '14%',
  },
});

const AgingReportTable = ({
  contactsWithInvoiceInfo,
}: {
  readonly contactsWithInvoiceInfo: InvoiceAgingReportDataFragment[];
}) => {
  return (
    <View style={styles.invoicesTable}>
      <View style={[styles.invoicesTableRow, styles.invoicesTableHeader]}>
        <View style={styles.invoicesTableRowLeftSection}>
          <Text style={styles.customerCol}>Customer</Text>
        </View>
        <View style={styles.invoicesTableRowRightSection}>
          <Text style={styles.balanceCol}> Balance </Text>
          <Text style={styles.currentCol}> Current </Text>
          <Text style={styles.col31to38}> 31-38 </Text>
          <Text style={styles.col39to45}> 39-45 </Text>
          <Text style={styles.col46to60}> 46-60 </Text>
          <Text style={styles.col61to75}> 61-75 </Text>
          <Text style={styles.colOver75}> Over 75 </Text>
        </View>
      </View>
      {contactsWithInvoiceInfo
        .sort((a, b) =>
          (a?.displayName ?? '').localeCompare(b?.displayName ?? ''),
        )
        .map(
          (contact) =>
            !isNil(contact) && (
              // eslint-disable-next-line react/jsx-key
              <View style={styles.invoicesTableRow} wrap={false}>
                <View style={styles.invoicesTableRowLeftSection}>
                  <Text style={styles.customerCol}>{contact.displayName}</Text>
                </View>
                <View style={styles.invoicesTableRowRightSection}>
                  <Text style={styles.balanceCol}>
                    {contact.contactOpenInvoiceValue?.toFixed(2)}
                  </Text>
                  <Text style={styles.currentCol}>
                    {contact.contactOpenInvoiceValue0to30d?.toFixed(2)}
                  </Text>
                  <Text style={styles.col31to38}>
                    {contact.contactOpenInvoiceValue31to38d?.toFixed(2)}
                  </Text>
                  <Text style={styles.col39to45}>
                    {contact.contactOpenInvoiceValue39to45d?.toFixed(2)}
                  </Text>
                  <Text style={styles.col46to60}>
                    {contact.contactOpenInvoiceValue46to60d?.toFixed(2)}
                  </Text>
                  <Text style={styles.col61to75}>
                    {contact.contactOpenInvoiceValue61to75d?.toFixed(2)}
                  </Text>
                  <Text style={styles.colOver75}>
                    {contact.contactOpenInvoiceValueOver75d?.toFixed(2)}
                  </Text>
                </View>
              </View>
            ),
        )}
      <View style={styles.invoicesTableRow} wrap={false}>
        <View style={styles.invoicesTableRowLeftSection}>
          <Text style={styles.customerCol}>Report total:</Text>
        </View>
        <View style={styles.invoicesTableRowRightSection}>
          <Text style={styles.balanceCol}>
            {contactsWithInvoiceInfo
              .reduce(
                (prev, curr) => prev + (curr?.contactOpenInvoiceValue ?? 0),
                0,
              )
              .toFixed(2)}
          </Text>
          <Text style={styles.currentCol}>
            {contactsWithInvoiceInfo
              .reduce(
                (prev, curr) =>
                  prev + (curr?.contactOpenInvoiceValue0to30d ?? 0),
                0,
              )
              .toFixed(2)}
          </Text>
          <Text style={styles.col31to38}>
            {contactsWithInvoiceInfo
              .reduce(
                (prev, curr) =>
                  prev + (curr?.contactOpenInvoiceValue31to38d ?? 0),
                0,
              )
              .toFixed(2)}
          </Text>
          <Text style={styles.col39to45}>
            {contactsWithInvoiceInfo
              .reduce(
                (prev, curr) =>
                  prev + (curr?.contactOpenInvoiceValue39to45d ?? 0),
                0,
              )
              .toFixed(2)}
          </Text>
          <Text style={styles.col46to60}>
            {contactsWithInvoiceInfo
              .reduce(
                (prev, curr) =>
                  prev + (curr?.contactOpenInvoiceValue46to60d ?? 0),
                0,
              )
              .toFixed(2)}
          </Text>
          <Text style={styles.col61to75}>
            {contactsWithInvoiceInfo
              .reduce(
                (prev, curr) =>
                  prev + (curr?.contactOpenInvoiceValue61to75d ?? 0),
                0,
              )
              .toFixed(2)}
          </Text>
          <Text style={styles.colOver75}>
            {contactsWithInvoiceInfo
              .reduce(
                (prev, curr) =>
                  prev + (curr?.contactOpenInvoiceValueOver75d ?? 0),
                0,
              )
              .toFixed(2)}
          </Text>
        </View>
      </View>
    </View>
  );
};

const GeneratedSummaryAgingReportPdfOld = ({
  contactUuid,
  data,
  startDate,
}: {
  readonly contactUuid?: string;
  readonly data: InvoiceAgingReportDataFragment[] | undefined | null;
  readonly startDate?: Dayjs | null | undefined;
}) => {
  if (isNil(data)) {
    return null;
  }
  return (
    <Document title="Invoice">
      <Page orientation="landscape" size="LETTER" style={styles.page}>
        <View
          style={[
            styles.rowWithSpaceBetween,
            {
              marginTop: '10px',
              fontSize: '12px',
            },
          ]}
        >
          <Text style={styles.header1}>Account Aging Summary Report</Text>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '10px',
          }}
        >
          <View style={styles.rowWithSpaceBetween}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontSize: '10px',
              }}
            >
              <Text style={{ fontWeight: 'bold' }}>Customer</Text>
              <Text style={{ marginLeft: '3px' }}>
                {isNil(contactUuid) ? 'All' : `${data?.at(0)?.displayName}`}
              </Text>
            </View>
            <Text style={{ fontSize: '10px' }}>
              Aging calculated as of{' '}
              {(startDate ?? dayjs(new Date())).format('MM/DD/YYYY')}
            </Text>
          </View>
        </View>
        {!isNil(data)}
        <View style={styles.contactBlock}>
          <AgingReportTable contactsWithInvoiceInfo={data} />
        </View>
      </Page>
    </Document>
  );
};

export default GeneratedSummaryAgingReportPdfOld;
