/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { isNil } from 'lodash';
import {
  type ShipmentSubscriberUpsertInput,
  type StandardShipmentFragment,
} from '../../../../generated/graphql';
import { type RootState } from '../../../../redux/store';
import {
  selectShipmentSubscriberValuesById,
  upsertOneShipmentSubscriberValues,
} from './shipment-subscribers-values-slice';

type CreateShipmentSubscriberUpsertInputArg = {
  shipmentSubscriberUuid: string;
};

export const createShipmentSubscriberUpsertInput = createAsyncThunk<
  ShipmentSubscriberUpsertInput,
  CreateShipmentSubscriberUpsertInputArg,
  { state: RootState }
>(
  'shipmentSubscribers/createShipmentSubscriberUpsertInput',
  async (arg, thunkAPI): Promise<ShipmentSubscriberUpsertInput> => {
    const shipmentSubscriber = selectShipmentSubscriberValuesById(
      thunkAPI.getState(),
      arg.shipmentSubscriberUuid,
    );
    if (isNil(shipmentSubscriber)) {
      throw new Error(
        `No shipment subscriber found with the UUID: ${arg.shipmentSubscriberUuid}`,
      );
    }
    return {
      uuid: shipmentSubscriber.uuid,
      email: shipmentSubscriber.email,
      phoneNumber: shipmentSubscriber.phone,
    };
  },
);

export const upsertShipmentSubscribersForShipment = createAsyncThunk<
  void,
  {
    shipment: StandardShipmentFragment;
  },
  { state: RootState }
>(
  'shipmentSubscribers/upsertShipmentSubscribersForShipment',
  async (arg, thunkAPI): Promise<void> => {
    const { shipment } = arg;
    if (!isNil(shipment.shipmentSubscribers)) {
      await Promise.all(
        shipment.shipmentSubscribers.map((shipmentSubscriber) =>
          thunkAPI.dispatch(
            upsertOneShipmentSubscriberValues({
              uuid: shipmentSubscriber.uuid,
              email: shipmentSubscriber.email?.toLowerCase() ?? undefined,
            }),
          ),
        ),
      );
    }
  },
);
