import React from 'react';
import { PLTRadioGroup } from '../../../common/components/forms/radio-group';

const AppointmentTimeFormatDisplayField = ({
  useMinimizedAppointmentTime,
  onChange,
}: {
  readonly useMinimizedAppointmentTime: boolean;
  readonly onChange: (useMinimizedAppointmentTime: boolean) => void;
}) => {
  const options = [
    { value: false, label: 'HH:mm-HH:mm' },
    { value: true, label: 'HH:mm+1' },
  ];
  return (
    <PLTRadioGroup
      options={options}
      selectedValue={useMinimizedAppointmentTime}
      label="Appointment time format"
      subLabel="All times in military"
      onChange={(newValue) => {
        onChange(newValue);
      }}
    />
  );
};

export { AppointmentTimeFormatDisplayField };
