import { Add, RemoveCircleOutline } from '@mui/icons-material';
import {
  Stack,
  Box,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
  type SxProps,
} from '@mui/material';
import { type ReactNode, useMemo, Fragment } from 'react';
import SecondaryButton from '../../../../../common/components/SecondaryButton';
import {
  type SingleFilterConstructionType,
  FilterGroupOperator,
  type GroupFilterConstructionType,
  EMPTY_SINGLE_FILTER_CONSTRUCTION_TYPE,
} from '../../../../../common/filters/types';
import {
  isGroupFilterConstructionType,
  getFilterGroupOperator,
} from '../../../../../common/filters/utils';
import FilterModelSelect from './filter-model-select';

const commonSelectStyles: SxProps = {
  borderBottomRightRadius: 0,
  borderTopRightRadius: 0,
  width: '100%',
};

/** Dummy component to hide the select "dropdown" icon */
const emptyIconComponent = () => null;

type DisabledSelectProps = {
  readonly value: string;
};

const DisabledSelect = ({ value }: DisabledSelectProps) => (
  <Select
    disabled
    value={value}
    size="small"
    IconComponent={emptyIconComponent}
    sx={{
      ...commonSelectStyles,
      backgroundColor: (theme) => theme.palette.background.default,
    }}
  >
    <MenuItem value={value}>{value}</MenuItem>
  </Select>
);

type OrderTableFilterGroupProps = {
  readonly filterGroup: GroupFilterConstructionType;
  readonly setFilterGroup: (updatedGroup: GroupFilterConstructionType) => void;
};

/**
 * A group of individual filter inputs, a filter group operator selector, and
 * add/remove filter buttons
 */
const OrderTableFilterGroup = ({
  filterGroup,
  setFilterGroup,
}: OrderTableFilterGroupProps) => {
  const operator = getFilterGroupOperator(filterGroup);
  const filters: SingleFilterConstructionType[] = useMemo(
    () =>
      filterGroup[operator]?.filter(
        // TODO: eventually we'll allow deeper nesting
        (f): f is SingleFilterConstructionType =>
          !isGroupFilterConstructionType(f),
      ) ?? [],
    [filterGroup, operator],
  );

  const setOperator = (newOperator: FilterGroupOperator) => {
    setFilterGroup({ [newOperator]: filters });
  };

  const onAddFilter = () => {
    setFilterGroup({
      [operator]: [...filters, EMPTY_SINGLE_FILTER_CONSTRUCTION_TYPE],
    });
  };

  const onUpdateFilter = (
    filterIndex: number,
    updatedFilter: SingleFilterConstructionType,
  ) => {
    setFilterGroup({
      [operator]: filters.map((f, i) =>
        i === filterIndex ? updatedFilter : f,
      ),
    });
  };

  const onDeleteFilter = (filterIndex: number) => {
    if (filters.length === 1) {
      setFilterGroup({ [operator]: [EMPTY_SINGLE_FILTER_CONSTRUCTION_TYPE] });
    } else {
      setFilterGroup({
        [operator]: filters.filter((_, i) => i !== filterIndex),
      });
    }
  };

  const getLeftContent = (index: number): ReactNode => {
    if (index === 0) {
      return <DisabledSelect value="Where" />;
    }
    if (index === 1) {
      return (
        <Select<FilterGroupOperator>
          value={operator}
          size="small"
          sx={{ ...commonSelectStyles, backgroundColor: 'white' }}
          onChange={(e) => {
            setOperator(e.target.value as FilterGroupOperator);
          }}
        >
          <MenuItem value={FilterGroupOperator.AND}>and</MenuItem>
          <MenuItem value={FilterGroupOperator.OR}>or</MenuItem>
        </Select>
      );
    }
    return <DisabledSelect value={operator} />;
  };

  return (
    <Stack
      display="grid"
      rowGap={1}
      gridTemplateColumns="auto 1fr auto"
      alignItems="start"
      minWidth={0}
    >
      {filters.map((filter, index) => (
        // We allow multiple filters on the same field with the same operator,
        // e.g. "contains A" and "contains B", so we need the array index as part of the key
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={`${filter.filter}-${filter.op}-${index}`}>
          <Box>{getLeftContent(index)}</Box>
          <Box marginLeft="-1px">
            <FilterModelSelect
              filter={filter}
              setFilter={(updatedFilter) => {
                onUpdateFilter(index, updatedFilter);
              }}
            />
          </Box>
          <Tooltip title="Remove filter" placement="left">
            <IconButton
              size="small"
              sx={{ marginLeft: 1 }}
              onClick={() => {
                onDeleteFilter(index);
              }}
            >
              <RemoveCircleOutline />
            </IconButton>
          </Tooltip>
        </Fragment>
      ))}
      <Box gridColumn="1 / -1">
        <SecondaryButton
          variant="outlined"
          startIcon={<Add />}
          onClick={onAddFilter}
        >
          Add
        </SecondaryButton>
      </Box>
    </Stack>
  );
};

export { OrderTableFilterGroup };
