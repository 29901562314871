import {
  Filter1,
  Filter2,
  Filter3,
  Filter4,
  Filter5,
  Filter6,
  Filter7,
  Filter8,
  Filter9,
  Filter9Plus,
  FilterNone,
} from '@mui/icons-material';
import { type SvgIconProps } from '@mui/material';

type NumberedFilterIconProps = {
  readonly num: number;
} & SvgIconProps;
const NumberedFilterIcon = ({ num, ...props }: NumberedFilterIconProps) => {
  if (num === 0) {
    return <FilterNone {...props} />;
  }
  if (num === 1) {
    return <Filter1 {...props} />;
  }
  if (num === 2) {
    return <Filter2 {...props} />;
  }
  if (num === 3) {
    return <Filter3 {...props} />;
  }
  if (num === 4) {
    return <Filter4 {...props} />;
  }
  if (num === 5) {
    return <Filter5 {...props} />;
  }
  if (num === 6) {
    return <Filter6 {...props} />;
  }
  if (num === 7) {
    return <Filter7 {...props} />;
  }
  if (num === 8) {
    return <Filter8 {...props} />;
  }
  if (num === 9) {
    return <Filter9 {...props} />;
  }
  return <Filter9Plus {...props} />;
};

export default NumberedFilterIcon;
