import { isEmpty, isNil } from 'lodash';
import { type Resolver, useForm } from 'react-hook-form';
import {
  AutoApplyAccessorialRuleType,
  PickupOrDelivery,
} from '../../../generated/graphql';

export type AddAutoApplyAccessorialRuleFormValues = {
  accessorialUuids: string[];

  ruleType: AutoApplyAccessorialRuleType;

  serviceUuid: string | null;

  stopType: PickupOrDelivery;

  contactUuid: string | null;

  terminalUuid: string | null;

  tagUuid: string | null;

  contactsToIncludeUuids: string[];

  contactsToExcludeUuids: string[];
};

const resolver: Resolver<AddAutoApplyAccessorialRuleFormValues> = async (
  formValues,
) => {
  const errors: {
    [property in keyof AddAutoApplyAccessorialRuleFormValues]?: {
      type: string;
      message: string;
    };
  } = {};

  if (formValues.accessorialUuids.length === 0) {
    errors.accessorialUuids = {
      type: 'required',
      message: 'Accessorial is required',
    };
  }

  if (isEmpty(formValues.ruleType)) {
    errors.ruleType = {
      type: 'required',
      message: 'Rule type is required',
    };
  }

  if (
    (formValues.ruleType === AutoApplyAccessorialRuleType.Service ||
      formValues.ruleType === AutoApplyAccessorialRuleType.ContactAndService ||
      formValues.ruleType === AutoApplyAccessorialRuleType.ServiceAndTag ||
      formValues.ruleType ===
        AutoApplyAccessorialRuleType.ContactAndServiceAndTag) &&
    isNil(formValues.serviceUuid)
  ) {
    errors.serviceUuid = {
      type: 'required',
      message: 'Service is required',
    };
  }

  if (
    (formValues.ruleType === AutoApplyAccessorialRuleType.StopType ||
      formValues.ruleType ===
        AutoApplyAccessorialRuleType.StopTypeAndTerminal) &&
    isNil(formValues.stopType)
  ) {
    errors.stopType = {
      type: 'required',
      message: 'Stop type is required',
    };
  }

  if (
    (formValues.ruleType === AutoApplyAccessorialRuleType.Contact ||
      formValues.ruleType === AutoApplyAccessorialRuleType.ContactAndService ||
      formValues.ruleType ===
        AutoApplyAccessorialRuleType.ContactAndServiceAndTag) &&
    isNil(formValues.contactUuid)
  ) {
    errors.contactUuid = {
      type: 'required',
      message: 'Contact is required',
    };
  }

  if (
    formValues.ruleType === AutoApplyAccessorialRuleType.StopTypeAndTerminal &&
    isNil(formValues.terminalUuid)
  ) {
    errors.terminalUuid = {
      type: 'required',
      message: 'Terminal is required',
    };
  }

  if (
    (formValues.ruleType ===
      AutoApplyAccessorialRuleType.ContactAndServiceAndTag ||
      formValues.ruleType === AutoApplyAccessorialRuleType.ServiceAndTag) &&
    isNil(formValues.tagUuid)
  ) {
    errors.tagUuid = {
      type: 'required',
      message: 'Tag is required',
    };
  }

  return {
    values: formValues,
    errors,
  };
};

const useAddAutoApplyAccessorialRuleForm = () => {
  return useForm({
    resolver,
    defaultValues: {
      accessorialUuids: [],
      ruleType: AutoApplyAccessorialRuleType.Global,
      serviceUuid: null,
      stopType: PickupOrDelivery.Delivery,
      contactUuid: null,
      terminalUuid: null,
      tagUuid: null,
      contactsToIncludeUuids: [],
      contactsToExcludeUuids: [],
    },
  });
};

export default useAddAutoApplyAccessorialRuleForm;
