import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, Menu, MenuItem, MenuList, Stack } from '@mui/material';
import { isNil } from 'lodash';
import { memo, useState } from 'react';
import { type LineHaulOrderFragment } from '../../../../../generated/graphql';
import useLineHaulDispatchActions from '../../../hooks/use-line-haul-dispatch-actions';

const ManifestOrdersContextMenu = ({
  manifestUuid,
  selectedOrders,
}: {
  readonly manifestUuid: string | undefined;
  readonly selectedOrders: LineHaulOrderFragment[];
}) => {
  const [dropdownMenuRef, setDropdownMenuRef] = useState<null | HTMLElement>(
    null,
  );
  const dropdownMenuOpen = Boolean(dropdownMenuRef);

  const { removeMultipleOrdersFromManifest } = useLineHaulDispatchActions();

  const closeDropdownMenu = () => {
    setDropdownMenuRef(null);
  };
  if (selectedOrders.length === 0 || isNil(manifestUuid)) {
    return null;
  }
  return (
    <>
      <Stack direction="row" spacing={1} alignItems="center">
        <Button
          color="info"
          endIcon={<ArrowDropDownIcon />}
          onClick={(e) => {
            setDropdownMenuRef(e.currentTarget);
          }}
        >
          Edit ({selectedOrders.length})
        </Button>
      </Stack>
      <Menu
        anchorEl={dropdownMenuRef}
        open={dropdownMenuOpen}
        onClose={closeDropdownMenu}
      >
        <MenuList dense sx={{ py: 0 }}>
          {/* <MenuItem
            onClick={() => {
              closeDropdownMenu();
            }}
          >
            Move orders
          </MenuItem> */}
          <MenuItem
            onClick={() => {
              closeDropdownMenu();
              removeMultipleOrdersFromManifest({
                uuid: manifestUuid,
                orderUuids: selectedOrders.map((o) => o.uuid),
              });
            }}
          >
            Remove from this manifest
          </MenuItem>
          {/* <MenuItem
            onClick={() => {
              closeDropdownMenu();
            }}
          >
            Postpone linehaul date
          </MenuItem> */}
        </MenuList>
      </Menu>
    </>
  );
};

export default memo(ManifestOrdersContextMenu);
