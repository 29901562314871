import { type ColDef } from 'ag-grid-community';
import { isNil } from 'lodash';
import { useMemo } from 'react';
import { shallow } from 'zustand/shallow';
import { FeatureFlag } from '../../../common/feature-flags';
import useDispatchTableColors from '../../../common/react-hooks/use-dispatch-table-colors';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import { useTableFields } from '../../../common/react-hooks/use-table-fields';
import useDispatchStore from '../dispatch-store';
import {
  getDefaultColumns,
  getDisplayColumns,
  getEtaColumn,
  getOrderHawbColumn,
} from '../routes/route-card-stops-list-columns';
import { useDispatchViewSettings } from './use-dispatch-view-settings';

const useRouteCardAgGridColumns = ({
  isMapView,
  locked,
}: {
  isMapView: boolean;
  locked: boolean;
}): ColDef[] => {
  const { dispatchTableColors } = useDispatchTableColors();
  const { useMinimizedAppointmentTime, routeCardStopTableFields } =
    useDispatchViewSettings();
  const { dispatchTableFields } = useTableFields();
  const ffCustomizableRouteColumns = useFeatureFlag(
    FeatureFlag.FF_CUSTOMIZABLE_ROUTE_COLUMNS,
  );
  const ffInboundOutboundPaperworkColumns = useFeatureFlag(
    FeatureFlag.FF_INBOUND_OUTBOUND_PAPERWORK_COLUMNS,
  );
  const [setOpenedOrderUuid] = useDispatchStore(
    (state) => [state.setOpenedOrderUuid],
    shallow,
  );

  return useMemo<ColDef[]>(() => {
    const columns = getDefaultColumns({
      dispatchTableColors,
      isMapView,
      locked,
    });

    const optionalColumns = [
      getEtaColumn(),
      getOrderHawbColumn(setOpenedOrderUuid),
      ...getDisplayColumns(
        useMinimizedAppointmentTime,
        ffInboundOutboundPaperworkColumns,
      ),
    ];

    const fieldsToInclude = ffCustomizableRouteColumns
      ? (routeCardStopTableFields ?? dispatchTableFields ?? [])
      : (dispatchTableFields ?? []);
    for (const field of fieldsToInclude) {
      const column = optionalColumns.find((c) => c.field === field);
      if (!isNil(column)) {
        columns.push(column);
      }
    }

    return columns;
  }, [
    isMapView,
    ffCustomizableRouteColumns,
    routeCardStopTableFields,
    dispatchTableFields,
    setOpenedOrderUuid,
    useMinimizedAppointmentTime,
    dispatchTableColors,
    locked,
    ffInboundOutboundPaperworkColumns,
  ]);
};

export { useRouteCardAgGridColumns };
