import PostAddIcon from '@mui/icons-material/PostAdd';
import { Stack, Badge, Typography, Button, useTheme } from '@mui/material';
import { shallow } from 'zustand/shallow';
import useGlobalStore from '../global-store';

export default function NavbarAddOrderItem() {
  const [setShowBillingPartyDialog] = useGlobalStore(
    (state) => [state.setShowBilingPartyDialog],
    shallow,
  );
  const { palette } = useTheme();
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      marginTop="4px"
      paddingX="8px"
    >
      <Stack direction="row" gap={1}>
        <Button
          sx={{
            justifyContent: 'flex-start',
            width: '100%',
            alignItems: 'center',
            padding: '0px',
          }}
          onClick={() => {
            setShowBillingPartyDialog(true);
          }}
        >
          <Badge
            showZero={false}
            sx={{
              '& .MuiBadge-badge': {
                color: palette.concreteGrey[50],
              },
            }}
          >
            <PostAddIcon
              sx={{
                color: palette.concreteGrey[50],
                width: 16,
                height: 16,
              }}
            />
          </Badge>
          <Typography
            variant="caption"
            color={palette.concreteGrey[10]}
            sx={{ marginLeft: '8px' }}
            fontSize="14px"
          >
            Add order
          </Typography>
        </Button>
      </Stack>
    </Stack>
  );
}
