import { Button, Stack } from '@mui/material';

const EditTariffButton = ({
  clickHandler,
  disabled,
}: // createLinkedTariffHandler,
{
  readonly clickHandler: () => void;
  readonly disabled?: boolean;
  // createLinkedTariffHandler: () => void;
}) => {
  //   const dispatch = useAppDispatch();
  //   const { allowOpenInNewTab } = useAllowOpenInNewTab();
  // const [buttonRef, setButtonRef] = useState<HTMLButtonElement | null>(null);
  // const [showContextMenu, setShowContextMenu] = useState(false);

  // const onClose = () => {
  //   setShowContextMenu(false);
  // };

  return (
    <Stack direction="row">
      <Button variant="contained" disabled={disabled} onClick={clickHandler}>
        Edit
      </Button>
      {/* <IconButton
        sx={{ padding: 0, mr: '10px' }}
        size="small"
        onClick={(e) => {
          setButtonRef(e.currentTarget);
          setShowContextMenu(true);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      {showContextMenu && (
        <Menu
          anchorEl={buttonRef}
          open={showContextMenu}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{
            style: {
              transform: 'translateY(15%)',
            },
          }}
        >
          <MenuItem onClick={createLinkedTariffHandler}>
            Create linked tariff
          </MenuItem>
        </Menu>
      )} */}
    </Stack>
  );
};

export default EditTariffButton;
