import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import OrderTableFunctionButton from './order-table-function-button';

type OrderTableFunctionsMenuProps = {
  readonly disabled: boolean;
  readonly onExportAllOrdersInView: () => Promise<void>;
  readonly shouldShowGenerateReportButtons?: boolean;
};

const OrderTableFunctionsMenu = ({
  disabled,
  onExportAllOrdersInView,
  shouldShowGenerateReportButtons,
}: OrderTableFunctionsMenuProps) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);

  return (
    <>
      <OrderTableFunctionButton
        size="small"
        variant="outlined"
        disabled={disabled}
        onClick={(e) => {
          setMenuAnchorEl(e.currentTarget);
        }}
      >
        <MoreHorizIcon fontSize="small" />
      </OrderTableFunctionButton>
      <Menu
        anchorEl={menuAnchorEl}
        id="customer-menu"
        open={Boolean(menuAnchorEl)}
        onClose={() => {
          setMenuAnchorEl(null);
        }}
      >
        {shouldShowGenerateReportButtons === true && (
          <MenuItem disabled={disabled} onClick={onExportAllOrdersInView}>
            Export all orders in this view
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default OrderTableFunctionsMenu;
