import { Add } from '@mui/icons-material';
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil, values } from 'lodash';
import { useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { filterNotNilOrEmpty } from 'shared/array';
import {
  type ContactItemsItemFragment,
  StorageOrderDocument,
  type StorageOrderFragment,
  type StorageOrderStorageUnitFragment,
  UnitOfMeasure,
  useUpsertItemGroupMutation,
} from '../../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import ItemFormModal from '../../../../items/item-form-modal';
import useAddItemForm, {
  type AddItemFormValues,
} from '../../../forms/inventory-items/use-add-item-form';

type AutocompleteOption = {
  label: string;
  value?: string;
  disabled?: boolean;
  createItem?: boolean;
};
type AddItemFormProps = {
  readonly storageOrder: StorageOrderFragment;
  readonly storageUnit: StorageOrderStorageUnitFragment;
  readonly items: ContactItemsItemFragment[];
};
const AddItemForm = ({
  storageOrder,
  storageUnit,
  items,
}: AddItemFormProps) => {
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useAddItemForm();
  const [upsertItemGroup, { loading: upsertItemGroupLoading }] =
    useUpsertItemGroupMutation({
      refetchQueries: [StorageOrderDocument],
      awaitRefetchQueries: true,
    });
  const [showItemFormModal, setShowItemFormModal] = useState(false);

  const onSubmit = async ({
    item,
    quantity,
    unitOfMeasure,
  }: AddItemFormValues) => {
    if (quantity === '') return;
    await upsertItemGroup({
      variables: {
        upsertItemGroupInput: {
          storageOrderUuid: storageOrder.uuid,
          storageUnitUuid: storageUnit.uuid,
          itemUuid: item.uuid,
          quantity,
          unitOfMeasure,
        },
      },
    });
    reset({
      quantity: '',
      unitOfMeasure,
    });
  };
  const selectedItem = watch('item');

  const itemOptions = useMemo(() => {
    return items.map((item) => ({
      label: filterNotNilOrEmpty([item.sku, item.description]).join(' | '),
      value: item.uuid,
      disabled: storageUnit.items.some(
        (unitItem) => unitItem.item.uuid === item.uuid,
      ),
    }));
  }, [items, storageUnit.items]);

  return (
    <>
      <Stack width="100%">
        <Stack
          direction="row"
          width="100%"
          maxWidth="100%"
          alignItems="end"
          gap={1}
        >
          <Controller
            name="item"
            control={control}
            render={({ field: { value, onChange, ...props } }) => (
              <AutocompleteFuzzy<AutocompleteOption>
                fullWidth
                options={itemOptions}
                stickyOptionProps={{
                  stickyOption: {
                    label: 'Create new item',
                    createItem: true,
                  },
                  filterStickyOption: false,
                }}
                matchSortOptions={{ keys: ['label'] }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select an item"
                    error={!isNil(errors.item)}
                  />
                )}
                renderOption={(
                  optionProps,
                  { label, value: key, disabled = false, createItem },
                ) => {
                  if (!isNil(createItem)) {
                    return (
                      <MenuItem {...optionProps}>
                        <Stack direction="row" alignItems="center" gap={1}>
                          <Add />
                          <Typography>Create new item</Typography>
                        </Stack>
                      </MenuItem>
                    );
                  }
                  return (
                    <Tooltip
                      key={key}
                      title={
                        disabled ? 'This item is already on the container' : ''
                      }
                      placement="bottom-start"
                    >
                      <div>
                        {' '}
                        {/* Wrapping in div to display tooltip over disabled MenuItem */}
                        <MenuItem
                          sx={{ whiteSpace: 'normal' }}
                          {...optionProps}
                          disabled={disabled}
                        >
                          {label}
                        </MenuItem>
                      </div>
                    </Tooltip>
                  );
                }}
                size="small"
                value={
                  isNil(value)
                    ? null
                    : {
                        label: [value.sku, value.description].join(' | '),
                        value: value.uuid,
                      }
                }
                sx={{ mt: 1 }}
                onChange={(_, val) => {
                  if (val?.disabled === true) return;
                  if (!isNil(val) && 'createItem' in val) {
                    setShowItemFormModal(true);
                    return;
                  }
                  onChange(items.find((item) => item.uuid === val?.value));
                }}
                {...props}
              />
            )}
          />
          <Controller
            name="unitOfMeasure"
            control={control}
            render={({ field: { value, onChange, ...props } }) => (
              <FormControl size="small" sx={{ minWidth: 100 }}>
                <InputLabel>UoM</InputLabel>
                <Select
                  required
                  label="UoM"
                  size="small"
                  value={value}
                  error={!isNil(errors.unitOfMeasure)}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  {...props}
                >
                  {values(UnitOfMeasure).map((uom) => {
                    return (
                      <MenuItem key={uom} value={uom}>
                        {sentenceCase(uom)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
          />
          <Controller
            name="quantity"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                name="quantity"
                label="Qty"
                type="number"
                size="small"
                value={value ?? undefined}
                error={!isNil(errors.quantity)}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
              />
            )}
          />
          <Button
            variant="outlined"
            disabled={isNil(selectedItem) || upsertItemGroupLoading}
            onClick={handleSubmit(onSubmit)}
          >
            Add
          </Button>
        </Stack>
        {values(errors).map((error) => (
          <FormHelperText key={error?.message} error>
            {error?.message}
          </FormHelperText>
        ))}
      </Stack>
      <ItemFormModal
        contactUuid={storageOrder.contact.uuid}
        open={showItemFormModal}
        warehouseOverride={{
          label: storageOrder.warehouse.name,
          value: storageOrder.warehouse.uuid,
        }}
        onClose={() => {
          setShowItemFormModal(false);
        }}
        onCreateItem={(item) => {
          setValue('item', {
            uuid: item.uuid,
            sku: item.sku,
            description: item.description ?? '',
          });
        }}
      />
    </>
  );
};

export default AddItemForm;
