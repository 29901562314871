import ModeIcon from '@mui/icons-material/Mode';
import {
  TextField,
  Typography,
  IconButton,
  useTheme,
  Stack,
} from '@mui/material';
import pluralize from 'pluralize';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { type OrderFormValues } from '../../../forms/types';
import { type OrderShipmentContext } from '../../../types';
import { contextToNamePrefix } from '../../../utils';

const MilesComponent = ({
  context,
  disabled,
  shouldSetFreightChargeQuantity,
}: {
  readonly context: OrderShipmentContext;
  readonly disabled?: boolean;
  readonly shouldSetFreightChargeQuantity: boolean;
}) => {
  const namePrefix = contextToNamePrefix(context);

  const [mileInput, setMileInput] = useState<string>('');
  const { control, setValue } = useFormContext<OrderFormValues>();
  const theme = useTheme();
  const [isEditing, setIsEditing] = useState(false);
  const miles = useWatch({ control, name: `${namePrefix}.miles` });

  useEffect(() => {
    setMileInput(miles?.toString() ?? '');
  }, [miles]);

  const onSaveMiles = () => {
    setIsEditing(false);
    let value: number | null = null;
    const parsedFloat = Number.parseFloat(mileInput);
    if (!Number.isNaN(parsedFloat)) {
      value = parsedFloat;
    }

    setValue(`${namePrefix}.miles`, value);
    if (shouldSetFreightChargeQuantity) {
      setValue(`${namePrefix}.freightCharge.quantity`, value);
    }
  };

  return (
    <Stack
      direction="row"
      spacing={0.5}
      sx={{
        color: theme.palette.grey[500],
      }}
      alignItems="center"
    >
      {isEditing ? (
        <TextField
          size="small"
          value={mileInput}
          variant="standard"
          label="Miles"
          sx={{ fontSize: '10px' }}
          disabled={disabled}
          onChange={(e) => {
            setMileInput(e.target.value);
          }}
          onBlur={() => {
            onSaveMiles();
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onSaveMiles();
            }
          }}
        />
      ) : (
        <>
          <Typography>
            {miles?.toString() ?? '-'} {pluralize('mile', miles ?? 0)}
          </Typography>
          {disabled !== true && (
            <IconButton
              sx={{ p: 0.5 }}
              onClick={() => {
                setIsEditing(true);
              }}
            >
              <ModeIcon
                sx={{ color: theme.palette.grey[500], fontSize: '16px' }}
              />
            </IconButton>
          )}
        </>
      )}
    </Stack>
  );
};

export default MilesComponent;
