import { isNil } from 'lodash';
import { shallow } from 'zustand/shallow';
import {
  NumberOfStopsDocument,
  RoutesDocument,
  useCreateRouteSlotMutation,
  useReassignRouteSlotMutation,
} from '../../../generated/graphql';
import useDispatchStore from '../dispatch-store';
import useFetchRoutes from './use-fetch-routes';

const useAssignStopsRouteActions = () => {
  const [setRoute] = useDispatchStore((state) => [state.setRoute], shallow);
  const { fetchRoute } = useFetchRoutes();
  const [createRouteSlot] = useCreateRouteSlotMutation({
    refetchQueries: [RoutesDocument, NumberOfStopsDocument],
  });
  const [reassignRouteSlot] = useReassignRouteSlotMutation();

  const assignStop = async ({
    routeUuid,
    stopUuids,
    refetch = false,
    emitMultiplayerEvent = false,
  }: {
    routeUuid: string;
    stopUuids: string[];
    refetch?: boolean;
    emitMultiplayerEvent?: boolean;
  }) => {
    const res = await createRouteSlot({
      variables: {
        routeSlotCreateInput: {
          drayageStopUuids: stopUuids,
          emitMultiplayerEvent,
        },
        routeUuid,
      },
    });
    if (refetch) {
      await fetchRoute(routeUuid);
    }
    return res.data?.createRouteSlot;
  };

  const reassignStop = async ({
    routeUuid,
    slotUuid,
    endIndex,
  }: {
    routeUuid: string;
    slotUuid: string;
    endIndex?: number;
  }) => {
    const res = await reassignRouteSlot({
      variables: {
        routeSlotReassignInput: {
          slotUuid,
          routeUuid,
          endIndex,
        },
      },
    });
    const updatedRoutes = res.data?.reassignRouteSlot;
    if (updatedRoutes)
      for (const updatedRoute of updatedRoutes) {
        setRoute(updatedRoute);
      }
    return isNil(res.errors);
  };

  return {
    assignStop,
    reassignStop,
  };
};

export default useAssignStopsRouteActions;
