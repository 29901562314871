import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Modal,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { type FunctionComponent, useEffect, useState } from 'react';
import { v4 } from 'uuid';
import {
  useDriverQualificationsQuery,
  useUpdateDriverQualificationsMutation,
} from '../../../../generated/graphql';

const styles = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '460px',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
  },
};

type DriverQualificationsFormElement = {
  // id must be a UUID.
  id: string;
  name: string;
};

type DriverQualificationsModalProps = {
  readonly open: boolean;
  readonly onClose: () => void;
};

const DriverQualificationsModal: FunctionComponent<
  DriverQualificationsModalProps
> = ({ open, onClose }) => {
  const { data, loading } = useDriverQualificationsQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [update, { loading: saving }] = useUpdateDriverQualificationsMutation({
    fetchPolicy: 'network-only',
  });
  const [driverQualifications, setDriverQualifications] = useState<
    DriverQualificationsFormElement[]
  >([]);
  const [showSavedSnackbar, setShowSavedSnackbar] = useState(false);
  useEffect(() => {
    setDriverQualifications([
      ...(data?.driverQualifications.map(
        ({ __typename: _ignored, ...rest }) => rest,
      ) ?? []),
      {
        id: v4(),
        name: '',
      },
    ]);
  }, [data]);

  const onSave = () => {
    update({
      variables: {
        input: {
          driverQualifications: driverQualifications.filter(
            ({ name }) => name.trim() !== '',
          ),
        },
      },
      onCompleted: () => {
        onClose();
        setShowSavedSnackbar(true);
      },
    });
  };

  return (
    <>
      <Snackbar
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showSavedSnackbar}
        onClose={() => {
          setShowSavedSnackbar(false);
        }}
      >
        <Alert severity="success">Driver qualifications saved</Alert>
      </Snackbar>
      <Modal open={open} onClose={onClose}>
        <Box sx={styles.modal}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            p={2}
          >
            <Typography variant="h4" fontSize="20px" lineHeight={1}>
              Driver qualifications
            </Typography>
            <IconButton
              disabled={saving}
              style={{ width: '30px', height: '30px' }}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider />
          <Box p={2} maxHeight="80vh" overflow="auto">
            <Typography
              variant="caption"
              color="text.secondary"
              display="block"
              mb={2}
            >
              Configure the professional licenses, qualifications, or skills a
              driver may need to fulfill an order.
            </Typography>
            {loading ? (
              <Box display="flex" justifyContent="center" py={3}>
                <CircularProgress />
              </Box>
            ) : (
              <Stack gap={2}>
                {driverQualifications.map(({ id, name }, index) => (
                  <Stack key={id} direction="row" alignItems="center" gap={1}>
                    <TextField
                      size="small"
                      style={{ flexGrow: 1 }}
                      placeholder="Add a qualification"
                      value={name}
                      onChange={({ target }) => {
                        setDriverQualifications((prev) => {
                          const newArray = prev.map((q) =>
                            q.id === id ? { ...q, name: target.value } : q,
                          );
                          if (index === prev.length - 1) {
                            newArray.push({ id: v4(), name: '' });
                          }
                          return newArray;
                        });
                      }}
                    />
                    {index < driverQualifications.length - 1 && (
                      <IconButton
                        style={{ width: '30px', height: '30px' }}
                        onClick={() => {
                          setDriverQualifications((prev) =>
                            prev.filter((q) => q.id !== id),
                          );
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Stack>
                ))}
              </Stack>
            )}
          </Box>
          <Divider />
          <Stack direction="row" justifyContent="flex-end" p={2} gap={2}>
            <Button
              variant="text"
              color="inherit"
              disabled={saving}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={loading || saving}
              onClick={onSave}
            >
              Save
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default DriverQualificationsModal;
