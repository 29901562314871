import { isNil } from 'lodash';
import { useMemo } from 'react';
import useMe from '../../../../../common/react-hooks/use-me';
import {
  type FilterViewPage,
  type SavedFilterViewFragment,
} from '../../../../../generated/graphql';
import { type DefaultFilterTabsConfigs } from '../../types';
import { type ViewTab } from './all-views-button';
import ViewTabOverflowMenu from './view-tab-overflow-menu';

type UseViewTabsParams<T> = {
  shouldAllowSavedViews: boolean;
  savedViews: SavedFilterViewFragment[] | undefined;
  tabOrder: Array<string | T>;
  defaultFilterTabsConfigs: DefaultFilterTabsConfigs<T>;
  tabsNumberOfOrdersData?:
    | Array<{
        tab: T;
        numberOfOrders: number | undefined;
      }>
    | undefined;
  handleSetIsViewShared: (viewUuid: string, isShared: boolean) => void;
  handleDeleteSavedView: (viewUuid: string) => void;
  handleEditSavedViewName: (viewUuid: string, newName: string) => void;
  pageType: FilterViewPage;
};

const useViewTabs = <T,>({
  shouldAllowSavedViews,
  savedViews,
  tabOrder,
  defaultFilterTabsConfigs,
  tabsNumberOfOrdersData,
  handleSetIsViewShared,
  handleDeleteSavedView,
  handleEditSavedViewName,
  pageType,
}: UseViewTabsParams<T>) => {
  const { userUuid } = useMe();

  const defaultViewTabs: Array<ViewTab<T>> = useMemo(
    () =>
      defaultFilterTabsConfigs.tabs.map((tab) => {
        const numberOfOrders = tabsNumberOfOrdersData?.find(
          (data) => data.tab === tab.value,
        )?.numberOfOrders;
        return {
          label: `${tab.label}${numberOfOrders === undefined ? '' : ` (${numberOfOrders})`}`,
          dropdownLabel: tab.label,
          value: tab.value,
          testId: tab.dataTestId,
        };
      }),
    [defaultFilterTabsConfigs.tabs, tabsNumberOfOrdersData],
  );

  const savedViewTabs: Array<ViewTab<T>> = useMemo(() => {
    if (!shouldAllowSavedViews || isNil(savedViews) || isNil(userUuid)) {
      return [];
    }
    return savedViews.map((view) => ({
      label: view.displayName,
      value: view.uuid,
      renderOverflowMenu: (anchorEl, onClose) => (
        <ViewTabOverflowMenu
          anchorEl={anchorEl}
          view={view}
          userUuid={userUuid}
          pageType={pageType}
          handleSetIsViewShared={(isShared) => {
            handleSetIsViewShared(view.uuid, isShared);
          }}
          handleDeleteSavedView={() => {
            handleDeleteSavedView(view.uuid);
          }}
          handleEditSavedViewName={(newName) => {
            handleEditSavedViewName(view.uuid, newName);
          }}
          onClose={onClose}
        />
      ),
    }));
  }, [
    shouldAllowSavedViews,
    savedViews,
    pageType,
    userUuid,
    handleDeleteSavedView,
    handleSetIsViewShared,
    handleEditSavedViewName,
  ]);

  const viewTabs: Array<ViewTab<T>> = useMemo(() => {
    const unorderedTabs = [...defaultViewTabs, ...savedViewTabs];
    return unorderedTabs.sort((a, b) => {
      const indexA = tabOrder.indexOf(a.value);
      const indexB = tabOrder.indexOf(b.value);

      // If both tabs are in tabOrder, sort by their index
      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
      }

      // If only one tab is in tabOrder, prioritize it
      if (indexA !== -1) return -1;
      if (indexB !== -1) return 1;

      // If neither tab is in tabOrder, maintain their original order
      return unorderedTabs.indexOf(a) - unorderedTabs.indexOf(b);
    });
  }, [defaultViewTabs, savedViewTabs, tabOrder]);

  return viewTabs;
};

export default useViewTabs;
