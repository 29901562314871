import { Delete as DeleteIcon } from '@mui/icons-material';
import {
  Menu,
  MenuList,
  MenuItem,
  Divider,
  ListItemIcon,
  Tooltip,
  Stack,
} from '@mui/material';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  type FilterViewPage,
  PermissionResource,
} from '../../../generated/graphql';
import { useNewTableFunctionsFeatureFlag } from '../../ag-grid/use-new-table-functions-feature-flag';

const getDisabledCopy = (action: 'rename' | 'share' | 'delete') => {
  return `You can only ${action} views you created`;
};

const styles = {
  menuItem: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'visible',
    p: '10px',
  },
};

type EditSavedViewMenuProps = {
  readonly anchorEl: HTMLElement | null;
  readonly isViewShared: boolean;
  readonly isUserViewCreator: boolean;
  readonly pageType: FilterViewPage;
  /**
   * Don't propagate the click event to the parent elements.
   * Useful when the menu is inside another menu that should remain open.
   */
  readonly shouldStopPropagation?: boolean;
  readonly handleCloseMenu: () => void;
  readonly handleClickRename: () => void;
  readonly handleSetIsViewShared: (isViewShared: boolean) => void;
  readonly handleClickDelete: () => void;
};

export const EditSavedViewMenu = ({
  anchorEl,
  isViewShared,
  isUserViewCreator,
  pageType,
  shouldStopPropagation,
  handleCloseMenu,
  handleClickRename,
  handleSetIsViewShared,
  handleClickDelete,
}: EditSavedViewMenuProps) => {
  const { ffEnableNewTableFunctions } =
    useNewTableFunctionsFeatureFlag(pageType);

  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteSharedViews } = getPermissionsFlags(
    userPermissions,
    PermissionResource.SavedViews,
  );

  const canRenameView = ffEnableNewTableFunctions
    ? isUserViewCreator || (isViewShared && canWriteSharedViews)
    : true;

  const handleMenuItemClick = (e: React.MouseEvent<HTMLElement>) => {
    handleCloseMenu();
    if (shouldStopPropagation === true) {
      e.stopPropagation();
    }
  };
  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      sx={{
        marginTop: 1,
        minWidth: '200px',
      }}
      onClose={handleCloseMenu}
    >
      <MenuList sx={{ p: 0 }}>
        <Tooltip title={!canRenameView && getDisabledCopy('rename')}>
          {/* <span> is required to anchor the tooltip when the MenuItem is disabled */}
          <span>
            <MenuItem
              sx={styles.menuItem}
              disabled={!canRenameView}
              onClick={(e) => {
                handleClickRename();
                handleMenuItemClick(e);
              }}
            >
              Rename
            </MenuItem>
          </span>
        </Tooltip>
        {ffEnableNewTableFunctions && (
          <Tooltip title={!isUserViewCreator && getDisabledCopy('share')}>
            {/* <span> is required to anchor the tooltip when the MenuItem is disabled */}
            <span>
              <MenuItem
                sx={styles.menuItem}
                disabled={!isUserViewCreator}
                onClick={(e) => {
                  handleSetIsViewShared(!isViewShared);
                  handleMenuItemClick(e);
                }}
              >
                {isViewShared ? 'Unshare' : 'Share'}
              </MenuItem>
            </span>
          </Tooltip>
        )}
        <Divider />
        <Tooltip title={!isUserViewCreator && getDisabledCopy('delete')}>
          {/* <span> is required to anchor the tooltip when the MenuItem is disabled */}
          <span>
            <MenuItem
              sx={styles.menuItem}
              disabled={!isUserViewCreator}
              onClick={(e) => {
                handleClickDelete();
                handleMenuItemClick(e);
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                sx={{ color: (theme) => theme.palette.error.main }}
              >
                <ListItemIcon>
                  <DeleteIcon
                    sx={{ color: (theme) => theme.palette.error.main }}
                  />
                </ListItemIcon>
                Delete
              </Stack>
            </MenuItem>
          </span>
        </Tooltip>
      </MenuList>
    </Menu>
  );
};
