import dayjs from 'dayjs';
import { isNil, uniqBy } from 'lodash';
import { filterNotNil } from 'shared/array';
import { type PicklistsFragment } from '../../../../generated/graphql';

export const formatDate = (date: string | null | undefined) => {
  if (isNil(date)) return null;
  return dayjs(date).format('MM/DD/YYYY');
};
export const formatAppointmentTime = (
  start: string | null | undefined,
  end: string | null | undefined,
) => {
  if (isNil(start) && isNil(end)) return null;
  const range = filterNotNil([start, end]);
  return range.map((time) => dayjs(time).format('HH:mm')).join(' - ');
};

export const getUniqueContactsFromPicklist = (picklist: PicklistsFragment) => {
  return filterNotNil(
    uniqBy(
      picklist.picklistItemGroups.map(
        (pig) => pig.itemGroup.storageUnit?.storageOrder?.contact,
      ),
      'uuid',
    ),
  );
};
