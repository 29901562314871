import { isNil } from 'lodash';
import { createContext, useContext } from 'react';
import {
  getDisabledForEditAccess,
  type OrderFormEditAccess,
} from '../forms/use-order-form-edit-access';

const context = createContext<OrderFormEditAccess | undefined>(undefined);

type OrderFormEditAccessContext = {
  editAccess: OrderFormEditAccess;
} & ReturnType<typeof getDisabledForEditAccess>;

export const useOrderFormEditAccess = (): OrderFormEditAccessContext => {
  const editAccess = useContext(context);
  if (isNil(editAccess)) {
    throw new Error(
      'useOrderFormEditAccess must be used within an OrderFormEditAccessProvider',
    );
  }
  const disabledForEditAccess = getDisabledForEditAccess({ editAccess });
  return { editAccess, ...disabledForEditAccess };
};

export const OrderFormEditAccessProvider = context.Provider;
