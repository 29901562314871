import { Box, Typography } from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import React from 'react';
import { transformAddressToFullAddressString } from 'shared/copy';
import { useAllowOpenInNewTab } from '../../../../common/react-hooks/use-allow-open-in-new-tab';
import { transformTimeToTimeString } from '../../../../common/utils/prettyPrintUtils';
import { type FormattedStop } from '../utils/utils';

const GenerateStopInformationFullDetails = ({
  stop,
}: {
  readonly stop: FormattedStop;
}) => {
  const { allowOpenInNewTab } = useAllowOpenInNewTab();
  const totalWeight = stop?.packages?.reduce(
    (sum, stopPackage) => sum + (stopPackage?.weight ?? 0),
    0,
  );
  const totalPieces = stop?.packages?.reduce(
    (sum, stopPackage) => sum + (stopPackage?.quantity ?? 0),
    0,
  );
  return (
    <Box sx={{ width: '100%', overflow: 'scroll', maxHeight: '50vh' }}>
      <Box sx={{ textAlign: 'center' }}>
        <Typography
          sx={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={(e) => {
            allowOpenInNewTab(e, `/order-entry/${stop.orderId ?? ''}`);
          }}
        >
          View order
        </Typography>
      </Box>
      <Box>
        <Typography>
          {isNil(stop.pickupOrDelivery)
            ? ''
            : sentenceCase(stop.pickupOrDelivery)}{' '}
          {isNil(stop.stopType) ? '' : `-${sentenceCase(stop.stopType)}`}
        </Typography>
      </Box>
      <Box>
        <Typography>
          {isNil(stop.serviceName)
            ? ''
            : `Service Level: ${sentenceCase(stop.serviceName)}`}
        </Typography>
      </Box>
      <Box>
        <Typography>
          Address:{' '}
          {stop.address &&
            transformAddressToFullAddressString(stop.address, true)}
        </Typography>
      </Box>
      <Box>
        <Typography>
          Pieces: {totalPieces}, Weight: {totalWeight}
        </Typography>
      </Box>
      <Box>
        <Typography>
          {' '}
          {isNil(stop?.overrideServiceTimeInMinutes)
            ? ''
            : `Service time: ${stop.overrideServiceTimeInMinutes} minutes.`}{' '}
          {isNil(stop?.etaArrivalTime)
            ? ''
            : `Eta: ${transformTimeToTimeString(
                stop.etaArrivalTime,
              )}-${transformTimeToTimeString(stop.etaFinishTime)}`}
        </Typography>
      </Box>
      <Box>
        <Typography>
          {!isNil(stop?.specialInstructions) &&
          stop?.specialInstructions?.length > 0
            ? `Special instructions: \n ${stop.specialInstructions}`
            : ''}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography>Packages: </Typography>
        {stop.packages?.map((package_, idx) => (
          <Typography key={package_.uuid ?? idx}>
            {`Package ${idx + 1}: ${package_?.quantity} ${
              !isNil(package_.length) &&
              !isNil(package_.width) &&
              !isNil(package_.height)
                ? `x${package_.length}x${package_.width}x${package_.height}`
                : ''
            } - ${package_.description}`}
          </Typography>
        ))}
      </Box>
      <Box>
        <Typography>
          {!isNil(stop?.orderNotes) && stop?.orderNotes.length > 0
            ? `Notes: \n ${stop.orderNotes}`
            : ''}
        </Typography>
      </Box>
    </Box>
  );
};

export default GenerateStopInformationFullDetails;
