import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Divider,
  Stack,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import { type ColumnState } from 'ag-grid-community';
import { type AgGridReact } from 'ag-grid-react';
import { useState, type RefObject } from 'react';
import { isNilOrEmptyString } from 'shared/string';
import SecondaryButton from '../../../../../common/components/SecondaryButton';
import {
  type FormattedOrderFragment,
  type OrderTableField,
} from '../../../../../generated/graphql';
import SortRow, { SORT_ROW_HEIGHT } from './sort-row';

const AddSortContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 400px;
  padding: 16px;
`;

const ApplySortsContainer = styled(Box)`
  padding: 8px 16px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

const SortRowsByTypography = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  opacity: 0.6;
`;

const getColumnsWithAppliedSort = (
  gridRef: RefObject<AgGridReact<FormattedOrderFragment>>,
) => {
  return (
    gridRef.current?.columnApi
      ?.getColumnState()
      .filter((column) => column.sort) ?? []
  );
};

type SortPopoverProps = {
  readonly gridRef: RefObject<AgGridReact>;
  readonly orderTableFields: OrderTableField[];
  readonly onClose: () => void;
};

const SortPopover = ({
  gridRef,
  orderTableFields,
  onClose: onCloseParent,
}: SortPopoverProps) => {
  const [unappliedColumnState, setUnappliedColumnState] = useState<
    ColumnState[]
  >(getColumnsWithAppliedSort(gridRef));

  const createEmptyRow = () => {
    setUnappliedColumnState([
      ...unappliedColumnState,
      {
        colId: '',
        sort: 'asc',
      },
    ]);
  };

  const applySortsToGrid = () => {
    gridRef.current?.columnApi?.resetColumnState();
    gridRef.current?.columnApi?.applyColumnState({
      state: unappliedColumnState,
      applyOrder: true,
    });
  };

  const onApplyHandler: React.MouseEventHandler<HTMLButtonElement> = () => {
    applySortsToGrid();
    onCloseParent();
  };

  const isApplyDisabled = unappliedColumnState.some((columnState) =>
    isNilOrEmptyString(columnState.colId),
  );

  return (
    <>
      <AddSortContainer>
        <SortRowsByTypography>Sort rows by...</SortRowsByTypography>
        {unappliedColumnState.length > 0 && (
          <Stack gap={1}>
            {unappliedColumnState.map((state, index) => (
              <SortRow
                // We need to use index as key here because multiple rows can have undefined colId
                // eslint-disable-next-line react/no-array-index-key
                key={`${state.colId}-${index}`}
                columnState={state}
                setColumnState={setUnappliedColumnState}
                visibleColumns={orderTableFields}
                unappliedColumnState={unappliedColumnState}
                index={index}
              />
            ))}
          </Stack>
        )}
        <Box height={SORT_ROW_HEIGHT}>
          <SecondaryButton
            size="small"
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={createEmptyRow}
          >
            Add sort
          </SecondaryButton>
        </Box>
      </AddSortContainer>
      <Divider />
      <ApplySortsContainer>
        <Button variant="text" onClick={onCloseParent}>
          Close
        </Button>
        <Tooltip title={isApplyDisabled && 'Select a column to sort by'}>
          <span>
            <Button
              disabled={isApplyDisabled}
              variant="contained"
              onClick={onApplyHandler}
            >
              Apply
            </Button>
          </span>
        </Tooltip>
      </ApplySortsContainer>
    </>
  );
};

export default SortPopover;
