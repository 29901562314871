import { type FeatureFlag } from '../../common/feature-flags';

/**
 * By default all feature flags are disabled. To enable a feature flag, add it to the list below.
 * To easily toggle overrides while developing, you can run
 * git update-index --skip-worktree frontend/src/statsig/hooks/statsig-overrides.ts
 * to prevent git from tracking changes to this file.
 */
export default [
  // e.x. FeatureFlag.FF_NEW_FONT,
] satisfies FeatureFlag[];
