import { countBy, isEmpty } from 'lodash';
import { type DocumentType } from '../../generated/graphql';
import useDocuments from './use-documents';

type UsePaperworkCompleteCopyProps = {
  paperworkComplete: boolean;
  paperworkMarkedCompleteOverride: boolean | null;
  attachedDocuments: DocumentType[];
  missingDocuments: DocumentType[];
};

export const usePaperworkCompleteCopy = ({
  paperworkComplete,
  paperworkMarkedCompleteOverride,
  attachedDocuments,
  missingDocuments,
}: UsePaperworkCompleteCopyProps): string => {
  const { getDocumentTypeCopy, loading } = useDocuments();

  if (loading) {
    return '';
  }

  const missingDocumentsMessage =
    missingDocuments.length > 0
      ? `Documents missing: ${missingDocuments
          .map((docType) => getDocumentTypeCopy({ documentType: docType }))
          .join(', ')}`
      : '';

  if (paperworkMarkedCompleteOverride === true) {
    return 'Paperwork marked complete';
  }
  if (paperworkMarkedCompleteOverride === false) {
    let message = `Paperwork marked incomplete`;
    if (!isEmpty(missingDocumentsMessage)) {
      message = `${message}. ${missingDocumentsMessage}`;
    }
    return message;
  }
  if (paperworkComplete) {
    return 'Paperwork complete';
  }
  if (!isEmpty(missingDocumentsMessage)) {
    return missingDocumentsMessage;
  }
  if (!isEmpty(attachedDocuments)) {
    const attachedDocumentCounts = countBy(attachedDocuments);
    return `Uploaded documents: ${Object.entries(attachedDocumentCounts)
      .map(
        ([docType, count]) =>
          `${getDocumentTypeCopy({ documentType: docType as DocumentType })} (${count})`,
      )
      .join(', ')}`;
  }
  return 'No documents uploaded';
};
