import { isNil } from 'lodash';
import { isNilOrEmptyString } from 'shared/string';
import { z } from 'zod';
import { BillingMethod } from '../../../../../common/types';
import {
  DeadlineType,
  DriverType,
  InboundMethod,
  OutboundMethod,
  ShipmentStatus,
  ShipmentType,
  StandardStopType,
  StopStatus,
} from '../../../../../generated/graphql';
import {
  addressSchema,
  addressSchemaWithAllOptionalFields,
} from './address-schema';
import { contactPersonSchema } from './contact-person-schema';
import {
  getCustomChargeSchema,
  type CustomChargeSchemaOptions,
} from './custom-charge-schema';
import { documentSchema } from './document-schema';
import { freightChargeSchema } from './freight-charge-schema';
import { stopDriverMapSchema } from './stop-driver-map-schema';
import { StopType } from './stop-type';
import { zDateOrDatetimeString } from './zod-utils';

export type StopSchemaOptions = CustomChargeSchemaOptions & {
  ffRecoveryTransferAddressOnly: boolean;
};

export const getStopSchema = ({
  ffRecoveryTransferAddressOnly,
  accessorials,
  disallowZeroDollarCharges,
}: StopSchemaOptions) =>
  z
    .object({
      customCharges: z
        .array(
          getCustomChargeSchema({
            accessorials,
            disallowZeroDollarCharges,
          }),
        )
        .nullish(),
      destinationInInbound: z.boolean().nullish(),
      destinationInOutbound: z.boolean().nullish(),
      deadlineTime: zDateOrDatetimeString().nullish(),
      stopType: z.nativeEnum(StopType),
      inboundMethod: z.nativeEnum(InboundMethod).nullish(),
      outboundMethod: z.nativeEnum(OutboundMethod).nullish(),
      destinationAirport: z.string().nullish(),
      incomingCarrier: z.string().nullish(),
      standardStopType: z.nativeEnum(StandardStopType).nullable(),
      deadlineType: z.nativeEnum(DeadlineType).nullable(),
      deadlineDate: zDateOrDatetimeString().nullish(),
      deliveryDate: zDateOrDatetimeString().nullish(),
      appointmentTime: zDateOrDatetimeString().nullish(),
      endAppointmentTime: zDateOrDatetimeString().nullish(),
      completedAt: zDateOrDatetimeString().nullish(),
      arrivedAt: zDateOrDatetimeString().nullish(),
      equipmentNames: z.string().nullish(),
      specialInstructions: z.string().nullish(),
      proofOfDeliverySignee: z.string().nullish(),
      airportInfoUuid: z.string().uuid().nullish(),
      overridePackageWeight: z.boolean(),
      shouldUseDimWeight: z.boolean(),
      address: addressSchema.nullish(),
      contactPerson: contactPersonSchema.nullish(),
      shipperContactPerson: contactPersonSchema.nullish(),
      consigneeContactPerson: contactPersonSchema.nullish(),
      hideFromBilling: z.boolean(),
      hideFromDispatch: z.boolean().nullish(),
      status: z.nativeEnum(StopStatus),
      uuid: z.string().uuid(),
      legUuid: z.string().uuid(),
      miles: z.number().nullish(),
      shipmentUuid: z.string().uuid(),
      documents: z.array(documentSchema).nullish(),
      expectedInboundArrivalDate: zDateOrDatetimeString().nullish(),
      expectedOutboundDate: zDateOrDatetimeString().nullish(),
      outboundDeadlineDate: zDateOrDatetimeString().nullish(),
      inboundDeadlineDate: zDateOrDatetimeString().nullish(),
      outboundCarrier: z.string().nullish(),
      freightCharge: freightChargeSchema.nullish(),
      standardShipmentFieldsUuid: z.string().uuid().nullable(),
      isLocal: z.boolean(),
      driverName: z.string().nullish(),
      notes: z.string().nullish(),
      invoiceUuid: z.string().uuid().nullish(),
      invoiceName: z.string().nullish(),
      invoiceDate: zDateOrDatetimeString().nullish(),
      invoicePostedDate: zDateOrDatetimeString().nullish(),
      paperworkMarkedComplete: z.boolean().nullish(),
      isSpecial: z.boolean().optional(),
      routeUuid: z.string().uuid().nullish(),
      routeSlotUuid: z.string().uuid().nullish(),
      routeDate: zDateOrDatetimeString().nullish(),
      createdAt: zDateOrDatetimeString().nullish(),
      shipperAddress: addressSchemaWithAllOptionalFields.nullish(),
      consigneeAddress: addressSchemaWithAllOptionalFields.nullish(),
      transferAddress: addressSchemaWithAllOptionalFields.nullish(),
      terminalsEnabled: z.boolean().nullable(),
      terminalUuid: z.string().uuid().nullable(),
      serviceDate: zDateOrDatetimeString().nullish(),
      driverUuid: z.string().uuid().nullish(),
      removedDriver: z.boolean().nullish(),
      driverType: z.nativeEnum(DriverType).optional(),
      settlementTotal: z.number().optional(),
      settlementDeductionPercentageRate: z.number().optional(),
      settlementDeductionFlatRate: z.number().optional(),
      settlementBillingMethod: z.nativeEnum(BillingMethod).optional(),
      settlementName: z.string().nullish(),
      stopDriverMaps: z.array(stopDriverMapSchema).nullish(),
      appointmentConfirmed: z.boolean().nullish(),
      appointmentRequired: z.boolean().nullish(),
      shipmentStatus: z.nativeEnum(ShipmentStatus),
      shipmentType: z.nativeEnum(ShipmentType),
      totalCharge: z.number().nullish(),
    })
    .refine(
      (data) => {
        const { stopType, terminalsEnabled, address, terminalUuid } = data;

        if (terminalsEnabled !== true) {
          return true;
        }

        if (stopType === StopType.None) {
          return true;
        }

        const requiresTerminal =
          !isNilOrEmptyString(address?.zip) ||
          stopType === StopType.PartnerCarrierPickup ||
          stopType === StopType.PartnerCarrierDropoff;
        if (!requiresTerminal) {
          return true;
        }

        return !isNilOrEmptyString(terminalUuid);
      },
      { path: ['terminalUuid'], message: 'Missing terminal' },
    )
    .refine(
      (data) => {
        const isAddressValid =
          !isNil(data?.address) &&
          addressSchema.safeParse(data.address).success;
        if (isAddressValid) {
          return true;
        }
        if (
          data.stopType === StopType.Delivery ||
          data.stopType === StopType.Pickup ||
          data.stopType === StopType.Recovery ||
          (data.stopType === StopType.Transfer &&
            data.destinationInOutbound === true)
        ) {
          return false;
        }
        return true;
      },
      { path: ['address'], message: 'Missing address' },
    )
    .refine(
      (data) => {
        if (
          !ffRecoveryTransferAddressOnly &&
          isNil(data.airportInfoUuid) &&
          (data.stopType === StopType.Recovery ||
            (data.stopType === StopType.Transfer &&
              data.destinationInOutbound === true))
        ) {
          return false;
        }
        return true;
      },
      { path: ['airportInfoUuid'], message: 'Missing recovery location' },
    );
