import { TextField } from '@mui/material';
import { isNil } from 'lodash';
import { exhaustive } from 'shared/switch';
import { type BaseTextFormFieldProps, FormMode } from '../common';

type SpecialAccessorialMatrixItemTextFieldProps = {
  readonly onChange: (value: number) => void;
  readonly onBlur: (value: number) => void;
} & BaseTextFormFieldProps;

export const SpecialAccessorialMatrixItemTextField = ({
  mode,
  value,
  type,
  label,
  disabled,
  onChange,
  onBlur,
}: SpecialAccessorialMatrixItemTextFieldProps) => {
  const hasError = isNil(value) || Number.isNaN(value);

  switch (mode) {
    case FormMode.EDIT:
    case FormMode.CREATE: {
      return (
        <TextField
          disabled={disabled}
          error={hasError}
          type={type}
          helperText={hasError ? 'Rate is required' : undefined}
          label={label}
          value={value ?? ''}
          size="small"
          onChange={(e) => {
            onChange(Number.parseFloat(e.target.value));
          }}
          onBlur={() => {
            onBlur(value as number);
          }}
          onWheel={(e) => {
            (e.target as HTMLTextAreaElement).blur();
          }}
        />
      );
    }
    default: {
      return exhaustive(mode);
    }
  }
};
