import { Add } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { groupBy, isNil } from 'lodash';
import { Calendar } from 'primereact/calendar';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import TerminalFilterButton from '../../../common/components/terminal-filter-button';
import { type Option } from '../../../common/filters/types';
import useTerminals from '../../../common/react-hooks/use-terminals';
import { isNilOrEmptyString } from '../../../common/utils/utils';
import {
  useLineHaulManifestLazyQuery,
  useLineHaulManifestsLazyQuery,
} from '../../../generated/graphql';
import useGlobalStore from '../../../layouts/dashboard/global-store';
import { getPlanningDate } from '../../dispatch/utils';
import useLineHaulDispatchActions from '../hooks/use-line-haul-dispatch-actions';
import useLineHaulDispatchStore, {
  ManifestTab,
} from '../store/line-haul-dispatch-store';
import CreateManifestModal from './create-manifest-modal';
import GroupedManifestCard from './grouped-manifest-card';
import ManifestsContextMenu from './manifests-context-menu';
import OpenedManifestDetails from './opened-manifest/opened-manifest-details';
import OpenedManifestToolbar from './opened-manifest/opened-manifest-toolbar';

const PLANNING_DATE = 'planningDate';
const PLANNING_DATE_EXPIRATION = 'planningDateExpiration';

const LineHaulDispatchManifestsSection = () => {
  const [searchParams] = useSearchParams();
  const manifestUuid = searchParams.get('manifestUuid');
  const [getManifest] = useLineHaulManifestLazyQuery();
  const selectedTerminalUuid = useGlobalStore(
    (state) => state.selectedTerminalUuid,
  );
  const { getTerminalName, terminals } = useTerminals({
    includeInactiveTerminals: false,
  });
  const [currentManifestTab, setCurrentManifestTab] = useLineHaulDispatchStore(
    (state) => [state.currentManifestTab, state.setCurrentManifestTab],
  );
  const [setManifests, setOpenedManifest] = useLineHaulDispatchStore(
    (state) => [state.setManifests, state.setOpenedManifest],
  );
  const [showCreateManifestModal, setShowCreateManifestModal] = useState(false);

  const [originTerminalOption, setOriginTerminalOption] = useState<
    Option | undefined
  >();

  const [destinationTerminalOption, setDestinationTerminalOption] = useState<
    Option | undefined
  >();

  const localPlanningDate: string | null = localStorage.getItem(PLANNING_DATE);
  const localPlanningDateExpiration: string | null = localStorage.getItem(
    PLANNING_DATE_EXPIRATION,
  );

  const { changePlanningDate } = useLineHaulDispatchActions();

  useEffect(() => {
    changePlanningDate({
      newPlanningDate: getPlanningDate({
        planningDate: localPlanningDate,
        planningDateExpiration: localPlanningDateExpiration,
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      currentManifestTab === ManifestTab.Arrivals &&
      !isNil(selectedTerminalUuid)
    ) {
      const name = getTerminalName(selectedTerminalUuid);
      setDestinationTerminalOption({
        value: selectedTerminalUuid,
        label: name,
      });
      setOriginTerminalOption(undefined);
    }
    if (
      currentManifestTab === ManifestTab.Departures &&
      !isNil(selectedTerminalUuid)
    ) {
      const name = getTerminalName(selectedTerminalUuid);
      setOriginTerminalOption({
        value: selectedTerminalUuid,
        label: name,
      });
      setDestinationTerminalOption(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTerminalUuid, terminals?.length, currentManifestTab]);

  useEffect(() => {
    if (isNil(manifestUuid)) {
      changePlanningDate({
        newPlanningDate: getPlanningDate({
          planningDate: localPlanningDate,
          planningDateExpiration: localPlanningDateExpiration,
        }),
      });
    } else {
      getManifest({
        variables: {
          uuid: manifestUuid,
        },
      }).then((data) => {
        setOpenedManifest(data.data?.lineHaulManifest);
        changePlanningDate({
          newPlanningDate: getPlanningDate({
            planningDate: data.data?.lineHaulManifest.departDate,
            planningDateExpiration: localPlanningDateExpiration,
          }),
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [
    planningDate,
    manifests,
    openedManifest,
    deselectAllManifestAndUnmanifestedSegmentUuids,
  ] = useLineHaulDispatchStore((state) => [
    state.planningDate,
    state.manifests,
    state.openedManifest,
    state.deselectAllManifestAndUnmanifestedSegmentUuids,
  ]);

  const [searchText, setSearchText] = useState<string>('');
  // const [debouncedSearchText] = useDebounce(searchText, 200);

  const [getLineHaulManifests] = useLineHaulManifestsLazyQuery();

  const fetchManifestsAndUnmanifestedSegments = async () => {
    if (!isNil(planningDate)) {
      const res = await getLineHaulManifests({
        variables: { date: planningDate },
      });

      setManifests(res.data?.lineHaulManifests.lineHaulManifests);
    }
  };

  const handleOriginTerminalChange = (option: Option | null | undefined) => {
    deselectAllManifestAndUnmanifestedSegmentUuids();
    setOriginTerminalOption(option ?? undefined);
  };
  const handleDestinationTerminalChange = (
    option: Option | null | undefined,
  ) => {
    deselectAllManifestAndUnmanifestedSegmentUuids();
    setDestinationTerminalOption(option ?? undefined);
  };

  useEffect(() => {
    if (!isNil(planningDate)) {
      localStorage.setItem(PLANNING_DATE, planningDate.toISOString());
      const endOfToday = dayjs().endOf('day');
      localStorage.setItem(PLANNING_DATE_EXPIRATION, endOfToday.toISOString());
    }
    fetchManifestsAndUnmanifestedSegments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planningDate]);

  const openedManifestFromList = manifests?.find(
    (m) => m.uuid === openedManifest?.uuid,
  );
  if (!isNil(openedManifestFromList)) {
    return (
      <Box display="flex" flexDirection="column" style={{ height: '100%' }}>
        <OpenedManifestToolbar
          manifest={openedManifestFromList}
          unmanifestedSegment={undefined}
        />
        <Divider />
        <OpenedManifestDetails
          manifest={openedManifestFromList}
          unmanifestedSegment={undefined}
        />
      </Box>
    );
  }

  const filteredManifests = manifests
    ?.filter(
      (m) =>
        (isNil(originTerminalOption) ||
          m.startTerminal.uuid === originTerminalOption?.value) &&
        (isNil(destinationTerminalOption) ||
          m.endTerminal.uuid === destinationTerminalOption?.value),
    )
    .filter(
      (m) =>
        isNilOrEmptyString(searchText) ||
        m.startTerminal.code.toLowerCase().includes(searchText.toLowerCase()) ||
        m.endTerminal.code.toLowerCase().includes(searchText.toLowerCase()) ||
        m.referenceNumber.toLowerCase().includes(searchText.toLowerCase()),
    )
    .sort((a, b) => {
      if (a.startTerminal.code === b.startTerminal.code) {
        return a.endTerminal.code.localeCompare(b.endTerminal.code);
      }
      return a.startTerminal.code.localeCompare(b.startTerminal.code);
    });

  const filteredManifestsGroupedByStartAndEndTerminal = Object.entries(
    groupBy(filteredManifests, (manifest) => {
      return JSON.stringify({
        start: manifest.startTerminal,
        end: manifest.endTerminal,
      });
    }),
  );

  return (
    <Stack height="100%">
      <CreateManifestModal
        open={showCreateManifestModal}
        setOpen={setShowCreateManifestModal}
        originTerminalOptionFromFilter={originTerminalOption}
        destinationTerminalOptionFromFilter={destinationTerminalOption}
      />
      <Stack width="100%" paddingTop={1}>
        <Tabs
          orientation="horizontal"
          value={currentManifestTab}
          sx={{
            width: '100%',
            marginTop: '-10px',
            marginBottom: '10px',
            borderBottom: 'solid 0.5px grey',
          }}
          onChange={(e, newIndex) => {
            setCurrentManifestTab(newIndex);
          }}
        >
          <Tab sx={{ flex: 1 }} label="Arrivals" />
          <Tab sx={{ flex: 1 }} label="Departures" />
        </Tabs>
      </Stack>

      <Stack width="100%" p={1} direction="row" alignItems="center" spacing={2}>
        <TextField
          fullWidth
          className="dispatch-search-input"
          variant="standard"
          size="small"
          placeholder="Search manifests..."
          autoComplete="off"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={searchText}
          sx={{ flex: 1 }}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
        <ManifestsContextMenu manifests={filteredManifests ?? []} />
      </Stack>
      <Stack
        direction="row"
        p={1}
        sx={{ flexWrap: 'wrap' }}
        gap={1}
        alignItems="center"
      >
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Calendar
            showButtonBar
            style={{
              minWidth: '76px',
              maxWidth: '125px',
            }}
            value={planningDate?.toDate()}
            placeholder="Planning Date"
            onChange={(e) => {
              if (!isNil(e.value)) {
                changePlanningDate({
                  newPlanningDate: dayjs(String(e.value)),
                });
              }
            }}
          />
          <TerminalFilterButton
            prefixText="Origin"
            selectedOption={originTerminalOption}
            handleChange={handleOriginTerminalChange}
            includeInactiveTerminals={false}
          />
          <TerminalFilterButton
            prefixText="Destination"
            selectedOption={destinationTerminalOption}
            handleChange={handleDestinationTerminalChange}
            includeInactiveTerminals={false}
          />
        </Stack>
        {currentManifestTab === ManifestTab.Departures && (
          <Button
            variant="contained"
            startIcon={<Add />}
            sx={{ height: 'fit-content' }}
            onClick={() => {
              setShowCreateManifestModal(true);
            }}
          >
            Create new
          </Button>
        )}
      </Stack>

      <Divider />
      {filteredManifests?.length === 0 && (
        <Typography p={2}> No manifests match your filters</Typography>
      )}
      <Stack p={1} gap={2} minHeight={0} overflow="scroll">
        {filteredManifestsGroupedByStartAndEndTerminal?.map(
          ([startAndEndTerminal, manifestsInGroup]) => {
            return (
              <GroupedManifestCard
                key={manifestsInGroup[0]?.uuid ?? ''}
                startAndEndTerminal={JSON.parse(startAndEndTerminal)}
                manifests={manifestsInGroup}
              />
            );
          },
        )}
      </Stack>
    </Stack>
  );
};

export default LineHaulDispatchManifestsSection;
