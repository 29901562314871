import {
  Checkbox,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { useContext, useState } from 'react';
import { useDebounce } from 'use-debounce';
import useDocuments from '../../../common/react-hooks/use-documents';
import {
  type DocumentFragment,
  type DocumentType,
  FindCompanyDocumentStatusFilter,
  useUpdateDocumentMutation,
} from '../../../generated/graphql';
import endOfDayContext from '../end-of-day-context';

const DocumentRow = ({
  document,
  ctrlPressed,
  idx,
}: {
  readonly document: DocumentFragment;
  readonly ctrlPressed: boolean;
  readonly idx: number;
}) => {
  const [updateDocument] = useUpdateDocumentMutation();
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingDebounced] = useDebounce(isEditing, 200);
  const formatDocumentStatus = () => {
    if (isNil(document?.order?.uuid) && isNil(document?.shipment?.uuid)) {
      return 'Unmatched';
    }

    return 'Matched';
  };
  const { documentTypesForSelection, getDocumentTypeCopy, loading } =
    useDocuments();

  const {
    documentStatusFilter,
    companyDocuments,
    selectedDocumentUuids,
    setSelectedDocumentUuids,
    setSelectedMatchedOrderUuid,
    documentUuidToDocumentType,
    setDocumentUuidToDocumentType,
  } = useContext(endOfDayContext);

  if (loading) {
    return null;
  }

  return (
    <TableRow>
      <TableCell padding="none">
        <Checkbox
          checked={selectedDocumentUuids?.includes(document.uuid)}
          onChange={(e) => {
            if (
              documentStatusFilter === FindCompanyDocumentStatusFilter.Matched
            ) {
              const selectedDocument = companyDocuments?.find(
                (doc) => doc.uuid === document.uuid,
              );
              const orderUuid = selectedDocument?.order?.uuid;
              if (!isNil(orderUuid) && e.target.checked) {
                setSelectedMatchedOrderUuid(orderUuid);
              } else if (!e.target.checked) {
                setSelectedMatchedOrderUuid(undefined);
              }
            }

            if (
              documentStatusFilter === FindCompanyDocumentStatusFilter.Matched
            ) {
              const orderUuid = document.order?.uuid;
              if (isNil(orderUuid)) {
                setSelectedDocumentUuids(
                  e.target.checked
                    ? [...selectedDocumentUuids, document.uuid]
                    : selectedDocumentUuids.filter(
                        (uuid) => uuid !== document.uuid,
                      ),
                );
              } else {
                const matchedOrderDocumentUuids = companyDocuments
                  ?.filter((doc) => doc.order?.uuid === orderUuid)
                  ?.map((doc) => doc.uuid);
                setSelectedDocumentUuids(
                  e.target.checked
                    ? matchedOrderDocumentUuids
                    : selectedDocumentUuids.filter(
                        (uuid) => uuid !== document.uuid,
                      ),
                );
              }
            } else {
              setSelectedDocumentUuids(
                e.target.checked
                  ? [...selectedDocumentUuids, document.uuid]
                  : selectedDocumentUuids.filter(
                      (uuid) => uuid !== document.uuid,
                    ),
              );
            }

            // If ctrl is pressed and the event checks the row, check everything between the checked row and the first checked row for that filter
            if (ctrlPressed && e.target.checked) {
              const firstCheckedDocumentIdx = companyDocuments.findIndex(
                (itrDoc) => selectedDocumentUuids.includes(itrDoc.uuid),
              );
              const rowsToCheck = companyDocuments.filter(
                (itrDoc, itrIdx) =>
                  itrIdx >= firstCheckedDocumentIdx && itrIdx < idx,
              );
              setSelectedDocumentUuids((prevState) => {
                const newCheckedRows = rowsToCheck.filter(
                  (row) => !selectedDocumentUuids.includes(row.uuid),
                );
                return [...prevState, ...newCheckedRows.map((row) => row.uuid)];
              });
            }
          }}
        />
      </TableCell>
      <TableCell style={{ width: '20%' }}>
        {document.fileName}{' '}
        {isNil(document.pageNumber) ? '' : `(page ${document.pageNumber})`}
      </TableCell>
      {documentStatusFilter !== FindCompanyDocumentStatusFilter.Matched && (
        <TableCell>{formatDocumentStatus()}</TableCell>
      )}
      <TableCell>
        {dayjs(document.uploadedAt).format('MM/DD/YY hh:mma')}
      </TableCell>
      {documentStatusFilter === FindCompanyDocumentStatusFilter.Matched && (
        <TableCell>
          {document?.order?.standardOrderFields?.shipperBillOfLadingNumber}
        </TableCell>
      )}
      <TableCell
        sx={{ minWidth: '150px' }}
        onMouseEnter={() => {
          setIsEditing(true);
        }}
        onMouseLeave={() => {
          setIsEditing(false);
        }}
      >
        {isEditingDebounced ? (
          <Select
            size="small"
            sx={{ fontSize: '12px' }}
            value={documentUuidToDocumentType[document.uuid] ?? document.type}
            onBlur={() => {
              setIsEditing(false);
            }}
            onChange={(e) => {
              const newDocumentType = e.target.value as DocumentType;
              setDocumentUuidToDocumentType((prevState) => {
                return {
                  ...prevState,
                  [document.uuid]: newDocumentType,
                };
              });
              updateDocument({
                variables: {
                  updateDocumentInput: {
                    documentUpdateInput: {
                      uuid: document.uuid,
                      type: newDocumentType,
                    },
                  },
                },
              });
              setIsEditing(false);
            }}
          >
            {documentTypesForSelection?.map((documentType) => (
              <MenuItem key={documentType} value={documentType}>
                {getDocumentTypeCopy({
                  documentType,
                })}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Typography sx={{ fontSize: '0.875rem' }}>
            {getDocumentTypeCopy({
              documentType:
                documentUuidToDocumentType[document.uuid] ?? document.type,
            })}
          </Typography>
        )}
      </TableCell>
    </TableRow>
  );
};

export default DocumentRow;
