import { TableCell, TableRow } from '@mui/material';
import { isNil } from 'lodash';
import { transformAddressToFullAddressString } from 'shared/copy';
import { transformDateToDateString } from '../../../../common/utils/prettyPrintUtils';
import { ShipmentType } from '../../../../generated/graphql';
import { useAppSelector } from '../../../../redux/hooks';
import { selectAddressById } from '../../../addresses/redux/addresses-values-slice';
import { getShipmentTypeCopy } from '../../../invoices/utils';
import { selectLegValuesById } from '../../../legs/redux/leg-values-slice';
import { isPartnerCarryShipment } from '../../../orders/components/utils';
import { selectStandardShipmentValuesById } from '../../../shipments/redux/standard-shipments-values-slice';
import { selectStopValuesById } from '../../../stops/redux/stop-values-slice';

const ShipmentRow = ({ shipmentUuid }: { readonly shipmentUuid: string }) => {
  const shipmentValues = useAppSelector((state) =>
    selectStandardShipmentValuesById(state, shipmentUuid),
  );
  const legValues = useAppSelector((state) =>
    selectLegValuesById(state, shipmentValues?.firstLegUuid ?? ''),
  );
  const endStop = useAppSelector((state) =>
    selectStopValuesById(state, legValues?.endStopUuid ?? ''),
  );
  const address = useAppSelector((state) =>
    selectAddressById(state, endStop?.addressUuid ?? ''),
  );
  const shipmentType = getShipmentTypeCopy({
    shipmentType: shipmentValues?.shipmentType ?? ShipmentType.Regular,
    legs: [
      {
        endStop,
      },
    ],
  });

  if (
    isPartnerCarryShipment({
      stopFromRedux: endStop,
    })
  ) {
    return null;
  }

  return (
    <TableRow>
      <TableCell>{shipmentType}</TableCell>
      <TableCell>
        {isNil(address)
          ? 'NA'
          : transformAddressToFullAddressString({
              ...address,
              city: address.city ?? null,
              line1: address.line1 ?? null,
              zip: address.zip ?? null,
            })}
      </TableCell>
      <TableCell>
        {!isNil(shipmentValues) && !isNil(shipmentValues.deliveryDate)
          ? transformDateToDateString(shipmentValues?.deliveryDate)
          : 'NA'}
      </TableCell>
      <TableCell>
        ${shipmentValues?.totalChargesAmount?.toFixed(2) ?? '0.00'}
      </TableCell>
    </TableRow>
  );
};

export default ShipmentRow;
