import {
  TableRow,
  TableCell,
  Chip,
  Fade,
  Button,
  useTheme,
  Stack,
} from '@mui/material';
import { isNil } from 'lodash';
import { useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../common/react-hooks/use-user-roles';
import { chooseForegroundColor } from '../../common/utils/colors';
import { PermissionResource } from '../../generated/graphql';
import AddTagModal from './add-tag-modal';
import DeleteTagModal from './delete-tag-modal';

const TagRow = ({
  color,
  value,
  uuid,
}: {
  readonly color: string | null | undefined;
  readonly value: string;
  readonly uuid: string;
}) => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteSettingsTags } = getPermissionsFlags(
    userPermissions,
    PermissionResource.SettingsTags,
  );

  const [isHovering, setIsHovering] = useState(false);
  const theme = useTheme();
  const [showDeleteTagModal, setShowDeleteTagModal] = useState(false);
  const [showEditTagModal, setShowEditTagModal] = useState(false);
  return (
    <TableRow
      key={uuid}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <TableCell component="th" scope="row">
        {value}
      </TableCell>
      <TableCell>
        <Chip
          label={value}
          sx={{
            backgroundColor: color,
            color: isNil(color) ? 'black' : chooseForegroundColor(color),
          }}
        />
      </TableCell>
      <TableCell align="right">
        <Fade in={isHovering}>
          <Stack direction="row" justifyContent="right" spacing={1}>
            <Button
              variant="contained"
              sx={{ backgroundColor: theme.palette.redColor.main }}
              disabled={!canWriteSettingsTags}
              onClick={() => {
                setShowDeleteTagModal(true);
              }}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              disabled={!canWriteSettingsTags}
              onClick={() => {
                setShowEditTagModal(true);
              }}
            >
              Edit
            </Button>
          </Stack>
        </Fade>
      </TableCell>
      {showDeleteTagModal && (
        <DeleteTagModal
          isOpen={showDeleteTagModal}
          setIsOpen={setShowDeleteTagModal}
          uuid={uuid}
        />
      )}
      {showEditTagModal && (
        <AddTagModal
          isEditing
          isOpen={showEditTagModal}
          setIsOpen={setShowEditTagModal}
          tag={{ uuid, value, color }}
        />
      )}
    </TableRow>
  );
};

export default TagRow;
