import { isNil } from 'lodash';
import type React from 'react';
import { useState, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';

const AG_PAGING_PANEL_CLASS = 'ag-paging-panel';

type ActionsWrapperProps = {
  readonly children: React.ReactNode;
};
const ActionsWrapper = ({ children }: ActionsWrapperProps) => {
  return <div style={{ order: -1 }}>{children}</div>;
};

type GridActionsProps = {
  readonly children: React.ReactNode;
};
const GridActions = ({ children }: GridActionsProps) => {
  const [isComponentRendered, setIsComponentRendered] = useState(false);
  useLayoutEffect(() => {
    setIsComponentRendered(true);
  }, []);

  const agGridPagingPanel = document.getElementsByClassName(
    AG_PAGING_PANEL_CLASS,
  )?.[0];

  if (!isComponentRendered || isNil(agGridPagingPanel)) return null;
  return createPortal(
    <ActionsWrapper> {children} </ActionsWrapper>,
    agGridPagingPanel,
  );
};

export default GridActions;
