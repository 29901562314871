import { Stack, TableCell, TableRow, Typography } from '@mui/material';
import type { ReactNode } from 'react';
import { VehicleTypeStatus } from '../../../../generated/graphql';

type VehicleTypeRowProps = {
  readonly name: string;
  readonly status: VehicleTypeStatus;
  readonly openButton?: ReactNode;
};

const VehicleTypeRow = ({ name, status, openButton }: VehicleTypeRowProps) => (
  <TableRow
    hover
    sx={{
      '&:last-child td, &:last-child th': { border: 0 },
      '& button': {
        visibility: 'hidden',
      },
      '&:hover button': {
        visibility: 'visible',
      },
    }}
  >
    <TableCell>
      <Stack flexDirection="row" gap={2} alignItems="center">
        <Typography>{name}</Typography>
        {status !== VehicleTypeStatus.Active && (
          <Typography color="text.secondary" fontSize="small">
            ({status})
          </Typography>
        )}
      </Stack>
    </TableCell>
    <TableCell align="right">{openButton}</TableCell>
  </TableRow>
);

export default VehicleTypeRow;
