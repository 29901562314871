import {
  createEntityAdapter,
  createSelector,
  createSlice,
  type EntityId,
  type EntityState,
} from '@reduxjs/toolkit';
import { type LegFormField } from 'shared/types';
import type { RootState } from '../../../redux/store';

type LegValuesStoreState = LegFormField;

const legsValuesAdapter = createEntityAdapter<LegValuesStoreState>({
  selectId: (leg) => leg.uuid,
});

export const legValuesSlice = createSlice({
  name: 'legsValues',
  initialState: legsValuesAdapter.getInitialState(),
  reducers: {
    addOneLeg: legsValuesAdapter.addOne,
    updateLeg: legsValuesAdapter.updateOne,
    deleteLeg: legsValuesAdapter.removeOne,
    updateLegs: legsValuesAdapter.updateMany,
    setAllLegs: legsValuesAdapter.setAll,
    upsertLeg: legsValuesAdapter.upsertOne,
  },
});

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectById: selectLegValuesById,
  selectAll: selectAllLegs,
  // Pass in a selector that returns the posts slice of state
} = legsValuesAdapter.getSelectors(
  (state: RootState) => state.orderFormLegsValues,
);

const legValuesSelector = (state: RootState) => state.orderFormLegsValues;

export const selectLegsByIds = createSelector(
  legValuesSelector,
  (state: RootState, ids: EntityId[]) => ids,
  (state: EntityState<LegFormField>, entityIds: EntityId[]) => {
    const entityResults: LegFormField[] = [];
    for (const id of entityIds) {
      const match = legsValuesAdapter.getSelectors().selectById(state, id);
      if (match) {
        entityResults.push(match);
      }
    }
    return entityResults;
  },
);

export const selectEndStopUuidForLeg = createSelector(
  selectLegValuesById,
  (values: LegValuesStoreState | undefined) => {
    return values?.endStopUuid;
  },
);

export const selectMilesForLeg = createSelector(
  selectLegValuesById,
  (values: LegValuesStoreState | undefined) => {
    return values?.miles;
  },
);

export const selectLegStopUuids = createSelector(
  selectLegValuesById,
  (values: LegValuesStoreState | undefined) => {
    return values?.endStopUuid;
  },
);

export const { addOneLeg, updateLeg, deleteLeg, setAllLegs, upsertLeg } =
  legValuesSlice.actions;

export default legValuesSlice.reducer;
