import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Stack,
  Typography,
} from '@mui/material';

type EdiOrdersHaveErrorsModalProps = {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly orders: Array<{ name: string; errors: string[] }>;
};

const EdiOrdersHaveErrorsModal = ({
  open,
  onClose,
  orders,
}: EdiOrdersHaveErrorsModalProps) => {
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          textAlign: 'left',
          alignContent: 'center',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        Could not accept orders
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            <Typography fontStyle="italic">
              We could not accept the following orders because they have errors.
            </Typography>
          </Grid>
          {orders.map((order) => (
            <Grid
              key={order.name + order.errors.join(',')}
              item
              xs={12}
              spacing={5}
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              <Stack
                width="100%"
                direction="column"
                justifyContent="space-between"
                pl="20px"
                pr="20px"
              >
                <Stack
                  direction="column"
                  gap="10px"
                  justifyContent="space-between"
                >
                  <Typography fontWeight={700}>{order.name}</Typography>
                  {order.errors.map((e, i) => {
                    // eslint-disable-next-line react/no-array-index-key
                    return <Typography key={`${e}-${i}`}>{e}</Typography>;
                  })}
                </Stack>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Go back
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EdiOrdersHaveErrorsModal;
