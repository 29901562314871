import { QrCode } from '@mui/icons-material';
import {
  Checkbox,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import React, { useMemo, useRef, useState } from 'react';
import { type DetailedLineHaulManifestFragment } from '../../../../../generated/graphql';
import PalletLink from '../../../../../pallet-ui/links/link/pallet-link';
import LineHaulPartialPiecesWarning from '../../common/line-haul-partial-pieces-warning';
import ManifestOrdersTableRowHoverMenu from './manifest-orders-table-row-hover-menu';

type OpenedManifestOrdersTableRowProps = {
  readonly orderSegment: DetailedLineHaulManifestFragment['orderSegments'][0];
  readonly orderIsSelected: boolean;
  readonly setOrderAsSelected: () => void;
  readonly unselectRow: () => void;
  readonly manifest: DetailedLineHaulManifestFragment;
};

const styles: Record<string, React.CSSProperties> = {
  orderIdContainer: {
    maxWidth: '120px',
  },
  overflowText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap' as const,
  },
};

const OpenedManifestOrdersTableRow = ({
  orderSegment,
  orderIsSelected,
  setOrderAsSelected,
  unselectRow,
  manifest,
}: OpenedManifestOrdersTableRowProps) => {
  const { order } = orderSegment;
  const checkboxChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setOrderAsSelected();
    } else {
      unselectRow();
    }
  };
  const rowRef = useRef<HTMLTableRowElement>(null);
  const [isHovering, setIsHovering] = useState<boolean>(false);

  const expectedPieces = useMemo(() => {
    return order.pieceCountFromPackages;
  }, [order.pieceCountFromPackages]);

  const getDepartureDate = () => {
    if (orderSegment.departureScans.length > 0) {
      return (
        <Stack gap={1}>
          <LineHaulPartialPiecesWarning
            totalScans={orderSegment.departureScans.length}
            totalPieces={expectedPieces}
          />
          <Stack direction="row" alignItems="center" gap={1}>
            <QrCode fontSize="small" />
            <Typography whiteSpace="nowrap" variant="body2">
              {dayjs(orderSegment.departureScans.at(-1)?.scannedAt).format(
                'MM/DD/YY h:mma',
              )}
            </Typography>
          </Stack>
        </Stack>
      );
    }
    if (!isNil(manifest.startedAt)) {
      return (
        <>
          <Typography whiteSpace="nowrap" variant="body2">
            {dayjs(manifest.startedAt).format('MM/DD/YY')}
          </Typography>
          <Typography whiteSpace="nowrap" variant="body2">
            {dayjs(manifest.startedAt).format('h:mma')}
          </Typography>
        </>
      );
    }
    return null;
  };

  const getArrivalDate = () => {
    if (orderSegment.arrivalScans.length > 0) {
      return (
        <Stack gap={1}>
          <LineHaulPartialPiecesWarning
            totalScans={orderSegment.arrivalScans.length}
            totalPieces={expectedPieces}
          />
          <Stack direction="row" alignItems="center" gap={1}>
            <QrCode fontSize="small" />
            <Typography whiteSpace="nowrap">
              {dayjs(orderSegment.arrivalScans.at(-1)?.scannedAt).format(
                'MM/DD/YY h:mma',
              )}
            </Typography>
          </Stack>
        </Stack>
      );
    }
    if (!isNil(orderSegment.arrivedAt)) {
      return (
        <>
          <Typography whiteSpace="nowrap" variant="body2">
            {dayjs(orderSegment.arrivedAt).format('MM/DD/YY')}
          </Typography>
          <Typography whiteSpace="nowrap" variant="body2">
            {dayjs(orderSegment.arrivedAt).format('h:mma')}
          </Typography>
        </>
      );
    }
    if (!isNil(manifest.completedAt)) {
      return (
        <>
          <Typography whiteSpace="nowrap" variant="body2">
            {dayjs(manifest.completedAt).format('MM/DD/YY h:mma')}
          </Typography>
          <Typography whiteSpace="nowrap" variant="body2">
            {dayjs(manifest.completedAt).format('h:mma')}
          </Typography>
        </>
      );
    }
    return null;
  };

  return (
    <TableRow
      ref={rowRef}
      className="opened-manifest-orders-table-row"
      sx={{
        '& .MuiTypography-root': {
          fontSize: 12,
        },
        '> *': {
          padding: '0px 5px !important',
        },
      }}
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <TableCell>
        <Checkbox
          checked={orderIsSelected}
          sx={{ padding: 0 }}
          onChange={checkboxChanged}
        />
      </TableCell>
      <TableCell style={styles.orderIdContainer}>
        <Tooltip title={order.name.length > 10 ? order.name : null}>
          <PalletLink
            sx={{
              cursor: 'pointer',
            }}
            href={`/orders/?orderUuid=${order.uuid}&goBackAfterSave=true`}
            target="_blank"
            underline="hover"
          >
            <Typography style={styles.overflowText}>{order.name}</Typography>
          </PalletLink>
        </Tooltip>
      </TableCell>
      <TableCell style={styles.orderIdContainer}>
        <Tooltip title={order.standardOrderFields.shipperBillOfLadingNumber}>
          <Typography variant="body2" style={styles.overflowText}>
            {order.standardOrderFields.shipperBillOfLadingNumber ?? '-'}
          </Typography>
        </Tooltip>
      </TableCell>

      <TableCell style={styles.orderIdContainer}>
        <Tooltip
          title={order.standardOrderFields.masterAirwayBillOfLadingNumber}
        >
          <Typography variant="body2" style={styles.overflowText}>
            {order.standardOrderFields.masterAirwayBillOfLadingNumber ?? '-'}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell sx={{ minWidth: '100px' }}>
        <Stack>
          {order.packages.length > 0
            ? order.packages.map((pkg) => (
                <Stack key={pkg.uuid} direction="row">
                  <Typography fontSize={13}>
                    {pkg.length} x {pkg.width} x {pkg.height} / {pkg.weight} lbs
                  </Typography>
                </Stack>
              ))
            : 'None'}
        </Stack>
      </TableCell>
      <TableCell>{order.tags.map((tag) => tag.value).join(', ')}</TableCell>
      <TableCell>{getDepartureDate()}</TableCell>
      <TableCell>{getArrivalDate()}</TableCell>
      <ManifestOrdersTableRowHoverMenu
        anchorRef={rowRef}
        order={order}
        manifestUuid={manifest.uuid}
        open={isHovering}
      />
    </TableRow>
  );
};

export default React.memo(OpenedManifestOrdersTableRow);
