import { centimeters, inches } from '@buge/ts-units/length';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Fade,
  FormControl,
  FormHelperText,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { get, isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  Controller,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { pounds } from 'shared/units/rates';
import { calculateDimensionalWeight } from 'shared/weight';
import { getPackageFieldsTestIds } from '../../../../../../../utils';
import WarehouseLocationSelector from '../../../../../../common/components/forms/warehouse-location-selector';
import { FeatureFlag } from '../../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../../common/react-hooks/use-feature-flag';
import useMe from '../../../../../../common/react-hooks/use-me';
import {
  convertKilogramsToPounds,
  convertPoundsToKilograms,
  convertToCentimeters,
  convertToInches,
} from '../../../../../../common/utils/utils';
import {
  type PackageSpecEntity,
  PackageType,
} from '../../../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import useDimensionsInputRefs from '../../../../hooks/use-dimensions-input-refs';
import { useOrderFormEditAccess } from '../../contexts/order-form-edit-access-context';
import { type OrderFormValues, type PackageValues } from '../../forms/types';
import {
  type PartialPackageSpec,
  usePackageSpecOptions,
} from '../../hooks/use-package-spec-options';
import FormNumberInput from '../common/form-number-input';
import { findCorrespondingPackageSpec } from './util';

export enum WeightUnit {
  Kilograms = 'kg',
  Pounds = 'lb',
}

export enum MeasurementUnit {
  Inches = 'in',
  Centimeters = 'cm',
}

const styles = {
  dimensionsTextField: {
    width: '65px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none', // Change this to the desired border color
      },
    },
  },
  errorText: {
    color: '#D32F2F',
    position: 'absolute',
    whiteSpace: 'nowrap',
  },
  inputWithErrorContainer: {
    position: 'relative',
    flexWrap: 'nowrap',
  },
};

type PackageRowProps = {
  readonly idx: number;
  readonly deletePackage: (uuid: string) => void;
  readonly useAllCaps: boolean;
  // A list of all package specs, including archived ones. Pass in null while loading.
  readonly allPackageSpecs: PackageSpecEntity[] | null;
  // The package specs available to the order's contact. This can be identical to allPackageSpecs.
  readonly contactPackageSpecs: PackageSpecEntity[] | null;
};

const PackageRow = ({
  idx,
  deletePackage,
  useAllCaps,
  allPackageSpecs,
  contactPackageSpecs,
}: PackageRowProps) => {
  const { companyConfiguration } = useMe();
  const packageWarehouseLocationsEnabled =
    companyConfiguration?.packageWarehouseLocationsEnabled ?? false;

  const ffPackageSpecs = useFeatureFlag(FeatureFlag.FF_PACKAGE_SPECS);

  const { packageQuantityTestId, packageWeightTestId } =
    getPackageFieldsTestIds({ idx });

  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const { control, setValue } = useFormContext();
  const { errors } = useFormState({ control });
  const useKilograms: OrderFormValues['useKilograms'] = useWatch({
    control,
    name: 'useKilograms',
  });
  const useCentimeters: OrderFormValues['useCentimeters'] = useWatch({
    control,
    name: 'useCentimeters',
  });
  const packageSpecIdKey = `packages.${idx}.packageSpecId`;
  const typeKey = `packages.${idx}.type`;
  const weightKey = `packages.${idx}.weight`;
  const quantityKey = `packages.${idx}.quantity`;
  const heightKey = `packages.${idx}.height`;
  const widthKey = `packages.${idx}.width`;
  const lengthKey = `packages.${idx}.length`;
  const warehouseLocationUuidKey = `packages.${idx}.warehouseLocationUuid`;
  const warehouseLocationNameKey = `packages.${idx}.warehouseLocationName`;

  const dimFactorKey = 'dimFactor';
  const packageSpecId: PackageValues['packageSpecId'] = useWatch({
    control,
    name: packageSpecIdKey,
  });
  const quantity: PackageValues['quantity'] = useWatch({
    control,
    name: quantityKey,
  });
  const weight: PackageValues['weight'] = useWatch({
    control,
    name: weightKey,
  });
  const type: PackageValues['type'] = useWatch({ control, name: typeKey });
  const length: PackageValues['length'] = useWatch({
    control,
    name: lengthKey,
  });
  const width: PackageValues['width'] = useWatch({ control, name: widthKey });
  const height: PackageValues['height'] = useWatch({
    control,
    name: heightKey,
  });
  const dimFactor: OrderFormValues['dimFactor'] = useWatch({
    control,
    name: dimFactorKey,
  });
  const uuid: PackageValues['uuid'] = useWatch({
    control,
    name: `packages.${idx}.uuid`,
  });

  const [weightInput, setWeightInput] = useState(String(weight ?? ''));
  const [lengthInput, setLengthInput] = useState(String(length ?? ''));
  const [widthInput, setWidthInput] = useState(String(width ?? ''));
  const [heightInput, setHeightInput] = useState(String(height ?? ''));

  const [dimWeight, setDimWeight] = useState('');

  const packageQuantityError = get(
    errors,
    `packages.${idx}.quantity.message`,
  )?.toString();
  const packageWeightError = get(
    errors,
    `packages.${idx}.weight.message`,
  )?.toString();

  const { disabledIfFinalizedOrLater, disabledIfInvoicePosted } =
    useOrderFormEditAccess();

  const dimensionsInputRefs = useDimensionsInputRefs();
  const { packageLengthTestId, packageWidthTestId, packageHeightTestId } =
    getPackageFieldsTestIds({ idx });

  useEffect(() => {
    if (useKilograms === true) {
      const kilograms = convertPoundsToKilograms(weight);
      setWeightInput(kilograms?.toFixed(2) ?? '');
      setValue(weightKey, kilograms);
    }
    if (useKilograms === false) {
      const weightPounds = convertKilogramsToPounds(weight);
      setWeightInput(weightPounds?.toFixed(2) ?? '');
      setValue(weightKey, weightPounds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useKilograms]);

  useEffect(() => {
    if (useCentimeters === true) {
      const centimetersLength = convertToCentimeters(length ?? 0);
      const centimetersWidth = convertToCentimeters(width ?? 0);
      const centimetersHeight = convertToCentimeters(height ?? 0);
      setValue(lengthKey, centimetersLength);
      setValue(widthKey, centimetersWidth);
      setValue(heightKey, centimetersHeight);
      setLengthInput(centimetersLength?.toFixed(2) ?? '');
      setWidthInput(centimetersWidth?.toFixed(2) ?? '');
      setHeightInput(centimetersHeight?.toFixed(2) ?? '');
    }
    if (useCentimeters === false) {
      const inchesLength = convertToInches(length ?? 0);
      const inchesWidth = convertToInches(width ?? 0);
      const inchesHeight = convertToInches(height ?? 0);
      setValue(lengthKey, inchesLength);
      setValue(widthKey, inchesWidth);
      setValue(heightKey, inchesHeight);
      setLengthInput(inchesLength?.toFixed(2) ?? '');
      setWidthInput(inchesWidth?.toFixed(2) ?? '');
      setHeightInput(inchesHeight?.toFixed(2) ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useCentimeters]);

  useEffect(() => {
    let dimensionalWeight = calculateDimensionalWeight({
      length,
      width,
      height,
      quantity,
      dimFactor: dimFactor ?? undefined,
    });
    if (useCentimeters === true) {
      dimensionalWeight = calculateDimensionalWeight({
        length: convertToInches(length),
        width: convertToInches(width),
        height: convertToInches(height),
        quantity,
        dimFactor: dimFactor ?? undefined,
      });
    }
    setDimWeight(dimensionalWeight.toFixed(2));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [length, width, height, dimFactor, quantity, weight]);

  const { packageSpecOptions, selectedPackageSpecOption } =
    usePackageSpecOptions({
      allPackageSpecs,
      contactPackageSpecs,
      selectedPackageSpecId: packageSpecId,
    });

  const onChangePackageSpec = (
    packageSpec: PackageSpecEntity | PartialPackageSpec,
  ) => {
    setValue(packageSpecIdKey, packageSpec.id);
    // Checking if weight is a property tells us if we have a PackageSpecEntity or a PartialPackageSpecEntity.
    if ('weight' in packageSpec) {
      if (
        weightInput === '' &&
        !isNil(packageSpec.weight) &&
        packageSpec.weight.amount > 0
      ) {
        const newWeight = packageSpec.weight.in(pounds).amount;
        setWeightInput(newWeight.toString());
        setValue(weightKey, newWeight);
      }
      if (
        lengthInput === '' &&
        !isNil(packageSpec.length) &&
        packageSpec.length.amount > 0
      ) {
        const newLength = packageSpec.length.in(
          useCentimeters === true ? centimeters : inches,
        ).amount;
        setLengthInput(newLength.toString());
        setValue(lengthKey, newLength);
      }
      if (
        widthInput === '' &&
        !isNil(packageSpec.width) &&
        packageSpec.width.amount > 0
      ) {
        const newWidth = packageSpec.width.in(
          useCentimeters === true ? centimeters : inches,
        ).amount;
        setWidthInput(newWidth.toString());
        setValue(widthKey, newWidth);
      }
      if (
        heightInput === '' &&
        !isNil(packageSpec.height) &&
        packageSpec.height.amount > 0
      ) {
        const newHeight = packageSpec.height.in(
          useCentimeters === true ? centimeters : inches,
        ).amount;
        setHeightInput(newHeight.toString());
        setValue(heightKey, newHeight);
      }
    }
  };

  return (
    <TableRow
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <TableCell>
        <Box sx={styles.inputWithErrorContainer}>
          <FormNumberInput
            nonNegativeInteger
            fieldName={`packages.${idx}.quantity`}
            disabled={disabledIfFinalizedOrLater}
            inputProps={{ 'data-testid': packageQuantityTestId }}
            error={!isNil(packageQuantityError)}
          />
          {!isNil(packageQuantityError) && (
            <FormHelperText sx={styles.errorText}>
              {packageQuantityError}
            </FormHelperText>
          )}
        </Box>
      </TableCell>
      <TableCell>
        <Box sx={styles.inputWithErrorContainer}>
          <FormControl fullWidth>
            <TextField
              disabled={disabledIfFinalizedOrLater}
              size="small"
              value={weightInput}
              inputProps={{ 'data-testid': packageWeightTestId }}
              onChange={(e) => {
                const newValue = e.target.value;
                const parsedFloat = Number.parseFloat(newValue);
                setWeightInput(newValue);
                if (!Number.isNaN(parsedFloat)) {
                  setValue(weightKey, parsedFloat);
                } else if (newValue === '') {
                  setValue(weightKey, null);
                }
              }}
            />
          </FormControl>
          {!isNil(packageWeightError) && (
            <FormHelperText sx={styles.errorText}>
              {packageWeightError}
            </FormHelperText>
          )}
        </Box>
      </TableCell>
      <TableCell>{dimWeight}</TableCell>
      <TableCell>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid',
            borderRadius: '4px',
            borderColor: '#bfbebd',
          }}
        >
          <FormControl>
            <TextField
              disabled={disabledIfFinalizedOrLater}
              size="small"
              inputRef={(el) => {
                dimensionsInputRefs.current[0] = el;
              }}
              sx={styles.dimensionsTextField}
              value={lengthInput}
              inputProps={{ 'data-testid': packageLengthTestId }}
              onChange={(e) => {
                const newValue = e.target.value;
                const parsedFloat = Number.parseFloat(newValue);
                setLengthInput(newValue);
                if (!Number.isNaN(parsedFloat)) {
                  setValue(lengthKey, parsedFloat);
                } else if (newValue === '') {
                  setValue(lengthKey, null);
                }
              }}
            />
          </FormControl>
          <Typography>⨉</Typography>
          <FormControl>
            <TextField
              disabled={disabledIfFinalizedOrLater}
              size="small"
              inputRef={(el) => {
                dimensionsInputRefs.current[1] = el;
              }}
              sx={styles.dimensionsTextField}
              value={widthInput}
              inputProps={{ 'data-testid': packageWidthTestId }}
              onChange={(e) => {
                const newValue = e.target.value;
                const parsedFloat = Number.parseFloat(newValue);
                setWidthInput(newValue);
                if (!Number.isNaN(parsedFloat)) {
                  setValue(widthKey, parsedFloat);
                } else if (newValue === '') {
                  setValue(widthKey, null);
                }
              }}
            />
          </FormControl>
          <Typography>⨉</Typography>
          <FormControl>
            <TextField
              disabled={disabledIfFinalizedOrLater}
              size="small"
              inputRef={(el) => {
                dimensionsInputRefs.current[2] = el;
              }}
              sx={styles.dimensionsTextField}
              value={heightInput}
              inputProps={{ 'data-testid': packageHeightTestId }}
              onChange={(e) => {
                const newValue = e.target.value;
                const parsedFloat = Number.parseFloat(newValue);
                setHeightInput(newValue);
                if (!Number.isNaN(parsedFloat)) {
                  setValue(heightKey, parsedFloat);
                } else if (newValue === '') {
                  setValue(heightKey, null);
                }
              }}
            />
          </FormControl>
        </Box>
      </TableCell>
      <TableCell>
        {ffPackageSpecs ? (
          <AutocompleteFuzzy<
            PackageSpecEntity | PartialPackageSpec,
            false,
            true,
            false
          >
            disableClearable
            size="small"
            value={selectedPackageSpecOption}
            options={packageSpecOptions}
            matchSortOptions={{ keys: ['name'] }}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label="Type"
                sx={{ width: '100px' }}
              />
            )}
            disabled={disabledIfInvoicePosted}
            onChange={(_, packageSpec) => {
              if (!isNil(packageSpec)) {
                onChangePackageSpec(packageSpec);
              }
            }}
          />
        ) : (
          <AutocompleteFuzzy
            disableClearable
            size="small"
            value={{
              value: type,
              label: sentenceCase(type ?? ''),
            }}
            options={Object.values(PackageType).map((itrType) => ({
              value: itrType,
              label: sentenceCase(itrType),
            }))}
            matchSortOptions={{ keys: ['label'] }}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label="Type"
                sx={{ width: '100px' }}
              />
            )}
            disabled={disabledIfInvoicePosted}
            onChange={(_, { value }) => {
              if (!isNil(value)) {
                setValue(typeKey, value);
                setValue(
                  packageSpecIdKey,
                  isNil(allPackageSpecs)
                    ? null
                    : findCorrespondingPackageSpec(value, allPackageSpecs)?.id,
                );
              }
            }}
          />
        )}
      </TableCell>
      {packageWarehouseLocationsEnabled && (
        <TableCell>
          <Controller
            name={warehouseLocationUuidKey}
            control={control}
            render={({ field: { onChange, value } }) => (
              <WarehouseLocationSelector
                selectedWarehouseLocationUuid={value}
                disabled={disabledIfInvoicePosted}
                error={errors?.warehouseLocationUuid?.message?.toString()}
                onChange={(option) => {
                  onChange(option.value);
                  setValue(warehouseLocationNameKey, option.label);
                }}
              />
            )}
          />
        </TableCell>
      )}
      <TableCell>
        <Controller
          name={`packages.${idx}.description`}
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControl fullWidth>
              {isDescriptionExpanded ? (
                <Box
                  sx={{
                    position: 'absolute',
                    top: -10,
                    left: 0,
                    width: '100%', // Adjust expanded width as needed
                    zIndex: 1,
                    backgroundColor: 'white',
                  }}
                >
                  <TextField
                    autoFocus
                    multiline
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={value}
                    maxRows={10}
                    inputProps={{
                      style: {
                        textTransform: useAllCaps ? 'uppercase' : undefined,
                      },
                    }}
                    onChange={onChange}
                    onBlur={() => {
                      setIsDescriptionExpanded(false);
                    }}
                  />
                </Box>
              ) : (
                <TextField
                  size="small"
                  value={value}
                  inputProps={{
                    style: {
                      textTransform: useAllCaps ? 'uppercase' : undefined,
                    },
                  }}
                  disabled={disabledIfInvoicePosted}
                  onFocus={() => {
                    setIsDescriptionExpanded(true);
                  }}
                  onChange={onChange}
                />
              )}
            </FormControl>
          )}
        />
      </TableCell>
      <TableCell>
        {disabledIfFinalizedOrLater ? (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <></>
        ) : (
          <Fade in={isHovering}>
            <IconButton
              onClick={() => {
                deletePackage(uuid);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Fade>
        )}
      </TableCell>
    </TableRow>
  );
};

export default React.memo(PackageRow);
