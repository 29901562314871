// eslint-disable-next-line no-restricted-imports
import { Link, LinkProps } from '@mui/material';

/**
 * A canonical link that is used to provide a styled and flexible anchor element that can be used in a variety of contexts.
 * The current implementation is a wrapper around the MUI Link component.
 * NOTE: This component should NOT be used for purely navigating the single page application. For those use-cases, use the PalletRouterLink component.
 * Example use-cases for this component include:
 * 1. External links
 * 2. Links that require a click handler
 * @param props - The props for the link.
 * @returns A styled link that is used to navigate to a different route.
 */
const PalletLink = (props: LinkProps) => <Link {...props} />;

export default PalletLink;
