import { Button, Stack, Typography } from '@mui/material';
import { type FunctionComponent } from 'react';

type RescheduleAppointmentDialogProps = {
  readonly handleAppointmentReschedule: () => void;
  readonly loading: boolean;
  readonly pickupOrDelivery?: string | undefined;
  readonly onBack: () => void;
};

export const ConfirmRescheduleAppointment: FunctionComponent<
  RescheduleAppointmentDialogProps
> = ({ handleAppointmentReschedule, loading, pickupOrDelivery, onBack }) => {
  return (
    <Stack
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        gap: 3,
      }}
    >
      <Typography fontWeight="500">
        If you request to reschedule this appointment, your{' '}
        {pickupOrDelivery ?? 'order'} will be delayed 3-5 business days.
      </Typography>
      <Button
        color="error"
        disabled={loading}
        variant="contained"
        onClick={handleAppointmentReschedule}
      >
        Confirm Reschedule Request
      </Button>
      <Button disabled={loading} variant="text" onClick={onBack}>
        Back
      </Button>
    </Stack>
  );
};
