import { TableRow, useTheme, TableCell } from '@mui/material';
import currency from 'currency.js';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { filterNotNil } from 'shared/array';
import { FeatureFlag } from '../../../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../../../common/react-hooks/use-feature-flag';
import { ShipmentType } from '../../../../../../../generated/graphql';
import { StopType } from '../../../forms/stop-type';
import { type OrderFormValues } from '../../../forms/types';
import { useShouldShowStopChargesSection } from '../../../hooks/use-expected-order-components';
import DriverSettlementCustomChargeRow from './driver-settlement-custom-charge-row';
import DriverSettlementDeductionRow from './driver-settlement-deduction-row';
import DriverSettlementFreightChargeRow from './driver-settlement-freight-charge-row';
import DriverSettlementFuelChargeRow from './driver-settlement-fuel-charge-row';
import DriversTable from './drivers-table';

const DriverSettlementTable = ({ idx }: { readonly idx: number }) => {
  const { control } = useFormContext<OrderFormValues>();
  const stopType = useWatch({ control, name: `stops.${idx}.stopType` });
  const customCharges = filterNotNil(
    useWatch({ control, name: `stops.${idx}.customCharges` }) ?? [],
  );
  const theme = useTheme();
  const ffDemoLoadManagement = useFeatureFlag(
    FeatureFlag.FF_DEMO_LOAD_MANAGEMENT,
  );
  const freightChargeSettlement = useWatch({
    control,
    name: `stops.${idx}.freightCharge.deductionTotal`,
  });
  const fuelChargeSettlement = useWatch({
    control,
    name: `stops.${idx}.freightCharge.fuelCharge.deductionTotal`,
  });
  const totalSettlement = (
    customCharges?.reduce(
      (prev, curr) => prev.add(curr.deductionTotal ?? 0),
      currency(0),
    ) ?? currency(0)
  )
    .add(freightChargeSettlement ?? 0)
    .add(fuelChargeSettlement ?? 0).value;

  const { showSection } = useShouldShowStopChargesSection({
    stopIdx: idx,
  });

  if (!showSection) {
    return null;
  }

  return (
    <>
      <TableRow sx={{ backgroundColor: theme.palette.grey[100] }}>
        <TableCell>{stopType?.toString().toUpperCase()}</TableCell>
        <TableCell />
        <TableCell />
        <TableCell />
      </TableRow>
      {stopType !== StopType.Recovery && (
        <DriverSettlementFreightChargeRow
          context={{ shipmentType: ShipmentType.Regular, stopIdx: idx }}
        />
      )}
      {stopType !== StopType.Recovery && (
        <DriverSettlementFuelChargeRow
          context={{ shipmentType: ShipmentType.Regular, stopIdx: idx }}
        />
      )}
      {customCharges?.map((cc, customChargeIdx) => (
        <DriverSettlementCustomChargeRow
          key={cc.uuid}
          context={{
            shipmentType: ShipmentType.Regular,
            stopIdx: idx,
          }}
          customChargeIdx={customChargeIdx}
        />
      ))}
      <DriverSettlementDeductionRow idx={idx} totalCharge={totalSettlement} />
      <TableRow>
        <TableCell sx={{ fontWeight: 500 }}>
          Eligible {ffDemoLoadManagement ? 'Settlement' : 'Driver Settlement'}{' '}
          Total
        </TableCell>
        <TableCell />
        <TableCell>{currency(totalSettlement).format()}</TableCell>
      </TableRow>
      <DriversTable idx={idx} />
    </>
  );
};

export default React.memo(DriverSettlementTable);
