import { List, ListItem, Stack, Typography } from '@mui/material';
import { type MoveShipmentsBetweenInvoicesIncompleteOperationOutput } from '../../../../generated/graphql';
import PalletModal from '../../../../pallet-ui/modal/pallet-modal-old';

type AdditionalShipmentsToBeMovedModalProps = {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly moveOperationOutput: MoveShipmentsBetweenInvoicesIncompleteOperationOutput;
  readonly handleConfirm: () => void;
};

const AdditionalShipmentsToBeMovedToNewInvoiceModal = ({
  isOpen,
  onClose,
  handleConfirm,
  moveOperationOutput,
}: AdditionalShipmentsToBeMovedModalProps) => {
  return (
    <PalletModal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      title="Additional orders need to be moved"
      actions={{
        bottomRight: [
          {
            children: 'Confirm',
            color: 'info',
            onClick: () => {
              handleConfirm();
            },
          },
        ],
      }}
      onClose={onClose}
    >
      <Stack direction="column" spacing={2}>
        <Typography>
          The following orders will also be moved to the new invoice:
        </Typography>
        <List>
          {moveOperationOutput?.missingOrders.map((order) => (
            <ListItem key={order.uuid}>{order.name}</ListItem>
          ))}
        </List>
      </Stack>
    </PalletModal>
  );
};

export default AdditionalShipmentsToBeMovedToNewInvoiceModal;
