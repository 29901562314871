import { Box, FormHelperText, TextField } from '@mui/material';
import { isNil } from 'lodash';
import { type FunctionComponent, useEffect, useState } from 'react';
import {
  parsePlainTime,
  plainTimeToHourAndMinute,
} from 'shared/plain-date-time';
import type { PlainTimeInput } from '../../generated/graphql';

type PlainTimeInputFieldProps = {
  readonly time: PlainTimeInput | undefined | null;
  readonly onChange: (time: PlainTimeInput | null) => void;
  readonly disabled?: boolean;
  readonly placeholder?: string;
  readonly width?: string | number;
};

/**
 * An input field for times.
 * Accepts strings in the 'HH:mm' or 'HHmm' or 'H:mm' format but always outputs 'HH:mm'.
 */
export const PlainTimeInputField: FunctionComponent<
  PlainTimeInputFieldProps
> = ({ time, onChange, disabled = false, placeholder, width }) => {
  const [timeInput, setTimeInput] = useState(() =>
    isNil(time) ? '' : plainTimeToHourAndMinute(time),
  );
  const [error, setError] = useState<string | null>(null);
  const [hasBlurred, setHasBlurred] = useState(false);

  useEffect(() => {
    if (!isNil(time) && timeInput.length === 0) {
      setTimeInput(plainTimeToHourAndMinute(time));
    }
  }, [time, timeInput]);

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <TextField
          value={timeInput}
          disabled={disabled}
          placeholder={placeholder}
          variant="outlined"
          size="small"
          sx={{
            backgroundColor: 'white',
          }}
          inputProps={{
            style: { width },
          }}
          onChange={(e) => {
            setHasBlurred(false);
            const newTimeInput = e.target.value.trim();
            setTimeInput(newTimeInput);
            const parsedTime = parsePlainTime(newTimeInput);
            onChange(parsedTime);
            if (newTimeInput.length > 0 && isNil(parsedTime)) {
              setError('Time is invalid.');
            } else {
              setError(null);
            }
          }}
          onBlur={() => {
            setHasBlurred(true);
          }}
        />
      </Box>
      {!isNil(error) && hasBlurred && (
        <FormHelperText error>{error}</FormHelperText>
      )}
    </Box>
  );
};
