import { Alert, Snackbar } from '@mui/material';
import { shallow } from 'zustand/shallow';
import useGlobalStore from '../../../layouts/dashboard/global-store';

/**
 * A global success alert that can be used across use-cases. The mutator methods
 * for the alert (displaying the alert and the message in the alert can be accessed via
 * the global zustand store.
 *
 */
const GlobalSuccessSnackbar = () => {
  const [successMessage, showSuccessMessage, setShowSuccessMessage] =
    useGlobalStore(
      (state) => [
        state.successMessage,
        state.showSuccessMessage,
        state.setShowSuccessMessage,
      ],
      shallow,
    );
  return (
    <Snackbar
      autoHideDuration={3000}
      open={showSuccessMessage}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={() => {
        setShowSuccessMessage(false);
      }}
    >
      <Alert>{successMessage}</Alert>
    </Snackbar>
  );
};

export default GlobalSuccessSnackbar;
