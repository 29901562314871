import {
  Button,
  Stack,
  TextField,
  type TextFieldProps,
  useTheme,
} from '@mui/material';
import { type MouseEventHandler } from 'react';

type CommentInputProps = TextFieldProps & {
  readonly onSubmit: MouseEventHandler<HTMLButtonElement>;
  readonly onCancel?: () => void; // Only passed in to cancel edits.
  readonly isEditMode?: boolean;
};

const CommentInput = ({
  value,
  onChange,
  disabled,
  onSubmit,
  onCancel,
  isEditMode,
  ...rest
}: CommentInputProps) => {
  const theme = useTheme();

  // Submit comment on Cmd + Enter
  const onKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && event.metaKey) {
      onSubmit(event);
    }
  };

  const textFieldComponent = (
    <TextField
      multiline
      fullWidth
      value={value}
      placeholder="Leave a note"
      size="small"
      disabled={disabled}
      onChange={onChange}
      onKeyDown={onKeyDownHandler}
      {...rest}
    />
  );

  const buttonComponents =
    isEditMode === true ? (
      <Stack direction="row" justifyContent="end" sx={{ width: '100%' }}>
        <Button sx={{ color: theme.palette.grey['600'] }} onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="outlined" disabled={disabled} onClick={onSubmit}>
          Save
        </Button>
      </Stack>
    ) : (
      <Button variant="outlined" disabled={disabled} onClick={onSubmit}>
        Add
      </Button>
    );

  return (
    <Stack
      direction={isEditMode === true ? 'column' : 'row'}
      spacing={1}
      alignItems="center"
      sx={{ width: '100%', pb: 3 }}
    >
      {textFieldComponent}
      {buttonComponents}
    </Stack>
  );
};

export default CommentInput;
