import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useState } from 'react';
import { useMarkStopAsIncompleteMutation } from '../../../../../../../generated/graphql';
import { useUpdateAndRefetchOrder } from '../../../../order-form/hooks/use-update-and-refetch-order';
import { type OnSubmitParams } from '../../../../order-form/types';

type ConfirmMarkIncompleteModalProps = {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly onSave: (params: OnSubmitParams) => Promise<boolean>;
  readonly stopUuid: string;
};

const ConfirmMarkIncompleteModal: React.FC<ConfirmMarkIncompleteModalProps> = ({
  open,
  onClose,
  onSave,
  stopUuid,
}: ConfirmMarkIncompleteModalProps) => {
  const [markStopAsIncomplete] = useMarkStopAsIncompleteMutation();
  const [loading, setLoading] = useState<boolean>(false);
  const { updateAndRefetchOrder } = useUpdateAndRefetchOrder();

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle>Mark stop as incomplete</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to mark this stop as incomplete? This action
          cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          startIcon={loading ? <CircularProgress size={10} /> : null}
          variant="contained"
          disabled={loading}
          onClick={async () => {
            setLoading(true);
            await updateAndRefetchOrder({
              additionalUpdateFns: [
                {
                  fn: markStopAsIncomplete,
                  vars: { uuid: stopUuid },
                },
              ],
              onSubmit: onSave,
              actionString: 'marking stop as incomplete',
            });
            setLoading(false);
            onClose();
          }}
        >
          Mark incomplete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmMarkIncompleteModal;
