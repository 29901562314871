import { z } from 'zod';
import { zDateOrDatetimeString } from './zod-utils';

export const stopDriverMapSchema = z.object({
  uuid: z.string().uuid(),
  stopUuid: z.string().uuid(),
  driverUuid: z.string().uuid(),
  driverSettlementBillUuid: z.string().uuid().nullish(),
  name: z.string().nullish(),
  enableDriverSettlement: z.boolean().nullish(),
  revenuePercentageRate: z.number().nullish(),
  revenueFlatRate: z.number().nullish(),
  driverPayoutFinalized: z.boolean(),
  isAttempt: z.boolean(),
  attemptedAt: zDateOrDatetimeString().nullish(),
});
