import { FormControl, TextField, type TextFieldProps } from '@mui/material';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  Controller,
  type FieldPath,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import {
  isValidPartialOrCompleteNumberInput,
  isValidPartialOrCompleteNonNegativeIntegerInput,
} from '../../../../../../../utils';
import { type OrderFormFieldValues } from '../../forms/types';

/**
 * To be used with React hook order form
 * Provides input validation for number inputs
 */
const FormNumberInput = (
  props: TextFieldProps & {
    readonly fieldName: FieldPath<OrderFormFieldValues>;
    /** If true, input must be a non-negative integer */
    readonly nonNegativeInteger?: boolean;
  },
) => {
  const { fieldName, nonNegativeInteger, onBlur, onChange, ...textFieldProps } =
    props;

  const { control } = useFormContext<OrderFormFieldValues>();
  const value = useWatch({ control, name: fieldName });
  const [numberInput, setNumberInput] = useState<string>();

  useEffect(() => {
    // Coalesce to '' rather than undefined to make sure the TextField updates
    setNumberInput(value?.toString() ?? '');
  }, [value]);

  const checkInput = (input: string) => {
    if (nonNegativeInteger === true) {
      return isValidPartialOrCompleteNonNegativeIntegerInput(input);
    }
    return isValidPartialOrCompleteNumberInput(input);
  };

  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field: { onChange: controllerOnChange } }) => (
        <FormControl fullWidth>
          <TextField
            size="small"
            value={numberInput ?? value}
            onBlur={(e) => {
              if (isNil(onBlur)) {
                const parsedAmount = Number.parseFloat(numberInput ?? '');
                const newValue = Number.isNaN(parsedAmount)
                  ? null
                  : parsedAmount;
                setNumberInput(newValue?.toString() ?? '');
                controllerOnChange(newValue);
              } else {
                onBlur(e);
              }
            }}
            onChange={(e) => {
              if (!isNil(onChange)) {
                onChange(e);
              } else if (checkInput(e.target.value)) {
                setNumberInput(e.target.value);
                const parsedAmount = Number.parseFloat(e.target.value);
                if (!Number.isNaN(parsedAmount)) {
                  controllerOnChange(parsedAmount);
                }
              }
            }}
            {...textFieldProps}
          />
        </FormControl>
      )}
    />
  );
};

export default FormNumberInput;
