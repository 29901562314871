import { isNil } from 'lodash';
import { isNilOrEmptyString } from 'shared/string';
import { z } from 'zod';
import { BillingMethod } from '../../../../../common/types';
import {
  CustomChargeBillingMethod,
  AccessorialType,
  type AccessorialsByBillingContactQuery,
} from '../../../../../generated/graphql';

type AccessorialForCustomCharge = Pick<
  AccessorialsByBillingContactQuery['accessorialsByBillingContact'][number],
  'uuid' | 'name' | 'isAuthoCodeRequired'
>;

export type CustomChargeSchemaOptions = {
  accessorials: AccessorialForCustomCharge[];
  disallowZeroDollarCharges: boolean;
};

export const getCustomChargeSchema = ({
  accessorials,
  disallowZeroDollarCharges,
}: CustomChargeSchemaOptions) =>
  z
    .object({
      billingMethod: z.nativeEnum(CustomChargeBillingMethod),
      // This can represent currency or a count. When currency, this represents a
      // dollar amount
      quantity: z.number().nullable(),
      // This is a dollar amount, e.g. 1.50 means a rate of $1.50 per X
      rate: z.number().nullable(),
      // This is a percentage out of 100
      fuelSurchargePercentageRate: z.number().nullable(),
      totalCharge: z.number(),
      accessorialUuid: z.string().uuid().nullable(),
      accessorialName: z.string().min(1).nullable(),
      accessorialType: z.nativeEnum(AccessorialType).nullable(),
      isAutoApplied: z.boolean(),
      uuid: z.string().uuid(),
      specialAccessorialMatrixItemUuid: z.string().uuid().nullable(),
      zoneBasedAccessorialMatrixItemUuid: z.string().uuid().nullable(),
      zoneUuid: z.string().uuid().nullable(),
      chargeGroupUuid: z.string().uuid().nullable(),
      accessorialRangeUuid: z.string().uuid().nullable(),

      // Set when charges are finalized, cleared when unfinalized. Prefer this value
      postedFuelSurchargeRate: z.number().nullable(),
      isLocal: z.boolean(),
      description: z.string().nullable(),
      authoCode: z.string().nullable(),
      name: z.string().nullable(),
      settlementPercentageRate: z.number().nullable(),
      settlementFlatRate: z.number().nullable(),
      settlementBillingMethod: z.nativeEnum(BillingMethod).nullable(),
      deductionTotal: z.number(),
      // Not persisted. Used to track whether the rate should be taken from the Accessorial object rather than the form.
      useAccessorialRate: z.boolean(),
    })
    .refine(
      (data) =>
        !(
          data.accessorialType === AccessorialType.Special &&
          isNil(data.specialAccessorialMatrixItemUuid)
        ),
      {
        message: 'Special charges must have a rate selected',
        path: ['specialAccessorialMatrixItemUuid'],
      },
    )
    .superRefine((data, ctx) => {
      if (
        data.accessorialType === AccessorialType.ZoneBased &&
        (isNil(data.zoneUuid) || isNil(data.chargeGroupUuid))
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Zone and charge group are required for ${data.accessorialName ?? 'zone-based accessorials'}`,
          path: ['zoneUuid', 'chargeGroupUuid'],
        });
      }
    })
    .superRefine((data, ctx) => {
      const accessorial = accessorials.find(
        (a) => a.uuid === data.accessorialUuid,
      );
      if (isNil(accessorial)) {
        return;
      }
      if (
        accessorial.isAuthoCodeRequired &&
        isNilOrEmptyString(data.authoCode?.trim())
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Autho code is required for ${accessorial.name}`,
          path: ['authoCode'],
        });
      }
    })
    .refine((data) => {
      if (!disallowZeroDollarCharges) {
        return true;
      }
      const isNonAccessorialCustomCharge = isNil(data.accessorialUuid);
      if (
        data.totalCharge === 0 ||
        (isNonAccessorialCustomCharge && data.rate === 0)
      ) {
        return false;
      }
      return true;
    }, 'Please remove any $0 charges before saving the order');
