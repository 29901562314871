import { Checkbox, TextField } from '@mui/material';
import { type Option } from '../../../common/filters/types';
import { convertValueArrayToOptionArray } from '../../../common/utils/utils';
import AutocompleteFuzzy from '../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import {
  BaseFilterInputInputPropsSx,
  type BaseFilterInputProps,
} from '../orders/components/filters/filter-utils';

type FilterMultiSelectInputProps = {
  readonly filterOptionMappings: Option[];
} & BaseFilterInputProps;

const FilterMultiSelectInput = ({
  filterValueInput,
  filterOperationInput,
  filterNameInput,
  setFilter,
  filterOptionMappings,
}: FilterMultiSelectInputProps) => {
  return (
    <AutocompleteFuzzy
      multiple
      disableCloseOnSelect
      size="small"
      sx={{ backgroundColor: 'white', width: '160px', minHeight: '32px' }}
      value={convertValueArrayToOptionArray({
        values: filterValueInput as string[],
        optionsList: filterOptionMappings,
      })}
      options={filterOptionMappings ?? []}
      matchSortOptions={{ keys: ['label'] }}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          placeholder="Value"
          InputProps={{
            ...params.InputProps,
            sx: {
              ...BaseFilterInputInputPropsSx,
              marginLeft: '-2px',
            },
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        />
      )}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderOption={(props, option, { selected }) => (
        <li {...props} style={{ padding: 0 }}>
          <Checkbox checked={selected} />
          {option.label}
        </li>
      )}
      onChange={(_, selected) => {
        setFilter({
          filter: filterNameInput,
          op: filterOperationInput,
          value: selected?.map((option) => option.value) ?? null,
        });
      }}
    />
  );
};

export default FilterMultiSelectInput;
