import { type AgGridReactProps } from 'ag-grid-react';
import invokeAll from './invoke-all';

const removeHiddenColumnFromSorts: AgGridReactProps['onColumnVisible'] = (
  e,
) => {
  const sorts = e.columnApi.getColumnState().filter((column) => column.sort);
  const sortsWithoutColumnInEvent = sorts.filter(
    (sort) => sort.colId !== e.column?.getColId(),
  );
  e.columnApi.applyColumnState({
    state: sortsWithoutColumnInEvent,
    defaultState: { sort: null },
  });
};

const useOnColumnVisible = (
  onColumnVisiblePropsFunction: AgGridReactProps['onColumnVisible'],
) => {
  return invokeAll(removeHiddenColumnFromSorts, onColumnVisiblePropsFunction);
};

export default useOnColumnVisible;
