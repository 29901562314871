import { Typography } from '@mui/material';
import { isNil } from 'lodash';
import React from 'react';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import { isNilOrEmptyString } from '../../../../common/utils/utils';
import {
  type PackageForCachedStop,
  type ShallowPackageFragment,
} from '../../../../generated/graphql';

const PackageInfoText = ({
  pkg,
  width,
}: {
  readonly pkg: ShallowPackageFragment | PackageForCachedStop | undefined;
  readonly width?: number | undefined;
}) => {
  const ffNewDispatchPage = useFeatureFlag(FeatureFlag.FF_NEW_DISPATCH_PAGE);
  const dimsString =
    isNil(pkg?.length) && isNil(pkg?.width) && isNil(pkg?.height)
      ? '-'
      : `${pkg?.length ?? ''}x${pkg?.width ?? ''}x${pkg?.height ?? ''}`;
  return (
    <Typography
      variant="caption"
      sx={{ maxWidth: isNil(width) ? undefined : width }}
    >
      {pkg?.quantity ?? '-'} / {dimsString} / {pkg?.weight ?? '-'}
      {!isNilOrEmptyString(pkg?.description) &&
        ffNewDispatchPage &&
        ` / ${pkg?.description}`}
    </Typography>
  );
};

export default PackageInfoText;
