import { exhaustive } from 'shared/switch';
import { AccountingReportTypeV2 } from '../../../generated/graphql';

/**
 * An enum representing the different options to email reports.
 * The current options include:
 * 1. Sending the report to specific emails that are specified by the user.
 * 2. Sending a contact-specific aging report to all contacts' primary emails.
 */
export enum EmailReportOption {
  SEND_TO_SPECIFIC_EMAILS = 'Send to specific emails',
  SEND_TO_ALL_CONTACTS = 'Send to all customers',
}

export function getEmailReportOptionCopy({
  option,
  reportType,
}: {
  option: EmailReportOption;
  reportType: AccountingReportTypeV2;
}): string {
  switch (option) {
    case EmailReportOption.SEND_TO_ALL_CONTACTS: {
      if (reportType === AccountingReportTypeV2.AgingReportDetailed) {
        return 'Send to all customers with open balances';
      }
      return 'Send to all customers';
    }
    case EmailReportOption.SEND_TO_SPECIFIC_EMAILS: {
      return 'Send to specific emails';
    }
    default: {
      return exhaustive(option);
    }
  }
}
