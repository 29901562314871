import { Box } from '@mui/material';
import { isNil } from 'lodash';
import React from 'react';
import { Marker } from 'react-map-gl';
import TerminalMarkerIcon from './terminal-marker-icon';

type TerminalMarkerProps = {
  readonly address: {
    longitude?: number | null;
    latitude?: number | null;
  };
  readonly text?: string;
  readonly color: string;
};

const TerminalMarker = ({ address, color, text }: TerminalMarkerProps) => {
  return (
    <Box>
      {!isNil(address.longitude) && !isNil(address.latitude) && (
        <Marker
          longitude={address.longitude}
          latitude={address.latitude}
          anchor="bottom"
        >
          <TerminalMarkerIcon color={color} text={text} />
        </Marker>
      )}
    </Box>
  );
};

export default React.memo(TerminalMarker);
