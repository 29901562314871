import { Divider } from '@mui/material';
import type { FunctionComponent } from 'react';

type GridDividerProps = {
  // The number of grid columns for the divider to span.
  readonly span: number;
};

/**
 * A horizontal divider line that spans a specified number of columns.
 */
export const GridDivider: FunctionComponent<GridDividerProps> = ({ span }) => (
  <Divider sx={{ gridColumn: `1 / span ${span}` }} />
);
