import { sentenceCase } from 'change-case';
import dayjs, { type ManipulateType } from 'dayjs';
import { isNil, omit } from 'lodash';
import { exhaustive } from 'shared/switch';
import {
  type ReportGroupConfigurationFragment,
  type ReportGroupConfigurationsQuery,
  type ReportGroupConfigurationUpdateInput,
} from '../../generated/graphql';
import { getDriverNameLabel } from './components/utils';
import { ReportFilterType } from './types/report-filters';
import {
  type OrderReportBucketData,
  type ReportGroupConfiguration,
} from './types/report-types';

export const convertReportGroupConfigurationToUpdateInputType = (
  reportGroupConfigurationToUpdate: ReportGroupConfiguration,
): ReportGroupConfigurationUpdateInput => {
  return {
    ...omit(reportGroupConfigurationToUpdate, [
      '__typename',
      'contact',
      'contacts',
      'driver',
      'drivers',
      'businessDivision',
      'terminal',
    ]),

    uuid: reportGroupConfigurationToUpdate.uuid,
    contactUuid: reportGroupConfigurationToUpdate.contact?.uuid ?? null,
    contactUuids:
      reportGroupConfigurationToUpdate.contacts?.map(
        (contact) => contact.uuid,
      ) ?? [],
    businessDivisionUuid:
      reportGroupConfigurationToUpdate.businessDivision?.uuid ?? null,
    driverUuid: reportGroupConfigurationToUpdate.driver?.uuid ?? null,
    driverUuids:
      reportGroupConfigurationToUpdate.drivers?.map((driver) => driver.uuid) ??
      [],
    terminalUuid: reportGroupConfigurationToUpdate.terminal?.uuid ?? null,
    lastNumberOfDays: reportGroupConfigurationToUpdate.lastNumberOfDays ?? null,
    lastNumberOfWeeks:
      reportGroupConfigurationToUpdate.lastNumberOfWeeks ?? null,
    lastNumberOfMonths:
      reportGroupConfigurationToUpdate.lastNumberOfMonths ?? null,
  };
};

export const convertCreateDataToReportGroupConfiguration = (
  data: ReportGroupConfigurationFragment,
): ReportGroupConfiguration => {
  return {
    ...data,

    contact: isNil(data.contact)
      ? undefined
      : {
          uuid: data.contact?.uuid,
          displayName: data.contact?.displayName,
        },
    contacts: isNil(data.contacts)
      ? undefined
      : data.contacts.map((contact) => ({
          uuid: contact.uuid,
          displayName: contact.displayName,
        })),
    driver: isNil(data.driver)
      ? undefined
      : {
          uuid: data.driver?.uuid,
          firstName: data.driver?.firstName,
          lastName: data.driver?.lastName,
        },
    drivers: isNil(data.drivers)
      ? undefined
      : data.drivers.map((driver) => ({
          uuid: driver.uuid,
          firstName: driver.firstName,
          lastName: driver.lastName,
        })),
    lastNumberOfDays: data.lastNumberOfDays ?? undefined,
    lastNumberOfWeeks: data.lastNumberOfWeeks ?? undefined,
    lastNumberOfMonths: data.lastNumberOfMonths ?? undefined,
    startDate: data.startDate,
    endDate: data.endDate,
  };
};

export const convertDataToReportGroupConfigurations = (
  data: ReportGroupConfigurationsQuery,
): ReportGroupConfiguration[] => {
  return data.reportGroupConfigurations
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((config) => convertCreateDataToReportGroupConfiguration(config));
};

const getLastUnitsDateRangeString = (unit: ManipulateType, value: number) => {
  const startDate = dayjs()
    .tz()
    .subtract(value - 1, unit)
    .startOf(unit);
  const endDate = dayjs().tz().endOf(unit);
  return `${startDate.format('MM/DD')} - ${endDate.format('MM/DD/YY')}`;
};

export const getDateRangeLabel = (
  reportGroupConfiguration: ReportGroupConfiguration | undefined,
) => {
  let filterValue = '';
  if (!isNil(reportGroupConfiguration?.lastNumberOfDays)) {
    filterValue = getLastUnitsDateRangeString(
      'day',
      reportGroupConfiguration?.lastNumberOfDays ?? 0,
    );
  } else if (!isNil(reportGroupConfiguration?.lastNumberOfWeeks)) {
    filterValue = getLastUnitsDateRangeString(
      'week',
      reportGroupConfiguration?.lastNumberOfWeeks ?? 0,
    );
  } else if (!isNil(reportGroupConfiguration?.lastNumberOfMonths)) {
    filterValue = getLastUnitsDateRangeString(
      'month',
      reportGroupConfiguration?.lastNumberOfMonths ?? 0,
    );
  } else if (isNil(reportGroupConfiguration?.startDate)) {
    filterValue = 'All';
  } else {
    filterValue = `${dayjs(reportGroupConfiguration?.startDate).format(
      'MM/DD',
    )} - ${dayjs(reportGroupConfiguration?.endDate).format('MM/DD/YY')}`;
  }
  return filterValue;
};

export const getFilterLabelValue = (
  type: ReportFilterType,
  reportGroupConfiguration: ReportGroupConfiguration | undefined,
) => {
  let filterValue = '';
  const stopTypes = reportGroupConfiguration?.stopTypes;
  const orderStatuses = reportGroupConfiguration?.orderStatuses;
  const contact = reportGroupConfiguration?.contact;
  const driver = reportGroupConfiguration?.driver;
  const businessDivision = reportGroupConfiguration?.businessDivision;
  const terminal = reportGroupConfiguration?.terminal;
  const contacts = reportGroupConfiguration?.contacts;
  const drivers = reportGroupConfiguration?.drivers;
  switch (type) {
    case ReportFilterType.SERVICE_DATE_RANGE: {
      filterValue = getDateRangeLabel(reportGroupConfiguration);
      break;
    }
    case ReportFilterType.REPORT_TYPE: {
      filterValue = sentenceCase(
        reportGroupConfiguration?.defaultReportType ?? '',
      );
      break;
    }
    case ReportFilterType.REVENUE_TYPE: {
      filterValue = sentenceCase(
        reportGroupConfiguration?.reportRevenueType ?? '',
      );
      break;
    }
    case ReportFilterType.AGGREGATION_PERIOD: {
      filterValue = sentenceCase(
        reportGroupConfiguration?.reportAggregationPeriod ?? '',
      );
      break;
    }
    case ReportFilterType.STOP_TYPE: {
      filterValue =
        isNil(stopTypes) || stopTypes.length === 0
          ? `All`
          : stopTypes
              .map((pickupOrDelivery) => sentenceCase(pickupOrDelivery))
              .join(', ');
      break;
    }
    case ReportFilterType.ORDER_STATUS: {
      filterValue =
        isNil(orderStatuses) || orderStatuses.length === 0
          ? `All`
          : orderStatuses
              .map((orderStatus) => sentenceCase(orderStatus))
              .join(', ');
      break;
    }
    case ReportFilterType.CONTACT: {
      filterValue = contact?.displayName ?? 'All';
      break;
    }
    case ReportFilterType.CONTACTS: {
      filterValue =
        isNil(contacts) || contacts.length === 0
          ? 'All'
          : `${contacts.length} selected`;
      break;
    }
    case ReportFilterType.DRIVER: {
      filterValue = isNil(driver) ? 'All' : getDriverNameLabel(driver);
      break;
    }
    case ReportFilterType.DRIVERS: {
      filterValue =
        isNil(drivers) || drivers.length === 0
          ? 'All'
          : `${drivers.length} selected`;
      break;
    }
    case ReportFilterType.BUSINESS_DIVISION: {
      filterValue = isNil(businessDivision) ? 'All' : businessDivision.name;
      break;
    }
    case ReportFilterType.TERMINAL: {
      filterValue = isNil(terminal) ? 'All' : terminal.code;
      break;
    }
    default: {
      exhaustive(type);
    }
  }

  return filterValue;
};

export const getOrderReportDataTotals = (
  orderReportData: OrderReportBucketData[] | undefined,
): {
  totalRevenue: number;
  freightRevenue: number;
  fuelRevenue: number;
  customChargeRevenue: number;
  numberOfPackages: number;
  totalWeight: number;
  numberOfOrders: number;
} => {
  const data = isNil(orderReportData) ? [] : orderReportData;
  return data.reduce(
    (accumulator, currentValue) => {
      return {
        totalRevenue: accumulator.totalRevenue + currentValue.totalRevenue,
        freightRevenue:
          accumulator.freightRevenue + currentValue.freightRevenue,
        fuelRevenue: accumulator.fuelRevenue + currentValue.fuelRevenue,
        customChargeRevenue:
          accumulator.customChargeRevenue + currentValue.customChargeRevenue,
        numberOfPackages:
          accumulator.numberOfPackages + currentValue.numberOfPackages,
        totalWeight: accumulator.totalWeight + currentValue.totalWeight,
        numberOfOrders:
          accumulator.numberOfOrders + currentValue.numberOfOrders,
      };
    },
    {
      totalRevenue: 0,
      freightRevenue: 0,
      fuelRevenue: 0,
      customChargeRevenue: 0,
      numberOfPackages: 0,
      totalWeight: 0,
      numberOfOrders: 0,
    },
  );
};
