import { Image } from '@react-pdf/renderer';
import QRCodeSVG from 'qrcode';

type QRCodeProps = {
  readonly value: string;
  readonly width?: number | string;
};

const QRCode = ({ value, width }: QRCodeProps) => {
  const codeUrl = QRCodeSVG.toDataURL(value);
  return <Image style={{ width, aspectRatio: 1 }} src={codeUrl} />;
};

export default QRCode;
