import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Skeleton, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import { isEmpty, isNil } from 'lodash';
import React, { type ReactNode } from 'react';
import { exhaustive } from 'shared/switch';
import { formatWeightUnits } from '../../../common/utils/prettyPrintUtils';
import {
  type DetailedLineHaulManifestFragment,
  LinehaulDispatchTableField,
} from '../../../generated/graphql';
import { formattedDateAbbreviated } from '../../dispatch/utils';
import TagsCell from '../../orders/components/ag-grid-cell-components/tags-cell-component';
import SegmentCell from '../components/segment-cell';
import { ManifestTab } from '../store/line-haul-dispatch-store';
import { type SegmentedLineHaulOrder } from '../types';

const styles = {
  textContainer: {
    display: 'inline-grid',
  },
  ellipsisText: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap' as const,
    overflow: 'hidden',
  },
};

export const getLineHaulOrdersTableFieldCopy = (
  field: LinehaulDispatchTableField,
): string => {
  switch (field) {
    case LinehaulDispatchTableField.Name: {
      return 'Name';
    }
    case LinehaulDispatchTableField.Hawb: {
      return 'HAWB';
    }
    case LinehaulDispatchTableField.Mawb: {
      return 'MAWB';
    }
    case LinehaulDispatchTableField.Segment: {
      return 'Segment';
    }
    case LinehaulDispatchTableField.NextSegment: {
      return 'Next segment';
    }
    case LinehaulDispatchTableField.Lane: {
      return 'Lane';
    }
    case LinehaulDispatchTableField.Service: {
      return 'Service level';
    }
    case LinehaulDispatchTableField.ServiceDate: {
      return 'Service date';
    }
    case LinehaulDispatchTableField.Weight: {
      return 'Weight';
    }
    case LinehaulDispatchTableField.DimWeight: {
      return 'Dim. weight';
    }
    case LinehaulDispatchTableField.IsHazmat: {
      return 'Hazmat';
    }
    case LinehaulDispatchTableField.IsSpecial: {
      return 'Special';
    }
    case LinehaulDispatchTableField.InboundAddressName: {
      return 'Inbound address';
    }
    case LinehaulDispatchTableField.OutboundAddressName: {
      return 'Outbound address';
    }
    case LinehaulDispatchTableField.CustomerName: {
      return 'Customer';
    }
    case LinehaulDispatchTableField.PieceCount: {
      return 'Piece count';
    }
    case LinehaulDispatchTableField.Tags: {
      return 'Tags';
    }
    case LinehaulDispatchTableField.DeadlineDate: {
      return 'Deadline date';
    }
    default: {
      return exhaustive(field);
    }
  }
};

const getEllipsisTextCell = (text: string) => {
  return (
    <Tooltip title={text}>
      <div style={styles.textContainer}>
        <span style={styles.ellipsisText}>{text}</span>
      </div>
    </Tooltip>
  );
};

export const getLineHaulOrdersTableField = (
  field: LinehaulDispatchTableField,
  segmentedOrder: SegmentedLineHaulOrder | undefined,
): ReactNode => {
  if (isNil(segmentedOrder)) {
    return '-';
  }
  switch (field) {
    case LinehaulDispatchTableField.Name: {
      return segmentedOrder.order.name ?? '-';
    }
    case LinehaulDispatchTableField.Hawb: {
      const hawb =
        segmentedOrder.order.standardOrderFields.shipperBillOfLadingNumber ??
        '';

      return getEllipsisTextCell(hawb);
    }
    case LinehaulDispatchTableField.Mawb: {
      const mawb =
        segmentedOrder.order.standardOrderFields
          .masterAirwayBillOfLadingNumber ?? '';

      return getEllipsisTextCell(mawb);
    }
    case LinehaulDispatchTableField.Segment: {
      return <SegmentCell segmentedOrder={segmentedOrder} />;
    }
    case LinehaulDispatchTableField.NextSegment: {
      return isNil(segmentedOrder.upcomingLineHaulSegment) ? (
        <Box width="100px !important">
          <Skeleton />
        </Box>
      ) : (
        `${segmentedOrder.upcomingLineHaulSegment?.startTerminal.code} → ${segmentedOrder.upcomingLineHaulSegment?.endTerminal.code}`
      );
    }
    case LinehaulDispatchTableField.Lane: {
      return `${segmentedOrder.order.lineHaulLane?.originTerminal.code} → ${segmentedOrder.order.lineHaulLane?.destinationTerminal.code}`;
    }
    case LinehaulDispatchTableField.Service: {
      return segmentedOrder.order.lineHaulServiceDisplayString;
    }
    case LinehaulDispatchTableField.ServiceDate: {
      const serviceDate = segmentedOrder.order.endStop?.serviceDate;
      return isNil(serviceDate)
        ? '-'
        : formattedDateAbbreviated(dayjs(serviceDate));
    }
    case LinehaulDispatchTableField.Weight: {
      return `${segmentedOrder.order.weight} ${formatWeightUnits(
        segmentedOrder.order.standardOrderFields.weightUnits,
      )}`;
    }
    case LinehaulDispatchTableField.DimWeight: {
      return `${segmentedOrder.order.dimWeight} ${formatWeightUnits(
        segmentedOrder.order.standardOrderFields.weightUnits,
      )}`;
    }
    case LinehaulDispatchTableField.InboundAddressName: {
      const inboundAddressSegments = [];
      const { startStop } = segmentedOrder.order;

      inboundAddressSegments.push(
        startStop?.address.name,
        startStop?.terminal?.code,
        startStop?.incomingCarrier,
      );

      const filtered = inboundAddressSegments.filter(
        (segment) => !isNil(segment) && !isEmpty(segment),
      );
      return filtered.length > 0 ? filtered.join(', ') : '-';
    }
    case LinehaulDispatchTableField.OutboundAddressName: {
      const outboundAddressSegments = [];
      const { endStop } = segmentedOrder.order;

      outboundAddressSegments.push(
        endStop?.address.name,
        endStop?.terminal?.code,
        endStop?.outboundCarrier,
        endStop?.destinationAirport,
      );

      const filtered = outboundAddressSegments.filter(
        (segment) => !isNil(segment) && !isEmpty(segment),
      );
      return filtered.length > 0 ? filtered.join(', ') : '-';
    }
    case LinehaulDispatchTableField.CustomerName: {
      return segmentedOrder.order.billingPartyContact.displayName;
    }
    case LinehaulDispatchTableField.IsSpecial: {
      const isSpecial =
        segmentedOrder.order.startStop?.isSpecial === true ||
        segmentedOrder.order.endStop?.isSpecial === true;
      return (
        <Box width="100px !important">
          {isSpecial ? (
            <CheckIcon color="success" />
          ) : (
            <CloseIcon color="error" />
          )}
        </Box>
      );
    }
    case LinehaulDispatchTableField.IsHazmat: {
      return (
        <Box width="100px !important">
          {segmentedOrder.order.hazmat === true ? (
            <CheckIcon color="success" />
          ) : (
            <CloseIcon color="error" />
          )}
        </Box>
      );
    }
    case LinehaulDispatchTableField.PieceCount: {
      return segmentedOrder.order.pieceCountFromPackages?.toString() ?? '-';
    }
    case LinehaulDispatchTableField.Tags: {
      const { tags } = segmentedOrder.order;
      return (
        <Box style={{ height: '20px' }}>
          <TagsCell tags={tags} />
        </Box>
      );
    }
    case LinehaulDispatchTableField.DeadlineDate: {
      const deadlineDate =
        segmentedOrder.order.endStop?.leg?.shipment?.standardShipmentFields
          ?.deadlineDate;
      return isNil(deadlineDate)
        ? '-'
        : formattedDateAbbreviated(dayjs(deadlineDate));
    }
    default: {
      return exhaustive(field);
    }
  }
};

export const canAddOrderToManifest = (
  openedManifest: DetailedLineHaulManifestFragment | undefined,
  currentManifestTab: ManifestTab,
) => {
  return !(
    isNil(openedManifest) || currentManifestTab === ManifestTab.Arrivals
  );
};
