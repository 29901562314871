import {
  Chip,
  CircularProgress,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import TablePagination from '@mui/material/TablePagination';
import { isEmpty, isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import CenteredCircularProgress from '../../../../../../common/components/centered-circular-progress';
import useMe from '../../../../../../common/react-hooks/use-me';
import useWindowDimensions from '../../../../../../common/react-hooks/use-window-dimensions';
import {
  EmailTransactionStatus,
  type PageInfo,
  useApiLogsLazyQuery,
} from '../../../../../../generated/graphql';
import useInvoicesStore from '../../../../invoices-store';
import EmailTransactionStatusChip from './email-transaction-status-chip';
import InvoiceEmailRow from './invoice-email-row';

const DEFAULT_EMAIL_PAGE_SIZE = 10;
const ROW_PER_PAGE_OPTIONS = [10, 25, 50, 100];

export type InvoiceEmailsTableProps = {
  readonly invoiceUuid?: string | undefined;
};

const InvoiceEmailsTable = ({ invoiceUuid }: InvoiceEmailsTableProps) => {
  const { companyConfiguration } = useMe();
  const { height } = useWindowDimensions();
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    DEFAULT_EMAIL_PAGE_SIZE,
  );
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [pageInfo, setPageInfo] = useState<PageInfo | undefined>();
  const [sortByCreatedAtDateAsc, setSortByCreatedAtDateAsc] =
    useState<boolean>(false);
  const [status, setStatus] = useState<EmailTransactionStatus | 'All'>('All');
  const [searchText, setSearchText] = useState<string>('');
  const [debouncedSearchText] = useDebounce(searchText, 300);
  const shouldRefreshInvoiceList = useInvoicesStore(
    (state) => state.shouldRefreshInvoiceList,
  );
  const [getApiLogs, { data: apiLogsData, loading }] = useApiLogsLazyQuery();

  const fetchInvoiceEmailTransactions = async ({
    first,
    after,
    last,
    before,
  }: {
    first?: number | null | undefined;
    after?: string | null | undefined;
    last?: number | null | undefined;
    before?: string | null | undefined;
  }) => {
    const res = await getApiLogs({
      variables: {
        first,
        after,
        last,
        before,
        isInvoiceEmails: true,
        isFreightSnap: false,
        invoiceUuid,
        emailTransactionStatus: status === 'All' ? undefined : status,
        searchText: debouncedSearchText,
        sortByCreatedAtDateAsc,
      },
    });
    setTotalCount(res.data?.apiLogs.totalCount ?? 0);
    setPageInfo(res.data?.apiLogs.pageInfo);
  };

  const refresh = () => {
    fetchInvoiceEmailTransactions({
      first: rowsPerPage,
    });
    setPage(0);
  };

  const prev = async (newPage: number) => {
    await fetchInvoiceEmailTransactions({
      last: rowsPerPage,
      before: pageInfo?.startCursor ?? undefined,
    });
    setPage(newPage);
  };
  const next = async (newPage: number) => {
    await fetchInvoiceEmailTransactions({
      first: rowsPerPage,
      after: pageInfo?.endCursor ?? undefined,
    });
    setPage(newPage);
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    invoiceUuid,
    rowsPerPage,
    debouncedSearchText,
    sortByCreatedAtDateAsc,
    status,
    shouldRefreshInvoiceList,
  ]);

  if (loading) {
    return <CenteredCircularProgress />;
  }

  if (isEmpty(apiLogsData?.apiLogs.edges)) {
    return null;
  }

  return (
    <TableContainer
      sx={{
        maxHeight: height - 305,
        overflowY: 'scroll',
      }}
    >
      <Grid
        container
        alignItems="center"
        sx={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 1 }}
      >
        <Grid item xs={4}>
          <Stack direction="row" alignItems="center" spacing={2}>
            {isNil(invoiceUuid) && (
              <TextField
                size="small"
                label="Search Invoices"
                InputProps={{ style: { backgroundColor: 'white' } }}
                value={searchText}
                sx={{ maxWidth: '300px' }}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            )}
            {loading && <CircularProgress size={20} />}
          </Stack>
        </Grid>
        <Grid item xs={8}>
          <TablePagination
            labelRowsPerPage="Show"
            rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              disabled: loading || page === 0,
            }}
            nextIconButtonProps={{
              disabled:
                loading || page + 1 === Math.ceil(totalCount / rowsPerPage),
            }}
            onPageChange={(e, newPage: number) => {
              if (newPage > page) {
                next(newPage);
              } else {
                prev(newPage);
              }
            }}
            onRowsPerPageChange={(e) => {
              setRowsPerPage(Number(e.target.value));
            }}
          />
        </Grid>
      </Grid>

      <Table stickyHeader aria-label="invoice-preview-table" size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active
                direction={sortByCreatedAtDateAsc ? 'asc' : 'desc'}
                hideSortIcon={false}
                onClick={() => {
                  setSortByCreatedAtDateAsc(!sortByCreatedAtDateAsc);
                }}
              >
                Sent At
              </TableSortLabel>
            </TableCell>
            {isNil(invoiceUuid) && (
              <TableCell sx={{ width: '200px' }}>Customer</TableCell>
            )}
            {isNil(invoiceUuid) && (
              <TableCell sx={{ width: '130px' }}>Invoice</TableCell>
            )}
            {isNil(invoiceUuid) &&
              companyConfiguration?.useJournalNumberForInvoice === true && (
                <TableCell>Journal Number</TableCell>
              )}
            <TableCell align="center">
              <Select
                value={status}
                sx={{
                  boxShadow: 'none',
                  '.MuiOutlinedInput-notchedOutline': { border: 0 },
                }}
                size="small"
                renderValue={(value) => {
                  return (
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography variant="subtitle2">Status</Typography>{' '}
                      {value === 'All' ? (
                        <Chip size="small" label="All" />
                      ) : (
                        <EmailTransactionStatusChip status={value} />
                      )}
                    </Stack>
                  );
                }}
                onChange={(e) => {
                  setStatus(e.target.value as EmailTransactionStatus | 'All');
                }}
              >
                {Object.values(EmailTransactionStatus).map((txnStatus) => (
                  <MenuItem key={txnStatus} value={txnStatus}>
                    <EmailTransactionStatusChip status={txnStatus} />
                  </MenuItem>
                ))}
                <MenuItem key="All" value="All">
                  <Chip size="small" label="All" />
                </MenuItem>
              </Select>
            </TableCell>
            <TableCell>Emails</TableCell>
            <TableCell>Document</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {apiLogsData?.apiLogs.edges.map(({ node: apiLog }) => (
            <InvoiceEmailRow
              key={apiLog.uuid}
              invoiceUuid={invoiceUuid}
              apiLog={apiLog}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InvoiceEmailsTable;
