import {
  // eslint-disable-next-line no-restricted-imports
  Grid,
  type SxProps,
  Tooltip,
  type TooltipProps,
  styled,
  tooltipClasses,
} from '@mui/material';
import { isNil } from 'lodash';
import { type ReactNode } from 'react';

const useStyles = (): Record<string, SxProps> => {
  return {
    container: {
      minWidth: 125,
      maxWidth: 125,
    },
    gridItem: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      position: 'relative',
    },
  };
};

export const GridItemTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    /* eslint-disable react/jsx-props-no-spreading */
    <Tooltip {...props} classes={{ popper: className }} />
  ),
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    minWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '10px',
    paddingBottom: '10px',
    gap: '5px',
  },
}));

export const GridItem = ({
  children,
  borderColorHex,
}: {
  readonly children: ReactNode;
  readonly borderColorHex?: string | null;
}) => {
  const styles = useStyles();
  // const width = size === 'large' ? '180px' : '115px';
  const px = '10px';
  // const py = '15px';
  // const alignItems = center ? 'center' : 'flex-start';
  // border width
  const border = isNil(borderColorHex) ? 0 : 2;
  const borderColor = isNil(borderColorHex) ? null : `${borderColorHex}70`;
  // set background color to the same as border color with low opacity
  const backgroundColor = isNil(borderColorHex) ? null : `${borderColorHex}50`;
  return (
    <Grid
      container
      sx={{
        ...styles.container,
        px,
        border,
        borderColor,
        backgroundColor,
      }}
    >
      {children}
    </Grid>
  );
};
