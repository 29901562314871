import { isNil } from 'lodash';
import {
  type MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { shallow } from 'zustand/shallow';
import { useInvoiceTotalsLazyQuery } from '../../../generated/graphql';
import useInvoicesStore from '../invoices-store';

const useInvoiceTotals = () => {
  const mainAbortController = useRef(new AbortController());
  const [queueProcessing, setQueueProcessing] = useState<boolean>(false);
  const [queue, setQueue] = useState<string[][]>([]);
  const [invoiceTotalsCache, invoiceBalancesCache] = useInvoicesStore(
    (state) => [state.invoiceTotalsCache, state.invoiceBalancesCache],
    shallow,
  );
  const [upsertInvoiceTotal, upsertInvoiceBalance] = useInvoicesStore(
    (state) => [state.upsertInvoiceTotal, state.upsertInvoiceBalance],
    shallow,
  );
  const [getInvoiceTotals] = useInvoiceTotalsLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const fetchAndCacheInvoiceTotalsByUuids = useCallback(
    async (uuids: string[], controller?: MutableRefObject<AbortController>) => {
      const res = await getInvoiceTotals({
        variables: { uuids },
        context: isNil(controller)
          ? undefined
          : {
              fetchOptions: {
                signal: controller.current.signal,
              },
            },
      });
      for (const { uuid, invoiceTotal, balanceInCents } of res.data
        ?.invoicesByUuids ?? []) {
        upsertInvoiceTotal(uuid, invoiceTotal);
        upsertInvoiceBalance(uuid, balanceInCents);
      }
    },
    [getInvoiceTotals, upsertInvoiceTotal, upsertInvoiceBalance],
  );

  const fetchInvoiceTotalsByUuids = useCallback(
    async (uuids: string[], addToQueue?: boolean) => {
      if (addToQueue === true) {
        setQueue([...queue, uuids]);
      } else {
        mainAbortController.current.abort();
        mainAbortController.current = new AbortController();
        fetchAndCacheInvoiceTotalsByUuids(uuids, mainAbortController);
      }
    },
    [queue, fetchAndCacheInvoiceTotalsByUuids],
  );

  useEffect(() => {
    if (queue.length > 0) {
      const uuids = queue.shift();
      if (!isNil(uuids) && !queueProcessing) {
        setQueueProcessing(true);
        fetchAndCacheInvoiceTotalsByUuids(uuids).then(() => {
          setQueueProcessing(false);
        });
      }
    }
  }, [queue, queueProcessing, fetchAndCacheInvoiceTotalsByUuids]);

  return {
    invoiceTotals: invoiceTotalsCache,
    invoiceBalances: invoiceBalancesCache,
    fetchInvoiceTotalsByUuids,
  };
};

export default useInvoiceTotals;
