import { z } from 'zod';
import { BillingMethod } from '../../../../../common/types';
import { FuelBillingMethod } from '../../../../../generated/graphql';

export const fuelChargeSchema = z.object({
  uuid: z.string().uuid(),
  billingMethod: z.nativeEnum(FuelBillingMethod),
  surchargeRate: z.number().nullable(),
  // This is a dollar amount, e.g. 1.50 means a rate of $1.50 per X
  flatRateDollars: z.number().nullable(),
  totalCharge: z.number(),
  description: z.string().nullable(),
  authoCode: z.string().nullable(),
  settlementPercentageRate: z.number().nullable(),
  settlementFlatRate: z.number().nullable(),
  settlementBillingMethod: z.nativeEnum(BillingMethod).nullable(),
  deductionTotal: z.number(),
});
