import {
  FormControl,
  FormHelperText,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { useMemo } from 'react';
import AutocompleteFuzzy from '../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';

import { useGLCodes } from '../react-hooks/use-gl-codes';

type GeneralLedgerCodeOption = {
  code: string;
  label: string;
  value: string;
  description: string;
};

const GeneralLedgerCodeAutocomplete = ({
  value,
  formError,
  setValue,
  disabled,
  size,
  showTextFieldLabel = true,
  showEvenIfEmpty = false,
}: {
  readonly value: string | null;
  readonly formError: string | null;
  readonly setValue: (newVal: string | null) => void;
  readonly disabled: boolean;
  readonly size?: 'small' | 'medium';
  readonly showTextFieldLabel?: boolean;
  readonly showEvenIfEmpty?: boolean;
}) => {
  const theme = useTheme();
  const { glCodes, glCodesLoading } = useGLCodes();

  const allOptions = useMemo(
    () =>
      glCodes?.map((glCode) => ({
        code: glCode.code ?? '',
        value: glCode.id,
        description: glCode.description ?? '',
        label: `${glCode.code} (${glCode.description})`,
      })) ?? [],
    [glCodes],
  );
  const selectedOption = useMemo(() => {
    const selectedCode = glCodes?.find((code) => code.id === value) ?? null;
    return isNil(selectedCode)
      ? null
      : {
          code: selectedCode.code,
          label: `${selectedCode.code} (${selectedCode.description})`,
          value: selectedCode.id,
          description: selectedCode.description,
        };
  }, [glCodes, value]);

  // don't show the select if there are no GL codes
  if (!glCodesLoading && isEmpty(glCodes) && !showEvenIfEmpty) {
    return null;
  }

  return (
    <FormControl sx={{ width: '100%' }} error={!isNil(formError)}>
      <AutocompleteFuzzy<GeneralLedgerCodeOption>
        fullWidth
        size={size}
        sx={{ backgroundColor: 'white' }}
        disabled={glCodesLoading || disabled}
        value={selectedOption}
        options={allOptions}
        matchSortOptions={{ keys: ['label'] }}
        renderOption={(props, option) => (
          <li {...props} key={option.value}>
            <Stack direction="row" gap={1}>
              <Typography>{option.code}</Typography>
              <Typography color={theme.palette.grey[500]}>
                {option.description}
              </Typography>
            </Stack>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={showTextFieldLabel ? 'General ledger (GL) code' : undefined}
            size={size}
            value={selectedOption?.label ?? ''}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
          />
        )}
        onChange={(_, option) => {
          setValue(option?.value ?? null);
        }}
      />
      {!isNil(formError) && <FormHelperText error>{formError}</FormHelperText>}
    </FormControl>
  );
};

export default GeneralLedgerCodeAutocomplete;
