import { type AddressEntity } from '../generated/graphql';

export const COLORS = [
  '#e6194b',
  '#3cb44b',
  '#FFD700',
  '#4363d8',
  '#f58231',
  '#911eb4',
  '#f032e6',
  '#008080',
  '#e6beff',
  '#9a6324',
  '#800000',
  '#808000',
  '#000075',
  '#808080',
  '#000000',
];

export const ADDRESS_REQUIRED_FIELDS: Array<keyof AddressEntity> = [
  'name',
  'line1',
  'city',
  'state',
  'zip',
];
