import {
  createEntityAdapter,
  createSelector,
  createSlice,
  type EntityId,
  type EntityState,
} from '@reduxjs/toolkit';
import type { RootState } from '../../../redux/store';

type ZoneBasedAccessorialZoneFields = {
  uuid: string;
  name: string;
};

type ZoneBasedAccessorialZoneStoreState = ZoneBasedAccessorialZoneFields;

const zoneBasedAccessorialsZonesAdapter =
  createEntityAdapter<ZoneBasedAccessorialZoneStoreState>({
    selectId: (accessorial) => accessorial.uuid,
  });

export const zoneBasedAccessorialZonesSlice = createSlice({
  name: 'zoneBasedAccessorialZones',
  initialState: zoneBasedAccessorialsZonesAdapter.getInitialState(),
  reducers: {
    addOneZoneBasedAccessorialZone: zoneBasedAccessorialsZonesAdapter.addOne,
    updateZoneBasedAccessorialZone: zoneBasedAccessorialsZonesAdapter.updateOne,
    deleteZoneBasedAccessorialZone: zoneBasedAccessorialsZonesAdapter.removeOne,
    updateZoneBasedAccessorialZones:
      zoneBasedAccessorialsZonesAdapter.updateMany,
    setAllZoneBasedAccessorialZones: zoneBasedAccessorialsZonesAdapter.setAll,
    upsertManyZoneBasedAccessorialZones:
      zoneBasedAccessorialsZonesAdapter.upsertMany,
  },
});

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectById: selectZoneBasedAccessorialZoneById,
  // Pass in a selector that returns the posts slice of state
} = zoneBasedAccessorialsZonesAdapter.getSelectors(
  (state: RootState) => state.zoneBasedAccessorialZones,
);

const zoneBasedAccessorialZonesSelector = (state: RootState) =>
  state.zoneBasedAccessorialZones;

export const selectZoneBasedAccessorialZonesByIds = createSelector(
  zoneBasedAccessorialZonesSelector,
  (state: RootState, ids: EntityId[]) => ids,
  (
    state: EntityState<ZoneBasedAccessorialZoneStoreState>,
    entityIds: EntityId[],
  ) => {
    const entityResults: ZoneBasedAccessorialZoneStoreState[] = [];
    for (const id of entityIds) {
      const match = zoneBasedAccessorialsZonesAdapter
        .getSelectors()
        .selectById(state, id);
      if (match) {
        entityResults.push(match);
      }
    }
    return entityResults;
  },
);

export const {
  addOneZoneBasedAccessorialZone,
  updateZoneBasedAccessorialZone,
  deleteZoneBasedAccessorialZone,
  setAllZoneBasedAccessorialZones,
  upsertManyZoneBasedAccessorialZones,
} = zoneBasedAccessorialZonesSlice.actions;

export default zoneBasedAccessorialZonesSlice.reducer;
