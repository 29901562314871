import { Checkbox, ListItemButton, ListItemText } from '@mui/material';
import * as React from 'react';
import { shallow } from 'zustand/shallow';
import {
  OrderStatus,
  type OutstandingOrderFragmentFragment,
} from '../../../../generated/graphql';
import useSaveOrderBrm from '../../../orders/components/order-form/forms/use-save-order-brm';
import useBillingReviewStore from '../../billing-review-store';

export const OutstandingOrdersSidebarListItemComponent = ({
  isSelected,
  isDisabled,
  onClick,
  hawb,
  name,
  status,
  isSelectingOrdersToSend,
  isSelectedToSendV2,
  onToggleOrder,
  increasePadding = false,
}: {
  readonly isSelected: boolean;
  readonly isDisabled: boolean;
  readonly onClick: () => void;
  readonly hawb: string | undefined;
  readonly name: string | undefined;
  readonly status: OrderStatus;
  readonly isSelectingOrdersToSend: boolean;
  readonly isSelectedToSendV2: boolean;
  readonly onToggleOrder: (checked: boolean) => void;
  readonly increasePadding?: boolean;
}) => {
  const displayedOrderBillingStatus = (orderStatus: OrderStatus) => {
    switch (orderStatus) {
      case OrderStatus.Finalized: {
        return 'Ready to invoice';
      }
      case OrderStatus.HasIssue: {
        return 'Has issue';
      }
      default: {
        return 'Not ready to invoice';
      }
    }
  };
  return (
    <ListItemButton
      //   key={order.uuid}
      selected={isSelected}
      disabled={isDisabled}
      sx={{ p: increasePadding ? '16px' : undefined }}
      onClick={onClick}
    >
      <ListItemText primary={hawb} secondary={name} />
      <ListItemText
        style={{
          color: 'gray',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        {isDisabled ? 'Saving...' : displayedOrderBillingStatus(status)}
      </ListItemText>
      {isSelectingOrdersToSend && status === OrderStatus.Finalized && (
        <Checkbox
          checked={isSelectedToSendV2}
          size="small"
          onChange={(event, checked) => {
            onToggleOrder(checked);
            event.stopPropagation();
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      )}
    </ListItemButton>
  );
};

const OutstandingOrdersSidebarListItem = ({
  isSelected,
  isDisabled,
  order,
  increasePadding = false,
}: {
  readonly isSelected: boolean;
  readonly isDisabled: boolean;
  readonly order: OutstandingOrderFragmentFragment;
  readonly increasePadding?: boolean;
}) => {
  const { saveOrder: saveCurrentOrder } = useSaveOrderBrm();
  const [
    setOpenedOutstandingOrderUuid,
    isSelectingOrdersToSend,
    addShipmentsToSend,
    removeOrdersToSend,
    isSelectedToSendV2,
    addOrdersToSendShipments,
    removeOrdersToSendShipments,
  ] = useBillingReviewStore(
    (state) => [
      state.setOpenedOutstandingOrderUuid,
      state.isSelectingOrdersToSendInReviewModal,
      state.addShipmentsToSend,
      state.removeOrdersToSend,
      state.selectedOrdersToSendShipments.some(
        ({ uuid }) => uuid === order.uuid,
      ),
      state.addOrdersToSendShipments,
      state.removeOrdersToSendShipments,
    ],
    shallow,
  );

  const onToggleOrder = (checked: boolean) => {
    if (checked) {
      addShipmentsToSend(
        order.shipments
          .filter((s) => !s.hideFromBilling)
          .map((shipment) => ({
            orderUuid: order.uuid,
            shipmentUuid: shipment.uuid,
            contact: order.billingPartyContact,
          })),
      );
      addOrdersToSendShipments([order]);
    } else {
      removeOrdersToSend([order.uuid]);
      removeOrdersToSendShipments([order.uuid]);
    }
  };

  return (
    <OutstandingOrdersSidebarListItemComponent
      isSelected={isSelected}
      isDisabled={isDisabled}
      hawb={order.standardOrderFields?.shipperBillOfLadingNumber ?? undefined}
      name={order.name}
      status={order.status}
      isSelectingOrdersToSend={isSelectingOrdersToSend}
      isSelectedToSendV2={isSelectedToSendV2}
      increasePadding={increasePadding}
      onClick={() => {
        if (!isSelected) {
          saveCurrentOrder({});
          setOpenedOutstandingOrderUuid(order.uuid);
        }
      }}
      onToggleOrder={onToggleOrder}
    />
  );
};

export default React.memo(OutstandingOrdersSidebarListItem);
