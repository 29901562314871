import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { sentenceCase } from 'change-case';
import currency from 'currency.js';
import dayjs from 'dayjs';
import { groupBy, isNil, sortBy } from 'lodash';
import { exhaustive } from 'shared/switch';
import { type DateOption } from '../../../../../common/components/date-dropdown-picker';
import { type Option } from '../../../../../common/filters/types';
import { type Me } from '../../../../../common/react-hooks/use-me';
import {
  CHECKED_CHECKBOX_IMAGE_URL,
  UNCHECKED_CHECKBOX_IMAGE_URL,
} from '../../../../../common/utils/pdf-gen';
import { type RevenueReportOrderDataFragment } from '../../../../../generated/graphql';

const TEXT_PADDING = '3px';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 25,
    fontFamily: 'Roboto',
  },
  header1: {
    fontSize: '18px',
  },
  header2: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  rowWithSpaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  filterLabelText: {
    fontWeight: 'bold',
    width: '100px',
  },
  contactBlock: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12px',
    marginTop: 4,
  },
  contactBlockHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#f0eded',
    padding: 4,
  },
  ordersTable: {
    width: '100%',
  },
  ordersTableRow: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 2,
    paddingBottom: 2,
    justifyContent: 'space-between',
    fontSize: '8px',
    width: '100%',
    textAlign: 'center',
  },
  ordersTableHeader: {
    borderTop: 'none',
    fontWeight: 'bold',
    borderBottom: '1px solid black',
  },
  ordersTableRowLeftSection: {
    display: 'flex',
    flexDirection: 'row',
    width: '85%',
    textAlign: 'left',
  },
  ordersTableRowRightSection: {
    display: 'flex',
    flexDirection: 'row',
    width: '20%',
    textAlign: 'right',
    gap: 4,
  },
  defaultCol: {
    width: '5%',
    paddingRight: TEXT_PADDING,
    textAlign: 'left',
  },
  billNumberCol: {
    width: '10%',
    paddingRight: TEXT_PADDING,
    textAlign: 'left',
  },
  statusCol: {
    width: '5%',
    paddingRight: TEXT_PADDING,
    textAlign: 'left',
  },
  typeCol: {
    width: '8%',
    paddingRight: TEXT_PADDING,
    textAlign: 'left',
  },
  dateCol: {
    width: '8%',
    paddingRight: TEXT_PADDING,
    textAlign: 'left',
  },
  addressCol: {
    width: '20%',
    maxWidth: '20%',
    paddingRight: TEXT_PADDING,
    textAlign: 'left',
    wordWrap: 'break-word',
    wordBreak: 'break-all', // Add this line
    overflowWrap: 'break-word', // This can also be helpful in certain scenarios
  },
  terminalCol: {
    width: '7%',
    paddingRight: TEXT_PADDING,
    textAlign: 'left',
  },
  pcsCol: {
    width: '3%',
    paddingRight: TEXT_PADDING,
    textAlign: 'left',
  },
  podCol: {
    width: '5%',
    paddingRight: TEXT_PADDING,
    textAlign: 'center',
  },
});

export enum ReportType {
  UnbilledRevenue = 'unbilled-revenue',
  UninvoicedOrders = 'uninvoiced-orders',
}

type GeneratedUnbilledAndUninvoicedOrdersReportProps = {
  readonly companyData: Me;
  readonly ordersData: RevenueReportOrderDataFragment[];
  readonly dateOption: DateOption;
  readonly customerOption?: Option;
  readonly terminalOption?: Option;
  readonly isLineHaul: boolean;
  readonly reportType: ReportType;
};

const GeneratedUnbilledAndUninvoicedOrdersReport = ({
  companyData,
  ordersData,
  dateOption,
  customerOption,
  terminalOption,
  isLineHaul,
  reportType,
}: GeneratedUnbilledAndUninvoicedOrdersReportProps) => {
  const ordersGroupedByContact = sortBy(
    Object.values(
      groupBy(ordersData, (order) => {
        return order.contactUuid;
      }),
    ).filter((orders) => {
      return orders.length > 0;
    }),
    'contactName',
  );

  const reportTotal = ordersGroupedByContact.reduce((total, orders) => {
    return (
      total +
      orders.reduce((orderTotal, order) => orderTotal + order.totalCharge, 0)
    );
  }, 0);

  const getDateHeader = () => {
    if (!isNil(dateOption.startDate) && !isNil(dateOption.endDate)) {
      return `${dayjs(dateOption.startDate).format('MM/DD/YY')} - ${dayjs(
        dateOption.endDate,
      ).format('MM/DD/YY')}`;
    }
    return 'All Time';
  };

  const getDocName = () => {
    switch (reportType) {
      case ReportType.UnbilledRevenue: {
        return 'Unbilled Revenue Report';
      }
      case ReportType.UninvoicedOrders: {
        return 'Uninvoiced Orders Report';
      }
      default: {
        return exhaustive(reportType);
      }
    }
  };

  return (
    <Document title={getDocName()}>
      <Page orientation="landscape" size="LETTER" style={styles.page}>
        <View
          style={[
            styles.rowWithSpaceBetween,
            {
              marginTop: '10px',
              fontSize: '12px',
            },
          ]}
        >
          <Text style={styles.header1}>{getDocName()}</Text>
        </View>
        <View
          style={[
            styles.rowWithSpaceBetween,
            {
              fontSize: '12px',
            },
          ]}
        >
          <Text style={styles.header2}>{companyData.companyName}</Text>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '10px',
            fontSize: '12px',
          }}
        >
          <View style={styles.rowWithSpaceBetween}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontSize: '10px',
              }}
            >
              <Text style={styles.filterLabelText}>Service date</Text>
              <Text style={{ marginLeft: '10px' }}>{getDateHeader()}</Text>
            </View>
          </View>
          <View style={styles.rowWithSpaceBetween}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontSize: '10px',
              }}
            >
              <Text style={styles.filterLabelText}>Customer</Text>
              <Text style={{ marginLeft: '10px' }}>
                {customerOption?.label ?? 'All'}
              </Text>
            </View>
          </View>
          {companyData.companyConfiguration?.terminalsEnabled === true && (
            <View style={styles.rowWithSpaceBetween}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  fontSize: '10px',
                }}
              >
                <Text style={styles.filterLabelText}>Terminal</Text>
                <Text style={{ marginLeft: '10px' }}>
                  {terminalOption?.label ?? 'All'}
                </Text>
              </View>
            </View>
          )}
          {isLineHaul && (
            <View style={styles.rowWithSpaceBetween}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  fontSize: '10px',
                }}
              >
                <Text style={styles.filterLabelText}>LINE HAUL ONLY</Text>
              </View>
            </View>
          )}
        </View>
        {ordersGroupedByContact.map((orders) => (
          <>
            <View style={styles.contactBlock}>
              <View style={styles.contactBlockHeader}>
                <Text style={{ fontWeight: 'bold' }}>
                  {orders[0]?.contactName}
                </Text>
              </View>
            </View>
            <View style={styles.ordersTable}>
              <View style={[styles.ordersTableRow, styles.ordersTableHeader]}>
                <Text style={styles.defaultCol}>Order name</Text>
                <Text style={styles.billNumberCol}>HAWB</Text>
                <Text style={styles.billNumberCol}>MAWB</Text>
                {companyData.companyConfiguration?.terminalsEnabled ===
                  true && (
                  <Text style={styles.terminalCol}>Controlling terminal</Text>
                )}
                <Text style={styles.statusCol}>Status</Text>
                <Text style={styles.addressCol}>Inbound address</Text>
                <Text style={styles.addressCol}>Outbound address</Text>
                <Text style={styles.typeCol}>Inbound stop</Text>
                <Text style={styles.typeCol}>Outbound stop</Text>
                <Text style={styles.dateCol}>Service date</Text>
                <Text style={styles.pcsCol}>Pcs</Text>
                <Text style={styles.defaultCol}>Weight</Text>
                <Text style={styles.defaultCol}>Total</Text>
                <Text style={styles.podCol}>POD document uploaded</Text>
              </View>
            </View>
            {orders.map((order) => {
              const serviceDates = order.serviceDates
                .map((serviceDate) => dayjs(serviceDate).format('MM/DD/YY'))
                .join(', ');
              return (
                <View
                  key={order.uuid}
                  style={styles.ordersTableRow}
                  wrap={false}
                >
                  <Text style={styles.defaultCol}>{order.name}</Text>
                  <Text style={styles.billNumberCol}>
                    {order.shipperBillOfLadingNumber ?? '-'}
                  </Text>
                  <Text style={styles.billNumberCol}>
                    {order.masterAirwayBillOfLadingNumber ?? '-'}
                  </Text>
                  {companyData.companyConfiguration?.terminalsEnabled ===
                    true && (
                    <Text style={styles.terminalCol}>
                      {order.controllingTerminalCode ?? '-'}
                    </Text>
                  )}
                  <Text style={styles.statusCol}>
                    {sentenceCase(order.detailedStatusV2 ?? '-')}
                  </Text>
                  <Text style={styles.addressCol}>
                    {order.inboundAddressString ?? '-'}
                  </Text>
                  <Text style={styles.addressCol}>
                    {order.outboundAddressString ?? '-'}
                  </Text>
                  <Text style={styles.typeCol}>
                    {sentenceCase(order.inboundStopType ?? 'NA')}
                  </Text>
                  <Text style={styles.typeCol}>
                    {sentenceCase(order.outboundStopType ?? 'NA')}
                  </Text>
                  <Text style={styles.dateCol}>{serviceDates}</Text>
                  <Text style={styles.pcsCol}>{order.packageQuantity}</Text>
                  <Text style={styles.defaultCol}>{order.packageWeight}</Text>
                  <Text style={styles.defaultCol}>
                    {currency(order.totalCharge).format()}
                  </Text>
                  <Text style={styles.podCol}>
                    <Image
                      src={
                        order.podUploaded
                          ? CHECKED_CHECKBOX_IMAGE_URL
                          : UNCHECKED_CHECKBOX_IMAGE_URL
                      }
                    />
                  </Text>
                </View>
              );
            })}
            <View
              style={[styles.ordersTableRow, { justifyContent: 'flex-end' }]}
            >
              <View
                style={[
                  styles.ordersTableRowRightSection,
                  { justifyContent: 'flex-end' },
                ]}
              >
                <Text style={{ fontWeight: 'bold' }}>Total:</Text>
                <Text>
                  {currency(
                    orders.reduce(
                      (total, current) => total + current.totalCharge,
                      0,
                    ),
                  ).format()}
                </Text>
              </View>
            </View>
          </>
        ))}
        <View style={styles.contactBlock}>
          <View style={styles.ordersTable}>
            <View style={styles.ordersTableRow}>
              <View
                style={[
                  styles.ordersTableRowLeftSection,
                  { justifyContent: 'flex-end' },
                ]}
              >
                <Text style={{ fontSize: '9px', fontWeight: 'bold' }}>
                  Report total:{' '}
                </Text>
              </View>
              <View style={styles.ordersTableRowRightSection}>
                <Text style={{ width: '100%', paddingRight: TEXT_PADDING }}>
                  {currency(reportTotal).format()}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default GeneratedUnbilledAndUninvoicedOrdersReport;
