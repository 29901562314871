import { Stack, TextField, Typography } from '@mui/material';
import { isNil } from 'lodash';
import React from 'react';
import {
  type EquipmentsQuery,
  useEquipmentsQuery,
} from '../../generated/graphql';
import AutocompleteFuzzy from '../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';

const VehicleAutocompleteComponent = ({
  selectedEquipmentUuid,
  onChange,
}: {
  readonly selectedEquipmentUuid: string | null;
  readonly onChange: (
    equipmentUuid: EquipmentsQuery['equipments'][0] | null,
  ) => void;
}) => {
  const { data: equipmentsData } = useEquipmentsQuery({
    fetchPolicy: 'cache-first',
  });

  const selectedEquipment = equipmentsData?.equipments.find(
    (equipment) => equipment.uuid === selectedEquipmentUuid,
  );

  return (
    <AutocompleteFuzzy
      size="small"
      sx={{
        minWidth: '120px',
      }}
      value={
        isNil(selectedEquipment)
          ? null
          : {
              value: selectedEquipment.uuid,
              label: selectedEquipment.name,
            }
      }
      options={
        equipmentsData?.equipments
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((equipment) => ({
            label: equipment.name,
            value: equipment.uuid,
          })) ?? []
      }
      matchSortOptions={{ keys: ['label'] }}
      renderInput={(params) => (
        <TextField
          sx={{ mt: 0.5 }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          variant="standard"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      )}
      renderOption={(props, option) => {
        const equipment = equipmentsData?.equipments?.find(
          (d) => d.uuid === option.value,
        );
        return (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <li {...props} key={option.value}>
            <Stack>
              <Typography sx={{ fontSize: '14px' }}>{option.label}</Typography>
              <Typography variant="caption" color="text.secondary">
                {equipment?.vehicleType?.name}
              </Typography>
            </Stack>
          </li>
        );
      }}
      onChange={(_, option) => {
        const equipment = equipmentsData?.equipments.find(
          (eq) => eq.uuid === option?.value,
        );
        if (isNil(equipment)) {
          onChange(null);
          return;
        }

        onChange(equipment);
      }}
    />
  );
};

export const VehicleAutocomplete = React.memo(VehicleAutocompleteComponent);
