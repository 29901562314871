import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ClearIcon from '@mui/icons-material/Clear';
import { Button, Fade, TableCell, TableRow, Box } from '@mui/material';
import { isNil } from 'lodash';
import { type Dispatch, type SetStateAction, useState } from 'react';
import { type LinehaulDispatchTableField } from '../../../../generated/graphql';
import { getLineHaulOrdersTableFieldCopy } from '../../utils';

const CurrentFieldRow = ({
  field,
  setShowCurrentlyShowingFields,
  setShowAvailableFieldsToShow,
  idx,
}: {
  readonly field: LinehaulDispatchTableField;
  readonly setShowCurrentlyShowingFields: Dispatch<
    SetStateAction<LinehaulDispatchTableField[]>
  >;
  readonly setShowAvailableFieldsToShow: Dispatch<
    SetStateAction<LinehaulDispatchTableField[]>
  >;
  readonly idx: number;
}) => {
  const [isHovering, setIsHovering] = useState(false);
  return (
    <TableRow
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <TableCell>{getLineHaulOrdersTableFieldCopy(field)}</TableCell>
      <TableCell>
        <Fade in={isHovering}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              onClick={() => {
                setShowAvailableFieldsToShow((prevState) => [
                  ...prevState,
                  field,
                ]);
                setShowCurrentlyShowingFields((prevState) =>
                  prevState.filter((itrField) => itrField !== field),
                );
              }}
            >
              <ClearIcon />
            </Button>
            <Button
              onClick={() => {
                setShowCurrentlyShowingFields((prevState) =>
                  prevState.map((itrField, itrIdx) => {
                    const aboveField = prevState[itrIdx - 1];
                    const belowField = prevState[itrIdx + 1];
                    if (itrIdx === idx && !isNil(aboveField)) {
                      return aboveField;
                    }
                    if (itrIdx === idx - 1 && !isNil(belowField)) {
                      return belowField;
                    }
                    return itrField;
                  }),
                );
              }}
            >
              <ArrowUpwardIcon />
            </Button>
            <Button
              onClick={() => {
                setShowCurrentlyShowingFields((prevState) =>
                  prevState.map((itrField, itrIdx) => {
                    const aboveField = prevState[itrIdx - 1];
                    const belowField = prevState[itrIdx + 1];
                    if (itrIdx === idx && !isNil(belowField)) {
                      return belowField;
                    }
                    if (itrIdx === idx + 1 && !isNil(aboveField)) {
                      return aboveField;
                    }
                    return itrField;
                  }),
                );
              }}
            >
              <ArrowDownwardIcon />
            </Button>
          </Box>
        </Fade>
      </TableCell>
    </TableRow>
  );
};

export default CurrentFieldRow;
