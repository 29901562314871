import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';
import { filterNotNil } from 'shared/array';
import { shallow } from 'zustand/shallow';
import {
  type OutstandingOrderFragmentFragment,
  type StandardOrderFragmentFragment,
  useUnconsolidateOrderMutation,
} from '../../../../generated/graphql';
import useBillingReviewStore from '../../billing-review-store';
import useBillingReviewActions from '../../hooks/use-billing-review-actions';
import useBillingReviewNavigationActions from '../../hooks/use-billing-review-navigation-actions';

const useStyles = () => {
  return {
    boxFlexRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    boxFlexCol: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    packageItem: {
      borderLeft: '2px solid lightgray',
      paddingLeft: 1,
      paddingRight: 1,
      textAlign: 'center',
    },
    step: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '.MuiStepIcon-text': {
        display: 'none',
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '.Mui-completed': {
        fontWeight: 'normal',
      },
    },
  };
};

// String is in format "HAWB1, HAWB2 (consolidated)"
export const parseConsolidatedShipmentsString = (
  consolidatedShipmentsString: string | null | undefined,
): string[] => {
  if (
    isNil(consolidatedShipmentsString) ||
    isEmpty(consolidatedShipmentsString)
  ) {
    return [];
  }
  const values: string[] = consolidatedShipmentsString
    .split(',')
    .map((val) => val.trim());
  // Remove the "consolidated" part of last element (which contains the consolidated label)
  const consolidatedTag = ' (consolidated)';
  const lastElem = values.pop();
  if (isNil(lastElem)) {
    return [];
  }
  const parsedLast = lastElem.replace(consolidatedTag, '').trim();
  return [...values, parsedLast];
};

const UnconsolidateOutstandingOrdersModal = ({
  handleClose,
  handleCloseWithoutSave,
  setErrorMessage,
}: {
  readonly handleClose: () => void;
  readonly handleCloseWithoutSave: () => void;
  readonly setErrorMessage: Dispatch<SetStateAction<string | null>>;
}) => {
  const styles = useStyles();

  const [
    openedOutstandingOrderUuid,
    outstandingOrdersInPage,
    removeSearchedOrdersByUuids,
  ] = useBillingReviewStore(
    (state) => [
      state.openedOutstandingOrderUuid,
      state.outstandingOrdersInPage,
      state.removeSearchedOrdersByUuids,
    ],
    shallow,
  );
  const { fetchOrderCacheFirst } = useBillingReviewActions();
  const { refetchPage } = useBillingReviewNavigationActions();
  const [currentOutstandingOrder, setCurrentOutstandingOrder] = useState<
    StandardOrderFragmentFragment | OutstandingOrderFragmentFragment | null
  >(null);
  const [unconsolidateOrder, { loading: unconsolidateOrderLoading }] =
    useUnconsolidateOrderMutation();

  const fetchData = async () => {
    setCurrentOutstandingOrder(
      await fetchOrderCacheFirst({
        orderUuid: openedOutstandingOrderUuid,
        bypassCache: true,
      }),
    );
  };
  useEffect(() => {
    if (!isNil(openedOutstandingOrderUuid)) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedOutstandingOrderUuid]);

  const unconsolidate = async () => {
    if (isNil(currentOutstandingOrder)) {
      setErrorMessage('Error unconsolidating order');
    } else {
      const result = await unconsolidateOrder({
        variables: {
          unconsolidateOrderInput: {
            orderUuid: currentOutstandingOrder.uuid,
          },
        },
      });
      const unconsolUuids = filterNotNil(
        result.data?.unconsolidateOrder.map((sh) => sh.order?.uuid) ?? [],
      );
      if (isEmpty(unconsolUuids)) {
        setErrorMessage(
          `Error unconsolidating order ${currentOutstandingOrder?.standardOrderFields.shipperBillOfLadingNumber}`,
        );
      } else {
        await refetchPage(
          [...outstandingOrdersInPage.map((o) => o.uuid), ...unconsolUuids],
          unconsolUuids[0],
        );
        removeSearchedOrdersByUuids([currentOutstandingOrder.uuid]);
      }
    }
    handleCloseWithoutSave();
  };

  return (
    <Card
      variant="outlined"
      style={{ height: '20%', maxHeight: '20%', overflow: 'auto' }}
    >
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            justifyContent: 'center',
          }}
        >
          <Box sx={styles.boxFlexCol}>
            <Typography
              variant="h6"
              sx={{ fontSize: '20px' }}
              textAlign="center"
            >
              Are you sure you want to unconsolidate{' '}
              {
                parseConsolidatedShipmentsString(
                  currentOutstandingOrder?.consolidatedShipmentString,
                ).length
              }{' '}
              orders?
            </Typography>
            <Typography sx={{ fontSize: '17px' }} textAlign="center">
              This will permanently delete the consolidated order.
            </Typography>
          </Box>

          <Box
            sx={{ ...styles.boxFlexRow, justifyContent: 'center', gap: '20px' }}
          >
            <Button
              variant="outlined"
              size="large"
              onClick={() => {
                handleClose();
              }}
            >
              Go back
            </Button>
            <Button
              disabled={unconsolidateOrderLoading}
              startIcon={
                unconsolidateOrderLoading && <CircularProgress size={20} />
              }
              variant="contained"
              color="primary"
              size="large"
              onClick={unconsolidate}
            >
              Yes, unconsolidate
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default UnconsolidateOutstandingOrdersModal;
