import { createAsyncThunk } from '@reduxjs/toolkit';
import { isNil } from 'lodash';
import { exhaustive } from 'shared/switch';
import { objectEntries, objectKeys } from 'tsafe';
import { type ValidationResponse } from '../../../common/form/formValidators';
import { isNilOrEmptyString } from '../../../common/utils/utils';
import {
  type ContactPersonCreateInput,
  type ContactPersonFragment,
  type ContactPersonUpdateInput,
  type ContactPersonUpsertInput,
  type StandardOrderQueryStopFragment,
} from '../../../generated/graphql';
import type { RootState } from '../../../redux/store';
import {
  type ContactPersonFormFieldError,
  updateContactPersonErrors,
  upsertContactPersonErrors,
} from './contact-persons-errors-slice';
import {
  CONTACT_PERSON_SCHEMA,
  type ContactPersonFormField,
  selectContactPersonById,
  upsertOneContactPerson,
} from './contact-persons-values-slice';

export const contactPersonIsEmpty = (
  contactPerson: ContactPersonFormField,
): boolean => {
  let isEmpty = true;
  for (const [key, value] of objectEntries(contactPerson)) {
    switch (key) {
      case 'createdAt': {
        break;
      }
      case 'firstName': {
        isEmpty = isNilOrEmptyString(value);
        break;
      }
      case 'lastName': {
        isEmpty = isNilOrEmptyString(value);
        break;
      }
      case 'notes': {
        isEmpty = isNilOrEmptyString(value);
        break;
      }
      case 'phone': {
        isEmpty = isNilOrEmptyString(value);
        break;
      }
      case 'uuid': {
        break;
      }
      case 'email': {
        isEmpty = isNilOrEmptyString(value);
        break;
      }
      case 'updatedAt': {
        break;
      }
      default: {
        throw new Error(`unimplemented key: ${key}`);
      }
    }
  }

  return isEmpty;
};

type CreateContactPersonCreateInputArg = {
  contactPersonUuid: string;
};

export const createContactPersonCreateInput = createAsyncThunk<
  ContactPersonCreateInput,
  CreateContactPersonCreateInputArg,
  { state: RootState }
>(
  'contactPersons/createContactPersonCreateInput',
  async (arg, thunkAPI): Promise<ContactPersonCreateInput> => {
    const contactPersonValues = selectContactPersonById(
      thunkAPI.getState(),
      arg.contactPersonUuid,
    );
    if (isNil(contactPersonValues)) {
      throw new Error(`Invalid contact person uuid: ${arg.contactPersonUuid}`);
    }

    return {
      email: contactPersonValues.email,
      firstName: contactPersonValues.firstName,
      lastName: contactPersonValues.lastName,
      notes: contactPersonValues.notes,
      phone: contactPersonValues.phone,
    };
  },
);

type CreateContactPersonUpdateInputArg = {
  contactPersonUuid: string;
};

export const createContactPersonUpdateInput = createAsyncThunk<
  ContactPersonUpdateInput,
  CreateContactPersonUpdateInputArg,
  { state: RootState }
>(
  'contactPersons/createContactPersonUpdateInput',
  async (arg, thunkAPI): Promise<ContactPersonUpdateInput> => {
    const contactPersonValues = selectContactPersonById(
      thunkAPI.getState(),
      arg.contactPersonUuid,
    );
    if (isNil(contactPersonValues)) {
      throw new Error(`Invalid contact person uuid: ${arg.contactPersonUuid}`);
    }

    return {
      email: contactPersonValues.email,
      firstName: contactPersonValues.firstName,
      lastName: contactPersonValues.lastName,
      notes: contactPersonValues.notes,
      phone: contactPersonValues.phone,
      uuid: contactPersonValues.uuid,
    };
  },
);

type CreateContactPersonUpsertInputArg = {
  contactPersonUuid: string;
};

export const createContactPersonUpsertInput = createAsyncThunk<
  ContactPersonUpsertInput,
  CreateContactPersonUpsertInputArg,
  { state: RootState }
>(
  'contact-persons/createContactPersonUpsertInput',
  async (arg, thunkAPI): Promise<ContactPersonUpsertInput> => {
    const contactPersonValues = selectContactPersonById(
      thunkAPI.getState(),
      arg.contactPersonUuid,
    );
    if (isNil(contactPersonValues)) {
      throw new Error(`Invalid contact person uuid: ${arg.contactPersonUuid}`);
    }

    return {
      email: contactPersonValues.email,
      firstName: contactPersonValues.firstName,
      lastName: contactPersonValues.lastName,
      notes: contactPersonValues.notes,
      phone: contactPersonValues.phone,
      uuid: contactPersonValues.uuid,
    };
  },
);

type UpsertContactPersonValuesThunkArg = {
  contactPerson: ContactPersonFragment;
};

export const upsertContactPersonValuesThunk = createAsyncThunk<
  ContactPersonFormField,
  UpsertContactPersonValuesThunkArg,
  { state: RootState }
>('contactPersons/upsertContactPersonValuesThunk', async (arg, thunkAPI) => {
  await thunkAPI.dispatch(upsertOneContactPerson(arg.contactPerson));
  return arg.contactPerson;
});

type ValidateContactPersonArgs = {
  allowEmpty: boolean;
  contactPersonId: string;
};

/**
 * Thunk to validate contact person fields
 */
export const validateContactPersonThunk = createAsyncThunk<
  ValidationResponse,
  ValidateContactPersonArgs,
  { state: RootState }
>('contactPersons/validateContactPersonArgs', async (args, thunkAPI) => {
  const { allowEmpty, contactPersonId } = args;
  const contactPersonValues = selectContactPersonById(
    thunkAPI.getState(),
    contactPersonId,
  );
  if (isNil(contactPersonValues)) {
    throw new Error(`Invalid contact person uuid: ${contactPersonId}`);
  }
  let valid = true;
  await (allowEmpty && contactPersonIsEmpty(contactPersonValues)
    ? Promise.all(
        objectKeys(CONTACT_PERSON_SCHEMA).map(async (field) => {
          if (field !== 'uuid') {
            thunkAPI.dispatch(
              upsertContactPersonErrors({
                uuid: contactPersonId,
                [field]: undefined,
              }),
            );
          }
        }),
      )
    : Promise.all(
        objectKeys(CONTACT_PERSON_SCHEMA).map(async (field) => {
          let validationResponse: ValidationResponse | null | undefined;
          switch (field) {
            case 'firstName':
            case 'lastName': {
              break;
            }
            case 'createdAt': {
              break;
            }
            case 'email': {
              break;
            }
            case 'notes': {
              break;
            }
            case 'phone': {
              break;
            }
            case 'updatedAt': {
              break;
            }
            case 'uuid': {
              break;
            }
            default: {
              exhaustive(field);
            }
          }
          if (!isNil(validationResponse)) {
            if (validationResponse.valid) {
              thunkAPI.dispatch(
                upsertContactPersonErrors({
                  uuid: contactPersonId,
                  [field]: undefined,
                }),
              );
            } else {
              valid = false;
              thunkAPI.dispatch(
                upsertContactPersonErrors({
                  uuid: contactPersonId,
                  [field]: validationResponse.explanation,
                }),
              );
            }
          }
        }),
      ));

  return { valid, explanation: null };
});

export const setAllContactPersonErrorsUndefined = createAsyncThunk<
  unknown,
  string,
  { state: RootState }
>('contactPersons/setAllUndefined', async (contactPersonId, thunkAPI) => {
  const { dispatch } = thunkAPI;
  const contactPersonErrors: ContactPersonFormFieldError = {
    createdAt: undefined,
    updatedAt: undefined,
    uuid: contactPersonId,
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    notes: undefined,
    phone: undefined,
  };
  dispatch(
    updateContactPersonErrors({
      id: contactPersonId,
      changes: contactPersonErrors,
    }),
  );
});

export const upsertContactPersonForStop = createAsyncThunk<
  void,
  {
    stop: StandardOrderQueryStopFragment;
    contactPersonUuid: string;
  },
  { state: RootState }
>(
  'contactPersons/upsertContactPersonForStop',
  async (arg, thunkAPI): Promise<void> => {
    const { stop, contactPersonUuid } = arg;
    if (!isNil(stop.contactPerson)) {
      await thunkAPI.dispatch(
        upsertContactPersonValuesThunk({
          contactPerson: {
            uuid: contactPersonUuid,
            email: stop.contactPerson.email,
            firstName: stop.contactPerson.firstName,
            lastName: stop.contactPerson.lastName,
            notes: stop.contactPerson.notes,
            phone: stop.contactPerson.phone,
            createdAt: stop.contactPerson.createdAt,
          },
        }),
      );
    }
  },
);

export const upsertShipperContactPersonForStop = createAsyncThunk<
  void,
  {
    stop: StandardOrderQueryStopFragment;
    shipperContactPersonUuid: string;
  },
  { state: RootState }
>(
  'contactPersons/upsertShipperContactPersonForStop',
  async (arg, thunkAPI): Promise<void> => {
    const { stop, shipperContactPersonUuid } = arg;
    if (!isNil(stop.shipperContactPerson)) {
      await thunkAPI.dispatch(
        upsertContactPersonValuesThunk({
          contactPerson: {
            uuid: shipperContactPersonUuid,
            email: undefined,
            firstName: stop.shipperContactPerson.firstName,
            lastName: stop.shipperContactPerson.lastName,
            notes: undefined,
            phone: stop.shipperContactPerson.phone,
            createdAt: stop.shipperContactPerson.createdAt,
          },
        }),
      );
    }
  },
);

export const upsertConsigneeContactPersonForStop = createAsyncThunk<
  void,
  {
    stop: StandardOrderQueryStopFragment;
    consigneeContactUuid: string;
  },
  { state: RootState }
>(
  'contactPersons/upsertConsigneeContactPersonForStop',
  async (arg, thunkAPI): Promise<void> => {
    const { stop, consigneeContactUuid } = arg;
    if (!isNil(stop.consigneeContactPerson)) {
      await thunkAPI.dispatch(
        upsertContactPersonValuesThunk({
          contactPerson: {
            uuid: consigneeContactUuid,
            email: undefined,
            firstName: stop.consigneeContactPerson.firstName,
            lastName: stop.consigneeContactPerson.lastName,
            notes: undefined,
            phone: stop.consigneeContactPerson.phone,
            createdAt: stop.consigneeContactPerson.createdAt,
          },
        }),
      );
    }
  },
);
