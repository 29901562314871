import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Button,
  Fade,
  IconButton,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { type CsvError, parse } from 'csv-parse/browser/esm';
import { isEmpty, isNil } from 'lodash';
import { useState } from 'react';
import {
  type CreateTerminalServiceAreaInput,
  type TerminalServiceAreaFragment,
  useCreateTerminalServiceAreasV2Mutation,
  useRemoveTerminalServiceAreaMutation,
} from '../../../../generated/graphql';

const TerminalServiceAreaRow = ({
  serviceArea,
  showSnackbar,
  reloadTerminal,
}: {
  readonly serviceArea: TerminalServiceAreaFragment;
  readonly showSnackbar: () => void;
  readonly reloadTerminal: () => void;
}) => {
  const [isHovering, setIsHovering] = useState<boolean>(false);

  const [removeTerminalServiceArea] = useRemoveTerminalServiceAreaMutation();

  const handleDelete = async () => {
    await removeTerminalServiceArea({
      variables: {
        uuid: serviceArea.uuid,
      },
    });
    showSnackbar();
    reloadTerminal();
  };

  return (
    <TableRow
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <TableCell>{serviceArea.city}</TableCell>
      <TableCell>{serviceArea.zipcode}</TableCell>
      <TableCell align="right">
        <Fade in={isHovering}>
          <div>
            <IconButton onClick={handleDelete}>
              <CloseIcon color="error" />
            </IconButton>
          </div>
        </Fade>
      </TableCell>
    </TableRow>
  );
};

const TerminalServiceAreas = ({
  terminalUuid,
  serviceAreas,
  reloadTerminal,
}: {
  readonly terminalUuid: string;
  readonly serviceAreas: TerminalServiceAreaFragment[] | undefined;
  readonly reloadTerminal: () => void;
}) => {
  const [csvUploadError, setCsvUploadError] = useState<boolean>(false);
  const [createTerminalServiceAreas] =
    useCreateTerminalServiceAreasV2Mutation();
  const [csvUploadSuccess, setCsvUploadSuccess] = useState<boolean>(false);

  const [deleteServiceAreaSuccess, setDeleteServiceAreaSuccess] =
    useState<boolean>(false);

  const saveServiceAreas = async (result: CreateTerminalServiceAreaInput[]) => {
    const serviceAreaCreateInputs: CreateTerminalServiceAreaInput[] = result
      .filter((row) => !isEmpty(row.zipcode))
      .map((data) => {
        return {
          terminalUuid,
          city: data.city,
          zipcode: data.zipcode,
        };
      });
    const res = await createTerminalServiceAreas({
      variables: {
        createTerminalServiceAreaInputs: serviceAreaCreateInputs,
      },
    });
    reloadTerminal();
    if (
      res.data?.createTerminalServiceAreasV2.createdCount !==
      serviceAreaCreateInputs.length
    ) {
      setCsvUploadError(true);
      setCsvUploadSuccess(false);
      return;
    }

    setCsvUploadError(false);
    setCsvUploadSuccess(true);
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; // Get the uploaded file
    if (file) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const csv = reader.result?.toString(); // Get the CSV data as a string
        if (csv !== undefined) {
          parse(
            csv,
            {
              delimiter: ',',
              columns: ['city', 'zipcode'],
              fromLine: 2,
            },
            (
              error: CsvError | undefined,
              result: CreateTerminalServiceAreaInput[],
            ) => {
              if (isNil(error)) {
                setCsvUploadError(false);
                saveServiceAreas(result);
              } else {
                setCsvUploadError(true);
              }
            },
          );
        }
      });
      reader.readAsText(file);
    }
  };
  return (
    <Stack flexGrow="1" gap="20px">
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6">
          {' '}
          Service areas ({serviceAreas?.length}){' '}
        </Typography>
        <Button component="label" variant="outlined" sx={{ float: 'right' }}>
          Upload from CSV
          <input
            hidden
            multiple
            type="file"
            accept=".csv"
            value=""
            onChange={handleFileUpload}
          />
        </Button>
      </Stack>
      <TableContainer
        sx={{
          height: '75%',
          maxHeight: '70vh',
          width: '100%',
          overflowY: 'auto',
        }}
      >
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>City</TableCell>
              <TableCell>Zipcode</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {serviceAreas?.map((serviceArea) => (
              <TerminalServiceAreaRow
                key={serviceArea.uuid}
                serviceArea={serviceArea}
                showSnackbar={() => {
                  setDeleteServiceAreaSuccess(true);
                }}
                reloadTerminal={reloadTerminal}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={csvUploadError}
        onClose={() => {
          setCsvUploadError(false);
        }}
      >
        <Alert severity="error">
          CSV was formatted incorrectly, please check and try again
        </Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={csvUploadSuccess}
        onClose={() => {
          setCsvUploadSuccess(false);
        }}
      >
        <Alert>Service areas saved</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={deleteServiceAreaSuccess}
        onClose={() => {
          setDeleteServiceAreaSuccess(false);
        }}
      >
        <Alert>Service area deleted</Alert>
      </Snackbar>
    </Stack>
  );
};

export default TerminalServiceAreas;
