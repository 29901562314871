import { Button, Fade, TableCell, TableRow } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { type AuditReportBucketFragment } from '../../../generated/graphql';
import useAuditReportStore from '../audit-report-store';

const AuditReportTableRow = ({
  data,
}: {
  readonly data: AuditReportBucketFragment;
}) => {
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const setCurrentBucket = useAuditReportStore(
    (state) => state.setCurrentBucket,
  );

  return (
    <TableRow
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <TableCell>
        {dayjs(data.startDate).format('MM/DD')} -{' '}
        {dayjs(data.endDate).format('MM/DD/YYYY')}
      </TableCell>
      <TableCell>{data.noAction.length}</TableCell>
      <TableCell>{data.onHand.length}</TableCell>
      <TableCell>{data.assigned.length}</TableCell>
      <TableCell>{data.partiallyComplete.length}</TableCell>
      <TableCell>{data.attempted.length}</TableCell>
      <TableCell>{data.onHold.length}</TableCell>
      <TableCell>{data.billingIssues.length}</TableCell>
      <TableCell>{data.unfinalized.length}</TableCell>
      <TableCell>{data.cancelledUnfinalized.length}</TableCell>
      <TableCell>{data.cancelledFinalized.length}</TableCell>
      <TableCell>{data.noChargeFinalized.length}</TableCell>
      <TableCell>{data.onInvoiceUnposted.length}</TableCell>
      <TableCell>{data.onInvoicePosted.length}</TableCell>
      <TableCell>
        <Fade in={isHovering}>
          <Button
            variant="contained"
            onClick={() => {
              setCurrentBucket(data);
            }}
          >
            View
          </Button>
        </Fade>
      </TableCell>
    </TableRow>
  );
};

export default React.memo(AuditReportTableRow);
