import { Box, Typography } from '@mui/material';
import { type PointLike, Popup } from 'react-map-gl';
import { transformAddressToFullAddressString } from 'shared/copy';
import { transformTimeToTimeString } from '../../../../common/utils/prettyPrintUtils';
import { type AddressFragment } from '../../../../generated/graphql';

type SameDayStopPopupProps = {
  readonly address: AddressFragment;
  readonly appointmentWindow: string;
  readonly orderName: string;
  readonly latitude: number;
  readonly longitude: number;
};

const POPUP_OFFSETS: Record<string, PointLike> = {
  bottom: [0, -20],
  'bottom-left': [0, -20],
  'bottom-right': [0, -20],
};

export const SameDayStopPopup = ({
  address,
  appointmentWindow,
  latitude,
  longitude,
  orderName,
}: SameDayStopPopupProps) => {
  return (
    <Popup
      longitude={longitude}
      latitude={latitude}
      closeButton={false}
      offset={POPUP_OFFSETS}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: '10px',
        }}
      >
        <Box sx={{ textAlign: 'center' }}>
          <Typography sx={{ color: 'black', textDecoration: 'underline' }}>
            {orderName}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography align="left" sx={{ color: 'black', fontWeight: 'bold' }}>
            Address:
          </Typography>
          <Typography align="right" sx={{ color: 'black' }}>
            {transformAddressToFullAddressString({
              ...address,
              city: address.city ?? null,
              line1: address.line1 ?? null,
              zip: address.zip ?? null,
            })}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Typography
            align="left"
            sx={{ color: 'black', fontWeight: 'bold', float: 'left' }}
          >
            Appt window:
          </Typography>
          <Typography align="right" sx={{ color: 'black', marginLeft: 'auto' }}>
            {transformTimeToTimeString(appointmentWindow)}
          </Typography>
        </Box>
      </Box>
    </Popup>
  );
};
