import { OrderDetailedStatus } from '../../../../../generated/graphql';

export type UseShouldRateOrderProps = {
  detailedStatus: OrderDetailedStatus | null | undefined;
};

export type UseShouldRateOrderOutput = {
  shouldRateOrder: boolean;
  invoicePosted: boolean;
  chargesFinalized: boolean;
};

export const useShouldRateOrder = ({
  detailedStatus,
}: UseShouldRateOrderProps): UseShouldRateOrderOutput => {
  let shouldRateOrder = true;
  let invoicePosted = false;
  let chargesFinalized = false;
  if (detailedStatus === OrderDetailedStatus.InvoicePosted) {
    shouldRateOrder = false;
    invoicePosted = true;
  } else if (
    detailedStatus === OrderDetailedStatus.ChargesFinalized ||
    detailedStatus === OrderDetailedStatus.OnInvoice
  ) {
    chargesFinalized = true;
  }

  return { shouldRateOrder, invoicePosted, chargesFinalized };
};
