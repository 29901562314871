import { TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { type IngestedEmailOrdersQuery } from '../../../../../generated/graphql';

type ReviewEmailOrdersTableRowProps = {
  readonly ingestedEmailOrder: IngestedEmailOrdersQuery['scannedOrderResults']['edges'][number]['node'];
  readonly rowIsSelected: boolean;
  readonly selectRow: () => void;
};

const ReviewEmailOrdersTableRow = ({
  ingestedEmailOrder,
  rowIsSelected,
  selectRow,
}: ReviewEmailOrdersTableRowProps) => {
  const [isHovering, setIsHovering] = useState<boolean>(false);

  return (
    <TableRow
      sx={[
        isHovering && { backgroundColor: 'lightgray', cursor: 'pointer' },
        rowIsSelected && { cursor: 'default', backgroundColor: 'gray' },
      ]}
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      onClick={() => {
        if (!rowIsSelected) selectRow();
      }}
    >
      <TableCell>{`${ingestedEmailOrder?.order?.name}`}</TableCell>
      <TableCell>
        {
          ingestedEmailOrder?.order?.standardOrderFields
            .shipperBillOfLadingNumber
        }
      </TableCell>
      <TableCell>
        {ingestedEmailOrder?.order?.billingPartyContact?.displayName}
      </TableCell>
      <TableCell>
        {ingestedEmailOrder.scannedOrder.ingestedOutlookEmailDetails
          ?.fromAddress ?? 'N/A'}
      </TableCell>
      <TableCell>
        <Tooltip
          title={
            ingestedEmailOrder.scannedOrder.ingestedOutlookEmailDetails?.subject
          }
        >
          <Typography
            fontSize={14}
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {
              ingestedEmailOrder.scannedOrder.ingestedOutlookEmailDetails
                ?.subject
            }
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell>
        {dayjs(ingestedEmailOrder.createdAt).format('MM/DD/YY hh:mm a')}
      </TableCell>
    </TableRow>
  );
};

export default React.memo(ReviewEmailOrdersTableRow);
