import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import dayjs, { type Dayjs } from 'dayjs';
import { isNil } from 'lodash';
import { Calendar } from 'primereact/calendar';
import {
  type Dispatch,
  type SetStateAction,
  memo,
  useEffect,
  useState,
} from 'react';
import useLineHaulDispatchActions from '../hooks/use-line-haul-dispatch-actions';
import useLineHaulDispatchStore from '../store/line-haul-dispatch-store';

const PostponeOrdersModal = ({
  open,
  setOpen,
  manifestUuid,
  orderUuids,
}: //   onDeleteRoute,
{
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
  readonly manifestUuid: string;
  readonly orderUuids: string[];
  //   onDeleteRoute: () => void;
}) => {
  const [selectedDate, setSelectedDate] = useState<Dayjs | undefined>(
    undefined,
  );

  const [planningDate] = useLineHaulDispatchStore((state) => [
    state.planningDate,
  ]);

  const { postponeMultipleOrdersOnManifest } = useLineHaulDispatchActions();

  useEffect(() => {
    if (!isNil(planningDate)) {
      setSelectedDate(planningDate);
    }
  }, [planningDate]);

  const handleClose = () => {
    setOpen(false);
    setSelectedDate(undefined);
  };

  const handleConfirm = async () => {
    if (!isNil(selectedDate)) {
      await postponeMultipleOrdersOnManifest({
        currentManifestUuid: manifestUuid,
        orderUuids,
        newDepartDate: selectedDate,
      });
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>
        <Typography variant="h6">Postpone orders</Typography>
      </DialogTitle>
      <DialogContent>
        {/* <Typography>Warning: this action is not reversible</Typography> */}
        <Stack gap={1}>
          <Typography> Select date:</Typography>
          <Calendar
            minDate={planningDate?.toDate()}
            placeholder="Planning Date"
            // showButtonBar

            value={selectedDate?.toDate()}
            onChange={(e) => {
              setSelectedDate(dayjs(String(e.value)));
              // if (!isNil(e.value)) {
              //   changePlanningDate({
              //     newPlanningDate: dayjs(String(e.value)),
              //   });
              // }
            }}
            inline
          />
          Postpone to {dayjs(selectedDate).format('MM/DD/YYYY')}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleConfirm}>
          Confirm
        </Button>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(PostponeOrdersModal);
