import { Box, TableContainer } from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import { useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import CenteredCircularProgress from '../../../../common/components/centered-circular-progress';
import {
  Table,
  TableBody,
  TableCell,
  TableButtonCell,
  TableHead,
  TableHeadCell,
  TableRow,
  TableButton,
  TableHeadButtonCell,
} from '../../../../common/components/table/styled-table-components';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import {
  PermissionResource,
  type TariffChainWithGroupsFragment,
  TariffChainsDocument,
  TariffChainsWithGroupsDocument,
  useArchiveTariffChainMutation,
  useRestoreTariffChainMutation,
  useTariffChainsWithGroupsQuery,
} from '../../../../generated/graphql';
import CreateOrEdit from '../../enums/create-or-edit';
import {
  ActiveArchivedButtonGroup,
  ActiveOrArchivedTab,
} from '../common/active-archived-tab-panel';
import CreateOrEditTariffChainModal from './create-or-edit-tariff-chain-modal';
import EditDefaultTariffChainsModal from './edit-default-tariff-chains-modal';

const TariffChainsPage = () => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteCompanyTariffs } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyTariffs,
  );

  const [activeOrArchivedTab, setActiveOrArchivedTab] =
    useState<ActiveOrArchivedTab>(ActiveOrArchivedTab.ACTIVE);
  const [
    showCreateOrEditTariffChainModal,
    setShowCreateOrEditTariffChainModal,
  ] = useState<boolean>(false);
  const [
    showEditDefaultTariffChainsModal,
    setShowEditDefaultTariffChainsModal,
  ] = useState<boolean>(false);

  const [currentTariffChain, setCurrentTariffChain] = useState<
    TariffChainWithGroupsFragment | undefined
  >(undefined);

  const { data: tariffChains, loading: tariffChainsLoading } =
    useTariffChainsWithGroupsQuery({
      fetchPolicy: 'network-only',
      variables: {
        findTariffChainsInput: {
          isArchived: activeOrArchivedTab === ActiveOrArchivedTab.ARCHIVED,
        },
      },
    });

  const [archiveTariffChain, { loading: archiveTariffChainLoading }] =
    useArchiveTariffChainMutation({
      refetchQueries: [TariffChainsDocument, TariffChainsWithGroupsDocument],
    });
  const [restoreTariffChain, { loading: restoreTariffChainLoading }] =
    useRestoreTariffChainMutation({
      refetchQueries: [TariffChainsDocument, TariffChainsWithGroupsDocument],
    });

  const handleCreateTariffChain = () => {
    setCurrentTariffChain(undefined);
    setShowCreateOrEditTariffChainModal(true);
  };

  const handleEditTariffChain = (
    tariffChain: TariffChainWithGroupsFragment,
  ) => {
    setCurrentTariffChain(tariffChain);
    setShowCreateOrEditTariffChainModal(true);
  };

  return (
    <Box>
      <ActiveArchivedButtonGroup
        activeTab={activeOrArchivedTab}
        setTab={setActiveOrArchivedTab}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell>Name</TableHeadCell>
              <TableHeadCell>Tariff Type</TableHeadCell>
              <TableHeadButtonCell sx={{ textAlign: 'right' }}>
                <TableButton onClick={handleCreateTariffChain}>
                  Create Tariff Chain
                </TableButton>
                <TableButton
                  onClick={() => {
                    setShowEditDefaultTariffChainsModal(true);
                  }}
                >
                  Edit Default Tariff Chains
                </TableButton>
              </TableHeadButtonCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tariffChainsLoading ? (
              <TableRow>
                <TableCell colSpan={3}>
                  <CenteredCircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              tariffChains?.tariffChains.map((tariffChain) => (
                <TableRow key={tariffChain.id}>
                  <TableCell>{tariffChain.name}</TableCell>
                  <TableCell>
                    {sentenceCase(tariffChain.tariffGroupType)}
                  </TableCell>
                  <TableButtonCell sx={{ textAlign: 'right' }}>
                    {activeOrArchivedTab === ActiveOrArchivedTab.ACTIVE ? (
                      <>
                        <TableButton
                          disabled={!canWriteCompanyTariffs}
                          onClick={() => {
                            handleEditTariffChain(tariffChain);
                          }}
                        >
                          Edit
                        </TableButton>
                        <TableButton
                          color="error"
                          disabled={
                            !canWriteCompanyTariffs || archiveTariffChainLoading
                          }
                          onClick={() => {
                            archiveTariffChain({
                              variables: {
                                archiveTariffChainInput: {
                                  id: tariffChain.id,
                                },
                              },
                            });
                          }}
                        >
                          Archive
                        </TableButton>
                      </>
                    ) : (
                      <TableButton
                        color="success"
                        disabled={
                          !canWriteCompanyTariffs || restoreTariffChainLoading
                        }
                        onClick={() => {
                          restoreTariffChain({
                            variables: {
                              restoreTariffChainInput: {
                                id: tariffChain.id,
                              },
                            },
                          });
                        }}
                      >
                        Restore
                      </TableButton>
                    )}
                  </TableButtonCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CreateOrEditTariffChainModal
        key={currentTariffChain?.id ?? 'CREATE_MODE'}
        open={showCreateOrEditTariffChainModal}
        createOrEdit={
          isNil(currentTariffChain) ? CreateOrEdit.Create : CreateOrEdit.Edit
        }
        tariffChain={currentTariffChain}
        onClose={() => {
          setShowCreateOrEditTariffChainModal(false);
        }}
      />
      <EditDefaultTariffChainsModal
        open={showEditDefaultTariffChainsModal}
        canEdit={canWriteCompanyTariffs}
        onClose={() => {
          setShowEditDefaultTariffChainsModal(false);
        }}
      />
    </Box>
  );
};

export default TariffChainsPage;
