import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, TableBody, TableCell, TableRow } from '@mui/material';
import { exhaustive } from 'shared/switch';
import RangeCell, { type RangeCellValues } from '../../common/range-cell';
import { FormMode } from '../common';

type SpecialAccessorialMileRangeFieldProps = {
  readonly mode: FormMode;
  readonly range: RangeCellValues;
  readonly onChange: (value: RangeCellValues) => void;
  readonly onDeleteMileRange: (index: number) => void;
};

export const SpecialAccessorialMileRangeField = ({
  mode,
  range,
  onChange,
  onDeleteMileRange,
}: SpecialAccessorialMileRangeFieldProps) => {
  switch (mode) {
    case FormMode.EDIT:
    case FormMode.CREATE: {
      return (
        <TableBody>
          {range.map((_, index) => (
            // Don't use the cell value as the key, otherwise it'll
            // remount and lose focus on every change event
            // eslint-disable-next-line react/no-array-index-key
            <TableRow key={index}>
              <TableCell>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    minWidth: '165px',
                  }}
                >
                  <RangeCell
                    rangeValues={range}
                    rangeIndex={index}
                    inputLabel="Miles"
                    onChange={onChange}
                  />
                </Box>
              </TableCell>
              <TableCell>
                <IconButton
                  color="error"
                  onClick={() => {
                    onDeleteMileRange(index);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      );
    }
    default: {
      return exhaustive(mode);
    }
  }
};
