import {
  Alert,
  Box,
  Button,
  Chip,
  Fade,
  Snackbar,
  Stack,
  TableCell,
  TableRow,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import { exhaustive } from 'shared/switch';
import { useAllowOpenInNewTab } from '../../../../../common/react-hooks/use-allow-open-in-new-tab';
import {
  type ApiLogFragment,
  FreightSnapTransactionStatus,
  useMatchFreightSnapMutation,
} from '../../../../../generated/graphql';
import FreightSnapMatchModal from './freight-snap-match-modal';

type FreightSnapTransactionRowProps = {
  readonly apiLog: ApiLogFragment;
};

export type FreightSnapStatusType =
  | FreightSnapTransactionStatus
  | 'All'
  | null
  | undefined;

export const getStatusChip = (status: FreightSnapStatusType) => {
  if (isNil(status)) {
    return <Chip size="small" label="Unknown" />;
  }
  switch (status) {
    case FreightSnapTransactionStatus.Processing: {
      return <Chip size="small" label="Processing" color="info" />;
    }
    case FreightSnapTransactionStatus.Success: {
      return <Chip size="small" label="Success" color="success" />;
    }
    case FreightSnapTransactionStatus.Failed: {
      return <Chip size="small" label="Failed" color="error" />;
    }
    case 'All': {
      return <Chip size="small" label="All" />;
    }
    default: {
      return exhaustive(status);
    }
  }
};

const FreightSnapTransactionRow = ({
  apiLog,
}: FreightSnapTransactionRowProps) => {
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [showFreightSnapMatchModal, setShowFreightSnapMatchModal] =
    useState<boolean>(false);
  const [showUnableToMatchSnackbar, setShowUnableToMatchSnackbar] =
    useState<boolean>(false);
  const [matchFreightSnap] = useMatchFreightSnapMutation();
  const { freightSnapTransaction } = apiLog;
  const { allowOpenInNewTab } = useAllowOpenInNewTab();

  const onConfirmMatchFreightSnap = async (proNum: string | undefined) => {
    if (!isNil(freightSnapTransaction)) {
      const res = await matchFreightSnap({
        variables: {
          freightSnapCreateInput: {
            proNum,
            freightSnapTransactionUuid: freightSnapTransaction.uuid,
          },
        },
      });
      if (isNil(res.errors)) {
        freightSnapTransaction.failureReason = null;
        freightSnapTransaction.status = FreightSnapTransactionStatus.Success;
      } else {
        setShowUnableToMatchSnackbar(true);
      }
    }
  };

  return (
    <TableRow
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <TableCell>
        {dayjs(apiLog.createdAt).format('MM/DD/YY hh:mm a')}
      </TableCell>
      <TableCell>
        <Box
          sx={{
            border: 1,
            pl: '2px',
            pr: '2px',
            backgroundColor: '#f7f7f7',
            borderRadius: 1,
            borderColor: 'thistle',
            width: 'fit-content',
            height: 'fit-content',
          }}
        >
          <code
            style={{
              borderRadius: 1,
              whiteSpace: 'pre',
            }}
          >
            {`${freightSnapTransaction?.proNum}`}
          </code>
        </Box>
      </TableCell>
      <TableCell>
        {freightSnapTransaction?.eldim}L x {freightSnapTransaction?.ewdim}W x{' '}
        {freightSnapTransaction?.ehdim}H
      </TableCell>
      <TableCell>{freightSnapTransaction?.weight}</TableCell>
      <TableCell align="center">
        {getStatusChip(freightSnapTransaction?.status)}
      </TableCell>
      <TableCell>
        {sentenceCase(freightSnapTransaction?.failureReason ?? '')}
      </TableCell>
      <TableCell sx={{ width: '250px' }} align="right">
        <Stack direction="row" spacing={2} sx={{ float: 'right' }}>
          {!isNil(freightSnapTransaction?.order) && (
            <Fade in={isHovering}>
              <Button
                variant="contained"
                onClick={(e) => {
                  allowOpenInNewTab(
                    e,
                    `/orders/?orderUuid=${freightSnapTransaction?.order?.uuid}`,
                  );
                }}
              >
                View Order
              </Button>
            </Fade>
          )}
          {freightSnapTransaction?.status ===
            FreightSnapTransactionStatus.Failed && (
            <Fade
              in={
                isHovering &&
                freightSnapTransaction?.status ===
                  FreightSnapTransactionStatus.Failed
              }
            >
              <Button
                variant="contained"
                color="info"
                onClick={() => {
                  setShowFreightSnapMatchModal(true);
                }}
              >
                Match
              </Button>
            </Fade>
          )}
        </Stack>
      </TableCell>
      <FreightSnapMatchModal
        open={showFreightSnapMatchModal}
        setShowFreightSnapMatchModal={setShowFreightSnapMatchModal}
        initialProNum={freightSnapTransaction?.proNum ?? undefined}
        onConfirm={onConfirmMatchFreightSnap}
      />
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showUnableToMatchSnackbar}
        onClose={() => {
          setShowUnableToMatchSnackbar(true);
        }}
      >
        <Alert severity="error">Unable to match freight snap to order</Alert>
      </Snackbar>
    </TableRow>
  );
};

export default React.memo(FreightSnapTransactionRow);
