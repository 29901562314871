// These constants aren't carrying much weight here -- they exist solely to
// make it more difficult to accidentially introduce diversion in behavior
// between different components (multiple of which depend on the inbound stop
// being at index 0 and the outbound stop being at index 1).

export const INBOUND_STOP_IDX = 0;
export const OUTBOUND_STOP_IDX = 1;
export const MAX_LABEL_QUANTITY = 30;

export const getOtherStopIdx = (stopIdx: number) => {
  return stopIdx === INBOUND_STOP_IDX ? OUTBOUND_STOP_IDX : INBOUND_STOP_IDX;
};
