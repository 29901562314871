import { joiResolver } from '@hookform/resolvers/joi';
import joi from 'joi';
import { useForm } from 'react-hook-form';

export type EditItemGroupFormValues = {
  quantity: number;
};

const useEditItemGroupForm = () => {
  const schema = joi.object({
    quantity: joi.number().integer().required().positive().messages({
      'any.required': 'Quantity is required',
      'number.integer': 'Quantity must be a whole number',
      'number.base': 'Quantity must be a number',
      'number.positive': 'Quantity must be a positive number',
    }),
  });
  return useForm<EditItemGroupFormValues>({
    resolver: joiResolver(schema),
  });
};

export default useEditItemGroupForm;
