import { saveAs } from 'file-saver';
import { isNil } from 'lodash';
import {
  transformDateStringToSpecifiedFormat,
  transformTimeToTimeString,
} from '../../common/utils/prettyPrintUtils';
import { DocumentType } from '../../generated/graphql';
import { type DocViewerDocument } from './types/doc-viewer-document';

// We have another getAppointmentTimeString used in the main stops tables
// The formatting is similar but not identical. Eventually we should kill this one
// and just use the shared one `getAppointmentTimeString`.
export const getAppointmentTimeStringEOD = (
  stopAppointmentTime: string | null | undefined,
  stopEndAppointmentTime: string | null | undefined,
) => {
  const appointmentTime = transformDateStringToSpecifiedFormat(
    stopAppointmentTime,
    'hh:mm a',
  );
  const endAppointmentTime = isNil(stopEndAppointmentTime)
    ? undefined
    : transformTimeToTimeString(stopEndAppointmentTime);
  let timeString = '';
  if (
    !isNil(stopAppointmentTime) &&
    (isNil(endAppointmentTime) || appointmentTime === endAppointmentTime)
  ) {
    timeString = `${appointmentTime}`;
  } else if (isNil(stopAppointmentTime) && !isNil(endAppointmentTime)) {
    timeString = `${endAppointmentTime}`;
  } else if (
    !isNil(endAppointmentTime) &&
    !isNil(stopAppointmentTime) &&
    !isNil(stopEndAppointmentTime) &&
    appointmentTime !== endAppointmentTime
  ) {
    timeString = `${appointmentTime} to ${endAppointmentTime}`;
  }

  return timeString;
};

export const downloadImage = async (url: string, name: string | undefined) => {
  try {
    const res = await fetch(url, {
      cache: 'no-cache',
    });
    const blob = await res.blob();
    saveAs(blob, name ?? 'image');
  } catch {
    const img = document.createElement('img'); // Create in-memory image
    img.addEventListener('load', () => {
      const a = document.createElement('a'); // Create in-memory anchor
      a.href = url;
      a.target = '_blank';
      a.click(); // Trigger click (download)
    });
    img.src = url; // Request image from your server
  }
};

export const getDocTypesPrioritizeHasDocuments = (
  docs: DocViewerDocument[],
  hideEmptyDocTypes: boolean,
): DocumentType[] => {
  const docTypesWithDocs: DocumentType[] = [];
  const docTypesWithoutDocs: DocumentType[] = [];
  for (const documentType of Object.values(DocumentType)) {
    if (docs.some((doc) => doc.docType === documentType)) {
      docTypesWithDocs.push(documentType);
    } else if (!hideEmptyDocTypes) {
      docTypesWithoutDocs.push(documentType);
    }
  }
  return [...docTypesWithDocs, ...docTypesWithoutDocs];
};
