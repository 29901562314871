import {
  type Action,
  configureStore,
  combineReducers,
  type ThunkAction,
  type Reducer,
  createAction,
} from '@reduxjs/toolkit';
import accessorialRangesReducer from '../domains/accessorials/redux/accessorial-range-slice';
import accessorialsReducer from '../domains/accessorials/redux/accessorials-slice';
import specialAccessorialChargeGroupsReducer from '../domains/accessorials/redux/special-accessorial-charge-groups-slice';
import specialAccessorialMatrixItemsReducer from '../domains/accessorials/redux/special-accessorial-matrix-items-slice';
import zoneBasedAccessorialChargeGroupsReducer from '../domains/accessorials/redux/zone-based-accessorial-charge-groups-slice';
import zoneBasedAccessorialMatrixItemsReducer from '../domains/accessorials/redux/zone-based-accessorial-matrix-items-slice';
import zoneBasedAccessorialZonesReducer from '../domains/accessorials/redux/zone-based-accessorial-zones-slice';
import addressesErrorsReducer from '../domains/addresses/redux/addresses-errors-slice';
import addressesValuesReducer from '../domains/addresses/redux/addresses-values-slice';
import contactPersonsErrorsReducer from '../domains/contact-persons/redux/contact-persons-errors-slice';
import contactPersonsValuesReducer from '../domains/contact-persons/redux/contact-persons-values-slice';
import contactErrorsReducer from '../domains/contacts/redux/contact-errors-slice';
import contactValuesReducer from '../domains/contacts/redux/contact-values-slice';
import freightChargesErrorsReducer from '../domains/freight-charges/redux/freight-charges-errors-slice';
import freightChargesValuesReducer from '../domains/freight-charges/redux/freight-charges-values-slice';
import fuelChargesErrorsReducer from '../domains/fuel-charges/redux/fuel-charges-errors-slice';
import fuelChargesValuesReducer from '../domains/fuel-charges/redux/fuel-charges-values-slice';
import invoiceErrorsReducer from '../domains/invoice-old/redux/invoice-error-values-slice';
import invoiceValuesReducer from '../domains/invoice-old/redux/invoices-values-slice';
import legsErrorsReducer from '../domains/legs/redux/leg-errors-slice';
import legsValuesReducer from '../domains/legs/redux/leg-values-slice';
import customChargesErrorsReducer from '../domains/orders/redux/custom-charges-errors-slice';
import customChargesValuesReducer from '../domains/orders/redux/custom-charges-values-slice';
import documentsReducer from '../domains/orders/redux/documents-values-slice';
import shipmentSubscriberValuesReducer from '../domains/orders/redux/standard/shipment-subscribers-values-slice';
import standardOrderErrorsReducer from '../domains/orders/redux/standard/standard-orders-errors-slice';
import standardOrderValuesReducer from '../domains/orders/redux/standard/standard-orders-values-slice';
import packageErrorsReducer from '../domains/packages/redux/package-errors-slice';
import packageValuesReducer from '../domains/packages/redux/package-values-slice';
import quotesValuesReducer from '../domains/quotes/redux/quote-values-slice';
import driverValuesReducer from '../domains/routes/redux/driver-values-slice';
import equipmentValuesReducer from '../domains/routes/redux/equipment-values-slice';
import placeValuesReducer from '../domains/routes/redux/places-values-slice';
import routeSlotValuesReducer from '../domains/routes/redux/route-slots-values-slice';
import routeValuesReducer from '../domains/routes/redux/routes-values-slice';
import scannedOrderResultPagesReducer from '../domains/scanned-order-result-pages/redux/scanned-order-result-pages.slice';
import scannedOrderResultsReducer from '../domains/scanned-order-results/redux/scanned-order-results.slice';
import scannedOrdersReducer from '../domains/scanned-orders/redux/scanned-order.slice';
import standardShipmentsErrorsReducer from '../domains/shipments/redux/standard-shipments-errors-slice';
import standardShipmentValuesReducer from '../domains/shipments/redux/standard-shipments-values-slice';
import stopErrorsReducer from '../domains/stops/redux/stop-errors-slice';
import stopValuesReducer from '../domains/stops/redux/stop-values-slice';

export const resetStateAction = createAction('resetState');

const reducers = combineReducers({
  contactErrors: contactErrorsReducer,
  contactValues: contactValuesReducer,
  driverValues: driverValuesReducer,
  quoteValues: quotesValuesReducer,
  equipmentValues: equipmentValuesReducer,
  orderFormLegsValues: legsValuesReducer,
  orderFormLegsErrors: legsErrorsReducer,
  orderFormFreightChargesErrors: freightChargesErrorsReducer,
  orderFormFreightChargesValues: freightChargesValuesReducer,
  orderFormFuelChargesErrors: fuelChargesErrorsReducer,
  orderFormFuelChargesValues: fuelChargesValuesReducer,
  orderFormCustomChargesErrors: customChargesErrorsReducer,
  orderFormDocumentsValues: documentsReducer,
  orderFormCustomChargesValues: customChargesValuesReducer,
  orderFormAddressesValues: addressesValuesReducer,
  orderFormAddressesErrors: addressesErrorsReducer,
  orderFormContactPersonsValues: contactPersonsValuesReducer,
  orderFormContactPersonsErrors: contactPersonsErrorsReducer,
  packageErrors: packageErrorsReducer,
  packageValues: packageValuesReducer,
  placeValues: placeValuesReducer,
  invoiceFormValues: invoiceValuesReducer,
  invoiceErrorFormValues: invoiceErrorsReducer,
  scannedOrders: scannedOrdersReducer,
  scannedOrderResults: scannedOrderResultsReducer,
  scannedOrderResultPages: scannedOrderResultPagesReducer,
  standardOrderErrors: standardOrderErrorsReducer,
  standardOrderValues: standardOrderValuesReducer,
  standardShipmentValues: standardShipmentValuesReducer,
  orderFormStandardShipmentsErrors: standardShipmentsErrorsReducer,
  routeValues: routeValuesReducer,
  routeSlotValues: routeSlotValuesReducer,
  stopErrors: stopErrorsReducer,
  stopValues: stopValuesReducer,
  shipmentSubscriberValues: shipmentSubscriberValuesReducer,
  accessorials: accessorialsReducer,
  zoneBasedAccessorialZones: zoneBasedAccessorialZonesReducer,
  zoneBasedAccessorialChargeGroups: zoneBasedAccessorialChargeGroupsReducer,
  zoneBasedAccessorialMatrixItems: zoneBasedAccessorialMatrixItemsReducer,
  specialAccessorialChargeGroups: specialAccessorialChargeGroupsReducer,
  specialAccessorialMatrixItems: specialAccessorialMatrixItemsReducer,
  accessorialRanges: accessorialRangesReducer,
});

const rootReducer: Reducer = (state, action) => {
  if (action.type === resetStateAction.type) {
    // resets slices to initial state
    return reducers(undefined, action);
  }
  return reducers(state, action);
};

export const createStore = () => {
  return configureStore({
    reducer: rootReducer,
  });
};

export type Store = ReturnType<typeof createStore>;

// You need to import these as types to avoid a circular dependency!
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<Store['getState']>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = Store['dispatch'];
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
