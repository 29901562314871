import { isNil } from 'lodash';
import { useState } from 'react';
import {
  type ParseCsvOrdersInput,
  type ParseCsvOrdersMutation,
  useGenerateCsvOrderPreSignedPutUrlsLazyQuery,
  useParseCsvOrdersMutation,
} from '../../../generated/graphql';

export type ParseCsvOrdersOnCompleted = (
  result: ParseCsvOrdersMutation['parseCsvOrders'],
) => void;

const useSubmitCsvOrder = () => {
  const [loading, setLoading] = useState(false);
  const [generateCsvOrderPreSignedPutUrls] =
    useGenerateCsvOrderPreSignedPutUrlsLazyQuery();
  const [parseCsvOrders] = useParseCsvOrdersMutation();

  const submitCsvOrders = async (
    files: File[],
    onFinish: ParseCsvOrdersOnCompleted,
    csvOrderMappingUuid: string,
  ): Promise<ParseCsvOrdersMutation['parseCsvOrders'] | null> => {
    setLoading(true);
    try {
      const preSignedPutUrls = await generateCsvOrderPreSignedPutUrls({
        variables: {
          generateCsvOrderPresignedPutUrlInputs: files.map((file) => {
            return {
              filename: file.name,
              filetype: file.type,
            };
          }),
        },
      });
      await Promise.all(
        files.map(async (file, idx) => {
          const options = { headers: { 'Content-Type': file.type } };
          const preSignedUrl =
            preSignedPutUrls.data?.generateCsvOrderPreSignedPutUrls[idx];
          if (isNil(preSignedUrl)) {
            // eslint-disable-next-line no-console
            console.error(
              `Could not find pre-signed URL for file - name: ${file.name}, type: ${file.type}, size: ${file.size}`,
            );
            return null;
          }
          return fetch(preSignedUrl.url, {
            method: 'PUT',
            body: file,
            ...options,
          });
        }),
      );

      const preSignedUrl =
        preSignedPutUrls.data?.generateCsvOrderPreSignedPutUrls[0];
      if (!isNil(preSignedUrl)) {
        const parseCsvOrdersInput: ParseCsvOrdersInput = {
          bucket: preSignedUrl.bucket,
          key: preSignedUrl.key,
          filename: preSignedUrl.filename,
          filetype: preSignedUrl.filetype,
          csvOrderMappingUuid,
        };
        const { data } = await parseCsvOrders({
          variables: {
            parseCsvOrdersInput,
          },
        });
        if (!isNil(data)) {
          onFinish(data.parseCsvOrders);
          return data.parseCsvOrders;
        }

        return null;
      }

      // eslint-disable-next-line no-console
      console.error(`Presigned URL is not defined`);
    } finally {
      setLoading(false);
    }

    return null;
  };

  return { submitCsvOrders, loading };
};

export default useSubmitCsvOrder;
