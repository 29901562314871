import WarningIcon from '@mui/icons-material/Warning';
import { Box, Button, Dialog, Typography } from '@mui/material';
import { type Dispatch, type SetStateAction } from 'react';

const ExportToQuickbooksModal = ({
  open,
  setOpen,
  missingCustomerMappings,
  missingFreightChargeMappings,
  missingFuelChargeMappings,
  missingAccessorialMappings,
  conductorUserFacingErrors,
}: {
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
  readonly missingCustomerMappings: string[];
  readonly missingFreightChargeMappings: string[];
  readonly missingFuelChargeMappings: string[];
  readonly missingAccessorialMappings: string[];
  readonly conductorUserFacingErrors?: string[];
}) => {
  const missingAccessorialNameMappings = missingAccessorialMappings.filter(
    (accessorial) => accessorial.length > 0,
  );
  const missingAdHocCustomChargeMappings = missingAccessorialMappings.filter(
    (accessorial) => accessorial.length === 0,
  );
  const containsMissingMappings =
    missingCustomerMappings.length > 0 ||
    missingFreightChargeMappings.length > 0 ||
    missingFuelChargeMappings.length > 0 ||
    missingAccessorialMappings.length > 0 ||
    (conductorUserFacingErrors ?? []).length > 0;

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Box
        sx={{
          p: '20px',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        {containsMissingMappings && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '10px',
              justifyContent: 'center',
            }}
          >
            <WarningIcon />
            <Typography variant="h6">
              Mappings are missing in your export
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            textAlign: 'left',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          {missingCustomerMappings.length > 0 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Typography sx={{ fontWeight: 'bold' }}>
                Customer(s) missing name mappings:
              </Typography>
              <Typography>{missingCustomerMappings.join(', ')}</Typography>
            </Box>
          )}
          {missingFreightChargeMappings.length > 0 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Typography sx={{ fontWeight: 'bold' }}>
                Customer(s) missing freight charge mappings:
              </Typography>
              <Typography>{missingFreightChargeMappings.join(', ')}</Typography>
            </Box>
          )}
          {missingFuelChargeMappings.length > 0 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Typography sx={{ fontWeight: 'bold' }}>
                Customer(s) missing fuel charge mappings:
              </Typography>
              <Typography>{missingFuelChargeMappings.join(', ')}</Typography>
            </Box>
          )}
          {missingAccessorialNameMappings.length > 0 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                textAlign: 'center',
              }}
            >
              <Typography sx={{ fontWeight: 'bold' }}>
                Missing accessorial mappings:
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'right',
                }}
              >
                {missingAccessorialNameMappings.map((mapping) => (
                  <Typography key={mapping}>{mapping}</Typography>
                ))}
              </Box>
            </Box>
          )}
          {(conductorUserFacingErrors ?? []).length > 0 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                textAlign: 'center',
              }}
            >
              <Typography sx={{ fontWeight: 'bold' }}>Errors:</Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'right',
                }}
              >
                {conductorUserFacingErrors?.map((err) => (
                  <Typography key={err}>{err}</Typography>
                ))}
              </Box>
            </Box>
          )}
          {missingAdHocCustomChargeMappings.length > 0 && (
            <Typography>
              {missingAdHocCustomChargeMappings.length} missing ad-hoc charge
              names
            </Typography>
          )}
        </Box>
        <Button
          variant="contained"
          sx={{ ml: 'auto', mr: 'auto' }}
          onClick={() => {
            setOpen(false);
          }}
        >
          Close
        </Button>
      </Box>
    </Dialog>
  );
};

export default ExportToQuickbooksModal;
