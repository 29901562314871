// eslint-disable-next-line no-restricted-imports
import { Box, Grid, type SxProps, Typography, useTheme } from '@mui/material';
import { sentenceCase } from 'change-case';
import { isEmpty, isNil } from 'lodash';
import { useMemo } from 'react';
import { isTariffBillingMethod } from 'shared/billing';
import { safeMultiply } from 'shared/math';
import { type InputBillingMethod } from 'shared/types';
import { formatWeightUnits } from '../../../../common/utils/prettyPrintUtils';
import {
  FreightBillingMethod,
  FuelBillingMethod,
  type LoadFreightChargeFragment,
  TariffType,
  TariffZoneType,
  type WeightUnits,
} from '../../../../generated/graphql';
import { calculateTariffRate } from '../../../orders/components/utils';

export const FREIGHT_CHARGE_HEADERS = [
  '',
  'Billing method',
  'Description',
  'Rate',
  'Quantity',
  'Total',
];

export const FUEL_CHARGE_HEADERS = [
  '',
  'Placeholder-0',
  'Description',
  'Surcharge rate',
  'Placeholder-1', // Empty one so that it aligns with the freight charge grid
  'Total',
];

const calculateFuelTotal = ({
  surchargeRate,
  flatRate,
  totalFreightCharge,
  type,
}: {
  surchargeRate: number;
  flatRate: number;
  totalFreightCharge: number;
  type: FuelBillingMethod | undefined;
}): number => {
  // Convert the surcharge rate to a decimal
  const result = safeMultiply(
    safeMultiply(totalFreightCharge, surchargeRate),
    0.01,
  );
  if (type === FuelBillingMethod.FlatRate) {
    return flatRate;
  }
  if (type === FuelBillingMethod.None) {
    return 0;
  }
  if (!Number.isNaN(result)) {
    return result;
  }
  return 0;
};

/**
 * The "Quantity" can represent different things depending on the billing method, so dynamically change the column name
 * @param billingMethod
 */
export const getQuantityColumnHeader = (
  billingMethod: InputBillingMethod | undefined,
  tariffType: TariffType | null,
  tariffZoneType?: TariffZoneType,
): string => {
  if (tariffZoneType === TariffZoneType.Miles) {
    if (tariffType === TariffType.PerCubicFoot) {
      return 'Miles (Total Package Volume)';
    }
    return 'Miles';
  }
  switch (billingMethod) {
    case undefined: {
      return 'Quantity';
    }
    case FreightBillingMethod.FlatRate: {
      // In the flat rate case this column moves one over to preserve alignment with the fuel charge table
      return '';
    }
    case FreightBillingMethod.PerMile: {
      return 'Miles';
    }
    case FreightBillingMethod.Weight: {
      return 'Weight';
    }
    case FreightBillingMethod.PerPiece: {
      return 'Total piece count';
    }
    case 'CUSTOMER_TARIFF':
    case 'STANDARD_TARIFF': {
      if (tariffType === TariffType.PerHundredPounds) {
        return 'Total package weight';
      }
      if (tariffType === TariffType.PerPiece) {
        return 'Total piece count';
      }
      return 'Quantity';
    }

    default: {
      return 'Quantity';
    }
  }
};

/**
 * The "amount" can represent different things depending on the billing method, so dynamically change the column name
 * @param billingMethod
 * @param weightUnits
 */
export const getRateColumnHeader = (
  billingMethod: InputBillingMethod | undefined,
  weightUnits: WeightUnits | undefined,
  tariffType: TariffType | null,
  tariffZoneType?: TariffZoneType,
): string => {
  switch (billingMethod) {
    case undefined: {
      return 'Rate';
    }
    case FreightBillingMethod.FlatRate: {
      // In the flat rate case this becomes the amount column to preserve alignment with the fuel charge table
      return 'Flat charge ($)';
    }
    case FreightBillingMethod.PerMile: {
      return 'Rate ($ / mile)';
    }
    case FreightBillingMethod.PerPiece: {
      return 'Rate ($ / piece)';
    }
    case FreightBillingMethod.Weight: {
      return `Rate ($ / 100 ${formatWeightUnits(weightUnits) ?? 'lbs'})`;
    }
    case 'CUSTOMER_TARIFF': {
      if (
        tariffZoneType === TariffZoneType.Miles &&
        tariffType === TariffType.PerCubicFoot
      ) {
        return 'Rate ($ / cu ft)';
      }
      return 'Rate';
    }
    case 'STANDARD_TARIFF': {
      return `Rate`;
    }
    default: {
      return 'Rate';
    }
  }
};

type FreightChargesTableProps = {
  readonly freightCharge: LoadFreightChargeFragment;

  readonly weightUnits: WeightUnits | undefined;
};

const FreightChargesTable = ({
  freightCharge,
  weightUnits,
}: FreightChargesTableProps) => {
  const theme = useTheme();
  const styles = {
    doubleCell: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    } as SxProps,
  };

  const cellStyle: SxProps = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const tariffRate =
    !isNil(freightCharge.tariff) && !isNil(freightCharge.tariff.tariffRanges)
      ? calculateTariffRate({
          tariff: freightCharge.tariff,
          quantity: 1,
        })
      : 0;

  const fuelChargeTotal = useMemo(() => {
    return calculateFuelTotal({
      surchargeRate: freightCharge?.fuelCharge?.surchargeRate ?? 0,
      flatRate: freightCharge?.fuelCharge?.flatRate ?? 0,
      totalFreightCharge: freightCharge.total,
      type: freightCharge?.fuelCharge?.type,
    });
  }, [freightCharge]);

  return (
    <Grid container spacing={2} sx={{ marginBottom: 6 }}>
      {/* <Grid item xs={12}>
        <Typography variant="h6">Freight Charges</Typography>
      </Grid> */}
      <Grid item xs={12}>
        <Grid
          key="freight-charge"
          container
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            mb: theme.spacing(2),
          }}
        >
          {FREIGHT_CHARGE_HEADERS.map((header) => {
            let headerString = header;
            if (header === 'Quantity') {
              headerString = getQuantityColumnHeader(
                freightCharge.billingMethod as InputBillingMethod,
                freightCharge?.tariff?.tariffType ?? null,
              );
            } else if (header === 'Rate') {
              headerString = getRateColumnHeader(
                freightCharge.billingMethod as InputBillingMethod,
                weightUnits,
                freightCharge?.tariff?.tariffType ?? null,
                freightCharge?.tariff?.tariffZone?.type,
              );
            }

            return (
              <Grid key={header} item xs={2}>
                <Box>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    {headerString}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          <Grid key="name" item xs={2}>
            <Box>
              <Typography sx={{ fontWeight: 'bold' }}>
                Freight charge
              </Typography>
            </Box>
          </Grid>
          <Grid key="billing-method" item xs={2}>
            <Box sx={styles.doubleCell}>
              {sentenceCase(freightCharge.billingMethod)}
              {freightCharge.billingMethod === FreightBillingMethod.Tariff &&
                !isEmpty(freightCharge.tariff?.tariffZone?.name) &&
                `(Zone: ${freightCharge.tariff?.tariffZone?.name})`}
            </Box>
          </Grid>
          <Grid key="description" item xs={2}>
            <Box sx={cellStyle}>
              <Typography>{freightCharge.description}</Typography>
            </Box>
          </Grid>
          <Grid key="rate" item xs={2}>
            <Box sx={cellStyle}>
              {isTariffBillingMethod(
                freightCharge.billingMethod as InputBillingMethod,
              ) ? (
                <Typography>{tariffRate}</Typography>
              ) : (
                <Typography>{freightCharge.rate}</Typography>
              )}
            </Box>
          </Grid>
          {(freightCharge.billingMethod as InputBillingMethod) ===
          FreightBillingMethod.FlatRate ? (
            <Grid key="quantity" item xs={2} />
          ) : (
            <Grid key="quantity" item xs={2}>
              <Box sx={cellStyle}>
                <Typography>{freightCharge.quantity}</Typography>
              </Box>
            </Grid>
          )}
          <Grid key="total" item xs={2}>
            <Box sx={cellStyle}>
              <Typography sx={{ display: 'inline' }}>
                {`$${freightCharge.total?.toFixed(2)}`}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {!isNil(freightCharge.fuelCharge) && (
          <>
            <Grid item xs={12}>
              <Grid
                key="fuel-charge"
                container
                sx={{ mt: theme.spacing(3), mb: theme.spacing(2) }}
              >
                {FUEL_CHARGE_HEADERS.map((header) => {
                  let headerString = header;
                  if (header.startsWith('Placeholder')) {
                    headerString = '';
                  }
                  return (
                    <Grid key={header} item xs={2}>
                      <Box sx={cellStyle}>
                        <Typography sx={{ fontWeight: 'bold' }}>
                          {headerString}
                        </Typography>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Grid key="name" item xs={2}>
                  <Box sx={cellStyle}>
                    <Typography sx={{ fontWeight: 'bold' }}>
                      Fuel charge
                    </Typography>
                  </Box>
                </Grid>
                <Grid key="type" item xs={2}>
                  <Box sx={cellStyle}>
                    {sentenceCase(freightCharge?.fuelCharge.type)}
                  </Box>
                </Grid>
                <Grid key="description" item xs={2}>
                  <Box sx={cellStyle}>
                    {freightCharge?.fuelCharge.description}
                  </Box>
                </Grid>
                <Grid key="surcharge-rate" item xs={2}>
                  <Box sx={cellStyle}>
                    {freightCharge.fuelCharge.type ===
                      FuelBillingMethod.Percentage ||
                    freightCharge.fuelCharge.type ===
                      FuelBillingMethod.AutoCalculate
                      ? freightCharge.fuelCharge?.surchargeRate
                      : freightCharge.fuelCharge?.flatRate}
                  </Box>
                </Grid>
                <Grid key="second-placeholder" item xs={2} />
                <Grid key="total" item xs={2}>
                  <Box sx={cellStyle}>{`$${fuelChargeTotal?.toFixed(2)}`}</Box>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default FreightChargesTable;
