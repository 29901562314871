import { TableRow, TableCell } from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import { useAppSelector } from '../../../../redux/hooks';
import { selectPackageById } from '../../../packages/redux/package-values-slice';

type PackageRowProps = {
  readonly packageUuid: string;
  readonly displayPackageSpecName: boolean;
};

const PackageRow = ({
  packageUuid,
  displayPackageSpecName,
}: PackageRowProps) => {
  const packageValues = useAppSelector((state) =>
    selectPackageById(state, packageUuid),
  );
  return (
    <TableRow>
      <TableCell>
        {sentenceCase(
          (displayPackageSpecName
            ? packageValues?.packageSpec?.name
            : packageValues?.type) ?? 'NA',
        )}
      </TableCell>
      <TableCell>{packageValues?.quantity}</TableCell>
      <TableCell>{packageValues?.weight}</TableCell>
      <TableCell>
        {!isNil(packageValues?.description) &&
        (packageValues?.description?.length ?? -1) > 0
          ? packageValues?.description
          : 'NA'}
      </TableCell>
    </TableRow>
  );
};

export default PackageRow;
