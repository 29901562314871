import { isNil } from 'lodash';
import { useCallback, useMemo } from 'react';
import {
  type ServiceFragment,
  useServicesLazyQuery,
} from '../../generated/graphql';
import useGlobalStore from '../../layouts/dashboard/global-store';

type Services = {
  loadServices: () => Promise<void>;
  getServiceName: (uuid: string | null | undefined) => string;
  services: ServiceFragment[];
};

const useServices = () => {
  const services = useGlobalStore((state) => state.services);
  const setServices = useGlobalStore((state) => state.setServices);
  const [getServices, { loading: servicesLoading }] = useServicesLazyQuery();

  const activeServices = useMemo<Services['services']>(
    () => services.filter((service) => service.isActive),
    [services],
  );

  const getServiceName = useCallback<Services['getServiceName']>(
    (uuid: string | null | undefined) => {
      if (isNil(uuid)) {
        return '';
      }
      return services.find((service) => service.uuid === uuid)?.name ?? '';
    },
    [services],
  );

  const loadServices = useCallback<Services['loadServices']>(async () => {
    const res = await getServices();
    const serviceLevels = res.data?.services;
    if (!isNil(serviceLevels)) {
      setServices(serviceLevels);
    }
  }, [getServices, setServices]);

  return {
    loadServices,
    getServiceName,
    /** Array of active services (where isActive === true) */
    services: activeServices,
    servicesLoading,
  };
};

export default useServices;
