import { isNilOrEmptyString } from 'shared/string';
import { z } from 'zod';
import { validatePhoneNumber } from '../../../../../common/utils/utils';

export const contactPersonSchema = z.object({
  uuid: z.string().uuid(),
  firstName: z.string().nullish(),
  lastName: z.string().nullish(),
  notes: z.string().nullish(),
  phone: z
    .string()
    .nullish()
    .refine((val) => isNilOrEmptyString(val) || validatePhoneNumber(val), {
      message: 'Invalid contact phone number',
    }),
  email: z.preprocess(
    // Preprocess empty strings to undefined
    // because email() doesn't accept empty strings
    (val) => (val === '' ? undefined : val),
    z.string().email({ message: 'Invalid contact email' }).nullish(),
  ),
});
