import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isEmpty, isNil } from 'lodash';
import React, { type FunctionComponent, useEffect, useState } from 'react';
import { comparePlainTimes } from 'shared/plain-date-time';
import { v4 } from 'uuid';
import { PlainTimeInputField } from '../../../common/components/plain-time-input-field';
import { ZipcodeAndCityMultiSelect } from '../../../common/components/zipcode-and-city-multi-select';
import { useHasUnsavedChanges } from '../../../common/react-hooks/use-has-unsaved-changes';
import {
  isZipcode,
  removeNonZipcodeCharacters,
} from '../../../common/utils/zipcodes';
import {
  type AllSchedulingZoneFieldsFragment,
  type PlainTimeInput,
  type SchedulingZoneAvailabilityUpsertInput,
  type SchedulingZoneLocationUpsertInput,
  useCreateSchedulingZoneMutation,
  useUpdateSchedulingZoneMutation,
} from '../../../generated/graphql';
import { daysOfWeek } from './days-of-week';

dayjs.extend(utc);
dayjs.extend(timezone);

const styles = {
  sectionHeading: {
    fontSize: '14.5px',
    fontWeight: 500,
    mt: 3,
    mb: 2,
  },
};

type SchedulingZoneFormData = {
  name: string;
  locations: SchedulingZoneLocationUpsertInput[];
  availabilities: FormDataAvailability[];
};

// The working state has times in HH:mm format, but the API expects them as ISO 8601 strings.
type FormDataAvailability = Pick<
  SchedulingZoneAvailabilityUpsertInput,
  'id' | 'dayOfWeek'
> & {
  start: PlainTimeInput | null | undefined;
  end: PlainTimeInput | null | undefined;
};

type SchedulingZoneFormErrors = {
  [key in keyof SchedulingZoneFormData]?: string;
};

type SchedulingZoneDialogProps = {
  readonly schedulingRegionId: string;
  // Pass in a scheduling zone to edit it instead of creating a new one.
  readonly zone: AllSchedulingZoneFieldsFragment | null;
  readonly open: boolean;
  readonly onSaved: (zone: { id: string }) => void;
  readonly onClose: () => void;
};

export const SchedulingZoneDialog: FunctionComponent<
  SchedulingZoneDialogProps
> = ({ schedulingRegionId, zone, open, onSaved, onClose }) => {
  const {
    hasUnsavedChanges,
    triggerHasUnsavedChanges,
    resetHasUnsavedChanges,
  } = useHasUnsavedChanges();

  const [globalErrorMessage, setGlobalErrorMessage] = useState<string | null>(
    null,
  );
  const [create, { loading: creating }] = useCreateSchedulingZoneMutation({
    onCompleted: (data) => {
      if (
        data.createSchedulingZone.__typename ===
        'CreateSchedulingZoneSuccessOutput'
      ) {
        resetHasUnsavedChanges();
        onSaved(data.createSchedulingZone.schedulingZone);
      } else {
        setGlobalErrorMessage(data.createSchedulingZone.message);
      }
    },
    onError: (error) => {
      setGlobalErrorMessage(error.message);
    },
  });
  const [update, { loading: updating }] = useUpdateSchedulingZoneMutation({
    onCompleted: (data) => {
      if (
        data.updateSchedulingZone.__typename ===
        'UpdateSchedulingZoneSuccessOutput'
      ) {
        resetHasUnsavedChanges();
        onSaved(data.updateSchedulingZone.schedulingZone);
      } else {
        setGlobalErrorMessage(data.updateSchedulingZone.message);
      }
    },
    onError: (error) => {
      setGlobalErrorMessage(error.message);
    },
  });

  const [formData, setFormData] = useState<SchedulingZoneFormData>({
    name: '',
    locations: [],
    availabilities: [],
  });
  const [formErrors, setFormErrors] = useState<SchedulingZoneFormErrors>({});
  const [enterLocationManually, setEnterLocationManually] = useState(false);
  const [manualLocationZipcode, setManualLocationZipcode] = useState('');
  const [manualLocationCity, setManualLocationCity] = useState('');

  useEffect(() => {
    setGlobalErrorMessage(null);
    setFormData({
      name: zone?.name ?? '',
      locations:
        zone?.locations.map(({ city, id, zipcode }) => ({
          id,
          zipcode,
          city,
        })) ?? [],
      availabilities:
        zone?.availabilities.map(({ id, dayOfWeek, start, end }) => ({
          id,
          dayOfWeek,
          // Omit the __typename field for these PlainTimeInput objects.
          start: {
            hour: start.hour,
            minute: start.minute,
          },
          end: {
            hour: end.hour,
            minute: end.minute,
          },
        })) ?? [],
    });
    setFormErrors({});
  }, [zone, open]);

  const onSave = () => {
    setGlobalErrorMessage(null);
    const newErrors: SchedulingZoneFormErrors = {};

    const name = formData.name.trim();
    if (formData.name.length === 0) {
      newErrors.name = 'A name is required';
    }

    const invalidTimeMessages: string[] = [];
    const availabilities: SchedulingZoneAvailabilityUpsertInput[] = [];
    for (const { id, dayOfWeek, start, end } of formData.availabilities) {
      if (isNil(start)) {
        invalidTimeMessages.push(
          `A start time for ${sentenceCase(dayOfWeek)} is required if this day is enabled`,
        );
      } else if (isNil(end)) {
        invalidTimeMessages.push(
          `An end time for ${sentenceCase(dayOfWeek)} is required if this day is enabled`,
        );
      } else if (comparePlainTimes(start, end) > 0) {
        invalidTimeMessages.push(
          `The end time for ${sentenceCase(dayOfWeek)} cannot be before the start time`,
        );
      } else {
        availabilities.push({
          id,
          dayOfWeek,
          start,
          end,
        });
      }
    }
    if (invalidTimeMessages.length > 0) {
      newErrors.availabilities = invalidTimeMessages.join('; ');
    }

    setFormErrors(newErrors);
    if (!isEmpty(newErrors)) {
      return;
    }

    if (isNil(zone)) {
      create({
        variables: {
          input: {
            schedulingRegionId,
            name,
            availabilities,
            locations: formData.locations,
          },
        },
      });
    } else {
      update({
        variables: {
          input: {
            id: zone.id,
            name,
            availabilities,
            locations: formData.locations,
          },
        },
      });
    }
  };

  return (
    <Dialog
      open={open}
      PaperProps={{ sx: { width: '550px' } }}
      onClose={onClose}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={2}
      >
        <Typography variant="h4" fontSize="20px" lineHeight={1}>
          {isNil(zone) ? 'Add a' : 'Edit'} scheduling zone
        </Typography>
        <IconButton
          disabled={creating}
          style={{ width: '30px', height: '30px' }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider />
      <Box p={3}>
        {!isNil(globalErrorMessage) && (
          <Typography color="error" mb={2}>
            {globalErrorMessage}
          </Typography>
        )}
        <FormControl required sx={{ width: '100%' }}>
          <TextField
            id="scheduling-zone-name"
            label="Name"
            value={formData.name}
            size="small"
            style={{ width: '100%' }}
            onChange={({ target }) => {
              setFormData({ ...formData, name: target.value });
              setFormErrors(({ name: _ignored, ...prevFormErrors }) => ({
                ...prevFormErrors,
              }));
              triggerHasUnsavedChanges();
            }}
          />
          {'name' in formErrors && (
            <FormHelperText error>{formErrors.name}</FormHelperText>
          )}
        </FormControl>
        <Typography sx={styles.sectionHeading}>
          Locations covered by this zone
        </Typography>
        {enterLocationManually ? (
          <Box>
            <Stack direction="row" gap={2} mb={2}>
              <FormControl sx={{ width: '122px', minWidth: '122px' }}>
                <TextField
                  id="scheduling-zone-name-manual-zipcode"
                  label="Zipcode"
                  value={manualLocationZipcode}
                  size="small"
                  onChange={({ target }) => {
                    const value = removeNonZipcodeCharacters(target.value);
                    setManualLocationZipcode(value);
                  }}
                />
              </FormControl>
              <FormControl sx={{ flexGrow: 1 }}>
                <TextField
                  id="scheduling-zone-name-manual-city"
                  label="City"
                  value={manualLocationCity}
                  size="small"
                  onChange={({ target }) => {
                    setManualLocationCity(target.value);
                  }}
                />
              </FormControl>
              <Button
                variant="contained"
                disabled={
                  !isZipcode(manualLocationZipcode) ||
                  manualLocationCity.trim().length === 0
                }
                sx={{ width: 'max-content', minWidth: 'max-content' }}
                onClick={() => {
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    locations: [
                      ...prevFormData.locations,
                      {
                        id: v4(),
                        zipcode: manualLocationZipcode,
                        city: manualLocationCity,
                      },
                    ],
                  }));
                  setEnterLocationManually(false);
                  setManualLocationZipcode('');
                  setManualLocationCity('');
                  triggerHasUnsavedChanges();
                }}
              >
                Add location
              </Button>
            </Stack>
            <Button
              variant="outlined"
              onClick={() => {
                setEnterLocationManually(false);
                setManualLocationZipcode('');
                setManualLocationCity('');
                triggerHasUnsavedChanges();
              }}
            >
              Use location selector
            </Button>
          </Box>
        ) : (
          <ZipcodeAndCityMultiSelect
            excludeLocation={({ zipcode, city }) =>
              formData.locations.some(
                (location) =>
                  location.zipcode === zipcode && location.city === city,
              )
            }
            onSelect={({ zipcode, city }) => {
              setFormData((prevFormData) => ({
                ...formData,
                locations: [
                  ...prevFormData.locations,
                  {
                    id: v4(),
                    zipcode,
                    city,
                  },
                ],
              }));
              triggerHasUnsavedChanges();
            }}
            onEnterManually={(input) => {
              setManualLocationZipcode(input);
              setManualLocationCity('');
              setEnterLocationManually(true);
            }}
          />
        )}
        {formData.locations.length > 0 && (
          <Stack direction="row" gap={1} mt={2} flexWrap="wrap">
            {formData.locations.map(({ id, city, zipcode }) => (
              <Chip
                key={id}
                label={
                  <span>
                    <span style={{ fontWeight: 700, paddingRight: '2px' }}>
                      {zipcode}
                    </span>{' '}
                    {city}
                  </span>
                }
                sx={{
                  borderRadius: '4px',
                }}
                onDelete={() => {
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    locations: prevFormData.locations.filter(
                      (location) => location.id !== id,
                    ),
                  }));
                  triggerHasUnsavedChanges();
                }}
              />
            ))}
          </Stack>
        )}
        <Typography sx={styles.sectionHeading}>Service times</Typography>
        <Stack gap={2}>
          {daysOfWeek.map((day) => (
            <Stack key={day} direction="row" alignItems="center" gap={2}>
              <FormControlLabel
                sx={{ width: '130px' }}
                label={sentenceCase(day)}
                control={
                  <Checkbox
                    checked={formData.availabilities.some(
                      (availability) => availability.dayOfWeek === day,
                    )}
                    onChange={(_event, checked) => {
                      if (checked) {
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          availabilities: [
                            ...prevFormData.availabilities,
                            {
                              id: v4(),
                              dayOfWeek: day,
                              start: undefined,
                              end: undefined,
                            },
                          ],
                        }));
                      } else {
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          availabilities: prevFormData.availabilities.filter(
                            (availability) => availability.dayOfWeek !== day,
                          ),
                        }));
                        // Clear any error on availabilities if a day is disabled.
                        setFormErrors(
                          ({
                            availabilities: _ignored,
                            ...prevFormErrors
                          }) => ({
                            ...prevFormErrors,
                          }),
                        );
                      }
                      triggerHasUnsavedChanges();
                    }}
                  />
                }
              />
              <PlainTimeInputField
                width="75px"
                placeholder="HH:MM"
                time={
                  formData.availabilities.find(
                    ({ dayOfWeek }) => dayOfWeek === day,
                  )?.start
                }
                onChange={(newTime) => {
                  setFormData((prevFormData) => {
                    const currentElement = prevFormData.availabilities.find(
                      (availability) => availability.dayOfWeek === day,
                    );
                    if (isNil(currentElement)) {
                      return {
                        ...prevFormData,
                        availabilities: [
                          ...prevFormData.availabilities,
                          {
                            id: v4(),
                            dayOfWeek: day,
                            start: newTime,
                            end: undefined,
                          },
                        ],
                      };
                    }
                    return {
                      ...prevFormData,
                      availabilities: prevFormData.availabilities.map(
                        (availability) =>
                          availability.dayOfWeek === day
                            ? {
                                ...availability,
                                start: newTime,
                              }
                            : availability,
                      ),
                    };
                  });
                  setFormErrors(
                    ({ availabilities: _ignored, ...prevFormErrors }) => ({
                      ...prevFormErrors,
                    }),
                  );
                  triggerHasUnsavedChanges();
                }}
              />
              <Typography>–</Typography>
              <PlainTimeInputField
                width="75px"
                placeholder="HH:MM"
                time={
                  formData.availabilities.find(
                    ({ dayOfWeek }) => dayOfWeek === day,
                  )?.end
                }
                onChange={(newTime) => {
                  setFormData((prevFormData) => {
                    const currentElement = prevFormData.availabilities.find(
                      (availability) => availability.dayOfWeek === day,
                    );
                    if (isNil(currentElement)) {
                      return {
                        ...prevFormData,
                        availabilities: [
                          ...prevFormData.availabilities,
                          {
                            id: v4(),
                            dayOfWeek: day,
                            start: undefined,
                            end: newTime,
                          },
                        ],
                      };
                    }
                    return {
                      ...prevFormData,
                      availabilities: prevFormData.availabilities.map(
                        (availability) =>
                          availability.dayOfWeek === day
                            ? {
                                ...availability,
                                end: newTime,
                              }
                            : availability,
                      ),
                    };
                  });
                  setFormErrors(
                    ({ availabilities: _ignored, ...prevFormErrors }) => ({
                      ...prevFormErrors,
                    }),
                  );
                  triggerHasUnsavedChanges();
                }}
              />
            </Stack>
          ))}
        </Stack>
        {'availabilities' in formErrors && (
          <FormHelperText error sx={{ mt: 1 }}>
            {formErrors.availabilities}
          </FormHelperText>
        )}
      </Box>
      <Divider />
      <Stack direction="row" justifyContent="flex-end" p={2} gap={2}>
        <Button
          variant="text"
          color="inherit"
          disabled={creating || updating}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={!hasUnsavedChanges || creating || updating}
          onClick={onSave}
        >
          {isNil(zone) ? 'Create' : 'Save'}
        </Button>
      </Stack>
    </Dialog>
  );
};
