import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { sentenceCase } from 'change-case';
import currency from 'currency.js';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { isEmpty, isNil, orderBy, sumBy } from 'lodash';
import { getSettlementEligibleTotal } from 'shared/billing';
import { transformAddressToFullAddressString } from 'shared/copy';
import { safeMultiply } from 'shared/math';
import { WrappedText } from 'shared/pdfs/common';
import {
  type DriverSettlementBillForDownloadFragment,
  type MeQuery,
  StopType,
  Segment,
  type StopDriverMapForDownloadFragment,
} from '../../../../generated/graphql';
import { SettlementsDownloadType } from '../../types';

dayjs.extend(duration);

const TEXT_PADDING = '3px';

const styles = StyleSheet.create({
  fullWidth: {
    width: '100%',
  },
  page: {
    flexDirection: 'column',
    padding: 25,
    fontFamily: 'Roboto',
  },
  header1: {
    fontSize: '18px',
  },
  header2: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  basicFont: {
    fontSize: '11px',
  },
  basicFontHeader: {
    fontSize: '12px',
  },
  detailedFont: {
    fontSize: '8px',
  },
  detailedFontHeader: {
    fontSize: '8px',
  },
  totalBreakdownFont: {
    fontSize: '9px',
  },
  totalFont: {
    fontSize: '14px',
  },
  rowWithSpaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  filterLabelText: {
    fontWeight: 'bold',
    width: '75px',
  },
  settlementTable: {
    width: '100%',
  },
  settlementTableRow: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingVertical: 2,
    paddingHorizontal: 4,
    justifyContent: 'space-between',
    width: '100%',
    textAlign: 'center',
  },
  settlementTableHeader: {
    borderTop: 'none',
    fontWeight: 'bold',
    borderBottom: '1px solid black',
    backgroundColor: '#f0eded',
    paddingVertical: 6,
    paddingHorizontal: 4,
    marginTop: 4,
  },
  settlementTableRowLeftSectionBasicColumn: {
    width: '85%',
  },
  settlementTableRowLeftSectionBasic: {
    display: 'flex',
    flexDirection: 'row',
    width: '85%',
    textAlign: 'left',
  },
  settlementTableRowRightSectionBasic: {
    display: 'flex',
    flexDirection: 'row',
    width: '15%',
    textAlign: 'right',
  },
  settlementTableRowLeftSectionDetailedColumn: {
    width: '100%',
  },
  settlementTableRowLeftSectionDetailed: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    textAlign: 'left',
  },
  settlementTableRowRightSectionDetailed: {
    display: 'flex',
    flexDirection: 'row',
    width: '25%',
    textAlign: 'right',
  },
  flexEnd: {
    justifyContent: 'flex-end',
  },
  basicRefInfoCol: {
    width: '20%',
    paddingRight: TEXT_PADDING,
  },
  basicInfoCol: {
    width: '30%',
    paddingRight: TEXT_PADDING,
  },
  detailedRefInfoCol: {
    width: '10%',
    paddingRight: TEXT_PADDING,
  },
  detailedDateCol: {
    width: '20%',
    paddingRight: TEXT_PADDING,
  },
  detailedCustomerCol: {
    width: '15%',
    paddingRight: TEXT_PADDING,
    wordBreak: 'break-word',
  },
  detailedAddressCol: {
    width: '35%',
    paddingRight: TEXT_PADDING,
  },
  detailedStopTypeCol: {
    width: '10%',
    paddingRight: TEXT_PADDING,
  },
  detailedShipmentInfoCol: {
    width: '15%',
    paddingRight: TEXT_PADDING,
  },
  detailedPayColLong: {
    width: '7%',
    paddingRight: TEXT_PADDING,
    textAlign: 'right',
  },
  pageNumbers: {
    position: 'absolute',
    bottom: 15,
    left: 0,
    right: 10,
    textAlign: 'right',
    fontSize: '10px',
  },
});

const getTimeAtStop = (stopDriverMap: StopDriverMapForDownloadFragment) => {
  return isNil(stopDriverMap.arrivedAt) ||
    isNil(stopDriverMap.completedAt) ||
    dayjs(stopDriverMap.completedAt).isBefore(dayjs(stopDriverMap.arrivedAt))
    ? '-'
    : dayjs
        .duration(
          dayjs(stopDriverMap.completedAt).diff(dayjs(stopDriverMap.arrivedAt)),
        )
        .format('H[h] m[m]');
};

const OtherDrivers = ({
  stopDriverMap,
}: {
  readonly stopDriverMap?: StopDriverMapForDownloadFragment;
}) => {
  if (isNil(stopDriverMap) || isEmpty(stopDriverMap.otherDriversOnStop))
    return null;
  return (
    <View style={{ textAlign: 'left', marginTop: 4 }}>
      <Text>
        <Text style={{ fontWeight: 'bold' }}>Performed with: </Text>
        <Text>
          {stopDriverMap.otherDriversOnStop
            .map(
              (driver) =>
                `${driver.firstName.trim()} ${driver.lastName.trim()}`,
            )
            .join(', ')}
        </Text>
      </Text>
    </View>
  );
};

const SettlementBillBasic = ({
  settlementBill,
  bolString,
  displayDriverPayout,
  showOtherDriversPaidForStop,
}: {
  readonly settlementBill: DriverSettlementBillForDownloadFragment;
  readonly bolString: string;
  readonly displayDriverPayout: boolean;
  readonly showOtherDriversPaidForStop: boolean;
}) => {
  const settlementTotal = settlementBill.stopDriverMaps.reduce(
    (prev, curr) => prev + curr.driverSettlementTotalAmounts.totalDriverPayout,
    0,
  );
  const totalDeductions = sumBy(
    settlementBill.settlementDeductions,
    (settlementDeduction) =>
      getSettlementEligibleTotal(
        settlementDeduction.flatRate,
        isNil(settlementDeduction.percentageDecimalRate)
          ? undefined
          : safeMultiply(settlementDeduction.percentageDecimalRate, 100),
        settlementTotal,
      ),
  );
  const finalPay = settlementTotal - totalDeductions;

  return (
    <View style={styles.settlementTable}>
      <View
        style={[
          styles.settlementTableRow,
          styles.settlementTableHeader,
          styles.basicFontHeader,
        ]}
      >
        <View style={styles.settlementTableRowLeftSectionBasic}>
          <Text style={styles.basicRefInfoCol}>{bolString}</Text>
          <Text style={styles.basicRefInfoCol}>Order Name</Text>
          <Text style={styles.basicInfoCol}>Completed At</Text>
          <Text style={styles.basicInfoCol}>Customer</Text>
        </View>
        <View style={styles.settlementTableRowRightSectionBasic}>
          {displayDriverPayout && (
            <Text style={{ textAlign: 'right' }}>Pay</Text>
          )}
        </View>
      </View>
      {settlementBill.stopDriverMaps
        .filter(
          (stopDriverMap) =>
            !(
              (
                [StopType.Transfer, StopType.Recovery] as Array<
                  StopType | null | undefined
                >
              ).includes(stopDriverMap.stopType) &&
              stopDriverMap.driverSettlementTotalAmounts
                .totalChargesForSettlement === 0 &&
              stopDriverMap.driverSettlementTotalAmounts.totalDriverPayout === 0
            ),
        )
        .map((stopDriverMap) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <View style={[styles.settlementTableRow, styles.basicFont]}>
              <View style={styles.settlementTableRowLeftSectionBasicColumn}>
                <View
                  style={[
                    styles.settlementTableRowLeftSectionBasic,
                    styles.fullWidth,
                  ]}
                >
                  <WrappedText style={styles.basicRefInfoCol}>
                    {stopDriverMap.shipperBillOfLadingNumber}
                  </WrappedText>
                  <WrappedText style={styles.basicRefInfoCol}>
                    {stopDriverMap.orderName}
                  </WrappedText>
                  <Text style={styles.basicInfoCol}>
                    {isNil(stopDriverMap.completedAt)
                      ? ''
                      : dayjs(stopDriverMap.completedAt).format(
                          'MM/DD/YY hh:mm a',
                        )}
                    {stopDriverMap.isAttempt ? ' (Attempt)' : ''}
                  </Text>
                  <Text style={styles.basicInfoCol}>
                    {stopDriverMap.billingPartyContactName}
                  </Text>
                </View>
                {showOtherDriversPaidForStop && (
                  <OtherDrivers stopDriverMap={stopDriverMap} />
                )}
              </View>
              <View style={styles.settlementTableRowRightSectionBasic}>
                {displayDriverPayout && (
                  <Text style={{ textAlign: 'right' }}>
                    {currency(
                      stopDriverMap.driverSettlementTotalAmounts
                        .totalDriverPayout ?? 0,
                    ).format()}
                  </Text>
                )}
              </View>
            </View>
          );
        })}
      {displayDriverPayout && (
        <>
          <View
            style={[
              styles.settlementTableRow,
              styles.totalBreakdownFont,
              { gap: '10px', justifyContent: 'space-between' },
            ]}
          >
            <Text style={{ fontWeight: 'bold' }}>Eligible Pay:</Text>
            <Text style={styles.detailedPayColLong}>
              {currency(settlementTotal).format()}
            </Text>
          </View>
          <View
            style={[
              styles.settlementTableRow,
              styles.totalBreakdownFont,
              { gap: '10px', justifyContent: 'space-between' },
            ]}
          >
            <Text style={{ fontWeight: 'bold' }}>Deductions:</Text>
            <View style={{ flexDirection: 'column' }}>
              {settlementBill.settlementDeductions?.map(
                (settlementDeduction) => (
                  // eslint-disable-next-line react/jsx-key
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      gap: '10px',
                    }}
                  >
                    <Text style={{ maxWidth: '700px' }}>
                      {settlementDeduction.name}
                    </Text>
                    <Text>
                      -
                      {currency(
                        getSettlementEligibleTotal(
                          settlementDeduction.flatRate,
                          isNil(settlementDeduction.percentageDecimalRate)
                            ? undefined
                            : safeMultiply(
                                settlementDeduction.percentageDecimalRate,
                                100,
                              ),
                          settlementTotal,
                        ),
                      ).format()}
                    </Text>
                  </View>
                ),
              )}
            </View>
          </View>
          <View style={[styles.settlementTableRow, styles.totalFont]}>
            <View
              style={[
                styles.settlementTableRowLeftSectionDetailed,
                { justifyContent: 'flex-end' },
              ]}
            >
              <Text style={{ fontWeight: 'bold' }}>Total Pay: </Text>
            </View>
            <View style={styles.settlementTableRowRightSectionDetailed}>
              <Text style={styles.detailedPayColLong}>
                {currency(finalPay).format()}
              </Text>
            </View>
          </View>
        </>
      )}
    </View>
  );
};

const SettlementBillDetailed = ({
  settlementBill,
  bolString,
  displayStopTotal,
  displayOrderTotal,
  displayDriverPayout,
  showOtherDriversPaidForStop,
}: {
  readonly settlementBill: DriverSettlementBillForDownloadFragment;
  readonly bolString: string;
  readonly displayStopTotal: boolean;
  readonly displayOrderTotal: boolean;
  readonly displayDriverPayout: boolean;
  readonly showOtherDriversPaidForStop: boolean;
}) => {
  const sortedStopDriverMaps = orderBy(
    settlementBill.stopDriverMaps,
    'completedAt',
    'asc',
  ).sort((a, b) => {
    const aBOL = a.shipperBillOfLadingNumber;
    const bBOL = b.shipperBillOfLadingNumber;
    if (!isNil(aBOL) && !isNil(bBOL)) {
      return aBOL.localeCompare(bBOL);
    }
    return 0;
  });

  const stopTotalSum = settlementBill.stopDriverMaps.reduce(
    (prev, curr) =>
      prev + curr.driverSettlementTotalAmounts.totalChargesForSettlement,
    0,
  );
  const orderTotalSum = settlementBill.stopDriverMaps.reduce(
    (prev, curr) => prev + (curr.order?.totalCharge ?? 0),
    0,
  );
  const eligibleAmountTotal = settlementBill.stopDriverMaps.reduce(
    (prev, curr) =>
      prev + curr.driverSettlementTotalAmounts.totalEligibleAmountForStop,
    0,
  );
  const settlementTotal = settlementBill.stopDriverMaps.reduce(
    (prev, curr) => prev + curr.driverSettlementTotalAmounts.totalDriverPayout,
    0,
  );
  const totalDeductions = sumBy(
    settlementBill.settlementDeductions,
    (settlementDeduction) =>
      getSettlementEligibleTotal(
        settlementDeduction.flatRate,
        isNil(settlementDeduction.percentageDecimalRate)
          ? undefined
          : safeMultiply(settlementDeduction.percentageDecimalRate, 100),
        settlementTotal,
      ),
  );
  const finalPay = settlementTotal - totalDeductions;

  return (
    <View style={styles.settlementTable}>
      <View
        style={[
          styles.settlementTableRow,
          styles.settlementTableHeader,
          styles.detailedFontHeader,
        ]}
      >
        <View style={styles.settlementTableRowLeftSectionDetailed}>
          <Text style={styles.detailedRefInfoCol}>{bolString}</Text>
          <Text style={styles.detailedRefInfoCol}>Order Name</Text>
          <Text style={styles.detailedDateCol}>Completed At</Text>
          <Text style={styles.detailedCustomerCol}>Customer</Text>
          <Text style={styles.detailedAddressCol}>Address</Text>
          <Text style={styles.detailedStopTypeCol}>Stop Type</Text>
          <Text style={styles.detailedShipmentInfoCol}>Shipment Info</Text>
        </View>
        {displayOrderTotal && (
          <Text style={styles.detailedPayColLong}>Order Total</Text>
        )}
        {displayStopTotal && (
          <Text style={styles.detailedPayColLong}>Stop Total</Text>
        )}
        {displayStopTotal && (
          <Text style={styles.detailedPayColLong}>Eligible Amt</Text>
        )}
        {displayDriverPayout && (
          <Text style={styles.detailedPayColLong}>Pay</Text>
        )}
      </View>
      {sortedStopDriverMaps
        .filter(
          (stopDriverMap) =>
            !(
              (
                [StopType.Transfer, StopType.Recovery] as Array<
                  StopType | null | undefined
                >
              ).includes(stopDriverMap.stopType) &&
              stopDriverMap.driverSettlementTotalAmounts
                .totalChargesForSettlement === 0 &&
              stopDriverMap.driverSettlementTotalAmounts.totalDriverPayout === 0
            ),
        )
        .map((stopDriverMap) => {
          const { address } = stopDriverMap;
          const addressString = `${address?.name}\n${address?.line1}${
            isNil(address?.line2) ? '' : ` ${address?.line2}`
          }\n${address?.city}, ${address?.state} ${address?.zip}`;

          return (
            // eslint-disable-next-line react/jsx-key
            <View style={[styles.settlementTableRow, styles.detailedFont]}>
              <View style={styles.settlementTableRowLeftSectionDetailedColumn}>
                <View
                  style={[
                    styles.settlementTableRowLeftSectionDetailed,
                    styles.fullWidth,
                  ]}
                >
                  <WrappedText style={styles.detailedRefInfoCol}>
                    {stopDriverMap.shipperBillOfLadingNumber}
                  </WrappedText>
                  <WrappedText style={styles.detailedRefInfoCol}>
                    {stopDriverMap.orderName}
                  </WrappedText>
                  <Text style={styles.detailedDateCol}>
                    {isNil(stopDriverMap.completedAt)
                      ? ''
                      : dayjs(stopDriverMap.completedAt).format(
                          'MM/DD/YY hh:mm a',
                        )}
                    {stopDriverMap.isAttempt ? ' (Attempt)' : ''}
                  </Text>
                  <Text style={styles.detailedCustomerCol}>
                    {stopDriverMap.billingPartyContactName}
                  </Text>
                  <Text style={styles.detailedAddressCol}>{addressString}</Text>
                  <Text style={styles.detailedStopTypeCol}>
                    {sentenceCase(stopDriverMap?.stopType ?? 'Adjustment')}
                  </Text>
                  <Text style={[styles.detailedShipmentInfoCol]}>
                    <Text style={{ fontWeight: 'bold' }}>Service level: </Text>
                    {`${sentenceCase(stopDriverMap.serviceName ?? '-')}\n`}
                    <Text style={{ fontWeight: 'bold' }}>Time at stop: </Text>
                    {`${getTimeAtStop(stopDriverMap)}\n`}
                    <Text style={{ fontWeight: 'bold' }}>Weight: </Text>
                    {sumBy(stopDriverMap.packages, (pkg) => pkg.weight ?? 0)}
                    lb
                  </Text>
                </View>
                {showOtherDriversPaidForStop && (
                  <OtherDrivers stopDriverMap={stopDriverMap} />
                )}
              </View>
              {displayOrderTotal && (
                <Text style={styles.detailedPayColLong}>
                  {currency(stopDriverMap.order?.totalCharge ?? 0).format()}
                </Text>
              )}
              {displayStopTotal && (
                <Text style={styles.detailedPayColLong}>
                  {currency(
                    stopDriverMap.driverSettlementTotalAmounts
                      .totalChargesForSettlement ?? 0,
                  ).format()}
                </Text>
              )}
              {displayStopTotal && (
                <Text style={styles.detailedPayColLong}>
                  {currency(
                    stopDriverMap.driverSettlementTotalAmounts
                      .totalEligibleAmountForStop ?? 0,
                  ).format()}
                </Text>
              )}
              {displayDriverPayout && (
                <Text style={styles.detailedPayColLong}>
                  {currency(
                    stopDriverMap.driverSettlementTotalAmounts
                      .totalDriverPayout ?? 0,
                  ).format()}
                </Text>
              )}
            </View>
          );
        })}

      {(displayOrderTotal || displayStopTotal || displayDriverPayout) && (
        <>
          <View style={[styles.settlementTableRow, styles.totalBreakdownFont]}>
            <View
              style={[
                styles.settlementTableRowLeftSectionDetailed,
                { justifyContent: 'flex-end', width: '15%' },
              ]}
            >
              <Text style={{ fontWeight: 'bold' }}>
                {displayOrderTotal || displayStopTotal
                  ? 'Totals:'
                  : 'Eligible Pay:'}
              </Text>
            </View>
            {displayOrderTotal && (
              <Text style={styles.detailedPayColLong}>
                {currency(orderTotalSum).format()}
              </Text>
            )}
            {displayStopTotal && (
              <Text style={styles.detailedPayColLong}>
                {currency(stopTotalSum).format()}
              </Text>
            )}
            {displayStopTotal && (
              <Text style={styles.detailedPayColLong}>
                {currency(eligibleAmountTotal).format()}
              </Text>
            )}
            {displayDriverPayout && (
              <Text style={styles.detailedPayColLong}>
                {currency(settlementTotal).format()}
              </Text>
            )}
          </View>
          <View style={[styles.settlementTableRow, styles.totalBreakdownFont]}>
            <View
              style={[
                styles.settlementTableRowLeftSectionDetailed,
                styles.flexEnd,
                {
                  width: '15%',
                },
              ]}
            >
              <Text style={{ fontWeight: 'bold' }}>Deductions: </Text>
            </View>
            <Text style={styles.detailedPayColLong}>
              -{currency(totalDeductions).format()}
            </Text>
          </View>
          {displayDriverPayout && (
            <View style={[styles.settlementTableRow, styles.totalFont]}>
              <View
                style={[
                  styles.settlementTableRowLeftSectionDetailed,
                  { justifyContent: 'flex-end' },
                ]}
              >
                <Text style={{ fontWeight: 'bold' }}>Total Pay: </Text>
              </View>
              <Text style={styles.detailedPayColLong}>
                {currency(finalPay).format()}
              </Text>
            </View>
          )}
        </>
      )}
    </View>
  );
};

export type GeneratedDriverSettlementPdfProps = {
  readonly settlementBills: DriverSettlementBillForDownloadFragment[];
  readonly companyData: MeQuery | undefined;
  readonly downloadType: SettlementsDownloadType;
  readonly printFormat: string;
  readonly displayStopTotal: boolean;
  readonly displayOrderTotal: boolean;
  readonly displayDriverPayout: boolean;
  readonly showOtherDriversPaidForStop: boolean;
};

const GeneratedDriverSettlementPdf = ({
  settlementBills,
  companyData,
  downloadType,
  printFormat,
  displayStopTotal,
  displayOrderTotal,
  displayDriverPayout,
  showOtherDriversPaidForStop,
}: GeneratedDriverSettlementPdfProps) => {
  const address = companyData?.me?.company.defaultAddress;
  const segment = companyData?.me?.company.segment;

  const bolString = segment === Segment.Cartage ? 'HAWB' : 'PRO #';

  return (
    <Document title="Driver Settlement Bill">
      {orderBy(settlementBills, 'billStartDate', 'asc').map(
        (settlementBill) => {
          return (
            <Page
              key={settlementBill.billUuid}
              size="LETTER"
              style={styles.page}
              orientation={printFormat === 'Basic' ? 'portrait' : 'landscape'}
            >
              <Text
                fixed
                style={styles.pageNumbers}
                render={({ pageNumber, totalPages }) =>
                  `Page ${pageNumber} of ${totalPages}`
                }
              />
              {downloadType !== SettlementsDownloadType.ONE_FILE_NO_HEADERS && (
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '10px',
                    fontSize: '12px',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Text style={styles.header1}>
                    Driver Settlement Bill {settlementBill.billName}
                  </Text>
                  {!isNil(
                    companyData?.me?.company.configuration?.invoicePdfLogoUrl,
                  ) && (
                    <Image
                      style={{ maxWidth: '100px' }}
                      src={
                        companyData?.me?.company.configuration
                          ?.invoicePdfLogoUrl
                      }
                    />
                  )}
                </View>
              )}
              {downloadType !== SettlementsDownloadType.ONE_FILE_NO_HEADERS && (
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '10px',
                    fontSize: printFormat === 'Basic' ? '12px' : '10px',
                  }}
                >
                  <View>
                    <Text style={styles.header2}>
                      {companyData?.me?.company.name}
                    </Text>
                    {!isNil(address) && (
                      <Text>
                        {transformAddressToFullAddressString(address)}
                      </Text>
                    )}
                  </View>
                </View>
              )}
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '10px',
                  justifyContent: 'space-between',
                  fontSize: printFormat === 'Basic' ? '12px' : '10px',
                }}
              >
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text style={{ fontWeight: 'bold', width: 50 }}>
                      Driver
                    </Text>
                    <Text>{`${settlementBill.driverFirstName ?? ''} ${
                      settlementBill.driverLastName ?? ''
                    }`}</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text style={{ fontWeight: 'bold', width: 50 }}>
                      Station
                    </Text>
                    <Text>{settlementBill.driverStationCode}</Text>
                  </View>
                </View>
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: printFormat === 'Basic' ? '200px' : '170px',
                    }}
                  >
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Text style={{ fontWeight: 'bold' }}>
                        Settlement Date
                      </Text>
                      <Text>
                        {isNil(settlementBill.settlementDate)
                          ? '-'
                          : dayjs(settlementBill.settlementDate).format(
                              'MM/DD/YY',
                            )}
                      </Text>
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Text style={{ fontWeight: 'bold' }}>Pay Period</Text>
                      <Text>
                        {isNil(settlementBill.billStartDate)
                          ? ''
                          : dayjs(settlementBill.billStartDate).format(
                              'MM/DD/YY',
                            )}{' '}
                        -{' '}
                        {isNil(settlementBill.billEndDate)
                          ? ''
                          : dayjs(settlementBill.billEndDate).format(
                              'MM/DD/YY',
                            )}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              <View>
                {printFormat === 'Basic' ? (
                  <SettlementBillBasic
                    settlementBill={settlementBill}
                    bolString={bolString}
                    displayDriverPayout={displayDriverPayout}
                    showOtherDriversPaidForStop={showOtherDriversPaidForStop}
                  />
                ) : (
                  <SettlementBillDetailed
                    settlementBill={settlementBill}
                    bolString={bolString}
                    displayDriverPayout={displayDriverPayout}
                    displayStopTotal={displayStopTotal}
                    displayOrderTotal={displayOrderTotal}
                    showOtherDriversPaidForStop={showOtherDriversPaidForStop}
                  />
                )}
              </View>
            </Page>
          );
        },
      )}
    </Document>
  );
};

export default GeneratedDriverSettlementPdf;
