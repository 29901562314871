import {
  Alert,
  Button,
  Card,
  CircularProgress,
  FormHelperText,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  InputLabel,
  Select,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil, values } from 'lodash';
import React, {
  type FunctionComponent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, type SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';
import {
  ItemDocument,
  UnitOfMeasure,
  useItemQuery,
  useUpdateItemMutation,
} from '../../generated/graphql';
import { type BaseItemFormValues } from './types';
import { updateItemFormResolver } from './utils';

const useStyles = () => {
  return {
    editItemFormContainer: {
      padding: 4,
    },
    centeredRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    formCard: {
      padding: 4,
    },
  };
};

type EditItemFormProps = {
  readonly itemUuid: string;
};

const EditItemForm: FunctionComponent<EditItemFormProps> = ({ itemUuid }) => {
  const navigate = useNavigate();
  const styles = useStyles();

  const [successSnackbarVisible, setSuccessSnackbarVisible] = useState(false);
  const [errorSnackbarVisible, setErrorSnackbarVisible] = useState(false);

  const { loading: itemLoading, data: itemData } = useItemQuery({
    variables: { itemUuid },
    // Assume this has already been fetched by the previous screen, so don't
    // refetch again
    fetchPolicy: 'cache-first',
  });

  const [updateItem, { loading: updateItemLoading }] = useUpdateItemMutation({
    refetchQueries: [ItemDocument],
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<BaseItemFormValues>({
    resolver: updateItemFormResolver,
    defaultValues: useMemo(() => {
      const item = itemData?.item;
      return {
        sku: item?.sku,
        description: item?.description,
        primaryUnitOfMeasure: item?.primaryUOM?.unitOfMeasure,
        primaryUnitOfMeasureUpcCode: item?.primaryUOM?.upcCode,
        secondaryUnitOfMeasure: item?.secondaryUOM?.unitOfMeasure,
        secondaryUnitOfMeasureUpcCode: item?.secondaryUOM?.upcCode,
        primaryUnitsPerSecondaryUOM:
          item?.secondaryUOM?.primaryUnitsPerSecondaryUOM?.toString(),
        storageUnitConfigConsolidationUnitUOM:
          item?.itemStorageUnitConfig?.consolidationUnitUOM,
        length: item?.itemStorageUnitConfig?.length?.toString(),
        width: item?.itemStorageUnitConfig?.width?.toString(),
        height: item?.itemStorageUnitConfig?.height?.toString(),
        tie: item?.itemStorageUnitConfig?.tie?.toString(),
        high: item?.itemStorageUnitConfig?.high?.toString(),
        weight: item?.itemStorageUnitConfig?.weight?.toString(),
        primaryUnitsPerConsolidationUnit:
          item?.itemStorageUnitConfig?.primaryUnitsPerConsolidationUnit?.toString(),
        warehouse: {
          label: item?.warehouse.name,
          value: item?.warehouse.uuid,
        },
      };
    }, [itemData]),
  });

  useEffect(() => {
    const item = itemData?.item;
    if (!isNil(item)) {
      reset({
        sku: item?.sku,
        description: item?.description,
        primaryUnitOfMeasure: item?.primaryUOM?.unitOfMeasure,
        secondaryUnitOfMeasure: item?.secondaryUOM?.unitOfMeasure,
        warehouse: {
          label: item.warehouse.name,
          value: item.warehouse.uuid,
        },
      });
    }
  }, [itemData, reset]);

  const goBack = () => {
    const contact = itemData?.item.contact;
    if (isNil(contact)) {
      navigate('/warehouse/settings');
    } else {
      navigate(`/contacts?contactUuid=${contact.uuid}`);
    }
  };

  const onSubmit: SubmitHandler<BaseItemFormValues> = async (
    data: BaseItemFormValues,
  ) => {
    try {
      const updateItemResponse = await updateItem({
        variables: {
          input: {
            uuid: itemData?.item?.uuid ?? '',
            sku: data.sku,
            description: data.description,
            status: itemData?.item?.status,
            upsertInventoryPrimaryUnitOfMeasureInput: {
              uuid: itemData?.item?.primaryUOM.uuid ?? v4(),
              unitOfMeasure: data.primaryUnitOfMeasure,
            },
            upsertInventorySecondaryUnitOfMeasureInput: isNil(
              data.secondaryUnitOfMeasure,
            )
              ? undefined
              : {
                  uuid: itemData?.item?.secondaryUOM?.uuid ?? v4(),
                  unitOfMeasure: data.secondaryUnitOfMeasure,
                },
          },
        },
      });
      const updateItemUuid = updateItemResponse?.data?.updateItem?.uuid;
      if (isNil(updateItemUuid)) {
        setErrorSnackbarVisible(true);
      } else {
        setSuccessSnackbarVisible(true);
        goBack();
      }
    } catch {
      setErrorSnackbarVisible(true);
    }
  };

  if (itemLoading) {
    return <CircularProgress />;
  }

  return (
    <Grid container spacing={2} sx={styles.editItemFormContainer}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={successSnackbarVisible}
      >
        <Alert
          severity="success"
          onClose={() => {
            setSuccessSnackbarVisible(false);
          }}
        >
          Successfully saved item
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={errorSnackbarVisible}
      >
        <Alert
          severity="error"
          onClose={() => {
            setErrorSnackbarVisible(false);
          }}
        >
          Error saving item
        </Alert>
      </Snackbar>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="outlined"
          type="submit"
          disabled={updateItemLoading}
          sx={{ marginRight: 2 }}
          onClick={() => {
            goBack();
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          type="submit"
          disabled={updateItemLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Card sx={styles.formCard}>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={styles.centeredRow}>
              <Typography variant="h5">
                <strong>Item Details</strong>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="sku"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <InputLabel>SKU Name</InputLabel>
                    <TextField
                      required
                      size="small"
                      defaultValue={itemData?.item?.sku}
                      value={value}
                      error={!isNil(errors.sku)}
                      helperText={errors?.sku?.message}
                      sx={{ width: '100%' }}
                      onChange={onChange}
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={8}>
              <Controller
                name="description"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <InputLabel>Description</InputLabel>
                    <TextField
                      required
                      size="small"
                      value={value}
                      error={!isNil(errors.description)}
                      helperText={errors?.description?.message}
                      sx={{ width: '100%' }}
                      onChange={onChange}
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Controller
                name="primaryUnitOfMeasure"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <InputLabel>Primary Unit of Measure</InputLabel>
                    <Select
                      required
                      native
                      size="small"
                      value={value}
                      placeholder="Primary Unit of Measure"
                      error={!isNil(errors.primaryUnitOfMeasure)}
                      sx={{ width: '100%' }}
                      onChange={onChange}
                    >
                      {values(UnitOfMeasure).map((uom) => {
                        return (
                          <option key={uom} value={uom}>
                            {sentenceCase(uom)}
                          </option>
                        );
                      })}
                    </Select>
                    {!isNil(errors.primaryUnitOfMeasure) && (
                      <FormHelperText sx={{ color: '#D32F2F' }}>
                        {errors.primaryUnitOfMeasure.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Controller
                name="secondaryUnitOfMeasure"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <InputLabel id="age">Secondary Unit of Measure</InputLabel>
                    <Select
                      native
                      size="small"
                      value={value}
                      label="Secondary Unit of Measure"
                      error={!isNil(errors.secondaryUnitOfMeasure)}
                      sx={{ width: '100%' }}
                      onChange={onChange}
                    >
                      {values(UnitOfMeasure).map((uom) => {
                        return (
                          <option key={uom} value={uom}>
                            {sentenceCase(uom)}
                          </option>
                        );
                      })}
                    </Select>
                    {!isNil(errors.secondaryUnitOfMeasure) && (
                      <FormHelperText sx={{ color: '#D32F2F' }}>
                        {errors.secondaryUnitOfMeasure.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <strong>Warehouse:</strong> {itemData?.item.warehouse.name}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default EditItemForm;
