import {
  Alert,
  Button,
  FormHelperText,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller, type SubmitHandler } from 'react-hook-form';
import { getPermissionsFlags } from 'shared/roles';
import { percent } from 'shared/units/scalar';
import HelpTooltip from '../../../common/help-tooltip';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  ContactDocument,
  PermissionResource,
  useContactQuery,
  useUpdateCustomerContactMutation,
} from '../../../generated/graphql';
import useContactDiscountSettingsForm, {
  type ContactDiscountSettingsFormValues,
} from '../forms/use-contact-discount-settings-form';

export type ContactDiscountSettingsProps = {
  readonly contactUuid: string;
};
const ContactDiscountSettings = ({
  contactUuid,
}: ContactDiscountSettingsProps) => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteContacts } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Contacts,
  );

  const [successSnackbarVisible, setSuccessSnackbarVisible] = useState(false);
  const [errorSnackbarVisible, setErrorSnackbarVisible] = useState(false);

  const [updateContact, { loading: updateContactLoading }] =
    useUpdateCustomerContactMutation({
      refetchQueries: [ContactDocument],
    });
  const { data: contactData } = useContactQuery({
    variables: {
      uuid: contactUuid,
    },
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useContactDiscountSettingsForm();

  useEffect(() => {
    if (contactData?.contact?.__typename === 'CustomerContactEntity') {
      const pickupDiscountRate =
        contactData?.contact?.pickupDiscountRate
          ?.in(percent)
          .amount.toNumber() ?? null;
      const deliveryDiscountRate =
        contactData?.contact?.deliveryDiscountRate
          ?.in(percent)
          .amount.toNumber() ?? null;
      const transferDiscountRate =
        contactData?.contact?.transferDiscountRate
          ?.in(percent)
          .amount.toNumber() ?? null;
      const lineHaulDiscountRate =
        contactData?.contact?.lineHaulDiscountRate
          ?.in(percent)
          .amount.toNumber() ?? null;
      reset({
        pickupDiscountRate,
        deliveryDiscountRate,
        transferDiscountRate,
        lineHaulDiscountRate,
      });
    }
  }, [contactData, reset]);

  const onSubmit: SubmitHandler<ContactDiscountSettingsFormValues> = async (
    data,
  ) => {
    const {
      pickupDiscountRate,
      deliveryDiscountRate,
      transferDiscountRate,
      lineHaulDiscountRate,
    } = data;

    try {
      await updateContact({
        variables: {
          input: {
            customerContactUpdateInput: {
              uuid: contactUuid,
              pickupDiscountRate:
                !isNil(pickupDiscountRate) &&
                pickupDiscountRate.toString() !== ''
                  ? percent(pickupDiscountRate)
                  : null,
              deliveryDiscountRate:
                !isNil(deliveryDiscountRate) &&
                deliveryDiscountRate.toString() !== ''
                  ? percent(deliveryDiscountRate)
                  : null,
              transferDiscountRate:
                !isNil(transferDiscountRate) &&
                transferDiscountRate.toString() !== ''
                  ? percent(transferDiscountRate)
                  : null,
              lineHaulDiscountRate:
                !isNil(lineHaulDiscountRate) &&
                lineHaulDiscountRate.toString() !== ''
                  ? percent(lineHaulDiscountRate)
                  : null,
            },
          },
        },
      });
      setSuccessSnackbarVisible(true);
    } catch {
      setErrorSnackbarVisible(true);
    }
  };

  return (
    <Grid container spacing={2}>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={successSnackbarVisible}
        onClose={() => {
          setSuccessSnackbarVisible(false);
        }}
      >
        <Alert
          severity="success"
          onClose={() => {
            setSuccessSnackbarVisible(false);
          }}
        >
          Successfully saved discount rates
        </Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={errorSnackbarVisible}
        onClose={() => {
          setSuccessSnackbarVisible(false);
        }}
      >
        <Alert
          severity="error"
          onClose={() => {
            setErrorSnackbarVisible(false);
          }}
        >
          Error saving discount rates
        </Alert>
      </Snackbar>
      <Grid item xs={9}>
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Typography variant="h6">Discount Rates</Typography>
          <HelpTooltip title="Discount rates are configured on a per-contact level for each stop type. The dicount rate for a stop type will apply to that stop type's freight charge for all of the contact's orders" />
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <Typography sx={{ fontSize: '12px', color: 'gray', float: 'right' }}>
          <Button
            variant="contained"
            size="small"
            disabled={updateContactLoading}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Typography sx={{ fontWeight: 'bold' }}>
            Pickup Discount Rate
          </Typography>
          <HelpTooltip title="The discount rate that will be applied to the pickup stop's freight charge" />
        </Stack>
        <Controller
          name="pickupDiscountRate"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Stack>
              <TextField
                required
                name="name"
                size="small"
                type="number"
                value={value}
                error={!isNil(errors.pickupDiscountRate)}
                disabled={!canWriteContacts}
                onChange={onChange}
              />
              {!isNil(errors.pickupDiscountRate) && (
                <FormHelperText sx={{ color: '#D32F2F' }}>
                  {errors.pickupDiscountRate?.message ?? ''}
                </FormHelperText>
              )}
            </Stack>
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Typography sx={{ fontWeight: 'bold' }}>
            Delivery Discount Rate
          </Typography>
          <HelpTooltip title="The discount rate that will be applied to the delivery stop's freight charge" />
        </Stack>
        <Controller
          name="deliveryDiscountRate"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Stack>
              <TextField
                required
                name="name"
                size="small"
                type="number"
                value={value}
                error={!isNil(errors.deliveryDiscountRate)}
                disabled={!canWriteContacts}
                onChange={onChange}
              />
              {!isNil(errors.deliveryDiscountRate) && (
                <FormHelperText sx={{ color: '#D32F2F' }}>
                  {errors.deliveryDiscountRate?.message ?? ''}
                </FormHelperText>
              )}
            </Stack>
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Typography sx={{ fontWeight: 'bold' }}>
            Transfer Discount Rate
          </Typography>
          <HelpTooltip title="The discount rate that will be applied to the transfer stop's freight charge" />
        </Stack>
        <Controller
          name="transferDiscountRate"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Stack>
              <TextField
                required
                name="name"
                size="small"
                type="number"
                value={value}
                error={!isNil(errors.transferDiscountRate)}
                disabled={!canWriteContacts}
                onChange={onChange}
              />
              {!isNil(errors.transferDiscountRate) && (
                <FormHelperText sx={{ color: '#D32F2F' }}>
                  {errors.transferDiscountRate?.message ?? ''}
                </FormHelperText>
              )}
            </Stack>
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Typography sx={{ fontWeight: 'bold' }}>
            Line Haul Discount Rate
          </Typography>
          <HelpTooltip title="The discount rate that will be applied to the line haul freight charge" />
        </Stack>
        <Controller
          name="lineHaulDiscountRate"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Stack>
              <TextField
                required
                name="name"
                size="small"
                type="number"
                value={value}
                error={!isNil(errors.lineHaulDiscountRate)}
                disabled={!canWriteContacts}
                onChange={onChange}
              />
              {!isNil(errors.lineHaulDiscountRate) && (
                <FormHelperText sx={{ color: '#D32F2F' }}>
                  {errors.lineHaulDiscountRate?.message ?? ''}
                </FormHelperText>
              )}
            </Stack>
          )}
        />
      </Grid>
    </Grid>
  );
};

export default ContactDiscountSettings;
