// eslint-disable-next-line no-restricted-imports
import { Box, Grid, Modal, Typography, Tab, Tabs } from '@mui/material';
import React, {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useState,
} from 'react';
import TabPanel from '../../../../common/components/tab-panel/tab-panel';
import styles from '../../styles';
import ContactSettings from './contact-settings';

const SettingsModal = ({
  isOpen,
  setIsOpen,
}: {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    if (isSaving) {
      // the illusion of saving lol
      setTimeout(() => {
        setIsSaving(false);
      }, 1000);
    }
  }, [isSaving]);

  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <Box sx={styles.modal}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Tabs
              orientation="horizontal"
              variant="scrollable"
              value={tabIndex}
              aria-label="settings tabs"
              sx={{
                marginTop: '-15px',
              }}
              onChange={(e, newIndex) => {
                setTabIndex(newIndex);
              }}
            >
              <Tab label="Contacts" />
            </Tabs>
          </Grid>
          <Grid item xs={2}>
            <Typography
              sx={{ fontSize: '12px', color: 'gray', float: 'right' }}
            >
              {isSaving ? 'Saving...' : 'Saved'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TabPanel selectedValue={tabIndex} panelValue={0}>
              <ContactSettings setIsSaving={setIsSaving} />
            </TabPanel>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default SettingsModal;
