import {
  Accordion,
  AccordionDetails,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { type FormControlLabelProps } from '@mui/material/FormControlLabel/FormControlLabel';
import { Fragment, type FunctionComponent } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import {
  NotificationChannel,
  type OrderEventNotificationType,
  PermissionResource,
} from '../../../../generated/graphql';
import { AccordionSummary } from '../../../../pallet-ui/accordion/accordion-summary';
import theme from '../../../../theme';
import { GridDivider } from './grid-divider';
import type { OrderPartySubscriptionsForm } from './use-notifications-form';
import {
  allOrderEventTypesSelectedForChannel,
  orderEventNotificationTypes,
  orderEventTypeHumanReadable,
} from './util';

const eventTypeRow = (
  form: OrderPartySubscriptionsForm,
  eventType: OrderEventNotificationType,
  { disabled }: { disabled: boolean },
) => (
  <>
    <Typography fontSize="14px">
      {orderEventTypeHumanReadable(eventType)}
    </Typography>
    <Box>
      <FormControlLabel
        control={<Checkbox />}
        label="Text message"
        sx={{ marginRight: 0 }}
        checked={form.subscriptions.some(
          (sub) =>
            sub.eventType === eventType &&
            sub.channel === NotificationChannel.TextMessage,
        )}
        disabled={disabled}
        onChange={(_e, checked) => {
          if (checked) {
            form.addSubscription(eventType, NotificationChannel.TextMessage);
          } else {
            form.removeSubscription(eventType, NotificationChannel.TextMessage);
          }
        }}
      />
    </Box>
    <Box>
      <FormControlLabel
        control={<Checkbox />}
        label="Email"
        sx={{ marginRight: 0 }}
        checked={form.subscriptions.some(
          (sub) =>
            sub.eventType === eventType &&
            sub.channel === NotificationChannel.Email,
        )}
        disabled={disabled}
        onChange={(_e, checked) => {
          if (checked) {
            form.addSubscription(eventType, NotificationChannel.Email);
          } else {
            form.removeSubscription(eventType, NotificationChannel.Email);
          }
        }}
      />
    </Box>
  </>
);

const selectAllComponentsProps: FormControlLabelProps['componentsProps'] = {
  typography: {
    color: theme.palette.text.secondary,
  },
};

type OrderPartyNotificationsProps = {
  readonly form: OrderPartySubscriptionsForm;
  readonly heading: string;
  readonly subheading: string;
};

// The notifications sections for a party to an order: the order contact, the pickup contact, or the delivery contact.
export const OrderPartyNotifications: FunctionComponent<
  OrderPartyNotificationsProps
> = ({ form, heading, subheading }) => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteContacts } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Contacts,
  );

  return (
    <Accordion>
      <AccordionSummary heading={heading} subheading={subheading} />
      <AccordionDetails>
        <Box
          display="grid"
          gridTemplateColumns="1fr minmax(135px, max-content) minmax(85px, max-content)"
          alignItems="center"
          gap={1}
        >
          <Typography color="text.secondary" fontSize="14px">
            Notify the order contact when...
          </Typography>
          <Box>
            <FormControlLabel
              control={<Checkbox />}
              label="Select all"
              componentsProps={selectAllComponentsProps}
              sx={{ marginRight: 0 }}
              checked={allOrderEventTypesSelectedForChannel(
                form.subscriptions,
                NotificationChannel.TextMessage,
              )}
              disabled={!canWriteContacts}
              onChange={() => {
                form.toggleSelectAll(NotificationChannel.TextMessage);
              }}
            />
          </Box>
          <Box>
            <FormControlLabel
              control={<Checkbox />}
              label="Select all"
              componentsProps={selectAllComponentsProps}
              sx={{ marginRight: 0 }}
              checked={allOrderEventTypesSelectedForChannel(
                form.subscriptions,
                NotificationChannel.Email,
              )}
              disabled={!canWriteContacts}
              onChange={() => {
                form.toggleSelectAll(NotificationChannel.Email);
              }}
            />
          </Box>
          {orderEventNotificationTypes.map((eventType) => (
            <Fragment key={eventType}>
              <GridDivider span={3} />
              {eventTypeRow(form, eventType, { disabled: !canWriteContacts })}
            </Fragment>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
