import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { groupBy, isNil, sortBy } from 'lodash';
import React from 'react';
import CenteredCircularProgress from '../../../../../common/components/centered-circular-progress';
import { FeatureFlag } from '../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../common/react-hooks/use-feature-flag';
import { specialChargeGroupFormat } from '../../../../../common/utils/utils';
import {
  SpecialAccessorialType,
  useAccessorialDateRangeConfigByUuidQuery,
} from '../../../../../generated/graphql';
import RangeCell, { type RangeCellValues } from '../../common/range-cell';
import AccessorialMatrixRateCell from './accessorial-matrix-rate-cell';

type SpecialAccessorialRateMatrixEditorProps = {
  readonly accessorialDateRangeConfigUuid: string;
  readonly specialAccessorialType?: SpecialAccessorialType | null;
};

const SpecialAccessorialRateMatrixEditor = ({
  accessorialDateRangeConfigUuid,
  specialAccessorialType,
}: SpecialAccessorialRateMatrixEditorProps) => {
  const { data: accessorialDateRangeConfigData, loading } =
    useAccessorialDateRangeConfigByUuidQuery({
      variables: {
        uuid: accessorialDateRangeConfigUuid,
      },
    });
  const ffEnableMileageBasedSpecials = useFeatureFlag(
    FeatureFlag.FF_ENABLE_MILEAGE_BASED_SPECIALS,
  );

  const accessorialMatrixItemsByZone = sortBy(
    Object.values(
      groupBy(
        accessorialDateRangeConfigData?.accessorialDateRangeConfigByUuid
          .specialMatrixItems,
        'tariffZone.uuid',
      ),
    ),
    ffEnableMileageBasedSpecials &&
      specialAccessorialType === SpecialAccessorialType.MileageBased
      ? '0.tariffZone.mileRangeStart'
      : '0.tariffZone.name',
  );

  const mileRangeEnds: RangeCellValues = [
    ...accessorialMatrixItemsByZone
      .map(
        (matrixItems) => matrixItems[0]?.tariffZone.mileRangeEnd ?? undefined,
      )
      // Replace the last value (which should be MAX_INTEGER) with undefined
      .slice(0, -1),
    undefined,
  ];

  const chargeGroups =
    accessorialDateRangeConfigData?.accessorialDateRangeConfigByUuid.specialChargeGroups.sort(
      (a, b) =>
        specialChargeGroupFormat(a).localeCompare(specialChargeGroupFormat(b)),
    );

  return (
    <TableContainer>
      <Table aria-label="accessorials-matrix">
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>
                {ffEnableMileageBasedSpecials &&
                specialAccessorialType === SpecialAccessorialType.MileageBased
                  ? 'MILES'
                  : 'ZONE'}
              </strong>
            </TableCell>
            {chargeGroups?.map((chargeGroup) => {
              return (
                <TableCell key={chargeGroup.uuid}>
                  {specialChargeGroupFormat(chargeGroup)}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <Box p={2}>
              <CenteredCircularProgress />
            </Box>
          ) : (
            accessorialMatrixItemsByZone.map((matrixItems, i) => {
              return (
                <TableRow key={matrixItems[0]?.uuid}>
                  {ffEnableMileageBasedSpecials &&
                  specialAccessorialType ===
                    SpecialAccessorialType.MileageBased ? (
                    <TableCell>
                      <RangeCell
                        editable={false}
                        rangeValues={mileRangeEnds}
                        rangeIndex={i}
                        inputLabel="Miles"
                      />
                    </TableCell>
                  ) : (
                    <TableCell>{matrixItems[0]?.tariffZone.name}</TableCell>
                  )}
                  {chargeGroups?.map((chargeGroup) => {
                    const matrixItem = matrixItems.find(
                      (m) => m.chargeGroupUuid === chargeGroup.uuid,
                    );

                    if (!isNil(matrixItem)) {
                      return (
                        <TableCell key={matrixItem.uuid}>
                          <AccessorialMatrixRateCell
                            uuid={matrixItem.uuid}
                            accessorialDateRangeConfigUuid={
                              accessorialDateRangeConfigUuid
                            }
                            initialRate={matrixItem.rate}
                          />
                        </TableCell>
                      );
                    }
                    return null;
                  })}
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SpecialAccessorialRateMatrixEditor;
