import {
  Box,
  Button,
  Dialog,
  Stack,
  type SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import React, {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useState,
} from 'react';
import useTags from '../../common/react-hooks/use-tags';
import {
  TagsDocument,
  useCreateTagMutation,
  useUpdateTagMutation,
} from '../../generated/graphql';
import SettingsColorPicker from '../daily-control-center/components/settings-color-picker';

const styles = {
  modalInnerContainer: {
    bgcolor: 'background.paper',
    boxShadow: 24,
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    p: 2,
  } as SxProps,
};

type Tag = {
  value: string;
  color: string | null | undefined;
  uuid: string;
};

const AddTagModal = ({
  isOpen,
  setIsOpen,
  tag,
  isEditing,
}: {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly tag?: Tag;
  readonly isEditing?: boolean;
}) => {
  const { refetchTagsData } = useTags();
  const [tagName, setTagName] = useState('');
  const [selectedColor, setSelectedColor] = useState<string | null | undefined>(
    'black',
  );
  const [createTag] = useCreateTagMutation({
    refetchQueries: [TagsDocument],
  });
  const [updateTag] = useUpdateTagMutation({ refetchQueries: [TagsDocument] });

  useEffect(() => {
    if (isEditing === true && !isNil(tag)) {
      setTagName(tag.value);
      setSelectedColor(tag.color);
    }
  }, [isEditing, tag]);

  const handleSave = async () => {
    await (isEditing === true && !isNil(tag)
      ? updateTag({
          variables: {
            updateTagInput: {
              tagUpdateInput: {
                uuid: tag.uuid,
                color: selectedColor,
                value: tagName,
              },
            },
          },
        })
      : createTag({
          variables: {
            createTagInput: {
              tagCreateInput: { value: tagName, color: selectedColor },
            },
          },
        }));
    refetchTagsData();
    setIsOpen(false);
  };
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <Box sx={styles.modalInnerContainer}>
        <Typography variant="h6">
          {isEditing === true ? 'Edit Tag' : 'Add Tag'}
        </Typography>
        <TextField
          size="small"
          label="Name"
          value={tagName}
          onChange={(e) => {
            setTagName(e.target.value);
          }}
        />
        <Stack spacing={1}>
          <Typography variant="caption">Color</Typography>
          <SettingsColorPicker
            currentColor={selectedColor}
            setColor={setSelectedColor}
          />
        </Stack>
        <Stack direction="row" justifyContent="right">
          <Button variant="contained" onClick={handleSave}>
            Save
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default AddTagModal;
