import { Document } from '@react-pdf/renderer';
import { sum } from 'lodash';
import { useMemo } from 'react';
import { type PackageValues } from 'shared/types';
import { isNilOrEmptyString } from '../../../../common/utils/utils';
import {
  type PackageFragment,
  type WeightUnits,
} from '../../../../generated/graphql';
import { type StopType } from '../../../orders/components/order-form/forms/stop-type';
import {
  type AddressType,
  LabelType,
  getRoutingText,
  getShipperText,
} from '../../utils';
import GeneratedLotLabel from './generated-lot-label';

export type LotLabelsDocumentProps = {
  readonly companyName: string;
  readonly contact: string;
  readonly orderName: string;
  readonly mawb: string;
  readonly hawb: string;
  readonly shipperAddress?: AddressType;
  readonly recoveryAddress?: AddressType;
  readonly consigneeAddress?: AddressType;
  readonly pcdCarrier?: string;
  readonly pcpCarrier?: string;
  readonly outboundFlightAirline?: string;
  readonly packages: PackageValues[] | PackageFragment[];
  readonly inboundStopType?: StopType;
  readonly outboundStopType?: StopType;
  readonly defaultWeightUnits?: WeightUnits;
  readonly originTerminalCode?: string;
  readonly outboundFlightOriginCode?: string;
  readonly destinationTerminalCode?: string;
  readonly destinationDetails?: string;
  readonly widthDimension: number;
  readonly heightDimension: number;
  readonly rotate: boolean;
  readonly terminalsEnabled?: boolean;
  readonly totalLotLabels: number;
  readonly appendPieceIdToLotLabel: boolean;
  readonly inboundDate?: string;
  readonly outboundDate?: string;
  readonly refNumbers:
    | Array<{
        refNumber: string;
        label?: string;
      }>
    | undefined;
  readonly inboundIataCode?: string;
  readonly outboundIataCode?: string;
};
const GeneratedLotLabelsPdf = ({
  companyName,
  contact,
  orderName,
  mawb,
  hawb,
  shipperAddress,
  recoveryAddress,
  pcdCarrier,
  pcpCarrier,
  outboundFlightAirline,
  consigneeAddress,
  packages,
  inboundStopType,
  outboundStopType,
  defaultWeightUnits,
  originTerminalCode,
  outboundFlightOriginCode,
  destinationTerminalCode,
  destinationDetails,
  widthDimension,
  heightDimension,
  rotate,
  terminalsEnabled,
  totalLotLabels,
  appendPieceIdToLotLabel,
  inboundDate,
  outboundDate,
  refNumbers = [],
  inboundIataCode,
  outboundIataCode,
}: LotLabelsDocumentProps) => {
  const totalWeight = useMemo(() => {
    return sum(packages?.map((pkg) => pkg.weight ?? 0));
  }, [packages]);

  const shipperText = getShipperText(
    LabelType.LotLabel,
    inboundStopType,
    shipperAddress,
    recoveryAddress,
    pcdCarrier,
  );

  const outboundText = getRoutingText(
    outboundStopType,
    outboundFlightAirline,
    pcpCarrier,
  );

  const originText =
    terminalsEnabled === true
      ? originTerminalCode?.toUpperCase()
      : outboundFlightOriginCode?.toUpperCase();

  let destinationText = destinationTerminalCode?.toUpperCase();

  if (!isNilOrEmptyString(destinationDetails?.trim())) {
    destinationText = destinationDetails?.toUpperCase();
  } else if (!isNilOrEmptyString(outboundIataCode?.trim())) {
    destinationText = outboundIataCode?.toUpperCase();
  }
  const lotLabels = [];
  for (
    let packageNumber = 1;
    packageNumber < totalLotLabels + 1;
    packageNumber += 1
  ) {
    lotLabels.push(
      <GeneratedLotLabel
        companyName={companyName}
        contact={contact}
        orderName={orderName}
        mawb={mawb}
        hawb={hawb}
        widthDimension={widthDimension}
        heightDimension={heightDimension}
        rotate={rotate}
        shipperText={shipperText ?? undefined}
        outboundText={outboundText}
        packageNumber={packageNumber}
        totalWeight={totalWeight}
        originText={
          isNilOrEmptyString(inboundIataCode) ? originText : inboundIataCode
        }
        destinationText={destinationText}
        totalQuantity={totalLotLabels}
        consigneeAddress={consigneeAddress}
        defaultWeightUnits={defaultWeightUnits}
        appendPieceIdToLotLabel={appendPieceIdToLotLabel}
        refNumbers={refNumbers}
        warehouseLocation={packages[packageNumber - 1]?.warehouseLocation?.name}
        inboundDate={inboundDate}
        outboundDate={outboundDate}
      />,
    );
  }

  return <Document>{lotLabels}</Document>;
};

export default GeneratedLotLabelsPdf;
