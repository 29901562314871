import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Typography,
} from '@mui/material';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { isNil } from 'lodash';
import React, {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { type FieldValues, useFormContext } from 'react-hook-form';
import useMe from '../../../../../../../common/react-hooks/use-me';
import useServices from '../../../../../../../common/react-hooks/use-services';
import useTerminals from '../../../../../../../common/react-hooks/use-terminals';
import {
  OrderDocumentType,
  useLineHaulLaneLazyQuery,
  useShallowContactLazyQuery,
} from '../../../../../../../generated/graphql';
import GeneratedOrderAirWaybillPdf from '../../../../../../generated-documents/components/generated-order-air-waybill-pdf';
import {
  convertOrderFormValuesToAirWaybillData,
  type OrderAirWaybillData,
} from '../../../../../../generated-documents/utils';
import EmailOrderDocuments from './email-order-documents-component';

const PrintOrderAirWaybillModal = ({
  isOpen,
  setIsOpen,
}: {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { companyData } = useMe();
  const { getServiceName } = useServices();
  const { getTerminalCode, terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const [getLineHaulLane, { data: lineHaulLaneData }] =
    useLineHaulLaneLazyQuery();
  const [getContact, { data: contactData }] = useShallowContactLazyQuery();
  const { getValues } = useFormContext();
  const order: FieldValues = getValues();
  const [showCharges, setShowCharges] = useState(true);

  useEffect(() => {
    if (!isNil(order.contactUuid)) {
      getContact({
        variables: {
          uuid: order.contactUuid,
        },
      });
    }
    if (!isNil(order.lineHaulLaneUuid) && order.isUsingLineHaul === true) {
      getLineHaulLane({
        variables: {
          uuid: order.lineHaulLaneUuid,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.contactUuid, order.lineHaulLaneUuid, order.isUsingLineHaul]);

  const orderForAirWaybill: OrderAirWaybillData = useMemo(
    () =>
      convertOrderFormValuesToAirWaybillData({
        order,
        getTerminalCode,
        getServiceName,
        contact: contactData?.contact,
        lineHaulLane: lineHaulLaneData?.lineHaulLane,
        companyName: companyData?.name,
        companyAddress: companyData?.defaultAddress,
        companyPhone: companyData?.phone,
        companyInvoiceEmail: companyData?.invoiceEmail,
        terminalsEnabled,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order, contactData, companyData, terminalsEnabled],
  );

  // eslint-disable-next-line react/jsx-no-useless-fragment
  const [generatedPdf, setGeneratedPdf] = useState<JSX.Element>(<></>);
  useEffect(() => {
    if (isOpen) {
      setGeneratedPdf(
        <GeneratedOrderAirWaybillPdf
          order={orderForAirWaybill}
          showCharges={showCharges}
        />,
      );
    }
  }, [orderForAirWaybill, isOpen, showCharges]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <DialogTitle
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        Air Waybill
      </DialogTitle>
      <DialogContent sx={{ pt: '10px !important' }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <PDFViewer showToolbar style={{ width: '100%', height: '65vh' }}>
              {generatedPdf}
            </PDFViewer>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Checkbox
                checked={showCharges}
                onChange={(e) => {
                  setShowCharges(e.target.checked);
                }}
              />
              <Typography>Show charges</Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button variant="contained">
              <PDFDownloadLink
                document={generatedPdf}
                fileName={`order-air-waybill-${orderForAirWaybill.shipperBillOfLadingNumber}.pdf`}
              >
                {({ loading }) => (loading ? 'Loading...' : 'Download')}
              </PDFDownloadLink>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <EmailOrderDocuments
              isQuote
              documentType={OrderDocumentType.AirWaybill}
              generatedPdf={generatedPdf}
              isOpen={isOpen}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PrintOrderAirWaybillModal;
