import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import {
  FilterViewPage,
  type GetOrderTableFieldValuesInput,
  type OrderForBulkEditFragment,
  useGetOrderTableFieldValuesQuery,
  useOrdersByUuidsForBulkEditQuery,
} from '../../../../generated/graphql';
import { useNewTableFunctionsFeatureFlag } from '../../../ag-grid/use-new-table-functions-feature-flag';

type UseOrdersForBulkEditOutput = {
  orders: OrderForBulkEditFragment[];
  loading: boolean;
};

export const useOrdersForBulkEdit = ({
  isOpen,
  selectedOrderUuids,
  searchText,
  getOrderTableFieldValuesInput,
}: {
  isOpen: boolean;
  selectedOrderUuids: string[];
  searchText: string;
  getOrderTableFieldValuesInput: GetOrderTableFieldValuesInput | undefined;
}): UseOrdersForBulkEditOutput => {
  const { ffEnableNewTableFunctions } = useNewTableFunctionsFeatureFlag(
    FilterViewPage.Orders,
  );
  const filtersInput = ffEnableNewTableFunctions
    ? { filters: getOrderTableFieldValuesInput?.filters }
    : getOrderTableFieldValuesInput;
  const {
    data: orderTableFieldValuesData,
    loading: loadingOrderTableFieldValues,
  } = useGetOrderTableFieldValuesQuery(
    isOpen && isEmpty(selectedOrderUuids)
      ? {
          variables: {
            getOrderTableFieldValuesInput: {
              ...filtersInput,
              orderTableFields: [],
              noMaximum: true,
              totalCount: false,
              searchText,
            },
          },
        }
      : { skip: true },
  );

  const orderUuids: string[] = useMemo(
    () =>
      isEmpty(selectedOrderUuids)
        ? (orderTableFieldValuesData?.getOrderTableFieldValues.formattedOrderConnection.edges.map(
            (edge) => edge.node.uuid,
          ) ?? [])
        : selectedOrderUuids,
    [selectedOrderUuids, orderTableFieldValuesData],
  );

  const { data: ordersData, loading: loadingOrdersByUuids } =
    useOrdersByUuidsForBulkEditQuery({
      variables: { uuids: orderUuids },
      skip: !isOpen || isEmpty(orderUuids),
    });

  const orders = useMemo(() => ordersData?.ordersByUuids ?? [], [ordersData]);

  return {
    orders,
    loading: loadingOrderTableFieldValues || loadingOrdersByUuids,
  };
};
