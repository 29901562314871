import {
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  Stack,
  Button,
  type SxProps,
  Snackbar,
  Alert,
  Fade,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import {
  type FindBusinessDivisionsFragment,
  PermissionResource,
  useBusinessDivisionsQuery,
} from '../../../../generated/graphql';
import CreateBusinessDivisionModal from './create-business-division-modal';
import UpdateBusinessDivisionModal from './update-business-division-modal';

const styles = {
  stackView: {
    display: 'flex',
  } as SxProps,
  warehouseEmployeeButton: {
    mx: 1.5,
  } as SxProps,
};

const BusinessDivisionsTable = () => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteCompanyBuisnessDivisions } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyBusinessDivisions,
  );

  const { data: businessDivisionsData } = useBusinessDivisionsQuery();
  const [createBusinessDivisionModalOpen, setCreateBusinessDivisionModalOpen] =
    useState(false);
  const [updateBusinessDivisionModalOpen, setUpdateBusinessDivisionModalOpen] =
    useState(false);
  const [hoveredBusinessDivisionUuid, setHoveredBusinessDivisionUuid] =
    useState<string>();
  const [selectedBusinessDivision, setSelectedBusinessDivision] = useState<
    FindBusinessDivisionsFragment | undefined
  >();

  const [successSnackbarVisible, setSuccessSnackbarVisible] = useState(false);
  const [errorSnackbarVisible, setErrorSnackbarVisible] = useState(false);

  const sortedBusinessDivisions = useMemo(() => {
    return (
      businessDivisionsData?.businessDivisions?.businessDivisions ?? []
    ).sort((a, b) => a.name.localeCompare(b.name));
  }, [businessDivisionsData]);

  return (
    <Stack
      direction="column"
      alignItems="flex-end"
      gap={2}
      sx={styles.stackView}
    >
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={successSnackbarVisible}
        onClose={() => {
          setSuccessSnackbarVisible(false);
        }}
      >
        <Alert severity="success">Saved business division</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={errorSnackbarVisible}
        onClose={() => {
          setErrorSnackbarVisible(false);
        }}
      >
        <Alert severity="error">Error saving business division</Alert>
      </Snackbar>
      <CreateBusinessDivisionModal
        open={createBusinessDivisionModalOpen}
        setOpen={() => {
          setCreateBusinessDivisionModalOpen(false);
        }}
        setSuccessSnackbarVisible={setSuccessSnackbarVisible}
        setErrorSnackbarVisible={setErrorSnackbarVisible}
      />
      <UpdateBusinessDivisionModal
        open={updateBusinessDivisionModalOpen}
        setOpen={() => {
          setUpdateBusinessDivisionModalOpen(false);
        }}
        setSuccessSnackbarVisible={setSuccessSnackbarVisible}
        setErrorSnackbarVisible={setErrorSnackbarVisible}
        selectedBusinessDivision={selectedBusinessDivision}
      />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableCell>Name</TableCell>
            <TableCell align="right">
              <Button
                variant="contained"
                disabled={!canWriteCompanyBuisnessDivisions}
                onClick={() => {
                  setCreateBusinessDivisionModalOpen(true);
                }}
              >
                Add business division
              </Button>
            </TableCell>
          </TableHead>
          {sortedBusinessDivisions.map((businessDivision) => {
            return (
              <TableRow
                key={businessDivision.uuid}
                onMouseEnter={() => {
                  setHoveredBusinessDivisionUuid(businessDivision.uuid);
                }}
                onMouseLeave={() => {
                  setHoveredBusinessDivisionUuid(undefined);
                }}
              >
                <TableCell>{businessDivision.name}</TableCell>
                <TableCell align="right">
                  <Fade
                    in={hoveredBusinessDivisionUuid === businessDivision.uuid}
                  >
                    <Button
                      variant="contained"
                      disabled={!canWriteCompanyBuisnessDivisions}
                      onClick={() => {
                        setSelectedBusinessDivision(businessDivision);
                        setUpdateBusinessDivisionModalOpen(true);
                      }}
                    >
                      Edit
                    </Button>
                  </Fade>
                </TableCell>
              </TableRow>
            );
          })}
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default BusinessDivisionsTable;
