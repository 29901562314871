import { joiResolver } from '@hookform/resolvers/joi';
import joi from 'joi';
import { useForm } from 'react-hook-form';
import { type FindItemGroupsFragment } from '../../../../generated/graphql';

export type PicklistFormValues = {
  outboundReferenceNumber: string;
  date: Date;
  pickedItemGroups: FindItemGroupsFragment[];
  itemGroupsRemovedFromPicklist: Array<{ uuid: string; quantity: number }>;
};
const usePicklistForm = () => {
  const schema = joi.object<PicklistFormValues>({
    outboundReferenceNumber: joi
      .string()
      .required()
      .empty('')
      .messages({ 'any.required': 'Reference number is required' }),

    date: joi.date().required().messages({
      'date.base': 'A valid date is required',
    }),

    pickedItemGroups: joi
      .array()
      .items(
        joi
          .object({
            uuid: joi.string().required(),
            quantity: joi
              .number()
              .integer()
              .min(1)
              .required()
              .empty('')
              .messages({
                'any.required': 'Quantity is required',
                'number.base': 'Quantity must be a number',
                'number.integer': 'Quantity must be a whole number',
                'number.min': 'Quantity must be at least 1',
              }),
          })
          .unknown(true),
      )
      .required(),

    itemGroupsRemovedFromPicklist: joi
      .array()
      .items(
        joi.object({ uuid: joi.string(), quantity: joi.number().integer() }),
      )
      .default([]),
  });
  return useForm<PicklistFormValues>({
    resolver: joiResolver(schema),
    defaultValues: {
      pickedItemGroups: [],
      date: new Date(),
    },
    mode: 'all',
  });
};

export default usePicklistForm;
