import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { type InputBillingMethod } from 'shared/types';
import { calculateTotalVolume, calculateTotalWeight } from 'shared/weight';
import {
  type TariffFragment,
  type TariffFragmentFragment,
  useTariffLazyQuery,
} from '../../../generated/graphql';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  selectFreightChargeById,
  updateFreightCharge,
} from '../../freight-charges/redux/freight-charges-values-slice';
import {
  useDimFactorForQuoteOrOrder,
  usePackagesForQuoteOrOrder,
} from '../../invoice-profile/hooks/custom-charge-hooks';
import {
  calculateQuantityForOrder,
  calculateTariffRate,
} from '../components/utils';

/**
 * WARNING: This may no longer return the correct tariff. Considering using
 * `useTariffForFreightCharge()` instead.
 */
export const useTariff = ({
  tariffUuid,
  allTariffs,
}: {
  tariffUuid: string;
  allTariffs: Array<TariffFragment | TariffFragmentFragment>;
}) => {
  const [getTariffByUuid] = useTariffLazyQuery();
  const [foundTariff, setFoundTariff] = useState<
    TariffFragment | TariffFragmentFragment | null
  >(allTariffs.find((tariff) => tariff.uuid === tariffUuid) ?? null);

  const fetchTariff = async () => {
    const res = await getTariffByUuid({
      variables: {
        uuid: tariffUuid,
      },
    });
    setFoundTariff(res.data?.tariff ?? null);
  };

  useEffect(() => {
    fetchTariff();
  }, [allTariffs, tariffUuid]);

  return foundTariff;
};

export const useQuantityAndKeepInSync = ({
  shipmentUuid,
  freightBillingMethod,
  tariff,
  freightChargeId,
  isFromQuotePage,
  overridePackageWeight,
  miles,
}: {
  isConsolidatedOrder: boolean;
  shipmentUuid: string;
  freightBillingMethod: InputBillingMethod | null;
  tariff: TariffFragment | TariffFragmentFragment | undefined;
  freightChargeId: string;
  isFromQuotePage: boolean | undefined;
  overridePackageWeight: boolean;
  miles: number | undefined;
}) => {
  // const consolidatedOrder = useAppSelector((state: RootState) =>
  //   selectConsolidatedOrderValuesById(
  //     state,
  //     shipmentValues?.consolidatedOrderUuid ?? '',
  //   ),
  // );
  const freightChargeValues = useAppSelector((state) =>
    selectFreightChargeById(state, freightChargeId),
  );
  const dispatch = useAppDispatch();
  const [quantity, setQuantity] = useState(0);

  // const consolidatedOrderPackages = useAppSelector((state: RootState) =>
  //   selectPackagesByIds(state, consolidatedOrder?.packageUuids ?? []),
  // );
  const packages = usePackagesForQuoteOrOrder({
    shipmentUuids: [shipmentUuid],
    isFromQuotePage,
  });
  const dimFactor = useDimFactorForQuoteOrOrder({
    shipmentUuids: [shipmentUuid],
    isFromQuotePage,
  });
  const totalWeight = calculateTotalWeight(
    packages,
    dimFactor ?? undefined,
    tariff?.useActualWeightInCalculation ?? false,
  );
  const totalPieces = packages.reduce(
    (acc, pkg) => acc + (pkg?.quantity ?? 0),
    0,
  );
  const totalVolume = calculateTotalVolume({ packages });
  useEffect(() => {
    const newQuantity = calculateQuantityForOrder(
      // isConsolidatedOrder ? consolidatedOrderPackages : packages,
      packages,
      freightBillingMethod,
      tariff,
      freightChargeValues,
      dimFactor ?? undefined,
      overridePackageWeight,
    );
    if (!isNil(newQuantity)) {
      setQuantity(newQuantity);
    }
    dispatch(
      updateFreightCharge({
        id: freightChargeId,
        changes: { quantity: newQuantity },
      }),
    );
  }, [
    freightBillingMethod,
    tariff,
    totalWeight,
    dispatch,
    freightChargeId,
    totalPieces,
    freightChargeValues?.quantity,
    overridePackageWeight,
    miles,
    totalVolume,
  ]);

  return quantity;
};

type UseTariffRateProps = {
  tariff: TariffFragment | TariffFragmentFragment | null | undefined;
  quantity: number;
  freightChargeId: string;
  editingDisabled: boolean;

  miles?: number | null | undefined;
};

export const useTariffRate = ({
  tariff,
  quantity,
  freightChargeId,
  editingDisabled,
  miles,
}: UseTariffRateProps) => {
  const [tariffRate, setTariffRate] = useState(0);
  const dispatch = useAppDispatch();
  const updateTariff = async () => {
    if (tariff !== null && !editingDisabled) {
      const rate = calculateTariffRate({
        tariff,
        quantity,
        miles,
      });
      setTariffRate(rate);
      dispatch(
        updateFreightCharge({
          id: freightChargeId,
          changes: { rate },
        }),
      );
    }
  };

  useEffect(() => {
    updateTariff();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tariff, quantity, freightChargeId, dispatch]);

  return tariffRate;
};
