import { Box, Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { type Dispatch, type SetStateAction } from 'react';
import { useDeleteDocumentMutation } from '../../../generated/graphql';

const DeleteDocumentsConfirmationModal = ({
  open,
  setOpen,
  selectedDocumentUuids,
  setShouldRefresh,
  setSelectedDocumentUuids,
}: {
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
  readonly selectedDocumentUuids: string[];
  readonly setShouldRefresh: Dispatch<SetStateAction<boolean>>;
  readonly setSelectedDocumentUuids: Dispatch<SetStateAction<string[]>>;
}) => {
  const [deleteDocument] = useDeleteDocumentMutation();

  const deleteDocuments = async () => {
    await Promise.all(
      selectedDocumentUuids.map(async (documentUuid) =>
        deleteDocument({ variables: { uuid: documentUuid } }),
      ),
    );
    setShouldRefresh(true);
    setSelectedDocumentUuids([]);
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Box
        sx={{
          p: '20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <DialogTitle>
          Delete {selectedDocumentUuids.length} documents
        </DialogTitle>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={deleteDocuments}>
            Confirm
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default DeleteDocumentsConfirmationModal;
