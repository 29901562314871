import {
  createEntityAdapter,
  createSelector,
  createSlice,
  type EntityId,
  type EntityState,
} from '@reduxjs/toolkit';
import type { RootState } from '../../../redux/store';

type AccessorialRangeFields = {
  uuid: string;
  lessThanOrEqualToValue: number;
  rateUsdCents: number;
};

type AccessorialRangeStoreState = AccessorialRangeFields;

const accessorialRangesAdapter =
  createEntityAdapter<AccessorialRangeStoreState>({
    selectId: (accessorial) => accessorial.uuid,
  });

export const accessorialRangesSlice = createSlice({
  name: 'accessorialRanges',
  initialState: accessorialRangesAdapter.getInitialState(),
  reducers: {
    addOneAccessorialRange: accessorialRangesAdapter.addOne,
    updateAccessorialRange: accessorialRangesAdapter.updateOne,
    deleteAccessorialRange: accessorialRangesAdapter.removeOne,
    updateAccessorialRanges: accessorialRangesAdapter.updateMany,
    setAllAccessorialRanges: accessorialRangesAdapter.setAll,
    upsertManyAccessorialRanges: accessorialRangesAdapter.upsertMany,
  },
});

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectById: selectAccessorialRangeById,
  // Pass in a selector that returns the posts slice of state
} = accessorialRangesAdapter.getSelectors(
  (state: RootState) => state.accessorialRanges,
);

const accessorialRangesSelector = (state: RootState) => state.accessorialRanges;

export const selectAccessorialRangesByIds = createSelector(
  accessorialRangesSelector,
  (state: RootState, ids: EntityId[]) => ids,
  (state: EntityState<AccessorialRangeStoreState>, entityIds: EntityId[]) => {
    const entityResults: AccessorialRangeStoreState[] = [];
    for (const id of entityIds) {
      const match = accessorialRangesAdapter
        .getSelectors()
        .selectById(state, id);
      if (match) {
        entityResults.push(match);
      }
    }
    return entityResults;
  },
);

export const {
  addOneAccessorialRange,
  updateAccessorialRange,
  deleteAccessorialRange,
  setAllAccessorialRanges,
  upsertManyAccessorialRanges,
} = accessorialRangesSlice.actions;

export default accessorialRangesSlice.reducer;
