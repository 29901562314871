import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Box,
  Button,
  Divider,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  Modal,
  Snackbar,
  Typography,
} from '@mui/material';
import { isNil, sortBy } from 'lodash';
import React, { type Dispatch, type SetStateAction, useState } from 'react';
import CustomerFilterButton from '../../../../../common/components/customer-filter-button';
import DateDropdownPicker, {
  type DateOption,
  defaultPast1WeekDateRangeOption,
} from '../../../../../common/components/date-dropdown-picker';
import { type Option } from '../../../../../common/filters/types';
import useMe from '../../../../../common/react-hooks/use-me';
import {
  useOrderPodReportsLazyQuery,
  useShipmentsByContactUuidLazyQuery,
} from '../../../../../generated/graphql';
import { downloadCustomerChargesPodReport } from '../../../../orders/components/utils';
import useInvoicesStore from '../../../invoices-store';
import styles from '../../../styles';
import { accountingReportDefaultDateOption } from '../../accounting-reports/constants';

type DownloadCustomerChargesReportModalProps = {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const DownloadCustomerChargesReportModal = ({
  isOpen,
  setIsOpen,
}: DownloadCustomerChargesReportModalProps) => {
  const { segment } = useMe();
  const [getOrderPodReportData] = useOrderPodReportsLazyQuery();
  const [dateOption, setDateOption] = useState<DateOption>(
    defaultPast1WeekDateRangeOption,
  );
  const [customerOption, setCustomerOption] = useState<Option | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [getShipmentsByContactUuid] = useShipmentsByContactUuidLazyQuery();

  const createFileDownload = useInvoicesStore(
    (state) => state.createFileDownload,
  );

  const resetFilters = () => {
    setDateOption(accountingReportDefaultDateOption);
    setCustomerOption(undefined);
  };

  const startDownload = async () => {
    setIsOpen(false);
    const completeDownload = createFileDownload();
    const variables = {
      contactUuid: customerOption?.value,
      startCompletedDate: dateOption.startDate,
      endCompletedDate: dateOption.endDate,
    };
    const res = await getShipmentsByContactUuid({
      variables,
    });
    const shipments = res.data?.shipmentsByContactUuid;
    if (isNil(shipments)) {
      console.error('[Customer charges report] getShipments failed', {
        extra: variables,
      });
      setErrorMessage('An error occurred - please contact support');
      return;
    }
    if (shipments.length > 500) {
      console.error(
        `[Customer charges report] trying to fetch too many orders (${shipments.length})`,
        {
          extra: variables,
        },
      );
      setErrorMessage('Too many orders, please select a smaller date range');
      return;
    }
    const podData = await getOrderPodReportData({
      variables: {
        getShipmentsInput: {
          uuids: shipments.map((s) => s.uuid) ?? [],
        },
      },
    });
    if (isNil(podData.data)) {
      console.error('[Customer charges report] getOrderPodReportData failed');
      setErrorMessage('An error occurred - please contact support');
      return;
    }
    await downloadCustomerChargesPodReport(
      segment,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      sortBy(podData.data?.shipments, (shipment) => shipment.order?.name) ?? [],
      customerOption?.label ?? 'all-customers',
      dateOption.startDate,
      dateOption.endDate,
    );
    resetFilters();
    completeDownload();
  };

  return (
    <>
      <Snackbar
        autoHideDuration={3000}
        sx={{ marginTop: '60px' }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!isNil(errorMessage)}
      >
        <Alert
          severity="error"
          onClose={() => {
            setErrorMessage(undefined);
          }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <Box sx={[styles.modal, { height: '300px', width: '500px' }]}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={10}>
              <Typography sx={{ fontWeight: 'bold' }}>
                Download Customer Charges Report
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                sx={{ float: 'right' }}
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12} display="flex" flexDirection="row" gap={1}>
              <DateDropdownPicker
                filterTitle="Completed Date"
                dateOption={dateOption}
                setDateOption={setDateOption}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              flexDirection="row"
              gap={1}
              flexWrap="wrap"
            >
              <CustomerFilterButton
                selectedOption={customerOption}
                handleChange={(option: Option | undefined) => {
                  setCustomerOption(option);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ float: 'right' }}>
                <Button
                  sx={{ width: '100px' }}
                  variant="contained"
                  color="info"
                  onClick={startDownload}
                >
                  Download
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default DownloadCustomerChargesReportModal;
