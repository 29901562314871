import { Stack } from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React from 'react';
import 'react-json-view-lite/dist/index.css';
import { type SnapshotDifferenceFragment } from '../../../../../../../../../generated/graphql';
import OrderDifferenceBlock from './order-difference-block';
import StopDifferenceBlock from './stop-difference-block';

dayjs.extend(utc);
dayjs.extend(timezone);

type SnapshotDifferenceDetailsProps = {
  readonly snapshotDifference: SnapshotDifferenceFragment;
};
const SnapshotDifferenceDetails = ({
  snapshotDifference,
}: SnapshotDifferenceDetailsProps) => {
  return (
    <Stack direction="column" spacing={1} width="100%">
      <OrderDifferenceBlock
        orderDifferences={snapshotDifference.orderDifferences ?? null}
      />
      <StopDifferenceBlock
        isInbound
        stopDifferences={snapshotDifference.inboundStopDifferences ?? null}
      />
      <StopDifferenceBlock
        stopDifferences={snapshotDifference.outboundStopDifferences ?? null}
        isInbound={false}
      />
    </Stack>
  );
};

export default React.memo(SnapshotDifferenceDetails);
