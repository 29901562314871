import {
  MenuItem,
  Select,
  TableCell,
  TableRow,
  InputLabel,
  FormControl,
} from '@mui/material';
import currency from 'currency.js';
import { isNil } from 'lodash';
import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { safeMultiply, safeDivide } from 'shared/math';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../../../../../common/react-hooks/use-user-roles';
import { BillingMethod } from '../../../../../../../common/types';
import { PermissionResource } from '../../../../../../../generated/graphql';
import {
  type CustomChargeValues,
  type OrderFormValues,
} from '../../../forms/types';
import { type OrderShipmentContext } from '../../../types';
import { contextToNamePrefix } from '../../../utils';
import { DriverSettlementCustomChargeRateField } from './driver-settlement-custom-charge-rate-field';

const DriverSettlementCustomChargeRowImpl = ({
  customChargePathPrefix,
  customCharge,
}: {
  readonly customChargePathPrefix:
    | `stops.${number}.customCharges.${number}`
    | `orderChargesShipment.customCharges.${number}`;
  readonly customCharge: CustomChargeValues;
}) => {
  const { userPermissions } = useUserRoles();

  const { canWrite: canWriteOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Orders,
  );

  const { setValue } = useFormContext<OrderFormValues>();
  const billingMethod =
    customCharge.settlementBillingMethod ?? BillingMethod.Percentage;

  useEffect(() => {
    if (billingMethod === BillingMethod.FlatRate) {
      setValue(
        `${customChargePathPrefix}.deductionTotal`,
        customCharge.settlementFlatRate ?? 0,
      );
    }
    if (billingMethod === BillingMethod.Percentage) {
      setValue(
        `${customChargePathPrefix}.deductionTotal`,
        safeMultiply(
          safeDivide(customCharge.settlementPercentageRate ?? 0, 100, 5),
          customCharge.totalCharge ?? 0,
          5,
        ),
      );
    }
  }, [
    billingMethod,
    setValue,
    customChargePathPrefix,
    customCharge.settlementFlatRate,
    customCharge.settlementPercentageRate,
    customCharge.totalCharge,
  ]);

  return (
    <TableRow>
      <TableCell>
        <FormControl>
          <InputLabel>{customCharge.name}</InputLabel>
          <Select
            label={customCharge.name}
            value={billingMethod}
            size="small"
            disabled={!canWriteOrders}
            onChange={(e) => {
              if (e.target.value === BillingMethod.FlatRate) {
                setValue(
                  `${customChargePathPrefix}.settlementPercentageRate`,
                  null,
                );
                setValue(`${customChargePathPrefix}.settlementFlatRate`, 0);
                setValue(
                  `${customChargePathPrefix}.settlementBillingMethod`,
                  BillingMethod.FlatRate,
                );
              }
              if (e.target.value === BillingMethod.Percentage) {
                setValue(
                  `${customChargePathPrefix}.settlementPercentageRate`,
                  0,
                );
                setValue(`${customChargePathPrefix}.settlementFlatRate`, null);
                setValue(
                  `${customChargePathPrefix}.settlementBillingMethod`,
                  BillingMethod.Percentage,
                );
              }
            }}
          >
            <MenuItem value={BillingMethod.FlatRate}>Flat rate</MenuItem>
            <MenuItem value={BillingMethod.Percentage}>Percentage</MenuItem>
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>
        <DriverSettlementCustomChargeRateField
          customChargePathPrefix={customChargePathPrefix}
          disabled={!canWriteOrders}
        />
      </TableCell>
      <TableCell>
        {currency(customCharge.deductionTotal ?? 0).format()}
      </TableCell>
    </TableRow>
  );
};

const DriverSettlementCustomChargeRow = ({
  context,
  customChargeIdx,
}: {
  readonly context: OrderShipmentContext;
  readonly customChargeIdx: number;
}) => {
  const namePrefix = contextToNamePrefix(context);
  const customChargePathPrefix:
    | `stops.${number}.customCharges.${number}`
    | `orderChargesShipment.customCharges.${number}` =
    `${namePrefix}.customCharges.${customChargeIdx}`;
  const { control } = useFormContext<OrderFormValues>();
  const customCharge = useWatch({
    control,
    name: customChargePathPrefix,
  });

  if (isNil(customCharge)) {
    return null;
  }

  return (
    <DriverSettlementCustomChargeRowImpl
      customChargePathPrefix={customChargePathPrefix}
      customCharge={customCharge}
    />
  );
};

export default React.memo(DriverSettlementCustomChargeRow);
