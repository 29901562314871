import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import {
  IconButton,
  Stack,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useThirdPartyBrokers from '../../../../common/react-hooks/use-third-party-brokers';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import {
  PermissionResource,
  type ThirdPartyBrokerFragment,
  useUpdateThirdPartyBrokerMutation,
} from '../../../../generated/graphql';

type ThirdPartyBrokerRowProps = {
  readonly thirdPartyBroker: ThirdPartyBrokerFragment;
  readonly onDelete: () => void;
};

const ThirdPartyBrokerRow = ({
  thirdPartyBroker,
  onDelete,
}: ThirdPartyBrokerRowProps) => {
  const { refetchThirdPartyBrokers } = useThirdPartyBrokers();
  const { userPermissions } = useUserRoles();

  const { canWrite: canWriteThirdPartyBrokers } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyThirdPartyBrokers,
  );

  const [updateThirdPartyBroker] = useUpdateThirdPartyBrokerMutation();
  const [isHovering, setIsHovering] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [nameInput, setNameInput] = useState(thirdPartyBroker.name);

  const update = async () => {
    if (!isEmpty(nameInput)) {
      await updateThirdPartyBroker({
        variables: {
          thirdPartyBrokerUpdateInput: {
            uuid: thirdPartyBroker.uuid,
            name: nameInput,
          },
        },
      });
      refetchThirdPartyBrokers();
    }
  };

  return (
    <TableRow
      hover
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
      }}
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <TableCell>
        <Stack direction="row" alignItems="center" spacing={2}>
          {showInput ? (
            <TextField
              required
              size="small"
              label="Name"
              value={nameInput}
              disabled={!canWriteThirdPartyBrokers}
              onChange={(e) => {
                setNameInput(e.target.value);
              }}
              onBlur={() => {
                update();
                setShowInput(false);
              }}
            />
          ) : (
            <>
              <Typography>{nameInput}</Typography>
              {isHovering && (
                <IconButton
                  disabled={!canWriteThirdPartyBrokers}
                  onClick={() => {
                    setShowInput(true);
                  }}
                >
                  <EditIcon />
                </IconButton>
              )}
            </>
          )}
        </Stack>
      </TableCell>
      <TableCell align="right">
        <IconButton disabled={!canWriteThirdPartyBrokers} onClick={onDelete}>
          <CloseIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default ThirdPartyBrokerRow;
