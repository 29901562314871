import { createAsyncThunk } from '@reduxjs/toolkit';
import { v4 } from 'uuid';
import { type FuelBillingMethod } from '../../../generated/graphql';
import type { RootState } from '../../../redux/store';
import { addFuelCharge } from './fuel-charges-values-slice';

type InitNewFuelChargeArg = {
  defaultFuelSurcharge?: number | undefined;
  defaultFuelSurchargeBillingMethod?: FuelBillingMethod | undefined;
};

export const createInitialFuelCharge = createAsyncThunk<
  string,
  InitNewFuelChargeArg,
  { state: RootState }
>('fuelCharges/createInitialFuelCharges', async (arg, thunkAPI) => {
  const newFuelChargeUuid = v4();
  thunkAPI.dispatch(
    addFuelCharge({
      uuid: newFuelChargeUuid,
      surchargeRate: arg.defaultFuelSurcharge,
      type: arg.defaultFuelSurchargeBillingMethod,
      flatRate: 0,
    }),
  );

  return newFuelChargeUuid;
});
