import { type ReportBucketData } from '../../utils';

export const GENERATED_REVENUE_REPORT_COLS_OBJ: ReportBucketData = {
  pickupRevenue: undefined,
  deliveryRevenue: undefined,
  transferRevenue: undefined,
  lineHaulRevenue: undefined,
  // customChargeRevenue: undefined,
  surchargeRevenue: undefined,
  specialRevenue: undefined,
  fuelRevenue: undefined,
  totalRevenue: undefined,
};

export const GENERATED_REVENUE_REPORT_COLS = Object.keys(
  GENERATED_REVENUE_REPORT_COLS_OBJ,
);

export const TEXT_PADDING = '3px';
