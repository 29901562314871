import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import type React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';
import CSVDownloadButton from '../../../common/components/buttons/csv-download-button';
import ContactAutocompleteComponent from '../../../common/components/contact-autocomplete-component';
import {
  useGenerateStorageOrderPreSignedPutUrlMutation,
  useImportStorageOrdersFromCsvMutation,
} from '../../../generated/graphql';

const CSV_TEMPLATE_HEADERS = [
  'Reference number',
  'Purchase order number',
  'Carrier',
  'Tracking number',
  'Supplier company name',
  'Lot number',
  'Consignee',
  'SKU',
  'Description',
  'Item UoM',
  'Quantity',
  'Contanier UoM',
  'Container ID',
  'Location field 1',
  'Location field 2',
  'Location field 3',
  'Weight (lbs)',
  'On hand',
  'OSD reason',
];

const useStyles = () => ({
  modalTitle: {
    display: 'flex',
  },
  contactRow: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: 2,
  },
  createOrderButtonRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

type UploadStorageOrdersFromCsvModalProps = {
  readonly open: boolean;
  readonly onClose: () => void;
};
const UploadStorageOrdersFromCsvModal = ({
  open,
  onClose,
}: UploadStorageOrdersFromCsvModalProps) => {
  const navigate = useNavigate();
  const styles = useStyles();
  const [contactUuid, setContactUuid] = useState<string>();
  const [isUploading, setIsUploading] = useState(false);
  const [file, setFile] = useState<File>();
  const [errors, setErrors] = useState<string[]>();

  const [importStorageOrdersFromCSV] = useImportStorageOrdersFromCsvMutation();
  const [generateStorageOrderPreSignedPutUrl] =
    useGenerateStorageOrderPreSignedPutUrlMutation();

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsUploading(false);
    setFile(e.target.files?.[0]);
  };

  const handleUpload = async () => {
    if (isNil(file) || isNil(contactUuid)) {
      return;
    }
    const presignedUrlResponse = await generateStorageOrderPreSignedPutUrl({
      variables: {
        generateStorageOrderPreSignedPutUrlInput: {
          storageOrderUuid: `import/${v4()}`,
          fileName: file.name,
          fileType: file.type,
        },
      },
      fetchPolicy: 'network-only',
    });
    const presignedUrl =
      presignedUrlResponse.data?.generateStorageOrderPreSignedPutUrl
        .preSignedPutUrl;

    if (isNil(presignedUrl)) return;

    setErrors(undefined);

    const blob = new Blob([file], { type: file.type });
    const awsRes = await fetch(presignedUrl, {
      method: 'PUT',
      body: blob,
      headers: {
        'Content-Type': file.type,
      },
    });
    if (awsRes.status !== 200) {
      setErrors(['Failed to upload file']);
    }

    const res = await importStorageOrdersFromCSV({
      variables: {
        importStorageOrdersFromCsvInput: {
          fileUrl: presignedUrl.split('?')[0] ?? '',
          contactUuid,
        },
      },
    });
    const resErrors = res.data?.importStorageOrdersFromCSV.errors;
    if (!isNil(resErrors) && !isEmpty(resErrors)) {
      setErrors(resErrors);
      return;
    }
    const resStorageOrders = res.data?.importStorageOrdersFromCSV.storageOrders;
    if (isNil(resStorageOrders) || isEmpty(resStorageOrders)) {
      setErrors(['No receipts were created.']);
      return;
    }
    navigate(
      `/warehouse/storage-orders/${res.data?.importStorageOrdersFromCSV.storageOrders[0]?.uuid}`,
    );
  };

  const handleClose = () => {
    setErrors(undefined);
    setIsUploading(false);
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <DialogTitle sx={styles.modalTitle}>Upload packing list</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={styles.contactRow}>
            <FormControl fullWidth>
              <Typography>
                <strong>Select contact</strong>
              </Typography>
              <ContactAutocompleteComponent
                billingPartyContactUuid={contactUuid}
                onChange={(value: string | undefined) => {
                  setContactUuid(value);
                }}
              />
            </FormControl>
            {errors?.map((error) => (
              <Typography key={error} color="error">
                {error}
              </Typography>
            ))}
          </Grid>
          <Grid item xs={12} sx={styles.createOrderButtonRow}>
            {!isNil(file?.name) && (
              <Typography>Selected: {file?.name}</Typography>
            )}
          </Grid>
          <Grid item xs={12} sx={styles.createOrderButtonRow}>
            <CSVDownloadButton
              getData={async () => [CSV_TEMPLATE_HEADERS]}
              filename="upload-storage-orders-template.csv"
              label="Download CSV template"
              buttonProps={{ variant: 'outlined' }}
              reportType="upload-storage-orders-template"
              dataForTimingLog={{ isTemplate: true }}
            />
            <Stack direction="row" alignItems="center" gap={2}>
              <Button component="label" variant="contained">
                Select file
                <input
                  hidden
                  multiple
                  type="file"
                  accept=".csv,.xlsx"
                  onChange={handleSelectFile}
                />
              </Button>
              <Button
                variant="contained"
                disabled={isEmpty(contactUuid) || isNil(file) || isUploading}
                onClick={async () => {
                  setIsUploading(true);
                  await handleUpload();
                  setIsUploading(false);
                }}
              >
                Upload
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default UploadStorageOrdersFromCsvModal;
