import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import React from 'react';
import { useAllowOpenInNewTab } from '../../../../common/react-hooks/use-allow-open-in-new-tab';
import {
  type OrderForBulkEditFragment,
  Segment,
  type StopType,
  useMeQuery,
} from '../../../../generated/graphql';
import { getOrderDetailedStatusCopy } from '../utils';

type BulkActionsPreviewItemProps = {
  readonly affectedOrderCount: number | undefined;
  readonly affectedStopCount: number | undefined;
  readonly affectedStopTypes: StopType[] | undefined;
  readonly orders: OrderForBulkEditFragment[];
};

const BulkActionsPreviewTable = ({
  affectedOrderCount,
  affectedStopCount,
  affectedStopTypes,
  orders,
}: BulkActionsPreviewItemProps) => {
  const { allowOpenInNewTab } = useAllowOpenInNewTab();
  const { data: companyData } = useMeQuery({ fetchPolicy: 'cache-first' });
  const segment = companyData?.me?.company.segment;

  return (
    <TableContainer sx={{ height: '60vh', overflowY: 'scroll', marginTop: 2 }}>
      {isNil(affectedStopCount) ? (
        <Typography variant="h6">
          Affected Orders: {affectedOrderCount}
        </Typography>
      ) : (
        <Typography variant="h6">
          Affected Stops: {affectedStopCount}
        </Typography>
      )}
      <Table stickyHeader aria-label="bulk-actions-preview-table" size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              {segment === Segment.Cartage ? 'HAWB' : 'Client Reference Number'}
            </TableCell>
            {segment === Segment.Cartage && <TableCell>MAWB</TableCell>}
            <TableCell>
              {segment === Segment.Cartage
                ? 'Reference Number'
                : 'Secondary Reference Number'}
            </TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Warehouse</TableCell>
            <TableCell>Stop Types</TableCell>
            {!isNil(affectedStopTypes) && (
              <>
                <TableCell>Deadline Type</TableCell>
                <TableCell>Deadline Date</TableCell>
              </>
            )}
            <TableCell>Open in New Tab</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order) => {
            const affectedShipments = order.shipments.filter((shipment) => {
              const stopType = shipment.legs[0]?.endStop.stopType;
              if (
                isNil(affectedStopTypes) ||
                (!isNil(stopType) && affectedStopTypes.includes(stopType))
              ) {
                return true;
              }
              return false;
            });
            return (
              <TableRow key={order.uuid}>
                <TableCell>
                  {order.standardOrderFields.shipperBillOfLadingNumber}
                </TableCell>
                {segment === Segment.Cartage && (
                  <TableCell>
                    {order.standardOrderFields.masterAirwayBillOfLadingNumber}
                  </TableCell>
                )}
                <TableCell sx={{ maxWidth: '250px' }}>
                  {order.secondaryRefNumber}
                </TableCell>
                <TableCell>
                  {getOrderDetailedStatusCopy({
                    detailedStatus: order?.detailedStatusV2,
                  })}
                </TableCell>
                <TableCell>{order.warehouse?.name}</TableCell>
                <TableCell>
                  {affectedShipments
                    .map((shipment) => shipment.legs[0]?.endStop.stopType)
                    .join(', ')}
                </TableCell>
                {!isNil(affectedStopTypes) && (
                  <>
                    <TableCell>
                      {affectedShipments
                        .map((shipment) =>
                          shipment.standardShipmentFields?.deadlineType?.replace(
                            '_',
                            ' ',
                          ),
                        )
                        .join(', ')}
                    </TableCell>
                    <TableCell>
                      {affectedShipments
                        .map((shipment) =>
                          dayjs(
                            shipment.standardShipmentFields?.deadlineDate,
                          ).format('MM/DD hh:mm a'),
                        )
                        .join(', ')}
                    </TableCell>
                  </>
                )}
                <TableCell>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={(e) => {
                      allowOpenInNewTab(e, `/orders/?orderUuid=${order.uuid}`);
                    }}
                  >
                    Open
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BulkActionsPreviewTable;
