import NotificationsNoneSharpIcon from '@mui/icons-material/NotificationsNoneSharp';
import NotificationsSharpIcon from '@mui/icons-material/NotificationsSharp';
import { Badge, Button, Popover, Stack, Typography } from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import useNotifications from '../../../common/react-hooks/use-notifications';
import NotificationRow from '../../../domains/notifications/components/notification-row';
import OrdersAwaitingApprovalNotification from '../../../domains/notifications/components/orders-awaiting-approval-notification';
import { NotificationType } from '../../../generated/graphql';
import theme from '../../../theme';

const NavbarNotificationItem = () => {
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [notificationsAnchorEl, setNotificationsAnchorEl] =
    useState<HTMLElement | null>(null);
  const iconRef = useRef<HTMLButtonElement | null>(null);
  const { notifications, ordersAwaitingApprovalNotifications } =
    useNotifications();
  useEffect(() => {
    if (!isNil(iconRef)) {
      setNotificationsAnchorEl(iconRef.current);
    }
  }, []);
  // Ensure that the popover closes when there are no more notifications to ack for the day.
  useEffect(() => {
    if (isEmpty(notifications)) {
      setNotificationsOpen(false);
    }
  }, [notifications]);

  return (
    <>
      <Stack>
        <Button
          sx={{
            justifyContent: 'flex-start',
            width: '100%',
            paddingLeft: '8px',
            paddingRight: '8px',
          }}
          onClick={() => {
            setNotificationsOpen(true);
          }}
        >
          <Badge
            badgeContent={notifications?.length ?? 0}
            showZero={false}
            sx={{
              '& .MuiBadge-badge': {
                color: '#FFFFFF',
                background: 'transparent',
              },
            }}
          >
            {isEmpty(notifications) ? (
              <NotificationsNoneSharpIcon
                sx={{
                  color: theme.palette.concreteGrey[50],
                  width: 16,
                  height: 16,
                }}
              />
            ) : (
              <NotificationsSharpIcon
                sx={{
                  color: theme.palette.concreteGrey[50],
                  width: 16,
                  height: 16,
                }}
              />
            )}
          </Badge>
          <Typography
            ref={iconRef}
            variant="caption"
            color={theme.palette.concreteGrey[10]}
            sx={{ marginLeft: '8px' }}
            fontSize="14px"
          >
            Notifications
          </Typography>
        </Button>
      </Stack>
      <Popover
        id="notifications"
        open={notificationsOpen}
        anchorEl={notificationsAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            padding: '8px',
          },
        }}
        onClose={() => {
          setNotificationsOpen(false);
        }}
      >
        {!isEmpty(ordersAwaitingApprovalNotifications) && (
          <OrdersAwaitingApprovalNotification
            notifications={ordersAwaitingApprovalNotifications ?? []}
          />
        )}
        {!isEmpty(notifications) &&
          notifications
            ?.filter(
              (notification) =>
                notification.type !== NotificationType.OrderAwaitingApproval &&
                notification.type !== NotificationType.EmailingInvoice,
            )
            .map((notification) => (
              <NotificationRow
                key={notification.uuid}
                notification={notification}
              />
            ))}
        {isEmpty(notifications) && (
          <Typography color="text.secondary" sx={{ fontSize: '14px' }}>
            No notifications
          </Typography>
        )}
      </Popover>
    </>
  );
};
export default React.memo(NavbarNotificationItem);
