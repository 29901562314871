import { Divider, Menu, MenuItem } from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { type Dispatch, type SetStateAction } from 'react';
import type React from 'react';
import { useContext, useState } from 'react';
import { MarkStopAsCompletedDialog } from '../../../common/components/modals/mark-stop-as-completed-dialog';
import { FeatureFlag } from '../../../common/feature-flags';
import { useAllowOpenInNewTab } from '../../../common/react-hooks/use-allow-open-in-new-tab';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import useTerminals from '../../../common/react-hooks/use-terminals';
import { isPartnerCarrierStop } from '../../../common/utils/stops';
import { isNilOrEmptyString } from '../../../common/utils/utils';
import {
  StopStatus,
  StopType,
  useCompleteStopV2Mutation,
  useOrderWithPaperworkQuery,
  useStopForCompletionCheckLazyQuery,
} from '../../../generated/graphql';
import { useAppSelector } from '../../../redux/hooks';
import { selectRouteValuesById } from '../../routes/redux/routes-values-slice';
import { selectStopValuesById } from '../../stops/redux/stop-values-slice';
import EndOfDayContext from '../end-of-day-context';

type StopRowContextMenuProps = {
  readonly unassignStop?: () => void;
  readonly anchorEl: HTMLButtonElement | null;
  readonly setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
  readonly status: StopStatus | undefined;
  readonly orderUuid: string;
  readonly stopUuid: string;
  readonly isLocalSlot: boolean;
  readonly setShowMarkStopAsFailedDialog: Dispatch<SetStateAction<boolean>>;
  readonly updatePaperworkCompleted: () => void;
  readonly setCannotCompleteOrderModalOpen: Dispatch<SetStateAction<boolean>>;
  readonly setCannotCompleteOrderModalMessage: Dispatch<
    SetStateAction<string | undefined>
  >;
};

const StopRowContextMenu = ({
  unassignStop,
  anchorEl,
  setAnchorEl,
  status,
  orderUuid,
  stopUuid,
  isLocalSlot,
  setShowMarkStopAsFailedDialog,
  updatePaperworkCompleted,
  setCannotCompleteOrderModalOpen,
  setCannotCompleteOrderModalMessage,
}: StopRowContextMenuProps) => {
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const ffRequireTransferAddress = useFeatureFlag(
    FeatureFlag.FF_REQUIRE_TRANSFER_ADDRESS_ON_COMPLETION,
  );
  const { allowOpenInNewTab } = useAllowOpenInNewTab();
  const { setShouldLoadRoutes } = useContext(EndOfDayContext);
  const [showMarkStopAsCompletedDialog, setShowMarkStopAsCompletedDialog] =
    useState(false);
  const stop = useAppSelector((state) =>
    selectStopValuesById(state, stopUuid ?? ''),
  );
  const route = useAppSelector((state) =>
    selectRouteValuesById(state, stop?.routeUuid ?? ''),
  );
  const [completeStop] = useCompleteStopV2Mutation({
    variables: {
      completeStopInput: {
        stopUuid,
        sentFromDriverMobileApplication: false,
      },
    },
  });
  const [getStopForCompletionCheck] = useStopForCompletionCheckLazyQuery();

  const { data: paperworkCompleteData, loading: paperworkCompleteLoading } =
    useOrderWithPaperworkQuery({
      variables: { uuid: orderUuid },
    });
  const paperworkComplete =
    paperworkCompleteData?.order?.paperwork.paperworkComplete ?? false;

  const handleUnassignStop = async (e: React.MouseEvent) => {
    if (!isNil(unassignStop)) {
      e.stopPropagation();
      await unassignStop();
      setAnchorEl(null);
      setShouldLoadRoutes(true);
    }
  };

  const viewShipmentInfo = (e: React.MouseEvent) => {
    allowOpenInNewTab(e, `/orders/?orderUuid=${orderUuid}`);
    setAnchorEl(null);
  };

  return (
    <>
      {!isNil(stop) && (
        <MarkStopAsCompletedDialog
          handleClose={() => {
            setShowMarkStopAsCompletedDialog(false);
            setShouldLoadRoutes(true);
            setAnchorEl(null);
          }}
          open={showMarkStopAsCompletedDialog}
          stopUuids={[stop?.uuid]}
          routeDate={route?.date ?? undefined}
          allStopsAreDeliveries={stop.stopType === StopType.Delivery}
          allStopsArePartnerStops={isPartnerCarrierStop({ stop: { ...stop } })}
          onSave={null} // we are not in form context
        />
      )}
      <Menu
        id="basic-menu"
        open={!isNil(anchorEl)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <MenuItem
          onClick={() => {
            setShowMarkStopAsFailedDialog(true);
          }}
        >
          Mark as attempted
        </MenuItem>
        {!isLocalSlot &&
          status !== StopStatus.Cancelled &&
          status !== StopStatus.Failed &&
          status !== StopStatus.Completed && (
            <MenuItem
              onClick={async () => {
                const stopRes = await getStopForCompletionCheck({
                  variables: {
                    uuid: stopUuid,
                  },
                });
                const stopInfo = stopRes?.data?.stop;
                if (stopInfo?.leg.endStop.stopType === StopType.Transfer) {
                  if (
                    ffRequireTransferAddress &&
                    isEmpty(stopInfo?.address?.line1)
                  ) {
                    setCannotCompleteOrderModalMessage(
                      'The transfer stop for the order needs to have an address',
                    );
                    setCannotCompleteOrderModalOpen(true);
                    return;
                  }
                  if (terminalsEnabled && isNil(stopInfo?.terminal?.uuid)) {
                    setCannotCompleteOrderModalMessage(
                      'The transfer stop for the order needs to have a destination',
                    );
                    setCannotCompleteOrderModalOpen(true);
                    return;
                  }
                }
                if (
                  isNilOrEmptyString(stop?.proofOfDeliverySignee) ||
                  isNil(stop?.completedAt)
                ) {
                  setShowMarkStopAsCompletedDialog(true);
                } else {
                  await completeStop({
                    variables: {
                      completeStopInput: {
                        stopUuid,
                        sentFromDriverMobileApplication: false,
                      },
                    },
                  });
                  setShouldLoadRoutes(true);
                  setAnchorEl(null);
                }
              }}
            >
              Mark as complete
            </MenuItem>
          )}
        <Divider />
        <MenuItem onClick={viewShipmentInfo}>View shipment info</MenuItem>
        <MenuItem
          disabled={paperworkCompleteLoading}
          onClick={updatePaperworkCompleted}
        >
          Mark paperwork as {paperworkComplete ? 'incomplete' : 'complete'}
        </MenuItem>
        {!isNil(unassignStop) && (
          <>
            <Divider />
            <MenuItem onClick={handleUnassignStop}>Unassign stop</MenuItem>
          </>
        )}
      </Menu>
    </>
  );
};

export default StopRowContextMenu;
