import { Warning } from '@mui/icons-material';
import { Icon, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import {
  type FunctionComponent,
  type PropsWithChildren,
  type ReactElement,
} from 'react';
import styled from 'styled-components';

const Boundary = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  gap: 8px;
`;

const fallback: ReactElement = (
  <Boundary>
    <Icon color="error" fontSize="large">
      <Warning fontSize="large" />
    </Icon>
    <Typography>
      Something went wrong. Our engineers have been notified. Please refresh and
      try again.
    </Typography>
  </Boundary>
);

export const ErrorBoundary: FunctionComponent<PropsWithChildren> = ({
  children,
}) => (
  <Sentry.ErrorBoundary showDialog fallback={fallback}>
    {children}
  </Sentry.ErrorBoundary>
);
