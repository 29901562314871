import { useCallback } from 'react';
import apolloClient from '../../../apollo-client';
import {
  StopsDocument,
  type StopsQuery,
  type StopsQueryVariables,
} from '../../../generated/graphql';

const useFetchStops = () => {
  const fetchStops = useCallback(
    async ({
      variables,
      first,
      after,
      cacheStartIndex,
      useCache = true,
      includeRecurringRunHeaders = false,
    }: {
      variables?: StopsQueryVariables;
      first?: number;
      after?: string | null | undefined;
      cacheStartIndex?: number | null | undefined;
      useCache?: boolean;
      includeRecurringRunHeaders?: boolean;
    }) => {
      const res = await apolloClient.query<StopsQuery, StopsQueryVariables>({
        query: StopsDocument,
        variables: {
          ...variables,
          hideOnHold: true,
          hideStopsWithoutAddress: true,
          first,
          after,
          cacheStartIndex,
          useCache,
          includeRecurringRunHeaders,
        },
      });
      return res.data;
    },
    [],
  );

  return {
    fetchStops,
  };
};

export default useFetchStops;
