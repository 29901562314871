import { Check, ExpandMore } from '@mui/icons-material';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import React, {
  type KeyboardEventHandler,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDebounce } from 'use-debounce';
import AutocompleteFuzzy from '../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';

import { type Option } from '../filters/types';
import useContacts from '../react-hooks/use-contacts';
import useStyles from './general-styles';
import MultiselectFilterButton, {
  getMultiselectFilterLabel,
  multiselectCacheKey,
} from './multiselect-filter-button';

const CACHE_PREFIX = 'CUSTOMER_FILTER';

export type PrefixOption = {
  prefix: string;
};

export const isPrefixOption = (
  option: Option | Option[] | PrefixOption | undefined | null,
): option is PrefixOption => !isNil(option) && 'prefix' in option;

// Single-select
export const isSingleCustomerOption = (
  option: Option | PrefixOption | undefined | null,
): option is Option => !isNil(option) && 'label' in option;

type CustomerOption<EnablePrefixSearch> = EnablePrefixSearch extends true
  ? Option | PrefixOption | undefined
  : Option | undefined;

// Multi-select
export const isMultiselectCustomerOption = (
  options: Option[] | PrefixOption | undefined | null,
): options is Option[] => !isNil(options) && Array.isArray(options);

type CustomerOptions<EnablePrefixSearch> = EnablePrefixSearch extends true
  ? Option[] | PrefixOption | undefined
  : Option[] | undefined;

type CustomerFilterButtonProps<EnablePrefixSearch extends boolean> = {
  // Single select
  readonly selectedOption?: CustomerOption<EnablePrefixSearch>;
  readonly handleChange?: (option: CustomerOption<EnablePrefixSearch>) => void;
  // Multi-select
  readonly selectedOptionsMultiselect?: CustomerOptions<EnablePrefixSearch>;
  readonly handleChangeMultiselect?: (
    options: CustomerOptions<EnablePrefixSearch> | null | undefined,
  ) => void;

  readonly cacheId?: string;
  readonly enablePrefixSearch?: EnablePrefixSearch;
  readonly isSmall?: boolean;
  readonly disableAllOption?: boolean;
  readonly prefixText?: string;
  // pass to specify contacts to use
  readonly contactsOverride?: Array<{ uuid: string; displayName: string }>;
  readonly disableButton?: boolean;
};

const stopPropagation: KeyboardEventHandler = (e) => {
  e.stopPropagation();
};

const CustomerFilterButton = <EnablePrefixSearch extends boolean = false>({
  cacheId,
  enablePrefixSearch,
  selectedOption,
  handleChange,
  selectedOptionsMultiselect,
  handleChangeMultiselect,
  isSmall = false,
  disableAllOption = false,
  prefixText = 'Customer',
  contactsOverride,
  disableButton = false,
}: CustomerFilterButtonProps<EnablePrefixSearch>) => {
  const consolidatedOptionForPrefix =
    selectedOption ?? selectedOptionsMultiselect;
  const isPrefixSelected = isPrefixOption(consolidatedOptionForPrefix);
  const [prefixInput, setPrefixInput] = useState<string>(
    isPrefixOption(consolidatedOptionForPrefix)
      ? consolidatedOptionForPrefix.prefix
      : '',
  );
  const [debouncedPrefixInput] = useDebounce(prefixInput, 500);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const styles = useStyles();
  const { contacts: contactsData } = useContacts();
  const contacts = contactsOverride ?? contactsData;

  useEffect(() => {
    if (!isNil(cacheId) && !isNil(handleChange)) {
      const cachedLabel = localStorage.getItem(
        `${CACHE_PREFIX}_LABEL_${cacheId}`,
      );
      const cachedValue = localStorage.getItem(
        `${CACHE_PREFIX}_VALUE_${cacheId}`,
      );
      if (!isNil(cachedLabel) && !isNil(cachedValue)) {
        handleChange({
          label: cachedLabel,
          value: cachedValue,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterLabel = isPrefixSelected
    ? `${consolidatedOptionForPrefix.prefix}*`
    : getMultiselectFilterLabel(
        // eslint-disable-next-line no-nested-ternary
        !isNil(selectedOptionsMultiselect) &&
          isMultiselectCustomerOption(selectedOptionsMultiselect)
          ? selectedOptionsMultiselect
          : !isNil(selectedOption) && isSingleCustomerOption(selectedOption)
            ? [selectedOption]
            : null,
        false,
      );

  useEffect(() => {
    if (disableAllOption && contacts.length > 0) {
      // set the first contact as the default if we disable the all option
      const defaultOption: Option = {
        label: contacts[0]?.displayName ?? '',
        value: contacts[0]?.uuid ?? '',
      };
      if (!isNil(handleChange)) {
        handleChange(defaultOption);
      } else if (!isNil(handleChangeMultiselect)) {
        handleChangeMultiselect([defaultOption]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts.length]);

  const contactOptions = useMemo(
    () =>
      contacts.map((contact) => ({
        label: contact.displayName,
        value: contact.uuid,
      })),
    [contacts],
  );

  const PrefixSearch =
    enablePrefixSearch === true ? (
      <MenuItem
        key="prefix"
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'visible',
          pl: '10px',
        }}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <Check
            sx={{
              visibility: isPrefixSelected ? undefined : 'hidden',
              fontSize: '14px',
              ml: 0,
              mr: '6px',
            }}
          />
          <TextField
            size="small"
            sx={{ backgroundColor: 'white', width: '200px' }}
            // This prevents focus from changing to the menu.
            onKeyDown={stopPropagation}
            value={prefixInput}
            onChange={(e) => {
              setPrefixInput(e.target.value);
            }}
            placeholder="Prefix search"
          />
        </Stack>
      </MenuItem>
    ) : (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <></>
    );

  /// /////////////////////////////////////////////////////////////////////////////
  // SINGLE SELECT
  /// /////////////////////////////////////////////////////////////////////////////

  const onChange = (option: CustomerOption<EnablePrefixSearch> | null) => {
    if (!isNil(handleChange)) {
      if (!isNil(cacheId)) {
        if (
          isSingleCustomerOption(option) &&
          !isNil(option.label) &&
          !isNil(option.value)
        ) {
          localStorage.setItem(
            `${CACHE_PREFIX}_LABEL_${cacheId}`,
            option.label,
          );
          localStorage.setItem(
            `${CACHE_PREFIX}_VALUE_${cacheId}`,
            option.value,
          );
        } else {
          localStorage.removeItem(`${CACHE_PREFIX}_LABEL_${cacheId}`);
          localStorage.removeItem(`${CACHE_PREFIX}_VALUE_${cacheId}`);
        }
      }
      handleChange(option ?? undefined);
    }
  };
  useEffect(() => {
    if (!isEmpty(debouncedPrefixInput)) {
      if (!isNil(handleChange)) {
        // @ts-expect-error -- A TypeScript limitation
        onChange({
          prefix: debouncedPrefixInput,
        });
      } else if (!isNil(handleChangeMultiselect)) {
        // @ts-expect-error -- A TypeScript limitation
        handleChangeMultiselect({
          prefix: debouncedPrefixInput,
        });
        if (!isNil(CACHE_PREFIX) && !isNil(cacheId)) {
          localStorage.removeItem(multiselectCacheKey(CACHE_PREFIX, cacheId));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedPrefixInput]);

  const isAllSelected = isNil(selectedOption);

  if (!isNil(handleChangeMultiselect)) {
    return (
      <MultiselectFilterButton
        selectedOptions={
          isPrefixOption(selectedOptionsMultiselect)
            ? null
            : selectedOptionsMultiselect
        }
        options={contactOptions}
        handleChange={handleChangeMultiselect}
        prefixText={prefixText}
        isSmall={isSmall}
        filterLabel={filterLabel}
        displayNoneOption={false}
        additionalMenuItems={[
          {
            element: PrefixSearch,
            checked: isPrefixSelected,
          },
        ]}
        cachePrefix={CACHE_PREFIX}
        cacheId={cacheId}
        disableButton={disableButton}
      />
    );
  }

  return (
    <Box>
      <Button
        onClick={(e) => {
          setMenuAnchorEl(e.currentTarget);
        }}
        variant="outlined"
        sx={[isSmall ? styles.filterButtonSmall : styles.filterButton]}
        disabled={disableButton}
      >
        <Box
          sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}
        >
          <Typography sx={styles.filterTitle}>{prefixText}:</Typography>
          <Typography sx={styles.filterValue}>{filterLabel}</Typography>
          <ExpandMore fontSize="small" sx={{ mr: 0 }} />
        </Box>
      </Button>
      <Menu
        anchorEl={menuAnchorEl}
        id="customer-menu"
        open={Boolean(menuAnchorEl)}
        onClose={() => {
          setMenuAnchorEl(null);
          if (prefixInput === '' && isPrefixSelected) {
            onChange(undefined);
          }
        }}
        sx={{
          '& .MuiMenu-paper': { overflow: 'visible' },
          top: '3px',
        }}
      >
        <MenuList
          dense
          sx={{
            p: 0,
          }}
        >
          {!disableAllOption && (
            <MenuItem
              key="all"
              onClick={() => {
                onChange(undefined);
              }}
              sx={{
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'visible',
                pl: '10px',
              }}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Check
                  sx={{
                    visibility: isAllSelected ? undefined : 'hidden',
                    fontSize: '14px',
                    ml: 0,
                    mr: '6px',
                  }}
                />
                <Typography sx={styles.menuText}>All</Typography>
              </Stack>
            </MenuItem>
          )}

          <MenuItem
            key="custom"
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'visible',
              pl: '10px',
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Check
                sx={{
                  visibility:
                    isAllSelected || isPrefixSelected ? 'hidden' : undefined,
                  fontSize: '14px',
                  ml: 0,
                  mr: '6px',
                }}
              />
              <AutocompleteFuzzy<Option>
                size="small"
                sx={{ backgroundColor: 'white', width: '200px' }}
                value={
                  isNil(selectedOption) || isPrefixOption(selectedOption)
                    ? null
                    : selectedOption
                }
                options={contactOptions}
                matchSortOptions={{ keys: ['label'] }}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    // This prevents focus from changing to the menu.
                    onKeyDown={stopPropagation}
                    size="small"
                  />
                )}
                onChange={(_, option) => {
                  onChange(option);
                }}
              />
            </Stack>
          </MenuItem>
          {PrefixSearch}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default CustomerFilterButton;
