import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { Box, Modal } from '@mui/material';
import { Viewer } from '@react-pdf-viewer/core';
import { isNil, range } from 'lodash';
import { PDFDocument } from 'pdf-lib';
import { getDocument } from 'pdfjs-dist';
import { useRef, useEffect, useState, type MouseEventHandler } from 'react';
import styled, { css } from 'styled-components';
import { type DocumentType } from '../../../generated/graphql';
import theme from '../../../theme';

export type UploadDocument = {
  file: File;
  attributes: {
    isSelected: boolean;
    url: string;
    documentType: DocumentType | 'MULTIPLE_PAGES';
    pageNumber?: number;
    pageCount?: number;
    disabled?: boolean;
    pages?: UploadDocument[];
    existingDocument?: {
      uuid: string;
    };
  };
};

const SharedButtonStyles = css`
  position: absolute;
  padding: 2.5px 5px;
  z-index: 1;
  cursor: pointer;
  border-radius: 5px;
`;
const CountIndicator = styled.div`
  ${SharedButtonStyles}
  background-color: black;
  color: white;
  font-size: 10px;
  text-align: center;
  right: 0;
  bottom: 0;
`;

const ExpandButton = styled.button`
  all: unset;
  ${SharedButtonStyles}
  right: 0;
  top: 0;
  z-index: 2;
  :hover {
    color: ${theme.palette.paleOrange.main};
  }
`;

const ModalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  height: '90vh',
  width: '80vw',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 1,
};

// Extracts a single page from a PDF file or all pages if pageNumber is not provided
export const extractPdfPage = async (file: File, pageNumber?: number) => {
  const arrayBuf = await file.arrayBuffer();
  const pdfSrcDoc = await PDFDocument.load(arrayBuf);
  const pdfNewDoc = await PDFDocument.create();

  let pdfPages;
  // If pageNumber is not provided, extract all pages
  if (isNil(pageNumber)) {
    pdfPages = await pdfNewDoc.copyPages(
      pdfSrcDoc,
      range(0, pdfSrcDoc.getPageCount()),
    );
  } else {
    // If pageNumber is out of bounds, return an empty array
    if (pageNumber < 1 || pageNumber > pdfSrcDoc.getPageCount()) {
      return new Uint8Array();
    }
    pdfPages = await pdfNewDoc.copyPages(
      pdfSrcDoc,
      range(pageNumber - 1, pageNumber),
    );
  }
  for (const page of pdfPages) pdfNewDoc.addPage(page);
  const pdfBytes = await pdfNewDoc.save();
  return pdfBytes;
};

type PDFDocumentThumbnailProps = {
  readonly document: UploadDocument;
};
const PDFDocumentThumbnail = ({ document }: PDFDocumentThumbnailProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pdfFileData, setPdfFileData] = useState<string | null>(null);
  const { url } = document.attributes;
  const isExistingDocument = !isNil(document.attributes.existingDocument);

  const renderPdf = async (pdfBytes: Uint8Array) => {
    const tempblob = new Blob([pdfBytes], { type: 'application/pdf' });
    const tempUrl = URL.createObjectURL(tempblob);
    setPdfFileData(tempUrl);
  };

  const getPdfData = async (pdfUrl: string) => {
    const { current: canvas } = canvasRef;
    if (!canvas) return;
    const pdf = await getDocument(pdfUrl).promise;
    const page = await pdf.getPage(document.attributes.pageNumber ?? 1);
    const viewport = page.getViewport({ scale: 1 });
    const context = canvas.getContext('2d');

    // For some reason the pdf is mirrored when splitting pages
    if (document.attributes.documentType !== 'MULTIPLE_PAGES') {
      context?.scale(-1, -1);
    }
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    if (!context) return;
    const renderParams = {
      canvasContext: context,
      viewport,
    };
    page.render(renderParams);
  };

  useEffect(() => {
    getPdfData(url);
    extractPdfPage(document.file, document.attributes.pageNumber).then(
      renderPdf,
    );
  }, [isExistingDocument, url]);

  const onExpandButtonClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };
  const dataUsed = isExistingDocument ? document.attributes.url : pdfFileData;

  const countIndicatorValue = isNil(document.attributes.pageNumber)
    ? `${document.attributes.pageCount} >`
    : `${document.attributes.pageNumber} of ${document.attributes.pageCount}`;
  return (
    <Box position="relative" width="100%" height="100%">
      <CountIndicator>{countIndicatorValue}</CountIndicator>
      <ExpandButton onClick={onExpandButtonClick}>
        <FullscreenIcon />
      </ExpandButton>
      {!isNil(pdfFileData) && <Viewer fileUrl={pdfFileData} />}
      {!isNil(dataUsed) && (
        <Modal
          open={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
          }}
        >
          <Box sx={ModalStyle}>
            <object
              data={dataUsed}
              width="100%"
              height="100%"
              aria-label="pdf-preview"
            />
          </Box>
        </Modal>
      )}
    </Box>
  );
};

export default PDFDocumentThumbnail;
