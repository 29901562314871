import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SortType } from '../../../../../common/components/sort-component';
import useMe from '../../../../../common/react-hooks/use-me';
import { Segment } from '../../../../../generated/graphql';
import useIngestedEmailsStore from '../ingested-emails-store';
import ReviewEmailOrdersTableRow from './review-email-orders-table-row';

const useStyles = () => ({
  headerRow: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
});

const ReviewEmailOrdersTable = () => {
  const [, setSearchParams] = useSearchParams();
  const { segment } = useMe();

  const [selectedOrderUuid, setSelectedOrderUuid, ingestedEmailOrdersData] =
    useIngestedEmailsStore((state) => [
      state.selectedOrderUuid,
      state.setSelectedOrderUuid,
      state.ingestedEmailOrdersData,
    ]);

  const [createdAtSortState, setCreatedAtSortState] = useState<SortType>(
    SortType.Descending,
  );

  const handleCreatedDateSort = () => {
    if (createdAtSortState === SortType.Ascending) {
      setCreatedAtSortState(SortType.Descending);
    } else if (createdAtSortState === SortType.Descending) {
      setCreatedAtSortState(SortType.Ascending);
    }
  };

  const styles = useStyles();

  const inputFieldString = segment === Segment.Cartage ? 'HAWB' : 'Pro #';

  const scannedOrderResults =
    ingestedEmailOrdersData?.scannedOrderResults.edges.map(
      ({ node: order }) => order,
    );

  return (
    <TableContainer sx={{ mt: '0!important' }}>
      <Table
        stickyHeader
        padding="normal"
        aria-label="scanned-orders-from-emails-table"
        sx={{
          overflowY: 'scroll',
          border: '1px solid #e0e0e0',
          borderRadius: '5px',
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell sx={styles.headerRow}>Order</TableCell>
            <TableCell sx={styles.headerRow}>{inputFieldString}</TableCell>

            <TableCell sx={styles.headerRow}>Customer</TableCell>
            <TableCell sx={styles.headerRow}>From Address</TableCell>
            <TableCell sx={styles.headerRow}>Subject</TableCell>
            <TableCell sx={{ ...styles.headerRow, width: '200px' }}>
              <TableSortLabel
                active
                disabled
                direction={
                  createdAtSortState === SortType.Descending ? 'desc' : 'asc'
                }
                hideSortIcon={false}
                onClick={handleCreatedDateSort}
              >
                Received At
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {scannedOrderResults?.map((scannedOrderResult) => (
            <ReviewEmailOrdersTableRow
              key={scannedOrderResult.uuid}
              ingestedEmailOrder={scannedOrderResult}
              rowIsSelected={
                selectedOrderUuid === scannedOrderResult.order?.uuid
              }
              selectRow={() => {
                const { order } = scannedOrderResult;
                if (!isNil(order)) {
                  setSelectedOrderUuid(order.uuid);
                  setSearchParams((sp) => {
                    const newParams = new URLSearchParams(sp);
                    newParams.set('orderUuid', order.uuid);
                    return newParams;
                  });
                }
              }}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReviewEmailOrdersTable;
