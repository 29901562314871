import {
  Box,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  type SelectChangeEvent,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import React, {
  type ChangeEvent,
  type Dispatch,
  type SetStateAction,
  useState,
} from 'react';
import { DayOfWeekAll } from '../../../generated/graphql';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import useStyles from '../../orders/components/styles';
import {
  selectOrderName,
  selectRecurringOrderFrequency,
  type StandardOrderValues,
  updateOneStandardOrderValues,
} from '../../orders/redux/standard/standard-orders-values-slice';

export const getDayOfWeekAllAbbreviation = (dow: string | null | undefined) => {
  // eslint-disable-next-line no-param-reassign
  dow = dow?.toUpperCase();
  switch (dow) {
    case DayOfWeekAll.Monday: {
      return 'M';
    }
    case DayOfWeekAll.Tuesday: {
      return 'Tu';
    }
    case DayOfWeekAll.Wednesday: {
      return 'W';
    }
    case DayOfWeekAll.Thursday: {
      return 'Th';
    }
    case DayOfWeekAll.Friday: {
      return 'F';
    }
    case DayOfWeekAll.Saturday: {
      return 'Sa';
    }
    case DayOfWeekAll.Sunday: {
      return 'Su';
    }
    default: {
      return '';
    }
  }
};

const RecurringOrderFrequencySelection = ({
  orderUuid,
  setOrderHasChanged,
}: {
  readonly orderUuid: string;
  readonly setOrderHasChanged: Dispatch<SetStateAction<boolean>>;
}) => {
  const dispatch = useAppDispatch();
  const styles = useStyles();

  const theme = useTheme();
  const values = useAppSelector((state) =>
    selectRecurringOrderFrequency(state, orderUuid),
  );
  const name = useAppSelector((state) => selectOrderName(state, orderUuid));
  const [daysOfWeek, setDaysOfWeek] = useState<string[]>(
    isNil(values?.daysOfWeek)
      ? ['Monday']
      : Object.keys(DayOfWeekAll).filter((dow) =>
          values?.daysOfWeek?.includes(
            DayOfWeekAll[dow as keyof typeof DayOfWeekAll],
          ),
        ),
  );
  const [repeatIntervalWeeks, setRepeatIntervalWeeks] = useState<number>(
    values?.repeatIntervalWeeks ?? 1,
  );
  const [neverEnds, setNeverEnds] = useState<boolean>(isNil(values?.endDate));

  const updateOrderData = (
    fieldName: keyof StandardOrderValues,
    value: StandardOrderValues[typeof fieldName],
  ) => {
    setOrderHasChanged(true);
    dispatch(
      updateOneStandardOrderValues({
        id: orderUuid,
        changes: { [fieldName]: value },
      }),
    );
  };

  const onDaysOfWeekChange = async (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    const parsedArr = typeof value === 'string' ? value.split(', ') : value;
    const parsedDaysOfWeek: DayOfWeekAll[] = parsedArr.map(
      (dow) => DayOfWeekAll[dow as keyof typeof DayOfWeekAll],
    );
    setOrderHasChanged(true);
    updateOrderData('daysOfWeek', parsedDaysOfWeek);
    setDaysOfWeek(parsedArr);
  };

  const onRepeatIntervalWeeksChange = async (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    let interval = Number.parseInt(event.target.value, 10);
    if (Number.isNaN(interval) || interval < 1) {
      interval = 1;
    }
    setOrderHasChanged(true);
    updateOrderData('repeatIntervalWeeks', interval);
    setRepeatIntervalWeeks(interval);
  };

  const onStartDateChange = async (newDate: dayjs.Dayjs | null) => {
    setOrderHasChanged(true);
    updateOrderData('startDate', newDate?.toDate() ?? new Date());
  };

  const onEndDateChange = async (newDate: dayjs.Dayjs | null | undefined) => {
    setOrderHasChanged(true);
    updateOrderData('endDate', newDate?.toDate() ?? undefined);
  };

  return (
    <Card sx={styles.card}>
      <Typography variant="h4">Recurring order settings</Typography>
      <Box
        sx={{
          ...styles.boxRow,
          columnGap: theme.spacing(3),
        }}
      >
        <FormControl sx={styles.formField}>
          <TextField
            required
            data-cy="order-name-input-if"
            size="small"
            value={name ?? ''}
            sx={styles.formField}
            label="Template name"
            onChange={(event) => {
              updateOrderData('name', event.target.value);
            }}
          />
        </FormControl>
        <FormControl sx={{ ...styles.formField, width: '125px' }}>
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {repeatIntervalWeeks === 1 ? 'week' : 'weeks'}
                </InputAdornment>
              ),
            }}
            value={repeatIntervalWeeks ?? 1}
            size="small"
            label="Repeat every"
            onChange={onRepeatIntervalWeeksChange}
          />
        </FormControl>
        <FormControl sx={{ ...styles.formField }}>
          <InputLabel id="demo-simple-select-label">Repeat on</InputLabel>
          <Select
            multiple
            displayEmpty
            size="small"
            value={daysOfWeek ?? []}
            renderValue={(selected) => {
              return selected
                .map((dow) => getDayOfWeekAllAbbreviation(dow))
                .join(', ');
            }}
            label="Repeat on"
            onChange={async (event) => onDaysOfWeekChange(event)}
          >
            {Object.keys(DayOfWeekAll).map((identifier) => (
              <MenuItem key={identifier} id={identifier} value={identifier}>
                <Checkbox checked={daysOfWeek.includes(identifier)} />
                <ListItemText primary={identifier} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ width: '175px' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disablePast
              value={dayjs(values?.startDate)}
              label="Start date"
              maxDate={
                isNil(values?.endDate) ? undefined : dayjs(values?.endDate)
              }
              slotProps={{ textField: { size: 'small' } }}
              onChange={async (newDate) => onStartDateChange(newDate)}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl sx={{ width: '175px' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disablePast
              value={
                !isNil(values?.endDate) && !neverEnds
                  ? dayjs(values?.endDate)
                  : null
              }
              label="End date"
              minDate={dayjs(values?.startDate)}
              disabled={neverEnds}
              slotProps={{
                textField: {
                  size: 'small',
                  sx: {
                    '& .MuiInputBase-root.Mui-disabled': {
                      'background-color': '#f6f6f6',
                    },
                  },
                },
              }}
              onChange={async (newDate) => onEndDateChange(newDate)}
            />
          </LocalizationProvider>
          <FormControlLabel
            control={
              <Checkbox
                checked={neverEnds}
                onChange={(e) => {
                  setNeverEnds(e.target.checked);
                  onEndDateChange(undefined);
                }}
              />
            }
            label="No end date"
          />
        </FormControl>
      </Box>
    </Card>
  );
};

export default RecurringOrderFrequencySelection;
