import { ExpandMore } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  MenuList,
  Typography,
  useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isNil } from 'lodash';
import pluralize from 'pluralize';
import React, { useEffect, useRef, useState } from 'react';
import { filterNotNilOrEmpty } from 'shared/array';

dayjs.extend(utc);
dayjs.extend(timezone);

const useStyles = () => {
  const theme = useTheme();
  return {
    filterButton: {
      backgroundColor: theme.palette.primary.light,
      borderRadius: '4px',
    },
    menuText: {
      fontSize: '14px',
    },
    filterTitle: {
      fontSize: '14px',
      ml: '3px',
    },
    filterValue: {
      fontSize: '14px',
      ml: '5px',
      fontWeight: 'bold',
    },
    menuItem: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'visible',
      p: '10px',
    },
  };
};

type ViewChangedButtonProps = {
  readonly numFiltersChanged: number;
  readonly numSortsChanged: number;
  readonly numColumnsChanged: number;
  readonly resetView: () => void;
  readonly saveView: () => void;
  readonly showCreateViewModal: (viewIsFromScratch: boolean) => void;
  readonly currentSavedViewUuid: string | null;
  readonly shouldAllowSavedViews: boolean;
};

const ViewChangedButton = ({
  numFiltersChanged,
  numSortsChanged,
  numColumnsChanged,
  resetView,
  saveView,
  showCreateViewModal,
  currentSavedViewUuid,
  shouldAllowSavedViews,
}: ViewChangedButtonProps) => {
  const styles = useStyles();
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);

  // Open the menu the first time its rendered
  useEffect(() => {
    if (!isNil(buttonRef)) {
      setMenuAnchorEl(buttonRef.current);
    }
  }, [buttonRef]);

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  if (numFiltersChanged < 1 && numSortsChanged < 1 && numColumnsChanged < 1) {
    return null;
  }

  return (
    <>
      <Button
        ref={buttonRef}
        size="small"
        onClick={(e) => {
          setMenuAnchorEl(e.currentTarget);
        }}
      >
        <Box
          sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}
        >
          {filterNotNilOrEmpty([
            numFiltersChanged > 0
              ? `${numFiltersChanged} ${pluralize('filter', numFiltersChanged)}`
              : null,
            // TODO: pluralizing this doesn't really make sense until we support multisort
            numSortsChanged > 0
              ? `${numSortsChanged} ${pluralize('sort', numSortsChanged)}`
              : null,
            numColumnsChanged > 0
              ? `${numColumnsChanged} ${pluralize('column', numColumnsChanged)}`
              : null,
          ]).join(', ')}
          {` changed`}
          <ExpandMore fontSize="small" sx={{ mr: 0 }} />
        </Box>
      </Button>
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => {
          handleCloseMenu();
        }}
      >
        <MenuList
          sx={{
            p: 0,
            width: '10vw',
          }}
        >
          {shouldAllowSavedViews && !isNil(currentSavedViewUuid) && (
            <MenuItem
              sx={styles.menuItem}
              onClick={() => {
                saveView();
                handleCloseMenu();
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Typography sx={styles.menuText}>Save</Typography>
              </Box>
            </MenuItem>
          )}
          {shouldAllowSavedViews && (
            <>
              <MenuItem
                sx={styles.menuItem}
                onClick={() => {
                  showCreateViewModal(false);
                  handleCloseMenu();
                }}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Typography sx={styles.menuText}>
                    Save as new view...
                  </Typography>
                </Box>
              </MenuItem>
              <Divider />
            </>
          )}
          <MenuItem
            sx={styles.menuItem}
            onClick={() => {
              resetView();
              handleCloseMenu();
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Typography sx={styles.menuText}>Reset</Typography>
            </Box>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};

export default React.memo(ViewChangedButton);
