type Context = {
  operationName: string;
  operationVariables: Record<string, unknown>;
  duration: number;
  timedOut: boolean;
};

export class TimeoutError extends Error {
  constructor(message: string, context: Context) {
    super(message);
    this.context = context;
  }

  context: Context;
}
