import { ArrowRightAlt } from '@mui/icons-material';
import { Table, TableBody, TableRow } from '@mui/material';
import { sentenceCase } from 'change-case';
import currency from 'currency.js';
import { isNil } from 'lodash';
import React from 'react';
import { type OrderDifferenceFragment } from '../../../../../../../../../generated/graphql';
import { TableCellCustom } from './common-table-components';
import DifferenceRow from './difference-row';
import PackageDifferenceBlock from './package-difference-block';

type OrderDifferenceBlockProps = {
  readonly orderDifferences: OrderDifferenceFragment | null;
};

const OrderDifferenceBlock = ({
  orderDifferences,
}: OrderDifferenceBlockProps) => {
  if (isNil(orderDifferences)) return null;
  return (
    <Table size="small">
      <colgroup>
        <col width="25%" />
        <col width="36%" />
        <col width="3%" />
        <col width="36%" />
      </colgroup>

      <TableBody>
        {/* HAWB */}
        {!isNil(orderDifferences.hawb) && (
          <DifferenceRow
            oldValue={orderDifferences.hawb?.oldValue ?? undefined}
            newValue={orderDifferences.hawb?.newValue ?? undefined}
            fieldName="HAWB"
            wasEntireEntityDeleted={false}
            wasEntireEntityAdded={false}
          />
        )}
        {/* MAWB */}
        {!isNil(orderDifferences.mawb) && (
          <DifferenceRow
            oldValue={orderDifferences.mawb?.oldValue ?? undefined}
            newValue={orderDifferences.mawb?.newValue ?? undefined}
            fieldName="MAWB"
            wasEntireEntityDeleted={false}
            wasEntireEntityAdded={false}
          />
        )}
        {/* Ref numbers */}
        {!isNil(orderDifferences.refNumbers) && (
          <DifferenceRow
            oldValue={
              orderDifferences.refNumbers?.oldValue?.toString() ?? undefined
            }
            newValue={
              orderDifferences.refNumbers?.newValue?.toString() ?? undefined
            }
            fieldName="Ref numbers"
            wasEntireEntityDeleted={false}
            wasEntireEntityAdded={false}
          />
        )}
        {/* Service level */}
        {!isNil(orderDifferences.serviceLevel) && (
          <DifferenceRow
            oldValue={sentenceCase(
              orderDifferences.serviceLevel?.oldValue ?? '',
            )}
            newValue={sentenceCase(
              orderDifferences.serviceLevel?.newValue ?? '',
            )}
            fieldName="Service level"
            wasEntireEntityDeleted={false}
            wasEntireEntityAdded={false}
          />
        )}
        {!isNil(orderDifferences.packages) && (
          <TableRow sx={{ border: 'none' }}>
            <TableCellCustom>Packages</TableCellCustom>
            <TableCellCustom>
              <PackageDifferenceBlock
                isOldPackages
                packageDifferences={orderDifferences.packages}
              />
            </TableCellCustom>
            <TableCellCustom>
              <ArrowRightAlt />
            </TableCellCustom>
            <TableCellCustom>
              <PackageDifferenceBlock
                packageDifferences={orderDifferences.packages}
                isOldPackages={false}
              />
            </TableCellCustom>
          </TableRow>
        )}
        {/* Order total */}
        {!isNil(orderDifferences.totalAmountCents) && (
          <DifferenceRow
            oldValue={
              currency(orderDifferences.totalAmountCents?.oldValue ?? 0, {
                fromCents: true,
              }).format() ?? undefined
            }
            newValue={
              currency(orderDifferences.totalAmountCents?.newValue ?? 0, {
                fromCents: true,
              }).format() ?? undefined
            }
            fieldName="Total charge"
            wasEntireEntityDeleted={false}
            wasEntireEntityAdded={false}
          />
        )}
      </TableBody>
    </Table>
  );
};

export default OrderDifferenceBlock;
