import { Menu, MenuItem } from '@mui/material';
import * as React from 'react';
import { type Dispatch, type SetStateAction, useState } from 'react';
import CreateEmptySettlementModal from './create-empty-settlement-modal';

type SettlementsContextMenuProps = {
  readonly anchorEl: null | HTMLElement;
  readonly setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
};
const SettlementsContextMneu = ({
  anchorEl,
  setAnchorEl,
}: SettlementsContextMenuProps) => {
  const [showCreateEmptySettlementsModal, setShowCreateEmptySettlementsModal] =
    useState<boolean>(false);

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      onClose={() => {
        setAnchorEl(null);
      }}
    >
      <CreateEmptySettlementModal
        isOpen={showCreateEmptySettlementsModal}
        setIsOpen={setShowCreateEmptySettlementsModal}
      />
      <MenuItem
        onClick={() => {
          setShowCreateEmptySettlementsModal(true);
        }}
      >
        Create Empty Settlement
      </MenuItem>
    </Menu>
  );
};

export default SettlementsContextMneu;
