import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Divider,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { isEmpty, isNil } from 'lodash';
import React, {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CSVLink } from 'react-csv';
import CustomerFilterButton from '../../../../common/components/customer-filter-button';
import DateDropdownPicker, {
  type DateOption,
} from '../../../../common/components/date-dropdown-picker';
import DownloadTypeSelection, {
  DownloadType,
} from '../../../../common/components/download-type-selection';
import TerminalFilterButton from '../../../../common/components/terminal-filter-button';
import { type Option } from '../../../../common/filters/types';
import { useMeasureExecutionTime } from '../../../../common/react-hooks/use-measure-execution-time';
import useTerminals from '../../../../common/react-hooks/use-terminals';
import {
  PaymentTableField,
  PaymentType,
  SortDirection,
  useMeQuery,
  usePaymentsForCheckRegisterReportLazyQuery,
} from '../../../../generated/graphql';
import useInvoicesStore from '../../invoices-store';
import styles from '../../styles';
import { accountingReportDefaultDateOption } from '../accounting-reports/constants';
import { downloadCheckRegisterReport } from './utils';

type DownloadCheckRegisterReportModalProps = {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const DownloadCheckRegisterReportModal = ({
  isOpen,
  setIsOpen,
}: DownloadCheckRegisterReportModalProps) => {
  const [customerOption, setCustomerOption] = useState<Option | undefined>();
  const [terminalOption, setTerminalOption] = useState<Option | undefined>();
  const [dateOption, setDateOption] = useState<DateOption>(
    accountingReportDefaultDateOption,
  );
  const { data: companyData } = useMeQuery({
    fetchPolicy: 'cache-first',
  });
  const [getPaymentsForReport, { loading: paymentsReportLoading }] =
    usePaymentsForCheckRegisterReportLazyQuery();
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const [downloadType, setDownloadType] = useState<DownloadType>(
    DownloadType.PDF,
  );
  const createFileDownload = useInvoicesStore(
    (state) => state.createFileDownload,
  );
  const [csvReportData, setCSVReportData] = useState<{
    fileName: string;
    csvData: Array<string[] | Array<string | number | undefined>>;
  }>({ fileName: '', csvData: [] });
  const csvLink = useRef<{ link: HTMLAnchorElement }>(null);

  const resetFilters = useCallback(() => {
    setCustomerOption(undefined);
    setTerminalOption(undefined);
    setDateOption(accountingReportDefaultDateOption);
  }, [setCustomerOption, setTerminalOption, setDateOption]);

  const paymentsForReportInput = useMemo(() => {
    return {
      paymentType: PaymentType.Payment,
      contactUuid: customerOption?.value ?? undefined,
      terminalUuid: terminalOption?.value ?? undefined,
      paymentDateFilters: isNil(dateOption)
        ? undefined
        : {
            startFilterValue: isNil(dateOption.startDate)
              ? undefined
              : dayjs(dateOption.startDate).startOf('day').toDate(),
            endFilterValue: isNil(dateOption.endDate)
              ? undefined
              : dayjs(dateOption.endDate).endOf('day').toDate(),
          },
      sorts: [
        {
          sortBy: PaymentTableField.Date,
          sortDirection: SortDirection.Asc,
        },
      ],
    };
  }, [customerOption, terminalOption, dateOption]);

  const startDownload = async () => {
    if (downloadType !== DownloadType.CSV) setIsOpen(false);
    const completeDownload = createFileDownload();
    const res = await getPaymentsForReport({
      variables: {
        args: paymentsForReportInput,
      },
    });
    const payments = res.data?.checkRegisterReport ?? [];

    const csvDataRes = await downloadCheckRegisterReport({
      companyData,
      customerOption,
      terminalOption,
      startDate: dateOption.startDate,
      endDate: dateOption.endDate,
      payments,
      terminalsEnabled,
      downloadType: downloadType ?? DownloadType.PDF,
    });

    if (downloadType === DownloadType.CSV) {
      if (!isNil(csvDataRes)) {
        setCSVReportData(csvDataRes);
      }
    } else if (downloadType === DownloadType.PDF) {
      resetFilters();
    }
    completeDownload();
  };

  const startDownloadWithMeasurement = useMeasureExecutionTime({
    fn: startDownload,
    rumLabel: 'download-check-register-report',
    logData: { ...paymentsForReportInput, downloadType },
  });

  useEffect(() => {
    if (!isEmpty(csvReportData)) {
      csvLink.current?.link.click();
      setIsOpen(false);
      resetFilters();
    }
  }, [csvReportData, setIsOpen, resetFilters]);

  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={() => {
        if (!paymentsReportLoading || downloadType !== DownloadType.CSV) {
          setIsOpen(false);
        }
      }}
    >
      <Box sx={[styles.modal, { height: '300px', width: '400px' }]}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={10}>
            <Typography sx={{ fontWeight: 'bold' }}>
              Download Check Register Report
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              sx={{ float: 'right' }}
              disabled={
                paymentsReportLoading && downloadType === DownloadType.CSV
              }
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <DateDropdownPicker
              filterTitle="Payment Date"
              dateOption={dateOption}
              setDateOption={setDateOption}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomerFilterButton
              selectedOption={customerOption}
              handleChange={(option: Option | undefined) => {
                setCustomerOption(option);
              }}
            />
          </Grid>
          {terminalsEnabled && (
            <Grid item xs={12}>
              <TerminalFilterButton
                displayCode
                prefixText="Terminal"
                selectedOption={terminalOption}
                handleChange={(option: Option | null | undefined) => {
                  setTerminalOption(option ?? undefined);
                }}
                includeInactiveTerminals={false}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{ float: 'left' }}
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={1}
            >
              <DownloadTypeSelection
                labelText="File type"
                cacheId="CHECK_REGISTER_REPORT"
                selectedOption={downloadType}
                handleChange={(selectedDownloadType: DownloadType) => {
                  setDownloadType(selectedDownloadType);
                }}
              />
            </Box>
            <Box sx={{ float: 'right' }}>
              <Button
                sx={{ width: '100px' }}
                variant="contained"
                color="info"
                disabled={
                  paymentsReportLoading && downloadType === DownloadType.CSV
                }
                onClick={startDownloadWithMeasurement}
              >
                Download
              </Button>
            </Box>
          </Grid>
          <CSVLink
            ref={csvLink}
            data={csvReportData.csvData}
            filename={csvReportData.fileName}
            className="hidden"
            target="_blank"
          />
        </Grid>
      </Box>
    </Modal>
  );
};

export default DownloadCheckRegisterReportModal;
