import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Divider,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import React, {
  type Dispatch,
  type SetStateAction,
  useMemo,
  useState,
} from 'react';
import { filterNotNil } from 'shared/array';
import CSVDownloadButton from '../../../../../common/components/buttons/csv-download-button';
import CustomerFilterButton from '../../../../../common/components/customer-filter-button';
import DateDropdownPicker, {
  type DateOption,
  defaultPast1WeekDateRangeOption,
} from '../../../../../common/components/date-dropdown-picker';
import DownloadTypeSelection, {
  DownloadType,
} from '../../../../../common/components/download-type-selection';
import SingleSelectFilterButton from '../../../../../common/components/single-select-filter-button';
import TerminalFilterButton from '../../../../../common/components/terminal-filter-button';
import { type Option } from '../../../../../common/filters/types';
import useMe from '../../../../../common/react-hooks/use-me';
import { useMeasureExecutionTime } from '../../../../../common/react-hooks/use-measure-execution-time';
import useTerminals from '../../../../../common/react-hooks/use-terminals';
import {
  FilterOperator,
  PaymentRail,
  PaymentTableField,
  PaymentType,
  SortDirection,
  usePaymentApplicationReportLazyQuery,
} from '../../../../../generated/graphql';
import useInvoicesStore from '../../../invoices-store';
import styles from '../../../styles';
import {
  convertPaymentApplicationReportDataToCSV,
  downloadPaymentApplicationReport,
} from '../../../utils';
import { accountingReportDefaultDateOption } from '../../accounting-reports/constants';
import { formatPaymentRail } from '../../customers/utils';
import { formatDateOption } from './utils';

type DownloadPaymentApplicationReportModalProps = {
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
};

const DownloadPaymentApplicationReportModal = ({
  open,
  setOpen,
}: DownloadPaymentApplicationReportModalProps) => {
  const { companyData } = useMe();
  const [customerOption, setCustomerOption] = useState<Option | undefined>();
  const [terminalOption, setTerminalOption] = useState<Option | undefined>();
  const [dateOption, setDateOption] = useState<DateOption>(
    defaultPast1WeekDateRangeOption,
  );
  const [paymentRail, setPaymentRail] = useState<PaymentRail | undefined>();
  const [downloadType, setDownloadType] = useState<DownloadType>(
    DownloadType.PDF,
  );
  const { terminalsEnabled } = useTerminals({});
  const [getPayments] = usePaymentApplicationReportLazyQuery();

  const paymentsInputs = useMemo(() => {
    return {
      contactUuid: customerOption?.value,
      paymentDateFilters: {
        filterOperator: FilterOperator.And,
        startFilterValue: dateOption.startDate,
        endFilterValue: dateOption.endDate,
      },
      paymentTypes: Object.values(PaymentType), // Include all payment types here
      paymentRails: filterNotNil([paymentRail]),
      terminalUuid: terminalOption?.value,
      sorts: [
        {
          sortBy: PaymentTableField.Date,
          sortDirection: SortDirection.Asc,
        },
      ],
    };
  }, [customerOption, dateOption, terminalOption, paymentRail]);

  const getPaymentsData = async () =>
    getPayments({
      variables: {
        args: paymentsInputs,
      },
    });

  const createFileDownload = useInvoicesStore(
    (state) => state.createFileDownload,
  );

  const downloadPDF = async () => {
    const completeDownload = createFileDownload();
    setOpen(false);
    const res = await getPaymentsData();

    const payments = res.data?.paymentApplicationReport;

    if (isNil(payments)) {
      completeDownload({
        alertSeverity: 'error',
        message: 'Error downloading report',
      });
    } else {
      await downloadPaymentApplicationReport({
        companyData,
        terminalsEnabled,
        dateOption,
        customerOption,
        terminalOption,
        paymentRail,
        payments,
      });
      completeDownload();
    }
    setCustomerOption(undefined);
    setTerminalOption(undefined);
    setDateOption(accountingReportDefaultDateOption);
  };

  const downloadCSV = async () => {
    const completeDownload = createFileDownload();
    const res = await getPaymentsData();

    const payments = res.data?.paymentApplicationReport;
    if (isNil(payments)) {
      completeDownload({
        alertSeverity: 'error',
        message: 'Error downloading report',
      });
      return null;
    }
    const csvData = convertPaymentApplicationReportDataToCSV(payments);
    completeDownload();
    return csvData;
  };

  const downloadPdfWithMeasurement = useMeasureExecutionTime({
    fn: downloadPDF,
    rumLabel: 'download-payment-application-report',
    logData: {
      ...paymentsInputs,
      downloadType,
    },
  });

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={() => {
        setOpen(false);
      }}
    >
      <Box
        sx={[
          styles.modal,
          {
            height: 'fit-content',
            width: '400px',
          },
        ]}
      >
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={10}>
            <Typography sx={{ fontWeight: 'bold' }}>
              Download Payment Application Report
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              sx={{ float: 'right' }}
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} sx={{ height: '100%', mb: 2 }}>
            <Stack direction="column" spacing={1}>
              <Box>
                <DateDropdownPicker
                  filterTitle="Payment date"
                  dateOption={dateOption}
                  setDateOption={setDateOption}
                />
              </Box>
              <CustomerFilterButton
                selectedOption={customerOption}
                handleChange={(option: Option | undefined) => {
                  setCustomerOption(option);
                }}
              />
              {terminalsEnabled && (
                <TerminalFilterButton
                  selectedOption={terminalOption}
                  prefixText="Terminal"
                  handleChange={(option: Option | null | undefined) => {
                    setTerminalOption(option ?? undefined);
                  }}
                  includeInactiveTerminals={false}
                />
              )}
              <SingleSelectFilterButton
                enableDefaultFilter
                option={paymentRail}
                handleChange={setPaymentRail}
                defaultFilterTitle="All"
                options={Object.values(PaymentRail)}
                renderOption={(opt) => formatPaymentRail(opt)}
                filterTitle="Method:"
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignContent="center"
            >
              <DownloadTypeSelection
                labelText="File type"
                cacheId="PAYMENT_JOURNAL_REPORT_FILE_TYPE"
                selectedOption={downloadType}
                handleChange={(selectedDownloadType: DownloadType) => {
                  setDownloadType(selectedDownloadType);
                }}
              />
              {downloadType === DownloadType.PDF ? (
                <Button
                  sx={{ width: 100 }}
                  variant="contained"
                  color="info"
                  onClick={downloadPdfWithMeasurement}
                >
                  Download
                </Button>
              ) : (
                <CSVDownloadButton
                  getData={downloadCSV}
                  buttonProps={{
                    sx: { width: 100 },
                    variant: 'contained',
                    color: 'info',
                    disabled: false,
                  }}
                  filename={`payment-application-report-${formatDateOption(dateOption)}.csv`}
                  label="Download"
                  reportType="payment-application-report"
                  dataForTimingLog={paymentsInputs}
                  onDownloadComplete={() => {
                    setCustomerOption(undefined);
                    setTerminalOption(undefined);
                    setDateOption(accountingReportDefaultDateOption);
                  }}
                />
              )}
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default DownloadPaymentApplicationReportModal;
