import {
  DragDropContext,
  Draggable,
  type DropResult,
  Droppable,
} from '@hello-pangea/dnd';
import CloseIcon from '@mui/icons-material/Close';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import {
  Box,
  Button,
  Dialog,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import {
  type TariffChainWithGroupsFragment,
  TariffChainsDocument,
  TariffChainsWithGroupsDocument,
  TariffGroupType,
  useCreateTariffChainMutation,
  useTariffGroupsQuery,
  useUpdateTariffChainMutation,
} from '../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import CreateOrEdit from '../../enums/create-or-edit';
import { TariffGroupTypeDropdown } from '../common/tariff-group-type-dropdown';
import { styles } from '../styles';

const CreateOrEditTariffChainModal = ({
  open,
  onClose,
  createOrEdit,
  tariffChain,
}: {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly createOrEdit: CreateOrEdit;
  readonly tariffChain: TariffChainWithGroupsFragment | undefined;
}) => {
  const [name, setName] = useState(tariffChain?.name ?? '');
  const [tariffGroupType, setTariffGroupType] = useState(
    tariffChain?.tariffGroupType ?? TariffGroupType.Ordinary,
  );
  const [tariffGroupsOnChain, setTariffGroupsOnChain] = useState<
    TariffChainWithGroupsFragment['tariffGroups']
  >(tariffChain?.tariffGroups ?? []);

  const [errorMessage, setErrorMessage] = useState('');

  const [selectedOption, setSelectedOption] = useState<{
    label: string;
    value: string;
  } | null>(null);

  useEffect(() => {
    setName(tariffChain?.name ?? '');
    setTariffGroupType(
      tariffChain?.tariffGroupType ?? TariffGroupType.Ordinary,
    );
    setTariffGroupsOnChain(tariffChain?.tariffGroups ?? []);
    setErrorMessage('');
    setSelectedOption(null);
  }, [tariffChain]);

  const { data: tariffGroupsForType, loading: tariffGroupsForTypeLoading } =
    useTariffGroupsQuery({
      variables: {
        tariffGroupType,
        excludeDefault: true,
        first: 1000,
      },
    });

  const [createTariffChain, { loading: creating }] =
    useCreateTariffChainMutation({
      refetchQueries: [TariffChainsDocument, TariffChainsWithGroupsDocument],
      onError: ({ message }) => {
        setErrorMessage(message);
      },
    });
  const [updateTariffChain, { loading: updating }] =
    useUpdateTariffChainMutation({
      refetchQueries: [TariffChainsDocument, TariffChainsWithGroupsDocument],
      onError: ({ message }) => {
        setErrorMessage(message);
      },
    });

  const validateInputs = () => {
    if (isEmpty(name)) {
      setErrorMessage('Please enter a name');
      return false;
    }
    return true;
  };

  const handleSave = async () => {
    const isValid = validateInputs();
    if (!isValid) {
      return;
    }
    if (createOrEdit === CreateOrEdit.Create) {
      await createTariffChain({
        variables: {
          createTariffChainInput: {
            name,
            tariffGroupType,
            tariffGroupUuids: tariffGroupsOnChain.map((tg) => tg.uuid),
          },
        },
        onCompleted: () => {
          onClose();
          setErrorMessage('');
        },
      });
    } else {
      if (!tariffChain) {
        return;
      }
      await updateTariffChain({
        variables: {
          updateTariffChainInput: {
            id: tariffChain.id,
            name,
            tariffGroupUuids: tariffGroupsOnChain.map((tg) => tg.uuid),
          },
        },
        onCompleted: () => {
          onClose();
          setErrorMessage('');
        },
      });
    }
    onClose();
  };

  const handleOnClose = () => {
    onClose();
    setName('');
    setErrorMessage('');
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const items = [...tariffGroupsOnChain];
    const [reorderedItem] = items.splice(result.source.index, 1);
    if (!isNil(reorderedItem)) {
      items.splice(result.destination.index, 0, reorderedItem);
      setTariffGroupsOnChain(items);
    }
  };

  const tariffGroupsToAddOptions = useMemo(() => {
    return tariffGroupsForType?.tariffGroupsPaginated.edges
      .map((tg) => ({
        label: tg.node.name ?? '',
        value: tg.node.uuid,
      }))
      .filter(
        (tg) => !tariffGroupsOnChain.some((tgc) => tgc.uuid === tg.value),
      );
  }, [tariffGroupsForType, tariffGroupsOnChain]);

  const onAddTariffGroup = () => {
    if (isNil(selectedOption)) {
      return;
    }
    const selectedTariffGroup =
      tariffGroupsForType?.tariffGroupsPaginated.edges.find(
        (tg) => tg.node.uuid === selectedOption.value,
      );
    if (isNil(selectedTariffGroup)) {
      return;
    }
    setTariffGroupsOnChain([...tariffGroupsOnChain, selectedTariffGroup.node]);
    setSelectedOption(null);
  };

  const onRemoveTariffGroup = (idx: number) => {
    const newTariffGroups = [...tariffGroupsOnChain];
    newTariffGroups.splice(idx, 1);
    setTariffGroupsOnChain(newTariffGroups);
  };

  return (
    <Dialog open={open} onClose={handleOnClose} maxWidth="lg">
      <Box sx={styles.modalInnerContainer}>
        <Typography variant="h5">{createOrEdit} a Tariff Chain</Typography>
        {!isEmpty(errorMessage) && (
          <Typography sx={{ color: 'red' }}>{errorMessage}</Typography>
        )}{' '}
        <Stack direction="row" gap={2}>
          <TariffGroupTypeDropdown
            tariffGroupType={tariffGroupType}
            setTariffGroupType={setTariffGroupType}
            disabled={createOrEdit === CreateOrEdit.Edit}
            fullWidth={false}
          />
          <TextField
            size="small"
            value={name}
            label="Name"
            onChange={(e) => {
              setName(e.target.value);
              setErrorMessage('');
            }}
            sx={{ width: '300px' }}
            // fullWidth
          />
        </Stack>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="tariff-chain-tariff-groups">
            {(provided) => (
              <Stack
                {...provided.droppableProps}
                ref={provided.innerRef}
                sx={{
                  height: tariffGroupsOnChain.length * 35,
                  rowGap: '5px',
                }}
              >
                {tariffGroupsOnChain.map((tariffGroup, index) => (
                  <Draggable
                    key={tariffGroup.uuid}
                    draggableId={tariffGroup.uuid}
                    index={index}
                  >
                    {(prov) => (
                      <Stack
                        key={tariffGroup.uuid}
                        ref={prov.innerRef}
                        {...prov.draggableProps}
                        direction="row"
                        gap={1}
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{
                          height: '30px',
                          '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                          },
                        }}
                      >
                        <Stack
                          direction="row"
                          gap={1}
                          sx={{ height: '30px', alignItems: 'center' }}
                        >
                          <Box
                            {...prov.dragHandleProps}
                            sx={{
                              p: '3px',
                              minWidth: 'max-content',
                              height: 'max-content',
                            }}
                          >
                            <DragHandleIcon />
                          </Box>
                          <Typography>{tariffGroup.name}</Typography>
                        </Stack>
                        <CloseIcon
                          sx={{ justifySelf: 'right' }}
                          onClick={() => {
                            onRemoveTariffGroup(index);
                          }}
                        />
                      </Stack>
                    )}
                  </Draggable>
                ))}
              </Stack>
            )}
          </Droppable>
        </DragDropContext>
        <Stack direction="row" gap={2} sx={{ width: '100%' }}>
          <AutocompleteFuzzy
            size="small"
            sx={{ backgroundColor: 'white' }}
            disabled={tariffGroupsForTypeLoading}
            value={selectedOption}
            options={tariffGroupsToAddOptions ?? []}
            matchSortOptions={{ keys: ['label'] }}
            renderOption={(props, option) => (
              <li {...props} key={option.value}>
                {option.label}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
                size="small"
              />
            )}
            onChange={(_, option) => {
              setSelectedOption(option);
            }}
            fullWidth
          />
          <Button
            onClick={onAddTariffGroup}
            sx={{ width: '200px' }}
            disabled={isNil(selectedOption)}
          >
            + Add Tariff Group
          </Button>
        </Stack>
        <Stack direction="row" justifyContent="flex-end" gap={2}>
          <Button
            variant="text"
            onClick={onClose}
            disabled={creating || updating}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={creating || updating}
          >
            Save
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default CreateOrEditTariffChainModal;
