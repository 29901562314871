// eslint-disable-next-line no-restricted-imports
import { FormControl, Grid, TextField, Typography } from '@mui/material';
import { type ReferenceNumberFormatCheckEntity } from '../../../../generated/graphql';

type LengthCheckConditionProps = {
  readonly check: ReferenceNumberFormatCheckEntity;
};

const LengthCheckCondition = ({ check }: LengthCheckConditionProps) => {
  return (
    <Grid container spacing={2} sx={{ marginBottom: 2 }}>
      <Grid item xs={12}>
        <Typography>
          <strong>Has Length Of:</strong>
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <FormControl sx={{ width: '100%' }}>
          <TextField disabled value={check.lengthOperand} size="small" />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default LengthCheckCondition;
