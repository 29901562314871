import { Add } from '@mui/icons-material';
import {
  Box,
  Button,
  Stack,
  Tab,
  Tabs,
  TextField,
  useTheme,
} from '@mui/material';
import {
  ColDef,
  FilterChangedEvent,
  GetRowIdFunc,
  GetRowIdParams,
  GridReadyEvent,
  SideBarDef,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { sentenceCase } from 'change-case';
import dayjs, { Dayjs } from 'dayjs';
import { isEmpty, isNil } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useStateRef from 'react-usestateref';
import { getPermissionsFlags } from 'shared/roles';
import { exhaustive } from 'shared/switch';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import {
  AppointmentCallStatus,
  AppointmentFragment,
  AppointmentTextStatus,
  GetAppointmentsQuery,
  PermissionResource,
  useContactsSimpleLazyQuery,
  useServicesLazyQuery,
} from '../../../../generated/graphql';
import { FilterModel } from '../../../orders/components/enums/order-filters';
import SelectedFilterButtonForToolbar from '../../../orders/components/selected-filter-button-for-toolbar';

import 'ag-grid-enterprise';
import AppointmentFilterField from '../../enums/appointment-filters';
import AppointmentsTableTabs from '../../types/appointments-table-tabs';
import { TextPreviewData } from '../../types/text-preview-data';
import { PhoneNumberData, UpdateRowPhoneNumber } from '../../types/types';
import AppointmentDatePicker from '../appointment-date-picker';
import CallAppointmentsButtonAndModal from '../call-appointments-button-and-modal';
import ScheduleAppointmentsModal from '../schedule-appointments-modal/schedule-appointments-modal';
import TextAppointmentsButtonAndModal from '../text-appointments-button-and-modal';
import MoreActionsButton from './more-actions-button';
import { Writable } from 'type-fest';

const SHOW_CALL_AND_TEXT_BUTTONS: string[] = [
  AppointmentsTableTabs.Unscheduled,
  AppointmentsTableTabs.Unconfirmed,
  AppointmentsTableTabs.RescheduleRequested,
];

const SHOW_MORE_ACTIONS: string[] = [
  AppointmentsTableTabs.Appointments,
  AppointmentsTableTabs.Unscheduled,
  AppointmentsTableTabs.Unconfirmed,
  AppointmentsTableTabs.RescheduleRequested,
];

const SHOW_SAVE_BUTTON: string[] = [
  AppointmentsTableTabs.Appointments,
  AppointmentsTableTabs.WithoutAppointments,
  AppointmentsTableTabs.WithoutContacts,
];

const SHOW_SCHEDULE_APPTS_BUTTON: string[] = [
  AppointmentsTableTabs.WithoutAppointments,
];

const useStyles = () => {
  const theme = useTheme();
  return {
    filterButton: {
      backgroundColor: theme.palette.primary.light,
      borderRadius: '4px',
    },
    menuText: {
      fontSize: '14px',
    },
    totalText: {
      ml: '4px',
      mb: '2px',
      color: 'black',
      fontSize: '15px',
    },
    filterTitle: {
      fontSize: '14px',
      ml: '3px',
    },
    filterValue: {
      fontSize: '14px',
      ml: '5px',
      fontWeight: 'bold',
    },
  };
};

type DefaultFilterTab<DefaultTabType> = {
  value: DefaultTabType;
  label: string;
  filterFunction: (
    appointments: AppointmentFragment[],
  ) => AppointmentFragment[];
  shouldShowCheckboxes: boolean;
};

// the type here will be an enum.
export type DefaultFilterTabsConfigs<DefaultTabType> = {
  defaultTab: DefaultTabType;
  baseTab: DefaultTabType; // This is the "parent" filter to base all searches off from
  tabs: Array<DefaultFilterTab<DefaultTabType>>;
};

type State<T> = {
  searchText: string;
  currentTab: DefaultFilterTab<T> | undefined;
  currentCursor: string | null | undefined;
  customFilterModelJson: FilterModel;
  currentFilterViewUuid: string | null;
  currentFilterViewName: string | null;
};

type AppointmentsTableProps<T> = {
  readonly appointmentsData: GetAppointmentsQuery | undefined;
  readonly columnDefinitions: ReadonlyArray<ColDef>;
  readonly filterColumns: ReadonlyArray<ColDef>;
  readonly pageSize: number;
  readonly defaultFilterTabsConfigs: DefaultFilterTabsConfigs<T>;
  readonly handleSavePhoneNumbers: (
    numbersToUpdate: PhoneNumberData[],
    refreshRowsCallback: (updates: UpdateRowPhoneNumber[]) => void,
  ) => void;
  readonly rowSelectionEnabled?: boolean;
  readonly selectedDate: Dayjs;
  readonly setSelectedDate: (date: Dayjs) => void;
};

export const AppointmentsTableWithFiltersAgGrid = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unnecessary-type-constraint
  DefaultFilterTabsType extends any,
>({
  appointmentsData,
  columnDefinitions,
  filterColumns,
  pageSize,
  defaultFilterTabsConfigs,
  handleSavePhoneNumbers,
  rowSelectionEnabled = false,
  selectedDate,
  setSelectedDate,
}: AppointmentsTableProps<DefaultFilterTabsType>) => {
  const styles = useStyles();
  const gridRef = useRef<AgGridReact>(null);
  const toolPanelVisibleRef = useRef(false);
  const [, setState, stateRef] = useStateRef<State<DefaultFilterTabsType>>({
    searchText: '',
    currentTab: defaultFilterTabsConfigs.tabs.find(
      (tab) => tab.value === defaultFilterTabsConfigs.defaultTab,
    ),
    currentCursor: null,
    customFilterModelJson: {},
    currentFilterViewName: null,
    currentFilterViewUuid: null,
  });
  const [searching, setSearching] = useState<boolean>(false);
  const [columnDefs, setColumnDefs] = useState<ColDef[]>(
    () => structuredClone(columnDefinitions) as Writable<ColDef[]>,
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unnecessary-type-constraint
  const [tabsNumberOfOrderData, setTabsNumberOfOrderData] = useState<
    Array<{ tab: DefaultFilterTabsType; numberOfOrders: number | undefined }>
  >([]);
  const [passOnRefetch, setPassOnRefetch] = useState(false);
  const [showAppointmentsSchedulingModal, setShowAppointmentsSchedulingModal] =
    useState(false);

  const [rowData, setRowData] = useState<AppointmentFragment[]>([]);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

  const [textPreviewData, setTextPreviewData] = useState<TextPreviewData[]>([]);

  /// /////////////////////////////////////////////////////////////////////////////
  // QUERIES
  /// /////////////////////////////////////////////////////////////////////////////

  const [queryServices] = useServicesLazyQuery();
  const [queryContacts] = useContactsSimpleLazyQuery();

  const { userPermissions } = useUserRoles();

  const { canWrite: canWriteAppointments } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Appointments,
  );

  /// /////////////////////////////////////////////////////////////////////////////
  // OVERALL GRID METHODS
  /// /////////////////////////////////////////////////////////////////////////////

  const handleRowSelectionChanged = () => {
    const selectedRows = gridRef.current?.api?.getSelectedRows() ?? [];
    const previewData = selectedRows?.map((row) => {
      const stopUuid = row.uuid;
      const consigneeName = row.shipmentConsigneeAddress.name;
      let appointmentString = '';
      const { appointmentTime } = row;
      const { endAppointmentTime } = row;
      if (!isNil(appointmentTime)) {
        appointmentString += `${dayjs(appointmentTime).format('hh:mm a')}`;
      }
      if (!isNil(appointmentTime) && !isNil(endAppointmentTime)) {
        appointmentString += ' - ';
      }
      if (!isNil(endAppointmentTime)) {
        appointmentString += `${dayjs(endAppointmentTime).format('hh:mm a')}`;
      }

      const textMessage = `Loading message...`;
      return {
        uuid: stopUuid,
        previewSelected: false,
        consigneeName,
        appointment: appointmentString,
        text: textMessage,
      } as TextPreviewData;
    });
    setTextPreviewData(previewData);
  };

  const applyAllFilters = () => {
    const filterModel = gridRef.current?.api?.getFilterModel() ?? {};
    const currentDefaultTab = stateRef.current.currentTab;
    let filteredAppointments =
      currentDefaultTab?.filterFunction(appointmentsData?.appointments ?? []) ??
      [];

    const applyFilterFromFilterModelToResult = (
      currentResult: AppointmentFragment[],
      filterField: AppointmentFilterField,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      filterModelValue: any,
    ): AppointmentFragment[] => {
      switch (filterField) {
        case AppointmentFilterField.APPOINTMENT_CONTACT: {
          return currentResult.filter(
            (appt) =>
              appt.contactPerson?.firstName
                ?.toLowerCase()
                .includes(filterModelValue.value.toLowerCase()) === true ||
              appt.contactPerson?.lastName
                ?.toLowerCase()
                .includes(filterModelValue.value.toLowerCase()) === true ||
              `${appt.contactPerson?.firstName?.toLowerCase()} ${appt.contactPerson?.lastName?.toLowerCase()}`.includes(
                filterModelValue.value.toLowerCase(),
              ),
          );
        }
        case AppointmentFilterField.SERVICE: {
          return currentResult.filter((appt) =>
            filterModelValue.values.some(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (value: any) =>
                value.actualValue ===
                appt.leg.shipment.standardShipmentFields?.service?.uuid,
            ),
          );
        }
        case AppointmentFilterField.TEXT_STATUS: {
          return currentResult.filter(
            (appt) =>
              (filterModelValue.value === AppointmentTextStatus.NotSent &&
                isNil(appt.appointmentTextStatus)) ||
              appt.appointmentTextStatus === filterModelValue.value,
          );
        }
        case AppointmentFilterField.CALL_STATUS: {
          return currentResult.filter(
            (appt) =>
              (filterModelValue.value === AppointmentCallStatus.NotCalled &&
                isNil(appt.appointmentCallStatus)) ||
              appt.appointmentCallStatus === filterModelValue.value,
          );
        }
        case AppointmentFilterField.CONSIGNEE: {
          return currentResult.filter((appt) =>
            appt.shipmentConsigneeAddress.name
              .toLowerCase()
              .includes(filterModelValue.value.toLowerCase()),
          );
        }
        case AppointmentFilterField.ADDRESS_TYPE: {
          return currentResult.filter(
            (appt) => appt.standardStopType === filterModelValue.value,
          );
        }
        case AppointmentFilterField.PHONE: {
          return currentResult.filter((appt) =>
            appt.contactPerson?.phone
              ?.toLowerCase()
              .includes(filterModelValue.value.toLowerCase()),
          );
        }
        case AppointmentFilterField.APPOINTMENT_REQUIRED: {
          if (filterModelValue.value === 'No') {
            return currentResult.filter((appt) => !appt.appointmentRequired);
          }
          if (filterModelValue.value === 'Yes') {
            return currentResult.filter((appt) => appt.appointmentRequired);
          }
          return currentResult;
        }
        case AppointmentFilterField.CUSTOMER: {
          return currentResult.filter(
            (appt) =>
              appt.leg.shipment.order?.billingPartyContact.uuid ===
              filterModelValue.value,
          );
        }
        default: {
          return exhaustive(filterField);
        }
      }
    };

    for (const key of Object.keys(filterModel)) {
      const value = filterModel[key];
      filteredAppointments = applyFilterFromFilterModelToResult(
        filteredAppointments,
        key as AppointmentFilterField,
        value,
      );
    }
    const searchText = stateRef.current.searchText.toLowerCase();
    if (!isEmpty(searchText)) {
      filteredAppointments = filteredAppointments.filter((appt) => {
        return (
          (!isNil(appt.leg.shipment.order) &&
            appt.leg.shipment.order.name.toLowerCase().includes(searchText)) ||
          (!isNil(appt.contactPerson) &&
            !isNil(appt.contactPerson.phone) &&
            appt.contactPerson?.phone?.toLowerCase().includes(searchText)) ||
          (!isNil(appt.leg.shipment.order) &&
            !isNil(
              appt.leg.shipment.order.standardOrderFields
                .shipperBillOfLadingNumber,
            ) &&
            appt.leg.shipment.order.standardOrderFields.shipperBillOfLadingNumber
              .toLowerCase()
              .includes(searchText)) ||
          appt.shipmentConsigneeAddress.name
            .toLowerCase()
            .includes(searchText) ||
          appt.address.city.toLowerCase().includes(searchText) ||
          appt.address.line1.toLowerCase().includes(searchText) ||
          appt.address.zip.toLowerCase().includes(searchText)
        );
      });
    }
    setRowData(filteredAppointments);
  };

  const getRowId = useMemo<GetRowIdFunc>(() => {
    return (params: GetRowIdParams) => {
      return params.data.uuid;
    };
  }, []);

  // KEEP
  const onGridReady = (params: GridReadyEvent) => {
    params.api.closeToolPanel();
    const filterToolPanel =
      gridRef.current?.api?.getToolPanelInstance('filters');
    filterToolPanel?.setFilterLayout(
      // Clone here because we don't know what AG Grid will do with this.
      structuredClone(filterColumns) as Writable<ColDef[]>,
    );
    filterToolPanel?.refresh();
  };

  // KEEP
  const closeFilterToolPanel = useCallback(() => {
    const filterToolPanel =
      gridRef.current?.api?.getToolPanelInstance('filters');
    filterToolPanel?.collapseFilters();
    // reset filters to all before closing.
    filterToolPanel?.refresh();
    filterToolPanel?.setFilterLayout(
      // Clone here because we don't know what AG Grid will do with this.
      structuredClone(filterColumns) as Writable<ColDef[]>,
    );
    gridRef.current?.api?.closeToolPanel();
  }, [filterColumns]);

  /// /////////////////////////////////////////////////////////////////////////////

  // KEEP
  const applyModel = useCallback((filterModel: FilterModel | null) => {
    if (!isNil(gridRef.current?.api)) {
      gridRef.current?.api?.setFilterModel(filterModel);
    }
  }, []);

  // KEEP
  const defaultColDef = useMemo(() => {
    return {
      autoHeight: true,
      wrapHeaderText: true,
      wrapText: true,
      resizable: false,
      suppressMenu: true,
      lockPinned: true,
    };
  }, []);

  // KEEP
  const sideBar = useMemo<
    SideBarDef | string | string[] | boolean | null
  >(() => {
    return {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
          toolPanelParams: {
            suppressExpandAll: false,
            suppressFilterSearch: false,
          },
        },
      ],
      defaultToolPanel: 'filters',
      position: 'left',
    };
  }, []);

  /// /////////////////////////////////////////////////////////////////////////////
  // USE EFFECT HOOKS
  /// /////////////////////////////////////////////////////////////////////////////

  // KEEP
  // This closes the toolpanel if we click outside the filter toolpanel since AG Grid doesn't have an API for this
  useEffect(() => {
    const handleDocumentClick = (event: Event) => {
      const gridApi = gridRef.current?.api;
      const isToolPanelClicked = (event.target as Element).closest(
        '.ag-filter-toolpanel',
      );

      if (gridApi && !isToolPanelClicked && toolPanelVisibleRef.current) {
        closeFilterToolPanel();
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [closeFilterToolPanel]);

  useEffect(() => {
    // pass on refreshing on refetch to avoid refreshing state of phone
    // text fields that are in progress
    if (!passOnRefetch) {
      applyAllFilters();
      setPassOnRefetch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentsData?.appointments]);

  useEffect(() => {
    const fetchDataAndPopulateColumnDefs = async () => {
      // Fetch data from query 1
      const services = await queryServices();
      const contacts = await queryContacts({
        fetchPolicy: 'cache-first',
      });

      setColumnDefs((prevColumnDefs) => {
        // SERVICES
        let prevIndex = prevColumnDefs.findIndex(
          (def) => def.field === AppointmentFilterField.SERVICE,
        );
        const updatedColumnDefs = [...prevColumnDefs];

        const updatedColumnDefServices = updatedColumnDefs[prevIndex];
        if (
          !isNil(updatedColumnDefServices) &&
          !isNil(updatedColumnDefServices.filterParams)
        ) {
          updatedColumnDefServices.filterParams.values =
            services.data?.services.map((service) => {
              return {
                actualValue: service.uuid,
                displayValue: sentenceCase(service.name),
              };
            }) ?? [];
          updatedColumnDefs[prevIndex] = updatedColumnDefServices;
        }
        // CUSTOMER
        prevIndex = prevColumnDefs.findIndex(
          (def) => def.field === AppointmentFilterField.CUSTOMER,
        );
        const updatedColumnDefContacts = updatedColumnDefs[prevIndex];
        if (
          !isNil(updatedColumnDefContacts) &&
          !isNil(updatedColumnDefContacts.filterParams)
        ) {
          updatedColumnDefContacts.filterParams.values =
            contacts.data?.contacts.map((contact) => {
              return {
                actualValue: contact.uuid,
                displayValue: contact.displayName,
              };
            }) ?? [];
          updatedColumnDefs[prevIndex] = updatedColumnDefContacts;
        }
        return updatedColumnDefs;
      });
    };
    fetchDataAndPopulateColumnDefs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const toolPanel = gridRef.current?.api?.getToolPanelInstance('filters');
    toolPanel?.setFilterLayout(
      // Clone here because we don't know what AG Grid will do with this.
      structuredClone(filterColumns) as Writable<ColDef[]>,
    );
  }, [filterColumns]);

  useEffect(() => {
    setColumnDefs(
      // Clone here because we don't know what AG Grid will do with this.
      structuredClone(columnDefinitions) as Writable<ColDef[]>,
    );
  }, [columnDefinitions]);

  // TODO: Remove
  useEffect(() => {
    if (isEmpty(stateRef.current.customFilterModelJson)) {
      setSearching(true);
      applyModel(null);
      setSearching(false);
    } else {
      applyModel(stateRef.current.customFilterModelJson);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRef.current.customFilterModelJson]);

  useEffect(() => {
    setSearching(true);
    setColumnDefs((prevColumnDefs) => {
      const prevIndex = prevColumnDefs.findIndex(
        (def) => !isNil(def.checkboxSelection),
      );
      const updatedColumnDefs = [...prevColumnDefs];
      const updatedColumnDefCheckbox = updatedColumnDefs[prevIndex];
      if (
        (prevIndex === -1 || isNil(updatedColumnDefCheckbox)) &&
        !isNil(stateRef.current.currentTab) &&
        stateRef.current.currentTab.shouldShowCheckboxes
      ) {
        return [
          {
            field: 'checkbox',
            headerName: '',
            headerCheckboxSelection: true,
            checkboxSelection: true,
            maxWidth: 50,
            lockPosition: 'left',
          },
          ...updatedColumnDefs,
        ];
      }
      if (
        !isNil(updatedColumnDefCheckbox) &&
        !isNil(updatedColumnDefCheckbox.checkboxSelection)
      ) {
        if (stateRef.current.currentTab?.shouldShowCheckboxes === false) {
          updatedColumnDefs.splice(prevIndex, 1);
        } else {
          updatedColumnDefCheckbox.checkboxSelection =
            stateRef.current.currentTab?.shouldShowCheckboxes;
          updatedColumnDefCheckbox.headerCheckboxSelection =
            stateRef.current.currentTab?.shouldShowCheckboxes;
          updatedColumnDefs[prevIndex] = updatedColumnDefCheckbox;
        }
      }
      return updatedColumnDefs;
    });
    applyAllFilters();
    setSearching(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRef.current.currentTab]);

  const getNumberOfOrdersForTab = async (
    tab: DefaultFilterTabsType,
    tabFilterFunction: (
      appointments: AppointmentFragment[],
    ) => AppointmentFragment[],
  ) => {
    const res = tabFilterFunction(appointmentsData?.appointments ?? []);
    setTabsNumberOfOrderData((prevState) => {
      const newRecord = [...prevState];
      const existingIndex = newRecord.findIndex((record) => record.tab === tab);
      if (existingIndex !== -1) {
        newRecord.splice(existingIndex, 1);
      }
      newRecord.push({ tab, numberOfOrders: res.length });
      return newRecord;
    });
  };

  const recalculateNumberOfOrdersPerTab = () => {
    defaultFilterTabsConfigs.tabs.map(async (tab) => {
      return getNumberOfOrdersForTab(tab.value, tab.filterFunction);
    });
  };

  // TODO: potentially change
  useEffect(() => {
    recalculateNumberOfOrdersPerTab();
    // TODO: DONT DELETE MAY ADD BACK LATER
    // savedFilterViewsData?.getSavedFilterViews.map((view) => {
    //   const variables = getFetchOrdersVariables({
    //     defaultTabsConfigs: defaultFilterTabsConfigs,
    //     filterModel: JSON.parse(view.filterModelJson),
    //     currentOrdersTab: defaultFilterTabsConfigs.baseTab,
    //   });
    //   return getNumberOfOrdersForTab(view.uuid, variables);
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilterTabsConfigs, appointmentsData]);

  /// /////////////////////////////////////////////////////////////////////////////
  // INDIVIDUAL FILTER HANDLERS
  /// /////////////////////////////////////////////////////////////////////////////

  // KEEP
  const destroyFilter = useCallback((field: AppointmentFilterField) => {
    gridRef.current?.api?.destroyFilter(field);
  }, []);

  // KEEP
  const handleDeleteFilter = async (field: AppointmentFilterField) => {
    // reset the filter
    destroyFilter(field);

    // collapse that filter in the panel.
    const toolPanel = gridRef.current?.api?.getToolPanelInstance('filters');
    toolPanel?.collapseFilters([field]);
  };

  // KEEP
  const handleClickSelectedFilter = async (field: AppointmentFilterField) => {
    // open the toolpanel and show that filter
    const toolPanel = gridRef.current?.api?.getToolPanelInstance('filters');
    toolPanel?.expandFilters([field]);
    toolPanel?.setFilterLayout([
      filterColumns.find((colDef) => colDef.field === field) ?? {},
    ]);
    gridRef.current?.api?.openToolPanel('filters');
  };

  // KEEP
  // callback which AG Grid calls when a filter changes.
  const handleFilterChanged = (_event: FilterChangedEvent) => {
    applyAllFilters();
  };

  const handleSearch = () => {
    setSearching(true);
    applyAllFilters();
    setSearching(false);
  };

  // update the rows without triggering an entire refresh because then the cells will also refresh
  const updateRowPhoneNumbersCallback = (updates: UpdateRowPhoneNumber[]) => {
    for (const update of updates) {
      if (!isNil(update.stopUuid)) {
        setRowData((prevRowData) => {
          const prevIndex = prevRowData.findIndex(
            (row) => row.uuid === update.stopUuid,
          );
          const updatedRowData = [...prevRowData];
          const rowToUpdate = updatedRowData[prevIndex];

          if (!isNil(rowToUpdate)) {
            rowToUpdate.contactPerson = isNil(rowToUpdate.contactPerson)
              ? update.contactPerson
              : update.contactPerson;
            updatedRowData[prevIndex] = rowToUpdate;
          }
          return updatedRowData;
        });
      }
    }
  };

  useEffect(() => {
    applyAllFilters();
    recalculateNumberOfOrdersPerTab();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rowData.length,
    passOnRefetch,
    isContextMenuOpen,
    showAppointmentsSchedulingModal,
  ]);

  const handleClickSave = async () => {
    if (!isNil(gridRef.current?.api)) {
      const params = { columns: ['Phone'] };
      const instances = gridRef.current?.api?.getCellRendererInstances(
        params,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ) as any[];
      const numbersToUpdate: PhoneNumberData[] = instances
        .map((instance) => instance.getPhoneNumberInfo())
        .filter((info) => !isNil(info.stopUuid));

      setPassOnRefetch(true);
      handleSavePhoneNumbers(numbersToUpdate, updateRowPhoneNumbersCallback);
    }
  };
  const selectedStops: AppointmentFragment[] =
    gridRef.current?.api?.getSelectedRows() ?? [];
  const selectedUuids = selectedStops.map((stop) => stop.uuid);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      {showAppointmentsSchedulingModal && (
        <ScheduleAppointmentsModal
          initialRouteUuid={null}
          open={showAppointmentsSchedulingModal}
          setIsOpen={setShowAppointmentsSchedulingModal}
          planningDate={selectedDate.toDate()}
        />
      )}
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          mb: '10px',
          justifyContent: 'space-between',
          pt: '5px',
          paddingLeft: 2,
          paddingRight: 2,
          flexWrap: 'wrap',
          gap: '10px',
        }}
      >
        <Stack direction="row" spacing={2}>
          <TextField
            size="small"
            label="Search appointments"
            InputProps={{ style: { backgroundColor: 'white' } }}
            value={stateRef.current.searchText}
            sx={{ width: '400px' }}
            onKeyDown={async (e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
            onChange={(e) => {
              setState((prevState: State<DefaultFilterTabsType>) => {
                return {
                  ...prevState,
                  searchText: e.target.value,
                  currentCursor: null,
                };
              });
            }}
          />
          <Button
            variant="contained"
            disabled={searching}
            sx={{ mb: 'auto' }}
            onClick={handleSearch}
          >
            Search
          </Button>
        </Stack>
        <AppointmentDatePicker
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      </Box>
      <Box
        sx={{
          border: '1px solid',
          borderColor: 'divider',
          mb: '10px',
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginLeft: 2,
          marginRight: 2,
        }}
      >
        <Box
          sx={{
            flex: '1 0 100%',
            overflowX: 'auto',
          }}
        >
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={stateRef.current.currentTab?.value}
            onChange={(_, val) => {
              const currentDefaultTab = defaultFilterTabsConfigs.tabs.find(
                (tab) => tab.value === val,
              );
              setState((prevState) => {
                return {
                  ...prevState,
                  currentTab: currentDefaultTab,
                  currentCursor: null,
                };
              });
            }}
          >
            {defaultFilterTabsConfigs.tabs.map((tabConfig) => (
              <Tab
                key={tabConfig.label}
                value={tabConfig.value}
                label={`${tabConfig.label} (${
                  tabsNumberOfOrderData.find(
                    (data) => data.tab === tabConfig.value,
                  )?.numberOfOrders ?? '-'
                })`}
              />
            ))}
          </Tabs>
        </Box>
      </Box>
      <Stack
        direction="row"
        spacing={2}
        justifyItems="center"
        sx={{
          paddingLeft: 2,
          paddingRight: 2,
          marginBottom: '10px',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            border: '1px solid',
            borderColor: 'divider',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '10px',
            p: '7px',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
            }}
          >
            <Button
              size="small"
              startIcon={<Add />}
              sx={styles.filterButton}
              variant="outlined"
              onClick={(_e) => {
                // sort the filters before opening (hacky but works)
                gridRef.current?.api
                  ?.getToolPanelInstance('filters')
                  ?.setFilterLayout(
                    // Clone here because we don't know what AG Grid will do with this.
                    structuredClone(filterColumns) as Writable<ColDef[]>,
                  );
                gridRef.current?.api?.openToolPanel('filters');
              }}
            >
              Filter
            </Button>
            {Object.keys(gridRef.current?.api?.getFilterModel() ?? {}).map(
              (key) => {
                return (
                  <SelectedFilterButtonForToolbar
                    key={key}
                    prependText={undefined}
                    filterModel={gridRef.current?.api?.getFilterModel()}
                    keyName={key}
                    handleDelete={async () =>
                      handleDeleteFilter(key as AppointmentFilterField)
                    }
                    handleSelect={async () =>
                      handleClickSelectedFilter(key as AppointmentFilterField)
                    }
                  />
                );
              },
            )}
          </Box>
        </Box>
        {!isNil(stateRef.current.currentTab) &&
          SHOW_CALL_AND_TEXT_BUTTONS.includes(
            String(stateRef.current.currentTab.value),
          ) && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '2px',
                alignItems: 'center',
              }}
            >
              <CallAppointmentsButtonAndModal
                selectedUuids={selectedUuids}
                rowData={rowData}
              />
              <TextAppointmentsButtonAndModal
                textPreviewData={textPreviewData}
              />
            </Box>
          )}
        {!isNil(stateRef.current.currentTab) &&
          SHOW_SAVE_BUTTON.includes(
            String(stateRef.current.currentTab.value),
          ) && (
            <Button
              sx={{ minWidth: '130px' }}
              variant="contained"
              disabled={!canWriteAppointments}
              onClick={handleClickSave}
            >
              Save
            </Button>
          )}
        {!isNil(stateRef.current.currentTab) &&
          SHOW_SCHEDULE_APPTS_BUTTON.includes(
            String(stateRef.current.currentTab.value),
          ) && (
            <Button
              sx={{
                whiteSpace: 'nowrap',
                textAlign: 'center',
                minWidth: '130px',
              }}
              variant="contained"
              disabled={!canWriteAppointments}
              onClick={() => {
                setShowAppointmentsSchedulingModal(true);
              }}
            >
              Schedule appts
            </Button>
          )}
        {!isNil(stateRef.current.currentTab) &&
          SHOW_MORE_ACTIONS.includes(
            String(stateRef.current.currentTab.value),
          ) && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '2px',
                alignItems: 'center',
                p: 0,
              }}
            >
              <MoreActionsButton
                selectedStops={selectedStops}
                setIsContextMenuOpen={setIsContextMenuOpen}
                isContextMenuOpen={isContextMenuOpen}
              />
            </Box>
          )}
      </Stack>
      <div
        className="appointments ag-theme-material ag-non-compact"
        style={{
          width: '100%',
          marginLeft: '15px',
          flex: 1,
          minHeight: 0,
          overflow: 'auto',
        }}
      >
        <AgGridReact
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ref={gridRef}
          rowMultiSelectWithClick
          columnDefs={columnDefs}
          rowSelection={rowSelectionEnabled ? 'multiple' : undefined}
          defaultColDef={defaultColDef}
          rowData={rowData}
          cacheBlockSize={pageSize}
          domLayout="autoHeight"
          sideBar={sideBar}
          getRowId={getRowId}
          gridOptions={{
            defaultColDef: {
              minWidth: 500,
              resizable: true,
            },
          }}
          onGridReady={onGridReady}
          onFilterChanged={handleFilterChanged}
          onSelectionChanged={handleRowSelectionChanged}
          onToolPanelVisibleChanged={() => {
            toolPanelVisibleRef.current = !toolPanelVisibleRef.current;
          }}
          onGridColumnsChanged={(event) => {
            event.columnApi.autoSizeAllColumns();
          }}
        />
      </div>
    </Box>
  );
};
