import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import React, { type FunctionComponent, useEffect, useState } from 'react';
import { pounds } from 'shared/units/rates';
import { isValidNonNegativeInteger } from '../../../../utils';
import { useHasUnsavedChanges } from '../../../common/react-hooks/use-has-unsaved-changes';
import {
  type AllSchedulingZoneFieldsFragment,
  useArchiveSchedulingRegionMutation,
  useRestoreSchedulingRegionMutation,
  useSchedulingRegionQuery,
  useUpdateSchedulingRegionMutation,
} from '../../../generated/graphql';
import { SchedulingZoneDialog } from './scheduling-zone-dialog';
import { SchedulingZoneOverviewRow } from './scheduling-zone-overview-row';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    background: 'white',
    width: '100%',
    p: 2,
  },
  sectionHeading: {
    fontSize: '14.5px',
    fontWeight: 500,
  },
};

type SchedulingRegionFormData = {
  name: string;
  dailyCapacityPieces: string | null;
  dailyCapacityStops: string | null;
  dailyCapacityWeight: string | null;
};

type SchedulingRegionFormErrors = {
  [key in keyof SchedulingRegionFormData]?: string;
};

type EditSchedulingRegionProps = {
  readonly id: string;
  readonly mode: 'view' | 'edit';
};

export const EditSchedulingRegion: FunctionComponent<
  EditSchedulingRegionProps
> = ({ id, mode }) => {
  const {
    hasUnsavedChanges,
    triggerHasUnsavedChanges,
    resetHasUnsavedChanges,
  } = useHasUnsavedChanges();
  const [globalErrorMessage, setGlobalErrorMessage] = useState<string | null>(
    null,
  );
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [editingZone, setEditingZone] =
    useState<AllSchedulingZoneFieldsFragment | null>(null);
  const [showAddZone, setShowAddZone] = useState(false);

  const {
    data: queryData,
    loading,
    refetch,
  } = useSchedulingRegionQuery({
    fetchPolicy: 'cache-and-network',
    variables: { id },
  });
  const [update, { loading: updating }] = useUpdateSchedulingRegionMutation({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (
        data.updateSchedulingRegion.__typename ===
        'UpdateSchedulingRegionSuccessOutput'
      ) {
        resetHasUnsavedChanges();
        setSnackbarMessage('Scheduling region updated');
      } else {
        setGlobalErrorMessage(data.updateSchedulingRegion.message);
      }
    },
  });
  const [archive, { loading: archiving }] = useArchiveSchedulingRegionMutation({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (
        data.archiveSchedulingRegion.__typename ===
        'UpdateSchedulingRegionSuccessOutput'
      ) {
        resetHasUnsavedChanges();
        setSnackbarMessage('Scheduling region archived');
      } else {
        setGlobalErrorMessage(data.archiveSchedulingRegion.message);
      }
    },
  });
  const [restore, { loading: restoring }] = useRestoreSchedulingRegionMutation({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (
        data.restoreSchedulingRegion.__typename ===
        'UpdateSchedulingRegionSuccessOutput'
      ) {
        resetHasUnsavedChanges();
        setSnackbarMessage('Scheduling region restored');
      } else {
        setGlobalErrorMessage(data.restoreSchedulingRegion.message);
      }
    },
  });

  const [formData, setFormData] = useState<SchedulingRegionFormData>({
    name: '',
    dailyCapacityPieces: null,
    dailyCapacityStops: null,
    dailyCapacityWeight: null,
  });
  const [formErrors, setFormErrors] = useState<SchedulingRegionFormErrors>({});

  useEffect(() => {
    setFormData({
      name: queryData?.schedulingRegion.name ?? '',
      dailyCapacityPieces:
        queryData?.schedulingRegion.dailyCapacityPieces?.toString() ?? null,
      dailyCapacityStops:
        queryData?.schedulingRegion.dailyCapacityStops?.toString() ?? null,
      dailyCapacityWeight:
        queryData?.schedulingRegion.dailyCapacityWeight
          ?.in(pounds)
          .amount.toString() ?? null,
    });
  }, [queryData]);

  const onSave = () => {
    setGlobalErrorMessage(null);
    const newErrors: SchedulingRegionFormErrors = {};
    if (formData.name.length === 0) {
      newErrors.name = 'A name is required';
    }
    const dailyCapacityPieces = formData.dailyCapacityPieces?.trim();
    if (
      !isNil(dailyCapacityPieces) &&
      dailyCapacityPieces.length > 0 &&
      !isValidNonNegativeInteger(dailyCapacityPieces)
    ) {
      newErrors.dailyCapacityPieces = dailyCapacityPieces?.includes('.')
        ? 'Please enter a whole number for pieces'
        : 'Please enter a valid number of pieces';
    }
    const dailyCapacityStops = formData.dailyCapacityStops?.trim();
    if (
      !isNil(dailyCapacityStops) &&
      dailyCapacityStops.length > 0 &&
      !isValidNonNegativeInteger(dailyCapacityStops)
    ) {
      newErrors.dailyCapacityStops = dailyCapacityStops?.includes('.')
        ? 'Please enter a whole number for stops'
        : 'Please enter a valid number of stops';
    }
    const dailyCapacityWeight = formData.dailyCapacityWeight?.trim();
    if (
      !isNil(dailyCapacityWeight) &&
      dailyCapacityWeight.length > 0 &&
      !isValidNonNegativeInteger(dailyCapacityWeight)
    ) {
      newErrors.dailyCapacityWeight = dailyCapacityWeight?.includes('.')
        ? 'Please enter a whole number'
        : 'Please enter a valid weight';
    }
    setFormErrors(newErrors);
    if (!isEmpty(newErrors)) {
      return;
    }

    update({
      variables: {
        input: {
          id,
          name: formData.name,
          dailyCapacityPieces:
            isNil(dailyCapacityPieces) || dailyCapacityPieces.length === 0
              ? null
              : Number(dailyCapacityPieces),
          dailyCapacityStops:
            isNil(dailyCapacityStops) || dailyCapacityStops.length === 0
              ? null
              : Number(dailyCapacityStops),
          dailyCapacityWeight:
            isNil(formData.dailyCapacityWeight) ||
            formData.dailyCapacityWeight.length === 0
              ? null
              : pounds(Number(formData.dailyCapacityWeight)),
        },
      },
    });
  };

  const onToggleArchive = () => {
    if (!isNil(queryData)) {
      if (queryData.schedulingRegion.isArchived) {
        restore({ variables: { id } });
      } else {
        archive({ variables: { id } });
      }
    }
  };

  if (loading) {
    return <CircularProgress sx={{ m: 3 }} />;
  }

  if (isNil(queryData)) {
    return (
      <Typography color="error" mt={3} ml={3}>
        {isNil(globalErrorMessage)
          ? 'An unknown error occurred'
          : globalErrorMessage}
      </Typography>
    );
  }

  return (
    <>
      <Box>
        {!isNil(globalErrorMessage) && (
          <Typography color="error" mt={3} ml={3}>
            {globalErrorMessage}
          </Typography>
        )}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          p={3}
        >
          <FormControl
            required
            disabled={mode === 'view'}
            error={'name' in formErrors}
            sx={{ width: 200 }}
          >
            <TextField
              id="scheduling-region-name"
              label="Region name"
              value={formData.name}
              error={'name' in formErrors}
              size="small"
              sx={{ width: '280px' }}
              inputProps={{
                sx: { py: '6.75px' },
              }}
              onChange={({ target }) => {
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  name: target.value,
                }));
                setFormErrors(({ name: _ignored, ...prevFormErrors }) => ({
                  ...prevFormErrors,
                }));
                triggerHasUnsavedChanges();
              }}
            />
            {'name' in formErrors && (
              <FormHelperText error>{formErrors.name}</FormHelperText>
            )}
          </FormControl>
          <Button
            variant="contained"
            disabled={!hasUnsavedChanges || loading || updating}
            onClick={onSave}
          >
            Save
          </Button>
        </Stack>
        <Divider sx={{ borderBottomWidth: '2px' }} />
        <Box sx={styles.container}>
          <Typography sx={styles.sectionHeading}>Capacity</Typography>
          <Stack direction="row" alignItems="center" gap={2} mt={2}>
            <FormControl
              disabled={mode === 'view'}
              error={'dailyCapacityStops' in formErrors}
            >
              <TextField
                id="scheduling-region-capacity-stops"
                label="Stops"
                value={formData.dailyCapacityStops ?? ''}
                error={'dailyCapacityStops' in formErrors}
                size="small"
                sx={{ width: '85px', mr: 3 }}
                onChange={({ target }) => {
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    dailyCapacityStops: target.value,
                  }));
                  setFormErrors(
                    ({ dailyCapacityStops: _ignored, ...prevFormErrors }) => ({
                      ...prevFormErrors,
                    }),
                  );
                  triggerHasUnsavedChanges();
                }}
              />
            </FormControl>
            <FormControl
              disabled={mode === 'view'}
              error={'dailyCapacityPieces' in formErrors}
            >
              <TextField
                id="scheduling-region-capacity-pieces"
                label="Pieces"
                value={formData.dailyCapacityPieces ?? ''}
                error={'dailyCapacityPieces' in formErrors}
                size="small"
                sx={{ width: '85px', mr: 3 }}
                onChange={({ target }) => {
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    dailyCapacityPieces: target.value,
                  }));
                  setFormErrors(
                    ({ dailyCapacityPieces: _ignored, ...prevFormErrors }) => ({
                      ...prevFormErrors,
                    }),
                  );
                  triggerHasUnsavedChanges();
                }}
              />
            </FormControl>
            <FormControl
              disabled={mode === 'view'}
              error={'dailyCapacityWeight' in formErrors}
            >
              <TextField
                id="scheduling-region-capacity-weight"
                label="Weight"
                value={formData.dailyCapacityWeight ?? ''}
                error={'dailyCapacityWeight' in formErrors}
                size="small"
                sx={{ width: '85px' }}
                onChange={({ target }) => {
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    dailyCapacityWeight: target.value,
                  }));
                  setFormErrors(
                    ({ dailyCapacityWeight: _ignored, ...prevFormErrors }) => ({
                      ...prevFormErrors,
                    }),
                  );
                  triggerHasUnsavedChanges();
                }}
              />
            </FormControl>
            <Typography variant="body1" color="text.secondary">
              pounds
            </Typography>
          </Stack>
          {'dailyCapacityStops' in formErrors && (
            <FormHelperText error>
              {formErrors.dailyCapacityStops}
            </FormHelperText>
          )}
          {'dailyCapacityPieces' in formErrors && (
            <FormHelperText error>
              {formErrors.dailyCapacityPieces}
            </FormHelperText>
          )}
          {'dailyCapacityWeight' in formErrors && (
            <FormHelperText error>
              {formErrors.dailyCapacityWeight}
            </FormHelperText>
          )}
        </Box>
        <Divider />
        <Box sx={styles.container}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="start"
            mb={1}
          >
            <Typography sx={styles.sectionHeading}>
              Scheduling zones with availability
            </Typography>
            <Button
              variant="contained"
              disabled={mode === 'view'}
              onClick={() => {
                setShowAddZone(true);
              }}
            >
              Add
            </Button>
          </Stack>
          {queryData.schedulingRegion.zones.length > 0 && (
            <Stack direction="row" gap="20px" mb={1}>
              <Typography
                variant="h4"
                sx={{
                  fontSize: '15px',
                  fontWeight: 500,
                  width: '260px',
                  color: 'text.secondary',
                }}
              >
                Time
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  fontSize: '15px',
                  fontWeight: 500,
                  width: '260px',
                  color: 'text.secondary',
                }}
              >
                Locations
              </Typography>
            </Stack>
          )}
          {queryData.schedulingRegion.zones.map((zone) => (
            <Box
              key={zone.id}
              sx={{
                borderTopWidth: '1px',
                borderTopStyle: 'solid',
                borderColor: 'divider',
                py: '13px',
              }}
            >
              <SchedulingZoneOverviewRow
                zone={zone}
                onEdit={() => {
                  setEditingZone(zone);
                }}
              />
            </Box>
          ))}
        </Box>
        <Divider />
        {!isNil(onToggleArchive) && (
          <Box sx={styles.container} gap={2}>
            <Box>
              <Typography sx={styles.sectionHeading}>
                {queryData.schedulingRegion.isArchived
                  ? 'Un-archive '
                  : 'Archive '}
                this scheduling region
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {queryData.schedulingRegion.isArchived
                  ? 'This region is archived and is not available for scheduling.'
                  : 'Archiving this region will make it unavailable for scheduling. Existing routes will not be affected.'}
              </Typography>
            </Box>
            {/* This wrapping box is here to keep the button from being stretched to full width. */}
            <Box>
              <Button
                variant="outlined"
                color={
                  queryData.schedulingRegion.isArchived ? undefined : 'error'
                }
                disabled={archiving || restoring}
                onClick={onToggleArchive}
              >
                {queryData.schedulingRegion.isArchived
                  ? 'Un-archive'
                  : 'Archive'}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      <Snackbar
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!isNil(snackbarMessage)}
        onClose={() => {
          setSnackbarMessage(null);
        }}
      >
        <Alert severity="success">{snackbarMessage}</Alert>
      </Snackbar>
      <SchedulingZoneDialog
        schedulingRegionId={id}
        zone={editingZone}
        open={!isNil(editingZone) || showAddZone}
        onSaved={() => {
          refetch();
          setShowAddZone(false);
          setEditingZone(null);
        }}
        onClose={() => {
          setShowAddZone(false);
          setEditingZone(null);
        }}
      />
    </>
  );
};
