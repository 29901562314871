import { Add, Clear } from '@mui/icons-material';
import {
  TextField,
  FormControl,
  InputAdornment,
  Button,
  IconButton,
} from '@mui/material';
import React from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import { updateOneContactValues } from '../redux/contact-values-slice';

const ReferenceNumberLabels = ({
  disabled,
  contactUuid,
  referenceNumberLabels,
}: {
  readonly disabled: boolean;
  readonly contactUuid: string;
  readonly referenceNumberLabels: string[];
}) => {
  const dispatch = useAppDispatch();

  const handleChange = (index: number, value: string) => {
    dispatch(
      updateOneContactValues({
        id: contactUuid,
        changes: {
          referenceNumberLabels: referenceNumberLabels.map((label, idx) =>
            idx === index ? value : label,
          ),
        },
      }),
    );
  };

  const handleAdd = () => {
    dispatch(
      updateOneContactValues({
        id: contactUuid,
        changes: {
          referenceNumberLabels: [...referenceNumberLabels, ''],
        },
      }),
    );
  };

  const handleDelete = (index: number) => {
    dispatch(
      updateOneContactValues({
        id: contactUuid,
        changes: {
          referenceNumberLabels: referenceNumberLabels.filter(
            (_, idx) => idx !== index,
          ),
        },
      }),
    );
  };

  return (
    <FormControl>
      {referenceNumberLabels.map((label, index) => (
        <TextField
          // eslint-disable-next-line react/no-array-index-key
          key={`ref-number-label-${index}`}
          disabled={disabled}
          size="small"
          label={`Reference Number Label ${index + 1}`}
          value={label}
          sx={{ mb: '10px', minWidth: '400px' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  disabled={disabled}
                  onClick={() => {
                    handleDelete(index);
                  }}
                >
                  <Clear />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            handleChange(index, e.target.value);
          }}
        />
      ))}
      <Button
        sx={{ justifyContent: 'flex-start' }}
        startIcon={<Add />}
        disabled={disabled}
        onClick={handleAdd}
      >
        Add Label
      </Button>
    </FormControl>
  );
};

export default ReferenceNumberLabels;
