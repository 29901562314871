import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Checkbox, Typography } from '@mui/material';
import {
  type CellClassParams,
  type ColDef,
  type ICellRendererParams,
  type ValueGetterParams,
} from 'ag-grid-community';
import { capitalCase, sentenceCase } from 'change-case';
import currency from 'currency.js';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { useRef } from 'react';
import { filterNotNil } from 'shared/array';
import { getAppointmentTimeString } from 'shared/copy';
import { shallow } from 'zustand/shallow';
import { chooseForegroundColor } from '../../../common/utils/colors';
import { transformDateStringToSpecifiedFormat } from '../../../common/utils/prettyPrintUtils';
import {
  type DispatchTableColorFragment,
  DispatchTableField,
  ShipmentType,
  type StopOnRouteFragment,
  StopStatus,
} from '../../../generated/graphql';
import {
  getDispatchHeaderCopy,
  inboundStopTypes,
  outboundStopTypes,
} from '../../ag-grid/dispatch-stops/constants';
import TagsCell from '../../orders/components/ag-grid-cell-components/tags-cell-component';
import Paperwork from '../../orders/components/paperwork';
import NotesComponent from '../../orders/components/standard/components/notes-component';
import useDispatchStore from '../dispatch-store';
import {
  getDispatchTableColorForStop,
  getStopDetailsStatusIcon,
} from '../utils';
import RouteStopCardHoverMenu from './components/route-stop-card-hover-menu';
import RouteStopCardPopup from './components/route-stop-card-popup';

export type TableStopOnRoute = StopOnRouteFragment & {
  // The index of the stop in the route (before filtering).
  index: number;
};

type StopIndexCellRendererProps = {
  readonly stop: TableStopOnRoute;
  readonly isMapView: boolean;
};

const StopIndexCellRenderer = ({
  stop,
  isMapView,
}: StopIndexCellRendererProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isHovered, showPopup, isChecked] = useDispatchStore(
    (state) => [
      state.hoveredStopUuid === stop.uuid,
      state.hoveredStopUuid === stop.uuid &&
        !state.arrangeStopsAndRoutesVertically &&
        !isMapView &&
        !state.showMap,
      state.selectedStopsForBulkAction.some((s) => s.uuid === stop.uuid),
    ],
    shallow,
  );
  const { index } = stop;
  const ordinal = index + 1;

  return (
    <Box ref={ref} display="flex" alignItems="center" justifyContent="center">
      <RouteStopCardPopup
        open={showPopup}
        anchorRef={ref}
        stop={stop}
        idx={index}
      />
      {!isHovered && !isChecked && (
        <span style={{ marginLeft: ordinal < 10 ? 4 : undefined }}>
          {ordinal}
        </span>
      )}
      {(isHovered || isChecked) && (
        <Checkbox
          checked={isChecked}
          sx={{
            '& .MuiSvgIcon-root': { fontSize: 18 },
            padding: 0,
          }}
          color="primary"
        />
      )}
    </Box>
  );
};

export const getOrderHawbColumn = (
  setOpenedOrderUuid: ((uuid: string | undefined) => void) | undefined,
): ColDef => {
  return {
    field: DispatchTableField.OrderName,
    hide: false,
    autoHeight: true,
    pinned: 'left',
    lockPinned: true,
    headerName: getDispatchHeaderCopy(DispatchTableField.OrderName),
    cellRenderer: (params: ICellRendererParams<TableStopOnRoute>) => {
      return (
        <Button
          variant="text"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'start',
            color: 'black',
            gap: 1,
            p: 0,
            fontWeight: 'inherit',
            '&:hover': {
              background: 'none',
              textDecoration: 'underline',
            },
          }}
          onClick={() => {
            setOpenedOrderUuid?.(params.data?.shipment?.order?.uuid);
          }}
        >
          <Typography
            variant="caption"
            sx={{ fontSize: '11px', fontWeight: 'inherit' }}
          >
            {isNil(params.data)
              ? null
              : params.data?.shipment?.order?.standardOrderFields
                  ?.shipperBillOfLadingNumber}
          </Typography>
        </Button>
      );
    },
  };
};

export const getEtaColumn = (): ColDef => {
  return {
    field: DispatchTableField.Eta,
    headerName: 'ETA',
    resizable: false,
    pinned: 'left',
    lockPinned: true,
    width: 60,
    headerClass: 'small-padding-ag-grid-header-cell',
    cellStyle: { textAlign: 'flex-start', padding: 0, paddingLeft: 4 },
    cellRenderer: (params: ICellRendererParams<TableStopOnRoute>) => {
      const status = params.data?.status;
      const isNotPending = status !== StopStatus.NotArrived;
      return (
        <Typography
          variant="caption"
          color={isNotPending ? 'text.secondary' : undefined}
          fontWeight="inherit"
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {isNil(params.data?.completedAt)
            ? isNil(params.data?.cachedEta?.arrivalTime)
              ? 'No ETA'
              : `${dayjs(params.data?.cachedEta?.arrivalTime).format('HH:mm')}`
            : dayjs(params.data?.completedAt).format('HH:mm')}
        </Typography>
      );
    },
  };
};

export const getDefaultColumns = ({
  dispatchTableColors,
  isMapView,
  locked,
}: {
  dispatchTableColors: DispatchTableColorFragment[] | undefined;
  isMapView: boolean;
  locked: boolean;
}): ColDef[] => {
  const defaultColumns: ColDef[] = [
    {
      field: 'index',
      hide: false,
      autoHeight: true,
      headerName: '',
      width: 20,
      pinned: 'left',
      lockPinned: true,
      cellStyle: () => {
        return { paddingLeft: 1 };
      },
      cellRenderer: ({ data: stop }: ICellRendererParams<TableStopOnRoute>) => {
        return isNil(stop) ? null : (
          <StopIndexCellRenderer stop={stop} isMapView={isMapView} />
        );
      },
    },
    {
      field: 'pord',
      hide: false,
      headerName: '',
      pinned: 'left',
      lockPinned: true,
      autoHeight: true,
      width: 20,
      cellStyle: ({ data: stop }: CellClassParams<TableStopOnRoute>) => {
        if (isNil(stop)) {
          return null;
        }
        const { shipment } = stop;
        const color = getDispatchTableColorForStop({
          isSpecial: stop.isSpecial === true,
          serviceUuid: shipment?.order?.service?.uuid,
          deadlineDate: shipment?.standardShipmentFields?.deadlineDate,
          appointmentDate: shipment?.standardShipmentFields?.deliveryDate,
          appointmentStartTime: stop.appointmentTime,
          appointmentEndTime: stop.endAppointmentTime,
          dispatchTableColors: dispatchTableColors ?? [],
        });
        return { background: color ?? 'white', paddingLeft: 5 };
      },
      cellRenderer: ({ data: stop }: ICellRendererParams<TableStopOnRoute>) => {
        if (isNil(stop)) {
          return null;
        }
        const { shipment } = stop;
        const color = getDispatchTableColorForStop({
          isSpecial: stop.isSpecial === true,
          serviceUuid: shipment?.order?.service?.uuid,
          deadlineDate: shipment?.standardShipmentFields?.deadlineDate,
          appointmentDate: shipment?.standardShipmentFields?.deliveryDate,
          appointmentStartTime: stop.appointmentTime,
          appointmentEndTime: stop.endAppointmentTime,
          dispatchTableColors: dispatchTableColors ?? [],
        });
        return (
          <Typography
            variant="caption"
            sx={{
              fontWeight: 'bold',
              color: isNil(color) ? 'black' : chooseForegroundColor(color),
            }}
          >
            {stop?.stopType?.at(0)}
          </Typography>
        );
      },
    },
    {
      field: 'stopDetailsIcon',
      headerName: '',
      pinned: 'left',
      lockPinned: true,
      width: 20,
      cellStyle: { paddingLeft: 2 },
      cellRenderer: (params: ICellRendererParams<TableStopOnRoute>) => {
        const stop = params.data;
        if (isNil(stop)) return null;
        return getStopDetailsStatusIcon({
          stopStatus: stop.status,
          stopDetails: stop.stopDetails,
          orderRefused: !isNil(stop.shipment?.order?.refusedBy),
        });
      },
    },
    // @ts-expect-error ag grid complaining about types
    ...(locked
      ? []
      : [
          {
            field: 'actions',
            headerName: 'Status',
            hide: false,
            pinned: 'right',
            lockPinned: true,
            cellStyle: { textAlign: 'flex-end' },
            width: 87,
            cellRenderer: (params: ICellRendererParams<TableStopOnRoute>) => {
              return isNil(params.data) ? null : (
                <RouteStopCardHoverMenu
                  stop={params?.data}
                  setIsEditing={() => null}
                  showEdit={false}
                />
              );
            },
          },
        ]),
  ];
  return defaultColumns;
};

export const getDisplayColumns = (
  useMinimizedAppointmentTime = false,
  ffInboundOutboundPaperworkColumns = false,
): ColDef[] => {
  return [
    {
      field: DispatchTableField.City,
      headerName: getDispatchHeaderCopy(DispatchTableField.City),
      sortable: true,
      hide: false,
      valueGetter: (params: ValueGetterParams<TableStopOnRoute>) => {
        return params?.data?.address?.city ?? '';
      },
    },
    {
      field: DispatchTableField.ZipCode,
      headerName: getDispatchHeaderCopy(DispatchTableField.ZipCode),
      hide: false,
      width: 60,
      sortable: true,
      valueGetter: (params: ValueGetterParams<TableStopOnRoute>) => {
        return params?.data?.address?.zip ?? '';
      },
    },
    {
      field: DispatchTableField.Mawb,
      headerName: getDispatchHeaderCopy(DispatchTableField.Mawb),
      sortable: true,
      hide: false,
      valueGetter: (params: ValueGetterParams<TableStopOnRoute>) => {
        return params.data?.shipment?.order?.standardOrderFields
          ?.masterAirwayBillOfLadingNumber;
      },
    },
    {
      field: DispatchTableField.DueDate,
      headerName: getDispatchHeaderCopy(DispatchTableField.DueDate),
      hide: false,
      sortable: true,
      valueGetter: (params: ValueGetterParams<TableStopOnRoute>) => {
        if (
          isNil(params.data?.shipment?.standardShipmentFields?.deadlineDate)
        ) {
          return '';
        }
        return dayjs(
          params.data?.shipment?.standardShipmentFields?.deadlineDate,
        ).format('MM/DD');
      },
      suppressSizeToFit: true,
    },
    {
      field: DispatchTableField.ServiceDate,
      headerName: getDispatchHeaderCopy(DispatchTableField.ServiceDate),
      hide: false,
      sortable: true,
      valueGetter: (params: ValueGetterParams<TableStopOnRoute>) => {
        if (isNil(params.data?.serviceDate)) {
          return '';
        }
        return dayjs(params.data?.serviceDate).format('MM/DD');
      },
      suppressSizeToFit: true,
    },
    {
      field: DispatchTableField.RefNumber,
      headerName: getDispatchHeaderCopy(DispatchTableField.RefNumber),
      hide: false,
      valueGetter: (params: ValueGetterParams<TableStopOnRoute>) => {
        return params?.data?.shipment?.order?.refNumbers.join(', ');
      },
    },
    {
      field: DispatchTableField.InboundPaperwork,
      headerName: getDispatchHeaderCopy(DispatchTableField.InboundPaperwork),
      hide: !ffInboundOutboundPaperworkColumns,
      suppressColumnsToolPanel: !ffInboundOutboundPaperworkColumns,
      width: 150,
      minWidth: 30,
      sortable: true,
      cellRenderer: (params: ICellRendererParams<TableStopOnRoute>) => (
        <Paperwork
          isSmall
          paperwork={params.data?.shipment?.order?.inboundPaperwork}
        />
      ),
    },
    {
      field: DispatchTableField.OutboundPaperwork,
      headerName: getDispatchHeaderCopy(DispatchTableField.OutboundPaperwork),
      hide: !ffInboundOutboundPaperworkColumns,
      suppressColumnsToolPanel: !ffInboundOutboundPaperworkColumns,
      width: 160,
      minWidth: 30,
      sortable: true,
      cellRenderer: (params: ICellRendererParams<TableStopOnRoute>) => (
        <Paperwork
          isSmall
          paperwork={params.data?.shipment?.order?.outboundPaperwork}
        />
      ),
    },
    {
      field: DispatchTableField.Address,
      headerName: getDispatchHeaderCopy(DispatchTableField.Address),
      hide: false,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      valueGetter: (params: ValueGetterParams<TableStopOnRoute>) => {
        return `${capitalCase(params.data?.address?.line1 ?? '')}`;
      },
    },
    {
      field: DispatchTableField.AddressType,
      headerName: getDispatchHeaderCopy(DispatchTableField.AddressType),
      hide: false,
      sortable: true,
      valueGetter: (params: ValueGetterParams<TableStopOnRoute>) => {
        return params?.data?.standardStopType ?? 'NA';
      },
    },
    {
      field: DispatchTableField.State,
      headerName: getDispatchHeaderCopy(DispatchTableField.State),
      hide: false,
      sortable: true,
      valueGetter: (params: ValueGetterParams<TableStopOnRoute>) => {
        return params?.data?.address?.state ?? 'NA';
      },
    },

    {
      field: DispatchTableField.Consignee,
      headerName: getDispatchHeaderCopy(DispatchTableField.Consignee),
      sortable: true,
      hide: false,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      valueGetter: (params: ICellRendererParams<TableStopOnRoute>) => {
        return params.data?.address?.name;
      },
    },
    {
      field: DispatchTableField.SpecialInstructions,
      headerName: getDispatchHeaderCopy(DispatchTableField.SpecialInstructions),
      hide: false,
      // eslint-disable-next-line react/no-unstable-nested-components
      cellRenderer: (params: ICellRendererParams<TableStopOnRoute>) => {
        return (
          <NotesComponent notes={params?.data?.specialInstructions ?? ''} />
        );
      },
    },
    {
      field: DispatchTableField.PieceCount,
      hide: false,
      headerName: getDispatchHeaderCopy(DispatchTableField.PieceCount),
      cellStyle: { textAlign: 'center' },
      // eslint-disable-next-line react/no-unstable-nested-components
      valueGetter: (params: ValueGetterParams<TableStopOnRoute>) => {
        const packages = params?.data?.shipment?.order?.packages;
        return packages?.reduce((prev, curr) => curr.quantity + prev, 0);
      },
    },
    {
      field: DispatchTableField.Dims,
      hide: false,
      headerName: getDispatchHeaderCopy(DispatchTableField.Dims),
      cellStyle: { textAlign: 'center' },
      // eslint-disable-next-line react/no-unstable-nested-components
      valueGetter: (params: ValueGetterParams<TableStopOnRoute>) => {
        const packages = params?.data?.shipment?.order?.packages;
        const packagesArr = filterNotNil(
          packages?.map((package_) => {
            const { length, width, height, quantity } = package_;
            if (!isNil(length) && !isNil(height) && !isNil(width)) {
              return `${quantity} ${length}x${width}x${height}`;
            }
            return null;
          }) ?? [],
        );
        return packagesArr.join(', ');
      },
    },
    {
      field: DispatchTableField.Weight,
      hide: false,
      headerName: getDispatchHeaderCopy(DispatchTableField.Weight),
      cellStyle: { textAlign: 'center' },
      // eslint-disable-next-line react/no-unstable-nested-components
      valueGetter: (params: ValueGetterParams<TableStopOnRoute>) => {
        const packages = params.data?.shipment?.order?.packages;
        const totalWeight = packages?.reduce(
          (prev, curr) => (isNil(curr.weight) ? prev : curr.weight + prev),
          0,
        );
        return isNil(totalWeight) ? null : `${totalWeight}`;
      },
    },
    {
      field: DispatchTableField.OrderNotes,
      hide: false,
      headerName: getDispatchHeaderCopy(DispatchTableField.OrderNotes),
      cellStyle: { textAlign: 'center' },
      // eslint-disable-next-line react/no-unstable-nested-components
      cellRenderer: (params: ICellRendererParams<TableStopOnRoute>) => {
        return (
          <NotesComponent
            notes={`${params.data?.shipment?.order?.orderComments
              ?.map((orderComment) => orderComment.comment)
              .join('\n')}`}
          />
        );
      },
    },
    {
      field: DispatchTableField.Appointment,
      headerName: getDispatchHeaderCopy(DispatchTableField.Appointment),
      sortable: true,
      hide: false,
      maxWidth: 130,
      valueGetter: (params: ValueGetterParams<TableStopOnRoute>) => {
        const apptDate = transformDateStringToSpecifiedFormat(
          params.data?.shipment?.standardShipmentFields?.deliveryDate,
          'MM/DD/YY',
        );
        const apptTimeString = getAppointmentTimeString({
          stop: params.data,
          deliveryDate:
            params.data?.shipment?.standardShipmentFields?.deliveryDate,
          useMinimizedAppointmentTime,
        });
        return `${
          useMinimizedAppointmentTime ? '' : apptDate
        } ${apptTimeString}`;
      },
      suppressSizeToFit: true,
    },
    {
      field: DispatchTableField.Consignee,
      headerName: getDispatchHeaderCopy(DispatchTableField.Consignee),
      sortable: true,
      hide: false,
      valueGetter: (params: ValueGetterParams<TableStopOnRoute>) => {
        return params.data?.address?.name;
      },
    },
    {
      field: DispatchTableField.AppointmentScheduled,
      hide: false,
      sortable: true,
      headerName: getDispatchHeaderCopy(
        DispatchTableField.AppointmentScheduled,
      ),
      cellStyle: { textAlign: 'center' },
      maxWidth: 115,
      // eslint-disable-next-line react/no-unstable-nested-components
      cellRenderer: (params: ICellRendererParams<TableStopOnRoute>) => {
        const appt = params?.data?.appointmentTime;
        const endAppt = params?.data?.endAppointmentTime;
        if (isNil(appt) && isNil(endAppt)) {
          return <CheckIcon color="success" sx={{ fontSize: '14px' }} />;
        }
        return <CloseIcon color="error" sx={{ fontSize: '14px' }} />;
      },
    },
    {
      field: DispatchTableField.Customer,
      headerName: getDispatchHeaderCopy(DispatchTableField.Customer),
      sortable: true,
      hide: false,
      valueGetter: (params: ValueGetterParams<TableStopOnRoute>) => {
        return params.data?.shipment?.order?.billingPartyContact?.displayName;
      },
    },
    {
      field: DispatchTableField.ServiceLevel,
      headerName: getDispatchHeaderCopy(DispatchTableField.ServiceLevel),
      hide: false,
      sortable: true,
      valueGetter: (params: ValueGetterParams<TableStopOnRoute>) => {
        return params.data?.shipment?.order?.service?.name;
      },
    },
    {
      field: DispatchTableField.ServiceTime,
      headerName: getDispatchHeaderCopy(DispatchTableField.ServiceTime),
      hide: false,
      sortable: false,
      valueGetter: (params: ValueGetterParams<TableStopOnRoute>) => {
        return params.data?.overrideServiceTimeInMinutes;
      },
    },
    {
      field: DispatchTableField.BusinessDivision,
      headerName: getDispatchHeaderCopy(DispatchTableField.BusinessDivision),
      hide: false,
      sortable: true,
      maxWidth: 100,
      valueGetter: (params: ValueGetterParams<TableStopOnRoute>) => {
        return params.data?.shipment?.order?.billingPartyContact
          ?.businessDivision?.name;
      },
    },
    {
      field: DispatchTableField.Special,
      hide: false,
      headerName: getDispatchHeaderCopy(DispatchTableField.Special),
      cellStyle: { textAlign: 'center' },
      maxWidth: 70,
      // eslint-disable-next-line react/no-unstable-nested-components
      cellRenderer: (params: ICellRendererParams<TableStopOnRoute>) => {
        const isSpecial = params?.data?.isSpecial;
        return isSpecial === true ? (
          <CheckIcon color="success" sx={{ fontSize: '14px' }} />
        ) : (
          <CloseIcon color="error" sx={{ fontSize: '14px' }} />
        );
      },
    },
    {
      field: DispatchTableField.Terminal,
      headerName: getDispatchHeaderCopy(DispatchTableField.Terminal),
      hide: false,
      maxWidth: 100,
      sortable: true,
      valueGetter: (params: ValueGetterParams<TableStopOnRoute>) => {
        return params.data?.shipment?.order?.startStop?.terminal?.name ?? '-';
      },
    },
    {
      field: DispatchTableField.Tags,
      headerName: getDispatchHeaderCopy(DispatchTableField.Tags),
      cellStyle: { textAlign: 'center' },
      maxWidth: 100,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cellRenderer: (params: ICellRendererParams<TableStopOnRoute>) => {
        const tags = params?.data?.shipment?.order?.tags;
        return <TagsCell tags={tags ?? []} />;
      },
    },
    {
      field: DispatchTableField.InboundMethod,
      headerName: getDispatchHeaderCopy(DispatchTableField.InboundMethod),
      autoHeight: true,
      hide: false,
      sortable: true,
      maxWidth: 150,
      valueGetter: (params: ValueGetterParams<TableStopOnRoute>) => {
        const stopType = params.data?.stopType;
        const otherShipment = params.data?.shipment?.order?.shipments.find(
          (shipment) =>
            shipment.uuid !== params.data?.shipment?.uuid &&
            shipment.shipmentType === ShipmentType.Regular,
        );
        const otherStopType = otherShipment?.legs[0]?.endStop.stopType;

        if (!isNil(stopType) && inboundStopTypes.includes(stopType)) {
          return sentenceCase(stopType);
        }

        if (!isNil(otherStopType) && inboundStopTypes.includes(otherStopType)) {
          return sentenceCase(otherStopType);
        }

        return '-';
      },
    },
    {
      field: DispatchTableField.OutboundMethod,
      headerName: getDispatchHeaderCopy(DispatchTableField.OutboundMethod),
      autoHeight: true,
      hide: false,
      sortable: true,
      maxWidth: 150,
      valueGetter: (params: ValueGetterParams<TableStopOnRoute>) => {
        const stopType = params.data?.stopType;
        const otherShipment = params.data?.shipment?.order?.shipments.find(
          (shipment) =>
            shipment.uuid !== params.data?.shipment?.uuid &&
            shipment.shipmentType === ShipmentType.Regular,
        );
        const otherStopType = otherShipment?.legs[0]?.endStop.stopType;

        if (!isNil(stopType) && outboundStopTypes.includes(stopType)) {
          return sentenceCase(stopType);
        }

        if (
          !isNil(otherStopType) &&
          outboundStopTypes.includes(otherStopType)
        ) {
          return sentenceCase(otherStopType);
        }

        return '-';
      },
    },
    {
      field: DispatchTableField.InboundTerminal,
      headerName: getDispatchHeaderCopy(DispatchTableField.InboundTerminal),
      autoHeight: true,
      hide: false,
      sortable: true,
      maxWidth: 150,
      valueGetter: (params: ValueGetterParams<TableStopOnRoute>) => {
        return params.data?.shipment?.order?.startStop?.terminal?.name ?? '-';
      },
    },
    {
      field: DispatchTableField.OutboundTerminal,
      headerName: getDispatchHeaderCopy(DispatchTableField.OutboundTerminal),
      autoHeight: true,
      hide: false,
      sortable: true,
      maxWidth: 150,
      valueGetter: (params: ValueGetterParams<TableStopOnRoute>) => {
        return params.data?.shipment?.order?.endStop?.terminal?.name ?? '-';
      },
    },
    {
      field: DispatchTableField.TotalCharges,
      headerName: getDispatchHeaderCopy(DispatchTableField.TotalCharges),
      hide: false,
      valueGetter: (params: ValueGetterParams<TableStopOnRoute>) => {
        const totalCharge = params.data?.shipment?.order?.totalCharge;

        return isNil(totalCharge) ? null : currency(totalCharge).format();
      },
    },
  ];
};
