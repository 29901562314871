import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { noop } from 'lodash';
import { Fragment } from 'react';
import { exhaustive } from 'shared/switch';
import CenteredCircularProgress from '../../../../../common/components/centered-circular-progress';
import { FeatureFlag } from '../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../common/react-hooks/use-feature-flag';
import { specialChargeGroupFormat } from '../../../../../common/utils/utils';
import { SpecialAccessorialType } from '../../../../../generated/graphql';
import RangeCell, { type RangeCellValues } from '../../common/range-cell';
import {
  FormMode,
  type SpecialAccessorialChargeGroup,
  type SpecialAccessorialMatrixItem,
  type SpecialAccessorialTariffZoneFragment,
} from '../common';
import { SpecialAccessorialMatrixItemTextField } from './special-accessorial-matrix-item-text-field';

type SpecialAccessorialMatrixTableFieldProps = {
  readonly mode: FormMode;
  readonly specialAccessorialType: SpecialAccessorialType | null;
  readonly chargeGroups: SpecialAccessorialChargeGroup[];
  readonly mileRangeEnds: RangeCellValues;
  readonly tariffZones: SpecialAccessorialTariffZoneFragment[];
  readonly loadingTariffZones: boolean;
  readonly matrixItems: SpecialAccessorialMatrixItem[][];
  readonly onMatrixItemChange: (
    rate: number,
    zoneIdx: number,
    chargeGroupIdx: number,
  ) => void;
};

export const SpecialAccessorialMatrixTableField = ({
  mode,
  specialAccessorialType,
  chargeGroups,
  mileRangeEnds,
  tariffZones,
  loadingTariffZones,
  matrixItems,
  onMatrixItemChange,
}: SpecialAccessorialMatrixTableFieldProps) => {
  const ffEnableMileageBasedSpecials = useFeatureFlag(
    FeatureFlag.FF_ENABLE_MILEAGE_BASED_SPECIALS,
  );
  switch (mode) {
    case FormMode.CREATE: {
      const tableLoadingOrEmptyState = (
        <TableRow>
          <TableCell
            colSpan={chargeGroups.length + 1}
            sx={{ textAlign: 'center', color: 'text.secondary' }}
          >
            {loadingTariffZones ? (
              <CenteredCircularProgress />
            ) : (
              `Add a rate group and ${
                specialAccessorialType === SpecialAccessorialType.MileageBased
                  ? 'enter a mile range'
                  : 'select a zone group'
              } to get started`
            )}
          </TableCell>
        </TableRow>
      );

      const renderAccessorialMatrixRowContent = (i: number) => (
        <Fragment key={matrixItems[i]?.[0]?.uuid}>
          {(matrixItems[i] ?? []).map((matrixItem, j) => {
            return (
              <TableCell key={matrixItem?.uuid}>
                <SpecialAccessorialMatrixItemTextField
                  mode={mode}
                  value={matrixItem?.rate}
                  type="number"
                  label="Rate"
                  onBlur={noop}
                  onChange={(e) => {
                    onMatrixItemChange(e, i, j);
                  }}
                />
              </TableCell>
            );
          })}
        </Fragment>
      );

      let tableBody: JSX.Element[] | null = null;
      if (
        ffEnableMileageBasedSpecials &&
        specialAccessorialType === SpecialAccessorialType.MileageBased
      ) {
        tableBody = mileRangeEnds.map((range, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <TableRow key={`${range}-${i}`}>
            <TableCell>
              <RangeCell
                editable={false}
                rangeValues={mileRangeEnds}
                rangeIndex={i}
                inputLabel="Miles"
              />
            </TableCell>
            {renderAccessorialMatrixRowContent(i)}
          </TableRow>
        ));
      } else {
        tableBody = tariffZones.map((zone, i) => (
          <TableRow key={zone.uuid}>
            <TableCell>{zone.name}</TableCell>
            {renderAccessorialMatrixRowContent(i)}
          </TableRow>
        ));
      }

      return (
        <TableContainer component={Paper}>
          <Table aria-label="accessorials-matrix">
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>
                    {ffEnableMileageBasedSpecials &&
                    specialAccessorialType ===
                      SpecialAccessorialType.MileageBased
                      ? 'MILES'
                      : 'ZONE'}
                  </strong>
                </TableCell>
                {chargeGroups.map(
                  (chargeGroup: SpecialAccessorialChargeGroup) => (
                    <TableCell key={chargeGroup.uuid}>
                      {specialChargeGroupFormat(chargeGroup)}
                    </TableCell>
                  ),
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {tariffZones.length === 0 && mileRangeEnds.length === 0
                ? tableLoadingOrEmptyState
                : tableBody}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }
    case FormMode.EDIT: {
      return null;
    }
    default: {
      return exhaustive(mode);
    }
  }
};
