export type MinMaxAmountValue = {
  min?: number | undefined;
  max?: number | undefined;
};

export const initialMinMaxAmountValue: MinMaxAmountValue = {
  min: undefined,
  max: undefined,
};

export type OverageRates = {
  mileOverageRate: number | null;
  mileOverageFlatRate: number | null;
  mileOverageApplicableAbove: number | null;
  pieceOverageRate: number | null;
  pieceOverageFlatRate: number | null;
  pieceOverageApplicableAbove: number | null;
  weightOverageRate: number | null;
  weightOverageFlatRate: number | null;
  weightOverageApplicableAbove: number | null;
};

export const initialOverageRates: OverageRates = {
  mileOverageRate: null,
  mileOverageFlatRate: null,
  mileOverageApplicableAbove: null,
  pieceOverageRate: null,
  pieceOverageFlatRate: null,
  pieceOverageApplicableAbove: null,
  weightOverageRate: null,
  weightOverageFlatRate: null,
  weightOverageApplicableAbove: null,
};

export const MAX_MATRIX_SIZE = 400;

export enum AmountInputType {
  MileOverage = 'MILE_OVERAGE',
  MileOverageFlatRate = 'MILE_OVERAGE_FLAT_RATE',
  MileOverageApplicableAbove = 'MILE_OVERAGE_APPLICABLE_ABOVE',
  PieceOverage = 'PIECE_OVERAGE',
  PieceOverageFlatRate = 'PIECE_OVERAGE_FLAT_RATE',
  PieceOverageApplicableAbove = 'PIECE_OVERAGE_APPLICABLE_ABOVE',
  WeightOverage = 'WEIGHT_OVERAGE',
  WeightOverageFlatRate = 'WEIGHT_OVERAGE_FLAT_RATE',
  WeightOverageApplicableAbove = 'WEIGHT_OVERAGE_APPLICABLE_ABOVE',
}
