import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  // eslint-disable-next-line no-restricted-imports
  Grid,
} from '@mui/material';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import React, {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { type FieldValues, useFormContext } from 'react-hook-form';
import useContacts from '../../../../../../../common/react-hooks/use-contacts';
import useMe from '../../../../../../../common/react-hooks/use-me';
import useServices from '../../../../../../../common/react-hooks/use-services';
import useTerminals from '../../../../../../../common/react-hooks/use-terminals';
import GeneratedOrderDeliveryReceiptPdf from '../../../../../../generated-documents/components/generated-order-delivery-receipt-pdf';
import {
  convertOrderFormValuesToDeliveryReceiptData,
  type OrderDeliveryReceiptData,
} from '../../../../../../generated-documents/utils';

const PrintOrderDeliveryReceiptModal = ({
  isOpen,
  setIsOpen,
}: {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { segment, companyName } = useMe();
  const { getServiceName } = useServices();
  const { getContactName } = useContacts();
  const { getTerminalCode, terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const { getValues } = useFormContext();
  const order: FieldValues = getValues();

  const orderForDeliveryReceipt: OrderDeliveryReceiptData = useMemo(
    () =>
      convertOrderFormValuesToDeliveryReceiptData({
        order,
        getTerminalCode,
        getContactName,
        getServiceName,
        companyName,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order, companyName],
  );

  // eslint-disable-next-line react/jsx-no-useless-fragment
  const [generatedPdf, setGeneratedPdf] = useState<JSX.Element>(<></>);
  useEffect(() => {
    if (isOpen) {
      setGeneratedPdf(
        <GeneratedOrderDeliveryReceiptPdf
          segment={segment}
          order={orderForDeliveryReceipt}
          terminalsEnabled={terminalsEnabled}
        />,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segment, orderForDeliveryReceipt, isOpen]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <DialogTitle
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        Delivery Receipt
      </DialogTitle>
      <DialogContent sx={{ pt: '10px !important' }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <PDFViewer showToolbar style={{ width: '100%', height: '75vh' }}>
              {generatedPdf}
            </PDFViewer>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button variant="contained">
              <PDFDownloadLink
                document={generatedPdf}
                fileName={`order-delivery-receipt-${orderForDeliveryReceipt.shipperBillOfLadingNumber}.pdf`}
              >
                {({ loading }) => (loading ? 'Loading...' : 'Download')}
              </PDFDownloadLink>
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PrintOrderDeliveryReceiptModal;
