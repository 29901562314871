import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { type Dispatch, type SetStateAction } from 'react';
import { FulfillmentType } from '../../../../generated/graphql';

export const DefaultFulfillmentTypeSelection = ({
  defaultFulfillmentType,
  setDefaultFulfillmentType,
}: {
  readonly defaultFulfillmentType: FulfillmentType | null;
  readonly setDefaultFulfillmentType: Dispatch<
    SetStateAction<FulfillmentType | null>
  >;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Typography sx={{ fontWeight: 'bold' }}>
        Default fulfillment type
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          alignItems: 'right',
        }}
      >
        <RadioGroup
          value={defaultFulfillmentType}
          onChange={(e) => {
            setDefaultFulfillmentType(e.target.value as FulfillmentType);
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            <FormControlLabel
              checked={defaultFulfillmentType === FulfillmentType.Dedicated}
              value={FulfillmentType.Dedicated}
              control={<Radio />}
              label="the same route"
            />
            <FormControlLabel
              checked={defaultFulfillmentType === FulfillmentType.MultiTrip}
              value={FulfillmentType.MultiTrip}
              control={<Radio />}
              label="separate routes"
              sx={{
                mr: 0,
              }}
            />
          </Box>
        </RadioGroup>
        <Button
          onClick={() => {
            setDefaultFulfillmentType(null);
          }}
        >
          Clear
        </Button>
      </Box>
    </Box>
  );
};
