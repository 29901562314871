import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { type FunctionComponent, useState } from 'react';
import {
  useIngestedOutlookEmailQuery,
  type IngestedOutlookEmailQuery,
} from '../../../../../generated/graphql';
import PalletLink from '../../../../../pallet-ui/links/link/pallet-link';
import { IngestedOutlookEmailAttachmentProcessingStatusChip } from './processing-status-chip';

const AttachmentDetailsRow = ({
  attachmentData,
}: {
  readonly attachmentData: IngestedOutlookEmailQuery['ingestedOutlookEmail']['attachments'][0];
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <>
      <TableRow key={attachmentData.uuid}>
        <TableCell>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setIsExpanded(!isExpanded);
            }}
          >
            {isExpanded ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {attachmentData.attachmentFilename}
        </TableCell>
        <TableCell>
          <IngestedOutlookEmailAttachmentProcessingStatusChip
            status={attachmentData.processingStatus}
          />
        </TableCell>
        <TableCell>
          <a
            href={attachmentData.scannedOrder?.preSignedGetUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            View Attachment
          </a>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse unmountOnExit in={isExpanded} timeout="auto">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Page(s)</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {attachmentData.scannedOrder?.scannedOrderResults.map(
                    (scannedOrderResult) => (
                      <TableRow key={scannedOrderResult.uuid}>
                        <TableCell>
                          {scannedOrderResult.pageStart ===
                          scannedOrderResult.pageEnd ? (
                            scannedOrderResult.pageStart + 1
                          ) : (
                            <>
                              {scannedOrderResult.pageStart + 1}-
                              {scannedOrderResult.pageEnd + 1}
                            </>
                          )}
                        </TableCell>
                        <TableCell>{scannedOrderResult.status}</TableCell>
                        <TableCell>
                          <PalletLink
                            href={
                              scannedOrderResult.scannedOrderResultDocuments[0]
                                ?.preSignedGetUrl
                            }
                          >
                            View Result
                          </PalletLink>
                        </TableCell>
                      </TableRow>
                    ),
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

type EmailProcessingStatusPageProps = {
  readonly emailUuid: string;
};

const EmailProcessingStatusPage: FunctionComponent<
  EmailProcessingStatusPageProps
> = ({ emailUuid }) => {
  const theme = useTheme();

  const {
    data: ingestedOutlookEmailData,
    loading: ingestedOutlookEmailLoading,
  } = useIngestedOutlookEmailQuery({
    variables: { uuid: emailUuid },
  });

  // return spinner centered horizontally and vertically
  if (ingestedOutlookEmailLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        flexShrink: 0,
        position: 'relative',
        overflow: 'auto',
      }}
    >
      <Typography variant="h5" sx={{ padding: theme.spacing(2) }}>
        Email Processing Status
      </Typography>
      <Typography variant="body1" sx={{ padding: theme.spacing(2) }}>
        Subject: {ingestedOutlookEmailData?.ingestedOutlookEmail.subject}
      </Typography>
      <Typography
        variant="body1"
        sx={{ padding: theme.spacing(2), paddingTop: theme.spacing(4) }}
      >
        Attachments:
      </Typography>
      <TableContainer component={Paper} sx={{ padding: theme.spacing(2) }}>
        <Table aria-label="attachments table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Filename</TableCell>
              <TableCell>Status</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {ingestedOutlookEmailData?.ingestedOutlookEmail.attachments.map(
              (attachment) => (
                <AttachmentDetailsRow
                  key={attachment.uuid}
                  attachmentData={attachment}
                />
              ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default EmailProcessingStatusPage;
