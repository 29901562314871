import { getNoonOfDay } from 'shared/date';
import { exhaustive } from 'shared/switch';
import { getCurrentTimeDefaultTimezone } from 'shared/time';
import {
  FilterOperator,
  OrderStatus,
  OrderStatusFilterType,
  type DateFilterStartEnd,
  StopType,
  type DateFilterV2,
  type GetOrderTableFieldValuesInput,
  type BooleanFilterV2,
} from '../../../../generated/graphql';

export enum ExcludeFromHiding {
  EMPTY_SPACE = 'Empty space',
  BUTTON = 'Button',
}

// These must have different values than OrderTableField in the backend or AGGrid will break since there cannot be duplicate fields.
export enum OrderFilterField {
  ACTIVE_TERMINAL = 'Active Terminal',
  ADDRESS_TYPE = 'Address Type', // Commercial or residential. TODO: Deprecate. Will not be in the new UI
  APPOINTMENT_SCHEDULED = 'Appointment Scheduled', // TODO: Deprecate. Will not be in the new UI
  APPOINTMENT_REQUIRED = 'Appointment Required', // TODO: Deprecate. Will not be in the new UI
  ASSIGNED_TO_A_ROUTE = 'Assigned to a Route',
  BUSINESS_DIVISION = 'Company Division',
  CHARGES_FINALIZED = 'Charges Finalized', // TODO: Implement for new UI
  CITY = 'City', // TODO: Deprecate. Will not be in the new UI
  CUSTOMER_NAME = 'Customer Name',
  COMPLETED_AT = 'Completed At', // TODO: Deprecate. Will not be in the new UI
  HAS_CONTACT_INFORMATION = 'Has Contact Information',
  HAWB = 'Order HAWB',
  MAWB = 'Order MAWB',
  TOTAL_WEIGHT = 'Total Package Weight',
  DIM_WEIGHT = 'Dim Weight',
  TOTAL_PIECES = 'Total Pieces',
  TOTAL_SKIDS = 'Total Skids',
  ORDER_NAME = 'Order Name',
  CUSTOMER = 'Customer', // Customer. // TODO: Implement for new UI
  DRIVER = 'Driver', // TODO: Deprecate. Will not be in the new UI
  DRIVER_NAME = 'Driver Name', // TODO: Deprecate. Will not be in the new UI
  INBOUND_ADDRESS = 'Inbound Address',
  OUTBOUND_ADDRESS = 'Outbound Address',
  INBOUND_DRIVER_NAME = 'Inbound Driver Name',
  OUTBOUND_DRIVER_NAME = 'Outbound Driver Name',
  INBOUND_DRIVER = 'Inbound Driver', // TODO: Deprecate. Will not be in the new UI
  OUTBOUND_DRIVER = 'Outbound Driver', // TODO: Deprecate. Will not be in the new UI
  DATE_ATTEMPTED = 'Date Attempted', // TODO: Implement for new UI
  DATE_COMPLETED = 'Date Completed', // TODO: Implement for new UI
  DATE_CREATED = 'Date Created', // TODO: Implement for new UI
  DATE_SCHEDULED = 'Date Planned', // TODO: Deprecate. Will not be in the new UI
  DATE_RECEIVED = 'Date Marked on Hand',
  DEADLINE_DATE = 'Deadline Date', // TODO: Implement for new UI
  EXPECTED_INBOUND_ARRIVAL_DATE = 'Expected Inbound Arrival Date', // TODO: Deprecate. Will not be in the new UI
  INBOUND_NOT_ARRIVED = 'Inbound Not Arrived',
  INBOUND_SERVICE_DATE = 'Service Date (Inbound)',
  OUTBOUND_SERVICE_DATE = 'Service Date (Outbound)',
  INBOUND_CITY = 'Inbound City', // TODO: Implement for new UI
  INBOUND_COMPLETED = 'Inbound Completed',
  OUTBOUND_COMPLETED = 'Outbound Completed',
  INBOUND_COMPLETED_DATE = 'Inbound Completed Date',
  OUTBOUND_COMPLETED_DATE = 'Outbound Completed Date',
  OUTBOUND_CITY = 'Outbound City', // TODO: Implement for new UI
  INBOUND_TERMINAL = 'Inbound Terminal',
  OUTBOUND_TERMINAL = 'Outbound Terminal',
  INBOUND_METHOD = 'Type of Inbound Method', // TODO: Deprecate. Will not be in the new UI
  OUTBOUND_METHOD = 'Type of Outbound Method', // TODO: Deprecate. Will not be in the new UI
  INVOICE_DATE = 'Invoice Date', // TODO: Implement for new UI
  IS_COLLECT_ON_DELIVERY = 'Is Collect on Delivery',
  IS_HAZMAT = 'Is Hazmat', // TODO: Implement for new UI
  IS_IN_BOND = 'Is In Bond',
  IS_FINALIZED = 'Is Finalized',
  IS_PICKED = 'Is Picked', // TODO: Implement for new UI
  IS_CANCELLED = 'Is Cancelled',
  IS_REFUSED = 'Is Refused',
  IS_REWEIGHED = 'Is Reweighed',
  IS_OSD = 'Is OSD',
  IS_LINE_HAUL = 'Is Line Haul',
  IS_SPECIAL = 'Is Special',
  IT_NUMBER = 'IT Number',
  LINE_HAUL_LANE = 'Line Haul Lane', // TODO: Implement for new UI
  ON_HAND = 'On Hand',
  ON_HOLD = 'On Hold',
  ON_INVOICE = 'On Invoice', // TODO: Implement for new UI
  ORDER_TYPE = 'Type of Order', // Pickup / delivery // TODO: Deprecate. Will not be in the new UI
  ORDER_SOURCE = 'Order Source',
  ORDER_STATUS = 'Order Status',
  ORDER_SERVICE_DATE = 'Order Service Date',
  PAPERWORK_COMPLETED = 'Paperwork Completed', // TODO: Implement for new UI
  PRIMARY_APPOINTMENT_DATE = 'Primary Appointment Date',
  PROOF_OF_DELIVERY_NAME = 'Proof of Delivery Name', // TODO: Deprecate. Will not be in the new UI
  PROOF_OF_DELIVERY_SIGNEE = 'PoD Signee',
  REQUIRES_ROUTING = 'Requires Routing', // TODO: Deprecate. Will not be in the new UI
  REQUIRES_RECOVERY = 'Requires Recovery',
  DATE_MARKED_ON_HAND = 'Date Marked On Hand',
  SERVICE_DATE = 'Service Date',
  SECONDARY_REFERENCE_NUMBER = 'Secondary Reference Number',
  TAG = 'Tag',
  TERMINAL = 'Terminal',
  TRANSFER_PENDING = 'Transfer Pending',
  UN_NUMBER = 'UN #',
  SERVICE_LEVEL = 'Service Level',
  ZIP_CODE = 'Zip Code',
  INBOUND_ZIPCODE = 'Inbound Zipcode',
  OUTBOUND_ZIPCODE = 'Outbound Zipcode',
  INBOUND_ROUTE_NAME = 'Inbound Route Name',
  OUTBOUND_ROUTE_NAME = 'Outbound Route Name',
  INBOUND_APPOINTMENT_REQUIRED = 'Inbound Appointment Required',
  OUTBOUND_APPOINTMENT_REQUIRED = 'Outbound Appointment Required',
  DESTINATION_DETAILS = 'Destination Details',
  HOLD_REASON = 'Hold Reason',
  OSD_REASON = 'OSD Reason',
  INBOUND_APPOINTMENT_CONFIRMED = 'Inbound Appointment Confirmed',
  OUTBOUND_APPOINTMENT_CONFIRMED = 'Outbound Appointment Confirmed',
  INBOUND_STOP_TYPE = 'Inbound Stop Type',
  OUTBOUND_STOP_TYPE = 'Outbound Stop Type',
  ROUTING_LOCATION = 'Routing Location',
  TAGS = 'Tags',
  NOTES = 'Notes',
  EXTERNAL_NOTES = 'External Notes',
  TOTAL_CHARGES = 'Total Charges',
  INBOUND_CONTACT_NAME = 'Inbound Contact Name',
  OUTBOUND_CONTACT_NAME = 'Outbound Contact Name',
  INBOUND_APPOINTMENT_DATE = 'Inbound Appointment Date',
  OUTBOUND_APPOINTMENT_DATE = 'Outbound Appointment Date',
  RECEIVED_AT_ORIGIN = 'Received At Origin',
  INBOUND_ADDRESS_TYPE = 'Inbound Address Type',
  OUTBOUND_ADDRESS_TYPE = 'Outbound Address Type',
  CAN_DISPATCH = 'Can Dispatch',
  INBOUND_ROUTING = 'Inbound Routing',
  OUTBOUND_ROUTING = 'Outbound Routing',
  INBOUND_ROUTE_DATE = 'Inbound Route Date',
  OUTBOUND_ROUTE_DATE = 'Outbound Route Date',
}

export enum OrderFilterFieldV2 {
  CUSTOMER_NAME = OrderFilterField.CUSTOMER_NAME,
  BUSINESS_DIVISION = OrderFilterField.BUSINESS_DIVISION,
  ORDER_NAME = OrderFilterField.ORDER_NAME,
  ORDER_SERVICE_DATE = OrderFilterField.ORDER_SERVICE_DATE,
  INBOUND_SERVICE_DATE = OrderFilterField.INBOUND_SERVICE_DATE,
  OUTBOUND_SERVICE_DATE = OrderFilterField.OUTBOUND_SERVICE_DATE,
  INBOUND_ADDRESS = OrderFilterField.INBOUND_ADDRESS,
  OUTBOUND_ADDRESS = OrderFilterField.OUTBOUND_ADDRESS,
  INBOUND_TERMINAL = OrderFilterField.INBOUND_TERMINAL,
  OUTBOUND_TERMINAL = OrderFilterField.OUTBOUND_TERMINAL,
  INBOUND_ROUTE_NAME = OrderFilterField.INBOUND_ROUTE_NAME,
  OUTBOUND_ROUTE_NAME = OrderFilterField.OUTBOUND_ROUTE_NAME,
  INBOUND_DRIVER_NAME = OrderFilterField.INBOUND_DRIVER_NAME,
  OUTBOUND_DRIVER_NAME = OrderFilterField.OUTBOUND_DRIVER_NAME,
  HAWB = OrderFilterField.HAWB,
  MAWB = OrderFilterField.MAWB,
  UN_NUMBER = OrderFilterField.UN_NUMBER,
  SECONDARY_REFERENCE_NUMBER = OrderFilterField.SECONDARY_REFERENCE_NUMBER,
  INBOUND_APPOINTMENT_REQUIRED = OrderFilterField.INBOUND_APPOINTMENT_REQUIRED,
  OUTBOUND_APPOINTMENT_REQUIRED = OrderFilterField.OUTBOUND_APPOINTMENT_REQUIRED,
  INBOUND_APPOINTMENT_CONFIRMED = OrderFilterField.INBOUND_APPOINTMENT_CONFIRMED,
  OUTBOUND_APPOINTMENT_CONFIRMED = OrderFilterField.OUTBOUND_APPOINTMENT_CONFIRMED,
  DESTINATION_DETAILS = OrderFilterField.DESTINATION_DETAILS,
  HOLD_REASON = OrderFilterField.HOLD_REASON,
  OSD_REASON = OrderFilterField.OSD_REASON,
  IS_SPECIAL = OrderFilterField.IS_SPECIAL,
  DATE_RECEIVED = OrderFilterField.DATE_RECEIVED,
  ON_HAND = OrderFilterField.ON_HAND,
  ON_HOLD = OrderFilterField.ON_HOLD,
  INBOUND_STOP_TYPE = OrderFilterField.INBOUND_STOP_TYPE,
  OUTBOUND_STOP_TYPE = OrderFilterField.OUTBOUND_STOP_TYPE,
  ROUTING_LOCATION = OrderFilterField.ROUTING_LOCATION,
  SERVICE_LEVEL = OrderFilterField.SERVICE_LEVEL,
  IS_REFUSED = OrderFilterField.IS_REFUSED,
  IS_REWEIGHED = OrderFilterField.IS_REWEIGHED,
  IS_CANCELLED = OrderFilterField.IS_CANCELLED,
  ACTIVE_TERMINAL = OrderFilterField.ACTIVE_TERMINAL,
  INBOUND_COMPLETED_DATE = OrderFilterField.INBOUND_COMPLETED_DATE,
  OUTBOUND_COMPLETED_DATE = OrderFilterField.OUTBOUND_COMPLETED_DATE,
  INBOUND_COMPLETED = OrderFilterField.INBOUND_COMPLETED,
  OUTBOUND_COMPLETED = OrderFilterField.OUTBOUND_COMPLETED,
  TAGS = OrderFilterField.TAGS,
  ORDER_SOURCE = OrderFilterField.ORDER_SOURCE,
  ORDER_STATUS = OrderFilterField.ORDER_STATUS,
  INBOUND_ZIPCODE = OrderFilterField.INBOUND_ZIPCODE,
  OUTBOUND_ZIPCODE = OrderFilterField.OUTBOUND_ZIPCODE,
  TOTAL_WEIGHT = OrderFilterField.TOTAL_WEIGHT,
  TOTAL_PIECES = OrderFilterField.TOTAL_PIECES,
  TOTAL_SKIDS = OrderFilterField.TOTAL_SKIDS,
  DIM_WEIGHT = OrderFilterField.DIM_WEIGHT,
  EXTERNAL_NOTES = OrderFilterField.EXTERNAL_NOTES,
  TOTAL_CHARGES = OrderFilterField.TOTAL_CHARGES,
  INBOUND_CONTACT_NAME = OrderFilterField.INBOUND_CONTACT_NAME,
  OUTBOUND_CONTACT_NAME = OrderFilterField.OUTBOUND_CONTACT_NAME,
  IS_LINE_HAUL = OrderFilterField.IS_LINE_HAUL,
  IS_IN_BOND = OrderFilterField.IS_IN_BOND,
  INBOUND_APPOINTMENT_DATE = OrderFilterField.INBOUND_APPOINTMENT_DATE,
  OUTBOUND_APPOINTMENT_DATE = OrderFilterField.OUTBOUND_APPOINTMENT_DATE,
  RECEIVED_AT_ORIGIN = OrderFilterField.RECEIVED_AT_ORIGIN,
  INBOUND_ADDRESS_TYPE = OrderFilterField.INBOUND_ADDRESS_TYPE,
  OUTBOUND_ADDRESS_TYPE = OrderFilterField.OUTBOUND_ADDRESS_TYPE,
  ASSIGNED_TO_A_ROUTE = OrderFilterField.ASSIGNED_TO_A_ROUTE,
  IS_FINALIZED = OrderFilterField.IS_FINALIZED,
  IS_PICKED = OrderFilterField.IS_PICKED,
  ON_INVOICE = OrderFilterField.ON_INVOICE,
  IS_HAZMAT = OrderFilterField.IS_HAZMAT,
  REQUIRES_RECOVERY = OrderFilterField.REQUIRES_RECOVERY,
  PAPERWORK_COMPLETED = OrderFilterField.PAPERWORK_COMPLETED,
  COMPLETED_AT = OrderFilterField.COMPLETED_AT,
  DATE_CREATED = OrderFilterField.DATE_CREATED,
  INVOICE_DATE = OrderFilterField.INVOICE_DATE,
  DEADLINE_DATE = OrderFilterField.DEADLINE_DATE,
  DATE_ATTEMPTED = OrderFilterField.DATE_ATTEMPTED,
  CAN_DISPATCH = OrderFilterField.CAN_DISPATCH,
  INBOUND_ROUTING = OrderFilterField.INBOUND_ROUTING,
  OUTBOUND_ROUTING = OrderFilterField.OUTBOUND_ROUTING,
  PROOF_OF_DELIVERY_SIGNEE = OrderFilterField.PROOF_OF_DELIVERY_SIGNEE,
  TRANSFER_PENDING = OrderFilterField.TRANSFER_PENDING,
  REQUIRES_ROUTING = OrderFilterField.REQUIRES_ROUTING,
  INBOUND_CITY = OrderFilterField.INBOUND_CITY,
  OUTBOUND_CITY = OrderFilterField.OUTBOUND_CITY,
  HAS_CONTACT_INFORMATION = OrderFilterField.HAS_CONTACT_INFORMATION,
  INBOUND_NOT_ARRIVED = OrderFilterField.INBOUND_NOT_ARRIVED,
  APPOINTMENT_REQUIRED = OrderFilterField.APPOINTMENT_REQUIRED,
  APPOINTMENT_SCHEDULED = OrderFilterField.APPOINTMENT_SCHEDULED,
  INBOUND_ROUTE_DATE = OrderFilterField.INBOUND_ROUTE_DATE,
  OUTBOUND_ROUTE_DATE = OrderFilterField.OUTBOUND_ROUTE_DATE,
  LINE_HAUL_LANE = OrderFilterField.LINE_HAUL_LANE,
}

export enum BooleanFilterOption {
  YES = 'Yes',
  NO = 'No',
}

// TODO: Support specific date and arbitrary date range
export enum DateFilterOption {
  ANY_DAY = 'Any Day',
  DATE_RANGE = 'Date Range',
  TODAY = 'Today',
  YESTERDAY = 'Yesterday',
  TOMORROW = 'Tomorrow',
  BEFORE_TODAY = 'Before Today',
  LAST_7 = 'Last 7 Days',
  LAST_15 = 'Last 15 Days',
  LAST_30 = 'Last 30 Days',
  LAST_60 = 'Last 60 Days',
}

export const incompleteOrderStatusFilters = [
  {
    filterType: OrderStatusFilterType.Equals,
    status: OrderStatus.Created,
  },
  {
    filterType: OrderStatusFilterType.Equals,
    status: OrderStatus.InProgress,
  },
];

export const attemptedOrderStatusFilters = [
  {
    filterType: OrderStatusFilterType.Equals,
    status: OrderStatus.Created,
  },
  {
    filterType: OrderStatusFilterType.Equals,
    status: OrderStatus.InProgress,
  },
];

export const onHoldOrderStatusFilters = [
  {
    filterType: OrderStatusFilterType.Equals,
    status: OrderStatus.OnHold,
  },
];

export const completedOrderFilters = [
  {
    filterType: OrderStatusFilterType.Equals,
    status: OrderStatus.Delivered,
  },
  {
    filterType: OrderStatusFilterType.Equals,
    status: OrderStatus.HasIssue,
  },
  {
    filterType: OrderStatusFilterType.Equals,
    status: OrderStatus.Invoiced,
  },
  {
    filterType: OrderStatusFilterType.Equals,
    status: OrderStatus.Finalized,
  },
];

export const booleanFilterOptionToBooleanFilter = (
  booleanFilterOption: BooleanFilterOption,
  // eslint-disable-next-line consistent-return
): boolean => {
  // eslint-disable-next-line default-case
  switch (booleanFilterOption) {
    case BooleanFilterOption.YES: {
      return true;
    }
    case BooleanFilterOption.NO: {
      return false;
    }
  }
};

export const booleanFilterOptionToBooleanFilterV2 = (
  booleanFilterOption: BooleanFilterOption,
): BooleanFilterV2 | null => {
  switch (booleanFilterOption) {
    case BooleanFilterOption.YES: {
      return { eq: true };
    }
    case BooleanFilterOption.NO: {
      return { eq: false };
    }
    default: {
      return null;
    }
  }
};

export const convertDateFilterOptionToDateFilterV2 = ({
  dateFilterOption,
  startDate,
  endDate,
}: {
  dateFilterOption: DateFilterOption;
  startDate: Date | null | undefined;
  endDate: Date | null | undefined;
}): DateFilterV2 | null => {
  const now = getCurrentTimeDefaultTimezone();

  // We intentionally use the start of the day for the startFilterValue
  // but getNoonOfDay for the endFilterValue. This is to avoid the endFilterValue
  // from rolling over to the next day. when converted to UTC
  // the backend handles setting the date to the end of the day
  // for the endFilterValue

  switch (dateFilterOption) {
    case DateFilterOption.ANY_DAY: {
      return null;
    }
    case DateFilterOption.TODAY: {
      return {
        gte: now.startOf('day').toDate(),
        lte: getNoonOfDay(now),
      };
    }
    case DateFilterOption.YESTERDAY: {
      return {
        gte: now.subtract(1, 'days').startOf('day').toDate(),
        lte: getNoonOfDay(now.subtract(1, 'days')),
      };
    }
    case DateFilterOption.TOMORROW: {
      return {
        gte: now.add(1, 'days').startOf('day').toDate(),
        lte: getNoonOfDay(now.add(1, 'days')),
      };
    }
    case DateFilterOption.BEFORE_TODAY: {
      return {
        lte: getNoonOfDay(now.subtract(1, 'day')),
      };
    }
    case DateFilterOption.LAST_7: {
      return {
        gte: now.subtract(7, 'days').startOf('day').toDate(),
        lte: getNoonOfDay(now),
      };
    }
    case DateFilterOption.LAST_15: {
      return {
        gte: now.subtract(15, 'days').startOf('day').toDate(),
        lte: getNoonOfDay(now),
      };
    }
    case DateFilterOption.LAST_30: {
      return {
        gte: now.subtract(30, 'days').startOf('day').toDate(),
        lte: getNoonOfDay(now),
      };
    }
    case DateFilterOption.LAST_60: {
      return {
        gte: now.subtract(60, 'days').startOf('day').toDate(),
        lte: getNoonOfDay(now),
      };
    }
    case DateFilterOption.DATE_RANGE: {
      return {
        gte: startDate,
        lte: endDate,
      };
    }
    default: {
      return null;
    }
  }
};

export const convertDateFilterOptionToDateFilters = ({
  dateFilterOption,
  startDate,
  endDate,
}: {
  dateFilterOption: DateFilterOption;
  startDate: Date | null | undefined;
  endDate: Date | null | undefined;
}): DateFilterStartEnd | null => {
  const now = getCurrentTimeDefaultTimezone();

  // We intentionally use the start of the day for the startFilterValue
  // but getNoonOfDay for the endFilterValue. This is to avoid the endFilterValue
  // from rolling over to the next day. when converted to UTC
  // the backend handles setting the date to the end of the day
  // for the endFilterValue

  switch (dateFilterOption) {
    case DateFilterOption.ANY_DAY: {
      return null;
    }
    case DateFilterOption.TODAY: {
      return {
        startFilterValue: now.startOf('day').toDate(),
        endFilterValue: getNoonOfDay(now),
      };
    }
    case DateFilterOption.YESTERDAY: {
      return {
        startFilterValue: now.subtract(1, 'days').startOf('day').toDate(),
        endFilterValue: getNoonOfDay(now.subtract(1, 'days')),
      };
    }
    case DateFilterOption.TOMORROW: {
      return {
        startFilterValue: now.add(1, 'days').startOf('day').toDate(),
        endFilterValue: getNoonOfDay(now.add(1, 'days')),
      };
    }
    case DateFilterOption.BEFORE_TODAY: {
      return {
        endFilterValue: getNoonOfDay(now.subtract(1, 'day')),
      };
    }
    case DateFilterOption.LAST_7: {
      return {
        startFilterValue: now.subtract(7, 'days').startOf('day').toDate(),
        endFilterValue: getNoonOfDay(now),
      };
    }
    case DateFilterOption.LAST_15: {
      return {
        startFilterValue: now.subtract(15, 'days').startOf('day').toDate(),
        endFilterValue: getNoonOfDay(now),
      };
    }
    case DateFilterOption.LAST_30: {
      return {
        startFilterValue: now.subtract(30, 'days').startOf('day').toDate(),
        endFilterValue: getNoonOfDay(now),
      };
    }
    case DateFilterOption.LAST_60: {
      return {
        startFilterValue: now.subtract(60, 'days').startOf('day').toDate(),
        endFilterValue: getNoonOfDay(now),
      };
    }
    case DateFilterOption.DATE_RANGE: {
      return {
        startFilterValue: startDate,
        endFilterValue: endDate,
      };
    }
    default: {
      return null;
    }
  }
};

export type FilterModel = Record<string, any>;

export type MultiSelectFilterValue = {
  actualValue: string;
  displayValue: string;
};

export enum AGGridFilterType {
  BOOLEAN = 'boolean',
  SET = 'set',
  SINGLE_SELECT = 'single_select',
  MULTI_SELECT = 'multi_select',
  DATE = 'date',
  TEXT = 'text',
}

export enum SingleSelectFilterOption {
  ANY = 'Any',
}

export enum DeprecatedOrderStatusFilterOption {
  CREATED = 'CREATED',
}

export enum OrderStatusFilterOption {
  INCOMPLETE = 'INCOMPLETE',
  ON_HOLD = 'ON_HOLD',
  SCHEDULED = 'PLANNED',
  ATTEMPTED = 'ATTEMPTED',
  OUT_FOR_DELIVERY = 'DISPATCHED',
  COMPLETED = 'COMPLETED',
}

export enum InboundMethodOptions {
  PICKUP = StopType.Pickup,
  RECOVERY = StopType.Recovery,
  PARTNER_CARRIER_DROPOFF = StopType.PartnerCarrierDropoff,
  NONE = StopType.None,
}

export enum OutboundMethodOptions {
  DELIVERY = StopType.Delivery,
  TRANSFER = StopType.Transfer,
  PARTNER_CARRIER_PICKUP = StopType.PartnerCarrierPickup,
  NONE = StopType.None,
}

export const convertStatusFilterOptionToQueryFilters = (
  statusFilterOption:
    | OrderStatusFilterOption
    | DeprecatedOrderStatusFilterOption,
): Pick<
  GetOrderTableFieldValuesInput,
  'statusFilters' | 'scheduledFilter' | 'hasFailures' | 'outForDeliveryFilter'
> | null => {
  switch (statusFilterOption) {
    case DeprecatedOrderStatusFilterOption.CREATED:
    case OrderStatusFilterOption.INCOMPLETE: {
      return {
        statusFilters: incompleteOrderStatusFilters,
      };
    }
    case OrderStatusFilterOption.ON_HOLD: {
      return {
        statusFilters: onHoldOrderStatusFilters,
      };
    }
    case OrderStatusFilterOption.SCHEDULED: {
      return {
        scheduledFilter: {
          value: true,
          filterOperator: FilterOperator.And,
        },
      };
    }
    case OrderStatusFilterOption.ATTEMPTED: {
      return {
        statusFilters: attemptedOrderStatusFilters,
        hasFailures: {
          filterOperator: FilterOperator.And,
          value: true,
        },
      };
    }
    case OrderStatusFilterOption.OUT_FOR_DELIVERY: {
      return {
        outForDeliveryFilter: {
          filterOperator: FilterOperator.And,
          value: true,
        },
      };
    }
    case OrderStatusFilterOption.COMPLETED: {
      return {
        statusFilters: completedOrderFilters,
      };
    }
    default: {
      return exhaustive(statusFilterOption);
    }
  }
};

export const NO_LIMIT = 'No limit';
