import { Stack, Typography } from '@mui/material';
import { isNil } from 'lodash';
import pluralize from 'pluralize';
import { useMemo } from 'react';
import { type DetailedLineHaulManifestFragment } from '../../../generated/graphql';

const ManifestLoadSummary = ({
  manifest,
}: {
  readonly manifest: DetailedLineHaulManifestFragment | undefined;
}) => {
  const pieces = useMemo(() => {
    return manifest?.orderSegments.reduce(
      (sum, orderSegment) => sum + orderSegment.order.pieceCountFromPackages,
      0,
    );
  }, [manifest?.orderSegments]);

  const weight = useMemo(() => {
    return manifest?.orderSegments.reduce(
      (sum, orderSegment) => sum + orderSegment.order.weight,
      0,
    );
  }, [manifest?.orderSegments]);

  const dimWeight = useMemo(() => {
    return manifest?.orderSegments
      .reduce((sum, orderSegment) => sum + orderSegment.order.dimWeight, 0)
      .toFixed(2);
  }, [manifest?.orderSegments]);

  if (isNil(manifest)) {
    return null;
  }

  return (
    <Stack flex="1" direction="row" sx={{ fontSize: 12 }} gap={1}>
      <Stack direction="row" width="15%" justifyContent="flex-end" gap={1}>
        <Typography sx={{ flex: 1, fontWeight: 600 }} textAlign="right">
          {pieces}
        </Typography>
        <Typography sx={{ width: '30%', opacity: 0.5 }} textAlign="right">
          {pluralize('pc', pieces)}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        width="30%"
        justifyContent="flex-end"
        textAlign="right"
        gap={1}
      >
        <Typography sx={{ flex: 1, fontWeight: 600 }} textAlign="right">
          {manifest?.orderSegments.length}
        </Typography>
        <Typography sx={{ opacity: 0.5 }} textAlign="right">
          {pluralize('order', manifest.orderSegments.length)}
        </Typography>
      </Stack>
      <Stack direction="row" width="25%" justifyContent="flex-end" gap={1}>
        <Typography sx={{ flex: 1, fontWeight: 600 }} textAlign="right">
          {weight?.toFixed(2) ?? '0'}
        </Typography>
        <Typography sx={{ opacity: 0.5 }} textAlign="right">
          {pluralize('lb', weight)}
        </Typography>
      </Stack>
      <Stack direction="row" width="30%" justifyContent="flex-end" gap={1}>
        <Typography sx={{ flex: 1, fontWeight: 600 }} textAlign="right">
          {dimWeight}
        </Typography>
        <Typography sx={{ opacity: 0.5 }} textAlign="right">
          DIM wt.
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ManifestLoadSummary;
