import dayjs from 'dayjs';
import { type Resolver, useForm } from 'react-hook-form';

export type MarkStopAsArrivedFormValues = {
  arrivedDate: Date | undefined;
};

export type MarkStopAsArrivedFormContext = {
  defaultDate?: Date;
};

const resolver: Resolver<MarkStopAsArrivedFormValues> = async (formValues) => {
  const errors: {
    [property in keyof MarkStopAsArrivedFormValues]?: {
      type: string;
      message: string;
    };
  } = {};
  if (!dayjs(formValues.arrivedDate).isValid()) {
    errors.arrivedDate = {
      type: 'required',
      message: 'Date arrived is required',
    };
  }

  return {
    values: formValues,
    errors,
  };
};

const useMarkStopAsArrivedForm = ({
  context,
}: {
  context: MarkStopAsArrivedFormContext;
}) => {
  return useForm({
    resolver,
    context,
    defaultValues: { arrivedDate: context.defaultDate },
  });
};

export default useMarkStopAsArrivedForm;
