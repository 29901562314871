import SearchIcon from '@mui/icons-material/Search';
import { Button, FormControl, InputAdornment, TextField } from '@mui/material';
import React, { useState } from 'react';
import { isNilOrEmptyString } from 'shared/string';
import ClearTextFieldButton from '../../../../common/components/clear-text-field-button';

type OrderTableSearchInputProps = {
  readonly initialSearchText: string | undefined;
  readonly onSearch: (searchText?: string) => void;
  readonly hideSearchButton?: boolean;
  readonly currentViewName: string | null | undefined;
  readonly currentTab: string | null | undefined;
};

const OrderTableSearchInput = ({
  initialSearchText,
  onSearch,
  hideSearchButton,
  currentViewName,
  currentTab,
}: OrderTableSearchInputProps) => {
  const [searchText, setSearchText] = useState<string>(initialSearchText ?? '');
  const currentViewOrTabName = currentViewName ?? currentTab ?? '';
  const placeholder = `Search ${isNilOrEmptyString(currentViewOrTabName) ? '' : `${currentViewOrTabName} `}orders`;

  return (
    <>
      <FormControl>
        <TextField
          size="small"
          placeholder={placeholder}
          InputProps={{
            style: { backgroundColor: 'white' },
            sx: { '& input': { textOverflow: 'ellipsis' } },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <ClearTextFieldButton
                searchText={searchText}
                handleClearSearchText={() => {
                  setSearchText('');
                  onSearch('');
                }}
              />
            ),
          }}
          value={searchText}
          sx={{ width: '250px' }}
          onKeyDown={async (e) => {
            if (e.key === 'Enter') {
              onSearch(searchText);
            }
          }}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
      </FormControl>
      {hideSearchButton !== true && (
        <Button
          variant="contained"
          onClick={() => {
            onSearch(searchText);
          }}
        >
          Search
        </Button>
      )}
    </>
  );
};

export default OrderTableSearchInput;
