import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EditLocationOutlinedIcon from '@mui/icons-material/EditLocationAltOutlined';
import { Box, IconButton, Menu, MenuItem, useTheme } from '@mui/material';
import React, { type Dispatch, type SetStateAction, useState } from 'react';
import { shallow } from 'zustand/shallow';
import {
  type StopOnRouteFragment,
  StopStatus,
} from '../../../../generated/graphql';
import useDispatchStore from '../../dispatch-store';
import { useUnassignStopsRouteActions } from '../../hooks/use-unassign-stops-route-actions';
import RouteStopAssignInput from './route-stop-assign-input';

const RouteStopCardHoverMenu = ({
  stop,
  setIsEditing,
  showEdit = true,
}: {
  readonly stop: StopOnRouteFragment;
  readonly setIsEditing: Dispatch<SetStateAction<boolean>>;
  readonly showEdit?: boolean;
}) => {
  const theme = useTheme();
  const [reassignMenuRef, setReassignMenuRef] = useState<HTMLElement | null>(
    null,
  );
  const reassignMenuRefOpen = Boolean(reassignMenuRef);
  const { unassignStop } = useUnassignStopsRouteActions();
  const [setShowUnassignedSnackbar, setErrorMessage] = useDispatchStore(
    (state) => [state.setShowUnassignedSnackbar, state.setErrorMessage],
    shallow,
  );

  const closeReassignMenu = () => {
    setReassignMenuRef(null);
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: -5,
        right: -1,
        border: `1px solid ${theme.palette.borderColor.main}`,
        borderRadius: '5px',
        backgroundColor: 'white',
        zIndex: 1000,
      }}
    >
      {stop.status === StopStatus.NotArrived && (
        <>
          {showEdit && (
            <IconButton
              sx={{ p: '5px' }}
              onClick={async (e) => {
                setIsEditing(true);
                e.stopPropagation();
              }}
            >
              <EditIcon sx={{ fontSize: '20px' }} />
            </IconButton>
          )}
          <IconButton
            sx={{ p: '5px' }}
            onClick={async (e) => {
              setReassignMenuRef(e.currentTarget);
              e.stopPropagation();
            }}
          >
            <EditLocationOutlinedIcon sx={{ fontSize: '20px' }} />
          </IconButton>
        </>
      )}
      {stop.status !== StopStatus.Completed && (
        <IconButton
          sx={{ p: '5px' }}
          onClick={async (e) => {
            const success = await unassignStop({
              routeUuid: stop.routeSlot?.route?.uuid,
              slotUuid: stop.routeSlot?.uuid,
              stopUuid: stop.uuid,
              refetch: true,
              emitMultiplayerEvent: true,
            });
            if (success) {
              setShowUnassignedSnackbar(true);
            } else {
              setErrorMessage(
                'Failed to unassign stop because the route has changed - please try again.',
              );
            }
            e.stopPropagation();
          }}
        >
          <DeleteIcon sx={{ fontSize: '20px' }} />
        </IconButton>
      )}
      {reassignMenuRefOpen && (
        <Menu
          anchorEl={reassignMenuRef}
          open={reassignMenuRefOpen}
          onClose={closeReassignMenu}
        >
          <MenuItem sx={{ width: '300px' }}>
            <RouteStopAssignInput
              stopUuids={[stop.uuid]}
              currentRouteUuid={stop.routeSlot?.route?.uuid}
            />
          </MenuItem>
        </Menu>
      )}
    </Box>
  );
};

export default RouteStopCardHoverMenu;
