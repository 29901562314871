import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import { Stack, TextField, Typography } from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import React, { useState, useEffect, memo } from 'react';
import { DispatchMultiplayerAction } from '../../../../common/multiplayer/types/dispatch';
import useMultiplayer from '../../../../common/multiplayer/use-multiplayer';
import {
  type RouteFragment,
  useEquipmentsQuery,
  useUpdateVehicleForRouteMutation,
} from '../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import useFetchRoutes from '../../hooks/use-fetch-routes';

type EquipmentOption = {
  value: string | undefined;
  label: string | undefined;
};

const RouteVehicleField = ({
  route,
  forceEdit = false,
  arrangeStopsAndRoutesVertically = false,
}: {
  readonly route: RouteFragment;
  readonly forceEdit?: boolean;
  readonly arrangeStopsAndRoutesVertically?: boolean;
}) => {
  const { sendDispatchUserLocationEvent } = useMultiplayer();
  const { data: equipmentsData } = useEquipmentsQuery();
  const [selectedEquipmentOption, setSelectedEquipmentOption] =
    useState<EquipmentOption>();
  const [showInput, setShowInput] = useState<boolean>(false);
  const { fetchRoute } = useFetchRoutes();
  const [updateVehicleForRoute] = useUpdateVehicleForRouteMutation();

  useEffect(() => {
    setShowInput(forceEdit);
  }, [forceEdit]);

  useEffect(() => {
    if (forceEdit && !showInput) {
      setShowInput(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showInput]);

  useEffect(() => {
    const currentEquipment = route.equipments[0];
    if (isNil(currentEquipment)) {
      setSelectedEquipmentOption(undefined);
    } else {
      setSelectedEquipmentOption({
        value: currentEquipment.uuid,
        label: currentEquipment.name,
      });
      if (isNil(selectedEquipmentOption)) {
        setShowInput(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  const updateEquipment = async (newOption: EquipmentOption | null) => {
    const equipmentUuid = newOption?.value;
    if (!isNil(equipmentUuid)) {
      await updateVehicleForRoute({
        variables: {
          routeUuid: route.uuid,
          vehicleUuid: equipmentUuid,
        },
      });

      fetchRoute(route.uuid);
    }
  };

  const vehiclesString = route.equipments
    .map(
      (equipment) =>
        `${equipment.name}${
          isEmpty(equipment.vehicleType?.name)
            ? ''
            : ` | ${equipment.vehicleType?.name}`
        }`,
    )
    .join(', ');

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent={
        showInput || arrangeStopsAndRoutesVertically ? 'flex-start' : 'flex-end'
      }
      spacing={0.5}
    >
      {(!showInput || route.locked) && isEmpty(route.equipments) && (
        <Typography noWrap variant="caption">
          No vehicle
        </Typography>
      )}
      {(!showInput || route.locked) && !isEmpty(route.equipments) && (
        <Typography noWrap variant="caption">
          {vehiclesString}
        </Typography>
      )}
      {showInput && !route.locked && (
        <>
          <LocalShippingOutlinedIcon color="primary" sx={{ fontSize: 17 }} />
          <AutocompleteFuzzy
            size="small"
            sx={{
              minWidth: '120px',
            }}
            value={selectedEquipmentOption}
            options={
              equipmentsData?.equipments
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map((equipment) => ({
                  label: equipment.name,
                  value: equipment.uuid,
                })) ?? []
            }
            matchSortOptions={{ keys: ['label'] }}
            renderInput={(params) => (
              <TextField
                sx={{ mt: 0.5 }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                variant="standard"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            )}
            onFocus={() => {
              sendDispatchUserLocationEvent({
                action: DispatchMultiplayerAction.EDITING,
                routeUuid: route.uuid,
              });
            }}
            onChange={(event, option) => {
              setSelectedEquipmentOption(option ?? undefined);
              updateEquipment(option);
              event.stopPropagation();
            }}
          />
        </>
      )}
    </Stack>
  );
};

export default memo(RouteVehicleField);
