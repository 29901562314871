import CheckIcon from '@mui/icons-material/Check';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Typography, Box, Fade, IconButton, TextField } from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { type OrderFormFieldValues } from '../../forms/types';

const DimFactor = ({
  dimFactor,
}: {
  readonly dimFactor: number | null | undefined;
}) => {
  const [dimFactorInput, setDimFactorInput] = useState(
    dimFactor?.toString() ?? '',
  );
  const [isEditingDimFactor, setIsEditingDimFactor] = useState(false);
  const [isHoveringOverDimFactor, setIsHoveringOverDimFactor] = useState(false);
  const { setValue } = useFormContext<OrderFormFieldValues>();

  const DimFactorEditComponent = (
    <TextField
      size="small"
      value={dimFactorInput}
      sx={{ width: '100px', ml: '5px' }}
      onChange={(e) => {
        setDimFactorInput(e.target.value);
        const parsedInput = Number.parseFloat(e.target.value);
        if (!Number.isNaN(parsedInput)) {
          setValue('dimFactor', parsedInput);
        }
      }}
    />
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      onMouseEnter={() => {
        setIsHoveringOverDimFactor(true);
      }}
      onMouseLeave={() => {
        setIsHoveringOverDimFactor(false);
      }}
    >
      <Typography
        sx={{
          ml: 'auto',
          color: 'rgba(0, 0, 0, 0.6)',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        Dim factor is {isEditingDimFactor ? DimFactorEditComponent : dimFactor}
      </Typography>
      <Fade in={isHoveringOverDimFactor || isEditingDimFactor}>
        <IconButton
          onClick={() => {
            setIsEditingDimFactor(!isEditingDimFactor);
          }}
        >
          {isEditingDimFactor ? <CheckIcon /> : <ModeEditIcon />}
        </IconButton>
      </Fade>
    </Box>
  );
};

export default DimFactor;
