import {
  datadogRum,
  type RumEvent,
  type RumEventDomainContext,
} from '@datadog/browser-rum';
import { isNil } from 'lodash';
import { filterNotNil } from 'shared/array';
import { getHeaderByNameFromInit } from '../utils';

export const OPERATION_NAME_HEADER = 'x-apollo-operation-name';

const RESOURCE_URLS_TO_IGNORE = [
  'fullstory.com',
  'sentry.io',
  'assets/', // static JS / CSS / assets for the frontend
  'cdn.jsdelivr.net', // fonts
];

const shouldIgnoreResource = (event: RumEvent) => {
  if (event.type === 'resource') {
    return RESOURCE_URLS_TO_IGNORE.some((url) =>
      event.resource.url.includes(url),
    );
  }
  return false;
};

const attachApolloOperationDetails = (
  event: RumEvent,
  context: RumEventDomainContext,
) => {
  // All apollo requests are fetch resources
  if (
    event.type === 'resource' &&
    event.resource.type === 'fetch' &&
    'requestInit' in context
  ) {
    // the operation name is stored by the apollo client operationNameLink. Setting it in the context here allows us to log it with the context and track this as a facet in datadog
    const headers = context.requestInit?.headers;
    const operationName = isNil(headers)
      ? null
      : getHeaderByNameFromInit(headers, OPERATION_NAME_HEADER);
    if (!isNil(operationName)) {
      // eslint-disable-next-line no-param-reassign
      event.context = {
        ...event.context,
        operationName,
      };
    }
  }
};

// MUST be called before apollo client is initialized https://docs.datadoghq.com/real_user_monitoring/browser/troubleshooting/#missing-data
export const initializeDatadogRum = () => {
  datadogRum.init({
    applicationId: 'e4a79639-5631-4190-ba21-695bbf3aab5f',
    clientToken: 'pub8456db1265c6bd9d1bdd2d4f279f97e4',
    site: 'datadoghq.com',
    service: 'tms-web',
    env: import.meta.env.VITE_APP_ENV,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: filterNotNil([import.meta.env.VITE_BACKEND_URL]),
    version: import.meta.env.VITE_RENDER_GIT_COMMIT,
    beforeSend: (event, context) => {
      if (shouldIgnoreResource(event)) {
        return false; // do not record rum event
      }
      attachApolloOperationDetails(event, context);
      return true;
    },
  });
};

// we call this once the user logs in and we can associate the session with a user
export const setupDatadogForUser = ({
  userUuid,
  email,
  companyUuid,
  isThirdPartyUser,
}: {
  userUuid?: string;
  email?: string;
  companyUuid?: string;
  isThirdPartyUser?: boolean;
}) => {
  datadogRum.setUser({
    userUuid,
    email,
    companyUuid,
    isThirdPartyUser,
  });
  datadogRum.startSessionReplayRecording();
};
