import { Stack, TextField } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import React, { useEffect, useState, memo } from 'react';
import { useDebounce } from 'use-debounce';
import { usePaginatedContactsLazyQuery } from '../../../../../generated/graphql';
import ContactAccordion from '../contacts-selector/contact-accordion';

const CONTACTS_ROWS_PER_PAGE = 50;

const ContactAccordionsList = () => {
  const [getPaginatedContacts, { data: contactsData, loading }] =
    usePaginatedContactsLazyQuery();
  const [searchContactsText, setSearchContactsText] = useState('');
  const [page, setPage] = useState(0);

  const [debouncedSearchText] = useDebounce(searchContactsText, 200);

  const fetchContacts = async ({
    first,
    after,
    last,
    before,
  }: {
    first?: number | null | undefined;
    after?: string | null | undefined;
    last?: number | null | undefined;
    before?: string | null | undefined;
  }) => {
    await getPaginatedContacts({
      variables: {
        first,
        after,
        last,
        before,
        searchText: debouncedSearchText,
        sortByContactName: true,
      },
    });
  };

  const prev = async () => {
    await fetchContacts({
      last: CONTACTS_ROWS_PER_PAGE,
      before: contactsData?.paginatedContacts.pageInfo.startCursor ?? undefined,
    });
  };
  const next = async () => {
    await fetchContacts({
      first: CONTACTS_ROWS_PER_PAGE,
      after: contactsData?.paginatedContacts.pageInfo.endCursor ?? undefined,
    });
  };

  const refresh = () => {
    fetchContacts({ first: CONTACTS_ROWS_PER_PAGE });
    setPage(0);
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText]);

  return (
    <Stack sx={{ border: 'solid 0.5px black', height: '100%' }}>
      <TextField
        size="small"
        id="outlined-basic"
        variant="outlined"
        sx={{ width: '100%', p: 1 }}
        placeholder="Search"
        value={searchContactsText}
        onChange={(event) => {
          setSearchContactsText(event.target.value);
        }}
      />
      <TablePagination
        labelRowsPerPage=""
        component="div"
        count={contactsData?.paginatedContacts.totalCount ?? 0}
        rowsPerPage={CONTACTS_ROWS_PER_PAGE}
        page={page}
        rowsPerPageOptions={[]}
        backIconButtonProps={{
          disabled: loading || page === 0,
        }}
        nextIconButtonProps={{
          disabled:
            loading ||
            contactsData?.paginatedContacts.totalCount === 0 ||
            page + 1 ===
              Math.ceil(
                (contactsData?.paginatedContacts.totalCount ?? 0) /
                  CONTACTS_ROWS_PER_PAGE,
              ),
        }}
        onPageChange={(_, newPage: number) => {
          if (newPage > page) {
            next();
          } else {
            prev();
          }
          setPage(newPage);
        }}
      />
      <Stack
        direction="column"
        width="100%"
        flex="1"
        sx={{ overflowY: 'scroll' }}
      >
        {contactsData?.paginatedContacts.edges.map(({ node: contact }) => (
          <ContactAccordion
            contactName={contact.displayName}
            contactUuid={contact.uuid}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default memo(ContactAccordionsList);
