import { Box, Button, Dialog, Typography } from '@mui/material';
import { sentenceCase } from 'change-case';
import { useState, type Dispatch, type SetStateAction } from 'react';
import {
  ThirdPartyUsersByContactDocument,
  useRemoveThirdPartyUserFromContactMutation,
} from '../../../../generated/graphql';

const DeleteThirdPartyUserModal = ({
  isDeleteUserModalOpen,
  setIsDeleteUserModalOpen,
  userUuid,
  email,
  contactUuid,
}: {
  readonly isDeleteUserModalOpen: boolean;
  readonly setIsDeleteUserModalOpen: Dispatch<SetStateAction<boolean>>;
  readonly userUuid: string;
  readonly email: string;
  readonly contactUuid: string;
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [removeThirdPartyUserFromContact] =
    useRemoveThirdPartyUserFromContactMutation({
      refetchQueries: [ThirdPartyUsersByContactDocument],
    });

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isDeleteUserModalOpen}
      onClose={() => {
        setIsDeleteUserModalOpen(false);
      }}
    >
      <Box sx={{ textAlign: 'center', padding: '20px' }}>
        <Typography variant="h5">
          Remove {email} as a third party user
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            gap: '20px',
            margin: '20px',
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              setIsDeleteUserModalOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={async () => {
              const res = await removeThirdPartyUserFromContact({
                variables: {
                  removeThirdPartyUserFromContactInput: {
                    userUuid,
                    contactUuid,
                  },
                },
              });
              setErrorMessage(sentenceCase(res.errors?.join(', ') ?? ''));
              setIsDeleteUserModalOpen(false);
            }}
          >
            Confirm
          </Button>
        </Box>
        <Typography sx={{ color: 'red' }}>{errorMessage}</Typography>
      </Box>
    </Dialog>
  );
};

export default DeleteThirdPartyUserModal;
