import { IconButton, Tooltip } from '@mui/material';
import { type ColumnApi } from 'ag-grid-community';
import { isNil } from 'lodash';
import React from 'react';
import useLocalStorageState from 'use-local-storage-state';
import WidthArrows from '../../common/icons/width-arrows.svg?react';
import { ORDERS_TABLE_COLUMN_WIDTHS_LOCAL_STORAGE_KEY } from '../../common/local-storage/keys';

type ResetGridColumnWidthsButtonProps = {
  readonly columnApi: ColumnApi;
};

const ResetGridColumnWidthsButton = ({
  columnApi,
}: ResetGridColumnWidthsButtonProps) => {
  const [, setColumnWidths] = useLocalStorageState<
    Array<{ width: number | undefined; colId: string }> | undefined
  >(ORDERS_TABLE_COLUMN_WIDTHS_LOCAL_STORAGE_KEY, {
    defaultValue: [],
  });
  const autoSizeAllColumns = () => {
    columnApi.autoSizeAllColumns();

    const columnState = columnApi.getColumnState();
    if (isNil(columnState)) return;
    setColumnWidths(
      columnState.map((column) => ({
        width: column.width,
        colId: column.colId,
      })),
    );
  };

  return (
    <Tooltip arrow title="Resize columns" placement="top">
      <IconButton size="small" onClick={autoSizeAllColumns}>
        <WidthArrows fill="#757575" />
      </IconButton>
    </Tooltip>
  );
};

export default ResetGridColumnWidthsButton;
