export type DeepStringified<T> = {
  [K in keyof T]: T[K] extends Record<string, unknown>
    ? DeepStringified<T[K]>
    : string;
};

export enum BillingMethod {
  Percentage = 'PERCENTAGE',
  FlatRate = 'FLAT_RATE',
}

export type PaginationArgs = {
  first?: number | null | undefined;
  after?: string | null | undefined;
  last?: number | null | undefined;
  before?: string | null | undefined;
};

export type AutocompleteOption = {
  value: string;
  label: string;
};

export type DriverOption = {
  value: string | undefined;
  label: string | undefined;
  terminal?: string | null | undefined;
};

export type Option<ValueT = string> = {
  label: string;
  value: ValueT;
};

export enum Size {
  xs = 'extra-small',
  sm = 'small',
  md = 'medium',
}
