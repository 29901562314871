import { useCallback } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../../../common/react-hooks/use-user-roles';
import {
  OrderDetailedStatus,
  PermissionResource,
} from '../../../../../generated/graphql';

/**
 * What level of edit access the user has for an order, based on the order
 * status and the user's permissions.
 */
export enum OrderFormEditAccess {
  /** The user cannot edit any fields. */
  None = 'None',
  /** The user can edit all fields. */
  All = 'All',
  /** The user can only edit fields that don't affect charges. */
  FinalizedOrOnInvoice = 'FinalizedOrOnInvoice',
  /** The user can only edit the HAWB and Notes fields. */
  InvoicePosted = 'InvoicePosted',
}

/**
 * Convenience method that converts OrderFormEditAccess to a set of
 * "disabled if ____" values that can be used directly in a `disabled` prop
 * instead of manually checking editAccess everywhere you want to disable a field.
 *
 * It might not be in the name, but all of these are disabled if
 * editAccess === OrderFormEditAccess.None
 * */
export const getDisabledForEditAccess = ({
  editAccess,
}: {
  editAccess: OrderFormEditAccess;
}) => {
  const disabledIfNoAccess = editAccess === OrderFormEditAccess.None;
  const disabledIfInvoicePosted =
    disabledIfNoAccess || editAccess === OrderFormEditAccess.InvoicePosted;
  const disabledIfFinalizedOrLater =
    disabledIfNoAccess ||
    disabledIfInvoicePosted ||
    editAccess === OrderFormEditAccess.FinalizedOrOnInvoice;
  return {
    /** Disable this field if the user has no write access to orders */
    disabledIfNoAccess,
    /**
     * Disable this field if one of these is true:
     * - The user has no write access to orders
     * - The order is finalized
     * - The order is on an invoice (posted or unposted)
     * */
    disabledIfFinalizedOrLater,
    /**
     * Disable this field if one of these is true:
     * - The user has no write access to orders
     * - The order is on a posted invoice
     */
    disabledIfInvoicePosted,
  };
};

/**
 * Returns the level of edit access the user has for an order, based on the
 * order status and the user's permissions. See OrderFormEditAccess for details.
 *
 * @param detailedStatus
 * @param canWriteOrders
 * @param canFinalizeCharges
 */
const getOrderFormEditAccess = ({
  detailedStatus,
  canWriteOrders,
  canFinalizeCharges,
}: {
  detailedStatus: OrderDetailedStatus | undefined | null;
  canWriteOrders: boolean;
  canFinalizeCharges: boolean;
}): OrderFormEditAccess => {
  if (!canWriteOrders) {
    return OrderFormEditAccess.None;
  }
  switch (detailedStatus) {
    case OrderDetailedStatus.ChargesFinalized:
    case OrderDetailedStatus.OnInvoice: {
      if (canFinalizeCharges) {
        return OrderFormEditAccess.All;
      }
      return OrderFormEditAccess.FinalizedOrOnInvoice;
    }
    case OrderDetailedStatus.InvoicePosted: {
      return OrderFormEditAccess.InvoicePosted;
    }
    default: {
      return OrderFormEditAccess.All;
    }
  }
};

export const useGetOrderFormEditAccess = () => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Orders,
  );
  const { canWrite: canFinalizeCharges } = getPermissionsFlags(
    userPermissions,
    PermissionResource.FinalizeChargesOrders,
  );

  const getOrderFormEditAccessCallback = useCallback(
    ({
      detailedStatus,
    }: {
      detailedStatus: OrderDetailedStatus | undefined | null;
    }) =>
      getOrderFormEditAccess({
        detailedStatus,
        canWriteOrders,
        canFinalizeCharges,
      }),
    [canFinalizeCharges, canWriteOrders],
  );

  return {
    /**
     * Returns the level of edit access the user has for an order, based on the
     * order status and the user's permissions. See OrderFormEditAccess for details.
     *
     * @param detailedStatus
     */
    getOrderFormEditAccess: getOrderFormEditAccessCallback,
  };
};
