import { Button, Stack, type SxProps, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useTerminals from '../../../../common/react-hooks/use-terminals';
import { useCreateLineHaulLaneMutation } from '../../../../generated/graphql';
import LineHaulEditor from './line-haul-editor';
import DestinationOriginSelectors from './select-origin-destination';

const styles = {
  container: {
    bgcolor: 'background.paper',
    // boxShadow: 24,
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '40px',
    p: 4,
  } as SxProps,
  terminalCard: {
    border: 'solid 1px black',
    padding: '50px',
    position: 'relative',
  },
  editorContainer: {
    width: '80%',
    justifyContent: 'space-between',
  },
};

const CreateLineHaulLane = () => {
  const navigate = useNavigate();

  const [originTerminalUuid, setOriginTerminalUuid] = useState<
    string | undefined
  >(undefined);
  const [destinationTerminalUuid, setDestinationTerminalUuid] = useState<
    string | undefined
  >(undefined);

  const [intermediateTerminalUuids, setIntermediateTerminalUuids] = useState<
    string[] | undefined
  >(undefined);

  const { terminals } = useTerminals({
    includeInactiveTerminals: false,
  });
  const [errorMessage, setErrorMessage] = useState('');

  const goBack = async () => {
    navigate('/management?tab=7');
  };

  const [createLineHaulLane] = useCreateLineHaulLaneMutation();

  const createLane = async () => {
    if (isNil(originTerminalUuid) || isNil(destinationTerminalUuid)) {
      return;
    }

    const res = await createLineHaulLane({
      variables: {
        createLineHaulLaneInput: {
          lineHaulLaneCreateInput: {
            destinationTerminalUuid,
            originTerminalUuid,
            intermediateTerminalUuids: intermediateTerminalUuids ?? [],
          },
        },
      },
    });

    const error = res.data?.createLineHaulLane.errorMessage;
    if (isNil(error)) {
      goBack();
    } else {
      setErrorMessage(error);
    }
  };

  if (isNil(terminals)) {
    return null;
  }

  return (
    <Stack sx={styles.container}>
      <Button variant="outlined" color="primary" onClick={goBack}>
        Back
      </Button>
      <Typography variant="h6"> Create new line haul lane</Typography>
      <Stack direction="row" gap="30px" width="100%">
        <DestinationOriginSelectors
          originTerminalUuid={originTerminalUuid}
          setOriginTerminalUuid={setOriginTerminalUuid}
          destinationTerminalUuid={destinationTerminalUuid}
          setDestinationTerminalUuid={setDestinationTerminalUuid}
          intermediateTerminalUuids={intermediateTerminalUuids}
          terminals={terminals}
          setErrorMessage={setErrorMessage}
        />
      </Stack>
      <LineHaulEditor
        isCreateMode
        originTerminalUuid={originTerminalUuid}
        destinationTerminalUuid={destinationTerminalUuid}
        terminals={terminals}
        intermediateTerminalUuids={intermediateTerminalUuids}
        setIntermediateTerminalUuids={setIntermediateTerminalUuids}
      />
      <Button
        sx={{ alignSelf: 'center', width: '20%', mt: '50px' }}
        variant="contained"
        onClick={createLane}
      >
        Save
      </Button>
      <Typography sx={{ color: 'red', alignSelf: 'center' }}>
        {errorMessage}
      </Typography>
    </Stack>
  );
};

export default CreateLineHaulLane;
