import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import useContacts from '../../../common/react-hooks/use-contacts';
import useServices from '../../../common/react-hooks/use-services';
import { type FindTariffGroupsWithContactAndServicesCombinationOutput } from '../../../generated/graphql';
import styles from './tariffs/styles';

const WarningDuplicateContactServicesModal = ({
  warningModalContent,
  closeWarningModal,
}: {
  readonly warningModalContent:
    | FindTariffGroupsWithContactAndServicesCombinationOutput[]
    | undefined;
  readonly closeWarningModal: () => void;
}) => {
  const { getContactName } = useContacts();
  const { getServiceName } = useServices();

  // don't show contacts where the only conflicting group is the current one
  // const cleanedUpContent = warningModalContent
  //   ?.map((content) => {
  //     return {
  //       ...content,
  //       matchingTariffGroups: content.matchingTariffGroups.filter(
  //         (group) => group.tariffGroupUuid !== currentGroupUuid,
  //       ),
  //     };
  //   })
  //   .filter((content) => content.matchingTariffGroups.length > 0);

  return (
    <Modal open={!isNil(warningModalContent)} onClose={closeWarningModal}>
      <Box sx={styles.modal}>
        <Stack gap="20px" overflow="scroll">
          <Stack direction="row" gap="10px" alignItems="center">
            <WarningIcon color="error" />
            <Typography variant="h5">Error saving this tariff:</Typography>
          </Stack>

          <Typography variant="h6">
            Some of the service levels selected conflict with other tariffs
          </Typography>
          {warningModalContent?.map((content, idx) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Stack key={idx}>
                <Accordion
                  disableGutters
                  elevation={0}
                  sx={{ border: 'solid 1px black' }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Stack
                      direction="row"
                      width="100%"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="h6">
                        {' '}
                        {getContactName(content.contactUuid)}{' '}
                      </Typography>
                      <Typography> see conflicts </Typography>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack gap="10px">
                      {content.matchingTariffGroups.map((group, idx2) => {
                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <Accordion key={idx2} disableGutters elevation={0}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Stack direction="row" gap="5px">
                                <Typography>
                                  {group.serviceUuids.length} conflicts with the
                                </Typography>
                                <Typography fontWeight={600}>
                                  {group.tariffGroupName}
                                </Typography>
                                <Typography>tariff</Typography>
                              </Stack>
                            </AccordionSummary>
                            <AccordionDetails>
                              {/* <Stack></Stack>{' '} */}
                              {group.serviceUuids.map((uuid) => (
                                <Typography key={uuid} pl="10px">
                                  {sentenceCase(getServiceName(uuid))}
                                </Typography>
                              ))}{' '}
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}
                    </Stack>
                  </AccordionDetails>
                </Accordion>
                {/* Tariff name: {content.tariffGroup.name}
                {content.serviceUuids.map((uuid) => (
                  <Typography pl="10px">
                    {sentenceCase(getServiceName(uuid))} for{' '}
                    {getContactName(content.contactUuid)}
                  </Typography>
                ))} */}
              </Stack>
            );
          })}
        </Stack>
      </Box>
    </Modal>
  );
};

export default WarningDuplicateContactServicesModal;
