import {
  Box,
  Card,
  Checkbox,
  CircularProgress,
  Stack,
  Table,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { isEmpty, isNil } from 'lodash';
import {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDebounce } from 'use-debounce';
import {
  FilterOperator,
  type OrderForConsolidationFlowFragment,
  OrderStatus,
  OrderStatusFilterType,
  StringFilterType,
  useTypeaheadOrderUuidsQueryForConsolidationFlowLazyQuery,
} from '../../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import {
  HawbLink,
  TableCellCustom,
  TableHeadCustom,
  TableHeaderTypography,
  TableTypography,
} from './common';

const SidebarCardOrderRow = ({
  order,
  isLineHaulEnabled,
  onCheck,
  onUncheck,
  isChecked,
  setOpenedOrderUuid,
  isPrimaryOrder,
}: {
  readonly order: OrderForConsolidationFlowFragment;
  readonly isLineHaulEnabled: boolean;
  readonly onCheck: () => void;
  readonly onUncheck: () => void;
  readonly isChecked: boolean;
  readonly setOpenedOrderUuid: (uuid: string) => void;
  readonly isPrimaryOrder: boolean;
}) => {
  return (
    <TableRow key={order.uuid}>
      <TableCellCustom width="5%">
        <Checkbox
          disabled={isPrimaryOrder}
          sx={{ m: 0, p: 0 }}
          // always check the primary order
          checked={isChecked || isPrimaryOrder}
          onChange={(e) => {
            if (e.target.checked) {
              onCheck();
            } else {
              onUncheck();
            }
          }}
        />
      </TableCellCustom>
      <TableCellCustom width="15%">
        <Stack>
          <HawbLink
            hawb={order.standardOrderFields.shipperBillOfLadingNumber ?? ''}
            fontSize={14}
            onClick={() => {
              setOpenedOrderUuid(order.uuid);
            }}
          />
          <TableTypography isGrey>{order.name}</TableTypography>
        </Stack>
      </TableCellCustom>
      <TableCellCustom width="7%">
        {order.pieceCountFromPackages}
      </TableCellCustom>
      <TableCellCustom width="10%">{order.weight} lbs</TableCellCustom>
      <TableCellCustom width="10%">{order.dimWeight} lbs</TableCellCustom>
      <TableCellCustom width="22%">
        <Stack>
          <TableTypography>
            {order.inboundShipment?.legs[0]?.endStop.address.name ?? '-'}
          </TableTypography>
          <TableTypography isGrey fontSize="12px">
            {order.inboundShipment?.legs[0]?.endStop.address.line1 ?? '-'}
          </TableTypography>
        </Stack>
      </TableCellCustom>
      <TableCellCustom width="22%">
        <TableTypography>
          {order.outboundShipment?.legs[0]?.endStop.address.name ?? '-'}
        </TableTypography>
        <TableTypography isGrey fontSize="12px">
          {order.outboundShipment?.legs[0]?.endStop.address.line1 ?? '-'}
        </TableTypography>
      </TableCellCustom>
      {isLineHaulEnabled && (
        <TableCellCustom sx={{ flex: 1 }}>
          {order.lineHaulLane?.name ?? '-'}
        </TableCellCustom>
      )}
    </TableRow>
  );
};

const SidebarCard = ({
  orders,
  lineHaulEnabled,
  isPrimaryOrder,
  checkedOrderUuids,
  setCheckedOrderUuids,
  setOpenedOrderUuid,
}: {
  readonly orders: OrderForConsolidationFlowFragment[];
  readonly lineHaulEnabled: boolean;
  readonly isPrimaryOrder: boolean;
  readonly checkedOrderUuids: string[];
  readonly setCheckedOrderUuids: Dispatch<SetStateAction<string[]>>;
  readonly setOpenedOrderUuid: (uuid: string) => void;
}) => {
  if (isEmpty(orders)) return null;

  return (
    <Card variant="outlined">
      <Table>
        {isPrimaryOrder && (
          <TableHeadCustom addBorderBottom>
            <TableCellCustom cellPadding={5} />
            <TableCellCustom cellPadding={5}>
              <TableHeaderTypography>Order</TableHeaderTypography>
            </TableCellCustom>
            <TableCellCustom cellPadding={5}>
              <TableHeaderTypography>Pcs</TableHeaderTypography>
            </TableCellCustom>
            <TableCellCustom cellPadding={5}>
              <TableHeaderTypography>Weight</TableHeaderTypography>
            </TableCellCustom>
            <TableCellCustom cellPadding={5}>
              <TableHeaderTypography>Dim. weight</TableHeaderTypography>
            </TableCellCustom>
            <TableCellCustom cellPadding={5}>
              <TableHeaderTypography>Inbound address</TableHeaderTypography>
            </TableCellCustom>
            <TableCellCustom cellPadding={5}>
              <TableHeaderTypography>Outbound address</TableHeaderTypography>
            </TableCellCustom>
            {lineHaulEnabled && (
              <TableCellCustom cellPadding={5}>
                <TableHeaderTypography>Line haul</TableHeaderTypography>
              </TableCellCustom>
            )}
          </TableHeadCustom>
        )}

        {orders.map((o) => (
          <SidebarCardOrderRow
            key={o.uuid}
            isLineHaulEnabled={lineHaulEnabled}
            order={o}
            isChecked={checkedOrderUuids.includes(o.uuid)}
            setOpenedOrderUuid={setOpenedOrderUuid}
            isPrimaryOrder={isPrimaryOrder}
            onCheck={() => {
              setCheckedOrderUuids([...checkedOrderUuids, o.uuid]);
            }}
            onUncheck={() => {
              setCheckedOrderUuids((uuids_) =>
                uuids_.filter((uuid) => uuid !== o.uuid),
              );
            }}
          />
        ))}
      </Table>
    </Card>
  );
};

const ConsolidatedOrderLeftSidebar = ({
  primaryOrder,
  searchedOrders,
  setSearchedOrders,
  checkedOrderUuids,
  setCheckedOrderUuids,
  setOpenedOrderUuid,
  lineHaulEnabled,
}: {
  readonly primaryOrder: OrderForConsolidationFlowFragment;
  readonly searchedOrders: OrderForConsolidationFlowFragment[];
  readonly setSearchedOrders: Dispatch<
    SetStateAction<OrderForConsolidationFlowFragment[]>
  >;
  readonly checkedOrderUuids: string[];
  readonly setCheckedOrderUuids: Dispatch<SetStateAction<string[]>>;
  readonly setOpenedOrderUuid: (uuid: string) => void;
  readonly lineHaulEnabled: boolean;
}) => {
  const theme = useTheme();

  const [autocompleteKey, setAutocompleteKey] = useState(0);
  const [newSearchText, setNewSearchText] = useState('');
  const versionIdRef = useRef(0);
  const [debouncedNewSearchText] = useDebounce(newSearchText, 400);

  const [getOrderResults, { loading: searchLoading }] =
    useTypeaheadOrderUuidsQueryForConsolidationFlowLazyQuery();

  const [searchOptions, setSearchOptions] = useState<
    Array<{
      value: string;
      label: string;
      object: OrderForConsolidationFlowFragment;
    }>
  >([]);

  useEffect(() => {
    // Create a signal to abort the request if needed
    const controller = new AbortController();
    const { signal } = controller;

    const fetchData = async () => {
      const currentVersionId = versionIdRef.current + 1;
      versionIdRef.current = currentVersionId;

      try {
        const orderData = await getOrderResults({
          variables: {
            searchText: debouncedNewSearchText,
            first: 10,
            statusFilters: [
              {
                status: OrderStatus.Cancelled,
                filterType: OrderStatusFilterType.Equals,
              },
              {
                status: OrderStatus.Created,
                filterType: OrderStatusFilterType.Equals,
              },
              {
                status: OrderStatus.InProgress,
                filterType: OrderStatusFilterType.Equals,
              },
              {
                status: OrderStatus.Delivered,
                filterType: OrderStatusFilterType.Equals,
              },
              {
                status: OrderStatus.Finalized,
                filterType: OrderStatusFilterType.Equals,
              },
              {
                status: OrderStatus.HasIssue,
                filterType: OrderStatusFilterType.Equals,
              },
            ],
            onInvoice: { value: false },
            versionId: currentVersionId,
            billingPartyContactUuidFilter: {
              filterType: StringFilterType.Equals,
              filterOperator: FilterOperator.And,
              value: primaryOrder.billingPartyContact.uuid,
            },
            includeOrdersPartOfV2ConsolidatedOrdersFilter: {
              eq: false,
            },
          },
          context: {
            // Attach the AbortController's signal to the context
            fetchOptions: {
              signal,
            },
          },
        });

        // Check if the response corresponds to the latest request
        if (
          currentVersionId === orderData.data?.orders.versionId &&
          debouncedNewSearchText === newSearchText
        ) {
          const options =
            orderData.data?.orders.edges.map(({ node: order }) => ({
              value: order.uuid,
              label:
                order.standardOrderFields.shipperBillOfLadingNumber ??
                order.name,
              object: order,
            })) ?? [];
          setSearchOptions(options);
        }
      } catch (error) {
        // Check if the error is due to the request being aborted
        captureException(
          `Search error in billing review modal: ${String(error)}`,
        );
      }
    };

    // Trigger the fetchData function
    fetchData();

    // Cleanup function to cancel the previous ongoing operation
    return () => {
      controller.abort();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedNewSearchText]);

  return (
    <Stack width="50%" padding={2} gap={1}>
      <AutocompleteFuzzy
        key={autocompleteKey}
        options={searchLoading ? [] : searchOptions}
        matchSortOptions={{ keys: ['label'] }}
        sx={{ minWidth: '200px' }}
        clearOnBlur={false}
        filterOptions={(x) => x}
        noOptionsText="We couldn't find this order"
        renderOption={(
          props,
          option: {
            value: string;
            label: string;
            object: OrderForConsolidationFlowFragment;
          },
        ) => (
          <li {...props}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
              }}
            >
              <Typography>{option.label}</Typography>
              <Typography
                sx={{ fontSize: '14px', color: theme.palette.grey[400] }}
              >
                {option.object.name}
              </Typography>
            </Box>
          </li>
        )}
        renderInput={(params) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '5px',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <TextField
              onChange={(e) => {
                setNewSearchText(e.target.value);
              }}
              {...params}
              size="small"
              label="Find orders by HAWB"
              value={newSearchText}
            />
            {searchLoading && (
              <CircularProgress
                size={20}
                sx={{
                  position: 'absolute',
                  right: '-25px',
                }}
              />
            )}
          </Box>
        )}
        onChange={async (e, option) => {
          //   await saveCurrentOrder({});
          if (
            !isNil(option) &&
            [...searchedOrders, primaryOrder].every(
              (itrOrder) => itrOrder.uuid !== option.object.uuid,
            )
          ) {
            setSearchedOrders((prevState) => [option.object, ...prevState]);
            setCheckedOrderUuids((prevState) => [
              option.object.uuid,
              ...prevState,
            ]);
          }
          setAutocompleteKey(autocompleteKey + 1);
        }}
      />
      <SidebarCard
        isPrimaryOrder
        orders={[primaryOrder]}
        lineHaulEnabled={lineHaulEnabled}
        checkedOrderUuids={checkedOrderUuids}
        setCheckedOrderUuids={setCheckedOrderUuids}
        setOpenedOrderUuid={setOpenedOrderUuid}
      />
      <SidebarCard
        orders={searchedOrders}
        isPrimaryOrder={false}
        lineHaulEnabled={lineHaulEnabled}
        checkedOrderUuids={checkedOrderUuids}
        setCheckedOrderUuids={setCheckedOrderUuids}
        setOpenedOrderUuid={setOpenedOrderUuid}
      />
    </Stack>
  );
};

export default ConsolidatedOrderLeftSidebar;
