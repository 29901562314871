import {
  Divider,
  ListSubheader,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import React, { type Dispatch, type SetStateAction, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import { PaymentType, PermissionResource } from '../../../../generated/graphql';
import { AgingReportType } from '../accounting-reports/aging-report/utils';
import DownloadAverageDaysToPayReportModal from '../customers/download-average-days-to-pay-report-modal';
import DownloadCheckRegisterReportModal from '../customers/download-check-register-report-modal';
import CreateEmptyInvoiceModal from './create-empty-invoice-modal';
import DownloadAccessorialDetailsReportModal from './download/download-accessorial-details-report-modal';
import DownloadAccountsReceivableReportModal from './download/download-accounts-receivable-report-modal';
import DownloadCustomerChargesReportModal from './download/download-customer-charges-report';
import DownloadIncomeAnalysisReportModal from './download/download-income-analysis-report-modal';
import DownloadInvoiceAgingReportModal from './download/download-invoice-aging-report-modal';
import DownloadInvoiceAgingReportModalOld from './download/download-invoice-aging-report-modal-old';
import DownloadInvoiceBillingSummaryModal from './download/download-invoice-billing-summary-modal';
import DownloadInvoiceRegisterReportModal from './download/download-invoice-register-report-modal';
import DownloadNetSalesReportModal from './download/download-net-sales-report-modal';
import DownloadOpenInvoicesReportModal from './download/download-open-invoices-report-modal';
import DownloadPaymentApplicationReportModal from './download/download-payment-application-report-modal';
import DownloadPaymentJournalReportModal from './download/download-payment-journal-report-modal';
import DownloadUnappliedPaymentsReportModal from './download/download-unapplied-payments-report-modal'; // Added this line
import DownloadUnbilledAndUninvoicedOrdersReportModal from './download/download-unbilled-and-uninvoiced-orders-report-modal';
import DunningModal from './download/dunning-modal';
import { ReportType } from './download/generated-unbilled-revenue-report';

type InvoicesContextMenuProps = {
  readonly anchorEl: null | HTMLElement;
  readonly setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
  readonly selectedInvoiceUuids: string[];
  readonly onClickPostWithoutSending: () => void;
  readonly setShowSendAccountingReportsSuccessMessage: Dispatch<
    SetStateAction<boolean>
  >;
  readonly setShowSendAccountingReportsErrorMessage: Dispatch<
    SetStateAction<boolean>
  >;
};
const InvoicesContextMenu = ({
  anchorEl,
  setAnchorEl,
  selectedInvoiceUuids,
  onClickPostWithoutSending,
  setShowSendAccountingReportsSuccessMessage,
  setShowSendAccountingReportsErrorMessage,
}: InvoicesContextMenuProps) => {
  const [showCreateEmptyInvoiceModal, setShowCreateEmptyInvoiceModal] =
    useState<boolean>(false);

  const { userPermissions } = useUserRoles();
  const { canRead: canReadInvoices, canWrite: canWriteInvoices } =
    getPermissionsFlags(userPermissions, PermissionResource.Invoices);
  const ffUpdatedAgingReport = useFeatureFlag(
    FeatureFlag.FF_SURELOGIX_AGING_REPORT_UPDATES,
  );
  const [
    showDownloadAccountsReceivableReportModal,
    setShowDownloadAccountsReceivableReportModal,
  ] = useState<boolean>(false);
  const [
    showDownloadInvoiceRegisterReportModal,
    setShowDownloadInvoiceRegisterReportModal,
  ] = useState<boolean>(false);
  const [
    showDownloadInvoiceBillingSummaryModal,
    setShowDownloadInvoiceBillingSummaryModal,
  ] = useState<boolean>(false);
  const [
    showDownloadOpenInvoicesReportModal,
    setShowDownloadOpenInvoicesReportModal,
  ] = useState<boolean>(false);
  const [currAgingReportType, setCurrAgingReportType] =
    useState<AgingReportType>(AgingReportType.Detailed);
  const [
    showDownloadCustomerChargesReportModal,
    setShowDownloadCustomerChargesReportModal,
  ] = useState(false);
  const [showDownloadAgingModalOld, setShowDownloadAgingModalOld] =
    useState(false);
  const [showDownloadAgingModal, setShowDownloadAgingModal] = useState(false);
  const [showDunningModal, setShowDunningModal] = useState(false);
  const [showPaymentJournalModal, setShowPaymentJournalModal] = useState(false);
  const [showCreditJournalModal, setShowCreditJournalModal] = useState(false);
  const [showPaymentApplicationModal, setShowPaymentApplicationModal] =
    useState(false);
  const [showUnbilledRevenueModal, setShowUnbilledRevenueModal] =
    useState(false);
  const [showCompletedUninvoicedModal, setShowCompletedUninvoicedModal] =
    useState(false);
  const [showAccessorialDetailsModal, setShowAccessorialDetailsModal] =
    useState(false);
  const [
    showDownloadCheckRegisterReportModal,
    setShowDownloadCheckRegisterReportModal,
  ] = useState<boolean>(false);
  const [
    showDownloadAverageDaysToPayReportModal,
    setShowDownloadAverageDaysToPayReportModal,
  ] = useState<boolean>(false);
  const [showIncomeAnalysisModal, setShowIncomeAnalysisModal] = useState(false);
  const [showDownloadNetSalesReportModal, setShowDownloadNetSalesReportModal] =
    useState(false);
  const [
    showDownloadUnappliedPaymentsReportModal,
    setShowDownloadUnappliedPaymentsReportModal,
  ] = useState<boolean>(false);

  const downloadableReportMenuItems: Array<{
    label: string;
    onClick: () => void;
    hidden: boolean;
  }> = [
    {
      label: 'Accessorial Details Report',
      onClick: () => {
        setShowAccessorialDetailsModal(true);
      },
      hidden: false,
    },
    {
      label: 'Accounts Receivable Report',
      onClick: () => {
        setShowDownloadAccountsReceivableReportModal(true);
      },
      hidden: false,
    },
    {
      label: 'Aging Report (Detailed)',
      onClick: () => {
        if (ffUpdatedAgingReport) {
          setShowDownloadAgingModal(true);
        } else {
          setShowDownloadAgingModalOld(true);
        }
        setCurrAgingReportType(AgingReportType.Detailed);
      },
      hidden: false,
    },
    {
      label: 'Aging Report (Summary)',
      onClick: () => {
        if (ffUpdatedAgingReport) {
          setShowDownloadAgingModal(true);
        } else {
          setShowDownloadAgingModalOld(true);
        }
        setCurrAgingReportType(AgingReportType.Summary);
      },
      hidden: false,
    },
    {
      label: 'Average Days to Pay Report',
      onClick: () => {
        setShowDownloadAverageDaysToPayReportModal(true);
      },
      hidden: false,
    },
    {
      label: 'Check Register Report',
      onClick: () => {
        setShowDownloadCheckRegisterReportModal(true);
      },
      hidden: false,
    },
    {
      label: 'Credit Journal Report',
      onClick: () => {
        setShowCreditJournalModal(true);
      },
      hidden: false,
    },
    {
      label: 'Customer Charges Report',
      onClick: () => {
        setShowDownloadCustomerChargesReportModal(true);
      },
      hidden: false,
    },
    {
      label: 'Dunning Report',
      onClick: () => {
        setShowDunningModal(true);
      },
      hidden: false,
    },
    {
      label: 'Income Analysis Report',
      onClick: () => {
        setShowIncomeAnalysisModal(true);
      },
      hidden: false,
    },
    {
      label: 'Invoice Billing Summary',
      onClick: () => {
        setShowDownloadInvoiceBillingSummaryModal(true);
      },
      hidden: false,
    },
    {
      label: 'Invoice Register Report',
      onClick: () => {
        setShowDownloadInvoiceRegisterReportModal(true);
      },
      hidden: false,
    },
    {
      label: 'Net Sales Summary Report',
      onClick: () => {
        setShowDownloadNetSalesReportModal(true);
      },
      hidden: false,
    },
    {
      label: 'Open Invoices Report',
      onClick: () => {
        setShowDownloadOpenInvoicesReportModal(true);
      },
      hidden: false,
    },
    {
      label: 'Payment Journal Report',
      onClick: () => {
        setShowPaymentJournalModal(true);
      },
      hidden: false,
    },
    {
      label: 'Payment Application Report',
      onClick: () => {
        setShowPaymentApplicationModal(true);
      },
      hidden: false,
    },
    {
      label: 'Unapplied Payments Report',
      onClick: () => {
        setShowDownloadUnappliedPaymentsReportModal(true);
      },
      hidden: false,
    },
    {
      label: 'Unbilled Revenue Report',
      onClick: () => {
        setShowUnbilledRevenueModal(true);
      },
      hidden: false,
    },
    {
      label: 'Uninvoiced Orders Report',
      onClick: () => {
        setShowCompletedUninvoicedModal(true);
      },
      hidden: false,
    },
  ];

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      MenuListProps={{
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'aria-labelledby': 'basic-button',
      }}
      onClose={() => {
        setAnchorEl(null);
      }}
    >
      <DownloadCustomerChargesReportModal
        isOpen={showDownloadCustomerChargesReportModal}
        setIsOpen={setShowDownloadCustomerChargesReportModal}
      />
      <CreateEmptyInvoiceModal
        isOpen={showCreateEmptyInvoiceModal}
        setIsOpen={setShowCreateEmptyInvoiceModal}
      />
      <DownloadAccountsReceivableReportModal
        isOpen={showDownloadAccountsReceivableReportModal}
        setIsOpen={setShowDownloadAccountsReceivableReportModal}
      />
      <DownloadInvoiceRegisterReportModal
        isOpen={showDownloadInvoiceRegisterReportModal}
        setIsOpen={setShowDownloadInvoiceRegisterReportModal}
      />
      <DownloadInvoiceBillingSummaryModal
        isOpen={showDownloadInvoiceBillingSummaryModal}
        setIsOpen={setShowDownloadInvoiceBillingSummaryModal}
      />
      <DownloadOpenInvoicesReportModal
        isOpen={showDownloadOpenInvoicesReportModal}
        setIsOpen={setShowDownloadOpenInvoicesReportModal}
      />
      <DunningModal
        isOpen={showDunningModal}
        setIsOpen={setShowDunningModal}
        setShowSendAccountingReportsSuccessMessage={
          setShowSendAccountingReportsSuccessMessage
        }
        setShowSendAccountingReportsErrorMessage={
          setShowSendAccountingReportsErrorMessage
        }
      />
      <DownloadPaymentJournalReportModal
        open={showPaymentJournalModal}
        setOpen={setShowPaymentJournalModal}
        paymentTypes={[PaymentType.Payment]}
      />
      <DownloadPaymentJournalReportModal
        open={showCreditJournalModal}
        setOpen={setShowCreditJournalModal}
        paymentTypes={[PaymentType.Credit, PaymentType.Debit]}
      />
      <DownloadPaymentApplicationReportModal
        open={showPaymentApplicationModal}
        setOpen={setShowPaymentApplicationModal}
      />
      <DownloadUnbilledAndUninvoicedOrdersReportModal
        open={showUnbilledRevenueModal}
        setOpen={setShowUnbilledRevenueModal}
        reportType={ReportType.UnbilledRevenue}
      />
      <DownloadUnbilledAndUninvoicedOrdersReportModal
        open={showCompletedUninvoicedModal}
        setOpen={setShowCompletedUninvoicedModal}
        reportType={ReportType.UninvoicedOrders}
      />
      <DownloadInvoiceAgingReportModal
        isOpen={showDownloadAgingModal}
        setIsOpen={setShowDownloadAgingModal}
        setShowSendAccountingReportsSuccessMessage={
          setShowSendAccountingReportsSuccessMessage
        }
        setShowSendAccountingReportsErrorMessage={
          setShowSendAccountingReportsErrorMessage
        }
        type={currAgingReportType}
      />
      <DownloadInvoiceAgingReportModalOld
        isOpen={showDownloadAgingModalOld}
        setIsOpen={setShowDownloadAgingModalOld}
        setShowSendAccountingReportsSuccessMessage={
          setShowSendAccountingReportsSuccessMessage
        }
        setShowSendAccountingReportsErrorMessage={
          setShowSendAccountingReportsErrorMessage
        }
        type={currAgingReportType}
      />
      <DownloadAccessorialDetailsReportModal
        isOpen={showAccessorialDetailsModal}
        setIsOpen={setShowAccessorialDetailsModal}
      />
      <DownloadCheckRegisterReportModal
        isOpen={showDownloadCheckRegisterReportModal}
        setIsOpen={setShowDownloadCheckRegisterReportModal}
      />
      <DownloadAverageDaysToPayReportModal
        isOpen={showDownloadAverageDaysToPayReportModal}
        setIsOpen={setShowDownloadAverageDaysToPayReportModal}
      />
      <DownloadIncomeAnalysisReportModal
        isOpen={showIncomeAnalysisModal}
        setIsOpen={setShowIncomeAnalysisModal}
      />
      <DownloadNetSalesReportModal
        isOpen={showDownloadNetSalesReportModal}
        setIsOpen={setShowDownloadNetSalesReportModal}
      />
      <DownloadUnappliedPaymentsReportModal
        open={showDownloadUnappliedPaymentsReportModal}
        setOpen={setShowDownloadUnappliedPaymentsReportModal}
      />
      {canWriteInvoices && (
        <>
          <MenuItem
            onClick={() => {
              onClickPostWithoutSending();
              setAnchorEl(null);
            }}
          >
            Post{' '}
            {selectedInvoiceUuids.length > 0
              ? `(${selectedInvoiceUuids.length})`
              : 'All'}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setShowCreateEmptyInvoiceModal(true);
            }}
          >
            Create Empty Invoice
          </MenuItem>
          <Divider />
        </>
      )}
      {canReadInvoices && (
        <>
          <ListSubheader component="div" sx={{ mb: 1 }}>
            <Typography>Download...</Typography>
          </ListSubheader>
          {downloadableReportMenuItems
            .filter((item) => !item.hidden)
            .map((item) => (
              // eslint-disable-next-line react/jsx-key
              <MenuItem onClick={item.onClick}>{item.label}</MenuItem>
            ))}
        </>
      )}
    </Menu>
  );
};

export default InvoicesContextMenu;
