import { Chip } from '@mui/material';
import { exhaustive } from 'shared/switch';
import { IngestedOutlookEmailAttachmentProcessingStatus } from '../../../../../generated/graphql';

export const IngestedOutlookEmailAttachmentProcessingStatusChip = ({
  status,
}: {
  readonly status: IngestedOutlookEmailAttachmentProcessingStatus | 'All';
}) => {
  switch (status) {
    case IngestedOutlookEmailAttachmentProcessingStatus.Completed: {
      return <Chip size="small" label="Finished Processing" color="success" />;
    }
    case IngestedOutlookEmailAttachmentProcessingStatus.InProgress: {
      return <Chip size="small" label="In Progress" color="info" />;
    }
    case IngestedOutlookEmailAttachmentProcessingStatus.Pending: {
      return <Chip size="small" label="Pending" color="warning" />;
    }
    case 'All': {
      return <Chip size="small" label="All" color="default" />;
    }
    default: {
      return exhaustive(status);
    }
  }
};
