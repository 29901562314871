import { View, Page, Document, Text, StyleSheet } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { safeAdd, safeMultiply } from 'shared/math';
import { type DateOption } from '../../../../../common/components/date-dropdown-picker';
import {
  type AccountsReceivableDataFragment,
  type CompanyFragment,
} from '../../../../../generated/graphql';
import { type BalanceCalculationType } from '../../accounting-reports/constants';
import { formatDateOption } from './utils';

const CELL_MARGIN = '10px';
const PAGE_BLOCK_SIZE = '300px';
const TEXT_PADDING = '3px';

const TOTALS_HEADERS = ['Invoices', 'Credits', 'Debits', 'Payments', 'Balance'];

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 24,
    fontFamily: 'Roboto',
  },
  header1: {
    fontSize: '24px',
  },
  header2: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  tableHeader: {
    borderTop: 'none',
    fontWeight: 'bold',
    borderBottom: '1px solid black',
    backgroundColor: '#f0eded',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    fontSize: '8px',
    marginTop: 10,
  },
  accountIDCol: {
    width: '10%',
    paddingRight: TEXT_PADDING,
  },
  customerCol: {
    width: '40%',
    paddingRight: TEXT_PADDING,
  },
  totalCol: {
    width: '10%',
    textAlign: 'right',
    paddingRight: TEXT_PADDING,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    fontSize: '8px',
    marginBottom: 1,
  },
  totalRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    fontSize: '8px',
    backgroundColor: '#f0eded',
    paddingTop: 1,
  },
  footer: {
    position: 'absolute',
    fontSize: '8px',
    bottom: 30,
    left: 24,
    right: 24,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: 'grey',
  },
});

export type GeneratedAccountsReceivableReportProps = {
  readonly companyData: CompanyFragment | undefined;
  readonly data: AccountsReceivableDataFragment[];
  readonly dateOption: DateOption;
  readonly balanceCalculationType: BalanceCalculationType;
};

const GeneratedAccountsReceivableReport = ({
  companyData,
  data,
  dateOption,
  balanceCalculationType,
}: GeneratedAccountsReceivableReportProps) => {
  const totalInvoices = data.reduce(
    (acc, curr) => safeAdd(acc, curr.totalInvoices),
    0,
  );
  const totalCredits = data.reduce(
    (acc, curr) => safeAdd(acc, curr.totalCredits),
    0,
  );
  const totalDebits = data.reduce(
    (acc, curr) => safeAdd(acc, curr.totalDebits),
    0,
  );
  const totalPayments = data.reduce(
    (acc, curr) => safeAdd(acc, curr.totalPayments),
    0,
  );
  const totalBalance = data.reduce(
    (acc, curr) => safeAdd(acc, curr.balance),
    0,
  );

  return (
    <Document title="Accounts Receivable Report">
      <Page size="LETTER" style={styles.page}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            fontSize: '12px',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text style={styles.header1}>Accounts Receivable</Text>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '10px',
            fontSize: '12px',
          }}
        >
          <View>
            <Text style={styles.header2}>{companyData?.name}</Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: CELL_MARGIN,
              justifyContent: 'space-between',
            }}
          >
            <View style={{ display: 'flex', flexDirection: 'column' }}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: PAGE_BLOCK_SIZE,
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: '10px',
                  }}
                >
                  <Text style={{ fontWeight: 'bold', width: 50 }}>Date</Text>
                  <Text>{formatDateOption(dateOption)}</Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: '10px',
                  }}
                >
                  <Text>{balanceCalculationType}</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.tableHeader}>
            <View style={styles.customerCol}>
              <Text>Customer</Text>
            </View>
            <View style={styles.accountIDCol}>
              <Text>Account ID</Text>
            </View>
            {TOTALS_HEADERS.map((header) => (
              <View key={header} style={styles.totalCol}>
                <Text>{header}</Text>
              </View>
            ))}
          </View>
          {data.map((row) => (
            <View key={row.contactUuid} style={styles.row}>
              <View style={styles.customerCol}>
                <Text>{row.displayName}</Text>
              </View>
              <View style={styles.accountIDCol}>
                <Text>{row.contactReferenceNumber}</Text>
              </View>
              <View style={styles.totalCol}>
                <Text>{row.totalInvoices.toFixed(2)}</Text>
              </View>
              <View style={styles.totalCol}>
                <Text>{row.totalCredits.toFixed(2)}</Text>
              </View>
              <View style={styles.totalCol}>
                <Text>{safeMultiply(row.totalDebits, -1).toFixed(2)}</Text>
              </View>
              <View style={styles.totalCol}>
                <Text>{row.totalPayments.toFixed(2)}</Text>
              </View>
              <View style={styles.totalCol}>
                <Text>{row.balance.toFixed(2)}</Text>
              </View>
            </View>
          ))}
          <View
            style={[
              styles.tableHeader,
              { borderTop: '1px solid black', borderBottom: 'none' },
            ]}
          >
            <View style={styles.customerCol}>
              <Text>Totals</Text>
            </View>
            <View style={styles.accountIDCol}>
              <Text />
            </View>
            {TOTALS_HEADERS.map((header) => (
              <View key={header} style={styles.totalCol}>
                <Text>{header}</Text>
              </View>
            ))}
          </View>
          <View style={styles.totalRow}>
            <View style={styles.customerCol}>
              <Text>{}</Text>
            </View>
            <View style={styles.accountIDCol}>
              <Text>{}</Text>
            </View>
            <View style={styles.totalCol}>
              <Text>{totalInvoices.toFixed(2)}</Text>
            </View>
            <View style={styles.totalCol}>
              <Text>{totalCredits.toFixed(2)}</Text>
            </View>
            <View style={styles.totalCol}>
              <Text>{safeMultiply(totalDebits, -1).toFixed(2)}</Text>
            </View>
            <View style={styles.totalCol}>
              <Text>{totalPayments.toFixed(2)}</Text>
            </View>
            <View style={styles.totalCol}>
              <Text>{totalBalance.toFixed(2)}</Text>
            </View>
          </View>
        </View>
        <View fixed style={styles.footer}>
          <Text>Generated {dayjs().format('MM/DD/YY')}</Text>
          <Text
            render={({ pageNumber, totalPages }) =>
              `Page ${pageNumber} of ${totalPages}`
            }
          />
        </View>
      </Page>
    </Document>
  );
};

export default GeneratedAccountsReceivableReport;
