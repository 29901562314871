import { FormControl, FormHelperText, TextField } from '@mui/material';
import { isNil } from 'lodash';
import type { FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import useMe from '../../../../common/react-hooks/use-me';
import { type QuoteFormValues } from './forms/types';

export const QuoteNumberField: FunctionComponent = () => {
  const { useAllCaps } = useMe();
  const {
    control,
    formState: { errors },
  } = useFormContext<QuoteFormValues>();

  return (
    <Controller
      name="number"
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl sx={{ width: '190px' }}>
          <TextField
            label="Quote Number"
            size="small"
            sx={{ width: '100%' }}
            value={value ?? ''}
            inputProps={
              useAllCaps ? { style: { textTransform: 'uppercase' } } : {}
            }
            error={!isNil(errors.number)}
            onChange={onChange}
          />
          {!isNil(errors.number) && (
            <FormHelperText sx={{ color: '#D32F2F' }}>
              {errors.number?.message?.toString()}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};
