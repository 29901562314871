import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Divider,
  FormControl,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import fileDownload from 'js-file-download';
import { isEmpty, isNil } from 'lodash';
import { type Dispatch, type SetStateAction, useState } from 'react';
import CustomerFilterButton from '../../../../../common/components/customer-filter-button';
import DateDropdownPicker, {
  type DateOption,
  defaultPast1WeekDateRangeOption,
} from '../../../../../common/components/date-dropdown-picker';
import DownloadTypeSelection, {
  DownloadType,
} from '../../../../../common/components/download-type-selection';
import { type Option } from '../../../../../common/filters/types';
import {
  InvoiceStatusFilter,
  useInvoiceRegisterReportCsvLazyQuery,
  useInvoiceRegisterReportPdfLazyQuery,
} from '../../../../../generated/graphql';
import useInvoicesStore from '../../../invoices-store';
import styles from '../../../styles';
import { InvoiceStatusTab } from '../../../types/types';
import { accountingReportDefaultDateOption } from '../../accounting-reports/constants';

type DownloadInvoiceRegisterReportModalProps = {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const DownloadInvoiceRegisterReportModal = ({
  isOpen,
  setIsOpen,
}: DownloadInvoiceRegisterReportModalProps) => {
  const [customerOption, setCustomerOption] = useState<Option | undefined>();
  const [dateOption, setDateOption] = useState<DateOption>(
    defaultPast1WeekDateRangeOption,
  );
  const [downloadType, setDownloadType] = useState<DownloadType>(
    DownloadType.PDF,
  );
  const [statusFilter, setStatusFilter] = useState<InvoiceStatusFilter>(
    InvoiceStatusFilter.All,
  );
  const createFileDownload = useInvoicesStore(
    (state) => state.createFileDownload,
  );
  const [getInvoiceRegisterReportPdf] = useInvoiceRegisterReportPdfLazyQuery();

  const [getInvoiceRegisterReportCsv] = useInvoiceRegisterReportCsvLazyQuery();

  const resetFilters = () => {
    setCustomerOption(undefined);
    setDateOption(accountingReportDefaultDateOption);
    setStatusFilter(InvoiceStatusFilter.All);
  };

  const startDownload = async () => {
    setIsOpen(false);
    const completeDownload = createFileDownload();

    try {
      let presignedGetUrl: string | null | undefined;
      let fileName: string | null | undefined;
      let errors: string[] | null | undefined;

      if (downloadType === DownloadType.CSV) {
        const csvRes = await getInvoiceRegisterReportCsv({
          variables: {
            invoiceRegisterReportInput: {
              statusFilter,
              contactUuid: customerOption?.value,
              invoiceStartDate: dateOption.startDate,
              invoiceEndDate: dateOption.endDate,
            },
          },
        });
        presignedGetUrl = csvRes.data?.invoiceRegisterReportCsv.url;
        fileName = csvRes.data?.invoiceRegisterReportCsv.fileName;
        errors = csvRes.data?.invoiceRegisterReportCsv.errors;
      } else if (downloadType === DownloadType.PDF) {
        const pdfRes = await getInvoiceRegisterReportPdf({
          variables: {
            invoiceRegisterReportInput: {
              statusFilter,
              contactUuid: customerOption?.value,
              invoiceStartDate: dateOption.startDate,
              invoiceEndDate: dateOption.endDate,
            },
          },
        });
        presignedGetUrl = pdfRes.data?.invoiceRegisterReportPdf.url;
        fileName = pdfRes.data?.invoiceRegisterReportPdf.fileName;
        errors = pdfRes.data?.invoiceRegisterReportPdf.errors;
      }
      if (!isNil(errors) && !isEmpty(errors)) {
        completeDownload({ alertSeverity: 'error', message: errors[0] });
      } else if (!isNil(presignedGetUrl) && !isNil(fileName)) {
        const getFileRes = await fetch(presignedGetUrl, {
          cache: 'no-cache',
        });

        const blob = await getFileRes.blob();

        fileDownload(blob, fileName);
        completeDownload();
      } else {
        completeDownload({
          alertSeverity: 'error',
          message:
            'An error occurred while generating the report, please try again and contact support if the issue persists',
        });
      }
      resetFilters();
    } catch (error) {
      completeDownload({
        alertSeverity: 'error',
        message:
          'An error occurred while generating the report, please try again and contact support if the issue persists',
      });
      console.error('Error generating invoice register report', error);
    }
  };

  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <Box sx={[styles.modal, { height: 'fit-content', width: '500px' }]}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={10}>
            <Typography sx={{ fontWeight: 'bold' }}>
              Download Invoice Register Report
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              sx={{ float: 'right' }}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} display="flex" flexDirection="row" gap={1}>
            <DateDropdownPicker
              filterTitle="Invoice Date"
              dateOption={dateOption}
              setDateOption={setDateOption}
            />
            <FormControl sx={{ width: '35%' }}>
              <InputLabel id="invoice-status-label">Invoice Status</InputLabel>
              <Select
                required
                labelId="invoice-status-label"
                label="Invoice Status"
                value={statusFilter}
                size="small"
                sx={{ backgroundColor: 'white' }}
                onChange={(e) => {
                  setStatusFilter(e.target.value as InvoiceStatusFilter);
                }}
              >
                {Object.values(InvoiceStatusTab).map((status) => (
                  <MenuItem key={status} id={status} value={status}>
                    {sentenceCase(status)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="row"
            gap={1}
            flexWrap="wrap"
          >
            <CustomerFilterButton
              selectedOption={customerOption}
              handleChange={(option: Option | undefined) => {
                setCustomerOption(option);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{ float: 'left' }}
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={1}
            >
              <DownloadTypeSelection
                labelText="File type"
                cacheId="INVOICE_REGISTER_REPORT"
                selectedOption={downloadType}
                handleChange={(selectedDownloadType: DownloadType) => {
                  setDownloadType(selectedDownloadType);
                }}
              />
            </Box>
            <Box sx={{ float: 'right' }}>
              <Button
                sx={{ width: '100px' }}
                variant="contained"
                color="info"
                onClick={startDownload}
              >
                Download
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default DownloadInvoiceRegisterReportModal;
