import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { type Dayjs } from 'dayjs';
import { isEmpty, isNil } from 'lodash';
import { type Dispatch } from 'react';
import DatePicker, { type DateObject } from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import GeneralDatePicker from '../../../../common/components/date-picker';
import {
  DeadlineType,
  type OrderForBulkEditFragment,
  StopAction,
  StopType,
} from '../../../../generated/graphql';
import { StopTypeGroup } from '../enums/bulk-actions';

const getPotentialStopsAffected = (
  orders: OrderForBulkEditFragment[],
  stopTypeGroup: StopTypeGroup | undefined,
) => {
  const test = orders.flatMap((order) =>
    order.shipments.filter((shipment) => {
      const stopType = shipment.legs[0]?.endStop.stopType;
      if (stopTypeGroup === StopTypeGroup.PickupRecovery) {
        return stopType === StopType.Pickup || stopType === StopType.Recovery;
      }
      if (stopTypeGroup === StopTypeGroup.DeliveryTransfer) {
        return stopType === StopType.Delivery || stopType === StopType.Transfer;
      }
      return false;
    }),
  );

  return test.length;
};

type EditStopsProps = {
  readonly loading: boolean;
  readonly stopTypeGroup: StopTypeGroup | undefined;
  readonly setStopTypeGroup: (value: StopTypeGroup | undefined) => void;
  readonly deadlineType: DeadlineType | undefined;
  readonly setDeadlineType: (value: DeadlineType | undefined) => void;
  readonly deadlineDate: Dayjs | undefined;
  readonly setDeadlineDate: Dispatch<React.SetStateAction<Dayjs | undefined>>;
  readonly stopAction: StopAction | undefined;
  readonly setStopAction: (value: StopAction | undefined) => void;
  readonly podName: string | undefined;
  readonly setPodName: (value: string | undefined) => void;
  readonly completedAt: Date | undefined;
  readonly setCompletedAt: (value: Date | undefined) => void;
  readonly orders: OrderForBulkEditFragment[];
  readonly serviceDate: Dayjs | undefined;
  readonly setServiceDate: Dispatch<React.SetStateAction<Dayjs | undefined>>;
};
const EditStops = ({
  loading,
  stopTypeGroup,
  setStopTypeGroup,
  deadlineType,
  setDeadlineType,
  deadlineDate,
  setDeadlineDate,
  stopAction,
  setStopAction,
  podName,
  setPodName,
  completedAt,
  setCompletedAt,
  orders,
  serviceDate,
  setServiceDate,
}: EditStopsProps) => {
  return (
    <Grid item xs>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">
            Edit Stops (
            {loading ? '-' : getPotentialStopsAffected(orders, stopTypeGroup)})
          </Typography>
        </Grid>
        {loading ? (
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{ margin: 2 }}
            width="100%"
            height="500px"
          />
        ) : (
          <>
            <Grid item xs={12}>
              <FormControl sx={{ width: '80%' }}>
                <InputLabel id="stop-type-label">
                  Select Stop Types to Modify
                </InputLabel>
                <Select
                  labelId="stop-type-label"
                  id="stop-type-select"
                  value={stopTypeGroup ?? ''}
                  label="Select Stop Types to Modify"
                  onChange={(e) => {
                    if (isEmpty(e.target.value)) {
                      setDeadlineType(undefined);
                      setDeadlineDate(undefined);
                      setStopAction(undefined);
                    }
                    setStopTypeGroup(
                      isEmpty(e.target.value)
                        ? undefined
                        : (e.target.value as StopTypeGroup),
                    );
                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  {Object.values(StopTypeGroup).map((group) => (
                    <MenuItem key={group} value={group}>
                      {group}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {!isNil(stopTypeGroup) && !isEmpty(stopTypeGroup) && (
              <>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="deadline-type-label">
                      Deadline Type
                    </InputLabel>
                    <Select
                      labelId="deadline-type-label"
                      id="deadline-type-select"
                      value={deadlineType ?? ''}
                      label="Deadline Type"
                      onChange={(e) => {
                        if (isEmpty(e.target.value)) {
                          setDeadlineType(undefined);
                        } else {
                          setDeadlineType(e.target.value as DeadlineType);
                        }
                      }}
                    >
                      <MenuItem value="">N/A</MenuItem>
                      {Object.values(DeadlineType).map((type) => (
                        <MenuItem key={type} value={type}>
                          {sentenceCase(type)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <GeneralDatePicker
                    isLarge
                    dateNil={deadlineDate}
                    setDateNil={setDeadlineDate}
                    text="Deadline Date"
                    width={215}
                  />
                </Grid>
                <Grid item xs={6}>
                  <GeneralDatePicker
                    isLarge
                    dateNil={serviceDate}
                    setDateNil={setServiceDate}
                    text="Service Date"
                    width="100%"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl>
                    <FormLabel id="stop-radio-buttons-group-label">
                      <Typography>Actions</Typography>
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="stop-radio-buttons-group-label"
                      name="radio-buttons-group"
                      value={stopAction ?? ''}
                      onChange={(e) => {
                        setStopAction(e.target.value as StopAction);
                      }}
                    >
                      {Object.values(StopAction).map((action) => (
                        <FormControlLabel
                          key={action}
                          value={action}
                          control={<Radio />}
                          label={sentenceCase(action)}
                        />
                      ))}
                    </RadioGroup>
                    {!isNil(stopAction) && (
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        onClick={() => {
                          setStopAction(undefined);
                        }}
                      >
                        Clear Selection
                      </Button>
                    )}
                  </FormControl>
                </Grid>
                {stopAction === StopAction.MarkComplete && (
                  <Grid item xs={6}>
                    <Stack spacing={2}>
                      <FormControl fullWidth>
                        <TextField
                          label="POD Full Signee Name"
                          value={podName ?? ''}
                          onChange={(event) => {
                            setPodName(
                              isEmpty(event.target.value)
                                ? undefined
                                : event.target.value,
                            );
                          }}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <DatePicker
                          hideOnScroll
                          containerClassName="completed-at-date-picker-container"
                          className="completed-at-date-picker"
                          value={isNil(completedAt) ? null : completedAt}
                          plugins={[
                            <TimePicker
                              key="time-picker"
                              hideSeconds
                              position="right"
                              format="hh:mma"
                            />,
                          ]}
                          calendarPosition="right"
                          style={{
                            padding: '10px',
                            height: '55px',
                            fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
                            fontSize: '1rem',
                            borderColor: 'rgba(0, 0, 0, 0.23)',
                          }}
                          placeholder="Completed Date"
                          name="Completed Date"
                          format="MM/DD/YYYY hh:mma"
                          onChange={(newDate) => {
                            setCompletedAt(
                              isNil(newDate) ? undefined : newDate.toDate(),
                            );
                          }}
                        />
                      </FormControl>
                    </Stack>
                  </Grid>
                )}
              </>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default EditStops;
