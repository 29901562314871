import { Close } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { type PicklistsFragment } from '../../../../generated/graphql';
import DownloadPackingList from './download-packing-slip';
import PackingListDetailsForm from './packing-slip-details-form';
import usePackingListForm from './use-packing-slip-form';

enum STEPS {
  SELECT_ORDER = 0,
  DOWNLOAD_PACKING_LIST = 1,
}
const STEP_LABELS = ['Select an order', 'Download packing slip'];

type PackingListModalProps = {
  readonly picklist: PicklistsFragment;
  readonly onClose: () => void;
};
const PackingListModal = ({ picklist, onClose }: PackingListModalProps) => {
  const [activeStep, setActiveStep] = useState(STEPS.SELECT_ORDER);
  const form = usePackingListForm();
  return (
    <FormProvider {...form}>
      <Stack direction="row">
        <Stepper activeStep={activeStep} sx={{ flex: 1 }}>
          {STEP_LABELS.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            return (
              <Step
                key={label}
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setActiveStep(index);
                }}
                {...stepProps}
              >
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Stack>
      {activeStep === STEPS.SELECT_ORDER && (
        <PackingListDetailsForm
          picklist={picklist}
          onNext={() => {
            setActiveStep(STEPS.DOWNLOAD_PACKING_LIST);
          }}
        />
      )}
      {activeStep === STEPS.DOWNLOAD_PACKING_LIST && (
        <DownloadPackingList
          picklist={picklist}
          onBack={() => {
            setActiveStep(STEPS.SELECT_ORDER);
          }}
        />
      )}
    </FormProvider>
  );
};

export default PackingListModal;
