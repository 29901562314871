import { Checkbox, Stack, Typography } from '@mui/material';
import { memo } from 'react';
import { shallow } from 'zustand/shallow';
import useTariffGroupStore from '../store/tariff-group-state-store';

const UseDeficitBillingField = () => {
  const [useDeficitBilling, setUseDeficitBilling] = useTariffGroupStore(
    (state) => [state.useDeficitBilling, state.setUseDeficitBilling],
    shallow,
  );

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography sx={{ fontWeight: 'bold' }}>Use Deficit Billing</Typography>
      <Checkbox
        checked={useDeficitBilling}
        onChange={(e) => {
          setUseDeficitBilling(e.target.checked);
        }}
      />
    </Stack>
  );
};

export default memo(UseDeficitBillingField);
