import { Box, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, {
  type Dispatch,
  type SetStateAction,
  type CSSProperties,
} from 'react';
import DownArrow from '../icons/down-arrow.svg?react';
import UpArrow from '../icons/up-arrow.svg?react';

export enum SortType {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

const FieldSortStyles = {
  arrowIcon: {
    width: 10,
    height: 10,
  } as CSSProperties,
};

type SortProps = {
  readonly setSortState: Dispatch<SetStateAction<SortType | null>>;
  readonly currentSortState: SortType | null;
};

const SortComponent = ({ setSortState, currentSortState }: SortProps) => {
  const theme = useTheme();
  const getArrowStyle = (sortType: SortType) => {
    const isSelected =
      currentSortState !== null && sortType === currentSortState;
    return isSelected
      ? { ...FieldSortStyles.arrowIcon, fill: theme.palette.primary.main }
      : FieldSortStyles.arrowIcon;
  };

  const handleClick = (sortType: SortType) => {
    // If there is no current sort state or the sort state is being switched to a different sort type or field type
    // Set the sort state to the new sort state.
    // Otherwise, set the sort state to null
    if (currentSortState === null || sortType !== currentSortState) {
      setSortState(sortType);
    } else {
      setSortState(null);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Button
        sx={{ minWidth: 10 }}
        onClick={() => {
          handleClick(SortType.Descending);
        }}
      >
        <UpArrow style={getArrowStyle(SortType.Descending)} />
      </Button>
      <Button
        sx={{ minWidth: 10 }}
        onClick={() => {
          handleClick(SortType.Ascending);
        }}
      >
        <DownArrow style={getArrowStyle(SortType.Ascending)} />
      </Button>
    </Box>
  );
};

export default SortComponent;
