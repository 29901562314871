import { type SxProps } from '@mui/material';

const styles = {
  center: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  } as SxProps,
  card: {
    display: 'flex',
    flexDirection: 'column',
    mb: 2,
    px: 2,
    py: 2,
    width: '100%',
  } as SxProps,
  modal: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    height: '70vh',
    width: '60vw',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'space-between',
    justifyContent: 'space-between',
  } as SxProps,
};

export default styles;
