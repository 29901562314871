import { isNil } from 'lodash';
import { exhaustive } from 'shared/switch';
import { v4 } from 'uuid';
import { shallow } from 'zustand/shallow';
import {
  type PaginatedRoutesQueryResult,
  type RouteFragment,
  RouteSortBy,
  SortDirection,
  usePaginatedRoutesLazyQuery,
  useRouteLazyQuery,
  useRoutePathByRouteLazyQuery,
  useRoutePathsLazyQuery,
} from '../../../generated/graphql';
import useGlobalStore from '../../../layouts/dashboard/global-store';
import useDispatchStore from '../dispatch-store';
import { RouteSortType } from '../types/routes';

const convertToGraphqlRouteSort = (
  routeSortType: RouteSortType | null | undefined,
  routeSortAsc: boolean | null | undefined,
) => {
  if (isNil(routeSortType) || isNil(routeSortAsc)) return null;
  const getRouteSortBy = (sortType: RouteSortType) => {
    switch (sortType) {
      case RouteSortType.CREATED_TIME: {
        return RouteSortBy.CreatedTime;
      }
      case RouteSortType.NAME: {
        return RouteSortBy.Name;
      }
      case RouteSortType.DRIVER: {
        return RouteSortBy.Driver;
      }
      case RouteSortType.STATUS: {
        return RouteSortBy.Status;
      }
      case RouteSortType.NUMBER_STOPS_COMPLETE: {
        return RouteSortBy.NumberStopsComplete;
      }
      case RouteSortType.NUMBER_STOPS_INCOMPLETE: {
        return RouteSortBy.NumberStopsIncomplete;
      }
      default: {
        return exhaustive(sortType);
      }
    }
  };
  return {
    sortBy: getRouteSortBy(routeSortType),
    sortDirection: routeSortAsc ? SortDirection.Asc : SortDirection.Desc,
  };
};

const useFetchRoutes = () => {
  const [getRoutePathByRoute] = useRoutePathByRouteLazyQuery();
  const [getRoutePaths] = useRoutePathsLazyQuery();
  const [getRoute] = useRouteLazyQuery();
  const [getRoutes] = usePaginatedRoutesLazyQuery();
  const selectedTerminalUuid = useGlobalStore(
    (state) => state.selectedTerminalUuid,
  );
  const [
    setRoute,
    setRoutesLoading,
    setRoutePaths,
    upsertRoutePath,
    deleteRoutePath,
    setRoutesVersionId,
    setErrorMessage,
  ] = useDispatchStore(
    (state) => [
      state.setRoute,
      state.setRoutesLoading,
      state.setRoutePaths,
      state.upsertRoutePath,
      state.deleteRoutePath,
      state.setRoutesVersionId,
      state.setErrorMessage,
    ],
    shallow,
  );

  const fetchRoutePath = async (routeUuid: string) => {
    const resRoutePath = await getRoutePathByRoute({
      variables: {
        uuid: routeUuid,
      },
    });

    const routePath = resRoutePath.data?.route.routePath;
    if (isNil(routePath)) {
      deleteRoutePath(routeUuid);
    } else {
      upsertRoutePath(routePath);
    }
  };

  const fetchRoute = async (routeUuid: string) => {
    const res = await getRoute({
      variables: {
        uuid: routeUuid,
      },
    });

    const route = res.data?.route;
    if (!isNil(route)) {
      setRoute(route);
    }

    await fetchRoutePath(routeUuid);

    return res.data?.route;
  };

  const fetchRoutes = async ({
    planningDate,
    uuids,
    searchText,
    sortAsc,
    sortType,
    first10 = false,
  }: {
    planningDate?: Date | undefined;
    uuids?: string[] | undefined;
    searchText?: string | undefined;
    sortAsc?: boolean;
    sortType?: RouteSortType;
    first10?: boolean;
  }) => {
    if (isNil(planningDate) && isNil(uuids)) return;
    const versionId = v4();
    setRoutesVersionId(versionId);
    let totalCount;
    let prevEndCursor;
    const resRoutes: RouteFragment[] = [];
    while (isNil(totalCount) || resRoutes.length < totalCount) {
      const routeSort = convertToGraphqlRouteSort(sortType, sortAsc);
      const res: PaginatedRoutesQueryResult = await getRoutes({
        variables: {
          first: 10,
          after: prevEndCursor,
          date: planningDate,
          terminalUuid: selectedTerminalUuid,
          routeUuids: uuids,
          routeSort,
          versionId,
          searchText,
        },
      });
      if (isNil(res.data)) {
        setErrorMessage(
          'An error occurred fetching routes. Please contact support',
        );
        break;
      }
      const {
        edges,
        versionId: id,
        pageInfo: { endCursor },
        totalCount: count,
      } = res.data.paginatedRoutes;
      prevEndCursor = endCursor;
      totalCount = count;
      for (const { node: route } of edges) {
        resRoutes.push(route);
        setRoute(route, id);
      }
      if (first10) break;
    }

    if (!isNil(planningDate)) {
      setRoutesLoading(false);
    }

    if (!isNil(planningDate)) {
      const resRoutePaths = await getRoutePaths({
        variables: {
          date: planningDate,
        },
      });

      const routePaths =
        resRoutePaths.data?.routes.map((route) => route.routePath ?? null) ??
        [];
      setRoutePaths(routePaths);
    }
  };

  return {
    fetchRoute,
    fetchRoutes,
  };
};

export default useFetchRoutes;
