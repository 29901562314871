import {
  Box,
  Button,
  Dialog,
  Stack,
  type SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';
import {
  type DriverSignableFormFragment,
  useCreateCustomDriverFormMutation,
  CustomDriverSignableFormsDocument,
  useUpdateCustomDriverFormMutation,
} from '../../../generated/graphql';
import theme from '../../../theme';
import CreateOrEdit from '../enums/create-or-edit';

const styles = {
  modalInnerContainer: {
    bgcolor: 'background.paper',
    boxShadow: 24,
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
    p: 4,
    width: '100%',
  } as SxProps,
};

const CustomDriverFormsModal = ({
  createOrEdit,
  open,
  setOpen,
  selectedCustomDriverForm,
}: {
  readonly createOrEdit: CreateOrEdit;
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
  readonly selectedCustomDriverForm: DriverSignableFormFragment | undefined;
}) => {
  const [title, setTitle] = useState('');
  const [formText, setFormText] = useState('');

  const [createCustomDriverForm] = useCreateCustomDriverFormMutation({
    refetchQueries: [CustomDriverSignableFormsDocument],
  });

  const [updateCustomDriverForm] = useUpdateCustomDriverFormMutation({
    refetchQueries: [CustomDriverSignableFormsDocument],
  });

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (!isNil(selectedCustomDriverForm)) {
      setTitle(selectedCustomDriverForm.title);
      setFormText(selectedCustomDriverForm.formText);
    }
  }, [selectedCustomDriverForm]);

  const handleReset = () => {
    setOpen(false);
    setTitle('');
    setFormText('');
  };

  useEffect(() => {
    if (createOrEdit === CreateOrEdit.Create) {
      handleReset();
    }
  }, [createOrEdit]);

  const validateInputs = () => {
    if (isEmpty(title)) {
      setErrorMessage('Please enter a title.');
      return false;
    }
    if (isEmpty(formText)) {
      setErrorMessage('Please enter form text.');
      return false;
    }

    return true;
  };

  const handleSave = async () => {
    const isValid = validateInputs();
    if (!isValid) {
      return;
    }
    try {
      if (createOrEdit === CreateOrEdit.Create) {
        await createCustomDriverForm({
          variables: {
            createDriverSignableFormTemplateInput: {
              title,
              formText,
            },
          },
        });
      }
      if (
        createOrEdit === CreateOrEdit.Edit &&
        !isNil(selectedCustomDriverForm)
      ) {
        await updateCustomDriverForm({
          variables: {
            updateDriverSignableFormTemplateInput: {
              uuid: selectedCustomDriverForm.uuid,
              title,
              formText,
            },
          },
        });
      }
    } catch {
      setErrorMessage(
        'An error occured when submitting the form, please try again.',
      );
      return;
    }
    handleReset();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Box sx={styles.modalInnerContainer}>
        <Typography variant="h4">{createOrEdit} Custom Form</Typography>
        <Stack direction="column" gap={theme.spacing(2)} sx={{ width: '100%' }}>
          <TextField
            fullWidth
            label="Title"
            placeholder="Title"
            size="small"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
              setErrorMessage('');
            }}
          />
          <TextField
            multiline
            label="Full text of release form"
            placeholder="Enter text here..."
            size="small"
            rows={8}
            maxRows={20}
            value={formText}
            onChange={(e) => {
              setFormText(e.target.value);
              setErrorMessage('');
            }}
          />
        </Stack>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
        <Typography sx={{ color: 'red' }}>{errorMessage}</Typography>
      </Box>
    </Dialog>
  );
};

export default CustomDriverFormsModal;
