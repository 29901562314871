import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  CircularProgress,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import React, {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useState,
} from 'react';
import CSVDownload from '../../../../common/components/buttons/csv-download';
import { useMeasureExecutionTime } from '../../../../common/react-hooks/use-measure-execution-time';
import { useAverageDaysToPayReportLazyQuery } from '../../../../generated/graphql';
import styles from '../../styles';
import { convertAverageDaysToPayDataToCSV } from './utils';

type DownloadAverageDaysToPayReportModalProps = {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const DownloadAverageDaysToPayReportModal = ({
  isOpen,
  setIsOpen,
}: DownloadAverageDaysToPayReportModalProps) => {
  const [
    getAverageDaysToPayReport,
    {
      data: averageDaysToPayReportNewData,
      loading: averageDaysToPayReportDataNewLoading,
    },
  ] = useAverageDaysToPayReportLazyQuery();
  const averageDaysToPayReportData =
    averageDaysToPayReportNewData?.averageDaysToPayReport;
  const averageDaysToPayReportDataLoading =
    averageDaysToPayReportDataNewLoading;

  const [downloadAverageDaysToPayReport, setDownloadAverageDaysToPayReport] =
    useState<boolean>(false);

  useEffect(() => {
    if (!isOpen) {
      setDownloadAverageDaysToPayReport(false);
    }
  }, [isOpen]);

  const handleDownloadClick = async () => {
    await getAverageDaysToPayReport();
    setDownloadAverageDaysToPayReport(true);
  };

  const startDownloadWithMeasurement = useMeasureExecutionTime({
    fn: handleDownloadClick,
    rumLabel: 'download-average-days-to-pay-report',
  });

  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={() => {
        if (!averageDaysToPayReportDataLoading) {
          setIsOpen(false);
        }
      }}
    >
      <Box sx={[styles.modal, { height: '150px', width: '400px' }]}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={10}>
            <Typography sx={{ fontWeight: 'bold' }}>
              Download Average Days to Pay Report
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              sx={{ float: 'right' }}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ float: 'left' }}>
              <Button
                sx={{ width: '150px' }}
                variant="contained"
                color="info"
                disabled={averageDaysToPayReportDataLoading}
                startIcon={
                  averageDaysToPayReportDataLoading && (
                    <CircularProgress size={15} sx={{ color: 'gray' }} />
                  )
                }
                onClick={startDownloadWithMeasurement}
              >
                Download CSV
              </Button>
              {!isEmpty(averageDaysToPayReportData ?? []) &&
                downloadAverageDaysToPayReport && (
                  <CSVDownload
                    data={convertAverageDaysToPayDataToCSV(
                      averageDaysToPayReportData ?? [],
                    )}
                    filename="average-days-to-pay.csv"
                  />
                )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default DownloadAverageDaysToPayReportModal;
