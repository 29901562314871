import { Check } from '@mui/icons-material';
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  MenuList,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { memo } from 'react';
import { shallow } from 'zustand/shallow';
import useStyles from '../../../../../common/components/general-styles';
import useTerminals from '../../../../../common/react-hooks/use-terminals';
import AutocompleteFuzzy from '../../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import useTariffGroupStore from '../store/tariff-group-state-store';

const TerminalsField = () => {
  const [terminalUuids, setTerminalUuids] = useTariffGroupStore(
    (state) => [state.terminalUuids, state.setTerminalUuids],
    shallow,
  );

  const { terminals, terminalsLoading } = useTerminals({
    includeInactiveTerminals: false,
  });
  const styles = useStyles();
  const getTerminalsToDisplay = (allPlaceholder?: string) => {
    const terminalsToDisplay = (terminals ?? [])
      .filter((terminal) => terminalUuids.includes(terminal.uuid))
      .map((terminal) => terminal.code);
    return terminalsToDisplay.length > 0
      ? terminalsToDisplay.join(', ')
      : (allPlaceholder ?? '');
  };

  return (
    <FormControl sx={{ width: '100%' }}>
      <InputLabel shrink id="select-terminals-label">
        Terminals
      </InputLabel>
      <Select
        fullWidth
        displayEmpty
        notched
        label="Terminals"
        labelId="select-terminals-label"
        size="small"
        renderValue={() => getTerminalsToDisplay('All')}
      >
        <MenuList
          dense
          sx={{
            p: 0,
          }}
        >
          <MenuItem
            key="all"
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'visible',
              pl: '10px',
            }}
            selected={terminalUuids.length === 0}
            onClick={() => {
              setTerminalUuids([]);
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Check
                sx={{
                  visibility: terminalUuids.length === 0 ? undefined : 'hidden',
                  fontSize: '14px',
                  ml: 0,
                  mr: '6px',
                }}
              />
              <Typography sx={styles.menuText}>All</Typography>
            </Stack>
          </MenuItem>
          <MenuItem
            key="custom"
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'visible',
              pl: '10px',
            }}
            selected={terminalUuids.length > 0}
            disabled={terminalsLoading}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Check
                sx={{
                  visibility: terminalUuids.length > 0 ? undefined : 'hidden',
                  fontSize: '14px',
                  ml: 0,
                  mr: '6px',
                }}
              />
              <AutocompleteFuzzy
                multiple
                disableCloseOnSelect
                size="small"
                sx={{ backgroundColor: 'white', width: '250px' }}
                value={terminalUuids.map((uuid) => ({
                  label: terminals?.find((terminal) => terminal.uuid === uuid)
                    ?.code,
                  value: uuid,
                }))}
                options={
                  terminals?.map((terminal) => ({
                    label: terminal.code,
                    value: terminal.uuid,
                  })) ?? []
                }
                matchSortOptions={{ keys: ['label'] }}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    size="small"
                    onKeyDown={(e) => {
                      e.stopPropagation();
                    }}
                  />
                )}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox checked={selected} />
                    {option.label}
                  </li>
                )}
                onChange={(_, options) => {
                  setTerminalUuids(options.map((option) => option.value));
                }}
              />
            </Stack>
          </MenuItem>
        </MenuList>
      </Select>
    </FormControl>
  );
};

export default memo(TerminalsField);
