import {
  Button,
  styled,
  Box,
  Typography,
  type ButtonProps,
} from '@mui/material';

export const TABLE_CONFIG_PILL_HEIGHT = '28px';
const PILL_FONT_SIZE = '14px';

const TableConfigPillIcon = styled(Box)<{
  roundLeft?: boolean;
  roundRight?: boolean;
  color?: 'secondary' | 'primary';
}>(({ theme, roundLeft, roundRight, color = 'primary' }) => ({
  background: theme.palette.background.default,
  color: theme.palette.text[color],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(1),
  userSelect: 'none',
  border: `1px solid ${theme.palette.borderColor.main}`,
  // Don't show left/right borders unless they are the first/last element
  // so they don't conflict with the PillButton border (especially on hover)
  ...(roundLeft === true
    ? {
        borderTopLeftRadius: theme.spacing(1),
        borderBottomLeftRadius: theme.spacing(1),
      }
    : { borderLeft: 0 }),
  ...(roundRight === true
    ? {
        borderTopRightRadius: theme.spacing(1),
        borderBottomRightRadius: theme.spacing(1),
      }
    : { borderRight: 0 }),
  height: TABLE_CONFIG_PILL_HEIGHT,
  fontSize: PILL_FONT_SIZE,
  '& svg': {
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
}));

const TableConfigPillIconButton = styled(
  TableConfigPillIcon.withComponent(Button),
)(({ theme }) => ({
  color: theme.palette.primary.main,
  background: theme.palette.background.paper,
  height: TABLE_CONFIG_PILL_HEIGHT,
  minWidth: 0,
  border: `1px solid ${theme.palette.borderColor.main}`,
  borderRadius: theme.spacing(1),
  '&:hover': {
    borderColor: theme.palette.primary.main,
    zIndex: 1,
  },
}));

const TableConfigPillTextButton = styled(Button)<{
  roundLeft?: boolean;
  roundRight?: boolean;
}>(({ theme, roundLeft, roundRight }) => ({
  height: TABLE_CONFIG_PILL_HEIGHT,
  color: theme.palette.text.primary,
  padding: theme.spacing(0.5, 1),
  borderRadius: 0,
  ...(roundRight === true && {
    borderTopRightRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
  }),
  ...(roundLeft === true
    ? {
        borderTopLeftRadius: theme.spacing(1),
        borderBottomLeftRadius: theme.spacing(1),
      }
    : { marginLeft: '-1px' }),
  border: `1px solid ${theme.palette.borderColor.main}`,
  zIndex: 1,
  '&:hover': {
    borderColor: theme.palette.primary.main,
  },
}));

const TableConfigPillText = ({
  children,
  ...props
}: ButtonProps & {
  readonly roundLeft?: boolean;
  readonly roundRight?: boolean;
}) => {
  return (
    <TableConfigPillTextButton {...props}>
      <Typography noWrap fontSize={PILL_FONT_SIZE}>
        {children}
      </Typography>
    </TableConfigPillTextButton>
  );
};

export { TableConfigPillIcon, TableConfigPillIconButton, TableConfigPillText };
