import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { type PicklistItemGroupFragment } from '../../../generated/graphql';

const PicklistTableForAuditLog = ({
  picklistItemGroups,
}: {
  readonly picklistItemGroups: PicklistItemGroupFragment[];
}) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableCell>SKU</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Container</TableCell>
          <TableCell>Quantity deducted</TableCell>
        </TableHead>
        {picklistItemGroups.map((pig) => (
          <TableRow key={pig.id}>
            <TableCell>{pig.itemGroup.item.sku}</TableCell>
            <TableCell>{pig.itemGroup.item.description}</TableCell>
            <TableCell>{pig.itemGroup.storageUnit?.name}</TableCell>
            <TableCell>{pig.pickedQuantity}</TableCell>
          </TableRow>
        ))}
      </Table>
    </TableContainer>
  );
};

export default PicklistTableForAuditLog;
