import { Alert, Snackbar } from '@mui/material';
import { shallow } from 'zustand/shallow';
import useMe from '../../../common/react-hooks/use-me';
import useOrdersPageStore from '../orders-table-store';

const OrderTableReportAlerts = () => {
  const { email } = useMe();
  const [
    showReportBeingEmailedMessage,
    setShowReportBeingEmailedMessage,
    showTableReportErrorMessage,
    setShowTableReportErrorMessage,
  ] = useOrdersPageStore(
    (state) => [
      state.showReportBeingEmailedMessage,
      state.setShowReportBeingEmailedMessage,
      state.showTableReportErrorMessage,
      state.setShowTableReportErrorMessage,
    ],
    shallow,
  );

  return (
    <>
      <Snackbar
        autoHideDuration={3000}
        open={showReportBeingEmailedMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => {
          setShowReportBeingEmailedMessage(false);
        }}
      >
        <Alert>{`Report is being generated and will be emailed to ${email}...`}</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        open={showTableReportErrorMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => {
          setShowTableReportErrorMessage(false);
        }}
      >
        <Alert severity="error">
          Error generating report. Please contact support.
        </Alert>
      </Snackbar>
    </>
  );
};

export default OrderTableReportAlerts;
