import { LocalShipping } from '@mui/icons-material';
import React from 'react';

type TruckIconProps = {
  readonly color: string;
  readonly diameterPx?: string;
};

const TruckIcon = ({ color, diameterPx = '20px' }: TruckIconProps) => {
  return (
    <LocalShipping
      sx={{
        cursor: 'pointer',
        fontSize: diameterPx,
        color,
      }}
    />
  );
};

export default TruckIcon;
