import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import fileDownload from 'js-file-download';
import { isEmpty, isNil } from 'lodash';
import { type Dispatch, type SetStateAction, useMemo, useState } from 'react';
import DateDropdownPicker, {
  type DateOption,
  defaultPast1WeekDateRangeOption,
} from '../../../../../common/components/date-dropdown-picker';
import { DownloadType } from '../../../../../common/components/download-type-selection';
import DriverFilterButton from '../../../../../common/components/driver-filter-button';
import { type Option } from '../../../../../common/filters/types';
import useMe from '../../../../../common/react-hooks/use-me';
import { useDriverDailyLogReportPdfLazyQuery } from '../../../../../generated/graphql';
import {
  throwIfFetchFails,
  useDownloadReport,
} from '../../../../reports/hooks/use-download-report';
import styles from '../../../styles';

type DownloadDriverDailyLogModalProps = {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const DownloadDriverDailyLogModal = ({
  isOpen,
  setIsOpen,
}: DownloadDriverDailyLogModalProps) => {
  const { companyConfiguration } = useMe();
  const [dateOption, setDateOption] = useState<DateOption>(
    defaultPast1WeekDateRangeOption,
  );
  const [onlyEligibleForSettlement, setOnlyEligibleForSettlement] =
    useState<boolean>(false);
  const [completedStopsOnly, setCompletedStopsOnly] = useState<boolean>(false);
  const [includeLinehaulStops, setIncludeLinehaulStops] =
    useState<boolean>(false);
  const [driverOptions, setDriverOptions] = useState<
    Option[] | null | undefined
  >(null);

  const [getDriverDailyLogReportPdf, { loading }] =
    useDriverDailyLogReportPdfLazyQuery();

  const resetFilters = () => {
    setDateOption(defaultPast1WeekDateRangeOption);
    setDriverOptions(null);
    setOnlyEligibleForSettlement(false);
    setCompletedStopsOnly(false);
    setIncludeLinehaulStops(false);
  };

  const driverDailyLogReportPdfInput = useMemo(
    () => ({
      serviceStartDate: dateOption.startDate,
      serviceEndDate: dateOption.endDate,
      driverUuids: driverOptions?.map((option) => option.value) ?? [],
      onlyEligibleForSettlement,
      completedStopsOnly,
      includeLinehaulStops,
    }),
    [
      dateOption,
      driverOptions,
      onlyEligibleForSettlement,
      completedStopsOnly,
      includeLinehaulStops,
    ],
  );

  const startDownload = async () => {
    setIsOpen(false);
    const pdfRes = await getDriverDailyLogReportPdf({
      variables: {
        driverDailyLogReportPdfInput,
      },
    });
    throwIfFetchFails(pdfRes);
    const errorMessage =
      'There was an error fetching the driver daily log report';

    const presignedGetUrl = pdfRes.data?.driverDailyLogReportPdf.url;
    const fileName = pdfRes.data?.driverDailyLogReportPdf.fileName;
    const errors = pdfRes.data?.driverDailyLogReportPdf.errors;

    if (!isNil(errors) && !isEmpty(errors)) {
      throw new Error(errors[0]);
    }
    if (isNil(presignedGetUrl) || isNil(fileName)) {
      throw new Error(errorMessage);
    }
    const getFileRes = await fetch(presignedGetUrl, { cache: 'no-cache' });
    const blob = await getFileRes.blob();
    fileDownload(blob, fileName);
  };
  const startDownloadWithMeasurement = useDownloadReport({
    name: 'Driver daily log',
    rumLabel: 'download-driver-daily-log',
    filters: driverDailyLogReportPdfInput,
    downloadType: DownloadType.PDF,
    downloadReport: startDownload,
    onComplete: resetFilters,
  });

  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={() => {
        if (!loading) {
          setIsOpen(false);
        }
      }}
    >
      <Box sx={[styles.modal, { height: 'fit-content', width: '600px' }]}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={10}>
            <Typography sx={{ fontWeight: 'bold' }}>
              Download Daily Driver Log
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              sx={{ float: 'right' }}
              disabled={loading}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} display="flex" flexDirection="row" gap={1}>
            <DateDropdownPicker
              filterTitle="Date Range"
              dateOption={dateOption}
              setDateOption={setDateOption}
            />
            <DriverFilterButton
              selectedOptionsMultiselect={driverOptions}
              handleChangeMultiselect={setDriverOptions}
              prefixText="Drivers"
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="row"
            gap={1}
            flexWrap="wrap"
          >
            <Grid item xs={12}>
              {companyConfiguration?.showDriverSettlementInBilling === true && (
                <Box
                  display="flex"
                  flexDirection="row"
                  gap={1}
                  alignItems="center"
                >
                  <Checkbox
                    checked={onlyEligibleForSettlement}
                    onChange={(e) => {
                      setOnlyEligibleForSettlement(e.target.checked);
                    }}
                  />
                  <Typography>
                    Only include drivers eligible for settlement
                  </Typography>
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="row"
                gap={1}
                alignItems="center"
              >
                <Checkbox
                  checked={includeLinehaulStops}
                  onChange={(e) => {
                    setIncludeLinehaulStops(e.target.checked);
                  }}
                />
                <Typography>Include linehaul stops</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="row"
                gap={1}
                alignItems="center"
              >
                <Checkbox
                  checked={completedStopsOnly}
                  onChange={(e) => {
                    setCompletedStopsOnly(e.target.checked);
                  }}
                />
                <Typography>Completed stops only</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{ float: 'left' }}
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={1}
            />
            <Box sx={{ float: 'right' }}>
              <Button
                sx={{ width: '100px' }}
                variant="contained"
                color="info"
                disabled={loading}
                onClick={startDownloadWithMeasurement}
              >
                Download
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default DownloadDriverDailyLogModal;
