import {
  ContentPaste,
  DescriptionOutlined,
  EmailOutlined,
  PhotoOutlined,
  PictureAsPdfOutlined,
} from '@mui/icons-material';
import { type ReactNode } from 'react';
import { exhaustive } from 'shared/switch';
import { DocumentType } from '../../../../../../../generated/graphql';
import ContentPasteSignature from '../../../../../../../icons/content-paste-signature.svg?react';

export const getDocumentTypeIcon = (
  documentType: DocumentType,
  fileType: string,
): ReactNode => {
  switch (documentType) {
    case DocumentType.PickupReceiptForEsign:
    case DocumentType.DeliveryReceipt:
    case DocumentType.ProofOfDeliveryScanned:
    case DocumentType.ProofOfDelivery: {
      return <ContentPaste />;
    }
    case DocumentType.DigitalProofOfPickup:
    case DocumentType.DigitalProofOfDelivery:
    case DocumentType.DeliverySignature:
    case DocumentType.PickupSignature: {
      return <ContentPasteSignature />;
    }
    case DocumentType.PickupAlert:
    case DocumentType.DeliveryAlert:
    case DocumentType.BillOfLading: {
      return <EmailOutlined />;
    }
    case DocumentType.ShipmentPhoto: {
      return <PhotoOutlined />;
    }
    case DocumentType.Other:
    case DocumentType.EndOfDayDocument:
    case DocumentType.CustomDriverFormSignature:
    case DocumentType.CoverPage:
    case DocumentType.PodReport:
    case DocumentType.AccountingReport:
    case DocumentType.OrderDocument:
    case DocumentType.StorageUnitPhoto:
    case DocumentType.OsdPhoto: {
      switch (fileType) {
        case 'image/png':
        case 'image/jpeg': {
          return <PhotoOutlined />;
        }
        case 'application/pdf': {
          return <PictureAsPdfOutlined />;
        }
        case 'message/rfc822':
        default: {
          return <DescriptionOutlined />;
        }
      }
    }
    default: {
      return exhaustive(documentType);
    }
  }
};
