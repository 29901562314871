import { Stack } from '@mui/material';
import type { CSSProperties, PropsWithChildren } from 'react';
import theme from '../../theme';

type GappedStackCardProps = PropsWithChildren & {
  readonly style?: CSSProperties;
};

/**
 * A Stack with the following properties:
 *   - direction is column
 *   - gap is 1px
 *   - background color is the main border color
 *
 * This is useful for dynamically inserting children components in various arrangements without needing to worry about
 * borders and spacing between them.
 */
export const GappedStackCard = ({ style, children }: GappedStackCardProps) => (
  <Stack
    sx={{
      gap: '1px',
      backgroundColor: theme.palette.borderColor.main,
      ...style,
    }}
  >
    {children}
  </Stack>
);
