import { Box, Button, Modal, type SxProps, Typography } from '@mui/material';
import pluralize from 'pluralize';
import { useState } from 'react';

const styles: Record<string, SxProps> = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '80%',
    display: 'flex',
    flexDirection: 'column',
    bgcolor: 'background.paper',
    p: 5,
    borderRadius: '10px',
    minWidth: '400px',
    overflow: 'scroll',
  },
  filterButton: {
    width: '170px',
  },
};

type TextAppointmentsButtonProps = {
  readonly type: 'text' | 'call';
  readonly isModalOpenButtonDisabled: boolean;
  readonly isSendButtonDisabled: boolean;
  readonly count: number;
  readonly appointmentsView: React.ReactNode;
  readonly onSendMessages: () => void;
};
const AppointmentsModal = ({
  type,
  isModalOpenButtonDisabled,
  isSendButtonDisabled,
  count,
  appointmentsView,
  onSendMessages,
}: TextAppointmentsButtonProps) => {
  const [open, setOpen] = useState(false);
  const onSendWrapper = () => {
    setOpen(false);
    onSendMessages();
  };
  const buttonText = type === 'text' ? 'Text' : 'Call';
  return (
    <Box>
      <Button
        variant="contained"
        sx={styles.filterButton}
        disabled={isModalOpenButtonDisabled}
        onClick={() => {
          setOpen(true);
        }}
      >
        {buttonText} Appointments
      </Button>
      <Modal
        open={open}
        aria-labelledby="appointments-modal-title"
        aria-describedby="appointments-modal-description"
        onClose={() => {
          setOpen(false);
        }}
      >
        <Box sx={styles.modal}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            {/* necessary empty div for spacing */}
            <div style={{ flex: 1 }} />
            <Typography textAlign="center" variant="h4">
              Schedule {pluralize('Appointment', count, true)}
            </Typography>
            <Box flex={1} display="flex" justifyContent="flex-end">
              <Button
                variant="outlined"
                disabled={isSendButtonDisabled}
                onClick={onSendWrapper}
              >
                Send {count} {pluralize(buttonText.toLowerCase(), count)}
              </Button>
            </Box>
          </Box>
          {appointmentsView}
        </Box>
      </Modal>
    </Box>
  );
};

export default AppointmentsModal;
