import { memo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { type OrderFormValues } from '../forms/types';
import ContactAutocompleteComponent from './order-form-contact-autocomplete-component';

type OrderCustomerFieldProps = {
  readonly disabled: boolean;
};

const OrderCustomerFieldComponent = ({ disabled }: OrderCustomerFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<OrderFormValues>();

  const contactUuid = useWatch({ control, name: 'contactUuid' });

  return (
    <Controller
      name="contactUuid"
      control={control}
      render={({ field }) => (
        <ContactAutocompleteComponent<OrderFormValues, 'contactUuid'>
          contactUuid={contactUuid}
          disabled={disabled}
          field={field}
          errors={errors}
        />
      )}
    />
  );
};

export const OrderCustomerField = memo(OrderCustomerFieldComponent);
