import {
  Accordion,
  AccordionDetails,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { useState, useEffect } from 'react';
import { type BatchAddShipmentsToInvoicesResultInvoiceFragment } from '../../../../generated/graphql';
import AccordionSummary from '../accordion-summary';
import InvoiceResultRow from './invoice-result-row';

type ContactResultCardProps = {
  readonly invoices: BatchAddShipmentsToInvoicesResultInvoiceFragment[];
  readonly existingInvoiceAdds:
    | Array<{
        // eslint-disable-next-line @typescript-eslint/naming-convention
        __typename?: 'InvoiceAdd';
        uuid?: string | null | undefined;
        name?: string | null | undefined;
        shipmentUuids: string[];
      }>
    | undefined;
};

const ContactResultCard = ({
  invoices,
  existingInvoiceAdds,
}: ContactResultCardProps) => {
  const theme = useTheme();
  const [shipmentUuidsAdded, setShipmentUuidsAdded] = useState<string[]>([]);

  useEffect(() => {
    if (!isNil(existingInvoiceAdds)) {
      setShipmentUuidsAdded(
        existingInvoiceAdds.flatMap((invoice) => invoice.shipmentUuids),
      );
    }
  }, [existingInvoiceAdds]);

  return (
    <Accordion
      defaultExpanded
      elevation={0}
      sx={{ border: '1px solid', borderColor: theme.palette.borderColor.main }}
    >
      <AccordionSummary>
        <Grid container>
          <Grid item xs={8}>
            <Typography sx={{ marginLeft: '10px' }}>
              {invoices[0]?.billToContact.displayName}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={{ color: 'text.secondary', float: 'right' }}>
              Invoices Updated: {invoices.length}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="column" sx={{ width: '100%' }} spacing={2}>
          <TableContainer>
            <Table aria-label="invoice-preview-table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell sx={{ width: '20%' }}>Type</TableCell>
                  <TableCell sx={{ width: '20%' }}>Invoice</TableCell>
                  <TableCell># Added Shipments</TableCell>
                  <TableCell># Total Shipments</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoices.map((invoice) => {
                  return (
                    <InvoiceResultRow
                      key={invoice.uuid}
                      isExisting={
                        !isNil(
                          existingInvoiceAdds?.find(
                            (existingInvoice) =>
                              existingInvoice.uuid === invoice.uuid,
                          ),
                        )
                      }
                      shipmentUuidsAdded={shipmentUuidsAdded}
                      invoice={invoice}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(ContactResultCard);
