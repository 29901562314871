import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Card, CircularProgress, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { useMemo, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  useContactStationsQuery,
  PermissionResource,
} from '../../../generated/graphql';
import type ContactsPageTabs from '../enums/contacts-page-tabs';
import ContactPageMode from './contact-page-mode';
import { CreateStationModal } from './create-station-modal';
import EditStationPanel from './edit-station-panel';

type StationsPanelProps = {
  readonly contactUuid: string;
  readonly mode: ContactPageMode;
  readonly handleChangeMode: (mode: ContactPageMode) => void;
  readonly onTabChange: (tab: ContactsPageTabs) => void;
};

export default function StationsPanel({
  contactUuid,
  mode,
  handleChangeMode,
  onTabChange,
}: StationsPanelProps) {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteContacts } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Contacts,
  );

  const {
    data: stationsData,
    refetch,
    loading,
  } = useContactStationsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      contactUuid,
    },
  });

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [selectedStationId, setSelectedStationId] = useState<string | null>(
    stationsData?.contactStations[0]?.id ?? null,
  );

  const selectedStation = useMemo(
    () =>
      stationsData?.contactStations.find(
        (station) => station.id === selectedStationId,
      ),
    [stationsData, selectedStationId],
  );

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            width: 220,
            p: 2,
          }}
        >
          <Button
            variant="outlined"
            disabled={mode === ContactPageMode.CREATE || !canWriteContacts}
            // onClick={() => handleChangeMode(ContactPageMode.CREATE)}
            onClick={() => setCreateModalOpen(true)}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <AddIcon />
            <span>Add Station</span>
          </Button>
          {stationsData?.contactStations.map((station) => (
            <Button
              key={station.id}
              // disabled={mode !== ContactPageMode.VIEW}
              onClick={() => setSelectedStationId(station.id)}
              variant={
                !isNil(selectedStationId) && station.id === selectedStationId
                  ? 'contained'
                  : 'outlined'
              }
            >
              {station.name}
            </Button>
          ))}
        </Card>
        {/* eslint-disable-next-line no-nested-ternary */}
        {loading ? (
          <Box sx={{ py: 3, textAlign: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Card
            sx={{
              width: 720,
            }}
          >
            {isNil(selectedStation) ? (
              <Typography p={3} textAlign="center">
                You do not have any stations for this contact.
              </Typography>
            ) : (
              <EditStationPanel
                handleChangeMode={handleChangeMode}
                contactUuid={contactUuid}
                station={selectedStation}
                canWrite={canWriteContacts}
                mode={mode}
                onTabChange={onTabChange}
              />
            )}
          </Card>
        )}
        <CreateStationModal
          contactUuid={contactUuid}
          open={createModalOpen}
          onCreated={(station) => {
            setCreateModalOpen(false);
            refetch();
            setSelectedStationId(station.id);
          }}
          onClose={() => setCreateModalOpen(false)}
        />
      </Box>
    </Box>
  );
}
