import { isNil } from 'lodash';
import { z, type ZodType } from 'zod';
import {
  CsvOrderMappingTargetFieldType,
  DeadlineType,
  FreightBillingMethod,
  PickupOrDelivery,
  StandardStopType,
  StopImportType,
} from '../../../../generated/graphql';

type FieldMapping = {
  sourceField: string;
  targetField: CsvOrderMappingTargetFieldType;
};

export type CsvImportConfigurationFormData = {
  uuid: string;
  name: string;
  stopImportType: StopImportType;
  defaultDeadlineType: DeadlineType | null;
  defaultDeadlineDate: Date | null;
  defaultStopType: StandardStopType | null;
  defaultFreightBillingMethod: FreightBillingMethod | null;
  defaultPickupOrDelivery: PickupOrDelivery | null;
  contactUuid: string | null;
  fieldMappings: FieldMapping[];
};

export const REQUIRED_TARGET_FIELDS = [
  CsvOrderMappingTargetFieldType.AddressCity,
  CsvOrderMappingTargetFieldType.AddressLine_1,
  CsvOrderMappingTargetFieldType.AddressState,
  CsvOrderMappingTargetFieldType.AddressZip,
  CsvOrderMappingTargetFieldType.AddressName,
  CsvOrderMappingTargetFieldType.Hawb,
];

let schema: ZodType<CsvImportConfigurationFormData> = z
  .object({
    uuid: z.string(),
    name: z.string().min(1, { message: 'Required' }),
    stopImportType: z.nativeEnum(StopImportType),
    defaultDeadlineType: z.nativeEnum(DeadlineType).nullable(),
    defaultDeadlineDate: z.date().nullable(),
    defaultStopType: z.nativeEnum(StandardStopType).nullable(),
    defaultFreightBillingMethod: z.nativeEnum(FreightBillingMethod).nullable(),
    defaultPickupOrDelivery: z.nativeEnum(PickupOrDelivery).nullable(),
    contactUuid: z.string().nullable(),
    fieldMappings: z.array(
      z.object({
        sourceField: z.string(),
        targetField: z.nativeEnum(CsvOrderMappingTargetFieldType),
      }),
    ),
  })
  .refine(
    (data: CsvImportConfigurationFormData) => {
      if (isNil(data.contactUuid)) {
        return data.fieldMappings.some(
          (mapping) =>
            mapping.targetField === CsvOrderMappingTargetFieldType.Contact,
        );
      }
      return true;
    },
    {
      message:
        'You must include a mapping to the target field Contact if a contact is not specified',
      path: ['fieldMappings'],
    },
  )
  .refine(
    (data: CsvImportConfigurationFormData) => {
      const targetFields = data.fieldMappings.map(
        (mapping) => mapping.targetField,
      );
      const uniqueTargetFields = new Set(targetFields);
      return targetFields.length === uniqueTargetFields.size;
    },
    {
      message: 'A target field cannot exist in more than one mapping',
      path: ['fieldMappings'],
    },
  );

// Required field validations
for (const field of REQUIRED_TARGET_FIELDS) {
  schema = schema.refine(
    (data: CsvImportConfigurationFormData) =>
      data.fieldMappings.some((mapping) => mapping.targetField === field),
    {
      message: `Missing required target field mapping: ${field}`,
      path: ['fieldMappings'],
    },
  );
}

export const csvImportConfigurationSchema: ZodType<CsvImportConfigurationFormData> =
  schema;
