import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import currency from 'currency.js';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  FuelBillingMethod,
  type FuelProfileFragment,
  FuelProfilesDocument,
  FuelProfileType,
  PermissionResource,
  useFuelProfilesLazyQuery,
  useUpdateFuelProfileMutation,
} from '../../../generated/graphql';
import AddFuelProfileForContact from './add-fuel-profile-for-contact';

type FuelProfileRowProps = {
  readonly contactUuid: string;
  readonly fuelProfile: FuelProfileFragment;
};

const FuelProfileRow = ({ contactUuid, fuelProfile }: FuelProfileRowProps) => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteContacts } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Contacts,
  );

  const [openDateRanges, setOpenDateRanges] = React.useState(false);

  const [updateFuelProfile] = useUpdateFuelProfileMutation({
    refetchQueries: [FuelProfilesDocument],
  });

  return (
    <>
      <TableRow
        key={fuelProfile.uuid}
        sx={{
          '&:last-child td, &:last-child th': { border: 0 },
        }}
      >
        <TableCell>
          <Box>
            <Typography variant="body1">
              {fuelProfile.name ?? 'None'}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Box>
            <Typography variant="body1">
              {sentenceCase(fuelProfile.billingType)}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Box>
            <Button
              variant="outlined"
              disabled={!canWriteContacts}
              onClick={async () => {
                await updateFuelProfile({
                  variables: {
                    input: {
                      uuid: fuelProfile.uuid,
                      unappliedContactUuid: contactUuid,
                    },
                  },
                });
              }}
            >
              Unapply
            </Button>
          </Box>
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand fuelProfile"
            size="small"
            disabled={!canWriteContacts}
            onClick={() => {
              setOpenDateRanges(!openDateRanges);
            }}
          >
            {openDateRanges ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse unmountOnExit in={openDateRanges} timeout="auto">
            <Box sx={{ margin: 1 }}>
              <Typography gutterBottom variant="h6" component="div">
                Date Ranges
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Start Date</TableCell>
                    <TableCell>Expiration Date</TableCell>
                    <TableCell>Rate</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fuelProfile?.fuelProfileDateRanges?.map(
                    (fuelProfileDateRange) => {
                      return (
                        <TableRow key={fuelProfileDateRange.uuid}>
                          <TableCell>
                            {dayjs(fuelProfileDateRange.startDate).format(
                              'MM/DD/YYYY',
                            )}
                          </TableCell>
                          <TableCell>
                            {dayjs(fuelProfileDateRange.expirationDate).format(
                              'MM/DD/YYYY',
                            )}
                          </TableCell>
                          <TableCell>
                            {fuelProfile.billingType ===
                            FuelBillingMethod.Percentage
                              ? `${fuelProfileDateRange.surchargeRate ?? '-'}%`
                              : currency(
                                  fuelProfileDateRange.flatRateUsdCents ?? 0,
                                  { fromCents: true },
                                ).format()}
                          </TableCell>
                        </TableRow>
                      );
                    },
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export const FuelProfilesTableForContact = ({
  contactUuid,
}: {
  readonly contactUuid: string;
}) => {
  const { userPermissions } = useUserRoles();

  const { canWrite: canWriteContacts } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Contacts,
  );

  const [open, setOpen] = useState(false);
  const [getFuelProfiles, { data: contactFuelProfiles }] =
    useFuelProfilesLazyQuery();

  useEffect(() => {
    getFuelProfiles({
      variables: { contactUuid, type: FuelProfileType.ContactSpecific },
    });
  }, [contactUuid]);

  return (
    <Stack direction="column" alignItems="flex-end" sx={{ display: 'flex' }}>
      {open && (
        <AddFuelProfileForContact
          open={open}
          setOpen={setOpen}
          contactUuid={contactUuid}
        />
      )}
      <Button
        sx={{ marginBottom: '16px' }}
        variant="contained"
        disabled={!canWriteContacts}
        onClick={() => {
          setOpen(true);
        }}
      >
        Add Fuel Profile
      </Button>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {contactFuelProfiles?.fuelProfiles.map((fuelProfile) => {
              return (
                <FuelProfileRow
                  contactUuid={contactUuid}
                  fuelProfile={fuelProfile}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default FuelProfilesTableForContact;
