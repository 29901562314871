import {
  Avatar,
  AvatarGroup,
  Paper,
  Grow,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import Popper from '@mui/material/Popper';
import { isEmpty } from 'lodash';
import React, { memo, type RefObject, useEffect, useState } from 'react';
import {
  DispatchMultiplayerAction,
  type DispatchUserLocationPayload,
} from '../../../../common/multiplayer/types/dispatch';
import {
  chooseForegroundColor,
  stringToColor,
} from '../../../../common/utils/colors';

const UserEditingPopup = ({
  anchorRef,
  userEditLocations,
  backgroundColor,
}: {
  readonly anchorRef: RefObject<HTMLDivElement | null>;
  readonly userEditLocations: DispatchUserLocationPayload[];
  readonly backgroundColor?: string;
}) => {
  const theme = useTheme();
  const [editingString, setEditingString] = useState<string>('');

  useEffect(() => {
    const firstName = userEditLocations[0]?.name.slice(0, 10);
    const action = userEditLocations.some(
      (userEditLocation) =>
        userEditLocation.action === DispatchMultiplayerAction.REORDERING,
    )
      ? 'reordering'
      : 'editing';
    if (isEmpty(userEditLocations)) {
      return;
    }
    if (userEditLocations.length === 1) {
      setEditingString(`${firstName} is ${action}...`);
    } else {
      setEditingString(
        `${firstName} + ${userEditLocations.length - 1} are ${action}...`,
      );
    }
  }, [userEditLocations]);

  return (
    <Popper
      transition
      open={!isEmpty(userEditLocations)}
      anchorEl={anchorRef?.current}
      placement="top-start"
      sx={{ zIndex: 100 }}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} timeout={300}>
          <Paper
            sx={{
              p: '2px',
              boxShadow: 0,
              border: `1px solid ${theme.palette.borderColor.main}`,
              borderBottom: 0,
              borderRadius: 0,
              borderTopLeftRadius: '5px',
              borderTopRightRadius: '5px',
              mb: '-1px',
              backgroundColor,
            }}
          >
            <Stack direction="row" spacing={0.5}>
              <AvatarGroup
                max={1}
                sx={{
                  '& .MuiAvatar-root': {
                    width: 14,
                    height: 14,
                    fontSize: 10,
                    ml: '-5px',
                  },
                }}
              >
                {userEditLocations.map((userEditingRoute) => {
                  const avatarColor = stringToColor(
                    userEditingRoute.connectionId,
                  );
                  return (
                    <Avatar
                      sx={{
                        width: 14,
                        height: 14,
                        fontSize: 10,
                        bgcolor: avatarColor,
                        color: chooseForegroundColor(avatarColor),
                      }}
                    >
                      {userEditingRoute.name.toUpperCase()[0]}
                    </Avatar>
                  );
                })}
              </AvatarGroup>
              <Typography variant="caption">{editingString}</Typography>
            </Stack>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default memo(UserEditingPopup);
