import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  List,
  ListSubheader,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { isNil, uniq } from 'lodash';
import { memo, useMemo } from 'react';
import { shallow } from 'zustand/shallow';
import { OrderStatus } from '../../../../generated/graphql';
import useBillingReviewStore from '../../billing-review-store';
import useBillingReviewNavigationActions from '../../hooks/use-billing-review-navigation-actions';
import useOrdersListToDisplayInSidebar from './hooks/use-orders-list-to-display-in-sidebar';
import OutstandingOrdersSidebarListItem from './outstanding-orders-sidebar-list-item';
import OutstandingOrdersSidebarListProratedOrderGroup from './outstanding-orders-sidebar-prorated-order-group';

const OutstandingOrdersListSidebar = () => {
  const theme = useTheme();
  const [
    outstandingOrdersInPage,
    searchedOrders,
    selectedOrderUuid,
    orderUuidsToSave,
    setOpenedOutstandingOrderUuid,
    setOutstandingOrdersInPage,
    setOutstandingOrderUuidsInReview,
    outstandingOrderUuidInForm,
    isSelectingOrdersToSendInReviewModal,
    addShipmentsToSend,
    removeOrdersToSend,
    selectedOrdersToSendShipments,
    addOrdersToSendShipments,
    removeOrdersToSendShipments,
    setSearchedOrders,
    hasNextPage,
    hasPreviousPage,
    ordersListDataLoading,
  ] = useBillingReviewStore(
    (state) => [
      state.outstandingOrdersInPage,
      state.searchedOrders,
      state.openedOutstandingOrderUuid,
      state.orderUuidsToSave,
      state.setOpenedOutstandingOrderUuid,
      state.setOutstandingOrdersInPage,
      state.setOutstandingOrderUuidsInReview,
      state.outstandingOrderUuidInForm,
      state.isSelectingOrdersToSendInReviewModal,
      state.addShipmentsToSend,
      state.removeOrdersToSend,
      state.selectedOrdersToSendShipments,
      state.addOrdersToSendShipments,
      state.removeOrdersToSendShipments,
      state.setSearchedOrders,
      state.paginationInfo.hasNextPage,
      state.paginationInfo.hasPrevPage,
      state.ordersListDataLoading,
    ],
    shallow,
  );

  const { goToPreviousPage, goToNextPage } =
    useBillingReviewNavigationActions();

  const ordersListToDisplayInSidebar = useOrdersListToDisplayInSidebar();

  // Flatten the orders list with their prorated orders so we can include these in sending
  const ordersListFlattenedWithProratedOrders = useMemo(() => {
    return ordersListToDisplayInSidebar.flatMap((order) => [
      order,
      ...(isNil(order.queriedProratedOrdersWith)
        ? []
        : order.queriedProratedOrdersWith),
    ]);
  }, [ordersListToDisplayInSidebar]);

  const finalizedOrders = useMemo(() => {
    return [...ordersListFlattenedWithProratedOrders].filter(
      (order) => order.status === OrderStatus.Finalized,
    );
  }, [ordersListFlattenedWithProratedOrders]);

  const selectedOrderUuids = uniq(
    selectedOrdersToSendShipments.map((order) => order.uuid),
  );
  const allFinalizedOrdersOnPageSelected = finalizedOrders.every((o) =>
    selectedOrderUuids.includes(o.uuid),
  );
  const onToggleSelectAllFinalizedOrdersOnPage = (checked: boolean) => {
    if (checked) {
      addShipmentsToSend(
        finalizedOrders.flatMap((order) => {
          return order.shipments
            .filter((s) => !s.hideFromBilling)
            .map((shipment) => ({
              orderUuid: order.uuid,
              shipmentUuid: shipment.uuid,
              contact: order.billingPartyContact,
            }));
        }),
      );
      addOrdersToSendShipments(finalizedOrders);
    } else {
      removeOrdersToSend(finalizedOrders.map((o) => o.uuid));
      removeOrdersToSendShipments(finalizedOrders.map((o) => o.uuid));
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
      }}
    >
      <List
        dense
        component="nav"
        subheader={
          <ListSubheader
            component="div"
            style={{
              backgroundColor: theme.palette.grey[100],
              borderBottom: `2px solid ${theme.palette.grey[300]}`,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography>
                Review bills (
                {outstandingOrdersInPage.length + searchedOrders.length})
              </Typography>
              {isSelectingOrdersToSendInReviewModal ? (
                <Checkbox
                  checked={allFinalizedOrdersOnPageSelected}
                  onChange={(e) => {
                    onToggleSelectAllFinalizedOrdersOnPage(e.target.checked);
                  }}
                />
              ) : (
                <Button
                  onClick={() => {
                    setSearchedOrders([]);
                    setOutstandingOrdersInPage([]);
                    setOutstandingOrderUuidsInReview([]);
                    setOpenedOutstandingOrderUuid(undefined);
                  }}
                >
                  Clear all
                </Button>
              )}
            </Box>
          </ListSubheader>
        }
        sx={{
          overflowY: 'scroll',
          boxShadow: 2,
          mb: '10px',
          '&& .Mui-selected': {
            borderRight: `5px solid ${theme.palette.primary.main}`,
          },
          minHeight: '80%',
        }}
      >
        {ordersListDataLoading ? (
          <Stack
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
              height: '100%',
            }}
          >
            <CircularProgress />
          </Stack>
        ) : (
          ordersListToDisplayInSidebar.map((order) =>
            !isNil(order.ordersProratedWith) &&
            order.ordersProratedWith.length > 0 ? (
              <Stack key={order.uuid}>
                <OutstandingOrdersSidebarListProratedOrderGroup
                  key={order.uuid}
                  firstOrder={{
                    order,
                    isDisabled: orderUuidsToSave.includes(order.uuid),
                    isSelected: selectedOrderUuid === order.uuid,
                  }}
                  otherOrdersInGroup={(order.ordersProratedWith ?? []).map(
                    (o) => ({
                      order: o,
                      isDisabled: orderUuidsToSave.includes(o.uuid),
                      isSelected: selectedOrderUuid === o.uuid,
                    }),
                  )}
                />
              </Stack>
            ) : (
              <OutstandingOrdersSidebarListItem
                key={order.uuid}
                isSelected={selectedOrderUuid === order.uuid}
                isDisabled={orderUuidsToSave.includes(order.uuid)}
                order={order}
              />
            ),
          )
        )}
      </List>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '5px',
        }}
      >
        <Button
          disabled={ordersListDataLoading || !hasPreviousPage}
          variant="outlined"
          startIcon={<ArrowLeft />}
          onClick={async () => {
            goToPreviousPage({
              saveCurrentOrderBeforeNavigating:
                !isNil(selectedOrderUuid) &&
                outstandingOrderUuidInForm === selectedOrderUuid,
            });
          }}
        >
          Prev page
        </Button>
        <Button
          disabled={ordersListDataLoading || !hasNextPage}
          variant="outlined"
          endIcon={<ArrowRight />}
          onClick={async () => {
            goToNextPage({
              saveCurrentOrderBeforeNavigating:
                !isNil(selectedOrderUuid) &&
                outstandingOrderUuidInForm === selectedOrderUuid,
            });
          }}
        >
          Next page
        </Button>
      </Box>
    </Box>
  );
};

export default memo(OutstandingOrdersListSidebar);
