import { Typography } from '@mui/material';
import { formatMoney } from '../../utils';

export type OrderReportRevenueProps = {
  readonly amount: number | null | undefined;
  readonly label: string;
  readonly isReportLoading: boolean;
};
const OrderReportRevenue = ({
  amount,
  label,
  isReportLoading,
}: OrderReportRevenueProps) => {
  const formattedRevenue = isReportLoading ? '-' : formatMoney({ amount });

  return <Typography>{`${label}: ${formattedRevenue}`}</Typography>;
};

export default OrderReportRevenue;
