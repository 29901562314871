import { z } from 'zod';
import { DocumentType } from '../../../../../generated/graphql';

export const documentSchema = z.object({
  uuid: z.string().uuid(),
  fileName: z.string(),
  bucket: z.string().nullish(),
  region: z.string().nullish(),
  key: z.string().nullish(),
  preSignedGetUrl: z.string(),
  fileType: z.string(),
  name: z.string().nullish(),
  type: z.nativeEnum(DocumentType),
  notes: z.string().nullish(),
  driverFormTemplateUuid: z.string().uuid().nullish(),
});
