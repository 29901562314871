import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { type ScannedOrderEntity } from '../../../generated/graphql';
import type { RootState } from '../../../redux/store';

export type ScannedOrder = Omit<
  ScannedOrderEntity,
  '__typename' | 'createdAt' | 'updatedAt'
>;

const scannedOrdersAdapter = createEntityAdapter<ScannedOrder>({
  selectId: (scannedOrder) => scannedOrder.uuid,
});

export const scannedOrdersSlice = createSlice({
  name: 'scannedOrder',
  initialState: scannedOrdersAdapter.getInitialState(),
  reducers: {
    addOneScannedOrder: scannedOrdersAdapter.addOne,
    upsertOneScannedOrder: scannedOrdersAdapter.upsertOne,
    addManyScannedOrder: scannedOrdersAdapter.addMany,
  },
});

export const { selectById: selectScannedOrderById } =
  scannedOrdersAdapter.getSelectors((state: RootState) => state.scannedOrders);

export const { addOneScannedOrder, upsertOneScannedOrder } =
  scannedOrdersSlice.actions;

const scannedOrdersReducer = scannedOrdersSlice.reducer;

export default scannedOrdersReducer;
