import { TableSortLabel } from '@mui/material';
import { pdf } from '@react-pdf/renderer';
import { capitalCase } from 'change-case';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { isNil, clamp } from 'lodash';
import { PDFDocument } from 'pdf-lib';
import pluralize from 'pluralize';
import { type Dispatch, type SetStateAction } from 'react';
import { safeDivide, safeAdd } from 'shared/math';
import { exhaustive } from 'shared/switch';
import { DownloadType } from '../../../../common/components/download-type-selection';
import { type Option } from '../../../../common/filters/types';
import createPagesForPdf from '../../../../common/utils/pdf-gen';
import {
  type AverageDaysToPayReportDataFragment,
  type MeQuery,
  type PaymentForMemoReportFragment,
  type PaymentForCheckRegisterReportFragment,
  PaymentType,
  PaymentRail,
  OrdersForPaymentsSortField,
  SortDirection,
  type OrdersForPaymentsSort,
} from '../../../../generated/graphql';
import GeneratedMemoReport from '../invoices/download/generated-memo-report';
import GeneratedCheckRegisterReport from './generated-check-register-report';

const CHECK_REGISTER_REPORT_CSV_HEADERS = [
  'Payment ref no',
  'Date',
  'Terminal',
  'Account ID',
  'Customer',
  'Comments',
  'Amount',
];

export const convertCheckRegisterReportDataToCSV = (
  payments: PaymentForCheckRegisterReportFragment[],
) => {
  const rows: Array<Array<string | number | undefined>> = payments.map(
    (payment) => {
      return [
        payment.referenceNumber ?? '',
        dayjs(payment.paymentDate).format('MM/DD/YY'),
        payment.terminalCodes?.join('/') ?? '',
        payment.contactReferenceNumber ?? '',
        payment.contactDisplayName ?? '',
        payment.comment ?? '',
        safeDivide(payment.amountInCents ?? 0, 100).toFixed(2),
      ];
    },
  );
  return [CHECK_REGISTER_REPORT_CSV_HEADERS, ...rows];
};

export const downloadCheckRegisterReport = async ({
  companyData,
  customerOption,
  terminalOption,
  startDate,
  endDate,
  payments,
  terminalsEnabled,
  downloadType,
}: {
  companyData: MeQuery | undefined;
  customerOption?: Option | null | undefined;
  terminalOption?: Option | null | undefined;
  startDate: Date | undefined;
  endDate: Date | undefined;
  payments: PaymentForCheckRegisterReportFragment[];
  terminalsEnabled: boolean;
  downloadType: DownloadType;
}) => {
  let dateString = 'All Time';
  if (!isNil(startDate) && !isNil(endDate)) {
    dateString = `${dayjs(startDate).format('MM/DD')} - ${dayjs(endDate).format(
      'MM/DD/YY',
    )}`;
  }
  const fileName = `check-register-report${
    isNil(customerOption?.label) ? '' : `-${customerOption?.label}`
  }${
    isNil(terminalOption?.label) ? '' : `-${terminalOption?.label}`
  }-${dateString}`;

  switch (downloadType) {
    case DownloadType.PDF: {
      const blob = await pdf(
        <GeneratedCheckRegisterReport
          companyData={companyData}
          dateString={dateString}
          payments={payments}
          customerOption={customerOption}
          terminalOption={terminalOption}
          terminalsEnabled={terminalsEnabled}
        />,
      ).toBlob();

      saveAs(blob, `${fileName}.pdf`);
      break;
    }
    case DownloadType.CSV: {
      return {
        fileName: `${fileName}.csv`,
        csvData: convertCheckRegisterReportDataToCSV(payments),
      };
    }
    default: {
      exhaustive(downloadType);
    }
  }
};

const AVERAGE_DAYS_TO_PAY_CSV_HEADERS = [
  'Customer',
  'Account ID',
  'Avg Days 90',
  'Avg Days 180',
  'Avg Days 270',
  'Avg Days 360',
  'Avg Days 720',
  'Avg Days All',
];

export const convertAverageDaysToPayDataToCSV = (
  reportData: AverageDaysToPayReportDataFragment[],
) => {
  const rows: Array<Array<string | number | undefined>> = [];
  for (const data of reportData) {
    rows.push([
      data.displayName,
      data.contactReferenceNumber ?? '',
      data.averageDaysToPay90?.toFixed(0) ?? '',
      data.averageDaysToPay180?.toFixed(0) ?? '',
      data.averageDaysToPay270?.toFixed(0) ?? '',
      data.averageDaysToPay360?.toFixed(0) ?? '',
      data.averageDaysToPay720?.toFixed(0) ?? '',
      data.averageDaysToPayAll?.toFixed(0) ?? '',
    ]);
  }
  return [AVERAGE_DAYS_TO_PAY_CSV_HEADERS, ...rows];
};

export const downloadMemoReport = async ({
  companyData,
  payment,
}: {
  companyData: MeQuery | undefined;
  payment: PaymentForMemoReportFragment;
}) => {
  const pdfDoc = await PDFDocument.create();
  const blob = await pdf(
    <GeneratedMemoReport companyData={companyData} payment={payment} />,
  ).toBlob();
  await createPagesForPdf(await blob.arrayBuffer(), 'application/pdf', pdfDoc);

  const pdfBytes = await pdfDoc.save();
  const file = new Blob([pdfBytes], {
    type: 'application/pdf',
  });

  const fileURL = globalThis.URL.createObjectURL(file);
  const alink = document.createElement('a');
  alink.href = fileURL;
  alink.download = `${payment.paymentType.toLowerCase()}-memo-report-${
    payment.creditNumber ?? payment.referenceNumber
  }.pdf`;
  alink.click();
};

export const getPrefillAmount = ({
  unappliedAmount,
  previouslyAppliedAmount,
  lineBalance,
  paymentType,
}: {
  unappliedAmount: number;
  previouslyAppliedAmount?: number | null;
  lineBalance: number;
  paymentType: PaymentType;
}) => {
  switch (paymentType) {
    case PaymentType.Credit:
    case PaymentType.Payment: {
      return safeAdd(
        previouslyAppliedAmount ?? 0,
        clamp(unappliedAmount, 0, Math.max(lineBalance ?? 0, 0)),
      );
    }
    case PaymentType.Debit: {
      return Math.max(unappliedAmount, 0);
    }
    default: {
      return exhaustive(paymentType);
    }
  }
};

export const getPaymentLessThanBalanceMessage = ({
  paymentType,
  totalOrders,
  appliedOrders,
}: {
  paymentType: PaymentType;
  totalOrders: number;
  appliedOrders: number;
}) => {
  return `${capitalCase(paymentType)} cannot be applied to all ${totalOrders} orders because the payment amount is less than the invoice balance. Would you like to apply the ${paymentType.toLowerCase()} to ${appliedOrders} ${pluralize('order', appliedOrders)}?`;
};

export const formatPaymentRail = (paymentRail: PaymentRail) => {
  switch (paymentRail) {
    case PaymentRail.Check: {
      return 'Check';
    }
    case PaymentRail.Ach: {
      return 'ACH';
    }
    default: {
      return exhaustive(paymentRail);
    }
  }
};

export const getPaymentRailColorForChip = (
  paymentRail: PaymentRail | string,
) => {
  switch (paymentRail) {
    case PaymentRail.Check: {
      return 'info';
    }
    case PaymentRail.Ach: {
      return 'warning';
    }
    default: {
      return 'default';
    }
  }
};

// Use this if exactly 1 sort (not 0 or multiple) should be active
export const SingleColumnPaymentApplicationOrdersTableSortLabel = ({
  label,
  sortBy,
  currentSort,
  setSort,
  onClick,
}: {
  readonly label: string;
  readonly sortBy: OrdersForPaymentsSortField; // This column's sort field
  readonly currentSort: OrdersForPaymentsSort | null; // Currently set sort
  readonly setSort: Dispatch<SetStateAction<OrdersForPaymentsSort | null>>;
  readonly onClick?: () => void;
}) => {
  const sortActive = currentSort?.sortBy === sortBy;

  const handleChangeSort = () => {
    if (currentSort?.sortBy === sortBy) {
      // toggle direction
      setSort({
        sortBy,
        sortDirection:
          currentSort.sortDirection === SortDirection.Asc
            ? SortDirection.Desc
            : SortDirection.Asc,
      });
    } else {
      // set to default for field
      switch (sortBy) {
        case OrdersForPaymentsSortField.ShipperBillOfLadingNumber: {
          setSort({
            sortBy,
            sortDirection: SortDirection.Asc,
          });
          break;
        }
        default: {
          exhaustive(sortBy);
        }
      }
    }
  };

  return (
    <TableSortLabel
      active={sortActive}
      direction={
        sortActive
          ? (currentSort.sortDirection.toLowerCase() as 'asc' | 'desc')
          : undefined
      }
      hideSortIcon={false}
      onClick={() => {
        handleChangeSort();
        if (!isNil(onClick)) {
          onClick();
        }
      }}
    >
      {label}
    </TableSortLabel>
  );
};
