/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { isNil } from 'lodash';
import { filterNotNil } from 'shared/array';
import apolloClient from '../../../apollo-client';
import {
  CreateShipmentDocumentDocument,
  type CreateShipmentDocumentMutation,
  type CreateShipmentDocumentMutationVariables,
  type DocumentCreateInput,
  type DocumentUpsertInput,
  type StandardShipmentFragment,
} from '../../../generated/graphql';
import type { RootState } from '../../../redux/store';
import {
  addDocument,
  selectDocumentsByIds,
  upsertDocument,
} from './documents-values-slice';

type CreateDocumentCreateInputArg = {
  documentIds: string[];
};

export const createDocumentsCreateInput = createAsyncThunk<
  DocumentCreateInput[],
  CreateDocumentCreateInputArg,
  { state: RootState }
>(
  'documents/createDocumentCreateInput',
  async (arg, thunkAPI): Promise<DocumentCreateInput[]> => {
    const documentValues = selectDocumentsByIds(
      thunkAPI.getState(),
      arg.documentIds,
    );
    if (isNil(documentValues)) {
      throw new Error('Could not find custom charges');
    }

    const filteredDocumentValues = documentValues.filter(
      (document) => document.isDeleted !== true,
    );
    return filteredDocumentValues.map((document) => {
      return {
        fileName: document.fileName,
        bucket: document.bucket,
        key: document.key,
        region: document.region,
        fileType: document.fileType,
        name: document.name,
        type: document.type,
      };
    });
  },
);

type CreateDocumentUpsertInputArg = {
  documentIds: string[];
  orderUuid?: string;
  invoiceUuid?: string;
};

export const createDocumentsUpsertInput = createAsyncThunk<
  DocumentUpsertInput[],
  CreateDocumentUpsertInputArg,
  { state: RootState }
>(
  'documents/createDocumentUpsertInput',
  async (arg, thunkAPI): Promise<DocumentUpsertInput[]> => {
    const documentValues = selectDocumentsByIds(
      thunkAPI.getState(),
      arg.documentIds,
    );
    if (isNil(documentValues)) {
      throw new Error('Could not find custom charges');
    }

    return documentValues.map((document) => {
      return {
        fileName: document.fileName,
        bucket: document.bucket,
        region: document.region,
        key: document.key,
        fileType: document.fileType,
        uuid: document.uuid,
        isDeleted: document.isDeleted ?? false,
        orderUuid: arg.orderUuid,
        invoiceUuid: arg.invoiceUuid,
        name: document.name,
        type: document.type,
        uploadedAt: null,
      };
    });
  },
);

export const upsertDocumentsForShipment = createAsyncThunk<
  string[],
  {
    shipment: StandardShipmentFragment;
    isDuplicate: boolean;
  },
  { state: RootState }
>(
  'addresses/upsertAddressForShipment',
  async (arg, thunkAPI): Promise<string[]> => {
    const { shipment, isDuplicate } = arg;
    if (isDuplicate) {
      if (!isNil(shipment.documents)) {
        const documents = await Promise.all(
          shipment.documents.map(async (document) => {
            return apolloClient.mutate<
              CreateShipmentDocumentMutation,
              CreateShipmentDocumentMutationVariables
            >({
              mutation: CreateShipmentDocumentDocument,
              variables: {
                createShipmentDocumentInput: {
                  documentType: document.type,
                  fileName: document.fileName,
                  fileType: document.fileType,
                  name: document.name,
                  shipmentUuid: shipment.uuid,
                  isNewlyCreatedShipment: true,
                },
              },
            });
          }),
        );

        await Promise.all(
          documents.map((doc) => {
            const document = doc.data?.createShipmentDocument;
            if (!isNil(document)) {
              return thunkAPI.dispatch(
                addDocument({
                  uuid: document.uuid,
                  fileName: document.fileName,
                  bucket: document.bucket,
                  key: document.key,
                  fileType: document.fileType,
                  region: document.region,
                  name: document.name,
                  type: document.type,
                  driverFormTemplate: document.driverFormTemplate,
                }),
              );
            }
            return null;
          }),
        );
        const docUuids = documents.map((doc) => {
          return doc.data?.createShipmentDocument.uuid;
        });
        return filterNotNil(docUuids);
      }
    } else if (!isNil(shipment.documents)) {
      await Promise.all(
        shipment.documents.map((document) =>
          thunkAPI.dispatch(upsertDocument(document)),
        ),
      );
    }
    return shipment.documents.map((doc) => doc.uuid);
  },
);
