import { joiResolver } from '@hookform/resolvers/joi';
import joi from 'joi';
import { useForm } from 'react-hook-form';
import { type BaseStorageOrderItemFormValues } from '../types';

export type AssignInventoryItemFormValues = {
  quantity: number;
};

const useAssignInventoryItemsForm = () => {
  const schema = joi.object({
    quantity: joi.number().positive().required().messages({
      'any.required': 'Quantity is required',
      'any.invalid': 'Quantity must be positive',
    }),
  });
  return useForm<BaseStorageOrderItemFormValues>({
    resolver: joiResolver(schema),
  });
};

export default useAssignInventoryItemsForm;
