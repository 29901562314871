import { Checkbox, TableCell, TableRow, TextField } from '@mui/material';
import { sentenceCase } from 'change-case';
import { isEmpty, isNil, round } from 'lodash';
import React from 'react';
import { cubicFeet, pounds } from 'shared/units/rates';
import { shallow } from 'zustand/shallow';
import { VehicleAutocomplete } from '../../../common/components/vehicle-autocomplete';
import { getDriverName } from '../../../common/utils/utils';
import { type DriversForSelectionQuery } from '../../../generated/graphql';
import useGenerateRoutesStore from './use-generate-routes-store';

const DriverVehicleSelectionRowComponent = ({
  driver,
  hide,
}: {
  readonly driver: DriversForSelectionQuery['drivers']['0'];
  readonly hide: boolean;
}) => {
  const [driverEquipmentConstraint, upsertDriverEquipmentConstraint] =
    useGenerateRoutesStore(
      (state) => [
        state.driverEquipmentConstraints.find(
          (constraint) => constraint.driverUuid === driver.uuid,
        ),
        state.upsertDriverEquipmentConstraint,
      ],
      shallow,
    );

  const onCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    upsertDriverEquipmentConstraint({
      active: e.target.checked,
      driverUuid: driver.uuid,
    });
  };

  const maxVolume = driverEquipmentConstraint?.maxVolume;
  const maxWeight = driverEquipmentConstraint?.maxWeight;
  const maxPieces = driverEquipmentConstraint?.maxPieces;

  return (
    <TableRow sx={{ display: hide ? 'none' : undefined }}>
      <TableCell>
        <Checkbox
          checked={driverEquipmentConstraint?.active === true}
          onChange={onCheck}
        />
      </TableCell>
      <TableCell>{getDriverName(driver)}</TableCell>
      <TableCell>{driver.driverReferenceNumber}</TableCell>
      <TableCell>{sentenceCase(driver.driverType ?? '')}</TableCell>
      <TableCell>
        <VehicleAutocomplete
          selectedEquipmentUuid={
            driverEquipmentConstraint?.equipmentUuid ?? null
          }
          onChange={(equipment) => {
            upsertDriverEquipmentConstraint({
              active: true,
              driverUuid: driver.uuid,
              equipmentUuid: equipment?.uuid ?? null,
              maxVolume: equipment?.vehicleType?.maxVolume,
              maxWeight: equipment?.vehicleType?.maxWeight,
            });
          }}
        />
      </TableCell>
      <TableCell>
        <TextField
          inputProps={{ pattern: '[0-9]*' }}
          size="small"
          value={
            isNil(maxVolume)
              ? ''
              : String(round(maxVolume.in(cubicFeet).amount, 2))
          }
          onChange={(e) => {
            upsertDriverEquipmentConstraint({
              active: true,
              driverUuid: driver.uuid,
              maxVolume: isEmpty(e.target.value)
                ? null
                : cubicFeet(Number.parseFloat(e.target.value)),
            });
          }}
        />
      </TableCell>
      <TableCell>
        <TextField
          inputProps={{ pattern: '[0-9]*' }}
          size="small"
          value={
            isNil(maxWeight)
              ? ''
              : String(round(maxWeight.in(pounds).amount, 2))
          }
          onChange={(e) => {
            upsertDriverEquipmentConstraint({
              active: true,
              driverUuid: driver.uuid,
              maxWeight: isEmpty(e.target.value)
                ? null
                : pounds(Number.parseFloat(e.target.value)),
            });
          }}
        />
      </TableCell>
      <TableCell>
        <TextField
          inputProps={{ pattern: '[0-9]*' }}
          size="small"
          value={isNil(maxPieces) ? '' : String(round(maxPieces, 2))}
          onChange={(e) => {
            upsertDriverEquipmentConstraint({
              active: true,
              driverUuid: driver.uuid,
              maxPieces: isEmpty(e.target.value)
                ? null
                : Number.parseInt(e.target.value, 10),
            });
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export const DriverVehicleSelectionRow = React.memo(
  DriverVehicleSelectionRowComponent,
);
