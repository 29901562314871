import {
  InsertChartOutlined as ReportsIcon,
  LocalShippingOutlined as DispatchIcon,
  MonetizationOnOutlined as AccountingIcon,
  ReceiptOutlined as OrdersIcon,
  SettingsOutlined as SettingsIcon,
  EmailOutlined as EmailIcon,
} from '@mui/icons-material';
import { isEmpty, isNil } from 'lodash';
import { getPermissionsFlags } from 'shared/roles';
import { FeatureFlag } from '../../common/feature-flags';
import useFeatureFlag from '../../common/react-hooks/use-feature-flag';
import useMe from '../../common/react-hooks/use-me';
import useUserRoles from '../../common/react-hooks/use-user-roles';
import {
  findCompanyPagePermissionsWithAccessLevel,
  findSettingsPagePermissionsWithAccessLevel,
} from '../../common/utils/roles';
import {
  AccessLevel,
  PermissionResource,
  PalletProductOffering,
} from '../../generated/graphql';
import WarehouseIcon from './icons/drawer-shelves.svg?react';
import {
  type DashboardDrawerItem,
  DashboardPage,
  type DashboardTabs,
} from './types';

const getTmsDrawerItems = ({
  schedulingEnabled,
  wmsEnabled,
  wmsStorageOrdersEnabled,
  wmsShortTermStorageEnabled,
  canReadOrders,
  canReadScannedOrders,
  canReadEmailOrders,
  canReadEdiApiOrders,
  canReadQuotes,
  canReadRoutes,
  canReadDailyControlCenter,
  shouldShowLineHaulEnabled,
  canReadAppointments,
  canReadEndOfDay,
  canReadBillingReview,
  canReadInvoices,
  canReadCustomers,
  canReadDriverSettlements,
  canReadReports,
  canReadWarehouses,
  canReadContacts,
  showCompanyDrawerItem,
  showSettingsDrawerItem,
  ffDemoLoadManagement,
}: {
  schedulingEnabled: boolean;
  wmsEnabled: boolean | undefined | null;
  wmsStorageOrdersEnabled: boolean | null | undefined;
  wmsShortTermStorageEnabled: boolean | null | undefined;
  canReadOrders: boolean | null | undefined;
  canReadScannedOrders: boolean | null | undefined;
  canReadEmailOrders: boolean | null | undefined;
  canReadEdiApiOrders: boolean | null | undefined;
  canReadQuotes: boolean | null | undefined;
  canReadRoutes: boolean | null | undefined;
  canReadDailyControlCenter: boolean | null | undefined;
  shouldShowLineHaulEnabled: boolean | null | undefined;
  canReadAppointments: boolean | null | undefined;
  canReadEndOfDay: boolean | null | undefined;
  canReadBillingReview: boolean | null | undefined;
  canReadInvoices: boolean | null | undefined;
  canReadCustomers: boolean | null | undefined;
  canReadDriverSettlements: boolean | null | undefined;
  canReadReports: boolean | null | undefined;
  canReadWarehouses: boolean | null | undefined;
  canReadContacts: boolean | null | undefined;
  showCompanyDrawerItem: boolean;
  showSettingsDrawerItem: boolean;
  ffDemoLoadManagement: boolean;
}): DashboardDrawerItem[] => {
  const showOrdersDrawerItem =
    canReadOrders === true ||
    canReadScannedOrders === true ||
    canReadEdiApiOrders === true ||
    canReadQuotes === true;
  const showDispatchDrawerItem =
    canReadRoutes === true ||
    canReadDailyControlCenter === true ||
    shouldShowLineHaulEnabled === true ||
    canReadAppointments === true ||
    canReadEndOfDay === true;
  const showAccountingDrawerItem =
    canReadBillingReview === true ||
    canReadInvoices === true ||
    canReadCustomers === true ||
    canReadDriverSettlements === true;
  const showWarehouseDrawerItem =
    canReadWarehouses === true && wmsEnabled === true;
  const showReportsDraweritem = canReadReports === true;
  const showContactsDraweritem = canReadContacts === true;
  const showEmailsDrawerItem = canReadEmailOrders === true;

  const WAREHOUSE_TABS = [
    {
      url: '/warehouse/storage-orders',
      name: 'Receipts',
      visibilityCondition: wmsStorageOrdersEnabled,
    },
    {
      url: '/warehouse/inventory',
      name: 'Inventory',
      visibilityCondition: wmsStorageOrdersEnabled,
    },
    {
      url: '/warehouse/reports',
      name: 'Reports',
      visibilityCondition: wmsShortTermStorageEnabled,
    },
    {
      url: '/warehouse/settings',
      name: 'Settings',
    },
  ];
  const ORDER_TABS: DashboardTabs[] = [
    { url: '/orders', name: 'All orders', visibilityCondition: canReadOrders },
    {
      url: '/orders/scans',
      name: 'Scanned orders',
      visibilityCondition: canReadScannedOrders,
    },
    {
      url: '/orders/inbound-messages',
      name: 'EDI / API messages',
      visibilityCondition: canReadEdiApiOrders,
    },
    {
      url: '/orders/inbound-messages/email',
      name: 'Orders from email',
      visibilityCondition: showEmailsDrawerItem,
    },
    {
      url: '/orders/quotes',
      name: 'Quotes',
      visibilityCondition: canReadQuotes,
    },
  ];

  const DISPATCH_TABS: DashboardTabs[] = [
    { url: '/dispatch', name: 'Routes', visibilityCondition: canReadRoutes },
    {
      url: '/dispatch/line-haul',
      name: 'Line haul',
      visibilityCondition: shouldShowLineHaulEnabled,
    },
    {
      url: '/dispatch/daily-control-center',
      name: 'Daily control center',
      visibilityCondition: canReadDailyControlCenter,
    },
    {
      url: '/dispatch/appointments',
      name: 'Delivery notifications',
      visibilityCondition: canReadAppointments,
    },
    {
      url: '/dispatch/reservation-system',
      name: 'Reservation system',
      visibilityCondition: schedulingEnabled,
    },
    {
      url: '/dispatch/end-of-day',
      name: 'End of day',
      visibilityCondition: canReadEndOfDay,
    },
  ];

  const ACCOUNTING_TABS: DashboardTabs[] = [
    {
      url: '/accounting',
      name: 'Invoicing',
      visibilityCondition: canReadInvoices,
    },
    {
      url: '/accounting/driver-settlement',
      name: ffDemoLoadManagement ? 'Settlement' : 'Driver settlement',
      visibilityCondition: canReadDriverSettlements,
    },
  ];

  const iconSize = 16;

  let drawerItems: DashboardDrawerItem[] = [
    {
      page: DashboardPage.ORDERS,
      name: 'Orders',
      icon: <OrdersIcon sx={{ height: iconSize, width: iconSize }} />,
      url: '/orders',
      tabs: ORDER_TABS,
      visibilityCondition: showOrdersDrawerItem,
    },
    {
      page: DashboardPage.TRANSIT,
      name: 'Dispatch',
      icon: <DispatchIcon sx={{ height: iconSize, width: iconSize }} />,
      url: '/dispatch',
      tabs: DISPATCH_TABS,
      visibilityCondition: showDispatchDrawerItem,
    },
    {
      page: DashboardPage.ACCOUNTING,
      name: 'Accounting',
      icon: <AccountingIcon sx={{ height: iconSize, width: iconSize }} />,
      url: '/accounting',
      tabs: ACCOUNTING_TABS,
      visibilityCondition: showAccountingDrawerItem,
    },
    {
      page: DashboardPage.WAREHOUSE,
      name: 'Warehouse',
      icon: <WarehouseIcon style={{ height: iconSize, width: iconSize }} />,
      url: '/warehouse',
      navbarUrl:
        wmsStorageOrdersEnabled === true
          ? '/warehouse/storage-orders'
          : '/warehouse/reports',
      tabs: WAREHOUSE_TABS,
      visibilityCondition: showWarehouseDrawerItem,
    },
    {
      page: DashboardPage.REPORTS,
      name: 'Reports',
      icon: <ReportsIcon sx={{ height: iconSize, width: iconSize }} />,
      url: '/reports',
      tabs: [{ url: '/reports', name: 'Reports' }],
      visibilityCondition: showReportsDraweritem,
    },
    {
      page: DashboardPage.SETTINGS,
      name: 'Settings',
      icon: <SettingsIcon sx={{ height: iconSize, width: iconSize }} />,
      url: '/settings',
      tabs: [
        {
          url: '/contacts',
          name: 'Customers',
          visibilityCondition: showContactsDraweritem,
        },
        {
          url: '/management',
          name: 'Company',
          visibilityCondition: showCompanyDrawerItem,
        },
        {
          url: '/settings',
          name: 'Settings',
          visibilityCondition: showSettingsDrawerItem,
        },
      ],
      visibilityCondition:
        showSettingsDrawerItem ||
        showCompanyDrawerItem ||
        showContactsDraweritem,
    },
  ];

  drawerItems = drawerItems.filter(
    (item) => isNil(item.visibilityCondition) || item.visibilityCondition,
  );

  for (let i = 0; i < drawerItems.length; i += 1) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    drawerItems[i] = {
      ...drawerItems[i],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      tabs: drawerItems[i].tabs.filter(
        (item) => isNil(item.visibilityCondition) || item.visibilityCondition,
      ),
    };
  }

  return drawerItems;
};

const getEmailIngestionDrawerItems = (): DashboardDrawerItem[] => {
  const TABS: DashboardTabs[] = [
    {
      url: '/email-ingestion/configure',
      name: 'Configure',
      visibilityCondition: true,
    },
    {
      url: '/email-ingestion/review',
      name: 'Review',
      visibilityCondition: true,
    },
  ];

  const iconSize = 16;

  let drawerItems: DashboardDrawerItem[] = [
    {
      page: DashboardPage.EMAIL_INGESTION,
      name: 'Email ingestion',
      icon: <EmailIcon sx={{ height: iconSize, width: iconSize }} />,
      url: '/email-ingestion',
      tabs: TABS,
      visibilityCondition: true,
    },
  ];

  drawerItems = drawerItems.filter(
    (item) => isNil(item.visibilityCondition) || item.visibilityCondition,
  );

  for (let i = 0; i < drawerItems.length; i += 1) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    drawerItems[i] = {
      ...drawerItems[i],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      tabs: drawerItems[i].tabs.filter(
        (item) => isNil(item.visibilityCondition) || item.visibilityCondition,
      ),
    };
  }

  return drawerItems;
};

const getFinalDrawerItems = ({
  tmsDrawerItems,
  emailIngestionDrawerItems,
  purchasedProductOfferings,
}: {
  tmsDrawerItems: DashboardDrawerItem[];
  emailIngestionDrawerItems: DashboardDrawerItem[];
  purchasedProductOfferings: PalletProductOffering[];
}) => {
  const drawerItems: DashboardDrawerItem[] = [];

  if (purchasedProductOfferings.includes(PalletProductOffering.Tms)) {
    drawerItems.push(...tmsDrawerItems);
  }
  if (
    purchasedProductOfferings.includes(PalletProductOffering.EmailIngestion)
  ) {
    drawerItems.push(...emailIngestionDrawerItems);
  }

  return drawerItems;
};

const useDrawerItems = () => {
  const { companyConfiguration, purchasedProductOfferings } = useMe();
  const { userPermissions } = useUserRoles();

  // Orders
  const { canRead: canReadOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Orders,
  );
  const { canRead: canReadScannedOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.ScannedOrders,
  );
  const { canRead: canReadEdiApiOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.EdiApiOrders,
  );
  const { canRead: canReadQuotes } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Quote,
  );

  // Dispatch
  const { canRead: canReadRoutes } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Routes,
  );
  const { canRead: canReadDailyControlCenter } = getPermissionsFlags(
    userPermissions,
    PermissionResource.DailyControlCenter,
  );
  const { canRead: canReadLineHaulDispatch } = getPermissionsFlags(
    userPermissions,
    PermissionResource.LineHaulDispatch,
  );
  const shouldShowLineHaulEnabled =
    (companyConfiguration?.lineHaulEnabled ?? false) && canReadLineHaulDispatch;

  const { canRead: canReadAppointments } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Appointments,
  );
  const { canRead: canReadEndOfDay } = getPermissionsFlags(
    userPermissions,
    PermissionResource.EndOfDay,
  );

  // Accounting
  const { canRead: canReadBillingReview } = getPermissionsFlags(
    userPermissions,
    PermissionResource.BillingReview,
  );
  const { canRead: canReadInvoices } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Invoices,
  );
  const { canRead: canReadCustomers } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Customers,
  );
  const driverSettlementEnabled =
    companyConfiguration?.showDriverSettlementInBilling === true;
  const { canRead: canReadDriverSettlements } = getPermissionsFlags(
    userPermissions,
    PermissionResource.DriverSettlements,
  );

  // Warehouse
  const { canRead: canReadWarehouses } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Warehouses,
  );

  // Reports
  const { canRead: canReadReports } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Reports,
  );

  // Contacts
  const { canRead: canReadContacts } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Contacts,
  );

  // Company
  const companyPermissionResourcesWithReadAccess =
    findCompanyPagePermissionsWithAccessLevel(
      userPermissions,
      AccessLevel.Read,
    );
  const showCompanyDrawerItem = !isEmpty(
    companyPermissionResourcesWithReadAccess,
  );

  // Settings
  const settingsPermissionResourcesWithReadAccess =
    findSettingsPagePermissionsWithAccessLevel(
      userPermissions,
      AccessLevel.Read,
    );
  const showSettingsDrawerItem = !isEmpty(
    settingsPermissionResourcesWithReadAccess,
  );

  const wmsEnabled = companyConfiguration?.wmsEnabled ?? false;
  const wmsStorageOrdersEnabled =
    companyConfiguration?.wmsStorageOrdersEnabled ?? false;
  const wmsShortTermStorageEnabled =
    companyConfiguration?.wmsShortTermStorageEnabled ?? false;

  const schedulingEnabled = useFeatureFlag(FeatureFlag.FF_SCHEDULING);
  const { canRead: canReadEmailOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.EmailOrders,
  );
  const ffDemoLoadManagement = useFeatureFlag(
    FeatureFlag.FF_DEMO_LOAD_MANAGEMENT,
  );

  const tmsDrawerItems = getTmsDrawerItems({
    schedulingEnabled,
    wmsEnabled,
    wmsStorageOrdersEnabled,
    wmsShortTermStorageEnabled,
    canReadOrders,
    canReadScannedOrders,
    canReadEmailOrders,
    canReadEdiApiOrders,
    canReadQuotes,
    canReadRoutes,
    canReadDailyControlCenter,
    shouldShowLineHaulEnabled,
    canReadAppointments,
    canReadEndOfDay,
    canReadBillingReview,
    canReadInvoices,
    canReadCustomers,
    canReadDriverSettlements:
      canReadDriverSettlements && driverSettlementEnabled,
    canReadWarehouses,
    canReadReports,
    canReadContacts,
    showCompanyDrawerItem,
    showSettingsDrawerItem,
    ffDemoLoadManagement,
  });

  const emailIngestionDrawerItems = getEmailIngestionDrawerItems();

  return {
    drawerItems: getFinalDrawerItems({
      tmsDrawerItems,
      emailIngestionDrawerItems,
      purchasedProductOfferings: purchasedProductOfferings ?? [],
    }),
  };
};

export default useDrawerItems;
